import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Setting SMS instant lead alerts with MessageBird`}</h1>
    <h3>{`⏱ 15 min build time      ||      `}<a parentName="h3" {...{
        "href": "https://github.com/messagebirdguides/lead-alerts-guide-python"
      }}>{`Download the Code`}</a></h3>
    <h2>{`Why build instant lead alerts for sales?`}</h2>
    <p>{`In this MessageBird Developer Tutorial, you’ll learn how to build SMS lead alerts with the `}<a parentName="p" {...{
        "href": "/api/sms-messaging"
      }}>{`MessageBird SMS Messaging API`}</a>{` and convert more leads by instantly notifying your sales team. In this tutorial the sample application will be for our fictitious car dealership, `}<em parentName="p">{`M.B. Cars`}</em>{`.`}</p>
    <p>{`Even though a lot of business transactions happen on the web, from both a business and user perspective, it's still often preferred to switch the channel and talk on the phone. Especially when it comes to high-value transactions in industries such as real estate or mobility, personal contact is essential.`}</p>
    <p>{`One way to streamline this workflow is by building callback forms on your website. Through these forms, customers can enter their contact details and receive a call to their phone, thus skipping queues where prospective leads need to stay on hold.`}</p>
    <p>{`Callback requests reflect a high level of purchase intent and should be dealt with as soon as possible to increase the chance of converting a lead; therefore, it's essential to get them pushed to a sales agent quickly. SMS messaging has proven to be one of the most instant and effective channels for this use case.`}</p>
    <h2>{`Getting started`}</h2>
    <p>{`First things first, our sample application is build in Python, so you need to have Python and the `}<a parentName="p" {...{
        "href": "http://flask.pocoo.org/docs/0.12/"
      }}>{`Flask`}</a>{` framework installed. You can use the Python package manager `}<a parentName="p" {...{
        "href": "https://pip.pypa.io/en/stable/"
      }}>{`pip`}</a>{` to install Flask as follows:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`pip install Flask
`}</code></pre>
    <p>{`The source code is available in the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/lead-alerts-guide-python"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{` from which it can be cloned or downloaded into your development environment.`}</p>
    <p>{`After saving the code, open a console for the download directory and run the following command, which installs the MessageBird Python client:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`pip install messagebird
`}</code></pre>
    <h2>{`Configuring the MessageBird client`}</h2>
    <p>{`The MessageBird Python client is used to send messages. It's added as a dependency and loaded with the following lines in `}<inlineCode parentName="p">{`app.py`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`#create instance of messagebird.Client using API key
client = messagebird.Client(app.config['SECRET_KEY'])
`}</code></pre>
    <p>{`You need an API key, which you can retrieve from the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`MessageBird Dashboard`}</a>{`. As you can see in the code example above, the key is set as a parameter when instantiating the MessageBird Python client, and it's loaded from an environment variable called `}<inlineCode parentName="p">{`SECRET_KEY`}</inlineCode>{`. We define configuration variables in the file `}<inlineCode parentName="p">{`config_file.cfg`}</inlineCode>{`—edit this file to use your own API key.`}</p>
    <p>{`In the same file, we specify the sales agents' telephone numbers. These are the recipients that will receive the SMS alerts when a potential customer submits the callback form. You can separate multiple numbers with commas. In our application, you'll have to include the country code with the numbers.`}</p>
    <p>{`You can also specify additional Flask configuration variables. When testing your application, it's often useful to set the variable `}<inlineCode parentName="p">{`DEBUG`}</inlineCode>{` to `}<inlineCode parentName="p">{`True`}</inlineCode>{`.`}</p>
    <p>{`Here's an example of a valid configuration file for our sample application:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-env"
      }}>{`SECRET_KEY='YOUR_API_KEY'
DEBUG=True
SALES_AGENT_NUMBERS = '+1312XXXXXXX, +1412XXXXXXX'
`}</code></pre>
    <h2>{`Showing a landing page`}</h2>
    <p>{`The landing page is a simple HTML page with information about our company, a call to action and a form with two input fields, name and number, and a submit button. We use Jinja templates for our HTML pages. You can see the landing page in the file `}<inlineCode parentName="p">{`templates/index.html`}</inlineCode>{`. The `}<inlineCode parentName="p">{`@app.route('/', methods=['GET', 'POST'])`}</inlineCode>{` route in `}<inlineCode parentName="p">{`app.py`}</inlineCode>{` is responsible for rendering it.`}</p>
    <h2>{`Handling callback requests`}</h2>
    <p>{`When the user submits the form, the `}<inlineCode parentName="p">{`app.post('/callMe')`}</inlineCode>{` route receives their name and number from the HTML form. These fields are marked as "required" in our `}<inlineCode parentName="p">{`index.html`}</inlineCode>{` template.`}</p>
    <p>{`First, we define where to send the message. As you've seen above, we specified multiple recipient numbers in the `}<inlineCode parentName="p">{`SALES_AGENT_NUMBERS`}</inlineCode>{` configuration variable. `}<em parentName="p">{`M.B. Cars`}</em>{` has decided to randomly distribute incoming calls to their staff so that every salesperson receives roughly the same number of leads. We do this by constructing a list from the comma-separated value of `}<inlineCode parentName="p">{`SALES_AGENT_NUMBERS`}</inlineCode>{`. When the message is created, we use a random number from the list by using `}<inlineCode parentName="p">{`random.choice(numbers)`}</inlineCode>{` as the recipient's number.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`#when form is submitted
if request.method=="POST":
   #make array of sales agent numbers. We'll send the SMS to a random choice from this array.
   numbers = app.config['SALES_AGENT_NUMBERS'].split(",")
`}</code></pre>
    <p>{`Now we can formulate a message for the agent and send it through the MessageBird client using the `}<inlineCode parentName="p">{`message_create()`}</inlineCode>{` method:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`#try submitting to MessageBird client
try:
   verify = client.message_create('M. B. Cars',
            random.choice(numbers),
            "You have a new lead: " + request.form['customer_name'] + ". Call them at " + request.form['phone'])
`}</code></pre>
    <p>{`There are three parameters:`}</p>
    <ul>
      <li parentName="ul">{`"M. B. Cars": This is the sender ID; it will appear to come from a source with this name.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`random.choice(numbers)`}</inlineCode>{`: A random element from the `}<inlineCode parentName="li">{`numbers`}</inlineCode>{` list defined earlier. The API supports an array of recipients; in this case we're sending the message to only one number, so we don't use an array.`}</li>
      <li parentName="ul">{`The text of the message, including variables from the form inputs.`}</li>
    </ul>
    <p>{`If the message is successfully created, we display a success page confirming the prospective customer's information:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`#on success, redirect the user to the confirmation page
return render_template('success.html',
                        name=request.form['customer_name'],
                        phone=request.form['phone'])
`}</code></pre>
    <p>{`The success page follows the template stored at `}<inlineCode parentName="p">{`templates/success.html`}</inlineCode>{`.`}</p>
    <p>{`If there's an error while creating the message, we print the error to the console and flash its description above the form:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`#on failure, flash error on webpage.
except messagebird.client.ErrorException as e:
   for error in e.errors:
       print (error)
       flash('  description : %s\\n' % error.description)
   return render_template('index.html')
`}</code></pre>
    <h2>{`Testing`}</h2>
    <p>{`Have you edited your `}<inlineCode parentName="p">{`config_file.cfg`}</inlineCode>{` file to contain a working key and added your phone number to the existing phone numbers, as explained above in `}<em parentName="p">{`Configuring the MessageBird client`}</em>{`, to receive the lead alert? Lovely!`}</p>
    <p>{`Now run the following command from your console:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`python app.py
`}</code></pre>
    <p>{`Go to http://localhost:5000/ to see the form and request a lead!`}</p>
    <p>{`Awesome! You can now use the flow, code snippets and UI examples from this tutorial as an inspiration to build your own SMS-based lead alerts application. Don't forget to download the code from the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/lead-alerts-guide-python"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`.`}</p>
    <p><strong parentName="p">{`Nice work!`}</strong>{` 🎉`}</p>
    <p>{`You've just built your own instant lead alerts application with MessageBird using Python!`}</p>
    <h2>{`Start building!`}</h2>
    <p>{`Want to build something similar but not quite sure how to get started? Please feel free to let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`;  we'd love to help!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      