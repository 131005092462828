import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Setting up your local development environment with MessageBird`}</h1>
    <h3>{`⏱ 10 min read`}</h3>
    <p>{`In this MessageBird Developer Tutorial you’ll first learn how to get started with MessageBird by setting up first your local development environment.`}</p>
    <p>{`MessageBird provides an SDK (Software Development Kit) for Ruby. This helper library facilitates interactions between your Ruby application's code and the MessageBird APIs, so you do not have to craft raw REST API requests.`}</p>
    <h2>{`Install Ruby and Bundler`}</h2>
    <p>{`Make sure that you have at least Ruby version 1.9 installed on your computer, as this is the minimum version of Ruby that the SDK requires. You also need bundler, Ruby's package manager. The two are typically installed in a single bundle; we recommended you to always use the latest versions of Ruby and bundler.`}</p>
    <p>{`To verify which version do you have, open a terminal and run the following two commands:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`ruby -v
bundle -v
`}</code></pre>
    <p>{`In both cases, your terminal should return a version number, for example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ ruby -v
ruby 2.3.7p456 (2018-03-28 revision 63024) [universal.x86_64-darwin18]
$ bundle -v
Bundler version 1.16.6
`}</code></pre>
    <p>{`If you don’t get a version number but something like `}<inlineCode parentName="p">{`ruby: command not found`}</inlineCode>{` instead, you need to install Ruby and bundler first.`}</p>
    <p>{`Follow the instructions on the `}<a parentName="p" {...{
        "href": "https://www.ruby-lang.org/en/documentation/installation/"
      }}>{`Ruby Installation page`}</a>{`, which will provide more guidance on installing Ruby for your operating system.`}</p>
    <p>{`Once that's complete, run the following command to install bundler:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`gem install bundler
`}</code></pre>
    <h2>{`Install the MessageBird SDK`}</h2>
    <p>{`You use bundler to install the MessageBird SDK for Ruby. The SDK is open source and you can `}<a parentName="p" {...{
        "href": "https://github.com/messagebird/ruby-rest-api"
      }}>{`browse the code in this GitHub repository`}</a>{`; it’s also `}<a parentName="p" {...{
        "href": "https://rubygems.org/gems/messagebird-rest"
      }}>{`listed on RubyGems`}</a>{` so that bundler knows where to find it. SDKs and other libraries are always installed for each project as a dependency.`}</p>
    <p>{`Each Ruby project that uses bundler needs a `}<inlineCode parentName="p">{`Gemfile`}</inlineCode>{` file that includes information both about the current package (your project, such as name and version), as well as the list of dependencies that it relies on (such as the MessageBird SDK).`}</p>
    <p>{`Create a new project directory or open an existing project directory in your terminal. For a new project that doesn't have a `}<inlineCode parentName="p">{`Gemfile`}</inlineCode>{` file yet, just run the following command and follow the instructions on screen:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`bundle init
`}</code></pre>
    <p>{`Once you have a `}<inlineCode parentName="p">{`Gemfile`}</inlineCode>{` file, you have two options for adding the MessageBird SDK to it:`}</p>
    <h4>{`Editing the Gemfile`}</h4>
    <p>{`We'll add the SDK to the `}<inlineCode parentName="p">{`Gemfile`}</inlineCode>{`. A minimal file that only defines the MessageBird SDK as a dependency should look like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`source 'http://rubygems.org'

gem 'messagebird-rest', '~> 1.4', require: 'messagebird'
`}</code></pre>
    <p>{`Keep in mind that you have to specify the version of the SDK in the file. You can `}<a parentName="p" {...{
        "href": "https://rubygems.org/gems/messagebird-rest"
      }}>{`see the available SDK versions on RubyGems`}</a>{` and learn more about different ways to specify versions, for example with ranges, `}<a parentName="p" {...{
        "href": "https://guides.rubygems.org/patterns/#semantic-versioning"
      }}>{`in the semantic-versioning documentation of RubyGems`}</a>{`.`}</p>
    <h4>{`Using the Install Command`}</h4>
    <p>{`After saving the file, open a terminal in the directory into which you've stored it and type this command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`bundle install
`}</code></pre>
    <p>{`This command installs everything specified in your `}<inlineCode parentName="p">{`Gemfile`}</inlineCode>{`.`}</p>
    <p><strong parentName="p">{`Nice work!`}</strong>{` 🎉`}</p>
    <p>{`Your Ruby development environment and project directory are ready now!`}</p>
    <h2>{`Start building!`}</h2>
    <p>{`Let's head over to the next MessageBird Developer Tutorials and `}<a parentName="p" {...{
        "href": "/tutorials/send-sms-node"
      }}>{`learn how to send your first SMS using Ruby`}</a>{`.`}</p>
    <p>{`Want to start building your solution but not quite sure how to get started? Feel free to let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we'd love to help!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      