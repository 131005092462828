import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Setting SMS instant lead alerts with MessageBird`}</h1>
    <h3>{`⏱ 15 min build time      ||      `}<a parentName="h3" {...{
        "href": "https://github.com/messagebirdguides/lead-alerts-guide-go"
      }}>{`Download the Code`}</a></h3>
    <h2>{`Why build instant lead alerts for sales?`}</h2>
    <p>{`In this MessageBird Developer Tutorial, you’ll learn how to build SMS lead alerts with the `}<a parentName="p" {...{
        "href": "/api/sms-messaging"
      }}>{`MessageBird SMS Messaging API`}</a>{` and convert more leads by instantly notifying your sales team. In this tutorial the sample application will be for our fictitious car dealership, `}<em parentName="p">{`M.B. Cars`}</em>{`.`}</p>
    <p>{`Even though a lot of business transactions happen on the web, from both a business and user perspective, it's still often preferred to switch the channel and talk on the phone. Especially when it comes to high-value transactions in industries such as real estate or mobility, personal contact is essential.`}</p>
    <p>{`One way to streamline this workflow is by building callback forms on your website. Through these forms, customers can enter their contact details and receive a call to their phone, thus skipping queues where prospective leads need to stay on hold.`}</p>
    <p>{`Callback requests reflect a high level of purchase intent and should be dealt with as soon as possible to increase the chance of converting a lead; therefore, it's essential to get them pushed to a sales agent quickly. SMS messaging has proven to be one of the most instant and effective channels for this use case.`}</p>
    <h2>{`Getting started`}</h2>
    <p>{`First things first, our sample application is build in Go, so you need to install `}<a parentName="p" {...{
        "href": "https://golang.org"
      }}>{`Go`}</a>{` and the `}<a parentName="p" {...{
        "href": "https://github.com/messagebird/go-rest-api"
      }}>{`MessageBird REST API package for Go`}</a></p>
    <p>{`The source code is available in the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/lead-alerts-guide-go"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{` from which it can be cloned or downloaded into your development environment.`}</p>
    <p>{`After you've installed Go, run the following command to install the MessageBird REST API package for Go:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`go get -u github.com/messagebird/go-rest-api
`}</code></pre>
    <h3>{`Dependencies`}</h3>
    <p>{`To keep things straightforward and get you up and running as fast and easy as possible, we'll use only packages from the Go standard library and the MessageBird REST API package for Go.`}</p>
    <h3>{`Create your API Key 🔑`}</h3>
    <p>{`To enable the MessageBird SDK, we need to provide an access key for the API. MessageBird provides keys in `}<em parentName="p">{`live`}</em>{` and `}<em parentName="p">{`test`}</em>{` modes. To get this application running, we’ll need to create and use a live API access key. You can read more about `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/articles/360000670709-What-is-the-difference-between-a-live-key-and-a-test-key-"
      }}>{`the difference between test and live API keys`}</a>{` in our Help Center.`}</p>
    <p>{`Let's create your live API access key. First, go to the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/user/index"
      }}>{`MessageBird Dashboard`}</a>{`; if you have already created an API key it will be shown right there. If you don’t see any key on the Dashboard or if you're unsure whether this key is in `}<em parentName="p">{`live`}</em>{` mode, go to the `}<em parentName="p">{`Developers`}</em>{` section in the MessageBird Dashboard and open the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`API access (REST) tab`}</a>{`. There you can create new API keys and manage your existing ones.`}</p>
    <p>{`If you are having any issues creating your API key, please reach out to `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we’ll make sure to help you out.`}</p>
    <p><strong parentName="p">{`Pro-tip:`}</strong>{` To keep our demonstration code simple, we’ll be saving our API key in `}<inlineCode parentName="p">{`main.go`}</inlineCode>{`; however, hardcoding your credentials in the code is a risky practice that should never be used in production applications. A better method, also recommended by the `}<a parentName="p" {...{
        "href": "https://12factor.net/"
      }}>{`Twelve-Factor App Definition`}</a>{`, is to use environment variables. You can use open source packages such as `}<a parentName="p" {...{
        "href": "https://github.com/joho/godotenv"
      }}>{`GoDotEnv`}</a>{` to read your API key from a `}<inlineCode parentName="p">{`.env`}</inlineCode>{` file into your Go application. Your `}<inlineCode parentName="p">{`.env`}</inlineCode>{` file should be written as follows:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`MESSAGEBIRD_API_KEY=YOUR-API-KEY
`}</code></pre>
    <p>{`To use `}<a parentName="p" {...{
        "href": "https://github.com/joho/godotenv"
      }}>{`GoDotEnv`}</a>{` in your application, you can install it by running:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`go get -u github.com/joho/godotenv
`}</code></pre>
    <p>{`Then, you'll need to import it in your application:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`import (
 // Other imported packages
 "os"

 "github.com/joho/godotenv"
)

func main(){
 // GoDotEnv loads any ".env" file located in the same directory as main.go
 err := godotenv.Load()
 if err != nil {
   log.Fatal("Error loading .env file")
 }

 // Store the value for the key "MESSAGEBIRD_API_KEY" in the loaded '.env' file.
 apikey := os.Getenv("MESSAGEBIRD_API_KEY")

 // The rest of your application ...
}
`}</code></pre>
    <h3>{`Initialize the MessageBird client`}</h3>
    <p>{`Let's now install the `}<a parentName="p" {...{
        "href": "https://github.com/messagebird/go-rest-api"
      }}>{`MessageBird's REST API package for Go`}</a>{` by running:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`go get -u github.com/messagebird/go-rest-api
`}</code></pre>
    <p>{`In your project folder which we created earlier, let's create a `}<inlineCode parentName="p">{`main.go`}</inlineCode>{` file, and write the following code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`package main

import (
 "os"

 "github.com/messagebird/go-rest-api"
)

var client *messagebird.Client

func main(){
 client := messagebird.New("<enter-your-api-key>")
}
`}</code></pre>
    <h3>{`Routes`}</h3>
    <p>{`To make the structure of our web application clear, we'll first stub the routes that we need. We need two routes:`}</p>
    <ul>
      <li parentName="ul">{`A route to ask for the user's name and phone number, so that our agent can contact them.`}</li>
      <li parentName="ul">{`A route to display a success state.`}</li>
    </ul>
    <p>{`To write these routes, we need to modify `}<inlineCode parentName="p">{`main.go`}</inlineCode>{` to look like the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`package main

import (
 "fmt"
 "net/http"

 "github.com/messagebird/go-rest-api"
)

func landing(w http.ResponseWriter, r *http.Request){
 // Do nothing
 fmt.Fprintln("We ask for the user's phone number here.")
}

func callme(w http.ResponseWriter, r *http.Request){
 // Do nothing
 fmt.Fprintln("We display a success state here.")
}

func main() {
   client = messagebird.New("<enter-your-api-key>")

   // Routes
   http.HandleFunc("/", landing)
   http.HandleFunc("/callme", callme)

   // Serve
   port := ":8080"
   log.Println("Serving application on", port)
   err := http.ListenAndServe(port, nil)
   if err != nil {
       log.Println(err)
   }
}
`}</code></pre>
    <p>{`We've defined a HTTP handler for each route, and assigned each handler to a corresponding URL path. At the bottom of our `}<inlineCode parentName="p">{`main()`}</inlineCode>{` function, we start a HTTP server on port 8080.`}</p>
    <p><strong parentName="p">{`Pro-tip:`}</strong>{` Here we’re using `}<inlineCode parentName="p">{`http.ListenAndServe`}</inlineCode>{` to start our HTTP server, but you may want to use `}<inlineCode parentName="p">{`http.ListenAndServeTLS`}</inlineCode>{` to secure your application's communication over the web with TLS/SSL.`}</p>
    <h3>{`Views`}</h3>
    <p>{`Now that we've got our routes set up, we can start writing templates to render for each route. Let's create the template files for rendering views in your project folder:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`views/layouts/default.gohtml`}</inlineCode>{`: The base HTML template we will use with all our views.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`views/start.gohtml`}</inlineCode>{`: Our landing page that takes user input.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`views/callme.gohtml`}</inlineCode>{`: Displays a success state.`}</li>
    </ul>
    <p>{`The `}<inlineCode parentName="p">{`.gohtml`}</inlineCode>{` files will contain our Go HTML template code for each of our views, and our "default" base template.`}</p>
    <p>{`First, we'll write the base template. In `}<inlineCode parentName="p">{`default.gohtml`}</inlineCode>{`, write the following code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`{{ define "default" }}
<!DOCTYPE html>
 <head>
   <meta charset="utf-8">
   <meta http-equiv="X-UA-Compatible" content="IE=edge">
   <title>MessageBird Verify Example</title>
   <meta name="description" content="">
   <meta name="viewport" content="width=device-width, initial-scale=1">
   <link rel="stylesheet" href="" type="text/css"/>
 </head>
 <body>
   <main>
   <h1>M.B. Cars 🚙</h1>
   <h2>Largest Car Dealership in Town!</h2>
   <h3>We buy &amp; sell all international brands.</h3>
   {{ template "yield" . }}
   <p><small>A sample application brought to you by <a href="/">MessageBird</a> :)</small></p>
   </main>
 </body>
</html>
{{ end }}
`}</code></pre>
    <p>{`Notice that in `}<inlineCode parentName="p">{`default.gohtml`}</inlineCode>{`, we are calling `}<inlineCode parentName="p">{`{{ template "yield" . }}`}</inlineCode>{`. This tells the Go templates package that the contents of any template named "yield" should be rendered in its place when the template is executed.`}</p>
    <h2>{`Writing a RenderDefaultTemplate() function`}</h2>
    <p>{`Let's write a helper function to help us render our views. At this point, we know that the handlers for our views must:`}</p>
    <ul>
      <li parentName="ul">{`Parse the `}<inlineCode parentName="li">{`default.gohtml`}</inlineCode>{` template file and one other template file.`}</li>
      <li parentName="ul">{`Execute the template.`}</li>
      <li parentName="ul">{`Handle errors from the `}<inlineCode parentName="li">{`ParseFiles()`}</inlineCode>{` and `}<inlineCode parentName="li">{`ExecuteTemplate()`}</inlineCode>{` methods.`}</li>
      <li parentName="ul">{`Display an error message if we have one.`}</li>
    </ul>
    <p>{`We can write a `}<inlineCode parentName="p">{`RenderDefaultTemplate()`}</inlineCode>{` helper function to make these calls, keeping our code DRY:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`func RenderDefaultTemplate(w http.ResponseWriter, thisView string, data interface{}) {
 renderthis := []string{thisView, "views/layouts/default.gohtml"}
 t, err := template.ParseFiles(renderthis...)
 if err != nil {
   log.Fatal(err)
 }
 err = t.ExecuteTemplate(w, "default", data)
 if err != nil {
   log.Fatal(err)
 }
}
`}</code></pre>
    <p>{`Then, we can go ahead and rewrite all our handlers in `}<inlineCode parentName="p">{`main.go`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`func landing(w http.ResponseWriter, r *http.Request){
 RenderDefaultTemplate(w,"views/landing.gohtml", nil)
}

func callme(w http.ResponseWriter, r *http.Request){
 RenderDefaultTemplate(w,"views/callme.gohtml", nil)
}
`}</code></pre>
    <p>{`Finally, remember to add the `}<inlineCode parentName="p">{`template/html`}</inlineCode>{` package to your `}<inlineCode parentName="p">{`import`}</inlineCode>{` statement:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`import (
 "fmt"
 "net/http"
 "html/template"

 "github.com/messagebird/go-rest-api"
)
`}</code></pre>
    <h2>{`Showing a landing page`}</h2>
    <p>{`The landing page is a simple HTML page with information about our company, a call to action and a form with two input fields, name and number, and a submit button. All this page does is to take the name and phone number entered by the user and submits a "POST" request to the `}<inlineCode parentName="p">{`/callme`}</inlineCode>{` route.`}</p>
    <p>{`In `}<inlineCode parentName="p">{`landing.gohtml`}</inlineCode>{`, let's write the following code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`{{ define "yield" }}
<h4>Leave your number here all talk to a sales agent within 5 minutes, guaranteed!</h4>
<div>
 {{ if .Message }}
 <p><strong>{{ .Message }}</strong></p>
 {{ end }}
 <form action="/callme" method="post">
     <div>
         <label>Your name:</label>
         <br />
         <input type="text" name="name"
         value="{{ if .Name }}{{ .Name }}{{ end }}" placeholder="Birdie" required/>
     </div>
     <div>
         <label>Your phone number:</label>
         <br />
         <input type="tel" name="phone"
         value="{{ if .Phone }}{{ .Phone }}{{ end }}" placeholder="+319876543210" required/>
     </div>
     <div>
         <button type="submit">Call me</button>
     </div>
 </form>
</div>
{{ end }}
`}</code></pre>
    <h2>{`Handling callback requests`}</h2>
    <p>{`When the user submits the form, the `}<inlineCode parentName="p">{`/callme`}</inlineCode>{` route receives their name and number; we then randomly assign an agent to call the user to follow up. We'll assume that we have a list of agent's phone numbers in a `}<inlineCode parentName="p">{`contacts.csv`}</inlineCode>{` file, and that we'll pick a phone number from this list at random to send an SMS message to ask the agent who owns that number to call the user.`}</p>
    <p>{`Our `}<inlineCode parentName="p">{`contacts.csv`}</inlineCode>{` file should look like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-csv"
      }}>{`+319876543210,
+319876543211,
+319876543212,
+319876543213,
+319876543214,
+319876543215,
+319876543216,
+319876543217,
+319876543218,
+319876543219,
+319876543220,
+319876543221,
+319876543222,
+319876543223,
+319876543224,
+319876543225,
+319876543226,
+319876543227,
+319876543228,
+319876543229,
`}</code></pre>
    <p>{`Keep in mind that to send a message to a phone number, you must have added that phone number to your MessageBird contact list. Check out our `}<a parentName="p" {...{
        "href": "/api/contacts#create-a-contact"
      }}>{`MessageBird API documentation`}</a>{` to learn how to add a new phone number to your contact list.`}</p>
    <p>{`To load the `}<inlineCode parentName="p">{`contacts.csv`}</inlineCode>{` file, add the following code under `}<inlineCode parentName="p">{`RenderDefaultTemplate()`}</inlineCode>{` in `}<inlineCode parentName="p">{`main.go`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`// getPhoneList reads a single column CSV file as a list of contacts, and
// returns its contents as a map of "int: string"
func getPhoneList(file string) map[int]string {
   f, err := os.Open(file)
   if err != nil {
       log.Fatal(err)
   }
   defer f.Close()
   rows, err := csv.NewReader(f).ReadAll()
   if err != nil {
       log.Fatal(err)
   }
   phonelist := make(map[int]string)
   for i := range rows {
       phonelist[i] = rows[i][0]
   }
   return phonelist
}

// getRandomContact picks a random key from a map[int]string and returns its value.
func getRandomContact(phonelist map[int]string) string {
   rand.Seed(time.Now().Unix())
   return phonelist[rand.Intn(len(phonelist))]
}
`}</code></pre>
    <p>{`And then add this line of code to the top of your `}<inlineCode parentName="p">{`callme()`}</inlineCode>{` handler function:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`agentToCall := getRandomContact(getPhoneList("contacts.csv"))
`}</code></pre>
    <p>{`Above, we've written two helper functions that work together to:`}</p>
    <ul>
      <li parentName="ul">
        <ol parentName="li">
          <li parentName="ol">{`Load data from a given CSV file and returns it as a map. We'll use this to load our `}<inlineCode parentName="li">{`contacts.csv`}</inlineCode>{` file.`}</li>
        </ol>
      </li>
      <li parentName="ul">
        <ol parentName="li" {...{
          "start": 2
        }}>
          <li parentName="ol">{`Selects a random item from a map using its `}<inlineCode parentName="li">{`key`}</inlineCode>{`, and return its value. We'll use this to select a random agent's contact number.`}</li>
        </ol>
      </li>
    </ul>
    <p>{`Now we're ready to write the rest of our `}<inlineCode parentName="p">{`callme()`}</inlineCode>{` handler function. Modify `}<inlineCode parentName="p">{`callme()`}</inlineCode>{` so that it looks like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`func callme(w http.ResponseWriter, r *http.Request) {
   r.ParseForm()
   msgBody := "You have a new lead: " + r.FormValue("name") + ". Call them at " + r.FormValue("phone")
   agentToCall := getRandomContact(getPhoneList("contacts.csv"))
   msg, err := sms.Create(
       client,
       "MBCars",
       []string{agentToCall},
       msgBody,
       nil,
   )
   if err != nil {
       log.Println(err)
       RenderDefaultTemplate(
           w,
           "views/landing.gohtml",
           &Data{
               Name:    r.FormValue("name"),
               Phone:   r.FormValue("phone"),
               Message: "We couldn't complete your request! Please try again.",
           },
       )
       return
   }
   // Logging msg for development. Can safely discard in production builds.
   log.Println(msg)
   RenderDefaultTemplate(w, "views/sent.gohtml", nil)
}
`}</code></pre>
    <p>{`We'll also need to define a `}<inlineCode parentName="p">{`Data`}</inlineCode>{` struct type, which we use to pass an error message and other data to our `}<inlineCode parentName="p">{`landing.gohtml`}</inlineCode>{` template:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`// Data is for passing data to our templates
type Data struct {
   Name    string
   Phone   string
   Message string
}
`}</code></pre>
    <p>{`In our `}<inlineCode parentName="p">{`callme()`}</inlineCode>{` handler function, we're:`}</p>
    <ul>
      <li parentName="ul">
        <ol parentName="li">
          <li parentName="ol">{`Parsing the form data submitted to the current route.`}</li>
        </ol>
      </li>
      <li parentName="ul">
        <ol parentName="li" {...{
          "start": 2
        }}>
          <li parentName="ol">{`Writing the message to send to the assigned agent, and assigning it to `}<inlineCode parentName="li">{`msgBody`}</inlineCode>{`.`}</li>
        </ol>
      </li>
      <li parentName="ul">
        <ol parentName="li" {...{
          "start": 3
        }}>
          <li parentName="ol">{`Picking an agent to call at random, and assigning it to `}<inlineCode parentName="li">{`agentToCall`}</inlineCode>{`.`}</li>
        </ol>
      </li>
      <li parentName="ul">
        <ol parentName="li" {...{
          "start": 4
        }}>
          <li parentName="ol">{`Sending an SMS message to the selected agent by calling `}<inlineCode parentName="li">{`sms.Create()`}</inlineCode>{`.`}</li>
        </ol>
      </li>
      <li parentName="ul">
        <ol parentName="li" {...{
          "start": 5
        }}>
          <li parentName="ol">{`If `}<inlineCode parentName="li">{`sms.Create()`}</inlineCode>{` returns an error, send the user back to the landing page and display and error.`}</li>
        </ol>
      </li>
      <li parentName="ul">
        <ol parentName="li" {...{
          "start": 6
        }}>
          <li parentName="ol">{`If we successfully send the message, display a success state.`}</li>
        </ol>
      </li>
    </ul>
    <p>{`To send an SMS message to an agent, we make an `}<inlineCode parentName="p">{`sms.Create()`}</inlineCode>{` call that takes four parameters:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`originator`}</inlineCode>{`: The sender ID. This is the name that appears as the sender of the SMS message.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`recipients`}</inlineCode>{`: The API supports an array of recipients; we're sending to only one, but this parameter is still specified as a `}<inlineCode parentName="li">{`[]string`}</inlineCode>{`. We're passing in our selected agent's contact number here by writing it into a `}<inlineCode parentName="li">{`[]string`}</inlineCode>{` literal: `}<inlineCode parentName="li">{`[]string{agentToCall}`}</inlineCode>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`body`}</inlineCode>{`: The text of the message that includes the input from the form.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`params`}</inlineCode>{`: Other message parameters that you can specify. Here, we're leaving this as `}<inlineCode parentName="li">{`nil`}</inlineCode>{`. Feel free to check out our `}<a parentName="li" {...{
          "href": "/docs"
        }}>{`MessageBird API documentation`}</a>{` for more information.`}</li>
    </ul>
    <h2>{`Testing`}</h2>
    <p>{`You're done! Make sure that you've replaced the API key in the sample code with your own, and you're ready to assign leads to agents.`}</p>
    <p>{`To test your application, run the following command from your console:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`go run main.go
`}</code></pre>
    <p>{`Go to http://localhost:8080/ to see the form and request a lead!`}</p>
    <p>{`Awesome! You can now use the flow, code snippets and UI examples from this tutorial as an inspiration to build your own SMS-based lead alerts application. Don't forget to download the code from the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/lead-alerts-guide-go"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`.`}</p>
    <p><strong parentName="p">{`Nice work!`}</strong>{` 🎉`}</p>
    <p>{`You've just built your own instant lead alerts application with MessageBird using Go!`}</p>
    <h2>{`Start building!`}</h2>
    <p>{`Want to build something similar but not quite sure how to get started? Please feel free to let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`;  we'd love to help!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      