import React from 'react';

const DuplicatesFinder = (headerArray) => {
  const tempMemory = new Set();
  const result = {};

  headerArray.forEach((header) => {
    if (tempMemory.has(header)) {
      result[header] = 0;
    }
    tempMemory.add(header);
  });
  return result;
};

const getHeaders = (children) =>
  children &&
  children
    .filter((el) => el.props && el.props.mdxType.match(/h[1-3]/))
    .map((el) => el.props.children);

const getHeadersFromAPIWrapper = (children) =>
  children
    .filter((el) => el.props && el.props.mdxType.match('APIWrapper'))
    .flatMap((el) => el.props && el.props.children) //flatten children of left & right columns
    .flatMap((el) => el.props && el.props.children) //flatten props of all children
    .filter((el) => el.props && el.props.mdxType.match(/h[1-3]/))
    .map((el) => el.props && el.props.children);

export const getAllIds = (children) => {
  return getHeaders(children).concat(getHeadersFromAPIWrapper(children));
};
export const getDuplicatesId = (allHeaders) => {
  return DuplicatesFinder(allHeaders);
};
