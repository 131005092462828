import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Building automated voice surveys with MessageBird`}</h1>
    <h3>{`⏱ 15 min build time || `}<a parentName="h3" {...{
        "href": "https://github.com/messagebirdguides/automated-surveys"
      }}>{`Download the Code`}</a></h3>
    <h2>{`Why build automated voice surveys?`}</h2>
    <p>{`In this MessageBird Developer Tutorial, you’ll learn how to gather valuable information from your users with this fully automated voice survey application powered by the `}<a parentName="p" {...{
        "href": "/api/voice-calling/"
      }}>{`MessageBird Voice Calling API`}</a>{`.`}</p>
    <p>{`Surveys are a great way to gather feedback about a product or service. Here we'll look at a company that wants to collect surveys over the phone by providing their customers a feedback number that they can call, submit their opinion as voice messages that the company's support team can listen to on a website, and incorporate that feedback into the next version of the product. This team should be able to focus their attention on the input instead of having to wait and answer calls; therefore, the feedback collection itself is fully automated.`}</p>
    <h2>{`Getting started`}</h2>
    <p>{`Our sample application is built in Node.js using the `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/express"
      }}>{`Express`}</a>{` framework, so you’ll need Node and npm, you can easily `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/get-npm"
      }}>{`install them for free`}</a>{`.`}</p>
    <p>{`The source code is available in the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/automated-surveys"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`, which you can either clone with git or from where you can download a ZIP file with the source code to your computer.`}</p>
    <p>{`After saving the code, open a console for the download directory and run the following command which downloads the Express framework, MessageBird SDK, and other dependencies defined in the `}<inlineCode parentName="p">{`package.json`}</inlineCode>{` file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install
`}</code></pre>
    <p>{`The sample application uses `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/mongo-mock"
      }}>{`mongo-mock`}</a>{` to provide an in-memory database for testing, so you don't need to configure an external database. As the mock loses data when you restart the application, you need to replace it with a real MongoDB server when you want to develop this sample into a production application.`}</p>
    <h2>{`Designing the call flow`}</h2>
    <p>{`Call flows in MessageBird are sequences of steps. Each step can be a different action, such as playing an audio file, speaking words through text-to-speech (TTS), recording the caller's voice or transferring the call to another party. The call flow for this survey application alternates two types of actions: saying the question (`}<inlineCode parentName="p">{`say`}</inlineCode>{` action) and recording an answer (`}<inlineCode parentName="p">{`record`}</inlineCode>{` action). Other action types are not required. The whole flow begins with a short introduction text and ends on a "Thank you" note, both of which are implemented as `}<inlineCode parentName="p">{`say`}</inlineCode>{` actions.`}</p>
    <p>{`The survey application generates the call flow dynamically through Javascript code and provides it on a webhook endpoint as a JSON response that MessageBird can parse; however, it doesn’t return the complete flow at once. The generated steps always end on a `}<inlineCode parentName="p">{`record`}</inlineCode>{` action with the `}<inlineCode parentName="p">{`onFinish`}</inlineCode>{` attribute set to the same webhook endpoint URL. This approach simplifies the collection of recordings because whenever the caller provides an answer, an identifier for the recording is sent with the next webhook request. The endpoint will then store information about the answer to the question and return additional steps: either the next question together with its answer recording step or, if the caller has reached the end of the survey, the final “Thank you” note.
The sample implementation contains only one survey. For each participant, we create a (mocked) MongoDB document that includes a unique MessageBird—generated identifier for the call, their number, and an array of responses. As the webhook is requested multiple times for each caller, once in the beginning and once for each answer they record, the length of the responses array indicates their position within the survey and determines the next step.`}</p>
    <p>{`All questions are stored as an array in the file `}<inlineCode parentName="p">{`questions.json`}</inlineCode>{` to keep them separate from the implementation. The following statement at the top of `}<inlineCode parentName="p">{`index.js`}</inlineCode>{` loads them:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`var questions = require('./questions.json');
`}</code></pre>
    <h2>{`Prerequisites for receiving calls`}</h2>
    <h3>{`Overview`}</h3>
    <p>{`Participants take part in a survey by calling a dedicated virtual phone number. MessageBird accepts the call and contacts the application on a `}<em parentName="p">{`webhook URL`}</em>{`, which you assign to your number on the MessageBird Dashboard using `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/flow-builder"
      }}>{`Flow Builder`}</a>{`. A webhook is a URL on your site that doesn't render a page to users but is like an API endpoint that can be triggered by other servers. Every time someone calls that number, MessageBird checks that URL for instructions on how to interact with the caller.`}</p>
    <h3>{`Exposing your development server with localtunnel`}</h3>
    <p>{`When working with webhooks, an external service like MessageBird needs to access your application, so the webhook URL must be public; however, during development you're typically working in a local development environment that is not publicly available. Thankfully this is not a big deal since various tools and services allow you to quickly expose your development environment to the Internet by providing a tunnel from a public URL to your local machine. One of these tools is `}<a parentName="p" {...{
        "href": "https://localtunnel.me/"
      }}>{`localtunnel.me`}</a>{`, which is uniquely suited to Node.js developers since you can easily install it using npm:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install -g localtunnel
`}</code></pre>
    <p>{`You can start a tunnel by providing a local port number on which your application runs. Our application is configured to run on port 8080, so you can start localtunnel with the following command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`lt --port 8080
`}</code></pre>
    <p>{`After you've launched the tunnel, localtunnel displays your temporary public URL. We'll need that in a minute.`}</p>
    <p>{`Another common tool for tunneling your local machine is `}<a parentName="p" {...{
        "href": "https://ngrok.com/"
      }}>{`ngrok`}</a>{`, which works virtually in the same way; you can have a look at it if you're facing problems with localtunnel.`}</p>
    <h3>{`Getting an inbound number`}</h3>
    <p>{`A requirement for receiving messages is a dedicated inbound number. Virtual mobile numbers (VNM) look and work similar to regular mobile numbers; however, instead of being attached to a mobile device via a SIM card, they live in the cloud and can process inbound SMS and voice calls. MessageBird offers numbers from different countries for a low monthly fee; `}<a parentName="p" {...{
        "href": "https://www.messagebird.com/en/numbers"
      }}>{`feel free to explore our low-cost programmable and configurable numbers`}</a>{`.`}</p>
    <p>{`Purchasing a number is quite easy:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Go to the ‘`}<a parentName="p" {...{
            "href": "https://dashboard.messagebird.com/en/numbers"
          }}>{`Numbers`}</a>{`’ section in the left-hand side of your Dashboard and click the blue button ‘`}<a parentName="p" {...{
            "href": "https://dashboard.messagebird.com/en/vmn/buy-number"
          }}>{`Buy a number`}</a>{`’ in the top-right side of your screen.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Pick the country in which you and your customers are located, and make sure the Voice capability is selected.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Choose one number from the selection and the duration for which you want to pay now.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Confirm by clicking ‘Buy Number’ in the bottom-right of your screen.`}</p>
      </li>
    </ol>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/automatedsurveys-node/buy-a-number.png",
        "alt": "Buy a VMN"
      }}></img></p>
    <p>{`Awesome, you’ve set up your first virtual mobile number! 🎉
`}<strong parentName="p">{`Pro-tip:`}</strong>{` Check out our Help Center for more information about `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/sections/201958489-Virtual-Numbers"
      }}>{`virtual mobile numbers`}</a>{` and `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/sections/360000108538-Country-info-Restrictions"
      }}>{`country restrictions`}</a>{`.`}</p>
    <h3>{`Connecting the number to your application`}</h3>
    <p>{`So you have a number now, but MessageBird has no idea what to do with it. That's why now you need to define a `}<em parentName="p">{`Flow`}</em>{` that links your number to your webhook.`}</p>
    <h4>{`STEP ONE`}</h4>
    <p>{`Go back to `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/flow-builder"
      }}>{`Flow Builder`}</a>{` and hit the button ‘Create new flow’ and then ‘Create Custom Flow’.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/automatedsurveys-node/image2.png",
        "alt": "Buy a VMN"
      }}></img></p>
    <h4>{`STEP TWO`}</h4>
    <p>{`Give your flow a name, choose ‘Phone Call’ as the trigger and hit ‘Next’.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/automatedsurveys-node/image3.png",
        "alt": "Buy a VMN"
      }}></img></p>
    <h4>{`STEP THREE`}</h4>
    <p>{`Click on the first step ‘Phone Call’ and select the number or numbers you’d like to attach the flow to.`}</p>
    <h4>{`STEP FOUR`}</h4>
    <p>{`Add a new step by pressing the small ‘+’, choose ‘Fetch call flow from URL’ and paste the localtunnel base URL into the form and append `}<inlineCode parentName="p">{`/callStep`}</inlineCode>{` to it-this is the name of our webhook handler route. Click on ‘Save’ when ready.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/automatedsurveys-node/image4.png",
        "alt": "Buy a VMN"
      }}></img></p>
    <h4>{`STEP FIVE`}</h4>
    <p><strong parentName="p">{`Ready!`}</strong>{` Hit ‘Publish’ on the right top of the screen to activate your flow. Your flow should look something like this:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/automatedsurveys-node/image5.png",
        "alt": "Buy a VMN"
      }}></img></p>
    <h2>{`Implementing the call steps`}</h2>
    <p>{`The route `}<inlineCode parentName="p">{`app.all('/callStep')`}</inlineCode>{` in `}<inlineCode parentName="p">{`index.js`}</inlineCode>{` contains the implementation of the survey call flow. It is specified with `}<inlineCode parentName="p">{`all()`}</inlineCode>{` because the first request to fetch the call flow uses GET and subsequent requests that include recording information use POST. It starts with the basic structure for a JSON call flow object called `}<inlineCode parentName="p">{`flow`}</inlineCode>{`, which we'll extend depending on where we are within our survey:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`app.all('/callStep', function(req, res) {
   // Prepare a Call Flow that can be extended
   var flow = {
       steps : []
   };
`}</code></pre>
    <p>{`Next, let’s connect to MongoDB, select a collection and try to find an existing call:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`MongoClient.connect(dbUrl, {}, function(err, db) {
   var surveyParticipants = db.collection('surveyParticipants');

   // Find a database entry for the number
   surveyParticipants.findOne({ callId : req.query.callID },
       function(err, doc) {
`}</code></pre>
    <p>{`The application continues inside the callback function. First, we determine the ID (array index) of the next question, which is 0 for new participants or the number of existing answers plus one for existing ones:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// Determine the next question
var questionId =
  doc == null
    ? 0 // The person is just starting the survey
    : doc.responses.length + 1;
`}</code></pre>
    <p>{`For new participants, we also need to create a document in the MongoDB collection and persist it to the database. This record contains the identifier of the call and the caller ID, which are taken from the query parameters sent by MessageBird as part of the webhook (call flow fetch) request, `}<inlineCode parentName="p">{`callID`}</inlineCode>{`, and `}<inlineCode parentName="p">{`destination`}</inlineCode>{` respectively. It includes an empty responses array as well.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`if (doc == null) {
  // Create new participant database entry
  var doc = {
    callId: req.query.callID,
    number: req.query.destination,
    responses: [],
  };
  surveyParticipants.insertOne(doc, function(err, result) {
    console.log('created survey participant', err, result);
  });
}
`}</code></pre>
    <p>{`Next, if we're not in the initial but a subsequent webhook request, we would have received a JSON payload with data about the recording. If you scroll up in `}<inlineCode parentName="p">{`index.js`}</inlineCode>{` you'll find the initialization code for the Express framework. Among other things, it configures the `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/body-parser"
      }}>{`body-parser`}</a>{` helper library to accept JSON inputs. As MessageBird sends the inputs without `}<inlineCode parentName="p">{`Content-Type`}</inlineCode>{`, we need to adjust the following configuration:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`app.use(
  bodyParser.json({
    type: function(req) {
      // Parse all bodies as JSON even without Content-Type
      return true;
    },
  }),
);
`}</code></pre>
    <p>{`Let's move back to our route's implementation. The answers are persisted by adding them to the responses array and then updating the document in the MongoDB collection. For every answer we store two identifiers from the parsed JSON request body: the `}<inlineCode parentName="p">{`legId`}</inlineCode>{` that identifies the caller in a multi-party voice call and is required to fetch the recording, as well as the `}<inlineCode parentName="p">{`id`}</inlineCode>{` of the recording itself which we store as `}<inlineCode parentName="p">{`recordingId`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`if (questionId > 0) {
  // Unless we're at the first question, store the response
  // of the previous question
  doc.responses.push({
    legId: req.body.legId,
    recordingId: req.body.id,
  });
  surveyParticipants.updateOne(
    { number: req.query.destination },
    {
      $set: {
        responses: doc.responses,
      },
    },
    function(err, result) {
      console.log('updated survey participant', err, result);
    },
  );
}
`}</code></pre>
    <p>{`It's time to ask a question. Let's first check if we reached the end of the survey. That is determined by whether the question index equals the length of the questions list and therefore is out of bounds of the array, which means there are no further questions; if so, we thank the caller for their participation:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`if (questionId == questions.length) {
   // All questions have been answered
   flow.steps.push(say("You have completed our survey. Thank you for participating!"));
`}</code></pre>
    <p>{`Did you notice the `}<inlineCode parentName="p">{`say()`}</inlineCode>{` function? It’s a small helper function we've declared separately in the initial section of `}<inlineCode parentName="p">{`index.js`}</inlineCode>{` to simplify the creation of `}<inlineCode parentName="p">{`say`}</inlineCode>{` steps as we need them multiple times in the application. The `}<inlineCode parentName="p">{`say()`}</inlineCode>{` function returns the action in the format expected by MessageBird so it can be added to the `}<inlineCode parentName="p">{`steps`}</inlineCode>{` of a flow using push(), as seen above. A function like this allows setting options for `}<inlineCode parentName="p">{`say`}</inlineCode>{` actions at a central location. You can modify it if you want to, for example, specify another language or voice.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`function say(payload) {
  return {
    action: 'say',
    options: {
      payload: payload,
      voice: 'male',
      language: 'en-US',
    },
  };
}
`}</code></pre>
    <p>{`Back in the route, there's an else-block that handles all questions other than the last; however, there's another nested `}<inlineCode parentName="p">{`if`}</inlineCode>{`-statement in it to treat the first question, since we need to read a welcome message to our participant `}<em parentName="p">{`before`}</em>{` the question:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`} else {
   if (questionId == 0) {
       // Before first question, say welcome
       flow.steps.push(say("Welcome to our survey! You will be asked " + questions.length + " questions. The answers will be recorded. Speak your response for each and press any key on your phone to move on to the next question. Here is the first question:"));
   }
`}</code></pre>
    <p>{`Finally, here comes the general logic used for each question:`}</p>
    <ul>
      <li parentName="ul">{`Ask the question using `}<inlineCode parentName="li">{`say`}</inlineCode></li>
      <li parentName="ul">{`Request a recording`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// Ask next question
flow.steps.push(say(questions[questionId]));

// Request recording of question
flow.steps.push({
  action: 'record',
  options: {
    // Finish either on key press or after 10 seconds of silence
    finishOnKey: 'any',
    timeout: 10,
    // Send recording to this same call flow URL
    onFinish: req.protocol + '://' + req.hostname + '/callStep',
  },
});
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`record`}</inlineCode>{` step is configured so that it finishes when the caller presses any key on their phone's keypad (`}<inlineCode parentName="p">{`finishOnKey`}</inlineCode>{` attribute) or when MessageBird detects 10 seconds of silence (`}<inlineCode parentName="p">{`timeout`}</inlineCode>{` attribute). By specifying the URL with the `}<inlineCode parentName="p">{`onFinish`}</inlineCode>{` attribute, we can make sure that the recording data is sent back to our route and that we can send additional steps to the caller. Building the URL with protocol and hostname information from the request ensures that it works wherever the application is deployed and also behind the tunnel.`}</p>
    <p>{`Only one tiny part remains: the last step in each webhook request is sending back a JSON response based on the `}<inlineCode parentName="p">{`flow`}</inlineCode>{` object:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`res.json(flow);
`}</code></pre>
    <h2>{`Building an admin view`}</h2>
    <p>{`The survey application also contains an admin view that allows us to view the survey participants and listen to their responses. The implementation of the `}<inlineCode parentName="p">{`app.get('/admin')`}</inlineCode>{` route is straightforward: it essentially loads everything from the database plus the questions data and adds it to the data available for a `}<a parentName="p" {...{
        "href": "http://handlebarsjs.com/"
      }}>{`Handlebars`}</a>{` template.`}</p>
    <p>{`The template, which you can see in `}<inlineCode parentName="p">{`views/participants.handlebars`}</inlineCode>{`, contains a basic HTML structure with a three-column table. Inside the table, two nested loops over the participants and their responses add a line for each answer with the number of the caller, the question, and a “Listen” button that plays it back.`}</p>
    <p>{`Let's have a more detailed look at the implementation of this “Listen” button. On the frontend, the button calls a Javascript function called `}<inlineCode parentName="p">{`playAudio()`}</inlineCode>{` with the `}<inlineCode parentName="p">{`callId`}</inlineCode>{`, `}<inlineCode parentName="p">{`legId`}</inlineCode>{` and `}<inlineCode parentName="p">{`recordingId`}</inlineCode>{` inserted through Handlebars expressions:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<button onclick="playAudio('{{p.callId}}','{{this.legId}}','{{this.recordingId}}')">Listen</button>
`}</code></pre>
    <p>{`The implementation of that function dynamically generates an invisible, auto-playing HTML5 audio element:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`function playAudio(callId, legId, recordingId) {
  document.getElementById('audioplayer').innerHTML =
    '<audio autoplay="1"><source src="/play/' +
    callId +
    '/' +
    legId +
    '/' +
    recordingId +
    '" type="audio/wav"></audio>';
}
`}</code></pre>
    <p>{`As you can see, the WAV audio is requested from a route of the survey application. This route acts as a proxy server that fetches the audio from the MessageBird API and uses the `}<inlineCode parentName="p">{`pipe()`}</inlineCode>{` function to forward it to the frontend. This architecture is necessary because we need a MessageBird API key to fetch the audio, but don't want to expose it on the client-side of our application. We use `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/request"
      }}>{`request`}</a>{` to make the API call and add the API key as an HTTP header:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`app.get('/play/:callId/:legId/:recordingId', function(req, res) {
  // Make a proxy request to the audio file on the API
  request({
    url:
      'https://voice.messagebird.com/calls/' +
      req.params.callId +
      '/legs/' +
      req.params.legId +
      '/recordings/' +
      req.params.recordingId +
      '.wav',
    headers: {
      Authorization: 'AccessKey ' + process.env.MESSAGEBIRD_API_KEY,
    },
  }).pipe(res);
});
`}</code></pre>
    <p>{`You need to provide a MessageBird API key via an environment variable loaded , let’s do it by using `}<a parentName="p" {...{
        "href": "https://mvnrepository.com/artifact/io.github.cdimascio/java-dotenv"
      }}>{`dotenv`}</a>{`. We've prepared an `}<inlineCode parentName="p">{`env.example`}</inlineCode>{` file in the repository, which you should rename to `}<inlineCode parentName="p">{`.env`}</inlineCode>{` and add the required information. Here's an example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`MESSAGEBIRD_API_KEY = YOUR - API - KEY;
`}</code></pre>
    <p>{`Go to the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/user/index"
      }}>{`MessageBird Dashboard`}</a>{`; if you have already created an API key it will be shown right there. If you don’t see any key on the Dashboard or if you're unsure whether this key is in `}<em parentName="p">{`live`}</em>{` mode, go to the `}<em parentName="p">{`Developers`}</em>{` section in the MessageBird Dashboard and open the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`API access (REST) tab`}</a>{`. There you can create new API keys and manage your existing ones.`}</p>
    <h2>{`Testing`}</h2>
    <p>{`You’re done! It’s time to test your application.`}</p>
    <p>{`Double-check that you’ve set up your number correctly with a flow that forwards incoming phone calls to a localtunnel URL and that the tunnel is still running. Keep in mind that whenever you start a fresh tunnel, you'll get a new URL, so you have to update it in the flows accordingly. You can also configure a more permanent URL using the `}<inlineCode parentName="p">{`-s`}</inlineCode>{` attribute with the `}<inlineCode parentName="p">{`lt`}</inlineCode>{` command.`}</p>
    <p>{`To start the sample application you have to enter another command, but your existing console window is already busy running your tunnel, so you need to open another one. With Mac you can press `}<em parentName="p">{`Command + Tab`}</em>{` to open a second tab that's already pointed to the correct directory. With other operating systems you may have to open another console window manually. Either way, once you've got a command prompt, type the following to start the application:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm start
`}</code></pre>
    <p>{`Now, take your phone and dial your survey number. You should hear the welcome message and the first question; speak an answer and press any key, at that moment you should see some database debug output in the console. Open `}<a parentName="p" {...{
        "href": "http://localhost:8080/admin"
      }}>{`http://localhost:8080/admin`}</a>{` to see your call as well.Continue interacting with the survey. In the end, you can refresh your browser and listen to all the answers you recorded within your phone call.`}</p>
    <h2>{`Supporting outbound calls`}</h2>
    <p>{`The application was designed for incoming calls where survey participants call a virtual number and can provide their answers. The same code works without any changes for an outbound call scenario as well, all you have to do is start a call through the API or other means and use a call flow that contains a `}<inlineCode parentName="p">{`fetchCallFlow`}</inlineCode>{` step pointing to your webhook route.`}</p>
    <p>{`Awesome! You can now leverage the flow, code snippets, and UI examples from this tutorial to build your own automated voice survey. Don't forget to download the code from the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/automated-surveys"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`.`}</p>
    <p><strong parentName="p">{`Nice work!`}</strong>{` 🎉`}</p>
    <p>{`You now have a running integration of MessageBird's Voice API using Node.js!`}</p>
    <h2>{`Start building!`}</h2>
    <p>{`Want to build something similar but not quite sure how to get started? Feel free to let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we'd love to help!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      