import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import Notification from 'components/Notification';
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Get your API keys`}</h1>
    <p>{`In this Programmable Voice Calling Quickstart, you'll learn how to sign up and log in to `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/user/index"
      }}>{`your MessageBird Dashboard`}</a>{`, and how to collect, manage, and use your free API keys.`}</p>
    <h2>{`Step 1: Sign up to MessageBird`}</h2>
    <p>{`First thing’s first, sign up for your free MessageBird account at `}<a parentName="p" {...{
        "href": "https://www.messagebird.com/en/"
      }}>{`www.messagebird.com`}</a>{`.`}</p>
    <h2>{`Step 2: Verify your phone number`}</h2>
    <p>{`You'll be asked to verify your mobile phone number, simply to check that you're human. In return, you'll receive 10 free test SMS credits for testing the MessageBird's SMS APIs and all the Dashboard tools.`}</p>
    <Notification type="INFO" mdxType="Notification">
  You can only use your free test SMS credits to send outbound SMS to your verified number. If you want to test Voice
  Calls, you first need to{' '}
  <a href="https://dashboard.messagebird.com/en/financial/buy-balance">top up your account balance</a>.
    </Notification>
    <h2>{`Step 3: Collect, manage, and use your free API keys`}</h2>
    <p>{`You can create, manage, and retrieve your API keys (both test and live) from the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/settings"
      }}>{`MessageBird Developers dashboard`}</a>{`.`}</p>
    <Notification type="INFO" mdxType="Notification">
  Learn about the{' '}
  <a href="https://support.messagebird.com/hc/en-us/articles/360000670709-What-is-the-difference-between-a-live-key-and-a-test-key-">
    difference between test and live API Keys
  </a>
  .
    </Notification>
    <p>{`Congrats! You're all ready to start testing the Voice Calling API.`}</p>
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'Make a voice call',
      to: '/quickstarts/voice-calling/make-an-outbound-call'
    }, {
      name: 'Receive voice calls',
      to: '/quickstarts/voice-calling/receive-calls'
    }, {
      name: 'Voice Calling API Reference',
      to: '/api/voice-calling'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      