import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Setting SMS marketing subscriptions with MessageBird`}</h1>
    <h3>{`⏱ 30 min build time || `}<a parentName="h3" {...{
        "href": "https://github.com/messagebirdguides/subscriptions-guide-go"
      }}>{`Download the Code`}</a></h3>
    <h2>{`Why build SMS marketing subscriptions?`}</h2>
    <p>{`In this MessageBird Developer Tutorial, you’ll learn how to implement an SMS marketing campaign subscription application powered by the `}<a parentName="p" {...{
        "href": "/api/sms-messaging"
      }}>{`MessageBird SMS Messaging API`}</a>{`, which enables your subscribers to seamlessly opt-in and out.`}</p>
    <p>{`SMS makes it incredibly easy for businesses to reach consumers everywhere at any time, directly on their mobile devices. For many people, these messages are a great way to discover things like discounts and special offers from a company, while others might find them annoying. For this reason, it’s important and also required by law in many countries to provide clear opt-in and opt-out mechanisms for SMS broadcast lists. To make it work independently of a website it's useful to assign a programmable `}<a parentName="p" {...{
        "href": "https://www.messagebird.com/en/numbers"
      }}>{`virtual mobile number`}</a>{` to your SMS campaign and handle incoming messages programmatically so users can control their subscription with basic command keywords.`}</p>
    <p>{`We'll walk you through the following steps:`}</p>
    <ul>
      <li parentName="ul">{`A person can send the keyword `}<em parentName="li">{`SUBSCRIBE`}</em>{` to a specific VMN that the company includes in their advertising material; the opt-in is immediately confirmed.`}</li>
      <li parentName="ul">{`If the person no longer wants to receive messages, they can send the keyword `}<em parentName="li">{`STOP`}</em>{` to the same number; the opt-out is also confirmed.`}</li>
      <li parentName="ul">{`An administrator can enter a message in a form on a website. Then they can immediately send this message to all confirmed subscribers.`}</li>
    </ul>
    <h2>{`Getting started`}</h2>
    <p>{`First things first, our sample application is build in Go, so you need to install `}<a parentName="p" {...{
        "href": "https://golang.org"
      }}>{`Go`}</a>{` and the `}<a parentName="p" {...{
        "href": "https://github.com/messagebird/go-rest-api"
      }}>{`MessageBird REST API package for Go`}</a>{`.
Let's install the MessageBird Go SDK with the `}<inlineCode parentName="p">{`go get`}</inlineCode>{` command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`go get -u -v github.com/messagebird/go-rest-api
`}</code></pre>
    <p>{`To get started, we need to:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/subscriptions-go#configuring-the-messagebird-sdk"
        }}>{`Configure the MessageBird SDK`}</a></li>
      <li parentName="ul">{`Set up our MessageBird Dashboard with the `}<a parentName="li" {...{
          "href": "/tutorials/subscriptions-go#prerequisites-for-receiving-messages"
        }}>{`prerequisites for receiving messages`}</a></li>
    </ul>
    <p>{`The source code is available in the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/lead-alerts-guide-go"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`, from which it can be cloned or downloaded into your development environment.`}</p>
    <h2>{`Configuring the MessageBird SDK`}</h2>
    <p>{`While the MessageBird SDK and an API key are not required to receive messages, it’s necessary for sending confirmations and our marketing messages.`}</p>
    <p>{`Let's create your live API access key. First, go to the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/user/index"
      }}>{`MessageBird Dashboard`}</a>{`; if you have already created an API key it will be shown right there. If you don’t see any key on the Dashboard or if you're unsure whether this key is in `}<em parentName="p">{`live`}</em>{` mode, go to the `}<em parentName="p">{`Developers`}</em>{` section in the MessageBird Dashboard and open the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`API access (REST) tab`}</a>{`. There you can create new API keys and manage your existing ones.`}</p>
    <p>{`If you are having any issues creating your API key, please reach out to `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we’ll make sure to help you out.`}</p>
    <p><strong parentName="p">{`Pro-tip:`}</strong>{` To keep this tutorial straightforward, we'll be hardcoding the MessageBird API key in our application; however, is a risky practice that should never be used in production applications. A better method is to to store your API key in a configuration file and access it using a library like `}<a parentName="p" {...{
        "href": "https://github.com/joho/godotenv"
      }}>{`GoDotEnv`}</a>{`.`}</p>
    <p>{`To initialize the MessageBird SDK in your Go application, add the following line of code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`client := messagebird.New("<enter-your-api-key>")
`}</code></pre>
    <p>{`We'll get into where we'll need to do this later in the tutorial as we start writing our application.`}</p>
    <h2>{`Prerequisites for receiving messages`}</h2>
    <h3>{`Overview`}</h3>
    <p>{`This tutorial describes receiving messages using MessageBird. From a high-level viewpoint, receiving is relatively simple: your application defines a `}<em parentName="p">{`webhook URL`}</em>{`, which you assign to a number purchased on the MessageBird Dashboard using `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/flow-builder"
      }}>{`Flow Builder`}</a>{`. A webhook is a URL on your site that doesn't render a page to users but is like an API endpoint that can be triggered by other servers. Whenever someone sends a message to that number, MessageBird collects it and forwards it to the webhook URL, where you can process it.`}</p>
    <h3>{`Exposing your development server with localtunnel`}</h3>
    <p>{`When working with webhooks, an external service like MessageBird needs to access your application, so the webhook URL must be public; however, during development you're typically working in a local development environment that is not publicly available. Thankfully this is not a big deal since various tools and services allow you to quickly expose your development environment to the Internet by providing a tunnel from a public URL to your local machine. One of these tools is `}<a parentName="p" {...{
        "href": "https://localtunnel.me"
      }}>{`localtunnel.me`}</a>{`, which you can install using npm:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install -g localtunnel
`}</code></pre>
    <p>{`You can start a tunnel by providing a local port number on which your application runs. Our sample is configured to run on port 8080, so you can launch your tunnel with this command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`lt --port 8080
`}</code></pre>
    <p>{`After you've launched the tunnel, localtunnel displays your temporary public URL. We'll need that in a minute.`}</p>
    <p>{`Another common tool for tunneling your local machine is `}<a parentName="p" {...{
        "href": "https://ngrok.com"
      }}>{`ngrok`}</a>{`, which works virtually in the same way; you can have a look at it if you're facing problems with localtunnel.me.`}</p>
    <h3>{`Get an inbound number`}</h3>
    <p>{`A requirement for receiving messages is a dedicated inbound number. Virtual mobile numbers look and work similar to regular mobile numbers; however, instead of being attached to a mobile device via a SIM card, they live in the cloud and can process inbound SMS and voice calls. MessageBird offers numbers from different countries for a low monthly fee; `}<a parentName="p" {...{
        "href": "https://www.messagebird.com/en/numbers"
      }}>{`feel free to explore our low-cost programmable and configurable numbers`}</a>{`.`}</p>
    <p>{`Purchasing a number is quite easy:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Go to the ‘`}<a parentName="p" {...{
            "href": "https://dashboard.messagebird.com/en/numbers"
          }}>{`Numbers`}</a>{`’ section in the left-hand side of your Dashboard and click the blue button ‘`}<a parentName="p" {...{
            "href": "https://dashboard.messagebird.com/en/vmn/buy-number"
          }}>{`Buy a number`}</a>{`’ in the top-right side of your screen.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Pick the country in which you and your customers are located, and make sure the SMS capability is selected.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Choose one number from the selection and the duration for which you want to pay now.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Confirm by clicking ‘Buy Number’ in the bottom-right of your screen.`}</p>
      </li>
    </ol>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/subscriptions-go/image1.png",
        "alt": "Buy a VMN"
      }}></img></p>
    <p>{`Awesome, you’ve set up your first virtual mobile number! 🎉`}</p>
    <p><strong parentName="p">{`Pro-tip:`}</strong>{` Check out our Help Center for more information about `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/sections/201958489-Virtual-Numbers"
      }}>{`virtual mobile numbers`}</a>{` and `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/sections/360000108538-Country-info-Restrictions"
      }}>{`country restrictions`}</a>{`.`}</p>
    <h3>{`Connect your number to the webhook`}</h3>
    <p>{`So you have a number now, but MessageBird has no idea what to do with it. That's why now you need to define a `}<em parentName="p">{`Flow`}</em>{` that links your number to your webhook. This is how you do it:`}</p>
    <h4>{`STEP ONE`}</h4>
    <p>{`On the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/numbers"
      }}>{`Numbers`}</a>{` section of the MessageBird Dashboard, click the "Add new flow" icon next to the number you purchased.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/subscriptions-go/image2.png",
        "alt": "FlowBuilder"
      }}></img></p>
    <h4>{`STEP TWO`}</h4>
    <p>{`Hit ‘Create Custom Flow’ and give your flow a name, choose ‘SMS’ as the trigger and hit ‘Next’.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/subscriptions-go/image3.png",
        "alt": "FlowBuilder"
      }}></img></p>
    <h4>{`STEP THREE`}</h4>
    <p>{`The number is already attached to the first step ‘SMS’. Add a new step by pressing the small ‘+’, choose ‘Fetch to URL’ and select ‘POST’ as the method; copy the output from the `}<inlineCode parentName="p">{`lt`}</inlineCode>{` command in the URL and add `}<inlineCode parentName="p">{`/webhook`}</inlineCode>{` to it—this is the name of the route we use to handle incoming messages in our sample application. Click on ‘Save’ when ready.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/subscriptions-go/image4.png",
        "alt": "FlowBuilder"
      }}></img></p>
    <h4>{`STEP FOUR`}</h4>
    <p>{`Ready! Hit ‘Publish’ on the right top of the screen to activate your flow. Well done, another step closer to testing incoming messages! Your flow should look something like this:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/subscriptions-go/image5.png",
        "alt": "FlowBuilder"
      }}></img></p>
    <p><strong parentName="p">{`Pro-tip:`}</strong>{` It might be useful to rename it this flow, because `}<em parentName="p">{`Untitled flow`}</em>{` won't be helpful in the long run. You can do this by clicking on the icon next to button ‘Back to Overview’ and pressing ‘Rename flow’.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/subscriptions-go/image6.png",
        "alt": "FlowBuilder"
      }}></img></p>
    <p>{`A number must be added to your MessageBird contact list before you can send SMS messages to it. To make sure that any phone number that sends a message to your VMN can receive messages from you, you can add an `}<inlineCode parentName="p">{`Add Contact`}</inlineCode>{` step just before the ‘Forward to URL’ step. Be sure to configure the \`Add Contact’ step to add the "sender" to your contact list.`}</p>
    <h2>{`Writing our application`}</h2>
    <p>{`Now that we've installed the MessageBird Go SDK, got our API key, and set up the MessageBird Dashboard with a VMN and attached a flow to it, we can begin writing our application. 🎉`}</p>
    <p>{`Enough talking, let’s get shit done:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "/tutorials/subscriptions-go#get-and-update-a-list-of-subscribers"
          }}>{`Get and update a list of subscribers`}</a></strong>{`: We need to be able to get a list of subscribers, check if they have consented to receive our marketing broadcast, and update their subscription status.`}</li>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "/tutorials/subscriptions-go#set-up-a-web-server-to-receive-requests"
          }}>{`Set up a web server to receive requests`}</a></strong>{`: We need a web server to set up a landing page where we can send out our marketing broadcast and to receive POST requests that the MessageBird server forwards to us.`}</li>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "/tutorials/subscriptions-go#receive-messages"
          }}>{`Receive messages`}</a></strong>{`: Our application must be able to receive POST requests from our MessageBird VMN flow and react according to the contents of that request.`}</li>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "/tutorials/subscriptions-go#send-messages"
          }}>{`Send messages`}</a></strong>{`: Our application must be able to send out messages. We have two types of messages:
`}<em parentName="li">{`(a) An SMS marketing broadcast to all the subscribers who have opted into our broadcast list
`}</em>{`(b) An SMS message that confirms that we have received a request to either subscribe or unsubscribe to our broadcast list.`}</li>
    </ul>
    <p>{`We also need the `}<em parentName="p">{`where and how`}</em>{` we're getting our list of subscribers. For this tutorial, we're using a list of subscribers loaded from a CSV file. In your production-ready application, you would have to replace this data source with an actual list of subscribers drawn from a database or similar.`}</p>
    <h3>{`Get and update a list of subscribers`}</h3>
    <p>{`In the sample code provided, our list of subscribers is stored in subscriberlist.csv. We'll need to load the CSV file into a map and share it with the rest of the application. We’ll do this by writing a `}<inlineCode parentName="p">{`appContext`}</inlineCode>{` struct type, which we’ll use to store the map we load from our CSV file and attach HTTP handle methods for our web server routes.`}</p>
    <p>{`In your project root, create a file named `}<inlineCode parentName="p">{`main.go`}</inlineCode>{`, and enter the following code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`package main

import (
   "log"
)

type appContext struct{
   db *map[string]string
}

func main(){
   db := make(map[string]string)
   app := &appContext{&db}
   err := app.initDB("subscriberlist.csv")
   if err != nil {
       log.Println(err)
   }
}

func (app *appContext) initDB(file string) error{
   f, err := os.Open(file)
   if err != nil {
       return err
   }
   rows, err := csv.NewReader(f).ReadAll()
   if err != nil {
       return err
   }
   for i := range rows {
       (*app.db)[rows[i][0]] = rows[i][1]
   }
   return nil
}
`}</code></pre>
    <p>{`Once we call `}<inlineCode parentName="p">{`app.initDB("subscriberlist.csv")`}</inlineCode>{`, our application loads the "subscriberlist.csv" file and stores it in `}<inlineCode parentName="p">{`app.db`}</inlineCode>{`.`}</p>
    <p>{`Let's add two methods to help us work with our subscriber list:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`func (app *appContext) getSubscriberList() ([]string, error) {
   subscribers := []string{}
   for k := range *app.db {
       if (*app.db)[k] == "yes" {
           subscribers = append(subscribers, k)
       } else {
           // log.Println("omitted: " + k)
       }
   }
   return subscribers, nil
}

func (app *appContext) upsertDB(key string, value string) error {
   (*app.db)[key] = value
   return nil
}
`}</code></pre>
    <p>{`We've added a `}<inlineCode parentName="p">{`getSubscriberList()`}</inlineCode>{` method that gives us a list of subscribers who have consented to receive our marketing broadcast, and a `}<inlineCode parentName="p">{`upsertDB()`}</inlineCode>{` method that updates the status of our subscribers in `}<inlineCode parentName="p">{`app.db`}</inlineCode>{` or adds a new subscriber if they don't exist in `}<inlineCode parentName="p">{`app.db`}</inlineCode>{`.`}</p>
    <p>{`Now that we've set up how we interact with our list of subscribers, we can move on to setting up our web server.`}</p>
    <h3>{`Set up a web server to receive requests`}</h3>
    <p>{`We need to set up a simple web server for a landing page that allows us to send a marketing broadcast and receive POST requests that the MessageBird server forwards to us from our VMN.`}</p>
    <p>{`We won't get into the details of how to work with template rendering here; you can see the `}<inlineCode parentName="p">{`views`}</inlineCode>{` folder on how we've got templating set up. We'll just cover how to write our HTTP server and handlers.`}</p>
    <p>{`First, let's set up our template rendering helper. Add the following code to the bottom of your `}<inlineCode parentName="p">{`main.go`}</inlineCode>{` file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`func renderDefaultTemplate(w http.ResponseWriter, thisView string, data interface{}) {
   renderthis := []string{thisView, "views/layouts/default.gohtml"}
   t, err := template.ParseFiles(renderthis...)
   if err != nil {
       log.Fatal(err)
   }
   err = t.ExecuteTemplate(w, "default", data)
   if err != nil {
       log.Fatal(err)
   }
}
`}</code></pre>
    <p>{`This loads our `}<inlineCode parentName="p">{`default.gohtml`}</inlineCode>{` template, along a single template that we pass to it, and any data we want to render along with the template. For example, we can render our landing template with `}<inlineCode parentName="p">{`renderDefaultTemplate(w, "views/landing.gohtml", &struct{Message string}{"this is a message"})`}</inlineCode>{`.`}</p>
    <p>{`Once we've done that, we can initialize our HTTP server by adding the following code to the bottom of our `}<inlineCode parentName="p">{`main()`}</inlineCode>{` block:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`func main(){
   // ...

   http.Handle("/", app.landingHandler())
   http.Handle("/webhook", app.updateSubscriptionHandler())

   port := ":8080"
   log.Println("Serving on " + port)
   err = http.ListenAndServe(port, nil)
   if err != nil {
       log.Fatalln(err)
   }

}
`}</code></pre>
    <p>{`Here, we've defined two routes:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`/`}</inlineCode>{`, which is our default route and where we will render our landing page.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`/webhook`}</inlineCode>{`, which we will receive POST requests forwarded from our VMN.`}</li>
    </ul>
    <p>{`Notice that our handlers, `}<inlineCode parentName="p">{`app.landingHandler()`}</inlineCode>{` and `}<inlineCode parentName="p">{`app.updateSubscriptionHandler()`}</inlineCode>{`, are attached to `}<inlineCode parentName="p">{`app *appContext`}</inlineCode>{` as methods. This way, they can access our subscriber list that we've stored in `}<inlineCode parentName="p">{`app.db`}</inlineCode>{`.`}</p>
    <p>{`We'll define our `}<inlineCode parentName="p">{`app.updateSubscriptionHandler()`}</inlineCode>{` we we work on `}<a parentName="p" {...{
        "href": "/tutorials/subscriptions-go#receive-messages"
      }}>{`receiving messages`}</a>{`. Right now, we'll define `}<inlineCode parentName="p">{`app.landingHandler()`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`func (app *appContext) landingHandler() http.HandlerFunc {
   templateData := struct {
       SubscriberCount int
       Message         string
   }{}

   subscriberList, err := app.getSubscriberList()
   if err != nil {
       templateData.Message = fmt.Sprintf("Couldn't get subscriber list: %v", err)
   } else {
       templateData.SubscriberCount = len(subscriberList)
   }

   return func(w http.ResponseWriter, r *http.Request) {
       if r.Method == "POST" {
           r.ParseForm()
           msgBody := r.FormValue("message")
           originator := "MBSender"
           err := app.sendSMS(msgBody, originator, subscriberList)
           if err != nil {
               templateData.Message = fmt.Sprintf("Could not send message: %v", err)
           } else {
               templateData.Message = fmt.Sprintf("Message sent to %d subscribers.", len(subscriberList))
           }
       }
       renderDefaultTemplate(w, "views/landing.gohtml", templateData)
       return
   }
}
`}</code></pre>
    <p>{`We're getting our subscriber list and storing it as `}<inlineCode parentName="p">{`subscriberList`}</inlineCode>{` and rendering our `}<inlineCode parentName="p">{`landing.gohtml`}</inlineCode>{` view. When we submit a message to broadcast to our subscriber list, we handle it by checking if `}<inlineCode parentName="p">{`r.Method == "POST"`}</inlineCode>{`, and then parsing the form submitted for the information we need to send the SMS broadcast.`}</p>
    <p>{`Notice that we've defined a `}<inlineCode parentName="p">{`templateData`}</inlineCode>{` struct at the start of our handler, and that we're using it to pass data to render in `}<inlineCode parentName="p">{`renderDefaultTemplate()`}</inlineCode>{` call at the end of our returned `}<inlineCode parentName="p">{`http.HandlerFunc`}</inlineCode>{`. This way, we only have to call `}<inlineCode parentName="p">{`renderDefaultTemplate()`}</inlineCode>{` once and change the content we render by updating the `}<inlineCode parentName="p">{`templateData`}</inlineCode>{` struct.`}</p>
    <p>{`With this approach, we can display errors in our rendered template by writing to the `}<inlineCode parentName="p">{`templateData`}</inlineCode>{` struct instead of log in them to the terminal. For example, instead of `}<inlineCode parentName="p">{`log.Println(err)`}</inlineCode>{`, we display errors in our code sample above with `}<inlineCode parentName="p">{`templateData.Message = fmt.Sprintf("Could not send message: %v", err)`}</inlineCode>{` and then calling `}<inlineCode parentName="p">{`renderDefaultTemplate(/*..*/,templateData)`}</inlineCode>{`.`}</p>
    <p>{`We've also written a `}<inlineCode parentName="p">{`sendSMS()`}</inlineCode>{` helper, which we'll cover in the `}<a parentName="p" {...{
        "href": "/tutorials/subscriptions-go#sending-messages"
      }}>{`sending messages`}</a>{` section.`}</p>
    <p>{`Now, we can move on to writing our `}<inlineCode parentName="p">{`app.updateSubscriptionHandler()`}</inlineCode>{` function.`}</p>
    <h3>{`Receiving messages`}</h3>
    <p>{`When MessageBird forwards messages sent to our VMN to our application, it sends a POST request to the URL that we specify in the `}<strong parentName="p">{`Forward to URL`}</strong>{` step of our defined flow: `}<inlineCode parentName="p">{`<prefix>.localtunnel.me/webhook`}</inlineCode>{` (if you're using localtunnel.me). When we parse the data sent with the POST request, we get a map that looks like the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`map[message_id:[7a76afeaef3743d28d0e2d93621235ca] originator:[<sender_phone_number>] reference:[47749346971] createdDatetime:[2018-09-24T08:30:59+00:00] id:[f91908b75f9e4b1fba3b96dc44995f03] message:[<message_body>] receiver:[<your_VMN>] body:[<message_body>] date:[1537806659] payload:[<message_body>] sender:[<sender_phone_number>] date_utc:[1537777859] recipient:[<your_VMN>]]
`}</code></pre>
    <p>{`Knowing this, let's start writing our `}<inlineCode parentName="p">{`app.updateSubscriptionHandler()`}</inlineCode>{` handler. From the POST request, we need to only get the values of the "receiver", originator" and the "payload" keys:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`func (app *appContext) updateSubscriptionHandler() http.HandlerFunc {
   return func(w http.ResponseWriter, r *http.Request) {
       if r.Method == "POST" {
           r.ParseForm()
           thisNumber := "+" + r.FormValue("receiver")
           subscriber := "+" + r.FormValue("originator")
           payload := r.FormValue("payload")
       }
   }
}
`}</code></pre>
    <p>{`Keep in mind that we need to prefix the "receiver" and "originator" values with a "+" to make sure that the phone numbers we pass into our `}<inlineCode parentName="p">{`sms.Create()`}</inlineCode>{` call are in the international format.`}</p>
    <p>{`Then, we need to write a switch statement that handles the following cases:`}</p>
    <p><em parentName="p">{`1. If the received message body contains the string "SUBSCRIBE", add the "originator" to our subscriber list. If the "originator" already exists in our subscriber list, set their subscription status to "yes" to indicate consent to receive our marketing broadcast.
`}</em>{`2. If the received message contains the string "STOP", set subscription status of the "originator" to "no" to indicate a withdrawal of consent.
`}{`*`}{`3. If the received message contains neither "SUBSCRIBE" nor "STOP", then do nothing with the subscription list.
Modify `}<inlineCode parentName="p">{`updateSubscriptionHandler()`}</inlineCode>{` to look like the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`func (app *appContext) updateSubscriptionHandler() http.HandlerFunc {
   return func(w http.ResponseWriter, r *http.Request) {
       if r.Method == "POST" {
           r.ParseForm()
           thisNumber := "+" + r.FormValue("receiver")
           subscriber := "+" + r.FormValue("originator")
           payload := r.FormValue("payload")

           // Decide what message to send to subscriber.
           switch {
           case strings.Contains(payload, "SUBSCRIBE"):
               err := app.upsertDB(subscriber, "yes")
               if err != nil {
                   log.Println(err)
               }
               err = app.sendSMS("You've been subscribed! Reply \\"STOP\\" to stop receiving these messages.", "MBSender", []string{subscriber})
               if err != nil {
                   log.Println(err)
               }
           case strings.Contains(payload, "STOP"):
               err := app.upsertDB(subscriber, "no")
               if err != nil {
                   log.Println(err)
               }
               err = app.sendSMS("You've been unsubscribed! Reply \\"SUBSCRIBE\\" if you changed your mind!", "MBSender", []string{subscriber})
               if err != nil {
                   log.Println(err)
               }
           default:
               err := app.sendSMS("SMS \\"SUBSCRIBE\\" to this number to subscribe to these messages.", thisNumber, []string{subscriber})
               if err != nil {
                   log.Println(err)
               }
           }
           log.Printf("Received %s request.", r.Method)
       }
   }
}
`}</code></pre>
    <p>{`Notice that we're not rendering a view here because we don't want a publicly available user interface for this; instead, we'll log all output to the terminal for our own use.`}</p>
    <h3>{`Sending messages`}</h3>
    <p>{`Now that we've got most of our application set up, we can get into where we need to send SMS messages and to whom.`}</p>
    <p>{`We need to send SMS messages when:`}</p>
    <p><em parentName="p">{`1. We click `}<strong parentName="em">{`Send to all subscribers`}</strong>{` on our rendered landing page to send a marketing broadcast to all numbers on our subscriber list.
`}</em>{`2. When we add or remove a subscriber to our list, we should send a confirmation SMS message to the subscriber.
`}{`*`}{`3. When we receive an SMS message which is not a request to add or remove the subscriber from our list, we should send an SMS message with a helpful message telling them what keywords their SMS message should contain so that our application can parse their request correctly.`}</p>
    <p>{`Make sure that all recipients in your subscriber list have been added to your contact list. If MessageBird fails to send an SMS message to any one recipient in the recipient list, the remaining SMS messages will successfully send but no error will be returned for the SMS messages that fail to send.`}</p>
    <p>{`So far we've already added `}<inlineCode parentName="p">{`app.sendSMS()`}</inlineCode>{` calls where we need to send SMS messages in our code snippets above, but we haven't defined it yet. Add the following code block just below your `}<inlineCode parentName="p">{`main()`}</inlineCode>{` block:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`func (app *appContext) sendSMS(msgBody string, originator string, recipients []string) error {
   client := messagebird.New("<enter-your-api-key>")
   msg, err := sms.Create(client, originator, recipients, msgBody, nil)
   if err != nil {
       var mbErrors[]string
       switch errResp := err.(type) {
           case messagebird.ErrorResponse:
               for _, mbError := range errResp.Errors {
                   mbErrors = append(mbErrors, fmt.Sprint(mbError))
               }
       }
       return errors.New(fmt.Sprint(mbErrors[:]))
   }
   log.Println(msg)
   return nil
}
`}</code></pre>
    <p>{`Our error handling is a bit more complex here because we have to read the JSON response from the MessageBird REST API and format it as a single error before returning it.`}</p>
    <h2>{`Testing`}</h2>
    <p>{`You’re done! It’s time to test your application.`}</p>
    <p>{`Double-check that you have set up your number correctly with a flow that forwards incoming messages to a localtunnel URL and that the tunnel is still running. You can restart the tunnel with the `}<inlineCode parentName="p">{`lt`}</inlineCode>{` command, but this will change your URL. Just keep in mind that whenever you start a fresh tunnel, you'll get a new URL, so you have to update it in the flows accordingly.`}</p>
    <p>{`To start the sample application you have to enter another command, but your existing terminal window is now busy running your tunnel, so you need to open another one. With Mac you can press `}<em parentName="p">{`Command + Tab`}</em>{` to open a second tab that's already pointed to the correct directory. With other operating systems you may have to open another console window manually. Either way, once you've got a command prompt, type the following to start the application:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`go run main.go
`}</code></pre>
    <p>{`While keeping the terminal open, take out your phone, launch the SMS app, and send a message to your virtual mobile number with the keyword "SUBSCRIBE". If you are using a live API key, you should receive a confirmation message stating that you've been subscribed to the broadcast list. Open http://localhost:8080/ in your browser (or your tunnel URL) and you should also see that there's one subscriber. Try sending yourself a message now. And voilá, your marketing system is ready!`}</p>
    <p>{`You can adapt the sample application for production by replying mongo-mock with a real MongoDB client, deploying the application to a server and providing that server's URL to your flow. Of course, you should add some authorization to the web form; otherwise, anybody could send messages to your subscribers. Don't forget to download the code from the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/subscriptions-guide-go"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`.`}</p>
    <p><strong parentName="p">{`Nice work!`}</strong>{` 🎉`}</p>
    <p>{`You've just built your own marketing system with MessageBird using Go!`}</p>
    <h2>{`Start building!`}</h2>
    <p>{`Want to start building your solution but not quite sure how to get started? Feel free to let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we'd love to help!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      