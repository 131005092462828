import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Docs.tsx";
import Notification from 'components/Notification';
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
import { Img } from 'components/shared';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Install Channel: WeChat`}</h1>
    <p>{`Let's get started with the Programmable Conversations API by learning how to install a channel. In this quickstart, you'll learn how to set up WeChat.`}</p>
    <h2>{`Step 1: Go to the channels directory in your Dashboard`}</h2>
    <p>{`Go to '`}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/channels"
      }}>{`Channels`}</a>{`' in the sidebar of your MessageBird Dashboard. Then click on `}<inlineCode parentName="p">{`Add channel`}</inlineCode>{` under WeChat.`}</p>
    <Img src="/img/screenshots/quickstarts/convo/InstallWeChat1.png" alt="Install WeChat" width="650px" align="middle" mdxType="Img" />
    <h2>{`Step 2: Give your channel a name`}</h2>
    <p>{`Name your channel, make sure it's easily recognizable for yourself and your team. Don't worry, this will only be used internally.`}</p>
    <Img src="/img/screenshots/quickstarts/convo/InstallWeChat2.png" alt="Install WeChat" width="650px" align="middle" mdxType="Img" />
    <h2>{`Step 3: Add your open ID`}</h2>
    <p>{`Add your open ID (also known as Original ID). You can find it in the Developer Section of the `}<a parentName="p" {...{
        "href": "https://mp.weixin.qq.com/?lang=en_US"
      }}>{`WeChat Admin Platform`}</a>{`.`}</p>
    <Img src="/img/screenshots/quickstarts/convo/InstallWeChat3.png" alt="Install WeChat" width="650px" align="middle" mdxType="Img" />
    <h2>{`Step 4: Add your app ID and secret`}</h2>
    <p>{`Add your app ID and secret. You can find them in the Developer Section of the `}<a parentName="p" {...{
        "href": "https://mp.weixin.qq.com/?lang=en_US"
      }}>{`WeChat Admin Platform`}</a>{`.`}</p>
    <Img src="/img/screenshots/quickstarts/convo/InstallWeChat4.png" alt="Install WeChat" width="650px" align="middle" mdxType="Img" />
    <h2>{`Step 5: Set up your profile picture`}</h2>
    <p>{`Personalize your account by adding the URL from your profile picture.`}</p>
    <Img src="/img/screenshots/quickstarts/convo/InstallWeChat5.png" alt="Install WeChat" width="650px" align="middle" mdxType="Img" />
    <h2>{`Step 6: Copy your webhook URL`}</h2>
    <p>{`Your webhook URL is ready! Click on the 'Copy' button, and paste it in the 'API configuration' section in the `}<a parentName="p" {...{
        "href": "https://mp.weixin.qq.com/?lang=en_US"
      }}>{`WeChat Admin Platform`}</a>{`.`}</p>
    <Img src="/img/screenshots/quickstarts/convo/InstallWeChat6.png" alt="Install WeChat" width="650px" align="middle" mdxType="Img" />
    <h2>{`Step 7: Install WeChat`}</h2>
    <p>{`Click on 'Install now' and voilà! WeChat is now installed as a channel.`}</p>
    <Img src="/img/screenshots/quickstarts/convo/InstallWeChat7.png" alt="Install WeChat" width="650px" align="middle" mdxType="Img" />
    <h2>{`Step 6: Manage your channel`}</h2>
    <p>{`If you want to edit, rename or delete your channel, simply select the channel and save the changes afterwards.`}</p>
    <p>{`Nice work! 🎉 You've successfully installed WeChat as a channel for the MessageBird Programmable Conversations API.`}</p>
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'Install Channel: SMS',
      to: '/docs/conversations/install-channel'
    }, {
      name: 'Install Channel: WhatsApp',
      to: '/docs/conversations/install-channel-whatsapp'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      