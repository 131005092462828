import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import Layout from '../Layout/Default';
import { LANGS } from '../../constants';
import { LangContext } from '../../contexts/LanguageContext';

const DocsContent = ({ location, allDocs, language, children }) => {
  const { setAvailableLanguages } = useContext(LangContext);
  const allLangs = Object.keys(LANGS);
  const pathChunks = location.pathname.split('-');
  const probablyALanguage = pathChunks.pop().replace(/^\/|\/$/g, '');
  const realLanguage =
    allLangs.includes(probablyALanguage) && probablyALanguage;
  const pathWithoutLanguage = pathChunks.join('-');
  const currDocsAllLangs = allDocs
    .filter((path) => path.includes(pathWithoutLanguage))
    .map((path) =>
      path
        .split('-')
        .pop()
        .replace(/^\/|\/$/g, ''),
    )
    .map((lang) => {
      if (!LANGS[lang]) {
        return null;
      }
      return lang;
    })
    .filter((lang) => !!lang);

  const uniqueCurrDocsAllLangs = currDocsAllLangs.filter(
    (tab, i) => currDocsAllLangs.indexOf(tab) === i,
  );
  const languageRedirect = React.useCallback(
    (window) => {
      if (realLanguage && realLanguage !== language) {
        const newPath = `${pathWithoutLanguage}-${language.toLowerCase()}`;
        if (allDocs.includes(`${newPath}/`)) {
          // if window is a fix for CI because navigate() needs window
          if (window) {
            navigate(newPath);
          }
          return true;
        }
      }
      return false;
    },
    [realLanguage, language, pathWithoutLanguage, allDocs],
  );
  useEffect(() => {
    setAvailableLanguages(uniqueCurrDocsAllLangs);
  }, []);
  useEffect(() => {
    languageRedirect(window);
  }, [language, languageRedirect]);
  const isHomepage = ['/', '/docs/'].includes(location.pathname);
  return (
    <Layout
      sidebar={isHomepage ? false : true}
      languages={uniqueCurrDocsAllLangs}
    >
      {children}
    </Layout>
  );
};

DocsContent.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  allDocs: PropTypes.array.isRequired,
  language: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default DocsContent;
