import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import Highlight, { defaultProps, Prism } from 'prism-react-renderer';
import PrismNormalizer from 'prismjs/plugins/normalize-whitespace/prism-normalize-whitespace';
import { LangContext } from '../../contexts/LanguageContext';
import LocationContext from '../../contexts/LocationContext';
import { CodeWrap } from './styles';
import loadable from '@loadable/component';

// Some languages are not included in prism-react-renderer by deafult, so we import them manually:
// https://github.com/FormidableLabs/prism-react-renderer#faq
(typeof global !== 'undefined' ? global : window).Prism = Prism;
require('prismjs/components/prism-java');
require('prismjs/components/prism-ruby');
require('prismjs/components/prism-php');

const CodeNormaliser = new PrismNormalizer({
  'remove-trailing': true,
  'remove-indent': true,
  'left-trim': true,
  'right-trim': true,
  'tabs-to-spaces': 4,
});

const changeLang = {
  curl: 'bash',
  csharp: 'c',
  node: 'javascript',
};
const CodeLanguageDropdown = loadable(() =>
  import('../Dropdowns/CodeLanguageDropdown'),
);

const CodeSnippet = ({ code, withTabs, lang, ...props }) => {
  const { pathname } = useContext(LocationContext);
  const {
    language: contextLanguage,
    availableLanguages,
    handleLangChange,
  } = useContext(LangContext);
  const codeLang = lang || contextLanguage;
  const preferedLang = code[codeLang] ? codeLang : Object.keys(code)[0];
  const prismLang = changeLang[preferedLang] || preferedLang;
  const prismCode = code[preferedLang]
    ? code[preferedLang]
    : code[Object.keys(code)[0]];
  let languages = pathname.includes('/api')
    ? Object.keys(code)
    : availableLanguages;
  const isTutorials = pathname.includes('/tutorials/');

  const prismProps = {
    ...defaultProps,
    code: CodeNormaliser.normalize(prismCode),
    language: prismLang,
    theme: undefined,
  };

  return (
    <>
      <CodeWrap {...props}>
        {languages && languages.length > 1 && !isTutorials && (
          <CodeLanguageDropdown
            css="margin-bottom: 28px;"
            language={preferedLang}
            languages={languages}
            handleExtChange={handleLangChange}
            fallback={<div css="height: 38px; position: relative;" />}
          />
        )}
        <Highlight {...prismProps}>
          {({ className, tokens, getLineProps, getTokenProps }) => (
            <pre className={className}>
              {tokens.map((line, i) => (
                <div {...getLineProps({ line, key: i })}>
                  {line.map((token, key) => (
                    <span {...getTokenProps({ token, key })} />
                  ))}
                </div>
              ))}
            </pre>
          )}
        </Highlight>
      </CodeWrap>
    </>
  );
};

CodeSnippet.propTypes = {
  code: PropTypes.shape({}).isRequired,
  withTabs: PropTypes.bool,
  lang: PropTypes.string,
};

CodeSnippet.defaultProps = {
  withTabs: false,
  lang: undefined,
};
export default CodeSnippet;
