export const SMS_SNIPPETS = {
  curl: `curl -X POST https://rest.messagebird.com/messages \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -d "recipients=31612345678" \\
  -d "originator=YourName" \\
  -d "body=This is a test message"`,
  php: `$MessageBird = new \\MessageBird\\Client('ACCESS_KEY');

$Message = new \\MessageBird\\Objects\\Message();
$Message->originator = 'MessageBird';
$Message->recipients = array(31612345678);
$Message->body = 'This is a test message.';

$MessageBird->messages->create($Message);`,
  ruby: `client = MessageBird::Client.new(YOUR_ACCESS_KEY)
client.message_create('FromMe', '31612345678', 'Hello World', :reference => 'Foobar')`,
  csharp: `const string YourAccessKey = "YOUR_ACCESS_KEY"; // your access key here
Client client = Client.CreateDefault(YourAccessKey);
const long Msisdn = 31612345678; // your phone number here

MessageBird.Objects.Message message =
client.SendMessage("MessageBird", "Tjirp", new[] { Msisdn });`,
  go: `// The optional parameters.
params := &sms.Params{Reference: "MyReference"}

message, err := sms.Create(
client,
"Originator",
[]string{"31612345678"},
"Hello World",
params,
)`,
  python: `client = messagebird.Client(ACCESS_KEY)
message = client.message_create(
      'MessageBird',
      '31612345678',
      'This is a test message.',
      { 'reference' : 'Foobar' }
  )`,
  java: `import com.messagebird.MessageBirdClient;
import com.messagebird.MessageBirdService;
import com.messagebird.MessageBirdServiceImpl;
import com.messagebird.exceptions.GeneralException;
import com.messagebird.exceptions.NotFoundException;
import com.messagebird.exceptions.UnauthorizedException;
import com.messagebird.objects.MessageResponse;

public class ExampleViewMessage {

  public static void main(String[] args) {
      if (args.length < 2) {
          System.out.println("Please specify your access key and a message ID : java -jar <this jar file≶ test_accesskey e8077d803532c0b5937c639b60216938");
          return;
      }

      // First create your service object
      final MessageBirdService wsr = new MessageBirdServiceImpl(args[0]);

      // Add the service to the client
      final MessageBirdClient messageBirdClient = new MessageBirdClient(wsr);

      try {
          // Get Hlr using msgId and msisdn
          System.out.println("getting message info message:");
          final MessageResponse response = messageBirdClient.viewMessage(args[1]);
          System.out.println(response.toString());


      } catch (UnauthorizedException unauthorized) {
          if (unauthorized.getErrors() != null) {
              System.out.println(unauthorized.getErrors().toString());
          }
          unauthorized.printStackTrace();
      } catch (GeneralException generalException) {
          if (generalException.getErrors() != null) {
              System.out.println(generalException.getErrors().toString());
          }
          generalException.printStackTrace();
      } catch (NotFoundException notFoundException) {
          if (notFoundException.getErrors() !=null) {
              System.out.println(notFoundException.getErrors().toString());
          }
          notFoundException.printStackTrace();
      }
  }
}`,
  node: `var messagebird = require('messagebird')('YOUR_ACCESS_KEY');

var params = {
  'originator': 'MessageBird',
  'recipients': [
    '31612345678'
],
  'body': 'This is a test message.'
};

messagebird.messages.create(params, function (err, response) {
  if (err) {
    return console.log(err);
  }
  console.log(response);
});`,
};

export const API_ENDPOINT = {
  bash: `https://rest.messagebird.com/messages`,
};

export const HTTP_METHODS = {
  bash: `POST /messages
GET /messages
GET /messages/{messageId}
DELETE /messages/{messageId}`,
};

export const SMS_AUTH1 = {
  curl: `curl -X GET https://rest.messagebird.com/balance \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM'`,
  php: `$MessageBird = new \\MessageBird\\Client('YOUR_ACCESS_KEY');`,
  ruby: `require 'messagebird'
client = MessageBird::Client.new('YOUR_ACCESS_KEY')`,
  csharp: `const string YourAccessKey = "YOUR_ACCESS_KEY";
Client client = Client.CreateDefault(YourAccessKey);`,
  go: `import messagebird "github.com/messagebird/go-rest-api"

func main() {
    client := messagebird.New("YOUR_ACCESS_KEY")
    // ...
}`,
  python: `import messagebird
client = messagebird.Client('YOUR_ACCESS_KEY')`,
  java: `import com.messagebird.MessageBirdClient;
import com.messagebird.MessageBirdService;
import com.messagebird.MessageBirdServiceImpl;

public class ExampleAuthenticate {

    public static void main() {
        // Create a MessageBirdService
        final MessageBirdService wsr = new MessageBirdServiceImpl("YOUR_ACCESS_KEY");

        // Add the service to the client
        final MessageBirdClient messageBirdClient = new MessageBirdClient(wsr);
    }
}`,
  node: `var messagebird = require('messagebird')('YOUR_ACCESS_KEY');`,
};

export const SMS_OBJECT1 = {
  json: `{
  "id":"e8077d803532c0b5937c639b60216938",
  "href":"https://rest.messagebird.com/messages/e8077d803532c0b5937c639b60216938",
  "direction":"mt",
  "type":"sms",
  "originator":"MessageBird",
  "body":"The message to be sent",
  "reference":"the-client-reference",
  "validity":null,
  "gateway":240,
  "typeDetails":{

  },
  "datacoding":"plain",
  "mclass":1,
  "scheduledDatetime":null,
  "createdDatetime":"2016-04-29T09:42:26+00:00",
  "recipients":{
    "totalCount":1,
    "totalSentCount":1,
    "totalDeliveredCount":1,
    "totalDeliveryFailedCount":0,
    "items":[
      {
        "recipient":31612345678,
        "status":"sent",
        "statusReason":"successfully delivered",
        "statusErrorCode":null,
        "statusDatetime":"2016-04-29T09:42:26+00:00",
        "recipientCountry":"Netherlands",
        "recipientCountryPrefix":31,
        "recipientOperator":"KPN",
        "mccmnc":"20408",
        "mcc":"204",
        "mnc":"08",
        "messageLength":22,
        "messagePartCount":1,
        "price":{
            "amount":0.075,
            "currency":"EUR"
        }
      }
    ]
  }
}`,
};

export const SMS_OBJECT2 = {
  json: `{
  "id":"98154fa03532c2c3fc7b341b46487018",
  "href":"https://rest.messagebird.com/messages/98154fa03532c2c3fc7b341b46487018",
  "direction":"mt",
  "type":"binary",
  "originator":"MessageBird",
  "body":"546865206d65737361676520746f2062652073656e74",
  "reference":null,
  "validity":null,
  "gateway":240,
  "typeDetails":{
    "udh":"050003340201"
  },
  "datacoding":"plain",
  "mclass":1,
  "scheduledDatetime":null,
  "createdDatetime":"2016-04-29T09:42:26+00:00",
  "recipients":{
    "totalCount":2,
    "totalSentCount":1,
    "totalDeliveredCount":1,
    "totalDeliveryFailedCount":0,
    "items":[
      {
        "recipient":31612345678,
        "status":"sent",
        "statusReason":"successfully delivered",
        "statusErrorCode":null,
        "statusDatetime":"2016-04-29T09:42:26+00:00",
        "recipientCountry":"Netherlands",
        "recipientCountryPrefix":31,
        "recipientOperator":"KPN",
        "mccmnc":"20408",
        "mcc":"204",
        "mnc":"08",
        "messageLength":44,
        "messagePartCount":1,
        "price":{
            "amount":0.075,
            "currency":"EUR"
        }
      },
      {
        "recipient":31612345679,
        "status":"delivery_failed",
        "statusReason":"unknown subscriber",
        "statusErrorCode":1,
        "statusDatetime":"2016-04-29T09:42:26+00:00",
        "recipientCountry":"Netherlands",
        "recipientCountryPrefix":31,
        "recipientOperator":"",
        "mccmnc":null,
        "mcc":null,
        "mnc":null,
        "messageLength":44,
        "messagePartCount":1,
        "price":{
            "amount":null,
            "currency":null
        }
      }
    ]
  }
}`,
};

export const SEND_DEF = {
  bash: `POST https://rest.messagebird.com/messages`,
};

export const SEND_EXAMPLE = {
  curl: `curl -X POST https://rest.messagebird.com/messages \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -d "recipients=31612345678" \\
  -d "originator=YourName" \\
  -d "shortcode=1008" \\
  -d "body=This is a test message"`,
  php: `$MessageBird = new \\MessageBird\\Client('ACCESS_KEY');

$Message = new \\MessageBird\\Objects\\Message();
$Message->originator = 'MessageBird';
$Message->recipients = array(31612345678);
$Message->body = 'This is a test message.';

$MessageBird->messages->create($Message);`,
  ruby: `
require 'messagebird'

client = MessageBird::Client.new('COLExGv4lYOAFbzJmcQdT7DJk')
msg = client.message_create('FromMe', '31616579155', 'Hello World', reference: 'Foobar')`,
  csharp: `const string YourAccessKey = "YOUR_ACCESS_KEY"; // your access key here
Client client = Client.CreateDefault(YourAccessKey);
const long Msisdn = 31612345678; // your phone number here

MessageBird.Objects.Message message = client.SendMessage("MessageBird", "Tjirp", new[] { Msisdn });`,
  go: `// The optional parameters.
params := &sms.Params{
  Reference: "MyReference",
  TypeDetails: sms.TypeDetails{
    "shortcode": 1008,
  },
}

message, err := sms.Create(
  client,
  "Originator",
  []string{"31612345678"},
  "Hello World",
  params,
)`,
  python: `client = messagebird.Client(ACCESS_KEY)
message = client.message_create(
        'MessageBird',
        '31612345678',
        'This is a test message.',
        { 'reference' : 'Foobar' }
    )`,
  java: `
  import com.messagebird.MessageBirdClient;
  import com.messagebird.MessageBirdService;
  import com.messagebird.MessageBirdServiceImpl;
  import com.messagebird.objects.MessageResponse;
  import com.messagebird.exceptions.GeneralException;
  import com.messagebird.exceptions.UnauthorizedException;
  
  import java.math.BigInteger;
  import java.util.ArrayList;
  import java.util.List;
  
  
  public class ExampleSendMessage {
  
      public static void main(String[] args) {
  
          // First create your service object
          final MessageBirdService wsr = new MessageBirdServiceImpl("COLExGv4lYOAFbzJmcQdT7DJk");
  
          // Add the service to the client
          final MessageBirdClient messageBirdClient = new MessageBirdClient(wsr);
          try {
              System.out.println("Sending message:");
              final List<BigInteger> phones = new ArrayList<BigInteger>();
              phones.add(new BigInteger("31616579155"));
              final MessageResponse response = messageBirdClient.sendMessage("MessageBird", "My message to be send", phones);
              System.out.println(response.toString());
  
          } catch (UnauthorizedException | GeneralException exception) {
              if (exception.getErrors() != null) {
                  System.out.println(exception.getErrors().toString());
              }
              exception.printStackTrace();
          }
      }
  }
  `,
  node: `var messagebird = require('messagebird')('YOUR_ACCESS_KEY');

var params = {
  'originator': 'MessageBird',
  'recipients': [
    '31612345678'
  ],
  'body': 'This is a test message.'
};

messagebird.messages.create(params, function (err, response) {
  if (err) {
    return console.log(err);
  }
  console.log(response);
});`,
};

export const SEND_RESPONSE = {
  curl: `{
  "id":"e8077d803532c0b5937c639b60216938",
  "href":"https://rest.messagebird.com/messages/e8077d803532c0b5937c639b60216938",
  "direction":"mt",
  "type":"sms",
  "originator":"YourName",
  "body":"This is a test message",
  "reference":null,
  "validity":null,
  "gateway":null,
  "typeDetails":{},
  "datacoding":"plain",
  "mclass":1,
  "scheduledDatetime":null,
  "createdDatetime":"2016-05-03T14:26:57+00:00",
  "recipients":{
    "totalCount":1,
    "totalSentCount":1,
    "totalDeliveredCount":0,
    "totalDeliveryFailedCount":0,
    "items":[
      {
        "recipient":31612345678,
        "status":"sent",
        "statusDatetime":"2016-05-03T14:26:57+00:00"
      }
    ]
  }
}`,
  php: `{
  'id' => 'e60a6f90453a19019c56ed6b20170831',
  'href' => 'https://rest.messagebird.com/messages/e60a6f90453a19019c56ed6b20170831',
  'direction' => 'mt',
  'type' => 'sms',
  'originator' => 'MessageBird',
  'body' => 'This is a test message.',
  'reference' => NULL,
  'validity' => NULL,
  'gateway' => 240,
  'typeDetails' => {},
  'datacoding' => 'plain',
  'mclass' => 1,
  'scheduledDatetime' => NULL,
  'createdDatetime' => '2016-05-03T14:26:57+00:00',
  'recipients' => array(
    'totalCount' => 1,
    'totalSentCount' => 1,
    'totalDeliveredCount' => 0,
    'totalDeliveryFailedCount' => 0,
    'items' => array (
      {
        'recipient' => 31612345678,
        'status' => 'sent',
        'statusDatetime' => '2016-05-03T14:26:57+00:00',
      },
    )
  )
}`,
  ruby: `#<MessageBird::Message:0x007f8d5b883520
@body="Hello World",
@createdDatetime=2016-05-03 14:26:57 +0000,
@datacoding="plain",
@direction="mt",
@gateway=239,
@href=
"https://rest.messagebird.com/messages/211e6280453ba746e8eeff7b12582146",
@id="211e6280453ba746e8eeff7b12582146",
@mclass=1,
@originator="FromMe",
@recipient=
{"totalCount"=>1,
"totalSentCount"=>1,
"totalDeliveredCount"=>0,
"totalDeliveryFailedCount"=>0,
"items"=>
  [#<MessageBird::Recipient:0x007f8d5c058c00
    @recipient=31612345678,
    @status="sent",
    @statusDatetime=2016-05-03 14:26:57 +0000>]},
@reference=nil,
@scheduledDatetime=nil,
@type="sms",
@typeDetails={},
@validity=nil>`,
  go: `&sms.Message{
  Id:"8ef55b60453c653f5794a87b46861030",
  HRef:"https://rest.messagebird.com/messages/8ef55b60453c653f5794a87b46861030",
  Direction:"mt",
  Type:"sms",
  Originator:"MyName",
  Body:"Hello World",
  Reference:"MyReference",
  Validity:"",
  Gateway:239,
  TypeDetails:map[string]interface {}{},
  DataCoding:"plain",
  MClass:1,
  ScheduledDatetime:(*time.Time)(nil),
  CreatedDatetime:(*time.Time)(0xc20810c040),
  Recipients:messagebird.Recipients{
    TotalCount:1,
    TotalSentCount:1,
    TotalDeliveredCount:0,
    TotalDeliveryFailedCount:0,
    Items:[]messagebird.Recipient{
      messagebird.Recipient{
        Recipient:31612345678,
        Status:"sent",
        StatusDatetime:(*time.Time)(0xc20810c360),
      }
    }
  }
}`,
  csharp: `{
  'id': 'e60a6f90453a19019c56ed6b20170831',
  'href': 'https://rest.messagebird.com/messages/e60a6f90453a19019c56ed6b20170831',
  'direction': 'mt',
  'type': 'sms',
  'originator': 'MessageBird',
  'body': 'This is a test message.',
  'reference': NULL,
  'validity': NULL,
  'gateway': 240,
  'typeDetails': {},
  'datacoding': 'plain',
  'mclass': 1,
  'scheduledDatetime': NULL,
  'createdDatetime': '2016-05-03T14:26:57+00:00',
  'recipients': {
    'totalCount': 1,
    'totalSentCount': 1,
    'totalDeliveredCount': 0,
    'totalDeliveryFailedCount': 0,
    'items': [
      {
        'recipient': 31612345678,
        'status': 'sent',
        'statusDatetime': '2016-05-03T14:26:57+00:00',
      },
    ]
  }
}`,
  python: `{
  'id' : 'e60a6f90453a19019c56ed6b20170831',
  'href' : 'https://rest.messagebird.com/messages/e60a6f90453a19019c56ed6b20170831',
  'direction' : 'mt',
  'type' : 'sms',
  'originator' : 'MessageBird',
  'body' : 'This is a test message.',
  'reference' : 'Foobar',
  'validity' : None,
  'gateway' : 240,
  'typeDetails' : {},
  'datacoding' : 'plain',
  'mclass' : 1,
  'scheduledDatetime' : None,
  'createdDatetime' : '2016-05-03 14:26:57',
  'recipients' : [
    'totalCount' : 1,
    'totalSentCount' : 1,
    'totalDeliveredCount' : 0,
    'totalDeliveryFailedCount' : 0,
    'items' : [
      {
        'recipient' : 31612345678,
        'status' : 'sent',
        'statusDatetime' : '2016-05-03 14:26:57',
      },
    ]
  ]
}`,
  java: `#<MessageBird::Message:0x007f8d5b883520
@body="Hello World",
@createdDatetime=2016-05-03 14:26:57 +0000,
@datacoding="plain",
@direction="mt",
@gateway=239,
@href=
"https://rest.messagebird.com/messages/211e6280453ba746e8eeff7b12582146",
@id="211e6280453ba746e8eeff7b12582146",
@mclass=1,
@originator="FromMe",
@recipient=
{"totalCount"=>1,
"totalSentCount"=>1,
"totalDeliveredCount"=>0,
"totalDeliveryFailedCount"=>0,
"items"=>
  [#<MessageBird::Recipient:0x007f8d5c058c00
    @recipient=31612345678,
    @status="sent",
    @statusDatetime=2016-05-03 14:26:57 +0000>]},
@reference=nil,
@scheduledDatetime=nil,
@type="sms",
@typeDetails={},
@validity=nil>`,
  node: `{
  'id': 'e60a6f90453a19019c56ed6b20170831',
  'href': 'https://rest.messagebird.com/messages/e60a6f90453a19019c56ed6b20170831',
  'direction': 'mt',
  'type': 'sms',
  'originator': 'MessageBird',
  'body': 'This is a test message.',
  'reference': NULL,
  'validity': NULL,
  'gateway': 240,
  'typeDetails': {},
  'datacoding': 'plain',
  'mclass': 1,
  'scheduledDatetime': NULL,
  'createdDatetime': '2016-05-03T14:26:57+00:00',
  'recipients': {
    'totalCount': 1,
    'totalSentCount': 1,
    'totalDeliveredCount': 0,
    'totalDeliveryFailedCount': 0,
    'items': [
      {
        'recipient': 31612345678,
        'status': 'sent',
        'statusDatetime': '2016-05-03T14:26:57+00:00',
      },
    ]
  }
}`,
};

export const RECEIVE_EXAMPLE1 = {
  bash: `GET http://your-own.url/script?id=e8077d803532c0b5937c639b60216938&recipient=31642500190&originator=31612345678&body=This+is+an+incoming+message&createdDatetime=2016-05-03T14%3A26%3A57%2B00%3A00`,

  php: `GET http://your-own.url/script?id=e8077d803532c0b5937c639b60216938&recipient=31642500190&originator=31612345678&body=This+is+an+incoming+message&createdDatetime=2016-05-03T14%3A26%3A57%2B00%3A00`,

  ruby: `GET http://your-own.url/script?id=e8077d803532c0b5937c639b60216938&recipient=31642500190&originator=31612345678&body=This+is+an+incoming+message&createdDatetime=2016-05-03T14%3A26%3A57%2B00%3A00`,

  csharp: `GET http://your-own.url/script?id=e8077d803532c0b5937c639b60216938&recipient=31642500190&originator=31612345678&body=This+is+an+incoming+message&createdDatetime=2016-05-03T14%3A26%3A57%2B00%3A00`,

  go: `GET http://your-own.url/script?id=e8077d803532c0b5937c639b60216938&recipient=31642500190&originator=31612345678&body=This+is+an+incoming+message&createdDatetime=2016-05-03T14%3A26%3A57%2B00%3A00`,

  python: `GET http://your-own.url/script?id=e8077d803532c0b5937c639b60216938&recipient=31642500190&originator=31612345678&body=This+is+an+incoming+message&createdDatetime=2016-05-03T14%3A26%3A57%2B00%3A00`,

  java: `GET http://your-own.url/script?id=e8077d803532c0b5937c639b60216938&recipient=31642500190&originator=31612345678&body=This+is+an+incoming+message&createdDatetime=2016-05-03T14%3A26%3A57%2B00%3A00`,

  node: `GET http://your-own.url/script?id=e8077d803532c0b5937c639b60216938&recipient=31642500190&originator=31612345678&body=This+is+an+incoming+message&createdDatetime=2016-05-03T14:26:57+00:00`,
};

export const RESPONSE_OK = {
  bash: `200 OK`,
};

export const RECEIVE_EXAMPLE2 = {
  bash: `GET http://your-own.url/script?mid=123456789&shortcode=1008&keyword=MESSAGEBIRD&originator=31612345678&operator=20401&message=This+is+an+incoming+message&receive_datetime=20160503142657`,
};

export const VIEW_DEF = {
  bash: 'GET https://rest.messagebird.com/messages/{messageId}',
};

export const VIEW_EXAMPLE = {
  curl: `curl -X GET https://rest.messagebird.com/messages/211e6280453ba746e8eeff7b12582146 \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM'`,
  php: `$MessageBird = new \\MessageBird\\Client('ACCESS_KEY');
$MessageBird->messages->read('e60a6f90453a19019c56ed6b20170831');`,
  ruby: `client = MessageBird::Client.new(YOUR_ACCESS_KEY)
client.message('211e6280453ba746e8eeff7b12582146')`,
  csharp: `const string YourAccessKey = "YOUR_ACCESS_KEY";
const string MessageId = "211e6280453ba746e8eeff7b12582146";

Client client = Client.CreateDefault(YourAccessKey);
MessageBird.Objects.Message message = client.ViewMessage(MessageId);`,
  go: `message, err := sms.Read(client, "8ef55b60453c653f5794a87b46861030")`,
  python: `client = messagebird.Client(ACCESS_KEY)
message = client.message('e60a6f90453a19019c56ed6b20170831')`,
  java: `import com.messagebird.MessageBirdClient;
import com.messagebird.MessageBirdService;
import com.messagebird.MessageBirdServiceImpl;
import com.messagebird.exceptions.GeneralException;
import com.messagebird.exceptions.NotFoundException;
import com.messagebird.exceptions.UnauthorizedException;
import com.messagebird.objects.MessageResponse;

public class ExampleViewMessage {

  public static void main(String[] args) {
      if (args.length < 2) {
          System.out.println("Please specify your access key and a message ID : java -jar <this jar file≶ test_accesskey e8077d803532c0b5937c639b60216938");
          return;
      }

      // First create your service object
      final MessageBirdService wsr = new MessageBirdServiceImpl(args[0]);

      // Add the service to the client
      final MessageBirdClient messageBirdClient = new MessageBirdClient(wsr);

      try {
          // Get Hlr using msgId and msisdn
          System.out.println("getting message info message:");
          final MessageResponse response = messageBirdClient.viewMessage(args[1]);
          System.out.println(response.toString());


      } catch (UnauthorizedException unauthorized) {
          if (unauthorized.getErrors() != null) {
              System.out.println(unauthorized.getErrors().toString());
          }
          unauthorized.printStackTrace();
      } catch (GeneralException generalException) {
          if (generalException.getErrors() != null) {
              System.out.println(generalException.getErrors().toString());
          }
          generalException.printStackTrace();
      } catch (NotFoundException notFoundException) {
          if (notFoundException.getErrors() !=null) {
              System.out.println(notFoundException.getErrors().toString());
          }
          notFoundException.printStackTrace();
      }
  }
}`,
  node: `var messagebird = require('messagebird')('YOUR_ACCESS_KEY');

messagebird.messages.read('e60a6f90453a19019c56ed6b20170831', function (err, response) {
  if (err) {
    return console.log(err);
  }
  console.log(response);
});`,
};

export const VIEW_RESPONSE = {
  curl: `{
  "id":"e8077d803532c0b5937c639b60216938",
  "href":"https://rest.messagebird.com/messages/e8077d803532c0b5937c639b60216938",
  "direction":"mt",
  "type":"sms",
  "originator":"YourName",
  "body":"This is a test message",
  "reference":null,
  "validity":null,
  "gateway":null,
  "typeDetails":{},
  "datacoding":"plain",
  "mclass":1,
  "scheduledDatetime":null,
  "createdDatetime":"2016-05-03T14:26:57+00:00",
  "recipients":{
    "totalCount":1,
    "totalSentCount":1,
    "totalDeliveredCount":0,
    "totalDeliveryFailedCount":0,
    "items":[
      {
        "recipient":31612345678,
        "status":"sent",
        "statusReason":"successfully delivered",
        "statusErrorCode":null,
        "statusDatetime":"2016-05-03T14:26:57+00:00",
        "recipientCountry":"Netherlands",
        "recipientCountryPrefix":31,
        "recipientOperator":"KPN",
        "mccmnc":"20408",
        "mcc":"204",
        "mnc":"08",
        "messageLength":22,
        "messagePartCount":1,
        "price":{
            "amount":0.75,
            "currency":"EUR"
        }
      }
    ]
  }
}`,
  php: `{
  'id' => 'e60a6f90453a19019c56ed6b20170831',
  'href' => 'https://rest.messagebird.com/messages/e60a6f90453a19019c56ed6b20170831',
  'direction' => 'mt',
  'type' => 'sms',
  'originator' => 'MessageBird',
  'body' => 'This is a test message.',
  'reference' => NULL,
  'validity' => NULL,
  'gateway' => 240,
  'typeDetails' => {},
  'datacoding' => 'plain',
  'mclass' => 1,
  'scheduledDatetime' => NULL,
  'createdDatetime' => '2016-05-03T14:26:57+00:00',
  'recipients' => array(
    'totalCount' => 1,
    'totalSentCount' => 1,
    'totalDeliveredCount' => 0,
    'totalDeliveryFailedCount' => 0,
    'items' => array (
      {
        'recipient' => 31612345678,
        'status' => 'delivered',
        'statusDatetime' => '2016-05-03T14:26:57+00:00',
      },
    )
  )
} `,
  ruby: `#<MessageBird::Message:0x007f8d5b883520
@body="Hello World",
@createdDatetime=2014-07-07 12:20:30 +0200,
@datacoding="plain",
@direction="mt",
@gateway=239,
@href=
"https://rest.messagebird.com/messages/211e6280453ba746e8eeff7b12582146",
@id="211e6280453ba746e8eeff7b12582146",
@mclass=1,
@originator="FromMe",
@recipient=
{"totalCount"=>1,
"totalSentCount"=>1,
"totalDeliveredCount"=>0,
"totalDeliveryFailedCount"=>0,
"items"=>
  [#<MessageBird::Recipient:0x007f8d5c058c00
    @recipient=31612345678,
    @status="delivered",
    @statusDatetime=2016-05-03 14:26:57 +0000>]},
@reference=nil,
@scheduledDatetime=nil,
@type="sms",
@typeDetails={},
@validity=nil>`,
  csharp: `{
  'id': 'e60a6f90453a19019c56ed6b20170831',
  'href': 'https://rest.messagebird.com/messages/e60a6f90453a19019c56ed6b20170831',
  'direction': 'mt',
  'type': 'sms',
  'originator': 'MessageBird',
  'body': 'This is a test message.',
  'reference': NULL,
  'validity': NULL,
  'gateway': 240,
  'typeDetails': {},
  'datacoding': 'plain',
  'mclass': 1,
  'scheduledDatetime': NULL,
  'createdDatetime': '2016-05-03T14:26:57+00:00',
  'recipients': {
    'totalCount': 1,
    'totalSentCount': 1,
    'totalDeliveredCount': 0,
    'totalDeliveryFailedCount': 0,
    'items': [
      {
        'recipient': 31612345678,
        'status': 'delivered',
        'statusDatetime': '2016-05-03T14:26:57+00:00',
      },
    ]
  }
}`,
  go: `&sms.Message{
  Id:"8ef55b60453c653f5794a87b46861030",
  HRef:"https://rest.messagebird.com/messages/8ef55b60453c653f5794a87b46861030",
  Direction:"mt",
  Type:"sms",
  Originator:"MyName",
  Body:"Hello World",
  Reference:"MyReference",
  Validity:nil,
  Gateway:239,
  TypeDetails:map[string]interface {}{},
  DataCoding:"plain",
  MClass:1,
  ScheduledDatetime:(*time.Time)(nil),
  CreatedDatetime:(*time.Time)(0xc20810c040),
  Recipients:messagebird.Recipients{
    TotalCount:1,
    TotalSentCount:0,
    TotalDeliveredCount:1,
    TotalDeliveryFailedCount:0,
    Items:[]messagebird.Recipient{
      messagebird.Recipient{
        Recipient:31612345678,
        Status:"delivered",
        StatusDatetime:(*time.Time)(0xc20810c360)
      }
    }
  }
}`,
  python: `{
  'id' : 'e60a6f90453a19019c56ed6b20170831',
  'href' : 'https://rest.messagebird.com/messages/e60a6f90453a19019c56ed6b20170831',
  'direction' : 'mt',
  'type' : 'sms',
  'originator' : 'MessageBird',
  'body' : 'This is a test message.',
  'reference' : None,
  'validity' : None,
  'gateway' : 240,
  'typeDetails' : {},
  'datacoding' : 'plain',
  'mclass' : 1,
  'scheduledDatetime' : None,
  'createdDatetime' : '2016-05-03 14:26:57',
  'recipients' : [
    'totalCount' : 1,
    'totalSentCount' : 1,
    'totalDeliveredCount' : 0,
    'totalDeliveryFailedCount' : 0,
    'items' : [
      {
        'recipient' : 31612345678,
        'status' : 'delivered',
        'statusDatetime' : '2016-05-03 14:26:57',
      },
    ]
  ]
}`,
  java: `MessageResponse {
  id = '802acbc0354e1d585223067b44890824',
    href = 'https://rest.messagebird.com/messages/802acbc0354e1d585223067b44890824',
    direction = 'mt',
    type = MsgType {
    value = 'sms'
    },
    originator = 'MessageBird',
    body = 'Hello world',
    reference = 'null',
    validity = null,
    gateway = 239,
    typeDetails = {},
    datacoding = DataCoding {
    value = 'unicode'
    },
    mclass = MClass {
    value = 1
    },
    scheduledDatetime = null,
    createdDatetime = Mon Feb 16 12: 33: 25 CET 2015,
    recipients = Recipients {
    totalSentCount = 1,
      totalDeliveredCount = 0,
      totalDeliveryFailedCount = 0,
      items = [Items {
      recipient = 31612345678, status = 'sent', statusDatetime = Mon Feb 16 12: 33: 25 CET 2015
      }]
    }
  }`,
  node: `{
  'id': 'e60a6f90453a19019c56ed6b20170831',
  'href': 'https://rest.messagebird.com/messages/e60a6f90453a19019c56ed6b20170831',
  'direction': 'mt',
  'type': 'sms',
  'originator': 'MessageBird',
  'body': 'This is a test message.',
  'reference': NULL,
  'validity': NULL,
  'gateway': 240,
  'typeDetails': {},
  'datacoding': 'plain',
  'mclass': 1,
  'scheduledDatetime': NULL,
  'createdDatetime': '2016-05-03T14:26:57+00:00',
  'recipients': {
    'totalCount': 1,
    'totalSentCount': 1,
    'totalDeliveredCount': 0,
    'totalDeliveryFailedCount': 0,
    'items': [
      {
        'recipient': 31612345678,
        'status': 'delivered',
        'statusDatetime': '2016-05-03T14:26:57+00:00',
      },
    ]
  }
}`,
};

export const LIST_MESSAGES = {
  curl: `curl -X GET https://rest.messagebird.com/messages \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM'`,
  php: `
  $MessageBird = new \MessageBird\Client('ACCESS_KEY');
  $MessageList = $MessageBird->messages->getList(array ('offset' => 100, 'limit' => 30));
  var_dump($MessageList);`,
  go: `
  import messagebird "github.com/messagebird/go-rest-api"

  func main() {
      client := messagebird.New("YOUR_ACCESS_KEY")
      messages, err := sms.List(client, &sms.ListParams{
        Originator: "Originator",
        Limit:      100,
      })
  }`,
  python: `
  client = messagebird.Client('YOUR_ACCESS_KEY')
  messages = client.message_list()`,
  java: `
  import com.messagebird.MessageBirdClient;
  import com.messagebird.MessageBirdService;
  import com.messagebird.MessageBirdServiceImpl;
  import com.messagebird.exceptions.GeneralException;
  import com.messagebird.exceptions.UnauthorizedException;
  import com.messagebird.objects.MessageList;
  
  public class ExampleListMessages {
      public static void main() {
          final MessageBirdService wsr = new MessageBirdServiceImpl("YOUR_ACCESS_KEY");
          final MessageBirdClient messageBirdClient = new MessageBirdClient(wsr);
          try {
              final MessageList messageList = messageBirdClient.listMessages(1, null);
              System.out.println(messageList.toString());
          } catch (UnauthorizedException | GeneralException exception) {
              if (exception.getErrors() != null) {
                  System.out.println(exception.getErrors().toString());
              }
              exception.printStackTrace();
          }
      }
  }`,
  node: `
  var messagebird = require('messagebird')('YOUR_ACCESS_KEY');
  messagebird.messages.list(function (err, response) {
    if (err) {
      return console.log(err);
    }
    console.log(response);
  });
  `,
  csharp: `
  Client client = Client.CreateDefault(YourAccessKey);
  var messages = client.ListMessages();
  `,
  ruby: `
  client = MessageBird::Client.new(ACCESS_KEY)
  messages = client.message_list()
  `,
};

export const LIST_RESPONSE = {
  curl: `{
    "offset": 0,
    "limit": 20,
    "count": 4,
    "totalCount": 4,
    "links": {
        "first": "https://rest.messagebird.com/messages/?offset=0",
        "previous": null,
        "next": null,
        "last": "https://rest.messagebird.com/messages/?offset=0"
    },
    "items": [
        {
            "id": "436d780b854749b4beca51623d9e2674",
            "href": "https://rest.messagebird.com/messages/436d780b854749b4beca51623d9e2674",
            "direction": "mt",
            "type": "sms",
            "originator": "YourName",
            "body": "This is a test message",
            "reference": null,
            "validity": null,
            "gateway": 10,
            "typeDetails": {},
            "datacoding": "plain",
            "mclass": 1,
            "scheduledDatetime": null,
            "createdDatetime": "2020-02-04T19:01:12+00:00",
            "recipients": {
                "totalCount": 1,
                "totalSentCount": 1,
                "totalDeliveredCount": 1,
                "totalDeliveryFailedCount": 0,
                "items": [
                    {
                        "recipient": 31612345678,
                        "originator": null,
                        "status": "delivered",
                        "statusReason": "successfully delivered",
                        "statusErrorCode": null,
                        "statusDatetime": "2020-02-04T19:01:12+00:00",
                        "recipientCountry": "Netherlands",
                        "recipientCountryPrefix": 31,
                        "recipientOperator": "KPN",
                        "mccmnc": "20408",
                        "mcc": "204",
                        "mnc": "08",
                        "messageLength": 22,
                        "messagePartCount": 1,
                        "price": {
                            "amount": 0.075,
                            "currency": "EUR"
                        }
                    }
                ]
            }
        }
    ]
}`,
  php: `
  object(MessageBird\Objects\BaseList)#128 (6) {
    ["limit"]=>
    int(30)
    ["offset"]=>
    int(0)
    ["count"]=>
    int(18)
    ["totalCount"]=>
    int(18)
    ["links"]=>
    object(stdClass)#55 (4) {
      ["first"]=>
      string(56) "https://rest.messagebird.com/messages/?offset=0&limit=30"
      ["previous"]=>
      NULL
      ["next"]=>
      NULL
      ["last"]=>
      string(56) "https://rest.messagebird.com/messages/?offset=0&limit=30"
    }
    ["items"]=>
    array(1) {
      [0]=>
      object(MessageBird\Objects\Message)#123 (16) {
        ["id":protected]=>
        string(32) "7f6526d794ac4cd1bdb262d86356d341"
        ["href":protected]=>
        string(70) "https://rest.messagebird.com/messages/7f6526d794ac4cd1bdb262d86356d341"
        ["direction"]=>
        string(2) "mt"
        ["type"]=>
        string(3) "sms"
        ["originator"]=>
        string(10) "Originator"
        ["body"]=>
        string(11) "Hello World"
        ["reference"]=>
        string(11) "MyReference"
        ["validity"]=>
        NULL
        ["gateway"]=>
        int(10)
        ["typeDetails"]=>
        object(stdClass)#125 (0) {
        }
        ["datacoding"]=>
        string(5) "plain"
        ["mclass"]=>
        int(1)
        ["scheduledDatetime"]=>
        NULL
        ["createdDatetime":protected]=>
        string(25) "2020-02-04T12:52:07+00:00"
        ["recipients"]=>
        object(stdClass)#126 (5) {
          ["totalCount"]=>
          int(1)
          ["totalSentCount"]=>
          int(1)
          ["totalDeliveredCount"]=>
          int(1)
          ["totalDeliveryFailedCount"]=>
          int(0)
          ["items"]=>
          array(1) {
            [0]=>
            object(MessageBird\Objects\Recipient)#147 (3) {
              ["recipient"]=>
              int(31616579155)
              ["status"]=>
              string(9) "delivered"
              ["statusDatetime"]=>
              string(25) "2020-02-04T12:52:08+00:00"
            }
          }
        }
        ["reportUrl"]=>
        NULL
      }
    }
  }`,
  go: `
  &sms.MessageList{
    Offset: 0,
    Limit: 100,
    Count: 1,
    TotalCount: 1,
    Links: {
      first: "https://rest.messagebird.com/messages/?offset=0\u0026limit=100\u0026originator=Originator",
      last: "https://rest.messagebird.com/messages/?offset=0\u0026limit=100\u0026originator=Originator",
      next: null,
      previous: null
    },
    Items: [
      &sms.Message{
        ID: "8ba2319c70dd4a619ecddf6127217c57",
        HRef: "https://rest.messagebird.com/messages/8ba2319c70dd4a619ecddf6127217c57",
        Direction: "mt",
        Type: "sms",
        Originator: "Originator",
        Body: "Hello World",
        Reference: "MyReference",
        Validity: null,
        Gateway: 10,
        TypeDetails: {},
        DataCoding: "plain",
        MClass: 1,
        ReportURL: "",
        ScheduledDatetime: null,
        CreatedDatetime: "2020-02-04T13:12:49Z",
        Recipients: {
          TotalCount: 1,
          TotalSentCount: 1,
          TotalDeliveredCount: 0,
          TotalDeliveryFailedCount: 1,
          Items: [
            {
              Recipient: 31612345678,
              Status: "delivery_failed",
              StatusDatetime: "2020-02-04T13:12:49Z"
            }
          ]
        }
      }
    ]
  }
  `,
  python: `
  {
    "limit": 20,
    "offset": 0,
    "count": 1,
    "totalCount": 1,
    "_links": {
        "first": "https://rest.messagebird.com/messages/?offset=0",
        "previous": null,
        "next": "https://rest.messagebird.com/messages/?offset=20",
        "last": "https://rest.messagebird.com/messages/?offset=20"
    },
    "_items": [
        {
            "id": "61308a10175f49cc925b9a8190782209",
            "href": "https://rest.messagebird.com/messages/61308a10175f49cc925b9a8190782209",
            "direction": "mt",
            "type": "sms",
            "originator": "MessageBird",
            "body": "This is a test message.",
            "reference": "Foobar",
            "validity": null,
            "gateway": 10,
            "typeDetails": {},
            "datacoding": "plain",
            "mclass": 1,
            "_scheduledDatetime": null,
            "_createdDatetime": "2020-02-05 13:24:23+00:00",
            "_recipients": {
                "totalCount": 1,
                "totalSentCount": 1,
                "totalDeliveredCount": 1,
                "totalDeliveryFailedCount": 0,
                "items": [
                    {
                        "recipient": 31616579155,
                        "status": "delivered",
                        "_statusDatetime": "2020-02-05 13:24:24+00:00"
                    }
                ]
            }
        },
    ]}`,
  java: `
  ListBase{
    offset=1,
    limit=20,
    totalCount=1,
    links=Links{
      first='https://rest.messagebird.com/messages/?offset=0',
      previous='https://rest.messagebird.com/messages/?offset=0',
      next='null',
      last='https://rest.messagebird.com/messages/?offset=0'
    },
    items=[
      MessageResponse{
        id='7f6526d794ac4cd1bdb262d86356d341',
        href='https://rest.messagebird.com/messages/7f6526d794ac4cd1bdb262d86356d341',
        direction='mt',
        type=MsgType{
          value='sms'
        },
        originator='Originator',
        body='Hello World',
        reference='MyReference',
        validity=null,
        gateway=10,
        typeDetails={
          
        },
        datacoding=DataCoding{
          value='plain'
        },
        mclass=MClass{
          value=1
        },
        scheduledDatetime=null,
        createdDatetime=TueFeb0413: 52: 07CET2020,
        recipients=Recipients{
          totalSentCount=1,
          totalDeliveredCount=1,
          totalDeliveryFailedCount=0,
          items=[
            Items{
              recipient=31616579155,
              status='delivered',
              statusDatetime=TueFeb0413: 52: 08CET2020
            }
          ]
        }
      }
    ]
  }`,
  node: `
  {
    offset: 0,
    limit: 20,
    count: 1,
    totalCount: 1,
    links: {
      first: 'https://rest.messagebird.com/messages/?offset=0&status=scheduled',
      previous: null,
      next: null,
      last: 'https://rest.messagebird.com/messages/?offset=0&status=scheduled'
    },
    items: [
      {
        id: 'e4097c6e351e4c70b50c2129f97e56f0',
        href: 'https://rest.messagebird.com/messages/e4097c6e351e4c70b50c2129f97e56f0',
        direction: 'mt',
        type: 'sms',
        originator: 'Originator',
        body: 'Hello World',
        reference: 'MyReference',
        validity: null,
        gateway: 10,
        typeDetails: {},
        datacoding: 'plain',
        mclass: 1,
        scheduledDatetime: '2020-02-08T10:56:40+00:00',
        createdDatetime: '2020-02-06T10:56:40+00:00',
        recipients: [Object]
      }
    ]
  }`,
  csharp: `
  {MessageBird.Objects.MessageList}
  Status: ""
  Limit: 20
  Offset: 0
  Count: 1
  TotalCount: 1
  Links: { }
    {MessageBird.Objects.Links}
      First: "https://rest.messagebird.com/messages/?offset=0"
      Previous: null
      Next: null
      Last: "https://rest.messagebird.com/messages/?offset=0"
  Items: ...
    {MessageBird.Objects.Message}
      Gateway: 10
      Id: "436d780b854749b4beca51623d9e2674"
      Href: "https://rest.messagebird.com/messages/436d780b854749b4beca51623d9e2674"
      Direction: MobileTerminated
      Type: Sms
      Originator: "YourName"
      Body: "This is a test message"
      Reference: null
      ReportUrl: null
      Validity: null
      TypeDetails: ...
      Encoding: Plain
      Class: Normal
      Scheduled: null
      Created: 02/04/2020 20:01:12
      Recipients: { }
        {MessageBird.Objects.Recipients}
          SerializeMsisdnsOnly: True
          TotalCount: 1
          TotalSentCount: 1
          TotalDeliveredCount: 0
          TotalDeliveryFailedCount: 1
          Items: ...
            {MessageBird.Objects.Recipient}
              Msisdn: 31612345678
              Status: DeliveryFailed
              StatusDatetime: 02/04/2020 20:01:12`,
  ruby: `
<List:0x00007fd9680b4a98 @type=MessageBird::Message,
 @offset=0,
 @limit=20,
 @count=20,
 @total_count=1,
 @links={"first"=>"https://rest.messagebird.com/messages/?offset=0",
 "previous"=>nil,
 "next"=>"https://rest.messagebird.com/messages/?offset=20",
 "last"=>"https://rest.messagebird.com/messages/?offset=20"},
 @items=[
    #<MessageBird::Message:0x00007fd967859a38 @id="f9ec1b91cdc94cba83a201289444091d",
    @href="https://rest.messagebird.com/messages/f9ec1b91cdc94cba83a201289444091d",
    @direction="mt",
    @type="sms",
    @originator="MessageBird",
    @body="This is a test message.",
    @reference=nil,
    @validity=nil,
    @gateway=10,
    @datacoding="plain",
    @mclass=1,
    @created_datetime=2020-02-05 07:46:04 +0000,
    @recipients={"totalCount"=>1,
    "totalSentCount"=>1,
    "totalDeliveredCount"=>0,
    "totalDeliveryFailedCount"=>1,
    "items"=>[#<MessageBird::Recipient:0x00007fd96981e198 @recipient=31612345678,
    @status="delivery_failed">]}>
 ]>
#<List:0x00007fd9680b4a98>
  `,
};

export const LIST_URI = {
  bash: 'https://rest.messagebird.com/messages?status=scheduled',
};

export const LIST_SCHEDULED_MESSAGES = {
  bash:
    "curl -X GET https://rest.messagebird.com/messages?status=scheduled \\\n \
    -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM'",
  php: `
  $MessageBird = new \MessageBird\Client('ACCESS_KEY');
  $MessageList = $MessageBird->messages->getList(array ('offset' => 0, 'limit' => 30, 'status' => 'scheduled'));
  var_dump($MessageList);
  `,
  go: `
  import messagebird "github.com/messagebird/go-rest-api"

  func main() {
      client := messagebird.New("YOUR_ACCESS_KEY")
      listMessages, err := List(client, &ListParams{
        Status: "scheduled",
      })
  }`,
  node: `
  var messagebird = require('messagebird')('YOUR_ACCESS_KEY');
  messagebird.messages.list({status: "scheduled"}, function (err, response) {
    if (err) {
      return console.log(err);
    }
    console.log(response);
  });`,
  csharp: `
  Client client = Client.CreateDefault(YourAccessKey);
  var messages = client.ListMessages("scheduled");
  `,
  python: `
  client = messagebird.Client('YOUR_ACCESS_KEY')
  messages = client.message_list(status="scheduled")`,
  ruby: `
  client = MessageBird::Client.new(ACCESS_KEY)
  messages = client.message_list(status: 'scheduled')`,
  java: `
  import com.messagebird.MessageBirdClient;
  import com.messagebird.MessageBirdService;
  import com.messagebird.MessageBirdServiceImpl;
  import java.util.LinkedHashMap;
  import java.util.Map;
  
  public class ExampleListScheduledMessages {
  
      public static void main() {
          final MessageBirdService wsr = new MessageBirdServiceImpl("YOUR_ACCESS_KEY");
          final MessageBirdClient messageBirdClient = new MessageBirdClient(wsr);

          // Create filters
          Map<String, Object> filters = new LinkedHashMap<>();
          filters.put("status", "scheduled");

          try {
            final MessageList messages = messageBirdClient.listMessagesFiltered(null, null, filters);
          } catch (UnauthorizedException | GeneralException exception) {
            if (exception.getErrors() != null) {
                System.out.println(exception.getErrors().toString());
            }
            exception.printStackTrace();
          }
      }
  }
  `,
};

export const LIST_SCHEDULED_MESSAGES_RESPONSE = {
  bash: `
  {
    "offset":0,
    "limit":20,
    "count":1,
    "totalCount":1,
    "links":{
       "first":"https://rest.messagebird.com/messages/?offset=0&status=scheduled",
       "previous":null,
       "next":null,
       "last":"https://rest.messagebird.com/messages/?offset=0&status=scheduled"
    },
    "items":[
       {
          "id":"",
          "href":"https://rest.messagebird.com/messages/",
          "direction":"mt",
          "type":"sms",
          "originator":"Test",
          "body":"test mesage",
          "reference":null,
          "validity":null,
          "gateway":10,
          "typeDetails":{},
          "datacoding":"plain",
          "mclass":1,
          "scheduledDatetime":"2020-04-04T13:14:00+00:00",
          "createdDatetime":"2020-03-04T13:06:20+00:00",
          "recipients":{
             "totalCount":0,
             "totalSentCount":0,
             "totalDeliveredCount":0,
             "totalDeliveryFailedCount":0,
             "items":[]
          }
       }
    ]
  }
  `,
  php: `
  object(MessageBirdObjectsBaseList)#128 (6) {
    ["limit"]=>
    int(30)
    ["offset"]=>
    int(0)
    ["count"]=>
    int(1)
    ["totalCount"]=>
    int(1)
    ["links"]=>
    object(stdClass)#55 (4) {
      ["first"]=>
      string(73) "https://rest.messagebird.com/messages/?offset=0&status=scheduled&limit=30"
      ["previous"]=>
      NULL
      ["next"]=>
      NULL
      ["last"]=>
      string(73) "https://rest.messagebird.com/messages/?offset=0&status=scheduled&limit=30"
    }
    ["items"]=>
    array(1) {
      [0]=>
      object(MessageBirdObjectsMessage)#123 (16) {
        ["id":protected]=>
        string(0) ""
        ["href":protected]=>
        string(38) "https://rest.messagebird.com/messages/"
        ["direction"]=>
        string(2) "mt"
        ["type"]=>
        string(3) "sms"
        ["originator"]=>
        string(10) "Originator"
        ["body"]=>
        string(11) "Hello World"
        ["reference"]=>
        NULL
        ["validity"]=>
        NULL
        ["gateway"]=>
        int(10)
        ["typeDetails"]=>
        object(stdClass)#125 (0) {
        }
        ["datacoding"]=>
        string(5) "plain"
        ["mclass"]=>
        int(1)
        ["scheduledDatetime"]=>
        string(25) "2020-04-04T13:14:00+00:00"
        ["createdDatetime":protected]=>
        string(25) "2020-03-04T13:06:20+00:00"
        ["recipients"]=>
        object(stdClass)#126 (5) {
          ["totalCount"]=>
          int(0)
          ["totalSentCount"]=>
          int(0)
          ["totalDeliveredCount"]=>
          int(0)
          ["totalDeliveryFailedCount"]=>
          int(0)
          ["items"]=>
          array(0) {
          }
        }
        ["reportUrl"]=>
        NULL
      }
    }
  }
  `,
  node: `
  {
    offset: 0,
    limit: 20,
    count: 1,
    totalCount: 1,
    links: {
      first: 'https://rest.messagebird.com/messages/?offset=0&status=scheduled',
      previous: null,
      next: null,
      last: 'https://rest.messagebird.com/messages/?offset=0&status=scheduled'
    },
    items: [
      {
        id: '',
        href: 'https://rest.messagebird.com/messages/',
        direction: 'mt',
        type: 'sms',
        originator: 'Originator',
        body: 'Hello World',
        reference: null,
        validity: null,
        gateway: 10,
        typeDetails: {},
        datacoding: 'plain',
        mclass: 1,
        scheduledDatetime: '2020-04-04T13:14:00+00:00"',
        createdDatetime: '2020-04-04T13:06:20+00:00',
        recipients: [Object]
      }
    ]
  }
  `,
  csharp: `
  {MessageBird.Objects.MessageList}
  Status: ""
  Limit: 20
  Offset: 0
  Count: 1
  TotalCount: 1
  Links: { }
    {MessageBird.Objects.Links}
      First: "https://rest.messagebird.com/messages/?offset=0&status=scheduled"
      Previous: null
      Next: null
      Last: "https://rest.messagebird.com/messages/?offset=0&status=scheduled"
  Items: ...
    {MessageBird.Objects.Message}
      Gateway: 10
      Id: ""
      Href: "https://rest.messagebird.com/messages/"
      Direction: MobileTerminated
      Type: Sms
      Originator: "Originator"
      Body: "This is a test message"
      Reference: null
      ReportUrl: null
      Validity: null
      TypeDetails: ...
      Encoding: Plain
      Class: Normal
      Scheduled: 04/04/2020 13:14:00
      Created: 04/04/2020 13:06:20
      Recipients: { }
        {MessageBird.Objects.Recipients}
          SerializeMsisdnsOnly: True
          TotalCount: 0
          TotalSentCount: 0
          TotalDeliveredCount: 0
          TotalDeliveryFailedCount: 0
          Items: ...
  `,
  java: `
  ListBase{
    offset=0,
    limit=20,
    totalCount=1,
    links=Links{
      first='https://rest.messagebird.com/messages/?offset=0&status=scheduled',
      previous=null,
      next='null',
      last='https://rest.messagebird.com/messages/?offset=0&status=scheduled'
    },
    items=[
      MessageResponse{
        id='',
        href='https://rest.messagebird.com/messages/',
        direction='mt',
        type=MsgType{
          value='sms'
        },
        originator='Originator',
        body='Hello World',
        reference='null',
        validity=null,
        gateway=10,
        typeDetails={
          
        },
        datacoding=DataCoding{
          value='plain'
        },
        mclass=MClass{
          value=1
        },
        scheduledDatetime=TueFeb0413: 54: 07CET2020,
        createdDatetime=TueFeb0413: 52: 07CET2020,
        recipients=Recipients{
          totalSentCount=0,
          totalDeliveredCount=0,
          totalDeliveryFailedCount=0,
          items=[]
        }
      }
    ]
  }
  `,
  ruby: `
  <List:0x00007fd9680b4a98 @type=MessageBird::Message,
   @offset=0,
   @limit=20,
   @count=1,
   @total_count=1,
   @links={"first"=>"https://rest.messagebird.com/messages/?offset=0&status=scheduled",
   "previous"=>nil,
   "next"=>"nil,
   "last"=>"https://rest.messagebird.com/messages/?offset=0&status=scheduled"},
   @items=[
      #<MessageBird::Message:0x00007fd967859a38 @id="",
      @href="https://rest.messagebird.com/messages/",
      @direction="mt",
      @type="sms",
      @originator="Originator",
      @body="This is a test message.",
      @reference=nil,
      @validity=nil,
      @gateway=10,
      @datacoding="plain",
      @mclass=1,
      @scheduled_datetime=2020-04-04 13:14:00 +0000,
      @created_datetime=2020-04-04 13:06:20 +0000,
      @recipients={"totalCount"=>0,
      "totalSentCount"=>0,
      "totalDeliveredCount"=>0,
      "totalDeliveryFailedCount"=>0,
      "items"=>[]}>
   ]>
  #<List:0x00007fd9680b4a98>
    `,
  go: `
  &sms.MessageList{
    Offset: 0,
    Limit: 20,
    Count: 1,
    TotalCount: 1,
    Links: {
      first: "https://rest.messagebird.com/messages/?offset=0&status=scheduled",
      last: "https://rest.messagebird.com/messages/?offset=0&status=scheduled",
      next: null,
      previous: null
    },
    Items: [
      &sms.Message{
        ID: "",
        HRef: "https://rest.messagebird.com/messages/",
        Direction: "mt",
        Type: "sms",
        Originator: "Originator",
        Body: "Hello World",
        Reference: null,
        Validity: null,
        Gateway: 10,
        TypeDetails: {},
        DataCoding: "plain",
        MClass: 1,
        ReportURL: "",
        ScheduledDatetime: "2020-04-04T13:14:00Z",
        CreatedDatetime: "2020-04-04T13:06:20Z",
        Recipients: {
          TotalCount: 0,
          TotalSentCount: 0,
          TotalDeliveredCount: 0,
          TotalDeliveryFailedCount: 0,
          Items: []
        }
      }
    ]
  }
  `,
  python: `
  {
    "limit": 20,
    "offset": 0,
    "count": 1,
    "totalCount": 1,
    "_links": {
        "first": "https://rest.messagebird.com/messages/?offset=0&status=scheduled",
        "previous": null,
        "next": null,
        "last": "https://rest.messagebird.com/messages/?offset=0&status=scheduled"
    },
    "_items": [
        {
            "id": "",
            "href": "https://rest.messagebird.com/messages/",
            "direction": "mt",
            "type": "sms",
            "originator": "MessageBird",
            "body": "This is a test message.",
            "reference": null,
            "validity": null,
            "gateway": 10,
            "typeDetails": {},
            "datacoding": "plain",
            "mclass": 1,
            "_scheduledDatetime": "2020-04-04 13:14:00+00:00",
            "_createdDatetime": "2020-04-04 13:06:20+00:00",
            "_recipients": {
                "totalCount": 0,
                "totalSentCount": 0,
                "totalDeliveredCount": 0,
                "totalDeliveryFailedCount": 0,
                "items": []
            }
        },
    ]}`,
};

export const SMPP1 = {
  bash:
    'id:xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx sub:001 dlvrd:NNN submit date:YYMMDDHHMMSS done date:YYMMDDHHMMSS stat:STATUS err:NNN text:',
};

export const SMPP2 = {
  bash:
    'id:fecf8e26-eb1d-46e7-5bdf-e509c058f7b7 sub:001 dlvrd:001 submit date:141029064451 done date:141029064502 stat:DELIVRD err:000 text:',
};

export const STATUSSMS_REQUEST = {
  bash:
    'GET http://your-own.url/script?id=efa6405d518d4c0c88cce11f7db775fb&reference=the-customers-reference&recipient=31612345678&status=delivered&statusReason=successfully%20delivered&statusDatetime=2017-09-01T10:00:05+00:00&datacoding=plain&mccmnc=20409&messageLength=44&messagePartCount=1&ported=0&price[amount]=0.75&price[currency]=EUR',
};

export const STATUSSMS_RESPONSE = {
  bash: '200 OK',
};
