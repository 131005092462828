import { Flex, Box } from '@rebass/grid';
import styled, { css } from 'styled-components';
import { CODE_COLORS } from '../../constants';

export const CodeWrap = styled.div`
  padding: 24px;
  border-radius: 8px;
  background: #001240;
  margin-bottom: 40px;
  code[class*='language-'],
  pre[class*='language-'] {
    font-family: Consolas, Menlo, Monaco, 'Andale Mono WT', 'Andale Mono',
      'Lucida Console', 'Lucida Sans Typewriter', 'DejaVu Sans Mono',
      'Bitstream Vera Sans Mono', 'Liberation Mono', 'Nimbus Mono L',
      'Courier New', Courier, monospace;
    font-size: 14px;
    line-height: 24px;
    direction: ltr;
    text-align: left;
    white-space: pre-wrap;
    word-spacing: normal;
    word-break: break-all;

    -moz-tab-size: 2;
    -o-tab-size: 2;
    tab-size: 2;

    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
    color: #96c4ff;
    background: #001240;
    border: 0;
    margin: 0;
    padding: 0;
    counter-reset: line;
  }

  pre[class*='language-']::-moz-selection,
  pre[class*='language-'] ::-moz-selection,
  code[class*='language-']::-moz-selection,
  code[class*='language-'] ::-moz-selection {
    text-shadow: none;
  }

  pre[class*='language-']::selection,
  pre[class*='language-'] ::selection,
  code[class*='language-']::selection,
  code[class*='language-'] ::selection {
    text-shadow: none;
  }

  :not(pre) > code[class*='language-'] {
    border-radius: 8px;
  }
  .token.plain {
    display: inline-block;
  }

  .token.comment,
  .token.prolog,
  .token.doctype,
  .token.cdata {
    color: ${CODE_COLORS.LIGHT_GREY};
  }

  .token.punctuation {
    color: ${CODE_COLORS.LIGHT_GREY};
  }

  .token.namespace {
    opacity: 0.7;
  }

  .token.class-name {
    color: #fff;
  }

  .token.tag,
  .token.number {
    color: #ecbd40;
  }

  .token.operator {
    color: ${CODE_COLORS.WHITE};
  }

  .token.property {
    color: #ecbd40;
  }

  .token.function {
    color: ${CODE_COLORS.PEACH};
  }

  .token.tag-id,
  .token.selector,
  .token.atrule-id {
    color: #eeebff;
  }

  code.language-javascript,
  .token.attr-name {
    color: #c4b9fe;
  }

  code.language-css,
  code.language-scss,
  .token.boolean,
  .token.string,
  .token.entity,
  .token.url,
  .language-css .token.string,
  .language-scss .token.string,
  .style .token.string,
  .token.attr-value,
  .token.keyword,
  .token.control,
  .token.directive,
  .token.unit,
  .token.statement,
  .token.regex,
  .token.atrule {
    color: ${CODE_COLORS.GREEN};
  }

  .token.placeholder,
  .token.variable {
    color: ${CODE_COLORS.YELLOW};
  }

  .token.deleted {
    text-decoration: line-through;
  }

  .token.inserted {
    border-bottom: 1px dotted #eeebff;
    text-decoration: none;
  }

  .token.italic {
    font-style: italic;
  }

  .token.important,
  .token.bold {
    font-weight: bold;
  }

  .token.important {
    color: #c4b9fe;
  }

  .token.entity {
    cursor: help;
  }

  pre > code.highlight {
    outline: 0.4em solid #8a75f5;
    outline-offset: 0.4em;
  }

  .line-numbers .line-numbers-rows {
    border-right-color: #2c2937;
  }

  .line-numbers-rows > span:before {
    color: #3c3949;
  }

  .line-highlight {
    background: rgba(224, 145, 66, 0.2);
    background: -webkit-linear-gradient(
      left,
      rgba(224, 145, 66, 0.2) 70%,
      rgba(224, 145, 66, 0)
    );
    background: linear-gradient(
      to right,
      rgba(224, 145, 66, 0.2) 70%,
      rgba(224, 145, 66, 0)
    );
  }
  .token-line {
    counter-increment: line;
    font-size: 14px;
    line-height: 24px;
    padding-left: 30px;
  }
  .token-line:before {
    content: counter(line);
    color: ${CODE_COLORS.GREY};
    position: absolute;
    margin-left: -30px;
  }
`;

export const activeTabStyle = css`
  cursor: pointer;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.333);
  border-radius: 3px;
`;

export const Tab = styled(Box)`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #9aa8bd;
  padding: 8px;
  border-radius: 3px;
  margin-bottom: 5px;
  &:not(:first-child) {
    margin-left: 8px;
  }

  ${(props) => props.active && activeTabStyle} :hover {
    ${activeTabStyle}
  }
`;

export const HorScroll = styled(Flex)`
  overflow-y: auto;
  &::-webkit-scrollbar {
    height: 10px;
    background-color: rgba(255, 255, 255, 0.15);
    border-radius: 999em;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 999em;
  }
`;
