import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Voice Calling`}</h1>
    <p>{`Messagebird’s Voice Calling API enables VOIP features for your web application to make, receive, and monitor voice calls from a MessageBird voice-enabled virtual mobile number to and from any country around the world through a REST API. `}</p>
    <p>{`The Voice Calling API uses `}<inlineCode parentName="p">{`HTTP verbs`}</inlineCode>{` and a `}<inlineCode parentName="p">{`RESTful`}</inlineCode>{` endpoint structure with an access key that is used as the API Authorization. Request and response payloads are formatted as JSON using UTF-8 encoding and URL encoded values.`}</p>
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'Make a voice call',
      to: 'quickstarts/voice-calling/make-an-outbound-call-curl/'
    }, {
      name: 'Receive voice calls',
      to: '/quickstarts/voice-calling/receive-calls'
    }, {
      name: 'Voice Calling API Reference',
      to: '/api/voice-calling/'
    }, {
      name: 'Structure of a Call',
      to: '/quickstarts/structure-of-a-call'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      