export const SEND_SMS = `var messagebird = require('messagebird')('test_gshuPaZoeEG6ovbc8M79w0QyM');

messagebird.messages.create({
    originator : '<YOUR-MOBILE-NUMBER>',
    recipients : [ '<YOUR-MOBILE-NUMBER>' ],
    body : 'Hi! This is your first message'
},
function (err, response) {
    if (err) {
    console.log("ERROR:");
    console.log(err);
} else {
    console.log("SUCCESS:");
    console.log(response);
        }
});
  `;

export const SEND_VERIFY = `var messagebird = require('messagebird')('test_gshuPaZoeEG6ovbc8M79w0QyM');

  var params = {
  originator: 'Your-Name'
  };
  messagebird.verify.create('<YOUR-MOBILE-NUMBER>', params, function (err, response) {
  if (err) {
      return console.log(err);
      }
      console.log(response);
  });
    `;

export const SEND_TTS = `var messagebird = require('messagebird')('test_gshuPaZoeEG6ovbc8M79w0QyM');

var params = {
  'recipients': [
    '<YOUR-MOBILE-NUMBER>'
  ],
  'body': 'Hi! This is your first text to speech message!',
  'language': 'en-gb',
  'voice': 'male'
};
messagebird.voice_messages.create(params, function (err, data) {
  if (err) {
    return console.log(err);
  }
    console.log(data);
});`;
