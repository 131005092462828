import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Setting SMS notifications with MessageBird`}</h1>
    <h3>{`⏱ 15 min build time || `}<a parentName="h3" {...{
        "href": "https://github.com/messagebirdguides/notifications-guide-python"
      }}>{`Download the Code`}</a></h3>
    <h2>{`Why build SMS order notifications?`}</h2>
    <p>{`In this MessageBird Developer Tutorial we'll show you how to keep your customers up to date with this easy-to-build runnable order notification application powered by the `}<a parentName="p" {...{
        "href": "/api/sms-messaging"
      }}>{`MessageBird SMS Messaging API`}</a>{`.
Have you ever ordered home delivery to find yourself wondering whether your order was received correctly and how long it will take to arrive? Some experiences are seamless and others... not so much.`}</p>
    <p>{`For on-demand industries such as food delivery, ride-sharing and logistics, excellent customer service during the ordering process is essential. One easy way to stand out from the crowd is providing proactive communication to keep your customers in the loop about the status of their orders. Regardless of whether your customer is waiting for a package delivery or growing hangry (a combination of hungry and angry) awaiting their food delivery, sending timely SMS order notifications is a great strategy to create a seamless user experience.`}</p>
    <p>{`The `}<a parentName="p" {...{
        "href": "/api/sms-messaging"
      }}>{`MessageBird SMS Messaging API`}</a>{` provides an easy way to fully automate and integrate a notifications application into your order handling software. Busy employees can trigger the notifications application with the push of a single button. No more confused hangry customers and great user experience, just like that!`}</p>
    <h2>{`Getting started`}</h2>
    <p>{`The application is a prototype order management system build in Python for our fictitious food delivery company, `}<em parentName="p">{`Birdie NomNom Foods`}</em>{`.`}</p>
    <p>{`Birdie NomNom Foods have set up the following workflow:`}</p>
    <ul>
      <li parentName="ul">{`New incoming orders are in a `}<strong parentName="li">{`pending`}</strong>{` state.`}</li>
      <li parentName="ul">{`Once the kitchen starts preparing an order, it moves to the `}<strong parentName="li">{`confirmed`}</strong>{` state. A message is sent to the customer to inform them about this.`}</li>
      <li parentName="ul">{`When the food is made and handed over to the delivery driver, staff marks the order as `}<strong parentName="li">{`delivered`}</strong>{`. A message is sent to the customer to let them know it will arrive briefly.`}</li>
      <li parentName="ul">{`If preparation takes longer than expected, it can be moved to a `}<em parentName="li">{`delayed`}{`_`}</em>{`state. A message is sent to the customer asking them to hang on just a little while longer.`}</li>
    </ul>
    <p>{`Thanks to this workflow, Birdie NomNom Foods saves time, money, and energy that would otherwise be spent answering `}<em parentName="p">{`"Where's my order?"`}</em>{` calls.`}</p>
    <p><strong parentName="p">{`Pro-tip:`}</strong>{` Follow this tutorial to build the whole application from scratch or, if you want to see it in action right away, you can download, clone or fork the sample application from the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/notifications-guide-python"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`.`}</p>
    <h3>{`Structure of your application`}</h3>
    <p>{`We'll be building our single-page web application with:`}</p>
    <ul>
      <li parentName="ul">{`Python 2.7`}</li>
      <li parentName="ul">{`The `}<a parentName="li" {...{
          "href": "http://flask.pocoo.org/"
        }}>{`Flask framework`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/messagebird/python-rest-api"
        }}>{`MessageBird's REST API package for Python`}</a>{`.`}</li>
    </ul>
    <p>{`We'll need the following components for our application to be viable:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`A data source`}</strong>{`: This should be a database or a REST endpoint containing information about our customers and their orders. For this tutorial, we'll be using a simple SQLite database, since SQLite comes with Python. You can easily modify the sample code to use other SQL databases.`}</li>
      <li parentName="ul"><strong parentName="li">{`A web interface to manage orders`}</strong>{`: The web interface will display information on customer orders, allow us to change the order status, and send SMS messages to customers. We will use the `}<a parentName="li" {...{
          "href": "http://flask.pocoo.org/"
        }}>{`Flask framework`}</a>{` to build this web interface.`}</li>
      <li parentName="ul"><strong parentName="li">{`A route handler that contains message sending logic`}</strong>{`: This handler will contain logic that:`}
        <ol parentName="li">
          <li parentName="ol">{`Checks our order against the orders database.`}</li>
          <li parentName="ol">{`Updates the database when we update order status.`}</li>
          <li parentName="ol">{`When we click a button, sends an SMS notification to the customer whose order is updated.`}</li>
        </ol>
      </li>
    </ul>
    <h3>{`Project setup`}</h3>
    <p>{`Let's start by creating a folder for your application. In this folder, you can create the following subfolder to contain our Jinja2 template for the web page:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`templates`}</inlineCode></li>
    </ul>
    <h4>{`Packages`}</h4>
    <p>{`We'll use a number of packages from the standard Flask library, in addition to the `}<a parentName="p" {...{
        "href": "http://flask-sqlalchemy.pocoo.org/2.3/"
      }}>{`Flask-SQLAlchemy`}</a>{` extension. The latter allows us to easily configure database connections and to update or query tables in the database.`}</p>
    <p>{`Let's install the required Flask packages as follows using the Python package manager `}<inlineCode parentName="p">{`pip`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`pip install flask
pip install Flask-SQLAlchemy
`}</code></pre>
    <p>{`Next, let’s also install `}<a parentName="p" {...{
        "href": "https://github.com/messagebird/python-rest-api"
      }}>{`MessageBird's REST API package for Python`}</a>{` using `}<inlineCode parentName="p">{`pip`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`pip install messagebird
`}</code></pre>
    <h3>{`Main file`}</h3>
    <p>{`In your application folder, let's create a file named `}<inlineCode parentName="p">{`app.py`}</inlineCode>{`. This file will contain most of the application logic. We'll start by importing the packages and modules we need by adding this to the start of the file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`from flask import Flask, render_template, request, flash, url_for
from flask_sqlalchemy import SQLAlchemy
import os
import messagebird
`}</code></pre>
    <h3>{`Create your API Key 🔑`}</h3>
    <p>{`To start making API calls, we need to generate an access key. MessageBird provides keys in `}<em parentName="p">{`live`}</em>{` and `}<em parentName="p">{`test`}</em>{` modes. For this tutorial you’ll need to use a live key; otherwise, you won’t be able to test the complete flow. You can read more about `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/articles/360000670709-What-is-the-difference-between-a-live-key-and-a-test-key-"
      }}>{`the difference between test and live API keys`}</a>{` in our Help Center.`}</p>
    <p>{`Let's create your live API access key. First, go to the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/user/index"
      }}>{`MessageBird Dashboard`}</a>{`; if you have already created an API key it will be shown right there. If you don’t see any key on the Dashboard or if you're unsure whether this key is in `}<em parentName="p">{`live`}</em>{` mode, go to the `}<em parentName="p">{`Developers`}</em>{` section in the MessageBird Dashboard and open the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`API access (REST) tab`}</a>{`. There you can create new API keys and manage your existing ones.`}</p>
    <p>{`If you are having any issues creating your API key, please reach out to `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we’ll make sure to help you out.`}</p>
    <p><strong parentName="p">{`Pro-tip:`}</strong>{` Hardcoding your credentials in the code is a risky practice that should never be used in production applications. For this reason, we will be saving our API key together with other configuration parameters in a separate configuration file, `}<inlineCode parentName="p">{`config_file.cfg`}</inlineCode>{`. Another method, also recommended by the `}<a parentName="p" {...{
        "href": "https://12factor.net/"
      }}>{`Twelve-Factor App Definition`}</a>{`, is to use environment variables. The following is an example of what this configuration file might look like:`}</p>
    <pre><code parentName="pre" {...{}}>{`SECRET_KEY='Put your key here'
DEBUG=True
`}</code></pre>
    <h3>{`Load configuration variables`}</h3>
    <p>{`After you have imported the appropriate packages and specified your API key, you can initialize the app and load configuration variables from the configuration file. Add the following to `}<inlineCode parentName="p">{`app.py`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`#Configure the app as follows.
app = Flask(__name__)
app.config.from_pyfile('config_file.cfg')
`}</code></pre>
    <h2>{`Initialize the MessageBird client`}</h2>
    <p>{`Now that the application has been configured, we can start the MessageBird client:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`#start MessageBird client
client = messagebird.Client(app.config['SECRET_KEY'])
`}</code></pre>
    <h2>{`Setting up your data source`}</h2>
    <p>{`We'll use an SQLite database to store our customer data. Flask's SQLAlchemy extension will help us interact with the database.`}</p>
    <p>{`After initializing the MessageBird client, add the following code to set up the database:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`# set up database and configure app to use database file
project_dir = os.path.dirname(os.path.abspath(__file__)) #gets the project directory
database_file = "sqlite:///{}".format(os.path.join(project_dir, "orderDatabase.db"))
app.config["SQLALCHEMY_DATABASE_URI"] = database_file
db = SQLAlchemy(app)

# Create our database model
class Order(db.Model):
   __tablename__ = "orders"
   id = db.Column(db.String(64), primary_key=True)
   name = db.Column(db.String(64))
   phone = db.Column(db.String(128))
   items = db.Column(db.String(128))
   status = db.Column(db.String(64))
   def __repr__(self):
       return '<Customer {}>'.format(self.name)

# create all tables and save this change to database
db.create_all()

# Make sure table is already empty before inserting any data.
db.session.query(Order).delete()

#commit changes to database
db.session.commit()
`}</code></pre>
    <p>{`We've created a placeholder database in the form of a list of orders, each containing `}<em parentName="p">{`id`}</em>{`,`}<em parentName="p">{`name`}</em>{`, `}<em parentName="p">{`phone`}</em>{`, `}<em parentName="p">{`items`}</em>{`, and `}<em parentName="p">{`status`}</em>{` fields. Next, we insert some sample orders into the table:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`Order1 = Order(id="c2972b5b4eef349fb1e5cc3e3150a2b6", name="Hannah Hungry", phone="+14127267380", items="1 x Hipster Burger, Fries", status="pending")
Order2 = Order(id="1b992e39dc55f0c79dbe613b3ad02f29", name="Mike Madeater", phone="+319876543211", items="1 x Chef Special Mozzarella Pizza", status="delayed")
Order3 = Order(id="81dc9bdb52d04dc20036dbd8313ed055", name="Don Cheetos", phone="+319876543212", items="1 x Awesome Cheese Platter", status="confirmed")
db.session.add_all([Order1, Order2, Order3])
db.session.commit()
`}</code></pre>
    <h2>{`Dealing with our routes`}</h2>
    <p>{`Now we'll define our routes for displaying order status, updating order status, and sending notifications.`}</p>
    <p>{`We'll be using two types of forms in our web application. One type of form lets you select a new order status for an existing order and update the status; the other type lets you send a notification about an order's status to the customer associated with that order.`}</p>
    <h3>{`Define routes`}</h3>
    <p>{`We'll have the following routes:`}</p>
    <ul>
      <li parentName="ul">{`One route displays the table of orders with their respective forms`}</li>
      <li parentName="ul">{`The second route handles the POST request for updating an order status`}</li>
      <li parentName="ul">{`The third route handles the POST request for notifying the customer of an order status.`}</li>
    </ul>
    <p>{`In `}<inlineCode parentName="p">{`app.py`}</inlineCode>{`, we add the first route as follows:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`# Page containing table of orders, order update form, and customer notification button.
@app.route('/')
def index():
   #load order data from database
   table = Order.query.all()
   return render_template('index.html', table=table)
`}</code></pre>
    <h3>{`Writing our template`}</h3>
    <p>{`The whole application is going to be on one page, so we can use one Jinja2 template for it.`}</p>
    <p>{`For `}<inlineCode parentName="p">{`index.html`}</inlineCode>{`, write the following code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<!DOCTYPE html PUBLIC "-//IETF//DTD HTML//EN">
<html>
  <head>
    <title></title>
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css" />
  </head>

  <body>
    <!--If password or username is incorrect, flash error message at top -->
    <!--of page-->
    {% with messages = get_flashed_messages() %} {% if messages %}
    <ul class="flashes">
      {% for message in messages %}
      <li>{{ message }}</li>
      {% endfor %}
    </ul>
    {% endif %} {% endwith %}

    <div class="container">
      <h1>Current Orders</h1>
      <table class="table">
        <thead>
          <th>Name</th>
          <th>Phone</th>
          <th>Items</th>
          <th>Status</th>
          <th>Update status</th>
        </thead>
        <tbody>
          {% for row in table %}
          <tr>
            <td>{{ row.name }}</td>
            <td>{{ row.phone }}</td>
            <td>{{ row.items }}</td>
            <td>{{ row.status }}</td>
            <td>
              <form action="{{ url_for('update') }}" method="post">
                <input type="hidden" value="{{ row.id }}" name="id" />
                <select name="orderStatus" required>
                  <option value="" selected="selected">--Order status--</option>
                  <option value="pending">Pending</option>
                  <option value="delayed">Delayed</option>
                  <option value="confirmed">Confirmed</option>
                  <option value="delivered">Delivered</option>
                </select>
                <input type="submit" value="Update" />
              </form>
              <form action="{{ url_for('notify') }}" method="post">
                <input type="hidden" value="{{ row.id }}" name="notify_id" />
                <button type="submit" name="sendMessageTo" value="{{ row.id }}">Notify Customer</button>
              </form>
            </td>
          </tr>
          {% endfor %}
        </tbody>
      </table>
    </div>
  </body>
</html>
`}</code></pre>
    <p>{`To improve the display of our table, we've used the popular `}<a parentName="p" {...{
        "href": "https://getbootstrap.com/"
      }}>{`Bootstrap`}</a>{` CSS stylesheet and implemented its `}<inlineCode parentName="p">{`table`}</inlineCode>{` `}<a parentName="p" {...{
        "href": "https://getbootstrap.com/docs/4.0/content/tables/"
      }}>{`style`}</a>{` to our table of orders.`}</p>
    <p>{`The top part of our page displays error messages if any exceptions are encountered when submitting forms. The main part of the page contains a table of orders, as extracted from our SQLite database. Each table row corresponds to one order and displays the customer's name, their phone number, what they ordered, and the current status of the order.`}</p>
    <p>{`The rightmost column of the table, with the header "Update Status", contains two forms for each row of the table:`}</p>
    <ul>
      <li parentName="ul">{`A drop-down menu that lets you submit a change to the status of the order when you select a choice and click "Update". This form contains a hidden field that stores the order ID, which helps us update the correct order upon submission.`}</li>
      <li parentName="ul">{`A "Notify Customer" button that sends a message to the customer informing them of the order's current status. This button stores the order ID in its `}<inlineCode parentName="li">{`value`}</inlineCode>{` attribute so that our application can quickly look up the phone number for the order.`}</li>
    </ul>
    <h3>{`Routes for submitting forms`}</h3>
    <p>{`Now, we'll write the routes that let you submit the form for updating an order's status and the form for notifying a customer.`}</p>
    <h4><inlineCode parentName="h4">{`orderUpdate`}</inlineCode>{` route`}</h4>
    <p>{`This route processes the POST request from the form to update an order's status. We define it in `}<inlineCode parentName="p">{`app.py`}</inlineCode>{` as follows:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`@app.route('/orderUpdate', methods=['POST'])
def update():
   try:
       newStatus = request.form.get("orderStatus")
       currentOrder = Order.query.filter_by(id=request.form.get("id")).first()
       currentOrder.status = newStatus
       db.session.commit()
   except Exception as e:
       flash("Could not update order status")
       flash(e)
   table = Order.query.all()
   return render_template('index.html', table=table)
`}</code></pre>
    <p>{`From the form defined in `}<inlineCode parentName="p">{`index.html`}</inlineCode>{`, the route obtains the new order status selected in the drop-down menu. It then uses the order ID stored in the form's hidden field to modify the status of the order in the database. If the attempt to obtain the new status and update the old one fails, it flashes the error message at the top of the page, in the region defined in `}<inlineCode parentName="p">{`index.html`}</inlineCode>{`.`}</p>
    <p>{`As a last step, the route also updates the order table to display the new order status.`}</p>
    <h4><inlineCode parentName="h4">{`notify`}</inlineCode>{` route`}</h4>
    <p>{`In our `}<inlineCode parentName="p">{`notify`}</inlineCode>{` route, we need to:`}</p>
    <ol>
      <li parentName="ol">{`Get the order ID that we want to send a notification for. This is stored in the hidden field in the second form defined in `}<inlineCode parentName="li">{`index.html`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`Get the order status and the phone number associated with that order by looking up the order ID in the database and finding the corresponding data.`}</li>
      <li parentName="ol">{`Send a message to that phone number.`}</li>
    </ol>
    <p>{`Add the following code to `}<inlineCode parentName="p">{`app.py`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`#What happens when you press the 'Notify Customer' button
@app.route('/notify', methods=['POST'])
def notify():
   #get data (phone and name) corresponding to customer id in form
   currentOrder = Order.query.filter_by(id=request.form.get("notify_id")).first()
   msgToSend = isOrderConfirmed(currentOrder.status, currentOrder.name)
   try:
       msg = client.message_create('OmNomNom', currentOrder.phone, msgToSend, None)
       print msg
       flash(currentOrder.name + " was notified that their order is " + currentOrder.status)
   except messagebird.client.ErrorException as e:
       flash("Could not send notification")
       for error in e.errors:
           print error.description
   table = Order.query.all()
   return render_template('index.html', table=table)
`}</code></pre>
    <p>{`We start by parsing our form for the `}<inlineCode parentName="p">{`notify_id`}</inlineCode>{` field that contains the order ID for the order of interest. Then we look in our database and find the order that matches `}<inlineCode parentName="p">{`notify_id`}</inlineCode>{`. Once we've found the relevant order, we can use its fields to construct a message to send to our customer.`}</p>
    <p>{`After a message is constructed using the order data, we send it by using the MessageBird Python library's `}<inlineCode parentName="p">{`message_create()`}</inlineCode>{` method. If we succeed in doing so, a success message flashes at the top of the page; if the notification fails, an error message is displayed.`}</p>
    <p>{`We want the route to return the same page that it starts with, so we populate the table with data and use the `}<inlineCode parentName="p">{`index.html`}</inlineCode>{` template again.`}</p>
    <p>{`Notice that in order to construct the notification message, we're calling a `}<inlineCode parentName="p">{`isOrderConfirmed()`}</inlineCode>{` helper function to construct our `}<inlineCode parentName="p">{`msgToSend`}</inlineCode>{` parameter. Add the code for `}<inlineCode parentName="p">{`isOrderConfirmed()`}</inlineCode>{` just under the body of `}<inlineCode parentName="p">{`orderNotify()`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`def isOrderConfirmed(status, recipientName):
   if status=="pending":
       return "Hello, " + recipientName + ", thanks for ordering at OmNomNom Foods! We're still working on your order. Please be patient with us!"
   elif status=="confirmed":
       return "Hello, " + recipientName + ", thanks for ordering at OmNomNom Foods! We are now preparing your food with love and fresh ingredients and will keep you updated."
   elif status=="delayed":
       return "Hello, " + recipientName + ", sometimes good things take time! Unfortunately your order is slightly delayed but will be delivered as soon as possible."
   elif status=="delivered":
       return "Hello, " + recipientName + ", you can start setting the table! Our driver is on their way with your order! Bon appetit!"
   else:
       return "We can't find your order! Please call our customer support for assistance."
`}</code></pre>
    <p>{`Our `}<inlineCode parentName="p">{`isOrderConfirmed()`}</inlineCode>{` helper matches the status of the order to a list of predefined messages, and returns a message string (complete with the customer's name). We assign this to the `}<inlineCode parentName="p">{`msgToSend`}</inlineCode>{` variable for use when triggering the SMS notification.`}</p>
    <h2>{`Testing`}</h2>
    <p>{`You’re done! We now have a fully working application, but we won't be able to test our application without having an order with a real phone number. Plus, if you're using a test API key, our code in `}<inlineCode parentName="p">{`app.py`}</inlineCode>{` doesn't give us visible feedback for each `}<inlineCode parentName="p">{`message_create()`}</inlineCode>{` call.`}</p>
    <p>{`To set up a development copy of the code to test if it works, we can modify a few things:`}</p>
    <ol>
      <li parentName="ol">{`Change one of the "Phone" fields in your database table to a test phone number that can receive messages. This phone number should also be saved in your MessageBird Dashboard as a contact.`}</li>
      <li parentName="ol">{`Use a live API key when you run the program.`}</li>
    </ol>
    <p>{`Now, a successful `}<inlineCode parentName="p">{`message_create()`}</inlineCode>{` call will result in one of the four messages defined in `}<inlineCode parentName="p">{`isOrderConfirmed()`}</inlineCode>{` being sent to the real phone number you used.`}</p>
    <p>{`Ready! You can begin testing your application! Run your application in the terminal:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`python app.py
`}</code></pre>
    <ol>
      <li parentName="ol">{`Point your browser at http://localhost:5000/ to see the table of orders.`}</li>
      <li parentName="ol">{`For any order displayed, select a new order status and click "Update" to update the order status. The page should display the updated order status.`}</li>
      <li parentName="ol">{`For any order displayed, click on "Notify Customer" to send an SMS notification to the customer. The phone number associated with that order should receive a message communicating the order status. Tada!`}</li>
    </ol>
    <p>{`Awesome! You can now use the flow, code snippets and UI examples from this tutorial as an inspiration to build your own SMS Notifications system. Don't forget to download the code from the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/notifications-guide-python"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`.`}</p>
    <p><strong parentName="p">{`Nice work!`}</strong>{` 🎉
You now have a running SMS Notifications application with MessageBird using Python!`}</p>
    <h2>{`Start building!`}</h2>
    <p>{`Want to build something similar but not quite sure how to get started? Please feel free to let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we'd love to help!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      