export const API_LANG = {
  json: `// In this column you view the code examples.
// Pick one of our APIs from the sidebar on the left and start coding.`,
};

export const API_ENDPOINT = {
  bash: `https://rest.messagebird.com/`,
};

export const ERRORS_REQUEST = {
  curl: `curl -X GET https://rest.messagebird.com/balance -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM'`,
  php: `$MessageBird = new \\MessageBird\\Client('YOUR_ACCESS_KEY');`,
  ruby: `require 'messagebird'
client = MessageBird::Client.new('YOUR_ACCESS_KEY')`,
  csharp: `const string YourAccessKey = "YOUR_ACCESS_KEY";
Client client = Client.CreateDefault(YourAccessKey);`,
  go: `import messagebird "github.com/messagebird/go-rest-api"

func main() {
    client := messagebird.New("YOUR_ACCESS_KEY")
    // ...
}`,
  python: `import messagebird
client = messagebird.Client('YOUR_ACCESS_KEY')`,
  java: `import com.messagebird.MessageBirdClient;
import com.messagebird.MessageBirdService;
import com.messagebird.MessageBirdServiceImpl;

public class ExampleAuthenticate {

    public static void main() {
        // Create a MessageBirdService
        final MessageBirdService wsr = new MessageBirdServiceImpl("YOUR_ACCESS_KEY");

        // Add the service to the client
        final MessageBirdClient messageBirdClient = new MessageBirdClient(wsr);
    }
}`,
  node: `var messagebird = require('messagebird')('YOUR_ACCESS_KEY');`,
};

export const ERRORS_OBJECT = {
  json: `{
  "errors":[
    {
      "code": 2,
      "description": "Request not allowed (incorrect access_key)",
      "parameter": "access_key"
    }
  ]
}`,
};

export const ERRORS_HANDLING = {
  curl: `curl -X POST https://rest.messagebird.com/messages \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -d "recipients=31612345678" \\
  -d "originator=YourName" \\
  -d "body="{"errors":[{"code":9,"description":"body is required","parameter":"body"}]}`,
  php: `try {
  // Execute a MessageBird method
} catch (\\MessageBird\\Exceptions\\AuthenticateException $e) {
  // Authentication failed. Is this a wrong access_key?

} catch (\\MessageBird\\Exceptions\\BalanceException $e) {
  // That means that you are out of credits. Only called on creation of a object.

} catch (\\Exception $e) {
  // Request failed. More information can be found in the body.

  // Echo's the error messages, split by a comma (,)
  echo $e->getMessage();
}`,
  ruby: `begin
  # Execute a MessageBird method

rescue MessageBird::ErrorException => ex
    # Request failed. More information can be found in the
    # ex.errors array.

    ex.errors.each { |error| puts error.inspect }
end`,
  csharp: `try
{
    # Execute a MessageBird method
}
catch (ErrorException e)
{
    // Request failed.
    if (e.HasErrors)
    {
        foreach (Error error in e.Errors)
        {
            // error.Code, error.Description, error.Parameter
        }
    }
}`,
  go: `if err != nil {
  // messagebird.ErrResponse means custom JSON errors.
  if err == messagebird.ErrResponse {
    for _, mbError := range object.Errors {
      fmt.Printf("Error: %#v\\n", mbError)
    }
  }

  return
}`,
  python: `try:
# Execute a MessageBird method
except messagebird.client.ErrorException as e:
for error in e.errors: print(error.__dict__)`,
  java: `try {
  // Execute a MessageBird method
} catch (UnauthorizedException unauthorized) {
  if (unauthorized.getErrors() != null) {
    // Authentication failed. Is this a wrong access key?
    System.out.println(unauthorized.getErrors().toString());
  }
  unauthorized.printStackTrace();
} catch (GeneralException generalException) {
  if (generalException.getErrors() != null) {
      System.out.println(generalException.getErrors().toString());
  }
  generalException.printStackTrace();
}`,
  node: `// MessageBird method example
messagebird.messages.create(params, function (err, response) {
  if (err) {
    for (error of err.errors) {
      console.error(error)  
    }
  }
});`,
};

export const JSON_PAYLOAD = {
  json: `{
  "recipients": 31612345678,
  "originator": "MessageBird",
  "body": "The message to be sent"
}`,
};

export const SEND_MESSAGE = {
  curl: `/messages?_method=POST&...`,
};

export const REQUEST_HLR = {
  curl: `/hlr?_method==POST&...`,
};

export const VERIFY_SIGNATURE = {
  php: `use MessageBird\Exceptions\ValidationException;
use MessageBird\RequestValidator;

// requestSignature is the value of the 'MessageBird-Signature-JWT' HTTP header.
$requestSignature = 'JWT_TOKEN_STRING';
$requestURL = 'https://yourdomain.com/path?query=2';
$requestBody = '{"foo":"bar"}';

$validator = new RequestValidator('YOUR_SIGNING_KEY');

try {
  $validator->validateSignature($requestSignature, $requestURL, $requestBody);
} catch (ValidationException $e) {
  // The request is invalid, so respond accordingly.
  http_response_code(412);
}
`,
  node: `var Validator = require('messagebird/lib/webookSignatureJwt');

// requestSignature is the value of the 'MessageBird-Signature-JWT' HTTP header.
var requestSignature = 'YOUR_REQUEST_SIGNATURE';
var requestURL = 'https://yourdomain.com/path?query=2';
var requestBody = YOUR_REQUEST_BODY;

Validator.verify(requestURL, requestBody, requestSignature, 'YOUR_SIGNING_KEY')
  .then(...)
  .catch(err => ...);

// Or you can use ExpressMiddlewareVerify:
const {ExpressMiddlewareVerify} = require('messagebird/lib/webookSignatureJwt');

const verifySignature = new ExpressMiddlewareVerify('YOUR_SIGNING_KEY');
  
// Retrieve the raw body as a buffer.
app.use(express.raw({ 'type': '*/*' }));

// Verify webhook.
app.get('/webhook', verifySignature, (req, res) => {
  res.send('verified');
});
`,
  java: `import com.messagebird.RequestValidator;
import com.messagebird.exceptions.RequestValidationException;

RequestValidator validator = new RequestValidator("YOUR_SIGNING_KEY");

// requestSignature is the value of the 'MessageBird-Signature-JWT' HTTP header.
String requestSignature = "YOUR_REQUEST_SIGNATURE";
String requestURL = "https://yourdomain.com/path?query=2";
byte[] requestBody = YOUR_REQUEST_BODY;

try {
  validator.validateSignature(requestSignature, requestURL, requestBody);
} catch (RequestValidationException e) {
  // The request is invalid, so respond accordingly.
}
`,
  python: `import messagebird
from messagebird.error import ValidationError

# request_signature is the value of the 'MessageBird-Signature-JWT' HTTP header.
request_signature = "YOUR_REQUEST_SIGNATURE"
request_url = "https://yourdomain.com/path?query=2"
request_body = YOUR_REQUEST_BODY

validator = messagebird.RequestValidator("YOUR_SIGNING_KEY")

try:
    validator.validate_signature(request_signature, request_url, request_body)
except ValidationError as err:
    print("The signed request cannot be verified: ", str(err))
`,
  csharp: `
using MessageBird;
using MessageBird.Exceptions;

RequestValidator validator = new RequestValidator("YOUR_SIGNING_KEY")

// requestSignature is the value of the 'MessageBird-Signature-JWT' HTTP header.
string requestSignature = "YOUR_REQUEST_SIGNATURE";
string requestURL = "https://yourdomain.com/path?query=2";
byte[] requestBody = YOUR_REQUEST_BODY;

try {
  validator.ValidateSignature(requestSignature, requestURL, requestBody);
} catch (RequestValidationException e) {
  // The request is invalid, so respond accordingly.
}
`,
  ruby: `require 'messagebird'

validator = MessageBird::RequestValidator.new('YOUR_SIGNING_KEY')

# request_signature is the value of the 'MessageBird-Signature-JWT' HTTP header.
request_signature = 'YOUR_REQUEST_SIGNATURE'
request_url = 'https://yourdomain.com/path?query=2'
request_body = YOUR_REQUEST_BODY

begin
  # Verify the signed request.
  validator.validate_signature(request_signature, request_url, request_body)
rescue MessageBird::ValidationError => e
  puts
  puts 'An error occurred while verifying the signed request:'
  puts e
end`,
  go: `import "signature_jwt"

validator := NewValidator('YOUR_SIGNING_KEY')

// requestSignature is the value of the 'MessageBird-Signature-JWT' HTTP header.
requestSignature := 'YOUR_REQUEST_SIGNATURE'
requestURL := 'https://yourdomain.com/path?query=2'
requestBody := YOUR_REQUEST_BODY

_, err := validator.ValidateSignature(requestSignature, requestURL, requestBody)
if err != nil {
  // The request is invalid, so respond accordingly.
}

// Or you can use the ValidRequest method, just pass the request and 
// base URL of your request as parameters:

    validator := signature.NewValidator("your signing key")
    if err := validator.ValidateRequest(r, "https://yourdomain.com"); err != nil {
        // handle error
    }

// Or use the handler as a middleware for your server:

	http.Handle("/path", validator.Validate(YourHandler, "https://yourdomain.com"))
`,
};
