import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
import CodeSnippet from 'components/CodeSnippet';
import * as CODE from 'constants/live-chat-widget';
import { Img } from 'components/shared';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Identifying and Managing Users on the Omnichannel Widget`}</h1>
    <p>{`The identify method allows you to create a consistent chat experience for known Live Chat visitors on your site.`}</p>
    <p>{`In this guide, we will outline the different types of Live Chat visitors on the Omnichannel Widget, how to identify these users to create a consistent conversation across devices and browsers, and how to logout and reset users.`}</p>
    <h2>{`Anonymous and Identified Users`}</h2>
    <p>{`There are two different types of Omnichannel Widget users: Anonymous and Identified users.
When a user interacts with the Omnichannel Widget, we will first create an Anonymous user within the Omnichannel Widget.`}</p>
    <p>{`This creates a frictionless experience for users who would like to start a conversation with you via the Omnichannel Widget.`}</p>
    <p>{`This session is stored via client-side storage, within the browser. This means that if the user returns to your site, or accesses your site via a different tab, they will be considered the same visitor and will see the same chat.`}</p>
    <p>{`However, if they use a different browser, or if they attempt to chat again on another device, they would be considered a separate user, and would therefore be unable to see the chat.`}</p>
    <p>{`If you would like your users to have a consistent conversation history, you can use the `}<inlineCode parentName="p">{`identify()`}</inlineCode>{` in javascript to make sure that they are considered the same user, regardless of how they access your site.`}</p>
    <p>{`The most common use case for the identify method is to keep the Omnichannel Widget aligned with your own site’s login system.`}</p>
    <p>{`If a user logs in to your site, you can pass the identifier of that user from your business’s system to create the exact same user in Live Chat. This Live Chat user profile can then be passed to Flow Builder or Inbox to be used by the team.`}</p>
    <h2>{`Identify vs setAttributes`}</h2>
    <p>{`It's important to outline the difference between the `}<inlineCode parentName="p">{`Identify`}</inlineCode>{` and the `}<inlineCode parentName="p">{`setAttributes`}</inlineCode>{` method.`}</p>
    <p><inlineCode parentName="p">{`setAttributes`}</inlineCode>{` will allow you to set an attribute as a userID and pass it, so that your Inbox agents can receive custom information that they can use when handling inquiries.
`}<inlineCode parentName="p">{`identify`}</inlineCode>{` will allow you to create a static user based on the identifier that is passed. If an anonymous visitor with some custom attributes changes devices or attempts to engage a conversation on the Omnichannel Widget after a period of time, they will create a new ticket in Inbox as a new user and they will also not see their previous conversations.`}</p>
    <h2>{`How to Identify Users`}</h2>
    <p>{`The identify method takes a static ID as a string which you can pass to identify a user. This ID will replace the session ID of the anonymous visitor created by the Omnichannel Widget.`}</p>
    <p><em parentName="p">{`Please make sure to keep the ID that you pass private, as anyone who knows this ID can use this to log in as the identified user and view their conversation history`}</em></p>
    <p><em parentName="p">{`Please make sure that the identifier is unique. If there is an overlap between user identifiers on your Omnichannel Widget then they could be able to see the conversations of the other user with the same identifier`}</em></p>
    <CodeSnippet code={CODE.IDENTIFY_WITHOUT_ATTRIBUTES} mdxType="CodeSnippet" />
    <p>{`It is also possible to setAttributes at the same time, for example, if a user has just signed up and you want to pass extra information to identify them in Inbox. Please see our guide `}<a parentName="p" {...{
        "href": "/quickstarts/omnichannel-widget/attributes"
      }}>{`here`}</a>{` for more information on setAttributes.`}</p>
    <CodeSnippet code={CODE.IDENTIFY} mdxType="CodeSnippet" />
    <p>{`Once the identify method has resolved the user of the Live Chat will use the ID you passed in the params of the method as its identifier.`}</p>
    <h2>{`Logging out Visitors and Identified Users`}</h2>
    <p>{`The logout method will reset the Omnichannel Widget, removing all stored data about the Live Chat visitor’s session. The Omnichannel Widget will then create a new anonymous user.`}</p>
    <CodeSnippet code={CODE.LOGOUT} mdxType="CodeSnippet" />
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'Omnichannel Widget API Reference',
      to: '/api/omnichannel-widget'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      