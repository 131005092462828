import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Implementing voice two-factor authentication (2FA) with MessageBird`}</h1>
    <h3>{`⏱ 15 min build time      ||      `}<a parentName="h3" {...{
        "href": "https://github.com/messagebirdguides/verify-voice-guide-python"
      }}>{`Download the Code`}</a></h3>
    <h2>{`Why build voice-based account security?`}</h2>
    <p>{`In this MessageBird Developer Tutorial you’ll learn how to improve your security by building a Voice-based two-factor authentication solution with the `}<a parentName="p" {...{
        "href": "https://www.messagebird.com/en/verify"
      }}>{`MessageBird Verify API`}</a>{`.`}</p>
    <p>{`Websites where users can sign up for an account typically use the email address as a unique identifier and a password as a security credential for users to sign in. At the same time, most websites ask users to add a verified phone number to their profile. Phone numbers are, in general, a better way to identify an account holder as a real person; they can also be used as a second authentication factor (2FA) or to restore access to a locked account.`}</p>
    <p>{`Verification of a phone number is straightforward:`}</p>
    <ol>
      <li parentName="ol">{`Ask your user to enter their number.`}</li>
      <li parentName="ol">{`Call the number programmatically and use a text-to-speech system to say a security code that acts as a one-time-password (OTP).`}</li>
      <li parentName="ol">{`Let the user enter this code on the website or inside an application as proof that they received the call.`}</li>
    </ol>
    <p>{`The MessageBird Verify API assists developers in implementing this workflow into their apps. Imagine you're running a social network and want to verify the profiles of your users. This MessageBird Developer Tutorial shows you an example application using Python with integrated account security following the steps outlined above.`}</p>
    <p>{`By the way, it is also possible to replace the second step with an SMS message, as we explain in our `}<a parentName="p" {...{
        "href": "/tutorials/verify-node"
      }}>{`two factor authentication tutorial`}</a>{`. However, using voice for verification has the advantage that it works with every phone number, not just mobile phones, so it can be used to verify, for example, the landline of a business.`}</p>
    <p>{`The `}<a parentName="p" {...{
        "href": "/api/verify"
      }}>{`MessageBird Verify API`}</a>{` supports both options; voice and SMS!`}</p>
    <h2>{`Getting started`}</h2>
    <p>{`Our sample application is built in Python using the `}<a parentName="p" {...{
        "href": "http://flask.pocoo.org/"
      }}>{`Flask`}</a>{` framework. You can download or clone the complete source code from the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/verify-voice-guide-python"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{` to run the application on your computer and follow along with the tutorial.`}</p>
    <p>{`You’ll also need Python and the Python’s pip package manager. Please `}<a parentName="p" {...{
        "href": "https://pip.pypa.io/en/stable/installing/"
      }}>{`install pip`}</a>{` if you don’t have it already. Using pip, you can install the `}<a parentName="p" {...{
        "href": "https://github.com/messagebird/python-rest-api"
      }}>{`Python client for the MessageBird REST API`}</a>{` and Flask with the following two commands:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`pip install messagebird
pip install Flask
`}</code></pre>
    <p>{`To handle our forms, we’ll use the `}<a parentName="p" {...{
        "href": "https://wtforms.readthedocs.io/en/stable/"
      }}>{`WTForms`}</a>{` forms rendering library and the `}<a parentName="p" {...{
        "href": "https://flask-wtf.readthedocs.io/en/stable/"
      }}>{`Flask-WTF library`}</a>{`, which integrates WTForms with Flask. Install these libraries with the following commands:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`pip install WTForms
pip install Flask-WTF
`}</code></pre>
    <h2>{`Configuring the MessageBird REST API`}</h2>
    <p>{`To run the code, you need to provide a MessageBird API key. One way to do so is through a configuration file that’s separate from the main application code. We've prepared a `}<inlineCode parentName="p">{`config_file.cfg `}</inlineCode>{` file in the repository, which you should edit to include your API key. The file has the following format:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-env"
      }}>{`SECRET_KEY=YOUR-API-KEY
`}</code></pre>
    <p>{`You can include more configuration variables in this file. For example, adding a `}<inlineCode parentName="p">{`DEBUG=True`}</inlineCode>{` line will facilitate troubleshooting when you test your application.`}</p>
    <p>{`You can create or retrieve a live API key from the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`API access (REST) tab`}</a>{` in the `}<em parentName="p">{`Developers`}</em>{` section of the MessageBird Dashboard.`}</p>
    <p>{`Let’s initialize the application and load the key into it from the `}<inlineCode parentName="p">{`config_file.cfg`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`app = Flask(__name__)
app.config.from_pyfile('config_file.cfg')
`}</code></pre>
    <p>{`Then, let's create an instance of the MessageBird Python client:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-Python"
      }}>{`client = messagebird.Client(app.config['SECRET_KEY'])
`}</code></pre>
    <h2>{`Asking for the phone number`}</h2>
    <p>{`The sample application contains a form to collect the user's phone number. You can see the HTML as a Jinja2 template in the file `}<inlineCode parentName="p">{`templates/index.html`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{` <h1>MessageBird Verify Example</h1>
 <p>Enter your phone number:</p>
 <form action="{{ url_for('submitPhone') }}" method="post">
 <dl>
 {{ initial_form.number }}
 {{ initial_form.submit }}
 </dl>
 </form>
`}</code></pre>
    <p>{`The route that renders the form is defined under `}<inlineCode parentName="p">{`@app.route('/', methods=['GET', 'POST'])`}</inlineCode>{` in `}<inlineCode parentName="p">{`app.py`}</inlineCode>{`. The route starts by instantiating the two forms used in the application.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`@app.route('/', methods=['GET', 'POST'])
def submitPhone():
   initial_form = SubmitPhoneNumber(request.form)
   code_form = EnterCode()
`}</code></pre>
    <p>{`The two forms instantiated are defined in `}<inlineCode parentName="p">{`forms.py`}</inlineCode>{`.
The form for obtaining the phone number, `}<inlineCode parentName="p">{`initial_form`}</inlineCode>{`, has a structure  defined by the  class `}<inlineCode parentName="p">{`SubmitPhoneNumber`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`class SubmitPhoneNumber(FlaskForm):
   number = TelField('Phone number')
   submit = SubmitField('Send code')
`}</code></pre>
    <p>{`The form has a `}<inlineCode parentName="p">{`Telfield`}</inlineCode>{` type field for collecting the phone number and a submit button. Once the user clicks on that button, the input is sent to the `}<inlineCode parentName="p">{`submitPhone()`}</inlineCode>{` function defined under `}<inlineCode parentName="p">{`@app.route('/', methods=['GET', 'POST'])`}</inlineCode>{` in `}<inlineCode parentName="p">{`app.py`}</inlineCode>{`.`}</p>
    <h2>{`Initiating the verification call`}</h2>
    <p>{`When the user submits their phone number, the `}<inlineCode parentName="p">{`submitPhone()`}</inlineCode>{` function sends it to the MessageBird API using the `}<inlineCode parentName="p">{`verify_create()`}</inlineCode>{` method. The Verify API expects the user's telephone number to be in international format, so when you test the code, make sure to include the `}{`[country code]`}{`((`}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/articles/115003950149-Numbers-format-"
      }}>{`https://support.messagebird.com/hc/en-us/articles/115003950149-Numbers-format-`}</a>{`) in the form.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`#when form is posted, try to obtain server response
if request.method=="POST":
   try:
       verify = client.verify_create(initial_form.number.data,
                                     {'type':'tts',
                                      'template': 'Your security code is %token.'})
       #on success we render verify.id on the hidden field on the verification page (/EnterCode)
       return redirect(url_for('enterCode', code_form=code_form, verify_id=verify.id))

`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`verify.create()`}</inlineCode>{` call takes the phone number entered in the form (`}<inlineCode parentName="p">{`initial_form.number.data`}</inlineCode>{`) and two other parameters:`}</p>
    <ul>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`type`}</inlineCode>{` parameter is set to `}<inlineCode parentName="li">{`tts`}</inlineCode>{` to inform the API that we want to use a voice call for verification.`}</li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`template`}</inlineCode>{` parameter contains the words to speak. It must contain the placeholder `}<inlineCode parentName="li">{`%token`}</inlineCode>{` so that MessageBird knows where the code goes (we use the words token and code interchangeably; they mean the same thing). We don't have to generate this numeric code ourselves; the API takes care of it.`}</li>
    </ul>
    <p>{`There are a few other available options. For example, you can change the length of the code (it defaults to 6) with `}<inlineCode parentName="p">{`tokenLength`}</inlineCode>{`; you can also specify `}<inlineCode parentName="p">{`voice`}</inlineCode>{` as `}<inlineCode parentName="p">{`male`}</inlineCode>{` or `}<inlineCode parentName="p">{`female`}</inlineCode>{`, and set the `}<inlineCode parentName="p">{`language`}</inlineCode>{` to an ISO language code if you want the synthesized voice to be in a non-English language. You can find more details about these and other options in our `}<a parentName="p" {...{
        "href": "/api/verify#request-a-verify"
      }}>{`Verify API reference documentation`}</a>{`.`}</p>
    <p>{`If the `}<inlineCode parentName="p">{`verify_create()`}</inlineCode>{` call succeeds, we redirect the user to the page where they can enter the verification code they’ll receive in the voice call. This page is represented by the `}<inlineCode parentName="p">{`/EnterCode`}</inlineCode>{` route in `}<inlineCode parentName="p">{`app.py`}</inlineCode>{`. The `}<inlineCode parentName="p">{`id`}</inlineCode>{` attribute of the `}<inlineCode parentName="p">{`verify`}</inlineCode>{` object returned by the call is passed into the route, as it’s required to determine which object the verification code corresponds to.`}</p>
    <p>{`If our `}<inlineCode parentName="p">{`verify_create()`}</inlineCode>{` call leads to an error, we flash an error message at the top of the page:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`#on failure, render description for error on same page.
except messagebird.client.ErrorException as e:
   for error in e.errors:
       flash('  description : %s\\n' % error.description)
   return render_template('index.html', initial_form=initial_form)
`}</code></pre>
    <p>{`The region where the error will flash is defined in the `}<inlineCode parentName="p">{`index.html`}</inlineCode>{` template as follows:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<!--If form submission results in error, flash error message -->
{% with messages = get_flashed_messages() %}
 {% if messages %}
   <ul class=flashes>
   {% for message in messages %}
     <li>{{ message }}</li>
   {% endfor %}
   </ul>
 {% endif %}
{% endwith %}
`}</code></pre>
    <h2>{`Verifying the code`}</h2>
    <p>{`The template stored in `}<inlineCode parentName="p">{`templates/entercode.html`}</inlineCode>{`, which we render in the success case, contains an HTML form with a hidden input field to pass forward the `}<inlineCode parentName="p">{`id`}</inlineCode>{` from the verification request. It also contains a visible text field in which the user can enter the code that they've heard on the phone. When the user submits this form, it sends this token to the `}<inlineCode parentName="p">{`/success`}</inlineCode>{` route.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{` <h1>Enter your verification code</h1>
 <form action="{{ url_for('enterCode', code_form=code_form, verify_id=code_form.verify_id.data) }}" method="post">
   <dl>
   {{ code_form.verify_id }}
   {{ code_form.token }}
   {{ code_form.submit }}
   </dl>
 </form>
`}</code></pre>
    <p>{`The structure of the `}<inlineCode parentName="p">{`code_form`}</inlineCode>{` object referenced in the template is defined by the `}<inlineCode parentName="p">{`EnterCode`}</inlineCode>{` class in `}<inlineCode parentName="p">{`forms.py`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`class EnterCode(FlaskForm):
   verify_id = HiddenField('ID')
   token = StringField('Enter your verification code:')
   submit = SubmitField('Check code')
`}</code></pre>
    <p>{`In the main file, `}<inlineCode parentName="p">{`app.py`}</inlineCode>{`, the `}<inlineCode parentName="p">{`enterCode()`}</inlineCode>{` function defines what happens when the page is loaded, and when the verification code is submitted. When the page loads, the form is instantiated, and the hidden field reserved for the ID is filled with the argument that is passed into `}<inlineCode parentName="p">{`enterCode()`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`#What happens when you submit the verification form
@app.route('/EnterCode/<verify_id>', methods=['GET', 'POST'])
def enterCode(verify_id):
   code_form = EnterCode(request.form)
   code_form.verify_id.data = verify_id
`}</code></pre>
    <p>{`When the form is posted, we call another method in the MessageBird API, `}<inlineCode parentName="p">{`verify_verify()`}</inlineCode>{`, and provide the ID and token as two parameters. Upon success, we redirect the user to a success page, which is defined by the `}<inlineCode parentName="p">{`/success`}</inlineCode>{` route.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`#when form is posted, try to obtain server response
if  request.method=="POST":
   try:
       verify = client.verify_verify(code_form.verify_id.data, code_form.token.data)
       #on success, render a (new) success page.
       return render_template('success.html')
`}</code></pre>
    <p>{`On a failure, we flash an error message at the top of the page.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`#on failure, flash error description on same page.
except messagebird.client.ErrorException as e:
   for error in e.errors:
       flash('  description : %s\\n' % error.description)
   return redirect(url_for('enterCode', code_form=code_form, verify_id=verify_id))
`}</code></pre>
    <p>{`The region for flashing error messages is defined in `}<inlineCode parentName="p">{`entercode.html`}</inlineCode>{` as follows:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<!--If error received upon form submission, flash error message at top -->
<!--of page -->
{% with messages = get_flashed_messages() %}
 {% if messages %}
   <ul class=flashes>
   {% for message in messages %}
     <li>{{ message }}</li>
   {% endfor %}
   </ul>
 {% endif %}
{% endwith %}
`}</code></pre>
    <p>{`The success page that the user sees after submitting a correct verification code is defined by the `}<inlineCode parentName="p">{`success.html`}</inlineCode>{` template:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<html>
 <head>
   <title></title>
 </head>
 <body>
   <p>You have successfully verified your phone number.</p>
 </body>
</html>
`}</code></pre>
    <p>{`The route for the success page, defined in `}<inlineCode parentName="p">{`app.py`}</inlineCode>{`, is simple:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`#success page; displayed if correct verification code is entered
@app.route('/success')
def success():
   return render_template('success.html')
`}</code></pre>
    <h2>{`Testing`}</h2>
    <p>{`You’re done! Let's go ahead that test your application. Check again that you have provided a live API key in `}<inlineCode parentName="p">{`config_file.cfg`}</inlineCode>{`. Then, in the project folder, enter the following command in your console:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`python app.py
`}</code></pre>
    <p>{`Now, open your browser to http://localhost:5000/ and walk through the process yourself.`}</p>
    <p>{`Awesome! You can now leverage the flow, code snippets and UI examples from this tutorial to build your own voice-based account security system. Don't forget to download the code from the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/verify-voice-guide-python"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`.`}</p>
    <p><strong parentName="p">{`Nice work!`}</strong>{` 🎉`}</p>
    <p>{`You now have a running integration of MessageBird's Verify API using Python!`}</p>
    <h2>{`Start building!`}</h2>
    <p>{`Want to build something similar but not quite sure how to get started? Please feel free to let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`;  we'd love to help!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      