import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Verify`}</h1>
    <p>{`MessageBird's Verify API enables you to verify a mobile phone number with two-factor authentication. Create a new Verify object through the API to start the verification process of a recipient. MessageBird will take care of creating a token and making sure that the message gets delivered to the provided number. `}</p>
    <p>{`The Verify API uses `}<inlineCode parentName="p">{`HTTP verbs`}</inlineCode>{` and a `}<inlineCode parentName="p">{`RESTful`}</inlineCode>{` endpoint structure with an access key that is used as the API Authorization. Request and response payloads are formatted as JSON using UTF-8 encoding and URL encoded values.`}</p>
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'Send a verification token',
      to: 'docs/verify/send-a-verification-token-curl//'
    }, {
      name: 'Verify a token',
      to: 'docs/whatsapp/verify/verify-a-token-curl/'
    }, {
      name: 'Verify API Reference',
      to: '/api/verify/'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      