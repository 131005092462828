import React from 'react';
import styled from 'styled-components';
import { apisNavigationGenerated } from './constants-generated';

export const MenuPill = styled.span<{ type?: string }>`
  display: inline-block;
  padding: 4px;
  font-weight: 600;
  font-size: 12px;
  margin: 0 0 2px 6px;
  line-height: 1em;
  border-radius: 2px;
  vertical-align: middle;
  color: #fff;
  background: ${(props) => (props.type === 'beta' ? '#eaa83c' : '#2ecea8')};
`;

export const docsNavigation = [
  {
    route: '/docs/',
    activeOn: /[docs/]?/,
    name: 'Home',
  },
  {
    noLink: true,
    route: '/docs/sms-messaging/',
    name: 'SMS',
    subMenu: [
      {
        noLink: true,
        route: '/docs/sms-messaging/quickstarts/',
        activeOn: '/docs/sms-messaging/quickstarts/',
        name: 'Quickstarts',
        subMenu: [
          {
            route: '/docs/sms-messaging/test-credits-api-keys/',
            activeOn: '/docs/sms-messaging/test-credits-api-keys/',
            name: 'Test credits & API keys',
          },
          {
            route: '/docs/sms-messaging/send-outbound-sms-curl/',
            activeOn: '/docs/sms-messaging/send-outbound-sms/',
            name: 'Send an SMS',
          },
          {
            route: '/docs/sms-messaging/handle-inbound-sms/',
            activeOn: '/docs/sms-messaging/handle-inbound-sms/',
            name: 'Receive and handle an SMS',
          },
        ],
      },
      {
        noLink: true,
        route: '/tutorials/',
        activeOn: '/tutorials/',
        name: 'Tutorials',
        subMenu: [
          {
            route: '/tutorials/send-sms-node',
            activeOn: '/tutorials/send-sms-node/',
            name: 'Sending SMS',
          },
          {
            route: '/tutorials/handle-incoming-calls-and-sms/',
            activeOn: '/tutorials/handle-incoming-calls-and-sms/',
            name: 'Handling incoming SMS',
          },
          {
            route: '/tutorials/verify-node/',
            activeOn: '/tutorials/verify-node/',
            name: 'SMS two-factor authentication (2FA)',
          },
          {
            route: '/tutorials/notifications-node/',
            activeOn: '/tutorials/notifications-node/',
            name: 'SMS notifications',
          },
          {
            route: '/tutorials/reminders-node/',
            activeOn: '/tutorials/reminders-node/',
            name: 'SMS reminders',
          },
          {
            route: '/tutorials/lead-alerts-node/',
            activeOn: '/tutorials/lead-alerts-node/',
            name: 'SMS lead alerts',
          },
          {
            route: '/tutorials/subscriptions-node/',
            activeOn: '/tutorials/subscriptions-node/',
            name: 'SMS marketing subscriptions',
          },
          {
            route: '/tutorials/sms-customer-support-node/',
            activeOn: '/tutorials/sms-customer-support-node/',
            name: 'SMS customer support',
          },
          {
            route: '/tutorials/sms-server-alerts-node/',
            activeOn: '/tutorials/sms-server-alerts-node/',
            name: 'SMS server slerts',
          },
          {
            route: '/tutorials/masked-numbers-node/',
            activeOn: '/tutorials/masked-numbers-node/',
            name: 'SMS Masked Phone Numbers',
          },
        ],
      },
      {
        route: '/api/sms-messaging/',
        activeOn: '/api/sms-messaging/',
        name: 'API Reference',
      },
      {
        route: '/docs/sms-messaging/troubleshooting/',
        activeOn: '/docs/sms-messaging/troubleshooting/',
        name: 'Troubleshooting',
      },
    ],
  },
  {
    noLink: true,
    route: '/docs/whatsapp/',
    activeOn: '/docs/whatsapp/',
    name: 'WhatsApp',
    subMenu: [
      {
        noLink: true,
        route: '/docs/conversations/whatsapp/',
        activeOn: '/docs/conversations/whatsapp/',
        name: 'Quickstarts',
        subMenu: [
          {
            route: '/docs/whatsapp/wb-overview/',
            activeOn: '/docs/whatsapp/wb-overview/',
            name: 'Overview',
          },
          {
            route: '/docs/whatsapp/wb-deepdive/',
            activeOn: '/docs/whatsapp/wb-deepdive/',
            name: 'Deep dive',
          },
          {
            route: '/docs/whatsapp/getting-started/',
            activeOn: '/docs/whatsapp/getting-started/',
            name: 'Getting started',
          },
          {
            route: '/docs/whatsapp/getting-started-sandbox/',
            activeOn: '/docs/whatsapp/getting-started-sandbox/',
            name: 'WhatsApp Sandbox',
          },
          {
            route: '/docs/whatsapp/install-channel-whatsapp/',
            activeOn: '/docs/whatsapp/install-channel-whatsapp/',
            name: 'Install channel: WhatsApp',
          },
          {
            route: '/docs/whatsapp/message-templates-curl/',
            activeOn: '/docs/whatsapp/message-templates/',
            name: 'Template Messages',
          },
          {
            route: '/docs/whatsapp/send-your-first-whatsapp-message-curl/',
            activeOn: '/docs/whatsapp/send-your-first-whatsapp-message/',
            name: 'Send a WhatsApp message',
          },
          {
            route: '/docs/whatsapp/number-quality-ratings/',
            activeOn: '/docs/whatsapp/number-quality-ratings/',
            name: 'Number quality ratings',
          },
          {
            route: '/docs/whatsapp/whatsapp-customer-optins/',
            activeOn: '/docs/whatsapp/whatsapp-customer-optins/',
            name: 'Guide for customer opt-ins',
          },
          {
            route: '/docs/whatsapp/number-migration/',
            activeOn: '/docs/whatsapp/number-migration/',
            name: 'Migrating a phone number',
          },
          {
            route: '/docs/whatsapp/troubleshooting-whatsapp/',
            activeOn: '/docs/whatsapp/troubleshooting-whatsapp/',
            name: 'Troubleshooting WhatsApp',
          },
        ],
      },
      {
        noLink: true,
        route: '/tutorials/',
        activeOn: '/tutorials/',
        name: 'Tutorials',
        subMenu: [
          {
            route: '/tutorials/whatsapp-bot-go',
            activeOn: '/tutorials/whatsapp-bot-go/',
            name: 'Building a WhatsApp bot',
          },
        ],
      },
      {
        route: '/api/conversations/',
        activeOn: '/api/conversations/',
        name: 'Full API Reference',
      },
    ],
  },
  {
    noLink: true,
    route: '/docs/voice-calling/',
    activeOn: '/docs/voice-calling/',
    name: 'Voice Calling',
    subMenu: [
      {
        noLink: true,
        route: '/docs/voice-calling/quickstarts/',
        activeOn: '/docs/voice-calling/quickstarts/',
        name: 'Quickstarts',
        subMenu: [
          {
            route: '/docs/voice-calling/test-credits-api-keys/',
            activeOn: '/docs/voice-calling/test-credits-api-keys/',
            name: 'Test Credits & API Keys',
          },
          {
            route: '/docs/voice-calling/make-an-outbound-call-curl/',
            activeOn: '/docs/voice-calling/make-an-outbound-call/',
            name: 'Make Your First Voice Call',
          },
          {
            route: '/docs/voice-calling/handle-inbound-calls/',
            activeOn: '/docs/voice-calling/handle-inbound-calls/',
            name: 'Handle Inbound Voice Calls',
          },
          {
            route: '/docs/voice-calling/SIP-trunking/',
            activeOn: '/docs/voice-calling/SIP-trunking/',
            name: 'SIP Trunking',
          },
        ],
      },
      {
        route: '/tutorials/',
        activeOn: '/tutorials/',
        name: 'Tutorials',
        subMenu: [
          {
            route: '/tutorials/make-call-php/',
            activeOn: '/tutorials/make-call-php/',
            name: 'Making Voice Calls',
          },
          {
            route: '/tutorials/voice-message-java/',
            activeOn: '/tutorials/voice-message-java/',
            name: 'Sending Voice Messages',
          },
          {
            route: '/tutorials/handle-incoming-calls-and-sms/',
            activeOn: '/tutorials/handle-incoming-calls-and-smsa/',
            name: 'Handling Incoming Voice Calls',
          },
          {
            route: '/tutorials/account-security-voice-node/',
            activeOn: '/tutorials/account-security-voice-node/',
            name: 'Voice Two-Factor Authentication (2FA)',
          },
          {
            route: '/tutorials/masked-numbers-node/',
            activeOn: '/tutorials/masked-numbers-node/',
            name: 'Masked Phone Numbers',
          },
          {
            route: '/tutorials/automated-voice-survey-node/',
            activeOn: '/tutorials/automated-voice-survey-node/',
            name: 'Automated Voice Surveys',
          },
          {
            route: '/tutorials/call-screening-curl/',
            activeOn: '/tutorials/call-screening-curl/',
            name: 'Call Screening',
          },
          {
            route: '/tutorials/warm-transfers-curl/',
            activeOn: '/tutorials/warm-transfers-curl/',
            name: 'Warm Transfers',
          },
          {
            route: '/tutorials/call-recording-curl/',
            activeOn: '/tutorials/call-recording-curl/',
            name: 'Recording Voice Calls',
          },
          {
            route: '/tutorials/call-info-curl/',
            activeOn: '/tutorials/call-info-curl/',
            name: 'Getting Call Information',
          },
          {
            route: '/tutorials/voice-recording-curl/',
            activeOn: '/tutorials/voice-recording-curl/',
            name: 'Recording Voice Messages',
          },
          {
            route: '/tutorials/ivr-curl/',
            activeOn: '/tutorials/ivr-curl/',
            name: 'Building an IVR System',
          },
          {
            route: '/tutorials/call-transfer-curl/',
            activeOn: '/tutorials/call-transfer-curl/',
            name: 'Transferring Voice Calls',
          },
          {
            route: '/tutorials/transcription-curl/',
            activeOn: '/tutorials/transcription-curl/',
            name: 'Transcription of Voice Recordings',
          },
        ],
      },
      {
        route: '/api/voice-calling/',
        activeOn: '/api/voice-calling/',
        name: 'Full API Reference',
      },
      {
        route: '/docs/voice-calling/troubleshooting/',
        activeOn: '/docs/voice-calling/troubleshooting/',
        name: 'Troubleshooting',
      },
    ],
  },
  {
    noLink: true,
    route: '/docs/voice-messaging/',
    activeOn: '/docs/voice-messaging/',
    name: 'Voice Messaging',
    subMenu: [
      {
        noLink: true,
        route: '/docs/voice-messaging/quickstarts/',
        activeOn: '/docs/voice-messaging/quickstarts/',
        name: 'Quickstarts',
        subMenu: [
          {
            route: '/docs/voice-messaging/test-credits-api-keys/',
            activeOn: '/docs/voice-messaging/test-credits-api-keys/',
            name: 'Test Credits & API Keys',
          },
          {
            route: '/docs/voice-messaging/send-outbound-voice-message-curl/',
            activeOn: '/docs/voice-messaging/send-outbound-voice-message/',
            name: 'Send Text To Speech Message',
          },
        ],
      },
      {
        route: '/api/voice-messaging/',
        activeOn: '/api/voice-messaging/',
        name: 'Full API Reference',
      },
      {
        route: '/docs/voice-messaging/troubleshooting/',
        activeOn: '/docs/voice-messaging/troubleshooting/',
        name: 'Troubleshooting',
      },
    ],
  },
  // Conversations
  {
    noLink: true,
    route: '/docs/conversations/',
    activeOn: '/docs/conversations/',
    name: 'Conversations',
    label: <MenuPill type="beta">Beta</MenuPill>,
    subMenu: [
      {
        noLink: true,
        route: '/docs/conversations/quickstarts/',
        activeOn: '/docs/conversations/quickstarts/',
        name: 'Quickstarts',
        subMenu: [
          {
            noLink: true,
            route: '/docs/conversations/sms/',
            activeOn: '/docs/conversations/sms/',
            name: 'SMS',
            subMenu: [
              {
                route: '/docs/conversations/test-credits-api-keys/',
                activeOn: '/docs/conversations/test-credits-api-keys/',
                name: 'Test Credits & API Keys',
              },
              {
                route: '/docs/conversations/install-channel/',
                activeOn: '/docs/conversations/install-channel/',
                name: 'Install Channel: SMS',
              },
              {
                route: '/docs/conversations/handle-inbound-messages-curl/',
                activeOn: '/docs/conversations/handle-inbound-messages/',
                name: 'Receiving Messages',
              },
              {
                route: '/docs/conversations/send-messages-curl/',
                activeOn: '/docs/conversations/send-messages/',
                name: 'Sending Messages',
              },
              {
                route: '/docs/conversations/reply-to-messages-curl/',
                activeOn: '/docs/conversations/reply-to-messages/',
                name: 'Replying to Messages',
              },
            ],
          },
          {
            noLink: true,
            route: '/docs/conversations/whatsapp/',
            activeOn: '/docs/conversations/whatsapp/',
            name: 'WhatsApp Business',
            subMenu: [
              {
                route: '/docs/whatsapp/wb-overview/',
                activeOn: '/docs/whatsapp/wb-overview/',
                name: 'Overview',
              },
              {
                route: '/docs/whatsapp/wb-deepdive/',
                activeOn: '/docs/whatsapp/wb-deepdive/',
                name: 'Deep dive',
              },
              {
                route: '/docs/whatsapp/getting-started/',
                activeOn: '/docs/whatsapp/getting-started/',
                name: 'Getting started',
              },
              {
                route: '/docs/whatsapp/getting-started-sandbox/',
                activeOn: '/docs/whatsapp/getting-started-sandbox/',
                name: 'WhatsApp Sandbox',
              },
              {
                route: '/docs/whatsapp/install-channel-whatsapp/',
                activeOn: '/docs/whatsapp/install-channel-whatsapp/',
                name: 'Install channel: WhatsApp',
              },
              {
                route: '/docs/whatsapp/message-templates-curl/',
                activeOn: '/docs/whatsapp/message-templates/',
                name: 'Template Messages',
              },
              {
                route: '/docs/whatsapp/send-your-first-whatsapp-message-curl/',
                activeOn: '/docs/whatsapp/send-your-first-whatsapp-message/',
                name: 'Send a WhatsApp message',
              },
              {
                route: '/docs/whatsapp/number-quality-ratings/',
                activeOn: '/docs/whatsapp/number-quality-ratings/',
                name: 'Number quality ratings',
              },
              {
                route: '/docs/whatsapp/whatsapp-customer-optins/',
                activeOn: '/docs/whatsapp/whatsapp-customer-optins/',
                name: 'Guide for customer opt-ins',
              },
              {
                route: '/docs/whatsapp/number-migration/',
                activeOn: '/docs/whatsapp/number-migration/',
                name: 'Migrating a phone number',
              },
              {
                route: '/docs/whatsapp/troubleshooting-whatsapp/',
                activeOn: '/docs/whatsapp/troubleshooting-whatsapp/',
                name: 'Troubleshooting WhatsApp',
              },
            ],
          },
          {
            noLink: true,
            route: '/docs/conversations/messenger/',
            activeOn: '/docs/conversations/messenger/',
            name: 'Facebook Messenger',
            subMenu: [
              {
                route: '/docs/conversations/messenger-optins/',
                activeOn: '/docs/conversations/messenger-optins/',
                name: 'Guide for Customer Opt-ins',
              },
            ],
          },
          {
            noLink: true,
            route: '/docs/conversations/email/',
            activeOn: '/docs/conversations/email/',
            name: 'Email',
            label: <MenuPill>Early Access</MenuPill>,
            subMenu: [
              {
                route: '/docs/conversations/install-channel-email/',
                activeOn: '/docs/conversations/install-channel-email/',
                name: 'Install Channel: Email',
              },
              {
                route: '/docs/conversations/ip-warmup/',
                activeOn: '/docs/conversations/ip-warmup/',
                name: 'IP Warm-up',
              },
            ],
          },
          {
            noLink: true,
            route: '/docs/conversations/wechat/',
            activeOn: '/docs/conversations/wechat/',
            name: 'WeChat',
            subMenu: [
              {
                route: '/docs/conversations/install-channel-wechat/',
                activeOn: '/docs/conversations/install-channel-wechat/',
                name: 'Install Channel: WeChat',
              },
            ],
          },
          {
            noLink: true,
            route: '/docs/conversations/line/',
            activeOn: '/docs/conversations/line/',
            name: 'LINE',
            subMenu: [
              {
                route: '/docs/conversations/install-channel-line/',
                activeOn: '/docs/conversations/install-channel-line/',
                name: 'Install Channel: LINE',
              },
            ],
          },
          {
            noLink: true,
            route: '/docs/conversations/telegram/',
            activeOn: '/docs/conversations/telegram/',
            name: 'Telegram',
            subMenu: [
              {
                route: '/docs/conversations/install-channel-telegram/',
                activeOn: '/docs/conversations/install-channel-telegram/',
                name: 'Install Channel: Telegram',
              },
            ],
          },
        ],
      },
      {
        noLink: true,
        route: '/tutorials/',
        activeOn: '/tutorials/',
        name: 'Tutorials',
        subMenu: [
          {
            route: '/tutorials/whatsapp-bot-go/',
            activeOn: '/tutorials/whatsapp-bot-go/',
            name: 'Bulding a WhatsApp Bot',
          },
        ],
      },
      {
        route: '/api/conversations/',
        activeOn: '/api/conversations/',
        name: 'Full API Reference',
      },
      {
        route: '/docs/conversations/troubleshooting/',
        activeOn: '/docs/conversations/troubleshooting/',
        name: 'Troubleshooting',
      },
    ],
  },
  {
    noLink: true,
    route: '/docs/numbers/',
    activeOn: '/docs/numbers/',
    name: 'Numbers',
    label: <MenuPill type="beta">Beta</MenuPill>,
    subMenu: [
      {
        noLink: true,
        route: '/docs/numbers/quickstarts/',
        activeOn: '/docs/numbers/quickstarts/',
        name: 'Quickstarts',
        subMenu: [
          {
            route: '/docs/numbers/buy-numbers',
            activeOn: '/docs/numbers/buy-numbers/',
            name: 'Buy a number',
          },
          {
            route: '/docs/numbers/restrictions-numbers/',
            activeOn: '/docs/numbers/restrictions-numbers/',
            name: 'Number restrictions',
          },
        ],
      },
      {
        route: '/api/numbers/',
        activeOn: '/api/numbers/',
        name: 'Full API Reference',
      },
    ],
  },
  {
    noLink: true,
    route: '/docs/verify/',
    activeOn: '/docs/verify/',
    name: 'Verify',
    subMenu: [
      {
        noLink: true,
        route: '/docs/verify/quickstarts/',
        activeOn: '/docs/verify/quickstarts/',
        name: 'Quickstarts',
        subMenu: [
          {
            route: '/docs/verify/test-credits-api-keys/',
            activeOn: '/docs/verify/test-credits-api-keys/',
            name: 'Test Credits & API Keys',
          },
          {
            route: '/docs/verify/send-a-verification-token-curl/',
            activeOn: '/docs/verify/send-a-verification-token/',
            name: 'Send Your First Verify Token',
          },
          {
            route: '/docs/verify/verify-a-token-curl/',
            activeOn: '/docs/verify/verify-a-token/',
            name: 'Verify a Token',
          },
        ],
      },
      {
        noLink: true,
        route: '/tutorials/',
        activeOn: '/tutorials/',
        name: 'Tutorials',
        subMenu: [
          {
            route: '/tutorials/verify-node/',
            activeOn: '/tutorials/verify-node/',
            name: 'SMS Two-Factor Authentication (2FA)',
          },
          {
            route: '/tutorials/account-security-voice-node/',
            activeOn: '/tutorials/account-security-voice-node/',
            name: 'Voice Two-Factor Authentication (2FA)',
          },
        ],
      },
      {
        route: '/api/verify/',
        activeOn: '/api/verify/',
        name: 'Full API Reference',
      },
      {
        route: '/docs/verify/troubleshooting/',
        activeOn: '/docs/verify/troubleshooting/',
        name: 'Troubleshooting',
      },
    ],
  },
  {
    noLink: true,
    route: '/docs/hlr/',
    activeOn: '/docs/hlr/',
    name: 'HLR',
    subMenu: [
      {
        route: '/api/hlr/',
        activeOn: '/api/hlr/',
        name: 'Full API Reference',
      },
    ],
  },
  {
    noLink: true,
    route: '/docs/contacts/',
    activeOn: '/docs/contacts/',
    name: 'Contacts',
    subMenu: [
      {
        route: '/api/contacts/',
        activeOn: '/api/contacts/',
        name: 'Full API Reference',
      },
    ],
  },
  {
    noLink: true,
    route: '/docs/groups/',
    activeOn: '/docs/groups/',
    name: 'Groups',
    subMenu: [
      {
        route: '/api/groups/',
        activeOn: '/api/groups/',
        name: 'Full API Reference',
      },
    ],
  },
  {
    noLink: true,
    route: '/docs/lookup/',
    activeOn: '/docs/lookup/',
    name: 'Lookup',
    subMenu: [
      {
        noLink: true,
        route: '/docs/lookup/quickstarts/',
        activeOn: '/docs/lookup/quickstarts/',
        name: 'Quickstarts',
        subMenu: [
          {
            route: '/docs/lookup/request-a-lookup/',
            activeOn: '/docs/lookup/request-a-lookup/',
            name: 'Request a Lookup',
          },
          {
            route: '/docs/lookup/request-an-hlr/',
            activeOn: '/docs/lookup/request-an-hlr/',
            name: 'Request an HLR',
          },
          {
            route: '/docs/lookup/view-an-hlr/',
            activeOn: '/docs/lookup/view-an-hlr/',
            name: 'View an HLR',
          },
        ],
      },
      {
        route: '/api/lookup/',
        activeOn: '/api/lookup/',
        name: 'Full API Reference',
      },
      {
        route: '/docs/lookup/troubleshooting/',
        activeOn: '/docs/lookup/troubleshooting/',
        name: 'Troubleshooting',
      },
    ],
  },
  {
    noLink: true,
    route: '/docs/balance/',
    activeOn: '/docs/balance/',
    name: 'Balance',
    subMenu: [
      {
        route: '/api/balance/',
        activeOn: '/api/balance/',
        name: 'Full API Reference',
      },
    ],
  },
  {
    noLink: true,
    route: '/docs/mms-messaging/',
    name: 'MMS',
    subMenu: [
      {
        noLink: true,
        route: '/docs/mms-messaging/quickstarts/',
        name: 'Quickstarts',
        subMenu: [
          {
            route: '/docs/mms-messaging/test-credits-api-keys/',
            name: 'Test Credits & API Keys',
          },
          {
            route: '/docs/mms-messaging/send-outbound-mms/',
            name: 'Send Your First MMS',
          },
        ],
      },
      {
        route: '/api/mms-messaging/',
        name: 'Full API Reference',
      },
      {
        route: '/docs/mms-messaging/troubleshooting/',
        name: 'Troubleshooting',
      },
    ],
  },
];

export const apisNavigation = [
  {
    route: '/api/',
    activeOn: '/api/',
    name: 'Overview',
    subMenu: [
      {
        route: '/api/#messagebird-api-reference',
        activeOn: '/api/#messagebird-api-reference',
        name: 'Introduction',
      },
      {
        route: '/api/#sdks',
        activeOn: '/api/#sdks',
        name: 'SDKs',
      },
      {
        route: '/api/#api-endpoint',
        activeOn: '/api/#api-endpoint',
        name: 'API Endpoint',
      },
      {
        route: '/api/#authentication',
        activeOn: '/api/#authentication',
        name: 'Authentication',
      },
      {
        route: '/api/#ip-addresses',
        activeOn: '/api/#ip-addresses',
        name: 'IP Addresses',
      },
      {
        route: '/api/#api-access-key-security',
        activeOn: '/api/#api-access-key-security',
        name: 'API Access Key Security',
      },
      {
        route: '/api/#api-errors',
        activeOn: '/api/#api-errors',
        name: 'API Errors',
      },
      {
        route: '/api/#requests',
        activeOn: '/api/#requests',
        name: 'Requests',
      },
      {
        route: '/api/#http-methods',
        activeOn: '/api/#http-methods',
        name: 'HTTP Methods',
      },
      {
        route: '/api/#verifying-http-requests',
        activeOn: '/api/#verifying-http-requests',
        name: 'Verifying Requests',
        subMenu: [
          {
            route: '/api/#what-is-request-signing?',
            activeOn: '/api/#what-is-request-signing?',
            name: 'Request signing',
          },
          {
            route: '/api/#what-if-my-signing-secret-has-been-compromised?',
            activeOn: '/api/#what-if-my-signing-secret-has-been-compromised?',
            name: 'Regenerate',
          },
          {
            route: '/api/#signature-details',
            activeOn: '/api/#signature-details',
            name: 'Signature Details',
          },
          {
            route: '/api/#signature-usage',
            activeOn: '/api/#signature-usage',
            name: 'Usage',
          },
        ],
      },
    ],
  },
  {
    activeOn: '/api/sms-messaging/',
    route: '/api/sms-messaging/',
    name: 'SMS',
    subMenu: [
      {
        route: '/api/sms-messaging/#sms-api',
        activeOn: '/api/sms-messaging/#sms-api',
        name: 'Overview',
      },
      {
        route: '/api/sms-messaging/#api-endpoint',
        activeOn: '/api/sms-messaging/#api-endpoint',
        name: 'API Endpoint',
      },
      {
        route: '/api/sms-messaging/#available-http-methods',
        activeOn: '/api/sms-messaging/#available-http-methods',
        name: 'HTTP methods',
      },
      {
        route: '/api/sms-messaging/#rate-limiting',
        activeOn: '/api/sms-messaging/#rate-limiting',
        name: 'Rate Limiting',
      },
      {
        route: '/api/sms-messaging/#the-message-object',
        activeOn: '/api/sms-messaging/#the-message-object',
        name: 'Object',
      },
      {
        route: '/api/sms-messaging/#send-outbound-sms',
        activeOn: '/api/sms-messaging/#send-outbound-sms',
        name: 'Send SMS',
      },
      {
        route: '/api/sms-messaging/#handle-a-status-report',
        activeOn: '/api/sms-messaging/#handle-a-status-report',
        name: 'Handle SMS status',
      },
      {
        route: '/api/sms-messaging/#receive-and-handle-inbound-sms',
        activeOn: '/api/sms-messaging/#receive-and-handle-inbound-sms',
        name: 'Receive SMS',
      },
      {
        route: '/api/sms-messaging/#view-an-sms',
        activeOn: '/api/sms-messaging/#view-an-sms',
        name: 'View',
      },
      {
        route: '/api/sms-messaging/#list-messages',
        activeOn: '/api/sms-messaging/#list-messages',
        name: 'List messsages',
      },
      {
        route: '/api/sms-messaging/#list-scheduled-messages',
        activeOn: '/api/sms-messaging/#list-scheduled-messages',
        name: 'List scheduled',
      },
      {
        route: '/api/sms-messaging/#sms-statuses',
        activeOn: '/api/sms-messaging/#sms-statuses',
        name: 'SMS Statuses',
        subMenu: [
          {
            route: '/api/sms-messaging/#sms-status-information-hierarchy',
            activeOn: '/api/sms-messaging/#sms-status-information-hierarchy',
            name: 'Hierarchy',
          },
          {
            route: '/api/sms-messaging/#sms-error-codes',
            activeOn: '/api/sms-messaging/#sms-error-codes',
            name: 'Error Codes',
          },
        ],
      },
      {
        route: '/api/sms-messaging/#smpp',
        activeOn: '/api/sms-messaging/#smpp',
        name: 'SMPP',
        subMenu: [
          {
            route: '/api/sms-messaging/#the-smpp-servers',
            activeOn: '/api/sms-messaging/#the-smpp-servers',
            name: 'SMPP servers',
          },
          {
            route: '/api/sms-messaging/#supported-pdus',
            activeOn: '/api/sms-messaging/#supported-pdus',
            name: 'Supported PDUs',
          },
        ],
      },
    ],
  },
  {
    activeOn: '/api/sms-batch-api/',
    route: '/api/sms-batch-api/',
    name: 'SMS batch API',
  },
  {
    route: '/api/voice-calling/',
    activeOn: '/api/voice-calling/',
    name: 'Voice Calling',
    subMenu: [
      {
        route: '/api/voice-calling/#voice-calling-api',
        activeOn: '/api/voice-calling/#voice-calling-api',
        name: 'Overview',
      },
      {
        route: '/api/voice-calling/#requests',
        activeOn: '/api/voice-calling/#requests',
        name: 'Requests/responses',
      },
      {
        route: '/api/voice-calling/#call-flows',
        activeOn: '/api/voice-calling/#call-flows',
        name: 'Call flows',
      },
      {
        route: '/api/voice-calling/#dynamic-call-flows',
        activeOn: '/api/voice-calling/#dynamic-call-flows',
        name: 'Dynamic call flows',
      },
      {
        route: '/api/voice-calling/#steps',
        activeOn: '/api/voice-calling/#steps',
        name: 'Call flow steps',
      },
      {
        route: '/api/voice-calling/#calls',
        activeOn: '/api/voice-calling/#calls',
        name: 'Calls',
      },
      {
        route: '/api/voice-calling/#legs',
        activeOn: '/api/voice-calling/#legs',
        name: 'Legs',
      },
      {
        route: '/api/voice-calling/#recordings',
        activeOn: '/api/voice-calling/#recordings',
        name: 'Recordings',
      },
      {
        route: '/api/voice-calling/#transcriptions',
        activeOn: '/api/voice-calling/#transcriptions',
        name: 'Transcriptions',
      },
      {
        route: '/api/voice-calling/#numbers',
        activeOn: '/api/voice-calling/#numbers',
        name: 'Numbers',
      },
      {
        route: '/api/voice-calling/#webhooks',
        activeOn: '/api/voice-calling/#webhooks',
        name: 'Webhooks',
      },
      {
        route: '/api/voice-calling/#callbacks',
        activeOn: '/api/voice-calling/#callbacks',
        name: 'Callbacks',
      },
    ],
  },
  {
    name: 'Voice Messaging',
    route: '/api/voice-messaging/',
    activeOn: '/api/voice-messaging/',
    subMenu: [
      {
        route: '/api/voice-messaging/#voice-messaging-api',
        activeOn: '/api/voice-messaging/#voice-messaging-api',
        name: 'Overview',
      },
      {
        route: '/api/voice-messaging/#the-voice-message-object',
        activeOn: '/api/voice-messaging/#the-voice-message-object',
        name: 'Object',
      },
      {
        route: '/api/voice-messaging/#the-voice-body',
        activeOn: '/api/voice-messaging/#the-voice-body',
        name: 'Body',
      },
      {
        route: '/api/voice-messaging/#send-a-voice-message',
        activeOn: '/api/voice-messaging/#send-a-voice-message',
        name: 'Send',
      },
      {
        route: '/api/voice-messaging/#view-a-voice-message',
        activeOn: '/api/voice-messaging/#view-a-voice-message',
        name: 'View',
      },
      {
        route: '/api/voice-messaging/#list-voice-messages',
        activeOn: '/api/voice-messaging/#list-voice-messages',
        name: 'List messages',
      },
      {
        route: '/api/voice-messaging/#handle-a-status-report',
        activeOn: '/api/voice-messaging/#handle-a-status-report',
        name: 'Handle status',
      },
    ],
  },
  {
    route: '/api/conversations/',
    activeOn: '/api/conversations/',
    name: 'Conversations API',
    subMenu: [
      {
        route: '/api/conversations/#base-url',
        activeOn: '/api/conversations/#base-url',
        name: 'Base URL',
      },
      {
        route: '/api/conversations/#authorization',
        activeOn: '/api/conversations/#authorization',
        name: 'Authorization',
      },
      {
        route: '/api/conversations/#formats-and-headers',
        activeOn: '/api/conversations/#formats-and-headers',
        name: 'Formats and Headers',
      },
      {
        route: '/api/conversations/#rate-limits',
        activeOn: '/api/conversations/#rate-limits',
        name: 'Rate Limits',
      },
      {
        route: '/api/conversations/#errors',
        activeOn: '/api/conversations/#errors',
        name: 'Errors',
      },
      {
        route: '/api/conversations/#sending-messages',
        activeOn: '/api/conversations/#sending-messages',
        name: 'Sending Messages',
        subMenu: [
          {
            route: '/api/conversations/#start-conversation',
            activeOn: '/api/conversations/#start-conversation',
            name: 'Start Conversation',
          },
          {
            route: '/api/conversations/#reply-conversation',
            activeOn: '/api/conversations/#reply-conversation',
            name: 'Reply Conversation',
          },
          {
            route: '/api/conversations/#send-message',
            activeOn: '/api/conversations/#send-message',
            name: 'Send Message',
          },
          {
            route: '/api/conversations/#fallback-messages',
            activeOn: '/api/conversations/#fallback-messages',
            name: 'Fallback Messages',
          },
        ],
      },
      {
        route: '/api/conversations/#receiving-messages-and-status-updates',
        activeOn: '/api/conversations/#receiving-messages-and-status-updates',
        name: 'Receiving Messages and Status Updates',
        subMenu: [
          {
            route: '/api/conversations/#webhooks',
            activeOn: '/api/conversations/#webhooks',
            name: 'Webhooks',
          },
          {
            route: '/api/conversations/#status-update-per-message',
            activeOn: '/api/conversations/#status-update-per-message',
            name: 'Status Update per Message',
          },
        ],
      },
      {
        route: '/api/conversations/#conversations',
        activeOn: '/api/conversations/#conversations',
        name: 'Conversations',
        subMenu: [
          {
            route: '/api/conversations/#list-conversations',
            activeOn: '/api/conversations/#list-conversations',
            name: 'List Conversations',
          },
          {
            route: '/api/conversations/#get-conversation',
            activeOn: '/api/conversations/#get-conversation',
            name: 'Get Conversation ',
          },
          {
            route: '/api/conversations/#update-conversation',
            activeOn: '/api/conversations/#update-conversation',
            name: 'Update Conversation',
          },
          {
            route: '/api/conversations/#get-conversations-by-contact',
            activeOn: '/api/conversations/#get-conversations-by-contact',
            name: 'Get by Contact',
          },
        ],
      },
      {
        route: '/api/conversations/#messages',
        activeOn: '/api/conversations/#messages',
        name: 'Messages',
        subMenu: [
          {
            route: '/api/conversations/#get-messages-in-conversation',
            activeOn: '/api/conversations/#get-messages-in-conversation',
            name: 'Get Messages in Conversation',
          },
          {
            route: '/api/conversations/#get-message',
            activeOn: '/api/conversations/#get-message',
            name: 'Get Message',
          },
          {
            route: '/api/conversations/#list-messages',
            activeOn: '/api/conversations/#list-messages',
            name: 'List Messages',
          },
        ],
      },
      {
        route: '/api/conversations/#objects-reference',
        activeOn: '/api/conversations/#objects-reference',
        name: 'Objects Reference',
        subMenu: [
          {
            route: '/api/conversations/#conversation-object',
            activeOn: '/api/conversations/#conversation-object',
            name: 'Conversation',
          },
          {
            route: '/api/conversations/#contact-object',
            activeOn: '/api/conversations/#contact-object',
            name: 'Contact',
          },
          {
            route: '/api/conversations/#channel-object',
            activeOn: '/api/conversations/#channel-object',
            name: 'Channel',
          },
          {
            route: '/api/conversations/#channelstatus-object',
            activeOn: '/api/conversations/#channelstatus-object',
            name: 'ChannelStatus',
          },
          {
            route: '/api/conversations/#messages-object',
            activeOn: '/api/conversations/#messages-object',
            name: 'Messages',
          },
          {
            route: '/api/conversations/#webhook-object',
            activeOn: '/api/conversations/#webhook-object',
            name: 'Webhook',
          },
          {
            route: '/api/conversations/#webhookevents-object',
            activeOn: '/api/conversations/#webhookevents-object',
            name: 'WebhookEvents',
          },
          {
            route: '/api/conversations/#webhookstatus-object',
            activeOn: '/api/conversations/#webhookstatus-object',
            name: 'WebhookStatus',
          },
          {
            route: '/api/conversations/#webhooksettings-object',
            activeOn: '/api/conversations/#webhooksettings-object',
            name: 'WebhookSettings',
          },
          {
            route: '/api/conversations/#message-object',
            activeOn: '/api/conversations/#message-object',
            name: 'Message',
          },
          {
            route: '/api/conversations/#platform-object',
            activeOn: '/api/conversations/#platform-object',
            name: 'Platform',
          },
          {
            route: '/api/conversations/#messagecontent-object',
            activeOn: '/api/conversations/#messagecontent-object',
            name: 'MessageContent',
          },
          {
            route: '/api/conversations/#messagerecipient-object',
            activeOn: '/api/conversations/#messagerecipient-object',
            name: 'MessageRecipient',
          },
          {
            route: '/api/conversations/#fallback-object',
            activeOn: '/api/conversations/#fallback-object',
            name: 'Fallback',
          },
          {
            route: '/api/conversations/#media-object',
            activeOn: '/api/conversations/#media-object',
            name: 'Media',
          },
          {
            route: '/api/conversations/#location-object',
            activeOn: '/api/conversations/#location-object',
            name: 'Location',
          },
          {
            route: '/api/conversations/#buttons-object',
            activeOn: '/api/conversations/#buttons-object',
            name: 'Buttons',
          },
          {
            route: '/api/conversations/#messagehsm-object',
            activeOn: '/api/conversations/#messagehsm-object',
            name: 'MessageHSM',
          },
          {
            route: '/api/conversations/#hsmlanguage-object',
            activeOn: '/api/conversations/#hsmlanguage-object',
            name: 'HSMLanguage',
          },
          {
            route: '/api/conversations/#hsmlocalizableparameters-object',
            activeOn: '/api/conversations/#hsmlocalizableparameters-object',
            name: 'HSMLocalizableParameters',
          },
          {
            route: '/api/conversations/#messagecomponent-object',
            activeOn: '/api/conversations/#messagecomponent-object',
            name: 'MessageComponent',
          },
          {
            route: '/api/conversations/#messageparam-object',
            activeOn: '/api/conversations/#messageparam-object',
            name: 'MessageParam',
          },
          {
            route: '/api/conversations/#templatemediatype-object',
            activeOn: '/api/conversations/#templatemediatype-object',
            name: 'TemplateMediaType',
          },
          {
            route: '/api/conversations/#hsmcurrency-object',
            activeOn: '/api/conversations/#hsmcurrency-object',
            name: 'HSMCurrency',
          },
          {
            route: '/api/conversations/#messagetag-object',
            activeOn: '/api/conversations/#messagetag-object',
            name: 'MessageTag',
          },
          {
            route: '/api/conversations/#replyto-object',
            activeOn: '/api/conversations/#replyto-object',
            name: 'ReplyTo',
          },
          {
            route: '/api/conversations/#email-object',
            activeOn: '/api/conversations/#email-object',
            name: 'Email',
          },
          {
            route: '/api/conversations/#emailcontent-object',
            activeOn: '/api/conversations/#emailcontent-object',
            name: 'EmailContent',
          },
          {
            route: '/api/conversations/#emailrecipient-object',
            activeOn: '/api/conversations/#emailrecipient-object',
            name: 'EmailRecipient',
          },
          {
            route: '/api/conversations/#emailtracking-object',
            activeOn: '/api/conversations/#emailtracking-object',
            name: 'EmailTracking',
          },
          {
            route: '/api/conversations/#emailattachment-object',
            activeOn: '/api/conversations/#emailattachment-object',
            name: 'EmailAttachment',
          },
          {
            route: '/api/conversations/#emailinlineimage-object',
            activeOn: '/api/conversations/#emailinlineimage-object',
            name: 'EmailInlineImage',
          },
          {
            route: '/api/conversations/#conversationstatus-object',
            activeOn: '/api/conversations/#conversationstatus-object',
            name: 'ConversationStatus',
          },
          {
            route: '/api/conversations/#messagestatus-object',
            activeOn: '/api/conversations/#messagestatus-object',
            name: 'MessageStatus',
          },
          {
            route: '/api/conversations/#messagetype-object',
            activeOn: '/api/conversations/#messagetype-object',
            name: 'MessageType',
          },
          {
            route: '/api/conversations/#messagedirection-object',
            activeOn: '/api/conversations/#messagedirection-object',
            name: 'MessageDirection',
          },
        ],
      },
      {
        route: '/api/conversations/#platforms',
        activeOn: '/api/conversations/#platforms',
        name: 'Platforms',
        subMenu: [
          {
            route: '/api/conversations/#supported-media-types-per-platform',
            activeOn: '/api/conversations/#supported-media-types-per-platform',
            name: 'Supported media types per platform',
          },
        ],
      },
    ],
  },
  {
    route: '/api/contactsv2/',
    activeOn: '/api/contactsv2/',
    name: 'Contacts API (v2)',
    subMenu: [
      {
        route: '/api/contactsv2/#endpoints-summary',
        activeOn: '/api/contactsv2/#endpoints-summary',
        name: 'Endpoints Summary',
      },
      {
        route: '/api/contactsv2/#the-contact-object',
        activeOn: '/api/contactsv2/#the-contact-object',
        name: 'The Contact object',
      },
      {
        route: '/api/contactsv2/#the-identifier-object',
        activeOn: '/api/contactsv2/#the-identifier-object',
        name: 'The Identifier object',
      },
      {
        route: '/api/contactsv2/#the-profile-object',
        activeOn: '/api/contactsv2/#the-profile-object',
        name: 'The Profile Object',
      },
      {
        route: '/api/contactsv2/#list-all-contacts',
        activeOn: '/api/contactsv2/#list-all-contacts',
        name: 'List all Contacts',
      },
      {
        route: '/api/contactsv2/#create-a-contact',
        activeOn: '/api/contactsv2/#create-a-contact',
        name: 'Create a Contact',
      },
      {
        route: '/api/contactsv2/#view-a-contact',
        activeOn: '/api/contactsv2/#view-a-contact',
        name: 'View a Contact',
      },
      {
        route: '/api/contactsv2/#update-a-contact',
        activeOn: '/api/contactsv2/#update-a-contact',
        name: 'Update a Contact',
      },
      {
        route: '/api/contactsv2/#update-or-create-contact',
        activeOn: '/api/contactsv2/#update-or-create-contact',
        name: 'Update or Create Contact',
      },
      {
        route: '/api/contactsv2/#delete-a-contact',
        activeOn: '/api/contactsv2/#delete-a-contact',
        name: 'Delete a Contact',
      },
      {
        route: '/api/contactsv2/#merge-contacts',
        activeOn: '/api/contactsv2/#merge-contacts',
        name: 'Merge contacts',
      },
      {
        route: '/api/contactsv2/#list-all-identifiers-of-a-contact',
        activeOn: '/api/contactsv2/#list-all-identifiers-of-a-contact',
        name: 'List all identifiers of a contact',
      },
      {
        route: '/api/contactsv2/#get-a-specific-identifier-of-a-contact',
        activeOn: '/api/contactsv2/#get-a-specific-identifier-of-a-contact',
        name: 'Get a specific identifier of a contact',
      },
      {
        route: '/api/contactsv2/#add-an-identifier-to-an-existing-contact',
        activeOn: '/api/contactsv2/#add-an-identifier-to-an-existing-contact',
        name: 'Add an Identifier to an existing Contact',
      },
      {
        route: '/api/contactsv2/#delete-an-identifier-from-a-contact',
        activeOn: '/api/contactsv2/#delete-an-identifier-from-a-contact',
        name: 'Delete an identifier from a contact',
      },
      {
        route: '/api/contactsv2/#import-contacts',
        activeOn: '/api/contactsv2/#import-contacts',
        name: 'Import Contacts',
      },
      {
        route: '/api/contactsv2/#track-a-imported-file',
        activeOn: '/api/contactsv2/#track-a-imported-file',
        name: 'Track an Imported File',
      },
    ],
  },
  {
    route: '/api/integrations/',
    activeOn: '/api/integrations/',
    name: 'Integrations API',
    subMenu: [
      {
        route: '/api/integrations/#templates',
        activeOn: '/api/integrations/#templates',
        name: 'Templates',
        subMenu: [
          {
            route: '/api/integrations/#create-template',
            activeOn: '/api/integrations/#create-template',
            name: 'Create template',
          },
          {
            route: '/api/integrations/#update-template',
            activeOn: '/api/integrations/#update-template',
            name: 'Update template',
          },
          {
            route: '/api/integrations/#list-templates',
            activeOn: '/api/integrations/#list-templates',
            name: 'List templates',
          },
          {
            route: '/api/integrations/#list-templates-by-name',
            activeOn: '/api/integrations/#list-templates-by-name',
            name: 'List templates by name',
          },
          {
            route: '/api/integrations/#fetch-template-by-name-and-language',
            activeOn: '/api/integrations/#fetch-template-by-name-and-language',
            name: 'Fetch template by name and language',
          },
          {
            route: '/api/integrations/#delete-templates-by-name',
            activeOn: '/api/integrations/#delete-templates-by-name',
            name: 'Delete templates by name',
          },
          {
            route: '/api/integrations/#delete-template-by-name-and-language',
            activeOn: '/api/integrations/#delete-template-by-name-and-language',
            name: 'Delete template by name and language',
          },
        ],
      },
      {
        route: '/api/integrations/#objects-reference',
        activeOn: '/api/integrations/#objects-reference',
        name: 'Objects reference',
      },
    ],
  },
  {
    route: '/api/file-storage/',
    activeOn: '/api/file-storage/',
    name: 'File Storage API',
    subMenu: [
      {
        route: '/api/file-storage/#upload-file',
        activeOn: '/api/file-storage/#upload-file',
        name: 'Upload file',
      },
      {
        route: '/api/file-storage/#fetch-file',
        activeOn: '/api/file-storage/#fetch-file',
        name: 'Fetch file',
      },
      {
        route: '/api/file-storage/#delete-file',
        activeOn: '/api/file-storage/#delete-file',
        name: 'Delete file',
      },
    ],
  },
  {
    activeOn: '/api/omnichannel-widget/',
    route: '/api/omnichannel-widget/',
    name: 'Omnichannel Widget',
  },
  {
    route: '/api/numbers/',
    activeOn: '/api/numbers/',
    name: 'Numbers',
    subMenu: [
      {
        route: '/api/numbers/#api-endpoint',
        activeOn: '/api/numbers/#api-endpoint',
        name: 'Overview',
      },
      {
        route: '/api/numbers/#authorization',
        activeOn: '/api/numbers/#authorization',
        name: 'Authorization',
      },
      {
        route: '/api/numbers/#formats-and-headers',
        activeOn: '/api/numbers/#formats-and-headers',
        name: 'Formats and Headers',
      },
      {
        route: '/api/numbers/#errors',
        activeOn: '/api/numbers/#errors',
        name: 'Errors',
      },
      {
        route: '/api/numbers/#searching-phone-numbers-available-for-purchase',
        activeOn:
          '/api/numbers/#searching-phone-numbers-available-for-purchase',
        name: 'Searching',
      },
      {
        route: '/api/numbers/#purchasing-a-phone-number',
        activeOn: '/api/numbers/#purchasing-a-phone-number',
        name: 'Purchasing',
      },
      {
        route: '/api/numbers/#fetching-all-purchased-phone-numbers',
        activeOn: '/api/numbers/#fetching-all-purchased-phone-numbers',
        name: 'Fetch all numbers',
      },
      {
        route: '/api/numbers/#fetching-a-purchased-phone-number',
        activeOn: '/api/numbers/#fetching-a-purchased-phone-number',
        name: 'Fetch a number',
      },
      {
        route: '/api/numbers/#updating-a-purchased-phone-number',
        activeOn: '/api/numbers/#updating-a-purchased-phone-number',
        name: 'Update',
      },
      {
        route: '/api/numbers/#searching-available-for-back-order-products',
        activeOn: '/api/numbers/#searching-available-for-back-order-products',
        name: 'Search available for back order products',
      },
      {
        route: '/api/numbers/#fetching-back-order-product-details',
        activeOn: '/api/numbers/#fetching-back-order-product-details',
        name: 'Fetching back order product details',
      },
      {
        route: '/api/numbers/#place-a-back-order',
        activeOn: '/api/numbers/#place-a-back-order',
        name: 'Place a back order',
      },
      {
        route: '/api/numbers/#fetching-a-placed-back-order',
        activeOn: '/api/numbers/#fetching-a-placed-back-order',
        name: 'Fetching a placed back order',
      },
      {
        route: '/api/numbers/#fetching-back-order-kyc-documents-requirements',
        activeOn:
          '/api/numbers/#fetching-back-order-kyc-documents-requirements',
        name: 'Fetching back order KYC documents requirements',
      },
      {
        route: '/api/numbers/#uploading-back-order-kyc-documents',
        activeOn: '/api/numbers/#uploading-back-order-kyc-documents',
        name: 'Uploading back order KYC documents',
      },
      {
        route: '/api/numbers/#fetching-back-order-eud-requirements',
        activeOn: '/api/numbers/#fetching-back-order-eud-requirements',
        name: 'Fetching back order EUD requirements',
      },
      {
        route: '/api/numbers/#uploading-back-order-eud-information',
        activeOn: '/api/numbers/#uploading-back-order-eud-information',
        name: 'Uploading back order EUD information',
      },
      {
        route: '/api/numbers/#cancel-a-purchased-phone-number',
        activeOn: '/api/numbers/#cancel-a-purchased-phone-number',
        name: 'Cancel',
      },
      {
        route: '/api/numbers/#number-pools',
        activeOn: '/api/numbers/#number-pools',
        name: 'Number pools',
      },
    ],
  },
  {
    route: '/api/partner/',
    activeOn: '/api/partner/',
    name: 'Partner Accounts',
    subMenu: [
      {
        route: '/api/partner/#api-endpoint',
        activeOn: '/api/partner/#api-endpoint',
        name: 'Overview',
      },
      {
        route: '/api/partner/#child-accounts',
        activeOn: '/api/partner/#child-accounts',
        name: 'Child Accounts',
      },
      {
        route: '/api/partner/#formats-and-headers',
        activeOn: '/api/partner/#formats-and-headers',
        name: 'Formats and Headers',
      },
      {
        route: '/api/partner/#errors',
        activeOn: '/api/partner/#errors',
        name: 'Errors',
      },
      {
        route: '/api/partner/#create-a-child-account',
        activeOn: '/api/partner/#create-a-child-account',
        name: 'Create a Child Account',
      },
      {
        route: '/api/partner/#edit-a-specific-child-account',
        activeOn: '/api/partner/#edit-a-specific-child-account',
        name: 'Edit a Child Account',
      },
      {
        route: '/api/partner/#fetch-a-specific-child-account',
        activeOn: '/api/partner/#fetch-a-specific-child-account',
        name: 'Fetch a Child Account',
      },
      {
        route: '/api/partner/#delete-child-account',
        activeOn: '/api/partner/#delete-child-account',
        name: 'Delete Child Account',
      },
    ],
  },
  {
    route: '/api/verify/',
    activeOn: '/api/verify/',
    name: 'Verify',
    subMenu: [
      {
        route: '/api/verify/#verify-api',
        activeOn: '/api/verify/#verify-api',
        name: 'Overview',
      },
      {
        route: '/api/verify/#the-verify-object',
        activeOn: '/api/verify/#the-verify-object',
        name: 'Object',
      },
      {
        route: '/api/verify/#request-a-verify',
        activeOn: '/api/verify/#request-a-verify',
        name: 'Request',
      },
      {
        route: '/api/verify/#verify-a-token',
        activeOn: '/api/verify/#verify-a-token',
        name: 'Verify token',
      },
      {
        route: '/api/verify/#view-a-verify-object',
        activeOn: '/api/verify/#view-a-verify-object',
        name: 'View',
      },
      {
        route: '/api/verify/#delete-a-verify-object',
        activeOn: '/api/verify/#delete-a-verify-object',
        name: 'Delete',
      },
    ],
  },
  {
    route: '/api/mms-messaging/',
    name: 'MMS',
    subMenu: [
      {
        route: '/api/mms-messaging/',
        activeOn: '/api/mms-messaging/',
        name: 'Overview',
      },
      {
        route: '/api/mms-messaging/#the-mms-message-object',
        activeOn: '/api/mms-messaging/#the-mms-message-object',
        name: 'Object',
      },
      {
        route: '/api/mms-messaging/#view-a-message',
        activeOn: '/api/mms-messaging/#view-a-message',
        name: 'View',
      },
      {
        route: '/api/mms-messaging/#send-an-mms-message',
        activeOn: '/api/mms-messaging/#send-an-mms-message',
        name: 'Send',
      },
      {
        route: '/api/mms-messaging/#receive-a-message',
        activeOn: '/api/mms-messaging/#receive-a-message',
        name: 'Receive',
      },
      {
        route: '/api/mms-messaging/#handle-a-status-report',
        activeOn: '/api/mms-messaging/#handle-a-status-report',
        name: 'Handle status',
      },
      {
        route: '/api/mms-messaging/#media-attachments',
        activeOn: '/api/mms-messaging/#media-attachments',
        name: 'Media attachments',
      },
    ],
  },
  {
    route: '/api/hlr/',
    activeOn: '/api/hlr/',
    name: 'HLR',
    subMenu: [
      {
        route: '/api/hlr/#api-endpoint',
        activeOn: '/api/hlr/#api-endpoint',
        name: 'Overview',
      },
      {
        route: '/api/hlr/#the-hlr-object',
        activeOn: '/api/hlr/#the-hlr-object',
        name: 'Object',
      },
      {
        route: '/api/hlr/#request-an-hlr',
        activeOn: '/api/hlr/#request-an-hlr',
        name: 'Request',
      },
      {
        route: '/api/hlr/#view-an-hlr',
        activeOn: '/api/hlr/#view-an-hlr',
        name: 'View',
      },
      {
        route: '/api/hlr/#handle-an-hlr-response',
        activeOn: '/api/hlr/#handle-an-hlr-response',
        name: 'Handle response',
      },
    ],
  },

  {
    route: '/api/balance/',
    activeOn: '/api/balance/',
    name: 'Balance',
    subMenu: [
      {
        route: '/api/balance/#api-endpoint',
        activeOn: '/api/balance/#api-endpoint',
        name: 'Overview',
      },
      {
        route: '/api/balance/#the-balance-object',
        activeOn: '/api/balance/#the-balance-object',
        name: 'Object',
      },
      {
        route: '/api/balance/#get-your-balance',
        activeOn: '/api/balance/#get-your-balance',
        name: 'Get your balance',
      },
    ],
  },
  {
    route: '/api/lookup/',
    activeOn: '/api/lookup/',
    name: 'Lookup',
    subMenu: [
      {
        route: '/api/lookup/',
        activeOn: '/api/lookup/',
        name: 'Overview',
      },
      {
        route: '/api/lookup/#the-lookup-object',
        activeOn: '/api/lookup/#the-lookup-object',
        name: 'Object',
      },
      {
        route: '/api/lookup/#request-a-lookup',
        activeOn: '/api/lookup/#request-a-lookup',
        name: 'Request Lookup',
      },
      {
        route: '/api/lookup/#request-an-hlr',
        activeOn: '/api/lookup/#request-an-hlr',
        name: 'Request HLR',
      },
      {
        route: '/api/lookup/#view-an-hlr',
        activeOn: '/api/lookup/#view-an-hlr',
        name: 'View',
      },
      {
        route: '/api/lookup/#handle-an-hlr-response',
        activeOn: '/api/lookup/#handle-an-hlr-response',
        name: 'Handle response',
      },
    ],
  },
  {
    route: '/api/contacts/',
    activeOn: '/api/contacts/',
    name: 'Contacts',
    subMenu: [
      {
        route: '/api/contacts/',
        activeOn: '/api/contacts/',
        name: 'Overview',
      },
      {
        route: '/api/contacts/#the-contact-object',
        activeOn: '/api/contacts/#the-contact-object',
        name: 'Object',
      },
      {
        route: '/api/contacts/#create-a-contact',
        activeOn: '/api/contacts/#create-a-contact',
        name: 'Create',
      },
      {
        route: '/api/contacts/#view-a-contact',
        activeOn: '/api/contacts/#view-a-contact',
        name: 'View',
      },
      {
        route: '/api/contacts/#update-a-contact',
        activeOn: '/api/contacts/#update-a-contact',
        name: 'Update',
      },
      {
        route: '/api/contacts/#delete-a-contact',
        activeOn: '/api/contacts/#delete-a-contact',
        name: 'Delete',
      },
    ],
  },
  {
    route: '/api/groups/',
    activeOn: '/api/groups/',
    name: 'Groups',
    subMenu: [
      {
        route: '/api/groups/#api-endpoint',
        activeOn: '/api/groups/$api-endpoint',
        name: 'Overview',
      },
      {
        route: '/api/groups/#the-group-object',
        activeOn: '/api/groups/#the-group-object',
        name: 'Object',
      },
      {
        route: '/api/groups/#create-a-group',
        activeOn: '/api/groups/#create-a-group',
        name: 'Create',
      },
      {
        route: '/api/groups/#view-a-group',
        activeOn: '/api/groups/#view-a-group',
        name: 'View',
      },
      {
        route: '/api/groups/#update-a-group',
        activeOn: '/api/groups/#update-a-group',
        name: 'Update',
      },
      {
        route: '/api/groups/#add-contact-to-group',
        activeOn: '/api/groups/#add-contact-to-group',
        name: 'Add contact',
      },
      {
        route: '/api/groups/#remove-contact-from-group',
        activeOn: '/api/groups/#remove-contact-from-group',
        name: 'Remove contact',
      },
      {
        route: '/api/groups/#delete-a-group',
        activeOn: '/api/groups/#delete-a-group',
        name: 'Delete',
      },
    ],
  },
].concat(apisNavigationGenerated);

export const toolsNavigation = [
  {
    route: '/docs/flow-builder/',
    activeOn: '/docs/flow-builder/',
    name: 'Flow Builder',
  },
  {
    route: '/docs/campaign-builder/',
    activeOn: '/docs/campaign-builder/',
    name: 'Campaign Builder',
  },
];

export const quickStartsNavigation = [
  {
    route: '/quickstarts/sms-overview/',
    activeOn: '/quickstarts/sms-overview/',
    name: 'SMS',
    subMenu: [
      {
        route: '/quickstarts/sms/test-credits-api-keys/',
        activeOn: '/quickstarts/sms/test-credits-api-keys/',
        name: 'Test credits & API keys',
      },
      {
        route: '/quickstarts/sms/send-sms-curl/',
        activeOn: '/quickstarts/sms/send-sms/',
        name: 'Send an SMS',
      },
      {
        route: '/quickstarts/sms/receive-sms/',
        activeOn: '/quickstarts/sms/receive-sms/',
        name: 'Receive an SMS',
      },
      {
        route: '/quickstarts/sms/troubleshooting/',
        activeOn: '/quickstarts/sms/troubleshooting/',
        name: 'Troubleshooting',
      },
    ],
  },
  {
    route: '/quickstarts/whatsapp-overview/',
    activeOn: '/quickstarts/whatsapp-overview/',
    name: 'WhatsApp',
    subMenu: [
      {
        route: '/quickstarts/whatsapp-deepdive/',
        activeOn: '/quickstarts/whatsapp-deepdive/',
        name: 'WA Deep Dive',
      },
      {
        route: '/quickstarts/whatsapp/getting-started/',
        activeOn: '/quickstarts/whatsapp/getting-started/',
        name: 'Getting started WA',
      },
      {
        route: '/quickstarts/whatsapp/getting-started-sandbox/',
        activeOn: '/quickstarts/whatsapp/getting-started-sandbox/',
        name: 'Sandbox',
      },
      {
        route: '/quickstarts/whatsapp/install-channel-whatsapp/',
        activeOn: '/quickstarts/whatsapp/install-channel-whatsapp/',
        name: 'Install Channel',
      },
      {
        route: '/quickstarts/whatsapp/message-templates-curl/',
        activeOn: '/quickstarts/whatsapp/message-templates/',
        name: 'Message Templates',
      },
      {
        route: '/quickstarts/whatsapp/create-media-template/',
        activeOn: '/quickstarts/whatsapp/create-media-template/',
        name: 'Create media template',
      },
      {
        route: '/quickstarts/whatsapp/send-your-first-whatsapp-message-curl/',
        activeOn: '/quickstarts/whatsapp/send-your-first-whatsapp-message/',
        name: 'Send a message',
      },
      {
        route: '/quickstarts/whatsapp/send-message-with-buttons/',
        activeOn: '/quickstarts/whatsapp/send-message-with-buttons/',
        name: 'Send message with buttons',
      },
      {
        route: '/quickstarts/whatsapp/send-interactive-messages/',
        activeOn: '/quickstarts/whatsapp/send-interactive-messages/',
        name: 'Send interactive messages',
      },
      {
        route: '/quickstarts/whatsapp/send-media-template-message-curl/',
        activeOn: '/quickstarts/whatsapp/send-media-template-message/',
        name: 'Send media template message',
      },
      {
        route: '/quickstarts/whatsapp/authentication-templates/',
        activeOn: '/quickstarts/whatsapp/authentication-templates/',
        name: 'Create and send authentication templates',
      },
      {
        route: '/quickstarts/whatsapp/sticker-messages',
        activeOn: '/quickstarts/whatsapp/sticker-messages',
        name: 'Sticker messages',
      },
      {
        route: '/quickstarts/whatsapp/whatsapp-product-messages',
        activeOn: '/quickstarts/whatsapp/whatsapp-product-messages',
        name: 'Product messages',
      },
      {
        route: '/quickstarts/whatsapp/carousel-template/',
        activeOn: '/quickstarts/whatsapp/carousel-template/',
        name: 'Carousel template',
      },
      {
        route: '/quickstarts/whatsapp/coupon-template/',
        activeOn: '/quickstarts/whatsapp/coupon-template/',
        name: 'Coupon template',
      },
      {
        route: '/quickstarts/whatsapp/referral',
        activeOn: '/quickstarts/whatsapp/referral',
        name: 'Referral',
      },
      {
        route: '/quickstarts/whatsapp/number-quality-ratings/',
        activeOn: '/quickstarts/whatsapp/number-quality-ratings/',
        name: 'Number Ratings',
      },
      {
        route: '/quickstarts/whatsapp/whatsapp-customer-options/',
        activeOn: '/quickstarts/whatsapp/whatsapp-customer-options/',
        name: "Сustomer's opt-ins",
      },
      {
        route: '/quickstarts/whatsapp/number-migration/',
        activeOn: '/quickstarts/whatsapp/number-migration/',
        name: 'Number Migration',
      },
      {
        route: '/quickstarts/whatsapp/wb-deepdive/',
        activeOn: '/quickstarts/whatsapp/wb-deepdive/',
        name: 'WhatsApp Business',
      },
      {
        route: '/quickstarts/whatsapp/troubleshooting-whatsapp/',
        activeOn: '/quickstarts/whatsapp/troubleshooting-whatsapp/',
        name: 'Troubleshooting',
      },
    ],
  },
  {
    route: '/quickstarts/facebook-overview/',
    activeOn: '/quickstarts/facebook-overview/',
    name: 'Facebook',
    subMenu: [
      {
        route: '/quickstarts/facebook/quick-replies/',
        activeOn: '/quickstarts/facebook/quick-replies/',
        name: 'Quick Replies',
      },
      {
        route: '/quickstarts/facebook/media-templates/',
        activeOn: '/quickstarts/facebook/media-templates/',
        name: 'Media Templates',
      },
      {
        route: '/quickstarts/facebook/generic-templates/',
        activeOn: '/quickstarts/facebook/generic-templates/',
        name: 'Generic Templates',
      },
      {
        route: '/quickstarts/facebook/referrals/',
        activeOn: '/quickstarts/facebook/referrals/',
        name: 'Messaging Referrals',
      },
    ],
  },
  {
    route: '/quickstarts/voice-calling/voicecalling-overview/',
    activeOn: '/quickstarts/voice-calling/voicecalling-overview/',
    name: 'Voice Calling',
    subMenu: [
      {
        route: '/quickstarts/voice-calling/test-credits-api-keys/',
        activeOn: '/quickstarts/voice-calling/test-credits-api-keys/',
        name: 'Get your API keys',
      },
      {
        route: '/quickstarts/voice-calling/make-an-outbound-call-curl/',
        activeOn: '/quickstarts/voice-calling/make-an-outbound-call/',
        name: 'Make a voice call',
      },
      {
        route: '/quickstarts/voice-calling/receive-calls/',
        activeOn: '/quickstarts/voice-calling/receive-calls/',
        name: 'Receive calls',
      },
      {
        route: '/quickstarts/voice-calling/SIP-trunking/',
        activeOn: '/quickstarts/voice-calling/SIP-trunking/',
        name: 'SIP Trunking',
      },
      {
        route: '/quickstarts/voice-calling/troubleshooting/',
        activeOn: '/quickstarts/voice-calling/troubleshooting/',
        name: 'Troubleshooting',
      },
      {
        route: '/quickstarts/voice-calling/structure-of-a-call/',
        activeOn: '/quickstarts/voice-calling/structure-of-a-call/',
        name: 'Structure of a Call',
      },
      {
        route: '/quickstarts/voice-calling/call-and-leg-statuses/',
        activeOn: '/quickstarts/voice-calling/call-and-leg-statuses/',
        name: 'Call and Leg statuses',
      },
    ],
  },
  {
    route: '/quickstarts/voicemessaging-overview/',
    activeOn: '/quickstarts/voicemessaging-overview/',
    name: 'Voice Messaging',
    subMenu: [
      {
        route: '/quickstarts/voicemessaging/test-credits-api-keys/',
        activeOn: '/quickstarts/voicemessaging/test-credits-api-keys/',
        name: 'Get your API keys',
      },
      {
        route: '/quickstarts/voicemessaging/send-outbound-voice-message-curl/',
        activeOn: '/quickstarts/voicemessaging/send-outbound-voice-message/',
        name: 'Text To Speech Message',
      },
      {
        route: '/quickstarts/voicemessaging/troubleshooting/',
        activeOn: '/quickstarts/voicemessaging/troubleshooting/',
        name: 'Troubleshooting',
      },
    ],
  },
  {
    route: '/quickstarts/conversations-overview/',
    activeOn: '/quickstarts/conversations-overview/',
    name: 'Conversations',
    subMenu: [
      {
        route: '/quickstarts/conversations/test-credits-api-keys/',
        activeOn: '/quickstarts/conversations/test-credits-api-keys/',
        name: 'Test Credits & API Keys',
      },
      {
        route: '/quickstarts/conversations/install-channel/',
        activeOn: '/quickstarts/conversations/install-channel/',
        name: 'Install SMS',
      },
      {
        route: '/quickstarts/conversations/install-channel-email/',
        activeOn: '/quickstarts/conversations/install-channel-email/',
        name: 'Install Email',
      },
      {
        route: '/quickstarts/conversations/install-channel-line/',
        activeOn: '/quickstarts/conversations/install-channel-line/',
        name: 'Install LINE',
      },
      {
        route: '/quickstarts/conversations/install-channel-telegram/',
        activeOn: '/quickstarts/conversations/install-channel-telegram/',
        name: 'Install Telegram',
      },
      {
        route: '/quickstarts/conversations/install-channel-wechat/',
        activeOn: '/quickstarts/conversations/install-channel-wechat/',
        name: 'Install WeChat',
      },
      {
        route: '/quickstarts/conversations/install-channel-whatsapp/',
        activeOn: '/quickstarts/conversations/install-channel-whatsapp/',
        name: 'Install WhatsApp',
      },
      {
        route: '/quickstarts/conversations/handle-inbound-messages-curl/',
        activeOn: '/quickstarts/conversations/handle-inbound-messages/',
        name: 'Receiving Messages',
      },
      {
        route: '/quickstarts/conversations/send-messages-curl/',
        activeOn: '/quickstarts/conversations/send-messages/',
        name: 'Sending Messages',
      },
      {
        route: '/quickstarts/conversations/reply-to-messages-curl/',
        activeOn: '/quickstarts/conversations/reply-to-messages/',
        name: 'Replying to Messages',
      },
      {
        route: '/quickstarts/conversations/messenger-optins/',
        activeOn: '/quickstarts/conversations/messenger-optins/',
        name: 'Messenger options',
      },
      {
        route: '/quickstarts/conversations/message-tags/',
        activeOn: '/quickstarts/conversations/message-tags/',
        name: 'Message Tags',
      },
      {
        route:
          '/quickstarts/conversations/email-attachments-and-inline-images/',
        activeOn:
          '/quickstarts/conversations/email-attachments-and-inline-images/',
        name: 'Email Attachments and Inline Images',
      },
      {
        route: '/quickstarts/conversations/ip-warmup/',
        activeOn: '/quickstarts/conversations/ip-warmup/',
        name: 'IP Warm-up',
      },
      {
        route: '/quickstarts/conversations/troubleshooting/',
        activeOn: '/quickstarts/conversations/troubleshooting/',
        name: 'Troubleshooting ',
      },
    ],
  },
  {
    route: '/quickstarts/numbers-overview/',
    activeOn: '/quickstarts/numbers-overview/',
    name: 'Numbers',
    subMenu: [
      {
        route: '/quickstarts/numbers/buy-numbers/',
        activeOn: '/quickstarts/numbers/buy-numbers/',
        name: 'Buy a number',
      },
      {
        route: '/quickstarts/numbers/restrictions-numbers/',
        activeOn: '/quickstarts/numbers/restrictions-numbers/',
        name: 'Number restrictions',
      },
    ],
  },
  {
    route: '/quickstarts/omnichannel-widget-overview/',
    activeOn: '/quickstarts/omnichannel-widget-overview/',
    name: 'Omnichannel Widget',
    subMenu: [
      {
        route: '/quickstarts/omnichannel-widget/install/',
        activeOn: '/quickstarts/omnichannel-widget/install/',
        name: 'Install',
      },
      {
        route: '/quickstarts/omnichannel-widget/visibility/',
        activeOn: '/quickstarts/omnichannel-widget/visibility/',
        name: ' Toggling / Hiding',
      },
      {
        route: '/quickstarts/omnichannel-widget/attributes/',
        activeOn: '/quickstarts/omnichannel-widget/attributes/',
        name: 'Adding Attributes',
      },
      {
        route: '/quickstarts/omnichannel-widget/identify/',
        activeOn: '/quickstarts/omnichannel-widget/identify/',
        name: 'Identifying Users',
      },
    ],
  },
  {
    route: '/quickstarts/notifications-overview/',
    activeOn: '/quickstarts/notifications-overview/',
    name: 'Notifications Center',
    subMenu: [
      {
        route: '/quickstarts/notifications-webhook/',
        activeOn: '/quickstarts/notifications-webhook/',
        name: 'Receiving Notifications via Webhooks',
      },
    ],
  },
  {
    route: '/quickstarts/verify-overview/',
    activeOn: '/quickstarts/verify-overview/',
    name: 'Verify',
    subMenu: [
      {
        route: '/quickstarts/verify/test-credits-api-keys/',
        activeOn: '/quickstarts/verify/test-credits-api-keys/',
        name: 'Test Credits & API Keys',
      },
      {
        route: '/quickstarts/verify/send-a-verification-token-curl/',
        activeOn: '/quickstarts/verify/send-a-verification-token/',
        name: 'Send Your First Verify Token',
      },
      {
        route: '/quickstarts/verify/verify-a-token-curl/',
        activeOn: '/quickstarts/verify/verify-a-token/',
        name: 'Verify a Token',
      },
    ],
  },
  {
    route: '/quickstarts/pricingapi/list-outbound-sms-prices/',
    activeOn: '/quickstarts/pricingapi/list-outbound-sms-prices/',
    name: 'Pricing API (SMS & MMS)',
  },
  {
    route: '/quickstarts/lookup-overview/',
    activeOn: '/quickstarts/lookup-overview/',
    name: 'Lookup',
    subMenu: [
      {
        route: '/quickstarts/lookup/request-a-lookup/',
        activeOn: '/quickstarts/lookup/request-a-lookup/',
        name: 'Request a Lookup',
      },
      {
        route: '/quickstarts/lookup/request-an-hlr/',
        activeOn: '/quickstarts/lookup/request-an-hlr/',
        name: 'Request an HLR',
      },
      {
        route: '/quickstarts/lookup/view-an-hlr/',
        activeOn: '/quickstarts/lookup/view-an-hlr/',
        name: 'View an HLR',
      },
      {
        route: '/quickstarts/lookup/troubleshooting/',
        activeOn: '/quickstarts/lookup/troubleshooting/',
        name: 'Troubleshooting',
      },
    ],
  },
  {
    route: '/quickstarts/mms-overview/',
    activeOn: '/quickstarts/mms-overview/',
    name: 'MMS',
    subMenu: [
      {
        route: '/quickstarts/mms/test-credits-api-keys/',
        activeOn: '/quickstarts/mms/test-credits-api-keys/',
        name: 'Test Credits & API Keys',
      },
      {
        route: '/quickstarts/mms/send-mms/',
        activeOn: '/quickstarts/mms/send-mms/',
        name: 'Send MMS',
      },
      {
        route: '/quickstarts/mms/troubleshooting/',
        activeOn: '/quickstarts/mms/troubleshooting/',
        name: 'Troubleshooting',
      },
    ],
  },
];
