import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import Notification from 'components/Notification';
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
import { Img } from 'components/shared';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Install Channel: LINE`}</h1>
    <p>{`Let's start using the Programmable Conversations by learning how to install a channel. In this quickstart, you'll learn how to set up LINE.`}</p>
    <h2>{`Step 1: Go to the channels directory in your Dashboard`}</h2>
    <p>{`Go to '`}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/channels"
      }}>{`Channels`}</a>{`' in the sidebar of your MessageBird Dashboard. Then click on `}<inlineCode parentName="p">{`Add channel`}</inlineCode>{` under LINE.`}</p>
    <Img src="/img/screenshots/quickstarts/convo/InstallLINE1.png" alt="Install LINE" width="650px" align="middle" mdxType="Img" />
    <h2>{`Step 2: Give your channel a name`}</h2>
    <p>{`Name your channel, make sure it's easily recognizable for yourself and your team. Don't worry, this will only be used internally.`}</p>
    <Img src="/img/screenshots/quickstarts/convo/InstallLINE2.png" alt="Install LINE" width="650px" align="middle" mdxType="Img" />
    <h2>{`Step 3: Add your channel secret, access token and LineID`}</h2>
    <p>{`Go to '`}<a parentName="p" {...{
        "href": "https://developers.line.biz/console/"
      }}>{`Line Console`}</a>{`', select the channel that you want to use. Select `}<inlineCode parentName="p">{`Basic Settings`}</inlineCode>{` from tabs, scroll down to `}<inlineCode parentName="p">{`Channel secret`}</inlineCode>{` and copy paste to channel Secret section in MessageBird channel creation page.`}</p>
    <Img src="/img/screenshots/quickstarts/convo/InstallLINE3.png" alt="Install LINE" width="650px" align="middle" mdxType="Img" />
    <p>{`Then, scroll up again to tabs and click on `}<inlineCode parentName="p">{`Messaging API`}</inlineCode>{`. Copy paste the `}<inlineCode parentName="p">{`Bot basic ID`}</inlineCode>{` to LINE ID section in MessageBird channel creation page.`}</p>
    <Img src="/img/screenshots/quickstarts/convo/InstallLINE4.png" alt="Install LINE" width="650px" align="middle" mdxType="Img" />
    <p>{`Then, scroll down again to `}<inlineCode parentName="p">{`Channel access token`}</inlineCode>{` in `}<inlineCode parentName="p">{`Messaging API`}</inlineCode>{` tab. Copy paste the `}<inlineCode parentName="p">{`Channel access token (long-lived)`}</inlineCode>{` to Access Token section in MessageBird channel creation page.`}</p>
    <Img src="/img/screenshots/quickstarts/convo/InstallLINE5.png" alt="Install LINE" width="650px" align="middle" mdxType="Img" />
    <p>{`Once channel secret, access token and LineID are added, all sections should be filled up.`}</p>
    <Img src="/img/screenshots/quickstarts/convo/InstallLINE6.png" alt="Install LINE" width="650px" align="middle" mdxType="Img" />
    <h2>{`Step 4: Install LINE`}</h2>
    <p>{`Click on 'Install now'! LINE is now installed as a channel.`}</p>
    <Img src="/img/screenshots/quickstarts/convo/InstallLINE7.png" alt="Install LINE" width="650px" align="middle" mdxType="Img" />
    <h2>{`Step 5: Configure Webhook`}</h2>
    <p>{`Click on edit button. Then copy the webhook URL that we provide`}</p>
    <Img src="/img/screenshots/quickstarts/convo/InstallLINE8.png" alt="Install LINE" width="650px" align="middle" mdxType="Img" />
    <p>{`Go to '`}<a parentName="p" {...{
        "href": "https://developers.line.biz/console/"
      }}>{`Line Console`}</a>{`', select the channel that you created. Then select the `}<inlineCode parentName="p">{`Messaging API`}</inlineCode>{` tab and scroll down to `}<inlineCode parentName="p">{`Webhook Settings`}</inlineCode>{`. Edit the webhook settings and paste the URL that you had copied and click `}<inlineCode parentName="p">{`Update`}</inlineCode>{` button. Once you set the URL, `}<inlineCode parentName="p">{`Verify`}</inlineCode>{` button should appear. Click `}<inlineCode parentName="p">{`Verify`}</inlineCode>{` button and result should be `}<inlineCode parentName="p">{`success`}</inlineCode></p>
    <Img src="/img/screenshots/quickstarts/convo/InstallLINE9.png" alt="Install LINE" width="650px" align="middle" mdxType="Img" />`
    <p>{`As last step, enable `}<inlineCode parentName="p">{`use webhook`}</inlineCode>{` toggle.`}</p>
    <Img src="/img/screenshots/quickstarts/convo/InstallLINE10.png" alt="Install LINE" width="650px" align="middle" mdxType="Img" />`
    <p>{`Now, your LINE Channel ready to work!`}</p>
    <h2>{`Step 6: Manage your channel`}</h2>
    <p>{`If you want to edit, rename or delete your channel, simply select the channel and save the changes afterwards.`}</p>
    <p>{`Nice work! 🎉 You've successfully installed LINE as a channel for the MessageBird Programmable Conversations API.`}</p>
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'Install Channel: SMS',
      to: '/docs/conversations/install-channel'
    }, {
      name: 'Install Channel: WhatsApp',
      to: '/docs/conversations/install-channel-whatsapp'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      