import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/ApiRef.js";
import { APIWrapper, LeftColumn, RightColumn } from 'components/ApiRef';
import CodeSnippet from 'components/CodeSnippet';
import Link from 'components/Link';
import Notification from 'components/Notification';
import * as CODE from '../../constants/tasks';
import Helmet from 'react-helmet';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Helmet mdxType="Helmet">
      <meta name="robots" content="noindex" />
    </Helmet>
    <h1>{`Tasks API `}<inlineCode parentName="h1">{`Early access`}</inlineCode></h1>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <Notification type="INFO" mdxType="Notification">
Programmable Tasks API is currently in Early Access. You can request access via <Link to="https://messagebird.com/en/tasks/" mdxType="Link">our website</Link>.
        </Notification>
        <p>{`The MessageBird Programmable Tasks API provides a way to build your contact centre logic and plumbing. It enables you to connect inbound traffic to your platform with your agents in a smart, optimised and easy way.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>API Endpoint</h4>
  <CodeSnippet code={CODE.TASKS_ENDPOINT} mdxType="CodeSnippet" />
  <h5>All requests must be over SSL.</h5>
        <h4>Object example</h4>
  <CodeSnippet code={CODE.TASKS_OBJECT} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Glossary`}</h2>
        <h3>{`Workflows`}</h3>
        <p>{`Workflows are configuration containers that define how tasks are routed to
queues, via conditional expressions and priority definitions.`}</p>
        <h3>{`Queues`}</h3>
        <p>{`Queues are container data structures where tasks are kept. In technical terms,
a queue is a linear array where tasks are put into (enqueued) and taken out of
(dequeued). Queues can have criteria defined for agent assignment.`}</p>
        <h3>{`Agents`}</h3>
        <p>{`Agents are individual actors that have the capability of handling tasks. They
too have attributes that denote their characteristics, e.g. their traits and
abilities. They have a status that mutates over time and describes their
(in)ability to handle tasks.`}</p>
        <h3>{`Tasks`}</h3>
        <p>{`Tasks are actionable units of work. They have attributes that denote their
characteristics. Upon creation, a task is placed in a queue. A task also has a status which defines its current state.`}</p>
        <h3>{`Reservations`}</h3>
        <p>{`When a task is placed in a queue and offered to eligible agents, a reservation is created for each of those agents. A reservation consists of the task, the agent and a status which defined the reservation's state.`}</p>
      </LeftColumn>
    </APIWrapper>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h1>{`Workflow`}</h1>
        <p>{`A workflow contains a set of Filters. Each filter is comprised of several Routing Steps.
Once a Task is created (and inherently pointed to a Workflow), the Task's attributes will be compared against each Filter that the Workflow contains.
Once a Task is being matched against a Filter, the Routing Steps come into play. These will be executed one-by-one, thus moving the Task into several Queues and applying various Agents extra filters.
If a Task does not match any of the Filters, it will be assigned to the Workflow's default queue, as a fallback behaviour.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
        <h4>Workflow object example</h4>
  <CodeSnippet code={CODE.WORKFLOW_OBJECT} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Available HTTP methods`}</h2>
        <p>{`The MessageBird API uses HTTP verbs to understand if you want to read (`}<inlineCode parentName="p">{`GET`}</inlineCode>{`), delete (`}<inlineCode parentName="p">{`DELETE`}</inlineCode>{`) or create (`}<inlineCode parentName="p">{`POST`}</inlineCode>{`) an object.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Available HTTP methods</h4>
  <CodeSnippet code={CODE.WORKFLOW_HTTP_METHODS} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`View workflow`}</h2>
        <p>{`Fetch an existing workflow.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.VIEWWORKFLOW_DEF} mdxType="CodeSnippet" />
        <h4>Example request</h4>
        <CodeSnippet code={CODE.VIEWWORKFLOW_REQUEST} mdxType="CodeSnippet" />
        <h4>Example response</h4>
  <CodeSnippet code={CODE.VIEWWORKFLOW_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Create workflow`}</h2>
        <p>{`Create a new workflow.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.CREATEWORKFLOW_DEF} mdxType="CodeSnippet" />
        <h4>Example request</h4>
        <CodeSnippet code={CODE.CREATEWORKFLOW_REQUEST} mdxType="CodeSnippet" />
        <h4>Example response</h4>
  <CodeSnippet code={CODE.CREATEWORKFLOW_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Update workflow`}</h2>
        <p>{`Update an existing workflow.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.UPDATEWORKFLOW_DEF} mdxType="CodeSnippet" />
        <h4>Example request</h4>
        <CodeSnippet code={CODE.UPDATEWORKFLOW_REQUEST} mdxType="CodeSnippet" />
        <h4>Example response</h4>
  <CodeSnippet code={CODE.UPDATEWORKFLOW_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Delete workflow`}</h2>
        <p>{`Delete a workflow.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.DELETEWORKFLOW_DEF} mdxType="CodeSnippet" />
        <h4>Example request</h4>
        <CodeSnippet code={CODE.DELETEWORKFLOW_REQUEST} mdxType="CodeSnippet" />
        <h4>Example response</h4>
  <CodeSnippet code={CODE.DELETEWORKFLOW_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Queue`}</h2>
        <p>{`Queues are container data structures where tasks are kept. In technical terms, a queue is a linear array where tasks are put into (enqueued) and taken out of (dequeued). Queues can have criteria defined for agent assignment.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
        <h4>Queue object example</h4>
  <CodeSnippet code={CODE.QUEUE_OBJECT} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Available HTTP methods`}</h2>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Available HTTP methods</h4>
  <CodeSnippet code={CODE.QUEUE_HTTP_METHODS} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`View queue`}</h2>
        <p>{`Fetch an existing queue.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.VIEWQUEUE_DEF} mdxType="CodeSnippet" />
        <h4>Example request</h4>
        <CodeSnippet code={CODE.VIEWQUEUE_REQUEST} mdxType="CodeSnippet" />
        <h4>Example response</h4>
  <CodeSnippet code={CODE.VIEWQUEUE_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Create queue`}</h2>
        <p>{`Create a new queue.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.CREATEQUEUE_DEF} mdxType="CodeSnippet" />
        <h4>Example request</h4>
        <CodeSnippet code={CODE.CREATEQUEUE_REQUEST} mdxType="CodeSnippet" />
        <h4>Example response</h4>
  <CodeSnippet code={CODE.CREATEQUEUE_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Update queue`}</h2>
        <p>{`Update an existing queue.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.UPDATEQUEUE_DEF} mdxType="CodeSnippet" />
        <h4>Example request</h4>
        <CodeSnippet code={CODE.UPDATEQUEUE_REQUEST} mdxType="CodeSnippet" />
        <h4>Example response</h4>
  <CodeSnippet code={CODE.UPDATEQUEUE_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Delete queue`}</h2>
        <p>{`Delete a queue.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.DELETEQUEUE_DEF} mdxType="CodeSnippet" />
        <h4>Example request</h4>
        <CodeSnippet code={CODE.DELETEQUEUE_REQUEST} mdxType="CodeSnippet" />
        <h4>Example response</h4>
  <CodeSnippet code={CODE.DELETEQUEUE_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Agent`}</h2>
        <p>{`Agents are individual actors that have the capability of handling tasks. They too have attributes that denote their characteristics, e.g. their traits and abilities. They have a status that mutates over time and describes their (in)ability to handle tasks.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
        <h4>Agent object example</h4>
  <CodeSnippet code={CODE.AGENT_OBJECT} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Available HTTP methods`}</h2>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Available HTTP methods</h4>
  <CodeSnippet code={CODE.AGENT_HTTP_METHODS} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`List agents`}</h2>
        <p>{`Fetch a list of all existing agents.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.LISTAGENTS_DEF} mdxType="CodeSnippet" />
        <h4>Example request</h4>
        <CodeSnippet code={CODE.LISTAGENTS_REQUEST} mdxType="CodeSnippet" />
        <h4>Example response</h4>
  <CodeSnippet code={CODE.LISTAGENTS_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`View agent`}</h2>
        <p>{`Fetch an existing agent.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.VIEWAGENT_DEF} mdxType="CodeSnippet" />
        <h4>Example request</h4>
        <CodeSnippet code={CODE.VIEWAGENT_REQUEST} mdxType="CodeSnippet" />
        <h4>Example response</h4>
  <CodeSnippet code={CODE.VIEWAGENT_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Create agent`}</h2>
        <p>{`Create a new agent.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.CREATEAGENT_DEF} mdxType="CodeSnippet" />
        <h4>Example request</h4>
        <CodeSnippet code={CODE.CREATEAGENT_REQUEST} mdxType="CodeSnippet" />
        <h4>Example response</h4>
  <CodeSnippet code={CODE.CREATEAGENT_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Update agent`}</h2>
        <p>{`Update an existing agent.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.UPDATEAGENT_DEF} mdxType="CodeSnippet" />
        <h4>Example request</h4>
        <CodeSnippet code={CODE.UPDATEAGENT_REQUEST} mdxType="CodeSnippet" />
        <h4>Example response</h4>
  <CodeSnippet code={CODE.UPDATEAGENT_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Delete agent`}</h2>
        <p>{`Delete an agent.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.DELETEAGENT_DEF} mdxType="CodeSnippet" />
        <h4>Example request</h4>
        <CodeSnippet code={CODE.DELETEAGENT_REQUEST} mdxType="CodeSnippet" />
        <h4>Example response</h4>
  <CodeSnippet code={CODE.DELETEAGENT_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Task`}</h2>
        <p>{`Tasks are actionable units of work. They have attributes that denote their characteristics. Upon creation, a task is placed in a queue. A task also has a status which defines its current state.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
        <h4>Task object example</h4>
  <CodeSnippet code={CODE.TASK_OBJECT} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Available HTTP methods`}</h2>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Available HTTP methods</h4>
  <CodeSnippet code={CODE.TASK_HTTP_METHODS} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`List tasks`}</h2>
        <p>{`Fetch a list of all existing tasks.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.LISTTASKS_DEF} mdxType="CodeSnippet" />
        <h4>Example request</h4>
        <CodeSnippet code={CODE.LISTTASKS_REQUEST} mdxType="CodeSnippet" />
        <h4>Example response</h4>
  <CodeSnippet code={CODE.LISTTASKS_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`View task`}</h2>
        <p>{`Fetch a specific task.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.VIEWTASK_DEF} mdxType="CodeSnippet" />
        <h4>Example request</h4>
        <CodeSnippet code={CODE.VIEWTASK_REQUEST} mdxType="CodeSnippet" />
        <h4>Example response</h4>
  <CodeSnippet code={CODE.VIEWTASK_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Create task`}</h2>
        <p>{`Create a new task.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.CREATETASK_DEF} mdxType="CodeSnippet" />
        <h4>Example request</h4>
        <CodeSnippet code={CODE.CREATETASK_REQUEST} mdxType="CodeSnippet" />
        <h4>Example response</h4>
  <CodeSnippet code={CODE.CREATETASK_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Delete task`}</h2>
        <p>{`Delete an existing task.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.DELETETASK_DEF} mdxType="CodeSnippet" />
        <h4>Example request</h4>
        <CodeSnippet code={CODE.DELETETASK_REQUEST} mdxType="CodeSnippet" />
        <h4>Example response</h4>
  <CodeSnippet code={CODE.DELETETASK_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h3>{`Task statuses`}</h3>
        <p>{`During a task lifecycle there will be a status which defines the tasks's current state. The following are the possible statuses. Some of the statuses are affected by reservation of the respective task. For more details about reservations read the reservations section.`}</p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Status`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`new`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`When a task is created.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`waiting`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`When there are no reservations made for the task.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`reserved`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`When reservations are made for the task.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`assigned`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`When an agent has been offered a task through a reservation and accepts it.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`canceled`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`When a task has been manually canceled.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`completed`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`When a task has been completed by an agent through a reservation.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`expired`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`When a task has expired due to a timeout.`}</td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
    </APIWrapper>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Reservation`}</h2>
        <p>{`When a task is placed in a queue, if there are any eligible agents, they will be offered the respective task through a reservation. Therefore a reservation is mainly composed of a task and an agent.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
        <h4>Reservation object example</h4>
  <CodeSnippet code={CODE.RESERVATION_OBJECT} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Available HTTP methods`}</h2>
        <p>{`These endpoints are authenticated by an agent JWT (typically used from agents SDK)`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Available HTTP methods</h4>
  <CodeSnippet code={CODE.RESERVATION_HTTP_METHODS} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Accept reservation`}</h2>
        <p>{`Accept an incoming reservation.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.ACCEPTRESERVATION_DEF} mdxType="CodeSnippet" />
        <h4>Example request</h4>
        <CodeSnippet code={CODE.ACCEPTRESERVATION_REQUEST} mdxType="CodeSnippet" />
        <h4>Example response</h4>
  <CodeSnippet code={CODE.ACCEPTRESERVATION_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Reject reservation`}</h2>
        <p>{`Reject an incoming reservation.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.REJECTRESERVATION_DEF} mdxType="CodeSnippet" />
        <h4>Example request</h4>
        <CodeSnippet code={CODE.REJECTRESERVATION_REQUEST} mdxType="CodeSnippet" />
        <h4>Example response</h4>
  <CodeSnippet code={CODE.REJECTRESERVATION_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Complete reservation`}</h2>
        <p>{`Complete an active reservation.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.COMPLETERESERVATION_DEF} mdxType="CodeSnippet" />
        <h4>Example request</h4>
        <CodeSnippet code={CODE.COMPLETERESERVATION_REQUEST} mdxType="CodeSnippet" />
        <h4>Example response</h4>
  <CodeSnippet code={CODE.COMPLETERESERVATION_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h3>{`Reservation statuses`}</h3>
        <p>{`During a reservation lifecycle there will be a status which defines the reservation current state. The following are the possible statuses. When a reservation status is changed it may affect the task (in the reservation) state. For more details about tasks read the tasks section.`}</p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Status`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`offered`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`When a reservation has been offered to an agent. This sets the task's status to offered.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`accepted`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`When a reservation offered was accepted by an agent. This sets the task's status to assigned.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`rejected`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`When a reservation offered was rejected by an agent. This may set the task's status back to waiting if no eligible agents are found after rejection.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`removed`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`When a reservation offered was removed from an agent because the task in the reservation was accepted by another agent.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`canceled`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`When a reservation offered was canceled from an agent.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`completed`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`When a task accepted in a reservation is complete by an agent. This sets the task's status to completed.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`expired`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`When a reservation offered has been expired due to a timeout.`}</td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
    </APIWrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      