import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/ApiRef.js";
import CodeSnippet from 'components/CodeSnippet';
import * as CODE from 'constants/agent';
import { APIWrapper, LeftColumn, RightColumn } from 'components/ApiRef';
import Notification from 'components/Notification';
import Link from 'components/Link';
import Helmet from 'react-helmet';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Helmet mdxType="Helmet">
      <meta name="robots" content="noindex" />
    </Helmet>
    <h1>{`Agent SDK `}<inlineCode parentName="h1">{`Early Access`}</inlineCode></h1>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <Notification type="INFO" mdxType="Notification">
Agent SDK is currently in Early Access. You can request access via <Link to="https://messagebird.com/en/tasks/" mdxType="Link">our website</Link>.
        </Notification>
        <h1>{`Installation`}</h1>
        <p>{`The SDK is distributed on npm. To install the SDK and start using it in your app just add the package @messagebird/agents`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
    <CodeSnippet code={CODE.INSTALL} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h1>{`Generating JSON Web Tokens`}</h1>
        <p>{`The Agents SDK uses JSON web tokens to authenticate you with the MessageBird platform. You can use the `}<inlineCode parentName="p">{`/token`}</inlineCode>{`s endpoint to generate a valid JWT. `}<em parentName="p">{`It is important that you don't directly call this endpoint from your browser, because you would be exposing your MessageBird access key.`}</em></p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
    <CodeSnippet code={CODE.AUTH} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <p>{`The token should be signed with the MessageBird `}<inlineCode parentName="p">{`accessKey`}</inlineCode>{`. Make sure you don't expose the `}<inlineCode parentName="p">{`accessKey`}</inlineCode>{` on the client or in the JWT, Only use it for signing the JWT. `}<a parentName="p" {...{
        "href": "https://gist.github.com/niekert/bec23a1bdde7d12564b1f10b36ab05e6"
      }}>{`This gist`}</a>{` shows an example of generating a JWT in Node.js. Reference `}<a parentName="p" {...{
        "href": "https://jwt.io/"
      }}>{`https://jwt.io`}</a>{` for help with generating JWT's in other programming languages.`}</p>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h1>{`Initializing an Agent Client`}</h1>
        <p>{`To connect your agent you'll have to instantiate a new `}<inlineCode parentName="p">{`Agent`}</inlineCode>{` client. Once connected, a websocket connection will be established and you can start listening for the events specified further in the documentation.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
    <CodeSnippet code={CODE.INIT} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h1>{`Events`}</h1>
        <p>{`As a consumer of the SDK you can listen for various events that occur within the Tasks API. The following events are supported:`}</p>
        <h3><inlineCode parentName="h3">{`agent.on('connected', () => void)`}</inlineCode></h3>
        <p>{`Received when the WebSocket connection is established. In this state the SDK will be ready to receive events.`}</p>
        <h3><inlineCode parentName="h3">{`agent.on('error', (error: Error) => void)`}</inlineCode></h3>
        <p>{`Received when the SDK encountered an error. The Callback includes an error object with the details of the error.`}</p>
        <h3><inlineCode parentName="h3">{`agent.on('disconnected', () => void)`}</inlineCode></h3>
        <p>{`Received when the SDK has disconnected. After this event the SDK will no longer receive any events until the `}<inlineCode parentName="p">{`connected`}</inlineCode>{` event is received again.`}</p>
        <h3><inlineCode parentName="h3">{`agent.on('reservation.offered', (reservation: Reservation) => void)`}</inlineCode></h3>
        <p>{`Received when a new reservation is offered to an agent. The agent can perform actions on this reservation`}</p>
        <h3><inlineCode parentName="h3">{`agent.on('reservation.accepted', (reservation: Reservation) => void)`}</inlineCode></h3>
        <p>{`Received when the current active agent has successfully accepted a reservation.`}</p>
        <h3><inlineCode parentName="h3">{`agent.on('reservation.expired', (reservation: Reservation) => void)`}</inlineCode></h3>
        <p>{`Received when an outstanding reservation has expired`}</p>
        <h3><inlineCode parentName="h3">{`agent.on('reservation.canceled', (reservation: Reservation) => void)`}</inlineCode></h3>
        <p>{`Received when an outstanding reservation was canceled`}</p>
        <h3><inlineCode parentName="h3">{`agent.on('reservation.removed', (reservation: Reservation) => void)`}</inlineCode></h3>
        <p>{`Received when an outstanding reservation was removed`}</p>
        <h3><inlineCode parentName="h3">{`agent.on('agent.activity.updated', (reservation: Agent) => void)`}</inlineCode></h3>
        <p>{`Received when the details of the agent have changed. The received callback will include the new agent details`}</p>
      </LeftColumn>
    </APIWrapper>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h1>{`Modifying a reservation`}</h1>
        <p>{`Agents can accept, reject, and complete a reservation. You can apply these modifications by calling the following functions`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
    <CodeSnippet code={CODE.MODIFY_RESERVATION} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h1>{`API Reference`}</h1>
        <h2>{`Agent`}</h2>
        <h4><inlineCode parentName="h4">{`constructor(token: string)`}</inlineCode></h4>
        <p>{`Instantiate a new Agent client. Accepts a token argument.`}</p>
        <h4><inlineCode parentName="h4">{`.on(eventName: string, callback: Function)`}</inlineCode></h4>
        <p>{`Listens for a new event. See the `}<a parentName="p" {...{
            "href": "#events"
          }}>{`Events`}</a>{` section for a list of supported events`}</p>
        <h4><inlineCode parentName="h4">{`.removeListener(eventName: string, callback: Function)`}</inlineCode></h4>
        <p>{`Removes the listener for an event`}</p>
        <h4><inlineCode parentName="h4">{`.acceptReservation(reservationId: string)`}</inlineCode></h4>
        <p>{`Accept a reservation with the given reservationId`}</p>
        <h4><inlineCode parentName="h4">{`.rejectReservation(reservationId: string)`}</inlineCode></h4>
        <p>{`Reject a reservation with the given reservationId`}</p>
        <h4><inlineCode parentName="h4">{`.completeReservation(reservationId: string)`}</inlineCode></h4>
        <p>{`Complete a reservation with the given reservationId`}</p>
      </LeftColumn>
    </APIWrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      