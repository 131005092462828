import CodeSnippet from 'components/CodeSnippet';
import * as CODE from "../../../../src/constants/integrations";
import { Img } from 'components/shared';
import Notification from "../../../../src/components/Notification";
import * as React from 'react';
export default {
  CodeSnippet,
  CODE,
  Img,
  Notification,
  React
};