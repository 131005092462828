import React from 'react';
import styled from 'styled-components';
import './cookieconsent.css';

const CookieBtn = styled.a`
  cursor: pointer;
  position: fixed;
  z-index: 9999;
  bottom: 5px;
  left: 5px;
  font-size: 10px;
  background-color: #fff;
  border: 1px solid #f6fafd;
  padding: 3px 3px 2px;
  border-radius: 3px;
  color: #9bb0bf;
  user-select: none;
  &:hover {
    color: #24374e;
  }
`;

const handleCookies = () => {
  window.Cookiebot && window.Cookiebot.renew();
};
const CookieBotBtn = () => (
  <CookieBtn onClick={() => handleCookies()}>Cookie Settings</CookieBtn>
);

export default CookieBotBtn;
