import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Setting up your local development environment with MessageBird`}</h1>
    <h3>{`⏱ 10 min read`}</h3>
    <p>{`In this MessageBird Developer Tutorial you’ll first learn how to get started with MessageBird by setting up first your local development environment.`}</p>
    <p>{`MessageBird provides an SDK (Software Development Kit) for Node.js. This helper library facilitates interactions between your Node.js application's code and the MessageBird API, so you don’t have to craft raw REST API requests. Along this tutorial we’ll explain how to get started with MessageBird in Node.js.`}</p>
    <h2>{`Install Node.js and NPM`}</h2>
    <p>{`Make sure that you have Node.js version 0.10 at least—as this is the minimum version of that the SDK requires. You also need npm, a package manager for Node. The two are typically installed in a single bundle; we recommend you to always use the latest versions of both Node.js and npm.`}</p>
    <p>{`To verify which version do you have, open a terminal and run the following two commands:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`node -v
npm -v
`}</code></pre>
    <p>{`In both cases, your terminal should return a version number, for example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ node -v
v9.4.0
$ npm -v
6.1.0
`}</code></pre>
    <p>{`If you don’t get a version number but something like `}<inlineCode parentName="p">{`node: command not found`}</inlineCode>{` instead, you need to install Node.js and npm first. We’ll explain you how.`}</p>
    <h4>{`MAC`}</h4>
    <p>{`Go to the `}<a parentName="p" {...{
        "href": "https://nodejs.org/en/download/"
      }}>{`Node.js Downloads`}</a>{` page and choose the `}<em parentName="p">{`macOS Installer`}</em>{`. The installer is a standard Mac package file with a UI that guides you through the installation process.
If you prefer, it’s also possible to install Node.js `}<a parentName="p" {...{
        "href": "https://nodejs.org/en/download/package-manager/#macos"
      }}>{`through a package manager, such as Homebrew`}</a>{`.`}</p>
    <h4>{`LINUX`}</h4>
    <p>{`Installation through a package manager is the easiest option. The `}<a parentName="p" {...{
        "href": "https://nodejs.org/en/download/package-manager/#installing-node-js-via-package-manager"
      }}>{`Node.js package manager downloads page`}</a>{` contains instructions for most Linux distributions.`}</p>
    <h4>{`WINDOWS`}</h4>
    <p>{`Go to the `}<a parentName="p" {...{
        "href": "https://nodejs.org/en/download/"
      }}>{`Node.js Downloads`}</a>{` page and choose the `}<em parentName="p">{`Windows Installer`}</em>{`. The installer is a Windows executable installer (MSI) with a UI that guides you through the installation process.`}</p>
    <h2>{`Updating npm`}</h2>
    <p>{`As mentioned above, the Node.js installer is a bundle that includes npm. The package manager, however, receives more frequent updates and the version included in the Node.js installer may not the latest one. Don’t worry, you can update npm independently by going to a terminal and issuing the following command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install npm@latest -g
`}</code></pre>
    <p>{`If you receive a permission error, try again and prefix the command with `}<inlineCode parentName="p">{`sudo`}</inlineCode>{`.`}</p>
    <h2>{`Installing the MessageBird SDK`}</h2>
    <p>{`You use npm to install the MessageBird SDK for Node.js. The SDK is open source, and you can `}<a parentName="p" {...{
        "href": "https://github.com/messagebird/messagebird-nodejs"
      }}>{`browse the code in this GitHub repository`}</a>{`; it’s also `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/messagebird"
      }}>{`listed on npmjs.com`}</a>{` so that npm knows where to find it. SDKs and other libraries are always installed for each project as a dependency and stored in a project-specific directory called `}<inlineCode parentName="p">{`node_modules`}</inlineCode>{` that npm manages.`}</p>
    <p>{`Each Node.js project that uses npm needs a `}<inlineCode parentName="p">{`package.json`}</inlineCode>{` file that includes information both about the current package (your project, such as name and version), as well as the list of dependencies that it relies on (such as the MessageBird SDK).`}</p>
    <p>{`Create a new project directory or open an existing project directory in your terminal.`}</p>
    <p>{`For a new project that doesn't have a `}<inlineCode parentName="p">{`package.json`}</inlineCode>{` file yet, just run the following command and follow the instructions on screen:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm init
`}</code></pre>
    <p>{`Once you have a `}<inlineCode parentName="p">{`package.json`}</inlineCode>{` file, you have two options for adding the MessageBird SDK to it:`}</p>
    <h4>{`Using the install command`}</h4>
    <p>{`Type the following command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install messagebird
`}</code></pre>
    <p>{`This command installs the latest version of the SDK and automatically updates the files `}<inlineCode parentName="p">{`package.json`}</inlineCode>{` and `}<inlineCode parentName="p">{`package-lock.json`}</inlineCode>{`.`}</p>
    <h4>{`Editing package.json`}</h4>
    <p>{`Create a new `}<inlineCode parentName="p">{`package.json`}</inlineCode>{` file or, if you already have this file, add the SDK to it. A minimal file that only defines the MessageBird SDK as a dependency should look like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "dependencies": {
    "messagebird": "^2.1.4"
  }
}
`}</code></pre>
    <p>{`Keep in mind that you have to specify the version of the SDK in the file. You can `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/messagebird"
      }}>{`see the available SDK versions on npmjs.com`}</a>{` and learn more about different ways to specify versions, for example with ranges, `}<a parentName="p" {...{
        "href": "https://docs.npmjs.com/misc/semver"
      }}>{`in the documentation of npm's semver module`}</a>{`.`}</p>
    <p>{`After saving the file, open a terminal in the directory into which you’ve stored it and type this command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install
`}</code></pre>
    <p>{`This command installs everything specified in your package file.`}</p>
    <p><strong parentName="p">{`Nice work!`}</strong>{` 🎉`}</p>
    <p>{`Your Node.js development environment and project directory are ready now!`}</p>
    <h2>{`Start building!`}</h2>
    <p>{`Let's head over to the next MessageBird Developer Tutorials and `}<a parentName="p" {...{
        "href": "/tutorials/send-sms-node"
      }}>{`learn how to send your first SMS using Node.JS`}</a>{`.`}</p>
    <p>{`Want to get started but not quite sure how? Feel free to let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we'd love to help.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      