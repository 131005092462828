/* eslint-disable no-useless-escape */
export const LOOKUP_URI = {
  bash: `https://rest.messagebird.com/lookup`,
};

export const LOOKUP_METHODS = {
  bash: `GET /lookup/{phoneNumber}
GET /lookup/{phoneNumber}/hlr
POST /lookup/{phoneNumber}/hlr`,
};

export const REQUESTLOOKUP_DEF = {
  bash: `GET https://rest.messagebird.com/lookup/{phoneNumber}`,
};

export const REQUESTLOOKUP_REQUEST = {
  curl: `curl -X GET https://rest.messagebird.com/lookup/31612345678 \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM'`,
  php: `$MessageBird = new \\MessageBird\\Client('ACCESS_KEY');

$Lookup = $MessageBird->lookup->read(31612345678);`,
  ruby: `client = MessageBird::Client.new(YOUR_ACCESS_KEY)

lookup = client.lookup('31612345678')`,
  csharp: `const string YourAccessKey = "YOUR_ACCESS_KEY";
const long PhoneNumber = 31612345678;

Client client = Client.CreateDefault(YourAccessKey);
Lookup lookup = client.DoLookup(PhoneNumber);`,
  go: `params := &lookup.Params{CountryCode: "NL"}

lookup, err := lookup.Read(client, "0612345678", params)`,
  python: `client = messagebird.Client(ACCESS_KEY)

lookup = client.lookup('31612345678')`,
  java: `import com.messagebird.MessageBirdClient;
import com.messagebird.MessageBirdService;
import com.messagebird.MessageBirdServiceImpl;
import com.messagebird.exceptions.NotFoundException;
import com.messagebird.exceptions.GeneralException;
import com.messagebird.exceptions.UnauthorizedException;
import com.messagebird.objects.Lookup;

import java.math.BigInteger;

public class ExampleViewLookup {

    public static void main(String[] args) {

        if (args.length < 2) {
            System.out.println("Please specify your access key and phone in that order example : java -jar <this jar file> test_accesskey 31612345678");
            return;
        }

        // First create your service object
        final MessageBirdService wsr = new MessageBirdServiceImpl(args[0]);

        // Add the service to the client
        final MessageBirdClient messageBirdClient = new MessageBirdClient(wsr);

        try {
            // View Lookup
            System.out.println("Viewing Lookup:");
            final Lookup lookupRequest = new Lookup(new BigInteger(args[1]));
            // Optionally set a country code (in the case of national numbers)
            if (args.length > 2 && args[2] != null && !args[2].isEmpty()) {
                lookupRequest.setCountryCode(args[2]);
            }
            final Lookup lookup = messageBirdClient.viewLookup(lookupRequest);
            System.out.println(lookup.toString());
        } catch (UnauthorizedException unauthorized) {
            if (unauthorized.getErrors()!=null) {
                System.out.println(unauthorized.getErrors().toString());
            }
            unauthorized.printStackTrace();
        } catch (GeneralException generalException) {
            if (generalException.getErrors() !=null) {
                System.out.println(generalException.getErrors().toString());
            }
            generalException.printStackTrace();
        } catch (NotFoundException notFoundException) {
            if (notFoundException.getErrors() !=null) {
                System.out.println(notFoundException.getErrors().toString());
            }
            notFoundException.printStackTrace();
        }
    }
}`,
  node: `var messagebird = require('messagebird')('YOUR_ACCESS_KEY');

messagebird.lookup.read('31612345678', function (err, response) {
  if (err) {
    return console.log(err);
  }
  console.log(response);
});`,
};

export const REQUESTLOOKUP_RESPONSE = {
  curl: `{
  "href":"https:\/\/rest.messagebird.com\/lookup\/31612345678",
  "countryCode":"NL",
  "countryPrefix":31,
  "phoneNumber":31612345678,
  "type":"mobile",
  "formats":{
    "e164":"+31612345678",
    "international":"+31 6 12345678",
    "national":"06 12345678",
    "rfc3966":"tel:+31-6-12345678"
  }
}`,
  php: `{
  'href' => 'https://rest.messagebird.com/lookup/31612345678',
  'countryCode' => 'NL',
  'countryPrefix' => 31,
  'phoneNumber' => 31612345678,
  'type' => 'mobile',
  'formats' => {
    'e164' => '+31612345678',
    'international' => '+31 6 12345678',
    'national' => '06 12345678',
    'rfc3966' => 'tel => +31-6-12345678'
  }
}`,
  ruby: `#<MessageBird::Lookup:0x007fafad025698
@href="https://rest.messagebird.com/lookup/31612345678",
@countryCode="NL",
@countryPrefix=31,
@phoneNumber=31612345678,
@type="mobile",
@formats=#<MessageBird::Formats:0x007fafad024ae0
  @e164="+31612345678",
  @international="+31 6 12345678",
  @national="06 12345678",
  @rfc3966="tel:+31-6-12345678">>`,
  csharp: `{
  'href': 'https://rest.messagebird.com/lookup/31612345678',
  'countryCode': 'NL',
  'countryPrefix': 31,
  'phoneNumber': 31612345678,
  'type': 'mobile',
  'formats': {
    'e164': '+31612345678',
    'international': '+31 6 12345678',
    'national': '06 12345678',
    'rfc3966': 'tel: +31-6-12345678'
  }
}`,
  go: `&lookup.Lookup{
  Href:"https://rest.messagebird.com/lookup/31612345678",
  CountryCode:"NL",
  CountryPrefix:31,
  PhoneNumber:31612345678,
  Type:"mobile",
  Formats:lookup.Formats{
    E164:"+31612345678",
    International:"+31 6 12345678",
    National:"06 12345678",
    Rfc3966:"tel:+31-6-12345678",
  },
  HLR:(*hlr.HLR)(0xc4201ae230),
}`,
  python: `{
  'href' : 'https://rest.messagebird.com/lookup/31612345678',
  'countryCode' : 'NL',
  'countryPrefix' : 31,
  'phoneNumber' : 31612345678,
  'type' : 'mobile',
  'formats' : {
    'e164' : '+31612345678',
    'international' : '+31 6 12345678',
    'national' : '06 12345678',
    'rfc3966' : 'tel : +31-6-12345678'
  }
}`,
  java: `{
  href='https://rest.messagebird.com/lookup/31612345678',
  countryCode='NL',
  countryPrefix=31,
  phoneNumber=31612345678,
  type='mobile',
  formats=Formats{
    e164='+31612345678',
    international='+31 6 12345678',
    national='06 12345678',
    rfc3966='tel=+31-6-12345678'
  }
}`,
  node: `{
  'href': 'https://rest.messagebird.com/lookup/31612345678',
  'countryCode': 'NL',
  'countryPrefix': 31,
  'phoneNumber': 31612345678,
  'type': 'mobile',
  'formats': {
    'e164': '+31612345678',
    'international': '+31 6 12345678',
    'national': '06 12345678',
    'rfc3966': 'tel: +31-6-12345678'
  }
}`,
};

export const REQUESTHLR_DEF = {
  bash: `POST https://rest.messagebird.com/lookup/{phoneNumber}/hlr`,
};
export const REQUESTHLR_REQUEST = {
  curl: `curl -X POST https://rest.messagebird.com/lookup/31612345678/hlr \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -d "reference=YourReference"`,
  php: `$MessageBird = new \\MessageBird\\Client('ACCESS_KEY');

$Hlr             = new \\MessageBird\\Objects\\Hlr();
$Hlr->msisdn     = '31612345678';
$Hlr->reference  = "reference";

$hlr = $MessageBird->lookupHlr->create($Hlr);`,
  ruby: `client = MessageBird::Client.new(YOUR_ACCESS_KEY)

hlr = client.lookup_hlr_create('31612345678', :reference => 'Reference')`,
  csharp: `const string YourAccessKey = "YOUR_ACCESS_KEY";
const long PhoneNumber = 31612345678;
const string Reference = "Reference";

Client client = Client.CreateDefault(YourAccessKey);
LookupHlr lookupHlr = client.RequestLookupHlr(PhoneNumber, Reference);`,
  go: `{
  'id': '9772ef10153a19646413512h43680562',
  'href': 'https://rest.messagebird.com/hlr/9772ef10153a19646413512h43680562',
  'msisdn': 31612345678,
  'network': NULL,
  'reference': 'Reference',
  'status': 'sent',
  'createdDatetime': '2016-05-04T07:32:46+00:00',
  'statusDatetime': '2016-05-04T07:32:46+00:00',
}`,
  python: `client = messagebird.Client(ACCESS_KEY)

lookup_hlr = client.lookup_hlr_create('31612345678', { 'reference' : 'Reference' })`,
  java: `import com.messagebird.MessageBirdClient;
import com.messagebird.MessageBirdService;
import com.messagebird.MessageBirdServiceImpl;
import com.messagebird.exceptions.NotFoundException;
import com.messagebird.exceptions.GeneralException;
import com.messagebird.exceptions.UnauthorizedException;
import com.messagebird.objects.LookupHlr;

import java.math.BigInteger;

public class ExampleRequestLookupHlr {

    public static void main(String[] args) {

        if (args.length < 3) {
            System.out.println("Please specify your access key, phone and reference in that order example : java -jar <this jar file> test_accesskey 31612345678 reference");
            return;
        }

        // First create your service object
        final MessageBirdService wsr = new MessageBirdServiceImpl(args[0]);

        // Add the service to the client
        final MessageBirdClient messageBirdClient = new MessageBirdClient(wsr);

        try {
            // Request Lookup HLR
            System.out.println("Requesting Lookup HLR:");
            final LookupHlr lookupHlrRequest = new LookupHlr();
            lookupHlrRequest.setPhoneNumber(new BigInteger(args[1]));
            lookupHlrRequest.setReference(args[2]);
            // Optionally set a country code (in the case of national numbers)
            if (args.length > 3 && args[3] != null && !args[3].isEmpty()) {
                lookupHlrRequest.setCountryCode(args[3]);
            }
            final LookupHlr lookupHlr = messageBirdClient.requestLookupHlr(lookupHlrRequest);
            System.out.println(lookupHlr.toString());
        } catch (UnauthorizedException unauthorized) {
            if (unauthorized.getErrors()!=null) {
                System.out.println(unauthorized.getErrors().toString());
            }
            unauthorized.printStackTrace();
        } catch (GeneralException generalException) {
            if (generalException.getErrors() !=null) {
                System.out.println(generalException.getErrors().toString());
            }
            generalException.printStackTrace();
        }
    }
}`,
  node: `var messagebird = require('messagebird')('YOUR_ACCESS_KEY');

messagebird.lookup.hlr.create('31612345678', function (err, response) {
  if (err) {
    return console.log(err);
  }
  console.log(response);
});`,
};
export const REQUESTHLR_RESPONSE = {
  curl: `{
  "id":"0da180b035398662ceb3c42h04904985",
  "href":"https:\/\/rest.messagebird.com\/hlr\/0da180b035398662ceb3c42h04904985",
  "msisdn":31612345678,
  "network":null,
  "details":[],
  "reference":"YourReference",
  "status":"sent",
  "createdDatetime":"2016-05-04T07:32:46+00:00",
  "statusDatetime":"2016-05-04T07:32:46+00:00"
}`,
  php: `{
  'id' => '0da180b035398662ceb3c42h04904985',
  'href' => 'https://rest.messagebird.com/hlr/0da180b035398662ceb3c42h04904985',
  'msisdn' => 31612345678,
  'network' => NULL,
  'reference' => 'Reference',
  'status' => 'sent',
  'createdDatetime' => '2016-05-04T07:32:46+00:00',
  'statusDatetime' => '2016-05-04T07:32:46+00:00'
}`,
  ruby: `#<MessageBird::HLR:0x007fc75b80a488
@id="af92e362556a0b6bbe0df50h61160940",
@href="https://rest.messagebird.com/hlr/af92e362556a0b6bbe0df50h61160940",
@msisdn=31612345678,
@network=nil,
@reference="Reference",
@status="sent",
@createdDatetime=2016-05-04 07:32:46 +0000,
@statusDatetime=2016-05-04 07:32:46 +0000>`,
  csharp: `{
  'id': '9772ef10153a19646413512h43680562',
  'href': 'https://rest.messagebird.com/hlr/9772ef10153a19646413512h43680562',
  'msisdn': 31612345678,
  'network': NULL,
  'reference': 'Reference',
  'status': 'sent',
  'createdDatetime': '2016-05-04T07:32:46+00:00',
  'statusDatetime': '2016-05-04T07:32:46+00:00',
}`,
  go: `&hlr.HLR{
  ID:"397ba0e5496a48729d99b93148763662",
  HRef:"https://rest.messagebird.com/hlr/397ba0e5496a48729d99b93148763662",
  MSISDN:31612345678,
  Reference:"Reference",
  Status:"sent",
  CreatedDatetime:(*time.Time)(0xc420342080),
  StatusDatetime:(*time.Time)(0xc4203420e0),
}`,
  python: `{
  'id' : '0da180b035398662ceb3c42h04904985',
  'href' : 'https://rest.messagebird.com/hlr/0da180b035398662ceb3c42h04904985',
  'msisdn' : 31612345678,
  'network' : None,
  'reference' : 'Reference',
  'status' : 'sent',
  'createdDatetime' : '2016-05-04 07:32:46',
  'statusDatetime' : '2016-05-04 07:32:46'
}`,
  java: `{
  id='ce654390654e1ce5530e9a8h56512055',
  href='https://rest.messagebird.com/hlr/ce654390654e1ce5530e9a8h56512055',
  msisdn=31612345678,
  network='null',
  reference='Reference',
  status='sent',
  createdDatetime=2016-05-04T07:32:46+00:00,
  statusDatetime=2016-05-04T07:32:46+00:00
}`,
  node: `{
  'id': '9772ef10153a19646413512h43680562',
  'href': 'https://rest.messagebird.com/hlr/9772ef10153a19646413512h43680562',
  'msisdn': 31612345678,
  'network': NULL,
  'reference': 'Reference',
  'status': 'sent',
  'createdDatetime': '2016-05-04T07:32:46+00:00',
  'statusDatetime': '2016-05-04T07:32:46+00:00',
}`,
};

export const VIEWHLR_DEF = {
  bash: `GET https://rest.messagebird.com/lookup/{phoneNumber}/hlr`,
};

export const VIEWHLR_REQUEST = {
  curl: `curl -X GET https://rest.messagebird.com/lookup/31612345678/hlr \\
   -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM'`,
  php: `$MessageBird = new \\MessageBird\\Client('ACCESS_KEY');

$hlr = $MessageBird->lookupHlr->read(31612345678);`,
  ruby: `client = MessageBird::Client.new(YOUR_ACCESS_KEY)

hlr = client.lookup_hlr('31612345678')`,
  csharp: `const string YourAccessKey = "YOUR_ACCESS_KEY";
const long PhoneNumber = 31612345678;

Client client = Client.CreateDefault(YourAccessKey);
LookupHlr LookupHlr = client.ViewLookupHlr(PhoneNumber);`,
  go: `params := &lookup.Params{CountryCode: "NL"}

lookupHLR, err := lookup.ReadHLR(client, "31612345678", params)`,
  python: `client = messagebird.Client(ACCESS_KEY)

lookup_hlr = client.lookup_hlr('31612345678')`,
  java: `import com.messagebird.MessageBirdClient;
import com.messagebird.MessageBirdService;
import com.messagebird.MessageBirdServiceImpl;
import com.messagebird.exceptions.NotFoundException;
import com.messagebird.exceptions.GeneralException;
import com.messagebird.exceptions.UnauthorizedException;
import com.messagebird.objects.LookupHlr;

import java.math.BigInteger;

public class ExampleViewLookupHlr {

    public static void main(String[] args) {

        if (args.length < 2) {
            System.out.println("Please specify your access key and phone in that order example : java -jar <this jar file> test_accesskey 31612345678");
            return;
        }

        // First create your service object
        final MessageBirdService wsr = new MessageBirdServiceImpl(args[0]);

        // Add the service to the client
        final MessageBirdClient messageBirdClient = new MessageBirdClient(wsr);

        try {
            // View Lookup HLR
            System.out.println("Viewing Lookup HLR:");
            final LookupHlr lookupHlrRequest = new LookupHlr();
            lookupHlrRequest.setPhoneNumber(new BigInteger(args[1]));
            // Optionally set a country code (in the case of national numbers)
            if (args.length > 2 && args[2] != null && !args[2].isEmpty()) {
                lookupHlrRequest.setCountryCode(args[2]);
            }
            final LookupHlr lookupHlr = messageBirdClient.viewLookupHlr(lookupHlrRequest);
            System.out.println(lookupHlr.toString());
        } catch (UnauthorizedException unauthorized) {
            if (unauthorized.getErrors()!=null) {
                System.out.println(unauthorized.getErrors().toString());
            }
            unauthorized.printStackTrace();
        } catch (GeneralException generalException) {
            if (generalException.getErrors() !=null) {
                System.out.println(generalException.getErrors().toString());
            }
            generalException.printStackTrace();
        } catch (NotFoundException notFoundException) {
            if (notFoundException.getErrors() !=null) {
                System.out.println(notFoundException.getErrors().toString());
            }
            notFoundException.printStackTrace();
        }
    }
}`,
  node: `var messagebird = require('messagebird')('YOUR_ACCESS_KEY');

messagebird.lookup.hlr.read('31612345678', function (err, response) {
  if (err) {
    return console.log(err);
  }
  console.log(response);
});`,
};

export const VIEWHLR_RESPONSE = {
  curl: `{
  "id":"0da180b035398662ceb3c42h04904985",
  "href":"https:\/\/rest.messagebird.com\/hlr\/0da180b035398662ceb3c42h04904985",
  "msisdn":31612345678,
  "network":20406,
  "reference":"YourReference",
  "details": {
    "status_desc": null,
    "imsi": "204080010948431",
    "country_iso": "NLD",
    "country_name": "Netherlands",
    "location_msc": "316530",
    "location_iso": 'nl',
    "ported": 0,
    "roaming": 0
  },
  "status":"active",
  "createdDatetime":"2016-05-04T07:32:46+00:00",
  "statusDatetime":"2016-05-04T07:32:46+00:00"
}`,
  php: `{
  'id' => '0da180b035398662ceb3c42h04904985',
  'href' => 'https://rest.messagebird.com/hlr/0da180b035398662ceb3c42h04904985',
  'msisdn' => 31612345678,
  'network' => 20406,
  'reference' => 'Reference',
  'status' => 'active',
  'createdDatetime' => '2016-05-04T07:32:46+00:00',
  'statusDatetime' => '2016-05-04T07:32:46+00:00'
}`,
  ruby: `#<MessageBird::HLR:0x007fc75b80a488
@id="af92e362556a0b6bbe0df50h61160940",
@href="https://rest.messagebird.com/hlr/af92e362556a0b6bbe0df50h61160940",
@msisdn=31612345678,
@network=20406,
@reference="Reference",
@status="active",
@createdDatetime=2016-05-04 07:32:46 +0000,
@statusDatetime=2016-05-04 07:32:46 +0000>`,
  csharp: `{
  'id': '9772ef10153a19646413512h43680562',
  'href': 'https://rest.messagebird.com/hlr/9772ef10153a19646413512h43680562',
  'msisdn': 31612345678,
  'network': 20104,
  'reference': 'Reference',
  'status': 'active',
  'createdDatetime': '2016-05-04T07:32:46+00:00',
  'statusDatetime': '2016-05-04T07:32:46+00:00',
}`,
  go: `&hlr.HLR{
  ID:"397ba0e5496a48729d99b93148763662",
  HRef:"https://rest.messagebird.com/hlr/397ba0e5496a48729d99b93148763662",
  MSISDN:31612345678,
  Network:20408,
  Reference:"",
  Status:"absent",
  CreatedDatetime:(*time.Time)(0xc42013a180),
  StatusDatetime:(*time.Time)(0xc42013a1e0),
}`,
  python: `{
  'id' : '0da180b035398662ceb3c42h04904985',
  'href' : 'https://rest.messagebird.com/hlr/0da180b035398662ceb3c42h04904985',
  'msisdn' : 31612345678,
  'network' : 20406,
  'reference' : 'Reference',
  'status' : 'active',
  'createdDatetime' : '2016-05-04 07:32:46',
  'statusDatetime' : '2016-05-04 07:32:46'
}`,
  java: `{
  id='ce654390654e1ce5530e9a8h56512055',
  href='https://rest.messagebird.com/hlr/ce654390654e1ce5530e9a8h56512055',
  msisdn=31612345678,
  network=20104,
  reference='Reference',
  status='active',
  createdDatetime=2016-05-04T07:32:46+00:00,
  statusDatetime=2016-05-04T07:32:46+00:00
}`,
  node: `{
  'id': '9772ef10153a19646413512h43680562',
  'href': 'https://rest.messagebird.com/hlr/9772ef10153a19646413512h43680562',
  'msisdn': 31612345678,
  'network': 20104,
  'reference': 'Reference',
  'status': 'active',
  'createdDatetime': '2016-05-04T07:32:46+00:00',
  'statusDatetime': '2016-05-04T07:32:46+00:00',
}`,
};
