import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Docs.tsx";
import Notification from 'components/Notification';
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`WhatsApp Message Templates`}</h1>
    <p>{`When initiating a conversation with a customer, WhatsApp requires you to use a pre-approved Message Template (previously referred to as a Highly Structured Message or HSM. In this quickstart, you'll learn how to write and send a Message Template using `}<strong parentName="p">{`Node.js`}</strong>{`.`}</p>
    <p>{`Every Message Template needs to be approved by WhatsApp before it can be used. WhatsApp generally takes a couple of days to approve or reject a template, but this may vary depending on the language.`}</p>
    <p>{`When submitting the Message Template, keep in mind that it needs to contain the full body of the message (with the option to add unique parameters represented as placeholders) and at least one parameter.`}</p>
    <p>{`But wait, how can you submit a WhatsApp Message Template? We're glad you asked, you can do it via the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/whatsapp-template-manager/"
      }}>{`WhatsApp Template Manager`}</a>{` on the MessageBird Dashboard.`}</p>
    <h2>{`Writing a Message Template`}</h2>
    <p>{`For example, the message:`}</p>
    <p><em parentName="p">{`Name: Account Registration`}</em></p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`Hi Anna! Thanks for starting your personal training plan. We’ll send you a weekly update with your new schedule. You can log-in online using your training ID 123456. Stay fit!
`}</code></pre>
    <p>{`Needs to be submitted as:`}</p>
    <p><em parentName="p">{`Name: account_registration`}</em></p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`Hi {{1}}! Thanks for starting your personal training plan. We’ll send you a {{2}} update with your new schedule. You can log-in online using your training ID {{3}}. Stay fit!
`}</code></pre>
    <p>{`The Message Template uses numbered placeholders '{{x}}' for each variable in the message. Each variable can be replaced with text that contains letters, digits, special characters, or spaces. For our example, {{1}} is the name or the customer, {{2}} is what the company is sending to the customer, {{3}} is the training ID of that specific client.`}</p>
    <p>{`Remember that each Message Template needs at least one parameter. The name of the Message Template needs to be formatted in small letters only, and the spaces must be replaced with an underscore.`}</p>
    <p>{`One of the unique features of WhatsApp is that it offers locale editing. When sending the Message Template, WhatsApp will identify the language of the recipient WhatsApp account. If a locale Message Template has been submitted, that specific language will be used.`}</p>
    <h2>{`Sending a Message Template`}</h2>
    <p>{`To input actual variables into a Message Template, you'll need to send parameter values instead of sending the full message.`}</p>
    <p>{`For the following Message Template example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`Hi {{1}}, your order has been shipped and should arrive {{2}}
`}</code></pre>
    <p>{`You'll need to send the following example request:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var messagebird = require('messagebird')('YOUR_ACCESS_KEY');

var params = {
  type: 'hsm',
  content: {
    hsm: {
      namespace: '5ba2d0b7_f2c6_433b_a66e_57b009ceb6ff',
      templateName: 'order_update',
      language: {
        policy: 'deterministic',
        code: 'en',
      },
      params: [{ default: 'Bob' }, { default: 'tomorrow!' }],
    },
  },
};

messagebird.conversations.reply('2e15efafec384e1c82e9842075e87beb', params, function(err, response) {
  if (err) {
    return console.log(err);
  }
  console.log(response);
});
`}</code></pre>
    <p>{`If the request is successful, the response will be similar to this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "id": "2ec3d1e8725f41b1933906426738e6a5",
  "conversationId": "2e15efafec384e1c82e9842075e87beb",
  "channelId": "aa4c8e7cee664341a22ef9bfd9f52477",
  "status": "pending",
  "type": "hsm",
  "direction": "sent",
  "content": {
    "hsm": {
      "namespace": "5ba2d0b7_f2c6_433b_a66e_57b009ceb6ff",
      "templateName": "order_update",
      "language": {
        "policy": "deterministic",
        "code": "en"
      },
      "params": [
        {
          "default": "Bob"
        },
        {
          "default": "tomorrow!"
        }
      ]
    }
  },
  "createdDatetime": "2018-08-29T13:53:44.642664784Z",
  "updatedDatetime": "2018-08-29T13:53:44.673850825Z"
}
`}</code></pre>
    <p>{`If the request is unsuccessful, head over to the Conversations API Troubleshooting to understand the error; if you're still stuck, feel free to `}<a parentName="p" {...{
        "href": "https://www.messagebird.com/en/contact"
      }}>{`contact us`}</a>{`—we're happy to help you out.`}</p>
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'Programmable Conversations API Reference',
      to: '/api/conversations'
    }, {
      name: 'Official MessageBird SDKs',
      to: '/libraries'
    }, {
      name: 'Use Case Tutorials',
      to: '/tutorials'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      