import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import { Img } from 'components/shared';
import Questions from "../../../components/Footer/Questions";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Authentication templates overview`}</h1>
    <p>{`If you give your users the option to receive one-time passwords or verification codes via the WhatsApp app or WhatsApp Business app, you must use an authentication template with a one-time password button (OTP) to deliver the password or code. `}<br />{`
Authentication templates with OTP buttons consist of the following: `}<br /></p>
    <ul>
      <li parentName="ul">{`Preset authentication message template fixed text:`}
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`VERIFICATION_CODE`}</inlineCode>{` is your verification code.`}</li>
          <li parentName="ul">{`Security disclaimer (optional): For your security, do not share this code.`}</li>
          <li parentName="ul">{`Expiration warning (optional): This code expires in `}<inlineCode parentName="li">{`NUM_MINUTES`}</inlineCode>{` minutes.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Button: Either a copy code or one-tap autofill button.`}</li>
    </ul>
    <p>{`URLs, media, and emojis are not supported. Because authentication templates with OTP buttons only consist of preset text and buttons, their risk of being paused is significantly minimized. `}<br /></p>
    <h2>{`Create a new authentication template using the template creator`}</h2>
    <ol>
      <li parentName="ol">{`The easiest way to create an authentication template is to use the WhatsApp Template Manager. From the WhatsApp template manager provide a template name, select the language(s) the template will be sent in and set the category as authentication.`}
        <Img src="/img/screenshots/quickstarts/whatsapp/create-authentication-template.png" alt="Authentication template" width="800px" align="middle" mdxType="Img" />
      </li>
      <li parentName="ol">{`Select the type of authentication template type that you want to use. Either:`}</li>
    </ol>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Copy Code`}</strong>{` - A copy code button copies the one-time password or code to the user's clipboard. The user can then manually switch to your login screen and paste the password or code. `}<br /></li>
      <li parentName="ul">{`*One Tap** - A one-tap autofill button automatically loads and passes your app the one-time password or code. This offers the best user experience, but one-tap buttons are only supported on an android device where the end user is also running your application. `}<a parentName="li" {...{
          "href": "https://developers.facebook.com/docs/whatsapp/business-management-api/authentication-templates#handshake"
        }}>{`See additional requirements for one tap`}</a>{` `}<br /></li>
    </ul>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Regardless of authentication template type you have chosen you can choose to include a security warning and an expiry time. `}<br /></li>
    </ol>
    <ul>
      <li parentName="ul">{`*Security warning** - this will add localised text to the message warning a user not to share a code. As an example in English - "For your security, do not share this code.""`}</li>
      <li parentName="ul">{`*Expiration warning** - this will add a localised text to the message informing the user when the code will expire. As an example in English - "This code expires in `}<inlineCode parentName="li">{`NUM_MINUTES`}</inlineCode>{` minutes.""`}
        <ul parentName="li">
          <li parentName="ul">{`If you opt to add an expiration warning then provide the number of minutes the code will be valid for`}</li>
        </ul>
      </li>
    </ul>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Complete the remaining fields `}<br /></li>
    </ol>
    <ul>
      <li parentName="ul">{`If you have selected the authentication template type of "Copy Code" you will then need to enter the localised text that will be displayed on the button in the message.`}
        <Img src="/img/screenshots/quickstarts/whatsapp/complete-copy-code-authentication-template.png" alt="Authentication template" width="800px" align="middle" mdxType="Img" />
      </li>
      <li parentName="ul">{`If you have selected the authentication template type of "One Tap" you will then need to enter localised text for both the one tap button and also a copy code button which will be used as a fallback on unsupported devices. Also fill in your android package name and signature hash. `}<a parentName="li" {...{
          "href": "https://developers.facebook.com/docs/whatsapp/business-management-api/authentication-templates#handshake"
        }}>{`See additional requirements for one tap`}</a>
        <Img src="/img/screenshots/quickstarts/whatsapp/complete-one-tap-authentication-template.png" alt="Authentication template" width="800px" align="middle" mdxType="Img" />
      </li>
    </ul>
    <h2>{`Create a new authentication template via the API`}</h2>
    <p>{`It is also possible to create an authentication template via the API. You can create a template by sending the following request.`}<br /></p>
    <h3>{`Example for Copy Code`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`curl -X "POST" "https://integrations.messagebird.com/v2/platforms/whatsapp/templates" \\
     -H 'Authorization: AccessKey <access-key>' \\
     -H 'Content-Type: text/plain; charset=utf-8' \\
     -d $'{
  "name": "copy_code_authentication_template",
  "wabaId": "169743163687422",
  "category": "AUTHENTICATION",
  "language": "en",
  "components": [
    {
      "type": "BODY",
      "add_security_recommendation": true
    },
    {
      "type": "FOOTER",
      "code_expiration_minutes": 5
    },
    {
      "type": "BUTTONS",
      "buttons": [
        {
          "type": "OTP",
          "otp_type": "COPY_CODE",
          "text": "Copy Code"
        }
      ]
    }
  ]
}
'
`}</code></pre>
    <h3>{`Example for One Tap`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`curl -X "POST" "https://integrations.messagebird.com/v2/platforms/whatsapp/templates" \\
     -H 'Authorization: AccessKey <access-key>' \\
     -H 'Content-Type: text/plain; charset=utf-8' \\
     -d $'{
  "name": "one_tap_authentication_template",
  "wabaId": "169743163687422",
  "category": "AUTHENTICATION",
  "language": "en",
  "components": [
    {
      "type": "BODY",
      "add_security_recommendation": true
    },
    {
      "type": "FOOTER",
      "code_expiration_minutes": 5
    },
    {
      "type": "BUTTONS",
      "buttons": [
        {
          "type": "OTP",
          "otp_type": "ONE_TAP",
          "text": "Copy Code",
          "autofill_text": "Autofill",
          "package_name": "com.example.luckyshrub",
          "signature_hash": "K8a%2FAINcGX7"
        }
      ]
    }
  ]
}
'
`}</code></pre>
    <h2>{`Send an authentication template`}</h2>
    <p>{`Once your template has been approved by Meta to send the template you must perform a HTTP request to the Conversations API. For both one tap and copy code templates the request is the same `}<br /></p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`curl -X "POST" "https://conversations.messagebird.com/v1/send" \\
     -H 'Authorization: AccessKey <access-key>' \\
     -H 'Content-Type: application/json; charset=utf-8' \\
     -d $'{
  "to": "+3197006520256",
  "from": "320b489e-d1dc-409a-900d-fd5812f8078a",
  "type": "hsm",
  "content": {
    "hsm": {
      "namespace": "20332cd4_f095_b080_d255_35677159aaff",
      "templateName": "auth_one_tap_otp_button",
      "language": {
        "code": "en_US"
      },
      "components": [
        {
          "type": "body",
          "parameters": [
            {
              "type": "text",
              "text": "12345"
            }
          ]
        },
        {
          "type": "button",
          "sub_type": "url",
          "index": 0,
          "parameters": [
            {
              "type": "text",
              "text": "12345"
            }
          ]
        }
      ]
    }
  }
}
'
`}</code></pre>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`content.hsm.namespace`}</inlineCode>{` can be found in the WhatsApp templates Manager of your Facebook Business account, or, in case you don't have access to it, please contact our `}<a parentName="li" {...{
          "href": "https://www.messagebird.com/en/contact"
        }}>{`support team`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`content.hsm.language.code`}</inlineCode>{` is the language which the template message should be sent`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`content.hsm.templateName`}</inlineCode>{` is the template name`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`to`}</inlineCode>{` is the phone number of the recipient`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`type`}</inlineCode>{` must be hsm because this is a template message`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`from`}</inlineCode>{` must be your WhatsApp channel ID`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`content.hsm.components`}</inlineCode>{` contains a list of components that will be used to replace the variables in the template.`}
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`type`}</inlineCode>{` defines the type of the component, in this case `}<inlineCode parentName="li">{`body`}</inlineCode>{` and `}<inlineCode parentName="li">{`button`}</inlineCode>{`. Both components must be present in the template`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`sub_type`}</inlineCode>{` defines the type of button, in this case a `}<inlineCode parentName="li">{`url`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`parameters`}</inlineCode>{` contains a list of parameters to provide values to variables`}
            <ul parentName="li">
              <li parentName="ul"><inlineCode parentName="li">{`type`}</inlineCode>{` defines the type of value, in this case `}<inlineCode parentName="li">{`text`}</inlineCode></li>
              <li parentName="ul"><inlineCode parentName="li">{`text`}</inlineCode>{` provides the value that must be used in the variable. For both `}<inlineCode parentName="li">{`body`}</inlineCode>{` and `}<inlineCode parentName="li">{`button`}</inlineCode>{` components, the value should be the same`}</li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      