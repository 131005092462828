import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 30px;
  table-layout: fixed;

  th,
  td {
    word-break: break-word;
    hyphens: auto;
    font-size: 16px;
    line-height: 25px;
    padding: 24px 10px;
    border-top: 1px solid #e6f0ff;
  }
  th:nth-child(3),
  td:nth-child(3) {
    width: 40%;
  }
  td .notification {
    margin-top: 24px;
    margin-bottom: 0;
    @media (max-width: 1366px) {
      flex-direction: column;
      align-items: center;
      padding: 10px;
    }
  }
  tr {
    text-align: left;
  }

  th,
  tr:nth-of-type(2n) {
    background-color: #fafbff;
  }
`;
