import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/ApiRef.js";
import Helmet from 'react-helmet';
import CodeSnippet from 'components/CodeSnippet';
import Notification from 'components/Notification';
import { APIWrapper, LeftColumn, RightColumn } from 'components/ApiRef';
import * as CODE from 'constants/navigator';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Helmet mdxType="Helmet">
      <meta name="robots" content="noindex" />
    </Helmet>
    <h1>{`Navigator`}</h1>
    <p>{`MessageBird Navigator is a backend logic service that helps send messages based on your Number inventory and sending configurations. Setting up Navigators makes it easier to ensure your messaging content is delivered correctly and in a compliant manner.`}</p>
    <p>{`Navigators are identified by a unique random ID. Using this ID, you can create, view, update or delete navigators.`}</p>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`The Navigator object`}</h2>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Attribute`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`id`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A unique random ID which is created on the MessageBird platform and is returned upon creation of the object.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`name`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The given name of the navigator.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`alphaSender`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The default originator. Not required.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`encodingHelper`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`bool`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Set to true if you want automatic encoding or false for no encoding.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`statusUrl`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`URL the status of the messages is delivered to.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`inboundSmsUrl`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`URL to which incoming SMS messages are forwarded for the numbers connected to this Navigator.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`inboundSmsMethod`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`HTTP Method: GET or POST`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`numbers`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`object`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Object containing the URL for the list of connected numbers. If there’s a number for the country, this number overwrites alphaSender. In case there is no alphaSender, a random number from this list is used, even for other countries.`}</td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Example</h4>
  <CodeSnippet code={CODE.EXAMPLE1} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Phonenumber object`}</h2>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Name`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Required`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`number`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`string`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`no`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Number fragment.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`region`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`string`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`no`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Fragment of Region name where number is.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`locality`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`string`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`no`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Fragment of Locality name where number is.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`features`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`array`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`no`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The `}<em parentName="td">{`feature`}</em>{` of a number. Possible values: `}<inlineCode parentName="td">{`sms`}</inlineCode>{`, `}<inlineCode parentName="td">{`voice`}</inlineCode>{`, `}<inlineCode parentName="td">{`mms`}</inlineCode>{`.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`type`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`string`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`no`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The type of number. Possible values: `}<inlineCode parentName="td">{`landline`}</inlineCode>{`, `}<inlineCode parentName="td">{`mobile`}</inlineCode>{`, `}<inlineCode parentName="td">{`shortcode`}</inlineCode>{`, `}<inlineCode parentName="td">{`toll_free`}</inlineCode>{`, `}<inlineCode parentName="td">{`premium_rate`}</inlineCode>{`.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`status`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`string`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`no`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Status of the number. Possible value: `}<inlineCode parentName="td">{`active`}</inlineCode>{` and `}<inlineCode parentName="td">{`cancelled`}</inlineCode>{`.`}</td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Example</h4>
  <CodeSnippet code={CODE.EXAMPLE2} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <h1>{`External API endpoints`}</h1>
    <h3>{`GET `}<inlineCode parentName="h3">{`/navigators`}</inlineCode></h3>
    <p>{`Get a list of all navigators`}</p>
    <h3>{`GET `}<inlineCode parentName="h3">{`/navigators/{id}`}</inlineCode></h3>
    <p>{`Get one navigator`}</p>
    <h3>{`PUT `}<inlineCode parentName="h3">{`/navigators/{id}`}</inlineCode></h3>
    <p>{`Update a navigator`}</p>
    <h3>{`POST `}<inlineCode parentName="h3">{`/navigators`}</inlineCode></h3>
    <p>{`Create a new navigator. Only `}<inlineCode parentName="p">{`name`}</inlineCode>{` is required.`}</p>
    <h3>{`DELETE `}<inlineCode parentName="h3">{`/navigators/{id}`}</inlineCode></h3>
    <p>{`Delete a navigator`}</p>
    <h3>{`GET `}<inlineCode parentName="h3">{`/navigators/{id}/phonenumbers`}</inlineCode></h3>
    <p>{`Show an array of all numbers that can/will be used with this.`}</p>
    <h3>{`POST `}<inlineCode parentName="h3">{`/navigators/{id}/phonenumbers`}</inlineCode></h3>
    <p>{`Add a new number to this navigator`}</p>
    <h3>{`DELETE `}<inlineCode parentName="h3">{`/navigators/{id}/phonenumbers/{number}`}</inlineCode></h3>
    <p>{`Remove the number from this navigator`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      