import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Setting SMS reminders with MessageBird`}</h1>
    <h3>{`⏱ 15 min build time      ||      `}<a parentName="h3" {...{
        "href": "https://github.com/messagebirdguides/reminders-guide-php"
      }}>{`Download the Code`}</a></h3>
    <h2>{`Why build SMS appointment reminders?`}</h2>
    <p>{`In this MessageBird Developer Tutorial, you’ll learn how to build an SMS appointment reminder application and improve your users’ experience with the `}<a parentName="p" {...{
        "href": "/api/sms-messaging"
      }}>{`MessageBird SMS Messaging API`}</a>{`.`}</p>
    <p>{`Booking appointments online from a website or mobile app is quick and easy. Customers just have to select their desired date and time, personal details and hit a button. The problem, however, is that easy-to-book appointments are often just as easy to forget.`}</p>
    <p>{`For appointment-based services, no-shows are annoying and costly because of the time and revenue lost waiting for a customer instead of serving them or another customer. Timely SMS reminders simple and discrete nudges, which can go a long way in the prevention of costly no-shows.`}</p>
    <h2>{`Getting started`}</h2>
    <p>{`This sample application is built in PHP and represents the order website for our fictitious online beauty salon, BeautyBird. To reduce the growing number of no-shows, BeautyBird now collects appointment bookings through a form on their website and schedules timely SMS reminders to be sent out three hours before the selected date and time.`}</p>
    <p>{`To run the sample application, you need to have PHP installed. If you're using a Mac, PHP is already installed. For Windows users, you can `}<a parentName="p" {...{
        "href": "https://windows.php.net/download/"
      }}>{`get it from windows.php.net`}</a>{`. Linux users, please check your system's default package manager. You also need Composer, which is available from `}<a parentName="p" {...{
        "href": "https://getcomposer.org/download/"
      }}>{`getcomposer.org`}</a>{`, to install the `}<a parentName="p" {...{
        "href": "https://github.com/messagebird/php-rest-api"
      }}>{`MessageBird SDK for PHP`}</a>{` and other dependencies.`}</p>
    <p>{`You can either clone the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/reminders-guide-php"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{` or download and extract it a ZIP archive.`}</p>
    <p>{`Then, open a console pointed at the directory into which you've placed the sample application and run the following command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`composer install
`}</code></pre>
    <p>{`Apart from the MessageBird SDK, Composer will install a few additional libraries: the `}<a parentName="p" {...{
        "href": "https://packagist.org/packages/slim/slim"
      }}>{`Slim framework`}</a>{`, the `}<a parentName="p" {...{
        "href": "https://packagist.org/packages/slim/twig-view"
      }}>{`Twig templating engine`}</a>{`, and the `}<a parentName="p" {...{
        "href": "https://packagist.org/packages/vlucas/phpdotenv"
      }}>{`Dotenv configuration library`}</a>{`. By using these libraries we keep our controller, view, and configuration nicely separated without having to set up a full-blown framework like Laravel or Symfony.`}</p>
    <h2>{`Configuring the MessageBird SDK`}</h2>
    <p>{`The SDK is listed as a dependency in `}<inlineCode parentName="p">{`composer.json`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
   "require" : {
       "messagebird/php-rest-api" : "^1.9.4"
       ...
   }
}
`}</code></pre>
    <p>{`An application can access the SDK, which is made available through Composer autoloading, by creating an instance of the `}<inlineCode parentName="p">{`MessageBird\\Client`}</inlineCode>{` class. The constructor takes a single argument, your `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`API key`}</a>{`. For frameworks like Slim it makes sense to add the SDK to the dependency injection container like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`// Load and initialize MessageBird SDK
$container['messagebird'] = function() {
   return new MessageBird\\Client(getenv('MESSAGEBIRD_API_KEY'));
};
`}</code></pre>
    <p>{`As it's a bad practice to keep credentials in the source code, we load the API key from an environment variable using `}<inlineCode parentName="p">{`getenv()`}</inlineCode>{`. To make the key available in the environment variable we need to initialize Dotenv and then add the key to a `}<inlineCode parentName="p">{`.env`}</inlineCode>{` file.`}</p>
    <p>{`Apart from `}<inlineCode parentName="p">{`MESSAGEBIRD_API_KEY`}</inlineCode>{`, we use two other environment variables as part of the application's configuration; the `}<inlineCode parentName="p">{`COUNTRY_CODE`}</inlineCode>{`, which will later help us understand the user's phone number, and the `}<inlineCode parentName="p">{`TIMEZONE`}</inlineCode>{`, so we can make date and time calculations within the correct timezone.`}</p>
    <p>{`You can copy the `}<inlineCode parentName="p">{`env.example`}</inlineCode>{` file provided in the repository to `}<inlineCode parentName="p">{`.env`}</inlineCode>{` and then add your API key and values for the other configuration options like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-env"
      }}>{`MESSAGEBIRD_API_KEY=YOUR-API-KEY
COUNTRY_CODE=NL
TIMEZONE=Europe/Berlin
`}</code></pre>
    <p>{`Let's create your live API access key. First, go to the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/user/index"
      }}>{`MessageBird Dashboard`}</a>{`; if you have already created an API key it will be shown right there. If you don’t see any key on the Dashboard or if you're unsure whether this key is in `}<em parentName="p">{`live`}</em>{` mode, go to the `}<em parentName="p">{`Developers`}</em>{` section in the MessageBird Dashboard and open the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`API access (REST) tab`}</a>{`. There you can create new API keys and manage your existing ones.`}</p>
    <p>{`If you are having any issues creating your API key, please reach out to `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we’ll make sure to help you out.`}</p>
    <h2>{`Collecting user input`}</h2>
    <p>{`To send SMS messages to users, you need to collect their phone number as part of the booking process. We’ve created a sample form that asks the user for their name, desired treatment, number, date and time. For HTML forms it's recommended to use `}<inlineCode parentName="p">{`type="tel"`}</inlineCode>{` for the phone number input. You can see the template for the complete form in the file `}<inlineCode parentName="p">{`views/home.html.twig`}</inlineCode>{` and the route that drives it is defined as `}<inlineCode parentName="p">{`$app->get('/')`}</inlineCode>{` in `}<inlineCode parentName="p">{`index.php`}</inlineCode>{`.`}</p>
    <h2>{`Storing appointments and scheduling reminders`}</h2>
    <p>{`The user's input is sent to the route `}<inlineCode parentName="p">{`$app->post('/book')`}</inlineCode>{` defined in `}<inlineCode parentName="p">{`index.php`}</inlineCode>{`. The implementation covers the following steps:`}</p>
    <h3>{`Step 1: Check their input`}</h3>
    <p>{`Validate that the user has entered a value for every field in the form. For easier access to input parameters, we assign the parsed request body to an array variable called `}<inlineCode parentName="p">{`$input`}</inlineCode>{`.`}</p>
    <h3>{`Step 2: Check the appointment date and time`}</h3>
    <p>{`Confirm that the date and time are valid and at least three hours and five minutes in the future—BeautyBird won't take bookings on shorter notice. Also, since we want to schedule reminders three hours before the treatment, anything else doesn't make sense from a testing perspective. We use PHP's `}<inlineCode parentName="p">{`DateTime`}</inlineCode>{` object for this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`   // Check if date/time is correct and at least 3:05 hours in the future
   $earliestPossibleDT = new DateTime('+ 3 hour 5 minute');
   $appointmentDT = new DateTime($input['date'].' '.$input['time']);
   if ($appointmentDT < $earliestPossibleDT) {
       // If not, show an error
       // ...
`}</code></pre>
    <h3>{`Step 3: Check their phone number`}</h3>
    <p>{`Check whether the phone number is correct. This can be done with the `}<a parentName="p" {...{
        "href": "/api/lookup#lookup-request"
      }}>{`MessageBird Lookup API`}</a>{`, which takes a phone number entered by a user, validates the format and returns information about the number, such as whether it is a mobile or fixed line number. This API doesn't enforce a specific format for the number but rather understands a variety of different variants for writing a phone number, for example using different separator characters between digits, giving your users the flexibility to enter their number in various ways. On the SDK object, which we can get from the container through `}<inlineCode parentName="p">{`$this->messagebird`}</inlineCode>{`, we can call `}<inlineCode parentName="p">{`lookup->read()`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`   // Check if phone number is valid
   try {
       $lookupResponse = $this->messagebird->lookup->read(
           $input['number'], getenv('COUNTRY_CODE'));
       // ...
`}</code></pre>
    <p>{`The function takes two arguments, the phone number and a default country code. Providing the latter enables users to supply their number in a local format, without the country code. We're reading it from the environment variable we defined earlier.`}</p>
    <p>{`There are four different cases we need to handle as the result of the Lookup API. First, there's two cases that are handled through exceptions:`}</p>
    <ul>
      <li parentName="ul">{`MessageBird was unable to parse the phone number because the user has entered an invalid value. For this case we're using a `}<inlineCode parentName="li">{`catch`}</inlineCode>{`-block to intercept a `}<inlineCode parentName="li">{`MessageBird\\Exceptions\\RequestException`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`Another error occurred in the API. For this case there's a second `}<inlineCode parentName="li">{`catch`}</inlineCode>{`-block to intercept any generic `}<inlineCode parentName="li">{`Exception`}</inlineCode>{`.`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`   } catch (MessageBird\\Exceptions\\RequestException $e) {
       // A RequestException typically indicates that the phone number has an unknown format
       return $this->view->render($response, 'home.html.twig',
           array_merge($input, [ 'error' => "You need to enter a valid phone number!" ]));
   } catch (Exception $e) {
       // Some other error occurred
       return $this->view->render($response, 'home.html.twig',
           array_merge($input, [ 'error' => "Something went wrong while checking your phone number!" ]));
   }
`}</code></pre>
    <p>{`If no exception was caught, our application calls `}<inlineCode parentName="p">{`$lookupResponse->getType()`}</inlineCode>{` and checks the value:`}</p>
    <ul>
      <li parentName="ul">{`If it is `}<inlineCode parentName="li">{`mobile`}</inlineCode>{`, we can continue.`}</li>
      <li parentName="ul">{`If it is any other value, return an error.`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`   // Check type
   if ($lookupResponse->getType() != 'mobile') {
       // The number lookup was successful but it is not a mobile number
       return $this->view->render($response, 'home.html.twig',
           array_merge($input, [ 'error' => "You have entered a valid phone number, but it's not a mobile number! Provide a mobile number so we can contact you via SMS." ]));
   }
`}</code></pre>
    <h3>{`Step 4: Schedule the reminder`}</h3>
    <p>{`We determine the date and time for sending a reminder:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`   // Schedule reminder 3 hours prior to the treatment
   $reminderDT = (clone $appointmentDT)->modify('- 3 hour');
`}</code></pre>
    <p>{`Then, we need to prepare a `}<inlineCode parentName="p">{`MessageBird\\Objects\\Message`}</inlineCode>{` object that we can send through the API:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`   // Create message object
   $message = new MessageBird\\Objects\\Message;
   $message->originator = 'BeautyBird';
   $message->recipients = [ $lookupResponse->getPhoneNumber() ]; // normalized phone number from lookup request
   $message->scheduledDatetime = $reminderDT->format('c');
   $message->body = $input['name'] . ", here's a reminder that you have a " . $input['treatment'] . " scheduled for " . $appointmentDT->format('H:i') . ". See you soon!";
`}</code></pre>
    <p>{`Let's break down the attributes of this object:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`originator`}</inlineCode>{`: The sender ID. You can use a mobile number here, or an alphanumeric ID, like in the example.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`recipients`}</inlineCode>{`: An array of phone numbers. We just need one number, and we're using the normalized number returned from the Lookup API instead of the user-provided input.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`scheduledDatetime`}</inlineCode>{`: This instructs MessageBird not to send the message immediately but at a given timestamp, which we've defined previously. Using `}<inlineCode parentName="li">{`format('c')`}</inlineCode>{` method we make sure the API can read this timestamp correctly.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`body`}</inlineCode>{`: The friendly text for the message.`}</li>
    </ul>
    <p>{`Next, we can send this object with the `}<inlineCode parentName="p">{`messages->create()`}</inlineCode>{` method in the MessageBird SDK, contained in a try-catch-block to report errors:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`   // Send scheduled message with MessageBird API
   try {
       $this->messagebird->messages->create($message);
   } catch (Exception $e) {
       return $this->view->render($response, 'home.html.twig',
           array_merge($input, [ 'error' => "Error occured while sending message!" ]));
   }
`}</code></pre>
    <h3>{`Step 5: Store the appointment`}</h3>
    <p>{`In a real application, you would have to store the appointment in a database, but we've omitted this part for the sample.`}</p>
    <p>{`Finally, we show a confirmation page, which is defined in `}<inlineCode parentName="p">{`views/confirm.html.twig`}</inlineCode>{`.`}</p>
    <h2>{`Testing`}</h2>
    <p>{`You’re done! To test your application, you can use PHP's built-in web server. Enter the following command on the console to start it:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`php -S 0.0.0.0:8080 index.php
`}</code></pre>
    <p>{`Then, point your browser at `}<inlineCode parentName="p">{`http://localhost:8080/`}</inlineCode>{` to see the form and schedule your appointment! If you've used a live API key, a message will arrive to your phone three hours before the appointment! But don't actually leave the house, this is just a demo. 😜`}</p>
    <p>{`Awesome! You can now use the flow, code snippets and UI examples from this tutorial as an inspiration to build your own SMS reminder system. Don't forget to download the code from the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/reminders-guide-php"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`.`}</p>
    <p><strong parentName="p">{`Nice work!`}</strong>{` 🎉`}</p>
    <p>{`You now have a running SMS appointment reminder application with MessageBird using PHP!`}</p>
    <h2>{`Start building!`}</h2>
    <p>{`Want to start building your solution but not quite sure how to get started? Feel free to let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we'd love to help!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      