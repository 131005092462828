import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Building a WhatsApp to-do list bot with Programmable Conversations`}</h1>
    <p>{`MessageBird recently launched `}<a parentName="p" {...{
        "href": "/api/conversations"
      }}>{`Programmable Conversations`}</a>{`. It lets companies blend communications platforms like WhatsApp, Messenger and SMS into their systems — using a single API.`}</p>
    <p>{`I wanted to give it a whirl, so I built a WhatsApp bot to-do list, because who doesn’t need an automated to-do list to help organize their day? It may sound complicated, but it was actually easy, and I’d like to tell you all about it.`}</p>
    <p>{`Now, I work at MessageBird, so I could just dive in and start building. If you try this, you’ll need to request early access. But once you’re set up with a WhatsApp channel, you can log on to the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/user/index"
      }}>{`Dashboard on the MessageBird website`}</a>{` and get started.`}</p>
    <h2>{`Let's start building`}</h2>
    <p>{`The first thing I did was read the `}<a parentName="p" {...{
        "href": "/api/conversations"
      }}>{`docs`}</a>{`. I learned that, in order to get messages from the bot, I would have to use a webhook. This meant that my bot would need to be accessible from the internet. Since I was just starting to code it, I decided to use `}<a parentName="p" {...{
        "href": "https://ngrok.com/"
      }}>{`ngrok`}</a>{`. It creates a tunnel from the public internet to your dear localhost port 5007. Engage!`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`ngrok http 5007 -region eu -subdomain todobot
`}</code></pre>
    <p>{`Next, I needed to do a call to the `}<a parentName="p" {...{
        "href": "/api/conversations"
      }}>{`Conversations API`}</a>{` to create the webhook. It’s a `}<inlineCode parentName="p">{`POST`}</inlineCode>{` to `}<inlineCode parentName="p">{`https://conversations.messagebird.com/v1/webhooks`}</inlineCode>{` and it looks something like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`func main() {
// define the webhook json payload 
        wh := struct {
                Events    []string \`json:"events"\`
                ChannelID string   \`json:"channelId"\`
                URL       string   \`json:"url"\`
        } {
// we would like to be notified on the URL 
                URL:       "https://todobot.eu.ngrok.io/create-hook",
                // whenever a message gets created 
                Events:    []string{"message.created"},
                // on the WhatsApp channel with ID 
                ChannelID: "23a780701b8849f7b974d8620a89a279",
        }
// encode the payload to json
        var b bytes.Buffer
        err := json.NewEncoder(&b).Encode(&wh)
        if err != nil {
                panic(err)
        }
// create the http request and set authorization header
        req, err := http.NewRequest("POST", "https://conversations.messagebird.com/v1/webhooks", &b)
        req.Header.Set("Authorization", "AccessKey todo-your-access-key")
        req.Header.Set("Content-Type", "application/json")
// fire the http request
        client := &http.Client{}
        resp, err := client.Do(req)
        if err != nil {
                panic(err)
        }
        defer resp.Body.Close()
// is everything ok? 
        body, _ := ioutil.ReadAll(resp.Body)
        if resp.StatusCode >= http.StatusBadRequest {
                panic(fmt.Errorf("Bad response code from api when trying to create webhook: %s. Body: %s", resp.Status, string(body)))
        } else {
                log.Println("All good. response body: ", string(body))
        }
}
`}</code></pre>
    <p>{`Sweet. Now the `}<a parentName="p" {...{
        "href": "/api/conversations"
      }}>{`Conversations API`}</a>{` is going to do a `}<inlineCode parentName="p">{`POST`}</inlineCode>{` request to `}<inlineCode parentName="p">{`https://todobot.eu.ngrok.io/create-hook`}</inlineCode>{` whenever a new message gets created on the WhatsApp channel you set up earlier.`}</p>
    <p>{`This is what a webhook payload looks like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
     "conversation":{
        "id":"55c66895c22a40e39a8e6bd321ec192e",
        "contactId":"db4dd5087fb343738e968a323f640576",
        "status":"active",
        "createdDatetime":"2018-08-17T10:14:14Z",
        "updatedDatetime":"2018-08-17T14:30:31.915292912Z",
        "lastReceivedDatetime":"2018-08-17T14:30:31.898389294Z"
     },
     "message":{
        "id":"ddb150149e2c4036a48f581544e22cfe",
        "conversationId":"55c66895c22a40e39a8e6bd321ec192e",
        "channelId":"23a780701b8849f7b974d8620a89a279",
        "status":"received",
        "type":"text",
        "direction":"received",
        "content":{
           "text":"add buy milk"
        },
        "createdDatetime":"2018-08-17T14:30:31.898389294Z",
        "updatedDatetime":"2018-08-17T14:30:31.915292912Z"
     },
     "type":"message.created"
  }
`}</code></pre>
    <p>{`We want to answer those messages. Let’s start by echoing them, what do you say?`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`// define the structs where we'll parse the webhook payload in
type whPayload struct {
        Conversation conversation \`json:"conversation"\`
        Message      message      \`json:"message"\`
        Type         string       \`json:"type"\`
}
type message struct {
        ID        string  \`json:"id"\`
        Direction string  \`json:"direction"\`
        Type      string  \`json:"type"\`
        Content   content \`json:"content"\`
}
type content struct {
        Text string \`json:"text"\`
}
type conversation struct {
        ID string \`json:"id"\`
}
func main() {
       http.HandleFunc("/create-hook", createHookHandler) 
       log.Fatal(http.ListenAndServe(*httpListenAddress, nil))
}
// createHookHandler is an http handler that will handle webhook requests
func createHookHandler(w http.ResponseWriter, r *http.Request) {
        // parse the incoming json payload
        whp := &whPayload{}
        err := json.NewDecoder(r.Body).Decode(whp)
        if err != nil {
                log.Println("Err: got weird body on the webhook")
                w.WriteHeader(http.StatusInternalServerError)
                fmt.Fprintf(w, "Internal Server Error")
                return
        }
if whp.Message.Direction != "received" {
                // you will get *all* messages on the webhook. Even the ones this bot sends to the channel. We don't want to answer those. 
                fmt.Fprintf(w, "ok")
                return
        }
// echo: respond what we get
        err = respond(whp.Conversation.ID, whp.Message.Content.Text)
        
        if err != nil {
                log.Println("Err: ", err)
                w.WriteHeader(http.StatusInternalServerError)
                fmt.Fprintf(w, "Internal Server Error")
return
        }
w.WriteHeader(http.StatusOK)
        fmt.Fprintf(w, "ok")
}
`}</code></pre>
    <p>{`Now, for the interesting part. Do a `}<inlineCode parentName="p">{`POST`}</inlineCode>{` request to `}<inlineCode parentName="p">{`https://conversations.messagebird.com/v1/conversations/<conversationID>/messages`}</inlineCode>{` to answer the request.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`func respond(conversationID, responseBody string) error {
      
        u := fmt.Sprintf("https://conversations.messagebird.com/v1/conversations/%s/messages", conversationID)
msg := message{
                Content: content{
                        Text: responseBody,
                },
                Type: "text",
        }
var b bytes.Buffer
        err := json.NewEncoder(&b).Encode(&msg)
        if err != nil {
                return fmt.Errorf("Error encoding buffer: %v", err)
        }
req, err := http.NewRequest("POST", u.String(), &b)
        req.Header.Set("Authorization", "AccessKey todo-your-access-key")
        req.Header.Set("Content-Type", "application/json")
client := &http.Client{}
        resp, err := client.Do(req)
        if err != nil {
                return err
        }
        defer resp.Body.Close()
body, _ := ioutil.ReadAll(resp.Body)
        if resp.StatusCode != http.StatusCreated {
                return fmt.Errorf("Bad response code from api when trying to create message: %s. Body: %s", resp.Status, string(body))
        }
log.Println("All good. Response body: ", string(body))
        return nil
}
`}</code></pre>
    <p>{`There. This is all you need to create a bot that acts like 5-year-old human.`}</p>
    <p>{`Now, let’s make a push towards building the whole to-do list. First, modify the `}<inlineCode parentName="p">{`createHookHandler`}</inlineCode>{` function a bit so it calls the new handleMessage function instead of `}<inlineCode parentName="p">{`respond`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`func createHookHandler(w http.ResponseWriter, r *http.Request) {
        ... 
        err = handleMessage(whp)
        ...
}
`}</code></pre>
    <p><inlineCode parentName="p">{`handle`}</inlineCode>{` will simplistically parse the messages, do some work, and pick the response. Let’s look at the “add” command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`func handleMessage(whp *whPayload) error {
        // every conversation has a todo list
        list := manager.fetch(whp.Conversation.ID)
        // parse the command from the message body: it's the first word
        text := whp.Message.Content.Text
        text = regexp.MustCompile(" +").ReplaceAllString(text, " ")
        parts := strings.Split(text, " ")
        command := strings.ToLower(parts[0])
        // default message
        responseBody := "I don't understand. Type 'help' to get help."
        switch command {
...
        case "add":
                if len(parts) < 2 {
                        return respond(whp.Conversation.ID, "err... the 'add' command needs a second param: the todo item you want to save. Something like 'add buy milk'.")
                }
                // get the item from the message body
                item := strings.Join(parts[1:], " ")
list.add(item)
                responseBody = "added."
...
        return respond(whp.Conversation.ID, responseBody)
}
`}</code></pre>
    <p>{`Here, we set up: `}<inlineCode parentName="p">{`list := manager.fetch(whp.Conversation.ID)`}</inlineCode>{`. Basically, “manager” is a concurrency safe map that maps conversation IDs to to-do lists.`}</p>
    <p>{`A to-do list is a concurrency safe string slice. All in memory!`}</p>
    <p>{`Another important thing! You can archive conversations. In some applications, like CRMs, it’s important to keep track of certain interactions — to track the effectiveness of customer support employees, for example. The `}<a parentName="p" {...{
        "href": "/api/conversations"
      }}>{`Conversations API`}</a>{` lets you archive a conversation to “close” the topic. If the user/customer sends another message, the `}<a parentName="p" {...{
        "href": "/api/conversations"
      }}>{`Conversations API`}</a>{` will open a new topic automatically.`}</p>
    <p>{`Also. Doing `}<inlineCode parentName="p">{`PATCH`}</inlineCode>{` request to `}<a parentName="p" {...{
        "href": "https://conversations.messagebird.com/v1/conversations/%7Bid%7D"
      }}>{`https://conversations.messagebird.com/v1/conversations/{id}`}</a>{` with the right status on the body allows you to archive the conversation with that id. We do this with the “bye” command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`case "bye":
                archiveConversation(whp.Conversation.ID)
                manager.close(whp.Conversation.ID)
                responseBody = "bye!"
`}</code></pre>
    <p><inlineCode parentName="p">{`archiveConversation`}</inlineCode>{` will do the PATCH request and manager.close(whp.Conversation.ID) will remove the to-do list conversation.`}</p>
    <h2>{`Try out another platform`}</h2>
    <p>{`But hey, Programmable Conversations is an omni-channel solution. What if you wanted to reuse the code of the bot for a different platform, like WeChat? How would you go about it?`}</p>
    <p>{`Just create a new webhook to target that channel! A webhook that sends requests to the same `}<inlineCode parentName="p">{`https://todobot.eu.ngrok.io/create-hook`}</inlineCode>{` url we used for WhatsApp!`}</p>
    <p>{`This will work because the handler code always uses the conversationID from the webhook payload to answer the messages instead of a hardcoded channelID. MessageBird’s `}<a parentName="p" {...{
        "href": "/api/conversations"
      }}>{`Conversations API`}</a>{` will automatically determine the channel for the conversation to send your message over.`}</p>
    <h2>{`Want to build your own bot?`}</h2>
    <p>{`Take a look at the full code on Github: `}<a parentName="p" {...{
        "href": "https://github.com/marcelcorso/wabot"
      }}>{`https://github.com/marcelcorso/wabot`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.messagebird.com/en/whatsapp/"
      }}>{`request early access to WhatsApp via this link`}</a>{` and start building. `}</p>
    <h2>{`Happy botting!`}</h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      