import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Setting SMS reminders with MessageBird`}</h1>
    <h3>{`⏱ 15 min build time      ||      `}<a parentName="h3" {...{
        "href": "https://github.com/messagebirdguides/reminders-guide-java"
      }}>{`Download the Code`}</a></h3>
    <h2>{`Why build SMS appointment reminders?`}</h2>
    <p>{`In this MessageBird Developer Tutorial, you’ll learn how to build an SMS appointment reminder application and improve your users’ experience with the `}<a parentName="p" {...{
        "href": "/api/sms-messaging"
      }}>{`MessageBird SMS Messaging API`}</a>{`.`}</p>
    <p>{`Booking appointments online from a website or mobile app is quick and easy. Customers just have to select their desired date and time, personal details and hit a button. The problem, however, is that easy-to-book appointments are often just as easy to forget.`}</p>
    <p>{`For appointment-based services, no-shows are annoying and costly because of the time and revenue lost waiting for a customer instead of serving them or another customer. Timely SMS reminders simple and discrete nudges, which can go a long way in the prevention of costly no-shows.`}</p>
    <h2>{`Getting started`}</h2>
    <p>{`This sample application is built in Java and represents the order website for our fictitious online beauty salon, BeautyBird. To reduce the growing number of no-shows, BeautyBird now collects appointment bookings through a form on their website and schedules timely SMS reminders to be sent out three hours before the selected date and time.`}</p>
    <p>{`To look at the full sample application or run it on your computer, go to the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/reminders-guide-java"
      }}>{`MessageBird Developer Guides GitHub repository`}</a>{` and clone it or download the source code as a ZIP archive.`}</p>
    <p>{`We'll be building our single-page web application with:`}</p>
    <p><em parentName="p"><a parentName="em" {...{
          "href": "https://www.oracle.com/technetwork/java/javase/downloads/jdk8-downloads-2133151.html"
        }}>{`Java 1.8`}</a>
      </em><a parentName="p" {...{
        "href": "https://maven.apache.org/"
      }}>{`Maven`}</a></p>
    <p>{`The `}<inlineCode parentName="p">{`pom.xml`}</inlineCode>{` file has all the dependencies the project needs. Also, keep in mind that your IDE should be configured to automatically install them.`}</p>
    <h2>{`Configuring the MessageBird SDK`}</h2>
    <p>{`The SDK is loaded with the following lines at the beginning of the application:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.messagebird.MessageBirdClient;
import com.messagebird.MessageBirdService;
import com.messagebird.MessageBirdServiceImpl;

import io.github.cdimascio.dotenv.Dotenv;

Dotenv dotenv = Dotenv.load();

// Create a MessageBirdService
final MessageBirdService messageBirdService = new MessageBirdServiceImpl(dotenv.get("MESSAGEBIRD_API_KEY"));

// Add the service to the client
final MessageBirdClient messageBirdClient = new MessageBirdClient(messageBirdService);
`}</code></pre>
    <p>{`The MessageBird API key needs to be provided as a parameter.`}</p>
    <p><strong parentName="p">{`Pro-tip:`}</strong>{` Hardcoding your credentials in the code is a risky practice that should never be used in production applications. A better method, also recommended by the `}<a parentName="p" {...{
        "href": "https://12factor.net/"
      }}>{`Twelve-Factor App Definition`}</a>{`, is to use environment variables.`}</p>
    <p>{`We've added `}<a parentName="p" {...{
        "href": "https://mvnrepository.com/artifact/io.github.cdimascio/java-dotenv"
      }}>{`dotenv`}</a>{` to the sample application, so you can supply your API key in a file named `}<inlineCode parentName="p">{`.env`}</inlineCode>{`. Copy the provided file `}<inlineCode parentName="p">{`env.example`}</inlineCode>{` to `}<inlineCode parentName="p">{`.env`}</inlineCode>{` and add your API key like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`MESSAGEBIRD_API_KEY=YOUR-API-KEY
`}</code></pre>
    <p>{`API keys can be created or retrieved from the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`API access (REST) tab`}</a>{` in the `}<em parentName="p">{`Developers section`}</em>{` of the MessageBird Dashboard. There you can create new API keys and manage your existing ones.`}</p>
    <p>{`If you are having any issues creating your API key, please reach out to `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we’ll make sure to help you out.`}</p>
    <h2>{`Collecting user input`}</h2>
    <p>{`To send SMS messages to users, you need to collect their phone number as part of the booking process. We’ve created a sample form that asks the user for their name, desired treatment, number, date and time. For HTML forms it's recommended to use `}<inlineCode parentName="p">{`type="tel"`}</inlineCode>{` for the phone number input. You can see the template for the complete form in the file `}<inlineCode parentName="p">{`views/home.mustache`}</inlineCode>{` and the route that drives it’s defined as `}<inlineCode parentName="p">{`get("/")`}</inlineCode>{` in the application.`}</p>
    <h2>{`Storing appointments and scheduling reminders`}</h2>
    <p>{`The user's input is sent to the route `}<inlineCode parentName="p">{`post("/book")`}</inlineCode>{` defined in the application. The implementation covers the following steps:`}</p>
    <h3>{`Step 1: Check their input`}</h3>
    <p>{`Validate that the user has entered a value for every field in the form.`}</p>
    <h3>{`Step 2: Check the appointment date and time`}</h3>
    <p>{`Confirm that the date and time are valid and at least three hours and five minutes in the future—BeautyBird won't take bookings on shorter notice. Also, since we want to schedule reminders three hours before the treatment, anything else doesn't make sense from a testing perspective.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`// Check if date/time is correct and at least 3:05 hours in the future
LocalDateTime earliestPossibleDt =  LocalDateTime.now().plusHours(3).plusMinutes(5);
LocalDateTime appointmentDt =  LocalDateTime.parse(String.format("%sT%s", date, time));
`}</code></pre>
    <h2>{`Step 3: Check their phone number`}</h2>
    <p>{`Check whether the phone number is correct. This can be done with the `}<a parentName="p" {...{
        "href": "/api/lookup#lookup-request"
      }}>{`MessageBird Lookup API`}</a>{`, which takes a phone number entered by a user, validates the format and returns information about the number, such as whether it is a mobile or fixed line number. This API doesn't enforce a specific format for the number but rather understands a variety of different variants for writing a phone number, for example using different separator characters between digits, giving your users the flexibility to enter their number in various ways. In the SDK, you can call `}<inlineCode parentName="p">{`client.viewLookup`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`// convert String number into acceptable format
BigInteger phoneNumber = new BigInteger(number);
final Lookup lookupRequest = new Lookup(phoneNumber);
lookupRequest.setCountryCode(dotenv.get("COUNTRY_CODE"));
final Lookup lookup = messageBirdClient.viewLookup(lookupRequest);
`}</code></pre>
    <p>{`The function takes a single argument of type `}<inlineCode parentName="p">{`Lookup`}</inlineCode>{`. When constructing `}<inlineCode parentName="p">{`Lookup`}</inlineCode>{`, you must provide the phone number as a `}<inlineCode parentName="p">{`BigInteger`}</inlineCode>{`, You can optionally provide a country code by setting it via `}<inlineCode parentName="p">{`setCountryCode`}</inlineCode>{`.`}</p>
    <p>{`To assign a country code, add the following line to you `}<inlineCode parentName="p">{`.env`}</inlineCode>{` file, replacing NL with your own ISO country code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`COUNTRY_CODE=NL
`}</code></pre>
    <p>{`In the `}<inlineCode parentName="p">{`lookup`}</inlineCode>{` response, we handle several different cases:`}</p>
    <ul>
      <li parentName="ul">{`An error (code 21) occurred, which means MessageBird was unable to parse the phone number.`}</li>
      <li parentName="ul">{`Another error code occurred, which means something else went wrong in the API.`}</li>
      <li parentName="ul">{`No error occurred, but the value of the response type attribute is something other than mobile.`}</li>
      <li parentName="ul">{`Everything is ok, which means a mobile number was provided successfully.`}</li>
    </ul>
    <p>{`We can capture and report all of these issues with a single `}<inlineCode parentName="p">{`try...catch`}</inlineCode>{` block, and report the errors back as a string:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`try {
       final Lookup lookup = messageBirdClient.viewLookup(lookupRequest);
   } catch (UnauthorizedException | GeneralException | NotFoundException ex) {
       model.put("errors", ex.toString());
       return new ModelAndView(model, "home.mustache");
   }
`}</code></pre>
    <h2>{`Step 4: Schedule the reminder`}</h2>
    <p>{`Using `}<inlineCode parentName="p">{`LocalDateTime`}</inlineCode>{`, we can easily specify the time for our reminder:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`// Schedule reminder 3 hours prior to the treatment
LocalDateTime reminderDt = appointmentDt.minusHours(3);
`}</code></pre>
    <p>{`Then it's time to call MessageBird's API:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`String body = String.format("%s, here's a reminder that you have a %s scheduled for %s. See you soon!", name, treatment, DateTimeFormatter.ofPattern("HH:mm").format(appointmentDt));

final List<BigInteger> phones = new ArrayList<BigInteger>();
phones.add(phoneNumber);
final MessageResponse response = messageBirdClient.sendMessage("BeautyBird", body, phones);

`}</code></pre>
    <p>{`Let's break down the parameters that are set with this call of `}<inlineCode parentName="p">{`client.sendMessage`}</inlineCode>{`:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`originator`}</inlineCode>{`: This is the first parameter. It represents the sender ID. You can use a mobile number here, or an alphanumeric ID, like in the example. Keep in mind that alphanumeric senders are not supported in every country including the United States, so it’s important to check the `}<a parentName="li" {...{
          "href": "https://support.messagebird.com/hc/en-us/sections/360000108538-Country-info-Restrictions"
        }}>{`country restrictions`}</a>{`. If you can't use alphanumeric IDs, use a real phone number instead. You can check our `}<a parentName="li" {...{
          "href": "https://support.messagebird.com/hc/en-us/articles/115002628665-What-is-the-originator-"
        }}>{`originator article`}</a>{` in Help Center to learn more about this topic.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`body`}</inlineCode>{`: This is the second parameter. It's the friendly text for the message.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`recipients`}</inlineCode>{`: This is the third parameter. It's an array of phone numbers. We just need one number, and we're using the normalized number returned from the Lookup API instead of the user-provided input.`}</li>
    </ul>
    <h2>{`Step 5: Store the appointment`}</h2>
    <p>{`The application's logic continues with the `}<inlineCode parentName="p">{`response`}</inlineCode>{`, where we need to handle both success and error cases:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`final MessageResponse response = messageBirdClient.sendMessage("BeautyBird", body, phones);

Map<String, Object> appointment = new HashMap<>();
appointment.put("name", name);
appointment.put("treatment", treatment);
appointment.put("number", number);
appointment.put("appointmentDt", appointmentDt);
appointment.put("reminderDt", reminderDt);

appointmentDb.add(appointment);

// Render confirmation page
return new ModelAndView(appointment, "confirm.mustache");
`}</code></pre>
    <p>{`As you can see, for the purpose of the sample application, we simply "persist" the appointment to a global variable in memory (`}<inlineCode parentName="p">{`appointmentDb`}</inlineCode>{`). This is where, in production applications, you would write the appointment to a persistence layer such as a file or database. We also show a confirmation page, which is defined in `}<inlineCode parentName="p">{`confirm.mustache`}</inlineCode>{`.`}</p>
    <h2>{`Testing`}</h2>
    <p>{`You’re done! To test your application, build and run the application through your IDE.`}</p>
    <p>{`Then, point your browser at `}<inlineCode parentName="p">{`localhost:4567`}</inlineCode>{` to see the form and schedule your appointment! If you've used a live API key, a message will arrive to your phone three hours before the appointment! But don't actually leave the house, this is just a demo. 😜`}</p>
    <p>{`Awesome! You can now use the flow, code snippets and UI examples from this tutorial as an inspiration to build your own SMS reminder system. Don't forget to download the code from the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/reminders-guide-ruby"
      }}>{`MessageBird Developer Guides GitHub repository`}</a>{`.`}</p>
    <p><strong parentName="p">{`Nice work!`}</strong>{` 🎉`}</p>
    <p>{`You now have a running SMS appointment reminder application with MessageBird using Java!`}</p>
    <h2>{`Start building!`}</h2>
    <p>{`Want to start building your solution but not quite sure how to get started? Feel free to let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we'd love to help!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      