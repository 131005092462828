import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Default from '../Layout/Default';
import PageHeadersContext from '../../contexts/PageHeadersContext';
import MobileNavigation from '../MobileNavigation';
import { getDuplicatesId, getAllIds } from '../../helpers/HeadersFinder';
import styled from 'styled-components';

const ApiRef = ({ children }) => {
  const { setDuplicateIds, setHeadersIds } = useContext(PageHeadersContext);
  const allAnchorsIds = getAllIds(children);
  const duplicatesAnchors = getDuplicatesId(allAnchorsIds);
  useEffect(() => {
    setDuplicateIds(duplicatesAnchors);
    setHeadersIds(allAnchorsIds);
  }, []);

  return (
    <Styling>
      <MobileNavigation api />
      <Default api sidebar>
        {children}
      </Default>
    </Styling>
  );
};

const Styling = styled.div`
  .sms-error-table + table {
    td {
      vertical-align: top;
    }
    th:nth-child(1),
    td:nth-child(1) {
      width: 8%;
    }
    th:nth-child(2),
    td:nth-child(2) {
      width: 42%;
    }
    th:nth-child(3),
    td:nth-child(3) {
      width: 50%;
    }
  }
`;

ApiRef.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ApiRef;
