import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/ApiRef.js";
import Helmet from 'react-helmet';
import CodeSnippet from 'components/CodeSnippet';
import { APIWrapper, LeftColumn, RightColumn } from 'components/ApiRef';
import * as CODE from 'constants/webrtc';
import Notification from 'components/Notification';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Helmet mdxType="Helmet">
  <meta name="robots" content="noindex" />
    </Helmet>
    <h1>{`Client SDK `}<inlineCode parentName="h1">{`Deprecated`}</inlineCode></h1>
    <hr></hr>
    <LeftColumn mdxType="LeftColumn">
      <h2>{`Table of Contents`}</h2>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/api/client-sdk/#introduction"
          }}>{`Introduction`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/api/client-sdk/#installation"
          }}>{`Installation`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/api/client-sdk/#authentication"
          }}>{`Authentication`}</a>
          <ul parentName="li">
            <li parentName="ul"><a parentName="li" {...{
                "href": "/api/client-sdk/#obtain-an-access-key"
              }}>{`Obtain an Access Key`}</a></li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "/api/client-sdk/#assign-capabilities-to-clients-&-generate-jwt"
              }}>{`Assign Capabilities to Clients & Generate JWT`}</a>
              <ul parentName="li">
                <li parentName="ul"><a parentName="li" {...{
                    "href": "/api/client-sdk/#capabilities"
                  }}>{`Capabilities`}</a>
                  <ul parentName="li">
                    <li parentName="ul"><a parentName="li" {...{
                        "href": "/api/client-sdk/#client.outgoing"
                      }}>{`client.outgoing`}</a></li>
                    <li parentName="ul"><a parentName="li" {...{
                        "href": "/api/client-sdk/#client.incoming"
                      }}>{`client.incoming`}</a></li>
                  </ul>
                </li>
                <li parentName="ul"><a parentName="li" {...{
                    "href": "/api/client-sdk/#generating-the-json-web-token"
                  }}>{`Generating the JSON Web Token`}</a></li>
              </ul>
            </li>
          </ul>
        </li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/api/client-sdk/#initializing-a-new-messagebirdclient"
          }}>{`Initializing a New MessageBirdClient`}</a>
          <ul parentName="li">
            <li parentName="ul"><a parentName="li" {...{
                "href": "/api/client-sdk/#handling-client-status-updates"
              }}>{`Handling client status updates`}</a></li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "/api/client-sdk/#handling-client-errors"
              }}>{`Handling client errors`}</a></li>
          </ul>
        </li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/api/client-sdk/#starting-a-new-call"
          }}>{`Starting a new Call`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/api/client-sdk/#handling-an-incoming-call"
          }}>{`Handling an incoming Call`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/api/client-sdk/#managing-active-calls"
          }}>{`Managing active calls`}</a>
          <ul parentName="li">
            <li parentName="ul"><a parentName="li" {...{
                "href": "/api/client-sdk/#handling-call-status-updates"
              }}>{`Handling status updates`}</a></li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "/api/client-sdk/#handling-call-errors"
              }}>{`Error handling`}</a></li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "/api/client-sdk/#audio-management"
              }}>{`Audio management`}</a></li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "/api/client-sdk/#sending-digits"
              }}>{`Sending digits`}</a></li>
          </ul>
        </li>
      </ul>
    </LeftColumn>
    <hr></hr>
    <LeftColumn mdxType="LeftColumn">
      <h2>{`Introduction`}</h2>
      <p>{`Client SDK is a browser based SDK written in JavaScript and powered by `}<a parentName="p" {...{
          "href": "https://webrtc.org"
        }}>{`WebRTC`}</a>{` Technology. Using the Client SDK, Developers can integrate voice calling capabilities into their core applications.
Please note that in order to control the behavior of incoming/outgoing calls, Voice API’s call flows can be used to provide the expected call logic. `}<a parentName="p" {...{
          "href": "https://developers.messagebird.com/api/voice-calling/"
        }}>{`Please check our Voice Calling API for more information`}</a></p>
    </LeftColumn>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Installation`}</h2>
        <p>{`The client SDK is distributed on npm. To install the SDK and start using it in your app, install the package `}<inlineCode parentName="p">{`@messagebird/client`}</inlineCode></p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
    <CodeSnippet code={CODE.INSTALL} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Authentication`}</h2>
        <p>{`In order to authenticate your Clients to use our platform, There are 2 steps that are needed:`}</p>
        <ol>
          <li parentName="ol">{`Obtain Access Key from Messagebird.`}</li>
          <li parentName="ol">{`Assign capabilities to your Clients & generate JSON Web token (JWT)`}</li>
        </ol>
        <p>{`The Following section explains the details of each of the previous steps:`}</p>
        <h3>{`Obtain an Access Key`}</h3>
        <p>{`Once you create a Messagebird account, you will be able to create, manage and retreive your Access keys from the `}<a parentName="p" {...{
            "href": "https://dashboard.messagebird.com/en/developers/settings"
          }}>{`Messagebird Developers Dashboard`}</a>{`.`}</p>
        <h3>{`Assign Capabilities to Clients & Generate JWT`}</h3>
        <h3>{`Capabilities`}</h3>
        <p>{`Clients can be given capabilities to perform specific actions. Capabilities are represented by a capability and their attributes. The attributes are represented as URL parameters.
Example structure of a capability: `}<inlineCode parentName="p">{`capability?attribute1=foo&attribute2=bar`}</inlineCode>{`.`}</p>
        <p>{`In order to provide control on what your Client can do, currently our Client SDK supports 2 different capabilities:`}</p>
        <ul>
          <li parentName="ul"><inlineCode parentName="li">{`client.outgoing`}</inlineCode>{` allows the Client to place an outgoing call`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`client.incoming`}</inlineCode>{` allows the Client to receive an incoming call`}</li>
        </ul>
        <p>{`You can choose to add either or both of them to your JWT.`}</p>
        <h4>{`client.outgoing`}</h4>
        <p>{`This capability gives the Client the capability to initiate an outgoing call to our platform, upon which a call flow will be executed to process the call and apply the desired call logic. At this moment, a call can only be processed by a Voice API Call flow. In the future, `}<a parentName="p" {...{
            "href": "https://dashboard.messagebird.com/en/flow-builder"
          }}>{`Flow Builder`}</a>{` will also be supported in order to execute the desired call logic.`}</p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`attribute`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`required`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`callProcessor`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The type of service that should process this call. At this moment we only support `}<inlineCode parentName="td">{`callflow`}</inlineCode>{`, which can be created through the `}<a parentName="td" {...{
                  "href": "/api/voice-calling#creating-a-call-flow"
                }}>{`Voice-API`}</a>{`.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Yes`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`callProcessorId`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The ID of the call flow object above. This ID can be retrieved through `}<a parentName="td" {...{
                  "href": "/api/voice-calling#creating-a-call-flow"
                }}>{`Voice-API`}</a>{` and will also be represented to you when creating the call flow.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Yes`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`variables`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`An urlencoded object of parameters that will be sent the incoming call processor (key\\<`}{`>`}{`value).`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`identity`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The alpha-numeric caller-id of this connection. This will show up in the calls/legs in the API and will show up as `}<inlineCode parentName="td">{`source`}</inlineCode>{` in fetchCallFlow steps and webhooks. When empty, anonymous will be used. Max-length: 30.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
          </tbody>
        </table>
        <p>{`An example of a `}<inlineCode parentName="p">{`client.outgoing`}</inlineCode>{` can be found on the right. This example would process the call with a call-flow with ID `}<inlineCode parentName="p">{`de3ed163-d5fc-45f4-b8c4-7eea7458c635`}</inlineCode>{`. The client would be identified as "Bert" and the variables "foo=bar&bar=foo" would be passed through with a call flow fetch. Note that the variables will show up in the call flow fetch also in the variables parameter.`}</p>
        <h4>{`client.incoming`}</h4>
        <p>{`This capability gives the client the capability to receive an incoming call from our platform via the Client SDK. Calls can be forwarded to the Client by using `}<inlineCode parentName="p">{`client:foobar`}</inlineCode>{` in the `}<inlineCode parentName="p">{`destination`}</inlineCode>{` parameter when doing a transfer-step or when creating an outgoing call through the Voice API.`}</p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`attribute`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`required`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`identity`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The alpha-numeric identity of this connection. This identity can be used to receive calls trough the `}<inlineCode parentName="td">{`destination`}</inlineCode>{` parameter like `}<inlineCode parentName="td">{`client:foobar`}</inlineCode>{`, if the identity would be `}<inlineCode parentName="td">{`foobar`}</inlineCode>{`. Max-length: 30.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Yes`}</td>
            </tr>
          </tbody>
        </table>
        <h4>{`Generating the JSON Web Token`}</h4>
        <p>{`The payload of a JWT should contain `}<inlineCode parentName="p">{`accessKeyID`}</inlineCode>{`, `}<inlineCode parentName="p">{`capabilities`}</inlineCode>{`, `}<inlineCode parentName="p">{`iat`}</inlineCode>{` and `}<inlineCode parentName="p">{`exp`}</inlineCode>{`.`}</p>
        <p>{`The token should be signed with the MessageBird `}<inlineCode parentName="p">{`accessKey`}</inlineCode>{`.
Please make sure that the `}<inlineCode parentName="p">{`accessKey`}</inlineCode>{` is not exposed on the client SDK side or in the JWT itself, only use it for signing the JWT.
Please refer to `}<a parentName="p" {...{
            "href": "https://jwt.io"
          }}>{`JSON Web Tokens`}</a>{` for help with generating JWT's in your current programming language.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
    <CodeSnippet code={CODE.AUTH} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Initializing a New MessageBirdClient`}</h2>
        <p>{`The next step after the authentication is done, is to instantiate a new `}<inlineCode parentName="p">{`MessageBirdClient`}</inlineCode>{`. You will use the `}<inlineCode parentName="p">{`MessageBirdClient`}</inlineCode>{` instance to set up a connection to our platform, and to initiate and receive calls.
After instantiating the client, call `}<inlineCode parentName="p">{`client.setup()`}</inlineCode>{` to start the connection to our platform and be able to make and receive calls. A valid JWT has to be passed to the `}<inlineCode parentName="p">{`.setup()`}</inlineCode>{` method for the authentication to succeed.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
    <CodeSnippet code={CODE.INIT} lang="node" mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h3>{`Handling client status updates`}</h3>
        <p>{`The client will emit a `}<inlineCode parentName="p">{`status`}</inlineCode>{` event when the connection status of the client changes.
Possible status values are:`}</p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`value`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`offline`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`the initial status of the client after it is initialized . Calling `}<inlineCode parentName="td">{`destroy()`}</inlineCode>{` resets the status to initial.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`connecting`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`the client is in the process of establishing a connection to our platform`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`connected`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`the client is connected to our platform and ready for initiating and receiving calls`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`error`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`An error occured. The `}<inlineCode parentName="td">{`error`}</inlineCode>{` event is also fired containing the error that occured`}</td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
    <CodeSnippet code={CODE.CLIENT_STATUS} lang="node" mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h3>{`Handling client errors`}</h3>
        <p>{`If the connection to our platform fails after calling `}<inlineCode parentName="p">{`setup()`}</inlineCode>{`, or if the client disconnects over time (for example when the browser loses internet connection), the client will emit an `}<inlineCode parentName="p">{`error`}</inlineCode>{` event.
Use this event to determine what your application should do next to ensure the client can reconnect to our platform.`}</p>
        <p>{`These are the possible errors:`}</p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Code`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Message`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`403`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Forbidden, Token not found or expired.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`JWT has expired or missing in the `}<inlineCode parentName="td">{`.setup()`}</inlineCode>{` method`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`406`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Invalid identity provided in `}<inlineCode parentName="td">{`client.incoming`}</inlineCode>{` capability, only alphanumeric and underscore characters are allowed`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`client.incoming`}</inlineCode>{` identity has to be alphanumeric characters only so that the Client can be identified by our platform and receive incoming calls.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`500`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Internal error`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`An unexpected internal error occurred. See the error object for more information`}</td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
    <CodeSnippet code={CODE.CLIENT_ERROR} lang="node" mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Starting a new Call`}</h2>
        <p>{`The `}<inlineCode parentName="p">{`client.startCall()`}</inlineCode>{` method can be called to start an outgoing call.
Any additional data can be passed as arguments to the '.startCall()' method.
This method will throw an error if you attempt to start a call while the client is not yet connected to our platform. `}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
    <CodeSnippet code={CODE.START_CALL} lang="node" mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Handling an Incoming Call`}</h2>
        <p>{`Listening to the `}<inlineCode parentName="p">{`client.on('incoming')`}</inlineCode>{` event will allow you to respond to incoming calls on your UI.
The callback function retrieves an instance of `}<inlineCode parentName="p">{`IncomingCall`}</inlineCode>{` as its first argument, which can be used to respond to the incoming call by either calling:`}</p>
        <ul>
          <li parentName="ul"><inlineCode parentName="li">{`.accept()`}</inlineCode>{` to accept the incoming call. `}</li>
          <li parentName="ul"><inlineCode parentName="li">{`.reject()`}</inlineCode>{` to reject the incoming call.`}</li>
        </ul>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
    <CodeSnippet code={CODE.INCOMING} lang="node" mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Managing active calls`}</h2>
        <p>{`After having accepted an incoming call, or started an outgoing call, you can access the `}<inlineCode parentName="p">{`call`}</inlineCode>{` instance.
You can use this instance to subscribe to updates about the status of the call, and to modify the state of the call.`}</p>
        <h3>{`Handling call status updates`}</h3>
        <p>{`Just like the client, the `}<inlineCode parentName="p">{`call`}</inlineCode>{` instance will emit `}<inlineCode parentName="p">{`status`}</inlineCode>{` event that notifies you when the status of a call has changed.
The `}<inlineCode parentName="p">{`status`}</inlineCode>{` of the call can have the following values:`}</p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`value`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`connecting`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Call is in the process of being connected`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`accepted`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Call is accepted by the called party`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`confirmed`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The call is accepted by called party and connection is established`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`failed`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Call failed. An `}<inlineCode parentName="td">{`error`}</inlineCode>{` event was emitted containing details of the failure`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`ended`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Call ended without any failures`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`ringing`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Call is ringing on the called party end`}</td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
    <CodeSnippet code={CODE.CALL_STATUS} lang="node" mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h3>{`Handling call errors`}</h3>
        <p>{`When a call fails to establish, or unexpectedly disconnects during the call, an `}<inlineCode parentName="p">{`error`}</inlineCode>{` event will be emitted notifying you of the failure reason.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
    <CodeSnippet code={CODE.CALL_ERRORS} lang="node" mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Audio Management`}</h2>
        <h3>{`Enumerating devices`}</h3>
        <p>{`It is possible to enumerate the user's available media devices using the `}<inlineCode parentName="p">{`MediaSources`}</inlineCode>{` class that is exposed by the SDK. This class exposes a public method called `}<inlineCode parentName="p">{`get()`}</inlineCode>{` which will return the list of user devices, as well as a getter method called `}<inlineCode parentName="p">{`devices`}</inlineCode>{` which performs the same function.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
    <CodeSnippet code={CODE.DEVICE_ENUMERATION} lang="node" mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <p>{`Because available devices may change over time as users connect/remove equipment, the `}<inlineCode parentName="p">{`MediaSources`}</inlineCode>{` class makes available an event listener method `}<inlineCode parentName="p">{`on`}</inlineCode>{` which accepts only the `}<inlineCode parentName="p">{`devicechange`}</inlineCode>{` event. A corresponding `}<inlineCode parentName="p">{`off`}</inlineCode>{` method can be used to remove any registered callbacks for this event, and it takes the same arguments. The callback to this method returns the full list of devices that are currently available, any handling of differences is therefore left up to the author.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
    <CodeSnippet code={CODE.MEDIA_SOURCES_EVENTS} lang="node" mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <p>{`Device listing methods, including the callbacks to the event listeners, will always respond with the following structure where `}<inlineCode parentName="p">{`MediaDeviceInfo`}</inlineCode>{` follows the `}<a parentName="p" {...{
            "href": "https://developer.mozilla.org/en-US/docs/Web/API/MediaDeviceInfo"
          }}>{`standard implementation`}</a>{`. Note that video input streams are not currently supported by the SDK, but are still available in the enumeration methods.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
    <CodeSnippet code={CODE.MEDIA_SOURCES_INTERFACE} lang="node" mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h3>{`Selecting devices`}</h3>
        <p>{`Once devices have been enumerated, it is possible to select a particular device to be used in the call by using the `}<inlineCode parentName="p">{`setInputSource`}</inlineCode>{` method available on a `}<inlineCode parentName="p">{`call`}</inlineCode>{` object. To learn how to initialize a call, and therefore have access to this interface, see `}<a parentName="p" {...{
            "href": "/api/client-sdk/#starting-a-new-call"
          }}>{`Starting a new Call`}</a>{`.`}</p>
        <p>{`The `}<inlineCode parentName="p">{`setInputSource`}</inlineCode>{` method accepts a single argument that specifies the `}<a parentName="p" {...{
            "href": "https://developer.mozilla.org/en-US/docs/Web/API/MediaStreamConstraints"
          }}>{`MediaStreamConstraints`}</a>{` that should be used. After enumerating available devices, the identifiers of the user's media sources will be available, and these can be used to select specific devices. `}</p>
        <RightColumn mdxType="RightColumn">
    <CodeSnippet code={CODE.SELECT_DEVICES} lang="node" mdxType="CodeSnippet" />
        </RightColumn>
      </LeftColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <p>{`The `}<inlineCode parentName="p">{`setOutputSource`}</inlineCode>{` method of a call allows you to specify the audio output that should be used on a particular call. Because the selection of this device must be unique, this method only accepts the deviceId property of a MediaSource.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
    <CodeSnippet code={CODE.SELECT_OUTPUT} lang="node" mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h3>{`Muting and unmuting audio`}</h3>
        <p>{`You can mute your microphone (or the selected audio device) by calling the `}<inlineCode parentName="p">{`.mute()`}</inlineCode>{` method on a call. If you need to unmute, `}<inlineCode parentName="p">{`.unmute()`}</inlineCode>{` can be called on the same call instance.
In both cases `}<inlineCode parentName="p">{`muted`}</inlineCode>{` event is fired when the change has been successfully applied. The event has the following values:`}</p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`value`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`true`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The audio device is muted`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`false`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The audio device is not muted`}</td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
    <CodeSnippet code={CODE.CALL_MUTING} lang="node" mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h3>{`Sending digits`}</h3>
        <p>{`To send DTMF digits during a call, `}<inlineCode parentName="p">{`.sendDigits()`}</inlineCode>{` method has to be called on call instance. The digits parameter should be a string containing only valid DTMF digits (`}<inlineCode parentName="p">{`0-9`}</inlineCode>{`, `}<inlineCode parentName="p">{`*`}</inlineCode>{`, and `}<inlineCode parentName="p">{`#`}</inlineCode>{`).`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
    <CodeSnippet code={CODE.CALL_SEND_DIGITS} lang="node" mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      