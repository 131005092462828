import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import Notification from 'components/Notification';
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Make a voice call`}</h1>
    <p>{`In this Programmable Voice Calling Quickstart, you'll learn how to make an outbound voice call from your web application using the Voice Calling API.`}</p>
    <h2>{`Step 1: Set up your test environment`}</h2>
    <p>{`To start testing with the official PHP SDK for Programmable Voice Calling, you need to first set up your test environment.`}</p>
    <p>{`The following command will install MessageBird:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`composer require messagebird/php-rest-api
`}</code></pre>
    <Notification type="INFO" mdxType="Notification">
  Want to learn how to set up your PHP development environment? Check out the{' '}
  <a href="/tutorials/setup-local-dev-environment-php">PHP tutorial</a>
    </Notification>
    <h2>{`Step 2: Make your first voice call`}</h2>
    <p>{`Now, create a file called `}<inlineCode parentName="p">{`make_call.php`}</inlineCode>{` in your `}<inlineCode parentName="p">{`composer.json`}</inlineCode>{` file, and include the below code snippet. You can now enter `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`your credentials`}</a>{` in the code snippet below, including `}<inlineCode parentName="p">{`YOUR-API-KEY`}</inlineCode>{` with your API key, and enter your mobile number as both the `}<inlineCode parentName="p">{`source`}</inlineCode>{` and `}<inlineCode parentName="p">{`destination`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`<?php

require_once __DIR__.'/vendor/autoload.php';

$messagebird = new MessageBird\\Client('YOUR-API-KEY');

$call = new MessageBird\\Objects\\Voice\\Call;
$call->source = '31123456789';
$call->destination = '31123456789';

$flow = new MessageBird\\Objects\\Voice\\CallFlow;

$step = new MessageBird\\Objects\\Voice\\Step;
$step->action = 'say';
$step->options = [
    'payload' => 'Hey you, a little bird told me you wanted a call!',
    'language' => 'en-GB',
    'voice' => 'female'
];

$flow->steps = [ $step ];

$call->callFlow = $flow;

$response = $messagebird->voiceCalls->create($call);

var_dump($response);
`}</code></pre>
    <Notification type="INFO" mdxType="Notification">
  You can access and manage your credentials in the{' '}
  <a href="https://dashboard.messagebird.com/en/developers/access">
    MessageBird Developer Dashboard
  </a>
  .
    </Notification>
    <p>{`Now, run the following command line:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`php make_call.php
`}</code></pre>
    <p>{`Good job! You've just made your first outbound voice call with the official PHP SDK for Programmable Voice Calling!`}</p>
    <h2>{`Step 3: View the response`}</h2>
    <p>{`Now, let’s view MessageBird’s HTTP response to your API call. If the set up was correct, you'll receive a response similar to this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`object(MessageBird\\Objects\\Voice\\Call)#32 (9) {
    ["id":protected]=>
    string(36) "67cb4f6f-8cdb-4bde-9bd5-f1f150364aa3"
    ["source"]=>
    string(11) "31XXXXXXXXX"
    ["destination"]=>
    string(13) "31XXXXXXXXX"
    ["callFlow"]=>
    NULL
    ["numberId":protected]=>
    NULL
    ["status":protected]=>
    string(6) "queued"
    ["createdAt":protected]=>
    string(20) "2019-02-20T14:47:53Z"
    ["updatedAt":protected]=>
    string(20) "2019-02-20T14:47:53Z"
    ["endedAt":protected]=>
    NULL
}
`}</code></pre>
    <p>{`If something went wrong, you’ll receive an error response from the Voice Calling API. Don't worry, simply head over to `}<a parentName="p" {...{
        "href": "/docs/voice-calling/troubleshooting"
      }}>{`Programmable Voice Calling Troubleshooting`}</a>{` to understand the error.`}</p>
    <p>{`Congrats! You can now programmatically make outbound Voice Calls with MessageBird's Voice Calling API using PHP.`}</p>
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'Receive voice calls',
      to: '/quickstarts/voice-calling/receive-calls'
    }, {
      name: 'Voice Calling Tutorials',
      to: '/tutorials?filter=voice'
    }, {
      name: 'Voice Calling API Reference',
      to: '/api/voice-calling'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      