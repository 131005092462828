import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import Notification from 'components/Notification';
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
import { Img } from 'components/shared';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`WhatsApp Business API Overview`}</h1>
    <p>{`MessageBird’s WhatsApp Business allows you to connect to your customers on the world’s most popular messaging app via our API, Flow Builder low-code solution, or native Zendesk integration. You can send and receive WhatsApp messages for customer support, alerts and notifications, as well as two-factor authentication.`}</p>
    <h2>{`Features`}</h2>
    <p>{`The channels of MessageBird’s Programmable Conversations API support several features. In the following table, you can see the feature comparison of the WhatsApp Business API with other channels:`}</p>
    <Img src="/img/screenshots/quickstarts/convo/ConversationsChannels.png" alt="Channels Features" width="650px" align="middle" mdxType="Img" />
    <h2>{`WhatsApp Business API with MessageBird`}</h2>
    <p>{`MessageBird’s Whatsapp Business API offers you the following benefits:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Easy integration`}</strong>{`: Send and receive WhatsApp messages without having to host, manage or take care of software updates.`}</li>
      <li parentName="ul"><strong parentName="li">{`Speed and deliverability`}</strong>{`: We support up to 200 requests per second with burst traffic of up to 1000 requests per second.`}</li>
      <li parentName="ul"><strong parentName="li">{`End-to-end WhatsApp encryption`}</strong>{`: All your information is secure.`}</li>
      <li parentName="ul"><strong parentName="li">{`Rich messaging`}</strong>{`: Share and receive images, locations, files, audio, and inbound videos.`}</li>
      <li parentName="ul"><strong parentName="li">{`24/7 support`}</strong>{`: Our offices around the world provide unparalleled support in every time zone, 24 hours a day, 7 days a week.`}</li>
    </ul>
    <h2>{`Getting started`}</h2>
    <p>{`Each business account must apply to WhatsApp for approval before gaining access to the WhatsApp Business API. Don’t worry! Simply follow our step-by-step `}<a parentName="p" {...{
        "href": "https://developers.messagebird.com/docs/conversations/whatsapp/getting-started/"
      }}>{`WhatsApp onboarding quickstart`}</a>{`, and we’ll walk you through the approval process, choosing a WhatsApp number, connecting WhatsApp to your MessageBird Account, installing WhatsApp, and more.`}</p>
    <h2>{`Creating a branded business profile`}</h2>
    <p>{`On your business profile, you can set up business information that will be visible to your WhatsApp contacts. To do so, head over to your `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/channels"
      }}>{`Channels`}</a>{` in the MessageBird Dashboard and easily configure your business profile.`}</p>
    <Img src="/img/screenshots/quickstarts/convo/WhatsappBusinessProfile.png" alt="Whatsapp Business Profile" width="350px" align="middle" mdxType="Img" />
    <h2>{`Using WhatsApp message templates`}</h2>
    <p>{`To initiate a conversation with a customer, WhatsApp requires you to use a pre-approved message template (previously referred to as Highly Structured Message or HSM). Head over to our step-by-step `}<a parentName="p" {...{
        "href": "https://developers.messagebird.com/docs/conversations/whatsapp/message-templates-curl/"
      }}>{`WhatsApp message templates quickstart`}</a>{` to learn how to write and submit your own message templates.`}</p>
    <h2>{`Getting customer opt-ins`}</h2>
    <p>{`The first step towards communicating with your customers over WhatsApp is getting them to opt-in. This is extremely important since it’s an `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/articles/360000344098-Do-customers-need-to-opt-in-to-receive-WhatsApp-messages-from-my-business-"
      }}>{`official WhatsApp requirement`}</a>{`. Check out our `}<a parentName="p" {...{
        "href": "https://developers.messagebird.com/docs/conversations/whatsapp/whatsapp-customer-optins/"
      }}>{`WhatsApp customer opt-ins quickstart`}</a>{` and learn what exactly is an opt-in, the opt-in guidelines, and how to collect them through the appropriate user interface and onboarding flow.`}</p>
    <h2>{`Sending and receiving WhatsApp messages`}</h2>
    <p>{`To send and receive WhatsApp messages using MessageBird’s Programmable Conversations, make sure your `}<a parentName="p" {...{
        "href": "https://developers.messagebird.com/docs/conversations/whatsapp/getting-started/"
      }}>{`business account`}</a>{` and `}<a parentName="p" {...{
        "href": "https://developers.messagebird.com/docs/conversations/whatsapp/message-templates-curl/"
      }}>{`message template`}</a>{` are already approved. Done? Awesome, now follow our step-by-step quickstart for `}<a parentName="p" {...{
        "href": "https://developers.messagebird.com/docs/conversations/whatsapp/message-templates-node"
      }}>{`Node.js`}</a>{` or `}<a parentName="p" {...{
        "href": "https://developers.messagebird.com/docs/conversations/whatsapp/message-templates-curl"
      }}>{`cURL`}</a>{` and start communicating with your clients via WhatsApp right away.`}</p>
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'Getting Started with WhatsApp Business',
      to: '/quickstarts/whatsapp/getting-started/'
    }, {
      name: 'Conversations API Reference',
      to: '/api/conversations'
    }, {
      name: 'Official MessageBird SDKs',
      to: '/libraries'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      