import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';
import Layout from '../components/Layout/Default';

const NotFoundPage = () => (
  <Layout>
    <Helmet title="404 Page Not Found" />
    <h1>NOT FOUND</h1>
    <p>
      You just hit a route that doesn&#39;t exist... the sadness. Let&#39;s go{' '}
      <Link to="/">back.</Link>
    </p>
  </Layout>
);

export default NotFoundPage;
