import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
import CodeSnippet from 'components/CodeSnippet';
import * as CODE from 'constants/live-chat-widget';
import { Img } from 'components/shared';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Setting attributes on Live Chat users`}</h1>
    <p>{`Online businesses often need to support different user experiences for different types of customers, such as anonymous visitors or known users.`}</p>
    <p>{`At the same time, customer support agents need to know which customer they are talking to, be confident that they can share private information with them, and ultimately be able to host efficient and effective conversations by using information that they already know about the customer.`}</p>
    <p>{`In this guide, we'll walk you through how to pass customer data, known as an "attribute", to the Omnichannel Widget, to help you identify users when they reach out over Live Chat.`}</p>
    <h2>{`What are customer data attributes?`}</h2>
    <p>{`First, let's define what we mean by “customer data attributes”.`}</p>
    <p>{`A customer data attribute is custom data information about your customer that enriches their anonymous contact profile and can identify them as a customer.`}</p>
    <p>{`This custom data can be set on anonymous or identified users and will be passed through to your Inbox contact center via Flow Builder.`}</p>
    <p>{`This allows you to create custom logic flows, based on the attributes that are passed.`}</p>
    <h2>{`Setting attributes on your javascript snippet`}</h2>
    <p>{`Start passing information about your users to the Omnichannel Widget by setting attributes via the method in the snippet below.`}</p>
    <p>{`Inside the `}<inlineCode parentName="p">{`.setAttributes`}</inlineCode>{`, you can now add your standard and custom data attributes as described above`}</p>
    <CodeSnippet code={CODE.SET_ATTRIBUTES} mdxType="CodeSnippet" />
    <p>{`This method takes an object with the keys and values that you would like to have on your visitor’s contact profile in Inbox.`}</p>
    <p><em parentName="p">{`These values can only be primitive values — a string, number, or a boolean.`}</em></p>
    <p>{`These values will only be attached to the visitor when the visitor sends a message from the Omnichannel Widget.`}</p>
    <p>{`The attributes passed will then be persisted on the contact of the visitor, via the steps below:
The attributes passed are last write wins. If you were to pass different values under the same key, the value that was sent last will overwrite the previous value of the key.`}</p>
    <h2>{`Mapping your attributes in Flow Builder`}</h2>
    <p>{`Now you've set your desired attributes in your Omnichannel Widget Javascript snippet, it's time to set these attributes in Flow Builder.`}</p>
    <p>{`To update the contact profile of the user who sent the message from the Omnichannel Widget, you need to add a step in Flow Builder.`}</p>
    <p>{`Please keep in mind that these values will only be attached to the visitor when the visitor sends a message from the Omnichannel Widget.`}</p>
    <ol>
      <li parentName="ol">{`Go to Inbox and navigate to the flow where you have enabled your Live Chat channel`}</li>
      <li parentName="ol">{`Drag the "Update Inbox Contact" step into the Flow, before the "Create Inbox Ticket" step`}</li>
      <li parentName="ol">{`Now, go to the step settings and add `}<inlineCode parentName="li">{`{{message.metadata.attributes}}`}</inlineCode>{` to the custom contact attributes part of the step`}</li>
    </ol>
    <p>{`{' '}`}</p>
    <Img src="/img/screenshots/quickstarts/ocw/metadataAttributes.png" alt="Install Omnichannel Widget" width="650px" align="middle" mdxType="Img" />
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Save the attributes to the step by clicking Click `}<em parentName="li">{`Add`}</em>{` and `}<em parentName="li">{`Save`}</em></li>
      <li parentName="ol">{`Send yourself a Live Chat test message and head over to Inbox`}</li>
      <li parentName="ol">{`Accept the ticket and check out the Contact Profile tab on the right of your Conversation view. You should see the fields updated correctly!`}</li>
    </ol>
    <Img src="/img/screenshots/quickstarts/ocw/inboxSidebar.png" alt="Install Omnichannel Widget" width="650px" align="middle" mdxType="Img" />
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'Omnichannel Widget API Reference',
      to: '/api/omnichannel-widget'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      