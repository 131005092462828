import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Docs.tsx";
import Notification from 'components/Notification';
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
import { Img } from 'components/shared';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Install Channel: LINE`}</h1>
    <p>{`Let's start using the Programmable Conversations by learning how to install a channel. In this quickstart, you'll learn how to set up LINE.`}</p>
    <h2>{`Step 1: Go to the channels directory in your Dashboard`}</h2>
    <p>{`Go to '`}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/channels"
      }}>{`Channels`}</a>{`' in the sidebar of your MessageBird Dashboard. Then click on `}<inlineCode parentName="p">{`Add channel`}</inlineCode>{` under LINE.`}</p>
    <Img src="/img/screenshots/quickstarts/convo/InstallLINE1.png" alt="Install LINE" width="650px" align="middle" mdxType="Img" />
    <h2>{`Step 2: Give your channel a name`}</h2>
    <p>{`Name your channel, make sure it's easily recognizable for yourself and your team. Don't worry, this will only be used internally.`}</p>
    <Img src="/img/screenshots/quickstarts/convo/InstallLINE2.png" alt="Install LINE" width="650px" align="middle" mdxType="Img" />
    <h2>{`Step 3: Add your app ID and secret`}</h2>
    <p>{`Add your `}<a parentName="p" {...{
        "href": "https://developers.line.biz/en/docs/line-login/overview/"
      }}>{`access token and channel secret`}</a>{`. You can find them in the Channel Section of the `}<a parentName="p" {...{
        "href": "https://manager.line.biz"
      }}>{`LINE Business Center`}</a>{`.`}</p>
    <Img src="/img/screenshots/quickstarts/convo/InstallLINE3.png" alt="Install LINE" width="650px" align="middle" mdxType="Img" />
    <h2>{`Step 4: Install LINE`}</h2>
    <p>{`Click on 'Install now' and voilà! LINE is now installed as a channel.`}</p>
    <Img src="/img/screenshots/quickstarts/convo/InstallLINE4.png" alt="Install LINE" width="650px" align="middle" mdxType="Img" />
    <h2>{`Step 5: Manage your channel`}</h2>
    <p>{`If you want to edit, rename or delete your channel, simply select the channel and save the changes afterwards.`}</p>
    <p>{`Nice work! 🎉 You've successfully installed LINE as a channel for the MessageBird Programmable Conversations API.`}</p>
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'Install Channel: SMS',
      to: '/docs/conversations/install-channel'
    }, {
      name: 'Install Channel: WhatsApp',
      to: '/docs/conversations/install-channel-whatsapp'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      