import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import { Img } from 'components/shared';
import Notification from 'components/Notification';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`How to Send a Media Template Message`}</h1>
    <p>{`Media templates must be pre-approved by Facebook. If you have access to Facebook Template Manager, you can submit your
template for approval. If you don't have access to the Facebook Template Manager, please send an email message to our
Customer Support team (`}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`), who will ask for the required template data. Once this is received,
the Support team will then confirm with you if the template has been approved by Facebook.`}</p>
    <Notification type="INFO" mdxType="Notification">
    Media template submissions are not currently possible through MessageBird's WhatsApp Template Manager.
    </Notification>
    <p>{`In order to send a WhatsApp media template message, you must use one of the Conversations API endpoints for sending
messages (see bellow). The message type must be `}<inlineCode parentName="p">{`hsm`}</inlineCode>{` and the `}<inlineCode parentName="p">{`content.hsm`}</inlineCode>{` must be set.`}</p>
    <h2>{`Image template message`}</h2>
    <p>{`Considering the following media template is approved by Facebook.`}</p>
    <Img src="/img/screenshots/quickstarts/whatsapp/media-template-with-buttons.png" alt="Media template with buttons" width="800px" align="middle" mdxType="Img" />
    <p>{`In order to send a WhatsApp message using this template, you must perform the following HTTP request to Conversations API.
The following example uses the endpoint `}<inlineCode parentName="p">{`POST /v1/conversations/start`}</inlineCode>{`, however, you can also use the other endpoints for sending messages,
please check the `}<a parentName="p" {...{
        "href": "/api/conversations"
      }}>{`Conversations API reference`}</a>{` for details about the request.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`import messagebird

client = messagebird.Client('YOUR_ACCESS_KEY')

conversation = client.conversation_start({
  'to': '+31612345678',
  'type': 'hsm',
  'channelId': '2181d3bb46f5449aad0309f852b36ba5',
  'content': {
    'hsm': {
      'language': {
        'code': 'en'
      },
      'components': [
        {
          'type': 'header',
          'parameters': [
            {
              'type': 'image',
              'image': {
                'url': 'https://www.messagebird.com/images/banner.jpg'
              }
            }
          ]
        },
        {
          'type': 'body',
          'parameters': [
            {
              'type': 'text',
              'text': 'John'
            },
            {
              'type': 'text',
              'text': 'MB93824'
            },
            {
              'type': 'text',
              'text': '2 days'
            },
            {
              'type': 'text',
              'text': 'MessageBird'
            }
          ]
        }
      ],
      'namespace': '20132cc4_f094_b090_d255_35677159bbdt',
      'templateName': '33172012024_ship_img_but_1'
    }
  }
})
print(conversation)
`}</code></pre>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`content.hsm.namespace`}</inlineCode>{` can be found in the WhatsApp Templates Manager of your Facebook Business account, or, in case
you don't have access to it, please contact our `}<a parentName="li" {...{
          "href": "https://www.messagebird.com/en/contact"
        }}>{`support team`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`content.hsm.language.code`}</inlineCode>{` is the language which the template message should be sent`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`content.hsm.templateName`}</inlineCode>{` is the template name`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`content.hsm.components[0].type`}</inlineCode>{` must be `}<inlineCode parentName="li">{`header`}</inlineCode>{` as the template has an image header`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`content.hsm.components[0].image.url`}</inlineCode>{` must contain an image URL because the template has an image header`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`content.hsm.components[1].type`}</inlineCode>{` must be `}<inlineCode parentName="li">{`body`}</inlineCode>{` because the template has variables in the message body`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`content.hsm.components[1].type.parameters`}</inlineCode>{` must contain a list of objects with the values that should be set in the variables`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`content.hsm.components[1].type.parameters[0..3].type`}</inlineCode>{` must be `}<inlineCode parentName="li">{`text`}</inlineCode>{` as the variable type is text`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`content.hsm.components[1].type.parameters[0..3].text`}</inlineCode>{` contains the value of the variable`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`to`}</inlineCode>{` is the phone number of the recipient`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`type`}</inlineCode>{` must be `}<inlineCode parentName="li">{`hsm`}</inlineCode>{` because this is a template message`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`channelId`}</inlineCode>{` must be your WhatsApp channel ID`}</li>
    </ul>
    <p>{`The HTTP response should be `}<inlineCode parentName="p">{`202 Accepted`}</inlineCode>{` and it should contain the message ID.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`id                   : UUID
contact id           : UUID
last used channel id : UUID
message id           : UUID
message total count  : 1
status               : active
created date time    : TIMESTAMP
updated date time    : TIMESTAMP
last received date   : TIMESTAMP
`}</code></pre>
    <h2>{`Video template message`}</h2>
    <p>{`Considering the following media template is approved by Facebook.`}</p>
    <Img src="/img/screenshots/quickstarts/whatsapp/video-template-sample.png" alt="Video template" width="800px" align="middle" mdxType="Img" />
    <p>{`In order to send a WhatsApp message using this template, you must perform the following HTTP request to Conversations API.
The following example uses the endpoint `}<inlineCode parentName="p">{`POST /v1/conversations/start`}</inlineCode>{`, however, you can also use the other endpoints for sending messages,
please check the `}<a parentName="p" {...{
        "href": "/api/conversations"
      }}>{`Conversations API reference`}</a>{` for details about the request.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`import messagebird

client = messagebird.Client('YOUR_ACCESS_KEY')

conversation = client.conversation_start({
  'to': '31612345678',
  'channelId': '2181d3bb46f5449aad0309f852b36ba5',
  'type': 'hsm',
  'content': {
    'hsm': {
      'namespace': '20132cc4_f094_b090_d255_35677159bbdt',
      'templateName': 'test_media_template_with_video_header',
      'language': {
        'policy': 'deterministic',
        'code': 'en'
      },
      'components': [
        {
          'type': 'header',
          'parameters': [
            {
              'video': {
                'url': 'https://www.yourcompany.com/videos/sample.mp4'
              },
              'type': 'video'
            }
          ]
        },
        {
          'type': 'body',
          'parameters': [
            {
              'type': 'text',
              'text': 'Robert'
            }
          ]
        }
      ]
    }
  }
})
print(conversation)
`}</code></pre>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`content.hsm.namespace`}</inlineCode>{` can be found in the WhatsApp Templates Manager of your Facebook Business account, or, in case
you don't have access to it, please contact our `}<a parentName="li" {...{
          "href": "https://www.messagebird.com/en/contact"
        }}>{`support team`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`content.hsm.language.code`}</inlineCode>{` is the language which the template message should be sent`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`content.hsm.templateName`}</inlineCode>{` is the template name`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`content.hsm.components[0].type`}</inlineCode>{` must be `}<inlineCode parentName="li">{`header`}</inlineCode>{` as the template has a video header`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`content.hsm.components[0].video.url`}</inlineCode>{` must contain a video URL because the template has a video header`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`content.hsm.components[1].type`}</inlineCode>{` must be `}<inlineCode parentName="li">{`body`}</inlineCode>{` because the template has variables in the message body`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`content.hsm.components[1].type.parameters`}</inlineCode>{` must contain a list of objects with the values that should be set in the variables`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`content.hsm.components[1].type.parameters[0].type`}</inlineCode>{` must be `}<inlineCode parentName="li">{`text`}</inlineCode>{` as the variable type is text`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`content.hsm.components[1].type.parameters[0].text`}</inlineCode>{` contains the value of the variable`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`to`}</inlineCode>{` is the phone number of the recipient`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`type`}</inlineCode>{` must be `}<inlineCode parentName="li">{`hsm`}</inlineCode>{` because this is a template message`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`from`}</inlineCode>{` must be your WhatsApp channel ID`}</li>
    </ul>
    <p>{`The HTTP response should be `}<inlineCode parentName="p">{`202 Accepted`}</inlineCode>{` and it should contain the message ID.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`id                   : UUID
contact id           : UUID
last used channel id : UUID
message id           : UUID
message total count  : 1
status               : active
created date time    : TIMESTAMP
updated date time    : TIMESTAMP
last received date   : TIMESTAMP
`}</code></pre>
    <h2>{`Document template message`}</h2>
    <p>{`Considering the following media template is approved by Facebook.`}</p>
    <Img src="/img/screenshots/quickstarts/whatsapp/document-template-sample.png" alt="Document template" width="800px" align="middle" mdxType="Img" />
    <p>{`In order to send a WhatsApp message using this template, you must perform the following HTTP request to Conversations API.
The following example uses the endpoint `}<inlineCode parentName="p">{`POST /v1/send`}</inlineCode>{`, however, you can also use the other endpoints for sending messages,
please check the `}<a parentName="p" {...{
        "href": "/api/conversations"
      }}>{`Conversations API reference`}</a>{` for details about the request.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`import messagebird

client = messagebird.Client('YOUR_ACCESS_KEY')

conversation = client.conversation_start({
  'to': '31612345678',
  'channelId': '2181d3bb46f5449aad0309f852b36ba5',
  'type': 'hsm',
  'content': {
    'hsm': {
      'namespace': '20132cc4_f094_b090_d255_35677159bbdt',
      'templateName': 'test_media_template_with_document_header',
      'language': {
        'policy': 'deterministic',
        'code': 'en'
      },
      'components': [
        {
          'type': 'header',
          'parameters': [
            {
              'document': {
                'url': 'https://www.yourcompany.com/documents/contract.pdf'
              },
              'type': 'document'
            }
          ]
        },
        {
          'type': 'body',
          'parameters': [
            {
              'type': 'text',
              'text': 'Robert'
            }
          ]
        }
      ]
    }
  }
})
print(conversation)
`}</code></pre>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`content.hsm.namespace`}</inlineCode>{` can be found in the WhatsApp Templates Manager of your Facebook Business account, or, in case
you don't have access to it, please contact our `}<a parentName="li" {...{
          "href": "https://www.messagebird.com/en/contact"
        }}>{`support team`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`content.hsm.language.code`}</inlineCode>{` is the language which the template message should be sent`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`content.hsm.templateName`}</inlineCode>{` is the template name`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`content.hsm.components[0].type`}</inlineCode>{` must be `}<inlineCode parentName="li">{`header`}</inlineCode>{` as the template has a document header`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`content.hsm.components[0].document.url`}</inlineCode>{` must contain a document URL because the template has a document header`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`content.hsm.components[1].type`}</inlineCode>{` must be `}<inlineCode parentName="li">{`body`}</inlineCode>{` because the template has variables in the message body`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`content.hsm.components[1].type.parameters`}</inlineCode>{` must contain a list of objects with the values that should be set in the variables`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`content.hsm.components[1].type.parameters[0].type`}</inlineCode>{` must be `}<inlineCode parentName="li">{`text`}</inlineCode>{` as the variable type is text`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`content.hsm.components[1].type.parameters[0].text`}</inlineCode>{` contains the value of the variable`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`to`}</inlineCode>{` is the phone number of the recipient`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`type`}</inlineCode>{` must be `}<inlineCode parentName="li">{`hsm`}</inlineCode>{` because this is a template message`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`from`}</inlineCode>{` must be your WhatsApp channel ID`}</li>
    </ul>
    <p>{`The HTTP response should be `}<inlineCode parentName="p">{`202 Accepted`}</inlineCode>{` and it should contain the message ID.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`id                   : UUID
contact id           : UUID
last used channel id : UUID
message id           : UUID
message total count  : 1
status               : active
created date time    : TIMESTAMP
updated date time    : TIMESTAMP
last received date   : TIMESTAMP
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      