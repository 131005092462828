import styled from 'styled-components';

export const Wrap = styled.section`
  padding-top: 80px !important;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  img {
    object-fit: contain;
    height: 42px;
    margin-left: 15px;
    margin-right: 15px;
  }
`;
