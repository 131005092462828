import React, { useEffect, useRef } from 'react';
import { string } from 'prop-types';

const defaultSnippet = `console.log('Hello world!')`;

const RunkitCodeBlock = ({ source }) => {
  const runkitEmbedRef = useRef();
  useEffect(() => {
    RunKit.createNotebook({
      element: runkitEmbedRef.current,
      source,
    });
  }, []);
  return <div ref={runkitEmbedRef} />;
};

export default RunkitCodeBlock;

RunkitCodeBlock.propTypes = {
  source: string,
};

RunkitCodeBlock.defaultProps = {
  source: defaultSnippet,
};
