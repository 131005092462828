export const VERIFY_URI = {
  bash: `https://rest.messagebird.com/verify`,
};

export const VERIFY_METHODS = {
  bash: `POST /verify
GET /verify/{verifyId}
DELETE /verify/{verifyId}`,
};

export const VERIFY_OBJECT = {
  json: `{
  "id": "4e213b01155d1e35a9d9571v00162985",
  "href": "https://rest.messagebird.com/verify/4e213b01155d1e35a9d9571v00162985",
  "recipient": 31612345678,
  "originator": 31612345678,
  "type": "sms", 
  "reference": null,
  "messages": {
    "id": "31bce2a1155d1f7c1db9df6b32167259",
    "href": "https://rest.messagebird.com/messages/31bce2a1155d1f7c1db9df6b32167259"
  },
  "status": "sent",
  "createdDatetime": "2016-05-03T14:26:57+00:00",
  "validUntilDatetime": "2016-05-03T14:27:27+00:00"
}`,
};

export const REQUESTVERIFY_DEF = {
  bash: `POST https://rest.messagebird.com/verify`,
};

export const EXAMPLE_ACCESS_KEY = 'YOUR_ACCESS_KEY';

export const REQUESTVERIFY_REQUEST = {
  curl:
    `curl -X POST https://rest.messagebird.com/verify \\
  -H 'Authorization: AccessKey ` +
    EXAMPLE_ACCESS_KEY +
    `' \\
  -d "recipient=31612345678" \\
  -d "originator=YourName"`,
  php:
    `$messageBird = new \\MessageBird\\Client('` +
    EXAMPLE_ACCESS_KEY +
    `'); // Set your own API access key here.

$verify             = new \\MessageBird\\Objects\\Verify();
$verify->recipient = 31612345678;

$extraOptions = [
    'originator' => 'YourBrand',
    'timeout' => 60,
];

$verifyResult = $messageBird->verify->create($verify, $extraOptions);`,
  ruby: `verify = client.verify_create(31612345678, :originator => 'YourName')`,
  csharp:
    `const string YourAccessKey = "` +
    EXAMPLE_ACCESS_KEY +
    `";
Client client = Client.CreateDefault(YourAccessKey);

VerifyOptionalArguments optionalArguments = new VerifyOptionalArguments
{
    Originator = "YourName"
};
Verify verify = client.CreateVerify(31612345678, optionalArguments);`,
  go: `v, err := verify.Create(c, "31612345678", &verify.VerifyParams{
  Originator: "YourName",
})`,
  python: `verify = client.verify_create('31612345678', {'originator': 'YourName'})`,
  java: `VerifyRequest request = new VerifyRequest("recipient");
request.setOriginator("YourName");

Verify verify = messageBirdClient.sendVerifyToken(request);`,
  node: `var params = {
  originator: 'YourName'
};

messagebird.verify.create('31612345678', params, function (err, response) {
  if (err) {
    return console.log(err);
  }
  console.log(response);
});`,
};

export const EMAIL_RECIPIENT = 'client-email@example.com';
export const EMAIL_ORIGINATOR = 'verify@company.com';
export const EMAIL_ORIGINATOR_HINT =
  'The originator email domain needs to be set up as an email channel in your account at https://dashboard.messagebird.com/en/channels/';
export const REQUEST_EMAIL_VERIFY_REQUEST = {
  curl:
    `curl -X POST https://rest.messagebird.com/verify \\
  -H 'Authorization: AccessKey ` +
    EXAMPLE_ACCESS_KEY +
    `' \\
  -d "type=email" \\
  -d "recipient=` +
    EMAIL_RECIPIENT +
    `" \\
  -d "originator=` +
    EMAIL_ORIGINATOR +
    `" \\
  # ` +
    EMAIL_ORIGINATOR_HINT,
  php:
    `$messageBird = new Client('` +
    EXAMPLE_ACCESS_KEY +
    `'); 

$verify = new Verify();
$verify->recipient = '` +
    EMAIL_RECIPIENT +
    `';

$extraOptions = [
    'type' => 'email',
    'originator' => '` +
    EMAIL_ORIGINATOR +
    `',
    // ` +
    EMAIL_ORIGINATOR_HINT +
    `
    'timeout' => 60,
];

$verifyResult = $messageBird->verify->create($verify, $extraOptions);`,
  ruby:
    `client = MessageBird::Client.new('` +
    EXAMPLE_ACCESS_KEY +
    `')

otp = client.verify_create('` +
    EMAIL_RECIPIENT +
    `',
\toriginator: '` +
    EMAIL_ORIGINATOR +
    `', # ` +
    EMAIL_ORIGINATOR_HINT +
    `
\ttype: 'email')`,
  csharp:
    `var client = Client.CreateDefault("` +
    EXAMPLE_ACCESS_KEY +
    `");

var optionalArguments = new VerifyOptionalArguments
{
    Type = MessageType.Email,
    Originator = "` +
    EMAIL_ORIGINATOR +
    `"
    // ` +
    EMAIL_ORIGINATOR_HINT +
    `
};

var verify = client.CreateVerify("` +
    EMAIL_RECIPIENT +
    `", optionalArguments);`,
  go:
    `accessKey := "` +
    EXAMPLE_ACCESS_KEY +
    `"

// Create a client.
c := messagebird.New(accessKey)

verify, err := verify.Create(c, "` +
    EMAIL_RECIPIENT +
    `", &verify.VerifyParams{
\tOriginator: "` +
    EMAIL_ORIGINATOR +
    `",
\t// ` +
    EMAIL_ORIGINATOR_HINT +
    ` 
\tType: "email"
})
`,
  python:
    `client = messagebird.Client('` +
    EXAMPLE_ACCESS_KEY +
    `')

verify = client.verify_create_email('` +
    EMAIL_RECIPIENT +
    `', 
    '` +
    EMAIL_ORIGINATOR +
    `', 
    {
        'subject': 'your code'
    })
#` +
    EMAIL_ORIGINATOR_HINT,
  java:
    `final MessageBirdService wsr = new MessageBirdServiceImpl("` +
    EXAMPLE_ACCESS_KEY +
    `");
final MessageBirdClient messageBirdClient = new MessageBirdClient(wsr);
Verify verify = null;

VerifyRequest request = new VerifyRequest("` +
    EMAIL_RECIPIENT +
    `");
request.setType("email");
request.setOriginator("` +
    EMAIL_ORIGINATOR +
    `");
// ` +
    EMAIL_ORIGINATOR_HINT +
    `
request.setTimeout(300);

verify = messageBirdClient.sendVerifyToken(request);`,
  node:
    `var messagebird = require('messagebird')('` +
    EXAMPLE_ACCESS_KEY +
    `');

var originator = "` +
    EMAIL_ORIGINATOR +
    `";
// ` +
    EMAIL_ORIGINATOR_HINT +
    `
var recipient = "` +
    EMAIL_RECIPIENT +
    `";
var additionalParams = {
  timeout: 60
}

//Creating a token with email
messagebird.verify.createWithEmail(originator, recipient, additionalParams, function (err, response) {
  if (err) {
    return console.log(err);
  }
  console.log(response);
});`,
};

export const REQUESTVERIFY_RESPONSE = {
  curl: `{
  "id": "4e213b01155d1e35a9d9571v00162985",
  "href":
    "https://rest.messagebird.com/verify/4e213b01155d1e35a9d9571v00162985",
  "recipient": 31612345678,
  "reference": null,
  "messages": {
    "href":
      "https://rest.messagebird.com/messages/31bce2a1155d1f7c1db9df6b32167259"
  },
  "status": "sent",
  "createdDatetime": "2016-05-03T14:26:57+00:00",
  "validUntilDatetime": "2016-05-03T14:27:27+00:00"
}`,
  php: `{
  "id": "4e213b01155d1e35a9d9571v00162985",
  "href": "https://rest.messagebird.com/verify/4e213b01155d1e35a9d9571v00162985",
  "recipient": 31612345678,
  "reference": null,
  "messages": {
    "href": "https://rest.messagebird.com/messages/31bce2a1155d1f7c1db9df6b32167259"
  },
  "status": "sent",
  "createdDatetime": "2016-05-03T14:26:57+00:00",
  "validUntilDatetime": "2016-05-03T14:27:27+00:00"
}`,
  ruby: `#<MessageBird::Verify:0x00007f869eafe2c8
@createdDatetime=2017-05-26 20:06:07 +0000,
@href="https://rest.messagebird.com/verify/15498233759288aaf929661v21936686",
@id="15498233759288aaf929661v21936686",
@recipient=31612345678,
@status="sent",
@validUntilDatetime=2017-05-26 20:06:37 +0000>`,
  csharp: `{
  "id": "4e213b01155d1e35a9d9571v00162985",
  "href": "https://rest.messagebird.com/verify/4e213b01155d1e35a9d9571v00162985",
  "recipient": 31612345678,
  "reference": null,
  "messages": {
    "href": "https://rest.messagebird.com/messages/31bce2a1155d1f7c1db9df6b32167259"
  },
  "status": "sent",
  "createdDatetime": "2016-05-03T14:26:57+00:00",
  "validUntilDatetime": "2016-05-03T14:27:27+00:00"
}
`,
  go: `&verify.Verify{
  ID:"4e213b01155d1e35a9d9571v00162985",
  HRef:"https://rest.messagebird.com/verify/4e213b01155d1e35a9d9571v00162985",
  Reference:"",
  Status:"sent",
  Messages:map[string]string{
    "href":"https://rest.messagebird.com/messages/31bce2a1155d1f7c1db9df6b32167259",
  },
  CreatedDatetime:(*time.Time)(0xc00000c3c0),
  ValidUntilDatetime:(*time.Time)(0xc00000c400),
  Recipient:31612345678,
}`,
  python: `{
  "id": "4e213b01155d1e35a9d9571v00162985",
  "href": "https://rest.messagebird.com/verify/4e213b01155d1e35a9d9571v00162985",
  "recipient": 31612345678,
  "reference": null,
  "messages": {
    "href": "https://rest.messagebird.com/messages/31bce2a1155d1f7c1db9df6b32167259"
  },
  "status": "sent",
  "createdDatetime": "2016-05-03T14:26:57+00:00",
  "validUntilDatetime": "2016-05-03T14:27:27+00:00"
}`,
  java: `com.messagebird.objects.Verify@4450d156[
  id=4e213b01155d1e35a9d9571v00162985
  href=https://rest.messagebird.com/verify/4e213b01155d1e35a9d9571v00162985
  recipient=31612345678
  messages=Messages{href=https://rest.messagebird.com/messages/31bce2a1155d1f7c1db9df6b32167259}
  status=sent
  createdDatetime=Tue May 03 20:12:54 CEST 2016
  validUntilDatetime=Tue May 03 20:13:24 CEST 2016
]`,
  node: `{
  id: 'd015e66b7f1f459892130425aabb99e1',
  href: 'https://rest.messagebird.com/verify/d015e66b7f1f459892130425aabb99e1',
  recipient: 31612345678,
  reference: null,
  messages: {
      href: 'https://rest.messagebird.com/messages/76ccc5cc2c42441595d71c4d646ef512'
  },
  status: 'sent',
  createdDatetime: '2018-09-08T13:06:03+00:00',
  validUntilDatetime: '2018-09-08T13:06:33+00:00'
}`,
};

export const VERIFYVERIFY_DEF = {
  bash: `GET https://rest.messagebird.com/verify/{verifyId}?token={token}`,
};

export const VERIFYVERIFY_REQUEST = {
  curl: `curl -X GET "https://rest.messagebird.com/verify/4e213b01155d1e35a9d9571v00162985?token=123456" \\
   -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM'`,
  php: `// ID returned upon creating the verify.
$verifyId = '4e213b01155d1e35a9d9571v00162985';

// User provided token to validate.
$token = '123456';

$result = $client->verify->verify($verifyId, $token);

// $isVerified = $result->status === 'verified';`,
  ruby: `verify = client.verify_token('15498233759288aaf929661v21936686', 'verify-token')`,
  csharp: `const string YourAccessKey = "YOUR_ACCESS_KEY";
Client client = Client.CreateDefault(YourAccessKey);

// ID returned upon creating the verify.
const string id = "4e213b01155d1e35a9d9571v00162985";

// User provided token to validate.
const string token = "123456";

Verify verify = client.SendVerifyToken(id, token);

// bool isVerified = verify.Status == VerifyStatus.Verified;`,
  go: `v, err := verify.VerifyToken(
  client,
  "4e213b01155d1e35a9d9571v00162985",
  "123456",
)`,
  python: `# ID returned upon creating the verify.
id = '4e213b01155d1e35a9d9571v00162985'

# User provided token to validate.
token = '123456'

verify = client.verify_verify(id, token)`,
  java: `Verify verify = messageBirdClient.verifyToken("4e213b01155d1e35a9d9571v00162985", "123456");`,
  node: `// ID returned upon creating the verify.
var id = 'd015e66b7f1f459892130425aabb99e1';

// User provided token to validate.
var token = '123456';

messagebird.verify.verify(id, token, function (err, response) {
  if (err) {
    return console.log(err);
  }
  console.log(response);
});`,
};

export const VERIFYVERIFY_RESPONSE = {
  curl: `{
  "id": "4e213b01155d1e35a9d9571v00162985",
  "href":
    "https://rest.messagebird.com/verify/4e213b01155d1e35a9d9571v00162985",
  "recipient": 31612345678,
  "reference": null,
  "messages": {
    "href":
      "https://rest.messagebird.com/messages/31bce2a1155d1f7c1db9df6b32167259"
  },
  "status": "sent",
  "createdDatetime": "2016-05-03T14:26:57+00:00",
  "validUntilDatetime": "2016-05-03T14:27:27+00:00"
}`,
  php: `{
  "id": "4e213b01155d1e35a9d9571v00162985",
  "href": "https://rest.messagebird.com/verify/4e213b01155d1e35a9d9571v00162985",
  "recipient": 31612345678,
  "reference": null,
  "messages": {
    "href": "https://rest.messagebird.com/messages/31bce2a1155d1f7c1db9df6b32167259"
  },
  "status": "sent",
  "createdDatetime": "2016-05-03T14:26:57+00:00",
  "validUntilDatetime": "2016-05-03T14:27:27+00:00"
}`,
  ruby: `#<MessageBird::Verify:0x00007f869eafe2c8
@createdDatetime=2017-05-26 20:06:07 +0000,
@href="https://rest.messagebird.com/verify/15498233759288aaf929661v21936686",
@id="15498233759288aaf929661v21936686",
@recipient=31612345678,
@status="verified",
@validUntilDatetime=2017-05-26 20:06:37 +0000>`,
  csharp: `{
  "id": "4e213b01155d1e35a9d9571v00162985",
  "href": "https://rest.messagebird.com/verify/4e213b01155d1e35a9d9571v00162985",
  "recipient": 31612345678,
  "reference": null,
  "messages": {
    "href": "https://rest.messagebird.com/messages/31bce2a1155d1f7c1db9df6b32167259"
  },
  "status": "sent",
  "createdDatetime": "2016-05-03T14:26:57+00:00",
  "validUntilDatetime": "2016-05-03T14:27:27+00:00"
}`,
  go: `&verify.Verify{
  ID:"4e213b01155d1e35a9d9571v00162985",
  HRef:"https://rest.messagebird.com/verify/4e213b01155d1e35a9d9571v00162985",
  Reference:"",
  Status:"verified",
  Messages:map[string]string{"href":"https://rest.messagebird.com/messages/31bce2a1155d1f7c1db9df6b32167259"},
  CreatedDatetime:(*time.Time)(0xc00000c3a0),
  ValidUntilDatetime:(*time.Time)(0xc00000c3e0),
  Recipient:31612345678,
}`,
  python: `{
  "id": "4e213b01155d1e35a9d9571v00162985",
  "href": "https://rest.messagebird.com/verify/4e213b01155d1e35a9d9571v00162985",
  "recipient": 31612345678,
  "reference": null,
  "messages": {
    "href": "https://rest.messagebird.com/messages/31bce2a1155d1f7c1db9df6b32167259"
  },
  "status": "sent",
  "createdDatetime": "2016-05-03T14:26:57+00:00",
  "validUntilDatetime": "2016-05-03T14:27:27+00:00"
}`,
  java: `com.messagebird.objects.Verify@4450d156[
  id=4e213b01155d1e35a9d9571v00162985
  href=https://rest.messagebird.com/verify/4e213b01155d1e35a9d9571v00162985
  recipient=31612345678
  messages=Messages{href=https://rest.messagebird.com/messages/31bce2a1155d1f7c1db9df6b32167259}
  status=verified
  createdDatetime=Tue May 03 20:12:54 CEST 2016
  validUntilDatetime=Tue May 03 20:13:24 CEST 2016
  ]
  `,
  node: `{
  id: 'd015e66b7f1f459892130425aabb99e1',
  href: 'https://rest.messagebird.com/verify/d015e66b7f1f459892130425aabb99e1',
  recipient: 31612345678,
  reference: null,
  messages: {
      href: 'https://rest.messagebird.com/messages/76ccc5cc2c42441595d71c4d646ef512'
  },
  status: 'sent',
  createdDatetime: '2018-09-08T13:06:03+00:00',
  validUntilDatetime: '2018-09-08T13:06:33+00:00'
}`,
};

export const VIEWVERIFY_DEF = {
  bash: `GET https://rest.messagebird.com/verify/{verifyId}`,
};

export const VIEWVERIFY_REQUEST = {
  curl: `curl -X GET https://rest.messagebird.com/verify/4e213b01155d1e35a9d9571v00162985 \\
   -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM'`,
  php: `// ID returned upon creating the verify.
$verifyId = '4e213b01155d1e35a9d9571v00162985';

$result = $client->verify->read($verifyId);`,
  ruby: `verify = client.verify('15498233759288aaf929661v21936686')`,
  csharp: `const string YourAccessKey = "YOUR_ACCESS_KEY";
Client client = Client.CreateDefault(YourAccessKey);

// ID returned upon creating the verify.
const string id = "4e213b01155d1e35a9d9571v00162985";

Verify verify = client.ViewVerify(id);`,
  python: `# ID returned upon creating the verify.
id = '4e213b01155d1e35a9d9571v00162985'

verify = client.verify(id)`,
  java: `Verify verify = messageBirdClient.getVerifyObject("4e213b01155d1e35a9d9571v00162985");`,
  node: `// ID returned upon creating the verify.
var id = 'd015e66b7f1f459892130425aabb99e1';

messagebird.verify.read(id, function (err, response) {
  if (err) {
    return console.log(err);
  }
  console.log(response);
});`,
};

export const VIEWVERIFY_RESPONSE = {
  curl: `{
  "id": "4e213b01155d1e35a9d9571v00162985",
  "href":
    "https://rest.messagebird.com/verify/4e213b01155d1e35a9d9571v00162985",
  "recipient": 31612345678,
  "reference": null,
  "messages": {
    "href":
      "https://rest.messagebird.com/messages/31bce2a1155d1f7c1db9df6b32167259"
  },
  "status": "sent",
  "createdDatetime": "2016-05-03T14:26:57+00:00",
  "validUntilDatetime": "2016-05-03T14:27:27+00:00"
}`,
  php: `{
  "id": "4e213b01155d1e35a9d9571v00162985",
  "href": "https://rest.messagebird.com/verify/4e213b01155d1e35a9d9571v00162985",
  "recipient": 31612345678,
  "reference": null,
  "messages": {
    "href": "https://rest.messagebird.com/messages/31bce2a1155d1f7c1db9df6b32167259"
  },
  "status": "sent",
  "createdDatetime": "2016-05-03T14:26:57+00:00",
  "validUntilDatetime": "2016-05-03T14:27:27+00:00"
}`,
  ruby: `#<MessageBird::Verify:0x00007f869eafe2c8
@createdDatetime=2017-05-26 20:06:07 +0000,
@href="https://rest.messagebird.com/verify/15498233759288aaf929661v21936686",
@id="15498233759288aaf929661v21936686",
@recipient=31612345678,
@status="verified",
@validUntilDatetime=2017-05-26 20:06:37 +0000>`,
  csharp: `{
  "id": "4e213b01155d1e35a9d9571v00162985",
  "href": "https://rest.messagebird.com/verify/4e213b01155d1e35a9d9571v00162985",
  "recipient": 31612345678,
  "reference": null,
  "messages": {
    "href": "https://rest.messagebird.com/messages/31bce2a1155d1f7c1db9df6b32167259"
  },
  "status": "sent",
  "createdDatetime": "2016-05-03T14:26:57+00:00",
  "validUntilDatetime": "2016-05-03T14:27:27+00:00"
}`,
  go: `{
  "id": "4e213b01155d1e35a9d9571v00162985",
  "href": "https://rest.messagebird.com/verify/4e213b01155d1e35a9d9571v00162985",
  "recipient": 31612345678,
  "reference": null,
  "messages": {
    "href": "https://rest.messagebird.com/messages/31bce2a1155d1f7c1db9df6b32167259"
  },
  "status": "sent",
  "createdDatetime": "2016-05-03T14:26:57+00:00",
  "validUntilDatetime": "2016-05-03T14:27:27+00:00"
}`,
  python: `{
  "id": "4e213b01155d1e35a9d9571v00162985",
  "href": "https://rest.messagebird.com/verify/4e213b01155d1e35a9d9571v00162985",
  "recipient": 31612345678,
  "reference": null,
  "messages": {
    "href": "https://rest.messagebird.com/messages/31bce2a1155d1f7c1db9df6b32167259"
  },
  "status": "sent",
  "createdDatetime": "2016-05-03T14:26:57+00:00",
  "validUntilDatetime": "2016-05-03T14:27:27+00:00"
}
`,
  java: `com.messagebird.objects.Verify@4450d156[
  id=4e213b01155d1e35a9d9571v00162985
  href=https://rest.messagebird.com/verify/4e213b01155d1e35a9d9571v00162985
  recipient=31612345678
  messages=Messages{href=https://rest.messagebird.com/messages/31bce2a1155d1f7c1db9df6b32167259}
  status=verified
  createdDatetime=Tue May 03 20:12:54 CEST 2016
  validUntilDatetime=Tue May 03 20:13:24 CEST 2016
  ]`,
  node: `{
  id: 'd015e66b7f1f459892130425aabb99e1',
  href: 'https://rest.messagebird.com/verify/d015e66b7f1f459892130425aabb99e1',
  recipient: 31612345678,
  reference: null,
  messages: {
      href: 'https://rest.messagebird.com/messages/76ccc5cc2c42441595d71c4d646ef512'
  },
  status: 'sent',
  createdDatetime: '2018-09-08T13:06:03+00:00',
  validUntilDatetime: '2018-09-08T13:06:33+00:00'
}`,
};

export const DELETEVERIFY_DEF = {
  bash: `DELETE https://rest.messagebird.com/verify/{verifyId}`,
};

export const DELETEVERIFY_REQUEST = {
  curl: `curl -X DELETE https://rest.messagebird.com/verify/4e213b01155d1e35a9d9571v00162985 \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM'`,
  php: `// ID returned upon creating the verify.
$verifyId = '4e213b01155d1e35a9d9571v00162985';

$client->verify->delete($verifyId);`,
  ruby: `client.verify_delete('15498233759288aaf929661v21936686')`,
  csharp: `const string YourAccessKey = "YOUR_ACCESS_KEY";
Client client = Client.CreateDefault(YourAccessKey);

// ID returned upon creating the verify.
const string id = "4e213b01155d1e35a9d9571v00162985";

client.DeleteVerify(id);`,
  java: `messageBirdClient.deleteVerifyObject("4e213b01155d1e35a9d9571v00162985");`,
  node: `// ID returned upon creating the verify.
var id = 'd015e66b7f1f459892130425aabb99e1';

messagebird.verify.delete(id, function (err, response) {
  if (err) {
    return console.log(err);
  }
  console.log(response);
});`,
};

export const DELETEVERIFY_RESPONSE = {
  bash: `[empty]`,
};
