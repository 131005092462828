import React from 'react';
import theme from '@messagebird/theme';
import { Flex } from '@rebass/grid';
import styled from 'styled-components';
import { Footer } from './Footer';

export const HomePageFooter = () => {
  return (
    <Flex
      width={1}
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
    >
      <FooterHeading>
        Trusted by over 450,000 developers and Fortune 500 companies
      </FooterHeading>

      <Flex
        width={[1, 1 / 2, 1 / 2]}
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <FooterLogo alt="Facebook" src="/img/logos/facebook.svg" />
        <FooterLogo alt="Uber" src="/img/logos/uber.svg" />
        <FooterLogo alt="Deliveroo" src="/img/logos/deliveroo.svg" />
        <FooterLogo alt="SAP" src="/img/logos/sap.svg" />
      </Flex>
      <Footer />
    </Flex>
  );
};

const FooterHeading = styled.h1`
  font-size: ${theme.fontSizes[6]}px;
  margin-bottom: ${theme.spacing(4)};
  font-weight: 600;
  color: ${theme.colors.primary.gray[70]};
  text-align: center;
`;

const FooterLogo = styled.img`
  flex: 1;
  padding: ${theme.spacing(1)};
`;
