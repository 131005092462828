import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Implementing SMS two-factor authentication (2FA) with MessageBird`}</h1>
    <h3>{`⏱ 15 min build time      ||      `}<a parentName="h3" {...{
        "href": "https://github.com/messagebirdguides/verify-guide-csharp"
      }}>{`Download the Code`}</a></h3>
    <h2>{`Why build two-factor authentication?`}</h2>
    <p>{`In this MessageBird Developer Tutorial you’ll learn how to improve your security building an SMS-based two factor authentication solution with the `}<a parentName="p" {...{
        "href": "/api/verify"
      }}>{`MessageBird Verify API`}</a>{`. The runnable application we’ll build is a prototype in C# for our fictitious online banking application, `}<em parentName="p">{`BirdBank`}</em>{`.`}</p>
    <p>{`Enterprises are increasingly challenged to keep sensitive information from falling into the wrong hands. This means that we can no longer trust old online authentication systems that rely solely on usernames and passwords, especially as security breaches grow in frequency, severity and sophistication.`}</p>
    <p>{`With the `}<a parentName="p" {...{
        "href": "/api/verify"
      }}>{`MessageBird Verify API`}</a>{`, you can implement two factor authentication (2FA) solutions to provide an additional layer of account security by verifying the user's password with a second authentication token and in turn, secure customer data, block fraudulent accounts, and safeguard key transactions in a matter of minutes. The most common use case involves the application of one-time passwords (OTP) generated by hardware tokens,  authenticator apps or directly sent to the user's mobile phone via SMS messaging.`}</p>
    <p>{`We'll walk you through the following steps:`}</p>
    <ul>
      <li parentName="ul">{`Asking for the phone number`}</li>
      <li parentName="ul">{`Sending a verification code`}</li>
      <li parentName="ul">{`Verifying the code`}</li>
    </ul>
    <p><strong parentName="p">{`Pro-tip:`}</strong>{` Follow this tutorial to build the whole application from scratch or, if you want to see it in action right away, you can download, clone or fork the sample application from our `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/verify-guide-csharp"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`.`}</p>
    <h2>{`Getting started`}</h2>
    <p>{`We'll build the sample web application using Microsoft Visual Studio. To follow this tutorial and run the sample application, we'll need to have Visual Studio (2012 or later) installed. Please visit the `}<a parentName="p" {...{
        "href": "https://visualstudio.microsoft.com/vs/compare/"
      }}>{`official web page`}</a>{` to find out more about the latest editions available. Keep in mind that you'll need to install the Visual Studio edition that matches your usage scenario (you can find it under `}<em parentName="p">{`Supported Usage Scenarios`}</em>{`).`}</p>
    <p>{`Now, it’s time to download, clone or fork the sample web application from the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/verify-guide-csharp"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`. Once you've done it, let's open the solution file `}<inlineCode parentName="p">{`verify-guide-csharp.sln`}</inlineCode>{` in Visual Studio.`}</p>
    <p>{`The solution file contains the `}<em parentName="p">{`verify-guide-csharp-project`}</em>{`, which is where we've stored our application source files. The application is written using ASP.NET Web Forms, a simple ASP.NET framework. We use Web Forms to add some structure to the code and keep the web page presentation separate from processing logic. Later we’ll explore the application source files.`}</p>
    <p>{`But first, let's install the libraries (packages) that are needed to get our application working. On the main menu in Visual Studio, click `}<em parentName="p">{`Tools | NuGet Package Manager | Package Manager Console`}</em>{`. The Package Manager Console will open and you’ll see a command prompt; type each line below one-by-one and press `}<em parentName="p">{`ENTER`}</em>{` after each line:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`Install-Package Microsoft.Net.Compilers -ProjectName verify-guide-csharp-project -Version 2.8.2
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`Install-Package Microsoft.CodeDom.Providers.DotNetCompilerPlatform -ProjectName verify-guide-csharp-project -Version 2.0.0
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`Install-Package MessageBird -ProjectName verify-guide-csharp-project -Version 1.3.5.1
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`Install-Package Newtonsoft.Json -ProjectName verify-guide-csharp-project -Version 11.0.2
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`Install-Package DotNetEnv -ProjectName verify-guide-csharp-project -Version 1.2.0
`}</code></pre>
    <p>{`Awesome! The five packages are now installed. 🤓`}</p>
    <p>{`The Microsoft packages are low-level packages that are needed to use ASP.NET. The MessageBird package contains the Verify APIs. The DotNetEnv package will allow us to retrieve your API key as an environment variable.`}</p>
    <p>{`We won't need to use the Package Manager Console window, so let's go ahead and close it.`}</p>
    <p>{`Now, open the Solution Explorer. We’ll use it to view our project files. You should see the Solution Explorer open on the right—if not, go to `}<em parentName="p">{`View | Solution Explorer`}</em>{` on the main menu in Visual Studio.`}</p>
    <h2>{`Creating the master page`}</h2>
    <p>{`Our sample application contains three web pages. To avoid coding the same header and footer HTML tags into each page, we've created a `}<inlineCode parentName="p">{`MasterPage.master`}</inlineCode>{` file that specifies these tags. In this file, you’ll see these tags above and below the `}<inlineCode parentName="p">{`ContentPlaceHolder`}</inlineCode>{` which has… you guessed right! The unique content that’s rendered on each page.`}</p>
    <h2>{`Asking for the phone number`}</h2>
    <p>{`The first step in verifying a user's phone number is asking them to provide their phone number. In `}<inlineCode parentName="p">{`Step1.aspx`}</inlineCode>{` you'll find a basic HTML form with a phone number input text box and a button:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-csharp"
      }}>{`<%@ Page Language="C#" AutoEventWireup="true" CodeFile="Step1.aspx.cs" Inherits="Step1" %>

<asp:Label id="ErrorLabel" runat="server"></asp:Label>
<p>Please enter your phone number (in international format, starting with +) to receive a verification code:</p>
<form id="Step1Form" runat="server">
   <asp:TextBox id="PhoneNumberTextBox" runat="server"></asp:TextBox>
   <asp:Button ID="SendCodeButton" runat="server" Text="Send code"
       OnClick="SendCodeButton_Click"/>
</form>
`}</code></pre>
    <p>{`When our user clicks on the button, the `}<inlineCode parentName="p">{`SendCodeButton_Click`}</inlineCode>{` method runs on the code behind page. This method is shown below in `}<inlineCode parentName="p">{`Step1.aspx.cs`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-csharp"
      }}>{`// Using statements not included (for brevity)
public partial class Step1 : System.Web.UI.Page
{
   protected void Page_Load(object sender, EventArgs e)
   {
       // Show an error if this page was previously called and CreateVerify generated an error
       if (Session["Error"] != null)
           ErrorLabel.Text = Session["Error"].ToString();
   }

   protected void SendCodeButton_Click(object sender, EventArgs e)
   {
       // Check if phone number is empty
       if (PhoneNumberTextBox.Text.Length == 0)
       {
           Session["Error"] = "Phone number cannot be empty.";
           Server.Transfer("Step1.aspx");
           return;
       }

       // Load the API key using the DotNetEnv library
       DotNetEnv.Env.Load(System.AppDomain.CurrentDomain.BaseDirectory + "/APIKey.env");
       String YourAccessKey = System.Environment.GetEnvironmentVariable("MESSAGEBIRD_API_KEY");

       // Initialize the MessageBird API
       MessageBird.Client client = MessageBird.Client.CreateDefault(YourAccessKey);

       Session["client"] = client;

       // Call CreateVerify to send a verification code to the user's phone. Here, we are sending "null" as the second
       // input. This input specifies optional settings, such as the timeout. Since we are sending "null", the default
       // timeout is used, which is 30 seconds.
       try
       {
           Verify verify = client.CreateVerify(PhoneNumberTextBox.Text, null);
           Session["VerifyId"] = verify.Id;
       }
       catch (ErrorException errorException)
       {
           Session["Error"] = ErrorGenerator.Generate(errorException);
           Server.Transfer("Step1.aspx");
       }
       Server.Transfer("Step2.aspx");
   }
}
`}</code></pre>
    <h1>{`Checking for an empty phone number`}</h1>
    <p>{`We check right away if the length of the phone number the user entered is 0. If it is, the phone number is empty and the user will be prompted to re-enter it; in this case, the page calls itself via `}<inlineCode parentName="p">{`Server.Transfer`}</inlineCode>{`.`}</p>
    <h1>{`Retrieving the API Key  🔑`}</h1>
    <p>{`If the phone number is populated correctly, the application will retrieve your API key as an environment variable. This is done via the DotNetEnv library. Rather than hardcoding the API key directly in `}<inlineCode parentName="p">{`Step1.aspx.cs`}</inlineCode>{`, we've created a separate `}<inlineCode parentName="p">{`APIKey.env`}</inlineCode>{` file to store the key. You shouldn't hardcode API keys in any files that you’ll be storing in a version control system, such as Git and Team Foundation Version Control (TFVC). So if you were to store this sample application in a version control system, you wouldn't add `}<inlineCode parentName="p">{`APIKey.env`}</inlineCode>{`.`}</p>
    <p>{`To create the file, let’s go to the Solution Explorer and right-click "verify-csharp-project", click `}<em parentName="p">{`Add | Add New Item…`}</em>{` For the name field, specify `}<inlineCode parentName="p">{`APIKey.env`}</inlineCode>{` and click `}<em parentName="p">{`Add`}</em>{`. With file now open, enter the line:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`MESSAGEBIRD_API_KEY=YOUR-API-KEY
`}</code></pre>
    <p>{`Replace `}<inlineCode parentName="p">{`YOUR-API-KEY`}</inlineCode>{` with your API key. Keys can be created or retrieved from the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`API access (REST) tab`}</a>{` in the `}<em parentName="p">{`Developers section`}</em>{` of your MessageBird Dashboard.`}</p>
    <p>{`Switching back to `}<inlineCode parentName="p">{`Step1.aspx.cs`}</inlineCode>{`, let’s initialize the MessageBird client API via the `}<inlineCode parentName="p">{`CreateDefault`}</inlineCode>{` method and pass in your API key.`}</p>
    <p>{`Next, let’s store a reference to the MessageBird client in an ASP.NET session variable. We'll use this this variable later.`}</p>
    <h2>{`Sending a verification code`}</h2>
    <p>{`Continuing with our walk-through of `}<inlineCode parentName="p">{`Step1.aspx.cs`}</inlineCode>{`, we call the `}<inlineCode parentName="p">{`Verify`}</inlineCode>{` method, which sends a verification code to the phone number that the user entered in the form. The MessageBird Verify API takes care of generating a random code, so you don't have to do this yourself. Codes are numeric and six digits by default; if you want to customize the length of the code or configure other options, you can check out our `}<a parentName="p" {...{
        "href": "/api/verify#verify-request"
      }}>{`Verify API documentation`}</a>{`.`}</p>
    <p>{`If all is well, the page `}<inlineCode parentName="p">{`Step2.aspx`}</inlineCode>{` is rendered. The details of this are explained in the `}<strong parentName="p">{`Verifying the Code`}</strong>{` section below.`}</p>
    <p>{`But, if there's an error while `}<inlineCode parentName="p">{`Verify`}</inlineCode>{` executes, an `}<inlineCode parentName="p">{`ErrorException`}</inlineCode>{` will be thrown. We handle the exception by invoking the `}<inlineCode parentName="p">{`Generate`}</inlineCode>{` method of the `}<inlineCode parentName="p">{`ErrorGenerator`}</inlineCode>{` class. This class is stored in the `}<inlineCode parentName="p">{`ErrorGenerator.cs`}</inlineCode>{` file in the `}<inlineCode parentName="p">{`App_Code`}</inlineCode>{` folder. This `}<inlineCode parentName="p">{`Generate`}</inlineCode>{` method builds the error string, which is then stored in a `}<inlineCode parentName="p">{`Session`}</inlineCode>{` variable. Then, the page calls itself via `}<inlineCode parentName="p">{`Server.Transfer`}</inlineCode>{`. The `}<inlineCode parentName="p">{`Page_Load`}</inlineCode>{` method detects that there’s an error, so it populates the `}<inlineCode parentName="p">{`ErrorLabel`}</inlineCode>{` on the form in `}<inlineCode parentName="p">{`Step1.aspx`}</inlineCode>{`, which displays the error to the user. Finally, the user is prompted to enter their phone number again.`}</p>
    <h2>{`Verifying the code`}</h2>
    <p>{`Once the code is delivered, our user will check their phone and enter the code into the textbox in our form in `}<inlineCode parentName="p">{`Step2.aspx`}</inlineCode>{` below.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-csharp"
      }}>{`<%@ Page Language="C#" AutoEventWireup="true" CodeFile="Step2.aspx.cs" Inherits="Step2" %>

<asp:Label id="ErrorLabel" runat="server"></asp:Label>
<p>We have sent you a verification code!</p>
<p>Please enter the code here:</p>
<form id="Step2Form" runat="server">
   <asp:TextBox id="CodeTextBox" runat="server"></asp:TextBox>
   <asp:Button ID="CheckCodeButton" runat="server" Text="Check code"
       OnClick="CheckCodeButton_Click"/>
</form>
`}</code></pre>
    <p>{`When the user clicks the button, the `}<inlineCode parentName="p">{`CheckCodeButton_Click`}</inlineCode>{` method runs on the code behind page `}<inlineCode parentName="p">{`Step2.aspx.cs`}</inlineCode>{`. Keep in mind that the terms "code" and "token" used here mean the same thing.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-csharp"
      }}>{`// Using statements not included (for brevity)
public partial class Step2 : System.Web.UI.Page
{
   protected void Page_Load(object sender, EventArgs e)
   {
       // Show an error if this page was previously called and SendVerifyToken generated an error
       if (Session["Error"] != null)
           ErrorLabel.Text = Session["Error"].ToString();
   }

   protected void CheckCodeButton_Click(object sender, EventArgs e)
   {
       // Check if code is empty
       if (CodeTextBox.Text.Length == 0)
       {
           Session["Error"] = "Verification code cannot be empty.";
           Server.Transfer("Step2.aspx");
           return;
       }

       // Call SendVerifyToken to verify the code entered in CodeTextBox.Text matches the code
       // sent to the user in the CreateVerify call in Step1.aspx.cs. Here, the words "token" and "code"
       // mean the same thing.
       try
       {
           Client client = ((Client)Session["Client"]);
           client.SendVerifyToken(Session["VerifyId"].ToString(), CodeTextBox.Text);
       }
       catch (ErrorException errorException)
       {
           Session["Error"] = ErrorGenerator.Generate(errorException);
           Server.Transfer("Step2.aspx");
       }
       Server.Transfer("Step3.aspx");
   }
}
`}</code></pre>
    <p>{`We check right away if the length of the phone number the user entered is 0. If it is, the phone number is empty and the user will be prompted to re-enter it; in this case, the page calls itself via `}<inlineCode parentName="p">{`Server.Transfer`}</inlineCode>{`.`}</p>
    <p>{`If the code is populated, we call the `}<inlineCode parentName="p">{`SendVerifyToken`}</inlineCode>{` method with two inputs: the code just entered by the user, and the VerifyId that was generated in `}<inlineCode parentName="p">{`Step1.aspx.cs`}</inlineCode>{` as a result of calling the `}<inlineCode parentName="p">{`Verify`}</inlineCode>{` method. Keep in mind that this VerifyId is stored in the `}<inlineCode parentName="p">{`Session`}</inlineCode>{` variable.`}</p>
    <p><inlineCode parentName="p">{`SendVerifyToken`}</inlineCode>{` verifies the code entered by the user and matches it with the code sent to the user in the `}<inlineCode parentName="p">{`CreateVerify`}</inlineCode>{` call in `}<inlineCode parentName="p">{`Step1.aspx.cs`}</inlineCode>{`.`}</p>
    <p>{`As before, there's a catch-block to handle errors such as an invalid token entered by the user. If there is an error, we show the same form again with the error.`}</p>
    <p>{`If the verification was successful, we render a simple confirmation page in `}<inlineCode parentName="p">{`Step3.aspx`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-csharp"
      }}>{`<%@ Page Language="C#" AutoEventWireup="true" CodeFile="Step3.aspx.cs" Inherits="Step3" %>

<p>You have successfully verified your phone number.</p>
`}</code></pre>
    <h2>{`Testing`}</h2>
    <p>{`To test the application, click on `}<inlineCode parentName="p">{`Step1.aspx`}</inlineCode>{` in the Solution Explorer and on the green start button on the toolbar at the top.`}</p>
    <p>{`Awesome! You can now leverage the flow, code snippets and UI examples from this tutorial to build your own two factor authentication system. Don't forget to download the code from the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/verify-guide-csharp"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`.`}</p>
    <p><strong parentName="p">{`Nice work!`}</strong>{` 🎉`}</p>
    <p>{`You now have a running integration of MessageBird's Verify API using C#!`}</p>
    <h2>{`Start building!`}</h2>
    <p>{`Want to build something similar but not quite sure how to get started? Please feel free to let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`;  we'd love to help!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      