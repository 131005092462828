export const API_ENDPOINT = {
  bash: `https://rest.messagebird.com/messages/batches`,
};

export const HTTP_METHODS = {
  bash: `POST /messages/batches`,
};

export const SEND_DEF = {
  bash: `POST https://rest.messagebird.com/messages/batches`,
};

export const SEND_EXAMPLE = {
  curl: `
    curl -X POST https://rest.messagebird.com/messages/batches \\
      -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
      -H 'Content-Type: application/json' \\
      -d '
      {
        "messages": [
          {
            "recipients": ["31612345678"],
            "originator": "YourName",
            "body": "This is a test message"
          },
          {
            "recipients": ["31612345678"],
            "originator": "YourName",
            "body": "This is another test message"
          }
        ]
      }'
  `
};

export const SEND_RESPONSE = {
  curl: `
  [
    {
      "id":"e8077d803532c0b5937c639b60216938",
      "href":"https://rest.messagebird.com/messages/e8077d803532c0b5937c639b60216938",
      "direction":"mt",
      "type":"sms",
      "originator":"YourName",
      "body":"This is a test message",
      "reference":null,
      "validity":null,
      "gateway":null,
      "typeDetails":{},
      "datacoding":"plain",
      "mclass":1,
      "scheduledDatetime":null,
      "createdDatetime":"2016-05-03T14:26:57+00:00",
      "recipients":{
        "totalCount":1,
        "totalSentCount":1,
        "totalDeliveredCount":0,
        "totalDeliveryFailedCount":0,
        "items":[
          {
            "recipient":31612345678,
            "status":"sent",
            "statusDatetime":"2016-05-03T14:26:57+00:00"
          }
        ]
      }
    },
    {
      "id":"f4486adbc0a44dc1b9cd558f7bbc0093",
      "href":"https://rest.messagebird.com/messages/f4486adbc0a44dc1b9cd558f7bbc0093",
      "direction":"mt",
      "type":"sms",
      "originator":"YourName",
      "body":"This is another test message",
      "reference":null,
      "validity":null,
      "gateway":null,
      "typeDetails":{},
      "datacoding":"plain",
      "mclass":1,
      "scheduledDatetime":null,
      "createdDatetime":"2016-05-03T14:26:57+00:00",
      "recipients":{
        "totalCount":1,
        "totalSentCount":1,
        "totalDeliveredCount":0,
        "totalDeliveryFailedCount":0,
        "items":[
          {
            "recipient":31612345678,
            "status":"sent",
            "statusDatetime":"2016-05-03T14:26:57+00:00"
          }
        ]
      }
    }
  ]`,
};
