import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/ApiRef.js";
import CodeSnippet from 'components/CodeSnippet';
import * as CODE from '../../constants/hlr';
import { APIWrapper, LeftColumn, RightColumn } from 'components/ApiRef';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`HLR API`}</h1>
    <p>{`MessageBird’s HLR API provides a way to send Network Queries to any mobile number globally. It allows you to view what mobile number (MSISDN) belongs to what operator in real-time and see if the number is active. `}</p>
    <p>{`The HLR API uses `}<inlineCode parentName="p">{`HTTP verbs`}</inlineCode>{` and a `}<inlineCode parentName="p">{`RESTful`}</inlineCode>{` endpoint structure with an access key that is used as the API Authorization framework. Request and response payloads are formatted as JSON—although we provide a GET alternative for requests—using UTF-8 encoding and URL encoded values.`}</p>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`API Endpoint`}</h2>
        <p>{`All URLs referenced in MessageBird's HLR Messaging API documentation have the base URL in the code snippet on the right side:`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>API Endpoint</h4>
  <CodeSnippet code={CODE.HLR_URI} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Available HTTP methods`}</h2>
        <p>{`The MessageBird API uses HTTP verbs to understand if you want to read (`}<inlineCode parentName="p">{`GET`}</inlineCode>{`), delete (`}<inlineCode parentName="p">{`DELETE`}</inlineCode>{`) or create (`}<inlineCode parentName="p">{`POST`}</inlineCode>{`) an object. When your web application cannot do a `}<inlineCode parentName="p">{`POST`}</inlineCode>{` or `}<inlineCode parentName="p">{`DELETE`}</inlineCode>{`, we provide the ability to set the method through the query parameter `}<inlineCode parentName="p">{`_method`}</inlineCode>{`.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Available HTTP methods</h4>
  <CodeSnippet code={CODE.HLR_METHODS} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`The HLR object`}</h2>
        <p>{`This object represents a HLR at MessageBird.com`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Example object</h4>
  <CodeSnippet code={CODE.HLR_OBJECT} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <h4>{`Attributes`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A unique random ID which is created on the MessageBird platform and is returned upon creation of the object.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`href`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The URL of the created object.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`msisdn`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`int`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The telephone number.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`network`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`int`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The `}<a parentName="td" {...{
              "href": "https://en.wikipedia.org/wiki/Mobile_country_code"
            }}>{`MCCMNC`}</a>{` code of the network provider.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`reference`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A client reference`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`details`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`hash`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A hash with extra HLR information. See table below for extra information.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`status`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The status of the msisdns. Possible values: `}<inlineCode parentName="td">{`sent`}</inlineCode>{`, `}<inlineCode parentName="td">{`absent`}</inlineCode>{`, `}<inlineCode parentName="td">{`active`}</inlineCode>{`, `}<inlineCode parentName="td">{`unknown`}</inlineCode>{`, and `}<inlineCode parentName="td">{`failed`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`createdDatetime`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The date and time of the creation of the message in RFC3339 format (Y-m-d\\TH:i:sP)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`statusDatetime`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The datum time of the last status in RFC3339 format (Y-m-d\\TH:i:sP)`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`The details array`}</h4>
    <p>{`Note that the attributes can change over time and some of them can be null because not all information is available for all countries and provider.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`status_desc`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Extended status information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`imsi`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://en.wikipedia.org/wiki/International_mobile_subscriber_identity"
            }}>{`IMSI (International Mobile Subscriber Identity)`}</a>{` of mobile number`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`country_iso`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Country ISO code of location of MSISDN`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`country_name`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Country name of location of MSISDN`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`location_msc`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://en.wikipedia.org/wiki/Network_switching_subsystem#Mobile_switching_center_.28MSC.29"
            }}>{`MSC (Mobile Switching Center)`}</a>{` of MSISDN`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`location_iso`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Country ISO of MSC (`}<a parentName="td" {...{
              "href": "https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2"
            }}>{`lowercase ISO 3166-1 alpha-2`}</a>{`)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ported`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Is 1 if the phone number is ported or 0 when the phone number is not ported or ported status is unknown`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`roaming`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Is 1 if the phone number is roaming or 0 when the phone number is not roaming or roaming status is unknown`}</td>
        </tr>
      </tbody>
    </table>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Request an HLR`}</h2>
        <p>{`Creates a new HLR object. MessageBird returns the created HLR object with each request.`}</p>
        <h4>{`Required parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Parameter`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`msisdn`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`int`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The telephone number that you want to do a network query on.`}</td>
            </tr>
          </tbody>
        </table>
        <h4>{`Optional parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Parameter`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`reference`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A client reference.`}</td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.REQUESTHLR_DEF} mdxType="CodeSnippet" />
  <h4>Example request</h4>
  <CodeSnippet code={CODE.REQUESTHLR_REQUEST} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Returns`}</h4>
        <p>{`Returns an HLR object if the request was successful. If the request failed, an `}<a parentName="p" {...{
            "href": "/api#api-errors"
          }}>{`error object`}</a>{` will be returned.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Example response</h4>
  <CodeSnippet code={CODE.REQUESTHLR_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`View an HLR`}</h2>
        <p>{`Retrieves the information of an existing HLR. You only need to supply the unique message id that was returned upon creation or receiving.`}</p>
        <h4>{`Required parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Parameter`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`id`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A unique random ID which is created on the MessageBird platform and is returned upon creation of the object. `}<strong parentName="td">{`Required`}</strong></td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.VIEWHLR_DEF} mdxType="CodeSnippet" />
  <h4>Example request</h4>
  <CodeSnippet code={CODE.VIEWHLR_REQUEST} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Returns`}</h4>
        <p>{`Returns an HLR object if the request was successful. If the request failed, an `}<a parentName="p" {...{
            "href": "/api#api-errors"
          }}>{`error object`}</a>{` will be returned.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Example response</h4>
  <CodeSnippet code={CODE.VIEWHLR_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Handle an HLR response`}</h2>
        <p>{`HLR responses are requests that are sent to your platform through a GET request. The requests hold information about the requested network query. You can configure your HLR callback url within the `}<a parentName="p" {...{
            "href": "https://www.messagebird.com/app/en/settings/developers/access"
          }}>{`API settings`}</a>{`.`}</p>
        <h4>{`Attributes`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Attribute`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`id`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A unique random ID which is created on the `}<inlineCode parentName="td">{`MessageBird`}</inlineCode>{` platform and is returned upon creation of the object.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`msisdn`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`int`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The telephone number.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`network`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`int`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The `}<a parentName="td" {...{
                  "href": "https://en.wikipedia.org/wiki/Mobile_country_code"
                }}>{`MCCMNC`}</a>{` code of the network provider.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`reference`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A client reference`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`status`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The status of the msisdn. Possible values: `}<inlineCode parentName="td">{`sent`}</inlineCode>{`, `}<inlineCode parentName="td">{`absent`}</inlineCode>{`, `}<inlineCode parentName="td">{`active`}</inlineCode>{`, `}<inlineCode parentName="td">{`unknown`}</inlineCode>{`, and `}<inlineCode parentName="td">{`failed`}</inlineCode></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`createdDatetime`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`datetime`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The date and time of the creation of the message in RFC3339 format (Y-m-d\\TH:i:sP)`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`statusDatetime`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`datetime`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The datum time of the last status in RFC3339 format (Y-m-d\\TH:i:sP)`}</td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Example request</h4>
  <CodeSnippet code={CODE.HANDLEHLR_REQUEST} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Response`}</h4>
        <p>{`Your platform should respond with a `}<inlineCode parentName="p">{`200 OK`}</inlineCode>{` HTTP header. When our platform doesn't receive a `}<inlineCode parentName="p">{`200 OK`}</inlineCode>{` header, we will try to deliver the receipt again (up to 10 times).`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Example response</h4>
  <CodeSnippet code={CODE.HANDLEHLR_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      