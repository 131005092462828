import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import Notification from 'components/Notification';
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`View an HLR`}</h1>
    <p>{`In this Lookup API Quickstart, we'll show you how to programmatically view an HLR via the Lookup API.`}</p>
    <h2>{`Step 1: Request an HLR`}</h2>
    <p>{`Did you complete the `}<a parentName="p" {...{
        "href": "/docs/lookup/request-an-hlr"
      }}>{`Lookup Quickstart: Request an HLR`}</a>{`?`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`No!`}</strong>{` It's a prerequisite, so make sure to do so before continuing.`}</li>
      <li parentName="ul"><strong parentName="li">{`Yes!`}</strong>{` Great, go to step 2.`}</li>
    </ul>
    <h3>{`Step 2: View your first HLR`}</h3>
    <p>{`Next, enter `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`your credentials`}</a>{` and mobile number to the below snippet and copy it to your terminal. Now, hit enter!`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-curl"
      }}>{`curl -X GET https://rest.messagebird.com/lookup/31XXXXXXXXX/hlr \\
       -H 'Authorization: AccessKey YOUR-API-KEY'
`}</code></pre>
    <Notification type="INFO" mdxType="Notification">
You can access and manage your credentials in the <a href="https://dashboard.messagebird.com/en/developers/access">MessageBird Developer Dashboard</a>.
    </Notification>
    <h2>{`Step 3: View the response`}</h2>
    <p>{`Now, let’s view MessageBird’s HTTP response to your API call. If the set up was correct, you will receive a response similar to this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "id":"<ID>",
  "href":"https:\\/\\/rest.messagebird.com\\/hlr\\/<ID>",
  "msisdn":31XXXXXXXXXX,
  "network":20406,
  "reference":"YourReference",
  "details": {
    "status_desc": null,
    "imsi": "204080010948431",
    "country_iso": "NLD",
    "country_name": "Netherlands",
    "location_msc": "316530",
    "location_iso": 'nl',
    "ported": 0,
    "roaming": 0
  },
  "status":"active",
  "createdDatetime":"2016-05-04T07:32:46+00:00",
  "statusDatetime":"2016-05-04T07:32:46+00:00"
}
`}</code></pre>
    <p>{`If something went wrong, you’ll receive an error response from the MessageBird API. Don't worry, head over to `}<a parentName="p" {...{
        "href": "/docs/lookup/troubleshooting"
      }}>{`Troubleshooting Lookup`}</a>{` to understand the error.`}</p>
    <p>{`Congrats! You can now programmatically view an HLR with MessageBird's Lookup API using cURL.`}</p>
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'Request a lookup',
      to: '/quickstarts/lookup/request-a-lookup'
    }, {
      name: 'Troubleshooting Lookup',
      to: '/quickstarts/lookup/troubleshooting'
    }, {
      name: 'Lookup API Reference',
      to: '/api/lookup'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      