import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Docs.tsx";
import Notification from 'components/Notification';
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Getting Started with the MessageBird WhatsApp Sandbox`}</h1>
    <p>{`The MessageBird WhatsApp Sandbox allows you to try and prototype WhatsApp Business in a developer environment without going through the approval process. In this quickstart, you’ll learn how to get started with MessageBird’s WhatsApp Sandbox in 6 easy steps.`}</p>
    <h2>{`Step 1: Activate the sandbox`}</h2>
    <p>{`Go to your ‘Channels’ in the MessageBird Dashboard, and click on ‘WhatsApp Sandbox’. Activate the sandbox by agreeing to the WhatsApp Business Solution Terms.`}</p>
    <h2>{`Step 2: Connect your device to the sandbox`}</h2>
    <p>{`You’ll now need to connect your device to the sandbox. You can it in two ways:`}</p>
    <p>{`Scan the QR Code: Open your phone camera and focus it on the QR Code, click on the push notification, you have a prefilled message with the word “join” followed by a two-word entry code, send the message, and 🎉 you're connected!
Two-word entry code: Save the WhatsApp Sandbox number as a new contact in your phone, now go to WhatsApp and send your unique two-word entry code to this number, and 🎉 you're connected!
Message received! Your device is now connected to the MessageBird’s WhatsApp Sandbox.
The ‘Waiting for Your Message’ box now reads Congrats! You're now connected to the sandbox. This means you're ready to start messaging. `}</p>
    <Notification type="INFO" mdxType="Notification">
   For now, the WhatsApp Sandbox can only be active in one window at a time and only one user per Workspace can connect simultaneously.  To successfully connect to the sandbox, make sure to close any additional active windows. If another user is already connected to the workspace, simply switch to another workspace. 
    </Notification>
    <p><strong parentName="p">{`Pro-tip`}</strong>{`: Want to leave this Sandbox and join another? Simply reply `}<inlineCode parentName="p">{`Stop`}</inlineCode>{` at any time to the WhatsApp Sandbox. `}</p>
    <p>{`Let's keep moving. Hit ‘Next: Send an outbound message.’`}</p>
    <h2>{`Step 3: Choose a WhatsApp Message Template`}</h2>
    <p>{`It’s time to choose a WhatsApp Message Template. The displayed templates have already been submitted approved by WhatsApp, so you can start using them straight away.`}</p>
    <p>{`You have three Message Template options:`}</p>
    <p>{`Customer Support
User Verification (2FA)
Notification
Check out some more examples of approved use cases for the WhatsApp Business solution.`}</p>
    <p>{`Go to our Message Templates quickstart to learn more about it. `}</p>
    <h2>{`Step 4: Send a message from the sandbox`}</h2>
    <p>{`In this step, the sandbox is replicating a business-initiated conversation via the WhatsApp API using Message Templates.`}</p>
    <p>{`We've chosen some custom variables for each Message Template. Feel free to play around with the custom variables using the text boxes.`}</p>
    <p>{`You can go back and forth step 3 and step 4 as many times as you want. Ready to go? Click ‘Send a message’ and tada 🎉 your WhatsApp Message Template with custom variables will be sent to your device.`}</p>
    <p>{`You can also use the code snippet on the right side of the sandbox to send a message from your project. You'll see an example response in the field below.`}</p>
    <p>{` Easy, right? Let's start a conversation by sending a reply from your device.`}</p>
    <p><strong parentName="p">{`Pro-tip`}</strong>{`: The dynamic API request automatically updates depending on the custom variables that you set, so you can use the code snippets from the sandbox to start building while waiting for the WhatsApp approval. `}</p>
    <h2>{`Step 5: Send a reply from the device`}</h2>
    <p>{`In this step, the sandbox is replicating a customer-initiated two-way conversation via the WhatsApp API.`}</p>
    <p>{`You can now write a response from your device to the sandbox number. This will enable a 24-hour conversation session, during which you can freely chat with yourself—to and from the sandbox. You can send as many replies as you want—the reply will automatically update.`}</p>
    <p>{`Learn more about the 24-hour window.`}</p>
    <p>{`Once you send a reply to the sandbox, the ‘Waiting for a message’ field will update with your message. Awesome, your 24-hour session is enabled. You're ready to continue the two-way conversation with the sandbox.`}</p>
    <h2>{`Step 6: Send another reply from the sandbox`}</h2>
    <p>{`This time you're not limited to a pre-approved WhatsApp Message Template because you're still in the 24-hour two-way conversation window. You can reply to the responses and the other way around as much as you wish.`}</p>
    <p>{`Good job! 🎉 You've completed the MessageBird’s WhatsApp Sandbox Quickstart.`}</p>
    <p><a parentName="p" {...{
        "href": "https://messagebird.com/en/whatsapp/"
      }}>{`Request access`}</a>{` to the WhatsApp Business API.`}</p>
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'Getting started with WhatsApp Business',
      to: '/docs/conversations/whatsapp/getting-started'
    }, {
      name: 'Install Channel: WhatsApp Business',
      to: '/docs/conversations/install-channel-whatsapp'
    }, {
      name: 'Programmable Conversations API Reference',
      to: '/api/conversations'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      