import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/ApiRef.js";
import CodeSnippet from 'components/CodeSnippet';
import * as CODE from '../../constants/lookup';
import { APIWrapper, LeftColumn, RightColumn } from 'components/ApiRef';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Lookup API`}</h1>
    <p>{`MessageBird's Lookup API enables you to validate and look up a mobile number. You supply our API with the phone numbers you’d like to check, our API performs an HLR Lookup on the mobile network and identifies the number format, and we fill in the number data, along with availability and provider. The Lookup API will help you keep your database clean. `}</p>
    <p>{`A phone number validation will tell you if the specified phone number looks valid. It will also include some alternative formats to display the phone number in. An HLR lookup allows you to view which mobile number (MSISDN) belongs to what operator in real-time and see whether the number is active.  `}</p>
    <p>{`The Lookup API uses `}<inlineCode parentName="p">{`HTTP verbs`}</inlineCode>{` and a `}<inlineCode parentName="p">{`RESTful`}</inlineCode>{` endpoint structure with an access key that is used as the API Authorization framework. Request and response payloads are formatted as JSON—although we provide a GET alternative for requests—using UTF-8 encoding and URL encoded values.`}</p>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`API Endpoint`}</h2>
        <p>{`All URLs referenced in MessageBird's Lookup API documentation have the base URL in the code snippet on the right side:`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>API Endpoint</h4>
  <CodeSnippet code={CODE.LOOKUP_URI} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Available HTTP methods`}</h2>
        <p>{`The MessageBird API uses HTTP verbs to understand if you want to read (`}<inlineCode parentName="p">{`GET`}</inlineCode>{`), delete (`}<inlineCode parentName="p">{`DELETE`}</inlineCode>{`) or create (`}<inlineCode parentName="p">{`POST`}</inlineCode>{`) an object. When your web application cannot do a `}<inlineCode parentName="p">{`POST`}</inlineCode>{` or `}<inlineCode parentName="p">{`DELETE`}</inlineCode>{`, we provide the ability to set the method through the query parameter `}<inlineCode parentName="p">{`_method`}</inlineCode>{`.`}</p>
        <hr></hr>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Available HTTP Methods</h4>
  <CodeSnippet code={CODE.LOOKUP_METHODS} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <h2>{`The Lookup object`}</h2>
    <p>{`This object represents a Lookup at MessageBird.com`}</p>
    <h4>{`Attributes`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`href`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The URL of this lookup.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`countryCode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The country code for this number in ISO 3166-1 alpha-2 format.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`countryPrefix`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The country calling code for this number.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`phoneNumber`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The phone number in E.164 format without the prefixed plus-sign.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The type of number. This can be `}<inlineCode parentName="td">{`fixed line`}</inlineCode>{`, `}<inlineCode parentName="td">{`mobile`}</inlineCode>{`, `}<inlineCode parentName="td">{`fixed line or mobile`}</inlineCode>{`, `}<inlineCode parentName="td">{`toll free`}</inlineCode>{`, `}<inlineCode parentName="td">{`premium rate`}</inlineCode>{`, `}<inlineCode parentName="td">{`shared cost`}</inlineCode>{`, `}<inlineCode parentName="td">{`voip`}</inlineCode>{`, `}<inlineCode parentName="td">{`personal number`}</inlineCode>{`, `}<inlineCode parentName="td">{`pager`}</inlineCode>{`, `}<inlineCode parentName="td">{`universal access number`}</inlineCode>{`, `}<inlineCode parentName="td">{`voice mail`}</inlineCode>{` or `}<inlineCode parentName="td">{`unknown`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`formats`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`hash`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A hash containing references to this phone number in several different formats.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`formats.e164`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The phone number in E.164 format.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`formats.international`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The phone number in international format.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`format.national`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The phone number in national/local format.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`format.rfc3966`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The phone number in RFC3966 format.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`hlr`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`hash`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The most recent HLR object. If no such HLR objects exists, this hash won't be returned.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`hlr.id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A unique random ID which is created on the MessageBird platform.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`hlr.network`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`int`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The `}<a parentName="td" {...{
              "href": "https://en.wikipedia.org/wiki/Mobile_country_code"
            }}>{`MCCMNC`}</a>{` code of the network provider.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`hlr.reference`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A client reference.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`hlr.status`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The status of the HLR request. Possible values: `}<inlineCode parentName="td">{`sent`}</inlineCode>{`, `}<inlineCode parentName="td">{`absent`}</inlineCode>{`, `}<inlineCode parentName="td">{`active`}</inlineCode>{`, `}<inlineCode parentName="td">{`unknown`}</inlineCode>{`, and `}<inlineCode parentName="td">{`failed`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`hlr.createdDatetime`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The date and time of the creation of the message in RFC3339 format (Y-m-d\\TH:i:sP).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`hlr.statusDatetime`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The datum time of the last status in RFC3339 format (Y-m-d\\TH:i:sP).`}</td>
        </tr>
      </tbody>
    </table>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Request a lookup`}</h2>
        <p>{`Performs a new lookup for the specified number. MessageBird returns a Lookup object with each request.`}</p>
        <h4>{`Required parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Parameter`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`phoneNumber`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`int`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The telephone number that you want to look up.`}</td>
            </tr>
          </tbody>
        </table>
        <h4>{`Optional parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Parameter`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`countryCode`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`If the phoneNumber parameter is in a national format, this parameter is required to figure out the international format.`}</td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.REQUESTLOOKUP_DEF} mdxType="CodeSnippet" />
  <h4>Example request</h4>
  <CodeSnippet code={CODE.REQUESTLOOKUP_REQUEST} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Response`}</h4>
        <p>{`Returns a Lookup object if the request was successful. If the request failed, an `}<a parentName="p" {...{
            "href": "/api#api-errors"
          }}>{`error object`}</a>{` will be returned.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Example response</h4>
  <CodeSnippet code={CODE.REQUESTLOOKUP_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <hr></hr>
        <h2>{`Request an HLR`}</h2>
        <p>{`Creates a new HLR object. MessageBird returns the created HLR object with each request.`}</p>
        <h4>{`Required parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Parameter`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`phoneNumber`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`int`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The telephone number that you want to do a network query on.`}</td>
            </tr>
          </tbody>
        </table>
        <h4>{`Optional parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Parameter`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`reference`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A client reference.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`countryCode`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`If the `}<inlineCode parentName="td">{`phoneNumber`}</inlineCode>{` parameter is in a national format, this parameter is required to figure out the international format.`}</td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.REQUESTHLR_DEF} mdxType="CodeSnippet" />
  <h4>Example request</h4>
  <CodeSnippet code={CODE.REQUESTHLR_REQUEST} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Response`}</h4>
        <p>{`Returns an HLR object if the request was successful. If the request failed, an `}<a parentName="p" {...{
            "href": "/api#api-errors"
          }}>{`error object`}</a>{` will be returned.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Example response</h4>
  <CodeSnippet code={CODE.REQUESTHLR_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <hr></hr>
        <h2>{`View an HLR`}</h2>
        <p>{`Retrieves the information of an existing HLR. You only need to supply the unique message id that was returned upon creation or receiving.`}</p>
        <h4>{`Required parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Parameter`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`phoneNumber`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`int`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The telephone number that you want to do a network query on.`}</td>
            </tr>
          </tbody>
        </table>
        <h4>{`Optional parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Parameter`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`countryCode`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`If the `}<inlineCode parentName="td">{`phoneNumber`}</inlineCode>{` parameter is in a national format, this parameter is required to figure out the international format.`}</td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.VIEWHLR_DEF} mdxType="CodeSnippet" />
  <h4>Example request</h4>
  <CodeSnippet code={CODE.VIEWHLR_REQUEST} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Returns`}</h4>
        <p>{`Returns an HLR object if the request was successful. If the request failed, an `}<a parentName="p" {...{
            "href": "/api#api-errors"
          }}>{`error object`}</a>{` will be returned.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Example response</h4>
  <CodeSnippet code={CODE.VIEWHLR_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <h2>{`Handle an HLR response`}</h2>
    <p>{`HLR responses from requests sent via the `}<inlineCode parentName="p">{`/lookup`}</inlineCode>{` URL are handled `}<a parentName="p" {...{
        "href": "/api/hlr#handle-an-hlr-response"
      }}>{`exactly the same as HLR responses`}</a>{` sent via the `}<inlineCode parentName="p">{`/hlr`}</inlineCode>{` URL.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      