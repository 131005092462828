import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Recording Voice Calls with MessageBird`}</h1>
    <p>{`Our Voice API provides an easy way to record the audio of a call. The recording can be enabled for both legs of the call as well as only for inbound or outbound legs. In this MessageBird Tutorial we’ll show you how to create a call flow that transfers a call to a number with call recording enabled.`}</p>
    <h2>{`Step 1: Create the call flow`}</h2>
    <p>{`In order to setup a call transfer to a new number, you will need to post a call flow to the Voice API with the appropriate transfer step.`}</p>
    <p>{`The endpoint for creating call flows via the Voice API is:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`POST https://voice.messagebird.com/call-flows
`}</code></pre>
    <p>{`You can post a call flow with a transfer step that has call recording enabled on both legs like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "steps": [
    {
      "action": "transfer",
      "options": {
            "destination":"31123456789",
            "record":"both"
      }
    }
  ]
}
`}</code></pre>
    <p>{`Here’s an example with cURL:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`curl -X "POST" "http://voice.messagebird.com/call-flows" \\
     -H "Authorization: AccessKey :your_access_key:" \\
     -H "Content-Type: application/json; charset=utf-8" \\
     -d $'{
  "steps": [
    {
      "action": "transfer",
      "options": {
        "destination":"31123456789",
        "record":"both"
      }
    }
  ]
}'
`}</code></pre>
    <p>{`A transfer step will be executed in this example. You can add more options to your transfer action as described in the ‘`}<a parentName="p" {...{
        "href": "/api/voice-calling#call-flows"
      }}>{`Call flows’ section`}</a>{` of our Voice Calling API documentation.`}</p>
    <p>{`You can also specify other options for the record option such as `}<em parentName="p">{`in, out`}</em>{` and `}<em parentName="p">{`both`}</em>{`. These options are related to recording the inbound/outbound voice on the destination:`}</p>
    <ul>
      <li parentName="ul"><em parentName="li">{`in`}</em>{` will record the incoming voice`}</li>
      <li parentName="ul"><em parentName="li">{`out`}</em>{` will record the outbound voice`}</li>
      <li parentName="ul">{`\``}<em parentName="li">{`both`}</em>{` will record both voices on destination, but in two different recording files.`}</li>
    </ul>
    <p>{`Find more information about these options for the transfer action in our `}<a parentName="p" {...{
        "href": "/api/voice-calling"
      }}>{`Voice Calling documentation`}</a>{`.`}</p>
    <h2>{`Step 2: Assign a number to the call flow`}</h2>
    <p>{`To have the call flow executed when a number you own is called, you first need to assign that number to the call flow. The endpoint to do it is:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`POST https://voice.messagebird.com/call-flows/:call_flow_id:/numbers
`}</code></pre>
    <p>{`Here’s an example with cURL:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`curl -X "POST" "http://voice.messagebird.com/call-flows/:call_flow_id/numbers" \\
     -H "Authorization: AccessKey :your_access_key:" \\
     -d $'{
  "numbers": ["31612345678"]
}'
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`call_flow_id`}</inlineCode>{` parameter is the ID of the call flow that we created in step 1.`}</p>
    <p>{`The string value in the `}<inlineCode parentName="p">{`numbers`}</inlineCode>{` array is the E.164 formatted number you want to assign. Keep in mind that this must be a number you previously purchased. Buying a MessageBird number is quite easy, in `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/articles/208011689-Buy-a-Number-"
      }}>{`this Help Center article`}</a>{` we explain to you how to do it.`}</p>
    <p>{`Awesome! After making this request, the call flow will be executed for every incoming call to the number.`}</p>
    <h2>{`Step 3: Dial the number, get your call transferred, have a recording of the call`}</h2>
    <p>{`Dial your number after setting the call flow; once connected, you will be transferred to the destination that you previously configured. After the callee answers, audio capture will take place in the direction that you specified in the record option. By hanging up the call, the recording will stop and be saved.`}</p>
    <h2>{`Step 4: Get the information about the call / leg / recording`}</h2>
    <h3>{`1. GET THE CALLS`}</h3>
    <p>{`By making a request to the `}<inlineCode parentName="p">{`/calls`}</inlineCode>{` endpoint, you can get every call for your account; from there, you can extract the id of the call you want to get the legs from. An example request for a list of your calls with cURL would like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`## List calls
curl "https://voice.messagebird.com/calls" \\
-H "Authorization: AccessKey :your_access_key:"
`}</code></pre>
    <p>{`Here’s example of the result to the request above:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`{
  "data": [
      {
        "id": ":callID",
        "status": "ended",
        "source": "31644556677",
        "destination": "31612345678",
        "numberId": "6e143426-d7ea-11e6-bf26-cec0c932ce01",
        "createdAt": "2017-06-21T12:42:25Z",
        "updatedAt": "2017-06-21T12:42:36Z",
        "endedAt": "2017-06-21T12:42:36Z",
        "_links": {
          "legs": "/calls/:callID/legs",
          "self": "/calls/:callID"
        }
      },
  ],
    "_links": {
      "self": "/calls?page=1"
    },
    "pagination": {
      "totalCount": 1,
      "pageCount": 1,
      "currentPage": 1,
      "perPage": 10
    }
}
`}</code></pre>
    <h3>{`2. GET THE LEGS FROM THE CALL`}</h3>
    <p>{`With the call info, you can get leg information by making a request to `}<inlineCode parentName="p">{`/legs`}</inlineCode>{`. An example request would be structured like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`## List legs
curl "https://voice.messagebird.com/calls/:callID/legs" \\
-H "Authorization: AccessKey :your_access_key:"
`}</code></pre>
    <p>{`An example of the result to this request is:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`{
  "data": [
    {
      "id": "d4f07ab3-b17c-44a8-bcef-2b351311c28f",
      "callId": "f1aa71c0-8f2a-4fe8-b5ef-9a330454ef58",
      "source": "31123456789",
      "destination": "31123456777",
      "status": "hangup",
      "direction": "outgoing",
      "duration": 31,
      "cost": 0.000385,
      "currency": "USD",
      "createdAt": "2017-02-16T10:52:00Z",
      "updatedAt": "2017-02-16T10:52:30Z",
      "answeredAt": "2017-02-16T10:52:30Z",
      "endedAt": "2017-02-16T10:52:30Z",
      "_links": {
        "self": "/calls/f1aa71c0-8f2a-4fe8-b5ef-9a330454ef58/legs/d4f07ab3-b17c-44a8-bcef-2b351311c28f"
      }
    },
    {
      "id": "e60ca497-0cf3-4954-b74c-265e95633ec8",
      "callId": "f1aa71c0-8f2a-4fe8-b5ef-9a330454ef58",
      "source": "31123456789",
      "destination": "31123456788",
      "status": "hangup",
      "direction": "incoming",
      "duration": 31,
      "cost": 0.000385,
      "currency": "USD",
      "createdAt": "2017-02-16T10:52:00Z",
      "updatedAt": "2017-02-16T10:52:30Z",
      "answeredAt": "2017-02-16T10:52:30Z",
      "endedAt": "2017-02-16T10:52:30Z",
      "_links": {
        "self": "/calls/f1aa71c0-8f2a-4fe8-b5ef-9a330454ef58/legs/e60ca497-0cf3-4954-b74c-265e95633ec8"
      }
    }
  ],
  "_links": {
    "self": "/calls/f1aa71c0-8f2a-4fe8-b5ef-9a330454ef58/legs?page=1"
  },
  "pagination": {
    "totalCount": 2,
    "pageCount": 1,
    "currentPage": 1,
    "perPage": 10
  }
}
`}</code></pre>
    <p>{`Keep in mind that the data field is an array of legs. For our call flow example with the transfer step, the API will return two or more legs—depending on your call-flow configuration every time.`}</p>
    <h3>{`3. GET THE RECORDINGS FOR THE LEG.`}</h3>
    <p>{`After you have the legs for the call, you can get the recordings by making a request as such:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`## List Recordings
curl "https://voice.messagebird.com/calls/:callID/legs/:legID/recordings" \\
-H "Authorization: AccessKey :your_access_key:"
`}</code></pre>
    <p>{`Here’s an example of the result to this request:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "data": [
    {
      "id": ":recordingID:",
      "format": "wav",
      "legId": ":legID:",
      "status": "done",
      "duration": 7,
      "createdAt": "2017-05-17T11:42:57Z",
      "updatedAt": "2017-05-17T11:43:04Z"
    }
  ],
  "_links": {
    "self": "/recordings/:recordingID:",
    "file": "/recordings/:recordingID:.wav"
  }
}
`}</code></pre>
    <p>{`Among other information, this result provides the file key which holds the URI for downloading the recording file. To download the recording, you need to do a request to that URI:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`GET https://voice.messagebird.com/recordings/:recordingID.wav
`}</code></pre>
    <p>{`A cURL example for this would be:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`curl "https://voice.messagebird.com/recordings/:recordingID.wav" \\
-H "Authorization: AccessKey :your_access_key:" > recording.wav
`}</code></pre>
    <p>{`Great! This request will download a wav file that holds the recording of that leg to your system.`}</p>
    <p><strong parentName="p">{`Nice work!`}</strong>{` 🎉`}</p>
    <p>{`You just learned how to record the audio of a call with MessageBird Voice API!`}</p>
    <h2>{`Start building!`}</h2>
    <p>{`Want to build something similar but not quite sure how to get started? Feel free to let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we'd love to help!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      