export const USAGE = {
  json: `window.MessageBirdChatWidget`,
};

export const ON_READY_EXAMPLE = {
  json: `window.MessageBirdChatWidget.on('ready', () => {
    // do something here
})`,
};

export const ON_VISITOR_CREATED_EXAMPLE = {
  json: `window.MessageBirdChatWidget.on('visitorCreated', (id) => {
    // do something with id here
})`,
};

export const SNIPPET_CODE = {
  json: `<script>  
    var MessageBirdChatWidgetSettings = { 
      widgetId: 'YOUR-WIDGET-ID', 
      initializeOnLoad: true,
    };  
    
    !function(){"use strict";if(Boolean(document.getElementById("live-chat-widget-script")))console.error("MessageBirdChatWidget: Snippet loaded twice on page");else{var e,t;window.MessageBirdChatWidget={},window.MessageBirdChatWidget.queue=[];for(var i=["init","setConfig","toggleChat","identify","hide","on","shutdown"],n=function(){var e=i[d];window.MessageBirdChatWidget[e]=function(){for(var t=arguments.length,i=new Array(t),n=0;n<t;n++)i[n]=arguments[n];window.MessageBirdChatWidget.queue.push([[e,i]])}},d=0;d<i.length;d++)n();var a=(null===(e=window)||void 0===e||null===(t=e.MessageBirdChatWidgetSettings)||void 0===t?void 0:t.widgetId)||"",o=function(){var e,t=document.createElement("script");t.type="text/javascript",t.src="https://livechat.messagebird.com/bootstrap.js?widgetId=".concat(a),t.async=!0,t.id="live-chat-widget-script";var i=document.getElementsByTagName("script")[0];null==i||null===(e=i.parentNode)||void 0===e||e.insertBefore(t,i)};"complete"===document.readyState?o():window.attachEvent?window.attachEvent("onload",o):window.addEventListener("load",o,!1)}}();
    </script>
`,
};

export const SNIPPET_SCRIPT_TAG = {
  html: `
  <script type="text/javascript" src="https://livechat.messagebird.com/bootstrap.js?widgetId=<YOUR_WIDGET_ID>" async id="live-chat-widget-script"></script>
  `,
};

export const SET_ATTRIBUTES = {
  json: `window.MessageBirdChatWidget.setAttributes({ 
    email: "hello@test.com", 
    phone: "+31 123 456 789", 
    premiumUser: true, 
    itemsInCart: 2 
  })
`,
};

export const IDENTIFY = {
  json: `window.MessageBirdChatWidget.identify('123-456-789', { 
    email: "hello@test.com", 
    phone: "+31 123 456 789", 
    premiumUser: true, 
    itemsInCart: 2 
  })
`,
};

export const IDENTIFY_WITHOUT_ATTRIBUTES = {
  json: `window.MessageBirdChatWidget.identify('123-456-789');
`,
};

export const LOGOUT = {
  json: `window.MessageBirdChatWidget.logout()
`,
};

export const ON_TOGGLE_EXAMPLE = {
  json: `window.MessageBirdChatWidget.on('toggle', (isOpen) => {
    // isOpen === true -> customer opened the widget
    // isOpen === false -> customer closed the widget
})`,
};

export const START_CONVERSATION_EXAMPLE = {
  json: `window.MessageBirdChatWidget.startConversation('hello there')`,
};

export const SET_LANGUAGE_EXAMPLE = {
  json: `window.MessageBirdChatWidget.setLanguage('nl')`,
};

export const NATIVE_APP_SETTINGS = {
  json: `
    var MessageBirdChatWidgetSettings = { 
      widgetId: <widget-id>, 
      initializeOnLoad: true,
      nativeMode: true,
    };
  `,
};

export const REACT_NATIVE_EXAMPLE = {
  json: `
    import { WebView } from 'react-native-webview';
    
    const App = () => (
      <WebView
        cacheEnabled={false}
        source={{uri: 'https://<your-hosted-widget-url>'}}
      />
    );
  `,
};

export const IOS_WEBVIEW_EXAMPLE = {
  json: `
    import UIKit
    import WebKit

    class ViewController: UIViewController, WKUIDelegate {
      var webView: WKWebView!
    
      override func loadView() {
        let webConfiguration = WKWebViewConfiguration()
        webView = WKWebView(frame: .zero, configuration: webConfiguration)
        webView.uiDelegate = self
        view = webView
      }
      override func viewDidLoad() {
        super.viewDidLoad()
        
        let myURL = URL(string:"https://<your-hosted-widget-url>")
        let myRequest = URLRequest(url: myURL!)
        webView.load(myRequest)
      }
    }
  `,
};
