import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`WhatsApp`}</h1>
    <p>{`MessageBird’s WhatsApp Business allows you to send and receive WhatsApp messages for alerts, notifications, customer support, as well as two-factor authentication. You’ll be able to add all WhatsApp Business features through a single API.`}</p>
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'Getting started with WhatsApp',
      to: '/quickstarts/whatsapp/getting-started/'
    }, {
      name: 'WhatsApp Sandbox',
      to: '/quickstarts/whatsapp/getting-started-sandbox/'
    }, {
      name: 'WhatsApp API Reference',
      to: '/api/sms'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      