import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Making outbound voice calls with MessageBird`}</h1>
    <h3>{`⏱ 10 min build time`}</h3>
    <p>{`In this MessageBird Developer Tutorial you’ll learn how to make calls with the MessageBird Voice API.`}</p>
    <p>{`Before we get started, have you set up your PHP development environment and
project directory with the MessageBird SDK?`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`No!`}</strong>{` Make sure you to set this up before continuing; you can read this `}<a parentName="li" {...{
          "href": "/tutorials/setup-local-dev-environment-php"
        }}>{`MessageBird Developer Tutorial`}</a>{` to learn how to do so.`}</li>
      <li parentName="ul"><strong parentName="li">{`Yes!`}</strong>{` Great! We’ll show you how to make your first API request and begin establishing Voice Calls with MessageBird using PHP.`}</li>
    </ul>
    <h2>{`Getting Started`}</h2>
    <p>{`First, let's create a new file in the directory of your `}<inlineCode parentName="p">{`composer.json`}</inlineCode>{` file and call it `}<inlineCode parentName="p">{`make_call.php`}</inlineCode>{`.`}</p>
    <p>{`Now we can include the Composer autoloader in this file so you can access the SDK (and other packages):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`<?php
require_once __DIR__.'/vendor/autoload.php';
`}</code></pre>
    <p>{`Then, initialize the SDK by creating a new instance of the `}<inlineCode parentName="p">{`MessageBird\\Client`}</inlineCode>{` class. The constructor takes a single argument, `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`your API key`}</a>{`. For testing the SDK, you can simply replace the string `}<inlineCode parentName="p">{`YOUR-API-KEY`}</inlineCode>{` in the following PHP code and thus hardcode your API key.`}</p>
    <p><strong parentName="p">{`Pro-tip:`}</strong>{` For production applications, we recommended storing the key in a configuration file or environment variable instead, and pass this variable with the key to the constructor.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`$messagebird = new MessageBird\\Client('YOUR-API-KEY');
`}</code></pre>
    <p>{`The SDK defines a PHP class for voice calls. Let’s create an instance of this class and then assign values for the required attributes `}<inlineCode parentName="p">{`source`}</inlineCode>{` and `}<inlineCode parentName="p">{`destination`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`$call = new MessageBird\\Objects\\Voice\\Call;
$call->source = '31970XXXXXXX';
$call->destination = '31970YYYYYYY';
`}</code></pre>
    <p>{`What do these attributes mean? 🤔`}</p>
    <ul>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`source`}</inlineCode>{` is the caller ID that appears on the receiving phone. Don’t forget to specify this telephone number in the `}<a parentName="li" {...{
          "href": "https://support.messagebird.com/hc/en-us/articles/115003950149?flash_digest=eaf79b7172b171cdf0d0b59dd80a232bb1994955"
        }}>{`international format with country code`}</a>{`. The number must be owned or `}<a parentName="li" {...{
          "href": "https://support.messagebird.com/hc/en-us/articles/4412586921233"
        }}>{`verified`}</a>{` in your account.`}</li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`destination`}</inlineCode>{` is the phone number that MessageBird should call. Likewise, you must specify this telephone number in the `}<a parentName="li" {...{
          "href": "https://support.messagebird.com/hc/en-us/articles/115003950149?flash_digest=eaf79b7172b171cdf0d0b59dd80a232bb1994955"
        }}>{`international format with country code`}</a>{`.`}</li>
    </ul>
    <p>{`Make sure to replace the values in the sample code with adequate data for testing.`}</p>
    <p>{`A third attribute named `}<inlineCode parentName="p">{`callFlow`}</inlineCode>{` is required, but before we can assign it to the call, we need to create it as an object as well. The call flow specifies one or more steps that will execute once the receiver picks up the call. They can be different actions, which include playing audio files, reading a block of text with a synthesized voice (TTS) or forwarding the call to another phone.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`$flow = new MessageBird\\Objects\\Voice\\CallFlow;

$step = new MessageBird\\Objects\\Voice\\Step;
$step->action = 'say';
$step->options = [
    'payload' => 'Hey you, a little bird told me you wanted a call!',
    'language' => 'en-GB',
    'voice' => 'female'
];
`}</code></pre>
    <p>{`Every step has two attributes:`}</p>
    <ul>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`action`}</inlineCode>{` classifies what the step does. For instance, the action type `}<inlineCode parentName="li">{`say`}</inlineCode>{` indicates that this step is responsible for reading a written block of text.`}</li>
      <li parentName="ul">{`The array `}<inlineCode parentName="li">{`options`}</inlineCode>{` contain the configuration of the step. The attributes that you can or must specify here depend on the action type. For `}<inlineCode parentName="li">{`say`}</inlineCode>{` you need `}<inlineCode parentName="li">{`payload`}</inlineCode>{` to define the text to speak, `}<inlineCode parentName="li">{`language`}</inlineCode>{` to indicate the language of the text as an ISO language code, and `}<inlineCode parentName="li">{`voice`}</inlineCode>{` to determine the speaker, `}<inlineCode parentName="li">{`male`}</inlineCode>{` or `}<inlineCode parentName="li">{`female`}</inlineCode>{`.`}</li>
    </ul>
    <p>{`You need to add the steps as an array on the `}<inlineCode parentName="p">{`steps`}</inlineCode>{` attribute of the flow. Our example flow has only one:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`$flow->steps = [ $step ];
`}</code></pre>
    <p>{`To learn more about call flows and steps, especially the types of actions that are available and which options are required or optionally available for them, you can read `}<a parentName="p" {...{
        "href": "/api/voice-calling#call-flows"
      }}>{`the ‘Call flows’ section`}</a>{` in our Voice Calling API documentation.`}</p>
    <p>{`Once the flow is ready, we can assign it to the call:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`$call->callFlow = $flow;
`}</code></pre>
    <p>{`There are additional optional attributes for the call objects as well; you can find them in `}<a parentName="p" {...{
        "href": "/api/voice-calling#calls"
      }}>{`the ‘Calls’ section`}</a>{` of our Voice Calling API documentation.`}</p>
    <p>{`Once the call object is fully configured, you can send it through the API. Call `}<inlineCode parentName="p">{`voiceCalls->create()`}</inlineCode>{` on the SDK instance and pass your previously created call object as the first argument:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`$response = $messagebird->voiceCalls->create($call);
var_dump($response);
`}</code></pre>
    <p>{`Your first PHP script is ready, save it! Keep in mind that the MessageBird SDK throws an exception when something goes wrong with your API request. It's okay for now, but in production applications you should place any API call between `}<inlineCode parentName="p">{`try`}</inlineCode>{` and `}<inlineCode parentName="p">{`catch`}</inlineCode>{` and provide user-friendly error handling inside the catch block.`}</p>
    <p>{`Enough said, let's try running it from the command line:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`php make_call.php
`}</code></pre>
    <p>{`You should see the API response as output from the script due to the `}<inlineCode parentName="p">{`var_dump()`}</inlineCode>{` function. If you used a live API key and had verified your number or added credits to your account, your phone will ring shortly and, once you pick up, deliver a message.`}</p>
    <p>{`If you see an error from the script try to read and understand the message so the problem can be fixed. If you have trouble still, don’t hesitate to contact us at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we’ll help you out.`}</p>
    <p><strong parentName="p">{`Nice work!`}</strong>{` 🎉`}</p>
    <p>{`You just initiated your first voice call with the MessageBird API!`}</p>
    <h2>{`Start building!`}</h2>
    <p>{`Let's head over to the next MessageBird Developer Tutorial and learn `}<a parentName="p" {...{
        "href": "/tutorials/handle-incoming-calls-and-sms"
      }}>{`how to set up and handle incoming voice calls`}</a>{`.`}</p>
    <p>{`Want to start building your solution but not quite sure how to get started? Feel free to let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we'd love to help!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      