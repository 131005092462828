import styled from 'styled-components';
import { COLOR } from '../../constants';

export const H1 = styled.h1`
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 40px;
  margin-bottom: 20px;
`;

export const H2 = styled.h2`
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  margin-top: 40px;
  margin-bottom: 20px;
`;

export const H3 = styled.h3`
  font-size: 18px;
  font-weight: 600;
  line-height: 1.4em;
  margin-top: 70px;
  margin-bottom: 20px;
`;

export const H4 = styled.h4`
  font-size: 17px;
  font-weight: 600;
  line-height: 1.4em;
  margin-top: 70px;
  margin-bottom: 20px;
`;

export const H5 = styled.h5`
  font-size: 17px;
  font-weight: 600;
  line-height: 1.4em;
  margin-top: 70px;
  margin-bottom: 20px;
`;

export const H6 = styled.h6`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.4em;
  margin-top: 70px;
  margin-bottom: 20px;
`;

export const Paragraph = styled.p`
  color: #687992;
  font-size: 16px;
  font-weight: normal;
  line-height: 28px;
  margin-bottom: 16px;
`;

export const Pre = styled.pre`
  overflow-x: scroll;
  ${({ inline }) =>
    inline &&
    ` background-color: #eef4ff;
      color: ${COLOR.DARKGREY};
      padding: 0;
      display: inline;
      padding: 2px 4px;
      word-break: break-word;
  `}
`;

export const Code = styled.code`
  background-color: #eef4ff;
  font-size: 14px;
  line-height: 24px;
`;

export const OrderedList = styled.ol`
  color: #687992;
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 40px;
  ol,
  ul {
    margin: 0;
  }
`;

export const UnOrderedList = styled.ul`
  color: #687992;
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 40px;
  ol,
  ul {
    margin: 0;
  }
`;
