import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import { Link } from 'gatsby';
import Notification from 'components/Notification';
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Receiving messages`}</h1>
    <p>{`Programmable Conversations allows you to receive messages from users over any supported communication platform.`}</p>
    <p>{`A conversation is a thread of sent and received messages. In this Programmable Conversations Quickstart, you'll learn how to handle inbound messages and complete the circle of your conversation using `}<em parentName="p">{`cURL`}</em>{`.`}</p>
    <p>{`You have two ways of receiving messages:`}</p>
    <ul>
      <li parentName="ul">{`You can get real-time notifications pushed to your server `}<strong parentName="li">{`through webhooks`}</strong>{`. Webhooks can be created and managed with the API.`}</li>
      <li parentName="ul">{`You can poll conversations and individual messages `}<strong parentName="li">{`via the API`}</strong>{`.`}</li>
    </ul>
    <h2>{`Webhooks`}</h2>
    <p>{`Webhooks enable real-time notifications delivery of conversation events to endpoints on your server and you can use them to integrate more deeply with your preferred CRM or to build a bot. A webhook's configuration contains your URL and a list of events for which MessageBird should notify you.`}</p>
    <p>{`When your webhook is triggered, you'll receive a JSON payload will be posted to the URL configured in your webhook object. For the `}<inlineCode parentName="p">{`message.created`}</inlineCode>{` event, the payload looks similar to the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "conversation": {
    "contactId": "73afe0acee2127fd3e7d9a7dc622826e",
    "createdDatetime": "2019-02-28T17:39:52Z",
    "id": "f1e812e9afd6a875d813d51f45823edd",
    "lastReceivedDatetime": "2019-03-01T10:40:02.880881081Z",
    "status": "active",
    "updatedDatetime": "2019-02-28T18:33:24.787229989Z"
  },
  "message": {
    "channelId": "2ceefea7c1b8c79673215c42c1a6b916",
    "content": {
      "text": "Testing webhook functionality."
    },
    "conversationId": "f1e812e9afd6a875d813d51f45823edd",
    "createdDatetime": "2019-03-01T10:40:02.880881081Z",
    "direction": "received",
    "id": "93a1f7ba935c4ee298cb5ef952052ea7",
    "status": "received",
    "type": "text",
    "updatedDatetime": "2019-03-01T10:40:02.88845145Z"
  },
  "type": "message.created"
}
`}</code></pre>
    <p>{`As you can see, the payload contains information on the message. It's possible to create multiple webhooks with different URLs to subscribe to one or more events each.`}</p>
    <h2>{`Programmatically configure webhooks via the API`}</h2>
    <p>{`To create a webhook using the Programmable Conversations API simply copy the following code snippet to your IDE and replace `}<inlineCode parentName="p">{`YOUR-API-KEY`}</inlineCode>{` with your API key, `}<inlineCode parentName="p">{`channelId`}</inlineCode>{` to your Channel ID, and `}<inlineCode parentName="p">{`url`}</inlineCode>{` to your publicly-accessible URL. Then, run the command in your terminal.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-curl"
      }}>{`curl -X "POST" "https://conversations.messagebird.com/v1/webhooks" \\
-H "Authorization: AccessKey YOUR-API-KEY" \\
-H "Content-Type: application/json" \\
--data '{
"events":["message.created", "message.updated"],
"channelId": "CHANNEL-ID",
"url":"https://your-domain.com/webhook"
}'

`}</code></pre>
    <p>{`If the set up was correct, you'll receive an API response that contains `}<inlineCode parentName="p">{`“status” : “enabled”`}</inlineCode>{`. If something went wrong, you’ll receive an error response from the MessageBird API. Don't worry, simply head over to the `}<a parentName="p" {...{
        "href": "https://developers.messagebird.com/docs/conversations/troubleshooting/"
      }}>{`Conversations API Troubleshooting`}</a>{` to understand the error.`}</p>
    <p>{`If you have a local development server that can receive webhooks, you can generate a public URL with a tool like `}<strong parentName="p"><a parentName="strong" {...{
          "href": "https://ngrok.com/"
        }}>{`ngrok`}</a></strong>{`.`}</p>
    <Notification type="INFO" mdxType="Notification">
  <Link to="/api/conversations" mdxType="Link">Programmable Conversations API Reference</Link>{' '}
  provides full details on how to programmatically configure and manage webhooks
  via the API,, and the expected responses for each API request.
    </Notification>
    <h2>{`Retrieving conversation history`}</h2>
    <p>{`Every message sent or received through Programmable Conversations is available via the API in its conversation object as long as they haven’t been deleted from the system.`}</p>
    <p>{`To access all conversations, you make the following GET request by replacing your credentials as usual:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-curl"
      }}>{`curl "https://conversations.messagebird.com/v1/conversations" \\
-H "Authorization: AccessKey YOUR-API-KEY"
`}</code></pre>
    <Notification type="INFO" mdxType="Notification">
  To make it easier to read the API response, install{' '}
  <a href="https://stedolan.github.io/jq/">jq</a> on your computer and add{' '}
  <code>| jq</code> to the end of the command to pretty-print JSON.
    </Notification>
    <p>{`To access a specific conversation’s history with all messages, make the following GET request, replacing CONVERSATION-ID with the ID of the conversation that you retrieved in the previous request:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-curl"
      }}>{`curl "https://conversations.messagebird.com/v1/conversations/CONVERSATION-ID/messages" \\
-H "Authorization: AccessKey YOUR-API-KEY"
`}</code></pre>
    <p>{`You can view the full example responses in the `}<a parentName="p" {...{
        "href": "/api/conversations"
      }}>{`Programmable Conversations API Reference`}</a>{`.`}</p>
    <p>{`Nice work! 🎉 You can now programmatically receive messages with the MessageBird Programmable Conversations.`}</p>
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'Replying to messages via the API',
      to: '/docs/conversations/reply-to-messages-curl'
    }, {
      name: 'Full Programmable Conversations API reference',
      to: '/api/conversations'
    }, {
      name: 'Troubleshooting Programmable Conversations',
      to: '/docs/conversations/troubleshooting'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      