import CodeSnippet from 'components/CodeSnippet';
import * as CODE from "../../../../src/constants/lookup";
import { APIWrapper, LeftColumn, RightColumn } from 'components/ApiRef';
import * as React from 'react';
export default {
  CodeSnippet,
  CODE,
  APIWrapper,
  LeftColumn,
  RightColumn,
  React
};