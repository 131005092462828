import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Reporting`}</h1>
    <p>{`MessageBird’s Reporting API enables you to programmatically retrieve reports on SMS, Voice, and Conversations performed on our platform. You can use various filters and group-by parameters for your requests and generate personalized reports for any period in which you have used our APIs and aggregate them with monthly, daily, or even hourly granularity. `}</p>
    <p>{`Statistical data is available in near real-time. The Reporting API can help you monitor and analyze your use of MessageBird APIs so you can optimize your usage with data insights.`}</p>
    <p>{`The Reporting API uses `}<inlineCode parentName="p">{`HTTP verbs`}</inlineCode>{` and a `}<inlineCode parentName="p">{`RESTful`}</inlineCode>{` endpoint structure with an access key that is used as the API Authorization framework. Request and response payloads are formatted as JSON—although we provide a GET alternative for requests—using UTF-8 encoding and URL encoded values.`}</p>
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'Reporting API Reference',
      to: '/api/reporting'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      