import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Docs.tsx";
import Notification from 'components/Notification';
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
import { Img } from 'components/shared';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Install Channel: SMS`}</h1>
    <p>{`Let's get started with the Programmable Conversations API by learning how to install a channel. In this quickstart, you'll learn how to set up SMS.`}</p>
    <p>{`First things first, make sure you've `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/login"
      }}>{`logged in to MessageBird Connectivity Platform`}</a>{` and `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`collected your API key`}</a>{` from the Developer Dashboard.`}</p>
    <h2>{`Step 1: Go to the channels directory in your Dashboard`}</h2>
    <p>{`Go to '`}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/channels"
      }}>{`Channels`}</a>{`' in the sidebar of your MessageBird Dashboard. Then click on `}<inlineCode parentName="p">{`Add channel`}</inlineCode>{` under SMS.`}</p>
    <Img src="/img/screenshots/quickstarts/convo/InstallWhatsApp1.png" alt="Install WhatsApp" width="650px" align="middle" mdxType="Img" />
    <h2>{`Step 2: Give your channel a name`}</h2>
    <p>{`Name your channel, make sure it's easily recognisable for yourself and your team. Don't worry, this will only be used internally. `}</p>
    <Img src="/img/screenshots/quickstarts/convo/install-2.png" alt="Install Channel" width="650px" align="middle" mdxType="Img" />
    <h2>{`Step 3: Buy a number`}</h2>
    <p>{`Here you can purchase your first MessageBird Virtual Mobile Number (VMN):`}</p>
    <ol>
      <li parentName="ol">{`Pick the country in which you and your customers are located, and make sure the correct capabilities for your use case are selected.`}</li>
      <li parentName="ol">{`Choose one or more numbers from the selection and the duration for which you want to pay now.`}</li>
      <li parentName="ol">{`Confirm by clicking on ‘Buy Number’.`}</li>
    </ol>
    <Img src="/img/screenshots/quickstarts/convo/install-3.png" alt="Install Channel" width="650px" align="middle" mdxType="Img" />
    <p>{`And that's it! You now have a MessageBird VMN.`}</p>
    <h2>{`Step 4: Collect your channel ID`}</h2>
    <p>{`Voilà! SMS as a channel is now installed. Here you can collect your Channel ID, which you need to start building with the Programmable Conversations API.`}</p>
    <Img src="/img/screenshots/quickstarts/convo/install-4.png" alt="Install Channel" width="650px" align="middle" mdxType="Img" />
    <h2>{`Step 5: Manage your channel`}</h2>
    <p>{`If you need to buy additional VMNs, edit, rename or delete your channel, simply select the channel and save the changes afterwards.`}</p>
    <Img src="/img/screenshots/quickstarts/convo/install-5.png" alt="Install Channel" width="650px" align="middle" mdxType="Img" />
    <p>{`Nice work! 🎉  You've successfully installed SMS as a channel for the MessageBird Programmable Conversations API.`}</p>
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'Sending messages via the API',
      to: '/docs/conversations/send-messages-curl'
    }, {
      name: 'Receiving messages via the API',
      to: '/docs/conversations/handle-inbound-messages-curl'
    }, {
      name: 'Replying to messages via the API',
      to: '/docs/conversations/reply-to-messages-curl'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      