import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/ApiRef.js";
import { Link } from 'gatsby';
import CodeSnippet from 'components/CodeSnippet';
import * as CODE from '../../constants/sms';
import Notification from 'components/Notification';
import { APIWrapper, LeftColumn, RightColumn } from 'components/ApiRef';
import StatusLabel from 'components/StatusLabel';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`SMS API`}</h1>
    <p>{`MessageBird’s SMS API allows you to send and receive SMS messages to and from any country in the world through a REST API. Each message is identified by a unique random ID so that users can always check the status of a message using the given endpoint. `}</p>
    <p>{`The SMS API uses `}<inlineCode parentName="p">{`HTTP verbs`}</inlineCode>{` and a `}<inlineCode parentName="p">{`RESTful`}</inlineCode>{` endpoint structure with an access key that is used as the API Authorization. Request and response payloads are formatted as JSON using UTF-8 encoding and URL encoded values.`}</p>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`API Endpoint`}</h2>
        <p>{`All URLs referenced in MessageBird's SMS API documentation have the base URL in the code snippet on the right side:`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>API Endpoint</h4>
  <CodeSnippet code={CODE.API_ENDPOINT} mdxType="CodeSnippet" />
  
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Available HTTP methods`}</h2>
        <p>{`The MessageBird API uses HTTP verbs to understand if you want to read (`}<inlineCode parentName="p">{`GET`}</inlineCode>{`), delete (`}<inlineCode parentName="p">{`DELETE`}</inlineCode>{`) or create (`}<inlineCode parentName="p">{`POST`}</inlineCode>{`) an object. When your web application cannot do a `}<inlineCode parentName="p">{`POST`}</inlineCode>{` or `}<inlineCode parentName="p">{`DELETE`}</inlineCode>{`, we provide the ability to set the method through the query parameter `}<inlineCode parentName="p">{`_method`}</inlineCode>{`.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Available HTTP methods</h4>
  <CodeSnippet code={CODE.HTTP_METHODS} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Rate Limiting`}</h2>
        <p>{`Excessive HTTP requests will be rejected with status code `}<inlineCode parentName="p">{`429 Too Many Requests`}</inlineCode>{`.
When you receive a 429 response, you can safely assume that the request has not been processed,
and can be retried at a later time.
Please reduce the rate of your requests and try the request again later.`}</p>
        <Notification type="INFO" mdxType="Notification">
    Please <a href="https://www.messagebird.com/en/contact">contact support</a> in case you need higher limits.
        </Notification>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`HTTP Method`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Limit`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`GET`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`50 req/s`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`POST`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`500 req/s`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`PATCH`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`50 req/s`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`DELETE`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`50 req/s`}</td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
    </APIWrapper>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Parameter`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`Authorization`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`When calling our API, send your access key with the authentication type set as `}<inlineCode parentName="td">{`AccessKey`}</inlineCode>{` (Example: `}<inlineCode parentName="td">{`Authorization: AccessKey {accessKey}`}</inlineCode>{`). `}<strong parentName="td">{`Required`}</strong>{`.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`Accept`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Set to `}<inlineCode parentName="td">{`application/json`}</inlineCode>{`. `}<strong parentName="td">{`Required`}</strong>{`.`}</td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Authentication Example</h4>
  <CodeSnippet code={CODE.SMS_AUTH1} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <h2>{`The message object`}</h2>
    <p>{`This is an object representing an SMS message at MessageBird.com.`}</p>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Attributes`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Attribute`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`id`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A unique random ID which is created on the MessageBird platform and is returned upon creation of the object.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`href`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The URL of the created object.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`direction`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Tells you if the message is sent or received.`}<br /><inlineCode parentName="td">{`mt`}</inlineCode>{`: mobile terminated (sent to mobile)`}<br /><inlineCode parentName="td">{`mo`}</inlineCode>{`: mobile originated (received from mobile)`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`type`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The type of message. Values can be: `}<inlineCode parentName="td">{`sms`}</inlineCode>{`, `}<inlineCode parentName="td">{`binary`}</inlineCode>{`, or `}<inlineCode parentName="td">{`flash`}</inlineCode></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`originator`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The sender of the message. This can be a telephone number (including country code) or an alphanumeric string. In case of an alphanumeric string, the maximum length is 11 characters. You can set a `}<a parentName="td" {...{
                  "href": "https://dashboard.messagebird.com/settings/sms"
                }}>{`default originator`}</a>{` in your account or use `}<inlineCode parentName="td">{`inbox`}</inlineCode>{` to use the `}<a parentName="td" {...{
                  "href": "/api/sms-messaging#sticky-vmn"
                }}>{`Sticky VMN`}</a>{` feature.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`body`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The body of the SMS message.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`reference`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A client reference.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`reportUrl`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The status report URL to be used on a per-message basis. `}<inlineCode parentName="td">{`reference`}</inlineCode>{` is required for a status report webhook to be sent.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`validity`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The amount of seconds that the message is valid. If a message is not delivered within this time, the message will be discarded.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`gateway`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The SMS route that is used to send the message.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`typeDetails`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`hashmap`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A hashmap with extra information. Further explanation in the table below.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`datacoding`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The datacoding used, defaults to `}<inlineCode parentName="td">{`plain`}</inlineCode>{` (GSM 03.38 characters only), or it can be set to `}<inlineCode parentName="td">{`unicode`}</inlineCode>{` (contains non-GSM 03.38 characters) or set to `}<inlineCode parentName="td">{`auto`}</inlineCode>{` and we will set unicode or plain depending on the body content.`}<Notification type="WARNING" css="margin-bottom: 0;" mdxType="Notification">{`Note: Using unicode will limit the maximum number of characters to 70 instead of 160. If message character size exceeds the limit, messages will be concatenated, resulting in separately billed messages. See `}<a href="https://support.messagebird.com/hc/en-us/articles/208739745-How-long-is-1-SMS-Message" target="_blank" rel="noopener noreferrer">{`this article`}</a>{` for more information.`}</Notification></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`mclass`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Indicated the message type. `}<inlineCode parentName="td">{`1`}</inlineCode>{` is a normal message, `}<inlineCode parentName="td">{`0`}</inlineCode>{` is a flash message. (0-3 are valid values)`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`scheduledDatetime`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`datetime`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The scheduled date and time of the message in RFC3339 format (Y-m-d\\TH:i:sP)`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`createdDatetime`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`datetime`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The date and time of the creation of the message in RFC3339 format (Y-m-d\\TH:i:sP)`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`recipients`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`hashmap`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The hashmap with recipient information. Further explanation in the table below.`}</td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Message Object Example (SMS)</h4>
  <CodeSnippet code={CODE.SMS_OBJECT1} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h3>{`The recipients array`}</h3>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Attribute`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`totalCount`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The total count of recipients.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`totalSentCount`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The count of recipients that have the message pending (status `}<inlineCode parentName="td">{`sent`}</inlineCode>{`, and `}<inlineCode parentName="td">{`buffered`}</inlineCode>{`).`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`totalDeliveredCount`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The count of recipients where the message is delivered (status `}<inlineCode parentName="td">{`delivered`}</inlineCode>{`).`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`totalDeliveryFailedCount`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The count of recipients where the delivery has failed (status `}<inlineCode parentName="td">{`delivery_failed`}</inlineCode>{`).`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`items`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`array`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`An array of recipient hashmaps. Further explanation in the table below.`}</td>
            </tr>
          </tbody>
        </table>
        <h3>{`The items array`}</h3>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Attribute`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`recipient`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The msisdn of the recipient`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`status`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The status of the message sent to the recipient. Possible values: `}<inlineCode parentName="td">{`scheduled`}</inlineCode>{`, `}<inlineCode parentName="td">{`sent`}</inlineCode>{`, `}<inlineCode parentName="td">{`buffered`}</inlineCode>{`, `}<inlineCode parentName="td">{`delivered`}</inlineCode>{`, `}<inlineCode parentName="td">{`expired`}</inlineCode>{`, and `}<inlineCode parentName="td">{`delivery_failed`}</inlineCode></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`statusReason`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The details about the message status. Possible values: `}<inlineCode parentName="td">{`successfully delivered`}</inlineCode>{`, `}<inlineCode parentName="td">{`pending DLR`}</inlineCode>{`, `}<inlineCode parentName="td">{`DLR not received`}</inlineCode>{`, `}<inlineCode parentName="td">{`unknown subscriber`}</inlineCode>{`, `}<inlineCode parentName="td">{`unavailable subscriber`}</inlineCode>{`, `}<inlineCode parentName="td">{`expired`}</inlineCode>{`, `}<inlineCode parentName="td">{`opted out`}</inlineCode>{`, `}<inlineCode parentName="td">{`received network error`}</inlineCode>{`, `}<inlineCode parentName="td">{`insufficient balance`}</inlineCode>{`, `}<inlineCode parentName="td">{`carrier rejected`}</inlineCode>{`, `}<inlineCode parentName="td">{`capacity limit reached`}</inlineCode>{` and `}<inlineCode parentName="td">{`generic delivery failure`}</inlineCode>{`. `}<a parentName="td" {...{
                  "href": "#sms-statuses"
                }}>{`Read more about status reasons`}</a>{`.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`statusErrorCode`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer `}{`|`}{` null`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Extra `}<a parentName="td" {...{
                  "href": "#sms-error-codes"
                }}>{`error code`}</a>{` that describes the failure in more detail (optional, `}<inlineCode parentName="td">{`null`}</inlineCode>{` if not available)`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`statusDatetime`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`datetime`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The date and time of the last status in RFC3339 format (Y-m-d\\TH:i:sP)`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`recipientCountry`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The name of the recipient’s original country, based on MSISDN.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`recipientCountryPrefix`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The prefix code for the recipient’s original country, based on MSISDN.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`recipientOperator`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The name of the operator of the recipient. Identified by MCCMNC of the message.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`mccmnc`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string `}{`|`}{` null`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The code of the operator of the message sender. It could have null value if the message isn’t delivered yet.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`mcc`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string `}{`|`}{` null`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The MCC (Mobile Country Code) part of MCCMNC.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`mnc`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string `}{`|`}{` null`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The MNC (Mobile Network Code) part of MCCMNC.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`messageLength`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The length of the message in characters. Depends on the message datacoding.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`messagePartCount`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The count of total messages send. Personalisation not taken in account.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`price`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`hashmap`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A hashmap with extra information about how much the message costs.`}<br /><em parentName="td">{`Note: see `}<a href="https://support.messagebird.com/hc/en-us/articles/360006537458-How-does-SMS-and-Voice-pricing-work" target="_blank" rel="noopener noreferrer">{`this article`}</a>{` for more details about our pricing policy.`}</em></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`price.amount`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`float `}{`|`}{` null`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The price the message was billed with. It could have a null value if the message isn’t billed yet.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`price.currency`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string `}{`|`}{` null`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The alphabetic code of the price currency, in ISO 4217 format. It could have null value if the message isn’t billed yet.`}</td>
            </tr>
          </tbody>
        </table>
        <h3>{`The typeDetails array`}</h3>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Attribute`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`udh`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The `}<a parentName="td" {...{
                  "href": "https://en.wikipedia.org/wiki/User_Data_Header"
                }}>{`UDH`}</a>{` to prepend to the message payload. This can be used for sending concatenated SMS. Often required to send `}<inlineCode parentName="td">{`binary`}</inlineCode>{` messages.`}</td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Message Object Example (Binary)</h4>
  <CodeSnippet code={CODE.SMS_OBJECT2} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <hr></hr>
        <h2>{`Send outbound SMS`}</h2>
        <p>{`MessageBird's Programmable SMS API enables you to programmatically send SMS messages from your web application.`}</p>
        <p>{`First, you need to create a new message object.
MessageBird returns the created message object with each request. A maximum of 50 recipients can be entered per request.`}</p>
        <p>{`Send your first SMS message with this example request.`}</p>
        <h3>{`Sticky VMN`}</h3>
        <p>{`Sticky VMN's ensure that the same `}<em parentName="p">{`Sender`}</em>{` phone number is selected each time a specific recipient receives an SMS from your web application.`}</p>
        <Notification type="INFO" mdxType="Notification">
  Sticky VMN's are only available in Canada, Netherlands and United Kingdom. As there are no capacity limits in other countries, you won't need multiple VMNs. <a href="https://support.messagebird.com/hc/en-us/sections/360000108538" target="_blank" rel="noopener noreferrer">Learn more about specific country restrictions</a>. Starting 1st March 2022 the services has been discontinued in the USA following a <a href="https://support.messagebird.com/hc/en-us/articles/208747865-United-States-10DLC-FAQ#h_01FTGK92K1PG0BJ4173A5S1F4H" target="_blank" rel="noopener noreferrer">change in regulation</a>.
        </Notification>
        <p>{`Whether you are using a pool of dedicated VMNs or MessageBird's shared VMN pool, it is best practice to ensure that your recipients always receive your SMS messages from the same VMN as a sender.`}</p>
        <p>{`MessageBird's `}<em parentName="p">{`Sticky-VMN-as-a-Sender`}</em>{` service makes this configuration possible. Simply use `}<inlineCode parentName="p">{`inbox`}</inlineCode>{` as the originator when sending SMS messages to the MessageBird SMS API.`}</p>
        <p>{`This will trigger our system to select a random VMN (dedicated or shared - depending on what’s available) for the first SMS message and ensure that the selected VMN be reused for all following SMS messages.`}</p>
        <h5>{`Required parameters`}</h5>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`attribute`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`originator`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The sender of the message. This can be a telephone number (including country code) or an alphanumeric string. In case of an alphanumeric string, the maximum length is 11 characters.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`body`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The body of the SMS message.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`recipients`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`array`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`An array of recipients msisdns.`}</td>
            </tr>
          </tbody>
        </table>
        <h5>{`Optional parameters`}</h5>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`attribute`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`groupIds`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`array`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`An array of group id's. If provided recipients can be omitted.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`type`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The type of message. Values can be: `}<inlineCode parentName="td">{`sms`}</inlineCode>{`, `}<inlineCode parentName="td">{`binary`}</inlineCode>{`, or `}<inlineCode parentName="td">{`flash`}</inlineCode>{`.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`reference`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A client reference. Is required for a status report callback to be sent`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`reportUrl`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The status report URL to be used on a per-message basis. This value overrides the standard `}<a parentName="td" {...{
                  "href": "https://dashboard.messagebird.com/en/developers/settings"
                }}>{`status report url`}</a>{` configured on the customer account.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`validity`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The amount of seconds that the message is valid. If a message is not delivered within this time, the message will be discarded.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`gateway`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The SMS route that is used to send the message.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`typeDetails`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`hashmap`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A hashmap with extra information. Is only used when a `}<inlineCode parentName="td">{`binary`}</inlineCode>{` message is sent.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`datacoding`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The datacoding used can be `}<inlineCode parentName="td">{`plain`}</inlineCode>{` (GSM 03.38 characters only), `}<inlineCode parentName="td">{`unicode`}</inlineCode>{` (contains non-GSM 03.38 characters) or `}<inlineCode parentName="td">{`auto`}</inlineCode>{`: we will then set unicode or plain depending on the body content.`}<Notification type="WARNING" css="margin-bottom: 0;" mdxType="Notification">{`Note: Using unicode will limit the maximum number of characters to 70 instead of 160. If message character size exceeds the limit, messages will be concatenated, resulting in separately billed messages. See `}<a href="https://support.messagebird.com/hc/en-us/articles/208739745-How-long-is-1-SMS-Message" target="_blank" rel="noopener noreferrer">{`this article`}</a>{` for more information.`}</Notification></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`mclass`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Indicated the message type. `}<inlineCode parentName="td">{`1`}</inlineCode>{` is a normal message, `}<inlineCode parentName="td">{`0`}</inlineCode>{` is a flash message. (0-3 are valid values)`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`shortenUrls`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`boolean`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`beta`}</inlineCode>{` Shorten all the URLs present in the message body.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`scheduledDatetime`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`datetime`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The scheduled date and time of the message in RFC3339 format (Y-m-d\\TH:i:sP), when you want to schedule a message in the future. Don't set this value when message needs to be send directly.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`createdDatetime`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`datetime`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The date and time of the creation of the message in RFC3339 format (Y-m-d\\TH:i:sP)`}</td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.SEND_DEF} mdxType="CodeSnippet" />
        <h4>Example Request</h4>
  <CodeSnippet code={CODE.SEND_EXAMPLE} mdxType="CodeSnippet" />
  <Notification type="WARNING" mdxType="Notification">
  Do note that values are not automatically urlencoded with the above example. Use --data-urlencode if you want to urlencode the values.
  </Notification>
        <h4>Example Response</h4>
  <CodeSnippet code={CODE.SEND_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <h2>{`Handle a status report`}</h2>
    <p>{`Status reports are HTTP `}<inlineCode parentName="p">{`GET`}</inlineCode>{` requests that are sent to your platform. The requests hold information about the status of a message that you have sent through our API. Status reports are only provided for SMS messages that have a `}<inlineCode parentName="p">{`reference`}</inlineCode>{` defined when sending the message, and a status report URL is set: as `}<inlineCode parentName="p">{`reportUrl`}</inlineCode>{` when sending the message, or configured in their account as `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/settings"
      }}>{`status report url`}</a>{`.`}</p>
    <h4>{`Attributes`}</h4>
    <Notification type="INFO" css="margin-bottom: 0;" mdxType="Notification">Note: New attributes can be added to the status report HTTP callbacks. Consider this for the <Link to="/api#verifying-http-requests" mdxType="Link">validation</Link> of received from MessageBird status reports. We will never <i>remove</i> attributes.</Notification>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A unique random ID which is created on the MessageBird platform and is returned upon creation of the object.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`reference`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A reference, given by customer when sending the message to the MessageBird platform.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`recipient`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The recipient where this status report applies to.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`status`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The status of the message sent to the recipient. Possible values: `}<inlineCode parentName="td">{`scheduled`}</inlineCode>{`, `}<inlineCode parentName="td">{`sent`}</inlineCode>{`, `}<inlineCode parentName="td">{`buffered`}</inlineCode>{`, `}<inlineCode parentName="td">{`delivered`}</inlineCode>{`, `}<inlineCode parentName="td">{`expired`}</inlineCode>{`, and `}<inlineCode parentName="td">{`delivery_failed`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`statusReason`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The details about the message status. Possible values: `}<inlineCode parentName="td">{`successfully delivered`}</inlineCode>{`, `}<inlineCode parentName="td">{`pending DLR`}</inlineCode>{`, `}<inlineCode parentName="td">{`DLR not received`}</inlineCode>{`, `}<inlineCode parentName="td">{`unknown subscriber`}</inlineCode>{`, `}<inlineCode parentName="td">{`unavailable subscriber`}</inlineCode>{`, `}<inlineCode parentName="td">{`expired`}</inlineCode>{`, `}<inlineCode parentName="td">{`opted out`}</inlineCode>{`, `}<inlineCode parentName="td">{`received network error`}</inlineCode>{`, `}<inlineCode parentName="td">{`insufficient balance`}</inlineCode>{`, `}<inlineCode parentName="td">{`carrier rejected`}</inlineCode>{`, `}<inlineCode parentName="td">{`capacity limit reached`}</inlineCode>{` and `}<inlineCode parentName="td">{`generic delivery failure`}</inlineCode>{`. `}<a parentName="td" {...{
              "href": "#sms-statuses"
            }}>{`Read more about status reasons`}</a>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`statusErrorCode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Extra `}<a parentName="td" {...{
              "href": "#sms-error-codes"
            }}>{`error code`}</a>{` that describes the failure in more detail (optional, only given if available).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`statusDatetime`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The date and time of this status in RFC3339 format (Y-m-d\\TH:i:sP).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`mccmnc`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The code of the operator of the message recipient number.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ported`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Indicates if the recipient number was ported from another operator.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`messageLength`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The length of the message in characters. Depends on the message datacoding.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`messagePartCount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The count of total messages sent.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`datacoding`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The encoding of the message. Possible values: `}<inlineCode parentName="td">{`plain`}</inlineCode>{`, `}<inlineCode parentName="td">{`unicode`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`price.amount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`float `}{`|`}{` null`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The price the message was billed with. It could have a null value if the message isn’t billed yet.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`price.currency`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string `}{`|`}{` null`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The alphabetic code of the price currency, in ISO 4217 format. Possible values are also `}<inlineCode parentName="td">{`CRE`}</inlineCode>{`, `}<inlineCode parentName="td">{`TRC`}</inlineCode>{` for internal (free) credits currency. It could have a null value if the message isn’t billed yet.`}</td>
        </tr>
      </tbody>
    </table>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Response`}</h4>
        <p>{`Your platform should respond with a `}<inlineCode parentName="p">{`200 OK`}</inlineCode>{` HTTP header. When our platform doesn't receive a `}<inlineCode parentName="p">{`200 OK`}</inlineCode>{` header, we will try to deliver the receipt again (up to 10 times).`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
        <h4>Example request</h4>
  <CodeSnippet code={CODE.STATUSSMS_REQUEST} mdxType="CodeSnippet" />
  <h4>Example response</h4>
  <CodeSnippet code={CODE.STATUSSMS_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <h2>{`Receive and handle inbound SMS`}</h2>
    <h3>{`On a virtual mobile number (VMN)`}</h3>
    <p>{`Inbound SMS messages from a Virtual Mobile Number are received through a callback URL that can be configured manually by using the `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/articles/360000358477-How-to-Call-HTTP-endpoint-with-SMS"
      }}>{`Forward to URL step in Flow Builder`}</a>{`, or by contacting our support team. The message attributes are sent through a POST request by default but the method can be configured per VMN.`}</p>
    <h4>{`Attributes`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A unique random ID which is created on the MessageBird platform.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`recipient`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The recipient of the message (the VMN).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`originator`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The sender of the message.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`body`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The body of the SMS message.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`createdDatetime`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The date and time of the creation of the message in RFC3339 format (Y-m-d\\TH:i:sP)`}</td>
        </tr>
      </tbody>
    </table>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Response`}</h4>
        <p>{`Your platform should respond with a `}<inlineCode parentName="p">{`200 OK`}</inlineCode>{` HTTP header. When our platform doesn't receive a `}<inlineCode parentName="p">{`200 OK`}</inlineCode>{` header, we will try to deliver the message again (up to 10 times).`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Example Request</h4>
  <CodeSnippet code={CODE.RECEIVE_EXAMPLE1} mdxType="CodeSnippet" />
  <h4>Example Response</h4>
  <CodeSnippet code={CODE.RESPONSE_OK} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <h3>{`On a shortcode`}</h3>
    <p>{`Inbound SMS messages from an end user to - a (sub)keyword at - a shortcode are received through a configured callback URL (configurable per keyword/shortcode). The message attributes are sent through a GET request by default but the method can be configured per keyword. Please contact our support team to set this up for you.`}</p>
    <h4>{`Attributes`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`mid`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A unique random ID which is created on the MessageBird platform.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`shortcode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The shortcode that received the message.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`keyword`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The keyword that matched the message.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`originator`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The sender of the message (end user).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`operator`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The operator of the sender of the message (MCCMNC)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`message`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The body of the SMS message, including the (sub)keyword.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`receive_datetime`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The date and time when the message was received at shortcode in format: YmdHis`}</td>
        </tr>
      </tbody>
    </table>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Response`}</h4>
        <p>{`Your platform should respond with a 200 OK HTTP header and a plain text OK as body. When our platform doesn't receive a OK response body, we will try to deliver the message again (up to 10 times).`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Example Request</h4>
  <CodeSnippet code={CODE.RECEIVE_EXAMPLE2} mdxType="CodeSnippet" />
  <h4>Example Response</h4>
  <CodeSnippet code={CODE.RESPONSE_OK} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <hr></hr>
        <h2>{`View an SMS`}</h2>
        <p>{`You can use MessageBird's SMS API to retrieve information of an existing inbound or outbound SMS message.`}</p>
        <p>{`You only need to supply the unique message id that was returned upon creation or receiving.`}</p>
        <h4>{`Required parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Parameter`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`id`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A unique random ID which is created on the MessageBird platform and is returned upon creation of the object. `}<strong parentName="td">{`Required`}</strong></td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.VIEW_DEF} mdxType="CodeSnippet" />
   <h4>Example Request</h4>
  <CodeSnippet code={CODE.VIEW_EXAMPLE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Response`}</h4>
        <p>{`This will return a message object if the request was successful.`}</p>
        <p>{`If the request failed, an `}<a parentName="p" {...{
            "href": "/api#api-errors"
          }}>{`error object`}</a>{` will be returned.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Example Response</h4>
  <CodeSnippet code={CODE.VIEW_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <hr></hr>
        <h2>{`List messages`}</h2>
        <p>{`To list all inbound and outbound SMS messages, you can do a GET request on the `}<inlineCode parentName="p">{`/messages`}</inlineCode>{` endpoint.`}</p>
        <p>{`Messages are identified by a unique random ID. You can use this ID to check the status of the SMS message through the provided endpoint.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>API Endpoint</h4>
  <CodeSnippet code={CODE.LIST_URI} mdxType="CodeSnippet" />
  <h4>Example Request</h4>
  <CodeSnippet code={CODE.LIST_MESSAGES} mdxType="CodeSnippet" />
  <h4>Example Response</h4>
  <CodeSnippet code={CODE.LIST_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <h3>{`Filters`}</h3>
    <p>{`Besides listing all SMS messages, the MessageBird SMS API also provides some filters that can be used as query parameters:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Filter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`originator`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Display messages by the specified originator.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`recipient`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Display messages for the specified recipient.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`direction`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Display either `}<inlineCode parentName="td">{`mt`}</inlineCode>{` (sent) or `}<inlineCode parentName="td">{`mo`}</inlineCode>{` (received) messages.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`limit`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`int`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Limit the amount of messages listed.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`offset`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`int`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Skip first `}<inlineCode parentName="td">{`n`}</inlineCode>{` results.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`searchterm`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Display messages including the specified searchterm in recipient and originator`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Display messages of type `}<inlineCode parentName="td">{`sms`}</inlineCode>{`, `}<inlineCode parentName="td">{`binary`}</inlineCode>{`, or `}<inlineCode parentName="td">{`flash`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`contact_id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`int`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Display messages by `}<inlineCode parentName="td">{`contact_id`}</inlineCode>{`. See `}<Link to="/docs/contacts" mdxType="Link">{`Contacts API`}</Link>{` for more info.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`status`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Display messages with status `}<inlineCode parentName="td">{`scheduled`}</inlineCode>{`, `}<inlineCode parentName="td">{`sent`}</inlineCode>{`, `}<inlineCode parentName="td">{`buffered`}</inlineCode>{`, `}<inlineCode parentName="td">{`delivered`}</inlineCode>{`, `}<inlineCode parentName="td">{`expired`}</inlineCode>{`, or `}<inlineCode parentName="td">{`delivery_failed`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`from`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Display messages starting from the specified date in RFC3339 format (Y-m-d\\TH:i:sP).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`until`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Display messages until the specified date in RFC3339 format (Y-m-d\\TH:i:sP).`}</td>
        </tr>
      </tbody>
    </table>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <hr></hr>
        <h2>{`List scheduled messages`}</h2>
        <p>{`You can also list all scheduled SMS messages using the endpoint on the right.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>API Endpoint</h4>
  <CodeSnippet code={CODE.LIST_URI} mdxType="CodeSnippet" />
  <h4>Example request</h4>
  <CodeSnippet code={CODE.LIST_SCHEDULED_MESSAGES} mdxType="CodeSnippet" />
  <h4>Example Response</h4>
  <CodeSnippet code={CODE.LIST_SCHEDULED_MESSAGES_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <h2>{`SMS Statuses`}</h2>
    <p>{`MessageBird provides three levels of information describing the status of an SMS: `}<StatusLabel statusCat mdxType="StatusLabel">{`Status`}</StatusLabel>{`, `}<StatusLabel reasonCat mdxType="StatusLabel">{`Status Reason`}</StatusLabel>{`, and `}<StatusLabel errorCat mdxType="StatusLabel">{`Error Code`}</StatusLabel>{`. These levels are complementary and should be analyzed together to get the full picture of an SMS delivery.`}</p>
    <h4>{`Via HTTP`}</h4>
    <p>{`The Status, Status Reason and Error Code are reported back as respectively `}<inlineCode parentName="p">{`status`}</inlineCode>{`, `}<inlineCode parentName="p">{`statusReason`}</inlineCode>{` and `}<inlineCode parentName="p">{`statusErrorCode`}</inlineCode>{` variables in the HTTP status report callback. The Error Code is only given when the data is available.`}</p>
    <h4>{`Via SMPP`}</h4>
    <p>{`When connected via SMPP, the Error Code is given back as value of the `}<inlineCode parentName="p">{`network_error_code`}</inlineCode>{` TLV and in the `}<inlineCode parentName="p">{`err`}</inlineCode>{` parameter in a DLR’s short_message value. It contains the code that references a specific error.`}</p>
    <h3>{`SMS Status Information Hierarchy`}</h3>
    <p>{`SMS `}<StatusLabel errorCat mdxType="StatusLabel">{`Error Codes`}</StatusLabel>{` are grouped in `}<StatusLabel reasonCat mdxType="StatusLabel">{`Status Reasons`}</StatusLabel>{`, that in turn provide additional details to an SMS `}<StatusLabel statusCat mdxType="StatusLabel">{`Status`}</StatusLabel>{`.
To read more about the relationship between Statuses, Status Reasons, and Error Codes you can check `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/articles/360000361557-Errors-error-codes"
      }}>{`our support article`}</a>{`.
The error codes descriptions can be found in the `}<a parentName="p" {...{
        "href": "#sms-error-codes"
      }}>{`table below`}</a>{`.`}</p>
    <ul>
      <li parentName="ul">
        <StatusLabel statusCat mdxType="StatusLabel">sent</StatusLabel>: The message has been sent, and it has left the MessageBird platform (this is a temporary status).
        <ul parentName="li">
          <li parentName="ul">
            <StatusLabel reasonCat mdxType="StatusLabel">pending DLR</StatusLabel>: The message has been sent to a downstream mobile operator and provider and we are waiting for Delivery Report to be returned.
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <StatusLabel statusCat mdxType="StatusLabel">delivered</StatusLabel>: The message has been delivered to the intended receiver.
        <ul parentName="li">
          <li parentName="ul">
            <StatusLabel reasonCat mdxType="StatusLabel">successfully delivered</StatusLabel>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <StatusLabel statusCat mdxType="StatusLabel">buffered</StatusLabel>: The message has been queued pending to be delivered. This could be as part of a retry scheme for temporary unavailable (i.e. Unreachable) receiver.
        <ul parentName="li">
          <li parentName="ul">
            <StatusLabel reasonCat mdxType="StatusLabel">pending DLR</StatusLabel>: The message has been sent to a downstream mobile operator and provider and we are waiting for Delivery Report to be returned.
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <StatusLabel statusCat mdxType="StatusLabel">expired</StatusLabel>: The message couldn't be delivered before the expiration of its validity period. MessageBird has a retry policy designed to maximize the chance of successfully delivery, but retries will stop once the validity period you've set expires.
        <ul parentName="li">
          <li parentName="ul">
            <StatusLabel reasonCat mdxType="StatusLabel">unavailable subscriber</StatusLabel>: The receiver wasn't available due a temporary issue (example it is out of coverage),the retry pattern couldn't complete a successfully delivered  it before the expiration of the validity period.
          </li>
          <li parentName="ul">
            <StatusLabel reasonCat mdxType="StatusLabel">DLR not received</StatusLabel>: The SMS was sent to a mobile operator or downstream provider however no DLR has been returned before the end of the message validity period. This usually indicate the receiving network has a longer retry pattern then the validity period or it is experiencing an issue processing DLR.
          </li>
          <li parentName="ul">
            <StatusLabel reasonCat mdxType="StatusLabel">expired</StatusLabel>: The SMS was expired before we were able to send the message to a mobile operator or downstream provider.
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <StatusLabel statusCat mdxType="StatusLabel">delivery_failed</StatusLabel>: The message couldn't be delivered and a negative DLR has been received. More detail can be found in the reason, or when present in the error code.
        <ul parentName="li">
          <li parentName="ul">
            <StatusLabel reasonCat mdxType="StatusLabel">unknown subscriber</StatusLabel>: The receiver number is not associated with an active line.
            <ul parentName="li">
              <li parentName="ul">
                <StatusLabel errorCat mdxType="StatusLabel">1: EC_UNKNOWN_SUBSCRIBER</StatusLabel>
              </li>
              <li parentName="ul">
                <StatusLabel errorCat mdxType="StatusLabel">2: EC_UNKNOWN_BASE_STATION</StatusLabel>
              </li>
              <li parentName="ul">
                <StatusLabel errorCat mdxType="StatusLabel">3: EC_UNKOWN_MSC</StatusLabel>
              </li>
              <li parentName="ul">
                <StatusLabel errorCat mdxType="StatusLabel">5: EC_UNIDENTIFIED_SUBSCRIBER</StatusLabel>
              </li>
              <li parentName="ul">
                <StatusLabel errorCat mdxType="StatusLabel">9: EC_ILLEGAL_SUBSCRIBER</StatusLabel>
              </li>
              <li parentName="ul">
                <StatusLabel errorCat mdxType="StatusLabel">10: EC_BEARERSERVICE_NOT_PROVISIONED</StatusLabel>
              </li>
              <li parentName="ul">
                <StatusLabel errorCat mdxType="StatusLabel">11: EC_TELESERVICE_NOT_PROVISIONED</StatusLabel>
              </li>
              <li parentName="ul">
                <StatusLabel errorCat mdxType="StatusLabel">12: EC_CALL_BARRED</StatusLabel>
              </li>
            </ul>
          </li>
          <li parentName="ul">
            <StatusLabel reasonCat mdxType="StatusLabel">insufficient balance</StatusLabel>: The cost of the SMS is higher than the remaining balance available on your account.
          </li>
          <li parentName="ul">
            <StatusLabel reasonCat mdxType="StatusLabel">generic delivery failure</StatusLabel>: No detailed information has been received by the downstream supplier and no additional information is available about the reason of the failure.
          </li>
          <li parentName="ul">
            <StatusLabel reasonCat mdxType="StatusLabel">unavailable subscriber</StatusLabel>: The receiver number the SMS is temporarily unavailable, the number is valid and shall be kept in your database however the retry.
            <ul parentName="li">
              <li parentName="ul">
                <StatusLabel errorCat mdxType="StatusLabel">8: EC_ROAMING_NOT_ALLOWED</StatusLabel>
              </li>
              <li parentName="ul">
                <StatusLabel errorCat mdxType="StatusLabel">26: EC_SUBSEQUENT_HANDOVER_FAILURE</StatusLabel>
              </li>
              <li parentName="ul">
                <StatusLabel errorCat mdxType="StatusLabel">31: EC_SUBSCRIBER_BUSY_FOR_MT_SMS</StatusLabel>
              </li>
              <li parentName="ul">
                <StatusLabel errorCat mdxType="StatusLabel">27: EC_ABSENT_SUBSCRIBER</StatusLabel>
              </li>
              <li parentName="ul">
                <StatusLabel errorCat mdxType="StatusLabel">28: EC_ABSENT_SUBSCRIBER_NO_PAGE</StatusLabel>
              </li>
              <li parentName="ul">
                <StatusLabel errorCat mdxType="StatusLabel">29: EC_ABSENT_SUBSCRIBER_IMSI_DETACHED</StatusLabel>
              </li>
              <li parentName="ul">
                <StatusLabel errorCat mdxType="StatusLabel">33: EC_MESSAGE_WAITING_LIST_FULL</StatusLabel>
              </li>
            </ul>
          </li>
          <li parentName="ul">
            <StatusLabel reasonCat mdxType="StatusLabel">received network error</StatusLabel>: the receiver's network is experiencing some issue, those are temporary preventing SMS delivery however the receiver number used is both valid and correct.
            <ul parentName="li">
              <li parentName="ul">
                <StatusLabel errorCat mdxType="StatusLabel">7: EC_UNKNOWN_EQUIPMENT</StatusLabel>
              </li>
              <li parentName="ul">
                <StatusLabel errorCat mdxType="StatusLabel">12: EC_ILLEGAL_EQUIPMENT</StatusLabel>
              </li>
              <li parentName="ul">
                <StatusLabel errorCat mdxType="StatusLabel">21: EC_FACILITY_NOT_SUPPORTED</StatusLabel>
              </li>
              <li parentName="ul">
                <StatusLabel errorCat mdxType="StatusLabel">30: EC_CONTROLLING_MSC_FAILURE</StatusLabel>
              </li>
              <li parentName="ul">
                <StatusLabel errorCat mdxType="StatusLabel">34: EC_SYSTEM_FAILURE</StatusLabel>
              </li>
              <li parentName="ul">
                <StatusLabel errorCat mdxType="StatusLabel">35: EC_DATA_MISSING</StatusLabel>
              </li>
              <li parentName="ul">
                <StatusLabel errorCat mdxType="StatusLabel">36: EC_UNEXPECTED_DATA_VALUE</StatusLabel>
              </li>
              <li parentName="ul">
                <StatusLabel errorCat mdxType="StatusLabel">39: EC_NO_ROAMING_NUMBER_AVAILABLE</StatusLabel>
              </li>
              <li parentName="ul">
                <StatusLabel errorCat mdxType="StatusLabel">40: EC_MEMORY_CAPACITY_EXCEEDED</StatusLabel>
              </li>
              <li parentName="ul">
                <StatusLabel errorCat mdxType="StatusLabel">71: EC_UNKNOWN_ALPHABET</StatusLabel>
              </li>
            </ul>
          </li>
          <li parentName="ul">
            <StatusLabel reasonCat mdxType="StatusLabel">opted out</StatusLabel>: The receiver revoked his/her consent to receive further SMS from the customer/originating number.
            <ul parentName="li">
              <li parentName="ul">
                <StatusLabel errorCat mdxType="StatusLabel">103: EC_SUBSCRIBER_OPTEDOUT</StatusLabel>
              </li>
            </ul>
          </li>
          <li parentName="ul">
            <StatusLabel reasonCat mdxType="StatusLabel">carrier rejected</StatusLabel>: The receiver's carrier or mobile operator is blocking the SMS, this is most likely caused by the lack of a mandatory registration for the SMS originator or its content and/or the locally applicable definition of forbidden / unlawful traffic
            <ul parentName="li">
              <li parentName="ul">
                <StatusLabel errorCat mdxType="StatusLabel">104: EC_SENDER_UNREGISTERED</StatusLabel>
              </li>
              <li parentName="ul">
                <StatusLabel errorCat mdxType="StatusLabel">105: EC_CONTENT_UNREGISTERED</StatusLabel>
              </li>
              <li parentName="ul">
                <StatusLabel errorCat mdxType="StatusLabel">110: EC_MESSAGE_FILTERED</StatusLabel>
              </li>
            </ul>
          </li>
          <li parentName="ul">
            <StatusLabel reasonCat mdxType="StatusLabel">capacity limit reached</StatusLabel>: The receiver's carrier or mobile operator is blocking the SMS due to limitation of SMS per second per day (e.g. as part of USA's campaign throttling regulation), or on the time of the day you are authorised to send SMS (e.g. as part of India's Do-Not-Disturb policy). Applicable for countries that require registered campaigns.
            <ul parentName="li">
              <li parentName="ul">
                <StatusLabel errorCat mdxType="StatusLabel">106: EC_CAMPAIGN_VOLUME_EXCEEDED</StatusLabel>
              </li>
              <li parentName="ul">
                <StatusLabel errorCat mdxType="StatusLabel">107: EC_CAMPAIGN_THROUGHPUT_EXCEEDED</StatusLabel>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <StatusLabel statusCat mdxType="StatusLabel">scheduled</StatusLabel>: The message is scheduled by the customer to be sent at a later time (API only).
      </li>
    </ul>
    <p>{`Some Statuses, Reasons and Error Codes are related to country restrictions. You can find more information about `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/articles/360017673738-Complete-list-of-sender-ID-availability-and-restrictions"
      }}>{`countries restrictions at our help pages`}</a>{`.`}</p>
    <h4>{`SMS Error Codes`}</h4>
    <div className="sms-error-table"></div>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`0`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`EC_NO_ERROR`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`EC_UNKNOWN_SUBSCRIBER`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Permanent; The receiver number is not associated with an active line.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`2`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`EC_UNKNOWN_BASE_STATION`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Permanent; The receiver number is currently attached to an equipment unknown to the receiving network.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`3`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`EC_UNKOWN_MSC`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Intermediate; The receiver number is currently attached to an equipment unknown to the receiving network.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`5`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`EC_UNIDENTIFIED_SUBSCRIBER`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Intermediate; The receiver number is not associated with an active line.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`7`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`EC_UNKNOWN_EQUIPMENT`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Intermediate; The receiver number is valid but its handset is not able to connect to the mobile network.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`8`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`EC_ROAMING_NOT_ALLOWED`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Intermediate; The receiver is currently outside of its network of origin and it is not authorised to roam. The issue will resolve once it returns.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`9`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`EC_ILLEGAL_SUBSCRIBER`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Permanent; The receiver failed to authenticate when connecting to the mobile network.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`10`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`EC_BEARERSERVICE_NOT_PROVISIONED`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Intermediate; The receiver number bearer is not provisioned for SMS. This could be because the subscriber have 2 active numbers (dual numbering) and the one used as receiver is not SMS enable (but the other is).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`11`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`EC_TELESERVICE_NOT_PROVISIONED`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Intermediate; The receiver is not provisioned for SMS this could be because the number is a not a mobile (i.e. is a IoT number) and/or the subscriber plan do not allow SMS.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`12`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`EC_ILLEGAL_EQUIPMENT`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Intermediate; The receiver number is valid but its handset is blacklisted as stolen/lost.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`13`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`EC_CALL_BARRED`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Intermediate; The SMS services have been blocked by the receiver operator. This is usually linked to an unpaid bill or lack of credit.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`21`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`EC_FACILITY_NOT_SUPPORTED`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Intermediate; The SMS is failing due to the receiving network not supporting SMS.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`26`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`EC_SUBSEQUENT_HANDOVER_FAILURE`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Intermediate; The receiver is currently moving between different areas of its network and the SMS couldn't be delivered.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`27`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`EC_ABSENT_SUBSCRIBER`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Intermediate; The receiver couldn't be located on the receiver network.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`28`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`EC_ABSENT_SUBSCRIBER_NO_PAGE`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Intermediate; The receiver is out of coverage.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`29`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`EC_ABSENT_SUBSCRIBER_IMSI_DETACHED`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Intermediate; The receiver switched its handset off.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`30`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`EC_CONTROLLING_MSC_FAILURE`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Intermediate; The network equipment the receiver is attached to is experiencing a temporary failure and/or planned maintenance.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`31`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`EC_SUBSCRIBER_BUSY_FOR_MT_SMS`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Intermediate; The receiver is currently busy (example performing USSD) and the SMS could not be delivered.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`32`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`EC_SM_DELIVERY_FAILURE`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Intermediate`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`33`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`EC_MESSAGE_WAITING_LIST_FULL`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Intermediate; The receiver has received more SMS then then local network nodes can store for a single user.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`34`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`EC_SYSTEM_FAILURE`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Intermediate; A generic issue with the receiver network.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`35`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`EC_DATA_MISSING`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Intermediate; A mandatory (by the receiver network) field was empty.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`36`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`EC_UNEXPECTED_DATA_VALUE`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Intermediate; Value in one the SMS fields was wrong (i.e. the charset used for coding the SMS).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`39`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`EC_NO_ROAMING_NUMBER_AVAILABLE`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Intermediate; The receiver is roaming and a temporary roaming redirecting number was not available to be assigned to reroute the SMS to the correct country/destination.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`40`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`EC_MEMORY_CAPACITY_EXCEEDED`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Intermediate; An unspecified equipment on the receiving network has no memory left to handle incoming SMS traffic.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`71`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`EC_UNKNOWN_ALPHABET`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Permanent; The SMS is written in an alphabet/charset that is not supported by the receiving network.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`72`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`EC_USSD_BUSY`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Intermediate`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`101`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`EC_SUBSCRIBER_INSUFFICIENT_BALANCE`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Intermediate`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`103`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`EC_SUBSCRIBER_OPTEDOUT`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Permanent; The receiver opted out from receiving SMS all future SMS will be blocked. This error can only be solved by the subscriber sending an opt-in keyword like 'START'.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`104`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`EC_SENDER_UNREGISTERED`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Permanent; The originator used in sending the SMS is not allowed by the receiving mobile operator, this is usually caused by a lack of registration. Please consult our `}<a parentName="td" {...{
              "href": "https://support.messagebird.com/hc/en-us/articles/360017673738-Complete-list-of-sender-ID-availability-and-restrictions"
            }}>{`countries restrictions list`}</a>{` for more information on how to register it.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`105`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`EC_CONTENT_UNREGISTERED`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Permanent; The content of the SMS is not allowed by the receiving mobile operator, this is usually caused by a lack of registration. Please consult our `}<a parentName="td" {...{
              "href": "https://support.messagebird.com/hc/en-us/articles/360017673738-Complete-list-of-sender-ID-availability-and-restrictions"
            }}>{`countries restrictions list`}</a>{` for more information on how to register it.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`106`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`EC_CAMPAIGN_VOLUME_EXCEEDED`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Intermediate; The volume cap associated to the campaign of the SMS (via used originator) is reached at the receiving operator, and further SMS will be blocked until the volume cap resets. SMS to other operators are unaffected. You can find more information at our `}<a parentName="td" {...{
              "href": "https://support.messagebird.com/hc/en-us/articles/360017673738-Complete-list-of-sender-ID-availability-and-restrictions"
            }}>{`countries restrictions list`}</a>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`107`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`EC_CAMPAIGN_THROUGHPUT_EXCEEDED`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Intermediate; The maximum SMS rate associated to the campaign of the SMS (via used originator) is reached at the receiving operator, and excess SMS are being blocked. You can find more information at our `}<a parentName="td" {...{
              "href": "https://support.messagebird.com/hc/en-us/articles/360017673738-Complete-list-of-sender-ID-availability-and-restrictions"
            }}>{`countries restrictions list`}</a>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`110`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`EC_MESSAGE_FILTERED`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Permanent; The SMS originator or content is not allowed by the receiving mobile operator. Please consult our `}<a parentName="td" {...{
              "href": "https://support.messagebird.com/hc/en-us/articles/360017673738-Complete-list-of-sender-ID-availability-and-restrictions"
            }}>{`countries restrictions list`}</a>{` for more information on applicable regulations and forbidden content or originators.`}</td>
        </tr>
      </tbody>
    </table>
    <hr></hr>
    <h2>{`SMPP`}</h2>
    <p>{`The SMPP documentation describes everything you need to know about the MessageBird SMPP server. Keep in mind that it's important for you to already have a basic understanding of the SMPP protocol and to know how to set up your own SMPP client software.`}</p>
    <h2>{`The SMPP servers`}</h2>
    <h4>{`Server list`}</h4>
    <p>{`MessageBird has multiple SMPP servers for you to connect to. Each SMPP server offers the ability to connect to it via the regular (plaintext) method, a TLS v1.2, or better connection.`}</p>
    <p>{`Here is an overview of the available servers:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Hostname`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Port`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TLS port`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`smpp01.messagebird.com`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2775`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2776`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`smpp02.messagebird.com`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2775`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2776`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`smpp03.messagebird.com`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2775`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2776`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Username and password`}</h4>
    <p>{`Your account manager at MessageBird will give you a username (system_id) and password. If you haven’t received them yet or you still need to make the request, simply send us an email to `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we'll be happy to help you out. `}</p>
    <h4>{`Bindings and throughput`}</h4>
    <p>{`Whenever an SMPP account has been set up for you, you’ll receive the maximum amount of binds and throughput. In most cases, these values will be something like 3 binds and 50 messages per second.`}</p>
    <p>{`It might be interesting to note that these values are enforced on a per-server basis. That means that given the above example, you can set up 9 binds in total with a throughput of 150 messages per second when you connect to all servers.`}</p>
    <p>{`Keep in mind that for maintenance purposes we only guarantee either that one server is up at any given time, so we advise you to connect to all of them.`}</p>
    <h4>{`Bindings and relaying`}</h4>
    <p>{`MessageBird’s message relaying system is connection and server agnostic, so when you send an MT via a submit_sm PDU on connection A, you might receive the matching DLR in the form of a deliver_sm on connection B if both connections are bound with the same username.`}</p>
    <p>{`This is even true for connections made to different servers, so this scenario is also possible if connection A is made to the smpp01 server and connection B to the smpp02 server.`}</p>
    <h4>{`Security`}</h4>
    <p>{`If you connect to either server via a TLS connection, make sure to select TCP port 2776. Also be aware that the servers only accept an SSLv3 handshake method, so the old (legacy) SSLv2 handshake won’t work, even though your crypto stick plans to negotiate to use TLS1.0 or better.`}</p>
    <h2>{`Supported PDUs`}</h2>
    <p>{`The MessageBird SMPP servers support the following list of PDU types:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`PDU name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`command_id`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`bind_receiver`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0x00000001`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`bind_transceiver`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0x00000009`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`bind_transmitter`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0x00000002`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`deliver_sm_resp`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0x80000005`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`enquire_link`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0x00000015`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`generic_nack`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0x80000000`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`submit_sm`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0x00000004`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`unbind`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0x00000006`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`unbind_resp`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0x80000006`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`bind PDU`}</h2>
    <p>{`An SMPP bind_receiver, bind_transceiver, or bind_transmitter PDU request has a fixed set of fields. Most fields are irrelevant to us; actually, we only read the system_id, password, and interface_version fields and the rest is ignored.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`system_id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The username`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`password`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The password`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`system_type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`IGNORED`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`interface_version`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The SMPP protocol version you want to talk`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`addr_ton`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`IGNORED`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`addr_npi`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`IGNORED`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`address_range`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`IGNORED`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`interface_version`}</h4>
    <p>{`The MessageBird SMPP server supports SMPP protocol version 3.3, 3.4, and 5.0. Keep in mind that if you set your SMPP client to talk version 3.3 you are missing out on some features, most notably TLV information in the deliver_sm PDUs you receive.`}</p>
    <h2>{`submit_sm PDU`}</h2>
    <p>{`You can use the submit_sm PDU to send us your MT messages. Like a bind request, the submit_sm PDU request also has a couple of fields that are unused by our platform and can safely be ignored.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`service_type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`IGNORED`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`source_addr_ton`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Type of number for source_addr`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`source_addr_npi`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Numbering plan indicator for source_addr`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`source_addr`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Address of message origin`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`dest_addr_ton`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Type of number for destination_addr`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`dest_addr_npi`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Numbering plan indicator for destination_addr`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`destination_addr`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Address of message destination`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`esm_class`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Message mode and type`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`protocol_id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`IGNORED`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`priority_flag`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`IGNORED`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`schedule_delivery_time`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`IGNORED`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`validity_period`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The validity period of this message`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`registered_delivery`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The type if DLRs you want to receive`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`replace_if_present_flag`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`IGNORED`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`data_coding`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The coding of the short_message field`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`sm_default_msg_id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`IGNORED`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`sm_length`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The length of short_message field`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`short_message`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The contents of the MT`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`data_coding`}</h4>
    <p>{`The values for the data_coding field are not solidly declared in the SMPP spec, so each SMPP server is more or less required to give its own definition. Below is a list of data codings that we accept as input. Keep in mind that only the values of 0, 2 and 8 are used as-is, other values will be converted to the most appropriate encoding out of 0, 2 and 8 to ensure acceptance of your messages by the operators.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Encoding`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`0`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`GSM7`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ASCII`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`2`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8BIT`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`3`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ISO-8859-15 West European languages (Latin-9)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`6`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ISO-8859-5 Latin/Cyrillic`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`7`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ISO-8859-8 Latin/Hebrew`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`8`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`UTF-16BE (UCS2)`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`deliver_sm PDU`}</h2>
    <p>{`MOs and DLRs will be sent to you via a deliver_sm PDU. The fields are exactly the same as a submit_sm PDU, but there will be differences in which fields you are free to ignore, and which you are not.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Name Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`service_type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`IGNORED`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`source_addr_ton`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Type of number for source_addr`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`source_addr_npi`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Numbering plan indicator for source_addr`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`source_addr`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Address of message origin`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`dest_addr_ton`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Type of number for destination_addr`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`dest_addr_npi`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Numbering plan indicator for destination_addr`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`destination_addr`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Address of message destination`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`esm_class`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Message mode and type`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`protocol_id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`IGNORED`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`priority_flag`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`IGNORED`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`schedule_delivery_time`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`IGNORED`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`validity_period`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The validity period of this message`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`registered_delivery`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`IGNORED`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`replace_if_present_flag`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`IGNORED`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`data_coding`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The coding of the short_message field`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`sm_default_msg_id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`IGNORED`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`sm_length`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The length of short_message field`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`short_message`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The contents of the MT`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`data_coding`}</h4>
    <p>{`The values here are the same as in section `}<a parentName="p" {...{
        "href": "#submit_sm-pdu"
      }}>{`submit_sm`}</a>{`.`}</p>
    <h4>{`tlvs`}</h4>
    <p>{`DLR messages which are sent to you as a `}<inlineCode parentName="p">{`deliver_sm`}</inlineCode>{` may contain TLVs that you might be interested in. The following TLVs are supported as of the writing of this document:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Tag ID`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`receipted_message_id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0x001E`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The message_id referencing the same message_id that was returned in the submit_sm_resp of the MT (SMPP v5.0 spec: 4.8.4.47)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`message_state`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0x0427`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The final message state for a delivery receipt (SMPP v5.0 spec: 4.7.15 and 4.8.4.37)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`network_error_code`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0x0423`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The actual `}<a parentName="td" {...{
              "href": "/#sms-error-codes"
            }}>{`network error code`}</a>{` (SMPP v5.0 spec: 4.8.4.42)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`network_mccmnc *`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0x1560`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Operator MCCMNC of recipient MSISDN of related SMS; given when available.`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`TLVs marked with `}{`*`}{` are custom MessageBird SMPP tags.`}</p>
    <h4>{`short_message`}</h4>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <p>{`In the case of MO messages, the short_message field will contain the contents of the message that was sent. In the case of DLR messages, it will contain report information in the following format:`}</p>
        <p>{`The id parameter references the message_id that was returned in the `}<inlineCode parentName="p">{`submit_sm_resp`}</inlineCode>{` of your `}<inlineCode parentName="p">{`submit_sm`}</inlineCode>{` and it has the same value as the `}<inlineCode parentName="p">{`receipted_message_id`}</inlineCode>{` TLV.`}</p>
        <p>{`The state parameter references the `}<inlineCode parentName="p">{`message_state`}</inlineCode>{` TLV, although the `}<inlineCode parentName="p">{`message_state`}</inlineCode>{` contains a number pointing to a state where the state parameter in the `}<inlineCode parentName="p">{`short_message`}</inlineCode>{` is described by a word.`}</p>
        <p>{`The err parameter references error codes in section 3.3.4 and is the same as the `}<inlineCode parentName="p">{`network_error_code`}</inlineCode>{` TLV.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Examples</h4>
  <CodeSnippet code={CODE.SMPP1} mdxType="CodeSnippet" />
  <CodeSnippet code={CODE.SMPP2} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      