import styled from 'styled-components';
import { bool } from 'prop-types';

import { COLOR } from '../../../constants';
import SubItems from './TreeNav.SubItems';
import Item from './TreeNav.Item';
import { Heading } from './TreeNav.Heading';

const TreeNav = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  color: ${COLOR.MIDGREY};
`;

TreeNav.propTypes = {
  sticky: bool,
};

TreeNav.defaultProps = {
  sticky: false,
};

TreeNav.SubItems = SubItems;
TreeNav.Item = Item;
TreeNav.Heading = Heading;

export default TreeNav;
