import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Docs.tsx";
import Notification from 'components/Notification';
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
import RunkitCodeBlock from 'components/RunkitCodeBlock';
import * as CODE from 'components/RunkitCodeBlock/constants';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Send a Verification Token`}</h1>
    <p>{`In this Verify API Quickstart, you'll learn how to send your first verification token from your web application using the MessageBird Verify API.`}</p>
    <h2>{`Step 1: Set up your test environment`}</h2>
    <p>{`To start testing with the official NodeJS SDK for MessageBird’s Verify API, you need to first set up your NodeJS environment.`}</p>
    <p>{`The following command will install MessageBird.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install messagebird
`}</code></pre>
    <p>{`Now, let's create a `}<inlineCode parentName="p">{`verify.js`}</inlineCode>{` file in the directory of your `}<inlineCode parentName="p">{`package.json`}</inlineCode>{` file`}</p>
    <Notification type="INFO" mdxType="Notification">
Want to learn how to set up your Python development environment? Check out the <a href="tutorials/setup-local-dev-environment-node">NodeJS tutorial</a>
    </Notification>
    <h2>{`Step 2: Send your first verification token`}</h2>
    <p>{`Next, enter `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`your credentials`}</a>{` and mobile number - as both the `}<inlineCode parentName="p">{`originator`}</inlineCode>{` and `}<inlineCode parentName="p">{`recipient`}</inlineCode>{` - to the snippet below and copy it to your `}<inlineCode parentName="p">{`verify.js`}</inlineCode>{` file: `}</p>
    <RunkitCodeBlock source={CODE.SEND_VERIFY} mdxType="RunkitCodeBlock" />
    <Notification type="INFO" mdxType="Notification">
You can access and manage your credentials in the <a href="https://dashboard.messagebird.com/en/developers/access">MessageBird Developer Dashboard</a>.
    </Notification>
    <p>{`Now, you can run the application by hitting `}<inlineCode parentName="p">{`run`}</inlineCode>{` above or if you're running the example from your project, enter following command line:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`node verify.js
`}</code></pre>
    <p>{`Good job! You’ve sent your first verification token with the official NodeJS SDK for MessageBird’s Verify API.`}</p>
    <h2>{`Step 3: View the response`}</h2>
    <p>{`Now, if you've run the example from your own project, let’s view MessageBird’s HTTP response to your API call.`}</p>
    <p>{` If the set up was correct, you'll receive a response similar to this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    id: 'd015e66b7f1f459892130425aabb99e1',
    href: 'https://rest.messagebird.com/verify/d015e66b7f1f459892130425aabb99e1',
    recipient: 31612345678,
    reference: null,
    messages: {
        href: 'https://rest.messagebird.com/messages/76ccc5cc2c42441595d71c4d646ef512'
    },
    status: 'sent',
    createdDatetime: '2018-09-08T13:06:03+00:00',
    validUntilDatetime: '2018-09-08T13:06:33+00:00'
}
`}</code></pre>
    <p>{`If something went wrong, you’ll receive an error response from the MessageBird API. Don't worry, simply head over to `}<a parentName="p" {...{
        "href": "/docs/verify/troubleshooting"
      }}>{`Verify API Troubleshooting`}</a>{` to understand the error.`}</p>
    <p>{`Congrats! You can now programmatically send a verification token with the MessageBird Verify API using NodeJS.`}</p>
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'Programmatically verify a token via the API',
      to: '/docs/verify/verify-a-token-node'
    }, {
      name: 'Verify API Use Case Tutorials and Sample Projects',
      to: '/tutorials?filter=verify'
    }, {
      name: 'Verify API Reference',
      to: '/api/verify'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      