export const VOICE_ENDPOINT = {
  json: `https://voice.messagebird.com`,
};

export const JSON_REQUEST = {
  json: `{
  "steps": [
    {
      "id": "foo",
      "action": "transfer",
      "options": {
        "destination": "31612345678"
      }
    },
    {
      "id": "bar",
      "action": "hangup"
    }
  ]
}`,
};

export const ERROR_OBJECT = {
  json: `{
    "errors": [
      {
        "description": "The 'steps' parameter is required.",
        "code": 11
      }
    ]
  }`,
};

export const CALLFLOW_URI = {
  json: `https://voice.messagebird.com/call-flows`,
};

export const CALLFLOW_METHODS = {
  json: `GET /call-flows/
POST /call-flows/
GET /call-flows/{id}
PUT /call-flows/{id}
DELETE /call-flows/{id}
GET /call-flows/{id}/numbers
POST /call-flows/{id}/numbers
PUT /call-flows/{id}/numbers`,
};

export const CALLFLOW_EXAMPLE = {
  json: `{
  "id": "de3ed163-d5fc-45f4-b8c4-7eea7458c635",
  "record": true,
  "steps": [
    {
      "id": "3538a6b8-5a2e-4537-8745-f72def6bd393",
      "action": "transfer",
      "options": {
        "destination": "31612345678"
      },
    }
  ],
  "createdAt": "2017-03-06T13:34:14Z",
  "updatedAt": "2017-03-06T13:34:14Z"
}`,
};

export const CALLFLOW_SAY_STEP = {
  json: `{
    "steps": [
      {
        "action": "say",
        "options": {
          "payload": "Hello, I am a robot. This is an example message.",
          "language": "en-GB",
          "voice": "male",
          "repeat": 2
        },
        "onKeypressGoto": "bar",
        "onKeypressVar": "foo"
      }
    ]
  }`,
};

export const CALLFLOW_RECORD_STEP = {
  json: `{
    "steps": [
      {
        "action": "record",
        "options": {
          "onFinish": "https://messagebird.com/onfinish",
          "timeout": 10,
          "maxLength": 30,
          "finishOnKey": "any",
          "transcribe": true,
          "transcribeLanguage": "en-UK"
        }
      }
    ]
  }`,
};

export const CALLFLOW_MASKEDTRANSFER_STEP = {
  json: `{
    "steps": [
      {
        "action": "maskedTransfer",
        "options": {
          "numbers": ["31612345678", "31612345679"]
        }
      }
    ]
  }`,
};

export const CALLFLOW_FETCHCALLFLOW_STEP = {
  json: `{
    "steps": [
      {
        "action": "fetchCallFlow",
        "options": {
          "url": "https://messagebird.com/fetchcallflow",
          "timeout": 30
        }
      }
    ]
  }`,
};

export const CALLFLOW_TRANSFER_STEP = {
  json: `{
    "steps": [
      {
        "action": "transfer",
        "options": {
          "destination": "31612345678",
          "record": "both"
        }
      }
    ]
  }`,
};

export const CALLFLOW_HANGUP_STEP = {
  json: `{
    "steps": [
      {
        "action": "hangup"
      }
    ]
  }`,
};

export const CALLFLOW_PLAY_STEP = {
  json: `{
    "steps": [
      {
        "action": "play",
        "options": {
          "media": "https://messagebird.com/play.wav"        
        },
        "onKeypressGoto": "bar",
        "onKeypressVar": "foo"
      }
    ]
  }`,
};

export const CALLFLOW_PAUSE_STEP = {
  json: `{
    "steps": [
      {
        "action": "pause",
        "options": {
          "length": "2s"      
        },
        "onKeypressGoto": "bar",
        "onKeypressVar": "foo"
      }
    ]
  }`,
};

export const CALLFLOW_SENDKEYS_STEP = {
  json: `{
    "steps": [
      {
        "action": "sendKeys",
        "options": {
          "keys": "*101#",        
          "duration": 500,        
          "interval": 2000        
        }
      }
    ]
  }`,
};

export const LISTCALLFLOW_DEF = {
  json: `GET https://voice.messagebird.com/call-flows`,
};

export const LISTCALLFLOW_REQUEST = {
  curl: `curl -X GET "https://voice.messagebird.com/call-flows" \\
  -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM"`,
  java: `import com.messagebird.MessageBirdClient;
import com.messagebird.MessageBirdService;
import com.messagebird.MessageBirdServiceImpl;
import com.messagebird.exceptions.GeneralException;
import com.messagebird.exceptions.UnauthorizedException;
import com.messagebird.objects.voicecalls.VoiceCallFlowList;

public class ExampleListCallFlows {
    public static void main(String[] args) {
        final MessageBirdService messageBirdService = new MessageBirdServiceImpl("test_gshuPaZoeEG6ovbc8M79w0QyM");
        final MessageBirdClient messageBirdClient = new MessageBirdClient(messageBirdService);

        try {
            VoiceCallFlowList voiceCallFlowList = messageBirdClient.listVoiceCallFlows(0, 100);
            System.out.println(voiceCallFlowList.getItems());
        } catch (UnauthorizedException unauthorized) {
            if (unauthorized.getErrors() != null) {
                System.out.println(unauthorized.getErrors().toString());
            }
            unauthorized.printStackTrace();
        } catch (GeneralException generalException) {
            if (generalException.getErrors() != null) {
                System.out.println(generalException.getErrors().toString());
            }
            generalException.printStackTrace();
        }
    }
}`,
  csharp: `using System;
using MessageBird;
using MessageBird.Objects.Voice;

namespace Examples
{
    class ExampleListCallFlows
    {
        static void Main(string[] args)
        {
            Client client = Client.CreateDefault("test_gshuPaZoeEG6ovbc8M79w0QyM");

            CallFlowList callFlowList = client.ListCallFlows();
            Console.WriteLine(callFlowList.Data.Count);
        }
    }
}`,
  go: `package main

import (
    "log"
    "github.com/messagebird/go-rest-api"
    "github.com/messagebird/go-rest-api/voice"
)

func main() {
    accessKey := "test_gshuPaZoeEG6ovbc8M79w0QyM"

    client := messagebird.New(accessKey)

    paginator := voice.CallFlows(client)

    page, err := paginator.NextPage()

    if err != nil {
        log.Println(err)
    }
    log.Println(page)
}`,
  python: `
import messagebird

def main():
    client = messagebird.Client('test_gshuPaZoeEG6ovbc8M79w0QyM')
    try:
        callList = client.call_flow_list()
        if callList.items is not None:
            print('  Containing the the following items:')
            for item in callList.items:
                print('  {')
                print('    id                : %s' % item.id)
                print('    status            : %s' % item.status)
                print('  },')
        else:
            print('  With an empty response.')

    except messagebird.client.ErrorException as e:
        print('\nAn error occurred while creating a call:\n')

        for error in e.errors:
            print('  code        : %d' % error.code)
            print('  description : %s' % error.description)

if __name__ == "__main__":
    main()
  `,
  node: `
var messagebird = require('messagebird')('test_gshuPaZoeEG6ovbc8M79w0QyM');
messagebird.callflows.list(function (err, response) {
  if (err) {
    return console.log(err);
  }
  console.log(response);
});
`,
  php: `
$messageBird = new \MessageBird\Client('YOUR_ACCESS_KEY');
$result = $messageBird->voiceCallFlows->getList(array('offset' => 100, 'limit' => 30));
var_dump($result);`,
};

export const LISTCALLFLOW_RESPONSE = {
  json: `{
  "data": [
    {
      "id": "de3ed163-d5fc-45f4-b8c4-7eea7458c635",
      "record": false,
      "steps": [
        {
          "id": "3538a6b8-5a2e-4537-8745-f72def6bd393",
          "action": "transfer",
          "options": {
            "destination": "31612345678"
          }
        }
      ],
      "createdAt": "2017-03-06T13:34:14Z",
      "updatedAt": "2017-03-06T13:34:14Z",
      "_links": {
        "self": "/call-flows/de3ed163-d5fc-45f4-b8c4-7eea7458c635"
      }
    }
  ],
  "_links": {
    "self": "/call-flows?page=1"
  },
  "pagination": {
    "totalCount": 1,
    "pageCount": 1,
    "currentPage": 1,
    "perPage": 10
  }
}`,
};

export const VIEWCALLFLOW_DEF = {
  json: `GET https://voice.messagebird.com/call-flows/{callflowID}`,
};

export const VIEWCALLFLOW_REQUEST = {
  curl: `curl -X GET https://voice.messagebird.com/call-flows/de3ed163-d5fc-45f4-b8c4-7eea7458c635 \\
  -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM"`,
  java: `import com.messagebird.MessageBirdClient;
import com.messagebird.MessageBirdService;
import com.messagebird.MessageBirdServiceImpl;
import com.messagebird.exceptions.GeneralException;
import com.messagebird.exceptions.NotFoundException;
import com.messagebird.exceptions.UnauthorizedException;
import com.messagebird.objects.voicecalls.VoiceCallFlowList;
import com.messagebird.objects.voicecalls.VoiceCallFlowResponse;

public class ExampleViewCallFlow {
    public static void main(String[] args) {
        final MessageBirdService messageBirdService = new MessageBirdServiceImpl("test_gshuPaZoeEG6ovbc8M79w0QyM");
        final MessageBirdClient messageBirdClient = new MessageBirdClient(messageBirdService);

        try {
            VoiceCallFlowResponse voiceCallFlowResponse = messageBirdClient.viewVoiceCallFlow("b0b2af5f-a475-40a9-a244-a9d58e7352ed");
            System.out.println(voiceCallFlowResponse);
        } catch (NotFoundException notFoundException) {
            if (notFoundException.getErrors() != null) {
                System.out.println(notFoundException.getErrors().toString());
            }
            notFoundException.printStackTrace();
        } catch (UnauthorizedException unauthorized) {
            if (unauthorized.getErrors() != null) {
                System.out.println(unauthorized.getErrors().toString());
            }
            unauthorized.printStackTrace();
        } catch (GeneralException generalException) {
            if (generalException.getErrors() != null) {
                System.out.println(generalException.getErrors().toString());
            }
            generalException.printStackTrace();
        }
    }
}`,
  csharp: `using System;
using MessageBird;
using MessageBird.Objects.Voice;

namespace Examples
{
    class ExampleViewCallFlows
    {
        static void Main(string[] args)
        {
            Client client = Client.CreateDefault("test_gshuPaZoeEG6ovbc8M79w0QyM");

            VoiceResponse<CallFlow> voiceResponse = client.ViewCallFlow("b0b2af5f-a475-40a9-a244-a9d58e7352ed");
            Console.WriteLine(voiceResponse.Data[0].Id);
        }
    }
}`,
  go: `package main

import (
    "log"
    "github.com/messagebird/go-rest-api"
    "github.com/messagebird/go-rest-api/voice"
)

func main() {
    accessKey := "test_gshuPaZoeEG6ovbc8M79w0QyM"

    client := messagebird.New(accessKey)

    callflow, err := voice.CallFlowByID(client, "b0b2af5f-a475-40a9-a244-a9d58e7352ed");

    if err != nil {
        log.Println(err)
    }
    log.Println(callflow)
}`,
  python: `
import messagebird

def main():
    client = messagebird.Client('test_gshuPaZoeEG6ovbc8M79w0QyM')

    try:
        call = client.call_flow("b0b2af5f-a475-40a9-a244-a9d58e7352ed")
        print('  id                : %s' % call.id)
        print('  steps             : %s' % call.steps)

    except messagebird.client.ErrorException as e:
        print('\nAn error occurred while creating a call:\n')

        for error in e.errors:
            print('  code        : %d' % error.code)
            print('  description : %s' % error.description)

if __name__ == "__main__":
    main()
`,
  node: `
var messagebird = require('messagebird')('test_gshuPaZoeEG6ovbc8M79w0QyM');
messagebird.callflows.read('b0b2af5f-a475-40a9-a244-a9d58e7352ed', function (err, response) {
  if (err) {
    return console.log(err);
  }
  console.log(response);
});
`,
  php: `
$messageBird = new \MessageBird\Client('YOUR_ACCESS_KEY');
$result = $messageBird->voiceCallsFlows->read('<CALL_ID>'); 
var_dump($result);
`,
};

export const VIEWCALLFLOW_RESPONSE = {
  json: `{
  "data": [
    {
      "id": "de3ed163-d5fc-45f4-b8c4-7eea7458c635",
      "record": false,
      "steps": [
        {
          "id": "3538a6b8-5a2e-4537-8745-f72def6bd393",
          "action": "transfer",
          "options": {
            "destination": "31611223344"
          }
        }
      ],
      "createdAt": "2017-03-06T13:34:14Z",
      "updatedAt": "2017-03-06T15:02:38Z",
    }
  ],
  "_links": {
    "self": "/call-flows/de3ed163-d5fc-45f4-b8c4-7eea7458c635"
  }
}`,
};

export const CREATECALLFLOW_DEF = {
  json: `POST https://voice.messagebird.com/call-flows`,
};

export const CREATECALLFLOW_REQUEST = {
  curl: `curl -X POST https://voice.messagebird.com/call-flows \\
  -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM" \\
  -H "Content-Type: application/json" \\
  -d $'{
    "record": true,
    "steps": [
      {
        "options": {
          "destination": "31612345678"
        },
        "action": "transfer"
      }
    ]
  }'`,
  java: `import com.messagebird.MessageBirdClient;
import com.messagebird.MessageBirdService;
import com.messagebird.MessageBirdServiceImpl;
import com.messagebird.exceptions.GeneralException;
import com.messagebird.exceptions.UnauthorizedException;
import com.messagebird.objects.VoiceStep;
import com.messagebird.objects.VoiceStepOption;
import com.messagebird.objects.voicecalls.VoiceCallFlowRequest;
import com.messagebird.objects.voicecalls.VoiceCallFlowResponse;

import java.util.List;

public class ExampleCreateCallFlow {
    public static void main(String[] args) {
        final MessageBirdService messageBirdService = new MessageBirdServiceImpl("test_gshuPaZoeEG6ovbc8M79w0QyM");
        final MessageBirdClient messageBirdClient = new MessageBirdClient(messageBirdService);

        try {
            VoiceStepOption options = getVoiceStepOption("31612345678");
            VoiceStep step = getVoiceStep("transfer", options);
            VoiceCallFlowRequest voiceCallFlowRequest = getVoiceCallFlowRequest(true, List.of(step));

            VoiceCallFlowResponse voiceCallFlowResponse = messageBirdClient.sendVoiceCallFlow(voiceCallFlowRequest);
            System.out.println(voiceCallFlowResponse);
        } catch (UnauthorizedException unauthorized) {
            if (unauthorized.getErrors() != null) {
                System.out.println(unauthorized.getErrors().toString());
            }
            unauthorized.printStackTrace();
        } catch (GeneralException generalException) {
            if (generalException.getErrors() != null) {
                System.out.println(generalException.getErrors().toString());
            }
            generalException.printStackTrace();
        }
    }

    private static VoiceStepOption getVoiceStepOption(String destination) {
        VoiceStepOption options = new VoiceStepOption();
        options.setDestination(destination);
        return options;
    }

    private static VoiceStep getVoiceStep(String action, VoiceStepOption options) {
        VoiceStep step = new VoiceStep();
        step.setAction(action);
        step.setOptions(options);
        return step;
    }

    private static VoiceCallFlowRequest getVoiceCallFlowRequest(boolean record, List<VoiceStep> steps) {
        VoiceCallFlowRequest voiceCallFlowRequest = new VoiceCallFlowRequest();
        voiceCallFlowRequest.setRecord(record);
        voiceCallFlowRequest.setSteps(steps);
        return voiceCallFlowRequest;
    }
}`,
  csharp: `using System;
using System.Collections.Generic;
using MessageBird;
using MessageBird.Objects.Voice;

namespace Examples
{
    class ExampleCreateCallFlows
    {
        static void Main(string[] args)
        {
            Client client = Client.CreateDefault("test_gshuPaZoeEG6ovbc8M79w0QyM");

            CallFlow request = new CallFlow
            {
                Steps = new List<Step>
                {
                    new Step
                    {
                        Action = "transfer",
                        Options = new Options
                        {
                            Destination = "31612345678",
                        }
                    }
                }
            };

            VoiceResponse<CallFlow> voiceResponse = client.CreateCallFlow(request);
            Console.WriteLine(voiceResponse.Data[0].Id);
        }
    }
}`,
  go: `package main

import (
    "log"
    "github.com/messagebird/go-rest-api"
    "github.com/messagebird/go-rest-api/voice"
)

func main() {
    accessKey := "test_gshuPaZoeEG6ovbc8M79w0QyM"

    client := messagebird.New(accessKey)

    callflow := voice.CallFlow{
        Record: true,
        Steps: []voice.CallFlowStep{
            &voice.CallFlowTransferStep{
                Destination: "31612345678",
            },
        },
    }

    err := callflow.Create(client)

    if err != nil {
        log.Println(err)
    }
    log.Println(callflow)
}`,
  python: `
import messagebird, json

def main():
    client = messagebird.Client('test_gshuPaZoeEG6ovbc8M79w0QyM')
    callFlow = {}
    callFlow["steps"] = '[{"options": {"destination": "31612345678"}, "action": "transfer"}]'
    callFlow["steps"] = json.loads(str(callFlow["steps"]).strip('\''))

    try:
        call = client.call_flow_create(**callFlow)
        print('  id                : %s' % call.id)

    except messagebird.client.ErrorException as e:
        print('\nAn error occurred while creating a call:\n')

        for error in e.errors:
            print('  code        : %d' % error.code)
            print('  description : %s' % error.description)

if __name__ == "__main__":
    main()
`,
  node: `
var messagebird = require('messagebird')('test_gshuPaZoeEG6ovbc8M79w0QyM');
var params = {
  'steps': [{
     'action': 'transfer',
     'options': {
            'language': 'en-GB',
            'voice': 'male',
            'payload': 'This is a journey into sound. Good bye!',
            'destination': '31612345678'
        },
    }]
};
messagebird.callflows.create(params, function (err, response) {
  if (err) {
    return console.log(err);
  }
  console.log(response);
});
`,
  php: `
$messageBird = new \MessageBird\Client('YOUR_ACCESS_KEY');
$callFlow = new \MessageBird\Objects\Voice\CallFlow();
$step = new \MessageBird\Objects\Voice\Step();
$step->action = 'say';
$step->options = array(
    'payload' => 'This is a journey into sound.',
    'language' => 'en-GB',
    'voice' => 'male',
;
$callFlow->steps = array($step);
$call->callFlow = $callFlow;

$result = $messageBird->voiceCallFlows->create($callflow);
var_dump($result);`,
};

export const CREATECALLFLOW_RESPONSE = {
  json: `{
  "data": [
    {
      "id": "de3ed163-d5fc-45f4-b8c4-7eea7458c635",
      "record": true,
      "steps": [
        {
          "id": "2fa1383e-6f21-4e6f-8c36-0920c3d0730b",
          "action": "transfer",
          "options": {
            "destination": "31612345678"
          }
        }
      ],
      "createdAt": "2017-03-06T14:52:22Z",
      "updatedAt": "2017-03-06T14:52:22Z"
    }
  ],
  "_links": {
    "self": "/call-flows/de3ed163-d5fc-45f4-b8c4-7eea7458c635"
  }
}`,
};

export const IF_TAG_EXAMPLE = {
  xml: `<CallFlow>
    <Say language="en-US" voice="male" onKeypressVar="foo" onKeypressGoto="bar">Hello! Press 1, 2, or 3 to continue.</Say>
    <Pause length="10s" onKeypressVar="foo" onKeypressGoto="bar"/>
    <Say language="en-US" voice="male">You did not press anything. Good bye!</Say>
    <Hangup/>

    <If id="bar" condition="foo == 1">
        <Say language="en-US" voice="male">You pressed 1. Bye!</Say>
        <Hangup/>
    </If>
    <If condition="foo == 2">
        <Say language="en-US" voice="male">You pressed 2. Bye!</Say>
        <Hangup/>
    </If>
    <If condition="foo == 3">
        <Say language="en-US" voice="male">You pressed 3. Bye!</Say>
        <Hangup/>
    </If>

    <Say language="en-US" voice="male">You pressed something, but it wasn't 1, 2 or 3. Bye!</Say>
</CallFlow>`,
};

export const RECORD_TAG_EXAMPLE = {
  xml: `<CallFlow>
    <Say language="en-US" voice="male">
        Unfortunately we are not able to answer the phone right now. Please leave your name and number.
    </Say>
    <Record maxLength="120" timeout="3" transcribe="true" transcribeLanguage="en-US" />
    <Say language="en-US" voice="male">
        Thanks for calling, we will get back to you shortly.
     </Say>
    <Hangup/>
</CallFlow>`,
};

export const SENDKEYS_TAG_EXAMPLE = {
  curl: `curl -X POST https://voice.messagebird.com/calls \\
  -H "Content-Type: application/json" \\
  -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM" \\
  -d $'{
    "source": "31644556677",
    "destination": "31626146604",
    "callFlow": {
      "steps": [{
        "action": "sendKeys",
          "options": {
              "keys": "1234567890"
          }
        }]
      },
      "webhook": {
        "url": "https://example.com",
        "token": "foobar"
      }
  }'`,
};

export const IFTAG_REQUEST = {
  curl: `curl -X POST https://voice.messagebird.com/call-flows \\
   -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM" \\
   -H "Content-Type: application/xml" \\
   -d $'<?xml version="1.0" encoding="UTF-8"?>
<CallFlow>
  <Say language="en-US" voice="male" onKeypressVar="foo" onKeypressGoto="bar">Hello! Press 1, 2, or 3 to continue.</Say>
  <Pause length="10s" onKeypressVar="foo" onKeypressGoto="bar"/>
  <Say language="en-US" voice="male">You did not press anything. Good bye!</Say>
<Hangup/>

    <If id="bar" condition="foo == 1">
        <Say language="en-US" voice="male">You pressed 1. Bye!</Say>
        <Hangup/>
    </If>
    <If condition="foo == 2">
        <Say language="en-US" voice="male">You pressed 2. Bye!</Say>
        <Hangup/>
    </If>
    <If condition="foo == 3">
        <Say language="en-US" voice="male">You pressed 3. Bye!</Say>
        <Hangup/>
    </If>

    <Say language="en-US" voice="male">You pressed something, but it was not 1, 2 or 3. Bye!</Say>
</CallFlow>'`,
};

export const IFTAG_RESPONSE = {
  json: `{
  "data": [
    {
      "id": "9e568623-a158-45f5-aa71-d877349abafc",
      "record": false,
      "steps": [
        {
          "id": "800a9655-92b0-4f80-bdf7-63d5c7650ec6",
          "action": "say",
          "options": {
            "payload": "Hello! Press 1, 2, or 3 to continue.",
            "language": "en-US",
            "voice": "male"
          },
          "onKeypressGoto": "bar",
          "onKeypressVar": "foo"
        },
        {
          "id": "71090bab-0683-438b-af57-490d7cdb94c8",
          "action": "pause",
          "options": {
            "length": "10s"
          },
          "onKeypressGoto": "bar",
          "onKeypressVar": "foo"
        },
        {
          "id": "184019d3-5f58-4e7a-a770-cd1401440367",
          "action": "say",
          "options": {
            "payload": "You didn't press anything. Good bye!",
            "language": "en-US",
            "voice": "male"
          }
        },
        {
          "id": "3b903119-821e-4e4f-baa3-83beb898f427",
          "action": "hangup"
        },
        {
          "id": "bar",
          "action": "say",
          "options": {
            "payload": "You pressed 1. Bye!",
            "language": "en-US",
            "voice": "male"
          },
          "conditions": [
            {
              "variable": "foo",
              "operator": "==",
              "value": "1"
            }
          ]
        },
        {
          "id": "8570f016-c27c-44c1-9327-236df34684d3",
          "action": "hangup",
          "conditions": [
            {
              "variable": "foo",
              "operator": "==",
              "value": "1"
            }
          ]
        },
        {
          "id": "a5fadcfd-1c2a-4017-9e5a-72c5e4df8007",
          "action": "say",
          "options": {
            "payload": "You pressed 2. Bye!",
            "language": "en-US",
            "voice": "male"
          },
          "conditions": [
            {
              "variable": "foo",
              "operator": "==",
              "value": "2"
            }
          ]
        },
        {
          "id": "a144f0e6-3702-4990-a63e-27d497ef801b",
          "action": "hangup",
          "conditions": [
            {
              "variable": "foo",
              "operator": "==",
              "value": "2"
            }
          ]
        },
        {
          "id": "4c6f803b-2e2f-458d-94a6-6698d53b0cc7",
          "action": "say",
          "options": {
            "payload": "You pressed 3. Bye!",
            "language": "en-US",
            "voice": "male"
          },
          "conditions": [
            {
              "variable": "foo",
              "operator": "==",
              "value": "3"
            }
          ]
        },
        {
          "id": "a9bbba8b-ed1b-4fa4-bdae-85c668723103",
          "action": "hangup",
          "conditions": [
            {
              "variable": "foo",
              "operator": "==",
              "value": "3"
            }
          ]
        },
        {
          "id": "38eb5a4c-3a2a-4475-a056-e8ece4e57e17",
          "action": "say",
          "options": {
            "payload": "You pressed something, but it wasn't 1, 2 or 3. Bye!",
            "language": "en-US",
            "voice": "male"
          }
        }
      ],
      "createdAt": "2017-03-13T13:39:52Z",
      "updatedAt": "2017-03-13T13:39:52Z"
    }
  ],
  "_links": {
    "self": "/v1/call-flows/9e568623-a158-45f5-aa71-d877349abafc"
  }
}`,
};

export const UPDATECALLFLOW_DEF = {
  json: `PUT https://voice.messagebird.com/call-flows/{callflowID}`,
};

export const UPDATECALLFLOW_REQUEST = {
  curl: `curl -X PUT https://voice.messagebird.com/call-flows/de3ed163-d5fc-45f4-b8c4-7eea7458c635 \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -d $'{
    "steps": [
      {
        "options": {
          "destination": "31611223344"
        },
        "action": "transfer"
      }
    ]
  }'`,
  java: `import com.messagebird.MessageBirdClient;
import com.messagebird.MessageBirdService;
import com.messagebird.MessageBirdServiceImpl;
import com.messagebird.exceptions.GeneralException;
import com.messagebird.exceptions.UnauthorizedException;
import com.messagebird.objects.VoiceStep;
import com.messagebird.objects.VoiceStepOption;
import com.messagebird.objects.voicecalls.VoiceCallFlowRequest;
import com.messagebird.objects.voicecalls.VoiceCallFlowResponse;

import java.util.List;

public class ExampleUpdateCallFlow {
    public static void main(String[] args) {
        final MessageBirdService messageBirdService = new MessageBirdServiceImpl("test_gshuPaZoeEG6ovbc8M79w0QyM");
        final MessageBirdClient messageBirdClient = new MessageBirdClient(messageBirdService);

        try {
            VoiceStepOption options = getVoiceStepOption("31612345678");
            VoiceStep step = getVoiceStep("transfer", options);
            VoiceCallFlowRequest voiceCallFlowRequest = getVoiceCallFlowRequest(true, List.of(step));

            VoiceCallFlowResponse voiceCallFlowResponse = messageBirdClient.updateVoiceCallFlow("782210e9-d8cb-45c2-9733-b0161dd37b85", voiceCallFlowRequest);
            System.out.println(voiceCallFlowResponse);
        } catch (UnauthorizedException unauthorized) {
            if (unauthorized.getErrors() != null) {
                System.out.println(unauthorized.getErrors().toString());
            }
            unauthorized.printStackTrace();
        } catch (GeneralException generalException) {
            if (generalException.getErrors() != null) {
                System.out.println(generalException.getErrors().toString());
            }
            generalException.printStackTrace();
        }
    }

    private static VoiceStepOption getVoiceStepOption(String destination) {
        VoiceStepOption options = new VoiceStepOption();
        options.setDestination(destination);
        return options;
    }

    private static VoiceStep getVoiceStep(String action, VoiceStepOption options) {
        VoiceStep step = new VoiceStep();
        step.setAction(action);
        step.setOptions(options);
        return step;
    }

    private static VoiceCallFlowRequest getVoiceCallFlowRequest(boolean record, List<VoiceStep> steps) {
        VoiceCallFlowRequest voiceCallFlowRequest = new VoiceCallFlowRequest();
        voiceCallFlowRequest.setRecord(record);
        voiceCallFlowRequest.setSteps(steps);
        return voiceCallFlowRequest;
    }
}`,
  csharp: `using System;
using System.Collections.Generic;
using MessageBird;
using MessageBird.Objects.Voice;

namespace Examples
{
    class ExampleUpdateCallFlows
    {
        static void Main(string[] args)
        {
            Client client = Client.CreateDefault("test_gshuPaZoeEG6ovbc8M79w0QyM");

            CallFlow request = new CallFlow
            {
                Steps = new List<Step>
                {
                    new Step
                    {
                        Action = "transfer",
                        Options = new Options
                        {
                            Destination = "31611223344",
                        }
                    }
                }
            };

            VoiceResponse<CallFlow> voiceResponse = client.UpdateCallFlow("d48ff2bc-3b45-4a34-98de-a9d754aa2c44", request);
            Console.WriteLine(voiceResponse.Data[0].Id);
        }
    }
}`,
  go: `package main

import (
    "log"
    "github.com/messagebird/go-rest-api"
    "github.com/messagebird/go-rest-api/voice"
)

func main() {
    accessKey := "test_gshuPaZoeEG6ovbc8M79w0QyM"

    client := messagebird.New(accessKey)

    callflow, err := voice.CallFlowByID(client, "b0b2af5f-a475-40a9-a244-a9d58e7352ed");

    if err != nil {
        log.Println(err)
    }

    newCallflow := voice.CallFlow{
        ID: callflow.ID,
        Record: true,
        Steps: []voice.CallFlowStep{
            &voice.CallFlowTransferStep{
                Destination: "31611223344",
            },
        },
    }

    err = newCallflow.Update(client)

    if err != nil {
        log.Println(err)
    }
    log.Println(voice.CallFlowByID(client, "b0b2af5f-a475-40a9-a244-a9d58e7352ed"))
}`,
  python: `
import messagebird, json

def main():
    client = messagebird.Client('test_gshuPaZoeEG6ovbc8M79w0QyM')
    callFlow = {}
    callFlow["id"] = "f5fe1d3c-b42b-45f2-8625-fe9294cedf55"
    callFlow["steps"] = '[{"options": {"destination": "31611223344"}, "action": "record"}]'
    callFlow["record"] = False
    callFlow["default"] = False
    callFlow["steps"] = json.loads(str(callFlow["steps"]).strip('\''))

    try:
        call = client.call_flow_update(**callFlow)
        print('  id               : %s' % call.id)
        print('  steps            : %s' % call.steps)

    except messagebird.client.ErrorException as e:
        print('\nAn error occurred while creating a call:\n')

        for error in e.errors:
            print('  code        : %d' % error.code)
            print('  description : %s' % error.description)

if __name__ == "__main__":
    main()
`,
  node: `
var messagebird = require('messagebird')('test_gshuPaZoeEG6ovbc8M79w0QyM');
var params = {
  'steps': [{
     'action': 'say',
     'options': {
            'language': 'en-GB',
            'voice': 'male',
            'payload': 'This is an update to callflow!',
            'destination': '31611223344'
        },
    }]
};
messagebird.callflows.update('f5fe1d3c-b42b-45f2-8625-fe9294cedf55',params, function (err, response) {
  if (err) {
    return console.log(err);
  }
  console.log(response);
});
`,
  php: `
$messageBird = new \MessageBird\Client('YOUR_ACCESS_KEY');
$callFlow = new \MessageBird\Objects\Voice\CallFlow();
$result = $messageBird->voiceCallFlows->update($callFlow, '<CALL_ID>');
var_dump($result);`,
};

export const UPDATECALLFLOW_RESPONSE = {
  json: `{
  "data": [
    {
      "id": "de3ed163-d5fc-45f4-b8c4-7eea7458c635",
      "record": false,
      "steps": [
        {
          "id": "3538a6b8-5a2e-4537-8745-f72def6bd393",
          "action": "transfer",
          "options": {
            "destination": "31611223344"
          }
        }
      ],
      "createdAt": "2017-03-06T13:34:14Z",
      "updatedAt": "2017-03-06T15:02:38Z"
    }
  ],
  "_links": {
    "self": "/call-flows/de3ed163-d5fc-45f4-b8c4-7eea7458c635"
  }
}`,
};

export const DELETECALLFLOW_DEF = {
  json: `DELETE https://voice.messagebird.com/call-flows/{callflowID}`,
};

export const DELETECALLFLOW_REQUEST = {
  curl: `curl -X DELETE https://voice.messagebird.com/call-flows/de3ed163-d5fc-45f4-b8c4-7eea7458c635 \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM'`,
  java: `import com.messagebird.MessageBirdClient;
import com.messagebird.MessageBirdService;
import com.messagebird.MessageBirdServiceImpl;
import com.messagebird.exceptions.GeneralException;
import com.messagebird.exceptions.NotFoundException;
import com.messagebird.exceptions.UnauthorizedException;

public class ExampleDeleteCallFlow {
    public static void main(String[] args) {
        final MessageBirdService messageBirdService = new MessageBirdServiceImpl("test_gshuPaZoeEG6ovbc8M79w0QyM");
        final MessageBirdClient messageBirdClient = new MessageBirdClient(messageBirdService);

        try {
            messageBirdClient.deleteVoiceCallFlow("747df247-ee56-4940-a49d-0a0e9d9ba884");
        } catch (NotFoundException notFoundException) {
            if (notFoundException.getErrors() != null) {
                System.out.println(notFoundException.getErrors().toString());
            }
            notFoundException.printStackTrace();
        } catch (UnauthorizedException unauthorized) {
            if (unauthorized.getErrors() != null) {
                System.out.println(unauthorized.getErrors().toString());
            }
            unauthorized.printStackTrace();
        } catch (GeneralException generalException) {
            if (generalException.getErrors() != null) {
                System.out.println(generalException.getErrors().toString());
            }
            generalException.printStackTrace();
        }
    }
}`,
  csharp: `using MessageBird;

namespace Examples
{
    class ExampleDeleteCallFlows
    {
        static void Main(string[] args)
        {
            Client client = Client.CreateDefault("test_gshuPaZoeEG6ovbc8M79w0QyM");

            client.DeleteCallFlow("d48ff2bc-3b45-4a34-98de-a9d754aa2c44");
        }
    }
}`,
  go: `package main

import (
    "log"
    "github.com/messagebird/go-rest-api"
    "github.com/messagebird/go-rest-api/voice"
)

func main() {
    accessKey := "test_gshuPaZoeEG6ovbc8M79w0QyM"

    client := messagebird.New(accessKey)

    callflow, err := voice.CallFlowByID(client, "b0b2af5f-a475-40a9-a244-a9d58e7352ed");

    if err != nil {
        log.Println(err)
    }

    callflow.Delete(client)
}`,
  python: `
import messagebird

def main():
    client = messagebird.Client('test_gshuPaZoeEG6ovbc8M79w0QyM')
    try:
        call = client.call_flow_delete("b0b2af5f-a475-40a9-a244-a9d58e7352ed")
        print(call)

    except messagebird.client.ErrorException as e:
        print('\nAn error occurred while creating a call:\n')

        for error in e.errors:
            print('  code        : %d' % error.code)
            print('  description : %s' % error.description)

if __name__ == "__main__":
    main()
`,
  node: `
var messagebird = require('messagebird')('test_gshuPaZoeEG6ovbc8M79w0QyM');
messagebird.callflows.delete('b0b2af5f-a475-40a9-a244-a9d58e7352ed', function (err, response) {
  if (err) {
    return console.log(err);
  }
  console.log(response);
});
`,
  php: `
$messageBird = new \MessageBird\Client('YOUR_ACCESS_KEY'); 
$deleted = $messageBird->voiceCallFlows->delete('<CALL_ID>');
var_dump('Deleted: ' . $deleted);`,
};

export const ASSIGNNUMBER_DEF = {
  json: `POST https://voice.messagebird.com/call-flows/{callflowID}/numbers
PUT https://voice.messagebird.com/call-flows/{callflowID}/numbers`,
};

export const ASSIGNNUMBER_REQUEST = {
  curl: `# Add number to the call flow
  curl -X POST https://voice.messagebird.com/call-flows/de3ed163-d5fc-45f4-b8c4-7eea7458c635/numbers \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -d $'{
    "numbers": ["31611111111", "31622222222"]
  }'

  # Replace the numbers of the call flow
  curl -X PUT https://voice.messagebird.com/call-flows/de3ed163-d5fc-45f4-b8c4-7eea7458c635/numbers \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -d $'{
    "numbers": ["31611111111", "31622222222"]
  }'`,
};

export const LISTNUMBER_DEF = {
  json: `GET https://voice.messagebird.com/call-flows/{callflowID}/numbers`,
};

export const LISTNUMBER_REQUEST = {
  curl: `curl -X GET "https://voice.messagebird.com/call-flows/de3ed163-d5fc-45f4-b8c4-7eea7458c635/numbers" \\
  -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM"`,
};

export const LISTNUMBER_RESPONSE = {
  json: `{
  "data": [
    {
      "id": "13f38f34-7ff4-45b3-8783-8d5b1143f22b",
      "number": "31611111111",
      "callFlowId": "de3ed163-d5fc-45f4-b8c4-7eea7458c635",
      "createdAt": "2017-03-16T13:49:24Z",
      "updatedAt": "2017-09-12T08:59:50Z",
      "_links": {
        "self": "/numbers/13f38f34-7ff4-45b3-8783-8d5b1143f22b"
      }
    }
  ],
  "_links": {
    "self": "/call-flows/de3ed163-d5fc-45f4-b8c4-7eea7458c635/numbers?page=1"
  },
  "pagination": {
    "totalCount": 1,
    "pageCount": 1,
    "currentPage": 1,
    "perPage": 10
  }
}`,
};

export const DYNAMICFLOWS_REQUEST = {
  curl: `curl -X POST https://voice.messagebird.com/call-flows \\
  -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM" \\
  -H "Content-Type: application/json" \\
  -d $'{
    "steps": [
      {
        "options": {
          "url": "https://example.com"
        },
        "action": "fetchCallFlow"
      }
    ]
  }'`,
  java: `import com.messagebird.MessageBirdClient;
import com.messagebird.MessageBirdService;
import com.messagebird.MessageBirdServiceImpl;
import com.messagebird.exceptions.GeneralException;
import com.messagebird.exceptions.UnauthorizedException;
import com.messagebird.objects.VoiceStep;
import com.messagebird.objects.VoiceStepOption;
import com.messagebird.objects.voicecalls.VoiceCallFlowRequest;
import com.messagebird.objects.voicecalls.VoiceCallFlowResponse;

import java.util.List;

public class ExampleCreateDynamicCallFlow {
    public static void main(String[] args) {
        final MessageBirdService messageBirdService = new MessageBirdServiceImpl("test_gshuPaZoeEG6ovbc8M79w0QyM");
        final MessageBirdClient messageBirdClient = new MessageBirdClient(messageBirdService);

        try {
            VoiceStepOption options = getVoiceStepOption("https://example.com");
            VoiceStep step = getVoiceStep("fetchCallFlow", options);
            VoiceCallFlowRequest voiceCallFlowRequest = getVoiceCallFlowRequest(true, List.of(step));

            VoiceCallFlowResponse voiceCallFlowResponse = messageBirdClient.sendVoiceCallFlow(voiceCallFlowRequest);
            System.out.println(voiceCallFlowResponse);
        } catch (UnauthorizedException unauthorized) {
            if (unauthorized.getErrors() != null) {
                System.out.println(unauthorized.getErrors().toString());
            }
            unauthorized.printStackTrace();
        } catch (GeneralException generalException) {
            if (generalException.getErrors() != null) {
                System.out.println(generalException.getErrors().toString());
            }
            generalException.printStackTrace();
        }
    }

    private static VoiceStepOption getVoiceStepOption(String url) {
        VoiceStepOption options = new VoiceStepOption();
        options.setUrl(url);
        options.setTimeout(1); // Must set to a minimum of 1
        return options;
    }

    private static VoiceStep getVoiceStep(String action, VoiceStepOption options) {
        VoiceStep step = new VoiceStep();
        step.setAction(action);
        step.setOptions(options);
        return step;
    }

    private static VoiceCallFlowRequest getVoiceCallFlowRequest(boolean record, List<VoiceStep> steps) {
        VoiceCallFlowRequest voiceCallFlowRequest = new VoiceCallFlowRequest();
        voiceCallFlowRequest.setRecord(record);
        voiceCallFlowRequest.setSteps(steps);
        return voiceCallFlowRequest;
    }
}`,
  go: `package main

import (
  "log"
    "github.com/messagebird/go-rest-api"
    "github.com/messagebird/go-rest-api/voice"
)

func main() {
    accessKey := "test_gshuPaZoeEG6ovbc8M79w0QyM"

    client := messagebird.New(accessKey)

    callflow := voice.CallFlow{
        Record: true,
        Steps: []voice.CallFlowStep{
            &voice.CallFlowFetchStep{
                URL: "https://example.com",
            },
        },
    }

    err := callflow.Create(client)

    if err != nil {
        log.Println(err)
    }
    log.Println(callflow)
}`,
  python: `
import messagebird, json

def main():
    client = messagebird.Client('test_gshuPaZoeEG6ovbc8M79w0QyM')
    callFlowDynamic = {}
    callFlowDynamic["steps"] = '[{"options": {"url": "https://example.com"}, "action": "fetchCallFlow"}]'
    callFlowDynamic["steps"] = json.loads(str(callFlowDynamic["steps"]).strip('\''))

    try:
        call = client.call_flow_create(**callFlowDynamic)
        print('  id                : %s' % call.id)

    except messagebird.client.ErrorException as e:
        print('\nAn error occurred while creating a call:\n')

        for error in e.errors:
            print('  code        : %d' % error.code)
            print('  description : %s' % error.description)

if __name__ == "__main__":
    main()
`,
  node: `
var messagebird = require('messagebird')('test_gshuPaZoeEG6ovbc8M79w0QyM');
var params = {
  'steps': [{
     'action': 'fetchCallFlow',
     'options': {
            'url': 'https://www.example.com'
        },
    }]
};
messagebird.callflows.create(params, function (err, response) {
  if (err) {
    return console.log(err);
  }
  console.log(response);
});
`,
};

export const DYNAMICFLOWSUSAGE_REQUEST = {
  json: `GET /?callID=97f9b1d2-b8b4-4db7-8829-ec3e660d2cdb&destination=19027064857&firstLegDirection=incoming&source=%2B31611171210&variables=%7B%22testingvar%22%3A%222%22%7D HTTP/1.1
Host: example.com
Accept-encoding: gzip
User-Agent: Go-http-client/1.1
X-MessageBird-Signature: OomF7gSVo5TdL6mxFvdlYodiTnJtPaEk7V6czObUUI0=
Connection: keep-alive
`,
};

export const DYNAMICFLOWSUSAGE_RESPONSE = {
  xml: `<?xml version="1.0" encoding="UTF-8"?>
<CallFlow>
    <Say language="en-GB" voice="female">The quick brown fox jumped over the lazy dog.</Say>
    <Hangup/>
</CallFlow>`,
};

export const CALLS_URI = {
  json: `https://voice.messagebird.com/calls`,
};

export const CALLS_METHODS = {
  json: `POST /calls/
GET /calls/
GET /calls/{callID}
PUT /calls/{callID}
DELETE /calls/{callID}
POST /calls/{callID}/{destination}`,
};

export const CALLOBJECT_EXAMPLE = {
  json: `{
  "id": "f1aa71c0-8f2a-4fe8-b5ef-9a330454ef58",
  "status": "ended",
  "source": "31644556677",
  "destination": "31612345678",
  "createdAt": "2017-02-16T10:52:00Z",
  "updatedAt": "2017-02-16T10:59:04Z",
  "endedAt": "2017-02-16T10:59:04Z"
}`,
};

export const LISTCALLS_DEF = {
  json: `GET https://voice.messagebird.com/calls`,
};

export const LISTCALLS_REQUEST = {
  curl: `curl -X GET "https://voice.messagebird.com/calls" \\
  -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM"`,
  java: `import com.messagebird.MessageBirdClient;
import com.messagebird.MessageBirdService;
import com.messagebird.MessageBirdServiceImpl;
import com.messagebird.exceptions.GeneralException;
import com.messagebird.exceptions.UnauthorizedException;
import com.messagebird.objects.voicecalls.VoiceCallResponseList;

public class ExampleListCalls {
    public static void main(String[] args) {
        final MessageBirdService messageBirdService = new MessageBirdServiceImpl("test_gshuPaZoeEG6ovbc8M79w0QyM");
        final MessageBirdClient messageBirdClient = new MessageBirdClient(messageBirdService);

        try {
            VoiceCallResponseList voiceCallResponseList = messageBirdClient.listAllVoiceCalls(0, 100);
            System.out.println(voiceCallResponseList);
        } catch (UnauthorizedException unauthorized) {
            if (unauthorized.getErrors() != null) {
                System.out.println(unauthorized.getErrors().toString());
            }
            unauthorized.printStackTrace();
        } catch (GeneralException generalException) {
            if (generalException.getErrors() != null) {
                System.out.println(generalException.getErrors().toString());
            }
            generalException.printStackTrace();
        }
    }
}`,
  csharp: `using System;
using MessageBird;
using MessageBird.Objects.Voice;

namespace Examples
{
    class ExampleListCalls
    {
        static void Main(string[] args)
        {
            Client client = Client.CreateDefault("test_gshuPaZoeEG6ovbc8M79w0QyM");

            CallList callList = client.ListCalls();
            Console.WriteLine("# calls found: " + callList.Data.Count);
        }
    }
}`,
  go: `package main

import (
  "log"
    "github.com/messagebird/go-rest-api"
    "github.com/messagebird/go-rest-api/voice"
)

func main() {
    accessKey := "test_gshuPaZoeEG6ovbc8M79w0QyM"

    client := messagebird.New(accessKey)

    paginator := voice.Calls(client)
    page, err := paginator.NextPage()

    if err != nil {
        log.Println(err)
    }
    log.Println(page);
}`,
  python: `
import messagebird

def main():
    client = messagebird.Client('test_gshuPaZoeEG6ovbc8M79w0QyM')
    try:
        callList = client.call_list()
        if callList.items is not None:
            print('  Containing the the following items:')
            for item in callList.items:
                print('  {')
                print('    id                : %s' % item.id)
                print('    status            : %s' % item.status)
                print('  },')
        else:
            print('  With an empty response.')

    except messagebird.client.ErrorException as e:
        print('\nAn error occurred while listing calls:\n')

        for error in e.errors:
            print('  code        : %d' % error.code)
            print('  description : %s' % error.description)

if __name__ == "__main__":
    main()
  
  `,
  node: `
var messagebird = require('messagebird')('test_gshuPaZoeEG6ovbc8M79w0QyM');
messagebird.calls.list(function (err, response) {
  if (err) {
    return console.log(err);
  }
  console.log(response);
});
`,
  php: `
$messageBird = new \MessageBird\Client('YOUR_ACCESS_KEY');
$result = $messageBird->voiceCalls->getList(array('offset' => 100, 'limit' => 30));   
var_dump($result);`,
};

export const LISTCALLS_RESPONSE = {
  json: `{
  "data": [
    {
      "id": "f1aa71c0-8f2a-4fe8-b5ef-9a330454ef58",
      "status": "ended",
      "source": "31644556677",
      "destination": "31612345678",
      "createdAt": "2017-02-16T10:52:00Z",
      "updatedAt": "2017-02-16T10:59:04Z",
      "endedAt": "2017-02-16T10:59:04Z",
      "_links": {
        "self": "/calls/f1aa71c0-8f2a-4fe8-b5ef-9a330454ef58"
      }
    },
    {
      "id": "ac07a602-dbc1-11e6-bf26-cec0c932ce01",
      "status": "ended",
      "source": "31644556677",
      "destination": "31612345678",
      "createdAt": "2017-01-16T07:51:56Z",
      "updatedAt": "2017-01-16T07:55:56Z",
      "endedAt": "2017-01-16T07:55:56Z",
      "_links": {
        "self": "/calls/ac07a602-dbc1-11e6-bf26-cec0c932ce01"
      }
    }
  ],
  "_links": {
    "self": "/calls?page=1"
  },
  "pagination": {
    "totalCount": 2,
    "pageCount": 1,
    "currentPage": 1,
    "perPage": 10
  }
}`,
};

export const VIEWCALLS_DEF = {
  json: `GET https://voice.messagebird.com/calls/{callID}`,
};

export const VIEWCALLS_REQUEST = {
  curl: `curl -X GET https://voice.messagebird.com/calls/f1aa71c0-8f2a-4fe8-b5ef-9a330454ef58 \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM'`,
  java: `import com.messagebird.MessageBirdClient;
import com.messagebird.MessageBirdService;
import com.messagebird.MessageBirdServiceImpl;
import com.messagebird.exceptions.GeneralException;
import com.messagebird.exceptions.NotFoundException;
import com.messagebird.exceptions.UnauthorizedException;
import com.messagebird.objects.voicecalls.VoiceCallResponse;

public class ExampleViewCall {
    public static void main(String[] args) {
        final MessageBirdService messageBirdService = new MessageBirdServiceImpl("test_gshuPaZoeEG6ovbc8M79w0QyM");
        final MessageBirdClient messageBirdClient = new MessageBirdClient(messageBirdService);

        try {
            VoiceCallResponse voiceCallResponse = messageBirdClient.viewVoiceCall("80117829-29d4-41ca-b72a-27fbb2337e49");
            System.out.println(voiceCallResponse);
        } catch (NotFoundException notFoundException) {
            if (notFoundException.getErrors() != null) {
                System.out.println(notFoundException.getErrors().toString());
            }
            notFoundException.printStackTrace();
        } catch (GeneralException generalException) {
            if (generalException.getErrors() != null) {
                System.out.println(generalException.getErrors().toString());
            }
            generalException.printStackTrace();
        } catch (UnauthorizedException unauthorized) {
            if (unauthorized.getErrors() != null) {
                System.out.println(unauthorized.getErrors().toString());
            }
            unauthorized.printStackTrace();
        }
    }
}`,
  csharp: `using System;
using MessageBird;
using MessageBird.Objects.Voice;

namespace Examples
{
    class ExampleViewCall
    {
        static void Main(string[] args)
        {
            Client client = Client.CreateDefault("test_gshuPaZoeEG6ovbc8M79w0QyM");

            VoiceResponse<Call> voiceResponse = client.ViewCall("80117829-29d4-41ca-b72a-27fbb2337e49");

            Console.WriteLine($"Call was {voiceResponse.Data[0].Duration} seconds long");
        }
    }
}`,
  go: `package main

import (
  "log"
    "github.com/messagebird/go-rest-api"
    "github.com/messagebird/go-rest-api/voice"
)

func main() {
    accessKey := "test_gshuPaZoeEG6ovbc8M79w0QyM"

    client := messagebird.New(accessKey)

    call, err := voice.CallByID(client, "80117829-29d4-41ca-b72a-27fbb2337e49")
    if err != nil {
        log.Println(err)
    }
    log.Println(call)
}`,
  python: `
import messagebird

def main():
    client = messagebird.Client('test_gshuPaZoeEG6ovbc8M79w0QyM')
    try:
        call = client.call("80117829-29d4-41ca-b72a-27fbb2337e49")
        print('  id                : %s' % call.data.id)
        print('  status            : %s' % call.data.status)
        print('  source            : %s' % call.data.source)
        print('  destination       : %s' % call.data.destination)

    except messagebird.client.ErrorException as e:
        print('\nAn error occurred while creating a call:\n')
        for error in e.errors:
            print('  code        : %d' % error.code)
            print('  description : %s' % error.description)

if __name__ == "__main__":
    main()
`,
  node: `
var messagebird = require('messagebird')('test_gshuPaZoeEG6ovbc8M79w0QyM');
messagebird.calls.read('80117829-29d4-41ca-b72a-27fbb2337e49', function (err, response) {
  if (err) {
    return console.log(err);
  }
  console.log(response);
});
`,
  php: `
$messageBird = new \MessageBird\Client('YOUR_ACCESS_KEY');
$result = $messageBird->voiceCalls->read('<CALL_ID>');
var_dump($result);`,
};

export const VIEWCALLS_RESPONSE = {
  json: `{
  "data": [
    {
      "id": "f1aa71c0-8f2a-4fe8-b5ef-9a330454ef58",
      "status": "ended",
      "source": "31644556677",
      "destination": "31612345678",
      "createdAt": "2017-02-16T10:52:00Z",
      "updatedAt": "2017-02-16T10:59:04Z",
      "endedAt": "2017-02-16T10:59:04Z"
    }
  ],
  "_links": {
    "self": "/calls/f1aa71c0-8f2a-4fe8-b5ef-9a330454ef58"
  }
}`,
};

export const CREATECALLS_DEF = {
  json: `POST https://voice.messagebird.com/calls`,
};

export const CREATECALLS_REQUEST = {
  curl: `curl -X POST https://voice.messagebird.com/calls \\
  -H "Content-Type: application/json" \\
  -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM" \\
  -d $'{
    "source": "31644556677",
    "destination": "31612345678",
    "callFlow": {
      "steps": [
        {
          "action": "say",
          "options": {
            "payload": "This is a journey into sound. Good bye!",
            "voice": "male",
            "language": "en-US"
          }
        }
      ]
    },
    "webhook": {
      "url": "https://example.com",
      "token": "token_to_sign_the_call_events_with"
    }
  }'`,
  java: `import com.messagebird.MessageBirdClient;
import com.messagebird.MessageBirdService;
import com.messagebird.MessageBirdServiceImpl;
import com.messagebird.exceptions.GeneralException;
import com.messagebird.exceptions.UnauthorizedException;
import com.messagebird.objects.VoiceStep;
import com.messagebird.objects.VoiceStepOption;
import com.messagebird.objects.voicecalls.VoiceCall;
import com.messagebird.objects.voicecalls.VoiceCallFlow;
import com.messagebird.objects.voicecalls.VoiceCallResponse;

import java.util.List;

public class ExampleCreateCall {
    public static void main(String[] args) {
        final MessageBirdService messageBirdService = new MessageBirdServiceImpl("test_gshuPaZoeEG6ovbc8M79w0QyM");
        final MessageBirdClient messageBirdClient = new MessageBirdClient(messageBirdService);

        VoiceStepOption options = getVoiceStepOption("This is a journey into sound. Good bye!", "male", "en-US");
        List<VoiceStep> steps = getVoiceSteps("say", options);
        VoiceCallFlow callFlow = getVoiceCallFlow(steps);
        VoiceCall voiceCall = getVoiceCall(callFlow, "31644556677", "31612345678");

        try {
            VoiceCallResponse voiceCallResponse = messageBirdClient.sendVoiceCall(voiceCall);
            System.out.println(voiceCallResponse);
        } catch (UnauthorizedException unauthorized) {
            if (unauthorized.getErrors() != null) {
                System.out.println(unauthorized.getErrors().toString());
            }
            unauthorized.printStackTrace();
        } catch (GeneralException generalException) {
            if (generalException.getErrors() != null) {
                System.out.println(generalException.getErrors().toString());
            }
            generalException.printStackTrace();
        }
    }

    private static VoiceCall getVoiceCall(VoiceCallFlow callFlow, String destination, String source) {
        VoiceCall voiceCall = new VoiceCall();
        voiceCall.setCallFlow(callFlow);
        voiceCall.setDestination(destination);
        voiceCall.setSource(source);
        voiceCall.setWebhook("https://www.example.com", "token_to_sign_the_call_events_with");
        return voiceCall;
    }

    private static VoiceCallFlow getVoiceCallFlow(List<VoiceStep> steps) {
        VoiceCallFlow callFlow = new VoiceCallFlow();
        callFlow.setSteps(steps);
        return callFlow;
    }

    private static List<VoiceStep> getVoiceSteps(String action, VoiceStepOption options) {
        VoiceStep step = new VoiceStep();
        step.setAction(action);
        step.setOptions(options);
        return List.of(step);
    }

    private static VoiceStepOption getVoiceStepOption(String payload, String voice, String language) {
        VoiceStepOption options = new VoiceStepOption();
        options.setPayload(payload);
        options.setVoice(voice);
        options.setLanguage(language);
        return options;
    }
}`,
  go: `package main

import (
  "log"
    "github.com/messagebird/go-rest-api"
    "github.com/messagebird/go-rest-api/voice"
)

func main() {
    accessKey := "test_gshuPaZoeEG6ovbc8M79w0QyM"

    client := messagebird.New(accessKey)

    source, destination := "31644556677", "31612345678"

    callflow := voice.CallFlow{
        Steps: []voice.CallFlowStep{
            &voice.CallFlowSayStep{
                Voice:    "male",
                Payload:  "This is a journey into sound. Good bye!",
                Language: "en-US",
            },
        },
    }

    call, err := voice.InitiateCall(client, source, destination, callflow, nil)
    if err != nil {
        log.Println(err)
    }
    log.Println(call)
}`,
  python: `
import messagebird, json

def main():
    client = messagebird.Client('test_gshuPaZoeEG6ovbc8M79w0QyM')
    callFlow = {}
    callFlow["source"] = "31612345678"
    callFlow["destination"] = "31612345678"
    callFlow["callFlow"] = '{"steps": [{"action": "say", "options": {"payload": "This is a journey into sound. Good bye!", "language": "en-GB", "voice": "female"}}]}'
    callFlow["webhook"] = {}
    callFlow["callFlow"] = json.loads(str(callFlow["callFlow"]).strip('\''))
    try:
        call = client.call_create(**callFlow)

        print('\nThe following information was returned as a', str(call.__class__), 'object:\n')
        print('  id                : %s' % call.data.id)
        print('  status            : %s' % call.data.status)
        print('  source            : %s' % call.data.source)
        print('  destination       : %s' % call.data.destination)

    except messagebird.client.ErrorException as e:
        print('\nAn error occurred while creating a call:\n')

        for error in e.errors:
            print('  code        : %d' % error.code)
            print('  description : %s' % error.description)

if __name__ == "__main__":
    main()
`,
  node: `
var messagebird = require('messagebird')('test_gshuPaZoeEG6ovbc8M79w0QyM');
var params = {
  'originator': 'MessageBird',
  'source': '31612345678',
  'destination': '31612345678',
  'CallFlow':  {
    'steps': [{
        'action': 'say',
        'options': {
            'language': 'en-GB',
            'voice': 'male',
            'payload': 'This is a journey into sound. Good bye!'
        },
    }]
  },
};
messagebird.calls.create(params, function (err, response) {
  if (err) {
    return console.log(err);
  }
  console.log(response);
});
`,
  php: `
$messageBird = new \MessageBird\Client('YOUR_ACCESS_KEY');
$call = new \MessageBird\Objects\Voice\Call();
$call->source = '31971234567';
$call->destination = '31612345678';
$callFlow = new \MessageBird\Objects\Voice\CallFlow();
$step = new \MessageBird\Objects\Voice\Step();
$step->action = 'say';
$step->options = array(
    'payload' => 'This is a journey into sound.',
    'language' => 'en-GB',
    'voice' => 'male',
);
$callFlow->steps = array($step);
$call->callFlow = $callFlow;
$result = $messageBird->voiceCalls->create($call);
var_dump($result);`,
};

export const CREATECALLS_RESPONSE = {
  json: `{
  "data": [
    {
      "id": "21025ed1-cc1d-4554-ac05-043fa6c84e00",
      "status": "queued",
      "source": "31644556677",
      "destination": "31612345678",
      "createdAt": "2017-08-30T07:35:37Z",
      "updatedAt": "2017-08-30T07:35:37Z",
      "endedAt": null
    }
  ],
  "_links": {
    "self": "/calls/21025ed1-cc1d-4554-ac05-043fa6c84e00"
  }
}`,
};

export const UPDATECALLS_DEF = {
  json: `PUT https://voice.messagebird.com/calls/{callID}`,
};

export const UPDATECALLS_REQUEST = {
  curl: `curl -X PUT https://voice.messagebird.com/calls/21025ed1-cc1d-4554-ac05-043fa6c84e00 \\
  -H "Content-Type: application/json" \\
  -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM" \\
  -d $'{
    "fetchCallFlowUrl": "https://www.example.com",
    "fetchCallFlowToken": "foobar"
  }'`,
};

export const UPDATECALLS_RESPONSE = {
  json: `{
  "data": [
    {
      "id": "21025ed1-cc1d-4554-ac05-043fa6c84e00",
      "status": "ongoing",
      "source": "31644556677",
      "destination": "31612345678",
      "createdAt": "2017-08-30T07:35:37Z",
      "updatedAt": "2017-08-30T07:35:37Z",
      "endedAt": null
    }
  ],
  "_links": {
    "legs": "/calls/21025ed1-cc1d-4554-ac05-043fa6c84e00/legs",
    "self": "/calls/21025ed1-cc1d-4554-ac05-043fa6c84e00"
  }
}`,
};

export const DELETECALLS_DEF = {
  json: `DELETE https://voice.messagebird.com/calls/{callID}`,
};

export const DELETECALLS_REQUEST = {
  curl: `curl -X DELETE https://voice.messagebird.com/calls/21025ed1-cc1d-4554-ac05-043fa6c84e00 \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM'`,
  java: `import com.messagebird.MessageBirdClient;
import com.messagebird.MessageBirdService;
import com.messagebird.MessageBirdServiceImpl;
import com.messagebird.exceptions.GeneralException;
import com.messagebird.exceptions.NotFoundException;
import com.messagebird.exceptions.UnauthorizedException;

public class ExampleDeleteCall {
    public static void main(String[] args) {
        final MessageBirdService messageBirdService = new MessageBirdServiceImpl("test_gshuPaZoeEG6ovbc8M79w0QyM");
        final MessageBirdClient messageBirdClient = new MessageBirdClient(messageBirdService);

        try {
            messageBirdClient.deleteVoiceCall("c830bf23-c1a6-445e-bcad-3a9acba7154f");
        } catch (NotFoundException notFoundException) {
            if (notFoundException.getErrors() != null) {
                System.out.println(notFoundException.getErrors().toString());
            }
            notFoundException.printStackTrace();
        } catch (GeneralException generalException) {
            if (generalException.getErrors() != null) {
                System.out.println(generalException.getErrors().toString());
            }
            generalException.printStackTrace();
        } catch (UnauthorizedException unauthorized) {
            if (unauthorized.getErrors() != null) {
                System.out.println(unauthorized.getErrors().toString());
            }
            unauthorized.printStackTrace();
        }
    }
}`,
  csharp: `using MessageBird;

namespace Examples
{
    class ExampleDeleteCall
    {
        static void Main(string[] args)
        {
            Client client = Client.CreateDefault("test_gshuPaZoeEG6ovbc8M79w0QyM");

            client.DeleteCall("3dcd5dd5-eecc-417b-bb7c-45a5ab05c5a8");
        }
    }
}`,
  go: `package main

import (
  "log"
    "github.com/messagebird/go-rest-api"
    "github.com/messagebird/go-rest-api/voice"
)

func main() {
    accessKey := "test_gshuPaZoeEG6ovbc8M79w0QyM"

    client := messagebird.New(accessKey)

  call := voice.Call{
    ID: "837480b3-60f2-40f6-a117-0f7ddbfaeb0f",
  }

  err := call.Delete(client)

    if err != nil {
        log.Println(err)
    }
}`,
  python: `
import messagebird

def main():
    client = messagebird.Client('test_gshuPaZoeEG6ovbc8M79w0QyM')
    try:
        call = client.call_delete("3dcd5dd5-eecc-417b-bb7c-45a5ab05c5a8")
        print(call)

    except messagebird.client.ErrorException as e:
        print('\nAn error occurred while creating a call:\n')
        for error in e.errors:
            print('  code        : %d' % error.code)
            print('  description : %s' % error.description)

if __name__ == "__main__":
    main()
`,
  node: `
var messagebird = require('messagebird')('test_gshuPaZoeEG6ovbc8M79w0QyM');
messagebird.calls.delete('3dcd5dd5-eecc-417b-bb7c-45a5ab05c5a8', function (err, response) {
  if (err) {
    return console.log(err);
  }
  console.log(response);
});
`,
};

export const ADDPARTICIPANT_DEF = {
  json: `POST https://voice.messagebird.com/calls/{callID}/{destination}`,
};

export const ADDPARTICIPANT_REQUEST = {
  curl: `curl -X POST https://voice.messagebird.com/calls/21025ed1-cc1d-4554-ac05-043fa6c84e00/31612345678 \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -d $'{"source": "310123456789"}'`,
};

export const LEGS_URI = {
  json: `https://voice.messagebird.com/calls/{callID}/legs`,
};

export const LEGS_METHODS = {
  json: `GET /calls/{callID}/legs
GET /calls/{callID}/legs/{legID}
DELETE /legs/{legID}
POST /legs/{legID}/hold
POST /legs/{legID}/unhold
`,
};

export const CALLS_AND_LEGS_METHODS = {
  json: CALLS_METHODS.json + `\n` + LEGS_METHODS.json,
};

export const LEGS_OBJECT = {
  json: `{
  "id": "d4f07ab3-b17c-44a8-bcef-2b351311c28f",
  "callId": "f1aa71c0-8f2a-4fe8-b5ef-9a330454ef58",
  "source": "31644556677",
  "destination": "31612345678",
  "status": "hangup",
  "sipResponseCode": 200,
  "direction": "outgoing",
  "duration": 31,
  "createdAt": "2017-02-16T10:52:00Z",
  "updatedAt": "2017-02-16T10:52:30Z",
  "answeredAt": "2017-02-16T10:52:30Z",
  "endedAt": "2017-02-16T10:52:30Z"
}`,
};

export const LISTLEGS_DEF = {
  json: `GET https://voice.messagebird.com/calls/{callID}/legs`,
};

export const LISTLEGS_REQUEST = {
  curl: `curl -X GET "https://voice.messagebird.com/calls/21025ed1-cc1d-4554-ac05-043fa6c84e00/legs" \\
  -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM"`,
  java: `import com.messagebird.MessageBirdClient;
import com.messagebird.MessageBirdService;
import com.messagebird.MessageBirdServiceImpl;
import com.messagebird.exceptions.GeneralException;
import com.messagebird.exceptions.UnauthorizedException;
import com.messagebird.objects.voicecalls.VoiceCallLegResponse;

import java.io.UnsupportedEncodingException;

public class ExampleListLegs {
    public static void main(String[] args) {
        final MessageBirdService messageBirdService = new MessageBirdServiceImpl("test_gshuPaZoeEG6ovbc8M79w0QyM");
        final MessageBirdClient messageBirdClient = new MessageBirdClient(messageBirdService);

        try {
            VoiceCallLegResponse voiceCallLegResponse = messageBirdClient.viewCallLegsByCallId(
                    "80117829-29d4-41ca-b72a-27fbb2337e49", 1, 100);
            System.out.println(voiceCallLegResponse.getData());
        } catch (UnsupportedEncodingException encodingException) {
            encodingException.printStackTrace();
        } catch (GeneralException generalException) {
            if (generalException.getErrors() != null) {
                System.out.println(generalException.getErrors().toString());
            }
            generalException.printStackTrace();
        } catch (UnauthorizedException unauthorized) {
            if (unauthorized.getErrors() != null) {
                System.out.println(unauthorized.getErrors().toString());
            }
            unauthorized.printStackTrace();
        }
    }
}`,
  go: `package main

import (
  "log"
    "github.com/messagebird/go-rest-api"
    "github.com/messagebird/go-rest-api/voice"
)

func main() {
    accessKey := "test_gshuPaZoeEG6ovbc8M79w0QyM"

    client := messagebird.New(accessKey)

  call := voice.Call{
    ID: "837480b3-60f2-40f6-a117-0f7ddbfaeb0f",
  }

  paginator := call.Legs(client)

    page, err := paginator.NextPage()

    if err != nil {
        log.Println(err)
    }
    log.Println(page);
}`,
  php: `
$messageBird = new \MessageBird\Client('YOUR_ACCESS_KEY');
$result = $messageBird->voiceLegs->getList('<CALL_ID>, array('offset' => 100, 'limit' => 30));
var_dump($result);`,
};

export const LISTLEGS_RESPONSE = {
  json: `{
  "data": [
    {
      "id": "d4f07ab3-b17c-44a8-bcef-2b351311c28f",
      "callId": "f1aa71c0-8f2a-4fe8-b5ef-9a330454ef58",
      "source": "31123456789",
      "destination": "31123456777",
      "status": "hangup",
      "sipResponseCode": 200,
      "direction": "outgoing",
      "duration": 31,
      "cost": 0.000385,
      "currency": "USD",
      "createdAt": "2017-02-16T10:52:00Z",
      "updatedAt": "2017-02-16T10:52:30Z",
      "answeredAt": "2017-02-16T10:52:30Z",
      "endedAt": "2017-02-16T10:52:30Z",
      "_links": {
        "self": "/calls/f1aa71c0-8f2a-4fe8-b5ef-9a330454ef58/legs/d4f07ab3-b17c-44a8-bcef-2b351311c28f"
      }
    },
    {
      "id": "e60ca497-0cf3-4954-b74c-265e95633ec8",
      "callId": "f1aa71c0-8f2a-4fe8-b5ef-9a330454ef58",
      "source": "31123456789",
      "destination": "31123456788",
      "status": "hangup",
      "sipResponseCode": 200,
      "direction": "incoming",
      "duration": 31,
      "cost": 0.000385,
      "currency": "USD",
      "createdAt": "2017-02-16T10:52:00Z",
      "updatedAt": "2017-02-16T10:52:30Z",
      "answeredAt": "2017-02-16T10:52:30Z",
      "endedAt": "2017-02-16T10:52:30Z",
      "_links": {
        "self": "/calls/f1aa71c0-8f2a-4fe8-b5ef-9a330454ef58/legs/e60ca497-0cf3-4954-b74c-265e95633ec8"
      }
    }
  ],
  "_links": {
    "self": "/calls/f1aa71c0-8f2a-4fe8-b5ef-9a330454ef58/legs?page=1"
  },
  "pagination": {
    "totalCount": 2,
    "pageCount": 1,
    "currentPage": 1,
    "perPage": 10
  }
}`,
};

export const VIEWLEGS_DEF = {
  json: `GET https://voice.messagebird.com/calls/{callID}/legs/{legID}`,
};

export const VIEWLEGS_REQUEST = {
  curl: `curl  -X GET https://voice.messagebird.com/calls/21025ed1-cc1d-4554-ac05-043fa6c84e00/legs/d4f07ab3-b17c-44a8-bcef-2b351311c28f \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM'`,
  java: `import com.messagebird.MessageBirdClient;
import com.messagebird.MessageBirdService;
import com.messagebird.MessageBirdServiceImpl;
import com.messagebird.exceptions.GeneralException;
import com.messagebird.exceptions.NotFoundException;
import com.messagebird.exceptions.UnauthorizedException;
import com.messagebird.objects.voicecalls.VoiceCallLeg;

import java.io.UnsupportedEncodingException;

public class ExampleViewLeg {
    public static void main(String[] args) {
        final MessageBirdService messageBirdService = new MessageBirdServiceImpl("test_gshuPaZoeEG6ovbc8M79w0QyM");
        final MessageBirdClient messageBirdClient = new MessageBirdClient(messageBirdService);

        try {
            VoiceCallLeg voiceCallLeg = messageBirdClient.viewCallLegByCallIdAndLegId(
                    "80117829-29d4-41ca-b72a-27fbb2337e49", "30ded463-d6d1-478f-b74f-f4bcdbd203b8");
            System.out.println(voiceCallLeg);
        } catch (NotFoundException notFoundException) {
            if (notFoundException.getErrors() != null) {
                System.out.println(notFoundException.getErrors().toString());
            }
            notFoundException.printStackTrace();
        } catch (GeneralException generalException) {
            if (generalException.getErrors() != null) {
                System.out.println(generalException.getErrors().toString());
            }
            generalException.printStackTrace();
        } catch (UnauthorizedException unauthorized) {
            if (unauthorized.getErrors() != null) {
                System.out.println(unauthorized.getErrors().toString());
            }
            unauthorized.printStackTrace();
        } catch (UnsupportedEncodingException encodingException) {
            encodingException.printStackTrace();
        }
    }
}`,
  php: `
$messageBird = new \MessageBird\Client('YOUR_ACCESS_KEY');
$result = $messageBird->voiceLegs->read('<CALL_ID>', '<LEG_ID>');
var_dump($result);`,
};

export const VIEWLEGS_RESPONSE = {
  json: `{
  "data": [
    {
      "id": "d4f07ab3-b17c-44a8-bcef-2b351311c28f",
      "callId": "f1aa71c0-8f2a-4fe8-b5ef-9a330454ef58",
      "source": "31644556677",
      "destination": "31612345678",
      "status": "hangup",
      "sipResponseCode": 200,
      "direction": "outgoing",
      "duration": 31,
      "cost": 0.000385,
      "currency": "USD",
      "createdAt": "2017-02-16T10:52:00Z",
      "updatedAt": "2017-02-16T10:52:30Z",
      "answeredAt": "2017-02-16T10:52:30Z",
      "endedAt": "2017-02-16T10:52:04Z"
    }
  ],
  "_links": {
    "self": "/calls/f1aa71c0-8f2a-4fe8-b5ef-9a330454ef58/legs/d4f07ab3-b17c-44a8-bcef-2b351311c28f"
  }
}`,
};

export const DELETELEG_DEF = {
  json: `DELETE https://voice.messagebird.com/legs/{legID}`,
};

export const DELETELEG_REQUEST = {
  curl: `curl -X DELETE "https://voice.messagebird.com/legs/07fda3f9-ddd3-4267-ab01-71cf55df31df" \\
  -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM"`,
};

export const HOLDLEG_DEF = {
  json: `POST https://voice.messagebird.com/legs/{legID}/hold`,
};

export const HOLDLEG_REQUEST = {
  curl: `curl -X POST "https://voice.messagebird.com/legs/07fda3f9-ddd3-4267-ab01-71cf55df31df/hold" \\
  -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM"\\
  -d $'{"musicOnHold": "https://path.to/hold-music.wav"}'
  `,
};

export const UNHOLDLEG_DEF = {
  json: `POST https://voice.messagebird.com/legs/{legID}/unhold`,
};

export const UNHOLDLEG_REQUEST = {
  curl: `curl -X POST "https://voice.messagebird.com/legs/07fda3f9-ddd3-4267-ab01-71cf55df31df/unhold" \\
  -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM"`,
};

export const RECORDINGS_URI = {
  json: `https://voice.messagebird.com/calls/{callID}/legs/{legID}/recordings`,
};

export const RECORDINGS_METHODS = {
  json: `GET /calls/{callID}/legs/{legID}/recordings
GET /recordings/{recordingID}
GET /recordings/{recordingID}.wav
# DEPRECATED:
GET /calls/{callID}/legs/{legID}/recordings/{recordingID}
GET /calls/{callID}/legs/{legID}/recordings/{recordingID}.wav
`,
};

export const RECORDINGS_OBJECT = {
  json: `{
  "data": [
    {
      "id": "3b4ac358-9467-4f7a-a6c8-6157ad181123",
      "format": "wav",
      "legId": "227bd14d-3eee-4380-b01f-fe7723c69a31",
      "status": "done",
      "duration": 7,
      "createdAt": "2017-05-17T11:42:57Z",
      "updatedAt": "2017-05-17T11:43:04Z",
      "startedAt": "2017-05-17T11:43:03Z",
      "finishedAt": "2017-05-17T11:43:10Z"
    }
  ],
  "_links": {
    "self": "/recordings/3b4ac358-9467-4f7a-a6c8-6157ad181123",
    "file": "/recordings/3b4ac358-9467-4f7a-a6c8-6157ad181123.wav"
  }
}`,
};

export const DOWNLOADRECORDING_URI = {
  json: `/recordings/{recordingID}.wav`,
};

export const TRANSCRIPTIONS_URI = {
  json: `https://voice.messagebird.com/recordings/{recordingID}/transcriptions`,
};

export const TRANSCRIPTIONS_METHODS = {
  json: `GET /recordings/{recordingID}/transcriptions
POST /recordings/{recordingID}/transcriptions
GET /transcriptions/{transcriptionID}
GET /transcriptions/{transcriptionID}.txt

# DEPRECATED:
GET /calls/{callID}/legs/{legID}/recordings/{recordingID}/transcriptions
GET /calls/{callID}/legs/{legID}/recordings/{recordingID}/transcriptions/{transcriptionID}
GET /calls/{callID}/legs/{legID}/recordings/{recordingID}/transcriptions/{transcriptionID}.txt
POST /calls/{callID}/legs/{legID}/recordings/{recordingID}/transcriptions
`,
};

export const VIEWRECORDING_DEF = {
  json: `GET https://voice.messagebird.com/recordings/{recordingID}`,
};

export const VIEWRECORDING_REQUEST = {
  curl: `curl -X GET "https://voice.messagebird.com/recordings/4c2ac358-b467-4f7a-a6c8-6157ad181142" \\
  -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM"`,
  php: `$messageBird = new \\MessageBird\\Client('<ACCESS_KEY>');
$result = $messageBird->voiceRecordings->read('<CALL_ID>', '<LEG_ID>', '<RECORDING_ID>');
var_dump($result);`,
  node: `var messagebird = require('messagebird')('<YOUR_ACCESS_KEY>');
  
messagebird.recordings.read('<CALL_ID>', '<LEG_ID>', '<RECORDING_ID>', function (err, response) {
  if (err) {
    return console.log(err);
  }
  console.log(response);
});`,
  java: `import com.messagebird.MessageBirdClient;
import com.messagebird.MessageBirdService;
import com.messagebird.MessageBirdServiceImpl;
import com.messagebird.exceptions.GeneralException;
import com.messagebird.exceptions.NotFoundException;
import com.messagebird.exceptions.UnauthorizedException;
import com.messagebird.objects.voicecalls.RecordingResponse;

public class ExampleViewRecording {
  public static void main(String[] args) {
    if (args.length < 4) {
      System.out.println("Please specify your access key and call id and leg id and recording id example :" +
              " java -jar <this jar file> test_accesskey e8077d803532c0b5937c639b60216938 e8077d803532c0b5937c639b60216938 e8077d803532c0b5937c639b60216938");
      return;
    }

    // First create your service object
    final MessageBirdService wsr = new MessageBirdServiceImpl(args[0]);

    // Add the service to the client
    final MessageBirdClient messageBirdClient = new MessageBirdClient(wsr);

    try {
      System.out.println("Getting a recording");
      final String callId = args[1];
      final String legId = args[2];
      final String recordingId = args[3];
      //Sending call id and leg id and recording id parameters to client
      final RecordingResponse recording = messageBirdClient.viewRecording(callId, legId, recordingId);
      //Display recording response
      System.out.println(recording.toString());

    } catch (GeneralException | NotFoundException | UnauthorizedException exception) {
      exception.printStackTrace();
    }
  }
}`,
  csharp: `using System;
using System.Linq;
using MessageBird;
using MessageBird.Exceptions;

namespace Examples.Recording
{ 
    internal class ViewRecording
    {
        const string YOUR_ACCESS_KEY = "YOUR_ACCESS_KEY";

        internal static void Main(string[] args)
        {
            var client = Client.CreateDefault(YOUR_ACCESS_KEY);

            try
            {
                var recordingResponse = client.ViewRecording("CALL ID", "LEG ID", "RECORDING ID");
                var recording = recordingResponse.Data.FirstOrDefault();

                Console.WriteLine("The Recording Id is: {0}", recording.Id);
                Console.WriteLine("The Recording Format is: {0}", recording.Format);
                Console.WriteLine("The Recording Duration is: {0}", recording.Duration);
            }
            catch (ErrorException e)
            {
                // Either the request fails with error descriptions from the endpoint.
                if (e.HasErrors)
                {
                    foreach (var error in e.Errors)
                    {
                        Console.WriteLine("code: {0} description: '{1}' parameter: '{2}'", error.Code, error.Description, error.Parameter);
                    }
                }
                // or fails without error information from the endpoint, in which case the reason contains a 'best effort' description.
                if (e.HasReason)
                {
                    Console.WriteLine(e.Reason);
                }
            }
            Console.WriteLine("Press any key to continue...");
            Console.ReadKey();
        }
    }
}`,
  ruby: `client = MessageBird::Client.new(YOUR_ACCESS_KEY)
client.call_leg_recording_view('<CALL_ID>', '<LEG_ID>', '<RECORDING_ID>')`,
  python: `client = messagebird.Client('<YOUR_ACCESS_KEY>')

voiceRecording = client.voice_recording_view('<CALL_ID>', '<LEG_ID>', '<RECORDING_ID>')
print(voiceRecording)`,
};

export const VIEWRECORDING_RESPONSE = {
  json: `{
  "_links": {
    "self": "/recordings/796c6d68-d307-46c0-8925-05bf069a7d14",
    "transcriptions": "/recordings/796c6d68-d307-46c0-8925-05bf069a7d14/transcriptions"
  },
  "data": [
    {
      "id": "796c6d68-d307-46c0-8925-05bf069a7d14",
      "format": "wav",
      "legId": "a6a2a420-bed7-4200-b87b-3d20e6a258c3",
      "status": "done",
      "duration": 18,
      "type": "call",
      "createdAt": "2020-05-19T06:43:34Z",
      "updatedAt": "2020-05-19T06:43:55Z",
      "deletedAt": null,
      "startedAt": "2020-05-19T06:43:34Z",
      "finishedAt": "2020-05-19T06:43:55Z",
      "_links": {
        "file": "/recordings/796c6d68-d307-46c0-8925-05bf069a7d14.wav",
        "self": "/recordings/796c6d68-d307-46c0-8925-05bf069a7d14"
      }
    }
  ]
}`,
};

export const DOWNLOADRECORDING_DEF = {
  json: `GET https://voice.messagebird.com/recordings/{recordingID}.wav`,
};

export const DOWNLOADRECORDING_REQUEST = {
  curl: `curl -X GET "https://voice.messagebird.com/recordings/4c2ac358-b467-4f7a-a6c8-6157ad181142.wav" \\
  -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM"`,
  php: `$messageBird = new \\MessageBird\\Client('<ACCESS_KEY>');
$result = $messageBird->voiceRecordings->download('<CALL_ID>', '<LEG_ID>', '<RECORDING_ID>');
var_dump($result);`,
  node: `var messagebird = require('messagebird')('<YOUR_ACCESS_KEY>');

messagebird.recordings.download('<CALL_ID>', '<LEG_ID>', '<RECORDING_ID>', function (err, response) {
  if (err) {
    return console.log(err);
  }
  console.log(response);
});`,
  java: `import com.messagebird.MessageBirdClient;
import com.messagebird.MessageBirdService;
import com.messagebird.MessageBirdServiceImpl;
import com.messagebird.exceptions.GeneralException;
import com.messagebird.exceptions.NotFoundException;
import com.messagebird.exceptions.UnauthorizedException;

public class ExampleDownloadRecording {
  public static void main(String[] args) {
    if (args.length < 4) {
      System.out.println("Please specify your access key and call id and leg id and recording id and base path(optional) example :" +
              " java -jar <this jar file> test_accesskey e8077d803532c0b5937c639b60216938 e8077d803532c0b5937c639b60216938 e8077d803532c0b5937c639b60216938 /users/{user}/test");
      return;
    }

    // First create your service object
    final MessageBirdService wsr = new MessageBirdServiceImpl(args[0]);

    // Add the service to the client
    final MessageBirdClient messageBirdClient = new MessageBirdClient(wsr);

    try {
      System.out.println("Getting a recording");
      final String callId = args[1];
      final String legId = args[2];
      final String recordingId = args[3];
      String basePath = null;
      if (args.length > 4) {
        basePath = args[4];
      }
      //Sending call id and leg id and recording id parameters to client
      final String filePath = messageBirdClient.downloadRecording(callId, legId, recordingId, basePath);
      if (filePath != null) {
        System.out.println("Record file is downloaded to "+filePath);
      }

    } catch (GeneralException | NotFoundException | UnauthorizedException exception) {
      exception.printStackTrace();
    }
  }
}`,
  csharp: `using MessageBird;
using MessageBird.Exceptions;
using System;
using System.IO;

namespace Examples.Recording
{
    internal class DownloadRecording
    {
        const string YOUR_ACCESS_KEY = "YOUR_ACCESS_KEY";

        internal static void Main(string[] args)
        {
            var client = Client.CreateDefault(YOUR_ACCESS_KEY);

            try
            {
                using (var recordingDataStream = client.DownloadRecording("CALL ID", "LEG ID", "RECORDING ID"))
                { 
                    using (var fileStream = File.OpenWrite(@"PATH TO FILE ON YOUR LOCAL MACHINE"))
                    {
                        recordingDataStream.CopyTo(fileStream);
                    }
                }
            }
            catch (ErrorException e)
            {
                // Either the request fails with error descriptions from the endpoint.
                if (e.HasErrors)
                {
                    foreach (var error in e.Errors)
                    {
                        Console.WriteLine("code: {0} description: '{1}' parameter: '{2}'", error.Code, error.Description, error.Parameter);
                    }
                }
                // or fails without error information from the endpoint, in which case the reason contains a 'best effort' description.
                if (e.HasReason)
                {
                    Console.WriteLine(e.Reason);
                }
            }
            Console.WriteLine("Press any key to continue...");
            Console.ReadKey();
        }
    }
}`,
  ruby: `client = MessageBird::Client.new(YOUR_ACCESS_KEY)
client.call_leg_recording_download('<RECORDING_URI>')`,
  go: `rec := &Recording{
  ID: "<RECORDING_ID>",
  Links: map[string]string{
    "file": "<FILE/PATH>.wav",
  },
}
r, _ := rec.DownloadFile(mbClient)
wav, _ := ioutil.ReadAll(r)`,
  python: `client = messagebird.Client('<YOUR_ACCESS_KEY>')

voiceRecordingLocation = client.voice_recording_download('<CALL_ID>', '<LEG_ID>', '<RECORDING_ID>')
print(voiceRecordingLocation)`,
};

export const LISTRECORDING_DEF = {
  json: `GET https://voice.messagebird.com/calls/{callID}/legs/{legID}/recordings`,
};

export const LISTRECORDING_REQUEST = {
  curl: `curl -X GET "https://voice.messagebird.com/calls/fdcf0391-4fdc-4e38-9551-e8a01602984f/legs/317bd14d-3eee-4380-b01f-fe7723c6913a/recordings" \\
  -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM"`,
  php: `$MessageBird = new \\MessageBird\\Client('<ACCESS_KEY>');
$result = $MessageBird->voiceRecordings->getList('<CALL_ID>', '<LEG_ID>', array('offset' => 100, 'limit' => 30));
var_dump($result);`,
  csharp: `using MessageBird;
using MessageBird.Exceptions;
using System;

namespace Examples.Recording
{
    internal class ListRecording
    {
        const string YOUR_ACCESS_KEY = "YOUR_ACCESS_KEY";

        internal static void Main(string[] args)
        {
            var client = Client.CreateDefault(YOUR_ACCESS_KEY);

            var recordings = client.ListRecordings(callId: "CALL ID", legId: "LEG ID");
            try
            {
                foreach (var item in recordings.Data)
                {
                    Console.WriteLine("The Recording Id is: {0}", item.Id);
                    Console.WriteLine("The Recording Format is: {0}", item.Format);
                    Console.WriteLine("The Recording Duration is: {0}", item.Duration);
                }
            }
            catch (ErrorException e)
            {
                // Either the request fails with error descriptions from the endpoint.
                if (e.HasErrors)
                {
                    foreach (var error in e.Errors)
                    {
                        Console.WriteLine("code: {0} description: '{1}' parameter: '{2}'", error.Code, error.Description, error.Parameter);
                    }
                }
                // or fails without error information from the endpoint, in which case the reason contains a 'best effort' description.
                if (e.HasReason)
                {
                    Console.WriteLine(e.Reason);
                }
            }
            Console.WriteLine("Press any key to continue...");
            Console.ReadKey();
        }
    }
}`,
  ruby: `client = MessageBird::Client.new(YOUR_ACCESS_KEY)
client.call_leg_recording_list('<CALL_ID>', '<LEG_ID>')`,
  python: `client = messagebird.Client('<YOUR_ACCESS_KEY>')

voiceRecordingList = client.voice_recording_list_recordings('<CALL_ID>', '<LEG_ID>')
print(voiceRecordingList)`,
  node: `
var messagebird = require('messagebird')('<YOUR_ACCESS_KEY>');
messagebird.recordings.list('<CALLID>', '<LEG_ID>', function (err, response) {
  if (err) {
      return console.log(err);
  }
  console.log(response);
});
`,
};

export const DELETERECORDING_DEF = {
  json: `DELETE https://voice.messagebird.com/recordings/{recordingID}`,
};

export const DELETERECORDING_REQUEST = {
  curl: `curl -X DELETE "https://voice.messagebird.com/recordings/07fda3f9-ddd3-4267-ab01-71cf55df31df" \\
  -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM"`,
  csharp: `using MessageBird;
using MessageBird.Exceptions;
using System;

namespace Examples.Recording
{
    internal class DeleteRecording
    {
        const string YOUR_ACCESS_KEY = "YOUR_ACCESS_KEY";

        internal static void Main(string[] args)
        {
            var client = Client.CreateDefault(YOUR_ACCESS_KEY);

            try
            {
                client.DeleteRecording("CALL ID", "LEG ID", "RECORDING ID");
                Console.WriteLine("The Recording was deleted successfully.");
            }
            catch (ErrorException e)
            {
                // Either the request fails with error descriptions from the endpoint.
                if (e.HasErrors)
                {
                    foreach (var error in e.Errors)
                    {
                        Console.WriteLine("code: {0} description: '{1}' parameter: '{2}'", error.Code, error.Description, error.Parameter);
                    }
                }
                // or fails without error information from the endpoint, in which case the reason contains a 'best effort' description.
                if (e.HasReason)
                {
                    Console.WriteLine(e.Reason);
                }
            }
            Console.WriteLine("Press any key to continue...");
            Console.ReadKey();
        }
    }
}`,
};

export const TRANSCRIPTIONS_OBJECT = {
  json: `{
  "data": [
    {
      "id": "87c377ce-1629-48b6-ad01-4b4fd069c53c",
      "recordingId": "123da3f9-ddd3-4267-ab01-71cf55df31df",
      "status": "done",
      "createdAt": "2017-06-20T10:03:14Z",
      "updatedAt": "2017-06-20T10:03:14Z"
    }
  ],
  "_links": {
    "self": "/transcriptions/87c377ce-1629-48b6-ad01-4b4fd069c53c",
    "file": "/transcriptions/87c377ce-1629-48b6-ad01-4b4fd069c53c.txt"
  }
}`,
};

export const CREATETRANSCRIPTIONS_REQUEST = {
  curl: `curl -X POST "https://voice.messagebird.com/recordings/07fda3f9-ddd3-4267-ab01-71cf55df31df/transcriptions/" \\
  -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM" \\
  -d $'{
    "language": "en-EN"
  }'`,
  php: `
$messageBird = new \MessageBird\Client('YOUR_ACCESS_KEY'); // Set your own API access key here.
$transcription = new \MessageBird\Objects\Voice\Recording();
$result = $messageBird->voiceTranscriptions->create('<CALL_ID>', '<LEG_ID>', '<RECORDING_ID');
var_dump($result);`,
  go: `
package main
import (
    "github.com/messagebird/go-rest-api"
    "github.com/messagebird/go-rest-api/voice"
    "log"
)
func main() {
    // Access keys can be managed through our dashboard.
    accessKey := "YOUR_ACCESS_KEY"
    // Create a client.
    client := messagebird.New(accessKey)
    // Create the transcription
    transcription, err := voice.CreateTranscription(client,"<CALL_ID>","<LEG_ID>","<RECORDING_ID>")
    if err != nil {
        // Handle error.
        log.Println(err)
	return
    }
    log.Println(transcription)

}`,
  python: `
import messagebird

def main():
    client = messagebird.Client('YOUR_ACCESS_KEY')
    try:
        transcription = client.voice_transcription_create(
            'de3ed163-d5fc-45f4-b8c4-7eea7458c635', #CALLID
            '21025ed1-cc1d-4554-ac05-043fa6c84e00', #LEGID
            '3b4ac358-9467-4f7a-a6c8-6157ad181123', #RECORDINGID
            'en-US' #LANGUAGE
        )
        if transcription.items is not None:
            print('  Containing the the following items:')
            for item in transcription.items:
                print('  {')
                print('    id                : %s' % item.id)
                print('    status            : %s' % item.status)
                print('  },')
        else:
            print('  With an empty response.')

    except messagebird.client.ErrorException as e:
        print('\nAn error occurred while creating a transcription request:\n')
        print('  code        : %d' % error.code)
        print('  description : %s' % error.description)

if __name__ == "__main__":
    main()
`,
  node: `
var messagebird = require('messagebird')('<YOUR_ACCESS_KEY>');
messagebird.transcriptions.create('<CALL_ID>', '<LEG_ID>', '<RECORDING_ID>', '<LANGUAGE>', function (err, data) {
  if (err) {
    return console.log(err);
  }

  console.log(data);
});
`,
  csharp: `
using System;
using System.Linq;
using MessageBird;
using MessageBird.Exceptions;

namespace Examples.Transcription
{ 
    internal class CreateTranscription
    {
        const string YOUR_ACCESS_KEY = "YOUR_ACCESS_KEY";

        internal static void Main(string[] args)
        {
            var client = Client.CreateDefault(YOUR_ACCESS_KEY);

            try
            {
                var transcriptionResponse = client.CreateTranscription("CALL ID", "LEG ID", "RECORDING ID", "LANGUAGE");
                var transcription = transcriptionResponse.Data.FirstOrDefault();

                Console.WriteLine("The Transcription Id is: {0}", transcription.Id);
                Console.WriteLine("The Transcription Recording Id is: {0}", transcription.RecordingId);
                Console.WriteLine("The Transcription Status is: {0}", transcription.Status);
            }
            catch (ErrorException e)
            {
                // Either the request fails with error descriptions from the endpoint.
                if (e.HasErrors)
                {
                    foreach (var error in e.Errors)
                    {
                        Console.WriteLine("code: {0} description: '{1}' parameter: '{2}'", error.Code, error.Description, error.Parameter);
                    }
                }
                // or fails without error information from the endpoint, in which case the reason contains a 'best effort' description.
                if (e.HasReason)
                {
                    Console.WriteLine(e.Reason);
                }
            }
            Console.WriteLine("Press any key to continue...");
            Console.ReadKey();
        }
    }
}
`,
  java: `
import com.messagebird.MessageBirdClient;
import com.messagebird.MessageBirdService;
import com.messagebird.MessageBirdServiceImpl;
import com.messagebird.exceptions.GeneralException;
import com.messagebird.exceptions.UnauthorizedException;
import com.messagebird.objects.voicecalls.TranscriptionResponse;

public class ExampleSendTranscription {
    public static void main(String[] args) {
        //First create your service object
        final MessageBirdService wsr = new MessageBirdServiceImpl('<YOUR_ACCESS_KEY>');

        //Add the service to the client
        final MessageBirdClient messageBirdClient = new MessageBirdClient(wsr);

        try {
            //Creating new transcription
            System.out.println("Creating new transcription..");
            final String callId = '<CALL_ID>';
            final String legId = '<LEG_ID>';
            final String recordingId = '<RECORDING_ID>';
            //Language should be in allowed languages list, can check on developer api
            final String language = '<LANGUAGE>';
            final TranscriptionResponse transcription = messageBirdClient.createTranscription(callId, legId, recordingId, language);
            if (transcription.getData() == null) {
                //Data is not present then return exception
                throw new NullPointerException("Transcription response has no data");
            }
            //Display data
            System.out.println(transcription.getData().get(0).toString());
        } catch (GeneralException | UnauthorizedException e) {
            e.printStackTrace();
        }
    }
}
`,
};

export const VIEWTRANS_DEF = {
  json: `GET https://voice.messagebird.com/recordings/{recordingID}/transcriptions`,
};

export const VIEWTRANS_REQUEST = {
  curl: `curl - X GET "https://voice.messagebird.com/transcriptions/87c377ce-1629-48b6-ad01-4b4fd069c53c" \\
-H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM"`,
  php: `
$messageBird = new \MessageBird\Client('YOUR_ACCESS_KEY'); // Set your own API access key here.
$result = $messageBird->voiceTranscriptions->read('<CALL_ID>', '<LEG_ID>', '<RECORDING_ID');
var_dump($result);`,
  python: `
import messagebird

def main():
    client = messagebird.Client('YOUR_ACCESS_KEY')
    try:
        transcription = client.voice_transcription_view(
            'de3ed163-d5fc-45f4-b8c4-7eea7458c635', #CALLID
            '21025ed1-cc1d-4554-ac05-043fa6c84e00', #LEGID
            '3b4ac358-9467-4f7a-a6c8-6157ad181123', #RECORDINGID
            'df4ac137-cc4f-432c-73fb-3452abc12345' #TRANSCRIPTIONID
        )
        if transcription.items is not None:
            print('  Containing the the following items:')
            for item in transcription.items:
                print('  {')
                print('    id                : %s' % item.id)
                print('    status            : %s' % item.status)
                print('  },')
        else:
            print('  With an empty response.')

    except messagebird.client.ErrorException as e:
        for error in e.errors:
            print('\nAn error occurred while creating a transcription request:\n')
            print('  code        : %d' % error.code)
            print('  description : %s' % error.description)

if __name__ == "__main__":
    main()`,
  node: `
var messagebird = require('messagebird')('<YOUR_ACCESS_KEY>');
messagebird.transcriptions.read('<CALL_ID>', '<LEG_ID>', '<RECORDING_ID>', '<TRANSCRIPTION_ID>', function (err, data) {
  if (err) {
    return console.log(err);
  }

  console.log(data);
});`,
  csharp: `
using System;
using System.Linq;
using MessageBird;
using MessageBird.Exceptions;

namespace Examples.Transcription
{ 
    internal class ViewTranscription
    {
        const string YOUR_ACCESS_KEY = "YOUR_ACCESS_KEY";

        internal static void Main(string[] args)
        {
            var client = Client.CreateDefault(YOUR_ACCESS_KEY);

            try
            {
                var transcriptionResponse = client.ViewTranscription("CALL ID", "LEG ID", "RECORDING ID", "TRANSCRIPTION ID");
                var transcription = transcriptionResponse.Data.FirstOrDefault();

                Console.WriteLine("The Transcription Id is: {0}", transcription.Id);
                Console.WriteLine("The Transcription Recording Id is: {0}", transcription.RecordingId);
                Console.WriteLine("The Transcription Status is: {0}", transcription.Status);
            }
            catch (ErrorException e)
            {
                // Either the request fails with error descriptions from the endpoint.
                if (e.HasErrors)
                {
                    foreach (var error in e.Errors)
                    {
                        Console.WriteLine("code: {0} description: '{1}' parameter: '{2}'", error.Code, error.Description, error.Parameter);
                    }
                }
                // or fails without error information from the endpoint, in which case the reason contains a 'best effort' description.
                if (e.HasReason)
                {
                    Console.WriteLine(e.Reason);
                }
            }
            Console.WriteLine("Press any key to continue...");
            Console.ReadKey();
        }
    }
}
`,
  java: `
import com.messagebird.MessageBirdClient;
import com.messagebird.MessageBirdService;
import com.messagebird.MessageBirdServiceImpl;
import com.messagebird.exceptions.GeneralException;
import com.messagebird.exceptions.NotFoundException;
import com.messagebird.exceptions.UnauthorizedException;
import com.messagebird.objects.voicecalls.TranscriptionResponse;

public class ExampleViewTranscription {
    public static void main(String[] args) {
        //First create your service object
        final MessageBirdService wsr = new MessageBirdServiceImpl('<YOUR_ACCESS_KEY>');

        //Add the service to the client
        final MessageBirdClient messageBirdClient = new MessageBirdClient(wsr);

        try {
            System.out.println("Getting transcription list");
            final String callId = '<CALL_ID>';
            final String legId = '<LEG_ID>';
            final String recordingId = '<RECORIDNG_ID>' ;
            final String transactionId = '<TRANSCRIPTION_ID>;
            // Sending call ID, leg ID, recording ID, page, page size parameters to client
            final TranscriptionResponse responseList = messageBirdClient.viewTranscription(callId, legId, recordingId, transactionId) ;
            //Display transcription response
            System.out.println(responseList.toString());

        } catch (GeneralException | UnauthorizedException | NotFoundException exceptions) {
            exceptions.printStackTrace();
        }

    }
}
`,
};

export const VIEWTRANS_RESPONSE = {
  json: `{
  "data": [
     {
       "id": "87c377ce-1629-48b6-ad01-4b4fd069c53c",
       "recordingId": "07fda3f9-ddd3-4267-ab01-71cf55df31df",
       "status": "done",
       "createdAt": "2017-06-20T10:03:14Z",
       "updatedAt": "2017-06-20T10:03:14Z",
       "_links": {
         "self": "/transcriptions/87c377ce-1629-48b6-ad01-4b4fd069c53c",
         "file": "/transcriptions/87c377ce-1629-48b6-ad01-4b4fd069c53c.txt"
       }
     }
}`,
};

export const DOWNLOADTRANS_URI = {
  json: `/transcriptions/{transcriptionID}.txt`,
};

export const WEBHOOKS_URI = {
  json: `https://voice.messagebird.com/webhooks`,
};

export const WEBHOOKS_METHODS = {
  json: `GET /webhooks/
GET /webhooks/{id}
POST /webhooks/
PUT /webhooks/{id}
DELETE /webhooks/{id}`,
};

export const WEBHOOKS_OBJECT = {
  json: `{
  "id": "534e1848-235f-482d-983d-e3e11a04f58a",
  "url": "https://example.com/",
  "token": "foobar",
  "createdAt": "2017-03-15T14:10:07Z",
  "updatedAt": "2017-03-15T14:10:07Z"
}`,
};

export const LISTWEBHOOKS_REQUEST = {
  curl: `curl -X GET "https://voice.messagebird.com/webhooks" \\
  -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM"`,
  php: `
$messageBird = new \MessageBird\Client('YOUR_ACCESS_KEY');
$result = $messageBird->voiceWebhooks->getList(array('offset' => 100, 'limit' => 30));
var_dump($result);`,
  go: `
package main
import (
    "github.com/messagebird/go-rest-api"
    "github.com/messagebird/go-rest-api/voice"
    "log"
)

func main() {
    // Access keys can be managed through our dashboard.
    accessKey := "test_gshuPaZoeEG6ovbc8M79w0QyM"
    // Create a client.
    client := messagebird.New(accessKey)
    paginator  := voice.Webhooks(client)
    page, err := paginator.NextPage()

    if err != nil {
        log.Println(err)
    }
    log.Println(page)
}
`,
  python: `
import messagebird
from messagebird.voice_webhook import VoiceCreateWebhookRequest

def main():
    client = messagebird.Client('YOUR_ACCESS_KEY')
    try:
        webhooks_list = client.voice_list_webhooks(limit=5, offset=0)
        # Print the object information.
        if webhooks_list is None or webhooks_list.data is None:
            print("\nNo webhooks\n")
            exit(0)

        # Print the object information.
        print('\nThe following information was returned as a Voice Webhook objects:\n')
        for webhook in webhooks_list.data:
            print('{')
            print('  id                 : {}'.format(webhook.id))
            print('  token              : {}'.format(webhook.token))
            print('  url                : {}'.format(webhook.url))
            print('  createdAt          : {}'.format(webhook.createdAt))
            print('  updatedAt          : {}'.format(webhook.updatedAt))
            print('}\n')

    except messagebird.client.ErrorException as e:
        print('An error occured while reading a Voice Webhook object:')

        for error in e.errors:
            print('  code        : {}'.format(error.code))
            print('  description : {}'.format(error.description))
            print('  parameter   : {}\n'.format(error.parameter))

if __name__ == "__main__":
    main()
`,
  node: `
var messagebird = require('messagebird')('<YOUR_ACCESS_KEY>');
messagebird.webhooks.list('<LIMIT>', '<OFFSET>', function (err, response) {
  if (err) {
    return console.log(err);
  }
  console.log(response);
});
`,
  csharp: `
using System;
using MessageBird;
using MessageBird.Exceptions;
using System.Linq;
using MessageBird.Objects.Voice;

namespace Examples.Webhooks
{
    internal class ListWebhooks
    {
        const string YOUR_ACCESS_KEY = "YOUR_ACCESS_KEY";

        internal static void Main(string[] args)
        {
            var client = Client.CreateDefault(YOUR_ACCESS_KEY);

            try
            {
                var webhookResponse = client.ListWebhooks();

                foreach (var item in webhookResponse.Data)
                {
                    Console.WriteLine("The Webhook Id is: {0}", item.Id);
                    Console.WriteLine("The Webhook URL is: {0}", item.url);
                    Console.WriteLine("The Webhook Token is: {0}", item.token);
                }
            }
            catch (ErrorException e)
            {
                // Either the request fails with error descriptions from the endpoint.
                if (e.HasErrors)
                {
                    foreach (var error in e.Errors)
                    {
                        Console.WriteLine("code: {0} description: '{1}' parameter: '{2}'", error.Code, error.Description, error.Parameter);
                    }
                }
                // or fails without error information from the endpoint, in which case the reason contains a 'best effort' description.
                if (e.HasReason)
                {
                    Console.WriteLine(e.Reason);
                }
            }
            Console.WriteLine("Press any key to continue...");
            Console.ReadKey();
        }
    }
}`,
};

export const LISTWEBHOOKS_RESPONSE = {
  json: `{
  "data": [
    {
      "id": "534e1848-235f-482d-983d-e3e11a04f58a",
      "url": "https://example.com/",
      "token": "foobar",
      "createdAt": "2017-03-15T13:28:32Z",
      "updatedAt": "2017-03-15T13:28:32Z",
      "_links": {
        "self": "/webhooks/534e1848-235f-482d-983d-e3e11a04f58a"
      }
    }
  ],
  "_links": {
    "self": "/webhooks?page=1"
  },
  "pagination": {
    "totalCount": 1,
    "pageCount": 1,
    "currentPage": 1,
    "perPage": 10
  }
}`,
};

export const VIEWWEBHOOK_DEF = {
  bash: `GET https://voice.messagebird.com/webhooks/{id}`,
};

export const VIEWWEBHOOK_REQUEST = {
  curl: `curl -X GET "https://voice.messagebird.com/webhooks/534e1848-235f-482d-983d-e3e11a04f58a" \\
   -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM"`,
  php: `
$messageBird = new \MessageBird\Client('YOUR_ACCESS_KEY');
$result = $messageBird->voiceWebhooks->read('<WEBHOOK_ID>'); // Set a webhook id here
var_dump($result);`,
  python: `
import messagebird
from messagebird.voice_webhook import VoiceCreateWebhookRequest

def main():
    client = messagebird.Client('YOUR_ACCESS_KEY')
    try:
        webhook = client.voice_read_webhook('YOUR_WEBHOOK_ID')
        # Print the object information.
        print('\nThe following information was returned as a Voice Webhook object:\n')
        print('  id                 : {}'.format(webhook.id))
        print('  token              : {}'.format(webhook.token))
        print('  url                : {}'.format(webhook.url))
        print('  createdAt          : {}'.format(webhook.createdAt))
        print('  updatedAt          : {}'.format(webhook.updatedAt))

    except messagebird.client.ErrorException as e:
        print('An error occured while creating a Voice Webhook object:')

        for error in e.errors:
            print('  code        : {}'.format(error.code))
            print('  description : {}'.format(error.description))
            print('  parameter   : {}\n'.format(error.parameter))

if __name__ == "__main__":
    main()
`,
  node: `
var messagebird = require('messagebird')('<YOUR_ACCESS_KEY>');
messagebird.webhooks.read('<WEBHOOK_ID>', function (err, response) {
  if (err) {
    return console.log(err);
  }
  console.log(response);
});
`,
  java: `
import com.messagebird.MessageBirdClient;
import com.messagebird.MessageBirdService;
import com.messagebird.MessageBirdServiceImpl;
import com.messagebird.exceptions.GeneralException;
import com.messagebird.exceptions.NotFoundException;
import com.messagebird.exceptions.UnauthorizedException;
import com.messagebird.objects.voicecalls.WebhookResponseData;

public class ExampleViewWebhook {
    public static void main(String[] args) {

        //First create your service object
        final MessageBirdService wsr = new MessageBirdServiceImpl('<YOUR_ACCESS_KEY>');

        //Add the service to the client
        final MessageBirdClient messageBirdClient = new MessageBirdClient(wsr);

        try {
            System.out.println("Viewing webhook..");
            final String webhookId = '<WEBHOOK_ID>' ;
            //Viewing webhook by webhook id
            final WebhookResponseData webhookResponseDataList = messageBirdClient.viewWebhook(webhookId);
            //Display Webhook Response Data
            System.out.println(webhookResponseDataList.toString());
        } catch (GeneralException | UnauthorizedException | NotFoundException exception) {
            exception.printStackTrace();
        }
    }
}
`,
};

export const VIEWWEBHOOK_RESPONSE = {
  json: `{
  "data": [
    {
      "id": "534e1848-235f-482d-983d-e3e11a04f58a",
      "url": "https://example.com/",
      "token": "foobar",
      "createdAt": "2017-03-15T13:28:32Z",
      "updatedAt": "2017-03-15T13:28:32Z"
    }
  ],
  "_links": {
    "self": "/webhooks/534e1848-235f-482d-983d-e3e11a04f58a"
  }
}`,
  csharp: `
using System;
using MessageBird;
using MessageBird.Exceptions;
using System.Linq;
using MessageBird.Objects.Voice;

namespace Examples.Webhooks
{
    internal class ViewWebhook
    {
        const string YOUR_ACCESS_KEY = "YOUR_ACCESS_KEY";

        internal static void Main(string[] args)
        {
            var client = Client.CreateDefault(YOUR_ACCESS_KEY);

            try
            {
                string webhookId = "WEBHOOK ID";
                var webhookResponse = client.ViewWebhook(webhookId);

                var webhook = webhookResponse.Data.FirstOrDefault();
                Console.WriteLine("The Webhook Id is: {0}", webhook.Id);
                Console.WriteLine("The Webhook URL is: {0}", webhook.url);
                Console.WriteLine("The Webhook Token is: {0}", webhook.token);
            }
            catch (ErrorException e)
            {
                // Either the request fails with error descriptions from the endpoint.
                if (e.HasErrors)
                {
                    foreach (var error in e.Errors)
                    {
                        Console.WriteLine("code: {0} description: '{1}' parameter: '{2}'", error.Code, error.Description, error.Parameter);
                    }
                }
                // or fails without error information from the endpoint, in which case the reason contains a 'best effort' description.
                if (e.HasReason)
                {
                    Console.WriteLine(e.Reason);
                }
            }
            Console.WriteLine("Press any key to continue...");
            Console.ReadKey();
        }
    }
}
`,
};

export const CREATEWEBHOOK_DEF = {
  json: `POST https://voice.messagebird.com/webhooks/`,
};

export const CREATEWEBHOOK_REQUEST = {
  curl: `curl -X POST https://voice.messagebird.com/webhooks \\
  -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM" \\
  -H "Content-Type: application/json" \\
  -d $'{
    "url": "https://example.com/",
    "token": "foobar"
  }'`,
  php: `
$messageBird = new \MessageBird\Client('YOUR_ACCESS_KEY');
$webhook = new \MessageBird\Objects\Voice\Webhook();
$webhook->url = 'https://example.com/status';
$webhook->token = '<TOKEN>';
$result = $messageBird->voiceWebhooks->create($webhook);
var_dump($result);`,
  go: `
package main
import (
    "github.com/messagebird/go-rest-api"
    "github.com/messagebird/go-rest-api/voice"
    "log"
)
func main() {
    // Access keys can be managed through our dashboard.
    accessKey := "test_gshuPaZoeEG6ovbc8M79w0QyM "
    // Create a client.
    client := messagebird.New(accessKey)
    // Request the balance information, returned as a balance.Balance object.
    webhook, err := voice.CreateWebHook(client,"https://example.com/webhook","foobar")
    if err != nil {
        // Handle error.
        log.Println(webhook)
        return
    }  

    // Display the results.
    log.Println(webhook)
}
`,
  python: `
import messagebird
from messagebird.voice_webhook import VoiceCreateWebhookRequest

def main():
    client = messagebird.Client('YOUR_ACCESS_KEY')
    try:
        create_webhook_request = VoiceCreateWebhookRequest( 
            'Webhook title', # webhook title
            'https://example.com/messagebird', #URL
            'Foobar', #TOKEN
        )
        webhook = client.voice_create_webhook(create_webhook_request)
        # Print the object information.
        print('\nThe following information was returned as a Voice Webhook object:\n')
        print('  id                 : {}'.format(webhook.id))
        print('  token              : {}'.format(webhook.token))
        print('  url                : {}'.format(webhook.url))
        print('  createdAt          : {}'.format(webhook.createdAt))
        print('  updatedAt          : {}'.format(webhook.updatedAt))

    except messagebird.client.ErrorException as e:
        print('An error occured while creating a Voice Webhook object:')

        for error in e.errors:
            print('  code        : {}'.format(error.code))
            print('  description : {}'.format(error.description))
            print('  parameter   : {}\n'.format(error.parameter))

if __name__ == "__main__":
    main()
`,
  node: `
var messagebird = require('messagebird')('<YOUR_ACCESS_KEY>');
var params = {
  url: "https://example.com/messagebird",
  title: "<WEBHOOK_TITLE>",
  token: "<TOKEN>"
};

messagebird.webhooks.create(params, function (err, response) {
  if (err) {
      return console.log(err);
  }
  console.log(response);
});
`,
  csharp: `
using System;
using MessageBird;
using MessageBird.Exceptions;
using System.Linq;
using MessageBird.Objects.Voice;

namespace Examples.Webhooks
{
    internal class CreateWebhook
    {
        const string YOUR_ACCESS_KEY = "YOUR_ACCESS_KEY";

        internal static void Main(string[] args)
        {
            var client = Client.CreateDefault(YOUR_ACCESS_KEY);

            try
            {
                // Create webhook object
                var newWebhook = new Webhook
                {
                    url = "WEBHOOK URL",
                    token = "WEBHOOK TOKEN"
                };

                var webhookResponse = client.CreateWebhook(newWebhook);
                var webhook = webhookResponse.Data.FirstOrDefault();
                Console.WriteLine("The Webhook was created successfully with Id = {0}.", webhook.Id);
            }
            catch (ErrorException e)
            {
                // Either the request fails with error descriptions from the endpoint.
                if (e.HasErrors)
                {
                    foreach (var error in e.Errors)
                    {
                        Console.WriteLine("code: {0} description: '{1}' parameter: '{2}'", error.Code, error.Description, error.Parameter);
                    }
                }
                // or fails without error information from the endpoint, in which case the reason contains a 'best effort' description.
                if (e.HasReason)
                {
                    Console.WriteLine(e.Reason);
                }
            }
            Console.WriteLine("Press any key to continue...");
            Console.ReadKey();
        }
    }
}
`,
  java: `
import com.messagebird.MessageBirdClient;
import com.messagebird.MessageBirdService;
import com.messagebird.MessageBirdServiceImpl;
import com.messagebird.exceptions.GeneralException;
import com.messagebird.exceptions.UnauthorizedException;
import com.messagebird.objects.voicecalls.Webhook;
import com.messagebird.objects.voicecalls.WebhookResponseData;

public class ExampleSendWebhook {

    public static void main(String[] args) {
        //First create your service object
        final MessageBirdService wsr = new MessageBirdServiceImpl('<YOUR_ACCESS_KEY>');

        //Add the service to the client
        final MessageBirdClient messageBirdClient = new MessageBirdClient(wsr);

        try {
            //Creating webhook object to send client
            System.out.println("Creating new webhook..");
            final Webhook webhook = new Webhook();
            webhook.setUrl('<WEBHOOK_URL>');
            webhook.setToken('<TOKEN>');
            //Sending webhook object to client
            final WebhookResponseData webhookResponseDataList = messageBirdClient.createWebhook(webhook);
            //Display webhook response
            System.out.println(webhookResponseDataList.toString());
        } catch (GeneralException | UnauthorizedException exception) {
            exception.printStackTrace();
        }
    }
}

`,
};

export const CREATEWEBHOOK_RESPONSE = {
  json: `{
  "data": [
    {
      "id": "534e1848-235f-482d-983d-e3e11a04f58a",
      "url": "https://example.com/",
      "token": "foobar",
      "createdAt": "2017-03-15T14:10:07Z",
      "updatedAt": "2017-03-15T14:10:07Z"
    }
  ],
  "_links": {
    "self": "/webhooks/534e1848-235f-482d-983d-e3e11a04f58a"
  }
}`,
};

export const UPDATEWEBHOOK_DEF = {
  json: `PUT https://voice.messagebird.com/webhooks/{id}`,
};

export const UPDATEWEBHOOK_REQUEST = {
  curl: `curl -X PUT https://voice.messagebird.com/webhooks/534e1848-235f-482d-983d-e3e11a04f58a \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -d $'{
    "url": "https://example.com/baz"
  }'`,
  php: `
$messageBird = new \MessageBird\Client('YOUR_ACCESS_KEY');
$webhook = new \MessageBird\Objects\Voice\Webhook();
$webhook->url = 'https://example.com/foobar';
$webhook->token = '<TOKEN>';
$result = $messageBird->voiceWebhooks->update($webhook, '<WEBHOOK_ID>');
var_dump($result);`,
  node: `
var messagebird = require('messagebird')('<YOUR_ACCESS_KEY>');
var params = {
  id: "<WEBHOOK_ID>",
};

messagebird.webhooks.create(params, function (err, response) {
  if (err) {
      return console.log(err);
  }
  console.log(response);
});`,
  csharp: `
using System;
using MessageBird;
using MessageBird.Exceptions;
using System.Linq;
using MessageBird.Objects.Voice;

namespace Examples.Webhooks
{
    internal class UpdateWebhook
    {
        const string YOUR_ACCESS_KEY = "YOUR_ACCESS_KEY";

        internal static void Main(string[] args)
        {
            var client = Client.CreateDefault(YOUR_ACCESS_KEY);

            try
            {
                string webhookId = "WEBHOOK ID";

                // Create webhook object
                var updateWebhook = new Webhook
                {
                    url = "WEBHOOK URL",
                    token = "WEBHOOK TOKEN"
                };

                var webhookResponse = client.UpdateWebhook(webhookId, updateWebhook);
                var webhook = webhookResponse.Data.FirstOrDefault();
                Console.WriteLine("The Webhook was successfully updated with Id = {0}.", webhook.Id);
            }
            catch (ErrorException e)
            {
                // Either the request fails with error descriptions from the endpoint.
                if (e.HasErrors)
                {
                    foreach (var error in e.Errors)
                    {
                        Console.WriteLine("code: {0} description: '{1}' parameter: '{2}'", error.Code, error.Description, error.Parameter);
                    }
                }
                // or fails without error information from the endpoint, in which case the reason contains a 'best effort' description.
                if (e.HasReason)
                {
                    Console.WriteLine(e.Reason);
                }
            }
            Console.WriteLine("Press any key to continue...");
            Console.ReadKey();
        }
    }
}
`,
};

export const UPDATEWEBHOOK_RESPONSE = {
  json: `{
  "data": [
    {
      "id": "534e1848-235f-482d-983d-e3e11a04f58a",
      "url": "https://example.com/baz",
      "token": "foobar",
      "createdAt": "2017-03-15T13:27:02Z",
      "updatedAt": "2017-03-15T13:28:01Z"
    }
  ],
  "_links": {
    "self": "/webhooks/534e1848-235f-482d-983d-e3e11a04f58a"
  }
}`,
};

export const DELETEWEBHOOK_DEF = {
  json: `DELETE https://voice.messagebird.com/webhooks/{id}`,
};

export const DELETEWEBHOOK_REQUEST = {
  curl: `curl -X DELETE https://voice.messagebird.com/webhooks/534e1848-235f-482d-983d-e3e11a04f58a \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM'`,
  php: `
$messageBird = new \MessageBird\Client('YOUR_ACCESS_KEY');
$deleted = $messageBird->voiceWebhooks->delete('<WEBHOOK_ID>');
var_dump('Deleted: ' . $deleted);`,
  go: `
package main
import (
    "github.com/messagebird/go-rest-api"
    "github.com/messagebird/go-rest-api/voice"
    "log"
)

func main() {
    // Access keys can be managed through our dashboard.
    accessKey := "test_gshuPaZoeEG6ovbc8M79w0QyM"
    // Create a client.
    client := messagebird.New(accessKey)
    wh :=  &voice.Webhook{ID: "8676ef56-c1de-4e93-98de-8b6ee0b846c3"}
    webhook  := wh.Delete(client)
    // Display the results.
    log.Println(webhook)
}`,
  python: `
import messagebird

def main():
    client = messagebird.Client('YOUR_ACCESS_KEY')
    try:
        webhook = client.voice_delete_webhook('WEBHOOK_ID')
        print('Webhook has been deleted')
    except messagebird.client.ErrorException as e:
        print('An error occured while deleting a Voice Webhook object:')

        for error in e.errors:
            print('  code        : {}'.format(error.code))
            print('  description : {}'.format(error.description))
            print('  parameter   : {}\n'.format(error.parameter))
if __name__ == "__main__":
    main()
`,
  node: `
var messagebird = require('messagebird')('<YOUR_ACCESS_KEY>');
messagebird.webhooks.delete('<WEBHOOK_ID>', function (err, response) {
  if (err) {
      return console.log(err);
  }
  console.log(response);
});
`,
  csharp: `
using System;
using MessageBird;
using MessageBird.Exceptions;

namespace Examples.Webhooks
{
    internal class DeleteWebhook
    {
        const string YOUR_ACCESS_KEY = "YOUR_ACCESS_KEY";

        internal static void Main(string[] args)
        {
            var client = Client.CreateDefault(YOUR_ACCESS_KEY);

            try
            {
                client.DeleteWebhook("WEBHOOK ID");
                Console.WriteLine("The Webhook was deleted successfully.");
            }
            catch (ErrorException e)
            {
                // Either the request fails with error descriptions from the endpoint.
                if (e.HasErrors)
                {
                    foreach (var error in e.Errors)
                    {
                        Console.WriteLine("code: {0} description: '{1}' parameter: '{2}'", error.Code, error.Description, error.Parameter);
                    }
                }
                // or fails without error information from the endpoint, in which case the reason contains a 'best effort' description.
                if (e.HasReason)
                {
                    Console.WriteLine(e.Reason);
                }
            }
            Console.WriteLine("Press any key to continue...");
            Console.ReadKey();
        }
    }
}

`,
};

export const HANDLEWEBHOOKCALL_REQUEST = {
  json: `POST / HTTP/1.1
Host: example.com
Content-Type: application/json
X-MessageBird-Signature: roAnwpDfIzW6G3JpKVS6866CKHcJQUiAC9DqswzGY6s/xFPUIa5Qla9AZuCOSQ=
Content-Length: 760

{
  "timestamp": "2017-08-30T07:56:46Z",
  "items": [
    {
      "type": "call",
      "event": "callUpdated",
      "payload": {
        "id": "5a0dfae6-c809-4f6f-9e3d-c593149e4c0f",
        "status": "ended",
        "source": "31644556677",
        "destination": "31612345678",
        "createdAt": "2017-08-30T07:54:22Z",
        "updatedAt": "2017-08-30T07:56:46Z",
        "endedAt": "2017-08-30T07:56:46Z",
      }
    }
  ]
}`,
};

export const HANDLEWEBHOOKLEG_REQUEST = {
  json: `POST / HTTP/1.1
Host: example.com
Content-Type: application/json
X-MessageBird-Signature: roAnwpDfIzW6G3JpKVS6866CKHcJQUiAC9DqswzGY6s/xFPUIa5Qla9AZuCOSQ=
Content-Length: 376

{
  "timestamp": "2017-09-14T07:40:12Z",
  "items": [
    {
      "type": "leg",
      "payload": {
        "id": "f3e699db-68a5-49dc-8f5b-e5272a7349c7",
        "callId": "2452531a-b7a9-4797-a45e-20f5feaf46ce",
        "source": "31644556677",
        "destination": "31612345678",
        "status": "ringing",
        "direction": "outgoing",
        "duration": 0,
        "createdAt": "2017-09-14T07:40:07Z",
        "updatedAt": "2017-09-14T07:40:07Z",
        "answeredAt": null,
        "endedAt": null
      }
    }
  ]
}`,
};

export const HANDLEWEBHOOK_AMD_REQUEST = {
  json: `POST / HTTP/1.1
Host: example.com
Accept-Encoding: gzip
Content-Type: application/json
Messagebird-Request-Timestamp: 1593666577
Messagebird-Signature: VasRR95LGHzM2DJ1tF/qaegtzUm8alUPVhiLNQoQjmQ=
User-Agent: MessageBirdHTTPQueue/1386
X-Messagebird-Request-Id: 3a13544c-bc22-11ea-a9a1-363c205f94d1
Content-Length: 817
Connection: keep-alive

{
    "timestamp": "2020-07-02T05:09:37.745741202Z",
    "items": [
        {
            "type": "AMDCompleteEvent",
            "event": "amdComplete",
            "payload": {
                "Call": {
                    "id": "c95ac24f-10e7-48ad-aa03-2887727d03b0",
                    "status": "ongoing",
                    "source": "31644556677",
                    "destination": "31612345678",
                    "webhook": {
                        "url": "https://example.com"
                    },
                    "createdAt": "2020-07-02T05:09:29Z",
                    "updatedAt": "2020-07-02T05:09:33Z",
                    "endedAt": null
                },
                "Leg": {
                    "id": "46733c65-0865-4c53-9e65-f7a09f0ec588",
                    "callId": "c95ac24f-a0e7-48ad-aa03-2887727d03b0",
                    "source": "31207009850",
                    "destination": "31624973844",
                    "status": "ongoing",
                    "direction": "outgoing",
                    "duration": null,
                    "createdAt": "2020-07-02T05:09:29Z",
                    "updatedAt": "2020-07-02T05:09:37Z",
                    "answeredAt": "2020-07-02T05:09:33.183Z",
                    "endedAt": null
                },
                "Status": "MACHINE",
                "Cause": "INITIALSILENCE-2500-2500",
                "Timestamp": "2020-07-02T05:09:37.745741202Z"
            }
        }
    ]
}`,
};

export const HANDLEWEBHOOKLEG_SIGNING_CMD = {
  bash: `
# Emulate the X-Messagebird-Signature generation from the command line using openssl and base64.

$ echo -n '{"timestamp":"2019-07-02T12:03:52.704109636Z","items":[{"type":"call","event":"callCreated","payload":{"id":"1ebbae0e-e1a6-4b85-8ccb-de609d0b4510","status":"queued","source":"31644556677","destination":"31612345678","webhook":{"url":"https://example.com","token":"test"},"createdAt":"2019-07-02T12:03:52Z","updatedAt":"2019-07-02T12:03:52Z","endedAt":null}}]}' | openssl dgst -sha256 -hmac "test" -binary |base64
X6S0ik6yQhYiVW6KqhRpYxUGSNIdebNQiESuHeGuQ2I=`,
};

export const HANDLEWEBHOOKLEG_SIGNING = {
  json: `signature = HMAC_SHA_256(
SHA_256_SUM(BODY),
signing_key
)

if HMAC_EQUALS(signature, BASE_64_DECODE(request_signature)) {
    // Yay!
}`,
};

export const DYNAMIC_CALL_FLOW_SIGNING_CMD = {
  bash: `
# Emulate the X-Messagebird-Signature generation from the command line using openssl and base64.

$ echo -n 'callID=97f9b1d2-b8b4-4db7-8829-ec3e660d2cdb&destination=19027064857&firstLegDirection=incoming&source=%2B31611171210&variables=%7B%22testingvar%22%3A%222%22%7D'| openssl dgst -sha256 -hmac "some-custom-token" -binary |base64
v3HpIXiFBDvwiwA4wwgRg6T85Vs7o74w8wAvkOoyDo8=`,
};

export const DYNAMIC_CALL_FLOW_SIGNING = {
  json: `
// Note that the query params should be sorted by alphabetically order of the keys including the variables array
signature = HMAC_SHA_256(
QUERY_PARAMS,
signing_key
)

if HMAC_EQUALS(signature, BASE_64_DECODE(request_signature)) {
    // Yay!
}`,
};

// combines all the URI's but needs to be defined after them
export const VOICE_HTTP_METHODS = {
  json:
    CALLFLOW_METHODS.json +
    `\n` +
    CALLS_METHODS.json +
    `\n` +
    LEGS_METHODS.json +
    `\n` +
    RECORDINGS_METHODS.json +
    `\n` +
    TRANSCRIPTIONS_METHODS.json +
    `\n` +
    WEBHOOKS_METHODS.json,
};

export const OTP_AMD_SAY_HANGUP = {
  json: `
  {
  "destination": "31612345678",
  "source": "31644556677",
  "callFlow": {
    "steps": [
      {
        "action": "say",
        "options": {
          "payload": "Testing hangup on AMD for OTP. Call should hangup",
          "voice": "male",
          "language": "en-GB",
          "ifMachine": "hangup"
        }
      }
    ]
  },
  "webhook": {
    "url": "https://some-callback-url"
  }
}
`,
};

export const OTP_AMD_TRANSFER_HANGUP = {
  json: `
  {
  "destination": "31612345678",
  "source": "31644556677",
  "callFlow": {
    "steps": [
      {
        "action": "say",
        "options": {
          "payload": "Testing hangup on AMD for transfers. Call should hangup shortly after ringing.",
          "voice": "male",
          "language": "en-GB"
        }
      },
      {
        "action":"transfer",
        "options":{
          "destination":"31612345555",
          "ifMachine":"hangup"
        }
      }
    ]
  },
  "webhook": {
    "url": "https://some-callback-url"
  }
}

`,
};

export const NUMBERS_URI = {
  json: `https://voice.messagebird.com/numbers`,
};

export const NUMBERS_METHODS = {
  json: [
    `GET /numbers`,
    `GET /numbers/{number}/call-flow`,
    `POST /numbers/{number}/webhook`,
    `GET /numbers/{number}/webhook`,
  ].join('\n'),
};

export const NUMBER_OBJECT = {
  json: `{
      "id": "d212d28a-bfe7-1235-1abd-f01fafdf8755",
      "number": "31123456789",
      "callFlowId": "200d95ee-4fcf-46e7-b75a-32a26cb6ebf4",
      "createdAt": "2019-11-11T14:53:47Z",
      "updatedAt": "2020-07-30T14:30:11Z"
  }`,
};

export const LISTNUMS_DEF = {
  json: `GET https://voice.messagebird.com/numbers`,
};

export const LISTNUMS_REQUEST = {
  curl: `curl -X GET "https://voice.messagebird.com/numbers?page=1" \\
  -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM"`,
};

export const LISTNUMS_RESPONSE = {
  json: `{
    "data": [
      {
        "id": "d212d28a-bfe7-1235-1abd-f01fafdf8755",
        "number": "31123456789",
        "callFlowId": "200d95ee-4fcf-46e7-b75a-32a26cb6ebf4",
        "createdAt": "2019-11-11T14:53:47Z",
        "updatedAt": "2020-07-30T14:30:11Z"
      },
      {
        "id": "023746d4-4ba4-44eb-a619-ba50da3b16f1",
        "number": "32460205634",
        "callFlowId": "e7b768eb-df78-4271-8940-9ffb9149dae7",
        "createdAt": "2018-10-24T16:12:03Z",
        "updatedAt": "2019-02-05T14:38:19Z"
      }
    ],
    "_links": {
      "self": "/numbers?page=1"
    },
    "pagination": {
      "totalCount": 2,
      "pageCount": 1,
      "currentPage": 1,
      "perPage": 10
    }
  }`,
};

export const GETNUMWEBHOOK_URI = {
  json: `https://voice.messagebird.com/numbers/{number}/webhook`,
};

export const GETNUMWEBHOOK_DEF = {
  json: `GET https://voice.messagebird.com/numbers/{number}/webhook`,
};

export const GETNUMWEBHOOK_REQUEST = {
  curl: `curl -X GET "https://voice.messagebird.com/numbers/{number}/webhook" \\
  -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM"`,
};

export const GETNUMWEBHOOK_RESPONSE = {
  json: `{
    "data": [
      {
        "id": "5f3cf195-b9c2-40fb-b4a6-aad529115f8f",
        "url": "https://example.com/343334",
        "token": "foobar123",
        "createdAt": "2020-08-03T22:50:14Z",
        "updatedAt": "2020-08-03T22:50:14Z"
      }
    ],
    "_links": {
      "self": "/numbers/3123XXXXXX/webhook"
    }
  }`,
};

export const CREATENUMWEBHOOK_URI = {
  json: `https://voice.messagebird.com/numbers/{number}/webhook`,
};

export const CREATENUMWEBHOOK_DEF = {
  json: `POST https://voice.messagebird.com/numbers/{number}/webhook`,
};

export const CREATENUMWEBHOOK_REQUEST = {
  curl: `curl -X POST "https://voice.messagebird.com/numbers/{number}/webhook" \\
  -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM" \\
  -H 'Content-Type: application/json' \\
  -d $'{
      "url": "https://example.com/test",
      "token": "foobar123"
  }'`,
};

export const CREATENUMWEBHOOK_RESPONSE = {
  json: `{
    "data": [
      {
        "id": "a3105944-703d-413f-bb13-42027dbee2ab",
        "url": "https://example.com/test",
        "token": "foobar123",
        "createdAt": "2020-08-04T00:31:10Z",
        "updatedAt": "2020-08-04T00:31:10Z"
      }
    ],
    "_links": {
      "self": "/webhooks/a3105944-703d-413f-bb13-42027dbee2ab"
    }
  }`,
};

export const CALLFLOW_CONDITIONS = {
  json: `{
  "steps": [
    {
      "action": "say",
      "options": {
        "payload": "press 1 to talk to department A, press 2 to talk to department B",
        "language": "en-US",
        "voice": "male"
      },
      "onKeypressVar": "digitpressed",
      "onKeypressGoto": "transferdept"
    },
    {
      "action": "pause",
      "options": {
        "length": "5s"
      },
      "onKeypressVar": "digitpressed",
      "onKeypressGoto": "transferdept"
    },
    {
      "action": "say",
      "options": {
        "payload": "you didn't press anything. Hanging up",
        "language": "en-US",
        "voice": "male"
      }
    },
    {
      "action": "hangup"
    },
    {
      "id": "transferdept",
      "action": "say",
      "options": {
        "payload": "you are being transferred to department A",
        "language": "en-US",
        "voice": "male"
      },
      "conditions": [
        {
          "variable": "digitpressed",
          "condition": "==",
          "value": "1"
        }
      ]
    },
    {
      "action": "transfer",
      "options": {
        "destination": "316123456781"
      },
      "conditions": [
        {
          "variable": "digitpressed",
          "condition": "==",
          "value": "1"
        }
      ]
    },
    {
      "action": "say",
      "options": {
        "payload": "you are being transferred to department B",
        "language": "en-US",
        "voice": "male"
      },
      "conditions": [
        {
          "variable": "digitpressed",
          "condition": "==",
          "value": "2"
        }
      ]
    },
    {
      "action": "transfer",
      "options": {
        "destination": "35123123123"
      },
      "conditions": [
        {
          "variable": "digitpressed",
          "condition": "==",
          "value": "2"
        }
      ]
    },
    {
      "action": "say",
      "options": {
        "payload": "goodbye",
        "language": "en-US",
        "voice": "male"
      }
    }
  ]
}`,
};
