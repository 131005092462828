import React from 'react';
import { Flex, Box } from '@rebass/grid';
import styled from 'styled-components';
import Helmet from 'react-helmet';

import Layout from '../components/Layout/Default';
import {
  Card as SharedCard,
  CardWrap,
  CardContainer,
} from '../components/shared';
import { COLOR } from '../constants';
import LogoFooter from '../components/LogoFooter';
import { track } from '../helpers/tracking';

const libraries = [
  {
    name: 'PHP',
    safe: 'php',
    icon: '/img/icons/codelangs/php.svg',
    link: 'https://github.com/messagebird/php-rest-api',
  },
  {
    name: 'Ruby',
    safe: 'ruby',
    icon: '/img/icons/codelangs/ruby.svg',
    link: 'https://github.com/messagebird/ruby-rest-api',
  },
  {
    name: 'Go',
    safe: 'go',
    icon: '/img/icons/codelangs/go.svg',
    link: 'https://github.com/messagebird/go-rest-api',
  },
  {
    name: 'C#',
    safe: 'csharp',
    icon: '/img/icons/codelangs/csharp.svg',
    link: 'https://github.com/messagebird/csharp-rest-api',
  },
  {
    name: 'Python',
    safe: 'python',
    icon: '/img/icons/codelangs/python.svg',
    link: 'https://github.com/messagebird/python-rest-api',
  },
  {
    name: 'Java',
    safe: 'java',
    icon: '/img/icons/codelangs/java.svg',
    link: 'https://github.com/messagebird/java-rest-api',
  },
  {
    name: 'NodeJS',
    safe: 'node',
    icon: '/img/icons/codelangs/node.svg',
    link: 'https://github.com/messagebird/messagebird-nodejs',
  },
];

const Title = styled.h5`
  color: ${COLOR.GREY};
  margin-top: 30px;
`;

const Card = styled(SharedCard)`
  &:hover {
    .php {
      background-image: url('/img/icons/codelangs/php_colored.svg');
    }
    .ruby {
      background-image: url('/img/icons/codelangs/ruby_colored.svg');
    }
    .go {
      background-image: url('/img/icons/codelangs/go_colored.svg');
    }
    .csharp {
      background-image: url('/img/icons/codelangs/csharp_colored.svg');
    }
    .python {
      background-image: url('/img/icons/codelangs/python_colored.svg');
    }
    .java {
      background-image: url('/img/icons/codelangs/java_colored.svg');
    }
    .node {
      background-image: url('/img/icons/codelangs/node_colored.svg');
    }
  }
`;

const Img = styled.div`
  height: 60px;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  transition: background-image 0.2s ease;
`;

const Libraries = (props) => (
  <Layout fullWidth {...props}>
    <Flex mb={60} justifyContent="center">
      <Box width={666} css="text-align: center;">
        <h1>Official SDKs</h1>
        <p>
          We offer official SDKs for many programming languages, so you can
          start adding messaging functionality to your application right away.
          Visit the Github repos via the cards below.
        </p>
      </Box>
    </Flex>

    <CardWrap css="justify-content:center;">
      {libraries.map((sdk) => (
        <CardContainer key={sdk.name} css="flex: 0 0 25%;text-align: center;">
          <a
            href={sdk.link}
            rel="noopener noreferrer"
            target="_blank"
            onClick={() => track('dev:librariesSdkSelected', { sdk: sdk.safe })}
          >
            <Card>
              <Img
                className={sdk.safe}
                css={{ backgroundImage: `url(${sdk.icon})` }}
              />
              <Title>{sdk.name}</Title>
            </Card>
          </a>
        </CardContainer>
      ))}
    </CardWrap>
    <LogoFooter />
  </Layout>
);

export default Libraries;
