import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import Notification from 'components/Notification';
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
import { Img } from 'components/shared';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Install Channel: WhatsApp`}</h1>
    <p>{`Let's start using the Programmable Conversations API by learning how to install a channel. In this quickstart, you'll learn how to set up WhatsApp Business.`}</p>
    <p>{`Before we go ahead, have you completed the `}<a parentName="p" {...{
        "href": "https://developers.messagebird.com/docs/conversations/whatsapp/getting-started/"
      }}>{`Getting Started with WhatsApp`}</a>{` quickstart?`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`No!`}</strong>{` Make sure to do so before continuing.`}</li>
      <li parentName="ul"><strong parentName="li">{`Yes!`}</strong>{` Great! Go to step 1.`}</li>
    </ul>
    <h2>{`Step 1: Go to the channels directory in your Dashboard`}</h2>
    <p>{`Go to '`}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/channels"
      }}>{`Channels`}</a>{`' in the sidebar of your MessageBird Dashboard. Then click on `}<inlineCode parentName="p">{`Add channel`}</inlineCode>{` under WhatsApp.`}</p>
    <Img src="/img/screenshots/quickstarts/convo/InstallWhatsApp1.png" alt="Install WhatsApp" width="650px" align="middle" mdxType="Img" />
    <h2>{`Step 2: Give your channel a name`}</h2>
    <p>{`Name your channel, make sure it's easily recognisable for yourself and your team. Don't worry, this will only be used internally. `}</p>
    <Img src="/img/screenshots/quickstarts/convo/InstallWhatsApp2.png" alt="Install WhatsApp" width="650px" align="middle" mdxType="Img" />
    <h2>{`Step 3: Add your WhatsApp number`}</h2>
    <p>{`Add the phone number which you'll be using for WhatsApp. This is the number you chose in step 4 of the `}<a parentName="p" {...{
        "href": "https://developers.messagebird.com/docs/conversations/whatsapp/getting-started/"
      }}>{`Getting Started with WhatsApp`}</a>{` quickstart.`}</p>
    <Img src="/img/screenshots/quickstarts/convo/InstallWhatsApp3.png" alt="Install WhatsApp" width="650px" align="middle" mdxType="Img" />
    <h2>{`Step 4: Add your vname certificate`}</h2>
    <p>{`You're almost there! Paste or download your vname certificate. You received it via email when your number was verified in step 6 of the `}<a parentName="p" {...{
        "href": "https://developers.messagebird.com/docs/conversations/whatsapp/getting-started/"
      }}>{`Getting Started with WhatsApp`}</a>{` quickstart.`}</p>
    <Img src="/img/screenshots/quickstarts/convo/InstallWhatsApp4.png" alt="Install WhatsApp" width="650px" align="middle" mdxType="Img" />
    <h2>{`Step 5: Install WhatsApp`}</h2>
    <p>{`Click on 'Install now' and voilà! WhatsApp Business is now installed as a channel. `}</p>
    <p>{`Keep in mind that it might take up to 10 minutes for your installation to be approved, during those minutes the status will be 'Pending'.`}</p>
    <Img src="/img/screenshots/quickstarts/convo/InstallWhatsApp5.png" alt="Install WhatsApp" width="650px" align="middle" mdxType="Img" />
    <h2>{`Step 6: Manage your channel`}</h2>
    <p>{`If you want to edit, rename or delete your channel, simply select the channel and save the changes afterwards.`}</p>
    <p>{`Nice work! 🎉 You've successfully installed WhatsApp Business as a channel for the MessageBird Programmable Conversations API.`}</p>
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'WhatsApp Message Templates',
      to: '/quickstarts/whatsapp/message-templates-curl'
    }, {
      name: 'WhatsApp guidelines for customer opt-ins',
      to: '/quickstarts/whatsapp/whatsapp-customer-options'
    }, {
      name: 'WhatsApp troubleshooting',
      to: '/quickstarts/whatsapp/troubleshooting-whatsapp'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      