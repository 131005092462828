/* eslint-disable react/prop-types */
import React from 'react';
import LanguageContext from './src/contexts/LanguageContext';
import LocationContext from './src/contexts/LocationContext';
import { HeadersContext } from './src/contexts/PageHeadersContext';

export const onRouteUpdate = ({ location }) => {
  if (window.analytics) {
    window.analytics.page({
      path: location.pathname,
    });
  }

  // Scroll to hash even if content waz lazyloaded
  const { hash } = location;
  if (hash !== '') {
    // Push onto callback queue so it runs after the DOM is updated,
    // this is required when navigating from a different page so that
    // the element is rendered on the page before trying to getElementById.
    setTimeout(() => {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) element.scrollIntoView();
    }, 0);
  } else {
    window.scroll(0, 0);
  }
};

export const onClientEntry = () => {
  window.MessageBird = {
    goto: {
      careers() {
        window.location.href = 'https://www.messagebird.com/en/careers';
      },
      api() {
        window.location.href = 'https://developers.messagebird.com/';
      },
      home() {
        window.location.href = 'https://www.messagebird.com/';
      },
      about() {
        window.location.href = 'https://www.messagebird.com/en/about';
      },
    },
    api: {
      info() {
        const text =
          '\nThe MessageBird API connects your website or application\n' +
          'to operators around the world.\n\n' +
          'The MessageBird API uses HTTP verbs and a RESTful endpoint structure.\n' +
          'An access key is used as the API Authorization framework. Request and\n' +
          'response payloads are formatted as JSON (although we provide a GET\n' +
          'alternative for requests), using UTF-8 encoding and URL encoded values.\n\n';
        window.MessageBird.log(text);
        return 'Check out our libraries: MessageBird.api.libraries();';
      },
      libraries() {
        const text =
          '\nPHP: https://github.com/messagebird/php-rest-api\n' +
          'Ruby: https://github.com/messagebird/ruby-rest-api\n' +
          'NodeJS: https://github.com/messagebird/messagebird-nodejs\n' +
          'GO: https://github.com/messagebird/go-rest-api\n' +
          'C#: https://github.com/messagebird/csharp-rest-api\n' +
          'Python: https://github.com/messagebird/python-rest-api\n' +
          'Java: https://github.com/messagebird/java-rest-api\n\n';
        window.MessageBird.log(text);
        return 'Check out our full docs: MessageBird.goto.api();';
      },
    },
    easterEggs: {
      eggs: [
        {
          name: 'Console Egg Hunt',
          checked: true,
        },
        {
          name: 'Office Flags',
          checked: false,
        },
        {
          name: 'Easter Egg 1',
          checked: false,
        },
        {
          name: 'Easter Egg 2',
          checked: false,
        },
        {
          name: 'Easter Egg 3',
          checked: false,
        },
      ],
      list(kicker) {
        let list = '\nAll of our hidden easter eggs:\n';

        kicker = typeof kicker === 'undefined';

        for (let i = 0; i < this.eggs.length; i++) {
          list += `- ${this.eggs[i].name}`;

          if (this.eggs[i].checked) {
            list += ' ✓';
          }

          list += '\n';
        }

        const text =
          '\nHave you found them all? Let us know on Twitter: @MessageBird.\n' +
          'If not, try MessageBird.hint();\n\n' +
          "Use MessageBird.found('Easter Egg'); to check it off the list.\n" +
          "i.e. MessageBird.found('Office Flags');\n\n";

        const output = kicker ? list + text : `${list}\n`;
        window.MessageBird.log(output);
      },
      hint() {
        const text = '\nSelect our other offices.';
        window.MessageBird.log(text);
      },
      found(name) {
        for (let i = 0; i < this.eggs.length; i++) {
          if (this.eggs[i].name === name) {
            this.eggs[i].checked = true;
            this.list(false);
            this.save();
            this.checkProgress();
            return;
          }
        }
      },
      save() {
        if (window.u.localstorage) {
          localStorage.mbEggs = JSON.stringify(window.MessageBird.eggs);
        }
      },
      checkProgress() {
        let completed = 0;
        for (let i = 0; i < this.eggs.length; i++) {
          completed += this.eggs[i].checked === true ? 1 : 0;
        }
        if (completed === this.eggs.length) {
          window.MessageBird.log('Completed!');
        }
      },
    },
    printMessageToConsole() {
      const art =
        '\n  .-------------------.\n' +
        '  | Well hello there! |\n' +
        "  |/------------------'\n" +
        '  .-.  ,-.\n' +
        '  ;oo  oo;\n' +
        " / \\|  |/ '\n" +
        "|. `.  .' .|\n" +
        "`;.;'  `;.;'\n" +
        '.-^-.  .-^-.';

      const text =
        '\n\n=========================================================================\n\n' +
        "If you like our source code, you'll love our API! Run\n" +
        'MessageBird.goto.api() to learn more about our API and\n' +
        'how you can use it in your project, or type MessageBird.api.info() and\n' +
        'press Enter.\n\n' +
        'Do you want to help build the telecom company of the future?\n' +
        'Run MessageBird.goto.careers() for current job openings or\n' +
        'MessageBird.goto.about() to learn more about the company.\n' +
        'We would love to hear from you!\n\n' +
        '- MessageBird Team' +
        '\n\n=========================================================================\n';
      this.log(art + text);
    },
    disable() {
      this.disabled = true;
    },
    enable() {
      this.disabled = false;
    },
    log(output) {
      console.log(`%c ${output}`, 'background: #fff; color: #2481d7');
    },
  };
  window.MessageBird.printMessageToConsole();
};

export const wrapRootElement = ({ element }) => {
  return (
    <LanguageContext>
      <HeadersContext>{element}</HeadersContext>
    </LanguageContext>
  );
};

export const wrapPageElement = ({ element, props }) => {
  const { location } = props;

  return (
    <LocationContext.Provider value={location}>
      {element}
    </LocationContext.Provider>
  );
};
