import { Link } from 'gatsby';
import CodeSnippet from 'components/CodeSnippet';
import * as CODE from "../../../../src/constants/sms";
import Notification from 'components/Notification';
import { APIWrapper, LeftColumn, RightColumn } from 'components/ApiRef';
import StatusLabel from 'components/StatusLabel';
import * as React from 'react';
export default {
  Link,
  CodeSnippet,
  CODE,
  Notification,
  APIWrapper,
  LeftColumn,
  RightColumn,
  StatusLabel,
  React
};