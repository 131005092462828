import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Getting Started with MessageBird`}</h1>
    <h3>{`⏱ 15 min read`}</h3>
    <p>{`This MessageBird Developer Tutorial will teach you the basics of getting started with MessageBird’s Dashboard and APIs.`}</p>
    <h2>{`Sign up for free`}</h2>
    <p>{`First things first, let's head over to `}<a parentName="p" {...{
        "href": "https://www.messagebird.com"
      }}>{`www.messagebird.com`}</a>{` and sign up for a free test account - no credit card is required to sign up and start testing. Begin by completing all the required fields, check your inbox for the confirmation email and tada! 🎉 You now have an active MessageBird account.`}</p>
    <h2>{`Claim free test credits & API Key`}</h2>
    <p>{`First, we need to verify you're human and as a reward, you'll receive 10 free `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/articles/115002599309-Test-credits"
      }}>{`test credits`}</a>{` that you can use to test MessageBird’s APIs.`}</p>
    <p><strong parentName="p">{`Pro-Tip:`}</strong>{` You can only use test credits to send messages to your own verified number. You can skip this step and go straight to top-up your balance and/or purchasing a virtual mobile number.`}</p>
    <p>{`If you run out of test credits but aren't quite done testing, just let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we'd love to help.`}</p>
    <h2>{`Create your API key`}</h2>
    <p>{`To enable any MessageBird SDK, you’ll need to provide an API access key.`}</p>
    <p>{`MessageBird provides keys in `}<em parentName="p">{`live`}</em>{` and `}<em parentName="p">{`test`}</em>{` modes for different purposes:`}</p>
    <ul>
      <li parentName="ul">{`To send live messages, you’ll need to create and use a live API access key.`}</li>
      <li parentName="ul">{`To simply test the API, you can use a test API access key. No messages will be sent in this case.`}</li>
    </ul>
    <p><strong parentName="p">{`Pro-Tip:`}</strong>{` You can read more about the `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/articles/360000670709-What-is-the-difference-between-a-live-key-and-a-test-key-"
      }}>{`difference between test and live API keys`}</a>{` in our Help Center.`}</p>
    <p>{`So let's create your live API access key. First, go to the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/user/index"
      }}>{`MessageBird Dashboard`}</a>{`; if you have already created an API key it will be shown right there. If you don’t see any key on the Dashboard or if you're unsure whether this key is in live mode, go to the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/settings"
      }}>{`Developers section`}</a>{` in the MessageBird Dashboard and open the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`API access (REST) tab`}</a>{`. Here, you can create new API keys and manage your existing ones.`}</p>
    <p>{`If you’re having any issues creating your API key, please reach out to `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we’ll make sure to help you out.`}</p>
    <h2>{`Purchase a virtual mobile number`}</h2>
    <p>{`MessageBird offers numbers from different countries for a low monthly fee; `}<a parentName="p" {...{
        "href": "https://www.messagebird.com/en/numbers"
      }}>{`feel free to explore our low-cost programmable and configurable numbers`}</a>{`.
Purchasing a number is quite easy:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Go to the `}<a parentName="p" {...{
            "href": "https://dashboard.messagebird.com/en/numbers"
          }}>{`‘Numbers’`}</a>{` section in the left-hand side of your Dashboard and click the blue button `}<a parentName="p" {...{
            "href": "https://dashboard.messagebird.com/en/vmn/buy-number"
          }}>{`‘Buy a number’`}</a>{` in the top-right side of your screen.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Pick the country in which you and your customers are located, and make sure you select the capabilities that you need: SMS, MMS, Voice (you can select more than one!)`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Choose one number from the selection and the duration for which you want to pay now. `}<img parentName="p" {...{
            "src": "/img/screenshots/getting-started/buy-a-number.png",
            "alt": "Buy a number screenshot"
          }}></img></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Confirm by clicking ‘Buy Number’ in the bottom-right of your screen.`}</p>
      </li>
    </ol>
    <p>{`Congratulations, you’ve set up your first virtual mobile number!  🎉`}</p>
    <p><strong parentName="p">{`Pro-Tip:`}</strong>{` Check out our `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us"
      }}>{`Help Center`}</a>{` for more information about `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/sections/201958489-Virtual-Numbers"
      }}>{`virtual mobile numbers`}</a>{` and `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/sections/360000108538-Country-info-Restrictions"
      }}>{`country restrictions`}</a>{`.`}</p>
    <h2>{`How to upgrade your MessageBird account`}</h2>
    <p>{`If you're done testing or want to skip straight to sending live messages to numbers other than your own, it's time to upgrade your account by topping up your balance. You can do this in a two ways:`}</p>
    <h3>{`Top-up via your Dashboard`}</h3>
    <p>{`Just hit the  `}<strong parentName="p">{`+Top-up`}</strong>{` button in the top left-hand corner of your `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/app/en-au/user/index"
      }}>{`Dashboard`}</a>{`. You choose your desired `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en-je/financial/buy-balance"
      }}>{`balance`}</a>{`, proceed to checkout and select your payment method. Keep in mind that the minimum balance purchase amount is €15,00.`}</p>
    <h3>{`Set up auto top-up`}</h3>
    <p>{`Want to set and forget? Head over to `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en-je/finance/settings"
      }}>{`Preferences`}</a>{` to configure your `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/articles/200787931-Auto-order"
      }}>{`‘Auto order’`}</a>{` settings. You can also configure a `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/articles/200788271-Set-low-balance-notifications"
      }}>{`low balance warning`}</a>{`, so you’re notified when your balance goes below a certain amount.`}</p>
    <p><strong parentName="p">{`Nice work!`}</strong>{` 🎉`}</p>
    <p>{`You’re all ready to start with the MessageBird Dashboard and APIs!`}</p>
    <h2>{`Start building!`}</h2>
    <p>{`Start building your communications solution by checking out more `}<a parentName="p" {...{
        "href": "/tutorials"
      }}>{`MessageBird Developer Tutorials`}</a>{` for common SMS, Voice and Chat use-cases.`}</p>
    <p>{`Want to get started but not quite sure how? Feel free to let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we'd love to help.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      