/** flattenByKey helps to flatten an array of objects with multidimensional children with the same keys
 * @param {array} arr - The array object with multidimensional sub-objects
 * @param {string} key - The key to flatten the object by
 */
function flattenByKey(arr, key) {
  return arr.reduce((acc, item) => {
    if (item[key]) {
      return [...acc, ...flattenByKey(item[key], key), item];
    }
    return [...acc, item];
  }, []);
}

export default flattenByKey;
