import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex } from '@rebass/grid';
import Cookies from 'js-cookie';
import theme from '@messagebird/theme';

const lang =
  typeof Cookies.get('lang') !== 'undefined' ? Cookies.get('lang') : 'en';

const FooterWrap = styled.footer`
  margin: ${theme.spacing(4)} auto;
  max-width: 1008px;
`;

const Copyright = styled.p`
  text-align: center;
  margin-top: 32px;
  margin-bottom: 80px;
  color: #9aa8bd;
  font-size: 14px;
  line-height: 18px;
`;

const ListItem = styled.a`
  color: #9aa8bd;
  font-size: 16px;
  line-height: 16px;
  margin-left: 20px;
  margin-right: 20px;
  @media screen and (max-width: 767px) {
    margin-bottom: 20px;
  }
`;

export const Footer = ({ fullWidth }) => (
  <FooterWrap>
    <Flex flexWrap="nowrap" justifyContent="center">
      <ListItem href={`https://www.messagebird.com/${lang}/about`}>
        About
      </ListItem>
      <ListItem href={`https://www.messagebird.com/${lang}/careers`}>
        Careers
      </ListItem>
      <ListItem href="https://support.messagebird.com/hc/en-us">
        Support
      </ListItem>
      <ListItem href="https://status.messagebird.com/">Status</ListItem>
    </Flex>
    <Copyright>
      &copy; {new Date().getFullYear()} by MessageBird. All rights reserved.
    </Copyright>
  </FooterWrap>
);

Footer.propTypes = {
  fullWidth: PropTypes.bool,
};

Footer.defaultProps = {
  fullWidth: false,
};
