import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
import CodeSnippet from 'components/CodeSnippet';
import * as CODE from 'constants/live-chat-widget';
import { Img } from 'components/shared';
import * as React from 'react';
export default {
  NextSteps,
  Questions,
  CodeSnippet,
  CODE,
  Img,
  React
};