import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import { Img } from 'components/shared';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`WhatsApp carousel template messages`}</h1>
    <p><a parentName="p" {...{
        "href": "https://developers.facebook.com/docs/whatsapp/business-management-api/message-templates/carousel-templates"
      }}>{`WhatsApp carousel templates`}</a>{`
allow your business to send a single message with up to 10 cards in a horizontally scrollable view. Each card must have
a media header (image or video), a body and at least one button (with max 2 buttons). Buttons can be the same, or a mix
of URL buttons, quick replies and call buttons.`}</p>
    <p>{`A body component is required along with the carousel component. This body component is displayed as a message bubble.
The media header format and the combination of buttons must be the same across all cards in the carousel.`}</p>
    <h2>{`Creating a WhatsApp carousel template`}</h2>
    <p>{`The following JSON code illustrates how to create a template with a body and a carousel, which contains two cards, each
one with an image header, a body and two buttons: a quick reply and a URL button with a variable. The values used as
examples can be customised when sending the template messages. The following JSON must be the payload of a POST request
to the `}<a parentName="p" {...{
        "href": "/api/integrations/#create-template"
      }}>{`Integrations API create templates endpoint`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "name": "whatsapp_carousel_template",
  "category": "MARKETING",
  "language": "en_US",
  "components": [
    {
      "type": "BODY",
      "text": "Message bubble"
    },
    {
      "type": "CAROUSEL",
      "cards": [
        {
          "components": [
            {
              "type": "HEADER",
              "format": "IMAGE",
              "example": {
                "header_url": ["https://bird.com/products/product1.jpg"]
              }
            },
            {
              "type": "BODY",
              "text": "Carousel card 1"
            },
            {
              "type": "BUTTONS",
              "buttons": [
                {
                  "type": "QUICK_REPLY",
                  "text": "More information"
                },
                {
                  "type": "URL",
                  "text": "Buy",
                  "url": "https://bird.com/products/{{1}}",
                  "example": ["product1"]
                }
              ]
            }
          ]
        },
        {
          "components": [
            {
              "type": "HEADER",
              "format": "IMAGE",
              "example": {
                "header_url": ["https://bird.com/products/product2.jpg"]
              }
            },
            {
              "type": "BODY",
              "text": "Carousel card 2"
            },
            {
              "type": "BUTTONS",
              "buttons": [
                {
                  "type": "QUICK_REPLY",
                  "text": "More information"
                },
                {
                  "type": "URL",
                  "text": "Buy",
                  "url": "https://bird.com/products/{{1}}",
                  "example": ["product2"]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}
`}</code></pre>
    <h2>{`Sending a WhatsApp carousel template message`}</h2>
    <p>{`The following JSON code illustrates how to send the WhatsApp carousel template created previously using the
`}<a parentName="p" {...{
        "href": "/api/conversations/#send-message"
      }}>{`Conversations API send message endpoint`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "type": "hsm",
  "content": {
    "hsm": {
      "namespace": "c5437a4f_80d2_4019_a6fd_170cce7d7b76",
      "templateName": "whatsapp_carousel_template",
      "language": {
        "code": "en_US"
      },
      "components": [
        {
          "type": "carousel",
          "cards": [
            {
              "card_index": 0,
              "components": [
                {
                  "type": "header",
                  "parameters": [
                    {
                      "type": "image",
                      "image": {
                        "url": "https://bird.com/products/product1.jpg"
                      }
                    }
                  ]
                },
                {
                  "type": "button",
                  "sub_type": "url",
                  "index": 1,
                  "parameters": [
                    {
                      "type": "text",
                      "text": "products/card1"
                    }
                  ]
                }
              ]
            },
            {
              "card_index": 1,
              "components": [
                {
                  "type": "header",
                  "parameters": [
                    {
                      "type": "image",
                      "image": {
                        "url": "https://bird.com/products/product2.jpg"
                      }
                    }
                  ]
                },
                {
                  "type": "button",
                  "sub_type": "url",
                  "index": 1,
                  "parameters": [
                    {
                      "type": "text",
                      "text": "products/card2"
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  },
  "to": "+1234567890",
  "from": "b489e76b-c740-4c1c-97da-322390c426e0"
}
`}</code></pre>
    <p>{`Worth to note the following:`}</p>
    <ul>
      <li parentName="ul">{`The property `}<inlineCode parentName="li">{`card_index`}</inlineCode>{` inside every card is required and must contain the index of the card in the carousel`}</li>
      <li parentName="ul">{`The header format must be the same in all carousel cards`}</li>
      <li parentName="ul">{`The button types must be the same in all carousel cards`}</li>
    </ul>
    <p>{`The following image shows how the carousel message is displayed in WhatsApp:`}</p>
    <Img src="/img/screenshots/quickstarts/whatsapp/carousel-message.png" alt="WhatsApp carousel message" width="600px" align="middle" mdxType="Img" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      