// node
export const INSTALL = {
  json: `npm install @messagebird/client

# Or if you use yarn

yarn add @messagebird/client`,
};

export const AUTH = {
  json: `{
  "accessKeyID": "example", // ID of a MessageBird AccessKey
  "capabilities": [
  	"client.outgoing" + 
  	"?callProcessor=callflow" + 
  	"&callProcessorId=de3ed163-d5fc-45f4-b8c4-7eea7458c635" + 
  	"&identity=Bert" + 
  	"&variables=foo%3Dbar%26bar%3Dfoo"
  ],
  "iat": 1516239022, // issued at
  "exp": 1516239022, // Expiry time
}`,
};

// node
export const INIT = {
  node: `import { MessageBirdClient } from '@messagebird/client';

/**
 * The "refreshToken" can be called multiple times over time.
 * This function should return a promise that resolves with a valid JWT.
 * If needed, you can also fetch a new JWT within this function.
 * */
const refreshToken = async () => {
  if (isTokenExpired(myCurrentToken)) {
    return await refreshToken();
  }
  
  return myCurrentToken;
}

const client = new MessageBirdClient({
  refreshToken,
});

// start a connection to the MessageBird WebRTC API
client.setup({ jwt: myCurrentToken })
`,
};

export const CLIENT_ERROR = {
  node: `
const handleClientError = (error) => {
  switch(error.code) {
    case 403:
      // Unauthorized. Try refreshing the JWT and try again
      break;
    case 406:
      // Invalid identity. An error in "identity" capability of the the JWT
      break;
    case 500:
      // An internal error on our platform.
      break;
  }
}

client.on('error', handleClientError);

client.removeEventListener('error', handleClientError);
`,
};

export const CLIENT_STATUS = {
  node: `
const handleStatusChange = (status) => {
  switch(status) {
    case 'connected':
      break;
    case 'error':
      break;
    case 'connecting':
      break;
    case 'initial':
      break;
  }
};

// Start listening for status updates
client.on('status', handleStatusChange);

// Stop receiving updates for status changes
client.removeEventListener('status', handleStatusChange);
  `,
};

// node
export const START_CALL = {
  node: `
  const { call } = client.startCall({
    // Any additional data can be passed as arguments to the 'startCall' method
    foo: 'bar'
  });
 

  call.on('status', handleCallStatus);
  call.on('error', handleCallError);
  call.endCall();
`,
};

// node
export const INCOMING = {
  node: `client.on('incoming', incomingCall => {
  // Update your UI to display an incoming call.

  // Accept the incoming connection and start a call
  const call = incomingCall.accept();

  // Reject the incoming call
  incomingCall.reject();
});

client.on('canceled', () => {
  // The incoming call was hung up from the other side.
  // Here, you can update UI to stop showing the incoming call.
})
`,
};

export const CALL_STATUS = {
  node: `
const handleStatusChange = (status) => {
  // Update the UI based on the status.
  setClientStatus(status);

  switch(status) {
    case 'connecting':
    case 'accepted':
    case 'confirmed':
    case 'failed':
    case 'ended':
    case 'ringing':
  }
}
call.on('status', handleStatusChange);

call.removeEventListener('status', handleStatusChange);
`,
};

export const CALL_ERRORS = {
  node: `
const handleCallError = (error) => {
  // Update UI based on the error that occurred within the call
}

call.on('error', handleCallError);

call.removeEventListener('error', handleCallError)`,
};

export const CALL_MUTING = {
  node: `
const handleMutedChange = (isMuted) => {
  // Update UI to show a call was muted or unmuted.
}

call.on('muted', handleMutedChange);
call.removeEventListener('muted', handleMutedChange)

call.mute(true); // Mute the call
call.mute(false); // unmute the call
`,
};

export const CALL_SEND_DIGITS = {
  node: `
  // Press "1" on the keypad.
  call.sendDigits('1');
`,
};

export const DEVICE_ENUMERATION = {
  node: `
import { MediaSources } from '@messagebird/client';

const sources = new MediaSources();
// Enumerates the list of devices
const devices = sources.get()
// Provides the same device list as the above get() call
const theSameDevices = sources.devices
`,
};

export const MEDIA_SOURCES_EVENTS = {
  node: `
import { MediaSources } from '@messagebird/client';

const sources = new MediaSources();
function handleChange(devices) {
    console.log({ devices })
}
sources.on('devicechange', handleChange)
sources.off('devicechange', handleChange)
`,
};

export const MEDIA_SOURCES_INTERFACE = {
  node: `
{
  inputs: {
    audio: MediaDeviceInfo[]
    video: MediaDeviceInfo[]
  };
  outputs: {
    audio: MediaDeviceInfo[]
  };
}
`,
};

export const SELECT_DEVICES = {
  node: `
const sources = new MediaSources();
const [anAudioDevice] = sources.devices.audio;

call.setInputSource({ audio: { deviceId: anAudioDevice.deviceId } })

`,
};

export const SELECT_OUTPUT = {
  node: `
const sources = new MediaSources();
const [anAudioDevice] = sources.devices.audio;

call.setOutputSource(anAudioDevice.deviceId)
`,
};
