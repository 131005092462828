// node
export const INSTALL = {
  json: `npm install @messagebird/agents

# Or if you use yarn

yarn add @messagebird/agents`,
};

export const AUTH = {
  bashs: `curl "https://tasks.messagebird.com/agents/{agentId}/token" \
  -H 'Authorization: AccessKey <MB_access_key>'
`,
};

// node
export const INIT = {
  json: `import { Agent } from '@messagebird/agents';

  const token = 'agent JWT';
  const agent = new Agent(token);

  // Now you can start listening for events.
  agent.on('connected', () => console.log('Agent is connected and ready!'));
`,
};

// node
export const MODIFY_RESERVATION = {
  json: `agent.on('reservation.offered', reservation => {
    // Accept a reservation right after it is offered
    agent.acceptReservation(reservation.id);

    //Or reject a reservation
    agent.rejectReservation(reservation.id);
  });

  // Once the reservation is completed, an agent can complete it
  agent.completeReservation(reservation.id);
  `,
};
