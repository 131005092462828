import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/ApiRef.js";
import CodeSnippet from 'components/CodeSnippet';
import * as CODE from '../../constants/messaging';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`File Storage API`}</h1>
    <p>{`Many message types of multiple platforms require media files stored in a public URL, so they can be used for sending
messages. The File Storage API enables you to safely upload, fetch and delete files to be used when sending messages
through Conversations API. MessageBird platforms identify when a file is stored in the File Storage API, and safely
retrieves them for message processing.`}</p>
    <h2>{`Authorization`}</h2>
    <p>{`Files stored in File Storage API are public by default, however, you can restrict access to them by requesting this
feature to the `}<a parentName="p" {...{
        "href": "https://www.messagebird.com/en/contact"
      }}>{`MessageBird support team`}</a>{`.`}</p>
    <p>{`When authorization is enabled, you need to set an access key to authenticate your HTTP requests. You can create, manage,
and retrieve your access keys using the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`MessageBird Dashboard`}</a>{`.`}</p>
    <p>{`To provide the access key, set the `}<inlineCode parentName="p">{`Authorization`}</inlineCode>{` HTTP header in the form of `}<inlineCode parentName="p">{`AccessKey {accessKey}`}</inlineCode>{`.`}</p>
    <h3>{`Errors`}</h3>
    <p>{`MessageBird uses standard HTTP status codes to indicate success or failure of API requests.`}</p>
    <ul>
      <li parentName="ul">{`HTTP response codes in the `}<inlineCode parentName="li">{`2xx`}</inlineCode>{` range indicate the request was successfully processed`}</li>
      <li parentName="ul">{`HTTP response codes in the `}<inlineCode parentName="li">{`4xx`}</inlineCode>{` range indicate that there was a client error - for example: authentication error,
not enough balance or wrong/missing parameters. Don't worry, the HTTP response body includes a JSON-formatted response
that tells you exactly what went wrong. If you're stuck, feel free to `}<a parentName="li" {...{
          "href": "https://www.messagebird.com/en/contact"
        }}>{`contact support`}</a>{`,
we're happy to help you out`}</li>
      <li parentName="ul">{`HTTP response codes in the `}<inlineCode parentName="li">{`5xx`}</inlineCode>{` range indicate a server error. It's considered safe to retry the same request,
preferably with a delay`}</li>
    </ul>
    <h4>{`Error object`}</h4>
    <CodeSnippet code={CODE.ERROR_OBJECT} mdxType="CodeSnippet" />
    <h4>{`Attributes`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`errors[].code`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An integer that represents the error type.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`errors[].description`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A human-readable description of the error. You can use this to let the user know what they can do about the error.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`errors[].parameter`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The parameter in your request related to the error if the error is parameter specific.`}</td>
        </tr>
      </tbody>
    </table>
    <hr></hr>
    <h2>{`Upload file`}</h2>
    <p>{`Upload a file of any type/format with a maximum size of 100 MB.`}</p>
    <CodeSnippet code={CODE.UPLOAD_FILE_ENDPOINT} mdxType="CodeSnippet" />
    <h3>{`Request`}</h3>
    <p>{`The following HTTP headers are required/supported along with the `}<inlineCode parentName="p">{`Authorization`}</inlineCode>{` header.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Header`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Content-Type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Indicates the `}<a parentName="td" {...{
              "href": "https://developer.mozilla.org/en-US/docs/Glossary/MIME_type"
            }}>{`media type`}</a>{` of the resource`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Content-Disposition`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Indicates how the resource should be displayed by a browser (`}<inlineCode parentName="td">{`inline`}</inlineCode>{` or `}<inlineCode parentName="td">{`attachment`}</inlineCode>{`) and also the filename.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
      </tbody>
    </table>
    <CodeSnippet code={CODE.UPLOAD_FILE_REQUEST_EXAMPLE} mdxType="CodeSnippet" />
    <h3>{`Response`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique ID generated by MessageBird that identifies the file.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`name`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`File name (if provided).`}</td>
        </tr>
      </tbody>
    </table>
    <CodeSnippet code={CODE.UPLOAD_FILE_RESPONSE_EXAMPLE} mdxType="CodeSnippet" />
    <h4>{`Response codes`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`200`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`File successfully uploaded.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`400`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Invalid HTTP request. The HTTP response should include details about the error.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`401`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unauthorized.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`5xx`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unexpected internal server error. Please retry the request with standard exponential backoff.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Fetch file`}</h2>
    <p>{`Fetch a file previously uploaded based on its ID.`}</p>
    <CodeSnippet code={CODE.FETCH_FILE_ENDPOINT} mdxType="CodeSnippet" />
    <h3>{`Request`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique ID generated by MessageBird that identifies the file.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
      </tbody>
    </table>
    <CodeSnippet code={CODE.FETCH_FILE_REQUEST_EXAMPLE} mdxType="CodeSnippet" />
    <h3>{`Response`}</h3>
    <p>{`The HTTP response includes the file binary along with the following HTTP headers.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Header`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Content-Type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Indicates the `}<a parentName="td" {...{
              "href": "https://developer.mozilla.org/en-US/docs/Glossary/MIME_type"
            }}>{`media type`}</a>{` of the resource`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Content-Disposition`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Indicates how the resource should be displayed by a browser (`}<inlineCode parentName="td">{`inline`}</inlineCode>{` or `}<inlineCode parentName="td">{`attachment`}</inlineCode>{`) and also the filename.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Content-Length`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Indicates the file size in bytes`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Response codes`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`200`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`File successfully fetched.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`400`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Invalid HTTP request. The HTTP response should include details about the error.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`401`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unauthorized.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`403`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Access forbidden.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`404`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`File not found.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`5xx`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unexpected internal server error. Please retry the request with standard exponential backoff.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Delete file`}</h2>
    <p>{`Delete a file previously uploaded based on its ID.`}</p>
    <CodeSnippet code={CODE.DELETE_FILE_ENDPOINT} mdxType="CodeSnippet" />
    <h3>{`Request`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The unique ID generated by MessageBird that identifies the file.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
      </tbody>
    </table>
    <CodeSnippet code={CODE.DELETE_FILE_REQUEST_EXAMPLE} mdxType="CodeSnippet" />
    <h3>{`Response`}</h3>
    <p>{`A successful HTTP response (`}<inlineCode parentName="p">{`204 No Content`}</inlineCode>{`) doesn't have response body.`}</p>
    <h4>{`Response codes`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`204`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`File successfully deleted.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`400`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Invalid HTTP request. The HTTP response should include details about the error.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`401`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unauthorized.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`403`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Access forbidden.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`404`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`File not found.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`5xx`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unexpected internal server error. Please retry the request with standard exponential backoff.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      