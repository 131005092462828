import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import Helmet from 'react-helmet';
import Notification from 'components/Notification';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Helmet mdxType="Helmet">
    <meta name="robots" content="noindex" />
    </Helmet>
    <h1>{`Pricing API`}</h1>
    <p>{`In this quickstart, you'll learn how to list the Prices for Outbound SMS, MMS or Voice messages from your web
application using the Pricing API.`}</p>
    <h2>{`Step 1: Fetch your credentials`}</h2>
    <p>{`Before we get started, collect your live API key from the
`}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`Developer Dashboard`}</a>{`.`}</p>
    <h2>{`Step 2: List pricing information for outbound SMS messages`}</h2>
    <p>{`With `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`your credentials`}</a>{` at hand, you can now list the
pricing information for outbound SMS messages.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-curl"
      }}>{`curl -X GET https://rest.messagebird.com/pricing/sms/outbound \\
     -H 'Authorization: AccessKey YOUR-API-KEY'
`}</code></pre>
    <p>{`This will return the pricing information for your default SMS configuration in MessageBird.`}</p>
    <p>{`To list the pricing information for a specific SMPP user name, simply use the below URL and specify the `}<inlineCode parentName="p">{`smppUser`}</inlineCode>{` with
the SMPP SystemID provided by MessageBird.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-curl"
      }}>{`curl -X GET https://rest.messagebird.com/pricing/sms/outbound/smpp/{smppUser} \\
     -H 'Authorization: AccessKey YOUR-API-KEY'
`}</code></pre>
    <p>{`Pricing API endpoints for related products are:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Voice Messages (Text to Speech)`}</strong>{`: `}<inlineCode parentName="li">{`https://rest.messagebird.com/pricing/tts/outbound`}</inlineCode></li>
      <li parentName="ul"><strong parentName="li">{`MMS`}</strong>{`: `}<inlineCode parentName="li">{`https://rest.messagebird.com/pricing/mms/outbound`}</inlineCode></li>
    </ul>
    <p>{`Responses are the same for all endpoints.`}</p>
    <h2>{`Step 3: View the response`}</h2>
    <p>{`Now, let’s view Pricing API’s HTTP response to your request.
If the set up was correct, you'll receive a response similar to this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "gateway": 10,
    "currencyCode": "EUR",
    "totalCount": 3,
    "prices": [
        {
            "price": "0.060000",
            "currencyCode": "EUR",
            "mccmnc": "0",
            "mcc": "0",
            "mnc": null,
            "countryName": "Default Rate",
            "countryIsoCode": "XX",
            "operatorName": "Default Rate"
        },
        {
            "price": "0.047000",
            "currencyCode": "EUR",
            "mccmnc": "202",
            "mcc": "202",
            "mnc": null,
            "countryName": "Greece",
            "countryIsoCode": "GR",
            "operatorName": null
        },
        {
            "price": "0.045000",
            "currencyCode": "EUR",
            "mccmnc": "20205",
            "mcc": "202",
            "mnc": "05",
            "countryName": "Greece",
            "countryIsoCode": "GR",
            "operatorName": "Vodafone"
        }
    ]
}
`}</code></pre>
    <p>{`If something went wrong, you’ll receive an error response from the Pricing API.
Don't worry if this happens, just head over to the `}<a parentName="p" {...{
        "href": "/docs/sms-messaging/troubleshooting"
      }}>{`SMS API Troubleshooting`}</a>{`
to understand the cause of the error.`}</p>
    <h3>{`Step 4: Checking the pricing information`}</h3>
    <p>{`The response includes pricing information for destinations, country default rates, and the default rate
for all other destinations.`}</p>
    <p>{`The pricing is applied in order from most specific to least specific rate:`}</p>
    <ul>
      <li parentName="ul">{`if the destination is listed, then the destination specific rate will be applied.`}</li>
      <li parentName="ul">{`if the destination is not listed but the country default rate is specified, then the country default rate will be applied.`}</li>
      <li parentName="ul">{`if neither the destination nor the country default rate is listed, then the default rate will be applied.`}</li>
    </ul>
    <h3>{`Examples`}</h3>
    <p>{`The rate for an outbound SMS message sent to a Vodafone Greece recipient can be found under the entry with the
mobile country code (`}<inlineCode parentName="p">{`mcc`}</inlineCode>{`) for Greece and the mobile network code (`}<inlineCode parentName="p">{`mnc`}</inlineCode>{`) for Vodafone.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "mccmnc": "20205",
    "mcc": "202",
    "mnc": "05",
    "countryName": "Greece",
    "operatorName": "Vodafone"
}
`}</code></pre>
    <p>{`The rate for an outbound SMS message sent to a Greek mobile network operator that is not listed can be found
under the default country rate for Greece.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "price": "0.047000",
    "mccmnc": "202",
    "mcc": "202",
    "mnc": null,
    "countryName": "Greece",
    "operatorName": null
}
`}</code></pre>
    <p>{`The rate for an outbound SMS message sent to any destination that is not listed can be found under the
default rate for all other destinations.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "price": "0.060000",
    "mccmnc": "0",
    "mcc": "0",
    "mnc": null,
    "countryName": "Default Rate",
    "operatorName": "Default Rate"
}
`}</code></pre>
    <p>{`Congratulations! You can now programmatically list your outbound SMS messages rates with
the MessageBird Pricing API using cURL.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      