import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import AnchorSvg from '../../../static/img/icons/icn_anchor.svg';

const BlockWrap = styled.div`
  max-width: 1008px;
  margin-bottom: 30px;
  @media (max-width: 767px) {
    padding: 0 15px;
  }
`;

const BlockTitle = styled.h2`
  margin: 0 0 24px 0;
  font-size: 24px;
  font-weight: 600;
  line-height: 38px;
  a > img {
    opacity: 0;
    transition: opacity 0.2s ease;
  }
  a:hover {
    > img {
      opacity: 1;
    }
  }
`;

const ContentBlock = ({ children, title, className }) => {
  const id =
    typeof title === 'string' ? title.split(' ').join('-').toLowerCase() : null;

  return (
    <BlockWrap id={id} className={className}>
      {title && (
        <BlockTitle>
          <a href={`#${id}`}>
            {title}
            <img alt="" css="margin-left:8px" src={AnchorSvg} />
          </a>
        </BlockTitle>
      )}
      {children}
    </BlockWrap>
  );
};

ContentBlock.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  title: PropTypes.string,
};

ContentBlock.defaultProps = {
  children: null,
  className: '',
  title: undefined,
};

export default ContentBlock;
