import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import Notification from 'components/Notification';
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Send a Text To Speech Message`}</h1>
    <p>{`In this Voice Messaging API Quickstart, you'll learn how to programmatically send your first outbound text to speech message via the API.`}</p>
    <h2>{`Step 1: Set up your test environment`}</h2>
    <p>{`To start testing the Voice Messaging API using Java, you need to first set up your test environment.`}</p>
    <p>{`First, let's create a `}<inlineCode parentName="p">{`VoiceQuickstart.java`}</inlineCode>{` file in the directory of your `}<inlineCode parentName="p">{`src/main/java`}</inlineCode>{` folder.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`git clone https://github.com/messagebird/java-rest-api
cd java-rest-api/api
mvn install
`}</code></pre>
    <Notification type="INFO" mdxType="Notification">
Want to learn how to set up your Java development environment? Check out the <a href="tutorials/setup-local-dev-environment-java">Java tutorial</a>
    </Notification>
    <h2>{`Step 2: Send your first text to speech message`}</h2>
    <p>{`Now, enter `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`your credentials`}</a>{` in the code snippet below, including `}<inlineCode parentName="p">{`YOUR-API-KEY`}</inlineCode>{` with your API key, and enter your mobile number as the `}<inlineCode parentName="p">{`recipient`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.messagebird.MessageBirdClient;
import com.messagebird.MessageBirdService;
import com.messagebird.MessageBirdServiceImpl;
import com.messagebird.exceptions.GeneralException;
import com.messagebird.exceptions.UnauthorizedException;
import com.messagebird.objects.IfMachineType;
import com.messagebird.objects.VoiceType;
import com.messagebird.objects.VoiceMessage;
import com.messagebird.objects.VoiceMessageResponse;

import java.math.BigInteger;
import java.util.ArrayList;
import java.util.List;

public class ExampleSendVoiceMessage {
    public static void main(String[] args) {
        if (args.length < 3) {
            System.out.println("Please specify your access key, one ore more phone numbers and a message body example : java -jar <this jar file≶ test_accesskey 31612345678,3161112233 \\"Hi! This is your first text to speech message!\\"");
            return;
        }

        // First create your service object
        final MessageBirdService wsr = new MessageBirdServiceImpl(args[0]);

        // Add the service to the client
        final MessageBirdClient messageBirdClient = new MessageBirdClient(wsr);

        try {
            // Get Hlr using msgId and msisdn
            System.out.println("Sending message:");
            final List<BigInteger≶ phones = new ArrayList<BigInteger≶();
            for (final String phoneNumber : args[1].split(",")) {
                phones.add(new BigInteger(phoneNumber));
            }

            // Send a voice message using the VoiceMessage object
            final VoiceMessage vm = new VoiceMessage(args[2], phones);
            vm.setIfMachine(IfMachineType.hangup);
            vm.setVoice(VoiceType.male);
            final VoiceMessageResponse response = messageBirdClient.sendVoiceMessage(vm);
            System.out.println(response.toString());

        } catch (UnauthorizedException unauthorized) {
            if (unauthorized.getErrors() != null) {
                System.out.println(unauthorized.getErrors().toString());
            }
            unauthorized.printStackTrace();
        } catch (GeneralException generalException) {
            if (generalException.getErrors() != null) {
                System.out.println(generalException.getErrors().toString());
            }
            generalException.printStackTrace();
        }
    }
}
`}</code></pre>
    <Notification type="INFO" mdxType="Notification">
You can access and manage your credentials in the <a href="https://dashboard.messagebird.com/en/developers/access">MessageBird Developer Dashboard</a>.
    </Notification>
    <p>{`Then, run the application in your IDE.`}</p>
    <p>{`Good job! You've just sent your first outbound text to speech message using Java with the Voice Messaging API!`}</p>
    <h2>{`Step 3: View the response`}</h2>
    <p>{`Now, let’s view MessageBird’s HTTP response to your API call. If the set up was correct, you'll receive a response similar to this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "id":"e8077d803532c0b5937c639b60216938",
  "href":"https://rest.messagebird.com/voicemessages/e8077d803532c0b5937c639b60216938",
  "originator":null,
  "body":"Hi! This is your first text to speech message!",
  "reference":"the-client-reference",
  "language":"en-gb",
  "voice":"female",
  "repeat":1,
  "ifMachine":"continue",
  "machineTimeout":7000,
  "scheduledDatetime":null,
  "createdDatetime":"2016-05-03T14:26:57+00:00",
  "recipients":{
    "totalCount":1,
    "totalSentCount":1,
    "totalDeliveredCount":0,
    "totalDeliveryFailedCount":0,
    "items":[
      {
        "recipient":31612345678,
        "status":"calling",
        "statusDatetime":"2016-05-03T14:26:57+00:00"
      }
    ]
  }
}
`}</code></pre>
    <p>{`If something went wrong, you’ll receive an error response from the MessageBird API. Don't worry, simply head over to `}<a parentName="p" {...{
        "href": "/docs/voice-messaging/troubleshooting"
      }}>{`Voice Messaging API Troubleshooting`}</a>{` to understand the error.`}</p>
    <p>{`Congrats! You can now programmatically send outbound text to speech messages with the Voice Messaging API using Java.`}</p>
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'Voice Messaging API Reference',
      to: '/api/voice-messaging'
    }, {
      name: 'Official MessageBird SDKs',
      to: '/libraries'
    }, {
      name: 'Voice Messaging API troubleshooting',
      to: '/quickstarts/voice-messaging/troubleshooting'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      