import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Sending outbound SMS messages with MessageBird`}</h1>
    <h3>{`⏱ 5 min build time  || `}<a parentName="h3" {...{
        "href": "https://youtu.be/k4N0steJJKc"
      }}>{`Watch the Video Tutorial`}</a></h3>
    <p>{`In this MessageBird Developer Tutorial you’ll learn how to send your first SMS using the `}<a parentName="p" {...{
        "href": "/api/sms-messaging"
      }}>{`MessageBird SMS Messaging API`}</a>{`.`}</p>
    <p>{`Before we get started, have you set up your PHP development environment and
project directory with the MessageBird SDK?`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`No!`}</strong>{` Make sure you to set this up before continuing; you can read this `}<a parentName="li" {...{
          "href": "/tutorials/setup-local-dev-environment-php"
        }}>{`MessageBird Developer Tutorial`}</a>{` to learn how to do so.`}</li>
      <li parentName="ul"><strong parentName="li">{`Yes!`}</strong>{` Great! Now you can make your first API request and send an SMS message with MessageBird using PHP.`}</li>
    </ul>
    <h2>{`Getting started`}</h2>
    <p>{`First, let's create a new file in the directory of your `}<inlineCode parentName="p">{`composer.json`}</inlineCode>{` file and call it `}<inlineCode parentName="p">{`send_sms.php`}</inlineCode>{`.`}</p>
    <p>{`In this file, first include the Composer autoloader so you can access the SDK (and other packages):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`<?php
require_once __DIR__.'/vendor/autoload.php';
`}</code></pre>
    <p>{`Then, initialize the SDK by creating a new instance of the `}<inlineCode parentName="p">{`MessageBird\\Client`}</inlineCode>{` class. The constructor takes a single argument, your `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`API key`}</a>{`. Next, to start testing the SDK, you can simply replace the string `}<em parentName="p">{`YOUR-API-KEY`}</em>{` in the following PHP code and thus hardcode your API key.`}</p>
    <p><strong parentName="p">{`Pro-tip:`}</strong>{` For production applications, we recommended storing the key in a configuration file or environment variable instead and passing this variable with the key to the constructor.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`$messagebird = new MessageBird\\Client('YOUR-API-KEY');
`}</code></pre>
    <p>{`The SDK defines a PHP class for SMS messages. Create an instance of this class and then assign values for the required attributes `}<inlineCode parentName="p">{`originator`}</inlineCode>{`, `}<inlineCode parentName="p">{`recipients`}</inlineCode>{` and `}<inlineCode parentName="p">{`body`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`$message = new MessageBird\\Objects\\Message;
$message->originator = '31970XXXXXXX';
$message->recipients = [ '31970YYYYYYY' ];
$message->body = 'Hello World, I am a text message and I was hatched by PHP code!';
`}</code></pre>
    <p>{`But wait, what do these attributes mean? 🤔`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The `}<inlineCode parentName="p">{`originator`}</inlineCode>{` is the sender for the message; it can be a telephone number including country code or an alphanumeric string (with a maximum length of 11 characters). You can use the number you bought as part of our `}<a parentName="p" {...{
            "href": "/tutorials/getting-started-101"
          }}>{`Getting Started tutorial`}</a>{` as originator. Keep in mind that alphanumeric senders are not supported in every country including the United States, so it’s important to check the `}<a parentName="p" {...{
            "href": "https://support.messagebird.com/hc/en-us/sections/360000108538-Country-info-Restrictions"
          }}>{`country restrictions`}</a>{`. If you can't use alphanumeric IDs, use a real phone number instead. You can check our `}<a parentName="p" {...{
            "href": "https://support.messagebird.com/hc/en-us/articles/115002628665-What-is-the-originator-"
          }}>{`originator article`}</a>{` in Help Center to learn more about this topic.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The `}<inlineCode parentName="p">{`recipients`}</inlineCode>{` are the phone numbers that will receive the message. All numbers should be in the `}<a parentName="p" {...{
            "href": "https://support.messagebird.com/hc/en-us/articles/115003950149-Numbers-format-"
          }}>{`international format with country code`}</a>{`. You must specify this attribute as an array even if you have just a single recipient. You can send a message to up to 50 numbers at a time. For testing, add your own mobile telephone number here so you can receive the test message on your phone.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The `}<inlineCode parentName="p">{`body`}</inlineCode>{` is the content of the message. Keep in mind that SMS messages are limited to 160 characters, and if you enter a longer value for the body it will be split into multiple parts which are each sent and billed separately.`}</p>
      </li>
    </ul>
    <p>{`Make sure to replace the values in the sample code with adequate data for testing. There are additional optional attributes as well; you can `}<a parentName="p" {...{
        "href": "/api/sms-messaging#send-a-message"
      }}>{`find them in our SMS Messaging reference documentation`}</a>{`.`}</p>
    <p>{`Once the message object is ready, you can send it through the API. Call `}<inlineCode parentName="p">{`messages->create()`}</inlineCode>{` on the SDK instance object and pass your previously created message object as the first argument:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`$response = $messagebird->messages->create($message);
var_dump($response);
`}</code></pre>
    <p>{`Your first PHP script is ready. Let’s go ahead and save it!`}</p>
    <p>{`Keep in mind that the MessageBird SDK throws an exception when something goes wrong with your API request. It's okay for now, but in production applications, you should place any API call between `}<inlineCode parentName="p">{`try`}</inlineCode>{` and `}<inlineCode parentName="p">{`catch`}</inlineCode>{` and provide user-friendly error handling inside the catch block.`}</p>
    <p>{`Enough said, let's try running it from the command line:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`php send_sms.php
`}</code></pre>
    <p>{`If everything works fine, you should see the API response as output from the script. If you used a live API key and verified your number or added `}<a parentName="p" {...{
        "href": ""
      }}>{`test credits to your account`}</a>{` the message will arrive on your phone in seconds.`}</p>
    <p><strong parentName="p">{`Nice work!`}</strong>{` 🎉`}</p>
    <p>{`You've just sent your first SMS message with `}<a parentName="p" {...{
        "href": "/api/sms-messaging"
      }}>{`MessageBird's SMS Messaging API`}</a>{` using PHP!`}</p>
    <h2>{`Start building!`}</h2>
    <p>{`Head over to the next MessageBird Developer Tutorial and `}<a parentName="p" {...{
        "href": "/tutorials/handle-incoming-calls-and-sms"
      }}>{`learn how to set up inbound messaging functionality`}</a>{`.`}</p>
    <p>{`Want to start building your solution but not quite sure how to get started? Feel free to let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we'd love to help!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      