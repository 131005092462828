import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Implementing voice two-factor authentication (2FA) with MessageBird`}</h1>
    <h3>{`⏱ 15 min build time      ||      `}<a parentName="h3" {...{
        "href": "https://github.com/messagebirdguides/verify-voice-guide-java"
      }}>{`Download the Code`}</a></h3>
    <h2>{`Why build voice-based account security?`}</h2>
    <p>{`In this MessageBird Developer Tutorial you’ll learn how to improve your security by building a Voice-based two-factor authentication solution with the `}<a parentName="p" {...{
        "href": "https://www.messagebird.com/en/verify"
      }}>{`MessageBird Verify API`}</a>{`.`}</p>
    <p>{`Websites where users can sign up for an account typically use the email address as a unique identifier and a password as a security credential for users to sign in. At the same time, most websites ask users to add a verified phone number to their profile. Phone numbers are, in general, a better way to identify an account holder as a real person; they can also be used as a second authentication factor (2FA) or to restore access to a locked account.`}</p>
    <p>{`Verification of a phone number is straightforward:`}</p>
    <ol>
      <li parentName="ol">{`Ask your user to enter their number.`}</li>
      <li parentName="ol">{`Call the number programmatically and use a text-to-speech system to say a security code that acts as a one-time-password (OTP).`}</li>
      <li parentName="ol">{`Let the user enter this code on the website or inside an application as proof that they received the call.`}</li>
    </ol>
    <p>{`The MessageBird Verify API assists developers in implementing this workflow into their apps. Imagine you're running a social network and want to verify the profiles of your users. This MessageBird Developer Tutorial shows you an example application using Java with integrated account security following the steps outlined above.`}</p>
    <p>{`By the way, it is also possible to replace the second step with an SMS message, as we explain in our `}<a parentName="p" {...{
        "href": "/tutorials/verify-node"
      }}>{`two factor authentication tutorial`}</a>{`. However, using voice for verification has the advantage that it works with every phone number, not just mobile phones, so it can be used to verify, for example, the landline of a business.`}</p>
    <p>{`The `}<a parentName="p" {...{
        "href": "/api/verify"
      }}>{`MessageBird Verify API`}</a>{` supports both options; voice and SMS!`}</p>
    <h2>{`Getting started`}</h2>
    <p>{`Our sample application is built in Java using the `}<a parentName="p" {...{
        "href": "http://sparkjava.com/"
      }}>{`Spark framework`}</a>{`, so you need to install `}<a parentName="p" {...{
        "href": "https://www.oracle.com/technetwork/java/javase/downloads/jdk8-downloads-2133151.html"
      }}>{`Java 1.8`}</a>{` and `}<a parentName="p" {...{
        "href": "https://maven.apache.org/"
      }}>{`Maven`}</a>{`.`}</p>
    <p>{`The source code is available in the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/verify-voice-guide-java"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`, which you can either clone with git or from where you can download a ZIP file with the source code to your computer.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`pom.xml`}</inlineCode>{` file has all the dependencies the project needs. Your IDE should be configured to automatically install them.`}</p>
    <h2>{`Configuring the MessageBird SDK`}</h2>
    <p>{`The MessageBird SDK is defined in the `}<inlineCode parentName="p">{`pom.xml`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<dependencies>
   <dependency>
       <groupId>com.messagebird</groupId>
       <artifactId>messagebird-api</artifactId>
       <version>2.0.0</version>
   </dependency>
</dependencies>
`}</code></pre>
    <p>{`It's loaded with a statement at the top of `}<inlineCode parentName="p">{`AccountSecurityVoice.java`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`// Create a MessageBirdService
final MessageBirdService messageBirdService = new MessageBirdServiceImpl(dotenv.get("MESSAGEBIRD_API_KEY"));
// Add the service to the client
final MessageBirdClient messageBirdClient = new MessageBirdClient(messageBirdService);
`}</code></pre>
    <p>{`You need to provide a MessageBird API key via an environment variable loaded with `}<a parentName="p" {...{
        "href": "https://mvnrepository.com/artifact/io.github.cdimascio/java-dotenv"
      }}>{`dotenv`}</a>{`. We've prepared an `}<inlineCode parentName="p">{`env.example`}</inlineCode>{` file in the repository, which you should rename to `}<inlineCode parentName="p">{`.env`}</inlineCode>{` and add the required information. Here's an example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`MESSAGEBIRD_API_KEY=YOUR-API-KEY
`}</code></pre>
    <p>{`Go to the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/user/index"
      }}>{`MessageBird Dashboard`}</a>{`; if you have already created an API key it will be shown right there. If you don’t see any key on the Dashboard or if you're unsure whether this key is in `}<em parentName="p">{`live`}</em>{` mode, go to the `}<em parentName="p">{`Developers`}</em>{` section in the MessageBird Dashboard and open the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`API access (REST) tab`}</a>{`. There you can create new API keys and manage your existing ones.`}</p>
    <h2>{`Asking for the phone number`}</h2>
    <p>{`The sample application contains a form to collect the user's phone number. You can see the HTML as a Mustache template in the file `}<inlineCode parentName="p">{`resources/templates/start.erb`}</inlineCode>{` and the route that renders it is `}<inlineCode parentName="p">{`get("/")`}</inlineCode>{`. All Mustache files use the layouts stored in `}<inlineCode parentName="p">{`resources/templates/header.mustache`}</inlineCode>{` and `}<inlineCode parentName="p">{`resources/templates/footer.mustache`}</inlineCode>{` to follow a common structure.`}</p>
    <p>{`The HTML form includes a `}<inlineCode parentName="p">{`<select>`}</inlineCode>{` element as a drop-down to choose the country. That allows users to enter their phone number without the country code. In production applications, you could use this to limit access on a country level and preselect the user's current country by IP address. The form field for the number is a simple `}<inlineCode parentName="p">{`<input>`}</inlineCode>{` with the `}<inlineCode parentName="p">{`type`}</inlineCode>{` set to `}<inlineCode parentName="p">{`tel`}</inlineCode>{` to inform compatible browsers that this is an input field for telephone numbers. Finally, there's a submit button. Once the user clicks on that button, the input is sent to the `}<inlineCode parentName="p">{`post("/verify")`}</inlineCode>{` route.`}</p>
    <h2>{`Initiating the verification call`}</h2>
    <p>{`When the user submits their submit, the `}<inlineCode parentName="p">{`post("/verify")`}</inlineCode>{` routes takes over. The Verify API expects the user's telephone number to be in international format, so the first step is reading the input and concatenating the country code and the number. If the user enters their local number with a leading zero, we remove this digit.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`// Compose number from country code and number
String countryCode = req.queryParams("country_code");
String number = req.queryParams("phone_number");
number = number.substring(0, 1) == "0" ? number.substring(1, -1) : number;
String phoneNumber = String.format("%s%s", countryCode, number);
`}</code></pre>
    <p>{`Next, we can call `}<inlineCode parentName="p">{`sendVerifyToken`}</inlineCode>{` on the MessageBird SDK. That launches the API request that initiates the verification call:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`// Create verification request with MessageBird Verify API
VerifyRequest verifyRequest = new VerifyRequest(phoneNumber);
verifyRequest.setType(VerifyType.TTS); // TTS = text-to-speech, otherwise API defaults to SMS
verifyRequest.setTemplate("Your account security code is %token.");

Verify verify = messageBirdClient.sendVerifyToken(verifyRequest);
`}</code></pre>
    <p>{`The method takes a single parameter, which is an object of type `}<inlineCode parentName="p">{`VerifyRequest`}</inlineCode>{`. `}<inlineCode parentName="p">{`new VerifyRequest`}</inlineCode>{` is instantiated with the phone number to verify. There are additional configuration options you may set. Our sample application sets two options:`}</p>
    <ul>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`type`}</inlineCode>{` is set to `}<inlineCode parentName="li">{`tts`}</inlineCode>{` to inform the API that we want to use a voice call for verification.`}</li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`template`}</inlineCode>{` parameter contains the words to speak. It must contain the placeholder `}<inlineCode parentName="li">{`%token`}</inlineCode>{` so that MessageBird knows where the code goes (we use the words token and code interchangeably; they mean the same thing). We don't have to generate this numeric code ourselves; the API takes care of it.`}</li>
    </ul>
    <p>{`There are a few other available options. For example, you can change the length of the code (it defaults to 6) with `}<inlineCode parentName="p">{`tokenLength`}</inlineCode>{`. You can also specify `}<inlineCode parentName="p">{`voice`}</inlineCode>{` as `}<inlineCode parentName="p">{`male`}</inlineCode>{` or `}<inlineCode parentName="p">{`female`}</inlineCode>{` and set the `}<inlineCode parentName="p">{`language`}</inlineCode>{` to an ISO language code if you want the synthesized voice to be in a non-English language. You can find more details about these and other options in our `}<a parentName="p" {...{
        "href": "/api/verify#request-a-verify"
      }}>{`Verify API reference documentation`}</a>{`.`}</p>
    <p>{`If there was an error, we show the same page to the user as before but add an error parameter which the template displays as a message above the form to notify the user. In the success case, we render a new template. We add the `}<inlineCode parentName="p">{`id`}</inlineCode>{` attribute of the API response to this template because we need the identification of our verification request in the next step to confirm the code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`try {
   Verify verify = messageBirdClient.sendVerifyToken(verifyRequest);
   String id = verify.getId();

   model.put("id", id);

   return new ModelAndView(model, "verify.mustache");
} catch (UnauthorizedException | GeneralException ex) {
   model.put("errors", ex.toString());
   return new ModelAndView(model, "start.mustache");
}
`}</code></pre>
    <h2>{`Verifying the code`}</h2>
    <p>{`The template stored in `}<inlineCode parentName="p">{`resources/templates/verify.mustache`}</inlineCode>{`, which we render in the success case, contains an HTML form with a hidden input field to pass forward the `}<inlineCode parentName="p">{`id`}</inlineCode>{` from the verification request. It also contains a regular `}<inlineCode parentName="p">{`<input>`}</inlineCode>{` with `}<inlineCode parentName="p">{`type`}</inlineCode>{` set to `}<inlineCode parentName="p">{`text`}</inlineCode>{` so that the user can enter the code that they've heard on the phone. When the user submits this form, it sends this token to the `}<inlineCode parentName="p">{`/confirm`}</inlineCode>{` route.`}</p>
    <p>{`Inside this route, we call another method on the MessageBird SDK, `}<inlineCode parentName="p">{`verifyToken`}</inlineCode>{`, and provide the ID and token as two parameters. Just as before, you'll need to handle any potential error states by `}<inlineCode parentName="p">{`catch`}</inlineCode>{`ing them. We inform the user about the status of the verification by showing either a new success response (which is stored in `}<inlineCode parentName="p">{`resources/templates/confirm.mustache`}</inlineCode>{`), or showing the first page again with an error message:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`post("/confirm",
       (req, res) ->
       {
           String id = req.queryParams("id");
           String token = req.queryParams("token");

           Map<String, Object> model = new HashMap<>();

           try {
               final Verify verify = messageBirdClient.verifyToken(id, token);

               return new ModelAndView(model, "confirm.mustache");
           } catch (UnauthorizedException | GeneralException ex) {
               model.put("errors", ex.toString());
               return new ModelAndView(model, "start.mustache");
           }
       },
       new MustacheTemplateEngine()
);
`}</code></pre>
    <p>{`In production applications, you would use the success case to update your user's phone number as verified in your database.`}</p>
    <h2>{`Testing`}</h2>
    <p>{`You’re done! Let's go ahead and test your application. To do so, simply build and run the application through your IDE.`}</p>
    <p>{`Now open your browser to http://localhost:4567/ and walk through the process yourself!`}</p>
    <p>{`You can now use the flow, code snippets, and UI examples from this tutorial to build your own voice-based account security system. Don't forget to download the code from the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/verify-voice-guide-java"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`.`}</p>
    <p><strong parentName="p">{`Nice work!`}</strong>{` 🎉`}</p>
    <p>{`You now have a running integration of MessageBird's Verify API using Java!`}</p>
    <h2>{`Start building!`}</h2>
    <p>{`Want to build something similar but not quite sure how to get started? Please feel free to let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`;  we'd love to help!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      