import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
import Notification from 'components/Notification';
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
import { Img } from 'components/shared';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`WhatsApp Account Masking`}</h1>
    <h3>{`⏱ 15 min read`}</h3>
    <h2>{`Why use WAAM?`}</h2>
    <p>{`WhatsApp Account Masking allows 2 end-users to communicate with each other without revealing their personal WhatsApp details.`}</p>
    <p>{`For retailers, last-mile logistics companies, and on-demand service providers, WAAM enables a better customer experience and allows conversations between the delivery driver/service provider and customers while maintaining the privacy of both parties. This ensures these interactions are only used for business purposes. `}</p>
    <h2>{`WAAM in action`}</h2>
    <p>{`Here's a demo of this solution in action: `}</p>
    <ul>
      <li parentName="ul">
        <ol parentName="li">
          <li parentName="ol">{`Have yourself and another person scan the QR code below or `}<a parentName="li" {...{
              "href": "https://api.whatsapp.com/send?phone=447418314219&text=%23waam"
            }}>{`navigate here`}</a>{`.`}
            <Img src="/img/screenshots/quickstarts/convo/qr-code1.png" alt="QR code demo" width="350px" align="middle" mdxType="Img" />
          </li>
        </ol>
      </li>
      <li parentName="ul">
        <ol parentName="li" {...{
          "start": 2
        }}>
          <li parentName="ol">{`Decide respective roles as the `}<strong parentName="li">{`Organizer`}</strong>{` (company worker) and the `}<strong parentName="li">{`Participant`}</strong>{` (customer).  Follow the prompts and have a conversation without seeing each other’s actual numbers. `}</li>
        </ol>
      </li>
    </ul>
    <h2>{`Setting up WAAM for your business`}</h2>
    <p>{`What you'll need:`}</p>
    <ul>
      <li parentName="ul">{`A MessageBird Connectivity Platform account (signing up not possible anymore)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.messagebird.com/whatsapp/"
        }}>{`A WhatsApp for Business account (WABA)`}</a></li>
    </ul>
    <h3>{`How it works`}</h3>
    <p>{`Our WAAM solution is built on `}<a parentName="p" {...{
        "href": "https://www.messagebird.com/flow-builder/"
      }}>{`Flow Builder`}</a>{` — our drag-and-drop automation tool. Don’t worry, you don't need to be an expert in Flow Builder, we'll provide downloadable flows that can be used and tailored to your needs.
There are 3 flows that comprise this solution:`}</p>
    <ul>
      <li parentName="ul">{`The first is for the customer talking to the business`}</li>
      <li parentName="ul">{`The second is for the business talking to the customer.`}</li>
      <li parentName="ul">{`The third engages the common session connecting the two previous flows.`}</li>
    </ul>
    <h3>{`Flow 1: "WAAM C2B" How the customer talks to the business`}</h3>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Sign in to your MessageBird account and navigate to `}<a parentName="p" {...{
            "href": "https://www.messagebird.com/flow-builder/"
          }}>{`Flow Builder`}</a>{` in your `}<a parentName="p" {...{
            "href": "https://dashboard.messagebird.com/"
          }}>{`MessageBird Dashboard`}</a>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://drive.google.com/file/d/1Vu2dJffWIa28A61r8KTWS5XaqLmFiFXj/view?usp=sharing"
          }}>{`Download the first flow`}</a>{` and import it in Flow Builder. `}</p>
        <Img src="/img/screenshots/quickstarts/convo/wa-masking1&4&6.png" alt="WA-Masking1" width="450px" align="middle" mdxType="Img" />
      </li>
      <li parentName="ol">
        <p parentName="li">{`Record the Webhook URL for later use.`}</p>
        <Img src="/img/screenshots/quickstarts/convo/wa-masking2&5.png" alt="WA-Masking2" width="450px" align="middle" mdxType="Img" />
      </li>
      <li parentName="ol">
        <p parentName="li">{`You should see 3 `}<strong parentName="p">{`Your WABA 1 of x`}</strong>{` steps in your imported flow with the Attention Needed sticker. Click these steps and select `}<strong parentName="p">{`your WhatsApp`}</strong>{` from the channel menu for each one. `}</p>
        <Img src="/img/screenshots/quickstarts/convo/wa-masking3.png" alt="WA-Masking3" width="450px" align="middle" mdxType="Img" />
      </li>
      <li parentName="ol">
        <p parentName="li">{`Publish your changes.`}</p>
      </li>
    </ol>
    <h3>{`Flow 2: "WAAM B2C" How the Business talks to the Customer`}</h3>
    <ol>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://drive.google.com/file/d/1dLCwifhd559t9p7Reh9LWs1_6FDAk3Xd/view?usp=sharing"
          }}>{`Download the second flow`}</a>{` and import it in Flow Builder. `}</p>
        <Img src="/img/screenshots/quickstarts/convo/wa-masking1&4&6.png" alt="WA-Masking4" width="450px" align="middle" mdxType="Img" />
      </li>
      <li parentName="ol">
        <p parentName="li">{`Repeat steps 3-5 from above. Don’t forget to record the Webhook URL.`}</p>
        <Img src="/img/screenshots/quickstarts/convo/wa-masking2&5.png" alt="WA-Masking5" width="450px" align="middle" mdxType="Img" />
      </li>
      <li parentName="ol">
        <p parentName="li">{`Click the `}<strong parentName="p">{`Your WABA 1 of x`}</strong>{` steps with the Attention Needed sticker, and select your WhatsApp from the menu.`}</p>
        <Img src="/img/screenshots/quickstarts/convo/wa-masking0.png" alt="WA-Masking2" width="350px" align="middle" mdxType="Img" />
        <Img src="/img/screenshots/quickstarts/convo/wa-masking01.png" alt="WA-Masking2" width="350px" align="middle" mdxType="Img" />
      </li>
      <li parentName="ol">
        <p parentName="li">{`Publish your changes. `}</p>
      </li>
    </ol>
    <h3>{`Flow 3: Engaging the Sessions`}</h3>
    <ol>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://drive.google.com/file/d/1fEy918bSpsQxH80rN-T6kIMl5RtjJa07/view?usp=sharing"
          }}>{`Download the third flow`}</a>{` and import it in Flow Builder. `}</p>
        <Img src="/img/screenshots/quickstarts/convo/wa-masking1&4&6.png" alt="WA-Masking6" width="450px" align="middle" mdxType="Img" />
      </li>
      <li parentName="ol">
        <p parentName="li">{`Click on the first step in the flow, Omni-channel, and select your WhatsApp from the menu. `}</p>
        <Img src="/img/screenshots/quickstarts/convo/wa-masking7.png" alt="WA-Masking7" width="450px" align="middle" mdxType="Img" />
      </li>
      <li parentName="ol">
        <p parentName="li">{`Click the steps that say Webhook URL of WAAM B2C and Webhook URL of WAAM C2B, and add the Webhook URLs recorded above.  `}</p>
        <Img src="/img/screenshots/quickstarts/convo/wa-masking8.png" alt="WA-Masking8" width="350px" align="middle" mdxType="Img" />
        <Img src="/img/screenshots/quickstarts/convo/wa-masking9.png" alt="WA-Masking9" width="350px" align="middle" mdxType="Img" />
      </li>
      <li parentName="ol">
        <p parentName="li">{`Don’t forget to publish your changes. `}</p>
      </li>
    </ol>
    <h2>{`Test it out`}</h2>
    <p>{`To test the WAAM you've just built you can modify the following deep link with your WhatsApp Business Account (WABA) number:`}</p>
    <p><a parentName="p" {...{
        "href": "https://api.whatsapp.com/send?phone=%7B%7BYOUR-WABA-PHONE-NUMBER%7D%7D&text=%23waam"
      }}>{`https://api.whatsapp.com/send?phone={{YOUR-WABA-PHONE-NUMBER}}&text=%23waam`}</a></p>
    <p>{`Or, you can generate a QR code (again changing the link to include your WABA number): `}</p>
    <p><a parentName="p" {...{
        "href": "https://chart.apis.google.com/chart?cht=qr&chs=300x300&chl=https%3A//api.whatsapp.com/send%3Fphone%3D%7B%7BYOUR-WABA-PHONE-NUMBER%7D%7D%26text%3D%2523waam&chld=H%7C0"
      }}>{`https://chart.apis.google.com/chart?cht=qr&chs=300x300&chl=https%3A//api.whatsapp.com/send%3Fphone%3D{{YOUR-WABA-PHONE-NUMBER}}%26text%3D%2523waam&chld=H|0`}</a></p>
    <p>{`Find a conversation buddy, send them the link or the QR code and start testing!`}</p>
    <p><strong parentName="p">{`Pro-tip:`}</strong>{` Reply `}<strong parentName="p">{`EXIT`}</strong>{` at any time during the conversation to jump out of the flow`}</p>
    <p>{`They should follow the prompts and register as the participant. Likewise, you should message the WABA channel, input the WhatsApp number you would like to use, and start the masked conversation.`}</p>
    <h2>{`And that’s it! 🎉`}</h2>
    <p>{`We hope you found this tutorial helpful. If you have any questions or need help, feel free to reach out at `}<a href="salesengineers_apac@messagebird.com"><a parentName="p" {...{
          "href": "mailto:salesengineers_apac@messagebird.com"
        }}>{`salesengineers_apac@messagebird.com`}</a></a>{`; we’re here for you. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      