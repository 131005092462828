import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Getting Call Information with MessageBird`}</h1>
    <p>{`When voice calls are created on the MessageBird platform, they're available for querying through the Voice API's RESTful endpoints. In this MessageBird Tutorial you’ll learn the concepts, terminology and the available methods for retrieving information about calls.`}</p>
    <h2>{`Calls and legs`}</h2>
    <p>{`When a call is received or placed, a call resource is stored on our platform. A call object typically looks like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "id": "f1aa71c0-8f2a-4fe8-b5ef-9a330454ef58",
  "status": "ended",
  "numberId": "206001de-a327-4dbb-9770-47c82ef18e82",
  "createdAt": "2017-02-16T10:52:00Z",
  "updatedAt": "2017-02-16T10:59:04Z",
  "endedAt": "2017-02-16T10:59:04Z"
}
`}</code></pre>
    <p>{`During the life cycle of a call, one or more voice connections are made. We call these entities legs. Amongst other properties, a leg has a source (number or SIP URL), destination (number or SIP URL) and direction (“incoming’ or ‘outgoing”).`}</p>
    <p>{`Calls have a "one to many" relation with legs, meaning a single call can have one or more legs. Typically, each incoming voice call has a leg with direction "incoming" and, based on the call flow that was executed, one or more legs with direction "outgoing". Legs with direction "outgoing" are created when a transfer step is executed.`}</p>
    <p>{`A leg object typically looks like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "id": "d4f07ab3-b17c-44a8-bcef-2b351311c28f",
  "callId": "f1aa71c0-8f2a-4fe8-b5ef-9a330454ef58",
  "source": "31644556677",
  "destination": "31612345678",
  "status": "hangup",
  "direction": "outgoing",
  "createdAt": "2017-02-16T10:52:00Z",
  "updatedAt": "2017-02-16T10:52:30Z",
  "answeredAt": "2017-02-16T10:52:30Z",
  "endedAt": "2017-02-16T10:52:04Z"
}
`}</code></pre>
    <p>{`Keep in mind that leg resources are created automatically by the platform during the lifecycle of a call; because of this, you can query them through the Voice API, but you cannot create them manually.`}</p>
    <h2>{`Retrieving call information`}</h2>
    <h3>{`STEP 1: LISTING ALL CALLS`}</h3>
    <p>{`To query a list of all calls, you can use the following API endpoint:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`GET https://voice.messagebird.com/calls
`}</code></pre>
    <p>{`An example cURL command looks like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`curl -X "GET" "https://voice.messagebird.com/calls" \\
    -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM"
`}</code></pre>
    <p>{`The response is a JSON object with a data array of call objects:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "data": [
    {
      "id": "f1aa71c0-8f2a-4fe8-b5ef-9a330454ef58",
      "status": "ended",
      "numberId": "6e143426-d7ea-11e6-bf26-cec0c932ce01",
      "createdAt": "2017-02-16T10:52:00Z",
      "updatedAt": "2017-02-16T10:59:04Z",
      "endedAt": "2017-02-16T10:59:04Z",
      "_links": {
        "self": "/calls/f1aa71c0-8f2a-4fe8-b5ef-9a330454ef58"
      }
    },
    {
      "id": "ac07a602-dbc1-11e6-bf26-cec0c932ce01",
      "status": "ended",
      "numberId": "6e143426-d7ea-11e6-bf26-cec0c932ce01",
      "createdAt": "2017-01-16T07:51:56Z",
      "updatedAt": "2017-01-16T07:55:56Z",
      "endedAt": "2017-01-16T07:55:56Z",
      "_links": {
        "self": "/calls/ac07a602-dbc1-11e6-bf26-cec0c932ce01"
      }
    }
  ],
  "_links": {
    "self": "/calls?page=1"
  },
  "pagination": {
    "totalCount": 2,
    "pageCount": 1,
    "currentPage": 1,
    "perPage": 10
  }
}
`}</code></pre>
    <p>{`You can find a detailed description of the call object in `}<a parentName="p" {...{
        "href": "/api/voice-calling#calls"
      }}>{`the ‘Call’ section`}</a>{` of our Voice Calling API documentation.`}</p>
    <p>{`To query a list of legs related to a call, you can use the following API endpoint:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-curl"
      }}>{`https://voice.messagebird.com/calls/{callID}/legs
`}</code></pre>
    <p>{`An example cURL command looks like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`curl -X "GET" "https://voice.messagebird.com/calls/f1aa71c0-8f2a-4fe8-b5ef-9a330454ef58/legs" \\
    -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM"
`}</code></pre>
    <p>{`The response is a JSON object with a data array of leg objects:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`{
  "data": [
    {
      "id": "d4f07ab3-b17c-44a8-bcef-2b351311c28f",
      "callId": "f1aa71c0-8f2a-4fe8-b5ef-9a330454ef58",
      "source": "31123456789",
      "destination": "31123456777",
      "status": "hangup",
      "direction": "outgoing",
      "createdAt": "2017-02-16T10:52:00Z",
      "updatedAt": "2017-02-16T10:52:30Z",
      "answeredAt": "2017-02-16T10:52:30Z",
      "endedAt": "2017-02-16T10:52:04Z",
      "_links": {
        "self": "/calls/f1aa71c0-8f2a-4fe8-b5ef-9a330454ef58/legs/d4f07ab3-b17c-44a8-bcef-2b351311c28f"
      }
    },
    {
      "id": "e60ca497-0cf3-4954-b74c-265e95633ec8",
      "callId": "f1aa71c0-8f2a-4fe8-b5ef-9a330454ef58",
      "source": "31123456789",
      "destination": "31123456788",
      "status": "hangup",
      "direction": "incoming",
      "createdAt": "2017-02-16T10:52:00Z",
      "updatedAt": "2017-02-16T10:52:04Z",
      "answeredAt": "2017-02-16T10:52:30Z",
      "endedAt": "2017-02-16T10:52:04Z",
      "_links": {
        "self": "/calls/f1aa71c0-8f2a-4fe8-b5ef-9a330454ef58/legs/e60ca497-0cf3-4954-b74c-265e95633ec8"
      }
    }
  ],
  "_links": {
    "self": "/calls/f1aa71c0-8f2a-4fe8-b5ef-9a330454ef58/legs?page=1"
  },
  "pagination": {
    "totalCount": 2,
    "pageCount": 1,
    "currentPage": 1,
    "perPage": 10
  }
}
`}</code></pre>
    <h3>{`Awesome!`}</h3>
    <p>{`You can find a detailed description of the leg object in `}<a parentName="p" {...{
        "href": "/api/voice-calling#legs"
      }}>{`the ‘Leg’ section`}</a>{` of our Voice Calling API documentation.`}</p>
    <h2>{`Receiving updates on calls`}</h2>
    <p>{`In addition to manually querying calls, you can also receive HTTP callbacks on your web service when calls are created and updated.`}</p>
    <h3>{`STEP 1: CONFIGURE A WEBHOOK FOR YOUR NUMBER`}</h3>
    <p>{`First, let’s create a webhook resource—a configuration object that is used for performing status callbacks.
Here’s the endpoint to create a webhook for a number:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`POST https://voice.messagebird.com/numbers/:your_number/webhook
curl -X POST https://voice.messagebird.com/numbers/31123456788/webhook
        -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM"
        -H "Content-Type: application/json"
        -d $'{"url": "https://example.com/messagebird/callback",
      "token": "foobar"
    }'
`}</code></pre>
    <h3>{`STEP 2: HANDLING INCOMING CALLBACKS:`}</h3>
    <p>{`When a call is created or updated, the MessageBird platform does an HTTP POST request to the URL configured. An example POST body of a callback looks like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "timestamp": "2017-08-24T09:44:37Z",
  "items": [
    {
      "type": "call",
      "event": "callUpdated",
      "payload": {
        "id": "a3369d39-52d6-4b4d-b852-05a72b45c589",
        "status": "ongoing",
        "number": {
          "id": "13f38f34-7ff4-45b3-8783-8d5b1143f22b",
          "number": "31123456788",
          "callFlowId": "4e0d9601-ad9c-45dc-a1f7-e015119c741c",
          "createdAt": "2017-03-16T13:49:24Z",
          "updatedAt": "2017-08-23T15:16:16Z"
        },
        "legs": [
          {
            "id": "079f1437-9c18-49c2-91af-000c88fcd051",
            "callId": "a3369d39-52d6-4b4d-b852-05a72b45c589",
            "source": "31612345678",
            "destination": "31123456788",
            "status": "ongoing",
            "direction": "incoming",
            "createdAt": "2017-08-24T09:44:37Z",
            "updatedAt": "2017-08-24T09:44:37Z",
            "answeredAt": null,
            "endedAt": null
          }
        ],
        "createdAt": "2017-08-24T09:44:37Z",
        "updatedAt": "2017-08-24T09:44:37Z",
        "endedAt": null
      }
    }
  ]
}
`}</code></pre>
    <p>{`Your web service should respond with a `}<inlineCode parentName="p">{`200 OK`}</inlineCode>{` HTTP header.`}</p>
    <p>{`You can find more information on the JSON structure and how to validate a callback in the ‘`}<a parentName="p" {...{
        "href": "/api/voice-calling#callbacks"
      }}>{`Handle webhooks callbacks section`}</a>{`’ of our Voice Calling API documentation.`}</p>
    <p><strong parentName="p">{`Nice work!`}</strong>{` 🎉`}</p>
    <p>{`You just learned how to retrieve information about calls!`}</p>
    <h2>{`Start building!`}</h2>
    <p>{`Want to build something similar but not quite sure how to get started? Feel free to let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we'd love to help!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      