import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
import Notification from 'components/Notification';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Warm Transfers`}</h1>
    <p>{`Messagebird's Voice API can be used to orchestrate interactions between multiple parties by providing a low-level API that provides fine-grained control over calls and the experience of call participants. In this tutorial, we'll explore how to use the Voice API to recreate a common scenario in most call centers: performing a "Warm Transfer" whereby a call participant is transferred to another caller who is aware of the context of the transferred party. This article will cover:`}</p>
    <ol>
      <li parentName="ol">{`Placing Users on Hold`}</li>
      <li parentName="ol">{`Adding Participants to Ongoing Calls`}</li>
      <li parentName="ol">{`Ending Holds and Hanging Up`}</li>
    </ol>
    <p>{`Before that, though, it is helpful to understand the Warm Transfer scenario. `}</p>
    <h2>{`Warm Versus Cold Transfers`}</h2>
    <p>{`To understand the Warm Transfer scenario, it is first helpful to describe its opposite: the Cold Transfer. In a Cold Transfer scenario, a caller (Caller) typically speaks with an agent (Agent A) and is then transferred to a different agent (Agent B) who is not aware of the caller in advance. Although this experience is common, it is often dissatisfying to the caller who must then re-explain the purpose of the call and their personal information to the new agent. Although Cold Transfers may be effective, and even necessary in some cases where the caller's original context is irrelevant, the user experience can often be improved by performing a Warm Transfer instead.`}</p>
    <p>{`A Warm Transfer scenario is very similar to a Cold Transfer: a caller is transferred from Agent A to Agent B, however instead of offloading the handoff work onto the caller, the caller is placed on hold while Agent A speaks with Agent B, preparing the next agent to receive the caller. When Agent B is ready, the caller is removed from the hold and Agent B can take over the rest of the call from Agent A who leaves the call, or stays on the line for some period until the handoff has been adequately completed. Warm Transfers therefore accomplish a similar task as a Cold Transfer, but with a significantly improved customer experience.`}</p>
    <h2>{`Initiating the call`}</h2>
    <p>{`Because Warm Transfers, by definition, require a caller (Caller) and at least one initial recipient (Agent A), let's assume that a voice-capable Messagebird Virtual Mobile Number (VMN) has been purchased, and is attached to a Call Flow that performs a transfer to Agent A. The Call Flow will therefore look like this, where `}<inlineCode parentName="p">{`$AGENT_A`}</inlineCode>{` is substituted for the agent's actual number:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "steps": [
    {
      "options": {
        "destination": "$AGENT_A"
      },
      "action": "transfer"
    }
  ]
}
`}</code></pre>
    <Notification type="WARNING" mdxType="Notification">Note that Warm Transfers currently are not supported for Outbound calls. The following behavior in the rest of the tutorial is possible only when a Call Flow is triggered by a user calling the associated VMN.</Notification>
    <h2>{`Placing Users on Hold`}</h2>
    <p>{`When the call is placed to the attached VMN, this Call Flow is executed by the Voice API and the Caller is connected to Agent A. After some discussion, Agent A decides that the caller would be better suited by speaking to Agent B instead, who has more experience dealing with the kinds of concerns the caller has. To start, Agent A initiates a hold by making the following HTTP request:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`curl -X "POST" "https://voice.messagebird.com/legs/:leg_id:/hold" \\
     -H "Authorization: AccessKey :access_key:" \\
     -H "Content-Type: application/json; charset=utf-8" \\
     -d $'{"musicOnHold": "https://path.to/hold-music.wav"}'
`}</code></pre>
    <p>{`Note that the request parameter, `}<inlineCode parentName="p">{`:leg_id:`}</inlineCode>{` must be substituted with the ID of the leg that should be placed on hold. Because this is an inbound call, it is possible to identify the correct leg by making a request to get the call legs, and then filtering for the leg ID where the source number matches the caller's number (or the destination matches Agent A). `}</p>
    <p>{`The user will now be placed indefinitely on hold, listening to the audio specified with the `}<inlineCode parentName="p">{`musicOnHold`}</inlineCode>{` parameter provided in the request body. Note that omitting this parameter will result in silence for the user on hold. `}</p>
    <h2>{`Adding Participants to Ongoing Calls`}</h2>
    <p>{`While the caller is on hold, Agent A wants to speak with Agent B to inform that agent about the caller's details, and therefore needs to add Agent B to the ongoing call. This can be accomplished by making the following request, which adds a new destination number to the ongoing call:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`curl -X "POST" "https://voice.messagebird.com/calls/:call_id:/:agent_b_number:" \\
     -H "Authorization: AccessKey :access_key:"
`}</code></pre>
    <p>{`This endpoint accomplishes the goal of adding Agent B to the call, while leaving the caller on hold. In theory, this endpoint can be used to continue to add additional call participants (a call center manager, for example), and anyone who is not in a hold state of the call will be able to speak with each other.`}</p>
    <p>{`By default, the Caller ID that Agent B sees will be the Caller's number and not Agent A's, but this can be customized by providing a `}<inlineCode parentName="p">{`source`}</inlineCode>{` property in the body of the request.`}</p>
    <h2>{`Ending Holds and Hanging Up`}</h2>
    <p>{`After Agents A & B have spoken, and are ready to bring the caller off of the hold, the following request can be made using the same leg ID as had previously been used to place the caller on hold:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`curl -X "POST" "https://voice.messagebird.com/legs/:leg_id:/unhold" \\
     -H "Authorization: AccessKey :access_key:"
`}</code></pre>
    <p>{`The caller will now rejoin the call with Agent A & B to discuss the handoff from Agent A to Agent B. When Agent A is ready to exit the conversation, the agent may either hang up their phone normally, or remove themselves using the following HTTP request:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`curl -X "DELETE" "https://voice.messagebird.com/legs/:leg_id:" \\
     -H "Authorization: AccessKey :access_key:"
`}</code></pre>
    <p>{`Note that here it is necessary to specify the leg ID that corresponds to the agent that should be removed, and not the caller.`}</p>
    <p><strong parentName="p">{`Nice work!`}</strong>{` 🎉`}</p>
    <p>{`You just successfully used the Messagebird API to coordinate a Warm Transfer! The concepts outlined here are not only applicable in the context of a Warm Transfer, and can be used for any purpose in which it's necessary to have low-level control over the call. In this tutorial we learned how to interact with four endpoints:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/api/voice-calling/#place-a-leg-on-hold"
        }}>{`Leg Hold`}</a>{` - puts a call participant on hold`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/api/voice-calling/#add-participant-to-call"
        }}>{`Add participant to call`}</a>{` - calls and adds a user to an ongoing call`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/api/voice-calling/#remove-a-leg-on-hold"
        }}>{`Leg unhold`}</a>{` - removes a call participant from hold`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/api/voice-calling/#deleting-a-leg"
        }}>{`Leg delete`}</a>{` - removes a participant from a call`}</li>
    </ul>
    <p>{`Click the links above to learn more about how to use these endpoints from Messagebird's Voice API documentation. We're excited to see how you use them.`}</p>
    <h2>{`Start building!`}</h2>
    <p>{`Want to build something similar but not quite sure how to get started? Feel free to let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we'd love to help!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      