import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Docs.tsx";
import Notification from 'components/Notification';
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Send a Verification Token`}</h1>
    <p>{`In this Verify API Quickstart, you'll learn how to send your first verification token from your web application using the MessageBird Verify API.`}</p>
    <h2>{`Step 1: Sign up for free`}</h2>
    <p>{`Before we get started, `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/login"
      }}>{`log in to the MessageBird Connectivity Platform`}</a>{` and collect your live API key from the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`Developer Dashboard`}</a>{`.`}</p>
    <h2>{`Step 2: Send your first verification token`}</h2>
    <p>{`Next, enter `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`your credentials`}</a>{` and mobile number - as both the `}<inlineCode parentName="p">{`originator`}</inlineCode>{` and `}<inlineCode parentName="p">{`recipient`}</inlineCode>{` - to the snippet below.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-curl"
      }}>{`curl -X POST https://rest.messagebird.com/verify \\
    -H 'Authorization: AccessKey YOUR-API-KEY' \\
    -d "recipient=31XXXXXXXXX" \\
    -d "originator=YourName"
`}</code></pre>
    <p>{`Next, simply copy the above snippet to your terminal and hit enter.`}</p>
    <p>{`Good job! You’ve sent your first verification token with MessageBird’s Verify API.`}</p>
    <h2>{`Step 3: View the response`}</h2>
    <p>{`Now, let’s view MessageBird’s HTTP response to your API call. If the set up was correct, you'll receive a response similar to this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`{
  id: "4e213b01155d1e35a9d9571v00162985",
  href: "https://rest.messagebird.com/verify/4e213b01155d1e35a9d9571v00162985",
  recipient: 31XXXXXXXXXX,
  reference: null,
  messages: {
    href: "https://rest.messagebird.com/messages/31bce2a1155d1f7c1db9df6b32167259"
  },
  status: "sent",
  createdDatetime: "2016-05-03T18:12:54+00:00"
  validUntilDatetime: "2016-05-03T18:13:24+00:00"
}
`}</code></pre>
    <p>{`If something went wrong, you’ll receive an error response from the MessageBird API. Don't worry, simply head over to `}<a parentName="p" {...{
        "href": "/docs/verify/troubleshooting"
      }}>{`Verify API Troubleshooting`}</a>{` to understand the error.`}</p>
    <p>{`Congrats! You can now programmatically send a verification token with the MessageBird Verify API using cURL.`}</p>
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'Programmatically verify a token via the API',
      to: '/docs/verify/verify-a-token-curl'
    }, {
      name: 'Verify API Use Case Tutorials and Sample Projects',
      to: '/tutorials?filter=verify'
    }, {
      name: 'Verify API Reference',
      to: '/api/verify'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      