import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import NextSteps from 'components/Footer/NextSteps';
import Notification from 'components/Notification';
import Questions from 'components/Footer/Questions';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Sending messages`}</h1>
    <p>{`In this Programmable Conversations API Quickstart, you'll learn how to send a new outbound message to a channel-specific user identifier, that is, a phone number for the SMS channel with the Programmable Conversations API.`}</p>
    <p>{`Programmable Conversations allows you to send messages to users over any supported communication platform such as SMS, WhatsApp, Live Chat, WeChat, Messenger, Telegram, LINE, Instagram, Email, Google Business Messages, Twitter, and Viber. `}</p>
    <h2>{`Step 1: Collect your credentials`}</h2>
    <p>{`To start testing the Programmable Conversations API using cURL, you need to first set up your terminal. `}</p>
    <p><a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/login"
      }}>{`Log in to the MessageBird Connectivity Platform`}</a>{` and collect your live API key from the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`Developer Dashboard`}</a>{`.`}</p>
    <h2>{`Step 2: Sending text messages with the API`}</h2>
    <p>{`Enter `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`your credentials`}</a>{` in the code snippet below. Start by replacing `}<inlineCode parentName="p">{`YOUR-API-KEY`}</inlineCode>{` with your API key.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-curl"
      }}>{`curl -X "POST" "https://conversations.messagebird.com/v1/send" \\
-H "Authorization: AccessKey YOUR-API-KEY" \\
-H "Content-Type: application/json" \\
--data '{ "to":"+31XXXXXXXXX", "from":"CHANNEL-ID", "type":"text", "content":{ "text":"Hello!" }, "reportUrl":"https://your-domain.com/reports" }'
`}</code></pre>
    <p>{`Replace your mobile number as `}<inlineCode parentName="p">{`to`}</inlineCode>{`, and the channel ID (that identifies the channel over which the message should be sent) as `}<inlineCode parentName="p">{`from`}</inlineCode>{`. You can find your channel IDs or create new channels in the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/channels"
      }}>{`Channels Directory`}</a>{` in your MessageBird Dashboard.`}</p>
    <p>{`You may also want to add `}<inlineCode parentName="p">{`reportUrl`}</inlineCode>{` to deliver status reports to your platform through a `}<inlineCode parentName="p">{`POST`}</inlineCode>{` request to a specific URL. If you don't need reports, remove the parameter from the request.`}</p>
    <p>{`Once you’ve entered all your credentials, go ahead and copy the above snippet to your terminal and hit enter!`}</p>
    <h2>{`Step 3: View the response`}</h2>
    <p>{`Have a look at MessageBird’s HTTP response to your API call. If the set up was correct, you'll receive a response similar to this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "id": "24500a370c86916fe8aef77e4c24b6a6",
  "status": "accepted",
  "fallback": null
}
`}</code></pre>
    <p>{`If something went wrong, you’ll receive an error response from the MessageBird API. Don't worry, simply head over to `}<a parentName="p" {...{
        "href": "https://developers.messagebird.com/docs/conversations/troubleshooting"
      }}>{`Conversations API Troubleshooting`}</a>{` to understand the error.`}</p>
    <p>{`Nice work! 🎉  You can now send outbound messages with Programmable Conversations.`}</p>
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'Receiving to messages via the API',
      to: '/quickstarts/conversations/handle-inbound-messages-curl'
    }, {
      name: 'Replying to messages via the API',
      to: '/quickstarts/conversations/reply-to-messages-curl'
    }, {
      name: 'Programmable Conversations API Reference',
      to: '/api/conversations'
    }]} mdxType="NextSteps" />
    <p>{`#`}<Questions mdxType="Questions" /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      