import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/ApiRef.js";
import CodeSnippet from 'components/CodeSnippet';
import * as CODE from '../../constants/apirefoverview';
import { APIWrapper, LeftColumn, RightColumn } from 'components/ApiRef';
import Notification from 'components/Notification';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`MessageBird API Reference`}</h1>
    <p>{`Dive into our full API Reference Documentation and seamlessly integrate SMS, Chat, Voice functionalities into your website or application with the MessageBird APIs.`}</p>
    <p>{`MessageBird's APIs use `}<inlineCode parentName="p">{`HTTP verbs`}</inlineCode>{` and a `}<inlineCode parentName="p">{`RESTful`}</inlineCode>{` endpoint structure. An access key is used as the API Authorization framework. Request and response payloads are formatted as JSON (although we provide a GET alternative for requests), using UTF-8 encoding and URL encoded values.`}</p>
    <h2>{`SDKs`}</h2>
    <p>{`Official SDK libraries for MessageBird's APIs are `}<a parentName="p" {...{
        "href": "https://developers.messagebird.com/libraries"
      }}>{`available in several languages`}</a>{`.`}</p>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`API Endpoint`}</h2>
        <p>{`In order to use the APIs of the MessageBird Connectivity Platform, you need to have an account and log in to `}<a parentName="p" {...{
            "href": "https://dashboard.messagebird.com/en/login/"
          }}>{`the Dashboard of the Connectivity Platform`}</a>{`.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
        <h4>API Endpoint</h4>
        <CodeSnippet code={CODE.API_ENDPOINT} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Authentication`}</h2>
        <p>{`MessageBird's APIs use API keys to authenticate requests. You can create, retrieve, and manage your API keys in your `}<a parentName="p" {...{
            "href": "https://dashboard.messagebird.com/en/developers/access"
          }}>{`MessageBird Dashboard`}</a>{`.`}</p>
        <p>{`Test API keys have the prefix `}<inlineCode parentName="p">{`test_`}</inlineCode>{` and live API keys don't require a prefix.`}</p>
        <p>{`With each API call, you will need to set request headers including your access key to authenticate yourself.`}</p>
        <Notification type="WARNING" mdxType="Notification">
Your API keys carry significant privileges. Please ensure to keep them 100% secure and be sure to not share your secret API keys in areas that are publicly accessible like GitHub. See <a href="/api/#api-access-key-security">API Access Key Security</a> for more information. 
        </Notification>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
        <h4>Example Request</h4>
        <CodeSnippet code={CODE.ERRORS_REQUEST} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameters`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Authorization`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`When calling our API, send your access key with the authentication type set as `}<inlineCode parentName="td">{`AccessKey`}</inlineCode>{` (Example: `}<inlineCode parentName="td">{`Authorization: AccessKey {accessKey}`}</inlineCode>{`). `}<strong parentName="td">{`Required`}</strong>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Accept`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set to `}<inlineCode parentName="td">{`application/json`}</inlineCode>{`. `}<strong parentName="td">{`Required`}</strong>{`.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`IP Addresses`}</h2>
    <p>{`Our API platform is offered from a globally distributed infrastructure, so you won't be able to whitelist the IP addresses of our platform. Keep in mind that request for delivery reports and inbound messages originate from various IP addresses.`}</p>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`API Access Key Security`}</h2>
        <p>{`Given your API access key is your authentication token for using MessageBird's APIs, they need to be appropriately secured. One of the easiest ways to think of this is to treat your API access keys just like you would your passwords, including storing them securely and never sharing them with anyone. `}</p>
        <p>{`One of the most common mistakes that is made with API keys is to inadvertently check them into public repositories on platforms such as GitHub. From here, fraudsters can find and steal your API access key and then use it to send Spam messages and also drain your account balance. There are numerous techniques to avoid this, however storing your API access key in an environment variable, passing them as command line arguments or using a secrets manager can all help to prevent this from occurring. The main takeaway is don't hard-code your API access key and don't check it into a public code repository.`}</p>
        <p>{`In a similar manner, sharing code snippets on platforms such as PasteBin, GitHub Gists or StackOverflow can inadvertently leak your API access key so ensure that you and your developers are aware of this risk. `}</p>
        <p>{`MessageBird also takes measures on our side to help detect and mitigate compromised API access keys, however prevention is the best medicine so please ensure you protect your API access keys.`}</p>
        <h2>{`API Errors`}</h2>
        <p>{`MessageBird uses standard HTTP status codes to indicate the success or failure of an API request. Generally speaking, codes in the 2xx range indicate that a request was successfully processed and codes in the 4xx range indicate that there was an error that resulted from the information provided (e.g., authentication, no balance or a missing or wrong parameter).`}</p>
        <p>{`In case of an error, the body of the response includes a json formatted response that tells you exactly what is wrong.`}</p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Attributes`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`code`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`An integer that represents the error type.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`description`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A human-readable description of the error. You can provide your users with this information to indicate what they can do about the error.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`parameter`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The parameter in your request that is related to the error if the error is parameter specific.`}</td>
            </tr>
          </tbody>
        </table>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`HTTP Status Codes`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`200 Found`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`We found the request resource`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`201 Created`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The resource is successfully created`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`204 No Content`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The requested resources is empty`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`401 Unauthorized`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The access key was incorrect`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`404 Not found`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The resources cannot be found`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`405 Method Not Allowed`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The method is not allowed`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`408 Request Timeout`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The request is taking too long to respond`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`422 Unprocessable Entity`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The resource couldn't be created`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`429 Too Many Requests`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Rate Limited. Reduce the number of requests`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`5xx Something went wrong on our end`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Please try again`}</td>
            </tr>
          </tbody>
        </table>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Error codes`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`2`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Request not allowed`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`9`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Missing params`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`10`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Invalid params`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`20`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Not found`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`21`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Bad request`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`25`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Not enough balance`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`98`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`API not found`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`99`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Internal error`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`100`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Service unavailable`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`101`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Duplicate entry`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`102`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Ambiguous lookup`}</td>
            </tr>
          </tbody>
        </table>
        <h4>{`Error handling`}</h4>
        <p>{`The error object will give you information about the error makes it easier to fix.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
        <h4>Error Object</h4>
        <CodeSnippet code={CODE.ERRORS_OBJECT} mdxType="CodeSnippet" />
        <h4>Error Handling</h4>
        <CodeSnippet code={CODE.ERRORS_HANDLING} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Requests`}</h2>
        <p><inlineCode parentName="p">{`POST`}</inlineCode>{` and `}<inlineCode parentName="p">{`PUT`}</inlineCode>{` requests to the API should contain a `}<inlineCode parentName="p">{`JSON`}</inlineCode>{`-formatted payload in the request body. Alternatively, resource attributes can be sent through `}<inlineCode parentName="p">{`GET`}</inlineCode>{` parameters.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
        <h4>JSON request payload example</h4>
        <CodeSnippet code={CODE.JSON_PAYLOAD} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`HTTP Methods`}</h2>
        <p>{`The MessageBird API uses HTTP verbs to understand if you want to read (GET), delete (DELETE), or create (POST) an object. When your website or application doesn't have the possibility to do a POST or DELETE, we provide the ability to set the method through the query parameter _method.`}</p>
        <p>{`Alternatively, resource attributes can be sent through GET attributes instead of a JSON body.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
        <h4>Sending a message</h4>
        <CodeSnippet code={CODE.SEND_MESSAGE} mdxType="CodeSnippet" />
        <h4>Requesting an HLR</h4>
        <CodeSnippet code={CODE.REQUEST_HLR} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Verifying HTTP requests`}</h2>
        <p>{`We sign our HTTP requests to allow you to verify that they actually came from us (authentication) and that they haven't been altered along the way (integrity).`}</p>
        <p>{`Please keep in mind that the rundown below applies to all our services apart from our Voice Calling API `}<a href="/api/voice-calling#dynamic-call-flows">{`Dynamic Call Flows`}</a>{`, where the `}<a href="/api/voice-calling#validation-of-signatures">{`signature validation process`}</a>{` is slightly different.`}</p>
        <Notification type="INFO" mdxType="Notification">You can conduct a live test via Flow Builder. Please do not use the "Test" Flow Simulator as it does not invoke an HTTP Request to your endpoint.</Notification>
        <h3>{`What is request signing?`}</h3>
        <p>{`For each HTTP request that MessageBird sends, a `}<inlineCode parentName="p">{`MessageBird-Signature-JWT`}</inlineCode>{` header is added.`}</p>
        <p>{`The `}<inlineCode parentName="p">{`MessageBird-Signature-JWT`}</inlineCode>{` header is a signature that consists of all the information that is required to verify the integrity of the request. The signature is generated from the request URL and request body and is signed with the HMAC-SHA256 algorithm using your `}<a parentName="p" {...{
            "href": "https://dashboard.messagebird.com/en/developers/settings"
          }}>{`your signing key`}</a>{`. You can validate this signature using our SDKs (see `}<a href="/api/#signature-usage">{`signature usage`}</a>{`) to ensure that the request is valid and unaltered. The token also includes timestamp claims that allow you to prove the time of the request, protecting from replay attacks and the like. Easy!`}</p>
        <h3>{`What if my signing secret has been compromised?`}</h3>
        <p>{`If your secret has been leaked, don't panic. You can easily regenerate your secret at any time via `}<a parentName="p" {...{
            "href": "https://dashboard.messagebird.com/en/user/index"
          }}>{`your MessageBird Dashboard`}</a>{` by clicking on the 'Regenerate' blue button.`}</p>
        <h3>{`Signature details`}</h3>
        <p>{`The `}<inlineCode parentName="p">{`MessageBird-Signature-JWT`}</inlineCode>{` header is a JWT token consisting of three sections: header, payload, and signature.`}</p>
        <ul>
          <li parentName="ul">{`Header:`}
            <ul parentName="li">
              <li parentName="ul"><inlineCode parentName="li">{`typ`}</inlineCode>{` - is the type of the token, which is always `}<strong parentName="li">{`JWT`}</strong>{`.`}</li>
              <li parentName="ul"><inlineCode parentName="li">{`alg`}</inlineCode>{` - is the algorithm that the token is signed with, `}<strong parentName="li">{`HS256`}</strong>{` reprensents HMAC-SHA256 algorithm.`}</li>
            </ul>
          </li>
          <li parentName="ul">{`Payload (claims):`}
            <ul parentName="li">
              <li parentName="ul"><inlineCode parentName="li">{`iss`}</inlineCode>{` - is the issuer of the token, which is always `}<strong parentName="li">{`MessageBird`}</strong>{`.`}</li>
              <li parentName="ul"><inlineCode parentName="li">{`nbf`}</inlineCode>{` - is the UNIX timestamp in seconds, after which the token becomes valid.`}</li>
              <li parentName="ul"><inlineCode parentName="li">{`exp`}</inlineCode>{` - is the UNIX timestamp in seconds, after which the token becomes invalid.`}</li>
              <li parentName="ul"><inlineCode parentName="li">{`jti`}</inlineCode>{` - is a unique ID that you can use to detect replay attacks.`}</li>
              <li parentName="ul"><inlineCode parentName="li">{`url_hash`}</inlineCode>{` - is a hex-encoded SHA256 hash of the canonicalized URL that the webhook was sent to.`}</li>
              <li parentName="ul"><inlineCode parentName="li">{`payload_hash`}</inlineCode>{` - is a hex-encoded SHA256 hash of the payload of the HTTP body.`}</li>
            </ul>
          </li>
          <li parentName="ul">{`Signature
`}</li>
        </ul>
        <p>{`When handling the token without the help of our SDKs, make sure you always validate the header `}<inlineCode parentName="p">{`alg`}</inlineCode>{` as `}<strong parentName="p">{`HS256`}</strong>{` and all the above claims. If either the `}<inlineCode parentName="p">{`url_hash`}</inlineCode>{` or `}<inlineCode parentName="p">{`payload_hash`}</inlineCode>{` claim checks are skipped, no query parameters or request body should be trusted.`}</p>
        <h3>{`Signature usage`}</h3>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
        <h4>Examples</h4>
        <CodeSnippet code={CODE.VERIFY_SIGNATURE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <h3>{`Start building`}</h3>
    <p>{`Explore the MessageBird Docs, SDKs, and Developer Tutorials in your favorite programming languages.`}</p>
    <p>{`If you have any questions, don’t hesitate to let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we’re here to help.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      