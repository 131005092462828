import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import { Link } from 'gatsby';
import Notification from 'components/Notification';
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Receive an SMS`}</h1>
    <p>{`In this SMS API Quickstart, we’ll show you how to receive and handle inbound SMS messages using webhooks, `}<a parentName="p" {...{
        "href": "https://www.messagebird.com/en/flow-builder"
      }}>{`Flow Builder`}</a>{` and the MessageBird SMS API.`}</p>
    <p>{`We’ll walk you through the following steps:`}</p>
    <ol>
      <li parentName="ol">{`Purchase a Virtual Mobile Number from MessageBird`}</li>
      <li parentName="ol">{`Set up a Webhook Handler`}</li>
      <li parentName="ol">{`Set up a flow in Flow Builder`}</li>
    </ol>
    <h2>{`Step 1: Purchase your SMS-enabled Virtual Mobile Number`}</h2>
    <ol>
      <li parentName="ol">{`Go to the `}<a parentName="li" {...{
          "href": "https://dashboard.messagebird.com/en/numbers"
        }}>{`Numbers`}</a>{` section in your Dashboard and click `}<a parentName="li" {...{
          "href": "https://dashboard.messagebird.com/en/vmn/buy-number"
        }}>{`Buy a number`}</a></li>
      <li parentName="ol">{`Pick the country in which you and your customers are located, and make sure the SMS capability is selected.`}</li>
      <li parentName="ol">{`Choose one number from the selection.`}</li>
    </ol>
    <h2>{`Step 2: Set up your Webhook Handler`}</h2>
    <p>{`You can set up your SMS handler with any HTTP method, but we strongly recommend you to use `}<inlineCode parentName="p">{`POST`}</inlineCode>{`. The method you specify in your application must be the same that you'll configure later in `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en-je/flow-builder"
      }}>{`Flow Builder`}</a>{`. MessageBird sends inbound SMS as form-encoded requests so you can read the input just as if it were an HTML form submitted by a user of your application.`}</p>
    <p>{`There are a variety of fields, but most applications only need the following two:`}</p>
    <ul>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`originator`}</inlineCode>{` is the phone number that sent the message to you. It will be provided in the international format with country code.`}</li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`payload`}</inlineCode>{` is the content of the message.`}</li>
    </ul>
    <p>{`Your application can return any or even an empty response to a webhook. You should make sure that you don’t return an HTTP error status code.`}</p>
    <Notification type="INFO" mdxType="Notification">
If you want to reply to the message, simply follow the instructions for <Link to="/docs/sms-messaging/send-outbound-sms-curl/" mdxType="Link">sending SMS using your programming language</Link> and add the originator from the webhook to the recipients parameter of the outgoing message.
    </Notification>
    <p>{`If you want to set up a test environment, you can use a Tunnelling provider such as Ngrok. Read more about `}<a parentName="p" {...{
        "href": "https://developers.messagebird.com/tutorials/handle-incoming-calls-and-sms"
      }}>{`setting up Ngrok in this tutorial`}</a>{`.`}</p>
    <h2>{`Step 3: Set up Flow Builder`}</h2>
    <p>{`Now, let’s head over to Flow Builder and select the `}<inlineCode parentName="p">{`Call HTTP endpoint with SMS`}</inlineCode>{` template:`}</p>
    <ol>
      <li parentName="ol">{`Select your SMS-enabled Virtual Mobile Number that you purchased in `}<inlineCode parentName="li">{`Step One`}</inlineCode></li>
      <li parentName="ol">{`Select `}<inlineCode parentName="li">{`POST`}</inlineCode>{` as the Method`}</li>
      <li parentName="ol">{`Enter your Webhook URL and make sure the URL ends with `}<inlineCode parentName="li">{`/webhook`}</inlineCode></li>
      <li parentName="ol">{`Hit publish to activate your flow.`}</li>
    </ol>
    <p>{`Congrats! You can now programmatically handle inbound SMS with `}<a parentName="p" {...{
        "href": "https://www.messagebird.com/en/flow-builder"
      }}>{`Flow Builder`}</a>{`, webhooks, and the SMS API.`}</p>
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'Send an SMS',
      to: '/quickstarts/sms/send-sms-curl'
    }, {
      name: 'SMS Tutorials',
      to: '/tutorials?filter=sms'
    }, {
      name: 'SMS API Reference',
      to: '/api/sms-messaging'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      