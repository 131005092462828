import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Implementing SMS two-factor authentication (2FA) with MessageBird`}</h1>
    <h3>{`⏱ 15 min build time      ||      `}<a parentName="h3" {...{
        "href": "https://github.com/messagebirdguides/verify-guide-go"
      }}>{`Download the Code`}</a></h3>
    <h2>{`Why build two-factor authentication?`}</h2>
    <p>{`In this MessageBird Developer Tutorial you’ll learn how to improve your security building an SMS-based two factor authentication solution with the `}<a parentName="p" {...{
        "href": "/api/verify"
      }}>{`MessageBird Verify API`}</a>{`. The runnable application we’ll build is a prototype in Go for our fictitious online banking application, `}<em parentName="p">{`BirdBank`}</em>{`.`}</p>
    <p>{`Enterprises are increasingly challenged to keep sensitive information from falling into the wrong hands. This means that we can no longer trust old online authentication systems that rely solely on usernames and passwords, especially as security breaches grow in frequency, severity and sophistication.`}</p>
    <p>{`With the `}<a parentName="p" {...{
        "href": "/api/verify"
      }}>{`MessageBird Verify API`}</a>{`, you can implement two factor authentication (2FA) solutions to provide an additional layer of account security by verifying the user's password with a second authentication token and in turn, secure customer data, block fraudulent accounts, and safeguard key transactions in a matter of minutes. The most common use case involves the application of one-time passwords (OTP) generated by hardware tokens,  authenticator apps or directly sent to the user's mobile phone via SMS messaging.`}</p>
    <p>{`We'll walk you through the following steps:`}</p>
    <ul>
      <li parentName="ul">{`Asking for the phone number`}</li>
      <li parentName="ul">{`Sending a verification code`}</li>
      <li parentName="ul">{`Verifying the code`}</li>
    </ul>
    <p><strong parentName="p">{`Pro-tip:`}</strong>{` Follow this tutorial to build the whole application from scratch or, if you want to see it in action right away, you can download, clone or fork the sample application from our `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/verify-guide-go"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`.`}</p>
    <h2>{`Getting started`}</h2>
    <p>{`Before we get started, let's make sure you've downloaded the latest version of `}<a parentName="p" {...{
        "href": "https://golang.org"
      }}>{`Go`}</a>{`, and the `}<a parentName="p" {...{
        "href": "https://github.com/messagebird/go-rest-api"
      }}>{`MessageBird's REST API package for Go`}</a>{`. We'll build our single-page web with both.`}</p>
    <h2>{`Project setup`}</h2>
    <p>{`First, let's create a folder for your application - you can call it whatever you like. Now, in this folder, let's create the following sub-folders:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`views`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`views/layouts`}</inlineCode></li>
    </ul>
    <p>{`We'll use the following packages from the Go standard library to build our routes and views:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`net/http`}</inlineCode>{`: A HTTP package for building our routes and a simple http server.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`html/template`}</inlineCode>{`: A HTML template library for building views.`}</li>
    </ul>
    <p>{`From the MessageBird Go REST API package, we'll import the following packages:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`github.com/messagebird/go-rest-api`}</inlineCode>{`: The MessageBird core client package.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`github.com/messagebird/go-rest-api`}</inlineCode>{`: The MessageBird Verify package.`}</li>
    </ul>
    <h3>{`Dependencies`}</h3>
    <p>{`To keep things straightforward and get you up and running as fast as possible, we'll use only packages from the Go standard library and the MessageBird Client library.`}</p>
    <h4>{`Create your API Key 🔑`}</h4>
    <p>{`To enable the MessageBird SDK, we need to provide an access key for the API. MessageBird provides keys in `}<em parentName="p">{`live`}</em>{` and `}<em parentName="p">{`test`}</em>{` modes. To get this application running, we’ll need to create and use a live API access key. You can read more about `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/articles/360000670709-What-is-the-difference-between-a-live-key-and-a-test-key-"
      }}>{`the difference between test and live API keys`}</a>{` in our Help Center.`}</p>
    <p>{`Let's create your live API access key. First, go to the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/user/index"
      }}>{`MessageBird Dashboard`}</a>{`; if you have already created an API key it will be shown right there. If you don’t see any key on the Dashboard or if you're unsure whether this key is in `}<em parentName="p">{`live`}</em>{` mode, go to the `}<em parentName="p">{`Developers`}</em>{` section in the MessageBird Dashboard and open the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`API access (REST) tab`}</a>{`. There you can create new API keys and manage your existing ones.`}</p>
    <p>{`If you are having any issues creating your API key, please reach out to `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we’ll make sure to help you out.`}</p>
    <p><strong parentName="p">{`Pro-tip:`}</strong>{` To keep our demonstration code simple, we’ll be saving our API key in `}<inlineCode parentName="p">{`main.go`}</inlineCode>{`; however, hardcoding your credentials in the code is a risky practice that should never be used in production applications. A better method, also recommended by the `}<a parentName="p" {...{
        "href": "https://12factor.net/"
      }}>{`Twelve-Factor App Definition`}</a>{`, is to use environment variables. You can use open source packages such as `}<a parentName="p" {...{
        "href": "https://github.com/joho/godotenv"
      }}>{`GoDotEnv`}</a>{` to read your API key from a `}<inlineCode parentName="p">{`.env`}</inlineCode>{` file into your Go application. Your `}<inlineCode parentName="p">{`.env`}</inlineCode>{` file should be written as follows:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`MESSAGEBIRD_API_KEY=YOUR-API-KEY
`}</code></pre>
    <p>{`To use `}<a parentName="p" {...{
        "href": "https://github.com/joho/godotenv"
      }}>{`GoDotEnv`}</a>{` in your application, install it by running:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`go get -u github.com/joho/godotenv
`}</code></pre>
    <p>{`Then, import it in your application:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`import (
 // Other imported packages
 "os"

 "github.com/joho/godotenv"
)

func main(){
 // GoDotEnv loads any ".env" file located in the same directory as main.go
 err := godotenv.Load()
 if err != nil {
   log.Fatal("Error loading .env file")
 }

 // Store the value for the key "MESSAGEBIRD_API_KEY" in the loaded '.env' file.
 apikey := os.Getenv("MESSAGEBIRD_API_KEY")

 // The rest of your application ...
}
`}</code></pre>
    <h2>{`Initialize the MessageBird Client`}</h2>
    <p>{`Let's now install the `}<a parentName="p" {...{
        "href": "https://github.com/messagebird/go-rest-api"
      }}>{`MessageBird's REST API package for Go`}</a>{` by running:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`go get -u github.com/messagebird/go-rest-api
`}</code></pre>
    <p>{`In your project folder which we created earlier, let's create a `}<inlineCode parentName="p">{`main.go`}</inlineCode>{` file, and write the following code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`package main

import (
 "github.com/messagebird/go-rest-api"
)

func main(){
 client := messagebird.New(<enter-your-apikey>)
}
`}</code></pre>
    <h3>{`Routes`}</h3>
    <p>{`To make the structure of our web application clear, we'll first stub the routes that we need. We need three routes:`}</p>
    <ul>
      <li parentName="ul">{`Step 1: A route to ask for the user's phone number.`}</li>
      <li parentName="ul">{`Step 2: A route for the user to enter a verification code.`}</li>
      <li parentName="ul">{`Step 3: A route to show if the verification code is valid.`}</li>
    </ul>
    <p>{`To write these routes, we need to modify `}<inlineCode parentName="p">{`main.go`}</inlineCode>{` to look like the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`package main

import (
 "fmt"
 "net/http"

 "github.com/messagebird/go-rest-api"
)

func step1(w http.ResponseWriter, r *http.Request){
 // Do nothing
 fmt.Fprintln("This is step 1")
}

func step2(w http.ResponseWriter, r *http.Request){
 // Do nothing
 fmt.Fprintln("This is step 2")
}

func step3(w http.ResponseWriter, r *http.Request){
 // Do nothing
 fmt.Fprintln("This is step 3")
}

func main(){
 client := messagebird.New(<enter-your-apikey>)

 http.HandleFunc("/",step1)
 http.HandleFunc("/step2",step2)
 http.HandleFunc("/step3",step3)

 err := http.ListenAndServe(":8080",nil)
 if err != nil {
   panic(err)
 }
}
`}</code></pre>
    <p>{`We've defined an HTTP handler for each route and assigned each handler to a corresponding URL path. At the bottom of our `}<inlineCode parentName="p">{`main()`}</inlineCode>{` function, we start a HTTP server on port 8080.`}</p>
    <p><strong parentName="p">{`Pro-tip:`}</strong>{` Here we’re using `}<inlineCode parentName="p">{`http.ListenAndServe`}</inlineCode>{` to start our HTTP server, but you may want to use `}<inlineCode parentName="p">{`http.ListenAndServeTLS`}</inlineCode>{` to secure your application's communication over the web with TLS/SSL.`}</p>
    <h3>{`Views`}</h3>
    <p>{`Now that we've got our routes set up, we can start writing templates to render for each route. We'll be defining four views:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`views/layouts/default`}</inlineCode>{`: The base HTML template we will use with all our views`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`views/step1`}</inlineCode>{`: View for step 1`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`views/step2`}</inlineCode>{`: View for step 2`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`views/step3`}</inlineCode>{`: View for step 3`}</li>
    </ul>
    <p>{`Let's create the following files in your project folder:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`views/layouts/default.gohtml`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`views/step1.gohtml`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`views/step2.gohtml`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`views/step3.gohtml`}</inlineCode></li>
    </ul>
    <p>{`The `}<inlineCode parentName="p">{`.gohtml`}</inlineCode>{` files will contain our Go HTML template code for each of our views, and our "default" base template.`}</p>
    <p>{`First, we'll write the base template. In `}<inlineCode parentName="p">{`default.gohtml`}</inlineCode>{`, write the following code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`{{ define "default" }}
<!DOCTYPE html>
 <head>
   <meta charset="utf-8">
   <meta http-equiv="X-UA-Compatible" content="IE=edge">
   <title>MessageBird Verify Example</title>
   <meta name="description" content="">
   <meta name="viewport" content="width=device-width, initial-scale=1">
   <link rel="stylesheet" href="" type="text/css"/>
 </head>
 <body>
   <main class="">
   <h1>MessageBird Verify Example</h1>
   {{ template "yield" . }}
   </main>
 </body>
</html>
{{ end }}
`}</code></pre>
    <p>{`Notice that in `}<inlineCode parentName="p">{`default.gohtml`}</inlineCode>{`, we’re calling `}<inlineCode parentName="p">{`{{ template "yield" . }}`}</inlineCode>{`. This tells the Go templates package that the contents of any template named "yield" should be rendered in its place when the template is executed.`}</p>
    <p>{`Next, modify the `}<inlineCode parentName="p">{`import`}</inlineCode>{` call in `}<inlineCode parentName="p">{`main.go`}</inlineCode>{` to add our HTML template package:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`import (
 "fmt"
 "net/http"
 "html/template"

 "github.com/messagebird/go-rest-api"
)
`}</code></pre>
    <h2>{`Asking for the phone number`}</h2>
    <p>{`The first step in verifying a user's phone number is asking them to provide their phone number. Let's do exactly this by writing the following code in `}<inlineCode parentName="p">{`step1.gohtml`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`{{ define "yield" }}
<p>Please enter your phone number (in international format, starting with +) to receive a verification code:</p>
<form method="post" action="/step2">
   <input type="tel" name="number" />
   <input type="submit" value="Send code" />
</form>
{{ end }}
`}</code></pre>
    <p>{`In `}<inlineCode parentName="p">{`step1.gohtml`}</inlineCode>{` we see that our template is defined as "yield", allowing `}<inlineCode parentName="p">{`default.gohtml`}</inlineCode>{` to render it when both templates are executed together by calling the `}<inlineCode parentName="p">{`ExecuteTemplate()`}</inlineCode>{` method.`}</p>
    <p>{`To get the view to render, open `}<inlineCode parentName="p">{`main.go`}</inlineCode>{` and rewrite the `}<inlineCode parentName="p">{`step1()`}</inlineCode>{` handler function:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`func step1(w http.ResponseWriter, r *http.Request) {
  t, err := template.ParseFiles("views/step1.gohtml","views/layouts/default.gohtml")
  if err != nil {
    log.Fatal(err)
  }
  err = t.ExecuteTemplate(w, "default", nil)
  if err != nil {
    log.Fatal(err)
 }
}
`}</code></pre>
    <p>{`We tell the `}<inlineCode parentName="p">{`step1()`}</inlineCode>{` handler to read both the "views/step1.gohtml" and "views/layouts/default.gohtml" template files. Then we call the `}<inlineCode parentName="p">{`ExecuteTemplate`}</inlineCode>{` method, telling it to render the "default" template.`}</p>
    <h2>{`Writing a RenderDefaultTemplate() function`}</h2>
    <p>{`At this point, we know that the handlers for all three steps must:`}</p>
    <ul>
      <li parentName="ul">{`Parse the `}<inlineCode parentName="li">{`default.gohtml`}</inlineCode>{` template file and one other template file.`}</li>
      <li parentName="ul">{`Execute the template.`}</li>
      <li parentName="ul">{`Handle errors from the `}<inlineCode parentName="li">{`ParseFiles()`}</inlineCode>{` and `}<inlineCode parentName="li">{`ExecuteTemplate()`}</inlineCode>{` methods.`}</li>
    </ul>
    <p>{`We can write a `}<inlineCode parentName="p">{`RenderDefaultTemplate()`}</inlineCode>{` helper function to make these calls, keeping our code DRY:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`func RenderDefaultTemplate(w http.ResponseWriter,thisView string){
 // Create an array of strings to contain our list of templates to render.
 renderthis := []string{thisView, "views/layouts/default.gohtml"}
  t, err := template.ParseFiles(renderthis...)
  if err != nil {
    log.Fatal(err)
  }
  err = t.ExecuteTemplate(w, "default", nil)
  if err != nil {
    log.Fatal(err)
  }
}
`}</code></pre>
    <p>{`Then, we can go ahead and re-write all our handlers in `}<inlineCode parentName="p">{`main.go`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`func step1(w http.ResponseWriter, r *http.Request){
 RenderDefaultTemplate(w,"views/step1.gohtml")
}

func step2(w http.ResponseWriter, r *http.Request){
 RenderDefaultTemplate(w,"views/step2.gohtml")
}

func step3(w http.ResponseWriter, r *http.Request){
 RenderDefaultTemplate(w,"views/step3.gohtml")
}
`}</code></pre>
    <h2>{`Sending a verification code`}</h2>
    <p>{`Once we've collected the number, we can send a verification message to the user's mobile device. The MessageBird Verify API takes care of generating a random token, so you don't have to do this yourself. Codes are numeric and six digits by default; if you want to customize the length of the code or configure other options, you can check out our `}<a parentName="p" {...{
        "href": "/api/verify#verify-request"
      }}>{`Verify API documentation`}</a>{`.`}</p>
    <p>{`Next, we need a page with `}<inlineCode parentName="p">{`step2()`}</inlineCode>{` for our user to enter their verification code.`}</p>
    <p>{`Let's write the following code in `}<inlineCode parentName="p">{`step2.gohtml`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`{{ define "yield" }}
<p>We have sent you a verification code!</p>
<p>Please enter the code here:</p>
<form method="post" action="/step3">
   <input type="text" name="token" />
   <input type="submit" value="Check code" />
</form>
{{ end }}
`}</code></pre>
    <p>{`Then, modify `}<inlineCode parentName="p">{`step2()`}</inlineCode>{` in `}<inlineCode parentName="p">{`main.go`}</inlineCode>{` to look like the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`func step2(w http.ResponseWriter, r *http.Request) {
  var err error

  r.ParseForm()
  num := r.FormValue("number")
  clientVerify, err = client.NewVerify(num, nil)
  if err != nil {
    log.Println(err)
  }
  RenderDefaultTemplate(w, "views/step2.gohtml")
}
`}</code></pre>
    <p>{`In the `}<inlineCode parentName="p">{`step2()`}</inlineCode>{` handler, we're using the `}<inlineCode parentName="p">{`ParseForm()`}</inlineCode>{` method to read data submitted through the form in `}<inlineCode parentName="p">{`step1.gohtml`}</inlineCode>{`. We can access these values using the `}<inlineCode parentName="p">{`FormValue(<key>)`}</inlineCode>{` method.`}</p>
    <p>{`Here, we get the user's phone number using `}<inlineCode parentName="p">{`FormValue("number")`}</inlineCode>{`, and pass it into `}<inlineCode parentName="p">{`verify.Create()`}</inlineCode>{`, that sends this data to the MessageBird servers. The MessageBird servers then responds with `}<inlineCode parentName="p">{`verify.Verify`}</inlineCode>{` object that contains information we need for the verification process. Because we want to share the response received from the MessageBird server with the `}<inlineCode parentName="p">{`step3`}</inlineCode>{` handler, we need to create two global variables in `}<inlineCode parentName="p">{`main.go`}</inlineCode>{`.`}</p>
    <p>{`Modify the `}<inlineCode parentName="p">{`import`}</inlineCode>{` statement to look like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`import (
 "html/template"
 "log"
 "net/http"

 "github.com/messagebird/go-rest-api"
 "github.com/messagebird/go-rest-api/verify"
)
`}</code></pre>
    <p>{`Just under our `}<inlineCode parentName="p">{`import`}</inlineCode>{` statement in `}<inlineCode parentName="p">{`main.go`}</inlineCode>{`, create the following variables:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`import (
 //...
)

var (
  // Stores the MessageBird Client object
 client       *messagebird.Client
 // Stores the Verify object received from the MessageBird servers
  clientVerify *verify.Verify
)

// The rest of the application...
`}</code></pre>
    <p>{`Then, modify `}<inlineCode parentName="p">{`step2()`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`func step2(w http.ResponseWriter, r *http.Request) {
  var err error

  r.ParseForm()
  num := r.FormValue("number")
  clientVerify, err = verify.Create(client, num, nil)
  if err != nil {
    log.Println(err)
  }
  RenderDefaultTemplate(w, "views/step2.gohtml")
}
`}</code></pre>
    <p>{`And, finally, modify `}<inlineCode parentName="p">{`main()`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`func main() {
 client = messagebird.New(<enter-your-apikey>)

 // The rest of your applicaiton...
}
`}</code></pre>
    <h2>{`Verifying the code`}</h2>
    <p>{`If everything runs smoothly, the user will check their phone, enter their verification code and submit the form. We now need to send the verification token along with the ID received in the `}<inlineCode parentName="p">{`clientVerify`}</inlineCode>{` object in `}<inlineCode parentName="p">{`step2()`}</inlineCode>{`.`}</p>
    <p>{`In `}<inlineCode parentName="p">{`step3.gohtml`}</inlineCode>{`, let's write the following code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`{{ define "yield" }}
   <h1>Done!</h1>
   <p>You have successfully verified your phone number.</p>
{{ end }}
`}</code></pre>
    <p>{`Then, modify `}<inlineCode parentName="p">{`step3()`}</inlineCode>{` in `}<inlineCode parentName="p">{`main.go`}</inlineCode>{` to look like the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`func step3(w http.ResponseWriter, r *http.Request) {
  r.ParseForm()
  token := r.FormValue("token")
  clientVerifyDone, err := verify.VerifyToken(client, clientVerify.ID, token)
  if err != nil {
    log.Println(err)
  }
  // Execute template and pass verify.Status as a variable into the step3.gohtml template.
  RenderDefaultTemplate(w, "views/step3.gohtml")
}
`}</code></pre>
    <p>{`In `}<inlineCode parentName="p">{`step3()`}</inlineCode>{`, we parse the form data submitted in `}<inlineCode parentName="p">{`step2`}</inlineCode>{` for the verification token, and send the token and the verification ID that we've stored in `}<inlineCode parentName="p">{`clientVerify`}</inlineCode>{` to the MessageBird servers with `}<inlineCode parentName="p">{`verify.VerifyToken(client, clientVerify.ID, token)`}</inlineCode>{`.`}</p>
    <h2>{`Displaying success state (optional)`}</h2>
    <p>{`When we submit the verification token, the MessageBird servers responds with another `}<inlineCode parentName="p">{`Verify`}</inlineCode>{` object, which we store in `}<inlineCode parentName="p">{`clientVerifyDone`}</inlineCode>{`. If the token is successfully verified, then the value of `}<inlineCode parentName="p">{`clientVerifyDone.Status`}</inlineCode>{` would be "verified". We can use this to display a success state in `}<inlineCode parentName="p">{`step3.gohtml`}</inlineCode>{`.`}</p>
    <p>{`To do this, we have to alter our `}<inlineCode parentName="p">{`RenderDefaultTemplate()`}</inlineCode>{` function to allow us to pass data into the `}<inlineCode parentName="p">{`ExecuteTemplate()`}</inlineCode>{` method.`}</p>
    <p>{`In `}<inlineCode parentName="p">{`main.go`}</inlineCode>{`, modify `}<inlineCode parentName="p">{`RenderDefaultTemplate()`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`func RenderDefaultTemplate(w http.ResponseWriter, thisView string, data interface{}) {
 // ...
  err = t.ExecuteTemplate(w, "default", data)
  if err != nil {
    log.Fatal(err)
  }
}
`}</code></pre>
    <p>{`Then, modify the `}<inlineCode parentName="p">{`RenderDefaultTemplate()`}</inlineCode>{` calls in our handlers to look like the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`func step1(...){
 // ...
 RenderDefaultTemplate(w,"views/step1.gohtml",nil)
}
func step2(...){
 // ...
 RenderDefaultTemplate(w,"views/step1.gohtml",nil)
}
func step3(...){
 // ...
 RenderDefaultTemplate(w,"views/step3.gohtml",clientVerifyDone)
}
`}</code></pre>
    <p>{`This allows the `}<inlineCode parentName="p">{`step3.gohtml`}</inlineCode>{` template to access the data we pass through our `}<inlineCode parentName="p">{`RenderDefaultTemplate`}</inlineCode>{` function. We then need to do the following:`}</p>
    <ol>
      <li parentName="ol">{`Write a struct that we’ll pass into the `}<inlineCode parentName="li">{`RenderDefaultTemplate`}</inlineCode>{` function.`}</li>
      <li parentName="ol">{`The struct should contain one attribute, "Success", that is set to either `}<inlineCode parentName="li">{`true`}</inlineCode>{` or `}<inlineCode parentName="li">{`false`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`If token verification is successful, "Success" should be set to `}<inlineCode parentName="li">{`true`}</inlineCode>{`. Otherwise, it should be set to `}<inlineCode parentName="li">{`false`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`Modify `}<inlineCode parentName="li">{`step3.gohtml`}</inlineCode>{` to display a success state if token verification is successful.`}</li>
    </ol>
    <p>{`Now, let's modify `}<inlineCode parentName="p">{`step3()`}</inlineCode>{` in `}<inlineCode parentName="p">{`main.go`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`func step3(w http.ResponseWriter, r *http.Request) {
 // ...
 type successState struct {
   Success bool
 }
 var s successState

 if clientVerifyDone.Status == "verified" {
   s = successState{Success: true}
 } else {
   s = successState{Success: false}
 }
  RenderDefaultTemplate(w, "views/step3.gohtml", s)
}
`}</code></pre>
    <p>{`Then, rewrite `}<inlineCode parentName="p">{`step3.gohtml`}</inlineCode>{` so it looks like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`{{ define "yield" }}
 {{ if .Success }}
   <h1>Done!</h1>
   <p>You have successfully verified your phone number.</p>
 {{ else }}
   <h1>Error</h1>
   <p>Something went wrong. Please try again</p>
 {{ end }}
{{ end }}
`}</code></pre>
    <h2>{`Testing`}</h2>
    <p>{`You're done! To check if your application works, go to your project folder in your terminal and run:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`go build main.go
./main
`}</code></pre>
    <p>{`Then, point your browser to `}<a parentName="p" {...{
        "href": "http://localhost:8080/"
      }}>{`http://localhost:8080/`}</a>{` and try to verify your own phone number.`}</p>
    <p>{`Awesome! You can now leverage the flow, code snippets and UI examples from this tutorial to build your own two factor authentication system. Don't forget to download the code from the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/verify-guide-go"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`.`}</p>
    <p><strong parentName="p">{`Nice work!`}</strong>{` 🎉`}</p>
    <p>{`You now have a running integration of MessageBird's Verify API using Go!`}</p>
    <h2>{`Start building!`}</h2>
    <p>{`Want to build something similar but not quite sure how to get started? Please feel free to let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`;  we'd love to help!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      