import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Conversations`}</h1>
    <p>{`The MessageBird Conversations API is an omnichannel messaging solution to unify sent and received messages across all of your chat channels in one conversation thread. Conversations is the view of all messages between the provider and customer across any configured channels-—SMS, WhatsApp, Live Chat, WeChat, Messenger, Telegram, LINE, Instagram, Email, Google Business Messages, Twitter, and Viber. `}</p>
    <p>{`The Conversations API uses `}<inlineCode parentName="p">{`HTTP verbs`}</inlineCode>{` and a `}<inlineCode parentName="p">{`RESTful`}</inlineCode>{` endpoint structure with an access key that is used as the API Authorization. Request and response payloads are formatted as JSON using UTF-8 encoding and URL encoded values.`}</p>
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'Install channel: SMS',
      to: 'docs/conversations/install-channel/'
    }, {
      name: 'Install channel: WhatsApp',
      to: 'docs/whatsapp/install-channel-whatsapp/'
    }, {
      name: 'Conversations API Reference',
      to: '/api/conversations/'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      