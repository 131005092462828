import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/ApiRef.js";
import Helmet from 'react-helmet';
import CodeSnippet from 'components/CodeSnippet';
import Notification from 'components/Notification';
import { APIWrapper, LeftColumn, RightColumn } from 'components/ApiRef';
import * as CODE from 'constants/partner';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Helmet mdxType="Helmet">
      <meta name="robots" content="noindex" />
    </Helmet>
    <h1>{`Partner Accounts`}</h1>
    <p>{`MessageBird’s Partner Accounts API allows you to programmatically set up a `}<strong parentName="p">{`Partner Network`}</strong>{` within your own software or cloud service. You can manage and control the delivery of MessageBird’s products, as well as the consumption and spend between different customer accounts. `}</p>
    <p>{`The Partner Accounts API uses `}<inlineCode parentName="p">{`HTTP verbs`}</inlineCode>{` and a `}<inlineCode parentName="p">{`RESTful`}</inlineCode>{` endpoint structure with an access key that is used as the API Authorization. Request and response payloads are formatted as JSON using UTF-8 encoding and URL encoded values.`}</p>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`API Endpoint`}</h2>
        <p>{`All URLs referenced in MessageBird's Partner Accounts API reference have the base URL in the code snippet on the right side.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>API Endpoint</h4>
  <CodeSnippet code={CODE.API_ENDPOINT} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <h2>{`Partner Accounts`}</h2>
    <p>{`A partner is a company that resells MessageBird’s products to its own customers. MessageBird’s `}<strong parentName="p">{`Partner Accounts`}</strong>{` are those which have the capability to create `}<strong parentName="p">{`Child Accounts`}</strong>{`.`}</p>
    <hr></hr>
    <h2>{`Child Accounts`}</h2>
    <p>{`MessageBird’s Child Accounts are regular accounts that are "owned" by your Partner Account. By using a Child Account, you can segment each of your customers to use MessageBird’s products and keep it separate from all the rest. This will allow you to easily manage the activity and resources of each customer independently.`}</p>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Authorization`}</h2>
        <p>{`In order to use the APIs of the MessageBird Connectivity Platform, you need to have an account and log in to `}<a parentName="p" {...{
            "href": "https://dashboard.messagebird.com/en/login/"
          }}>{`the Dashboard of the Connectivity Platform`}</a>{`.`}</p>
        <p>{`You'll need to set an access key to authenticate yourself. You can create, manage, and retrieve your API keys (both test and live) from the `}<a parentName="p" {...{
            "href": "https://dashboard.messagebird.com/en/developers/access"
          }}>{`MessageBird Dashboard`}</a>{`.`}</p>
        <p>{`To provide the access key, set the `}<inlineCode parentName="p">{`Authorization`}</inlineCode>{` header in the form of `}<inlineCode parentName="p">{`AccessKey {accessKey}`}</inlineCode>{`.`}</p>
        <p>{`You have to have a privilege to be able to access the MessageBird’s `}<strong parentName="p">{`Partner Accounts`}</strong></p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Authorization error (403):</h4>
  <CodeSnippet code={CODE.AUTHORIZATION_ERROR} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <h2>{`Formats and Headers`}</h2>
    <p>{`All API responses are in JSON.`}</p>
    <p>{`POST and PUT requests to the API must contain a JSON-formatted payload in the request body.`}</p>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <hr></hr>
        <h2>{`Errors`}</h2>
        <p>{`MessageBird uses standard HTTP status codes to indicate success or failure of an API request. Codes in the 2xx range indicate that a request was successfully processed and codes in the 4xx range indicate that there was an error that resulted from the information sent (e.g. authentication, a missing or wrong parameter).`}</p>
        <p>{`In the case of an error, the body of the response includes a JSON-formatted response that tells you exactly what is wrong.`}</p>
        <p>{`MessageBird uses standard HTTP status codes to indicate success or failure of an API request:`}</p>
        <ul>
          <li parentName="ul">{`Codes in the `}<inlineCode parentName="li">{`2xx`}</inlineCode>{` range indicate that a request was processed successfully`}</li>
          <li parentName="ul">{`The `}<inlineCode parentName="li">{`4xx`}</inlineCode>{` range indicates that there was an error - for example, due to authentication, a missing or wrong parameter. Don't worry, the body of the response includes a JSON-formatted response that tells you exactly what is wrong. If you're stuck, feel free to `}<a parentName="li" {...{
              "href": "https://www.messagebird.com/en/contact"
            }}>{`contact support`}</a>{`; we're happy to help you out.`}</li>
          <li parentName="ul">{`A `}<inlineCode parentName="li">{`5xx`}</inlineCode>{` status code indicates that something went wrong on our end.`}</li>
        </ul>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Error Object Example</h4>
  <CodeSnippet code={CODE.ERROR_EXAMPLE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <h3>{`Attributes`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A type error definition.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`title`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A human-readable title of the error.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`detail`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`What kind of error occurred.`}</td>
        </tr>
      </tbody>
    </table>
    <hr></hr>
    <h3>{`Create a Child Account`}</h3>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <p>{`Create a new `}<strong parentName="p">{`Child Accounts`}</strong>{` which will be related to the `}<strong parentName="p">{`Partner Account`}</strong>{` that the access key belongs to.`}</p>
        <h4>{`Parameters:`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Attribute`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`required`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`name`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The name of the subaccount. This name will be displayed in the invoices as well.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Yes`}</td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
Definition:
        <CodeSnippet code={CODE.POST_DEFINITION} mdxType="CodeSnippet" />
Request:
        <CodeSnippet code={CODE.CREATE_REQUEST} mdxType="CodeSnippet" />
Response:
        <CodeSnippet code={CODE.CREATE_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <h3>{`Edit a specific Child Account`}</h3>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <p>{`Edit a Child Account related to the Partner Account that the access key belongs to.`}</p>
        <h4>{`Parameters:`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Attribute`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`required`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`name`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The name of the subaccount. This name will be displayed in the invoices as well.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Yes`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`id`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Subaccount Id.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Yes`}</td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
Definition:
        <CodeSnippet code={CODE.EDIT_DEFINITION} mdxType="CodeSnippet" />
Request:
        <CodeSnippet code={CODE.EDIT_REQUEST} mdxType="CodeSnippet" />
Response:
        <CodeSnippet code={CODE.EDIT_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <a name="fetch-subaccount-details"></a>
    <hr></hr>
    <h3>{`Fetch a specific Child Account`}</h3>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <p>{`This endpoint will retrieve the complete data from the Child Account that has been provided.`}</p>
        <h4>{`Parameters:`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Attribute`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`required`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`id`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Subaccount Id.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Yes`}</td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
Definition:
        <CodeSnippet code={CODE.GET_DEFINITION} mdxType="CodeSnippet" />
Request:
        <CodeSnippet code={CODE.GET_REQUEST_SINGLE} mdxType="CodeSnippet" />
Response:
        <CodeSnippet code={CODE.GET_RESPONSE_SINGLE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <h3>{`Fetch all the Child Accounts`}</h3>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <p>{`This endpoint will retrieve limited data for all Child Accounts. The default and  maximum number of Child Accounts' items per page is 100.
You can decrease that number by using the `}<inlineCode parentName="p">{`limit`}</inlineCode>{` parameter.
If you want to fetch the details for a single Child Account, you can use `}<a parentName="p" {...{
            "href": "/api/partner/#fetch-subaccount-details"
          }}>{`Fetch Subaccount Details`}</a></p>
        <h4>{`Parameters:`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Attribute`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`required`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`page`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Page`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`limit`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Limit, number of items per page (Default/Max. 100)`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
Definition:
        <CodeSnippet code={CODE.GET_ALL_DEFINITION} mdxType="CodeSnippet" />
Request:
        <CodeSnippet code={CODE.GET_REQUEST_ALL} mdxType="CodeSnippet" />
Response:
        <CodeSnippet code={CODE.GET_RESPONSE_ALL} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <h3>{`Delete Child Account`}</h3>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <p>{`Delete the Child account, the token will be no longer valid. Once the Child Account has been deleted, it won’t be able to use the services anymore.`}</p>
        <h4>{`Parameters:`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Attribute`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`required`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`id`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Subaccount Id.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Yes`}</td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
Definition:
        <CodeSnippet code={CODE.DELETE_DEFINITION} mdxType="CodeSnippet" />
Request:
        <CodeSnippet code={CODE.DELETE_REQUEST} mdxType="CodeSnippet" />
Response:
        <CodeSnippet code={CODE.DELETE_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <h3>{`Reporting`}</h3>
    <p>{`You can use the `}<a parentName="p" {...{
        "href": "/api/reporting"
      }}>{`Reporting APIs`}</a>{` to fetch consumption data for respective subaccount.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      