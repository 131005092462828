import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import Notification from 'components/Notification';
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Message Tags`}</h1>
    <p>{`Message tags allow messages to be marked with a particular tag. The meaning and effect of each tag depend on each
specific platform. Using Conversations API, it's possible to set a MessageBird Message Tag, which will be converted to
a platform-specific message tag.`}</p>
    <p>{`The table below displays the correspondence between MessageBird Message Tags and platform-specific message tags.`}</p>
    <Notification type="INFO" mdxType="Notification">
    Currently message tags are only supported by Facebook Messenger.
    </Notification>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`MessageBird Message Tag`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Facebook Message Tag`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Platform-specific Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`event.update`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`CONFIRMED_EVENT_UPDATE`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Send the user reminders or updates for an event they have registered for (e.g., RSVP'ed, purchased tickets).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`purchase.update`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`POST_PURCHASE_UPDATE`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Notify the user of an update on a recent purchase.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`account.update`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ACCOUNT_UPDATE`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Notify the user of a non-recurring change to their application or account.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`human_agent`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`HUMAN_AGENT`}</inlineCode>{` (Closed BETA) `}<a parentName="td" {...{
              "href": "https://www.facebook.com/help/contact/?id=2616212338594331"
            }}>{`Apply for access`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Allows human agents to respond to user inquiries. Messages can be sent within 7 days after a user message.`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`More details about Facebook Message Tags are available here: `}<a parentName="p" {...{
        "href": "https://developers.facebook.com/docs/messenger-platform/send-messages/message-tags/"
      }}>{`https://developers.facebook.com/docs/messenger-platform/send-messages/message-tags/`}</a></p>
    <p>{`Here's an example of HTTP request you can perform to the Conversations API `}<inlineCode parentName="p">{`POST /v1/send`}</inlineCode>{` endpoint using the tag
`}<inlineCode parentName="p">{`account.update`}</inlineCode>{`, which corresponds to the Facebook `}<inlineCode parentName="p">{`ACCOUNT_UPDATE`}</inlineCode>{` message tag.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-curl"
      }}>{`    curl -X "POST" "https://conversations.messagebird.com/v1/send" \\
         -H 'Authorization: AccessKey <access-key>' \\
         -H 'Content-Type: application/json; charset=utf-8' \\
         -d $'{
      "content": {
        "text": "Sample message using a tag"
      },
      "to": "107998841006481",
      "type": "text",
      "tag": "account.update",
      "from": "8145h20547u54cb7bcde4d5c0bb40a35"
    }'
`}</code></pre>
    <p>{`A few details about the HTTP request above:`}</p>
    <ul>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`to`}</inlineCode>{` must contain a valid Facebook recipient identifier, or an existing contact ID`}</li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`tag`}</inlineCode>{` must contain one of the aforementioned MessageBird message tags. You can't use platform-specific
message tags. For example, `}<inlineCode parentName="li">{`ACCOUNT_UPDATE`}</inlineCode></li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`from`}</inlineCode>{` must contain a valid Facebook channel ID`}</li>
    </ul>
    <p>{`The HTTP response looks like the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`    {
      "id": "7d9b3s4f2e3g44ab5cd72a31c133c5c1",
      "status": "accepted",
      "fallback": null
    }
`}</code></pre>
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'Conversations API',
      to: '/api/conversations'
    }, {
      name: 'Sending Messages',
      to: '/docs/conversations/send-messages-curl'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      