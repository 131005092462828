import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import Questions from 'components/Footer/Questions';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Notifications Overview`}</h1>
    <p>{`MessageBird allows you to customize and setup different mediums through which you will receive notifications for our various products.`}</p>
    <p>{`You can configure these notification settings directly in your `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/notifications"
      }}>{`Notifications Center in the Dashboard.`}</a></p>
    <p>{`For notifications, you can setup multiple emails and webhooks in each MessageBird product available in the Notifications Center, and then subscribe to specific events/notification types.`}</p>
    <h2>{`Email`}</h2>
    <p>{`To setup email notifications, just supply the emails under the product that you would like to receive those notifications on. Emails will be received from a no-reply email address `}<inlineCode parentName="p">{`notifications@messagebird.com`}</inlineCode>{`.`}</p>
    <h2>{`Webhook`}</h2>
    <p>{`View our guide to notification webhooks `}<a parentName="p" {...{
        "href": "/quickstarts/notifications-webhook"
      }}>{`here`}</a>{`.`}</p>
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      