export const EXAMPLE1 = {
  bash: `
    "id": "77625cbc0fc6494982ff042d38c63985",
    "name": "testing",
    "alphaSender": null,
    "encodingHelper": true,
    "statusUrl": null,
    "inboundSmsUrl": "http://www.example.com/receive",
    "inboundSmsMethod": "post",
    "numbers": {
      "totalCount": 0,
      "href": "https://rest.messagebird.com/navigators/77625cbc0fc6494982ff042d38c63985/phonenumbers"
    },
    "createDatetime": "2018-01-07T13:00:36+00:00",
    "updateDatetime": null
  }`,
};

export const EXAMPLE2 = {
  bash: `{
    "number": "12025550114",
    "countryCode": "US",
    "region": null,
    "locality": null,
    "features": ["sms", "voice"],
    "type": "mobile",
    "status": "active"
}`,
};
