import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Fetch variables in Flow Builder`}</h1>
    <h3>{`⏱ 4 min read`}</h3>
    <p>{`In this MessageBird Developer tutorial you’ll learn how to use the `}<inlineCode parentName="p">{`Fetch Variables`}</inlineCode>{` step in Flow Builder to fetch variables from external APIs.`}</p>
    <h3>{`Fetch variables step`}</h3>
    <p>{`This step makes an HTTP request to a URL and assigns the response to variables in Flow Builder. If you are not interested in the response body and you would like to call an external endpoint, you can use `}<a parentName="p" {...{
        "href": "https://developers.messagebird.com/tutorials/http-request-in-flow-builder"
      }}>{`HTTP Request step`}</a>{` instead.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/flows/fetch-variables-step.png",
        "alt": "Fetch variables"
      }}></img></p>
    <h3>{`Fetch variables step options`}</h3>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/flows/fetch-variables-options.png",
        "alt": "Fetch variables options"
      }}></img></p>
    <h3>{`URL`}</h3>
    <p>{`URL address to your website or some external HTTP endpoint`}</p>
    <p>{`Expected value: A hardcoded URL (starting with http:// or https://) or a variable. If a variable is passed, validation will happen during step execution.`}</p>
    <h4>{`Method`}</h4>
    <p>{`The HTTP request method used for the request.
Please read `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods"
      }}>{`HTTP request methods documentation`}</a>{` for more information. `}</p>
    <p>{`Expected value: one of the following: GET, POST, PUT, DELETE, PATCH, HEAD`}</p>
    <h4>{`OAuth Integration (Optional)`}</h4>
    <p>{`Flowbuilder supports OAuth2 authorization to be used at the Fetch Variables step. Your OAuth2 integrations will be listed in this dropdown so that you can include them in the request.`}</p>
    <p>{`Expected value: Empty, or one of the integrations listed in the dropdown.`}</p>
    <h3>{`Body`}</h3>
    <p>{`You can define the body of your requests with this option. This option is used together with the “Set Content-Type header” field so that the receiver of your request will be able to decode it correctly.`}</p>
    <p>{`Expected value: this value is not validated. Please make sure to check that the body and content-type of the request is valid for the HTTP endpoint you are planning to fetch the variables from.
Please read `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Type"
      }}>{`HTTP Content-Type header documentation`}</a>{` for more information.  `}</p>
    <h3>{`Headers`}</h3>
    <p>{`This is a dictionary of key-value pairs. The key is header name and the value is the header value used for sending HTTP requests.
To know more about HTTP Headers and their purpose please read `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers"
      }}>{`HTTP headers documentation`}</a>{`.`}</p>
    <h3>{`Variables`}</h3>
    <p>{`You can set a variable name in order to use the variable in later steps. Make sure the variable names match the keys on the JSON object in the response.
Eg. for a JSON response as follows`}</p>
    <pre><code parentName="pre" {...{}}>{`{ 
    "name": "Example user", 
    "zip_codes": ["31123", "31125"], 
    "billing": { "bank": "ABN AMRO" } 
}
`}</code></pre>
    <p>{`You can define `}<inlineCode parentName="p">{`name`}</inlineCode>{`, `}<inlineCode parentName="p">{`zip_codes[0]`}</inlineCode>{`, `}<inlineCode parentName="p">{`zip_codes[1]`}</inlineCode>{` and `}<inlineCode parentName="p">{`billing.bank`}</inlineCode>{` variable names, which after the fetch variables step have the values `}<inlineCode parentName="p">{`"Example user"`}</inlineCode>{`, `}<inlineCode parentName="p">{`31123`}</inlineCode>{`, `}<inlineCode parentName="p">{`31125`}</inlineCode>{`, `}<inlineCode parentName="p">{`ABN AMRO`}</inlineCode>{`, respectively.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      