import React from 'react';
import styled from 'styled-components';
// @ts-ignore
import Cookies from 'js-cookie';
import { NextUp } from './NextUp';
import Feedback from '../../Feedback/index';
import QuestionsSection from './QuestionsSection';

const availableLanguages = ['en', 'nl', 'es', 'id', 'fr', 'pt_BR'];
const cookiesLanguage = Cookies.get('lang');
const isLanguageAvailable = availableLanguages.indexOf(cookiesLanguage) !== -1;
const lang = isLanguageAvailable ? cookiesLanguage : 'en';

const FooterWrap = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 45px auto 0;
  max-width: 1008px;
  margin-top: 40px;
  margin-bottom: 72px;
`;

const Copyright = styled.span`
  text-align: center;
  color: #9aa8bd;
  font-size: 14px;
  line-height: 18px;
  margin-left: 20px;
  margin-right: 20px;
`;

const ListItem = styled.a`
  color: #9aa8bd;
  font-size: 16px;
  line-height: 16px;
  margin-left: 20px;
  margin-right: 20px;
  @media screen and (max-width: 767px) {
    margin-bottom: 20px;
  }
`;
const PageBottom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  @media (max-width: 767px) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
`;
const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const StyledFeedback = styled(Feedback)`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`;

const ContentPageBottom = (): JSX.Element => (
  <PageBottom>
    <StyledFeedback />
    <NextUp />
  </PageBottom>
);

const ContentPageLinks = (): JSX.Element => (
  <FooterWrap>
    <ListItem href={`https://www.messagebird.com/${lang}/legal/terms/`}>
      Terms of service
    </ListItem>
    <ListItem href={`https://www.messagebird.com/${lang}/legal/privacy/`}>
      Privacy policy
    </ListItem>
    <Copyright>
      &copy; {new Date().getFullYear()} by{' '}
      <a href="https://messagebird.com">MessageBird</a>. All rights reserved.
    </Copyright>
  </FooterWrap>
);
export const ContentPageFooter = (): JSX.Element => (
  <FooterWrapper>
    <ContentPageBottom />
    <QuestionsSection />
    <ContentPageLinks />
  </FooterWrapper>
);
