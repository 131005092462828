import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import styled, { keyframes } from 'styled-components';
import { Flex, Box } from '@rebass/grid';
import Helmet from 'react-helmet';
import queryString from 'querystring';
import Layout from '../../components/Layout/Default';
import MobileNavigation from '../../components/MobileNavigation';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const LLink = styled(Link)`
  margin: 15px;
  display: inline-block;
  width: calc(33.333333% - 30px);
  @media (max-width: 1023px) {
    width: calc(50% - 30px);
  }
  @media (max-width: 767px) {
    width: calc(100% - 30px);
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 30px 30px 10px;
  box-shadow: 0 3px 15px 0 rgba(113, 150, 193, 0.05), 0px -1px 0 0 #ecf2fc;
  background: #fff;
  border: 1px solid #ecf2fc;
  border-radius: 5px;
  transition: all 0.2s ease;
  border-top: 4px solid #fff;
  animation: ${fadeIn} 0.3s ease;
  &:hover {
    border-top: 4px solid;
    ${({ smsCat }) => smsCat && `border-top-color: #2ECEA8`};
    ${({ voiceCat }) => voiceCat && `border-top-color: #6BD1FF`};
    ${({ verifyCat }) => verifyCat && `border-top-color: #F0AD4E`};
    ${({ startCat }) => startCat && `border-top-color: #B27FF2`};
    ${({ conversationsCat }) =>
      conversationsCat && `border-top-color: #FFC5BC`};
    ${({ flowsCat }) => flowsCat && `border-top-color: #2A2AE6`};
    box-shadow: 0 9px 25px 0 rgba(113, 150, 193, 0.1),
      0 9px 25px -4px rgba(113, 150, 193, 0.1);
  }
`;

const Label = styled.label`
  margin-bottom: 15px;
  font-size: 16px;
  color: #556173;
  font-weight: 600;
  cursor: pointer;
`;

const PP = styled.p`
  color: rgba(36, 55, 78, 0.7);
`;

const Button = styled.button`
  cursor: pointer;
  font-weight: 600;
  background-color: #c3cddd;
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  border-radius: 2px;
  padding: 3px 6px 2px;
  margin-left: 5px;
  border: 0;
  outline: 0;
  transition: all 0.2s ease;
  &:hover {
    background-color: #9aa8be;
    ${({ smsCat }) => smsCat && `background-color: #2ECEA8`};
    ${({ voiceCat }) => voiceCat && `background-color: #6BD1FF`};
    ${({ verifyCat }) => verifyCat && `background-color: #F0AD4E`};
    ${({ startCat }) => startCat && `background-color: #B27FF2`};
    ${({ conversationsCat }) =>
      conversationsCat && `background-color: #FFC5BC`};
    ${({ flowsCat }) => flowsCat && `background-color: #2A2AE6`};
  }
`;

const LangLabel = styled.span`
  color: #fff;
  font-weight: 600;
  padding: 3px 6px 2px;
  font-size: 14px;
  border-radius: 2px;
  margin-bottom: 15px;
  margin-right: 5px;
  background-color: #c3cddd;
  ${({ smsCat }) => smsCat && `background-color: #2ECEA8`};
  ${({ voiceCat }) => voiceCat && `background-color: #6BD1FF`};
  ${({ verifyCat }) => verifyCat && `background-color: #F0AD4E`};
  ${({ startCat }) => startCat && `background-color: #B27FF2`};
  ${({ conversationsCat }) => conversationsCat && `background-color: #FFC5BC`};
  ${({ flowsCat }) => flowsCat && `background-color: #2A2AE6`};
`;

const LangLogo = styled.span`
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  &:not(:last-of-type) {
    margin-right: 5px;
  }
  ${({ curl }) =>
    curl && `background-image: url('/img/icons/codelangs/curl.svg');`};
  ${({ php }) =>
    php && `background-image: url('/img/icons/codelangs/php.svg');`};
  ${({ ruby }) =>
    ruby && `background-image: url('/img/icons/codelangs/ruby.svg');`};
  ${({ go }) => go && `background-image: url('/img/icons/codelangs/go.svg');`};
  ${({ csharp }) =>
    csharp && `background-image: url('/img/icons/codelangs/csharp.svg');`};
  ${({ python }) =>
    python && `background-image: url('/img/icons/codelangs/python.svg');`};
  ${({ java }) =>
    java && `background-image: url('/img/icons/codelangs/java.svg');`};
  ${({ node }) =>
    node && `background-image: url('/img/icons/codelangs/node.svg');`};
`;

const Filter = styled.span`
  color: #4a5669;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  margin-right: 5px;
`;

const Nav = styled.nav`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 60px;
`;

const LangWrap = styled.div`
  margin-left: auto;
`;

const ProductFilters = [
  'voice',
  'sms',
  'verify',
  'gettingstarted101',
  'conversations',
];

const getFiltersFromSearch = () => {
  if (typeof window === 'undefined') {
    return [];
  }

  const query = window.location.search && window.location.search.slice(1);
  const { products } = queryString.parse(query);

  if (!products) return [];

  return products
    .split(',')
    .map((s) => s.toLowerCase())
    .filter((x) => !!x);
};

const updateQueryParams = (queries) => {
  if (window) {
    const newurl = `${window.location.origin}${
      window.location.pathname
    }?products=${queries.join(',')}`;

    if (newurl !== window.location.href) {
      window.history.pushState({ path: newurl }, '', newurl);
    }
  }
};

const Tutorials = ({ ...props }) => {
  const [queries, updateQueries] = useState([]);

  useEffect(() => {
    const products = getFiltersFromSearch();
    updateQueries(products.filter((p) => ProductFilters.includes(p)));
  }, []);

  useEffect(() => {
    if (queries.length) {
      updateQueryParams(queries);
    }
  }, [queries]);

  const toggleFilter = (product) => {
    updateQueries(
      queries.includes(product)
        ? queries.filter((f) => f !== product)
        : [...queries, product],
    );
  };

  const all = queries.length === 0;
  const voice = queries.includes('voice') || all;
  const sms = queries.includes('sms') || all;
  const verify = queries.includes('verify') || all;
  const gettingstarted101 = queries.includes('gettingstarted101') || all;
  const conversations = queries.includes('conversations') || all;
  const flows = queries.includes('flows') || all;

  return (
    <>
      <MobileNavigation tutorials />
      <Layout {...props} fullWidth>
        <div className="docs">
          <Flex mb={120} alignItems="center" flexWrap="wrap">
            <Box width={[1, 1 / 2, 1 / 2]}>
              <h1>Tutorials</h1>
              <p>
                Start building your communications solution with MessageBird by
                exploring our step-by-step Developer Tutorials.
              </p>
              <p>
                Download the sample code from{' '}
                <a
                  href="https://github.com/messagebirdguides"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GitHub
                </a>{' '}
                & add functionalities to your app in minutes.
              </p>
            </Box>
            <Box
              width={[1, 1 / 2, 1 / 2]}
              css="text-align: right; @media (max-width: 767px){text-align:center;}"
            >
              <img
                alt="Tutorials"
                width="300"
                height="196"
                src="/img/illustrations/tuts.svg"
              />
            </Box>
          </Flex>

          <Nav css="@media (max-width: 767px){justify-content: center;}">
            <Filter>Filter by </Filter>
            <Button
              style={{
                backgroundColor:
                  gettingstarted101 && !all ? '#B27FF2' : '#c3cddd',
              }}
              startCat
              onClick={() => toggleFilter('gettingstarted101')}
            >
              Getting Started
            </Button>
            <Button
              style={{
                backgroundColor: sms && !all ? '#2ECEA8' : '#c3cddd',
              }}
              smsCat
              onClick={() => toggleFilter('sms')}
            >
              SMS
            </Button>
            <Button
              style={{
                backgroundColor: voice && !all ? '#6BD1FF' : '#c3cddd',
              }}
              voiceCat
              onClick={() => toggleFilter('voice')}
            >
              Voice
            </Button>
            <Button
              style={{
                backgroundColor: verify && !all ? '#F0AD4E' : '#c3cddd',
              }}
              verifyCat
              onClick={() => toggleFilter('verify')}
            >
              Verify
            </Button>
            <Button
              style={{
                backgroundColor: conversations && !all ? '#FFC5BC' : '#c3cddd',
              }}
              conversationsCat
              onClick={() => toggleFilter('conversations')}
            >
              Conversations
            </Button>
            <Button
              style={{
                backgroundColor: flows && !all ? '#2A2AE6' : '#c3cddd',
              }}
              flowsCat
              onClick={() => toggleFilter('flows')}
            >
              Flows
            </Button>
          </Nav>
          <Flex flexWrap="wrap">
            {flows && (
              <LLink to="/tutorials/fetch-variables-in-flow-builder">
                <Card flowsCat>
                  <Label>Fetch variables in Flow Builder</Label>
                  <PP>
                    Use Fetch Variables step in the Flow Builder to fetch
                    variables from external APIs.
                  </PP>
                  <Flex mt="auto" flexWrap="wrap">
                    <LangLabel flowsCat>Flows</LangLabel>
                  </Flex>
                </Card>
              </LLink>
            )}
            {flows && (
              <LLink to="/tutorials/http-request-in-flow-builder">
                <Card flowsCat>
                  <Label>HTTP request in Flow Builder</Label>
                  <PP>
                    Use HTTP request step in the Flow Builder to make HTTP
                    requests to external APIs.
                  </PP>
                  <Flex mt="auto" flexWrap="wrap">
                    <LangLabel flowsCat>Flows</LangLabel>
                  </Flex>
                </Card>
              </LLink>
            )}
            {gettingstarted101 && (
              <LLink to="/tutorials/getting-started-101">
                <Card startCat>
                  <Label>Getting Started with MessageBird</Label>
                  <PP>
                    Build your communications solution by learning the basics of
                    our Dashboard & APIs.
                  </PP>
                  <Flex mt="auto" flexWrap="wrap">
                    <LangLabel startCat>Getting Started</LangLabel>
                  </Flex>
                </Card>
              </LLink>
            )}

            {gettingstarted101 && (
              <LLink to="/tutorials/setup-local-dev-environment-node">
                <Card startCat>
                  <Label>Setting Up Your Local Development Environment</Label>
                  <PP>
                    Before you dive into building your first app, learn how to
                    set up your local development environment.
                  </PP>
                  <Flex mt="auto" flexWrap="wrap">
                    <LangLabel startCat>Getting Started</LangLabel>
                    <LangWrap>
                      <LangLogo node />
                      <LangLogo php />
                      <LangLogo go />
                      <LangLogo python />
                      <LangLogo ruby />
                      <LangLogo java />
                    </LangWrap>
                  </Flex>
                </Card>
              </LLink>
            )}

            {(gettingstarted101 || sms) && (
              <LLink to="/tutorials/send-sms-node">
                <Card startCat>
                  <Label>Sending Outbound SMS Messages</Label>
                  <PP>
                    Master how to send SMS Messages using MessageBird’s SMS API
                    in 10 minutes.
                  </PP>
                  <Flex mt="auto" flexWrap="wrap">
                    <LangLabel startCat>Getting Started</LangLabel>{' '}
                    <LangLabel smsCat>SMS</LangLabel>
                    <LangWrap>
                      <LangLogo node />
                      <LangLogo php />
                      <LangLogo go />
                      <LangLogo python />
                      <LangLogo ruby />
                      <LangLogo java />
                    </LangWrap>
                  </Flex>
                </Card>
              </LLink>
            )}

            {(gettingstarted101 || voice) && (
              <LLink to="/tutorials/make-call-php">
                <Card startCat>
                  <Label>Making Outbound Voice Calls</Label>
                  <PP>
                    Master how to make Voice calls using MessageBird's Voice
                    Calling API in 10 minutes.
                  </PP>
                  <Flex mt="auto" flexWrap="wrap">
                    <LangLabel startCat>Getting Started</LangLabel>{' '}
                    <LangLabel voiceCat>Voice</LangLabel>
                    <LangWrap>
                      <LangLogo php />
                      <LangLogo go />
                      <LangLogo python />
                    </LangWrap>
                  </Flex>
                </Card>
              </LLink>
            )}

            {(gettingstarted101 || voice) && (
              <LLink to="/tutorials/voice-message-java">
                <Card startCat>
                  <Label>Sending Outbound Voice Messages</Label>
                  <PP>
                    Learn how to send voice messages with MessageBird's Voice
                    Messaging API in 10 minutes.
                  </PP>
                  <Flex mt="auto" flexWrap="wrap">
                    <LangLabel startCat>Getting Started</LangLabel>{' '}
                    <LangLabel voiceCat>Voice</LangLabel>
                    <LangWrap>
                      <LangLogo ruby />
                      <LangLogo java />
                    </LangWrap>
                  </Flex>
                </Card>
              </LLink>
            )}

            {(gettingstarted101 || sms || voice) && (
              <LLink to="/tutorials/handle-incoming-calls-and-sms">
                <Card startCat>
                  <Label>Handle Incoming SMS Messages and Voice Calls</Label>
                  <PP>
                    Learn the basics of handling inbound SMS messages and Voice
                    calls at scale.
                  </PP>
                  <Flex mt="auto" flexWrap="wrap">
                    <LangLabel startCat>Getting Started</LangLabel>{' '}
                    <LangLabel smsCat>SMS</LangLabel>
                    <LangLabel voiceCat>Voice</LangLabel>
                  </Flex>
                </Card>
              </LLink>
            )}

            {(verify || sms) && (
              <LLink to="/tutorials/verify-node">
                <Card verifyCat>
                  <Label>
                    Implementing SMS Two-Factor Authentication (2FA)
                  </Label>
                  <PP>
                    Learn how to verify your user’s password with SMS using
                    MessageBird’s Verify API.
                  </PP>
                  <Flex mt="auto" flexWrap="wrap">
                    <LangLabel verifyCat>Verify</LangLabel>
                    <LangLabel smsCat>SMS</LangLabel>
                    <LangWrap>
                      <LangLogo node />
                      <LangLogo php />
                      <LangLogo go />
                      <LangLogo python />
                      <LangLogo csharp />
                      <LangLogo ruby />
                      <LangLogo java />
                    </LangWrap>
                  </Flex>
                </Card>
              </LLink>
            )}

            {(verify || voice) && (
              <LLink to="/tutorials/account-security-voice-node">
                <Card verifyCat>
                  <Label>
                    Implementing Voice Two-Factor Authentication (2FA)
                  </Label>
                  <PP>
                    Learn how to verify your user’s password with Voice using
                    MessageBird’s Verify API.
                  </PP>
                  <Flex mt="auto" flexWrap="wrap">
                    <LangLabel verifyCat>Verify</LangLabel>
                    <LangLabel voiceCat>Voice</LangLabel>
                    <LangWrap>
                      <LangLogo node />
                      <LangLogo php />
                      <LangLogo go />
                      <LangLogo ruby />
                      <LangLogo java />
                    </LangWrap>
                  </Flex>
                </Card>
              </LLink>
            )}

            {sms && (
              <LLink to="/tutorials/notifications-node">
                <Card smsCat>
                  <Label>SMS Notifications</Label>
                  <PP>
                    Keep your customers up to date with this easy-to-build order
                    notification application.
                  </PP>
                  <Flex mt="auto" flexWrap="wrap">
                    <LangLabel smsCat>SMS</LangLabel>
                    <LangWrap>
                      <LangLogo node />
                      <LangLogo php />
                      <LangLogo go />
                      <LangLogo python />
                      <LangLogo ruby />
                      <LangLogo java />
                    </LangWrap>
                  </Flex>
                </Card>
              </LLink>
            )}

            {sms && (
              <LLink to="/tutorials/reminders-node">
                <Card smsCat>
                  <Label>SMS Reminders</Label>
                  <PP>
                    Reduce no-shows with timely & automated SMS reminders using
                    MessageBird’s API.
                  </PP>
                  <Flex mt="auto" flexWrap="wrap">
                    <LangLabel smsCat>SMS</LangLabel>
                    <LangWrap>
                      <LangLogo node />
                      <LangLogo php />
                      <LangLogo go />
                      <LangLogo python />
                      <LangLogo ruby />
                      <LangLogo java />
                    </LangWrap>
                  </Flex>
                </Card>
              </LLink>
            )}

            {sms && (
              <LLink to="/tutorials/lead-alerts-node">
                <Card smsCat>
                  <Label>SMS Instant Lead Alerts</Label>
                  <PP>
                    Instantly notify your sales team & convert more leads with
                    SMS lead alerts.
                  </PP>
                  <Flex mt="auto" flexWrap="wrap">
                    <LangLabel smsCat>SMS</LangLabel>
                    <LangWrap>
                      <LangLogo node />
                      <LangLogo php />
                      <LangLogo go />
                      <LangLogo python />
                      <LangLogo ruby />
                      <LangLogo java />
                    </LangWrap>
                  </Flex>
                </Card>
              </LLink>
            )}

            {sms && (
              <LLink to="/tutorials/subscriptions-node">
                <Card smsCat>
                  <Label>SMS Marketing Subscriptions</Label>
                  <PP>
                    Enable your subscribers to seamlessly opt-in & out of your
                    SMS marketing programs.
                  </PP>
                  <Flex mt="auto" flexWrap="wrap">
                    <LangLabel smsCat>SMS</LangLabel>
                    <LangWrap>
                      <LangLogo node />
                      <LangLogo php />
                      <LangLogo go />
                      <LangLogo ruby />
                      <LangLogo java />
                    </LangWrap>
                  </Flex>
                </Card>
              </LLink>
            )}
            {sms && (
              <LLink to="/tutorials/sms-customer-support-node">
                <Card smsCat>
                  <Label>SMS Customer Support System</Label>
                  <PP>
                    Successfully manage your inbound support tickets with this
                    real-time SMS communication application.
                  </PP>
                  <Flex mt="auto" flexWrap="wrap">
                    <LangLabel smsCat>SMS</LangLabel>
                    <LangWrap>
                      <LangLogo node />
                      <LangLogo php />
                      <LangLogo ruby />
                      <LangLogo java />
                    </LangWrap>
                  </Flex>
                </Card>
              </LLink>
            )}
            {sms && (
              <LLink to="/tutorials/sms-server-alerts-node">
                <Card smsCat>
                  <Label>SMS Server Alerts</Label>
                  <PP>
                    Quickly report error logs of your server via SMS to ensure a
                    faster response time.{' '}
                  </PP>
                  <Flex mt="auto" flexWrap="wrap">
                    <LangLabel smsCat>SMS</LangLabel>
                    <LangWrap>
                      <LangLogo node />
                      <LangLogo php />
                      <LangLogo go />
                      <LangLogo ruby />
                      <LangLogo java />
                    </LangWrap>
                  </Flex>
                </Card>
              </LLink>
            )}

            {(sms || voice) && (
              <LLink to="/tutorials/masked-numbers-node">
                <Card smsCat>
                  <Label>Masked Phone Numbers</Label>
                  <PP>
                    Anonymize & protect your users’ personal information with a
                    masked numbers application.
                  </PP>
                  <Flex mt="auto" flexWrap="wrap">
                    <LangLabel smsCat>SMS</LangLabel>{' '}
                    <LangLabel voiceCat>Voice</LangLabel>
                    <LangWrap>
                      <LangLogo node />
                      <LangLogo php />
                      <LangLogo go />
                      <LangLogo ruby />
                      <LangLogo java />
                    </LangWrap>
                  </Flex>
                </Card>
              </LLink>
            )}

            {voice && (
              <LLink to="/tutorials/automated-voice-survey-node">
                <Card voiceCat>
                  <Label>Automated Voice Surveys</Label>
                  <PP>
                    Gather valuable information from your users with this
                    automated voice survey application.
                  </PP>
                  <Flex mt="auto" flexWrap="wrap">
                    <LangLabel voiceCat>Voice</LangLabel>
                    <LangWrap>
                      <LangLogo node />
                      <LangLogo php />
                      <LangLogo ruby />
                      <LangLogo java />
                    </LangWrap>
                  </Flex>
                </Card>
              </LLink>
            )}

            {voice && (
              <LLink to="/tutorials/warm-transfers-curl">
                <Card voiceCat>
                  <Label>Warm Transfers</Label>
                  <PP>
                    Find out how to prepare agents for inbound calls using a
                    Warm Transfer.
                  </PP>
                  <Flex mt="auto" flexWrap="wrap">
                    <LangLabel voiceCat>Voice</LangLabel>
                    <LangWrap>
                      <LangLogo curl />
                    </LangWrap>
                  </Flex>
                </Card>
              </LLink>
            )}

            {voice && (
              <LLink to="/tutorials/call-screening-curl">
                <Card voiceCat>
                  <Label>Call Screening</Label>
                  <PP>
                    Learn how to enable Call Screening before initiating a
                    transfer between two numbers.
                  </PP>
                  <Flex mt="auto" flexWrap="wrap">
                    <LangLabel voiceCat>Voice</LangLabel>
                    <LangWrap>
                      <LangLogo curl />
                    </LangWrap>
                  </Flex>
                </Card>
              </LLink>
            )}

            {conversations && (
              <LLink to="/tutorials/whatsapp-bot-go">
                <Card conversationsCat>
                  <Label>WhatsApp To-Do Bot</Label>
                  <PP>
                    Build a simple to-do list bot with WhatsApp using
                    Programmable Conversations.
                  </PP>
                  <Flex mt="auto" flexWrap="wrap">
                    <LangLabel conversationsCat>Conversations</LangLabel>
                    <LangWrap>
                      <LangLogo go />
                    </LangWrap>
                  </Flex>
                </Card>
              </LLink>
            )}

            {conversations && (
              <LLink to="/tutorials/whatsapp-accountmasking">
                <Card conversationsCat>
                  <Label>WhatsApp Account Masking</Label>
                  <PP>
                    Create secure and anonymous conversations on WhatsApp using
                    Flow Builder.
                  </PP>
                  <Flex mt="auto" flexWrap="wrap">
                    <LangLabel conversationsCat>Conversations</LangLabel>
                    <LangWrap>
                      <LangLogo go />
                    </LangWrap>
                  </Flex>
                </Card>
              </LLink>
            )}

            {voice && (
              <LLink to="/tutorials/call-recording-curl">
                <Card voiceCat>
                  <Label>Recording Voice Calls</Label>
                  <PP>
                    Create a call flow that transfers your calls to a number
                    with recording functionality enabled.
                  </PP>
                  <Flex mt="auto" flexWrap="wrap">
                    <LangLabel voiceCat>Voice</LangLabel>
                    <LangWrap>
                      <LangLogo curl />
                    </LangWrap>
                  </Flex>
                </Card>
              </LLink>
            )}
            {voice && (
              <LLink to="/tutorials/call-info-curl">
                <Card voiceCat>
                  <Label>Getting Call Information</Label>
                  <PP>
                    Learn the concepts, terminology and methods for for
                    retrieving information about calls.
                  </PP>
                  <Flex mt="auto" flexWrap="wrap">
                    <LangLabel voiceCat>Voice</LangLabel>
                    <LangWrap>
                      <LangLogo curl />
                    </LangWrap>
                  </Flex>
                </Card>
              </LLink>
            )}
            {voice && (
              <LLink to="/tutorials/voice-recording-curl">
                <Card voiceCat>
                  <Label>Recording Inbound Voice Messages</Label>
                  <PP>
                    Easily record an inbound or outbound leg in your call flow
                    with MessageBird’s API.
                  </PP>
                  <Flex mt="auto" flexWrap="wrap">
                    <LangLabel voiceCat>Voice</LangLabel>
                    <LangWrap>
                      <LangLogo curl />
                    </LangWrap>
                  </Flex>
                </Card>
              </LLink>
            )}
            {voice && (
              <LLink to="/tutorials/ivr-curl">
                <Card voiceCat>
                  <Label>Interactive Voice Response (IVR)</Label>
                  <PP>
                    Master the basics of support call centers & build a
                    sophisticated IVR system.
                  </PP>
                  <Flex mt="auto" flexWrap="wrap">
                    <LangLabel voiceCat>Voice</LangLabel>
                    <LangWrap>
                      <LangLogo curl />
                    </LangWrap>
                  </Flex>
                </Card>
              </LLink>
            )}
            {voice && (
              <LLink to="/tutorials/call-transfer-curl">
                <Card voiceCat>
                  <Label>Transferring Voice Calls</Label>
                  <PP>
                    Build a call flow for handling & transferring incoming voice
                    calls with the MessageBird’s API.
                  </PP>
                  <Flex mt="auto" flexWrap="wrap">
                    <LangLabel voiceCat>Voice</LangLabel>
                    <LangWrap>
                      <LangLogo curl />
                    </LangWrap>
                  </Flex>
                </Card>
              </LLink>
            )}
            {voice && (
              <LLink to="/tutorials/transcription-curl">
                <Card voiceCat>
                  <Label>Transcription Requests for Voice Recordings</Label>
                  <PP>
                    Learn how to create a transcription request for a voice
                    recording with MessageBird’s API.
                  </PP>
                  <Flex mt="auto" flexWrap="wrap">
                    <LangLabel voiceCat>Voice</LangLabel>
                    <LangWrap>
                      <LangLogo curl />
                    </LangWrap>
                  </Flex>
                </Card>
              </LLink>
            )}
          </Flex>
        </div>
      </Layout>
    </>
  );
};

export default Tutorials;
