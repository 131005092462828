import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { COLOR } from '../../constants';
//set z-index higher for header wrapper always  to show header on the top
const Wrapper = styled.div`
  background-color: ${({ header }) =>
    header ? `${COLOR.WHITE};` : `${COLOR.GREY_BACHGROUND};`}
  z-index: ${({ header }) => (header ? 2 : 1)};
  margin: 0 auto;
  max-width: ${({ header }) => !header && `1396px;`};
  position: sticky;
  top: 0;
  padding: 0;
  ${({ isDebug }) => isDebug && 'background-color: orange;'};
  ${({ header }) => !header && 'display: flex; justify-content: center;'};
`;

const Container = ({ children, ...props }) => (
  <Wrapper {...props}>{children}</Wrapper>
);

Container.defaultProps = {
  isDebug: false,
};

Container.propTypes = {
  isDebug: PropTypes.bool,
};

export { Container };
