import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Handling Incoming SMS Messages and Voice Calls with MessageBird`}</h1>
    <h3>{`⏱ 20 min build time`}</h3>
    <p>{`In this MessageBird Developer Tutorial you’ll learn the basics of handling inbound SMS Messages and Voice Calls at scale. In earlier tutorials, we’ve discussed `}<a parentName="p" {...{
        "href": "/tutorials/send-sms-node"
      }}>{`sending SMS`}</a>{` and `}<a parentName="p" {...{
        "href": "/tutorials/make-call-php"
      }}>{`making outbound calls`}</a>{`. What these scenarios have in common is that activity inside your code, such as a user interacting with your application or a background task running on your server, triggers an API request that instructs MessageBird to do something. The opposite behavior, in which a user sending an SMS message or calling you on a MessageBird `}<a parentName="p" {...{
        "href": "https://www.messagebird.com/en/numbers"
      }}>{`virtual mobile number`}</a>{` should trigger some action in your code, is also extremely useful. This functionality enables you to build solutions like a custom telephony system or implement bots that allow two-way communication over text messaging.`}</p>
    <p>{`Traditional APIs, including the MessageBird RESTful API that you’ve used with the help of the SDKs, are request-response systems in which an API consumer (you) makes an HTTP request to an API provider (MessageBird) that triggers an action and receives a response in return. If you wanted to build an inbound system with an API, you'd have to resort to polling and continuously ask the API provider, for example, if there are any new messages to process. A better way to implement these scenarios is a pattern commonly known as webhooks, also often named callbacks. Conceptually, webhooks are like a reverse API: Your system exposes an API endpoint at a publicly available URL and provides this URL to your API provider so that they can make an HTTP request to it.`}</p>
    <p>{`Developing a webhook setup to handle incoming calls or SMS messages involves the following steps:`}</p>
    <ol>
      <li parentName="ol">{`Implement a `}<inlineCode parentName="li">{`URL/route`}</inlineCode>{` to receive and process the webhook as part of your application.`}</li>
      <li parentName="ol">{`To prepare for testing your webhook endpoint before deploying your application, temporarily make your development environment publicly available. We'll show you how to do this with a third party tunneling provider.`}</li>
      <li parentName="ol">{`Assign your development URL to the desired number so that we know which URL to trigger and when. We have implemented webhooks as part of `}<a parentName="li" {...{
          "href": "https://www.messagebird.com/en/flow-builder"
        }}>{`Flow Builder`}</a>{`; we've also prepared examples for setting up webhooks.`}</li>
      <li parentName="ol">{`Test your integration.`}</li>
      <li parentName="ol">{`Deploy your application.`}</li>
      <li parentName="ol">{`Update your flow with the production URL.`}</li>
    </ol>
    <h2>{`Creating a Webhook Handler`}</h2>
    <p>{`The webhook handler must be a URL of your application. We recommend that you use a path that is difficult to guess so that only you and MessageBird know about it—to reduce the likelihood of accidental or malicious requests by a third-party. On the other hand, you have to make sure that the URL is publicly accessible. If you use a framework for developing your application, make sure that it doesn’t enforce user authentication or ask for CSRF (Cross-Site-Request-Forgery) tokens on the path.`}</p>
    <p>{`Keep in mind that you don’t need a MessageBird SDK to receive webhooks, but depending on your use case you may need it to act upon it by sending a reply message or call.`}</p>
    <p>{`The following sections describe the conceptual basics of receiving and replying to webhooks; they don’t contain any code examples. If you need specific advice to your programming language you can check our `}<a parentName="p" {...{
        "href": "/tutorials"
      }}>{`MessageBird Developer Tutorials`}</a>{` for full examples of several scenarios involving incoming messages and calls.`}</p>
    <p>{`Generally, webhooks come in different flavors. Most webhooks act as a simple push mechanism to inform you about events that have happened and leave it up to your application to deal with the notification (or ignore it) on its own terms. Other webhooks treat your application as a `}<em parentName="p">{`plugin`}</em>{` and expect some data as a response to control the flow at the API provider's side further. MessageBird SMS webhooks are an example of the former, while incoming call webhooks are an example of the latter.`}</p>
    <h4>{`SMS WEBHOOKS`}</h4>
    <p>{`You can set up your SMS handler with any HTTP method, but we strongly recommend you to use `}<inlineCode parentName="p">{`POST`}</inlineCode>{`. The method you specify in your application must be the same that you'll configure later in `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en-je/flow-builder"
      }}>{`Flow Builder`}</a>{`. MessageBird sends inbound SMS as form-encoded requests so you can read the input just as if it were an HTML form submitted by a user of your application.`}</p>
    <p>{`There are a variety of fields, but most applications only need the following two:`}</p>
    <ul>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`originator`}</inlineCode>{` is the phone number that sent the message to you. It will be provided in the international format with country code.`}</li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`payload`}</inlineCode>{` is the content of the message.`}</li>
    </ul>
    <p>{`Your application can return any or even an empty response to a webhook. MessageBird doesn’t read replies. You should just make sure that you don’t return an HTTP error status code.`}</p>
    <p>{`If you want to reply to the message, simply follow the instructions for sending SMS for your programming language and add the `}<inlineCode parentName="p">{`originator`}</inlineCode>{` from the webhook to the `}<inlineCode parentName="p">{`recipients`}</inlineCode>{` parameter of the outgoing message.`}</p>
    <h4>{`CALL WEBHOOKS`}</h4>
    <p>{`For call webhooks, you must use the HTTP `}<inlineCode parentName="p">{`GET`}</inlineCode>{` method in your application. Call webhooks are actually called ‘Retrieve a Call Flow from a URL’, which means that they expect you to return a call flow. When a person calls a virtual number assigned to such a URL via Flow Builder, we’ll make an HTTP request to your application and retrieve the flow. This flow describes what the caller hears; if the flow is invalid, the caller hears a failure message and the call disconnects.`}</p>
    <p>{`Since it's a `}<inlineCode parentName="p">{`GET`}</inlineCode>{`, we send parameters in the query string. For most applications you should only need the `}<inlineCode parentName="p">{`source`}</inlineCode>{` field which indicates the phone number that is calling you; it will be provided in the international format with country code.`}</p>
    <p>{`Your application should return a call flow encoded as JSON or XML with the appropriate content type, for example, `}<em parentName="p">{`application/json`}</em>{` for a JSON-encoded call flow or `}<em parentName="p">{`application/xml`}</em>{` for an XML-encoded call flow. Here’s an example of an XML-encoded call flow with a single step:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<?xml version="1.0" encoding="UTF-8"?>
<Say language="en-GB" voice="female">Hello, thanks for calling!</Say>
`}</code></pre>
    <p>{`You can check our `}<a parentName="p" {...{
        "href": "/api/voice-calling#call-flows"
      }}>{`Voice Calling API documentation`}</a>{` for a list of available step actions and their options.`}</p>
    <h2>{`Tunneling Providers`}</h2>
    <p>{`A local web server on your development machine is typically not available on the public Internet. There are changing dynamic IP addresses, firewalls and network address translation (NAT) in your router or inside the carrier network standing in the way; while this is indeed great from a security perspective, it makes testing inbound requests to web applications difficult.`}</p>
    <p>{`Don’t worry! The best workaround is a `}<em parentName="p">{`tunneling service`}</em>{`. They come with small client software that runs on your computer and opens an outgoing permanent connection to a publicly available server in a data center. Then, they assign a public URL—typically on a random or custom subdomain—on that server to your account; the public server acts as a proxy that accepts incoming connections to your URL, forwards (tunnels) them through the already established connection and sends them to the local web server as if they originated from the same machine.`}</p>
    <h4>{`NGROK`}</h4>
    <p>{`One of the most popular tunneling tools is `}<a parentName="p" {...{
        "href": "https://ngrok.com/"
      }}>{`ngrok`}</a>{`. You can `}<a parentName="p" {...{
        "href": "https://ngrok.com/download"
      }}>{`download ngrok for free`}</a>{` as a single-file binary for almost every operating system, or optionally sign up for an account to access additional features.`}</p>
    <p>{`You can start a tunnel by providing a local port number on which your application runs. For example, if your web server is using port 8080, you can launch your tunnel with this command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`ngrok http 8080
`}</code></pre>
    <p>{`After you’ve launched the tunnel, ngrok displays your temporary public URL along with some other information.`}</p>
    <h4>{`LOCALTUNNEL`}</h4>
    <p>{`Another great tool is `}<a parentName="p" {...{
        "href": "https://localtunnel.me/"
      }}>{`localtunnel.me`}</a>{`, which is uniquely suited to Node.js developers since you can comfortably install it using npm:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install -g localtunnel
`}</code></pre>
    <p>{`You can start a tunnel by providing a local port number on which your application runs. For example, if your web server is using port 8080, you can launch your tunnel with this command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`lt --port 8080
`}</code></pre>
    <p>{`After you've started the tunnel, localtunnel displays your temporary public URL.`}</p>
    <h4>{`OTHER TOOLS`}</h4>
    <p>{`If you’re not happy using either of these, you can have a look at `}<a parentName="p" {...{
        "href": "https://pagekite.net/"
      }}>{`Pagekite`}</a>{` or `}<a parentName="p" {...{
        "href": "https://forwardhq.com/"
      }}>{`Forward`}</a>{`.`}</p>
    <h3>{`Webhooks in the Flow Builder`}</h3>
    <p>{`You can use `}<a parentName="p" {...{
        "href": "https://www.messagebird.com/en/flow-builder"
      }}>{`Flow Builder`}</a>{` to design complex interactions with incoming calls or messages without writing code; you can also create simple flows with an SMS or call trigger and forwarding them to the webhook endpoint URL of your application.`}</p>
    <h4>{`SETTING UP A FLOW FOR SMS`}</h4>
    <p>{`Let’s set up a simple flow to receive inbound SMS on a virtual number in just three steps:`}</p>
    <p><strong parentName="p">{`Step one`}</strong></p>
    <p>{`Go to `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/flow-builder"
      }}>{`Flow Builder`}</a>{`, choose the template ‘Call HTTP endpoint with SMS’ and click ‘Try this flow’.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/handle-incoming/call-http-with-sms.png",
        "alt": "Call HTTP with SMS, Step 1"
      }}></img></p>
    <p><strong parentName="p">{`Step two`}</strong></p>
    <p>{`This template has two steps. Click on the first step ‘SMS’ and select the number or numbers you’d like to attach the flow to. Now, click on the second step ‘Forward to URL’ and choose POST as the method; copy the output from the `}<inlineCode parentName="p">{`lt`}</inlineCode>{` command in the URL and add `}<inlineCode parentName="p">{`/webhook`}</inlineCode>{` at the end—this is the name of the route we use to handle incoming messages in our sample application. Click on ‘Save’ when ready.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/handle-incoming/forward-to-url.png",
        "alt": "Forward to URL, Step 2"
      }}></img></p>
    <p><strong parentName="p">{`Step three`}</strong></p>
    <p><strong parentName="p">{`Ready!`}</strong>{` Hit ‘Publish’ on the right top of the screen to activate your flow.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/handle-incoming/number-for-proxy.png",
        "alt": "Number Proxy, Step 3"
      }}></img></p>
    <p>{`You can see our video tutorial and detailed article in Help Center for learning `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/articles/360000358477"
      }}>{`how to trigger a GET or POST request for your own server`}</a>{` with Flow Builder.`}</p>
    <h4>{`SETTING UP A FLOW FOR CALLS`}</h4>
    <p>{`Set up a simple flow to handle incoming calls on a virtual number in just a few steps:`}</p>
    <p><strong parentName="p">{`Step one`}</strong></p>
    <p>{`Go back to `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/flow-builder"
      }}>{`Flow Builder`}</a>{`, hit the button ‘Create new flow’ and then ‘Create Custom Flow’.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/handle-incoming/create-new-flow.png",
        "alt": "Create new flow, step 1"
      }}></img></p>
    <p><strong parentName="p">{`Step two`}</strong></p>
    <p>{`Give your flow a name, choose ‘Phone Call’ as the trigger and hit ‘Next’.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/handle-incoming/setup-new-flow.png",
        "alt": "Setup new flow, Step 2"
      }}></img></p>
    <p><strong parentName="p">{`Step three`}</strong></p>
    <p>{`Click on the first step ‘Phone Call’ and select the number or numbers you’d like to attach the flow to.`}</p>
    <p><strong parentName="p">{`Step four`}</strong></p>
    <p>{`Add a new step by pressing the small ‘+’, choose ‘Fetch call flow from URL’ and paste the same localtunnel base URL into the form, but this time append `}<inlineCode parentName="p">{`/webhook-voice`}</inlineCode>{` to it—this is the name of the route we use to handle incoming calls in our sample application. Click on ‘Save’ when ready.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/handle-incoming/fetch-call-from-URL.png",
        "alt": "Fetch Call From URL, Step 4"
      }}></img></p>
    <p><strong parentName="p">{`Step five`}</strong></p>
    <p>{`Ready! Hit ‘Publish’ on the right top of the screen to activate your flow.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/handle-incoming/proxy-for-voice.png",
        "alt": "FNumber Proxy for Voice, Step 5"
      }}></img></p>
    <p><strong parentName="p">{`Nice work!`}</strong>{` 🎉`}</p>
    <p>{`You’re now know how to handle incoming Voice Calls and SMS Messages at scale!`}</p>
    <h2>{`Start building!`}</h2>
    <p>{`Head over to more `}<a parentName="p" {...{
        "href": "/tutorials"
      }}>{`MessageBird Developer Tutorials`}</a>{` for common SMS, Voice and Chat use-cases.`}</p>
    <p>{`Want to start building your solution but not quite sure how to get started? Feel free to let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we'd love to help!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      