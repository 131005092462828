import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import Notification from 'components/Notification';
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
import { Img } from 'components/shared';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Install Channel: WeChat`}</h1>
    <p>{`Let's get started with the Programmable Conversations API by learning how to install a channel. In this quickstart,
you'll learn how to set up WeChat. All the information you need from WeChat will be available in your
`}<a parentName="p" {...{
        "href": "https://mp.weixin.qq.com/?lang=en_US"
      }}>{`WeChat Admin Platform`}</a>{`.`}</p>
    <h2>{`Step 1: Go to the channels directory in your Dashboard`}</h2>
    <p>{`Go to `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/channels"
      }}>{`Channels`}</a>{` in the sidebar of your MessageBird Dashboard. Then,
in the section "Add a new channel", click on "WeChat".`}</p>
    <Img src="/img/screenshots/quickstarts/convo/InstallWeChat1.png" alt="Install WeChat" width="800px" align="middle" mdxType="Img" />
    <h2>{`Step 2: Give your channel a name`}</h2>
    <p>{`Name your channel, make sure it's easily recognizable for yourself and your team. Don't worry, this will only be used internally.`}</p>
    <Img src="/img/screenshots/quickstarts/convo/InstallWeChat2.png" alt="Install WeChat" align="middle" mdxType="Img" />
    <h2>{`Step 3: Add your Open/Original ID`}</h2>
    <p>{`In the side-menu, expand the section "Settings and Development" and click on "Account Info". Then, in the "Account details"
tab, find the "Original ID" in the section "Registration information".`}</p>
    <Img src="/img/screenshots/quickstarts/convo/InstallWeChat3.png" alt="Install WeChat" align="middle" mdxType="Img" />
    <p>{`Fill the "Original ID" in the channel creation form.`}</p>
    <Img src="/img/screenshots/quickstarts/convo/InstallWeChat4.png" alt="Install WeChat" align="middle" mdxType="Img" />
    <h2>{`Step 4: Add your App ID and Secret`}</h2>
    <p>{`In the side-menu, inside the section "Settings and Development", click on "Basic Configuration". You will find the
"Developer ID (AppID)" and "Developer Password (AppSecret)" in the section "Official Account development information".`}</p>
    <Img src="/img/screenshots/quickstarts/convo/InstallWeChat5.png" alt="Install WeChat" align="middle" mdxType="Img" />
    <p>{`Fill the "App ID" and "App Secret" in the channel creation form.`}</p>
    <Img src="/img/screenshots/quickstarts/convo/InstallWeChat6.png" alt="Install WeChat" align="middle" mdxType="Img" />
    <h2>{`Step 5: Whitelist MessageBird's IPs`}</h2>
    <p>{`In the channel creation form in MessageBird's dashboard, you should see a list of IPs that must be whitelisted in WeChat.`}</p>
    <Img src="/img/screenshots/quickstarts/convo/InstallWeChatIPs.png" alt="Install WeChat" align="middle" mdxType="Img" />
    <p>{`Copy those IPs and, in the section "Official Account development information" of the `}<a parentName="p" {...{
        "href": "https://mp.weixin.qq.com/?lang=en_US"
      }}>{`WeChat Admin Platform`}</a>{`,
click on the "View" link in the "IP whitelist" field.`}</p>
    <Img src="/img/screenshots/quickstarts/convo/InstallWeChatIPWhitelist.png" alt="Install WeChat" width="800px" align="middle" mdxType="Img" />
    <p>{`Click on "Modify" and paste the IPs.`}</p>
    <Img src="/img/screenshots/quickstarts/convo/InstallWeChatIPWhitelistField.png" alt="Install WeChat" align="middle" mdxType="Img" />
    <h2>{`Step 5: Set up your profile picture`}</h2>
    <p>{`Optionally, personalize your account by adding the URL from your profile picture.`}</p>
    <Img src="/img/screenshots/quickstarts/convo/InstallWeChat7.png" alt="Install WeChat" align="middle" mdxType="Img" />
    <h2>{`Step 6: Copy your webhook URL`}</h2>
    <p>{`Your webhook URL is ready! Click on the 'Copy' button in the channel creation form.`}</p>
    <Img src="/img/screenshots/quickstarts/convo/InstallWeChat8.png" align="middle" mdxType="Img" />
    <p>{`Then, in the "Basic Configuration" page of the `}<a parentName="p" {...{
        "href": "https://mp.weixin.qq.com/?lang=en_US"
      }}>{`WeChat Admin Platform`}</a>{`, click on
"Change Configuration" and paste the URL in the field "Server Address (URL)".`}</p>
    <Img src="/img/screenshots/quickstarts/convo/InstallWeChat9.png" align="middle" mdxType="Img" />
    <h2>{`Step 7: Set the token`}</h2>
    <p>{`Generate a random token using letters and numbers with a length of 3 to 32 characters, and fill the token in the field
"Token".`}</p>
    <Img src="/img/screenshots/quickstarts/convo/InstallWeChat10.png" align="middle" mdxType="Img" />
    <h2>{`Step 8: Set the EncodingAESKey`}</h2>
    <p>{`In the field "EncodingAESKey", click on "Random Generation" to randomly generate a message encryption key.`}</p>
    <Img src="/img/screenshots/quickstarts/convo/InstallWeChat11.png" align="middle" mdxType="Img" />
    <h2>{`Step 9: Set the Message Encryption Method`}</h2>
    <p>{`Set the "Message Encryption Method" to "Plaintext Mode".`}</p>
    <Img src="/img/screenshots/quickstarts/convo/InstallWeChat12.png" align="middle" mdxType="Img" />
    <h2>{`Step 10: Install WeChat`}</h2>
    <p>{`Click on 'Install now' and voilà! WeChat is now installed as a channel.`}</p>
    <h2>{`Step 11: Manage your channel`}</h2>
    <p>{`If you want to edit, rename or delete your channel, simply select the channel and save the changes afterwards.`}</p>
    <p>{`Nice work! 🎉 You've successfully installed WeChat as a channel for the MessageBird Programmable Conversations API.`}</p>
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'Install Channel: SMS',
      to: '/docs/conversations/install-channel'
    }, {
      name: 'Install Channel: WhatsApp',
      to: '/docs/conversations/install-channel-whatsapp'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      