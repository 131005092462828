import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Building an SMS-based customer support system with MessageBird`}</h1>
    <h3>{`⏱ 30 min build time || `}<a parentName="h3" {...{
        "href": "https://github.com/messagebirdguides/customer-support-guide-php"
      }}>{`Download the Code`}</a></h3>
    <h2>{`Why build SMS customer support?`}</h2>
    <p>{`In this MessageBird Developer Tutorial, we'll show you how to provide an excellent user experience by managing your inbound support tickets with this real-time SMS communication application between consumers and companies powered by the `}<a parentName="p" {...{
        "href": "/api/sms-messaging"
      }}>{`MessageBird SMS Messaging API`}</a>{`.`}</p>
    <p>{`People love communicating in real time, regardless of whether it’s their friends or to a business. Real time support in a comfortable medium helps to create an excellent support experience that can contribute to retaining users for life.`}</p>
    <p>{`On the business side, Support teams need to organize communication with their customers, often using ticket systems to combine all messages for specific cases in a shared view for support agents.`}</p>
    <p>{`We'll walk you through the following steps:`}</p>
    <ul>
      <li parentName="ul">{`Customers can send any message to a virtual mobile number (VMN) created and published by the company. Their message becomes a support ticket, and they receive an automated confirmation with a ticket ID for their reference.`}</li>
      <li parentName="ul">{`Any subsequent message from the same number is added to the same support ticket; there's no additional confirmation.`}</li>
      <li parentName="ul">{`Support agents can view all messages in a web view and reply to them.`}</li>
    </ul>
    <h2>{`Getting started`}</h2>
    <p>{`First things first, our sample application is built in PHP, so if you're using a Mac, PHP is already installed; for Windows users, you can `}<a parentName="p" {...{
        "href": "https://windows.php.net/download/"
      }}>{`get it from windows.php.net`}</a>{`; for Linux users, please check your system's default package manager.`}</p>
    <p>{`You’ll also need Composer, which is available from `}<a parentName="p" {...{
        "href": "https://getcomposer.org/download/"
      }}>{`getcomposer.org`}</a>{`, to install application dependencies like the `}<a parentName="p" {...{
        "href": "https://github.com/messagebird/php-rest-api"
      }}>{`MessageBird SDK for PHP`}</a>{`.`}</p>
    <p>{`The source code is available in the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/customer-support-guide-php"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`, from which you can either clone with git or from where you can download a ZIP file with the source code to your computer.`}</p>
    <p>{`After saving the code, open a console for the download directory and run the following command, which downloads the Slim framework, MessageBird SDK, and other dependencies defined in the `}<inlineCode parentName="p">{`composer.json`}</inlineCode>{` file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`composer install
`}</code></pre>
    <p>{`It's helpful to know the basics of the `}<a parentName="p" {...{
        "href": "https://packagist.org/packages/slim/slim"
      }}>{`Slim framework`}</a>{` to follow along with the tutorial, but you should be able to get the gist of it also if your experience lies with other frameworks.`}</p>
    <p>{`Our sample application uses a relational database to store tickets and messages. It’s configured to use a single-file `}<a parentName="p" {...{
        "href": "https://www.sqlite.org/"
      }}>{`SQLite`}</a>{` database, which is natively supported by PHP through PDO so that it works out of the box without the need to configure an external RDBMS like MySQL. Run the following helper command to create the `}<inlineCode parentName="p">{`support.sqlite`}</inlineCode>{` file which contains an empty database with the required schema:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`php init.php
`}</code></pre>
    <h2>{`Prerequisites for receiving messages`}</h2>
    <h3>{`Overview`}</h3>
    <p>{`The support system receives incoming messages. From a high-level viewpoint, receiving is relatively simple: an application defines a `}<em parentName="p">{`webhook URL`}</em>{`, which you assign to a number purchased in the MessageBird Dashboard using `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/flow-builder"
      }}>{`Flow Builder`}</a>{`. A webhook is a URL on your site that doesn't render a page to users but is like an API endpoint that can be triggered by other servers. Every time someone sends a message to that number, MessageBird collects it and forwards it to the webhook URL where you can process it.`}</p>
    <h3>{`Exposing your development server with ngrok`}</h3>
    <p>{`When working with webhooks, an external service like MessageBird needs to access your application, so the URL must be public; however, during development you're typically working in a local development environment that is not publicly available. Thankfully this is not a big deal since various tools and services allow you to quickly expose your development environment to the Internet by providing a tunnel from a public URL to your local machine. One of the most popular tools is `}<a parentName="p" {...{
        "href": "https://ngrok.com/"
      }}>{`ngrok`}</a>{`.`}</p>
    <p>{`You can download `}<a parentName="p" {...{
        "href": "https://ngrok.com/download"
      }}>{`ngrok`}</a>{` for free as a single-file binary for almost every operating system, or optionally sign up for an account to access additional features.`}</p>
    <p>{`Let’s start a tunnel by providing a local port number on which your application runs. We’ll run our PHP server on port 8080, so you can launch your tunnel with this command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`ngrok http 8080
`}</code></pre>
    <p>{`After you've launched the tunnel, ngrok displays your temporary public URL along with some other information. We'll need that URL in a minute.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/support-php/ngrok.png",
        "alt": "ngrok"
      }}></img></p>
    <p>{`Another common tool for tunneling your local machine is `}<a parentName="p" {...{
        "href": "https://localtunnel.me/"
      }}>{`localtunnel.me`}</a>{`, which works virtually in the same way; you can have a look at it if you're facing problems with with ngrok. Keep in mind that it requires you to install `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/"
      }}>{`NPM`}</a>{` first.`}</p>
    <h3>{`Getting an inbound number`}</h3>
    <p>{`A requirement for receiving messages is a dedicated inbound number. Virtual mobile numbers look and work similar to regular mobile numbers; however, instead of being attached to a mobile device via a SIM card, they live in the cloud and can process inbound SMS and voice calls. MessageBird offers numbers from different countries for a low monthly fee; `}<a parentName="p" {...{
        "href": "https://www.messagebird.com/en/numbers"
      }}>{`feel free to explore our low-cost programmable and configurable numbers`}</a>{`.`}</p>
    <p>{`Purchasing a number is quite easy:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Go to the ‘`}<a parentName="p" {...{
            "href": "https://dashboard.messagebird.com/en/numbers"
          }}>{`Numbers`}</a>{`’ section in the left-hand side of your Dashboard and click the blue button ‘`}<a parentName="p" {...{
            "href": "https://dashboard.messagebird.com/en/vmn/buy-number"
          }}>{`Buy a number`}</a>{`’ in the top-right side of your screen.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Pick the country in which you and your customers are located, and make sure the SMS capability is selected.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Choose one number from the selection and the duration for which you want to pay now.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Confirm by clicking ‘Buy Number’ in the bottom-right of your screen.`}</p>
      </li>
    </ol>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/support-php/image0.png",
        "alt": "VMN"
      }}></img></p>
    <p>{`Awesome, you’ve set up your first virtual mobile number! 🎉`}</p>
    <p><strong parentName="p">{`Pro-tip:`}</strong>{` Check out our Help Center for more information about `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/sections/201958489-Virtual-Numbers"
      }}>{`virtual mobile numbers`}</a>{` and `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/sections/360000108538-Country-info-Restrictions"
      }}>{`country restrictions`}</a>{`.`}</p>
    <h3>{`Connect your number to the webhook`}</h3>
    <p>{`So you have a number now, but MessageBird has no idea what to do with it. That's why now you need to define a `}<em parentName="p">{`Flow`}</em>{` that links your number to your webhook. This is how you do it:`}</p>
    <h4>{`STEP ONE`}</h4>
    <p>{`On the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/numbers"
      }}>{`Numbers`}</a>{` section of the MessageBird Dashboard, click the "Add new flow" icon next to the number you purchased.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/support-php/image1.png",
        "alt": "FlowBuilder"
      }}></img></p>
    <h4>{`STEP TWO`}</h4>
    <p>{`Hit ‘Create Custom Flow’ and give your flow a name, choose ‘SMS’ as the trigger and hit ‘Next’.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/support-php/image2.png",
        "alt": "FlowBuilder"
      }}></img></p>
    <h4>{`STEP THREE`}</h4>
    <p>{`The number is already attached to the first step ‘SMS’. Add a new step by pressing the small ‘+’, choose ‘Fetch to URL’ and select ‘POST’ as the method; copy the output from the output of the ngrok command in the URL and add `}<inlineCode parentName="p">{`/webhook`}</inlineCode>{` to it—this is the name of the route we use to handle incoming messages in our sample application. Click on ‘Save’ when ready.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/support-php/image3.png",
        "alt": "FlowBuilder"
      }}></img></p>
    <h4>{`STEP FOUR`}</h4>
    <p>{`Ready! Hit ‘Publish’ on the right top of the screen to activate your flow. Well done, another step closer to testing incoming messages! Your flow should look something like this:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/support-php/image4.png",
        "alt": "FlowBuilder"
      }}></img></p>
    <p><strong parentName="p">{`Pro-tip:`}</strong>{` It might be useful to rename it this flow, because `}<em parentName="p">{`Untitled flow`}</em>{` won't be helpful in the long run. You can do this by clicking on the icon next to button ‘Back to Overview’ and pressing ‘Rename flow’.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/support-php/image5.png",
        "alt": "VMN"
      }}></img></p>
    <h2>{`Configuring the MessageBird SDK`}</h2>
    <p>{`The MessageBird SDK and an API key are not required to receive messages; however, since we want to send replies, we need to add and configure it. The SDK is listed as a dependency in `}<inlineCode parentName="p">{`composer.json`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
   "require" : {
       "messagebird/php-rest-api" : "^1.9.4"
       ...
   }
}
`}</code></pre>
    <p>{`An application can access the SDK, which is made available through Composer autoloading, by creating an instance of the `}<inlineCode parentName="p">{`MessageBird\\Client`}</inlineCode>{` class. The constructor takes a single argument, your `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`API key`}</a>{`. For frameworks like Slim you can add the SDK to the dependency injection container:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`// Load and initialize MessageBird SDK
$container['messagebird'] = function() {
   return new MessageBird\\Client(getenv('MESSAGEBIRD_API_KEY'));
};
`}</code></pre>
    <p>{`As it's a bad practice to keep credentials in the source code, we load the API key from an environment variable using `}<inlineCode parentName="p">{`getenv()`}</inlineCode>{`. To make the key available in the environment variable we need to initialize Dotenv and then add the key to a `}<inlineCode parentName="p">{`.env`}</inlineCode>{` file.`}</p>
    <p>{`Apart from `}<inlineCode parentName="p">{`MESSAGEBIRD_API_KEY`}</inlineCode>{` we use another environment variable called `}<inlineCode parentName="p">{`MESSAGEBIRD_ORIGINATOR`}</inlineCode>{` which contains the phone number used in our system, i.e., the VMN you just registered.`}</p>
    <p>{`You can copy the `}<inlineCode parentName="p">{`env.example`}</inlineCode>{` file provided in the repository to `}<inlineCode parentName="p">{`.env`}</inlineCode>{` and then add your API key and phone number like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-env"
      }}>{`MESSAGEBIRD_API_KEY=YOUR-API-KEY
MESSAGEBIRD_ORIGINATOR=+31970XXXXXXX
`}</code></pre>
    <p>{`Let's create your live API access key. First, go to the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/user/index"
      }}>{`MessageBird Dashboard`}</a>{`; if you have already created an API key it will be shown right there. If you don’t see any key on the Dashboard or if you're unsure whether this key is in `}<em parentName="p">{`live`}</em>{` mode, go to the `}<em parentName="p">{`Developers`}</em>{` section in the MessageBird Dashboard and open the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`API access (REST) tab`}</a>{`. There you can create new API keys and manage your existing ones.`}</p>
    <p>{`If you are having any issues creating your API key, please reach out to `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we’ll make sure to help you out.`}</p>
    <h2>{`Receiving messages`}</h2>
    <p>{`Now we're fully prepared for receiving inbound messages, so let’s implement the `}<inlineCode parentName="p">{`$app->post('/webhook')`}</inlineCode>{` route:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`// Handle incoming webhooks
$app->post('/webhook', function($request, $response) {
   // Read input sent from MessageBird
   $number = $request->getParsedBodyParam('originator');
   $text = $request->getParsedBodyParam('payload');
`}</code></pre>
    <p>{`The webhook receives multiple request parameters from MessageBird; however, we're interested in two of them: the `}<inlineCode parentName="p">{`originator`}</inlineCode>{` which is the number of the user who sent the message (it’s important don't confuse it with the `}<em parentName="p">{`originator`}</em>{` you configured which is for `}<em parentName="p">{`outgoing`}</em>{` messages), and the `}<inlineCode parentName="p">{`payload`}</inlineCode>{`, which is the content of the text message.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`   // Find open ticket for number in our database
   $stmt = $this->db->prepare('SELECT * FROM tickets WHERE number = :number AND open = :open');
   $stmt->execute([ 'number' => $number, 'open' => (int)true ]);
   $ticket = $stmt->fetch();
`}</code></pre>
    <p>{`The number is used to look up the ticket with an SQL query. If none exists, we create a new ticket using SQL INSERT into the `}<inlineCode parentName="p">{`tickets`}</inlineCode>{` table and send a confirmation message to the user with the ticket ID:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`   if ($ticket === false) {
       // Creating a new ticket
       $stmt = $this->db->prepare('INSERT INTO tickets (number, open) VALUES (:number, :open)');
       $stmt->execute([ 'number' => $number, 'open' => (int)true ]);
       $id = $this->db->lastInsertId();

       // Send a confirmation
       $message = new MessageBird\\Objects\\Message;
       $message->originator = getenv('MESSAGEBIRD_ORIGINATOR');
       $message->recipients = [ $number ];
       $message->body = "Thanks for contacting customer support! Your ticket ID is " . $id . ".";
       try {
           $this->messagebird->messages->create($message);
       } catch (Exception $e) {
           error_log(get_class($e).": ".$e->getMessage());
       }
   } else {
       $id = $ticket['id'];
   }
`}</code></pre>
    <p>{`To send the confirmation message, we create a new `}<inlineCode parentName="p">{`MessageBird\\Objects\\Message`}</inlineCode>{` object first. This object requires three attributes:`}</p>
    <ul>
      <li parentName="ul">{`Our configured `}<inlineCode parentName="li">{`originator`}</inlineCode>{`, so that the receiver sees a reply from the number which they contacted in the first place. It’s taken from the environment variable.`}</li>
      <li parentName="ul">{`A `}<inlineCode parentName="li">{`recipient`}</inlineCode>{` array with the number from the incoming message so that the reply goes back to the right person.`}</li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`body`}</inlineCode>{` of the message, which contains the ticket ID.`}</li>
    </ul>
    <p>{`Then, we call `}<inlineCode parentName="p">{`messages->create()`}</inlineCode>{` on the SDK object and provide the message object as its only parameter. The API call is contained in a try-catch block, and any sending errors are written to the default error log.`}</p>
    <p>{`So, what if a ticket already exists? In this case, our `}<inlineCode parentName="p">{`else`}</inlineCode>{` block, we just read the ticket ID from it. The next block of code, which is executed in both cases, is responsible for adding a new message to the `}<inlineCode parentName="p">{`messages`}</inlineCode>{` table with an SQL INSERT statement including a reference to the ticket ID:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`   // Add inbound message to ticket
   $stmt = $this->db->prepare('INSERT INTO messages (ticket_id, direction, content) VALUES (:ticket_id, :direction, :content)');
   $stmt->execute([
       'ticket_id' => $id,
       'direction' => 'in',
       'content' => $text
   ]);
`}</code></pre>
    <p>{`Servers sending webhooks typically expect you to return a response with a default 200 status code to indicate that their webhook request was received, but they don’t parse the response. Therefore, we send the string `}<em parentName="p">{`OK`}</em>{` at the end of the route handler, independent of the case that we handled:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`   // Return any response, MessageBird won't parse this
   return "OK";
});
`}</code></pre>
    <h2>{`Sending messages`}</h2>
    <p>{`Customer support team members can view incoming tickets from an admin view. We have implemented a simple admin view in the `}<inlineCode parentName="p">{`$app->get('/admin')`}</inlineCode>{` route:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`// Show tickets for customer support admin
$app->get('/admin', function($request, $response) {
   // Find all open tickets
   $stmt = $this->db->prepare('SELECT t.id, t.number, m.direction, m.content FROM tickets t JOIN messages m ON m.ticket_id = t.id WHERE t.open = :open');
   $stmt->execute([ 'open' => (int)true ]);
   $ticketsAndMessages = $stmt->fetchAll();

   // Group and format messages to tickets for easier display
   $tickets = [];
   foreach ($ticketsAndMessages as $tam) {
       if (!isset($tickets[$tam['id']])) {
           $tickets[$tam['id']] = [
               'id' => $tam['id'],
               'number' => $tam['number'],
               'messages' => [
                   [
                       'direction' => $tam['direction'],
                       'content' => $tam['content']
                   ]
               ]
           ];
       } else {
           $tickets[$tam['id']]['messages'][] = [
               'direction' => $tam['direction'],
               'content' => $tam['content']
           ];
       }
   }

   // Show a page with tickets
   return $this->view->render($response, 'admin.html.twig', [ 'tickets' => $tickets ]);
});
`}</code></pre>
    <p>{`Inside this route, we use an SQL SELECT query with a JOIN over the `}<inlineCode parentName="p">{`tickets`}</inlineCode>{` and `}<inlineCode parentName="p">{`messages`}</inlineCode>{` table, and then run a loop over the results to convert them into a nested array structure. This array structure is used by `}<a parentName="p" {...{
        "href": "https://twig.symfony.com/"
      }}>{`Twig`}</a>{` to render the view from a template. This template is stored in `}<inlineCode parentName="p">{`views/admin.handlebars`}</inlineCode>{`. Apart from the HTML that renders the documents, there’s a small Javascript section in it that refreshes the page every 10 seconds. Thanks to this, you can keep the page open and will receive messages automatically with only a small delay and without the implementation of Websockets.`}</p>
    <h2>{`Replying to messages`}</h2>
    <p>{`The admin template also contains a form for each ticket through which you can send replies. The implementation uses `}<inlineCode parentName="p">{`messages->create()`}</inlineCode>{`, analogous to the confirmation messages we're sending for new tickets. If you're curious about the details, you can look at the `}<inlineCode parentName="p">{`$app->post('/reply')`}</inlineCode>{` implementation route in `}<inlineCode parentName="p">{`index.php`}</inlineCode>{`.`}</p>
    <h2>{`Testing`}</h2>
    <p>{`You’re done! It’s time to test your application.`}</p>
    <p>{`Double-check that you’ve set up your number correctly with a flow that forwards incoming messages to a ngrok URL and that the tunnel is still running. Keep in mind that whenever you start a fresh tunnel with the ngrok command, you'll get a new URL, so you have to update it in the flow accordingly.`}</p>
    <p>{`To start the application you have to enter another command, but your existing console window is already busy running your tunnel. Therefore you need to open another one. With Mac you can press `}<em parentName="p">{`Command + Tab`}</em>{` to open a second tab that's already pointed to the correct directory. With other operating systems you may have to open another console window manually. Either way, once you've got a command prompt, type the following to start the application:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`php -S 0.0.0.0:8080 index.php
`}</code></pre>
    <p>{`Open http://localhost:8080/admin in your browser. You should see an empty list of tickets. Then, take out your phone, launch the SMS app, and send a message to your virtual mobile number. Around 10-20 seconds later, you should see your message in the browser. Amazing! Try again with another message which will be added to the ticket, or send a reply.`}</p>
    <p>{`Use the flow, code snippets and UI examples from this tutorial as an inspiration to build your own SMS Customer Support system. Don't forget to download the code from the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/customer-support-guide-php"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`.`}</p>
    <p><strong parentName="p">{`Nice work!`}</strong>{` 🎉`}</p>
    <p>{`You now have a running SMS Customer Support application with MessageBird using PHP!`}</p>
    <h2>{`Start building!`}</h2>
    <p>{`Want to start building your solution but not quite sure how to get started? Feel free to let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we'd love to help!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      