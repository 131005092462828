import { track } from '../../../helpers/tracking';
import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import useNextSidebarItem from '../../../customHooks/useNextSidebarItem';
const StyledLink = styled(Link)`
  margin-left: 16px;
`;

const NextUpContainer = styled.div`
  white-space: nowrap;
  font-size: 16px;
  font-weight: normal;
  height: 22px;
  line-height: 22px;
  text-align: right;
`;

const Icon = styled.img`
  vertical-align: middle;
  margin-left: 6px;
`;

export const NextUp = (): JSX.Element | null => {
  const step: { name: string; url: string } | undefined = useNextSidebarItem();

  if (step) {
    return (
      <NextUpContainer>
        <span>Next up</span>
        <StyledLink
          to={step.url}
          key={step.name}
          onClick={() =>
            track('dev:quickstartsNextStepSelected', { path: step.url })
          }
        >
          {step.name}
          <Icon src="/img/icons/arrow.svg" />
        </StyledLink>
      </NextUpContainer>
    );
  } else {
    return null;
  }
};
