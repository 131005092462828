export const REPORTING_URL = {
  bash: `https://rest.messagebird.com/reporting`,
};

export const SMS_METHODS = {
  bash: `GET /sms`,
};

export const SMS_EXAMPLE = {
  curl: `curl -X GET 'https://rest.messagebird.com/reporting/sms?periodStart=2019-01-01T00:00:00Z&periodEnd=2019-01-30T00:00:00Z&periodGroup=month&groupBy=country&groupBy=networkCode' \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -H 'Accept: application/json'`,
};

export const SMS_RESPONSE = {
  json: `{
    "items": [
       {
          "timestamp": "2019-01-01T00:00:00Z",
          "message": {
             "mcc": 204,
             "countryName": "Netherlands",
             "networkCode": 20402
          },
          "submittedCount": 2,
          "deliveredCount": 2,
          "processingCount": 0,
          "failedCount": 0,
          "deliveryImpossibleCount": 0
       },
       {
          "timestamp": "2019-01-01T00:00:00Z",
          "message": {
             "mcc": 204,
             "countryName": "Netherlands",
             "networkCode": 20416
          },
          "submittedCount": 1,
          "deliveredCount": 1,
          "processingCount": 0,
          "failedCount": 0,
          "deliveryImpossibleCount": 0
       }
    ],
    "ref": "59d4a651fada4873b6a8b173b0a4a180",
    "totalCount": 2
 }`,
};

export const SMS_EXAMPLE1 = {
  curl: `curl -X GET 'https://rest.messagebird.com/reporting/sms?periodStart=2018-04-01T00:00:00Z&periodEnd=2018-04-30T00:00:00Z&periodGroup=month&groupBy=country' \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -H 'Accept: application/json'`,
};

export const SMS_EXAMPLE2 = {
  curl: `curl --globoff -X GET 'https://rest.messagebird.com/reporting/sms?periodStart=2018-04-01T00:00:00Z&periodEnd=2018-04-30T00:00:00Z&periodGroup=month&filterBy[originator]=OmNomNom&filterBy[originator]=BeautyBird&groupBy=originator' \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -H 'Accept: application/json'`,
};

export const SMS_EXAMPLE3 = {
  curl: `curl --globoff -X GET 'https://rest.messagebird.com/reporting/sms?periodStart=2018-04-01T00:00:00Z&periodEnd=2018-04-30T00:00:00Z&periodGroup=day&filterBy[countryCode]=DE&groupBy=networkCode' \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -H 'Accept: application/json'`,
};

export const SMS_EXAMPLE4 = {
  curl: `curl -X GET 'https://rest.messagebird.com/reporting/sms?periodStart=2018-04-01T00:00:00Z&periodEnd=2018-09-30T00:00:00Z&periodGroup=day&offset=0&limit=100' \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -H 'Accept: application/json'`,
};

export const SMS_EXAMPLE5 = {
  curl: `curl -X GET 'https://rest.messagebird.com/reporting/sms?periodStart=2018-04-01T00:00:00Z&periodEnd=2018-09-30T00:00:00Z&periodGroup=day&offset=100&limit=100&ref=b5ba8e88b10011e896f8529269fb1459' \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -H 'Accept: application/json'`,
};

export const CALL_METHODS = {
  bash: `GET /call`,
};

export const CALL_EXAMPLE = {
  curl: `curl -X GET 'https://rest.messagebird.com/reporting/call?periodStart=2019-01-01T00:00:00Z&periodEnd=2019-01-30T00:00:00Z&periodGroup=month&groupBy=status&groupBy=sourceCountry&groupBy=sourceType' \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -H 'Accept: application/json'`,
};

export const CALL_RESPONSE = {
  json: `{
  "items": [
    {
      "timestamp": "2019-01-01T00:00:00Z",
      "call": {
        "source": {
          "countryCode": "US",
          "type": "country_code"
        },
        "status": "ended"
      },
      "totalDuration": 24,
      "averageDuration": 8,
      "count": 3,
      "successCount": 3,
      "failureCount": 0,
      "successRate": 1,
      "failureRate": 0
    },
    {
      "timestamp": "2019-01-01T00:00:00Z",
      "call": {
        "source": {
          "countryCode": "ES",
          "type": "landline"
        },
        "status": "ended"
      },
      "totalDuration": 17,
      "averageDuration": 8.5,
      "count": 2,
      "successCount": 2,
      "failureCount": 0,
      "successRate": 1,
      "failureRate": 0
    },
    {
      "timestamp": "2019-01-01T00:00:00Z",
      "call": {
        "source": {
          "countryCode": "ES",
          "type": "mobile"
        },
        "status": "ended"
      },
      "totalDuration": 50,
      "averageDuration": 8.333333,
      "count": 6,
      "successCount": 6,
      "failureCount": 0,
      "successRate": 1,
      "failureRate": 0
    },
    {
      "timestamp": "2019-01-01T00:00:00Z",
      "call": {
        "source": {
          "countryCode": "NL",
          "type": "mobile"
        },
        "status": "ended"
      },
      "totalDuration": 61,
      "averageDuration": 15.25,
      "count": 4,
      "successCount": 4,
      "failureCount": 0,
      "successRate": 1,
      "failureRate": 0
    }
  ],
  "ref": "821fa2bfb6114381b79748ceb53b0721",
  "totalCount": 4
}}`,
};

export const CALL_EXAMPLE1 = {
  curl: `curl -X GET 'https://rest.messagebird.com/reporting/call?periodStart=2018-08-01T00%3A00%3A00%2B08%3A00&periodEnd=2018-09-01T00%3A00%3A00%2B08%3A00&periodGroup=none&groupBy=sourceCountry&timezone=Asia/Shanghai' \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -H 'Accept: application/json'`,
};

export const CALL_EXAMPLE2 = {
  curl: `curl -X GET 'https://rest.messagebird.com/reporting/call?periodStart=2018-08-01T00%3A00%3A00%2B08%3A00&periodEnd=2018-09-01T00%3A00%3A00%2B08%3A00&timezone=Asia/Shanghai&periodGroup=day&groupBy=sourceCountry' \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -H 'Accept: application/json'`,
};

export const CALL_EXAMPLE3 = {
  curl: `curl --globoff -X GET 'https://rest.messagebird.com/reporting/call?periodStart=2018-08-01T00%3A00%3A00%2B08%3A00&periodEnd=2018-09-01T00%3A00%3A00%2B08%3A00&timezone=Asia/Shanghai&periodGroup=day&filterBy[sourceCountryCode]=NL' \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -H 'Accept: application/json'`,
};

export const CALL_EXAMPLE4 = {
  curl: `curl --globoff -X GET 'https://rest.messagebird.com/reporting/call?periodStart=2018-08-01T00%3A00%3A00%2B08%3A00&periodEnd=2018-09-01T00%3A00%3A00%2B08%3A00&timezone=Asia/Shanghai&periodGroup=day&filterBy[sourceCountryCode]=NL&filterBy[sourceCountryCode]=DE' \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -H 'Accept: application/json'`,
};

export const CALL_EXAMPLE5 = {
  curl: `curl --globoff -X GET 'https://rest.messagebird.com/reporting/call?periodStart=2018-08-01T00%3A00%3A00%2B08%3A00&periodEnd=2018-09-01T00%3A00%3A00%2B08%3A00&timezone=Asia/Shanghai&periodGroup=day&filterBy[sourceCountryCode]=NL&filterBy[status]=failed' \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -H 'Accept: application/json'`,
};

export const CALL_EXAMPLE6 = {
  curl: `curl --globoff -X GET 'https://rest.messagebird.com/reporting/call?periodStart=2018-08-01T00%3A00%3A00%2B08%3A00&periodEnd=2018-09-01T00%3A00%3A00%2B08%3A00&timezone=Asia/Shanghai&periodGroup=day&filterBy[sourceCountryCode]=NL&groupBy=sourceType' \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -H 'Accept: application/json'`,
};

export const CALL_EXAMPLE7 = {
  curl: `curl -X GET 'https://rest.messagebird.com/reporting/call?periodStart=2016-08-01T00%3A00%3A00%2B08%3A00&periodEnd=2018-09-01T00%3A00%3A00%2B08%3A00&offset=0&limit=100' \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -H 'Accept: application/json'`,
};

export const CALL_EXAMPLE8 = {
  curl: `curl -X GET 'https://rest.messagebird.com/reporting/call?periodStart=2016-08-01T00%3A00%3A00%2B08%3A00&periodEnd=2018-09-01T00%3A00%3A00%2B08%3A00&offset=100&limit=100&ref=03aa7194b10111e88981529269fb1459' \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -H 'Accept: application/json'`,
};

export const LEG_METHODS = {
  bash: `GET /leg`,
};

export const LEG_EXAMPLE = {
  curl: `curl -X GET 'https://rest.messagebird.com/reporting/leg?periodStart=2019-01-01T00:00:00Z&periodEnd=2019-01-30T00:00:00Z&periodGroup=month&groupBy=status&groupBy=sourceCountry&groupBy=sourceType' \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -H 'Accept: application/json'`,
};

export const LEG_RESPONSE = {
  json: `{
  "items": [
    {
      "timestamp": "2019-01-01T00:00:00Z",
      "leg": {
        "source": {
          "countryCode": "US",
          "type": "country_code"
        },
        "status": "HANGUP"
      },
      "totalDuration": 27,
      "averageDuration": 9,
      "count": 3,
      "successCount": 3,
      "failureCount": 0,
      "successRate": 1,
      "failureRate": 0
    },
    {
      "timestamp": "2019-01-01T00:00:00Z",
      "leg": {
        "source": {
          "countryCode": "ES",
          "type": "mobile"
        },
        "status": "HANGUP"
      },
      "totalDuration": 56,
      "averageDuration": 9.333333,
      "count": 6,
      "successCount": 6,
      "failureCount": 0,
      "successRate": 1,
      "failureRate": 0
    },
    {
      "timestamp": "2019-01-01T00:00:00Z",
      "leg": {
        "source": {
          "countryCode": "ES",
          "type": "landline"
        },
        "status": "HANGUP"
      },
      "totalDuration": 19,
      "averageDuration": 9.5,
      "count": 2,
      "successCount": 2,
      "failureCount": 0,
      "successRate": 1,
      "failureRate": 0
    },
    {
      "timestamp": "2019-01-01T00:00:00Z",
      "leg": {
        "source": {
          "countryCode": "NL",
          "type": "mobile"
        },
        "status": "HANGUP"
      },
      "totalDuration": 65,
      "averageDuration": 13,
      "count": 5,
      "successCount": 5,
      "failureCount": 0,
      "successRate": 1,
      "failureRate": 0
    }
  ],
  "ref": "c4f78607865e407eb9abf9856cc93e6e",
  "totalCount": 4
}`,
};

export const LEG_EXAMPLE1 = {
  curl: `curl -X GET 'https://rest.messagebird.com/reporting/leg?periodStart=2018-08-01T00%3A00%3A00%2B08%3A00&periodEnd=2018-09-01T00%3A00%3A00%2B08%3A00&periodGroup=none&groupBy=sourceCountry&timezone=Asia/Shanghai' -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' -H 'Accept: application/json'`,
};

export const LEG_EXAMPLE2 = {
  curl: `curl -X GET 'https://rest.messagebird.com/reporting/leg?periodStart=2018-08-01T00%3A00%3A00%2B08%3A00&periodEnd=2018-09-01T00%3A00%3A00%2B08%3A00&timezone=Asia/Shanghai&periodGroup=day&groupBy=sourceCountry' \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -H 'Accept: application/json'`,
};

export const LEG_EXAMPLE3 = {
  curl: `curl --globoff -X GET 'https://rest.messagebird.com/reporting/leg?periodStart=2018-08-01T00%3A00%3A00%2B08%3A00&periodEnd=2018-09-01T00%3A00%3A00%2B08%3A00&timezone=Asia/Shanghai&periodGroup=day&filterBy[sourceCountryCode]=NL' \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -H 'Accept: application/json'`,
};

export const LEG_EXAMPLE4 = {
  curl: `curl --globoff -X GET 'https://rest.messagebird.com/reporting/leg?periodStart=2018-08-01T00%3A00%3A00%2B08%3A00&periodEnd=2018-09-01T00%3A00%3A00%2B08%3A00&timezone=Asia/Shanghai&periodGroup=day&filterBy[sourceCountryCode]=NL&filterBy[sourceCountryCode]=DE' \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -H 'Accept: application/json'`,
};

export const LEG_EXAMPLE5 = {
  curl: `curl --globoff -X GET 'https://rest.messagebird.com/reporting/leg?periodStart=2018-08-01T00%3A00%3A00%2B08%3A00&periodEnd=2018-09-01T00%3A00%3A00%2B08%3A00&timezone=Asia/Shanghai&periodGroup=day&filterBy[sourceCountryCode]=NL&filterBy[status]=failed' \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -H 'Accept: application/json'`,
};

export const LEG_EXAMPLE6 = {
  curl: `curl --globoff -X GET 'https://rest.messagebird.com/reporting/leg?periodStart=2018-08-01T00%3A00%3A00%2B08%3A00&periodEnd=2018-09-01T00%3A00%3A00%2B08%3A00&timezone=Asia/Shanghai&periodGroup=day&filterBy[sourceCountryCode]=NL&groupBy=sourceType' \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -H 'Accept: application/json'`,
};

export const LEG_EXAMPLE7 = {
  curl: `curl -X GET 'https://rest.messagebird.com/reporting/leg?periodStart=2016-08-01T00%3A00%3A00%2B08%3A00&periodEnd=2018-09-01T00%3A00%3A00%2B08%3A00&offset=0&limit=100' \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -H 'Accept: application/json'`,
};

export const LEG_EXAMPLE8 = {
  curl: `curl -X GET 'https://rest.messagebird.com/reporting/leg?periodStart=2016-08-01T00%3A00%3A00%2B08%3A00&periodEnd=2018-09-01T00%3A00%3A00%2B08%3A00&offset=100&limit=100&ref=1d192ee0b10111e896f8529269fb1459' \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -H 'Accept: application/json'`,
};

export const CONVO_METHODS = {
  bash: `GET /conversations/message`,
};

export const CONVO_EXAMPLE = {
  curl: `curl -X GET 'https://rest.messagebird.com/reporting/conversations/message?periodStart=2019-01-01T00:00:00Z&periodEnd=2019-01-30T00:00:00Z&periodGroup=month&groupBy=status&groupBy=platform' \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -H 'Accept: application/json'`,
};

export const CONVO_RESPONSE = {
  json: `{
  "items": [
    {
      "timestamp": "2019-01-01T00:00:00Z",
      "message": {
        "platform": "whatsapp",
        "status": "deleted"
      },
      "count": 9
    },
    {
      "timestamp": "2019-01-01T00:00:00Z",
      "message": {
        "platform": "whatsapp",
        "status": "delivered"
      },
      "count": 12
    }
  ],
  "ref": "985de941e2a641699aac9556fce68b78",
  "total_count": 2
}`,
};

export const CONVO_EXAMPLE1 = {
  curl: `curl -X GET 'https://rest.messagebird.com/reporting/conversations/message?periodStart=2018-08-01T00%3A00%3A00%2B08%3A00&periodEnd=2018-09-01T00%3A00%3A00%2B08%3A00&periodGroup=none&groupBy=status&timezone=Asia/Shanghai' \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -H 'Accept: application/json'`,
};

export const FLOW_METHODS = {
  bash: `GET /flow`,
};

export const FLOW_EXAMPLE1 = {
  curl: `curl -X GET 'https://rest.messagebird.com/reporting/flow?periodStart=2018-08-01T00%3A00%3A00%2B08%3A00&periodEnd=2018-09-01T00%3A00%3A00%2B08%3A00&periodGroup=none&groupBy=trigger&timezone=Asia/Shanghai' \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -H 'Accept: application/json'`,
};

export const FLOW_EXAMPLE2 = {
  curl: `curl -X GET 'https://rest.messagebird.com/reporting/flow?periodStart=2018-08-01T00%3A00%3A00%2B08%3A00&periodEnd=2018-09-01T00%3A00%3A00%2B08%3A00&timezone=Asia/Shanghai&periodGroup=day&groupBy=trigger' \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -H 'Accept: application/json'`,
};

export const FLOW_EXAMPLE3 = {
  curl: `curl -X GET 'https://rest.messagebird.com/reporting/flow?periodStart=2016-08-01T00%3A00%3A00%2B08%3A00&periodEnd=2018-09-01T00%3A00%3A00%2B08%3A00&offset=0&limit=100' \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -H 'Accept: application/json'`,
};

export const FLOW_EXAMPLE4 = {
  curl: `curl -X GET 'https://rest.messagebird.com/reporting/flow?periodStart=2016-08-01T00%3A00%3A00%2B08%3A00&periodEnd=2018-09-01T00%3A00%3A00%2B08%3A00&offset=100&limit=100&ref=03aa7194b10111e88981529269fb1459' \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -H 'Accept: application/json'`,
};

export const INVOCATION_METHODS = {
  bash: `GET /invocation`,
};

export const INVOCATION_EXAMPLE = {
  curl: `curl -X GET 'https://rest.messagebird.com/reporting/invocation?periodStart=2019-01-01T00:00:00Z&periodEnd=2019-01-30T00:00:00Z&periodGroup=month&groupBy=status&groupBy=flowTrigger' \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -H 'Accept: application/json'`,
};

export const INVOCATION_RESPONSE = {
  json: `{
  "items": [
    {
      "timestamp": "2019-01-01T00:00:00Z",
      "invocation": {
        "status": "expired",
        "flowTrigger": "invalid"
      },
      "count": 1,
      "successCount": 0,
      "failureCount": 0,
      "successRate": 0,
      "failureRate": 0
    }
  ],
  "ref": "54730373ac1a4fdaba0814d82e10d16e",
  "totalCount": 1
}`,
};

export const INVOCATION_EXAMPLE1 = {
  curl: `curl -X GET 'https://rest.messagebird.com/reporting/invocation?periodStart=2018-08-01T00%3A00%3A00%2B08%3A00&periodEnd=2018-09-01T00%3A00%3A00%2B08%3A00&periodGroup=none&groupBy=flowTrigger&timezone=Asia/Shanghai' \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -H 'Accept: application/json'`,
};

export const INVOCATION_EXAMPLE2 = {
  curl: `curl -X GET 'https://rest.messagebird.com/reporting/invocation?periodStart=2018-08-01T00%3A00%3A00%2B08%3A00&periodEnd=2018-09-01T00%3A00%3A00%2B08%3A00&timezone=Asia/Shanghai&periodGroup=day&groupBy=flowTrigger' \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -H 'Accept: application/json'`,
};

export const INVOCATION_EXAMPLE3 = {
  curl: `curl -X GET 'https://rest.messagebird.com/reporting/invocation?periodStart=2016-08-01T00%3A00%3A00%2B08%3A00&periodEnd=2018-09-01T00%3A00%3A00%2B08%3A00&offset=0&limit=100' \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -H 'Accept: application/json'`,
};

export const INVOCATION_EXAMPLE4 = {
  curl: `curl -X GET 'https://rest.messagebird.com/reporting/invocation?periodStart=2016-08-01T00%3A00%3A00%2B08%3A00&periodEnd=2018-09-01T00%3A00%3A00%2B08%3A00&offset=100&limit=100&ref=03aa7194b10111e88981529269fb1459' \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -H 'Accept: application/json'`,
};

export const STEPS_METHODS = {
  bash: `GET /step`,
};

export const STEPS_EXAMPLE = {
  curl: `curl -X GET 'https://rest.messagebird.com/reporting/step?periodStart=2019-03-01T00:00:00Z&periodEnd=2019-03-30T00:00:00Z&periodGroup=month&groupBy=status' \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -H 'Accept: application/json'`,
};

export const STEPS_RESPONSE = {
  json: `{
  "items": [
    {
      "timestamp": "2019-01-01T00:00:00Z",
      "invocation": {
        "status": "expired",
        "flowTrigger": "invalid"
      },
      "count": 1,
      "successCount": 0,
      "failureCount": 0,
      "successRate": 0,
      "failureRate": 0
    }
  ],
  "ref": "54730373ac1a4fdaba0814d82e10d16e",
  "totalCount": 1
}`,
};

export const STEPS_EXAMPLE1 = {
  curl: `curl -X GET 'https://rest.messagebird.com/reporting/step?periodStart=2018-08-01T00%3A00%3A00%2B08%3A00&periodEnd=2018-09-01T00%3A00%3A00%2B08%3A00&periodGroup=none&groupBy=action&timezone=Asia/Shanghai' \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -H 'Accept: application/json'`,
};

export const STEPS_EXAMPLE2 = {
  curl: `curl -X GET 'https://rest.messagebird.com/reporting/step?periodStart=2018-08-01T00%3A00%3A00%2B08%3A00&periodEnd=2018-09-01T00%3A00%3A00%2B08%3A00&timezone=Asia/Shanghai&periodGroup=day&groupBy=action' \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -H 'Accept: application/json'`,
};

export const STEPS_EXAMPLE3 = {
  curl: `curl -X GET 'https://rest.messagebird.com/reporting/step?periodStart=2016-08-01T00%3A00%3A00%2B08%3A00&periodEnd=2018-09-01T00%3A00%3A00%2B08%3A00&offset=0&limit=100' \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -H 'Accept: application/json'`,
};

export const STEPS_EXAMPLE4 = {
  curl: `curl -X GET 'https://rest.messagebird.com/reporting/step?periodStart=2016-08-01T00%3A00%3A00%2B08%3A00&periodEnd=2018-09-01T00%3A00%3A00%2B08%3A00&offset=100&limit=100&ref=03aa7194b10111e88981529269fb1459' \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -H 'Accept: application/json'`,
};

export const EMAIL_METHODS = {
  bash: `GET /conversations/email`,
};

export const EMAIL_EXAMPLE = {
  curl: `curl -X GET 'https://rest.messagebird.com/reporting/conversations/email?periodStart=2019-06-01T00:00:00Z&periodEnd=2019-09-01T00:00:00Z&periodGroup=month&groupBy=status' \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -H 'Accept: application/json'`,
};

export const EMAIL_RESPONSE = {
  json: `{
  "items": [
    {
      "timestamp": "2019-06-01T00:00:00Z",
      "message": {
        "status": "received"
      },
      "count": 1
    },
    {
      "timestamp": "2019-06-01T00:00:00Z",
      "message": {
        "status": "sent"
      },
      "count": 8
    }
  ],
  "ref": "e84a7c0b943d4ba7bec73cc956a6b113",
  "totalCount": 2
}`,
};

export const EMAIL_EXAMPLE_JUNE = {
  curl: `curl -X GET 'https://rest.messagebird.com/reporting/conversations/email?periodStart=2019-06-01T00%3A00%3A00%2B08%3A00&periodEnd=2019-07-01T00%3A00%3A00%2B08%3A00&periodGroup=none&offset=0&limit=100' \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -H 'Accept: application/json'`,
};

export const EMAIL_EXAMPLE_MONTHLY = {
  curl: `curl -X GET 'https://rest.messagebird.com/reporting/conversations/email?periodStart=2019-01-01T00%3A00%3A00%2B08%3A00&periodEnd=2020-01-01T00%3A00%3A00%2B08%3A00&periodGroup=month&offset=0&limit=100' \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -H 'Accept: application/json'`,
};

export const EMAIL_EXAMPLE_STATUS = {
  curl: `curl -X GET 'https://rest.messagebird.com/reporting/conversations/email?periodStart=2019-06-01T00%3A00%3A00%2B08%3A00&periodEnd=2019-07-01T00%3A00%3A00%2B08%3A00&periodGroup=none&groupBy=status&offset=0&limit=100' \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -H 'Accept: application/json'`,
};

export const EMAIL_EXAMPLE_AMSTERDAM = {
  curl: `curl -X GET 'https://rest.messagebird.com/reporting/conversations/email?periodStart=2019-03-01T00%3A00%3A00%2B01%3A00&periodEnd=2019-04-01T00%3A00%3A00%2B02%3A00&periodGroup=day&timezone=Europe/Amsterdam&offset=0&limit=100' \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -H 'Accept: application/json'`,
};

export const EMAIL_EXAMPLE_FILTER = {
  curl: `curl -X GET 'https://rest.messagebird.com/reporting/conversations/email?periodStart=2019-06-01T00%3A00%3A00%2B01%3A00&periodEnd=2019-07-01T00%3A00%3A00%2B02%3A00&periodGroup=day&filterBy%5Bstatus%5D=received&offset=0&limit=100' \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -H 'Accept: application/json'`,
};

export const EMAIL_EXAMPLE_PART1 = {
  curl: `curl -X GET 'https://rest.messagebird.com/reporting/conversations/email?periodStart=2019-01-01T00%3A00%3A00%2B01%3A00&periodEnd=2020-01-01T00%3A00%3A00%2B02%3A00&periodGroup=day&offset=0&limit=100' \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -H 'Accept: application/json'`,
};

export const EMAIL_EXAMPLE_PART2 = {
  curl: `curl -X GET 'https://rest.messagebird.com/reporting/conversations/email?periodStart=2019-01-01T00%3A00%3A00%2B01%3A00&periodEnd=2020-01-01T00%3A00%3A00%2B02%3A00&periodGroup=day&offset=100&limit=100&ref=0226f90e567d43c6ae160fe0ddfa5560' \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -H 'Accept: application/json'`,
};
