import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Docs.tsx";
import Notification from 'components/Notification';
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`SIP Trunking`}</h1>
    <p>{`MessageBird's SIP Trunking allows you the possibility to place outbound voice calls from your VoIP platform to phones anywhere in the world.  `}</p>
    <p>{`A SIP-trunk makes calls via the public internet or a private IP-connection. Our SIP-trunks run authentication protocols on your IP-addresses and/or a username-password combination. In this quickstart, you'll learn how to set up accepting inbound SIP-calls. Keep in mind that it's currently not possible to SIP-register with MessageBird.`}</p>
    <h2>{`How to set up SIP trunking:`}</h2>
    <p>{`To start making outbound calls, you need to authenticate your VoIP platform. The easiest way is to add the IP address of your VoIP platform in your `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/"
      }}>{`MessageBird Dashboard`}</a>{`.`}</p>
    <p>{`Select `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/sip-trunking"
      }}>{`SIP Trunking`}</a>{` on the 'Voice' icon in the left-panel menu, hit 'Add new', and fill in your details.`}</p>
    <h3>{`Step 1: Add an ACL (Access Control List)`}</h3>
    <p>{`An ACL is used to define which IP-addresses and/or username-password accounts are allowed to use yours. To manage the authentication of your VoIP infrastructure, you can create multiple ACLs, each with one or more accounts.`}</p>
    <p>{`Click on '`}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/sip-acls"
      }}>{`Access Control`}</a>{`' and hit the 'Add New' button. Name each ACL in a new pop-up window so you can easily recognize them. After saving the ACL, it will appear in the overview.`}</p>
    <p>{`When you click the ACL name, you'll see a new screen where you can add IP-addresses and/or username-password accounts. The options are:`}</p>
    <ul>
      <li parentName="ul">{`IP-address`}</li>
      <li parentName="ul">{`Username/password`}</li>
      <li parentName="ul">{`IP-address and a combination of username/password.`}</li>
    </ul>
    <p>{`If you have entered both an IP-address and a username/password, both need to be used in the SIP-INVITE header.`}</p>
    <p>{`This is an example of a FROM field as part of SIP-INVITE header with username 31000000: `}</p>
    <ul>
      <li parentName="ul"><strong parentName="li"><em parentName="strong">{`SIP from address: sip:31000000@`}{`[yourdomain]`}{`.sip.messagebird.com;transport=UDP`}</em></strong></li>
    </ul>
    <h3>{`Step 2: Add a Subdomain`}</h3>
    <p>{`A subdomain is a personal DNS name inside the `}<a parentName="p" {...{
        "href": "https://messagebird.com/"
      }}>{`Messagebird.com`}</a>{` domain, for example, `}<a parentName="p" {...{
        "href": "http://yourcompany.sip.messagebird.com/"
      }}>{`yourcompany.sip.messagebird.com`}</a>{`. Only calls to this specific subdomain will be authenticated by the attached ACL’s.`}</p>
    <p>{`To add a subdomain, go to '`}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/sip-trunking"
      }}>{`SIP Trunks`}</a>{`', hit the 'Add new' button. You'll see a pop-up screen where you can insert your subdomain name.`}</p>
    <p>{`The field 'Domain' is the address used for your SIP-communication. A valid domain name contains:`}</p>
    <ul>
      <li parentName="ul">{`Minimum of 1 character`}</li>
      <li parentName="ul">{`Valid characters range from 0-9, a-z and A-Z`}</li>
    </ul>
    <p>{`After creating a Domain, you can attach an ACL to this Domain by selecting the domain in the left-hand menu and clicking on 'Attach Access Control List'. Choose the preferred ACL to attach to this domain and save your choice.`}</p>
    <p>{`On the SIP Trunks page, you’ll find an overview of the ACLs attached to this particular domain. Keep in mind that a domain can have multiple attached ACLs and an ACL can be attached to multiple domains`}</p>
    <p><strong parentName="p">{`That's it!`}</strong>{` 🎉`}</p>
    <p>{`You're  all ready to place calls from your VoIP platform to our SIP servers.`}</p>
    <p>{`Remember that only accounts attached to this domain can place calls, so the full domain name should be used in the SIP-INVITE header. `}</p>
    <p>{`This is an example of a SIP-INVITE header: `}</p>
    <ul>
      <li parentName="ul"><strong parentName="li"><em parentName="strong">{`sip:`}{`[your_account]`}{`@`}{`[your_domain]`}{`.sip.messagebird.com`}</em></strong></li>
    </ul>
    <h3>{`Localisation (optional)`}</h3>
    <p>{`For customers that require localised SIP trunking we are available in 3 regions: Asia, Europe, US.`}</p>
    <p>{`By using the default configuration of `}<inlineCode parentName="p">{`[your_domain].sip.messagebird.com`}</inlineCode>{` your traffic will be redirected to our European servers or, in the case of a local failure, to one of the 3 available regions.
To be able to keep your traffic bound to one region and avoid an additional hop through our European servers, you can configure the domain name as follows:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Asia`}</strong>{`:
`}{`[your_domain]`}{`.apac1.sip.messagebird.com
`}{`[your_domain]`}{`.apac2.sip.messagebird.com`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`USA`}</strong>{`:
`}{`[your_domain]`}{`.us1.sip.messagebird.com
`}{`[your_domain]`}{`.us2.sip.messagebird.com`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`EU`}</strong>{`:
`}{`[your_domain]`}{`.eu1.sip.messagebird.com
`}{`[your_domain]`}{`.eu2.sip.messagebird.com
By using these subdomains inter-region failover is not guaranteed.`}</p>
      </li>
    </ul>
    <h2>{`What's next?`}</h2>
    <p>{`You may now want to check out these resources:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://support.messagebird.com/hc/en-us/articles/360000708169-SIP-Server-connection-details"
        }}>{`SIP server connection details for SIP trunking`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://blog.messagebird.com/5-reasons-to-use-messagebird-sip-trunking-14cd16c208ac"
        }}>{`Five reasons to use MessageBird's SIP Trunking`}</a></li>
    </ul>
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      