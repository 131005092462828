import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Setting SMS notifications with MessageBird`}</h1>
    <h3>{`⏱ 15 min build time      ||      `}<a parentName="h3" {...{
        "href": "https://github.com/messagebirdguides/notifications-guide"
      }}>{`Download the Code`}</a></h3>
    <h2>{`Why build SMS order notifications?`}</h2>
    <p>{`In this MessageBird Developer Tutorial we'll show you how to keep your customers up to date with this easy-to-build runnable order notification application powered by the `}<a parentName="p" {...{
        "href": "/api/sms-messaging"
      }}>{`MessageBird SMS Messaging API`}</a>{`.
Have you ever ordered home delivery to find yourself wondering whether your order was received correctly and how long it will take to arrive? Some experiences are seamless and others... not so much.`}</p>
    <p>{`For on-demand industries such as food delivery, ride-sharing and logistics, excellent customer service during the ordering process is essential. One easy way to stand out from the crowd is providing proactive communication to keep your customers in the loop about the status of their orders. Regardless of whether your customer is waiting for a package delivery or growing hangry (a combination of hungry and angry) awaiting their food delivery, sending timely SMS order notifications is a great strategy to create a seamless user experience.`}</p>
    <p>{`The `}<a parentName="p" {...{
        "href": "/api/sms-messaging"
      }}>{`MessageBird SMS Messaging API`}</a>{` provides an easy way to fully automate and integrate a notifications application into your order handling software. Busy employees can trigger the notifications application with the push of a single button. No more confused hangry customers and great user experience, just like that!`}</p>
    <h2>{`Getting started`}</h2>
    <p>{`The application is a prototype order management system build in Node.js for our fictitious food delivery company, `}<em parentName="p">{`Birdie NomNom Foods`}</em>{`.`}</p>
    <p>{`Birdie NomNom Foods have set up the following workflow:`}</p>
    <ul>
      <li parentName="ul">{`New incoming orders are in a `}<strong parentName="li">{`pending`}</strong>{` state.`}</li>
      <li parentName="ul">{`Once the kitchen starts preparing an order, it moves to the `}<strong parentName="li">{`confirmed`}</strong>{` state. A message is sent to the customer to inform them about this.`}</li>
      <li parentName="ul">{`When the food is made and handed over to the delivery driver, staff marks the order as `}<strong parentName="li">{`delivered`}</strong>{`. A message is sent to the customer to let them know it will arrive briefly.`}</li>
      <li parentName="ul">{`If preparation takes longer than expected, it can be moved to a `}<em parentName="li">{`delayed_`}</em>{`state. A message is sent to the customer asking them to hang on just a little while longer. `}</li>
    </ul>
    <p>{`Thanks to this workflow, Birdie NomNom Foods saves time, money, and energy that would otherwise be spent answering `}<em parentName="p">{`"Where's my order?"`}</em>{` calls.
To run the application, you’ll need Node and npm, you can easily `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/get-npm"
      }}>{`install them for free`}</a>{`.`}</p>
    <p><strong parentName="p">{`Pro-tip`}</strong>{`: Follow this tutorial to build the whole application from scratch or, if you want to see it in action right away, you can download, clone or fork the sample application from the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/notifications-guide"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`.`}</p>
    <p>{`Let's open a console pointed to the directory into which you've placed the sample application and run the following command to install it:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install
`}</code></pre>
    <p>{`This command will install the `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/messagebird"
      }}>{`MessageBird SDK for Node.js`}</a>{` and other dependencies from npm as defined in `}<inlineCode parentName="p">{`package.json`}</inlineCode>{`.`}</p>
    <h2>{`Configuring the MessageBird SDK`}</h2>
    <p>{`Now, let's open `}<inlineCode parentName="p">{`index.js`}</inlineCode>{`, the main file of the sample application. You'll find the following lines:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// Load and initialize MessageBird SDK
var messagebird = require('messagebird')('YOUR_API_KEY');
`}</code></pre>
    <p>{`Next, we'll replace the string `}<em parentName="p">{`YOUR_API_KEY`}</em>{` with a `}<em parentName="p">{`live`}</em>{` access key. You can create or retrieve a key from the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`API access (REST) tab`}</a>{` in the `}<em parentName="p">{`Developers`}</em>{` section in the MessageBird Dashboard. It's also possible to use a `}<em parentName="p">{`test`}</em>{` key to test the application. In this case, you can see the API output on the console, but no live SMS messages will be sent.`}</p>
    <p><strong parentName="p">{`Pro-tip:`}</strong>{` Hardcoding your credentials in the code is a risky practice that should never be used in production applications. A better method, also recommended by the `}<a parentName="p" {...{
        "href": "https://12factor.net/"
      }}>{`Twelve-Factor App Definition`}</a>{`, is to use environment variables. `}</p>
    <p>{`We've added `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/dotenv"
      }}>{`dotenv`}</a>{` to the sample application, so that you can supply your API key in a file named `}<inlineCode parentName="p">{`.env`}</inlineCode>{` too:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-env"
      }}>{`MESSAGEBIRD_API_KEY=YOUR-API-KEY
`}</code></pre>
    <h2>{`Notifying customers by triggering an SMS:`}</h2>
    <p>{`The sample application triggers SMS delivery in the `}<inlineCode parentName="p">{`/updateOrder`}</inlineCode>{` route together with updating the stored data.`}</p>
    <p>{`Sending a message with the MessageBird SDK is straightforward—we simply call the `}<inlineCode parentName="p">{`messagebird.messages.create()`}</inlineCode>{` method with a parameters object.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// Send the message through MessageBird API
messagebird.messages.create({
   originator : 'Birdy NomNom',
   recipients : [ order.phone ],
   body : body
}, function (err, response) {
   if (err) {
       // Request has failed
       console.log(err);
       res.send("Error occured while sending message!");
   } else {
       // Request was successful
       console.log(response);
       res.redirect('/');
   }
});
`}</code></pre>
    <p>{`You should set at least the following attributes in this object:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`originator`}</inlineCode>{`: A sender ID for the SMS, either a telephone number `}<a parentName="li" {...{
          "href": "https://support.messagebird.com/hc/en-us/articles/115003950149-Numbers-format-"
        }}>{`including country code`}</a>{`, or an alphanumeric string with at most 11 characters. Keep in mind that alphanumeric senders are not supported in every country including the United States, so it’s important to check the `}<a parentName="li" {...{
          "href": "https://support.messagebird.com/hc/en-us/sections/360000108538-Country-info-Restrictions"
        }}>{`country restrictions`}</a>{`. If you can't use alphanumeric IDs, use a real phone number instead. You can check our `}<a parentName="li" {...{
          "href": "https://support.messagebird.com/hc/en-us/articles/115002628665-What-is-the-originator-"
        }}>{`originator article`}</a>{` in Help Center to learn more about this topic.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`recipients`}</inlineCode>{`: An array of one or more phone numbers to send the message to.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`body`}</inlineCode>{`: The content of the message.`}</li>
    </ul>
    <p>{`Make sure to replace the values in the sample code with adequate data for testing. There are additional optional attributes as well; you can find them in the `}<a parentName="p" {...{
        "href": "/api/messaging#messaging-send"
      }}>{`SMS Messaging API documentation`}</a>{`.`}</p>
    <p>{`The MessageBird API call is asynchronous and executes a callback function once finished. This callback function takes two parameters, `}<inlineCode parentName="p">{`err`}</inlineCode>{` and `}<inlineCode parentName="p">{`response`}</inlineCode>{`. If `}<inlineCode parentName="p">{`err`}</inlineCode>{` is defined, it indicates that something went wrong with the request. If everything went well, `}<inlineCode parentName="p">{`response`}</inlineCode>{` contains… you guessed it, the response from the API.`}</p>
    <h2>{`Testing`}</h2>
    <p>{`You’re done! The sample application works on a set of test data defined in a variable called `}<inlineCode parentName="p">{`OrderDatabase`}</inlineCode>{` in `}<inlineCode parentName="p">{`index.js`}</inlineCode>{`. To test the full flow, replace one of the phone numbers with your own to receive the message on your phone:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// Set up Order "Database"
var OrderDatabase = [
   {
       name : 'Hannah Hungry',
       phone : '+319876543210', // <- put your number here for testing
       items : '1 x Hipster Burger + Fries',
       status : 'pending'
   },
   {
       name : 'Mike Madeater',
       phone : '+319876543211', // <- put your number here for testing
       items : '1 x Chef Special Mozzarella Pizza',
       status : 'pending'
   }
];
`}</code></pre>
    <p>{`It's time to run the following command from your console:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`node index.js
`}</code></pre>
    <p>{`Open `}<a parentName="p" {...{
        "href": "http://localhost:8080/"
      }}>{`http://localhost:8080/`}</a>{` in your browser to see the list of orders.`}</p>
    <p>{`Click on one of the buttons in the `}<em parentName="p">{`Action`}</em>{` column to trigger a status change and, at the same time, automatically send a message.`}</p>
    <p>{`Awesome! You can use the flow, code snippets, and UI examples from this tutorial as an inspiration to build your own SMS Notifications system. Don't forget to download the code from the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/notifications-guide"
      }}>{`MessageBird Developer Tutorial GitHub repository`}</a>{`.`}</p>
    <p><strong parentName="p">{`Nice work!`}</strong>{` 🎉`}</p>
    <p>{`You now have a running SMS Notifications application with Node.js!`}</p>
    <h2>{`Start building!`}</h2>
    <p>{`Want to build something similar but not quite sure how to get started? Feel free to let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we'd love to help!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      