import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import Notification from 'components/Notification';
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
import { Img } from 'components/shared';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Install Channel: Email`}</h1>
    <p>{`Let's get started with the Programmable Conversations API by learning how to install a channel. In this quickstart, you'll learn how to set up Email.`}</p>
    <p>{`Before we go ahead, did you request early access?`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`No?`}</strong>{` Make sure `}<a parentName="li" {...{
          "href": "https://www.messagebird.com/en/email/"
        }}>{`to do so`}</a>{` before continuing with this quickstart.`}</li>
      <li parentName="ul"><strong parentName="li">{`Yes?`}</strong>{` Great! Go to step 2.`}</li>
    </ul>
    <h2>{`Step 1: Go to the channels directory in your Dashboard`}</h2>
    <p>{`Go to '`}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/channels"
      }}>{`Channels`}</a>{`' in the sidebar of your MessageBird Dashboard. Then click on `}<inlineCode parentName="p">{`Add channel`}</inlineCode>{` under Email.`}</p>
    <Img src="/img/screenshots/quickstarts/convo/InstallEmail1.png" alt="Install Email" width="650px" align="middle" mdxType="Img" />
    <h2>{`Step 2: Give your channel a name`}</h2>
    <p>{`Name your channel, make sure it's easily recognizable for yourself and your team. Don't worry, this will only be used internally.`}</p>
    <Img src="/img/screenshots/quickstarts/convo/InstallEmail2.png" alt="Install Email" width="650px" align="middle" mdxType="Img" />
    <h2>{`Step 3: Add your domain name`}</h2>
    <p>{`Add your domain. Please make sure that you own the domain and have access to its Domain Name System (DNS) record.`}</p>
    <Img src="/img/screenshots/quickstarts/convo/InstallEmail3.png" alt="Install Email" width="650px" align="middle" mdxType="Img" />
    <h2>{`Step 4: Add your webhook URL`}</h2>
    <p>{`Add the URL from which you want to receive webhooks. Keep in mind that this webhook will only be used to receive inbound emails.`}</p>
    <Img src="/img/screenshots/quickstarts/convo/InstallEmail4
.png" alt="Install Email" width="650px" align="middle" mdxType="Img" />
    <h2>{`Step 5: Install Email`}</h2>
    <p>{`Click on 'Install now' and voilà! Email is now installed as a channel.`}</p>
    <h2>{`Step 6: Manage your channel`}</h2>
    <p>{`If you want to edit, rename or delete your channel, simply select the channel and save the changes afterwards.`}</p>
    <p>{`Nice work! 🎉 You've successfully installed Email as a channel for the MessageBird Programmable Conversations API.`}</p>
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'Install Channel: SMS',
      to: '/docs/conversations/install-channel'
    }, {
      name: 'Install Channel: WhatsApp',
      to: '/docs/conversations/install-channel-whatsapp'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      