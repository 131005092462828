import { Link } from "gatsby";
import Notification from 'components/Notification';
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
import * as React from 'react';
export default {
  Link,
  Notification,
  NextSteps,
  Questions,
  React
};