export const CONTACTS_URI = {
  bash: `https://rest.messagebird.com/contacts`,
};

export const CONTACTS_METHODS = {
  bash: `POST /contacts
GET /contacts
GET /contacts/{contactId}
GET /contacts/{contactId}/groups
GET /contacts/{contactId}/messages
PATCH /contacts/{contactId}
DELETE /contacts/{contactId}`,
};

export const CONTACTS_OBJECT = {
  json: `{
  "id": "61afc0531573b08ddbe36e1c85602827",
  "href": "https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827",
  "msisdn": 31612345678,
    "firstName": "John",
    "lastName": "Doe",
  "customDetails": {
    "custom1": "",
    "custom2": "",
    "custom3": "",
    "custom4": ""
  },
  "groups": {
    "totalCount": 0,
    "href": "https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827/groups"
  },
  "messages": {
    "totalCount": 0,
    "href": "https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827/messages"
  },
  "createdDatetime": "2016-04-29T09:42:26+00:00",
  "updatedDatetime": "2016-04-29T09:42:26+00:00"
}`,
};

export const CREATECONTACTS_DEF = {
  bash: `POST https://rest.messagebird.com/contacts`,
};

export const CREATECONTACTS_REQUEST = {
  curl: `curl -X POST https://rest.messagebird.com/contacts \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -d "msisdn=31612345678"`,
  php: `$Contact = new \\MessageBird\\Objects\\Contact();
$Contact->msisdn = "31123456780";
$Contact->firstName = "FirstName";
$Contact->lastName = "LastName";

$ContactResult = $MessageBird->contacts->create($Contact);`,
  ruby: `msisdn = '31612345678'
first_name = 'Foo'

contact = client.contact_create(msisdn, :firstName => first_name)`,
  csharp: `ContactOptionalArguments optionalArguments = new ContactOptionalArguments
{
    FirstName = "Foo"
};

Contact contact = client.CreateContact(31612345678, optionalArguments);`,
  go: `result, err := contact.Create(c, &contact.Request{
  MSISDN: "31612345678",
})`,
  python: `contact = client.contact_create('31612345678')`,
  java: `ContactRequest request = new ContactRequest();
request.setMsisdn("31612345678");
request.setFirstName("Foo");
request.setLastName("Bar");

Contact contact = client.sendContact(request);`,
  node: `messagebird.contacts.create(31612345678, function (err, response) {
  if (err)
    return console.log(err);
  console.log(response);
});`,
};

export const CREATECONTACTS_RESPONSE = {
  curl: `{
  "id": "61afc0531573b08ddbe36e1c85602827",
  "href": "https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827",
  "msisdn": 31612345678,
  "firstName": "John",
  "lastName": "Doe",
  "customDetails": {
    "custom1": "",
    "custom2": "",
    "custom3": "",
    "custom4": ""
  },
  "groups": {
    "totalCount": 0,
    "href": "https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827/groups"
  },
  "messages": {
    "totalCount": 0,
    "href": "https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827/messages"
  },
  "createdDatetime": "2016-04-29T09:42:26+00:00",
  "updatedDatetime": "2016-04-29T09:42:26+00:00"
}`,
  php: `{
  "id": "61afc0531573b08ddbe36e1c85602827",
  "href": "https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827",
  "msisdn": 31612345678,
  "firstName": "John",
  "lastName": "Doe",
  "customDetails": {
    "custom1": "",
    "custom2": "",
    "custom3": "",
    "custom4": ""
  },
  "groups": {
    "totalCount": 0,
    "href": "https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827/groups"
  },
  "messages": {
    "totalCount": 0,
    "href": "https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827/messages"
  },
  "createdDatetime": "2016-04-29T09:42:26+00:00",
  "updatedDatetime": "2016-04-29T09:42:26+00:00"
}`,
  ruby: `#<MessageBird::Contact:0x00007f9b1782f5a0
@createdDatetime=2018-09-16 11:35:07 +0000,
@customDetails=
  #<MessageBird::CustomDetails:0x00007f9b1782f438
  @custom1=nil,
  @custom2=nil,
  @custom3=nil,
  @custom4=nil>,
@firstName="Foo",
@groups=
  #<MessageBird::GroupReference:0x00007f9b1782f348
  @href=
    "https://rest.messagebird.com/contacts/3092d38f13cb48879b9a7a98f32f987a/groups",
  @totalCount=0>,
@href=
  "https://rest.messagebird.com/contacts/3092d38f13cb48879b9a7a98f32f987a",
@id="3092d38f13cb48879b9a7a98f32f987a",
@lastName=nil,
@messages=
  #<MessageBird::MessageReference:0x00007f9b1782f280
  @href=
    "https://rest.messagebird.com/contacts/3092d38f13cb48879b9a7a98f32f987a/messages",
  @totalCount=0>,
@msisdn=31612345678,
@updatedDatetime=2018-09-16 11:35:07 +0000>`,
  csharp: `{
  "id": "61afc0531573b08ddbe36e1c85602827",
  "href": "https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827",
  "msisdn": 31612345678,
  "firstName": "John",
  "lastName": "Doe",
  "customDetails": {
    "custom1": "",
    "custom2": "",
    "custom3": "",
    "custom4": ""
  },
  "groups": {
    "totalCount": 0,
    "href": "https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827/groups"
  },
  "messages": {
    "totalCount": 0,
    "href": "https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827/messages"
  },
  "createdDatetime": "2016-04-29T09:42:26+00:00",
  "updatedDatetime": "2016-04-29T09:42:26+00:00"
}`,
  go: `&contact.Contact {
  ID:"b15d7d0b5ce740ddadcb08e6e1c9053a",
  HRef:"https://rest.messagebird.com/contacts/b15d7d0b5ce740ddadcb08e6e1c9053a",
  MSISDN:3161234567,
  FirstName:"",
  LastName:"",
  CustomDetails: {
      Custom1:"",
      Custom2:"",
      Custom3:"",
      Custom4:"",
  },
  Groups: {
      TotalCount:0,
      HRef:"https://rest.messagebird.com/contacts/b15d7d0b5ce740ddadcb08e6e1c9053a/groups",
  },
  Messages: {
      TotalCount:0,
      HRef:"https://rest.messagebird.com/contacts/b15d7d0b5ce740ddadcb08e6e1c9053a/messages",
  },
  CreatedDatetime: ...,
  UpdatedDatetime: ...,
}`,
  python: `{
  "id": "61afc0531573b08ddbe36e1c85602827",
  "href": "https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827",
  "msisdn": 31612345678,
  "firstName": "John",
  "lastName": "Doe",
  "customDetails": {
    "custom1": "",
    "custom2": "",
    "custom3": "",
    "custom4": ""
  },
  "groups": {
    "totalCount": 0,
    "href": "https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827/groups"
  },
  "messages": {
    "totalCount": 0,
    "href": "https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827/messages"
  },
  "createdDatetime": "2016-04-29T09:42:26+00:00",
  "updatedDatetime": "2016-04-29T09:42:26+00:00"
}
`,
  java: `com.messagebird.objects.Contact@731f8236[
  id=61afc0531573b08ddbe36e1c85602827
  href=https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827
  msisdn=31612345678
  firstName=Foo
  lastName=Bar
  groups=GroupReference{href='https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827/messages', totalCount=0}
  messages=MessageReference{href='https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827/messages', totalCount=0}
  createdDatetime=Fri Apr 29 11:42:26 CEST 2016
  updatedDatetime=Fri Apr 29 11:42:26 CEST 2016
  customDetails=CustomDetails{custom1='', custom2='', custom3='', custom4=''}
]`,
  node: `{ id: '61afc0531573b08ddbe36e1c85602827',
href:
  'https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827',
msisdn: 31612345678,
firstName: 'John',
lastName: 'Doe',
customDetails: { custom1: '', custom2: '', custom3: '', custom4: '' },
groups:
  { totalCount: 0,
    href:
    'https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827/groups' },
messages:
  { totalCount: 0,
    href:
    'https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827/messages' },
createdDatetime: '2016-04-29T09:42:26+00:00',
updatedDatetime: '2016-04-29T09:42:26+00:00' }`,
};

export const VIEWCONTACTS_DEF = {
  bash: `GET https://rest.messagebird.com/contacts/{contactId}`,
};

export const VIEWCONTACTS_REQUEST = {
  curl: `curl -X GET https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827 \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM'`,
  php: `$ContactResult = $MessageBird->contacts->read('61afc0531573b08ddbe36e1c85602827');`,
  ruby: `existing_id = '3092d38f13cb48879b9a7a98f32f987a'

contact = client.contact(existing_id)`,
  csharp: `string existingId = "61afc0531573b08ddbe36e1c85602827";

Contact contact = client.ViewContact(existingId);`,
  go: `result, err := contact.Read(c, "b15d7d0b5ce740ddadcb08e6e1c9053a")`,
  python: `existing_id = 'b15d7d0b5ce740ddadcb08e6e1c9053a'

contact = client.contact(existing_id)`,
  java: `String existingId = "61afc0531573b08ddbe36e1c85602827";

Contact contact = client.viewContact(existingId);`,
  node: `messagebird.contacts.read('61afc0531573b08ddbe36e1c85602827', function (err, response) {
  if (err)
    return console.log(err);
  console.log(response);
});`,
};

export const VIEWCONTACTS_RESPONSE = {
  curl: `{
  "id": "61afc0531573b08ddbe36e1c85602827",
  "href": "https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827",
  "msisdn": 31612345678,
  "firstName": "John",
  "lastName": "Doe",
  "customDetails": {
    "custom1": "",
    "custom2": "",
    "custom3": "",
    "custom4": ""
  },
  "groups": {
    "totalCount": 0,
    "href": "https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827/groups"
  },
  "messages": {
    "totalCount": 0,
    "href": "https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827/messages"
  },
  "createdDatetime": "2016-04-29T09:42:26+00:00",
  "updatedDatetime": "2016-04-29T09:42:26+00:00"
}`,
  php: `{
  "id": "61afc0531573b08ddbe36e1c85602827",
  "href": "https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827",
  "msisdn": 31612345678,
  "firstName": "John",
  "lastName": "Doe",
  "customDetails": {
    "custom1": "",
    "custom2": "",
    "custom3": "",
    "custom4": ""
  },
  "groups": {
    "totalCount": 0,
    "href": "https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827/groups"
  },
  "messages": {
    "totalCount": 0,
    "href": "https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827/messages"
  },
  "createdDatetime": "2016-04-29T09:42:26+00:00",
  "updatedDatetime": "2016-04-29T09:42:26+00:00"
}`,
  ruby: `#<MessageBird::Contact:0x00007f9b1782f5a0
@createdDatetime=2018-09-16 11:35:07 +0000,
@customDetails=
  #<MessageBird::CustomDetails:0x00007f9b1782f438
  @custom1=nil,
  @custom2=nil,
  @custom3=nil,
  @custom4=nil>,
@firstName=nil,
@groups=
  #<MessageBird::GroupReference:0x00007f9b1782f348
  @href=
    "https://rest.messagebird.com/contacts/3092d38f13cb48879b9a7a98f32f987a/groups",
  @totalCount=0>,
@href=
  "https://rest.messagebird.com/contacts/3092d38f13cb48879b9a7a98f32f987a",
@id="3092d38f13cb48879b9a7a98f32f987a",
@lastName=nil,
@messages=
  #<MessageBird::MessageReference:0x00007f9b1782f280
  @href=
    "https://rest.messagebird.com/contacts/3092d38f13cb48879b9a7a98f32f987a/messages",
  @totalCount=0>,
@msisdn=31612345678,
@updatedDatetime=2018-09-16 11:35:07 +0000>
`,
  csharp: `{
  "id": "61afc0531573b08ddbe36e1c85602827",
  "href": "https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827",
  "msisdn": 31612345678,
  "firstName": "John",
  "lastName": "Doe",
  "customDetails": {
    "custom1": "",
    "custom2": "",
    "custom3": "",
    "custom4": ""
  },
  "groups": {
    "totalCount": 0,
    "href": "https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827/groups"
  },
  "messages": {
    "totalCount": 0,
    "href": "https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827/messages"
  },
  "createdDatetime": "2016-04-29T09:42:26+00:00",
  "updatedDatetime": "2016-04-29T09:42:26+00:00"
}`,
  go: `&contact.Contact {
  ID:"b15d7d0b5ce740ddadcb08e6e1c9053a",
  HRef:"https://rest.messagebird.com/contacts/b15d7d0b5ce740ddadcb08e6e1c9053a",
  MSISDN:3161234567,
  FirstName:"",
  LastName:"",
  CustomDetails: {
      Custom1:"",
      Custom2:"",
      Custom3:"",
      Custom4:"",
  },
  Groups: {
      TotalCount:0,
      HRef:"https://rest.messagebird.com/contacts/b15d7d0b5ce740ddadcb08e6e1c9053a/groups",
  },
  Messages: {
      TotalCount:0,
      HRef:"https://rest.messagebird.com/contacts/b15d7d0b5ce740ddadcb08e6e1c9053a/messages",
  },
  CreatedDatetime: ...,
  UpdatedDatetime: ...,
}`,
  python: `{
  "id": "61afc0531573b08ddbe36e1c85602827",
  "href": "https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827",
  "msisdn": 31612345678,
  "firstName": "John",
  "lastName": "Doe",
  "customDetails": {
    "custom1": "",
    "custom2": "",
    "custom3": "",
    "custom4": ""
  },
  "groups": {
    "totalCount": 0,
    "href": "https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827/groups"
  },
  "messages": {
    "totalCount": 0,
    "href": "https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827/messages"
  },
  "createdDatetime": "2016-04-29T09:42:26+00:00",
  "updatedDatetime": "2016-04-29T09:42:26+00:00"
}`,
  java: `com.messagebird.objects.Contact@731f8236[
  id=61afc0531573b08ddbe36e1c85602827
  href=https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827
  msisdn=31612345678
  firstName=Foo
  lastName=Bar
  groups=GroupReference{href='https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827/messages', totalCount=0}
  messages=MessageReference{href='https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827/messages', totalCount=0}
  createdDatetime=Fri Apr 29 11:42:26 CEST 2016
  updatedDatetime=Fri Apr 29 11:42:26 CEST 2016
  customDetails=CustomDetails{custom1='', custom2='', custom3='', custom4=''}
]`,
  node: `{ id: '61afc0531573b08ddbe36e1c85602827',
href:
  'https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827',
msisdn: 31612345678,
firstName: 'John',
lastName: 'Doe',
customDetails: { custom1: '', custom2: '', custom3: '', custom4: '' },
groups:
  { totalCount: 0,
    href:
    'https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827/groups' },
messages:
  { totalCount: 0,
    href:
    'https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827/messages' },
createdDatetime: '2016-04-29T09:42:26+00:00',
updatedDatetime: '2016-04-29T09:42:26+00:00' }`,
};

export const UPDATECONTACTS_DEF = {
  bash: `PATCH https://rest.messagebird.com/contacts/{contactId}`,
};

export const UPDATECONTACTS_REQUEST = {
  curl: `curl -X PATCH https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827 \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -d "msisdn=31612345679"`,
  php: `$ExistingId = '61afc0531573b08ddbe36e1c85602827';

$Contact = new \\MessageBird\\Objects\\Contact();
$Contact->msisdn = '31123456789';

$ContactResult = $MessageBird->contacts->update($Contact, $ExistingId);`,
  ruby: `existing_id = '3092d38f13cb48879b9a7a98f32f987a'

contact = client.contact_update(existing_id, :firstName => 'Foo')`,
  csharp: `string existingId = "61afc0531573b08ddbe36e1c85602827";
ContactOptionalArguments optionalArguments = new ContactOptionalArguments
{
    FirstName = "Bar"
};

client.UpdateContact(existingId, optionalArguments);`,
  go: `request := contact.Request{
  FirstName: "Foo",
  LastName:  "Bar",
}
result, err := contact.Update(c, "b15d7d0b5ce740ddadcb08e6e1c9053a", &request)`,
  python: `existing_id = 'b15d7d0b5ce740ddadcb08e6e1c9053a'

contact = client.contact_update(existing_id, {
    'firstName': 'Foo',
    'lastName': 'Bar'
})`,
  java: `ContactRequest request = new ContactRequest();
request.setFirstName("Baz");

String existingId = "61afc0531573b08ddbe36e1c85602827";

client.updateContact(existingId, request);`,
  node: `messagebird.contacts.update('61afc0531573b08ddbe36e1c85602827', 'new-name', function (err, response) {
  if (err)
    return console.log(err);
  console.log(response);
});`,
};

export const UPDATECONTACTS_RESPONSE = {
  curl: `{
  "id": "61afc0531573b08ddbe36e1c85602827",
  "href": "https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827",
  "msisdn": 31612345679,
  "firstName": "John",
  "lastName": "Doe",
  "customDetails": {
    "custom1": "",
    "custom2": "",
    "custom3": "",
    "custom4": ""
  },
  "groups": {
    "totalCount": 0,
    "href": "https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827/groups"
  },
  "messages": {
    "totalCount": 0,
    "href": "https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827/messages"
  },
  "createdDatetime": "2016-04-29T09:42:26+00:00",
  "updatedDatetime": "2016-04-29T09:42:26+00:00"
}`,
  php: `{
  "id": "61afc0531573b08ddbe36e1c85602827",
  "href": "https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827",
  "msisdn": 31612345679,
  "firstName": "John",
  "lastName": "Doe",
  "customDetails": {
    "custom1": "",
    "custom2": "",
    "custom3": "",
    "custom4": ""
  },
  "groups": {
    "totalCount": 0,
    "href": "https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827/groups"
  },
  "messages": {
    "totalCount": 0,
    "href": "https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827/messages"
  },
  "createdDatetime": "2016-04-29T09:42:26+00:00",
  "updatedDatetime": "2016-04-29T09:42:26+00:00"
}`,
  ruby: `#<MessageBird::Contact:0x00007f9b1782f5a0
@createdDatetime=2018-09-16 11:35:07 +0000,
@customDetails=
  #<MessageBird::CustomDetails:0x00007f9b1782f438
  @custom1=nil,
  @custom2=nil,
  @custom3=nil,
  @custom4=nil>,
@firstName="Foo",
@groups=
  #<MessageBird::GroupReference:0x00007f9b1782f348
  @href=
    "https://rest.messagebird.com/contacts/3092d38f13cb48879b9a7a98f32f987a/groups",
  @totalCount=0>,
@href=
  "https://rest.messagebird.com/contacts/3092d38f13cb48879b9a7a98f32f987a",
@id="3092d38f13cb48879b9a7a98f32f987a",
@lastName=nil,
@messages=
  #<MessageBird::MessageReference:0x00007f9b1782f280
  @href=
    "https://rest.messagebird.com/contacts/3092d38f13cb48879b9a7a98f32f987a/messages",
  @totalCount=0>,
@msisdn=31612345678,
@updatedDatetime=2018-09-16 11:35:07 +0000>`,
  csharp: `{
  "id": "61afc0531573b08ddbe36e1c85602827",
  "href": "https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827",
  "msisdn": 31612345679,
  "firstName": "John",
  "lastName": "Doe",
  "customDetails": {
    "custom1": "",
    "custom2": "",
    "custom3": "",
    "custom4": ""
  },
  "groups": {
    "totalCount": 0,
    "href": "https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827/groups"
  },
  "messages": {
    "totalCount": 0,
    "href": "https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827/messages"
  },
  "createdDatetime": "2016-04-29T09:42:26+00:00",
  "updatedDatetime": "2016-04-29T09:42:26+00:00"
}`,
  go: `&contact.Contact {
  ID:"b15d7d0b5ce740ddadcb08e6e1c9053a",
  HRef:"https://rest.messagebird.com/contacts/b15d7d0b5ce740ddadcb08e6e1c9053a",
  MSISDN:3161234567,
  FirstName:"Foo",
  LastName:"Bar",
  CustomDetails: {
      Custom1:"",
      Custom2:"",
      Custom3:"",
      Custom4:"",
  },
  Groups: {
      TotalCount:0,
      HRef:"https://rest.messagebird.com/contacts/b15d7d0b5ce740ddadcb08e6e1c9053a/groups",
  },
  Messages: {
      TotalCount:0,
      HRef:"https://rest.messagebird.com/contacts/b15d7d0b5ce740ddadcb08e6e1c9053a/messages",
  },
  CreatedDatetime: ...,
  UpdatedDatetime: ...,
}`,
  python: `{
  "id": "61afc0531573b08ddbe36e1c85602827",
  "href": "https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827",
  "msisdn": 31612345679,
  "firstName": "John",
  "lastName": "Doe",
  "customDetails": {
    "custom1": "",
    "custom2": "",
    "custom3": "",
    "custom4": ""
  },
  "groups": {
    "totalCount": 0,
    "href": "https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827/groups"
  },
  "messages": {
    "totalCount": 0,
    "href": "https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827/messages"
  },
  "createdDatetime": "2016-04-29T09:42:26+00:00",
  "updatedDatetime": "2016-04-29T09:42:26+00:00"
}`,
  java: `com.messagebird.objects.Contact@731f8236[
  id=61afc0531573b08ddbe36e1c85602827
  href=https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827
  msisdn=31612345678
  firstName=Baz
  lastName=Bar
  groups=GroupReference{href='https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827/messages', totalCount=0}
  messages=MessageReference{href='https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827/messages', totalCount=0}
  createdDatetime=Fri Apr 29 11:42:26 CEST 2016
  updatedDatetime=Fri Apr 29 11:42:51 CEST 2016
  customDetails=CustomDetails{custom1='', custom2='', custom3='', custom4=''}
]`,
  node: `{ id: '61afc0531573b08ddbe36e1c85602827',
href:
  'https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827',
msisdn: 31612345679,
firstName: 'John',
lastName: 'Doe',
customDetails: { custom1: '', custom2: '', custom3: '', custom4: '' },
groups:
  { totalCount: 0,
    href:
    'https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827/groups' },
messages:
  { totalCount: 0,
    href:
    'https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827/messages' },
createdDatetime: '2016-04-29T09:42:26+00:00',
updatedDatetime: '2016-04-29T09:42:26+00:00' }`,
};

export const DELETECONTACTS_DEF = {
  bash: `DELETE https://rest.messagebird.com/contacts/{contactId}`,
};

export const DELETECONTACTS_REQUEST = {
  curl: `curl -X DELETE https://rest.messagebird.com/contacts/61afc0531573b08ddbe36e1c85602827 \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM'`,
  php: `$ExistingId = '61afc0531573b08ddbe36e1c85602827';

$MessageBird->contacts->delete($ExistingId);`,
  ruby: `existing_id = '3092d38f13cb48879b9a7a98f32f987a'

client.contact_delete(existing_id)`,
  csharp: `string existingId = "61afc0531573b08ddbe36e1c85602827";

client.DeleteContact(existingId);`,
  go: `err := contact.Delete(c, "b15d7d0b5ce740ddadcb08e6e1c9053a")`,
  python: `existing_id = 'b15d7d0b5ce740ddadcb08e6e1c9053a'

client.contact_delete(existing_id)`,
  java: `String existingId = "61afc0531573b08ddbe36e1c85602827";

client.deleteContact(existingId);`,
  node: `messagebird.contacts.delete('61afc0531573b08ddbe36e1c85602827', function (err, response) {
  if (err)
    return console.log(err);
  console.log(response);
});
`,
};

export const DELETECONTACTS_RESPONSE = {
  bash: `[empty]`,
};
