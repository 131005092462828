import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Docs.tsx";
import { Img } from 'components/shared';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`MMS API Troubleshooting`}</h1>
    <p>{`MessageBird offers multiple tools to help you to quickly understand any unexpected behavior between the MMS API and your application.`}</p>
    <h2>{`Explore the Messaging Logs in your dashboard`}</h2>
    <p>{`If you run into issues with MMS delivery, the best place to start troubleshooting is in the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/messages/list"
      }}>{`Message Logs in your MessageBird dashboard.`}</a></p>
    <Img src="/img/screenshots/quickstarts/message-logs.png" alt="Message Logs" width="650px" align="middle" mdxType="Img" />
    <p>{`Here you can find an overview of the sent and received messages for the last 30 days. You can `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/messages/export"
      }}>{`export`}</a>{` messages older than 30 days.`}</p>
    <p>{`Select the eye symbol on the far right side of the logs to drill into each specific message for information such as `}<inlineCode parentName="p">{`Type`}</inlineCode>{`, `}<inlineCode parentName="p">{`ID`}</inlineCode>{`, `}<inlineCode parentName="p">{`Originator`}</inlineCode>{`, `}<inlineCode parentName="p">{`Send Time`}</inlineCode>{`, `}<inlineCode parentName="p">{`Message`}</inlineCode>{`, and `}<inlineCode parentName="p">{`Summary`}</inlineCode>{`.`}</p>
    <p>{`You can filter the message logs by Status (e.g. filter by undelivered) using the `}<inlineCode parentName="p">{`Filter`}</inlineCode>{` button on the top right.`}</p>
    <h2>{`General API Error Codes`}</h2>
    <p>{`If you received an error response and code from MessageBird that is not listed in the above Error Codes list, you can troubleshoot the error by exploring the general `}<a parentName="p" {...{
        "href": "/docs/handling-errors"
      }}>{`API Error Codes.`}</a></p>
    <p>{`In case of an API error, the body of the response includes a `}<inlineCode parentName="p">{`json`}</inlineCode>{` formatted response that tells you exactly what is wrong.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      