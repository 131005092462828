import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Setting SMS marketing subscriptions with MessageBird`}</h1>
    <h3>{`⏱ 30 min build time || `}<a parentName="h3" {...{
        "href": "https://github.com/messagebirdguides/subscriptions-guide-php"
      }}>{`Download the Code`}</a></h3>
    <h2>{`Why build SMS marketing subscriptions?`}</h2>
    <p>{`In this MessageBird Developer Tutorial, you’ll learn how to implement an SMS marketing campaign subscription application powered by the `}<a parentName="p" {...{
        "href": "/api/sms-messaging"
      }}>{`MessageBird SMS Messaging API`}</a>{`, which enables your subscribers to seamlessly opt-in and out.`}</p>
    <p>{`SMS makes it incredibly easy for businesses to reach consumers everywhere at any time, directly on their mobile devices. For many people, these messages are a great way to discover things like discounts and special offers from a company, while others might find them annoying. For this reason, it’s important and also required by law in many countries to provide clear opt-in and opt-out mechanisms for SMS broadcast lists. To make it work independently of a website it's useful to assign a programmable `}<a parentName="p" {...{
        "href": "https://www.messagebird.com/en/numbers"
      }}>{`virtual mobile number`}</a>{` to your SMS campaign and handle incoming messages programmatically so users can control their subscription with basic command keywords.`}</p>
    <p>{`We'll walk you through the following steps:`}</p>
    <ul>
      <li parentName="ul">{`A person can send the keyword `}<em parentName="li">{`SUBSCRIBE`}</em>{` to a specific VMN that the company includes in their advertising material; the opt-in is immediately confirmed.`}</li>
      <li parentName="ul">{`If the person no longer wants to receive messages, they can send the keyword `}<em parentName="li">{`STOP`}</em>{` to the same number; the opt-out is also confirmed.`}</li>
      <li parentName="ul">{`An administrator can enter a message in a form on a website. Then they can immediately send this message to all confirmed subscribers.`}</li>
    </ul>
    <h2>{`Getting started`}</h2>
    <p>{`First things first, our sample application is build in PHP, so if you're using a Mac, PHP is already installed; for Windows users, you can `}<a parentName="p" {...{
        "href": "https://windows.php.net/download/"
      }}>{`get it from windows.php.net`}</a>{`; for Linux users, please check your system's default package manager.`}</p>
    <p>{`You’ll also need Composer, which is available from `}<a parentName="p" {...{
        "href": "https://getcomposer.org/download/"
      }}>{`getcomposer.org`}</a>{`, to install application dependencies like the `}<a parentName="p" {...{
        "href": "https://github.com/messagebird/php-rest-api"
      }}>{`MessageBird SDK for PHP`}</a>{`.`}</p>
    <p>{`The source code is available in the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/subscriptions-guide-php"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`, from which you can either clone with git or from where you can download a ZIP file with the source code to your computer.`}</p>
    <p>{`After saving the code, open a console pointed at the directory into which you've placed the sample application and run the following command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`composer install
`}</code></pre>
    <p>{`Apart from the MessageBird SDK, Composer installs a few additional libraries: the `}<a parentName="p" {...{
        "href": "https://packagist.org/packages/slim/slim"
      }}>{`Slim framework`}</a>{`, the `}<a parentName="p" {...{
        "href": "https://packagist.org/packages/slim/twig-view"
      }}>{`Twig templating engine`}</a>{`, and the `}<a parentName="p" {...{
        "href": "https://packagist.org/packages/vlucas/phpdotenv"
      }}>{`Dotenv configuration library`}</a>{`. Using these libraries, we keep our controller, view, and configuration separated without having to set up a full-blown framework.`}</p>
    <p>{`To store the subscriber list, our sample application uses a relational database. It’s configured to use a single-file `}<a parentName="p" {...{
        "href": "https://www.sqlite.org/"
      }}>{`SQLite`}</a>{` database, which is natively supported by PHP through PDO so that it works out of the box without the need to configure an external RDBMS like MySQL. Run the following helper command to initialize an empty SQLite database:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`php init.php
`}</code></pre>
    <h2>{`Prerequisites for receiving messages`}</h2>
    <h3>{`Overview`}</h3>
    <p>{`This tutorial describes receiving messages using MessageBird. From a high-level viewpoint, receiving is relatively simple: an application defines a `}<em parentName="p">{`webhook URL`}</em>{`, which you assign to a number purchased on the MessageBird Dashboard using `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/flow-builder"
      }}>{`Flow Builder`}</a>{`. A webhook is a URL on your site that doesn't render a page to users but is like an API endpoint that can be triggered by other servers. Whenever someone sends a message to that number, MessageBird collects it and forwards it via HTTP to the webhook URL, where you can process it.`}</p>
    <h3>{`Exposing your development server with ngrok`}</h3>
    <p>{`When working with webhooks, an external service like MessageBird needs to access your application, so the URL must be public; however, during development you're typically working in a local development environment that is not publicly available. Thankfully this is not a big deal since various tools and services allow you to quickly expose your development environment to the Internet by providing a tunnel from a public URL to your local machine. One of the most popular tools is `}<a parentName="p" {...{
        "href": "https://ngrok.com/"
      }}>{`ngrok`}</a>{`.`}</p>
    <p>{`You can download `}<a parentName="p" {...{
        "href": "https://ngrok.com/download"
      }}>{`ngrok`}</a>{` for free as a single-file binary for almost every operating system, or optionally sign up for an account to access additional features.`}</p>
    <p>{`Let’s start a tunnel by providing a local port number on which the application runs. We’ll run our PHP server on port 8080, so you can launch your tunnel with this command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`ngrok http 8080
`}</code></pre>
    <p>{`After you've launched the tunnel, ngrok displays your temporary public URL along with some other information. We'll need that URL in a minute.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/subscriptions-php/ngrok.png",
        "alt": "ngrok"
      }}></img></p>
    <p>{`Another common tool for tunneling your local machine is `}<a parentName="p" {...{
        "href": "https://localtunnel.me/"
      }}>{`localtunnel.me`}</a>{`, which works virtually in the same way; you can have a look at it if you're facing problems with with ngrok. Keep in mind that it requires you to install `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/"
      }}>{`NPM`}</a>{` first.`}</p>
    <h3>{`Get an inbound number`}</h3>
    <p>{`A requirement for receiving messages is a dedicated inbound number. Virtual mobile numbers look and work similar to regular mobile numbers; however, instead of being attached to a mobile device via a SIM card, they live in the cloud and can process inbound SMS and voice calls. MessageBird offers numbers from different countries for a low monthly fee; `}<a parentName="p" {...{
        "href": "https://www.messagebird.com/en/numbers"
      }}>{`feel free to explore our low-cost programmable and configurable numbers`}</a>{`.`}</p>
    <p>{`Purchasing a number is quite easy:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Go to the ‘`}<a parentName="p" {...{
            "href": "https://dashboard.messagebird.com/en/numbers"
          }}>{`Numbers`}</a>{`’ section in the left-hand side of your Dashboard and click the blue button ‘`}<a parentName="p" {...{
            "href": "https://dashboard.messagebird.com/en/vmn/buy-number"
          }}>{`Buy a number`}</a>{`’ in the top-right side of your screen.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Pick the country in which you and your customers are located, and make sure the SMS capability is selected.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Choose one number from the selection and the duration for which you want to pay now.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Confirm by clicking ‘Buy Number’ in the bottom-right of your screen.`}</p>
      </li>
    </ol>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/subscriptions-php/image1.png",
        "alt": "Buy a VMN"
      }}></img></p>
    <p>{`Awesome, you’ve set up your first virtual mobile number! 🎉`}</p>
    <p><strong parentName="p">{`Pro-tip:`}</strong>{` Check out our Help Center for more information about `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/sections/201958489-Virtual-Numbers"
      }}>{`virtual mobile numbers`}</a>{` and `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/sections/360000108538-Country-info-Restrictions"
      }}>{`country restrictions`}</a>{`.`}</p>
    <h3>{`Connect your number to the webhook`}</h3>
    <p>{`So you have a number now, but MessageBird has no idea what to do with it. That's why now you need to define a `}<em parentName="p">{`Flow`}</em>{` that links your number to your webhook. This is how you do it:`}</p>
    <h4>{`STEP ONE`}</h4>
    <p>{`Go to `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/flow-builder"
      }}>{`Flow Builder`}</a>{`, choose the template ‘Call HTTP endpoint with SMS’, and click ‘Try this flow’.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/subscriptions-php/image2.png",
        "alt": "FlowBuilder"
      }}></img></p>
    <h4>{`STEP TWO`}</h4>
    <p>{`This template has two steps. Click on the first step ‘SMS’ and select the number or numbers you’d like to attach the flow to. Now, click on the second step ‘Forward to URL’ and choose POST as the method; copy the output from the ngrok command in the URL and add `}<inlineCode parentName="p">{`/webhook`}</inlineCode>{` at the end—this is the name of the route we use to handle incoming messages in our sample application. Click on ‘Save’ when ready.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/subscriptions-php/image3.png",
        "alt": "FlowBuilder"
      }}></img></p>
    <h4>{`STEP THREE`}</h4>
    <p><strong parentName="p">{`Ready!`}</strong>{` Hit ‘Publish’ on the right top of the screen to activate your flow. Well done, another step closer to testing incoming messages! Your flow should look something like this:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/subscriptions-php/image4.png",
        "alt": "FlowBuilder"
      }}></img></p>
    <p><strong parentName="p">{`Pro-tip:`}</strong>{` It might be useful to rename it this flow, because `}<em parentName="p">{`Untitled flow`}</em>{` won't be helpful in the long run. You can do this by clicking on the icon next to button ‘Back to Overview’ and pressing ‘Rename flow’.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/subscriptions-php/image5.png",
        "alt": "Buy a VMN"
      }}></img></p>
    <h2>{`Configuring the MessageBird SDK`}</h2>
    <p>{`The SDK is listed as a dependency in `}<inlineCode parentName="p">{`composer.json`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
   "require" : {
       "messagebird/php-rest-api" : "^1.9.4"
       ...
   }
}
`}</code></pre>
    <p>{`An application can access the SDK, which is made available through Composer autoloading, by creating an instance of the `}<inlineCode parentName="p">{`MessageBird\\Client`}</inlineCode>{` class. The constructor takes a single argument, your `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`API key`}</a>{`. For frameworks like Slim you can add the SDK to the dependency injection container:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`// Load and initialize MessageBird SDK
$container['messagebird'] = function() {
   return new MessageBird\\Client(getenv('MESSAGEBIRD_API_KEY'));
};
`}</code></pre>
    <p>{`As it's a bad practice to keep credentials in the source code, let’s load the API key from an environment variable using `}<inlineCode parentName="p">{`getenv()`}</inlineCode>{`. To make the key available in the environment variable we need to initialize Dotenv and then add the key to a `}<inlineCode parentName="p">{`.env`}</inlineCode>{` file.`}</p>
    <p>{`Apart from `}<inlineCode parentName="p">{`MESSAGEBIRD_API_KEY`}</inlineCode>{` we use another environment variable called `}<inlineCode parentName="p">{`MESSAGEBIRD_ORIGINATOR`}</inlineCode>{` which contains the phone number used in our system, i.e., the VMN you just registered.`}</p>
    <p>{`You can copy the `}<inlineCode parentName="p">{`env.example`}</inlineCode>{` file provided in the repository to `}<inlineCode parentName="p">{`.env`}</inlineCode>{` and then add your API key and phone number like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-env"
      }}>{`MESSAGEBIRD_API_KEY=YOUR-API-KEY
MESSAGEBIRD_ORIGINATOR=+31970XXXXXXX
`}</code></pre>
    <p>{`Go to the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/user/index"
      }}>{`MessageBird Dashboard`}</a>{`; if you have already created an API key it will be shown right there. If you don’t see any key on the Dashboard or if you're unsure whether this key is in `}<em parentName="p">{`live`}</em>{` mode, go to the `}<em parentName="p">{`Developers`}</em>{` section in the MessageBird Dashboard and open the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`API access (REST) tab`}</a>{`. There you can create new API keys and manage your existing ones.`}</p>
    <p>{`If you are having any issues creating your API key, please reach out to `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we’ll make sure to help you out.`}</p>
    <h2>{`Receiving messages`}</h2>
    <p>{`Now that we're fully prepared for receiving inbound messages, let's have a look at the actual implementation of our `}<inlineCode parentName="p">{`/webhook`}</inlineCode>{` route:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`// Handle incoming webhooks
$app->post('/webhook', function($request, $response) {
   // Read input sent from MessageBird
   $number = $request->getParsedBodyParam('originator');
   $text = strtolower(trim($request->getParsedBodyParam('payload')));
`}</code></pre>
    <p>{`The webhook receives multiple request parameters from MessageBird; however, we're only interested in two of them: the `}<inlineCode parentName="p">{`originator`}</inlineCode>{` (the number of the user who sent the message) and the `}<inlineCode parentName="p">{`payload`}</inlineCode>{` (the text of the message). The content is trimmed and converted into lower case so we can easily do case-insensitive command detection.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`   // Find subscriber in our database
   $stmt = $this->db->prepare('SELECT * FROM subscribers WHERE number = :number');
   $stmt->execute([ 'number' => $number ]);
   $subscriber = $stmt->fetch();
`}</code></pre>
    <p>{`This SQL SELECT query searches for the originator number in a database table named `}<em parentName="p">{`subscribers`}</em>{`.`}</p>
    <p>{`We're looking at three potential cases:`}</p>
    <ul>
      <li parentName="ul">{`The user has sent `}<em parentName="li">{`SUBSCRIBE`}</em>{` and the number does not exist. The subscriber should be added and opted in.`}</li>
      <li parentName="ul">{`The user has submitted `}<em parentName="li">{`SUBSCRIBE`}</em>{` and the number exists but has opted out. In that case, it should be opted in (again).`}</li>
      <li parentName="ul">{`The user has sent `}<em parentName="li">{`STOP`}</em>{` and the number exists and has opted in. In that case, it should be opted out.`}</li>
    </ul>
    <p>{`For each of those cases, a differently worded confirmation message should be sent. For the sake of keeping the tutorial simple, all incoming messages that don't fit any of these cases are ignored and don't get a reply; however, for production-ready applications, you can optimize this behavior by sending a help message with all supported commands.`}</p>
    <p>{`Sending a message through the MessageBird SDK for PHP is a two-step process. First, you create an instance of the `}<inlineCode parentName="p">{`MessageBird\\Objects\\Message`}</inlineCode>{` class and set all message parameters on that object. Then, you call a method on the SDK object and pass the message as a parameter. As the parameters, except for the message body, are the same in each case, we can prepare the object before any conditional checks:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`   // Prepare a message object, which will be
   // updated depending on the subscriber status
   // and only sent if necessary
   $message = new MessageBird\\Objects\\Message;
   $message->originator = getenv('MESSAGEBIRD_ORIGINATOR');
   $message->recipients = [ $number ];
   $message->body = "";
`}</code></pre>
    <p>{`As the implementation of each case is similar we'll only look at one here, but you can check the others in the source code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`   if ($subscriber === false && $text == "subscribe") {
       // The user has sent the "subscribe" keyword
       // and is not stored in the database yet, so
       // we add them to the database.
       $stmt = $this->db->prepare('INSERT INTO subscribers (number, subscribed) VALUES (:number, :subscribed)');
       $stmt->execute([
           'number' => $number,
           'subscribed' => (int)true
       ]);

       // Set notification text
       $message->body = "Thanks for subscribing to our list! Send STOP anytime if you no longer want to receive messages from us.";

   }
`}</code></pre>
    <p>{`If no `}<inlineCode parentName="p">{`$subscriber`}</inlineCode>{` exists and the text matches "subscribe", the script executes an SQL INSERT query that stores a new row with the number and the field `}<inlineCode parentName="p">{`subscribed`}</inlineCode>{` set to `}<inlineCode parentName="p">{`true`}</inlineCode>{` (cast to an integer because SQLite doesn't support boolean fields). As confirmation, we assign a message to the message's body attribute.`}</p>
    <p>{`After the code for all cases our incoming message endpoint should handle, we can call `}<inlineCode parentName="p">{`messages->create()`}</inlineCode>{` to send the message. The call is only necessary if one of the cases applied, and the message body was assigned.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`   if ($message->body != "") {
       // A message body was defined, so we send the message through the API
       error_log($number." <-- ".$message->body);
       try {
           $this->messagebird->messages->create($message);
       } catch (Exception $e) {
           error_log(get_class($e).": ".$e->getMessage());
       }
   }
`}</code></pre>
    <p>{`Sending the message is contained in a try-catch block which sends exceptions thrown by the MessageBird SDK to the server's default error log.`}</p>
    <h2>{`Sending messages`}</h2>
    <h3>{`Showing a form`}</h3>
    <p>{`We've defined a simple form with a single text area and a submit button, and stored it as a Twig template in `}<inlineCode parentName="p">{`views/home.html.twig`}</inlineCode>{`. It’s rendered for a GET request on the root of the application. As a small hint for the admin, we're also showing the number of subscribers in the database.`}</p>
    <h3>{`Processing input`}</h3>
    <p>{`The form submits its content as a POST request to the `}<inlineCode parentName="p">{`/send`}</inlineCode>{` route. The implementation of this route fetches all subscribers that have opted in from the database and then uses the MessageBird SDK to send a message to them. It’s possible to send a message to up to 50 receivers in a single API call, so the script splits a list of subscribers that is longer than 50 numbers (highly unlikely during testing unless you have amassed an impressive collection of phones) into blocks of 50 numbers each. Sending encompasses creating a `}<inlineCode parentName="p">{`MessageBird\\Objects\\Message`}</inlineCode>{` object and then calling the `}<inlineCode parentName="p">{`messages->create()`}</inlineCode>{` SDK method which you've already seen in the previous section.`}</p>
    <p>{`Here's the full code block:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`$app->post('/send', function($request, $response) {
   // Read input from user
   $messageBody = $request->getParsedBodyParam('message');

   // Get all subscribers
   $stmt = $this->db->prepare('SELECT * FROM subscribers WHERE subscribed = :subscribed');
   $stmt->execute([ 'subscribed' => (int)true ]);
   $subscribers = $stmt->fetchAll();

   // Collect all numbers
   $recipients = [];
   $lastIndex = count($subscribers) - 1;
   for ($i = 0; $i <= $lastIndex; $i++) {
       $recipients[] = $subscribers[$i]['number'];
       if ($i == $lastIndex || ($i+1) % 50 == 0) {
           // We have reached either the end of our list or 50 numbers,
           // which is the maximum that MessageBird accepts in a single
           // API call, so we send the message and then, if any numbers
           // are remaining, start a new list
           $message = new MessageBird\\Objects\\Message;
           $message->originator = getenv('MESSAGEBIRD_ORIGINATOR');
           $message->recipients = $recipients;
           $message->body = $messageBody;
           try {
               $this->messagebird->messages->create($message);
           } catch (Exception $e) {
               error_log(get_class($e).": ".$e->getMessage());
           }

           $recipients = [];
       }
   }

   $this->view->render($response, 'sent.html.twig',
       [ 'count' => count($subscribers) ]);
});
`}</code></pre>
    <h2>{`Testing`}</h2>
    <p>{`You’re done! It’s time to test your application.`}</p>
    <p>{`Double-check that you’ve set up your number correctly with a flow that forwards incoming messages to a ngrok URL and that the tunnel is still running. Keep in mind that whenever you start a fresh tunnel, you'll get a new URL, so you have to update it in the flows accordingly.`}</p>
    <p>{`To start the sample application you have to enter another command, but your existing console window is now busy running your tunnel, so you need to open another one. On a Mac you can press `}<em parentName="p">{`Command + Tab`}</em>{` to open a second tab that's already pointed to the correct directory. With other operating systems you may have to resort to open another console window manually. Once you've got a command prompt, type the following to start the application:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`php -S 0.0.0.0:8080 index.php
`}</code></pre>
    <p>{`While keeping the console open, take out your phone, launch the SMS app, and send a message to your virtual mobile number with the keyword "SUBSCRIBE". A few seconds later, you should see some output in the console from both the PHP runtime and the ngrok proxy. If you are using a live API key, you should receive a confirmation message stating that you've been subscribed to the broadcast list. Open http://localhost:8080/ (or your tunnel URL), and you should also see that there's one subscriber. Try sending yourself a message now. And voilá, your marketing system is ready!`}</p>
    <p>{`You can adapt the sample application for production by replying mongo-mock with a real MongoDB client, deploying the application to a server and providing that server's URL to your flow. Of course, you should add some authorization to the web form. Otherwise, anybody could send messages to your subscribers.`}</p>
    <p>{`Don't forget to download the code from the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/subscriptions-guide-php"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`.`}</p>
    <p><strong parentName="p">{`Nice work!`}</strong>{` 🎉`}</p>
    <p>{`You've just built your own marketing system with MessageBird using PHP!`}</p>
    <h2>{`Start building!`}</h2>
    <p>{`Want to start building your solution but not quite sure how to get started? Feel free to let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we'd love to help!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      