import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Docs.tsx";
import Questions from 'components/Footer/Questions';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`WhatsApp number quality ratings`}</h1>
    <p>{`We'll notify you of the quality rating and status for your WhatsApp account via email. `}</p>
    <h3>{`Quality ratings`}</h3>
    <p>{`The quality rating shows how messages your customers have received in a rolling window of the past 24 hours. There are three different states of messaging quality:`}</p>
    <ul>
      <li parentName="ul">{`Green: High`}</li>
      <li parentName="ul">{`Yellow: Medium`}</li>
      <li parentName="ul">{`Red: Poor`}</li>
    </ul>
    <h3>{`Rate limit tiers`}</h3>
    <p>{`The quality-based rate limit tiers affect how many daily messages to customers your business can send. Keep in mind that this includes both new and existing conversations. There are three tiers:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Tier 1`}</strong>{`: Allows your company to send messages to 1K customers per day.`}</li>
      <li parentName="ul"><strong parentName="li">{`Tier 2`}</strong>{`: Allows your company to send messages to 10K customers per day.`}</li>
      <li parentName="ul"><strong parentName="li">{`Tier 3`}</strong>{`: Allows your company to send messages to 100K customers per day.`}</li>
    </ul>
    <p>{`How does it work? We're glad you asked. When a company registers its phone number, it starts with `}<strong parentName="p">{`Tier 1`}</strong>{`. The company will be upgraded to the next tier only if the quality rating is not in the Red state and it sends 2x the number of messages of its current limit in a maximum window of 7 days.`}</p>
    <p>{`Keep in mind that since there is a limit of the messages per day depending of your tier, the earliest the upgrade can occur is after 2 days.`}</p>
    <h3>{`Status`}</h3>
    <p>{`Phone numbers may have many statuses listed, such as `}<strong parentName="p">{`Disconnected`}</strong>{`, `}<strong parentName="p">{`Pending`}</strong>{`, etc., but two statuses related to quality are `}<em parentName="p">{`Warned`}</em>{` and `}<strong parentName="p">{`Rate Limited`}</strong>{`. Typically, the `}<strong parentName="p">{`Connected`}</strong>{` status is shown when the phone number used to send messages is in a good state.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Warned:`}</strong>{` When quality rating reaches a Red state, that number is moved to Warned status for the next 7 days. If this number continues to maintain a good quality rating for the next 7 days, it returns to Connected status; otherwise, it changes to the Rate Limited state.`}</li>
      <li parentName="ul"><strong parentName="li">{`Rate Limited:`}</strong>{` If the phone number is not maintaining good quality even after moving to the Warned state, it's moved to Rate Limited. During this phase, the rate limit for this number is set by the quality-based rate limit tier your business belongs to.`}</li>
    </ul>
    <p>{`If you have been given a Rate Limited status and do not improve your message quality, you will be downgraded to a lower rate limit tier and its lower sending threshold.`}</p>
    <h3>{`Keeping a high-quality rating`}</h3>
    <ul>
      <li parentName="ul">{`Make sure your messages follow the `}<a parentName="li" {...{
          "href": "https://www.whatsapp.com/legal/business-policy/"
        }}>{`WhatsApp Business Policy`}</a>{`.`}</li>
      <li parentName="ul">{`Only send messages to `}<a parentName="li" {...{
          "href": "https://developers.messagebird.com/docs/conversations/whatsapp/whatsapp-customer-optins/"
        }}>{`customers who opt-in`}</a>{`.`}</li>
      <li parentName="ul">{`Make sure that your messages are clear, personalized, and useful.`}</li>
      <li parentName="ul">{`Be mindful of your messaging frequency.`}</li>
    </ul>
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      