import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Setting SMS instant lead alerts with MessageBird`}</h1>
    <h3>{`⏱ 15 min build time      ||      `}<a parentName="h3" {...{
        "href": "https://github.com/messagebirdguides/lead-alerts-guide-php"
      }}>{`Download the Code`}</a></h3>
    <h2>{`Why build instant lead alerts for sales?`}</h2>
    <p>{`In this MessageBird Developer Tutorial, you’ll learn how to build SMS lead alerts with the `}<a parentName="p" {...{
        "href": "/api/sms-messaging"
      }}>{`MessageBird SMS Messaging API`}</a>{` and convert more leads by instantly notifying your sales team. In this tutorial the sample application will be for our fictitious car dealership, `}<em parentName="p">{`M.B. Cars`}</em>{`.`}</p>
    <p>{`Even though a lot of business transactions happen on the web, from both a business and user perspective, it's still often preferred to switch the channel and talk on the phone. Especially when it comes to high-value transactions in industries such as real estate or mobility, personal contact is essential.`}</p>
    <p>{`One way to streamline this workflow is by building callback forms on your website. Through these forms, customers can enter their contact details and receive a call to their phone, thus skipping queues where prospective leads need to stay on hold.`}</p>
    <p>{`Callback requests reflect a high level of purchase intent and should be dealt with as soon as possible to increase the chance of converting a lead; therefore, it's essential to get them pushed to a sales agent quickly. SMS messaging has proven to be one of the most instant and effective channels for this use case.`}</p>
    <h2>{`Getting started`}</h2>
    <p>{`First things first, our sample application is build in PHP, so if you're using a Mac, PHP is already installed; for Windows users, you can `}<a parentName="p" {...{
        "href": "https://windows.php.net/download/"
      }}>{`get it from windows.php.net`}</a>{`; for Linux users, please check your system's default package manager.`}</p>
    <p>{`You’ll also need Composer, which is available from `}<a parentName="p" {...{
        "href": "https://getcomposer.org/download/"
      }}>{`getcomposer.org`}</a>{`, to install application dependencies like the `}<a parentName="p" {...{
        "href": "https://github.com/messagebird/php-rest-api"
      }}>{`MessageBird SDK for PHP`}</a>{`.`}</p>
    <p>{`The source code is available in the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/lead-alerts-guide-php"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{` from which it can be cloned or downloaded into your development environment.`}</p>
    <p>{`After saving the code, open a console for the download directory and run the following command, which downloads the Slim framework, MessageBird SDK, and other dependencies defined in the `}<inlineCode parentName="p">{`composer.json`}</inlineCode>{` file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`composer install
`}</code></pre>
    <p>{`It's helpful to know the basics of the `}<a parentName="p" {...{
        "href": "https://packagist.org/packages/slim/slim"
      }}>{`Slim framework`}</a>{` to follow along with the tutorial, but you should be able to get the gist of it also if your experience lies with other frameworks.`}</p>
    <h2>{`Configuring the MessageBird SDK`}</h2>
    <p>{`The SDK, which is used to send messages, is listed as a dependency in `}<inlineCode parentName="p">{`composer.json`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
   "require" : {
       "messagebird/php-rest-api" : "^1.9.4"
       ...
   }
}
`}</code></pre>
    <p>{`An application can access the SDK, which is made available through Composer autoloading, by creating an instance of the `}<inlineCode parentName="p">{`MessageBird\\Client`}</inlineCode>{` class. The constructor takes a single argument, your `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`API key`}</a>{`. For frameworks like Slim you can add the SDK to the dependency injection container like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`// Load and initialize MessageBird SDK
$container['messagebird'] = function() {
   return new MessageBird\\Client(getenv('MESSAGEBIRD_API_KEY'));
};
`}</code></pre>
    <p>{`You need an API key, which you can retrieve from the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`MessageBird Dashboard`}</a>{`. As you can see in the code example above, the key is loaded from an environment variable called `}<inlineCode parentName="p">{`MESSAGEBIRD_API_KEY`}</inlineCode>{`. With `}<a parentName="p" {...{
        "href": "https://packagist.org/packages/vlucas/phpdotenv"
      }}>{`dotenv`}</a>{` you can define these variables in a `}<inlineCode parentName="p">{`.env`}</inlineCode>{` file.`}</p>
    <p>{`The repository contains an `}<inlineCode parentName="p">{`env.example`}</inlineCode>{` file which you can copy to `}<inlineCode parentName="p">{`.env`}</inlineCode>{` and then enter your information.`}</p>
    <p>{`Apart from the API key, we also specify the originator, which is what is displayed as the sender of the messages. Please note that alphanumeric sender IDs like the one in our example file don't work in all countries, including the United States, so it’s important to check the `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/sections/360000108538-Country-info-Restrictions"
      }}>{`country restrictions`}</a>{`. If you can't use alphanumeric IDs, use a real phone number instead. You can check our `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/articles/115002628665-What-is-the-originator-"
      }}>{`originator article`}</a>{` in Help Center to learn more about this topic.`}</p>
    <p>{`Additionally, we specify the sales agent's telephone numbers. These are the recipients that will receive the SMS alerts when a potential customer submits the callback form. You can separate multiple numbers with commas.`}</p>
    <p>{`Here's an example of a valid `}<inlineCode parentName="p">{`.env`}</inlineCode>{` file for our sample application:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-env"
      }}>{`MESSAGEBIRD_API_KEY=YOUR-API-KEY
MESSAGEBIRD_ORIGINATOR=MBCars
SALES_AGENT_NUMBERS=+31970XXXXXXX,+31970YYYYYYY
`}</code></pre>
    <h2>{`Showing a landing page`}</h2>
    <p>{`The landing page is a simple HTML page with information about our company, a call to action and a form with two input fields, name and number, and a submit button. We use `}<a parentName="p" {...{
        "href": "https://twig.symfony.com/"
      }}>{`Twig templates`}</a>{`, so we can compose the view with a layout and have the ability to show dynamic content. You can see the landing page in the file `}<inlineCode parentName="p">{`views/landing.html.twig`}</inlineCode>{`, which extends the layout stored in `}<inlineCode parentName="p">{`views/layouts.html.twig`}</inlineCode>{`; the `}<inlineCode parentName="p">{`$app->get('/')`}</inlineCode>{` route in `}<inlineCode parentName="p">{`index.php`}</inlineCode>{` is responsible for rendering it.`}</p>
    <h2>{`Handling callback requests`}</h2>
    <p>{`When the user submits the form, the `}<inlineCode parentName="p">{`$app->post('/callme')`}</inlineCode>{` route receives their name and number. First, we fetch the input and do some validation:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`// Handle callback request
$app->post('/callme', function($request, $response) {
   // Check if user has provided input for all form fields
   $name = $request->getParsedBodyParam('name', '');
   $number = $request->getParsedBodyParam('number', '');
   if ($name == '' || $number == '') {
       // If not, show an error
       return $this->view->render($response, 'landing.html.twig', [
           'error' => "Please fill all required fields!",
           'name' => $name,
           'number' => $number
       ]);
   }
`}</code></pre>
    <p>{`Then, we define where to send the message. As you've seen above, we specified multiple recipient numbers in the `}<inlineCode parentName="p">{`SALES_AGENT_NUMBERS`}</inlineCode>{` environment variable. `}<em parentName="p">{`M.B. Cars`}</em>{` has decided to randomly distribute incoming calls to their staff so that every salesperson receives roughly the same amount of leads. Here's the code for the random distribution:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`   // Choose one of the sales agent numbers randomly
   // a) Convert comma-separated values to array
   $numbers = explode(',', getenv('SALES_AGENT_NUMBERS'));
   // b) Random number between 0 and (number count - 1)
   $randomIndex = rand(0, count($numbers) - 1);
   // c) Pick number
   $recipient = $numbers[$randomIndex];
`}</code></pre>
    <p>{`Now we can formulate a message for the agent as an instance of the `}<inlineCode parentName="p">{`MessageBird\\Objects\\Message`}</inlineCode>{` class:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`   // Send lead message with MessageBird API
   // Prepare lead message
   $message = new MessageBird\\Objects\\Message;
   $message->originator = getenv('MESSAGEBIRD_ORIGINATOR');
   $message->recipients = [ $recipient ];
   $message->body = "You have a new lead: ".$name.". Call them at ".$number;
`}</code></pre>
    <p>{`There are three object attributes:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`originator`}</inlineCode>{`: The sender ID comes from the environment variable defined earlier.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`recipients`}</inlineCode>{`: The API supports an array of recipients; we're sending to only one, but this attribute still has to be specified as an array.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`body`}</inlineCode>{`: The text of the message that includes the input from the form.`}</li>
    </ul>
    <p>{`Now, we can send our object through the MessageBird SDK using the `}<inlineCode parentName="p">{`messages->create()`}</inlineCode>{` method:`}</p>
    <p>{`The API request is surrounded with a try-catch construct to handle any errors that the SDK throws as exceptions. Inside the catch block, we handle the error case by showing the previous form again and inform the user that something went wrong. In the success case, we show a basic confirmation page which you can see in `}<inlineCode parentName="p">{`views/sent.html.twig`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`   // Send lead message with MessageBird API
   try {
       $this->messagebird->messages->create($message);
   } catch (Exception $e) {
       return $this->view->render($response, 'landing.html.twig', [
           'error' => "An error occurred while requesting a callback!",
           'name' => $name,
           'number' => $number
       ]);
   }

   // Message was sent successfully
   return $this->view->render($response, 'sent.html.twig', []);
`}</code></pre>
    <h2>{`Testing`}</h2>
    <p>{`Have you created your `}<inlineCode parentName="p">{`.env`}</inlineCode>{` file with a working key and added one more existing phone numbers, as explained above in `}<em parentName="p">{`Configuring the MessageBird SDK`}</em>{`, to receive the lead alert? Lovely!`}</p>
    <p>{`You're done! To test your application, run the following command from your console:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`php -S 0.0.0.0:8080 index.php
`}</code></pre>
    <p>{`Go to http://localhost:8080/ to see the form and request a lead!`}</p>
    <p>{`You can now use the flow, code snippets and UI examples from this tutorial as an inspiration to build your own SMS-based lead alerts application. Don't forget to download the code from the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/lead-alerts-guide-php"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`.`}</p>
    <p><strong parentName="p">{`Nice work!`}</strong>{` 🎉`}</p>
    <p>{`You've just build your own instant lead alerts application with MessageBird using PHP!`}</p>
    <h2>{`Start building!`}</h2>
    <p>{`Want to build something similar but not quite sure how to get started? Please feel free to let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`;  we'd love to help!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      