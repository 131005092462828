import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import Notification from 'components/Notification';
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`WhatsApp Business step-by-step onboarding`}</h1>
    <p>{`This step-by-step quickstart will lead you through the WhatsApp Business onboarding process. Feel free to reach out to our `}<a parentName="p" {...{
        "href": "http://messagebird.com/contact"
      }}>{`Support team`}</a>{` if you need any help.`}</p>
    <h2>{`Step 1:  Sign up for WhatsApp Business`}</h2>
    <p><a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/getting-started/whatsapp-multiple-wabas/start"
      }}>{`Sign up for WhatsApp Business`}</a>{`. We’ll qualify your application and send it to WhatsApp for approval. You’ll be able to track the status of your application in your `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/"
      }}>{`MessageBird Dashboard`}</a>{`. `}</p>
    <p>{`During the flow, you’ll be asked for your Facebook Business ID. Go to “`}<a parentName="p" {...{
        "href": "https://business.facebook.com/settings"
      }}>{`Facebook Business settings`}</a>{`”, click `}<strong parentName="p">{`Business info`}</strong>{`, and you’ll see your ID below “Business Manager info”. Your ID is required to connect your business account with WhatsApp.`}</p>
    <p>{`If you don’t have a Facebook Business account yet, you can sign up on `}<a parentName="p" {...{
        "href": "https://business.facebook.com/"
      }}>{`https://business.facebook.com/`}</a></p>
    <h2>{`Step 2: Verify your business and accept the invitation`}</h2>
    <p>{`Once you finished signing up for WhatsApp Business, you’ll receive an email from our Service Delivery team asking you to accept WhatsApp via Facebook and verify your business:`}</p>
    <p><strong parentName="p">{`Accept:`}</strong>{`
Visit `}<a parentName="p" {...{
        "href": "https://business.facebook.com/"
      }}>{`Facebook Business`}</a>{`, go to the “Requests" tab, and accept the invitation. `}</p>
    <p><strong parentName="p">{`Verify:`}</strong>{`
WhatsApp requires your business to be `}<a parentName="p" {...{
        "href": "https://www.facebook.com/business/help/2058515294227817?id=180505742745347"
      }}>{`verified on Facebook`}</a>{`. `}</p>
    <ul>
      <li parentName="ul">{`Go to “`}<a parentName="li" {...{
          "href": "https://business.facebook.com/settings"
        }}>{`Business settings`}</a>{`” and click `}<strong parentName="li">{`Security Center`}</strong>{`.`}</li>
      <li parentName="ul">{`Tap `}<strong parentName="li">{`Verification`}</strong>{` and click `}<strong parentName="li">{`Start Verification`}</strong>{`. Once you start the verification process, you can check the status in this section.`}</li>
      <li parentName="ul">{`Enter your business details and tap `}<strong parentName="li">{`Next`}</strong>{`.`}</li>
      <li parentName="ul">{`Select the correct business from the list or “None of the above” if your name does not appear.`}</li>
      <li parentName="ul">{`Follow the instructions on the screen to confirm your business information. If you’ve selected “None of the above” in the previous step, Facebook will ask you to verify your company by sending additional documentation.`}</li>
      <li parentName="ul">{`Decide how you prefer to receive the confirmation code and enter the verification code.`}</li>
      <li parentName="ul">{`And that’s it! You’ll receive a confirmation from Facebook regarding your request. `}</li>
    </ul>
    <p>{`Please note that the verification decision is entirely at the discretion of Facebook. `}</p>
    <p>{`Once your Facebook Business profile has been successfully verified, your application will go to WhatsApp for approval. `}</p>
    <h2>{`Step 3: Choosing a number and a display name`}</h2>
    <p>{`As soon as WhatsApp approves your application, our Service Delivery team will contact you to connect the number you'll be using for WhatsApp. This will be the number that customers can reach you at and from where you can send WhatsApp messages. `}</p>
    <p>{`You have the option of using one of the free MessageBird Virtual Mobile Number (VMN) or use one of your current phone numbers: `}</p>
    <ul>
      <li parentName="ul">{`VMN: Your WhatsApp plan includes one or more free VMN from selected countries. `}</li>
      <li parentName="ul">{`Your own number: This should be a number that has not been associated with WhatsApp before. It can be either mobile or landline. `}</li>
    </ul>
    <p>{`Need some help? `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/articles/360000244558-Picking-a-number-for-use-with-WhatsApp"
      }}>{`Learn how to pick a number for use with WhatsApp`}</a>{`.`}</p>
    <p>{`Each number needs a “display name”. This will be the name that is visible to your customers and can be found in your Business' company info on WhatsApp and is unchangeable once activated. Need some help? Take a look at Facebook’s guidelines for display names or contact our `}<a parentName="p" {...{
        "href": "https://www.messagebird.com/en/contact"
      }}>{`Support team`}</a>{`.`}</p>
    <h2>{`Step 4: Activate the WhatsApp channel in your MessageBird account`}</h2>
    <p>{`You’ll receive an email from our Service DeIivery team letting you know when your channel ready for activation. Go to `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/channels"
      }}>{`Channels`}</a>{` on the left sidebar of your MessageBird Dashboard, click `}<strong parentName="p">{`WhatsApp`}</strong>{`, and find your very first WhatsApp channel. You should see "Activate channel" as a green button. To activate the channel, a verification code will be sent to your WhatsApp number:`}</p>
    <ul>
      <li parentName="ul">{`VMN: You’ll receive the code via a phone call. Please `}<a parentName="li" {...{
          "href": "https://support.messagebird.com/hc/en-us/articles/360001844337-Forwarding-Call"
        }}>{`attach the number to a forwarding call flow`}</a>{` and make sure to publish the flow. `}</li>
      <li parentName="ul">{`Your own mobile number: You can receive the code via SMS or phone call. `}</li>
      <li parentName="ul">{`Your own landline number: You’ll receive the code via a phone call. `}</li>
    </ul>
    <p>{`Click `}<strong parentName="p">{`Activate channel`}</strong>{`, request the code either via SMS or phone call and type it in the right field. `}</p>
    <p>{`If you see the status "Pending", wait a couple of minutes and refresh the page. Please `}<a parentName="p" {...{
        "href": "https://www.messagebird.com/en/contact"
      }}>{`contact our Support team`}</a>{` if the status has not changed for more than 30 minutes.`}</p>
    <p>{`Once the number has been activated, you’re all set to start using our WhatsApp Business. Connect your CRM with our `}<a parentName="p" {...{
        "href": "https://developers.messagebird.com/docs/conversations"
      }}>{`Conversations API`}</a>{` to get the full use of our options!`}</p>
    <h2>{`Step 5: Registering a Message Template`}</h2>
    <p>{`To reach out to your customers, you need a `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/articles/360001853778-Message-Templates"
      }}>{`pre-approved Message Template`}</a>{`. Your plan already includes a certain number of free Message Templates. You can submit Message Templates via the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/whatsapp-template-manager/"
      }}>{`WhatsApp Template Manager`}</a>{` on the MessageBird Dashboard. Once your Message Template has been approved, it can not be edited anymore;  however, you can add new HSM’s at any time.`}</p>
    <p>{`And that's it! You're all set to start using WhatsApp Business 🎉`}</p>
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'Message Templates',
      to: '/docs/conversations/whatsapp/message-templates-curl'
    }, {
      name: 'Conversations API Reference',
      to: '/api/conversations'
    }, {
      name: 'Official MessageBird SDKs',
      to: '/libraries'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      