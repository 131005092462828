import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import Notification from 'components/Notification';
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
import { Img } from 'components/shared';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Send an SMS`}</h1>
    <p>{`In this SMS API Quickstart, you'll learn how to send an SMS from your web application using the SMS API.`}</p>
    <h2>{`Step 1: Sign up for free`}</h2>
    <p>{`Before we get started, `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/login"
      }}>{`log in to the MessageBird Connectivity Platform`}</a>{` and collect your live API key from the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`Developer Dashboard`}</a>{`.`}</p>
    <h2>{`Step 2: Send your first SMS`}</h2>
    <p>{`Next, enter `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`your credentials`}</a>{` and mobile number - as both the `}<inlineCode parentName="p">{`originator`}</inlineCode>{` and `}<inlineCode parentName="p">{`recipient`}</inlineCode>{` - to the snippet below and copy it to your terminal.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-curl"
      }}>{`curl -X POST https://rest.messagebird.com/messages \\
        -H 'Authorization: AccessKey YOUR-API-KEY' \\
        -d "recipients=+31XXXXXXXXX" \\
        -d "originator=+31XXXXXXXXX" \\
        -d "body=Hi! This is your first message."
`}</code></pre>
    <p>{`Next, simply hit enter in your terminal.`}</p>
    <p>{`Good job! You’ve sent your first SMS with the MessageBird SMS API using cURL!`}</p>
    <h2>{`Step 3: View the response`}</h2>
    <p>{`Now, let’s view MessageBird’s HTTP response to your API call. If the set up was correct, you'll receive a response similar to this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
 "id": "9a9c83bc90d34a49b57fbc79e33bc5cd",
 "href": "https://rest.messagebird.com/messages/9a9c83bc90d34a49b57fbc79e33bc5cd",
 "direction": "mt",
 "type": "sms",
 "originator": "31XXXXXXXXX",
 "body": "Hi! This is your first message.",
 "reference": null,
 "validity": null,
 "gateway": 10,
 "typeDetails": {},
 "datacoding": "plain",
 "mclass": 1,
 "scheduledDatetime": null,
 "createdDatetime": "2019-02-19T11:56:17+00:00",
 "recipients": {
    "totalCount": 1,
    "totalSentCount": 1,
    "totalDeliveredCount": 0,
    "totalDeliveryFailedCount": 0,
    "items": [
        {
            "recipient": 31XXXXXXXXX,
            "status": "sent",
            "statusDatetime": "2019-02-19T11:56:17+00:00"
        }
    ]
}}
`}</code></pre>
    <p>{`If something went wrong, you’ll receive an error response from the MessageBird API. Don't worry, simply head over to the `}<a parentName="p" {...{
        "href": "/docs/sms-messaging/troubleshooting"
      }}>{`SMS API Troubleshooting`}</a>{` to understand the error.`}</p>
    <p>{`Congrats! You can now programmatically send SMS with the MessageBird SMS API using cURL.`}</p>
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'Receive an SMS',
      to: '/quickstarts/sms/receive-sms'
    }, {
      name: 'SMS Tutorials',
      to: '/tutorials?filter=sms'
    }, {
      name: 'SMS API Reference',
      to: '/api/sms-messaging'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      