import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const ContentLinksWrapper = styled.div`
  border-radius: 8px;
  background-color: #eef4ff;
  padding: 40px 0 40px 40px;
  margin-bottom: 80px;
`;
const ContentLinksList = styled.ul`
  display: flex;
  padding-left: 15px;
  flex-wrap: wrap;
`;
const ContentLinksListItem = styled.li`
  flex: 0 0 33%;
  color: #0089ff;
  text-align: left;
  margin-bottom: 40px;
  &:nth-last-child(-n + 3) {
    margin-bottom: 0px;
  }
`;
const StyledLink = styled(Link)`
  color: inherit;
  font-size: 18px;
  font-weight: 600;
  line-height: 16px;
`;

const ContentLinks = (props) => (
  <ContentLinksWrapper>
    <ContentLinksList>
      {props.products.map(({ text, url }) => {
        return (
          <ContentLinksListItem>
            <StyledLink to={url}>{text}</StyledLink>
          </ContentLinksListItem>
        );
      })}
    </ContentLinksList>
  </ContentLinksWrapper>
);

ContentLinks.propTypes = {
  products: PropTypes.array,
};

ContentLinks.defaultProps = {
  products: [],
};

export default ContentLinks;
