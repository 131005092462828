import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Implementing voice two-factor authentication (2FA) with MessageBird`}</h1>
    <h3>{`⏱ 15 min build time      ||      `}<a parentName="h3" {...{
        "href": "https://github.com/messagebirdguides/verify-voice-guide-php"
      }}>{`Download the Code`}</a></h3>
    <h2>{`Why build voice-based account security?`}</h2>
    <p>{`In this MessageBird Developer Tutorial you’ll learn how to improve your security by building a Voice-based two-factor authentication solution with the `}<a parentName="p" {...{
        "href": "https://www.messagebird.com/en/verify"
      }}>{`MessageBird Verify API`}</a>{`.`}</p>
    <p>{`Websites where users can sign up for an account typically use the email address as a unique identifier and a password as a security credential for users to sign in. At the same time, most websites ask users to add a verified phone number to their profile. Phone numbers are, in general, a better way to identify an account holder as a real person; they can also be used as a second authentication factor (2FA) or to restore access to a locked account.`}</p>
    <p>{`Verification of a phone number is straightforward:`}</p>
    <ol>
      <li parentName="ol">{`Ask your user to enter their number.`}</li>
      <li parentName="ol">{`Call the number programmatically and use a text-to-speech system to say a security code that acts as a one-time-password (OTP).`}</li>
      <li parentName="ol">{`Let the user enter this code on the website or inside an application as proof that they received the call.`}</li>
    </ol>
    <p>{`The MessageBird Verify API assists developers in implementing this workflow into their apps. Imagine you're running a social network and want to verify the profiles of your users. This MessageBird Developer Tutorial shows you an example application using PHP with integrated account security following the steps outlined above.`}</p>
    <p>{`By the way, it is also possible to replace the second step with an SMS message, as we explain in our `}<a parentName="p" {...{
        "href": "/tutorials/verify-node"
      }}>{`two factor authentication tutorial`}</a>{`. However, using voice for verification has the advantage that it works with every phone number, not just mobile phones, so it can be used to verify, for example, the landline of a business.`}</p>
    <p>{`The `}<a parentName="p" {...{
        "href": "/api/verify"
      }}>{`MessageBird Verify API`}</a>{` supports both options; voice and SMS!`}</p>
    <h2>{`Getting started`}</h2>
    <p>{`Our sample application is built in PHP using the `}<a parentName="p" {...{
        "href": "https://packagist.org/packages/slim/slim"
      }}>{`Slim`}</a>{` framework. You can download or clone the complete source code from the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/verify-voice-guide-php"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{` to run the application on your computer and follow along with the tutorial. To run the sample, you need to have PHP and Composer set up. PHP is already installed on Mac and available through the default package manager of most Linux distributions; Windows users can `}<a parentName="p" {...{
        "href": "https://windows.php.net/download/"
      }}>{`download it from windows.php.net`}</a>{`. Composer is available from `}<a parentName="p" {...{
        "href": "https://getcomposer.org/download/"
      }}>{`getcomposer.org`}</a>{`.`}</p>
    <p>{`Let's now open the directory where you've stored the sample code and run the following command to install the `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/messagebird"
      }}>{`MessageBird SDK`}</a>{` and other dependencies:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`composer install
`}</code></pre>
    <h2>{`Configuring the MessageBird SDK`}</h2>
    <p>{`The MessageBird SDK is defined in `}<inlineCode parentName="p">{`composer.json`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`{
   // [...]
   "require": {
       // [...]
       "messagebird/php-rest-api" : "^1.9.4"
   },
   // [...]
}
`}</code></pre>
    <p>{`An application can access the SDK, which is made available through Composer autoloading, by creating an instance of the `}<inlineCode parentName="p">{`MessageBird\\Client`}</inlineCode>{` class. The constructor takes a single argument, your `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`API key`}</a>{`. For frameworks like Slim, you can add the SDK to the dependency injection container:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`// Load and initialize MessageBird SDK
$container['messagebird'] = function() {
   return new MessageBird\\Client(getenv('MESSAGEBIRD_API_KEY'));
};
`}</code></pre>
    <p>{`As you can see in the code example above, the API key is loaded from an environment variable called MESSAGEBIRD_API_KEY. With `}<a parentName="p" {...{
        "href": "https://packagist.org/packages/vlucas/phpdotenv"
      }}>{`dotenv`}</a>{` you can define these variables in a `}<inlineCode parentName="p">{`.env`}</inlineCode>{` file. We've prepared an `}<inlineCode parentName="p">{`env.example`}</inlineCode>{` file in the repository, which you should rename to `}<inlineCode parentName="p">{`.env`}</inlineCode>{` and add the required information. Here's an example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-env"
      }}>{`MESSAGEBIRD_API_KEY=YOUR-API-KEY
`}</code></pre>
    <p>{`API keys can be created or retrieved from the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`API access (REST) tab`}</a>{` in the `}<em parentName="p">{`Developers section`}</em>{` of the MessageBird Dashboard.`}</p>
    <h2>{`Asking for the phone number`}</h2>
    <p>{`The sample application contains a form to collect the user's phone number. You can see the HTML as a Twig template in the file `}<inlineCode parentName="p">{`views/start.html.twig`}</inlineCode>{` and the route that renders it is `}<inlineCode parentName="p">{`get('/')`}</inlineCode>{`. All Twig files use the layout stored in `}<inlineCode parentName="p">{`views/layout.html.twig`}</inlineCode>{` to follow a common structure.`}</p>
    <p>{`The HTML form includes a `}<inlineCode parentName="p">{`<select>`}</inlineCode>{` element as a drop-down to choose the country, which allows users to enter their phone number without the country code. In production applications, you could use this to limit access on a country level and preselect the user's current country by IP address. The form field for the number is a simple `}<inlineCode parentName="p">{`<input>`}</inlineCode>{` with the `}<inlineCode parentName="p">{`type`}</inlineCode>{` set to `}<inlineCode parentName="p">{`tel`}</inlineCode>{` to inform compatible browsers that this is an input field for telephone numbers. Finally, there's a submit button; once the user clicks on it, the input is sent to the `}<inlineCode parentName="p">{`/verify`}</inlineCode>{` route.`}</p>
    <h2>{`Initiating the verification call`}</h2>
    <p>{`When the user submits their submit, the `}<inlineCode parentName="p">{`post('/verify')`}</inlineCode>{` routes takes over. The Verify API expects the user's telephone number to be in international format, so the first step is reading the input and concatenating the country code and the number. If the user enters their local number with a leading zero, we remove this digit.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`$app->post('/verify', function($request, $response) {
   $country_code = $request->getParsedBodyParam('country_code');
   $phone_number = $request->getParsedBodyParam('phone_number');

   // Compose number from country code and number
   $number = $country_code .
       ($phone_number[0] == '0' ? substr($phone_number, 1) : $phone_number);
`}</code></pre>
    <p>{`Next, we can create a `}<inlineCode parentName="p">{`MessageBird\\Objects\\Verify`}</inlineCode>{` object that encapsulates the information that’s necessary to initiate the verification call.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`   // Prepare verification object
   $verify = new MessageBird\\Objects\\Verify;
   $verify->recipient = $number;
   $verify->type = 'tts'; // TTS = text-to-speech, otherwise API defaults to SMS
   $verify->template = "Your account security code is %token.";
`}</code></pre>
    <p>{`The object has three attributes:`}</p>
    <ul>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`recipient`}</inlineCode>{` is the telephone number that we want to verify.`}</li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`type`}</inlineCode>{` is set to `}<inlineCode parentName="li">{`tts`}</inlineCode>{` to inform the API that we want to use a voice call for verification.`}</li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`template`}</inlineCode>{` contains the words to speak. It must include the placeholder `}<inlineCode parentName="li">{`%token`}</inlineCode>{` so that MessageBird knows where the code goes (we use the words token and code interchangeably; they mean the same thing). We don't have to generate this numeric code ourselves; the API takes care of it.`}</li>
    </ul>
    <p>{`There are a few other available options. For example, you can change the length of the code (it defaults to 6) with `}<inlineCode parentName="p">{`tokenLength`}</inlineCode>{`; you can also specify `}<inlineCode parentName="p">{`voice`}</inlineCode>{` as `}<inlineCode parentName="p">{`male`}</inlineCode>{` or `}<inlineCode parentName="p">{`female`}</inlineCode>{`, and set the `}<inlineCode parentName="p">{`language`}</inlineCode>{` to an ISO language code if you want the synthesized voice to be in a non-English language. You can find more details about these and other options in our `}<a parentName="p" {...{
        "href": "/api/verify#request-a-verify"
      }}>{`Verify API reference documentation`}</a>{`.`}</p>
    <p>{`Once the object has been created, we can send it through the API using the `}<inlineCode parentName="p">{`verify->create()`}</inlineCode>{` method on the SDK:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`   try {
       // Send request with MessageBird Verify API
       $verifyResponse = $this->messagebird->verify->create($verify);

       // API request was successful, call is on its way
       return $this->view->render($response, 'verify.html.twig', [
           'id' => $verifyResponse->getId()
       ]);
   } catch (Exception $e) {
       // Something went wrong
       error_log(get_class($e).": ".$e->getMessage());
       return $this->view->render($response, 'start.html.twig', [
           'error' => "Could not initiate call."
       ]);
   }
`}</code></pre>
    <p>{`As you can see, the API request is placed inside a try-catch block. If the API does not throw any exception, we can assume our request was successful. In this case, we render a new template. We also add the `}<inlineCode parentName="p">{`id`}</inlineCode>{` attribute of the API response (using the `}<inlineCode parentName="p">{`getId()`}</inlineCode>{` method) to this template because we need the identification of our verification request in the next step to confirm the code.`}</p>
    <p>{`If there was an error and the application ends up in the catch block, we show the same page to the user as before but add an error parameter which the template displays as a message above the form to notify the user. We also log the raw output to assist with debugging.`}</p>
    <h2>{`Verifying the code`}</h2>
    <p>{`The template stored in `}<inlineCode parentName="p">{`views/verify.twig.html`}</inlineCode>{`, which we render in the success case, contains an HTML form with a hidden input field to pass forward the `}<inlineCode parentName="p">{`id`}</inlineCode>{` from the verification request. It also contains a regular `}<inlineCode parentName="p">{`<input>`}</inlineCode>{` with `}<inlineCode parentName="p">{`type`}</inlineCode>{` set to `}<inlineCode parentName="p">{`text`}</inlineCode>{` so that the user can enter the code that they've heard on the phone. When the user submits this form, it sends this token to the `}<inlineCode parentName="p">{`/confirm`}</inlineCode>{` route.`}</p>
    <p>{`Inside this route, we call another method on the MessageBird SDK, `}<inlineCode parentName="p">{`verify->verify()`}</inlineCode>{` and provide the ID and token as two parameters:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`$app->post('/confirm', function($request, $response) {
   $id = $request->getParsedBodyParam('id');
   $token = $request->getParsedBodyParam('token');

   try {
       // Complete verification request with MessageBird Verify API
       $this->messagebird->verify->verify($id, $token);

       // Confirmation was successful
       return $this->view->render($response, 'confirm.html.twig', []);
   } catch (Exception $e) {
       // Something went wrong
       error_log(get_class($e).": ".$e->getMessage());
       return $this->view->render($response, 'start.html.twig', [
           'error' => "Verification has failed. Please try again."
       ]);
   }
});
`}</code></pre>
    <p>{`Just as before, the API request is contained in a try-catch block. We inform the user about the status of the verification by showing either a new success response, which is stored in `}<inlineCode parentName="p">{`views/confirm.handlebars`}</inlineCode>{`, or the first page again with an error message. In production applications, you would use the success case to update your user's phone number as verified in your database.`}</p>
    <h2>{`Testing`}</h2>
    <p>{`You’re done! Let's go ahead and test your application. All we need to do is enter the following command in your console:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`php -S 0.0.0.0:8080 index.php
`}</code></pre>
    <p>{`Then, open your browser to http://localhost:8080/ and walk through the process yourself!`}</p>
    <p>{`Awesome! You can now leverage the flow, code snippets and UI examples from this tutorial to build your own voice-based account security system. Don't forget to download the code from the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/verify-voice-guide"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`.`}</p>
    <p><strong parentName="p">{`Nice work!`}</strong>{` 🎉`}</p>
    <p>{`You now have a running integration of MessageBird's Verify API using PHP!`}</p>
    <h2>{`Start building!`}</h2>
    <p>{`Want to build something similar but not quite sure how to get started? Please feel free to let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`;  we'd love to help!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      