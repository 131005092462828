import React, { Fragment, useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Flex } from '@rebass/grid';
import { bool, arrayOf, string } from 'prop-types';
import loadable from '@loadable/component';

import TreeNav from './components/TreeNav';
import { apisNavigation, quickStartsNavigation } from './constants';
import LocationContext from 'src/contexts/LocationContext';
import flattenByKey from 'src/helpers/flattenByKey';
import { Heading } from './components/TreeNav.Heading';
import PageHeadersContext from 'src/contexts/PageHeadersContext';
import useScrollSpy from 'src/customHooks/useScrollSpy';
// TODO: ideally expanding and height should be handled by css only
const NAV_ITEM_HEIGHT = 40;

function isRouteActive(route, activeOn) {
  if (activeOn instanceof RegExp) {
    return !!route.contains(activeOn);
  }
  return route === activeOn;
}

/** SidebarItem recursively renders the an item and their subMenu
 * @param {object} item - The item object. Should contain a route and a name. Can also contain a submenu
 * @param {func} expandMenu - A reference to the function that toggles the expanded state of a specific submenu
 * @param {array} expandedMenus - An array of routes that refer to all the expanded submenus
 * @param {string} activeRoute - The current active Route. Used for active state
 * @param {bool} nested - Whether or not the submenu is a child of another submenu
 */

const SidebarItem = React.memo(
  ({
    item,
    expandMenu,
    expandedMenus,
    handleExpandedMenusChange,
    activeRoute,
    nested,
  }) => {
    const { name, route, subMenu, label, activeOn, noLink } = item;
    const isActive = isRouteActive(activeRoute, activeOn);

    useEffect(() => {
      // add items to expandedMenus array during page rendering
      if (!expandedMenus.includes(route) && isActive) {
        handleExpandedMenusChange(route);
      }
    }, [expandedMenus, isActive, route]);
    if (subMenu) {
      const allSubItems = flattenByKey(subMenu, 'subMenu');

      const height = allSubItems.length * NAV_ITEM_HEIGHT + 50;
      const isActive = isRouteActive(activeRoute, activeOn);
      const hasActiveSubItems = allSubItems.some((subItem) =>
        isRouteActive(activeRoute, subItem.activeOn),
      );

      // add some props if it's a link, !noLink means a link ;)
      const treeNavItemProps =
        (!noLink && { key: route, to: route, component: Link }) || {};
      useEffect(() => {
        // add items to expandedMenus array during page rendering
        if (!expandedMenus.includes(route) && (isActive || hasActiveSubItems)) {
          handleExpandedMenusChange(route);
        }
      }, [expandedMenus, route, isActive, hasActiveSubItems]);

      const handleClick = (e) => {
        // do not prevent default for nav items that will be links
        if (noLink) {
          e.preventDefault();
        }
        expandMenu(route);
      };

      return (
        <Fragment key={activeOn}>
          <TreeNav.Item
            onClick={handleClick}
            active={isActive}
            {...treeNavItemProps}
          >
            {name}
            {label}
          </TreeNav.Item>
          <TreeNav.SubItems
            height={height}
            isExpanded={expandedMenus.includes(route)}
            nested
          >
            {subMenu.map((subItem) => (
              <SidebarItem
                key={subItem.activeOn}
                item={subItem}
                handleExpandedMenusChange={handleExpandedMenusChange}
                expandMenu={expandMenu}
                expandedMenus={expandedMenus}
                activeRoute={activeRoute}
                nested
                expanded
              />
            ))}
          </TreeNav.SubItems>
        </Fragment>
      );
    }

    return (
      <TreeNav.Item
        key={route}
        to={route}
        component={Link}
        active={isRouteActive(activeRoute, activeOn)}
      >
        {name}
        {label}
      </TreeNav.Item>
    );
  },
);

SidebarItem.propTypes = {
  item: PropTypes.shape({}).isRequired,
  expandMenu: PropTypes.func.isRequired,
  expandedMenus: PropTypes.arrayOf(PropTypes.string),
  activeRoute: PropTypes.string,
  nested: PropTypes.bool,
};

SidebarItem.defaultProps = {
  expandedMenus: [],
  activeRoute: '',
  nested: false,
};

const Sidebar = ({ api, tutorials, languages, ...props }) => {
  const [expandedMenus, setExpandedMenus] = useState([]);
  const { activeRoute } = useContext(PageHeadersContext);
  const { hash, pathname } = useContext(LocationContext) || {};
  const pathnameWithoutLanguage = (languages || ['curl']).reduce(
    (path, lang) => path.replace(new RegExp(`-${lang}${'/'}?$`), '/'),
    pathname + hash,
  );
  const expandMenu = (route) => {
    if (expandedMenus.includes(route)) {
      setExpandedMenus(expandedMenus.filter((e) => e !== route));
      return;
    }
    setExpandedMenus([...expandedMenus, route]);
  };
  const handleExpandedMenusChange = (route) => {
    setExpandedMenus([...expandedMenus, route]);
  };
  const isApi = api;
  const navigation = isApi ? apisNavigation : quickStartsNavigation;

  useScrollSpy(isApi);
  //clean up expandedMenus on each pathname update when location was changed during scrolling
  useEffect(() => {
    isApi && setExpandedMenus(expandedMenus.filter((e) => e !== pathname));
  }, [pathname]);
  return (
    <TreeNav {...props}>
      <Flex
        py="40px"
        flexDirection="column"
        css="z-index: 2; @media (min-width: 768px){display:none;} a:not(:last-child) {margin-bottom: 12px;} a {color: #24374E; opacity: .9;}"
      >
        <Link to="/quickstarts/sms-overview/">Quickstarts</Link>
        <Link to="/api/">Api Reference</Link>
        <Link to="/tutorials">Tutorials</Link>
      </Flex>
      {!tutorials ? (
        <>
          <Heading>{api ? 'API Reference' : 'Quickstarts'}</Heading>
          {navigation.map((item) => (
            <SidebarItem
              key={item.activeOn + item.name}
              item={item}
              expandMenu={expandMenu}
              expandedMenus={expandedMenus}
              activeRoute={isApi ? activeRoute : pathnameWithoutLanguage}
              handleExpandedMenusChange={handleExpandedMenusChange}
            />
          ))}
        </>
      ) : null}
    </TreeNav>
  );
};

Sidebar.propTypes = {
  api: bool,
};

Sidebar.defaultProps = {
  api: false,
};

export default React.memo(Sidebar);
