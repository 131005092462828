import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Docs.tsx";
import Notification from 'components/Notification';
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Send an Outbound SMS`}</h1>
    <p>{`In this SMS API Quickstart, you'll learn how to send an SMS from your web application using the SMS API.`}</p>
    <h2>{`Step 1: Set up your test environment`}</h2>
    <p>{`To start testing with the official Python SDK for the SMS API, you need to first set up your test environment.`}</p>
    <p>{`The following command will install MessageBird:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`pip install messagebird
`}</code></pre>
    <p>{`Now, let's create a `}<inlineCode parentName="p">{`send_sms.py`}</inlineCode>{` file in your project directory.`}</p>
    <h2>{`Step 2: Send your first SMS`}</h2>
    <p>{`Next, enter `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`your credentials`}</a>{` and mobile number - as both the `}<inlineCode parentName="p">{`originator`}</inlineCode>{` and `}<inlineCode parentName="p">{`recipient`}</inlineCode>{` - to the snippet below and copy it to your `}<inlineCode parentName="p">{`send_sms.py`}</inlineCode>{` file`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`import messagebird

client = messagebird.Client(ACCESS_KEY)
message = client.message_create(
          'MessageBird',
          '31XXXXXXXXX',
          'Hi! This is your first message',
          { 'reference' : 'Foobar' }
      )
`}</code></pre>
    <Notification type="INFO" mdxType="Notification">
You can access and manage your credentials in the <a href="https://dashboard.messagebird.com/en/developers/access">MessageBird Developer Dashboard</a>.
    </Notification>
    <p>{`Now, run the following command line:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`python send_sms.py
`}</code></pre>
    <p>{`Good job! You’ve just sent your first SMS with the MessageBird SMS API!`}</p>
    <h2>{`Step 3: View the response`}</h2>
    <p>{`Now, let’s view MessageBird’s HTTP response to your API call. If the set up was correct, you'll receive a response similar to this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`{
  'id' : 'e60a6f90453a19019c56ed6b20170831',
  'href' : 'https://rest.messagebird.com/messages/e60a6f90453a19019c56ed6b20170831',
  'direction' : 'mt',
  'type' : 'sms',
  'originator' : 'MessageBird',
  'body' : 'Hi! This is your first message',
  'reference' : 'Foobar',
  'validity' : None,
  'gateway' : 240,
  'typeDetails' : {},
  'datacoding' : 'plain',
  'mclass' : 1,
  'scheduledDatetime' : None,
  'createdDatetime' : '2016-05-03 14:26:57',
  'recipients' : [
    'totalCount' : 1,
    'totalSentCount' : 1,
    'totalDeliveredCount' : 0,
    'totalDeliveryFailedCount' : 0,
    'items' : [
      {
        'recipient' : 31XXXXXXXXX,
        'status' : 'sent',
        'statusDatetime' : '2016-05-03 14:26:57',
      },
    ]
  ]
}
`}</code></pre>
    <p>{`If something went wrong, you’ll receive an error response from the MessageBird API. Don't worry, simply head over to the `}<a parentName="p" {...{
        "href": "/docs/sms-messaging/troubleshooting"
      }}>{`SMS API Troubleshooting`}</a>{` to understand the error.`}</p>
    <p>{`Congrats! You can now programmatically send SMS with the MessageBird SMS API using Python.`}</p>
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'Handle inbound SMS via the API',
      to: '/docs/sms-messaging/handle-inbound-sms'
    }, {
      name: 'SMS API Use Case Tutorials and Sample Projects',
      to: '/tutorials?filter=sms'
    }, {
      name: 'Full SMS API Reference',
      to: '/api/sms-messaging'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      