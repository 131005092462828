import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const QuestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 80px;
`;

const FooterQuestionsDesc = styled.p`
  text-align: center;
  font-size: 16px;
  color: #435369;
  margin: 0;
  a {
    cursor: pointer;
  }
`;
const FooterHeading = styled.h1`
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
`;

const QuestionsSection = (): JSX.Element => (
  <QuestionWrapper>
    <FooterHeading css="margin-bottom: 32px">Questions?</FooterHeading>
    <FooterQuestionsDesc>
      We’re always happy to help with code or other doubts you might have! Check
      out our <Link to="/quickstarts/sms-overview/">Quickstarts</Link>,{' '}
      <Link to="/api">API Reference</Link>,{' '}
      <Link to="/tutorials">Tutorials</Link>, <Link to="/libraries">SDKs</Link>,
      or{' '}
      <a
        href="https://www.messagebird.com/en/contact"
        target="_blank"
        rel="noopener noreferrer"
      >
        contact our Support team
      </a>
      .
    </FooterQuestionsDesc>
  </QuestionWrapper>
);
export default QuestionsSection;
