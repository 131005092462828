import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Setting SMS marketing subscriptions with MessageBird`}</h1>
    <h3>{`⏱ 30 min build time || `}<a parentName="h3" {...{
        "href": "https://github.com/messagebirdguides/subscriptions-guide-java"
      }}>{`Download the Code`}</a></h3>
    <h2>{`Why build SMS marketing subscriptions?`}</h2>
    <p>{`In this MessageBird Developer Tutorial, you’ll learn how to implement an SMS marketing campaign subscription application powered by the `}<a parentName="p" {...{
        "href": "/api/sms-messaging"
      }}>{`MessageBird SMS Messaging API`}</a>{`, which enables your subscribers to seamlessly opt-in and out.`}</p>
    <p>{`SMS makes it incredibly easy for businesses to reach consumers everywhere at any time, directly on their mobile devices. For many people, these messages are a great way to discover things like discounts and special offers from a company, while others might find them annoying. For this reason, it’s important and also required by law in many countries to provide clear opt-in and opt-out mechanisms for SMS broadcast lists. To make it work independently of a website it's useful to assign a programmable `}<a parentName="p" {...{
        "href": "https://www.messagebird.com/en/numbers"
      }}>{`virtual mobile number`}</a>{` to your SMS campaign and handle incoming messages programmatically so users can control their subscription with basic command keywords.`}</p>
    <p>{`We'll walk you through the following steps:`}</p>
    <ul>
      <li parentName="ul">{`A person can send the keyword `}<em parentName="li">{`SUBSCRIBE`}</em>{` to a specific VMN that the company includes in their advertising material; the opt-in is immediately confirmed.`}</li>
      <li parentName="ul">{`If the person no longer wants to receive messages, they can send the keyword `}<em parentName="li">{`STOP`}</em>{` to the same number; the opt-out is also confirmed.`}</li>
      <li parentName="ul">{`An administrator can enter a message in a form on a website. Then they can immediately send this message to all confirmed subscribers.`}</li>
    </ul>
    <h2>{`Getting started`}</h2>
    <p>{`First things first, our sample application is build in Java, so you need to install `}<a parentName="p" {...{
        "href": "https://www.oracle.com/technetwork/java/javase/downloads/jdk8-downloads-2133151.html"
      }}>{`Java`}</a>{` and `}<a parentName="p" {...{
        "href": "https://maven.apache.org/"
      }}>{`Maven`}</a>{`.`}</p>
    <p>{`The source code is available in the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/subscriptions-guide-java"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`, which you can either clone with git or from where you can download a ZIP file with the source code to your computer.`}</p>
    <p>{`After saving the code, import the project into your IDE. Maven should automatically download all the necessary dependencies.`}</p>
    <p>{`The sample application uses `}<a parentName="p" {...{
        "href": "https://mongodb.github.io/mongo-java-driver/"
      }}>{`MongoDB`}</a>{` to provide an in-memory database for testing, so you don't need to configure an external database.`}</p>
    <h2>{`Prerequisites for receiving messages`}</h2>
    <h3>{`Overview`}</h3>
    <p>{`This tutorial describes receiving messages using MessageBird. From a high-level viewpoint, receiving is relatively simple: an application defines a `}<em parentName="p">{`webhook URL`}</em>{`, which you assign to a number purchased in the MessageBird Dashboard using `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/flow-builder"
      }}>{`Flow Builder`}</a>{`.. A webhook is a URL on your site that doesn't render a page to users but is like an API endpoint that can be triggered by other servers. Every time someone sends a message to that number, MessageBird collects it and forwards it to the webhook URL where you can process it.`}</p>
    <h3>{`Exposing your development server with ngrok`}</h3>
    <p>{`When working with webhooks, an external service like MessageBird needs to access your application, so the URL must be public; however, during development you're typically working in a local development environment that is not publicly available. Thankfully this is not a big deal since various tools and services allow you to quickly expose your development environment to the Internet by providing a tunnel from a public URL to your local machine. One of the most popular tools is `}<a parentName="p" {...{
        "href": "https://ngrok.com/"
      }}>{`ngrok`}</a>{`.`}</p>
    <p>{`You can download `}<a parentName="p" {...{
        "href": "https://ngrok.com/download"
      }}>{`ngrok`}</a>{` for free as a single-file binary for almost every operating system, or optionally sign up for an account to access additional features.`}</p>
    <p>{`Let’s start a tunnel by providing a local port number on which the application runs. We’ll run our Ruby server on port 4567, so you can launch your tunnel with this command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`ngrok http 4567
`}</code></pre>
    <p>{`After you've launched the tunnel, ngrok displays your temporary public URL along with some other information. We'll need that URL in a minute.`}</p>
    <p>{`Another common tool for tunneling your local machine is `}<a parentName="p" {...{
        "href": "https://localtunnel.me/"
      }}>{`localtunnel.me`}</a>{`, which works virtually in the same way; you can have a look at it if you're facing problems with with ngrok. Keep in mind that it requires you to install `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/"
      }}>{`NPM`}</a>{` first.`}</p>
    <h3>{`Getting an inbound number`}</h3>
    <p>{`A requirement for receiving messages is a dedicated inbound number. Virtual mobile numbers look and work similar to regular mobile numbers; however, instead of being attached to a mobile device via a SIM card, they live in the cloud and can process inbound SMS and voice calls. MessageBird offers numbers from different countries for a low monthly fee; `}<a parentName="p" {...{
        "href": "https://www.messagebird.com/en/numbers"
      }}>{`feel free to explore our low-cost programmable and configurable numbers`}</a>{`.`}</p>
    <p>{`Purchasing a number is quite easy:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Go to the ‘`}<a parentName="p" {...{
            "href": "https://dashboard.messagebird.com/en/numbers"
          }}>{`Numbers`}</a>{`’ section in the left-hand side of your Dashboard and click the blue button ‘`}<a parentName="p" {...{
            "href": "https://dashboard.messagebird.com/en/vmn/buy-number"
          }}>{`Buy a number`}</a>{`’ in the top-right side of your screen.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Pick the country in which you and your customers are located, and make sure the SMS capability is selected.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Choose one number from the selection and the duration for which you want to pay now.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Confirm by clicking ‘Buy Number’ in the bottom-right of your screen.`}</p>
      </li>
    </ol>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/subscriptions-java/image1.png",
        "alt": "Buy a VMN"
      }}></img></p>
    <p>{`Awesome, you’ve set up your first virtual mobile number! 🎉
`}<strong parentName="p">{`Pro-tip:`}</strong>{` Check out our Help Center for more information about `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/sections/201958489-Virtual-Numbers"
      }}>{`virtual mobile numbers`}</a>{` and `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/sections/360000108538-Country-info-Restrictions"
      }}>{`country restrictions`}</a>{`.`}</p>
    <h3>{`Connect your number to the webhook`}</h3>
    <p>{`So you have a number now, but MessageBird has no idea what to do with it. That's why now you need to define a `}<em parentName="p">{`Flow`}</em>{` that links your number to your webhook. This is how you do it:`}</p>
    <h4>{`STEP ONE`}</h4>
    <p>{`On the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/numbers"
      }}>{`Numbers`}</a>{` section of the MessageBird Dashboard, click the "Add new flow" icon next to the number you purchased.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/subscriptions-java/image2.png",
        "alt": "FlowBuilder"
      }}></img></p>
    <h4>{`STEP TWO`}</h4>
    <p>{`Hit ‘Create Custom Flow’ and give your flow a name, choose ‘SMS’ as the trigger and hit ‘Next’.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/subscriptions-java/image3.png",
        "alt": "FlowBuilder"
      }}></img></p>
    <h4>{`STEP THREE`}</h4>
    <p>{`The number is already attached to the first step ‘SMS’. Add a new step by pressing the small ‘+’, choose ‘Fetch to URL’ and select ‘POST’ as the method; copy the output from the output of the ngrok command in the URL and add `}<inlineCode parentName="p">{`/webhook`}</inlineCode>{` to it—this is the name of the route we use to handle incoming messages in our sample application. Click on ‘Save’ when ready.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/subscriptions-java/image4.png",
        "alt": "FlowBuilder"
      }}></img></p>
    <h4>{`STEP FOUR`}</h4>
    <p>{`Ready! Hit ‘Publish’ on the right top of the screen to activate your flow. Well done, another step closer to testing incoming messages! Your flow should look something like this:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/subscriptions-java/image5.png",
        "alt": "FlowBuilder"
      }}></img></p>
    <p><strong parentName="p">{`Pro-tip:`}</strong>{` It might be useful to rename it this flow, because `}<em parentName="p">{`Untitled flow`}</em>{` won't be helpful in the long run. You can do this by clicking on the icon next to button ‘Back to Overview’ and pressing ‘Rename flow’.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/subscriptions-java/image6.png",
        "alt": "FlowBuilder"
      }}></img></p>
    <p>{`A number must be added to your MessageBird contact list before you can send SMS messages to it. To make sure that any phone number that sends a message to your VMN can receive messages from you, you can add an `}<inlineCode parentName="p">{`Add Contact`}</inlineCode>{` step just before the ‘Forward to URL’ step. Be sure to configure the \`Add Contact’ step to add the "sender" to your contact list.`}</p>
    <h2>{`Configuring the MessageBird SDK`}</h2>
    <p>{`While the MessageBird SDK and an API key are not required to receive messages, it is necessary for sending confirmations and marketing messages. The SDK is defined in `}<inlineCode parentName="p">{`pom.xml`}</inlineCode>{` and loaded with these statements:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.messagebird.MessageBirdClient;
import com.messagebird.MessageBirdService;
import com.messagebird.MessageBirdServiceImpl;

import io.github.cdimascio.dotenv.Dotenv;

Dotenv dotenv = Dotenv.load();

// Create a MessageBirdService
final MessageBirdService messageBirdService = new MessageBirdServiceImpl(dotenv.get("MESSAGEBIRD_API_KEY"));

// Add the service to the client
final MessageBirdClient messageBirdClient = new MessageBirdClient(messageBirdService);
`}</code></pre>
    <p>{`You need to provide a MessageBird API key, as well as the phone number you registered so that you can use it as the originator via environment variables. Thanks to `}<a parentName="p" {...{
        "href": "https://mvnrepository.com/artifact/io.github.cdimascio/java-dotenv"
      }}>{`dotenv`}</a>{` you can also supply these through an `}<inlineCode parentName="p">{`.env`}</inlineCode>{` file stored in your resources:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`MESSAGEBIRD_API_KEY=YOUR-API-KEY
MESSAGEBIRD_ORIGINATOR=+31970XXXXXXX
`}</code></pre>
    <p>{`Go to the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/user/index"
      }}>{`MessageBird Dashboard`}</a>{`; if you have already created an API key it will be shown right there. If you don’t see any key on the Dashboard or if you're unsure whether this key is in `}<em parentName="p">{`live`}</em>{` mode, go to the `}<em parentName="p">{`Developers`}</em>{` section in the MessageBird Dashboard and open the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`API access (REST) tab`}</a>{`. There you can create new API keys and manage your existing ones.`}</p>
    <p>{`If you are having any issues creating your API key, please reach out to `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we’ll make sure to help you out.`}</p>
    <h2>{`Receiving messages`}</h2>
    <p>{`Now we're fully prepared for receiving inbound messages; let's have a look at the actual implementation of our `}<inlineCode parentName="p">{`/webhook`}</inlineCode>{` route:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`// Handle incoming webhooks
post("/webhook",
   (req, res) ->
   {
       JSONObject requestPayload = new JSONObject(req.body());

       // Read request
       String number = (String) requestPayload.get("originator");
       String text = (String) requestPayload.get("body");
       text = text.toLowerCase();
`}</code></pre>
    <p>{`The webhook receives multiple request parameters from MessageBird; however, we're only interested in two of them: the `}<inlineCode parentName="p">{`originator`}</inlineCode>{` (the number of the user who sent the message) and the `}<inlineCode parentName="p">{`body`}</inlineCode>{` (the text of the message). The content is trimmed and converted into lower case so we can easily do case-insensitive command detection.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`// Find subscriber in our database
MongoCollection<Document> subscribers = database.getCollection("subscribers");
Document doc = subscribers.find(eq("number", number)).first();
`}</code></pre>
    <p>{`Using our MongoDB client, we'll look up the number in a collection aptly named subscribers.`}</p>
    <p>{`We're looking at three potential cases:`}</p>
    <ul>
      <li parentName="ul">{`The user has sent `}<em parentName="li">{`SUBSCRIBE`}</em>{` and the number doesn’t exist. In that case, the subscriber should be added and opted in.`}</li>
      <li parentName="ul">{`The user has submitted `}<em parentName="li">{`SUBSCRIBE`}</em>{` and the number exists but has opted out. In that case, it should be opted in (again).`}</li>
      <li parentName="ul">{`The user has sent `}<em parentName="li">{`STOP`}</em>{` and the number exists and has opted in. In that case, it should be opted out.`}</li>
    </ul>
    <p>{`For each of those cases, a differently worded confirmation message should be sent. For the sake of keeping the tutorial simple, all incoming messages that don't fit any of these cases are ignored and don't get a reply; however, for production-ready applications, you can optimize this behavior by sending a help message with all supported commands.`}</p>
    <p>{`The implementation of each case is similar, so let's only look at one of them here:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`if (doc == null && text.equals("subscribe")) {
   // The user has sent the "subscribe" keyword
   // and is not stored in the database yet, so
   // we add them to the database.
   Document entry = new Document("number", number)
           .append("subscribed", true);
   subscribers.insertOne(entry);

   // Notify the user
   messageBirdClient.sendMessage(dotenv.get("MESSAGEBIRD_ORIGINATOR"), "Thanks for subscribing to our list! Send STOP anytime if you no longer want to receive messages from us.", phones);
}
`}</code></pre>
    <p>{`If no `}<inlineCode parentName="p">{`doc`}</inlineCode>{` (database entry) exists and the text matches “SUBSCRIBE”, the script executes an insert query that stores a document with the number and the boolean variable `}<inlineCode parentName="p">{`subscribed`}</inlineCode>{` set to `}<inlineCode parentName="p">{`true`}</inlineCode>{`. The user is notified by calling the `}<inlineCode parentName="p">{`messageBirdClient.sendMessage`}</inlineCode>{` SDK method and, as parameters, passing the originator from our configuration, a recipient list with the number from the incoming message and a hardcoded text body.`}</p>
    <h2>{`Sending messages`}</h2>
    <h3>{`Showing a form`}</h3>
    <p>{`We've defined a simple form with a single text area and a submit button, and stored it as a Mustache template in `}<inlineCode parentName="p">{`views/home.mustache`}</inlineCode>{`. It’s rendered for a GET request on the root of the application. As a small hint for the admin, we're also showing the number of subscribers in the database.`}</p>
    <h3>{`Processing input`}</h3>
    <p>{`The form submits its content as a POST request to the `}<inlineCode parentName="p">{`/send`}</inlineCode>{` route. The implementation of this route fetches all subscribers that have opted in from the database and then uses the MessageBird SDK to send a message to them. It’s possible to send a message to up to 50 receivers in a single API call, so the script splits a list of subscribers that is longer than 50 numbers (highly unlikely during testing, unless you have amassed an impressive collection of phones) into blocks of 50 numbers each. Sending uses the `}<inlineCode parentName="p">{`messageBirdClient.sendMessage`}</inlineCode>{` SDK method which you've already seen in the previous section.`}</p>
    <p>{`Here's the full code block:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`post("/send",
   (req, res) ->
   {
       String message = req.queryParams("message");

       Map<String, Object> model = new HashMap<>();

       // Get number of subscribers to show on the form
       MongoCollection<Document> subscribers = database.getCollection("subscribers");

       FindIterable<Document> iterable = subscribers.find(eq("subscribed", true));
       MongoCursor<Document> cursor = iterable.iterator();

       final List<BigInteger> recipients = new ArrayList<BigInteger>();
       Integer count = 0;

       // Collect all numbers
       while (cursor.hasNext()) {
           Document d = cursor.next();
           BigInteger phoneNumber = new BigInteger((String) d.get("number"));
           recipients.add(phoneNumber);
           count += 1;
           if (count == subscribers.countDocuments() || count % 50 == 0) {
               // We have reached either the end of our list or 50 numbers,
               // which is the maximum that MessageBird accepts in a single
               // API call, so we send the message and then, if any numbers
               // are remaining, start a new list
               messageBirdClient.sendMessage(dotenv.get("MESSAGEBIRD_ORIGINATOR"), message, recipients);
               recipients.clear();
           }
       }

       model.put("count", count);
       return new ModelAndView(model, "sent.mustache");
   },

   new MustacheTemplateEngine()
);
`}</code></pre>
    <h2>{`Testing`}</h2>
    <p>{`You’re done! It’s time to test your application.`}</p>
    <p>{`Double-check that you’ve set up your number correctly with a flow that forwards incoming messages to a ngrok URL and that the tunnel is still running. Keep in mind that whenever you start a fresh tunnel, you'll get a new URL, so you have to update it in the flows accordingly.`}</p>
    <p>{`To start the application, build and run it through your IDE.`}</p>
    <p>{`While keeping the console open, take out your phone, launch the SMS app, and send a message to your virtual mobile number with the keyword “SUBSCRIBE”. You should receive a confirmation message stating that you've been subscribed to the broadcast list. Open http://localhost:4567/ in your browser (or your tunnel URL), and you should also see that there's one subscriber. Try sending yourself a message now. And voilá, your marketing system is ready!`}</p>
    <p>{`You can adapt the sample application for production by adding some authorization to the web form; otherwise, anybody could send messages to your subscribers. Don't forget to download the code from the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/subscriptions-guide-java"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`.`}</p>
    <p><strong parentName="p">{`Nice work!`}</strong>{` 🎉`}</p>
    <p>{`You've just built your own marketing system with MessageBird using Java!`}</p>
    <h2>{`Start building!`}</h2>
    <p>{`Want to start building your solution but not quite sure how to get started? Feel free to let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we'd love to help!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      