import React, { useContext, useEffect } from 'react';
import { createHistory } from '@reach/router';
import PageHeadersContext from '../contexts/PageHeadersContext';
import { apisNavigation } from '../components/Sidebar/constants';

//Identify headers which are visible
const isInView = (element) => {
  const rect = element.getBoundingClientRect();
  return rect.top >= 0 && rect.bottom <= window.innerHeight;
};

export const getTopHeader = (allHeadersIds) => {
  const items = allHeadersIds.map((el) => {
    if (typeof el == 'string') {
      // ignore all elements that are not text
      const id = el.replace(/[\s\t\n\r]/g, '-').toLowerCase();
      return document.getElementById(id) && document.getElementById(id);
    }
  });
  const visibleHeaders = items
    .filter((element) => element && isInView(element))
    .filter((el) => sidebarActiveOnHeaders.includes(el.id));
  return visibleHeaders.length > 0 && visibleHeaders[0].id;
};

const extractActiveOnLinks = (item) => {
  const childActiveOnLinks = item.subMenu
    ? item.subMenu.flatMap((subItem) => extractActiveOnLinks(subItem))
    : [];
  return [item.activeOn, ...childActiveOnLinks];
};
// Get array of sidebar links
const sidebarActiveOnHeaders = apisNavigation
  .flatMap((item) => extractActiveOnLinks(item))
  .filter((item) => item && item.includes('#'))
  .map((item) => item.split('#')[1]);

export const useScrollSpy = (isApi) => {
  const { headersIds, setActiveRoute } = useContext(PageHeadersContext);
  const [topHeader, setTopHeader] = React.useState('');
  let history;
  useEffect(() => {
    if (window) {
      history = createHistory(window);
    }
  });
  let timeout = null;

  const onScroll = () => {
    timeout = window.setTimeout(() => {
      const header = getTopHeader(headersIds);
      if (header) {
        setTopHeader(header);
      }
    }, 100);
  };
  useEffect(() => {
    if (topHeader) {
      setActiveRoute(history.location.pathname + '#' + topHeader);
      history.navigate(`#${topHeader}`);
    }
  }, [topHeader]);

  useEffect(() => {
    if (isApi) {
      window.addEventListener('scroll', onScroll);
      return () => {
        window.removeEventListener('scroll', onScroll);
        window.clearTimeout(timeout);
      };
    }
  }, [headersIds]);
};

export default useScrollSpy;
