import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Making outbound voice calls with MessageBird`}</h1>
    <h3>{`⏱ 10 min build time`}</h3>
    <p>{`In this MessageBird Developer Tutorial you’ll learn how to make calls with the MessageBird Voice API.`}</p>
    <p>{`Before we get started, have you set up your Python development environment with the MessageBird Python client?`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`No!`}</strong>{` Make sure you to set this up before continuing; you can read this `}<a parentName="li" {...{
          "href": "/tutorials/setup-local-dev-environment-node"
        }}>{`MessageBird Developer Tutorial`}</a>{` to learn how to do so.`}</li>
      <li parentName="ul"><strong parentName="li">{`Yes!`}</strong>{` Great! We’ll show you how to make your first API request and begin establishing Voice Calls with MessageBird using Go.`}</li>
    </ul>
    <h2>{`Getting started`}</h2>
    <p>{`First, let's create a new Python project directory. In this directory, create a `}<inlineCode parentName="p">{`voice_call.py`}</inlineCode>{` file and open it in your favorite text editor. Start by importing the MessageBird Python library and creating an instance of the MessageBird client:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`import messagebird

#create instance of messagebird.Client using API key
client = messagebird.Client('your-api-key')
`}</code></pre>
    <p>{`Keep in mind that you can create either a `}<strong parentName="p">{`test`}</strong>{` or `}<strong parentName="p">{`live`}</strong>{` API key:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`test`}</strong>{` API keys simulate responses from the MessageBird server, allowing you to test your code flow and error handling before sending real messages. You still must have an internet connection to use MessageBird REST API features.`}</li>
      <li parentName="ul"><strong parentName="li">{`live`}</strong>{` API keys allow you to send actual messages to your recipients. We recommend that you don’t publish this key anywhere.`}</li>
    </ul>
    <p>{`In order to start using the SDK, replace `}<inlineCode parentName="p">{`your-api-key`}</inlineCode>{` with your API
key.`}</p>
    <p>{`To keep this tutorial straightforward, we'll be hardcoding the MessageBird API key in our application; yet, for production applications, we recommended storing
the key in a configuration file or environment variable instead and passing this variable to your application. You'll see this in practice later in our MessageBird Developer Tutorials for common use cases.`}</p>
    <h2>{`Making a voice call`}</h2>
    <p>{`Let’s create a voice call with a message as follows:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`try:
   msg = client.voice_message_create('+319876543211',
         'Hey you, a little bird told me you wanted a call!',
         { 'voice' : 'male' })
   print(msg.__dict__)

`}</code></pre>
    <p>{`Here, we're calling `}<inlineCode parentName="p">{`voice_message_create()`}</inlineCode>{` with these parameters:`}</p>
    <ul>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`destination`}</inlineCode>{` of the call, which is a phone number written in the `}<a parentName="li" {...{
          "href": "https://support.messagebird.com/hc/en-us/articles/115003950149-Numbers-format-"
        }}>{`international format with country code`}</a>{`.`}</li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`message`}</inlineCode>{` that the recipient will hear on the call.`}</li>
      <li parentName="ul">{`A `}<inlineCode parentName="li">{`Python dictionary`}</inlineCode>{` containing optional attributes that you can specify for this phone call. Here, we're setting one of the optional attributes which is the gender of the voice in the call, to "male". You can find more information about other optional attributes in our `}<a parentName="li" {...{
          "href": "/api/voice-calling#calls"
        }}>{`Voice Calling API documentation`}</a>{`.`}</li>
    </ul>
    <p>{`If the call succeeds, our application will print the details about the call to the console.`}</p>
    <p>{`We've used an example phone number for the recipient's phone number. For your application to work, you should replace this number with a working number.`}</p>
    <p>{`If the attempt to create a voice call fails, we print the errors to the console with the following clause:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`except messagebird.client.ErrorException as e:
   for error in e.errors:
       print(error)
`}</code></pre>
    <h2>{`Finishing your program`}</h2>
    <p>{`Awesome! You have a fully functioning Python program that makes a call to your set destination phone number. Let's go ahead and save it.`}</p>
    <p>{`To test your application, run in the terminal:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`python voice_call.py
`}</code></pre>
    <p>{`If everything works fine, you should see the API response logged in the terminal, signalling that you've successfully made a call. If you used a live API key and had verified your number or added `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/articles/115002599309-Test-credits"
      }}>{`test credits to your account`}</a>{` your phone will ring shortly and deliver the message when you pick up the phone.`}</p>
    <p>{`If you see an error from the script try to read and understand the message so the problem can be fixed. If you have trouble still, don’t hesitate to contact us at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we’ll help you out.`}</p>
    <p><strong parentName="p">{`Nice work!`}</strong>{` 🎉`}</p>
    <p>{`You just initiated your first voice call with the MessageBird `}<a parentName="p" {...{
        "href": "/api/voice-calling"
      }}>{`Voice Calling API`}</a>{` using Python!`}</p>
    <h2>{`Start building!`}</h2>
    <p>{`Head over to the next MessageBird Developer Tutorial and learn how
to `}<a parentName="p" {...{
        "href": "/tutorials/handle-incoming-calls-and-sms"
      }}>{`set up and handle incoming voice
calls`}</a>{`.`}</p>
    <p>{`Want to start building your solution but not quite sure how to get
started? Please feel free to let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we'd love to help!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      