import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import { Img } from 'components/shared';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Questions = makeShortcode("Questions");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Voice Messaging API Troubleshooting`}</h1>
    <p>{`MessageBird offers multiple tools to enable you to quickly understand any unexpected behavior between the Voice Messaging API and your application.`}</p>
    <h2>{`Explore the text to speech message logs in the Dashboard`}</h2>
    <p>{`If you run into issues with your text to speech messages, the best place to start troubleshooting is the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/voice/list"
      }}>{`Text To Speech Logs in the MessageBird Dashboard`}</a>{`.`}</p>
    <Img src="/img/screenshots/quickstarts/message-logs.png" alt="Message Logs" width="650px" align="middle" mdxType="Img" />
    <p>{`Here you can find an overview of any issues detected with your text to speech messages.`}</p>
    <p>{`Select the eye symbol on the far right side of the logs to drill into each specific text to speech message for information such as `}<inlineCode parentName="p">{`ID`}</inlineCode>{`, `}<inlineCode parentName="p">{`Originator`}</inlineCode>{`, `}<inlineCode parentName="p">{`Send Time`}</inlineCode>{`, `}<inlineCode parentName="p">{`Message`}</inlineCode>{`, and `}<inlineCode parentName="p">{`Summary`}</inlineCode>{`.`}</p>
    <p>{`You can filter the Text To Speech Message logs by Status (e.g. filter by undelivered) using the `}<inlineCode parentName="p">{`Filter`}</inlineCode>{` button on the top-right.`}</p>
    <h2>{`General error codes`}</h2>
    <p>{`If you received an error response and code from MessageBird that is not listed in the above Error Codes list, you can troubleshoot the error by exploring the general `}<a parentName="p" {...{
        "href": "/api#api-errors"
      }}>{`API Error Codes.`}</a></p>
    <p>{`In case of an API error, the body of the response includes a `}<inlineCode parentName="p">{`json`}</inlineCode>{` formatted response that tells you exactly what's wrong.`}</p>
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      