import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Setting SMS server alerts with MessageBird`}</h1>
    <h3>{`⏱ 30 min build time      ||      `}<a parentName="h3" {...{
        "href": "https://github.com/messagebirdguides/sms-server-alerts-guide"
      }}>{`Download the Code`}</a></h3>
    <h2>{`Why build SMS server alerts?`}</h2>
    <p>{`In this MessageBird Developer Tutorial, you’ll learn how to quickly report error logs of your server via SMS server alerts with the MessageBird API to ensure a faster response time.`}</p>
    <p>{`For any online service advertising guaranteed uptime north of 99%, being available and reliable is extremely important; therefore, it is essential that any errors in the system are fixed as soon as possible, and the prerequisite for that is that error reports are delivered quickly to the engineers on duty. Providing those error logs via SMS ensures a faster response time compared to email reports and helps companies keep their uptime promises.`}</p>
    <p>{`We’ll show you how to build an integration of SMS alerts into a Node.js application that uses the `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/winston"
      }}>{`Winston`}</a>{` logging framework.`}</p>
    <h2>{`Logging Primer with Winston`}</h2>
    <p>{`Logging is the default approach for gaining insights into running applications. Before we start building our sample application, let's take a minute to understand two fundamental concepts of logging: levels and transports.`}</p>
    <p><strong parentName="p">{`Levels`}</strong>{` indicate the severity of the log item. Common log levels are `}<em parentName="p">{`debug`}</em>{`, `}<em parentName="p">{`info`}</em>{`, `}<em parentName="p">{`warning`}</em>{` and `}<em parentName="p">{`error`}</em>{`. For example, a user trying to login could have the `}<em parentName="p">{`info`}</em>{` level, a user entering the wrong password during login could be a `}<em parentName="p">{`warning`}</em>{` since it may be a potential attack, and a user not able to access the system due to a subsystem failure would trigger an `}<em parentName="p">{`error`}</em>{`.`}</p>
    <p><strong parentName="p">{`Transports`}</strong>{` are different channels into which the logger writes its data. Typical channels are the console, files, log collection servers and services, or communication channels such as email, SMS or push notifications.`}</p>
    <p>{`It's possible and common to set up multiple kinds of transport for the same logger but set different levels for each. In our sample application, we write entries of all severities to the console and a log file; the application will send SMS notifications only for log items that have the `}<em parentName="p">{`error`}</em>{` level (or higher, when using more levels).`}</p>
    <h2>{`Getting started`}</h2>
    <p>{`First things first, the sample application is built in Node.js and uses `}<em parentName="p">{`Winston`}</em>{` as the logging library, so you’ll need Node and npm, you can easily `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/get-npm"
      }}>{`install them for free`}</a>{`.`}</p>
    <p>{`We have also included an example using `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/express"
      }}>{`Express`}</a>{` and `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/express-winston"
      }}>{`express-winston`}</a>{` (don't confuse it with winston-express...) to demonstrate web application request logging.`}</p>
    <p>{`The source code is available in the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/verify-voice-guide"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{` which you can either clone with git or from where you can download a ZIP file with the source code to your computer.`}</p>
    <p>{`Let's now open the directory where you've stored the sample code and run the following command to install the `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/messagebird"
      }}>{`MessageBird SDK`}</a>{` and other dependencies:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install
`}</code></pre>
    <h2>{`Building a MessageBird transport`}</h2>
    <p>{`Winston enables developers to build custom transports and use them with the logger just like built-in transports such as the file or console transports. They are extensions of the `}<inlineCode parentName="p">{`Transport`}</inlineCode>{` class and need to implement a constructor for initialization as well as the `}<inlineCode parentName="p">{`log()`}</inlineCode>{` method. We have created one in the file `}<inlineCode parentName="p">{`MessageBirdTransport.js`}</inlineCode>{`.`}</p>
    <p>{`Our SMS alert functionality needs the following information to work:`}</p>
    <ul>
      <li parentName="ul">{`A functioning MessageBird API key.`}</li>
      <li parentName="ul">{`An originator, that is, a sender ID for the messages.`}</li>
      <li parentName="ul">{`One or more recipients, that is, the phone numbers of the system engineers that should be informed about problems with the server.`}</li>
    </ul>
    <p>{`To keep the custom transport self-contained and independent from the way the application wants to provide the information, we take all as parameters in our constructor. Here's the code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const Transport = require('winston-transport');

/**
* This is a MessageBird Transport for Winston
*/
module.exports = class MessageBirdTransport extends Transport {
 constructor(opts) {
   super(opts);

   // Load and initialize MessageBird SDK
   this.messagebird = require('messagebird')(opts.apiKey);

   // Store required options
   this.recipients = opts.recipients;
   this.originator = opts.originator;
 }
`}</code></pre>
    <p>{`As you can see, the constructor calls the `}<inlineCode parentName="p">{`super()`}</inlineCode>{`-constructor to keep basic custom transport behavior intact, then loads and initializes the MessageBird SDK with the key and stores the other the necessary configuration fields as members of the object.`}</p>
    <p>{`In the `}<inlineCode parentName="p">{`log()`}</inlineCode>{` method, again we start with some default code from the basic custom transport class:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`log(info, callback) {
 setImmediate(() => {
   this.emit('logged', info);
 });
`}</code></pre>
    <p>{`Then, we shorten the log entry, to make sure it fits in the 160 characters of a single SMS so that notifications don't incur unnecessary costs or break limits:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// Shorten log entry
var text = (info.message.length > 140) ? info.message.substring(0, 140) + ' ...' : info.message;
`}</code></pre>
    <p>{`Finally, we call `}<inlineCode parentName="p">{`messagebird.messages.create()`}</inlineCode>{` to send an SMS notification. For the required parameters `}<em parentName="p">{`originator`}</em>{` and `}<em parentName="p">{`recipients`}</em>{` we use the values stored in the constructor, and for `}<em parentName="p">{`body`}</em>{` we use the (shortened) log text prefixed with the level:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// Send notification with MessageBird SDK
this.messagebird.messages.create({
   originator : this.originator,
   recipients : this.recipients,
   body : '[' + info.level +'] ' + text
}, function(err, response) {
 console.log(err, response);
});
`}</code></pre>
    <p>{`The MessageBird API call is asynchronous and uses a callback function. In this callback function we only log the response to the console and don't do anything else (we can't record it with Winston here because then we might get stuck in an infinite loop).`}</p>
    <h2>{`Configuring Winston and our Transport`}</h2>
    <p>{`In `}<inlineCode parentName="p">{`index.js`}</inlineCode>{`, the primary file of our application, we start off by loading the dependencies and the custom transport class:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// Load dependencies
var winston = require('winston');
var express = require('express');
var expressWinston = require('express-winston');
var MessageBirdTransport = require('./MessageBirdTransport');
`}</code></pre>
    <p>{`Let’s also use `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/dotenv"
      }}>{`dotenv`}</a>{` to load configuration data from a `}<inlineCode parentName="p">{`.env`}</inlineCode>{` file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// Load configuration from .env file
require('dotenv').config();
`}</code></pre>
    <p>{`Copy `}<inlineCode parentName="p">{`env.example`}</inlineCode>{` to `}<inlineCode parentName="p">{`.env`}</inlineCode>{` and store your information:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-env"
      }}>{`MESSAGEBIRD_API_KEY=YOUR-API-KEY
MESSAGEBIRD_ORIGINATOR=Winston
MESSAGEBIRD_RECIPIENTS=31970XXXXXXX,31970YYYYYYY
`}</code></pre>
    <p>{`You can create or retrieve an API key `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`in your MessageBird account`}</a>{`. The originator can be a phone number you registered through MessageBird or, for countries that support it, an alphanumeric sender ID with at most 11 characters. You can provide one or more comma-separated phone numbers as recipients. Keep in mind that alphanumeric senders are not supported in every country including the United States, so it’s important to check the `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/sections/360000108538-Country-info-Restrictions"
      }}>{`country restrictions`}</a>{`. If you can't use alphanumeric IDs, use a real phone number instead. You can check our `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/articles/115002628665-What-is-the-originator-"
      }}>{`originator article`}</a>{` in Help Center to learn more about this topic.`}</p>
    <p>{`Back to `}<inlineCode parentName="p">{`index.js`}</inlineCode>{`, it's time to set up the logger:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// Set up Logger
var logger = winston.createLogger({
   format: winston.format.simple(),
   transports: [
       new winston.transports.Console({
        level: 'debug'
    }),
       new winston.transports.File({
        filename: 'app.log',
        level: 'info'
    }),
       new MessageBirdTransport({
           apiKey : process.env.MESSAGEBIRD_API_KEY,
           originator : process.env.MESSAGEBIRD_ORIGINATOR,
           recipients : process.env.MESSAGEBIRD_RECIPIENTS.split(','),
           level: 'error'
       })
   ]
});
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`winston.createLogger()`}</inlineCode>{` method takes a variety of optional configuration parameters. Using the `}<inlineCode parentName="p">{`transports`}</inlineCode>{` parameter, you can define one or more transports. As you see in the example, we have added three transports:`}</p>
    <ul>
      <li parentName="ul">{`The default Console transport, where we log everything starting with the `}<inlineCode parentName="li">{`debug`}</inlineCode>{` level.`}</li>
      <li parentName="ul">{`A default File transport, where we log `}<inlineCode parentName="li">{`info`}</inlineCode>{` and higher into a file called `}<inlineCode parentName="li">{`app.log`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`Our previously created custom `}<inlineCode parentName="li">{`MessageBirdTransport`}</inlineCode>{` with all the configuration options taken from our environment file. We convert the comma-separated recipients into an array with `}<inlineCode parentName="li">{`split(',')`}</inlineCode>{`. This transport only handles log events with the `}<inlineCode parentName="li">{`error`}</inlineCode>{` level.`}</li>
    </ul>
    <h2>{`Configuring Winston for Express`}</h2>
    <p>{`After setting up an Express app, you can call `}<inlineCode parentName="p">{`app.use()`}</inlineCode>{` to specify a middleware. Middlewares are extensions to Express that touch each request, and they are useful for globally required functionality such as authentication or, in our example, logging:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// Configure Winston logging for express
app.use(expressWinston.logger({
   statusLevels : true,
   winstonInstance : logger
}));
`}</code></pre>
    <p>{`We provide the previously initialized `}<em parentName="p">{`winstonInstance`}</em>{` `}<inlineCode parentName="p">{`_logger`}</inlineCode>{`, so the same logger is used for automated Express request logging and custom log entries. The `}<inlineCode parentName="p">{`statusLevels`}</inlineCode>{` parameter enables a built-in behavior of express-winston that logs requests that report a server error, that is, have response codes in the 5xx range with `}<inlineCode parentName="p">{`error`}</inlineCode>{` level, and uses the `}<inlineCode parentName="p">{`info`}</inlineCode>{` level for successful requests with a 2xx response code. This behavior is fully in line with our intention since we want to report only server errors through our MessageBirdTransport.`}</p>
    <h2>{`Testing`}</h2>
    <p>{`You’re done! We have added some test log entries in `}<inlineCode parentName="p">{`index.js`}</inlineCode>{` and we also created an Express test route to simulate a 500 server response. It’s time to test your application! Go to your console and type the following command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`node index.js
`}</code></pre>
    <p>{`You should see:`}</p>
    <ul>
      <li parentName="ul">{`Four messages printed on the console.`}</li>
      <li parentName="ul">{`Three log items written to the `}<inlineCode parentName="li">{`app.log`}</inlineCode>{` file (open it with a text editor or with `}<inlineCode parentName="li">{`tail`}</inlineCode>{` in a new console tab).`}</li>
      <li parentName="ul">{`One error message on your phone.`}</li>
    </ul>
    <p>{`Open `}<a parentName="p" {...{
        "href": "http://localhost:8080/"
      }}>{`http://localhost:8080/`}</a>{` in your browser, and along with the successful request, you’ll see a log entry on the console and in the file.`}</p>
    <p>{`Open `}<a parentName="p" {...{
        "href": "http://localhost:8080/simulateError"
      }}>{`http://localhost:8080/simulateError`}</a>{` and, along with the request error on your console and the log file, another notification will arrive at your phone.`}</p>
    <p>{`You can now take these elements and integrate them into a Node.js production application. Don't forget to download the code from the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/sms-server-alerts-guide"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`.`}</p>
    <p><strong parentName="p">{`Nice work!`}</strong>{` 🎉`}</p>
    <p>{`You've learned how to log with Winston and express-winston to create a custom MessageBird transport using Node.js!`}</p>
    <h2>{`Start building!`}</h2>
    <p>{`Want to build something similar but not quite sure how to get started? Feel free to let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we'd love to help!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      