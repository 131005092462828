import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Implementing SMS two-factor authentication (2FA) with MessageBird`}</h1>
    <h3>{`⏱ 15 min build time      ||      `}<a parentName="h3" {...{
        "href": "https://github.com/messagebirdguides/verify-guide-ruby"
      }}>{`Download the Code`}</a></h3>
    <h2>{`Why build two-factor authentication?`}</h2>
    <p>{`In this MessageBird Developer Tutorial you’ll learn how to improve your security building an SMS-based two factor authentication solution with the `}<a parentName="p" {...{
        "href": "/api/verify"
      }}>{`MessageBird Verify API`}</a>{`. The runnable application we’ll build is a prototype in Ruby for our fictitious online banking application, `}<em parentName="p">{`BirdBank`}</em>{`.`}</p>
    <p>{`Enterprises are increasingly challenged to keep sensitive information from falling into the wrong hands. This means that we can no longer trust old online authentication systems that rely solely on usernames and passwords, especially as security breaches grow in frequency, severity and sophistication.`}</p>
    <p>{`With the `}<a parentName="p" {...{
        "href": "/api/verify"
      }}>{`MessageBird Verify API`}</a>{`, you can implement two factor authentication (2FA) solutions to provide an additional layer of account security by verifying the user's password with a second authentication token and in turn, secure customer data, block fraudulent accounts, and safeguard key transactions in a matter of minutes. The most common use case involves the application of one-time passwords (OTP) generated by hardware tokens,  authenticator apps or directly sent to the user's mobile phone via SMS messaging.`}</p>
    <p>{`We'll walk you through the following steps:`}</p>
    <ul>
      <li parentName="ul">{`Asking for the phone number`}</li>
      <li parentName="ul">{`Sending a verification code`}</li>
      <li parentName="ul">{`Verifying the code`}</li>
    </ul>
    <p><strong parentName="p">{`Pro-tip:`}</strong>{` Follow this tutorial to build the whole application from scratch or, if you want to see it in action right away, you can download, clone or fork the sample application from our `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/verify-guide-ruby"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`.`}</p>
    <h2>{`Getting started`}</h2>
    <p>{`To build our sample application we'll use `}<a parentName="p" {...{
        "href": "https://www.ruby-lang.org/en/"
      }}>{`Ruby`}</a>{`, the `}<a parentName="p" {...{
        "href": "http://sinatrarb.com/"
      }}>{`Sinatra framework`}</a>{` and the `}<a parentName="p" {...{
        "href": "https://ruby-doc.org/stdlib-2.5.1/libdoc/erb/rdoc/ERB.html"
      }}>{`ERB templates`}</a>{` as well as the `}<a parentName="p" {...{
        "href": "https://rubygems.org/gems/messagebird-rest"
      }}>{`MessageBird SDK`}</a>{`.`}</p>
    <p>{`Before we get started, make sure that Ruby's package manager, `}<inlineCode parentName="p">{`bundler`}</inlineCode>{`, is installed. If not, you can easily `}<a parentName="p" {...{
        "href": "https://bundler.io/"
      }}>{`install it`}</a>{` for free.`}</p>
    <h2>{`Project setup`}</h2>
    <h3>{`Dependencies`}</h3>
    <p>{`First, create a new directory to store the sample application. Within this new directory, create a file called `}<inlineCode parentName="p">{`Gemfile`}</inlineCode>{` with the following contents:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`source 'http://rubygems.org'

gem 'dotenv', '~> 2.5'
gem 'messagebird-rest', '~> 1.4', require: 'messagebird'
gem 'sinatra', '~> 2.0'
`}</code></pre>
    <p>{`This file defines all of the dependencies necessary for your sample application, including Sinatra and the MessageBird SDK; the ERB templating system comes along with Ruby.`}</p>
    <p>{`Next, we need to instruct bundler to install all the required dependencies in your project. Open a console pointed to the directory that contains the file you just created and type the following command:`}</p>
    <pre><code parentName="pre" {...{}}>{`bundle install
`}</code></pre>
    <h4>{`Create your API Key 🔑`}</h4>
    <p>{`To enable the MessageBird REST API, we need to provide an access key for the API. MessageBird provides keys in `}<em parentName="p">{`live`}</em>{` and `}<em parentName="p">{`test`}</em>{` modes. To get this application running, we’ll need to create and use a live API access key. You can read more about `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/articles/360000670709-What-is-the-difference-between-a-live-key-and-a-test-key-"
      }}>{`the difference between test and live API keys`}</a>{` in our Help Center.`}</p>
    <p>{`Let's create your live API access key. First, go to the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/user/index"
      }}>{`MessageBird Dashboard`}</a>{`; if you have already created an API key it will be shown right there. If you don’t see any key on the Dashboard or if you're unsure whether this key is in `}<em parentName="p">{`live`}</em>{` mode, go to the `}<em parentName="p">{`Developers`}</em>{` section in the MessageBird Dashboard and open the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`API access (REST) tab`}</a>{`. There you can create new API keys and manage your existing ones.`}</p>
    <p>{`If you are having any issues creating your API key, please reach out to `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we’ll make sure to help you out.`}</p>
    <p><strong parentName="p">{`Pro-tip:`}</strong>{` Hardcoding your credentials in the code is a risky practice that should never be used in production applications. A better method, also recommended by the `}<a parentName="p" {...{
        "href": "https://12factor.net/"
      }}>{`Twelve-Factor App Definition`}</a>{`, is to use environment variables.`}</p>
    <p>{`We've added `}<a parentName="p" {...{
        "href": "https://rubygems.org/gems/dotenv"
      }}>{`dotenv`}</a>{` to the sample application, so you can supply your API key in a file named `}<inlineCode parentName="p">{`.env`}</inlineCode>{`. Simply copy the provided file `}<inlineCode parentName="p">{`env.example`}</inlineCode>{` to `}<inlineCode parentName="p">{`.env`}</inlineCode>{` and add your API key like this:`}</p>
    <pre><code parentName="pre" {...{}}>{`MESSAGEBIRD_API_KEY=YOUR-API-KEY
`}</code></pre>
    <h3>{`Main file`}</h3>
    <p>{`You now have your API key, so let's get started with the main file. First, create an `}<inlineCode parentName="p">{`app.rb`}</inlineCode>{` file in the same directory as your `}<inlineCode parentName="p">{`Gemfile`}</inlineCode>{`. This file starts off by requiring dependencies:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`require 'dotenv'
require 'sinatra'
require 'messagebird'
`}</code></pre>
    <p>{`Then, we'll set up a source for our view templates:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`set :root, File.dirname(__FILE__)
`}</code></pre>
    <p>{`Then, we'll load any required environment variables:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`Dotenv.load if Sinatra::Base.development?
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`client = MessageBird::Client.new(ENV['MESSAGEBIRD_API_KEY'])
`}</code></pre>
    <p>{`Next, we're creating a new MessageBird client. This will be used to access the API.`}</p>
    <p>{`And finally, we’ll set up an endpoint which we can access:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`get '/' do
 'Hello!'
end
`}</code></pre>
    <p>{`If you run `}<inlineCode parentName="p">{`ruby app.rb`}</inlineCode>{`, you should see a message similar to the following:`}</p>
    <pre><code parentName="pre" {...{}}>{`== Sinatra (v2.0.4) has taken the stage on 4567 for development with backup from Thin
Thin web server (v1.7.2 codename Bachmanity)
Maximum connections set to 1024
Listening on localhost:4567, CTRL+C to stop
`}</code></pre>
    <p>{`Head to `}<inlineCode parentName="p">{`localhost:4567`}</inlineCode>{` in your browser, and you should be greeted with a message! This shows that the basics of the app are working. 🤓`}</p>
    <h3>{`Views`}</h3>
    <p>{`We use ERB to separate the logic of our code from the HTML pages. To do this, create a directory named `}<inlineCode parentName="p">{`views`}</inlineCode>{`. Now, inside `}<inlineCode parentName="p">{`views`}</inlineCode>{` create a file called `}<inlineCode parentName="p">{`layout.erb`}</inlineCode>{` inside with the following content:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<!doctype html>
<html>
 <head>
   <title>MessageBird Verify Example</title>
 </head>
 <body>
   <h1>MessageBird Verify Example</h1>

   <%= yield %>
 </body>
</html>
`}</code></pre>
    <p>{`This is the main layout which acts as a container for all pages of our application. We'll create the views for each page next.`}</p>
    <h2>{`Asking for the phone number`}</h2>
    <p>{`The first step in verifying a user's phone number is asking them to provide their phone number. Let's do exactly this by creating an HTML form and storing it as `}<inlineCode parentName="p">{`step1.erb`}</inlineCode>{` inside the `}<inlineCode parentName="p">{`views`}</inlineCode>{` directory:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<% if !errors.nil? %>
 <p><%= errors %></p>
<% end %>
<p>Please enter your phone number (in international format, starting with +) to receive a verification code:</p>
<form method="post" action="/step2">
   <input type="tel" name="number" />
   <input type="submit" value="Send code" />
</form>
`}</code></pre>
    <p>{`The form is simple, having just one input field and one submit button. Providing `}<inlineCode parentName="p">{`tel`}</inlineCode>{` as the `}<inlineCode parentName="p">{`type`}</inlineCode>{` attribute of our input field allows some browsers, especially on mobile devices, to optimize for telephone number input, for example by displaying a number pad. The section starting with `}<inlineCode parentName="p">{`<% if error %>`}</inlineCode>{` is needed to display errors—we'll come back to this in a minute.`}</p>
    <p>{`Now, it's time to change the initial route in `}<inlineCode parentName="p">{`app.rb`}</inlineCode>{` to display the page:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`get '/' do
 erb :step1, locals: { errors: nil }
end
`}</code></pre>
    <p>{`Running `}<inlineCode parentName="p">{`ruby app.rb`}</inlineCode>{` should display the form.`}</p>
    <h2>{`Sending a verification code`}</h2>
    <p>{`Once we've collected the number, we can send a verification message to a user's mobile device. The MessageBird Verify API takes care of generating a random token, so you don't have to do this yourself. Codes are numeric and six digits by default. If you want to customize the length of the code or configure other options, you can check out our `}<a parentName="p" {...{
        "href": "/api/verify#verify-request"
      }}>{`Verify API documentation`}</a>{`.`}</p>
    <p>{`The form we created in the last step submits the phone number via HTTP POST to `}<inlineCode parentName="p">{`/step2`}</inlineCode>{`, so let's define this route in our `}<inlineCode parentName="p">{`app.rb`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`post '/step2' do
 number = params['number']

 otp = nil

 begin
   otp = client.verify_create(
     number,
     originator: 'Code',
     template: 'Your verification code is %token.'
   )
 rescue MessageBird::ErrorException => ex
   errors = ex.errors.each_with_object([]) do |error, memo|
     memo << "Error code #{error.code}: #{error.description}"
   end.join("\\n")
   return erb :step1, locals: { errors: errors }
 end

 erb :step2, locals: { otp_id: otp.id, errors: nil }
end
`}</code></pre>
    <p>{`Before we move on, let's quickly dive into what happens here: 🤔`}</p>
    <p>{`First, the number is passed along from the request. The MessageBird client calls `}<inlineCode parentName="p">{`verify_create()`}</inlineCode>{` with the number as the first parameter. The second parameter is used to provide additional options:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The `}<inlineCode parentName="p">{`originator`}</inlineCode>{` is the sender for the message; it can be a telephone number including country code or an alphanumeric string (with a maximum length of 11 characters). You can use the number you bought as part of our G`}<a parentName="p" {...{
            "href": "/tutorials/getting-started-101"
          }}>{`Getting Started tutorial`}</a>{` as originator. Keep in mind that alphanumeric senders are not supported in every country including the United States, so it’s important to check the `}<a parentName="p" {...{
            "href": "https://support.messagebird.com/hc/en-us/sections/360000108538-Country-info-Restrictions"
          }}>{`country restrictions`}</a>{`. If you can't use alphanumeric IDs, use a real phone number instead. You can check our `}<a parentName="p" {...{
            "href": "https://support.messagebird.com/hc/en-us/articles/115002628665-What-is-the-originator-"
          }}>{`originator article`}</a>{` in Help Center to learn more about this topic.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Using `}<inlineCode parentName="p">{`template`}</inlineCode>{` we can phrase the wording of the message. The template contains the placeholder `}<inlineCode parentName="p">{`%token`}</inlineCode>{`, which is replaced with the generated token on MessageBird's end. If we omitted this, the message would simply contain the token and nothing else.`}</p>
      </li>
    </ul>
    <p>{`If this call fails, the MessageBird client throws `}<inlineCode parentName="p">{`MessageBird::ErrorException`}</inlineCode>{` error. A typical error could be that the user has entered an invalid phone number. For our application, we simply re-render the page from our first step and pass the description of the error into the template - remember the `}<inlineCode parentName="p">{`<%= if !errors.nil %>`}</inlineCode>{` section from the first step? In production applications, you'd most likely not expose the raw API error; instead, you could consider different possible problems and return an appropriate message in your own words. You might also want to prevent some errors from occurring by doing some input validation on the phone number yourself.`}</p>
    <p>{`In case the request was successful, we'll render a new page. Our API response contains an ID, which we'll need for the next step, so we'll just add it to the form. Since the ID is meaningless without your API access key there are no security implications of doing so; however, in practice you'd be more likely to store this ID in a session object on the server. Just as before, we're logging the whole response to the console for debugging purposes. We still need to build the new page, so create a file called `}<inlineCode parentName="p">{`step2.erb`}</inlineCode>{` in your `}<inlineCode parentName="p">{`views`}</inlineCode>{` directory:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<% if !errors.nil? %>
 <p><%= errors %></p>
<% end %>
<p>We have sent you a verification code!</p>
<p>Please enter the code here:</p>
<form method="post" action="/step3">
   <input type="hidden" name="id" value="<%= otp_id %>" />
   <input type="text" name="token" />
   <input type="submit" value="Check code" />
</form>
`}</code></pre>
    <p>{`The form is very similar to the first step. Keep in mind that we include a hidden field with our verification ID and once again have a conditional error section.`}</p>
    <h2>{`Verifying the code`}</h2>
    <p>{`The user will check their phone and enter the code into our form. What we need to do next is send the user's input along with the ID of the verification request to MessageBird's API and see whether the verification was successful or not. Let's declare this third step as a new route in our `}<inlineCode parentName="p">{`app.rb`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`post '/step3' do
 id = params[:id]
 token = params[:token]

 begin
   client.verify_token(id, token)
 rescue MessageBird::ErrorException => ex
   errors = ex.errors.each_with_object([]) do |error, memo|
     memo << "Error code #{error.code}: #{error.description}"
   end.join("\\n")
   return erb :step2, locals: { otp_id: nil, errors: errors }
 end

 erb :step3, locals: { errors: nil }
end
`}</code></pre>
    <p>{`This code looks very similar to the one in the second step. First, we're reading the input and then make a call to MessageBird's API. This time, it's the `}<inlineCode parentName="p">{`verify_token()`}</inlineCode>{` method, which accepts `}<inlineCode parentName="p">{`id`}</inlineCode>{` and `}<inlineCode parentName="p">{`token`}</inlineCode>{` as its parameters.`}</p>
    <p>{`In case of an error, such as an invalid or expired token, we're showing that error on our page from the second step.`}</p>
    <p>{`In the success case, we simply show a new page. Create this page in your `}<inlineCode parentName="p">{`views`}</inlineCode>{` directory and call it `}<inlineCode parentName="p">{`step3.erb`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{}}>{`<p>You have successfully verified your phone number.</p>
`}</code></pre>
    <h2>{`Testing`}</h2>
    <p>{`You're done! To check if your application works run it one more time from the command line:`}</p>
    <pre><code parentName="pre" {...{}}>{`ruby app.rb
`}</code></pre>
    <p>{`Then, point your browser to `}<a parentName="p" {...{
        "href": "http://localhost:4567/"
      }}>{`http://localhost:4567/`}</a>{` and try to verify your own phone number.`}</p>
    <p>{`Awesome! You can now leverage the flow, code snippets and UI examples from this tutorial to build your own two factor authentication system. Don't forget to download the code from the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/verify-guide-ruby"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`.`}</p>
    <p><strong parentName="p">{`Nice work!`}</strong>{` 🎉`}</p>
    <p>{`You now have a running integration of MessageBird's Verify API with Ruby!`}</p>
    <h2>{`Start building!`}</h2>
    <p>{`Want to build something similar but not quite sure how to get started? Please feel free to let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`;  we'd love to help!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      