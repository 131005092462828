import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Implementing voice two-factor authentication (2FA) with MessageBird`}</h1>
    <h3>{`⏱ 15 min build time      ||      `}<a parentName="h3" {...{
        "href": "https://github.com/messagebirdguides/verify-voice-guide-go"
      }}>{`Download the Code`}</a></h3>
    <h2>{`Why build voice-based account security?`}</h2>
    <p>{`In this MessageBird Developer Tutorial you’ll learn how to improve your security by building a Voice-based two-factor authentication solution with the `}<a parentName="p" {...{
        "href": "https://www.messagebird.com/en/verify"
      }}>{`MessageBird Verify API`}</a>{`.`}</p>
    <p>{`Websites where users can sign up for an account typically use the email address as a unique identifier and a password as a security credential for users to sign in. At the same time, most websites ask users to add a verified phone number to their profile. Phone numbers are, in general, a better way to identify an account holder as a real person; they can also be used as a second authentication factor (2FA) or to restore access to a locked account.`}</p>
    <p>{`Verification of a phone number is straightforward:`}</p>
    <ol>
      <li parentName="ol">{`Ask your user to enter their number.`}</li>
      <li parentName="ol">{`Call the number programmatically and use a text-to-speech system to say a security code that acts as a one-time-password (OTP).`}</li>
      <li parentName="ol">{`Let the user enter this code on the website or inside an application as proof that they received the call.`}</li>
    </ol>
    <p>{`The MessageBird Verify API assists developers in implementing this workflow into their apps. Imagine you're running a social network and want to verify the profiles of your users. This MessageBird Developer Tutorial shows you an example application using Go with integrated account security following the steps outlined above.`}</p>
    <p>{`By the way, it is also possible to replace the second step with an SMS message, as we explain in our `}<a parentName="p" {...{
        "href": "/tutorials/verify-node"
      }}>{`two factor authentication tutorial`}</a>{`. However, using voice for verification has the advantage that it works with every phone number, not just mobile phones, so it can be used to verify, for example, the landline of a business.`}</p>
    <p>{`The `}<a parentName="p" {...{
        "href": "/api/verify"
      }}>{`MessageBird Verify API`}</a>{` supports both options; voice and SMS!`}</p>
    <h2>{`Getting started`}</h2>
    <p>{`First things first, you can download or clone the complete source code from the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/verify-voice-guide-go"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{` to run the application on your computer and follow along with the tutorial. Our sample application is built in Go, so in order to run the sample, you’ll need to have `}<a parentName="p" {...{
        "href": "https://golang.org"
      }}>{`Go installed`}</a>{` on your machine.`}</p>
    <p>{`We'll also need the `}<a parentName="p" {...{
        "href": "https://github.com/messagebird/go-rest-api"
      }}>{`MessageBird REST API package for Go`}</a>{`. Once Go is installed, run the following command to install the MessageBird REST API package for Go:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`go get -u github.com/messagebird/go-rest-api
`}</code></pre>
    <h2>{`Project setup`}</h2>
    <p>{`Now, let's create a folder for your application—you can call it whatever you like. Then, in this folder, let's create the following subfolders:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`views`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`views/layouts`}</inlineCode></li>
    </ul>
    <p>{`We'll use the following packages from the Go standard library to build our routes and views:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`net/http`}</inlineCode>{`: A HTTP package for building our routes and a simple http server.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`html/template`}</inlineCode>{`: A HTML template library for building views.`}</li>
    </ul>
    <p>{`From the MessageBird Go REST API package, we'll import the following packages:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`github.com/messagebird/go-rest-api`}</inlineCode>{`: The MessageBird core client package.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`github.com/messagebird/go-rest-api/verify`}</inlineCode>{`: The MessageBird Verify package.`}</li>
    </ul>
    <h3>{`Dependencies`}</h3>
    <p>{`To keep things straightforward and get you up and running as fast and easy as possible, we'll use only packages from the Go standard library and the MessageBird REST API package for Go.`}</p>
    <h4>{`Create your API Key 🔑`}</h4>
    <p>{`To enable the MessageBird SDK, we need to provide an access key for the API. MessageBird provides keys in `}<em parentName="p">{`live`}</em>{` and `}<em parentName="p">{`test`}</em>{` modes. To get this application running, we’ll need to create and use a live API access key. You can read more about `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/articles/360000670709-What-is-the-difference-between-a-live-key-and-a-test-key-"
      }}>{`the difference between test and live API keys`}</a>{` in our Help Center.`}</p>
    <p>{`Let's create your live API access key. First, go to the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/user/index"
      }}>{`MessageBird Dashboard`}</a>{`; if you have already created an API key it will be shown right there. If you don’t see any key on the Dashboard or if you're unsure whether this key is in `}<em parentName="p">{`live`}</em>{` mode, go to the `}<em parentName="p">{`Developers`}</em>{` section in the MessageBird Dashboard and open the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`API access (REST) tab`}</a>{`. There you can create new API keys and manage your existing ones.`}</p>
    <p>{`If you are having any issues creating your API key, please reach out to `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we’ll make sure to help you out.`}</p>
    <p><strong parentName="p">{`Pro-tip:`}</strong>{` To keep our demonstration code simple, we’ll be saving our API key in `}<inlineCode parentName="p">{`main.go`}</inlineCode>{`; however, hardcoding your credentials in the code is a risky practice that should never be used in production applications. A better method, also recommended by the `}<a parentName="p" {...{
        "href": "https://12factor.net/"
      }}>{`Twelve-Factor App Definition`}</a>{`, is to use environment variables. You can use open source packages such as `}<a parentName="p" {...{
        "href": "https://github.com/joho/godotenv"
      }}>{`GoDotEnv`}</a>{` to read your API key from a `}<inlineCode parentName="p">{`.env`}</inlineCode>{` file into your Go application. Your `}<inlineCode parentName="p">{`.env`}</inlineCode>{` file should be written as follows:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`MESSAGEBIRD_API_KEY=YOUR-API-KEY
`}</code></pre>
    <p>{`To use `}<a parentName="p" {...{
        "href": "https://github.com/joho/godotenv"
      }}>{`GoDotEnv`}</a>{` in your application, install it by running:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`go get -u github.com/joho/godotenv
`}</code></pre>
    <p>{`Then, import it in your application:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`import (
 // Other imported packages
 "os"

 "github.com/joho/godotenv"
)

func main(){
 // GoDotEnv loads any ".env" file located in the same directory as main.go
 err := godotenv.Load()
 if err != nil {
   log.Fatal("Error loading .env file")
 }

 // Store the value for the key "MESSAGEBIRD_API_KEY" in the loaded '.env' file.
 apikey := os.Getenv("MESSAGEBIRD_API_KEY")

 // The rest of your application ...
}
`}</code></pre>
    <h2>{`Initialize the MessageBird client`}</h2>
    <p>{`Let's now install the `}<a parentName="p" {...{
        "href": "https://github.com/messagebird/go-rest-api"
      }}>{`MessageBird's REST API package for Go`}</a>{` by running:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`go get -u github.com/messagebird/go-rest-api
`}</code></pre>
    <p>{`In your project folder which we created earlier, let's create a `}<inlineCode parentName="p">{`main.go`}</inlineCode>{` file, and write the following code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`package main

import (
 "github.com/messagebird/go-rest-api"
)

func main(){
 client := messagebird.New(<enter-your-apikey>)
}
`}</code></pre>
    <h3>{`Routes`}</h3>
    <p>{`To make the structure of our web application clear, we'll first stub the routes that we need. We need three routes:`}</p>
    <ul>
      <li parentName="ul">{`Step 1: A route to ask for the user's phone number.`}</li>
      <li parentName="ul">{`Step 2: A route for the user to enter a verification code.`}</li>
      <li parentName="ul">{`Step 3: A route to show if the verification code is valid.`}</li>
    </ul>
    <p>{`To write these routes, we need to modify `}<inlineCode parentName="p">{`main.go`}</inlineCode>{` to look like the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`package main

import (
 "fmt"
 "net/http"

 "github.com/messagebird/go-rest-api"
)

func startHandler(w http.ResponseWriter, r *http.Request){
 // Do nothing
 fmt.Fprintln("We ask for the user's phone number here.")
}

func verifyHandler(w http.ResponseWriter, r *http.Request){
 // Do nothing
 fmt.Fprintln("We ask the user to enter their verification token here.")
}

func confirmHandler(w http.ResponseWriter, r *http.Request){
 // Do nothing
 fmt.Fprintln("We display a message confirming that the user's token has been verified.")
}

func main(){
 client := messagebird.New(<enter-your-apikey>)

 http.HandleFunc("/",startHandler)
 http.HandleFunc("/verify",verifyHandler)
 http.HandleFunc("/confirm",confirmHandler)

 err := http.ListenAndServe(":8080",nil)
 if err != nil {
   panic(err)
 }
}
`}</code></pre>
    <p>{`We've defined a HTTP handler for each route, and assigned each handler to a corresponding URL path. At the bottom of our `}<inlineCode parentName="p">{`main()`}</inlineCode>{` function, we start a HTTP server on port 8080.`}</p>
    <p>{`To allow us to display errors, we've also added an `}<inlineCode parentName="p">{`ErrorMessage`}</inlineCode>{` struct type to pass messages to our rendered templates.`}</p>
    <p><strong parentName="p">{`Pro-tip:`}</strong>{` Here we’re using `}<inlineCode parentName="p">{`http.ListenAndServe`}</inlineCode>{` to start our HTTP server, but you may want to use `}<inlineCode parentName="p">{`http.ListenAndServeTLS`}</inlineCode>{` to secure your application's communication over the web with TLS/SSL.`}</p>
    <h3>{`Views`}</h3>
    <p>{`Now that we've got our routes set up, we can start writing templates to render for each route. We'll be defining four views:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`views/layouts/default`}</inlineCode>{`: The base HTML template we will use with all our views.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`views/start`}</inlineCode>{`: View for step 1.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`views/verify`}</inlineCode>{`: View for step 2.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`views/confirm`}</inlineCode>{`: View for step 3.`}</li>
    </ul>
    <p>{`Let's create the following files in your project folder:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`views/layouts/default.gohtml`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`views/start.gohtml`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`views/verify.gohtml`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`views/confirm.gohtml`}</inlineCode></li>
    </ul>
    <p>{`The `}<inlineCode parentName="p">{`.gohtml`}</inlineCode>{` files will contain our Go HTML template code for each of our views, and our "default" base template.`}</p>
    <p>{`First, we'll write the base template. In `}<inlineCode parentName="p">{`default.gohtml`}</inlineCode>{`, write the following code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`{{ define "default" }}
<!DOCTYPE html>
 <head>
   <meta charset="utf-8">
   <meta http-equiv="X-UA-Compatible" content="IE=edge">
   <title>MessageBird Verify Example</title>
   <meta name="description" content="">
   <meta name="viewport" content="width=device-width, initial-scale=1">
   <link rel="stylesheet" href="" type="text/css"/>
 </head>
 <body>
   <main>
   <h1>SocialBird Account Security</h1>
   <hr />
   <p>A sample application brought to you by <a href="https://developers.messagebird.com/">MessageBird</a> :)</p>
   {{ template "yield" . }}
   </main>
 </body>
</html>
{{ end }}
`}</code></pre>
    <p>{`Notice that in `}<inlineCode parentName="p">{`default.gohtml`}</inlineCode>{`, we are calling `}<inlineCode parentName="p">{`{{ template "yield" . }}`}</inlineCode>{`. This tells the Go templates package that the contents of any template named "yield" should be rendered in its place when the template is executed.`}</p>
    <p>{`Next, modify the `}<inlineCode parentName="p">{`import`}</inlineCode>{` call in `}<inlineCode parentName="p">{`main.go`}</inlineCode>{` to add our HTML template package:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`import (
 "fmt"
 "net/http"
 "html/template"

 "github.com/messagebird/go-rest-api"
)
`}</code></pre>
    <h2>{`Asking for the phone number`}</h2>
    <p>{`The first step in verifying a user's phone number is asking them to provide their phone number.Let's do exactly this by writing the following code in `}<inlineCode parentName="p">{`start.gohtml`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`{{ define "yield" }}
<p>To increase your account's security please provide your phone number. Your number will be verified with an automated call.</p>
{{ if .Message }}
<p><strong>{{ .Message }}</strong></p>
{{ end }}
<form method="post" action="/verify">
   <div>
       <label>Country:</label>
       <br />
       <select name="country_code" required>
           <option value="93">Afghanistan (+93)</option>
           <option value="355">Albania (+355)</option>
           <!-- ...other Country Codes omitted to keep example code short. -->
           <option value="31" selected>Netherlands (+31)</option>
           <!-- ... --->
           <option value="263">Zimbabwe (+263)</option>
           <option value="358">Åland Islands (+358)</option>

       </select>
   </div>
   <div>
       <label>Phone number (mobile or landline):</label>
       <br />
       <input type="tel" name="phone_number" placeholder="0123456768" required/>
   </div>
   <div>
       <button type="submit">Call me</button>
   </div>
</form>
{{ end }}
`}</code></pre>
    <p>{`Keep in mind that we've omitted most of the `}<inlineCode parentName="p">{`<select>`}</inlineCode>{` component of the form for selecting the country code to prefix the user's phone number with. For the full code sample, check out the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`.`}</p>
    <p>{`Here in `}<inlineCode parentName="p">{`start.gohtml`}</inlineCode>{`, we see that our template is defined as "yield", allowing `}<inlineCode parentName="p">{`default.gohtml`}</inlineCode>{` to render it when both templates are executed together by calling the `}<inlineCode parentName="p">{`ExecuteTemplate()`}</inlineCode>{` method. We've also added a `}<inlineCode parentName="p">{`{{ if .Message }}...{{ end }}`}</inlineCode>{` block to handle any `}<inlineCode parentName="p">{`Message`}</inlineCode>{` struct fields we pass to the template. For the moment, we're not passing any data to the template.`}</p>
    <p>{`To get this view to render, open `}<inlineCode parentName="p">{`main.go`}</inlineCode>{` and rewrite the `}<inlineCode parentName="p">{`startHandler()`}</inlineCode>{` handler function:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`func startHandler(w http.ResponseWriter, r *http.Request) {
  t, err := template.ParseFiles("views/start.gohtml","views/layouts/default.gohtml")
  if err != nil {
    log.Fatal(err)
  }
  err = t.ExecuteTemplate(w, "default", nil)
  if err != nil {
    log.Fatal(err)
 }
}
`}</code></pre>
    <p>{`We tell `}<inlineCode parentName="p">{`startHandler()`}</inlineCode>{` to read both the "views/start.gohtml" and "views/layouts/default.gohtml" template files. Then we call the `}<inlineCode parentName="p">{`ExecuteTemplate`}</inlineCode>{` method, telling it render the "default" template.`}</p>
    <h2>{`Writing a RenderDefaultTemplate() function`}</h2>
    <p>{`At this point, we know that the handlers for all three steps must:`}</p>
    <ul>
      <li parentName="ul">{`Parse the `}<inlineCode parentName="li">{`default.gohtml`}</inlineCode>{` template file and one other template file.`}</li>
      <li parentName="ul">{`Execute the template.`}</li>
      <li parentName="ul">{`Handle errors from the `}<inlineCode parentName="li">{`ParseFiles()`}</inlineCode>{` and `}<inlineCode parentName="li">{`ExecuteTemplate()`}</inlineCode>{` methods.`}</li>
      <li parentName="ul">{`Display an error message if we have one.`}</li>
    </ul>
    <p>{`We can write a `}<inlineCode parentName="p">{`RenderDefaultTemplate()`}</inlineCode>{` helper function to make these calls, keeping our code DRY:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`func RenderDefaultTemplate(w http.ResponseWriter, thisView string, data interface{}) {
 renderthis := []string{thisView, "views/layouts/default.gohtml"}
 t, err := template.ParseFiles(renderthis...)
 if err != nil {
   log.Fatal(err)
 }
 err = t.ExecuteTemplate(w, "default", data)
 if err != nil {
   log.Fatal(err)
 }
}
`}</code></pre>
    <p>{`Then, we can go ahead and rewrite all our handlers in `}<inlineCode parentName="p">{`main.go`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`func startHandler(w http.ResponseWriter, r *http.Request){
 RenderDefaultTemplate(w,"views/start.gohtml", nil)
}

func verifyHandler(w http.ResponseWriter, r *http.Request){
 RenderDefaultTemplate(w,"views/verify.gohtml", nil)
}

func confirmHandler(w http.ResponseWriter, r *http.Request){
 RenderDefaultTemplate(w,"views/confirm.gohtml", nil)
}
`}</code></pre>
    <h2>{`Initiating the verification call`}</h2>
    <p>{`Once we've collected the number, we can send a verification message to the user's mobile device. The MessageBird Verify API takes care of generating a random token, so you don't have to do this yourself. Codes are numeric and six digits by default; if you want to customize the length of the code or configure other options, you can check out our `}<a parentName="p" {...{
        "href": "/api/verify#verify-request"
      }}>{`Verify API documentation`}</a>{`.`}</p>
    <p>{`Next, we need a page with `}<inlineCode parentName="p">{`verifyHandler()`}</inlineCode>{` for our user to enter their verification code.`}</p>
    <p>{`In `}<inlineCode parentName="p">{`verify.gohtml`}</inlineCode>{`, let's write the following code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`{{ define "yield" }}
<p>Please pick up the automated call, listen to the verification code and enter it in the form below.</p>
{{ if .Message }}
<p><strong>{{ .Message }}</strong></p>
{{ end }}
<form method="post" action="/confirm">
   <div>
       <label>Code:</label>
       <br />
       <input type="text" name="token" placeholder="123456" required/>
   </div>
   <div>
       <button type="submit">Confirm</button>
   </div>
</form>
{{ end }}
`}</code></pre>
    <p>{`Then, we need to import MessageBird's `}<inlineCode parentName="p">{`verify`}</inlineCode>{` package. Modify the `}<inlineCode parentName="p">{`import`}</inlineCode>{` statement to look like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`import (
 "html/template"
 "log"
 "net/http"

 "github.com/messagebird/go-rest-api"
 "github.com/messagebird/go-rest-api/verify"
)
`}</code></pre>
    <p>{`Modify `}<inlineCode parentName="p">{`verifyHandler()`}</inlineCode>{` in `}<inlineCode parentName="p">{`main.go`}</inlineCode>{` to look like the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`func verifyHandler(w http.ResponseWriter, r *http.Request) {
 r.ParseForm()
 num := r.FormValue("country_code") + r.FormValue("phone_number")
 clientVerify, err := verify.Create(
   client,
   num,
   &verify.Params{
     Type:     "tts", // TTS = text-to-speech; otherwise API defaults to SMS.
     Template: "Your account security code is %token",
   },
 )
 if err != nil {
   log.Println(err)
   RenderDefaultTemplate(w, "views/verify.gohtml", &ErrorMessage{
     Message: "Could not start verification call. Please try again.",
   })
   return
 }
 RenderDefaultTemplate(w, "views/verify.gohtml", nil)
}
`}</code></pre>
    <p>{`In `}<inlineCode parentName="p">{`verifyHandler()`}</inlineCode>{`, we're using the `}<inlineCode parentName="p">{`ParseForm()`}</inlineCode>{` method to read data submitted through the form in `}<inlineCode parentName="p">{`start.gohtml`}</inlineCode>{`. We can access these values using the `}<inlineCode parentName="p">{`FormValue("<key>"")`}</inlineCode>{` method.`}</p>
    <p>{`We're doing a number of things:`}</p>
    <ol>
      <li parentName="ol">{`We're getting the user's phone number from the `}<inlineCode parentName="li">{`country_code`}</inlineCode>{` and `}<inlineCode parentName="li">{`phone_number`}</inlineCode>{` form fields, and assigning it to `}<inlineCode parentName="li">{`num`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`We then make a `}<inlineCode parentName="li">{`verify.Create()`}</inlineCode>{` call, to which we pass the MessageBird `}<inlineCode parentName="li">{`client`}</inlineCode>{` object, the `}<inlineCode parentName="li">{`num`}</inlineCode>{` variable, and custom parameters using the `}<inlineCode parentName="li">{`verify.Params`}</inlineCode>{` struct. In our custom `}<inlineCode parentName="li">{`verify.Params`}</inlineCode>{` struct, we specify the `}<inlineCode parentName="li">{`Type`}</inlineCode>{` as `}<inlineCode parentName="li">{`"tts"`}</inlineCode>{` so that the MessageBird servers know that we want to send a "text-to-speech" to the recipient instead of an SMS message. We also specify a `}<inlineCode parentName="li">{`Template`}</inlineCode>{` to use.`}</li>
      <li parentName="ol">{`If our `}<inlineCode parentName="li">{`verify.Create()`}</inlineCode>{` call succeeds, we get back a `}<inlineCode parentName="li">{`verify.Verify`}</inlineCode>{` object that contains information we need for the verification process.`}</li>
    </ol>
    <p>{`To share both the MessageBird `}<inlineCode parentName="p">{`client`}</inlineCode>{` object and the `}<inlineCode parentName="p">{`verify.Verify`}</inlineCode>{` object that we receive from the MessageBird server here, we need to create two global variables in `}<inlineCode parentName="p">{`main.go`}</inlineCode>{`.`}</p>
    <p>{`Just under our `}<inlineCode parentName="p">{`import`}</inlineCode>{` statement in `}<inlineCode parentName="p">{`main.go`}</inlineCode>{`, create the following variables:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`import (
 //...
)

var (
  // Stores the MessageBird Client object
 client       *messagebird.Client
 // Stores the Verify object received from the MessageBird servers
  clientVerify *verify.Verify
)

// The rest of the application...
`}</code></pre>
    <p>{`Then, modify `}<inlineCode parentName="p">{`verifyHandler()`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`func verifyHandler(w http.ResponseWriter, r *http.Request) {
 var err error

 r.ParseForm()
 num := r.FormValue("country_code") + r.FormValue("phone_number")
 clientVerify, err = verify.Create(
   client,
   num,
   &verify.Params{
     Type:     "tts", // TTS = text-to-speech; otherwise API defaults to SMS.
     Template: "Your account security code is %token",
   },
 )
 if err != nil {
   log.Println(err)
   RenderDefaultTemplate(w, "views/verify.gohtml", &ErrorMessage{
     Message: "Could not start verification call. Please try again.",
   })
   return
 }
 RenderDefaultTemplate(w, "views/verify.gohtml", nil)
}
`}</code></pre>
    <p>{`And, finally, modify `}<inlineCode parentName="p">{`main()`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`func main() {
 client = messagebird.New(<enter-your-apikey>)

 // The rest of your applicaiton...
}
`}</code></pre>
    <h2>{`Verifying the code`}</h2>
    <p>{`If everything runs smoothly, the user will check their phone, enter their verification code and submit the form. We now need to send the verification token along with the ID received in the `}<inlineCode parentName="p">{`clientVerify`}</inlineCode>{` object in `}<inlineCode parentName="p">{`verifyHandler()`}</inlineCode>{`.`}</p>
    <p>{`In `}<inlineCode parentName="p">{`confirm.gohtml`}</inlineCode>{`, let's write the following code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`{{ define "yield" }}
<p>Your number was confirmed! Thank you for helping us secure your account.</p>
{{ end }}
`}</code></pre>
    <p>{`Then, modify `}<inlineCode parentName="p">{`step3()`}</inlineCode>{` in `}<inlineCode parentName="p">{`main.go`}</inlineCode>{` to look like the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`func confirmHandler(w http.ResponseWriter, r *http.Request) {
 r.ParseForm()
 token := r.FormValue("token")
 clientVerifyDone, err := verify.VerifyToken(client, clientVerify.ID, token)
 if err != nil {
   log.Println(err)
   RenderDefaultTemplate(w, "views/start.gohtml", &ErrorMessage{
     Message: "Verification has failed. Please try again.",
   })
   return
 }

 if clientVerifyDone.Status == "verified" {
   // Success
   RenderDefaultTemplate(w, "views/confirm.gohtml", nil)
   return
 }
 // Catchall
 RenderDefaultTemplate(w, "views/start.gohtml", &ErrorMessage{
   Message: "Verification has failed: " + clientVerifyDone.Status + ". Please try again.",
 })
}
`}</code></pre>
    <p>{`In `}<inlineCode parentName="p">{`confirmHandler()`}</inlineCode>{`, we parse the form data submitted in `}<inlineCode parentName="p">{`verifyHandler`}</inlineCode>{` for the verification token, and send the token and the verification ID that we've stored in `}<inlineCode parentName="p">{`clientVerify`}</inlineCode>{` to the MessageBird servers with `}<inlineCode parentName="p">{`verify.VerifyToken()`}</inlineCode>{` call.`}</p>
    <p>{`If our `}<inlineCode parentName="p">{`verify.VerifyToken()`}</inlineCode>{` call returns an error, we know that token verification has failed. In this case:`}</p>
    <ol>
      <li parentName="ol">{`We check if `}<inlineCode parentName="li">{`err`}</inlineCode>{` is not a `}<inlineCode parentName="li">{`nil`}</inlineCode>{` value.`}</li>
      <li parentName="ol">{`If `}<inlineCode parentName="li">{`err`}</inlineCode>{` is not `}<inlineCode parentName="li">{`nil`}</inlineCode>{`, we send the user back to `}<inlineCode parentName="li">{`start.gohtml`}</inlineCode>{` and display an error message asking the user to try again.`}</li>
    </ol>
    <p>{`As an extra layer of safety, we check the `}<inlineCode parentName="p">{`Status`}</inlineCode>{` field of the received `}<inlineCode parentName="p">{`verify.Verify`}</inlineCode>{` object before deciding to display a success state. If verification has really succeeded, the `}<inlineCode parentName="p">{`Status`}</inlineCode>{` field value should be `}<inlineCode parentName="p">{`"verified"`}</inlineCode>{`, and we can safely render the `}<inlineCode parentName="p">{`confirm.gohtml`}</inlineCode>{` template. Otherwise, we send them back to `}<inlineCode parentName="p">{`start.gohtml`}</inlineCode>{` with an error message.`}</p>
    <h2>{`Testing`}</h2>
    <p>{`You’re done! Let's go ahead and test your application works. Check again that you’ve provided a working API key in `}<inlineCode parentName="p">{`.env`}</inlineCode>{`. Then, enter the following command in your console:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`go build main.go
./main
`}</code></pre>
    <p>{`Now, open your browser to http://localhost:8080/ and walk through the process yourself!`}</p>
    <p>{`Awesome! You can now leverage the flow, code snippets and UI examples from this tutorial to build your own voice-based account security system. Don't forget to download the code from the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/verify-voice-guide-go"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`.`}</p>
    <p><strong parentName="p">{`Nice work!`}</strong>{` 🎉`}</p>
    <p>{`You now have a running integration of MessageBird's Verify API using Go!`}</p>
    <h2>{`Start building!`}</h2>
    <p>{`Want to build something similar but not quite sure how to get started? Please feel free to let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; We'd love to help!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      