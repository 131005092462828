export const apisNavigationGenerated = [
    {
        activeOn: '/api/intent/',
        route: '/api/intent/',
        name: 'Intent',
        subMenu: [
            {
                route: '/api/intent/#base-url',
                activeOn: '/api/intent/#base-url',
                name: 'Base URL',
            },
            {
                route: '/api/intent/#authorization',
                activeOn: '/api/intent/#authorization',
                name: 'Authorization',
            },
            {
                route: '/api/intent/#list-datasets',
                activeOn: '/api/intent/#list-datasets',
                name: 'ListDatasets',
            },
            {
                route: '/api/intent/#get-dataset',
                activeOn: '/api/intent/#get-dataset',
                name: 'GetDataset',
            },
            {
                route: '/api/intent/#predict-intent',
                activeOn: '/api/intent/#predict-intent',
                name: 'PredictIntent',
            },
            {
                route: '/api/intent/#list-supported-languages',
                activeOn: '/api/intent/#list-supported-languages',
                name: 'ListSupportedLanguages',
            },
            {
                route: '/api/intent/#objects-reference',
                activeOn: '/api/intent/#objects-reference',
                name: 'Objects Reference',
                subMenu: [
                    {
                        route: '/api/intent/#dataset-object',
                        activeOn: '/api/intent/#dataset-object',
                        name: 'Dataset',
                    },
                    {
                        route: '/api/intent/#language-object',
                        activeOn: '/api/intent/#language-object',
                        name: 'Language',
                    },
                    {
                        route: '/api/intent/#language-code-enum',
                        activeOn: '/api/intent/#language-code-enum',
                        name: 'LanguageCode',
                    },
                    {
                        route: '/api/intent/#language-list-object',
                        activeOn: '/api/intent/#language-list-object',
                        name: 'LanguageList',
                    },
                    {
                        route: '/api/intent/#list-dataset-mode-enum',
                        activeOn: '/api/intent/#list-dataset-mode-enum',
                        name: 'ListDatasetMode',
                    },
                    {
                        route: '/api/intent/#list-datasets-response-object',
                        activeOn: '/api/intent/#list-datasets-response-object',
                        name: 'ListDatasetsResponse',
                    },
                    {
                        route: '/api/intent/#predict-intent-response-object',
                        activeOn: '/api/intent/#predict-intent-response-object',
                        name: 'PredictIntentResponse',
                    },
                ]
            },
        ]
    },
    {
        activeOn: '/api/faq/',
        route: '/api/faq/',
        name: 'FAQ',
        subMenu: [
            {
                route: '/api/faq/#base-url',
                activeOn: '/api/faq/#base-url',
                name: 'Base URL',
            },
            {
                route: '/api/faq/#authorization',
                activeOn: '/api/faq/#authorization',
                name: 'Authorization',
            },
            {
                route: '/api/faq/#list-datasets',
                activeOn: '/api/faq/#list-datasets',
                name: 'ListDatasets',
            },
            {
                route: '/api/faq/#get-dataset',
                activeOn: '/api/faq/#get-dataset',
                name: 'GetDataset',
            },
            {
                route: '/api/faq/#predict-answer',
                activeOn: '/api/faq/#predict-answer',
                name: 'PredictAnswer',
            },
            {
                route: '/api/faq/#list-supported-languages',
                activeOn: '/api/faq/#list-supported-languages',
                name: 'ListSupportedLanguages',
            },
            {
                route: '/api/faq/#objects-reference',
                activeOn: '/api/faq/#objects-reference',
                name: 'Objects Reference',
                subMenu: [
                    {
                        route: '/api/faq/#answer-object',
                        activeOn: '/api/faq/#answer-object',
                        name: 'Answer',
                    },
                    {
                        route: '/api/faq/#dataset-object',
                        activeOn: '/api/faq/#dataset-object',
                        name: 'Dataset',
                    },
                    {
                        route: '/api/faq/#language-object',
                        activeOn: '/api/faq/#language-object',
                        name: 'Language',
                    },
                    {
                        route: '/api/faq/#language-code-enum',
                        activeOn: '/api/faq/#language-code-enum',
                        name: 'LanguageCode',
                    },
                    {
                        route: '/api/faq/#language-list-object',
                        activeOn: '/api/faq/#language-list-object',
                        name: 'LanguageList',
                    },
                    {
                        route: '/api/faq/#list-datasets-mode-enum',
                        activeOn: '/api/faq/#list-datasets-mode-enum',
                        name: 'ListDatasetsMode',
                    },
                    {
                        route: '/api/faq/#list-datasets-response-object',
                        activeOn: '/api/faq/#list-datasets-response-object',
                        name: 'ListDatasetsResponse',
                    },
                    {
                        route: '/api/faq/#predict-answer-response-object',
                        activeOn: '/api/faq/#predict-answer-response-object',
                        name: 'PredictAnswerResponse',
                    },
                ]
            },
        ]
    },
    {
        activeOn: '/api/named-entity-recognition/',
        route: '/api/named-entity-recognition/',
        name: 'Named Entity Recognition',
        subMenu: [
            {
                route: '/api/named-entity-recognition/#base-url',
                activeOn: '/api/named-entity-recognition/#base-url',
                name: 'Base URL',
            },
            {
                route: '/api/named-entity-recognition/#authorization',
                activeOn: '/api/named-entity-recognition/#authorization',
                name: 'Authorization',
            },
            {
                route: '/api/named-entity-recognition/#predict-entities',
                activeOn: '/api/named-entity-recognition/#predict-entities',
                name: 'PredictEntities',
            },
            {
                route: '/api/named-entity-recognition/#list-supported-languages',
                activeOn: '/api/named-entity-recognition/#list-supported-languages',
                name: 'ListSupportedLanguages',
            },
            {
                route: '/api/named-entity-recognition/#objects-reference',
                activeOn: '/api/named-entity-recognition/#objects-reference',
                name: 'Objects Reference',
                subMenu: [
                    {
                        route: '/api/named-entity-recognition/#amount-of-money-value-object',
                        activeOn: '/api/named-entity-recognition/#amount-of-money-value-object',
                        name: 'AmountOfMoneyValue',
                    },
                    {
                        route: '/api/named-entity-recognition/#currency-code-iso4217-enum',
                        activeOn: '/api/named-entity-recognition/#currency-code-iso4217-enum',
                        name: 'CurrencyCodeIso4217',
                    },
                    {
                        route: '/api/named-entity-recognition/#currency-code-iso4217-value-object',
                        activeOn: '/api/named-entity-recognition/#currency-code-iso4217-value-object',
                        name: 'CurrencyCodeIso4217Value',
                    },
                    {
                        route: '/api/named-entity-recognition/#default-values-object',
                        activeOn: '/api/named-entity-recognition/#default-values-object',
                        name: 'DefaultValues',
                    },
                    {
                        route: '/api/named-entity-recognition/#duration-values-object',
                        activeOn: '/api/named-entity-recognition/#duration-values-object',
                        name: 'DurationValues',
                    },
                    {
                        route: '/api/named-entity-recognition/#entity-object',
                        activeOn: '/api/named-entity-recognition/#entity-object',
                        name: 'Entity',
                    },
                    {
                        route: '/api/named-entity-recognition/#entity-type-enum',
                        activeOn: '/api/named-entity-recognition/#entity-type-enum',
                        name: 'EntityType',
                    },
                    {
                        route: '/api/named-entity-recognition/#entity-values-wrapper-object',
                        activeOn: '/api/named-entity-recognition/#entity-values-wrapper-object',
                        name: 'EntityValuesWrapper',
                    },
                    {
                        route: '/api/named-entity-recognition/#interval-values-object',
                        activeOn: '/api/named-entity-recognition/#interval-values-object',
                        name: 'IntervalValues',
                    },
                    {
                        route: '/api/named-entity-recognition/#language-object',
                        activeOn: '/api/named-entity-recognition/#language-object',
                        name: 'Language',
                    },
                    {
                        route: '/api/named-entity-recognition/#language-code-enum',
                        activeOn: '/api/named-entity-recognition/#language-code-enum',
                        name: 'LanguageCode',
                    },
                    {
                        route: '/api/named-entity-recognition/#list-supported-languages-responses-object',
                        activeOn: '/api/named-entity-recognition/#list-supported-languages-responses-object',
                        name: 'ListSupportedLanguagesResponses',
                    },
                    {
                        route: '/api/named-entity-recognition/#predict-entities-request-object',
                        activeOn: '/api/named-entity-recognition/#predict-entities-request-object',
                        name: 'PredictEntitiesRequest',
                    },
                    {
                        route: '/api/named-entity-recognition/#predict-entities-response-object',
                        activeOn: '/api/named-entity-recognition/#predict-entities-response-object',
                        name: 'PredictEntitiesResponse',
                    },
                    {
                        route: '/api/named-entity-recognition/#product-quantity-value-object',
                        activeOn: '/api/named-entity-recognition/#product-quantity-value-object',
                        name: 'ProductQuantityValue',
                    },
                    {
                        route: '/api/named-entity-recognition/#supported-entities-for-language-object',
                        activeOn: '/api/named-entity-recognition/#supported-entities-for-language-object',
                        name: 'SupportedEntitiesForLanguage',
                    },
                    {
                        route: '/api/named-entity-recognition/#unit-values-object',
                        activeOn: '/api/named-entity-recognition/#unit-values-object',
                        name: 'UnitValues',
                    },
                    {
                        route: '/api/named-entity-recognition/#url-values-object',
                        activeOn: '/api/named-entity-recognition/#url-values-object',
                        name: 'UrlValues',
                    },
                ]
            },
        ]
    },
    {
        activeOn: '/api/language-detection/',
        route: '/api/language-detection/',
        name: 'Language Detection',
        subMenu: [
            {
                route: '/api/language-detection/#base-url',
                activeOn: '/api/language-detection/#base-url',
                name: 'Base URL',
            },
            {
                route: '/api/language-detection/#authorization',
                activeOn: '/api/language-detection/#authorization',
                name: 'Authorization',
            },
            {
                route: '/api/language-detection/#predict-language',
                activeOn: '/api/language-detection/#predict-language',
                name: 'PredictLanguage',
            },
            {
                route: '/api/language-detection/#list-supported-languages',
                activeOn: '/api/language-detection/#list-supported-languages',
                name: 'ListSupportedLanguages',
            },
            {
                route: '/api/language-detection/#objects-reference',
                activeOn: '/api/language-detection/#objects-reference',
                name: 'Objects Reference',
                subMenu: [
                    {
                        route: '/api/language-detection/#language-object',
                        activeOn: '/api/language-detection/#language-object',
                        name: 'Language',
                    },
                    {
                        route: '/api/language-detection/#language-code-enum',
                        activeOn: '/api/language-detection/#language-code-enum',
                        name: 'LanguageCode',
                    },
                    {
                        route: '/api/language-detection/#language-list-object',
                        activeOn: '/api/language-detection/#language-list-object',
                        name: 'LanguageList',
                    },
                    {
                        route: '/api/language-detection/#predict-language-request-object',
                        activeOn: '/api/language-detection/#predict-language-request-object',
                        name: 'PredictLanguageRequest',
                    },
                    {
                        route: '/api/language-detection/#predict-language-response-object',
                        activeOn: '/api/language-detection/#predict-language-response-object',
                        name: 'PredictLanguageResponse',
                    },
                ]
            },
        ]
    },
]