import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Setting SMS reminders with MessageBird`}</h1>
    <h3>{`⏱ 15 min build time      ||      `}<a parentName="h3" {...{
        "href": "https://github.com/messagebirdguides/reminders-guide-go"
      }}>{`Download the Code`}</a></h3>
    <h2>{`Why build SMS appointment reminders?`}</h2>
    <p>{`In this MessageBird Developer Tutorial, you’ll learn how to build an SMS appointment reminder application and improve your users’ experience with the `}<a parentName="p" {...{
        "href": "/api/sms-messaging"
      }}>{`MessageBird SMS Messaging API`}</a>{`.`}</p>
    <p>{`Booking appointments online from a website or mobile app is quick and easy. Customers just have to select their desired date and time, personal details and hit a button. The problem, however, is that easy-to-book appointments are often just as easy to forget.`}</p>
    <p>{`For appointment-based services, no-shows are annoying and costly because of the time and revenue lost waiting for a customer instead of serving them or another customer. Timely SMS reminders simple and discrete nudges, which can go a long way in the prevention of costly no-shows.`}</p>
    <h2>{`Getting started`}</h2>
    <p>{`This sample application is built in Go and represents the order website for our fictitious online beauty salon, BeautyBird. To reduce the growing number of no-shows, BeautyBird now collects appointment bookings through a form on their website and schedules timely SMS reminders to be sent out three hours before the selected date and time.`}</p>
    <p>{`To look at the full sample application or run it on your computer, go to the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/reminders-guide-go"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{` and clone it or download the source code as a ZIP archive.`}</p>
    <p>{`We'll be building our single-page web application with:`}</p>
    <ul>
      <li parentName="ul">{`The latest version of `}<a parentName="li" {...{
          "href": "https://golang.org"
        }}>{`Go`}</a></li>
      <li parentName="ul">{`The `}<a parentName="li" {...{
          "href": "https://github.com/messagebird/go-rest-api"
        }}>{`MessageBird's REST API package for Go`}</a></li>
    </ul>
    <h3>{`Structure of your application`}</h3>
    <p>{`We're building a single page application that takes user input from our BeautyBird booking page and schedules an SMS reminder to be sent three hours before the appointment. To do this, we need to do the following:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Build an appointment booking page`}</strong>{`: Our appointment booking page should take in a name, treatment details, a phone number, and a date and time for the booking.`}</li>
      <li parentName="ul"><strong parentName="li">{`Check if the phone number provided is valid`}</strong>{`: We need to know if the phone number provided works. We'll use the `}<a parentName="li" {...{
          "href": "/api/lookup"
        }}>{`MessageBird Lookup REST API`}</a>{` to check if the phone number is valid.`}</li>
      <li parentName="ul"><strong parentName="li">{`Check if the booking time is valid`}</strong>{`: We should only accept appointments that are within opening hours and that are made at least three hours in advance.`}</li>
      <li parentName="ul"><strong parentName="li">{`Finally, schedule an SMS reminder to be sent`}</strong>{`: After we've verified that all the booking information we've received is valid, we schedule an SMS reminder to be sent three hours before the appointment.`}</li>
    </ul>
    <h3>{`Project setup`}</h3>
    <p>{`Create a folder for your application. In this folder, create the
following subfolders:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`views`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`views/layouts`}</inlineCode></li>
    </ul>
    <p>{`We'll use the following packages from the Go standard library to build our application:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`net/http`}</inlineCode>{`: A HTTP package for building our routes and a simple http server.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`html/template`}</inlineCode>{`: A HTML template library for building views.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`time`}</inlineCode>{`: The Go standard library for handling time.`}</li>
    </ul>
    <p>{`From the MessageBird Go REST API package, we'll import the following packages:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`github.com/messagebird/go-rest-api`}</inlineCode>{`: The MessageBird core client package.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`github.com/messagebird/go-rest-api/sms`}</inlineCode>{`: The MessageBird SMS messaging package.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`github.com/messagebird/go-rest-api/lookup`}</inlineCode>{`: The MessageBird phone number lookup package.`}</li>
    </ul>
    <h3>{`Create your API Key 🔑`}</h3>
    <p>{`To start making API calls, we need to generate an access key. MessageBird provides keys in `}<em parentName="p">{`live`}</em>{` and `}<em parentName="p">{`test`}</em>{` modes. To get this application running, we’ll need to create and use a live API access key. You can read more about `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/articles/360000670709-What-is-the-difference-between-a-live-key-and-a-test-key-"
      }}>{`the difference between test and live API keys`}</a>{` in our Help Center.`}</p>
    <p>{`Let's create your live API access key. First, go to the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/user/index"
      }}>{`MessageBird Dashboard`}</a>{`; if you have already created an API key it will be shown right there. If you don’t see any key on the Dashboard or if you're unsure whether this key is in `}<em parentName="p">{`live`}</em>{` mode, go to the `}<em parentName="p">{`Developers`}</em>{` section in the MessageBird Dashboard and open the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`API access (REST) tab`}</a>{`. There you can create new API keys and manage your existing ones.`}</p>
    <p>{`If you are having any issues creating your API key, please reach out to `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we’ll make sure to help you out.`}</p>
    <p><strong parentName="p">{`Pro-tip:`}</strong>{` To keep our demonstration code simple, we will be saving our API key in `}<inlineCode parentName="p">{`main.go`}</inlineCode>{`. Hardcoding your credentials in the code is a risky practice that should never be used in production applications. A better method, also recommended by the `}<a parentName="p" {...{
        "href": "https://12factor.net/"
      }}>{`Twelve-Factor App Definition`}</a>{`, is to use environment variables. You can use open source packages such as `}<a parentName="p" {...{
        "href": "https://github.com/joho/godotenv"
      }}>{`GoDotEnv`}</a>{` to read your API key from a `}<inlineCode parentName="p">{`.env`}</inlineCode>{` file into your Go application. Your `}<inlineCode parentName="p">{`.env`}</inlineCode>{` file should be written as follows:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`MESSAGEBIRD_API_KEY=YOUR-API-KEY
`}</code></pre>
    <p>{`To use `}<a parentName="p" {...{
        "href": "https://github.com/joho/godotenv"
      }}>{`GoDotEnv`}</a>{` in your application, install it by running:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`go get -u github.com/joho/godotenv
`}</code></pre>
    <p>{`Then, import it in your application:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`import (
 // Other imported packages
 "os"

 "github.com/joho/godotenv"
)

func main(){
 // GoDotEnv loads any ".env" file located in the same directory as main.go
 err := godotenv.Load()
 if err != nil {
   log.Fatal("Error loading .env file")
 }

 // Store the value for the key "MESSAGEBIRD_API_KEY" in the loaded '.env' file.
 apikey := os.Getenv("MESSAGEBIRD_API_KEY")

 // The rest of your application ...
}
`}</code></pre>
    <h2>{`Initialize the MessageBird client`}</h2>
    <p>{`Install the `}<a parentName="p" {...{
        "href": "https://github.com/messagebird/go-rest-api"
      }}>{`MessageBird's REST API package for Go`}</a>{` by running:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`go get -u github.com/messagebird/go-rest-api
`}</code></pre>
    <p>{`In your project folder, create a `}<inlineCode parentName="p">{`main.go`}</inlineCode>{` file, and write the following code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`package main

import (
 "github.com/messagebird/go-rest-api"
)

var client *messagebird.Client

func main(){
 client = messagebird.New(<enter-your-apikey>)
}
`}</code></pre>
    <h2>{`Building an appointment booking page`}</h2>
    <p>{`Our goal here is to set up a page to collect customer details and, most importantly, their phone number so that we can send them an SMS reminder three hours before their appointment.`}</p>
    <p>{`First, we'll set up our templates and routes. Modify `}<inlineCode parentName="p">{`main.go`}</inlineCode>{` to look like the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`package main

import (
   "log"
   "net/http"
   "html/template"

   "github.com/messagebird/go-rest-api"
   )

var client *messagebird.Client

func main(){
   client = messagebird.New(<enter-your-apikey>)

   // Routes
   http.HandleFunc("/", bbScheduler)

   // Serve
   port := ":8080"
   log.Println("Serving application on", port)
   err := http.ListenAndServe(port, nil)
   if err != nil {
       log.Println(err)
   }
}

func bbScheduler(w http.ResponseWriter, r *http.Request){
   RenderDefaultTemplate(w,"views/booking.gohtml",nil)
}

func RenderDefaultTemplate(w http.ResponseWriter, thisView string, data interface{}) {
   renderthis := []string{thisView, "views/layouts/default.gohtml"}
   t, err := template.ParseFiles(renderthis...)
   if err != nil {
       log.Fatal(err)
   }
   err = t.ExecuteTemplate(w, "default", data)
   if err != nil {
       log.Fatal(err)
   }
}
`}</code></pre>
    <p>{`Here, we've set up a route that's handled by the `}<inlineCode parentName="p">{`bbScheduler`}</inlineCode>{` handler function. We've also set up a helper function `}<inlineCode parentName="p">{`RenderDefaultTemplate()`}</inlineCode>{` that parses our `}<inlineCode parentName="p">{`default.gohtml`}</inlineCode>{` template and one other template, and handles possible errors.`}</p>
    <p>{`With that done, we'll set up our `}<inlineCode parentName="p">{`default`}</inlineCode>{` template. Create `}<inlineCode parentName="p">{`views/layouts/default.gohtml`}</inlineCode>{` and write the following code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`{{ define "default" }}
<!DOCTYPE html>
 <head>
   <meta charset="utf-8">
   <meta http-equiv="X-UA-Compatible" content="IE=edge">
   <title>MessageBird Verify Example</title>
   <meta name="description" content="">
   <meta name="viewport" content="width=device-width, initial-scale=1">
 </head>
 <body>
   <main>
   {{ template "yield" . }}
   </main>
 </body>
</html>
{{ end }}
`}</code></pre>
    <p>{`And our `}<inlineCode parentName="p">{`booking`}</inlineCode>{` template. Create `}<inlineCode parentName="p">{`views/booking.gohtml`}</inlineCode>{` and add the following code to it:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`{{ define "yield" }}
<h1>BeautyBird &lt;3</h1>
<p>Book an appointment for a treatment in our salon, right here on our website!</p>
<form method="post" action="/">
   <div>
       <label>Your name:</label>
       <br />
       <input type="text" name="name" required/>
   </div>
   <div>
       <label>Your desired treatment:</label>
       <br />
       <input type="text" name="treatment" required/>
   </div>
   <div>
       <label>Your mobile number (e.g. +31624971134):</label>
       <br />
       <input type="tel" name="phone" required/>
   </div>
   <div>
       <label>Date and Time (<small>Please book at least 3 hours in advance.</small>):</label>
       <br/>
       <input type="date" name="date" required/>
       <input type="time" name="time" required/>
   </div>
   <div>
       <button type="submit">Book Now!</button>
   </div>
</form>
{{ end }}
`}</code></pre>
    <p>{`Done! If you run `}<inlineCode parentName="p">{`go run main.go`}</inlineCode>{` now and navigate to `}<inlineCode parentName="p">{`http://localhost:8080`}</inlineCode>{`, you'll find your booking appointment page ready to accept new appointments (and immediately forget them; we'll deal with that in a moment).`}</p>
    <h2>{`Storing appointments and scheduling reminders`}</h2>
    <p>{`Let's write some code to pull date from the booking form.`}</p>
    <p>{`First, we need to create data structures to contain our appointment information. Just above `}<inlineCode parentName="p">{`main()`}</inlineCode>{`, add the following code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`type booking struct {
   Name        string
   Treatment   string
   Phone       string
   BookingTime *time.Time
   MinDate     string
}

type bookingContainer struct {
   Booking booking
   Message string
}
`}</code></pre>
    <p>{`Here, we're setting up two structs to contain data we're parsing from the submitted booking form, and the text that we want to pass back into our template to display a state message that tells our customers if their booking was successful. Because (1) we can only pass one data object into a template, and (2) we want to manage our booking data separately from the status message, we'll set up two separate struct types: `}<inlineCode parentName="p">{`booking`}</inlineCode>{`, and `}<inlineCode parentName="p">{`bookingContainer`}</inlineCode>{`.`}</p>
    <p>{`Keep in mind that this tutorial shows you how to schedule an SMS reminder for a single appointment. In production, you'll need to save the appointment information in a persistent data store; to keep the tutorial straightforward, we've omitted those instructions.`}</p>
    <h3>{`Parse the booking form`}</h3>
    <p>{`Let's get our customer's booking information out of that form. We'll add code to `}<inlineCode parentName="p">{`bbScheduler()`}</inlineCode>{` to:`}</p>
    <ol>
      <li parentName="ol">{`Parse the submitted form.`}</li>
      <li parentName="ol">{`Extract data from the submitted form and add them to a `}<inlineCode parentName="li">{`ThisBooking`}</inlineCode>{` struct.`}</li>
      <li parentName="ol">{`Update the view with this new information.`}</li>
    </ol>
    <p>{`Modify `}<inlineCode parentName="p">{`bbScheduler()`}</inlineCode>{` to look like the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`func bbScheduler(w http.ResponseWriter, r *http.Request){

   if r.Method == "POST" {
       r.ParseForm()

       ThisBooking := booking{
           Name:        r.FormValue("name"),
           Treatment:   r.FormValue("treatment"),
           Phone:       r.FormValue("phone"),
           BookingTime: bookingTime,
           MinDate:     minDate,
       }
       RenderDefaultTemplate(w,"views/booking.gohtml",bookingContainer{ThisBooking,"Booking successful!"})
       return
   }

   RenderDefaultTemplate(w,"views/booking.gohtml",nil)
}
`}</code></pre>
    <p>{`Here, we're parsing the form with `}<inlineCode parentName="p">{`r.ParseForm()`}</inlineCode>{` and putting information that we've extracted from the booking form into a `}<inlineCode parentName="p">{`ThisBooking`}</inlineCode>{` struct. Then we call `}<inlineCode parentName="p">{`RenderDefaultTemplate()`}</inlineCode>{` again, and pass `}<inlineCode parentName="p">{`ThisBooking`}</inlineCode>{` and a `}<inlineCode parentName="p">{`"Booking successful!"`}</inlineCode>{` message to the template with a `}<inlineCode parentName="p">{`bookingContainer{}`}</inlineCode>{` struct literal.`}</p>
    <h3>{`Working with `}<inlineCode parentName="h3">{`time`}</inlineCode></h3>
    <p>{`Keep in mind that in our `}<inlineCode parentName="p">{`booking`}</inlineCode>{` struct, `}<inlineCode parentName="p">{`BookingTime`}</inlineCode>{` is of type `}<inlineCode parentName="p">{`time.Time`}</inlineCode>{`. This is a data structure that Go uses to work with time values. Using the `}<inlineCode parentName="p">{`time`}</inlineCode>{` package gives us the ability to set and compare time values; features that we'll need when writing code to check if a booking time is valid. We'll need to add code to `}<inlineCode parentName="p">{`bbScheduler()`}</inlineCode>{` to:`}</p>
    <ol>
      <li parentName="ol">{`Import the `}<inlineCode parentName="li">{`time`}</inlineCode>{` package.`}</li>
      <li parentName="ol">{`Define a time locale. If we don't define a time locale, then the `}<inlineCode parentName="li">{`time`}</inlineCode>{` package will assume that its working with UTC.`}</li>
      <li parentName="ol">{`convert the value of `}<inlineCode parentName="li">{`r.FormValue("date")`}</inlineCode>{` and `}<inlineCode parentName="li">{`r.FormValue("time")`}</inlineCode>{` to a format that is digestible by the `}<inlineCode parentName="li">{`time`}</inlineCode>{` package.`}</li>
    </ol>
    <p>{`Modify the `}<inlineCode parentName="p">{`import`}</inlineCode>{` statement in `}<inlineCode parentName="p">{`main.go`}</inlineCode>{` to add the `}<inlineCode parentName="p">{`time`}</inlineCode>{` package:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`import (
   "log"
   "net/http"
   "html/template"
   "time"

   "github.com/messagebird/go-rest-api"
   )
`}</code></pre>
    <p>{`Then, rewrite `}<inlineCode parentName="p">{`bbScheduler()`}</inlineCode>{` so that it looks like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`func bbScheduler(w http.ResponseWriter, r *http.Request){

   loc, err = time.LoadLocation("Europe/Amsterdam")
   if err != nil {
       log.Println(err)
   }

   if r.Method == "POST" {
       r.ParseForm()

       bookingTime, err := time.ParseInLocation("2006-01-02 15:04", r.FormValue("date")+"  "+r.FormValue("time"), loc)
       if err != nil {
           log.Println(err)
       }

       ThisBooking := booking{
           Name:        r.FormValue("name"),
           Treatment:   r.FormValue("treatment"),
           Phone:       r.FormValue("phone"),
           BookingTime: &bookingTime,
           MinDate:     time.Now().In(loc).Format("2006-01-02"),
       }
       RenderDefaultTemplate(w,"views/booking.gohtml",ThisBooking)
       return
   }

   RenderDefaultTemplate(w,"views/booking.gohtml",nil)
`}</code></pre>
    <p>{`There are several things happening here, so let's break it down.`}</p>
    <h4>{`a. Define a time locale`}</h4>
    <p>{`We're defining a location at the top of `}<inlineCode parentName="p">{`bbScheduler()`}</inlineCode>{` to make sure that we're working in the correct time locale. Because we don't expect BeautyBird customers to cross timezones for their appointments, we can hardcode this value here as the `}<inlineCode parentName="p">{`loc`}</inlineCode>{` variable.`}</p>
    <h4>{`b. Parse date and time from form input`}</h4>
    <p>{`To get the date and time values extracted from our appointment form into a format that the `}<inlineCode parentName="p">{`time`}</inlineCode>{` package understands, we need to tell `}<inlineCode parentName="p">{`time`}</inlineCode>{` to parse those values. In the above code, we call
`}<inlineCode parentName="p">{`time.ParseInLocation()`}</inlineCode>{` which takes three parameters: a "layout" string, a "value" string that specifies a specific date and time, and a "location" (which we've defined as `}<inlineCode parentName="p">{`loc`}</inlineCode>{`).`}</p>
    <p>{`The "layout" string is Go's way of allowing you to quickly specify in what format the date and time is written in "value". When we write:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`time.ParseInLocation("2006-01-02 15:04", r.FormValue("date")+"  "+r.FormValue("time"), loc)
`}</code></pre>
    <p>{`We're telling Go to parse `}<inlineCode parentName="p">{`r.FormValue("date") + " " + r.FormValue("time")`}</inlineCode>{` with the "layout": `}<inlineCode parentName="p">{`"2006-01-02 15:04"`}</inlineCode>{`.`}</p>
    <p>{`This "layout" can be read like this:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`2006`}</inlineCode>{`: tells Go to expect a four digit "year" value.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`01`}</inlineCode>{`: A two digit "month" value.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`02`}</inlineCode>{`: A two digit "day" value.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`15`}</inlineCode>{`: A two digit "hour" value, in a 24-hour format. We can also tell Go to expect an "hour" value in a 12-hour format by writing `}<inlineCode parentName="li">{`03`}</inlineCode>{` instead.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`04`}</inlineCode>{`: A two digit "minute" value.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`PM`}</inlineCode>{`: We're not using it here yet, but we tell Go to expect a `}<inlineCode parentName="li">{`PM`}</inlineCode>{` or `}<inlineCode parentName="li">{`AM`}</inlineCode>{` value by writing `}<inlineCode parentName="li">{`PM`}</inlineCode>{` (or `}<inlineCode parentName="li">{`pm`}</inlineCode>{` for lowercase).`}</li>
    </ul>
    <h4>{`c. Set a MinDate value`}</h4>
    <p>{`We're using `}<inlineCode parentName="p">{`<input type=date/>`}</inlineCode>{` to allow our customers to enter the a date for their booking. To prevent them from selecting a date that's earlier than today, we can write a `}<inlineCode parentName="p">{`min`}</inlineCode>{` attribute that contains today's date. In the code above, we get today's date and assign it to the `}<inlineCode parentName="p">{`MinDate`}</inlineCode>{` field in `}<inlineCode parentName="p">{`ThisBooking`}</inlineCode>{`, which we then pass to the template when we call `}<inlineCode parentName="p">{`RenderDefaultTemplate()`}</inlineCode>{`.`}</p>
    <p>{`Once we've parsed the values submitted through the booking form, we assign them to their corresponding struct fields in `}<inlineCode parentName="p">{`ThisBooking`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`ThisBooking := booking{
       Name:        r.FormValue("name"),
       Treatment:   r.FormValue("treatment"),
       Phone:       r.FormValue("phone"),
       BookingTime: &bookingTime,
       MinDate:     time.Now().In(loc).Format("2006-01-02"),
   }
`}</code></pre>
    <p>{`For the purposes of this tutorial, we'll use `}<inlineCode parentName="p">{`ThisBooking`}</inlineCode>{` once to setup a scheduled SMS reminder, and then forget it once the next booking is received. In production, you'll want to push each new instance of `}<inlineCode parentName="p">{`ThisBooking`}</inlineCode>{` to a persistent data store to save that information.`}</p>
    <h2>{`Setting up templates to use booking data`}</h2>
    <p>{`Now, we can set up our template to use all the data that we're passing through the `}<inlineCode parentName="p">{`RenderDefaultTemplate()`}</inlineCode>{` call.`}</p>
    <p>{`Modify your `}<inlineCode parentName="p">{`booking.gohtml`}</inlineCode>{` file to look like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`{{ define "yield" }}
   <h1>BeautyBird &lt;3</h1>
   <p>Book an appointment for a treatment in our salon, right here on our website!</p>
   <form method="post" action="/">
       <div>
           <label>Your name:</label>
           <br />
           <input type="text" name="name" {{ if .Booking.Name }} value="{{ .Booking.Name }}"{{ end }} required/>
       </div>
       <div>
           <label>Your desired treatment:</label>
           <br />
           <input type="text" name="treatment" {{ if .Booking.Treatment }} value="{{ .Booking.Treatment }}"{{ end }} required/>
       </div>
       <div>
           <label>Your mobile number (e.g. +31624971134):</label>
           <br />
           <input type="tel" name="phone" {{ if .Booking.Phone }} value="{{ .Booking.Phone }}"{{ end }} required/>
       </div>
       <div>
           <label>Date and Time (<small>Please book at least 3 hours in advance.</small>):</label>
           <br/>
           <input type="date" name="date" min="{{ .Booking.MinDate }}" required/>
           <input type="time" name="time" required/>
       </div>
       <div>
           <button type="submit">Book Now!</button>
       </div>
   </form>

   {{ if .Message }}
   <section>
   <strong>{{ .Message }}</strong>
   </section>
   {{ end }}
{{ end }}
`}</code></pre>
    <p>{`For our "name", "treatment", and "phone" fields, we're adding `}<inlineCode parentName="p">{`{{ if .FieldName }}value="{{ .FieldName }}"{{ end }}`}</inlineCode>{` blocks to tell our template to display a field value if it's been defined and available. This allows us to display field values entered for the previous form submissions and that way we can handle a case where a submission fails—our customer can check and re-submit their booking details without having to re-enter information.`}</p>
    <p>{`We've also added a `}<inlineCode parentName="p">{`min`}</inlineCode>{` attribute to our `}<inlineCode parentName="p">{`<input type="date"/>`}</inlineCode>{` line, so that customers cannot select a date before the present date.`}</p>
    <p>{`Finally, we're displaying a status message (if any) at the bottom of the template with an `}<inlineCode parentName="p">{`{{ if .Message }}{{ .Message }}{{ end }}`}</inlineCode>{` block.`}</p>
    <h2>{`Process form input`}</h2>
    <p>{`Now that we've got our basic application structure and templates set up, we can start writing code to (1) check if we have enough valid information to schedule an appointment, and (2) schedule an SMS reminder for the appointment.`}</p>
    <h3>{`Checking appointment information`}</h3>
    <p>{`The first check we need to do—that all the form fields are filled in on submission—is already handled by the `}<inlineCode parentName="p">{`required`}</inlineCode>{` attribute we've added to all our `}<inlineCode parentName="p">{`input`}</inlineCode>{` fields.`}</p>
    <p>{`The other two checks that we need to do are:`}</p>
    <ul>
      <li parentName="ul">{`Checking if the entered phone number is valid.`}</li>
      <li parentName="ul">{`Checking if the appointment is set for a valid date and time.`}</li>
    </ul>
    <h4>{`a. Checking phone number validity`}</h4>
    <p>{`We can use MessageBird's Lookup REST API to check if a phone number is valid.`}</p>
    <p>{`First, we need to add the MessageBird Lookup package to our `}<inlineCode parentName="p">{`import`}</inlineCode>{` statement:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`import (
   "log"
   "net/http"
   "html/template"
   "time"

   "github.com/messagebird/go-rest-api"
   "github.com/messagebird/go-rest-api/lookup"
   )
`}</code></pre>
    <p>{`Then, in `}<inlineCode parentName="p">{`bbScheduler()`}</inlineCode>{`, add this code just under `}<inlineCode parentName="p">{`r.ParseForm()`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`   _, err = lookup.Read(client, r.FormValue("phone"), &lookup.Params{CountryCode: "NL"})
   if err != nil {
       RenderDefaultTemplate(w, "views/booking.gohtml", bookingContainer{ThisBooking, "Please enter a valid phone number."})
       return
   }
`}</code></pre>
    <p>{`Here, we're calling `}<inlineCode parentName="p">{`lookup.Read()`}</inlineCode>{` and passing in the phone number we've gotten through the booking form, and a `}<inlineCode parentName="p">{`CountryCode`}</inlineCode>{` field value that tells MessageBird which locality the phone number should belong to. If MessageBird cannot validate the phone number, `}<inlineCode parentName="p">{`lookup.Read()`}</inlineCode>{` returns an error, which we catch in the following `}<inlineCode parentName="p">{`if err != nil { ... }`}</inlineCode>{` block. We're discarding the resulting `}<inlineCode parentName="p">{`lookup`}</inlineCode>{` object by assigning it to `}<inlineCode parentName="p">{`_`}</inlineCode>{` because we don't need it in our application.`}</p>
    <p>{`Keep in mind that to send a message to a phone number, you must have added that phone number to your MessageBird contact list. Check out our `}<a parentName="p" {...{
        "href": "/api/contacts#create-a-contact"
      }}>{`MessageBird API documentation`}</a>{`to learn how to add a new phone number to your contact list.`}</p>
    <h4>{`b. Checking appointment date and time`}</h4>
    <p>{`Next, we need to add code that checks if the date and time of the booking meets the following criteria:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Not before "now"`}</strong>{`: We're preventing customers from selecting a date before today in our `}<inlineCode parentName="li">{`booking.gohtml`}</inlineCode>{` template, but we also need to check if the time of the appointment is before the current time. We can't do this check in the template because date and time input values are checked separately. For example, adding a `}<inlineCode parentName="li">{`min="15:00"`}</inlineCode>{` attribute value to `}<inlineCode parentName="li">{`<input type="time"/>`}</inlineCode>{` would stop customers from booking appointments before 3 PM on any day, instead of just the current day. Instead, we'll check the date and time together in `}<inlineCode parentName="li">{`bbScheduler()`}</inlineCode>{` when the form is submitted.`}</li>
      <li parentName="ul"><strong parentName="li">{`Within opening hours`}</strong>{`: We don't want our customers to be able to book an appointment after hours, so we'll only allow bookings between 9 AM and 6 PM. If a customer tries to book an appointment after hours, we'll display a message asking them to book their appointment within opening hours.`}</li>
      <li parentName="ul"><strong parentName="li">{`There is at least 3 hours between the current time and the set appointment`}</strong>{`: We want at least 3 hours of advanced notice for each appointment, so that the staff at BeautyBird have the time to prepare for it (and so our application can send that SMS reminder).`}</li>
    </ul>
    <p>{`We'll write a helper function `}<inlineCode parentName="p">{`checkTime()`}</inlineCode>{` to perform these checks. Add this code just under `}<inlineCode parentName="p">{`main()`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`func checkTime(w http.ResponseWriter, bookingTime time.Time, reminderDiff time.Duration, loc *time.Location) string {
   bookingYear := bookingTime.Year()
   bookingMonth := bookingTime.Month()
   bookingDay := bookingTime.Day()

   openingTime := time.Date(bookingYear, bookingMonth, bookingDay, 9, 0, 0, 0, loc)
   closingTime := time.Date(bookingYear, bookingMonth, bookingDay, 18, 0, 0, 0, loc)

   now := time.Now().In(loc)

   timeBeforeBooking := bookingTime.Sub(now)

   switch {
   case bookingTime.Before(now):
       return "Cannot make a booking before now. Please try again!"
   case bookingTime.Before(openingTime):
       return "We're not open yet! Please book your appointment between " + openingTime.Format("03:04 PM") + " and " + closingTime.Format("03:04 PM") + "."
   case bookingTime.After(closingTime):
       return "We're closed! Please book your appointment between " + openingTime.Format("03:04 PM") + " and " + closingTime.Format("03:04 PM") + "."
   case timeBeforeBooking < reminderDiff:
       return "Please book an appointment " + strings.Split(reminderDiff.String(), "h")[0] + " hours in advance."
   default:
       return "Success!"
   }
}
`}</code></pre>
    <p>{`Then, in `}<inlineCode parentName="p">{`bbScheduler()`}</inlineCode>{`, add the following lines of code after `}<inlineCode parentName="p">{`ThisBooking := booking{ ... }`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`var reminderDiff time.Duration

reminderDiff, err = time.ParseDuration("3h")
if err != nil {
   log.Println(err)
}

status := checkTime(w, bookingTime, reminderDiff, loc)
`}</code></pre>
    <p>{`Remember how we defined `}<inlineCode parentName="p">{`bookingTime`}</inlineCode>{` earlier to help populate our `}<inlineCode parentName="p">{`ThisBooking`}</inlineCode>{` struct? We're using it to:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Set a `}<inlineCode parentName="strong">{`reminderDiff`}</inlineCode>{` value`}</strong>{`: To subtract three hours from bookingTime, we need to set a variable of `}<inlineCode parentName="li">{`time.Duration`}</inlineCode>{` type. Here, we set a duration of three hours using the `}<inlineCode parentName="li">{`time.ParseDuration()`}</inlineCode>{` function and assign it to `}<inlineCode parentName="li">{`reminderDiff`}</inlineCode>{`. We then pass `}<inlineCode parentName="li">{`reminderDiff`}</inlineCode>{` into our `}<inlineCode parentName="li">{`checkTime()`}</inlineCode>{` helper function.`}</li>
      <li parentName="ul"><strong parentName="li">{`Check if our three time and date criteria above`}</strong>{`: In `}<inlineCode parentName="li">{`checkTime()`}</inlineCode>{`, we set up a switch statement that checks if `}<inlineCode parentName="li">{`bookingTime`}</inlineCode>{` fulfils various conditions that make the booked time and date invalid. If it meets any one of the conditions, then the switch statement returns a predefined string that we assign to the `}<inlineCode parentName="li">{`status`}</inlineCode>{` variable, and can display as an error message in our `}<inlineCode parentName="li">{`booking.gohtml`}</inlineCode>{` template. If it passes all the conditions, then we return the string `}<inlineCode parentName="li">{`"Success!"`}</inlineCode>{`, which also gets assigned to the `}<inlineCode parentName="li">{`status`}</inlineCode>{` variable.`}</li>
    </ul>
    <h2>{`Checkpoint: What `}<inlineCode parentName="h2">{`bbScheduler()`}</inlineCode>{` should look like now`}</h2>
    <p>{`This is how your `}<inlineCode parentName="p">{`bbScheduler`}</inlineCode>{` handler should look like now:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`func bbScheduler(w http.ResponseWriter, r *http.Request) {
   var (
       loc          *time.Location
       reminderDiff time.Duration
       err          error
   )

   loc, err = time.LoadLocation("Europe/Amsterdam")
   if err != nil {
       log.Println(err)
   }

   reminderDiff, err = time.ParseDuration("3h")
   if err != nil {
       log.Println(err)
   }

   BookingEmpty := booking{
       MinDate: time.Now().In(loc).Format("2006-01-02"),
   }

   if r.Method == "POST" {
       r.ParseForm()

       bookingTime, err := time.ParseInLocation("2006-01-02 15:04", r.FormValue("date")+" "+r.FormValue("time"), loc)
       if err != nil {
           log.Println(err)
       }

       reminderTime := bookingTime.Add(-reminderDiff)

       ThisBooking := booking{
           Name:        r.FormValue("name"),
           Treatment:   r.FormValue("treatment"),
           Phone:       r.FormValue("phone"),
           BookingTime: &bookingTime,
           MinDate:     time.Now().In(loc).Format("2006-01-02"),
       }

       _, err = lookup.Read(client, r.FormValue("phone"), &lookup.Params{CountryCode: "NL"})
       if err != nil {
           RenderDefaultTemplate(w, "views/booking.gohtml", bookingContainer{ThisBooking, "Please enter a valid phone number."})
           return
       }

       status := checkTime(w, bookingTime, reminderDiff, loc)
       if status == "Success!" {
           // =====
           // Display success state and actually schedule an SMS reminder.
           // =====
       } else if status != "" {
           RenderDefaultTemplate(w, "views/booking.gohtml", bookingContainer{ThisBooking, status})
           return
       }
   }
   RenderDefaultTemplate(w, "views/booking.gohtml", bookingContainer{BookingEmpty, ""})
}
`}</code></pre>
    <p>{`We've cleaned up the code a little by grouping some variable assignments, as well added a `}<inlineCode parentName="p">{`BookingEmpty`}</inlineCode>{` struct to send an initial `}<inlineCode parentName="p">{`MinDate`}</inlineCode>{` value to our template when the booking page first loads. We've also set up some logic to display the `}<inlineCode parentName="p">{`status`}</inlineCode>{` returned by our `}<inlineCode parentName="p">{`checkTime()`}</inlineCode>{` call for any other `}<inlineCode parentName="p">{`status`}</inlineCode>{` than `}<inlineCode parentName="p">{`"Success!"`}</inlineCode>{`. All that's left to do is to write the code for our `}<inlineCode parentName="p">{`"Success!"`}</inlineCode>{` state.`}</p>
    <h2>{`Writing the success state and scheduling an SMS reminder`}</h2>
    <p>{`We'll modify this block from the code in the above section:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`if status == "Success!" {
           // =====
           // Display success state and actually schedule an SMS reminder.
           // =====
}
`}</code></pre>
    <p>{`First, we'll write the messages that (1) we should display as a success state on our booking page, and (2) we want to send it out as a scheduled SMS reminder. To the top of the `}<inlineCode parentName="p">{`if status == "Success!" {...}`}</inlineCode>{` block, add:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`successStatus := "Done! We've set up an appointment for you at " + bookingTime.Format("Mon, 02 Jan 2006 3:04 PM") +
   " for " + r.FormValue("treatment") + ". We'll send a reminder to " + r.FormValue("phone") + " at " + reminderTime.Format("Mon, 02 Jan 2006 3:04 PM") + ". Thanks for using BeautyBird!"
reminderMessage := "Gentle reminder: you've got an appointment with BeautyBird at " + bookingTime.Format("Mon, 02 Jan 2006 3:04 PM") + ". See you then!"
`}</code></pre>
    <p>{`Next, we finally get to schedule an SMS reminder.`}</p>
    <p>{`First, add the MessageBird SMS Message package to your `}<inlineCode parentName="p">{`import`}</inlineCode>{` statement:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`import (
   "fmt"
   "html/template"
   "log"
   "net/http"
   "strings"
   "time"

   "github.com/messagebird/go-rest-api"
   "github.com/messagebird/go-rest-api/lookup"
   "github.com/messagebird/go-rest-api/sms"
)
`}</code></pre>
    <p>{`Then, add the following code to the `}<inlineCode parentName="p">{`if status == "Success!" { ...}`}</inlineCode>{` block:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`msg, err := sms.Create(
   client,
   "BeautyBird",
   []string{r.FormValue("phone")},
   reminderMessage,
   &sms.Params{
       ScheduledDatetime: reminderTime,
   },
)
if err != nil {
   log.Println(err)
   RenderDefaultTemplate(w, "views/booking.gohtml", bookingContainer{ThisBooking, fmt.Sprintln(err) + ". Please check your details and try again!"})
   return
}
log.Println(msg)
RenderDefaultTemplate(w, "views/booking.gohtml", bookingContainer{ThisBooking, successStatus})
return
`}</code></pre>
    <p>{`Here, we call `}<inlineCode parentName="p">{`sms.Create()`}</inlineCode>{` with the following parameters:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`client`}</inlineCode>{` is our client object.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`"BeautyBird"`}</inlineCode>{` as our "originator".`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`[]string{r.FormValue("phone")}`}</inlineCode>{` is a list of phone numbers, which we assign the phone number from our submitted form.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`reminderMessage`}</inlineCode>{` as the "body" of our message.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`&sms.Params{...}`}</inlineCode>{` is a list of message parameters, of which we only define the `}<inlineCode parentName="li">{`ScheduledDatetime`}</inlineCode>{` field value.`}</li>
    </ul>
    <p>{`If the call succeeds, we get a `}<inlineCode parentName="p">{`*sms.Message`}</inlineCode>{` object that we save to the `}<inlineCode parentName="p">{`msg`}</inlineCode>{` variable and log for development. You can safely discard this object by replacing `}<inlineCode parentName="p">{`msg`}</inlineCode>{` with `}<inlineCode parentName="p">{`_`}</inlineCode>{` if you don't need to log the `}<inlineCode parentName="p">{`*sms.Message`}</inlineCode>{` object. We then call `}<inlineCode parentName="p">{`RenderDefaultTemplate`}</inlineCode>{`, pass in `}<inlineCode parentName="p">{`successStatus`}</inlineCode>{` in our `}<inlineCode parentName="p">{`bookingContainer`}</inlineCode>{` struct, and tell our application to skip the rest of `}<inlineCode parentName="p">{`bbScheduler()`}</inlineCode>{`.`}</p>
    <p>{`If we encounter an error while creating a new message, we'll log and display the error, and skip the rest of `}<inlineCode parentName="p">{`bbScheduler()`}</inlineCode>{` with:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`if err != nil {
   log.Println(err)
   RenderDefaultTemplate(w, "views/booking.gohtml", bookingContainer{ThisBooking, fmt.Sprintln(err) + ". Please check your details and try again!"})
   return
}
`}</code></pre>
    <h2>{`Testing`}</h2>
    <p>{`You're done! To test your application, navigate to your project folder in the terminal and run:`}</p>
    <pre><code parentName="pre" {...{}}>{`go run main.go
`}</code></pre>
    <p>{`Then, point your browser at http://localhost:8080/ to see the form and schedule your appointment! If you've used a live API key, a message will arrive to your phone three hours before the appointment! But don't actually leave the house, this is just a demo. 😜`}</p>
    <p>{`Awesome! You can now use the flow, code snippets and UI examples from this tutorial as an inspiration to build your own SMS reminder system. Don't forget to download the code from the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/reminders-guide-go"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`.`}</p>
    <p><strong parentName="p">{`Nice work!`}</strong>{` 🎉`}</p>
    <p>{`You now have a running SMS appointment reminder application with MessageBird using Go!`}</p>
    <h2>{`Start building!`}</h2>
    <p>{`Want to start building your solution but not quite sure how to get started? Feel free to let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we'd love to help!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      