import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Setting up your local development environment with MessageBird`}</h1>
    <h3>{`⏱ 10 min read`}</h3>
    <p>{`In this MessageBird Developer Tutorial you’ll first learn how to get started with MessageBird by setting up first your local development environment.`}</p>
    <p>{`MessageBird provides an SDK (Software Development Kit) for Go. This helper library facilitates interactions between your Go programs and the MessageBird API, so you don’t have to craft raw REST API requests.`}</p>
    <h2>{`Install Git`}</h2>
    <p><a parentName="p" {...{
        "href": "https://git-scm.com/"
      }}>{`Git`}</a>{` is a popular source control management system that is used to manage work in code repositories.
The `}<inlineCode parentName="p">{`go get`}</inlineCode>{` command uses Git to pull Go package libraries from online code repositories
(such as Github) so that you can include and run that code in your local projects.`}</p>
    <p>{`To check if you already have git installed, let's run the following command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`git --version
`}</code></pre>
    <p>{`To install Git, you can download and run the installer for your platform from `}<a parentName="p" {...{
        "href": "https://git-scm.com/"
      }}>{`https://git-scm.com/`}</a>{`.`}</p>
    <p>{`Alternatively:`}</p>
    <h3>{`MAC`}</h3>
    <p>{`You can run `}<inlineCode parentName="p">{`brew install git`}</inlineCode>{` to install it using homebrew.`}</p>
    <h3>{`Linux`}</h3>
    <p>{`You can install Git using your distribution's package manager, e.g. `}<inlineCode parentName="p">{`apt-get install git`}</inlineCode>{`.`}</p>
    <h3>{`Windows`}</h3>
    <p>{`We recommend you to use the MSI installation package found on `}<a parentName="p" {...{
        "href": "https://git-scm.com/"
      }}>{`https://git-scm.com/`}</a>{` to install Git.`}</p>
    <h2>{`Check if you have Go installed`}</h2>
    <p>{`To check that you have Go installed, let's open a terminal window and run the following command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`go version
`}</code></pre>
    <p>{`Your terminal should return a version number, like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ go version
go version go1.11 darwin/amd64
`}</code></pre>
    <p>{`If you do not get a version number but something like
`}<inlineCode parentName="p">{`bash: go: command not found`}</inlineCode>{` instead, follow the instructions in the next section to get Go installed.`}</p>
    <h2>{`Install Go`}</h2>
    <h3>{`Mac`}</h3>
    <p>{`You can download the official Go `}<inlineCode parentName="p">{`pkg`}</inlineCode>{` installer from `}<a parentName="p" {...{
        "href": "https://golang.org/"
      }}>{`https://golang.org/`}</a>{` and run it.`}</p>
    <p>{`If you use homebrew, you can also install Go using the `}<inlineCode parentName="p">{`brew`}</inlineCode>{` command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`brew install go
`}</code></pre>
    <h3>{`Linux`}</h3>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Download the tarball archive that matches your Linux system from `}<a parentName="p" {...{
            "href": "https://golang.org/"
          }}>{`https://golang.org/`}</a>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Unpack the tarball. Run:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`# Replace $VERSION and $OS-$ARCH with what appears in the downloaded archive file name.
tar -C /usr/local -xzf go$VERSION.$OS-$ARCH.tar.gz
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Add Go's `}<inlineCode parentName="p">{`bin`}</inlineCode>{` directory to your `}<inlineCode parentName="p">{`PATH`}</inlineCode>{` environment variable by adding the following to `}<inlineCode parentName="p">{`~/.profile`}</inlineCode>{`:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`export PATH=$PATH:/usr/local/go/bin
`}</code></pre>
      </li>
    </ol>
    <p><strong parentName="p">{`Keep in mind`}</strong>{`: Go may be available on your distribution's package manager, but we recommend downloading and installing Go from the official source on `}<a parentName="p" {...{
        "href": "https://dl.google.com/go/go1.11.linux-amd64.tar.gz"
      }}>{`https://golang.org`}</a></p>
    <h3>{`Windows`}</h3>
    <p>{`You can download and run the official Go `}<inlineCode parentName="p">{`MSI`}</inlineCode>{` installer from `}<a parentName="p" {...{
        "href": "https://golang.org/"
      }}>{`https://golang.org/`}</a>{`.`}</p>
    <h2>{`Configure your Go development environment`}</h2>
    <p>{`Go needs you to set a specific location on your computer as your Go workspace.
This is set as the `}<inlineCode parentName="p">{`GOPATH`}</inlineCode>{` environment variable, and is used by Go as the
designated location for source code for all Go projects and dependencies for this computer.
Each computer or development environment usually needs only one `}<inlineCode parentName="p">{`GOPATH`}</inlineCode>{`.`}</p>
    <p>{`In this tutorial, we'll assume that you'll want to set your `}<inlineCode parentName="p">{`GOPATH`}</inlineCode>{` to:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`/go`}</inlineCode>{` for Linux and Mac`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`C:\\go`}</inlineCode>{` for Windows`}</li>
    </ul>
    <p>{`All source files are stored in the `}<inlineCode parentName="p">{`GOPATH/src`}</inlineCode>{` directory. You may want to create a folder in the `}<inlineCode parentName="p">{`src`}</inlineCode>{` directory to store your work, like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`/go/src/github.com/FeatheredUserName
`}</code></pre>
    <p>{`Using your Github username as your Go workspace makes it convenient for working with other Go packages you've written and want to import into your current project.`}</p>
    <h3>{`Set `}<inlineCode parentName="h3">{`GOPATH`}</inlineCode>{` on Mac or Linux`}</h3>
    <p>{`To check if your `}<inlineCode parentName="p">{`GOPATH`}</inlineCode>{` is already set up, run:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`go env GOPATH
`}</code></pre>
    <p>{`If the terminal displays a path, that directory is your `}<inlineCode parentName="p">{`GOPATH`}</inlineCode>{` root. Otherwise, add the following lines of code to your `}<inlineCode parentName="p">{`~/.profile`}</inlineCode>{`, or run them in the terminal:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`# Sets $GOPATH to /go
export GOPATH=/go

# We'll also add the bin directory in your GOPATH
# to your PATH environment variable
export PATH=$PATH:$(go env GOPATH)/bin
`}</code></pre>
    <h3>{`Set `}<inlineCode parentName="h3">{`GOPATH`}</inlineCode>{` on Windows`}</h3>
    <p>{`To check if your `}<inlineCode parentName="p">{`GOPATH`}</inlineCode>{` is already set up, run in the command prompt:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-cmd"
      }}>{`go env GOPATH
`}</code></pre>
    <p>{`If the command prompt displays a path, that directory is your `}<inlineCode parentName="p">{`GOPATH`}</inlineCode>{` root. Otherwise, run the following in the command prompt:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-cmd"
      }}>{`setx GOPATH "C:\\Go"
`}</code></pre>
    <h3>{`Set up Go Modules (optional)`}</h3>
    <p>{`Go modules are a recent addition to the language, and allow you to keep track of your project's dependencies and their versions. To use Go modules in your project:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Make sure that your project's source files are stored in `}<inlineCode parentName="p">{`GOPATH/src`}</inlineCode>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Flick the switch on the GO111MODULE environment variable. Run in the terminal:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`# For Linux and Mac
export GO111MODULE=on

# For Windows
setx GO111MODULE=on
`}</code></pre>
        <p parentName="li"><em parentName="p">{`Note`}</em>{`: All Go packages located outside of `}<inlineCode parentName="p">{`GOPATH/src`}</inlineCode>{` will not compile once `}<inlineCode parentName="p">{`GO111MODULE`}</inlineCode>{` is set to `}<inlineCode parentName="p">{`on`}</inlineCode>{`. Make sure that all your project files are in `}<inlineCode parentName="p">{`GOPATH/src`}</inlineCode>{` once you enable Go modules.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`In your project directory, run:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`go mod init
`}</code></pre>
        <p parentName="li">{`This produces a `}<inlineCode parentName="p">{`go.mod`}</inlineCode>{` file that lists (1) the path for your current project repository, and (2) the dependencies required by this project.`}</p>
      </li>
    </ol>
    <p>{`If you run `}<inlineCode parentName="p">{`go mod init`}</inlineCode>{` in a new project, you'll find no dependencies are listed in your resulting `}<inlineCode parentName="p">{`go.mod`}</inlineCode>{` file. This file will be automatically updated with your project's dependencies when you build your project.`}</p>
    <h2>{`Installing the MessageBird SDK`}</h2>
    <p>{`Once you've got your Go development environment set up, run the following command to install the MessageBird SDK for Go:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`go get github.com/messagebird/go-rest-api
`}</code></pre>
    <p>{`The SDK is open source and you can `}<a parentName="p" {...{
        "href": "https://github.com/messagebird/go-rest-api"
      }}>{`browse the code in this GitHub
repository`}</a>{`.`}</p>
    <h2>{`Upgrading the MessageBird SDK for Go`}</h2>
    <p>{`Once in a while, you'll want to check if your project is using the latest version of the MessageBird SDK. To update the MessageBird SDK for Go on your development environment, run:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`go get -u github.com/messagebird/go-rest-api
`}</code></pre>
    <p><strong parentName="p">{`Nice work!`}</strong>{` 🎉`}</p>
    <p>{`Your Go development environment and project directory are ready now!`}</p>
    <h3>{`Start building!`}</h3>
    <p>{`Let's head over to the next `}<strong parentName="p">{`MessageBird Developer Tutorial`}</strong>{` and
`}<a parentName="p" {...{
        "href": "/tutorials/send-sms-node"
      }}>{`learn how to send your first SMS using
Go`}</a>{`.`}</p>
    <p>{`Want to start building your solution but not quite sure how to get started? Feel free to let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we'd love to help!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      