export const TTS_URI = {
  bash: `https://rest.messagebird.com/voicemessages`,
};

export const TTS_METHODS = {
  bash: `POST /voicemessages
GET /voicemessages
GET /voicemessages/{voiceMessageId}
DELETE /voicemessages/{voiceMessageId}`,
};

export const TTS_OBJECT = {
  json: `{
  "id":"e8077d803532c0b5937c639b60216938",
  "href":"https://rest.messagebird.com/voicemessages/e8077d803532c0b5937c639b60216938",
  "originator":null,
  "body":"The voice message to be sent",
  "reference":"the-client-reference",
  "language":"en-gb",
  "voice":"female",
  "repeat":1,
  "ifMachine":"continue",
  "machineTimeout":7000,
  "scheduledDatetime":null,
  "createdDatetime":"2016-05-03T14:26:57+00:00",
  "recipients":{
    "totalCount":1,
    "totalSentCount":1,
    "totalDeliveredCount":0,
    "totalDeliveryFailedCount":0,
    "items":[
      {
        "recipient":31612345678,
        "status":"answered",
        "statusDatetime":"2016-05-03T14:26:57+00:00"
      }
    ]
  }
}`,
};

export const TTS_BODY1 = {
  bash: `Hello, your login token is 1, 8, 3, 4, 0`,
};

export const TTS_BODY2 = {
  bash: `Hello, your login token is<break time="1s"/>1<break time="500ms"/>8<break time="500ms"/>3<break time="500ms"/>4<break time="500ms"/>0`,
};

export const TTS_BODY3 = {
  bash: `Your login token is <prosody rate="-10%">1, 8, 3, 4, 0</prosody>`,
};

export const SENDTTS_REQUEST = {
  curl: `curl -X POST https://rest.messagebird.com/voicemessages \\
   -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
   -d "recipients=31612345678" \\
   -d "body=The voice message to be sent"`,
  php: `$MessageBird = new \\MessageBird\\Client('ACCESS_KEY');

$VoiceMessage = new \\MessageBird\\Objects\\VoiceMessage();
$VoiceMessage->recipients = array(31612345678);
$VoiceMessage->originator = 'MessageBird';
$VoiceMessage->body = 'This is a test message.';
$VoiceMessage->language = 'en-gb';
$VoiceMessage->voice = 'male';

$VoiceMessageResult = $MessageBird->voicemessages->create($VoiceMessage);
var_dump($VoiceMessageResult);`,
  ruby: `client = MessageBird::Client.new(YOUR_ACCESS_KEY)
vmsg = client.voice_message_create('31612345678', 'Hello World', :reference => 'Foobar')`,
  csharp: `const string YourAccessKey = "YOUR_ACCESS_KEY";
const long Msisdn = 31612345678;

Client client = Client.CreateDefault(YourAccessKey);

MessageBird.Objects.VoiceMessage voiceMessage = client.SendVoiceMessage("Hello world", new[] { Msisdn }, {});`,
  go: `// The optional parameters.
params := &voicemessage.Params{Reference: "MyReference"}

message, err := voicemessage.Create(
  client,
  []string{"31612345678"},
  "Hello World",
  params,
)`,
  python: `client = messagebird.Client(ACCESS_KEY)
voice_message = client.voice_message_create('31612345678', 'This is a test message', { 'language' : 'en-gb', 'voice': 'male' })`,
  java: `import com.messagebird.MessageBirdClient;
import com.messagebird.MessageBirdService;
import com.messagebird.MessageBirdServiceImpl;
import com.messagebird.exceptions.GeneralException;
import com.messagebird.exceptions.UnauthorizedException;
import com.messagebird.objects.IfMachineType;
import com.messagebird.objects.VoiceType;
import com.messagebird.objects.VoiceMessage;
import com.messagebird.objects.VoiceMessageResponse;

import java.math.BigInteger;
import java.util.ArrayList;
import java.util.List;

public class ExampleSendVoiceMessage {
    public static void main(String[] args) {
        if (args.length < 3) {
            System.out.println("Please specify your access key, one ore more phone numbers and a message body example : java -jar <this jar file≶ test_accesskey 31612345678,3161112233 \\"My message to be send\\"");
            return;
        }

        // First create your service object
        final MessageBirdService wsr = new MessageBirdServiceImpl(args[0]);

        // Add the service to the client
        final MessageBirdClient messageBirdClient = new MessageBirdClient(wsr);

        try {
            // Get Hlr using msgId and msisdn
            System.out.println("Sending message:");
            final List<BigInteger≶ phones = new ArrayList<BigInteger≶();
            for (final String phoneNumber : args[1].split(",")) {
                phones.add(new BigInteger(phoneNumber));
            }

            // Send a voice message using the VoiceMessage object
            final VoiceMessage vm = new VoiceMessage(args[2], phones);
            vm.setIfMachine(IfMachineType.hangup);
            vm.setVoice(VoiceType.male);
            final VoiceMessageResponse response = messageBirdClient.sendVoiceMessage(vm);
            System.out.println(response.toString());

        } catch (UnauthorizedException unauthorized) {
            if (unauthorized.getErrors() != null) {
                System.out.println(unauthorized.getErrors().toString());
            }
            unauthorized.printStackTrace();
        } catch (GeneralException generalException) {
            if (generalException.getErrors() != null) {
                System.out.println(generalException.getErrors().toString());
            }
            generalException.printStackTrace();
        }
    }
}`,
  node: `var messagebird = require('messagebird')('YOUR_ACCESS_KEY');

var params = {
  'recipients': [
    '31612345678'
  ],
  'body': 'This is a test message.',
  'language': 'en-gb',
  'voice': 'male'
};

messagebird.voice_messages.create(params, function (err, data) {
  if (err) {
    return console.log(err);
  }
  console.log(data);
});`,
};

export const SENDTTS_RESPONSE = {
  json: `{
  "id":"e8077d803532c0b5937c639b60216938",
  "href":"https://rest.messagebird.com/voicemessages/e8077d803532c0b5937c639b60216938",
  "originator":null,
  "body":"The voice message to be sent",
  "reference":"the-client-reference",
  "language":"en-gb",
  "voice":"female",
  "repeat":1,
  "ifMachine":"continue",
  "machineTimeout":7000,
  "scheduledDatetime":null,
  "createdDatetime":"2016-05-03T14:26:57+00:00",
  "recipients":{
    "totalCount":1,
    "totalSentCount":1,
    "totalDeliveredCount":0,
    "totalDeliveryFailedCount":0,
    "items":[
      {
        "recipient":31612345678,
        "status":"calling",
        "statusDatetime":"2016-05-03T14:26:57+00:00"
      }
    ]
  }
}`,
};

export const VIEWTTS_DEF = {
  bash: `GET https://rest.messagebird.com/voicemessages/{voiceMessageID}`,
};

export const VIEWTTS_REQUEST = {
  curl: `curl -X GET https://rest.messagebird.com/voicemessages/e8077d803532c0b5937c639b60216938 \\
   -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM'`,
  php: `$MessageBird = new \\MessageBird\\Client('ACCESS_KEY');
$VoiceMessageResult = $MessageBird->voicemessages->read('e60a6f90453a19019c56ed6b20170831');
var_dump($VoiceMessageResult);`,
  ruby: `client = MessageBird::Client.new(YOUR_ACCESS_KEY)
client.voice_message('211e6280453ba746e8eeff7b12582146')`,
  csharp: `const string YourAccessKey = "YOUR_ACCESS_KEY";
const string MessageId = "211e6280453ba746e8eeff7b12582146";

Client client = Client.CreateDefault(YourAccessKey);
MessageBird.Objects.VoiceMessage voiceMessage = client.ViewVoiceMessage(MessageId);`,
  go: `voicemessage, err := voicemessage.Read(
  client,
  "211e6280453ba746e8eeff7b12582146",
)`,
  python: `client = messagebird.Client(ACCESS_KEY)
voice_message = client.voice_message('e60a6f90453a19019c56ed6b20170831')`,
  java: `import com.messagebird.MessageBirdClient;
import com.messagebird.MessageBirdService;
import com.messagebird.MessageBirdServiceImpl;
import com.messagebird.exceptions.NotFoundException;
import com.messagebird.objects.VoiceMessageResponse;
import com.messagebird.exceptions.GeneralException;
import com.messagebird.exceptions.UnauthorizedException;

public class ExampleViewVoiceMessage {
  public static void main(String[] args) {
      if (args.length < 2) {
          System.out.println("Please specify your access key and a voice message ID : java -jar  test_accesskey e8077d803532c0b5937c639b60216938");
          return;
      }

      // First create your service object
      final MessageBirdService wsr = new MessageBirdServiceImpl(args[0]);

      // Add the service to the client
      final MessageBirdClient messageBirdClient = new MessageBirdClient(wsr);

      try {
          // Get Hlr using msgId and msisdn
          System.out.println("Getting message info message:");
          final VoiceMessageResponse response = messageBirdClient.viewVoiceMessage(args[1]);
          System.out.println(response.toString());


      } catch (UnauthorizedException unauthorized) {
          if (unauthorized.getErrors() != null) {
              System.out.println(unauthorized.getErrors().toString());
          }
          unauthorized.printStackTrace();
      } catch (GeneralException generalException) {
          if (generalException.getErrors() != null) {
              System.out.println(generalException.getErrors().toString());
          }
          generalException.printStackTrace();
      } catch (NotFoundException notFoundException) {
          if (notFoundException.getErrors() !=null) {
              System.out.println(notFoundException.getErrors().toString());
          }
          notFoundException.printStackTrace();
      }
  }
}`,
  node: `var messagebird = require('messagebird')('YOUR_ACCESS_KEY');

messagebird.voice_messages.read('e60a6f90453a19019c56ed6b20170831', function (err, data) {
  if (err) {
    return console.log(err);
  }
  console.log(data);
});`,
};

export const VIEWTTS_RESPONSE = {
  json: `{
  "id":"e8077d803532c0b5937c639b60216938",
  "href":"https://rest.messagebird.com/voicemessages/e8077d803532c0b5937c639b60216938",
  "originator":null,
  "body":"The voice message to be sent",
  "reference":"the-client-reference",
  "language":"en-gb",
  "voice":"female",
  "repeat":1,
  "ifMachine":"continue",
  "machineTimeout":7000,
  "scheduledDatetime":null,
  "createdDatetime":"2016-05-03T14:26:57+00:00",
  "recipients":{
    "totalCount":1,
    "totalSentCount":1,
    "totalDeliveredCount":0,
    "totalDeliveryFailedCount":0,
    "items":[
      {
        "recipient":31612345678,
        "status":"answered",
        "statusDatetime":"2016-05-03T14:26:57+00:00",
        "recipientCountry":"Netherlands",
        "recipientCountryPrefix":31,
        "recipientOperator":"",
        "messageLength":28,
        "statusReason":"successfully delivered",
        "price":{
          "amount":0.055,
          "currency":"EUR"
        },
        "mccmnc":"20408",
        "mcc":"204",
        "mnc":"08"
      }
    ]
  }
}`,
};

export const LISTTTS_REQUEST = {
  curl: `curl -X GET https://rest.messagebird.com/voicemessages/?offset=100&limit=30 \\
   -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM'`,
  php: `$MessageBird = new \\MessageBird\\Client('ACCESS_KEY');
$VoiceMessageList = $MessageBird->voicemessages->getList(array('offset' => 100, 'limit' => 30));
var_dump($VoiceMessageList);`,
  go: `client := messagebird.New('ACCESS_KEY')
vmsgList, _ := voicemessage.List(client)
fmt.Println(vmsgList)`,
  csharp: `const string YourAccessKey = "YOUR_ACCESS_KEY";

Client client = Client.CreateDefault(YourAccessKey);
MessageBird.Objects.VoiceMessageList voiceMessageList = client.ListVoiceMessages(<LIMIT>, <OFFSET>);`,
  java: `import com.messagebird.MessageBirdClient;
import com.messagebird.MessageBirdService;
import com.messagebird.MessageBirdServiceImpl;
import com.messagebird.exceptions.GeneralException;
import com.messagebird.exceptions.UnauthorizedException;
import com.messagebird.objects.VoiceMessageList;

public class ExampleListVoiceMessages {
    public static void main(String[] args) {

        if (args.length == 0) {
            System.out.println("Please specify your access key example : java -jar <this jar file> test_accesskey");
            return;
        }

        // First create your service object
        final MessageBirdService wsr = new MessageBirdServiceImpl(args[0]);

        // Add the service to the client
        final MessageBirdClient messageBirdClient = new MessageBirdClient(wsr);

        try {
            System.out.println("Retrieving message list");
            final VoiceMessageList messageList = messageBirdClient.listVoiceMessages(2, 10);

            System.out.println(messageList.toString());

        } catch (UnauthorizedException | GeneralException exception) {
            if (exception.getErrors() != null) {
                System.out.println(exception.getErrors().toString());
            }
            exception.printStackTrace();
        }
    }
}`,
  python: `client = messagebird.Client(ACCESS_KEY)
voice_message = client.voice_message_list(limit=10, offset=0)`,
};

export const LISTTTS_RESPONSE = {
  json: `{
  "offset": 0,
  "limit": 30,
  "count": 2,
  "totalCount": 2,
  "links": {
    "first": "https://rest.messagebird.com/voicemessages/?offset=0&limit=30&contact_id=65",
    "previous": null,
    "next": null,
    "last": "https://rest.messagebird.com/voicemessages/?offset=0&limit=30&contact_id=65"
  },
  "items": [
    {
      "id":"e8077d803532c0b5937c639b60216938",
      "href":"https://rest.messagebird.com/voicemessages/e8077d803532c0b5937c639b60216938",
      "originator":null,
      "body":"The voice message to be sent",
      "reference":"the-client-reference",
      "language":"en-gb",
      "voice":"female",
      "repeat":1,
      "ifMachine":"continue",
      "machineTimeout":7000,
      "scheduledDatetime":null,
      "createdDatetime":"2016-05-03T14:26:57+00:00",
      "recipients":{
        "totalCount":1,
        "totalSentCount":1,
        "totalDeliveredCount":0,
        "totalDeliveryFailedCount":0,
        "items":[
          {
            "recipient":31612345678,
            "status":"calling",
            "statusDatetime":"2016-05-03T14:26:57+00:00"
          }
        ]
      }
    },
  ]
}`,
};

export const STATUSTTS_REQUEST = {
  bash: `GET http://your-own.url/script?id=e8077d803532c0b5937c639b60216938&reference=YouReference123&recipient=31612345678&status=delivered&statusDatetime=2016-04-13T07:27:49+00:00`,
};

export const STATUSTTS_RESPONSE = {
  bash: `200 OK`,
};
