/* eslint-disable no-console */
export function track(id, data) {
  if (
    typeof window !== 'undefined' &&
    window.analytics &&
    process.env === 'production'
  ) {
    window.analytics.track(id, data);
  } else {
    console.log(
      '%cAnalytics Track', // jshint ignore:line
      'color: #0a0; padding: 3px; display: block; background: #beb; font-size: 90%;',
      id,
      data,
    );
  }
}

export function identify(data) {
  if (
    typeof window !== 'undefined' &&
    window.analytics &&
    process.env === 'production'
  ) {
    window.analytics.identify(data);
  } else {
    console.log(
      '%cAnalytics Identify', // jshint ignore:line
      'color: #0a0; padding: 3px; display: block; background: #beb; font-size: 90%;',
      data,
    );
  }
}

export function gtagEvent(type, sendTo, opts) {
  if (
    typeof window !== 'undefined' &&
    window.gtag &&
    process.env === 'production'
  ) {
    window.gtag('event', type, { send_to: sendTo, ...opts });
  } else {
    console.log(
      '%cGoogle Tag Manager', // jshint ignore:line
      'color: #0a0; padding: 3px; display: block; background: #beb; font-size: 90%;',
      type,
      sendTo,
      opts,
    );
  }
}
