import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import Notification from 'components/Notification';
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Sending messages`}</h1>
    <p>{`In this Programmable Conversations API Quickstart, you'll learn how to send a new outbound message to a channel-specific user identifier, that is, a phone number for the SMS channel with the Programmable Conversations API.`}</p>
    <p>{`Programmable Conversations allows you to send messages to users over any supported communication platform such as SMS, WhatsApp, Live Chat, WeChat, Messenger, Telegram, LINE, Instagram, Email, Google Business Messages, Twitter, and Viber. `}</p>
    <h2>{`Step 1: Set up your test environment`}</h2>
    <p>{`To start testing the Programmable Conversations API using NodeJS, you need to first set up your NodeJS test environment.`}</p>
    <p><a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/login"
      }}>{`Log in to the MessageBird Connectivity Platform`}</a>{` and collect your live API key from the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`Developer Dashboard`}</a>{`.`}</p>
    <p>{`The following command will install the MessageBird SDK:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install messagebird
`}</code></pre>
    <p>{`Now, let's create a `}<inlineCode parentName="p">{`send_sms.js`}</inlineCode>{` file in the directory in which you installed the SDK.`}</p>
    <Notification type="INFO" mdxType="Notification">
Want to learn how to set up your NodeJS development environment? Check out the <a href="tutorials/setup-local-dev-environment-node">NodeJS tutorial</a>
    </Notification>
    <h2>{`Step 2: Send your first message`}</h2>
    <p>{`Copy the code snippet below into your `}<inlineCode parentName="p">{`send_sms.js`}</inlineCode>{` file and make the following changes.`}</p>
    <p>{`To set `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`your credentials`}</a>{` in the code snippet below, replace `}<inlineCode parentName="p">{`YOUR-API-KEY`}</inlineCode>{` with your API key.`}</p>
    <p>{`Replace your mobile number as `}<inlineCode parentName="p">{`to`}</inlineCode>{`, and the channel `}<inlineCode parentName="p">{`ID`}</inlineCode>{` (that identifies the channel over which the message should be sent) as `}<inlineCode parentName="p">{`from`}</inlineCode>{`. You can find your channel IDs or create new channels in the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/channels"
      }}>{`Channels Directory`}</a>{` in your MessageBird Dashboard.`}</p>
    <p>{`You may also want to add `}<inlineCode parentName="p">{`reportUrl`}</inlineCode>{` to deliver status reports to your platform through a `}<inlineCode parentName="p">{`POST`}</inlineCode>{` request to a specific URL. If you don't need reports, remove the parameter from the request.`}</p>
    <p>{`Once you’ve entered all your credentials, go ahead and copy the above snippet to your terminal and hit enter!`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var messagebird = require('messagebird')('YOUR-API-KEY');

var params = {
  'to': '+31XXXXXXXXXX',
  'from': 'CHANNEL-ID',
  'type': 'text',
  'content': {
    'text': 'Hello!'
  },
  'reportUrl': 'https://your-domain.com/reports’
};

messagebird.conversations.send(params, function (err, response) {
  if (err) {
    return console.log(err);
  }
  console.log(response);
});
`}</code></pre>
    <Notification type="INFO" mdxType="Notification">
You can access and manage your credentials in the <a href="https://dashboard.messagebird.com/en/developers/access">MessageBird Developer Dashboard</a>.
    </Notification>
    <p>{`Then, save the file and run the following command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`node send_sms.js
`}</code></pre>
    <h2>{`Step 3: View the response`}</h2>
    <p>{`Have a look at MessageBird’s HTTP response to your API call. If the set up was correct, you'll receive a response similar to this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`{
    id: ‘ec7caddaaf50e694e39ffed1f2c4cdd2’,
    status: ‘accepted’,
    fallback: null
}
`}</code></pre>
    <p>{`If something went wrong, you’ll receive an error response from the MessageBird API. Don't worry, simply head over to `}<a parentName="p" {...{
        "href": "/docs/conversations/troubleshooting"
      }}>{`Conversations API Troubleshooting`}</a>{` to understand the error.`}</p>
    <p>{`Nice work! 🎉  You can now send outbound messages with Programmable Conversations.`}</p>
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'Programmatically handle inbound messages',
      to: '/quickstarts/conversations/handle-inbound-messages-node'
    }, {
      name: 'Programmatically reply to inbound messages',
      to: '/quickstarts/conversations/reply-to-messages-node'
    }, {
      name: 'Programmable Conversations API Reference',
      to: '/api/conversations'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      