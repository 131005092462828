import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Recording Inbound Voice Messages with MessageBird`}</h1>
    <p>{`The MessageBird API provides an easy way to record an inbound or outbound leg as part of the execution of a `}<a parentName="p" {...{
        "href": "/api/voice-calling#call-flows"
      }}>{`call flow`}</a>{`. After a call ends, a recording can be downloaded as a wav file—keep in mind that MessageBird hosts all recordings for 90 days before automatically deleting them.`}</p>
    <p>{`In this MessageBird Tutorial learn how to easily record an inbound or outbound leg in your call flow with the MessageBird API. We’ll show you specifically the steps to record an inbound call to a number that you subscribed to MessageBird; an example of a use case would be a cloud-based voice mail service.`}</p>
    <h2>{`Step 1: Create the call flow`}</h2>
    <p>{`To initiate a call recording with MessageBird Voice API, you need to post a call flow with an appropriate record step.
The endpoint for creating call flows via the Voice API is:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`POST https://voice.messagebird.com/call-flows
`}</code></pre>
    <p>{`Here’s an example with cURL:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`curl -X "POST" "http://voice.messagebird.com/call-flows" \\
     -H "Authorization: AccessKey :your_access_key:" \\
     -H "Content-Type: application/json; charset=utf-8" \\
     -d $'{
  "steps": [
    {
      "action": "record",
    }
  ]
}'
`}</code></pre>
    <p>{`In the example below, a simple record step will be executed.`}</p>
    <h2>{`Step 2: Assign a number to the call flow`}</h2>
    <p>{`To have the call flow executed when a number you own is called, you first need to assign that number to the call flow. The endpoint to do this is:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`POST https://voice.messagebird.com/call-flows/:call_flow_id:/numbers
`}</code></pre>
    <p>{`The following is an example with cURL:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`curl -X "POST" "http://voice.messagebird.com/call-flows/:call_flow_id/numbers" \\
     -H "Authorization: AccessKey :your_access_key:" \\
     -d $'{
  "numbers": ["31612345678"]
}'
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`call_flow_id`}</inlineCode>{` parameter is the ID of the call flow created in step 1.`}</p>
    <p>{`The string value in the `}<inlineCode parentName="p">{`numbers`}</inlineCode>{` array is the E.164 formatted number you want to assign. Keep in mind that this must be a number you previously purchased. Buying a MessageBird number is quite easy; in `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/articles/208011689-Buy-a-Number-"
      }}>{`this Help Center article`}</a>{` we explain to you how to do it.`}</p>
    <p>{`Awesome! After making this request, the call flow will be executed for every incoming call to the number.`}</p>
    <h2>{`Step 3: Dial the number, record your voice.`}</h2>
    <p>{`After setting the call flow, dial your number. The recording will begin when the call is answered. Speak as you normally would on a call and long enough, so there’s sufficient voice audio to transcribe. Hang up once you finish; the call recording will then stop and be saved.`}</p>
    <h2>{`Step 4: Get the information about the call / leg and recording`}</h2>
    <h3>{`1. GET THE CALLS.`}</h3>
    <p>{`By making a request to the `}<inlineCode parentName="p">{`/calls`}</inlineCode>{` endpoint, you can get a list of every call for your account; from there, you can extract the id of the call you want to retrieve leg recordings from. An example request for a list of your calls with cURL would like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`curl "https://voice.messagebird.com/calls" \\
   -H "Authorization: AccessKey :your_access_key:"
`}</code></pre>
    <p>{`Here’s an example result to a request like that:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "data": [
        {
          "id": ":callID",
          "status": "ended",
          "source": "31644556677",
          "destination": "31612345678",
          "numberId": "6e143426-d7ea-11e6-bf26-cec0c932ce01",
          "createdAt": "2017-06-21T12:42:25Z",
          "updatedAt": "2017-06-21T12:42:36Z",
          "endedAt": "2017-06-21T12:42:36Z",
          "_links": {
            "legs": "/calls/:callID/legs",
            "self": "/calls/:callID"
          }
        },
    ],
      "_links": {
        "self": "/calls?page=1"
      },
      "pagination": {
        "totalCount": 1,
        "pageCount": 1,
        "currentPage": 1,
        "perPage": 10
      }
    }
}
`}</code></pre>
    <h3>{`2. GET THE LEGS FROM THE CALL.`}</h3>
    <p>{`With the call info, you can get leg information by making a request to /legs. An example request would be structured like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`## List Legs
curl "https://voice.messagebird.com/calls/:callID/legs" \\
     -H "Authorization: AccessKey :your_access_key:"
`}</code></pre>
    <p>{`The leg information can be found in a result similar to this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "data": [
    {
      "id": ":legId",
      "callId": ":callId",
      "source": "31123456789",
      "destination": "31123456777",
      "status": "hangup",
      "direction": "outgoing",
      "duration": 31,
      "cost": 0.000385,
      "currency": "USD",
      "createdAt": "2017-02-16T10:52:00Z",
      "updatedAt": "2017-02-16T10:52:30Z",
      "answeredAt": "2017-02-16T10:52:30Z",
      "endedAt": "2017-02-16T10:52:30Z",
      "_links": {
        "self": "/calls/:callId/legs/:legId"
      }
    },
    {
      "id": ":legId",
      "callId": ":callId",
      "source": "31123456789",
      "destination": "31123456788",
      "status": "hangup",
      "direction": "incoming",
      "duration": 31,
      "cost": 0.000385,
      "currency": "USD",
      "createdAt": "2017-02-16T10:52:00Z",
      "updatedAt": "2017-02-16T10:52:30Z",
      "answeredAt": "2017-02-16T10:52:30Z",
      "endedAt": "2017-02-16T10:52:30Z",
      "_links": {
        "self": "/calls/:callId/legs/:legId"
      }
    }
  ],
  "_links": {
    "self": "/calls/:callId/legs?page=1"
  },
  "pagination": {
    "totalCount": 2,
    "pageCount": 1,
    "currentPage": 1,
    "perPage": 10
  }
}
`}</code></pre>
    <p>{`Keep in mind that the data field is an array of legs. For our call flow example with the single record step, the API will return only one leg.`}</p>
    <h3>{`3. GET THE RECORDINGS FOR THE LEG.`}</h3>
    <p>{`After you have the leg ids, you can get the recording by making a request as such:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`#curl "https://voice.messagebird.com/calls/:callID/legs/:legID/recordings"
    -H "Authorization: AccessKey :your_access_key:"
`}</code></pre>
    <p>{`An example result of a recording request will be structured like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "data": [
    {
      "id": ":recordingID:",
      "format": "wav",
      "legId": ":legID:",
      "status": "done",
      "duration": 7,
      "createdAt": "2017-05-17T11:42:57Z",
      "updatedAt": "2017-05-17T11:43:04Z"
    }
  ],
  "_links": {
    "self": "/recordings/:recordingID:",
    "file": "/recordings/:recordingID:.wav"
  }
}
`}</code></pre>
    <p>{`Among other information, this result provides the file key which holds the URI for downloading the recording file. To download the recording, you need to do a request to that URI:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`https://voice.messagebird.com/recordings/:recordingID.wav
`}</code></pre>
    <p>{`A cURL example for this would be:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`curl "https://voice.messagebird.com/recordings/:recordingID.wav" \\
     -H "Authorization: AccessKey :your_access_key:"
`}</code></pre>
    <p>{`Great! This request will download a wav file that holds the recording of that leg to your system.`}</p>
    <p><strong parentName="p">{`Nice work!`}</strong>{` 🎉`}</p>
    <p>{`You just learned how to retrieve information about calls!`}</p>
    <h2>{`Start building!`}</h2>
    <p>{`Want to build something similar but not quite sure how to get started? Feel free to let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we'd love to help!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      