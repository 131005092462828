import BaseLayout from './Base';
import React, { useEffect } from 'react';
import { Link, navigate } from 'gatsby';
import { Flex, Box } from '@rebass/grid';
import { Badge } from '@messagebird/badge';
import { Text, Card, CardTitle } from '../../components/shared';
import { track } from '../../helpers/tracking';
import styled from 'styled-components';
import { HomePageFooter } from '../Footer/HomePageFooter/HomePageFooter.js';
import MobileNavigation from '../MobileNavigation';
import { COLOR } from '../../constants';

const ALink = ({ to, children, newTab, ...props }) => {
  const openInNewTab = newTab
    ? { target: '_blank', rel: 'noopener noreferrer' }
    : {};

  if (to.includes('http')) {
    return (
      <a href={to} {...props} {...openInNewTab}>
        {children}
      </a>
    );
  }
  return (
    <Link to={to} {...props} {...openInNewTab}>
      {children}
    </Link>
  );
};

const SDKLink = {
  linkTracking: 'sdk',
  label: 'SDKs',
  link: 'https://github.com/messagebird',
  newTab: true,
};
const TutorialsLink = (product) => ({
  label: 'Tutorials',
  linkTracking: 'tutorial',
  link: '/tutorials?products=' + product,
});
const ApiReferenceLink = (url) => ({
  label: 'API Reference',
  linkTracking: 'api',
  link: `/api/${url}`,
});
const QuickStartLink = (url) => ({
  linkTracking: 'quickstarts',
  label: 'Quickstarts',
  link: `/quickstarts/${url}`,
});

const products = [
  {
    trackTag: 'sms',
    title: 'SMS',
    desc: 'Send and receive text messages from your app, product, or website.',
    links: [
      QuickStartLink('sms-overview/'),
      ApiReferenceLink('sms-messaging/'),
      SDKLink,
      TutorialsLink('sms'),
    ],
  },
  {
    trackTag: 'whatsapp',
    title: 'WhatsApp',
    desc: 'Add all WhatsApp Business features through a single API.',
    links: [
      QuickStartLink('whatsapp-overview/'),
      ApiReferenceLink('whatsapp'),
      SDKLink,
      {
        label: 'Sandbox',
        link: 'https://dashboard.messagebird.com/en/whatsapp/sandbox',
        newTab: true,
      },
    ],
  },
  {
    trackTag: 'voice',
    title: 'Voice Calling',
    desc: 'Make, receive, and control your calls with one API.',
    links: [
      QuickStartLink('voice-calling/voicecalling-overview/'),
      ApiReferenceLink('voice-calling/'),
      SDKLink,
      TutorialsLink('voice'),
    ],
  },
  {
    trackTag: 'conversations',
    title: 'Conversations',
    desc: 'Manage your omni-channel messaging in one unified conversation.',
    links: [
      QuickStartLink('conversations-overview/'),
      ApiReferenceLink('conversations/'),
      SDKLink,
      TutorialsLink('conversations'),
    ],
  },
  {
    trackTag: 'numbers',
    title: 'Numbers',
    desc: "Purchase and manage MessageBird's phone numbers.",
    links: [
      QuickStartLink('numbers-overview/'),
      ApiReferenceLink('numbers/'),
      SDKLink,
      TutorialsLink(''),
    ],
  },
  {
    trackTag: 'verify',
    title: 'Verify',
    desc:
      'Verify mobile numbers with two-factor authentication using SMS or Voice.',
    links: [
      QuickStartLink('verify-overview/'),
      ApiReferenceLink('verify/'),
      SDKLink,
      TutorialsLink('verify'),
    ],
  },
];

const subProducts = [
  {
    name: 'Reporting',
    label: <Badge color="yellow">BETA</Badge>,
    link: '/api/reporting/',
    track: 'reporting',
  },
  {
    name: 'HLR',
    link: '/api/hlr/',
    track: 'hlr',
  },
  {
    name: 'Contacts',
    link: '/api/contacts/',
    track: 'contacts',
  },
  {
    name: 'Groups',
    link: '/api/groups/',
    track: 'groups',
  },
  {
    name: 'Lookup',
    link: '/api/lookup/',
    track: 'lookup',
  },
  {
    name: 'Balance',
    link: '/api/balance/',
    track: '',
  },
  {
    name: 'Voice Messaging',
    link: '/api/voice-messaging/',
    track: 'voice_messaging',
  },
  {
    name: 'MMS',
    link: '/api/mms-messaging/',
    track: 'mms',
  },
];

export const HomePageLayout = ({ children, ...props }) => {
  useEffect(() => {
    track('developer_portal', { step: 'homepage', portal_event: 'pageView' });
  }, []);
  return (
    <>
      <MobileNavigation />
      <BaseLayout location={props.path} fullWidth {...props}>
        <HeroContainer>
          <Box>
            <HeroHeading>Welcome to MessageBird Docs</HeroHeading>
          </Box>
          <Box>
            <HeroSubHeading>
              Get familiar with our APIs and technical resources in your
              favorite languages.
            </HeroSubHeading>
          </Box>
        </HeroContainer>
        <MainContentContainer
          width={1}
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
        >
          <MainProductsGrid width={1} flexWrap="wrap">
            {products.map(
              ({ title, desc, trackTag, links, earlyAccess, label }) => (
                <MainProductCard key={title}>
                  <CardTextContainer
                    //redirect to docs
                    onClick={() => navigate(links[0].link)}
                  >
                    <Flex justifyContent="flex-start" alignItems="center">
                      <CardIcon
                        src={`/img/icons/products/icon-products-${title.toLowerCase()}.svg`}
                        width="20"
                        height="20"
                      />
                      <CardTitle>
                        {title} {label && label}
                      </CardTitle>
                      {earlyAccess && (
                        <Badge ordinances="secondary" colors="green">
                          Early Access
                        </Badge>
                      )}
                    </Flex>

                    <Text css="line-height: normal;">{desc}</Text>
                  </CardTextContainer>
                  <Flex justifyContent="space-between" flexWrap="wrap">
                    {links.map(({ label, link, linkTracking, newTab }) => (
                      <ALink
                        key={link}
                        css="flex: 50%; margin-top: 8px; font-size: 16px; font-weight: normal;"
                        to={link}
                        newTab={newTab}
                        onClick={() =>
                          track('developer_portal', {
                            step: 'homepage',
                            portal_event: 'choiceClick',
                            card_type: trackTag,
                            choice: linkTracking,
                          })
                        }
                      >
                        {label}
                      </ALink>
                    ))}
                  </Flex>
                </MainProductCard>
              ),
            )}
          </MainProductsGrid>
          <SubProductsGrid>
            {subProducts.map(({ name, track, link, label }) => {
              return (
                <ALink
                  key={link}
                  css="color: inherit;"
                  to={link}
                  onClick={() =>
                    track('developer_portal', {
                      step: 'homepage',
                      portal_event: 'choiceClick',
                      card_type: track,
                    })
                  }
                >
                  <SubProductsCard>
                    {name}
                    {label && label}
                  </SubProductsCard>
                </ALink>
              );
            })}
          </SubProductsGrid>
          <HomePageFooter />
        </MainContentContainer>
      </BaseLayout>
    </>
  );
};
export default HomePageLayout;

const HeroContainer = styled.div`
  width: 100%;
  padding: 84px 0 30px 0;
  position: relative;
  z-index: -1;
  display: flex;
  flex-direction: column;
  box-shadow: inset 0 2px 4px 0 #ebf2fc;
  align-items: center;
  justify-content: center;
  @media (max-width: 767px) {
    text-align: center;
    padding: initial;
  }
  div {
    max-width: 1008px;
    @media (max-width: 767px) {
      margin-top: 54px;
      flex-wrap: wrap;
    }
  }
`;

const HeroHeading = styled.h1`
  font-size: 40px;
  line-height: 48px;
  letter-spacing: 0px;
  @media (max-width: 767px) {
    text-align: center;
  }
`;

const HeroSubHeading = styled.h2`
  font-size: 20px;
  line-height: 32px;
  color: #687879;
  font-weight: normal;
  @media (max-width: 767px) {
    text-align: center;
  }
`;

const MainContentContainer = styled(Flex)`
  div {
    max-width: 1008px;
  }
`;

const MainProductsGrid = styled.div`
  display: grid;
  width: 100%;
  margin-top: 8px;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  grid-column-gap: 24px;
  grid-row-gap: 24px;
`;

const SubProductsGrid = styled.div`
  display: grid;
  width: 100%;
  margin-top: 24px;
  grid-template-columns: repeat(auto-fill, minmax(234px, 1fr));
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  margin-bottom: 92px;
`;

const SubProductsCard = styled(Card)`
  background-color: ${COLOR.WHITE};
  min-height: 84px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 663px) {
    margin: 0 15px;
  }
  span {
    margin-left: 16px;
    height: inherit;
    line-height: inherit;
  }
`;

const MainProductCard = styled(Card)`
  background-color: ${COLOR.WHITE};
  cursor: default;
  @media (max-width: 663px) {
    margin: 0 15px;
  }
  &:hover {
    background-color: ${COLOR.WHITE};
    h5::after {
      display: none;
    }
    box-shadow: rgba(113, 150, 193, 0.1) 0px 9px 25px 0px,
      rgba(113, 150, 193, 0.1) 0px 9px 25px -4px;
  }
`;
const CardTextContainer = styled.div`
  cursor: pointer;
`;
const CardIcon = styled.img`
  margin-right: 14px;
`;
