import Helmet from 'react-helmet';
import CodeSnippet from 'components/CodeSnippet';
import Notification from 'components/Notification';
import { APIWrapper, LeftColumn, RightColumn } from 'components/ApiRef';
import * as CODE from 'constants/partner';
import * as React from 'react';
export default {
  Helmet,
  CodeSnippet,
  Notification,
  APIWrapper,
  LeftColumn,
  RightColumn,
  CODE,
  React
};