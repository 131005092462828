import React, { useContext, useEffect } from 'react';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import { Flex, Box } from '@rebass/grid';
import styled from 'styled-components';
import loadable from '@loadable/component';
import Layout from './Base';
import { Container } from '../Container';
import ContentBlock from '../ContentBlock';
import { LangContext } from '../../contexts/LanguageContext';
import MobileNavigation from '../MobileNavigation';
import { ContentPageFooter } from '../Footer/ContentPagesFooter/ContentPagesFooter';

const CodeDropdownSelect = loadable(
  () => import('../Dropdowns/CodeLanguageDropdown'),
);

const CenterNarrowContentBlock = styled(ContentBlock)`
  width: 66.6%;
  margin: auto;
`;
const useSiteMetadata = () => {
  const allTutorials = useStaticQuery(
    graphql`
      query TutorialQuery {
        allMdxCodeLanguage {
          edges {
            node {
              mdxDir
              codeLang
            }
          }
        }
      }
    `,
  );
  return allTutorials.allMdxCodeLanguage.edges;
};
type pageContextTypes = {
  codeLang: string;
  mdxDir: string;
  pagePath: string;
};
interface TutorialLayout {
  children: React.ReactNode;
  pageContext: pageContextTypes;
}

const TutorialLayout: React.FC<TutorialLayout> = ({
  children,
  pageContext,
}) => {
  const { language: contextLanguage, handleLangChange } = useContext(
    LangContext,
  );
  const data = useSiteMetadata();
  const languages = data
    .filter(({ node }) => node.mdxDir === pageContext.pagePath || null)
    .map(({ node }) => node.codeLang);
  useEffect(() => {
    if (
      languages.includes(contextLanguage) &&
      contextLanguage !== pageContext.codeLang &&
      typeof window !== 'undefined'
    ) {
      //handleLangChange(pageContext.codeLang);
      const newPage = `${pageContext.pagePath}-${contextLanguage}`;
      navigate(newPage, {
        replace: true,
      });
    }
  }, [contextLanguage]);
  return (
    <>
      <Layout fullWidth>
        <MobileNavigation api={false} tutorials={true} />
        <Container>
          <CenterNarrowContentBlock className="docs-content">
            <Flex flexWrap="wrap">
              {languages.length > 1 && (
                <Box width={1} mb={30} mt={[100, 100, 0]}>
                  <CodeDropdownSelect
                    tutorials
                    language={pageContext.codeLang}
                    languages={languages}
                    fallback={<div css="height: 38px;" />}
                    handleExtChange={(value) => {
                      handleLangChange(value);
                      navigate(`${pageContext.pagePath}-${value}`);
                    }}
                  />
                </Box>
              )}
              <Box mt={[100, 100, 0]}>
                {children}
                <ContentPageFooter />
              </Box>
            </Flex>
          </CenterNarrowContentBlock>
        </Container>
      </Layout>
    </>
  );
};

export default TutorialLayout;
