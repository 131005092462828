import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import Notification from 'components/Notification';
import { Img } from 'components/shared';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Facebook Media Templates`}</h1>
    <p><a parentName="p" {...{
        "href": "https://developers.facebook.com/docs/messenger-platform/send-messages/template/media"
      }}>{`Facebook media templates`}</a>{` allows
your business to send images, GIFs and videos as structured messages with an optional button.`}</p>
    <Notification type="INFO" mdxType="Notification">
    Facebook Messenger supports only images and video for media templates. Audio isn't supported at the moment.
    </Notification>
    <h2>{`Sending a media template message with a URL button`}</h2>
    <p>{`In order to send a media template with a URL button, you can use any of
`}<a parentName="p" {...{
        "href": "https://developers.messagebird.com/api/conversations/#sending-messages"
      }}>{`the endpoints for sending messages in Conversations API`}</a>{`
with the message type `}<inlineCode parentName="p">{`facebookMediaTemplate`}</inlineCode>{`. The example below illustrates how to send it using the endpoint `}<inlineCode parentName="p">{`POST /v1/send`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-curl"
      }}>{`curl -X "POST" "https://conversations.messagebird.com/v1/send" \\
     -H 'Authorization: AccessKey {your-access-key}' \\
     -H 'Content-Type: application/json; charset=utf-8' \\
     -d $'{
  "content": {
    "facebookMediaTemplate": {
      "attachment": {
        "type": "template",
        "payload": {
          "template_type": "media",
          "elements": [
            {
              "media_url": "https://www.yourcompany.com/image.png",
              "buttons": [
                {
                  "type": "web_url",
                  "url": "https://www.yourcompany.com",
                  "title": "Visit website"
                }
              ],
              "media_type": "image"
            }
          ]
        }
      }
    }
  },
  "to": "4190117307726093",
  "type": "facebookMediaTemplate",
  "from": "99d0e570cd834661a1771ab08044be8b"
}'
`}</code></pre>
    <ul>
      <li parentName="ul">{`The message `}<inlineCode parentName="li">{`type`}</inlineCode>{` must be `}<inlineCode parentName="li">{`facebookMediaTemplate`}</inlineCode></li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.facebookMediaTemplate`}</inlineCode>{` must be set with a `}<a parentName="li" {...{
          "href": "/api/conversations/#facebookmessage-object"
        }}>{`FacebookMessage`}</a></li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.facebookMediaTemplate.attachment`}</inlineCode>{` must be set with a `}<a parentName="li" {...{
          "href": "/api/conversations/#facebookattachment-object"
        }}>{`FacebookAttachment`}</a></li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.facebookMediaTemplate.attachment.type`}</inlineCode>{` must be `}<inlineCode parentName="li">{`template`}</inlineCode>{` for template messages`}</li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.facebookMediaTemplate.attachment.payload`}</inlineCode>{` must be set with a `}<a parentName="li" {...{
          "href": "/api/conversations/#facebookattachmentpayload-object"
        }}>{`FacebookAttachmentPayload`}</a></li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.facebookMediaTemplate.attachment.payload.template_type`}</inlineCode>{` must be `}<inlineCode parentName="li">{`media`}</inlineCode>{` for media template messages`}</li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.facebookMediaTemplate.attachment.payload.elements`}</inlineCode>{` must contain at least one `}<a parentName="li" {...{
          "href": "/api/conversations/#facebookelement-object"
        }}>{`FacebookElement`}</a></li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.facebookMediaTemplate.attachment.payload.elements[].media_type`}</inlineCode>{` must be `}<inlineCode parentName="li">{`image`}</inlineCode>{` for images`}</li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.facebookMediaTemplate.attachment.payload.elements[].media_url`}</inlineCode>{` must contain the URL of the media`}</li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.facebookMediaTemplate.attachment.payload.elements[].buttons`}</inlineCode>{` may contain one `}<a parentName="li" {...{
          "href": "/api/conversations/#facebookbutton-object"
        }}>{`FacebookButton`}</a></li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.facebookMediaTemplate.attachment.payload.elements[].buttons[].type`}</inlineCode>{` may be `}<inlineCode parentName="li">{`web_url`}</inlineCode>{` for a URL button`}</li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.facebookMediaTemplate.attachment.payload.elements[].buttons[].url`}</inlineCode>{` must contain the URL the recipient will see when clicking at the button`}</li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.facebookMediaTemplate.attachment.payload.elements[].buttons[].title`}</inlineCode>{` must contain the label of the button`}</li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`to`}</inlineCode>{` must contain a valid Facebook-specific recipient identifier`}</li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`from`}</inlineCode>{` must contain a valid Facebook channel ID`}</li>
    </ul>
    <p>{`The message sent by the previous code snippet looks like the following.`}</p>
    <Img src="/img/screenshots/quickstarts/facebook/media-template-with-url-button.png" align="middle" mdxType="Img" />
    <p>{`The message looks like the following in Conversations API.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "id": "8a7e47cf40cc472b8141ca4f033f361a",
  "conversationId": "6279c2012f7d4df393cfa1f872518518",
  "platform": "facebook",
  "to": "4190117307726093",
  "from": "101397625015964",
  "channelId": "99d0e570cd834661a1771ab08044be8b",
  "type": "facebookMediaTemplate",
  "content": {
    "facebookMediaTemplate": {
      "attachment": {
        "type": "template",
        "payload": {
          "template_type": "media",
          "elements": [
            {
              "media_type": "image",
              "attachment_id": "531649808135398",
              "media_url": "https://www.yourcompany.com/image.png",
              "buttons": [
                {
                  "type": "web_url",
                  "url": "https://www.yourcompany.com",
                  "title": "Visit website"
                }
              ]
            }
          ]
        }
      }
    }
  },
  "direction": "sent",
  "status": "sent",
  "createdDatetime": "2022-02-15T16:15:35.576027239Z",
  "updatedDatetime": "2022-02-15T16:15:35.576027239Z"
}
`}</code></pre>
    <h2>{`Sending a media template message with a call button`}</h2>
    <p>{`In order to send a media template with a call button, you can use any of
`}<a parentName="p" {...{
        "href": "https://developers.messagebird.com/api/conversations/#sending-messages"
      }}>{`the endpoints for sending messages in Conversations API`}</a>{`
with the message type `}<inlineCode parentName="p">{`facebookMediaTemplate`}</inlineCode>{`. The example below illustrates how to send it using the endpoint `}<inlineCode parentName="p">{`POST /v1/send`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-curl"
      }}>{`curl -X "POST" "https://conversations.messagebird.com/v1/send" \\
     -H 'Authorization: AccessKey {your-access-key}' \\
     -H 'Content-Type: application/json; charset=utf-8' \\
     -d $'{
  "content": {
    "facebookMediaTemplate": {
      "attachment": {
        "type": "template",
        "payload": {
          "template_type": "media",
          "elements": [
            {
              "media_url": "https://www.yourcompany.com/image.png",
              "buttons": [
                {
                  "type": "phone_number",
                  "payload": "+31000000000",
                  "title": "Call us!"
                }
              ],
              "media_type": "image"
            }
          ]
        }
      }
    }
  },
  "to": "4190117307726093",
  "type": "facebookMediaTemplate",
  "from": "99d0e570cd834661a1771ab08044be8b"
}'
`}</code></pre>
    <ul>
      <li parentName="ul">{`The message `}<inlineCode parentName="li">{`type`}</inlineCode>{` must be `}<inlineCode parentName="li">{`facebookMediaTemplate`}</inlineCode></li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.facebookMediaTemplate`}</inlineCode>{` must be set with a `}<a parentName="li" {...{
          "href": "/api/conversations/#facebookmessage-object"
        }}>{`FacebookMessage`}</a></li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.facebookMediaTemplate.attachment`}</inlineCode>{` must be set with a `}<a parentName="li" {...{
          "href": "/api/conversations/#facebookattachment-object"
        }}>{`FacebookAttachment`}</a></li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.facebookMediaTemplate.attachment.type`}</inlineCode>{` must be `}<inlineCode parentName="li">{`template`}</inlineCode>{` for template messages`}</li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.facebookMediaTemplate.attachment.payload`}</inlineCode>{` must be set with a `}<a parentName="li" {...{
          "href": "/api/conversations/#facebookattachmentpayload-object"
        }}>{`FacebookAttachmentPayload`}</a></li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.facebookMediaTemplate.attachment.payload.template_type`}</inlineCode>{` must be `}<inlineCode parentName="li">{`media`}</inlineCode>{` for media template messages`}</li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.facebookMediaTemplate.attachment.payload.elements`}</inlineCode>{` must contain at least one `}<a parentName="li" {...{
          "href": "/api/conversations/#facebookelement-object"
        }}>{`FacebookElement`}</a></li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.facebookMediaTemplate.attachment.payload.elements[].media_type`}</inlineCode>{` must be `}<inlineCode parentName="li">{`image`}</inlineCode>{` for images`}</li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.facebookMediaTemplate.attachment.payload.elements[].media_url`}</inlineCode>{` must contain the URL of the media`}</li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.facebookMediaTemplate.attachment.payload.elements[].buttons`}</inlineCode>{` may contain one `}<a parentName="li" {...{
          "href": "/api/conversations/#facebookbutton-object"
        }}>{`FacebookButton`}</a></li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.facebookMediaTemplate.attachment.payload.elements[].buttons[].type`}</inlineCode>{` may be `}<inlineCode parentName="li">{`phone_number`}</inlineCode>{` for a call button`}</li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.facebookMediaTemplate.attachment.payload.elements[].buttons[].payload`}</inlineCode>{` must contain a valid phone number which the recipient will call when clicking the button`}</li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.facebookMediaTemplate.attachment.payload.elements[].buttons[].title`}</inlineCode>{` must contain the label of the button`}</li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`to`}</inlineCode>{` must contain a valid Facebook-specific recipient identifier`}</li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`from`}</inlineCode>{` must contain a valid Facebook channel ID`}</li>
    </ul>
    <p>{`The message sent by the previous code snippet looks like the following.`}</p>
    <Img src="/img/screenshots/quickstarts/facebook/media-template-with-call-button.png" align="middle" mdxType="Img" />
    <p>{`The message looks like the following in Conversations API.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "id": "7e57780ce09f4bada118257113bc610a",
  "conversationId": "6279c2012f7d4df393cfa1f872518518",
  "platform": "facebook",
  "to": "4190117307726093",
  "from": "101397625015964",
  "channelId": "99d0e570cd834661a1771ab08044be8b",
  "type": "facebookMediaTemplate",
  "content": {
    "facebookMediaTemplate": {
      "attachment": {
        "type": "template",
        "payload": {
          "template_type": "media",
          "elements": [
            {
              "media_type": "image",
              "attachment_id": "212930650994208",
              "media_url": "https://www.yourcompany.com/image.png",
              "buttons": [
                {
                  "type": "phone_number",
                  "title": "Call us!",
                  "payload": "+31000000000"
                }
              ]
            }
          ]
        }
      }
    }
  },
  "direction": "sent",
  "status": "sent",
  "createdDatetime": "2022-02-15T16:42:35.478886849Z",
  "updatedDatetime": "2022-02-15T16:42:35.478886849Z"
}
`}</code></pre>
    <h2>{`Sending a media template message with a custom button`}</h2>
    <p>{`In order to send a media template with a custom button, you can use any of
`}<a parentName="p" {...{
        "href": "https://developers.messagebird.com/api/conversations/#sending-messages"
      }}>{`the endpoints for sending messages in Conversations API`}</a>{`
with the message type `}<inlineCode parentName="p">{`facebookMediaTemplate`}</inlineCode>{`. The example below illustrates how to send it using the endpoint `}<inlineCode parentName="p">{`POST /v1/send`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-curl"
      }}>{`curl -X "POST" "https://conversations.messagebird.com/v1/send" \\
     -H 'Authorization: AccessKey {your-access-key}' \\
     -H 'Content-Type: application/json; charset=utf-8' \\
     -d $'{
  "content": {
    "facebookMediaTemplate": {
      "attachment": {
        "type": "template",
        "payload": {
          "template_type": "media",
          "elements": [
            {
              "media_url": "https://www.yourcompany.com/image.png",
              "buttons": [
                {
                  "type": "postback",
                  "payload": "custom-value",
                  "title": "Click here"
                }
              ],
              "media_type": "image"
            }
          ]
        }
      }
    }
  },
  "to": "4190117307726093",
  "type": "facebookMediaTemplate",
  "from": "99d0e570cd834661a1771ab08044be8b"
}'
`}</code></pre>
    <ul>
      <li parentName="ul">{`The message `}<inlineCode parentName="li">{`type`}</inlineCode>{` must be `}<inlineCode parentName="li">{`facebookMediaTemplate`}</inlineCode></li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.facebookMediaTemplate`}</inlineCode>{` must be set with a `}<a parentName="li" {...{
          "href": "/api/conversations/#facebookmessage-object"
        }}>{`FacebookMessage`}</a></li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.facebookMediaTemplate.attachment`}</inlineCode>{` must be set with a `}<a parentName="li" {...{
          "href": "/api/conversations/#facebookattachment-object"
        }}>{`FacebookAttachment`}</a></li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.facebookMediaTemplate.attachment.type`}</inlineCode>{` must be `}<inlineCode parentName="li">{`template`}</inlineCode>{` for template messages`}</li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.facebookMediaTemplate.attachment.payload`}</inlineCode>{` must be set with a `}<a parentName="li" {...{
          "href": "/api/conversations/#facebookattachmentpayload-object"
        }}>{`FacebookAttachmentPayload`}</a></li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.facebookMediaTemplate.attachment.payload.template_type`}</inlineCode>{` must be `}<inlineCode parentName="li">{`media`}</inlineCode>{` for media template messages`}</li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.facebookMediaTemplate.attachment.payload.elements`}</inlineCode>{` must contain at least one `}<a parentName="li" {...{
          "href": "/api/conversations/#facebookelement-object"
        }}>{`FacebookElement`}</a></li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.facebookMediaTemplate.attachment.payload.elements[].media_type`}</inlineCode>{` must be `}<inlineCode parentName="li">{`image`}</inlineCode>{` for images`}</li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.facebookMediaTemplate.attachment.payload.elements[].media_url`}</inlineCode>{` must contain the URL of the media`}</li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.facebookMediaTemplate.attachment.payload.elements[].buttons`}</inlineCode>{` may contain one `}<a parentName="li" {...{
          "href": "/api/conversations/#facebookbutton-object"
        }}>{`FacebookButton`}</a></li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.facebookMediaTemplate.attachment.payload.elements[].buttons[].type`}</inlineCode>{` may be `}<inlineCode parentName="li">{`postback`}</inlineCode>{` for a custom button`}</li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.facebookMediaTemplate.attachment.payload.elements[].buttons[].payload`}</inlineCode>{` must contain the value which will be sent as an `}<a parentName="li" {...{
          "href": "/api/conversations/#facebookpostback-object"
        }}>{`FacebookPostback`}</a>{` inbound message`}</li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.facebookMediaTemplate.attachment.payload.elements[].buttons[].title`}</inlineCode>{` must contain the label of the button`}</li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`to`}</inlineCode>{` must contain a valid Facebook-specific recipient identifier`}</li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`from`}</inlineCode>{` must contain a valid Facebook channel ID`}</li>
    </ul>
    <p>{`The message sent by the previous code snippet looks like the following.`}</p>
    <Img src="/img/screenshots/quickstarts/facebook/media-template-with-custom-button.png" align="middle" mdxType="Img" />
    <p>{`The message looks like the following in Conversations API.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "id": "5e7ce3d1058e4460ac1e801b69203d27",
  "conversationId": "6279c2012f7d4df393cfa1f872518518",
  "platform": "facebook",
  "to": "4190117307726093",
  "from": "101397625015964",
  "channelId": "99d0e570cd834661a1771ab08044be8b",
  "type": "facebookMediaTemplate",
  "content": {
    "facebookMediaTemplate": {
      "attachment": {
        "type": "template",
        "payload": {
          "template_type": "media",
          "elements": [
            {
              "media_type": "image",
              "attachment_id": "628162978478942",
              "media_url": "https://www.yourcompany.com/image.png",
              "buttons": [
                {
                  "type": "postback",
                  "title": "Click here",
                  "payload": "custom-value"
                }
              ]
            }
          ]
        }
      }
    }
  },
  "direction": "sent",
  "status": "sent",
  "createdDatetime": "2022-02-15T16:58:13.042419184Z",
  "updatedDatetime": "2022-02-15T16:58:13.042419184Z"
}
`}</code></pre>
    <p>{`When the recipient clicks the button, this is how the `}<a parentName="p" {...{
        "href": "/api/conversations/#facebookpostback-object"
      }}>{`FacebookPostback`}</a>{`
inbound message looks like in Conversations API.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "id": "9fca85766f8d4940a33c28ce33e8960e",
  "conversationId": "6279c2012f7d4df393cfa1f872518518",
  "platform": "facebook",
  "to": "101397625015964",
  "from": "4190117307726093",
  "channelId": "99d0e570cd834661a1771ab08044be8b",
  "type": "facebookPostback",
  "content": {
    "facebookPostback": {
      "title": "Click here",
      "payload": "custom-value"
    }
  },
  "direction": "received",
  "status": "received",
  "createdDatetime": "2022-02-15T17:12:13.322Z",
  "updatedDatetime": "2022-02-15T17:12:13.322Z"
}
`}</code></pre>
    <p>{`Note that the `}<a parentName="p" {...{
        "href": "/api/conversations/#facebookpostback-object"
      }}>{`FacebookPostback`}</a>{` inbound message contains the button label
in `}<inlineCode parentName="p">{`content.facebookPostback.title`}</inlineCode>{` and the custom value set in the `}<inlineCode parentName="p">{`content.facebookPostback.payload`}</inlineCode>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      