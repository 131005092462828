import styled from "styled-components";

export const StatusLabel = styled.span`
  color: #fff;
  font-weight: 600;
  padding: 3px 6px 2px;
  font-size: 14px;
  border-radius: 2px;
  margin-bottom: 15px;
  margin-right: 2px;
  background-color: #c3cddd;
  ${({ statusCat }) => statusCat && `background-color: #ee6c4d`};
  ${({ reasonCat }) => reasonCat && `background-color: #3d5a80`};
  ${({ errorCat }) => errorCat && `background-color: #98c1d9`};
`;

export default StatusLabel;
