import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/ApiRef.js";
import CodeSnippet from 'components/CodeSnippet';
import * as CODE from 'constants/email';
import { APIWrapper, LeftColumn, RightColumn } from 'components/ApiRef';
import Notification from 'components/Notification';
import Helmet from 'react-helmet';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Helmet mdxType="Helmet">
      <meta name="robots" content="noindex" />
    </Helmet>
    <h1>{`Programmable Email `}<inlineCode parentName="h1">{`Early Access`}</inlineCode></h1>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <p>{`MessageBird's Programmable Email API enables you to send and receive emails using your own domain. You can use the API to do this programmatically or you can choose to integrate the API with your own frontend solution, such as a CRM.`}</p>
        <p>{`To enable two-way email communication, you'll need to first create a MessageBird email channel, through which you can register your domain. Once set up, you'll be able to both send transactional emails to one or many recipients with a single API call, as well as also receive emails.`}</p>
        <p>{`As a prerequisite, you will need access to the DNS configuration of the domain you want to send email from during the setup of an email channel.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>API Endpoint</h4>
  <CodeSnippet code={CODE.ENDPOINT} mdxType="CodeSnippet" />
        <h4>Endpoints Summary</h4>
  <CodeSnippet code={CODE.ENDPOINTS_SUMMARY} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <h2>{`Data Retention`}</h2>
    <p>{`The emails which are directly sent through Email Service, will be removed after 30 days.`}</p>
    <h2>{`Authorization`}</h2>
    <p>{`With each API call you'll need to set an access key to authenticate yourself. API keys can be created or retrieved through the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`MessageBird Dashboard`}</a>{`.`}</p>
    <p>{`To provide the access key, set the `}<inlineCode parentName="p">{`Authorization`}</inlineCode>{` header in the form of `}<inlineCode parentName="p">{`AccessKey {accessKey}`}</inlineCode>{`.`}</p>
    <h2>{`Formats and Headers`}</h2>
    <p>{`All API responses are in JSON. You're required to send the `}<inlineCode parentName="p">{`Accept`}</inlineCode>{` header with the value `}<inlineCode parentName="p">{`application/json`}</inlineCode>{` with each request.`}</p>
    <p>{`POST and PUT requests to the API must contain a JSON-formatted payload in the request body.`}</p>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Errors`}</h2>
        <p>{`MessageBird uses standard HTTP status codes to indicate success or failure of an API request. Codes in the 2xx range indicate that a request was successfully processed and codes in the 4xx range indicate that there was an error that resulted from the information sent (e.g. authentication, no balance or a missing or wrong parameter).`}</p>
        <p>{`In the case of an error, the body of the response includes a JSON-formatted response that tells you exactly what is wrong.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Error Object Example</h4>
  <CodeSnippet code={CODE.ERROR_EXAMPLE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <h3>{`Attributes`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`errors[].code`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An integer that represents the error type.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`errors[].description`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A human-readable description of the error. You can use this to let the user know what they can do about the error.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`errors[].parameter`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The parameter in your request related to the error if the error is parameter specific.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Possible errors`}</h3>
    <p>{`The following table documents errors that could be returned with an HTTP Status in the 4XX range indicating the request was not accepted.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Param`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`10`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`name is required and can't be empty`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`name`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`10`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`domainName is required and can't be empty`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`domainName`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`10`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`domainName must be a valid domain name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`domainName`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`10`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`webhook is longer than 1024 bytes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`webhook`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`10`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`channel already exists`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`10`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`domain is already registered to a channel`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`20`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`channel not found`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`21`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`channel is not active: domain is not yet verified`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`21`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`the from domain must match the registered domain for your channel`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`21`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`recipient is invalid`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`you have reached the API rate limit, please try again later`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`The Channel object`}</h2>
        <p>{`A Channel represents a registered domain used for sending email from you to your customers. The domain that you configure for a channel allows emails to be sent from `}<inlineCode parentName="p">{`<your email>@<your domain>`}</inlineCode>{` in the `}<inlineCode parentName="p">{`From:`}</inlineCode>{` header of an email sent to your customers.`}</p>
        <h3>{`Attributes`}</h3>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Attribute`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`id`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A unique random ID which is created on the MessageBird platform and is returned upon creation of the channel.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`name`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`This is the name you want to give to your channel.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`domainName`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`This is the domain you are going to use to send your emails, to be able to sent emails using your domain, you have to have access to the DNS configuration.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`dkim`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`DKIM`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The Domain Key Identified Mail is a security feature to avoid email spoofing.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`status`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The status indicates the current state of your channel, it can be one of the following: action_required, pending, running, invalid`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`createdDatetime`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`date`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`RFC3339 formatted	timestamp of the creation time of the channel.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`updatedDatetime`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`date`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`RFC3339 formatted timestamp of the time of the last update made to the channel.`}</td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Example Channel</h4>
  <CodeSnippet code={CODE.CHANNEL_OBJ} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h3>{`DKIM`}</h3>
        <p>{`The DKIM record includes the TXT record you need to add to your DNS settings to enable DKIM verification once an email reaches the mail client of your customer, and some additional information about the public key and headers used for signing.`}</p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Attributes`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`public`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The public portion of the DKIM signature.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`selector`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The selector for the TXT record, it should have the the shape: `}<inlineCode parentName="td">{`scph0319._domainkey.*`}</inlineCode></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`signingDomain`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`This is your domain, as used to create the secure signature.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`record`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A representation of the TXT record that needs to be added to your DNS settings.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`headers`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Headers used to create the secure signature.`}</td>
            </tr>
          </tbody>
        </table>
        <h3>{`DKIM and DMARC Verification`}</h3>
        <p>{`We currently support sending emails with DKIM (DomainKeys Identified Mail) and DMARC (Domain Message Authentication Reporting & Conformance) verification.`}</p>
        <p>{`DKIM is an authorization mechanism that detects forged sender addresses in emails. DKIM works by allowing a receiving mail client to validate that a specific piece of mail that appears to come from a domain was authorized (by way of digital signature) by the person with ownership of that domain.`}</p>
        <p>{`When you create a new channel we will provide you a DKIM record that must be inserted into the DNS configuration for your domain, typically via your DNS provider. Once you've added your DKIM record to your DNS, the channel will automatically verify the DKIM record and become ready for use after a period of up to 10 minutes. MessageBird will verify the status of your DNS records regularly to ensure the DKIM is configured correctly and keep the status of the channel up-to-date to reflect this.`}</p>
        <p>{`DMARC is an extension mechanism that leverages DKIM to provide an additional guarantee that the displayed sender address (also known as the "From address") of an email is in `}<em parentName="p">{`alignment`}</em>{` with the domain used for DKIM verification. With DKIM alignment, the `}<inlineCode parentName="p">{`d=`}</inlineCode>{` tag in the DKIM-Signature header must be aligned with the domain used in the `}<inlineCode parentName="p">{`From:`}</inlineCode>{` header of the mail, i.e. the displayed from address. Alignment in this case can be "strict", where the domains must be identical, or "relaxed", where the organisational domain must match and a subdomain can be used.`}</p>
        <p>{`To be able to validate your messages using DMARC, you need to access your DNS configurations and add a new TXT record with your DMARC configuration. An example DMARC record looks like:`}</p>
        <CodeSnippet code={CODE.DMARC_RECORD} mdxType="CodeSnippet" />
        <p>{`Where "p" is the policy you want to adopt when a message fails the DMARC validation, "pct" is the percentage of mail to which you would like to have the DMARC policy applied to and "rua" and "ruf" are the receivers of the reports created when the messages are validated. If you want to access these reports, set these fields to a valid email address within your domain. To see more information on how to create your DMARC record and all the policy options, go to: `}<a parentName="p" {...{
            "href": "https://tools.ietf.org/html/rfc7489#section-6.1"
          }}>{`https://tools.ietf.org/html/rfc7489#section-6.1`}</a>{`.`}</p>
      </LeftColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Create a Channel`}</h2>
        <p>{`Register a new channel for a given domain.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.CREATECHANNEL_DEF} mdxType="CodeSnippet" />
  <h4>Example request</h4>
  <CodeSnippet code={CODE.CREATECHANNEL_REQ} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h3>{`Response`}</h3>
        <p>{`A `}<em parentName="p">{`Channel`}</em>{` object as described above.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Example response</h4>
  <CodeSnippet code={CODE.CREATECHANNEL_RESP} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <a name="message"></a>
        <h2>{`The Message Object`}</h2>
        <p>{`A Message is an Email that can be sent via the API to one or more recipients.
A message can be thought of as a single message to an end-user, such as a password reset form,
or a campaign message sent out with a pre-defined template to many users at once.`}</p>
        <p>{`E-mail message content can be set as HTML, plain text or both. Attachments and inline images need to be uploaded before posting the request to send the message.
For more details about how to upload attachments and inline images, refer to the `}<a parentName="p" {...{
            "href": "/api/email/#upload-attachments-and-inline-images"
          }}>{`Uploading attachments and inline images`}</a>{` section.`}</p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Attributes`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`id`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A unique random ID for this message on the MessageBird platform, returned upon acceptance of the message.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`to`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Array of `}<a parentName="td" {...{
                  "href": "/api/email/#recipient"
                }}>{`Recipient`}</a></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Required. An array containing of all the recipients of the message.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`cc`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Array of `}<a parentName="td" {...{
                  "href": "/api/email/#recipient"
                }}>{`Recipient`}</a></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Optional. An array containing of all the recipients who will be in the cc field of the  email.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`from`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/api/email/#recipient"
                }}>{`Recipient`}</a></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Required. Name / address that will be displayed in the from field of the sent messages, the domain in the address email must be registered to one of your channels.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`subject`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Required. This will be displayed as the subject in the message,  expected in the UTF-8 charset without RFC2047 encoding.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`content`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/api/email/#content"
                }}>{`Content`}</a></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Required. HTML or text content for the email. At least one type of content is required.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`replyTo`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Email address used to compose the email’s “Reply-To” header.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`returnPath`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Email address used to compose the email’s “Return-Path” header. Must match your sending domain.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`headers`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`object`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Object containing custom headers other than Subject, From, To, and Reply-To. These will be sent along with your message.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`tracking`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "/api/email/#tracking"
                }}>{`Tracking`}</a></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Optional. Allows you to set tracking options.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`reportUrl`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The URL for delivery of status reports for the message. Must use https.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`attachments`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`array of `}<a parentName="td" {...{
                  "href": "/api/email/#attachment"
                }}>{`Attachment`}</a></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Optional. List of files attached to a message.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`inlineImages`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`array of `}<a parentName="td" {...{
                  "href": "/api/email/#inline-image"
                }}>{`InlineImage`}</a></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Optional. List of inline images added to the message content.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`mode`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Optional. If it's set to `}<inlineCode parentName="td">{`campaign`}</inlineCode>{`, destinations won't see each other in the email message. If it's set to `}<inlineCode parentName="td">{`normal`}</inlineCode>{`, destinations will see each other in the email message.`}</td>
            </tr>
          </tbody>
        </table>
        <a name="content"></a>
        <h3>{`Content`}</h3>
        <p>{`The Content object represents the content to be included as the body of your message, it can contain either HTML or plain text.`}</p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Attributes`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`html`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`HTML content of the message, expected in UTF-8.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`text`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Plain text of the message, expected in UTF-8.`}</td>
            </tr>
          </tbody>
        </table>
        <a name="recipient"></a>
        <h3>{`Recipient`}</h3>
        <p>{`You can specify the recipients of your email as an array of objects with the properties listed below.
Variables added for each recipient are used to customise the content sent to a user if a template with
matching dynamic placeholders is used in the body or custom headers. Additional variables not present in the content or headers are ignored.`}</p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Attributes`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Example`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`address`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Required. Valid email address.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><a parentName="td" {...{
                  "href": "mailto:demo@messagebird.com"
                }}>{`demo@messagebird.com`}</a></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`name`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Name attached to the email address, this appears in the To field in a users email client.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Jane Doe`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`variables`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`object`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`List of variables used for placeholders inside the content or headers of your email.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
          </tbody>
        </table>
        <a name="tracking"></a>
        <h3>{`Tracking`}</h3>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Attributes`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`open`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`bool`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Adds a tracking pixel to handle `}<inlineCode parentName="td">{`message.opened`}</inlineCode>{` events. (Default: true)`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`click`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`bool`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Adds link-wrapping to handle `}<inlineCode parentName="td">{`link.clicked`}</inlineCode>{` events. (Default: true)`}</td>
            </tr>
          </tbody>
        </table>
        <a name="attachment"></a>
        <h5>{`Attachment`}</h5>
        <p>{`The Attachment object represents a file attached to a particular message. The maximum attachment size is 20 MB.`}</p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Attributes`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`id`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Attachment ID.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`name`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`File name.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`type`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`File type.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`URL`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`URL of the file to be attached to the message.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`length`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`File size in bytes.`}</td>
            </tr>
          </tbody>
        </table>
        <a name="inline-image"></a>
        <h5>{`Inline images`}</h5>
        <p>{`The Inline Image object represents a image added to the message content. The maximum image size is 20 MB.`}</p>
        <p>{`In order to use the inline image in the content of the message, you must refer the `}<inlineCode parentName="p">{`contentId`}</inlineCode>{` using the `}<inlineCode parentName="p">{`img`}</inlineCode>{` tag. For example: `}<inlineCode parentName="p">{`<img src="cid:<contentId>">`}</inlineCode></p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Attributes`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`id`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Inline image ID.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`name`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`File name.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`type`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`File type.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`URL`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`URL of the file.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`length`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`File size in bytes.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`contentId`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`ID used in the content of the message.`}</td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Example Message</h4>
  <CodeSnippet code={CODE.MESSAGE_OBJ} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Send a Message`}</h2>
        <p>{`Send a new message to one or many recipients.`}</p>
        <p>{`You won't be able to send any messages until the domain for your channel is verified after adding the DKIM record to your DNS. Keep in mind that DNS changes may take some time to propagate.`}</p>
        <p>{`Before sending a message with attachments or inline images, you need to upload your files/images to the Messaging API and then use IDs in the payload. For more information about how to upload attachments, refer to the `}<a parentName="p" {...{
            "href": "/api/email/#upload-attachments-and-inline-images"
          }}>{`Uploading attachments and inline images`}</a>{` section.`}</p>
        <p>{`The entire message content, including all attachments, inline images, text and html, should not exceed 20 MB, otherwise they will be rejected.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.SEND_DEF} mdxType="CodeSnippet" />
  <h4>Example: Send an HTML message to a single recipient</h4>
  <CodeSnippet code={CODE.SEND_REQ_HTML_SINGLE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h3>{`Send a templated message`}</h3>
        <p>{`It's possible to add placeholder variables to your email. You can insert these using double curly braces. The value of each placeholder key needs to be set on a per recipient basis in their `}<inlineCode parentName="p">{`variables`}</inlineCode>{` array.`}</p>
        <p>{`All variables will be escaped by default. However, if you would like to keep raw encoding for URLs or inline HTML for example, you can use triple curly braces.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Example: Send a templated message to multiple recipients</h4>
  <CodeSnippet code={CODE.SEND_REQ_TEMPLATE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h3>{`Response`}</h3>
        <p>{`A object with an ID for the message, which you can use to track webhook events generated by the delivery of this message.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Example response</h4>
  <CodeSnippet code={CODE.SEND_RESP} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <a name="upload-attachments-and-inline-images"></a>
        <h3>{`Upload attachments and inline images`}</h3>
        <p>{`Before sending an email with attachments or inline images, they need to be uploaded to the Messaging API. It is mandatory to provide the `}<inlineCode parentName="p">{`Content-Type`}</inlineCode>{` in the HTTP headers, and also chucked transmission is not supported yet.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.UPLOAD_FILE_DEF} mdxType="CodeSnippet" />
  <h4>Example: Uploading a file to Messaging API</h4>
  <CodeSnippet code={CODE.UPLOAD_FILE_REQ} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h3>{`Response`}</h3>
        <p>{`Returns an object with an ID, which you can use while sending an email with attachments or inline image.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Example response</h4>
  <CodeSnippet code={CODE.UPLOAD_FILE_RES} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <a name="fetching-file"></a>
        <h3>{`Fetching files`}</h3>
        <p>{`You need to provide the ID for fetching the attachment/inline image. The ID has given you when you uploaded the attachment/inline image.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.FETCH_FILE_DEF} mdxType="CodeSnippet" />
  <h4>Example: Fetches the file from Messaging API</h4>
  <CodeSnippet code={CODE.FETCH_FILE_REQ} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <h2>{`Receiving status updates for sent Messages`}</h2>
    <p>{`You can request that message delivery events be sent to your server through Webhooks. When sending a message, the optional `}<inlineCode parentName="p">{`reportUrl`}</inlineCode>{` attribute can be used to provide the url of where you want us to deliver webhook notifications for events generated by that message. The message ID that you were returned on sending will be included for all events generated by that message. If the report url is provided, you will receive POST requests with JSON content of events for:`}</p>
    <ul>
      <li parentName="ul">{`Delivery statuses: accepted, sent, opened, or failed, per each individual recipient in the message.`}</li>
      <li parentName="ul">{`Link tracking: triggered whenever a link is clicked in your email by an individual recipient.`}</li>
    </ul>
    <p>{`When dealing with failed messages you can use the code in the `}<inlineCode parentName="p">{`error`}</inlineCode>{` object to programmatically check what kind of
failure occurred and the description will give more detail if available.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`10`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Bounced email`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`11`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Email marked as spam`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Emails being sent to multiple recipients will generate webhooks for each recipient. For example, sending a message to 500 recipients would generate ~1500 events when each individual recipient email is accepted, sent, and opened.`}</p>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h3>{`Examples`}</h3>
        <p>{`Example webhook formats for the possible events are provided.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Example: Message Accepted</h4>
  <CodeSnippet code={CODE.WEBHOOK_EXAMPLE_MESSAGE_ACCEPTED} mdxType="CodeSnippet" />
  <h4>Example: Message Sent</h4>
  <CodeSnippet code={CODE.WEBHOOK_EXAMPLE_MESSAGE_SENT} mdxType="CodeSnippet" />
  <h4>Example: Message Opened</h4>
  <CodeSnippet code={CODE.WEBHOOK_EXAMPLE_MESSAGE_OPENED} mdxType="CodeSnippet" />
  <h4>Example: Message Failed</h4>
  <CodeSnippet code={CODE.WEBHOOK_EXAMPLE_MESSAGE_FAILED} mdxType="CodeSnippet" />
  <h4>Example: Link Clicked</h4>
  <CodeSnippet code={CODE.WEBHOOK_EXAMPLE_LINK_CLICKED} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Receiving email`}</h2>
        <p>{`For instances where you need to receive emails, for example customer support use cases, you can configure your channel to enable inbound email.`}</p>
        <p>{`To receive email on your MessageBird channel you need to setup a Forwarding Rule with your mail provider.`}</p>
        <p>{`You can forward email to `}<inlineCode parentName="p">{`<channel ID>@fwd-sp.messagebird.com`}</inlineCode>{` to have MessageBird receive a copy of inbound mail, and receive it in your application via webhooks. The original email will still exist in your mail provider.`}</p>
        <p>{`To receive webhook notifications when an inbound email arrives, you can set an Inbound Webhook on your Channel configuration page.
Make sure you set up your webhook before adding the Forwarding Rule as some providers are known to send confirmation emails to the designated inbox to test for authorization.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Received Email webhook example</h4>
  <CodeSnippet code={CODE.WEBHOOK_EXAMPLE_MESSAGE_RECEIVED} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      