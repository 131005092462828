export const NUMBERS_ENDPOINT = {
  bash: `https://numbers.messagebird.com`,
};

export const NUMBERS_OBJECT = {
  json: `  {
  "errors": [
    {
      "code": 1001,
      "message": "You are requesting with an invalid credential.",
      "parameter": null
    }
  ]
}`,
};

export const SEARCHNUMBERS_DEF = {
  bash: `GET /v1/available-phone-numbers/{countryCode}`,
};

export const SEARCHNUMBERS_REQUEST = {
  curl: `curl -X GET "https://numbers.messagebird.com/v1/available-phone-numbers/NL?features=sms&features=voice&type=mobile&number=319&search_pattern=start&limit=25" \\
  -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM" `,
};

export const SEARCHNUMBERS_RESPONSE = {
  json: `{
  "items": [
    {
      "number": "3197010260188",
      "country": "NL",
      "region": "",
      "locality": "",
      "features": [
          "sms",
          "voice"
      ],
      "type": "mobile",
      "verificationRequired": true,
      "initialContractDuration": 2,
      "inboundCallsOnly": false,
      "monthlyPrice": 1,
      "currency": "EUR",
      "conditions": [
          "Proof of in region address (max 3 months old)"
      ]
    }
  ],
  "limit": 20,
  "count": 1
}`,
};

export const PURCHASENUMBERS_DEF = {
  bash: `POST /v1/phone-numbers`,
};

export const PURCHASENUMBERS_REQUEST = {
  curl: `curl -X POST "https://numbers.messagebird.com/v1/phone-numbers" \\
  -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM" \\
  -H "Content-Type: application/json" \\
  -d '{
    "number": "31971234567",
    "countryCode": "NL", 
    "billingIntervalMonths": 1
  }'`,
};

export const PURCHASENUMBERS_RESPONSE = {
  json: `{
  "number": "31971234567",
  "country": "NL",
  "region": "Haarlem",
  "locality": "Haarlem",
  "features": [
    "sms",
    "voice"
  ],
  "tags": [],
  "type": "landline_or_mobile",
  "status": "active",
  "createdAt": "2019-04-25T14:04:04Z",
  "renewalAt": "2019-05-25T00:00:00Z"
}`,
};

export const FETCHNUMBERS_DEF = {
  bash: `GET /v1/phone-numbers`,
};

export const FETCHNUMBERS_REQUEST = {
  curl: `curl -X GET "https://numbers.messagebird.com/v1/phone-numbers?type=mobile&features=sms&features=voice" \\
  -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM" `,
};

export const FETCHNUMBERS_RESPONSE = {
  json: `{
  "offset": 0,
  "limit": 20,
  "count": 1,
  "totalCount": 1,
  "items": [
    {
      "number": "31612345670",
      "country": "NL",
      "region": "Texel",
      "locality": "Texel",
      "features": [
        "sms",
        "voice"
      ],
      "tags": [],
      "type": "mobile",
      "status": "active"
    }
  ]
}`,
};

export const FETCH1NUMBERS_DEF = {
  bash: `GET /v1/phone-numbers/{phoneNumber}`,
};

export const FETCH1NUMBERS_REQUEST = {
  curl: `curl -X GET "https://numbers.messagebird.com/v1/phone-numbers/31612345670" \\
  -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM"`,
};

export const FETCH1NUMBERS_RESPONSE = {
  json: `{
  "number": "31612345670",
  "country": "NL",
  "region": "Texel",
  "locality": "Texel",
  "features": [
    "sms",
    "voice"
  ],
  "tags": [],
  "type": "mobile",
  "status": "active"
}`,
};

export const FETCHNUMBERDOCS_DEF = {
  bash: `GET /v1/phone-numbers/{phoneNumber}/documents`,
};

export const FETCHNUMBERDOCS_REQUEST = {
  curl: `curl -X GET "https://numbers.messagebird.com/v1/phone-numbers/31612345670/documents" \\
  -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM"`,
};

export const FETCHNUMBERDOCS_RESPONSE = {
  json: `[
    {
        "id": 3,
        "name": "Proof of local address (max 3 months old)",
        "description": "The number-user must provide proof that he has an in-region address",
        "status": "missing",
        "checked_at": "",
        "reject_reason": "",
        "file_name": "",
        "uploaded_at": "",
        "mime_type": "",
        "content": ""
    }
]`,
};

export const UPLOADNUMBERDOCS_DEF = {
  bash: `POST /v1/phone-numbers/{phoneNumber}/documents`,
};

export const UPLOADNUMBERDOCS_REQUEST = {
  curl: `curl -X POST "https://numbers.messagebird.com/v1/phone-numbers/31612345670/documents" \\
  -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM" \\
  -H "Content-Type: application/json" \\
  -d '{
    "id": 3,
    "name": "messagebird-kyc-upload-test.txt",
    "mimeType": "text/plain",
    "content": "RG9jdW1lbnQgdGhhdCBnb2luZyB0byBiZSB2ZXJpZmllZCBieSBhIHBlcnNvbi4="
  }'`,
};

export const UPLOADNUMBERDOCS_RESPONSE = {
  bash: `HTTP/1.1 204 No Content`,
};

export const UPDATENUMBERS_DEF = {
  bash: `PATCH /v1/phone-numbers/{phoneNumber}`,
};

export const UPDATENUMBERS_REQUEST = {
  curl: `curl -X PATCH "https://numbers.messagebird.com/v1/phone-numbers/31612345670" \\
  -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM" \\
  -H "Content-Type: application/json" \\
  -d '{
    "tags":["tag1"]
  }'`,
};

export const UPDATENUMBERS_RESPONSE = {
  json: `{
  "number": "31612345670",
  "country": "NL",
  "region": "Texel",
  "locality": "Texel",
  "features": [
    "sms",
    "voice"
  ],
  "tags": ["tag1"],
  "type": "mobile",
  "status": "active"
}`,
};

export const CANCELNUMBERS_DEF = {
  bash: `DELETE /v1/phone-numbers/{phoneNumber}`,
};

export const CANCELNUMBERS_REQUEST = {
  curl: `curl -X DELETE "https://numbers.messagebird.com/v1/phone-numbers/31612345670" \\
  -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM" \\
  -H "Content-Type: application/json"`,
};

export const CANCELNUMBERS_RESPONSE = {
  bash: `HTTP/1.1 204 No Content`,
};

export const SEARCHPRODUCTS_DEF = {
  bash: `GET /v1/products`,
};

export const SEARCHPRODUCTS_REQUEST = {
  curl: `curl -X GET "https://numbers.messagebird.com/v1/products?features=voice&type=bv&country=GB" \\
  -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM" `,
};

export const SEARCHPRODUCTS_RESPONSE = {
  json: `{
    "limit": 20,
    "count": 1,
    "items": [
        {
            "numberType": "bv",
            "verificationRequired": true,
            "country": "GB",
            "id": 1993,
            "currency": "EUR",
            "price": 1
        }
    ]
}`,
};

export const SEARCHPREFIXES_DEF = {
  bash: `GET /v1/products/{productID}`,
};

export const SEARCHPREFIXES_REQUEST = {
  curl: `curl -X GET "https://numbers.messagebird.com/v1/products/1993" \\
  -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM" `,
};

export const SEARCHPREFIXES_RESPONSE = {
  json: `{
  "country": "GB",
  "numberType": "bv",
  "backOrderLeadTime": "5 - 10 Business days",
  "reachableFromNationalFixed": true,
  "reachableFromNationalMobile": true,
  "reachableFromPayPhone": true,
  "verificationRequired": true,
  "initialContractDuration": "1 year",
  "prefixes": [
    {
        "prefix": "44113",
        "city": "Leeds",
        "stateProv": "",
        "prefixType": "Geographic"
    },
    {
        "prefix": "44114",
        "city": "Sheffield",
        "stateProv": "",
        "prefixType": "Geographic"
    },
    {
        "prefix": "44115",
        "city": "Nottingham",
        "stateProv": "",
        "prefixType": "Geographic"
    }
],
  "remarks": [
      "Customer support is NOT allowed on a PRS number",
      "The session pricing on your price list will reflect the number cost."
  ],
  "conditions": [
      "Business use: VAT number",
      "Service description required",
      "Outbound calling not permitted",
      "Business use : Name &amp; Address",
      "Entertainment Services",
      "A native speaking helpdesk is mandatory",
      "Customer support email",
      "URL content-provider &amp; URL content",
      "Customer support national phone number"
  ],
  "endUserData": [
      "End user name",
      "End user address",
      "VAT number",
      "Content description",
      "Customer care phone",
      "Customer care e-mail"
  ],
  "forbiddenContent": [
      "Adult content"
  ]
}
`,
};

export const PLACEBACKORDER_DEF = {
  bash: `POST /v1/backorders`,
};

export const PLACEBACKORDER_REQUEST = {
  curl: `curl -X POST "https://numbers.messagebird.com/v1/backorders" \\
  -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM" \\
  -H "Content-Type: application/json" \\
  -d '{
    "productID": 1993,
    "quantity": 3, 
    "prefix": "31114"
  }'`,
};

export const PLACEBACKORDER_RESPONSE = {
  json: `{
    "id": "48f6057c21de42d4bbf73fb86caaf361"
}`,
};

export const FETCH1BACKORDER_DEF = {
  bash: `GET /v1/backorders/{backOrderID}`,
};

export const FETCH1BACKORDER_REQUEST = {
  curl: `curl -X GET "https://numbers.messagebird.com/v1/backorders/48f6057c21de42d4bbf73fb86caaf361" \\
  -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM"`,
};

export const FETCH1BACKORDER_RESPONSE = {
  json: `{
    "id": "48f6057c21de42d4bbf73fb86caaf361",
    "productID": 1993,
    "country": "GB",
    "prefix": "44113",
    "status": "blocked",
    "reasonCodes": [
        "MISSING_KYC",
        "MISSING_EUD"
    ]
}`,
};

export const FETCHBACKORDERKYC_DEF = {
  bash: `GET /v1/backorders/{backOrderID}/documents`,
};

export const FETCHBACKORDERKYC_REQUEST = {
  curl: `curl -X GET "https://numbers.messagebird.com/v1/backorders/48f6057c21de42d4bbf73fb86caaf361/documents" \\
  -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM"`,
};

export const FETCHBACKORDERKYC_RESPONSE = {
  json: `{
    "limit": 20,
    "count": 1,
    "items": [
        {
            "id": 62,
            "name": "Proof of in region address (max 3 months old)",
            "description": "The number-user must provide proof that he has an address within the region of the prefix.",
            "status": "missing"
        }
    ]
}`,
};

export const UPLOADBACKORDERKYC_DEF = {
  bash: `POST /v1/backorders/{backOrderID}/documents`,
};

export const UPLOADBACKORDERKYC_REQUEST = {
  curl: `curl -X POST "https://numbers.messagebird.com/v1/backorders/48f6057c21de42d4bbf73fb86caaf361/documents" \\
  -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM" \\
  -H "Content-Type: application/json" \\
  -d '{
    "id": 62,
    "name": "messagebird-kyc-upload-test.txt",
    "mimeType": "text/plain",
    "content": "RG9jdW1lbnQgdGhhdCBnb2luZyB0byBiZSB2ZXJpZmllZCBieSBhIHBlcnNvbi4="
  }'`,
};

export const UPLOADBACKORDERKYC_RESPONSE = {
  bash: `HTTP/1.1 204 No Content`,
};

export const FETCHBACKORDEREUD_DEF = {
  bash: `GET /v1/backorders/{backOrderID}/end-user-details`,
};

export const FETCHBACKORDEREUD_REQUEST = {
  curl: `curl -X GET "https://numbers.messagebird.com/v1/backorders/48f6057c21de42d4bbf73fb86caaf361/end-user-details" \\
  -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM"`,
};

export const FETCHBACKORDEREUD_RESPONSE = {
  json: `{
    "items": [
        {
            "id": "CompanyName",
            "label": "Company name"
        },
        {
            "id": "Street",
            "label": "Street"
        },
        {
            "id": "StreetNumber",
            "label": "Street number"
        },
        {
            "id": "ZipCode",
            "label": "Zip code"
        },
        {
            "id": "City",
            "label": "City"
        },
        {
            "id": "Country",
            "label": "Country"
        }
    ]
}`,
};

export const UPLOADBACKORDEREUD_DEF = {
  bash: `POST /v1/backorders/{backOrderID}/end-user-details`,
};

export const UPLOADBACKORDEREUD_REQUEST = {
  curl: `curl -X POST "https://numbers.messagebird.com/v1/backorders/48f6057c21de42d4bbf73fb86caaf361/end-user-details" \\
  -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM" \\
  -H "Content-Type: application/json" \\
  -d '{
    "CompanyName": "Messagebird",
    "Street": "Burgwal",
    "StreetNumber": "35",
    "ZipCode": "2011",
    "City": "Amsterdam",
    "Country": "Netherlands"
    }'`,
};

export const UPLOADBACKORDEREUD_RESPONSE = {
  bash: `HTTP/1.1 204 No Content`,
};

// Create pool
export const CREATEPOOL_DEF = {
  bash: `POST /v1/pools`,
};

export const CREATEPOOL_REQUEST = {
  curl: `curl -X POST "https://numbers.messagebird.com/v1/pools" \\
  -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM" \\
  -H "Content-Type: application/json" \\
  -d '{
    "poolName": "myPool",
    "service": "randomcli", 
    "configuration": {
      "byCountry": false
    }
  }'`,
};

export const CREATEPOOL_RESPONSE = {
  json: `{
    "id": "726db88c-0fd6-44e8-8f0d-9ce2b2a5e16f",
    "name": "myPool",
    "service": "randomcli",
    "createdAt": "2021-12-15T13:40:38.617645742Z",
    "updatedAt": "2021-12-15T13:40:38.617645742Z",
    "numbersCount": 0,
    "configuration": {
      "byCountry": false
    }
  }`,
};

// Fetch number pool

export const FETCH1POOL_DEF = {
  bash: `GET /v1/pools/{poolName}`,
};

export const FETCH1POOL_REQUEST = {
  curl: `curl -X GET "https://numbers.messagebird.com/v1/pools/myPool" \\
  -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM"`,
};

export const FETCH1POOL_RESPONSE = {
  json: `{
    "id": "726db88c-0fd6-44e8-8f0d-9ce2b2a5e16f",
    "name": "myPool",
    "service": "randomcli",
    "createdAt": "2021-12-15T13:40:38.617645742Z",
    "updatedAt": "2021-12-15T13:40:38.617645742Z",
    "numbersCount": 5,
    "configuration": {
      "byCountry": false
    }
  }`,
};

// Update a number pool

export const UPDATEPOOL_DEF = {
  bash: `PUT /v1/pools/{poolName}`,
};

export const UPDATEPOOL_REQUEST = {
  curl: `curl -X PUT "https://numbers.messagebird.com/v1/pools/myPool" \\
  -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM" \\
  -H "Content-Type: application/json" \\
  -d '{
    "poolName": "myRenamedPool",
    "configuration": {
        "byCountry": true
    }
  }'`,
};

export const UPDATEPOOL_RESPONSE = {
  json: `{
    "id": "726db88c-0fd6-44e8-8f0d-9ce2b2a5e16f",
    "name": "myRenamedPool",
    "service": "randomcli",
    "createdAt": "2021-12-15T13:40:38.617645742Z",
    "updatedAt": "2021-12-17T14:12:17.664352935Z",
    "numbersCount": 5,
    "configuration": {
      "byCountry": true
    }
  }`,
};

// Remove a number pool

export const DELETENUMBERPOOL_DEF = {
  bash: `DELETE /v1/pools/{poolName}`,
};

export const DELETENUMBERPOOL_REQUEST = {
  curl: `curl -X DELETE "https://numbers.messagebird.com/v1/pools/myPool" \\
  -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM" \\
  -H "Content-Type: application/json"`,
};

export const DELETENUMBERPOOL_RESPONSE = {
  bash: `HTTP/1.1 204 No Content`,
};

// List pools

export const LISTNUMBERPOOLS_DEF = {
  bash: `GET /v1/pools`,
};

export const LISTNUMBERPOOLS_REQUEST = {
  curl: `curl -X GET "https://numbers.messagebird.com/v1/pools?name=myPool&service=randomcli&limit=1&offset=0" \\
  -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM" \\
  -H "Content-Type: application/json"`,
};

export const LISTNUMBERPOOLS_RESPONSE = {
  json: `{
  "limit": 1,
  "offset": 0,
  "count": 1,
  "totalCount": 5,
  "items": [
    {
      "name": "myPool",
      "service": "randomcli",
      "createdAt": "2021-12-16T15:27:04.342Z",
      "numbersCount": 10
    }
  ]
}`,
};

// List numbers in a pool

export const LISTPOOLNUMBERS_DEF = {
  bash: `GET /v1/pools/{poolName}/numbers`,
};

export const LISTPOOLNUMBERS_REQUEST = {
  curl: `curl -X GET "https://numbers.messagebird.com/v1/pools/myPool/numbers?limit=20&offset=0&number=316" \\
  -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM" \\
  -H "Content-Type: application/json"`,
};

export const LISTPOOLNUMBERS_RESPONSE = {
  json: `{
    "limit": 20,
    "offset": 0,
    "count": 3,
    "totalCount": 3,
    "numbers": [
      "31612345678",
      "31612345679",
      "31612345670"
    ],
  }`,
};

// Add numbers to a pool

export const ADDNUMBERSTOPOOL_DEF = {
  bash: `POST /v1/pools/{poolName}/numbers`,
};

export const ADDNUMBERSTOPOOL_REQUEST = {
  curl: `curl -X POST "https://numbers.messagebird.com/v1/pools/myPool/numbers" \\
  -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM" \\
  -H "Content-Type: application/json" \\
  -d '{
    "numbers": [
      "31612345678",
      "31612345679",
      "31612345670"
    ],
  }'`,
};

export const ADDNUMBERSTOPOOL_RESPONSE = {
  json: `{
  "success": [
    "31612345678",
    "31612345679"
  ],
  "fail": [
    {
      "number": "31612345670",
      "error": "number is not verified"
    }
  ]
}`,
};

// Remove numbers from pool
export const REMOVENUMBERSFROMPOOL_DEF = {
  bash: `DELETE /v1/pools/{poolName}/numbers`,
};

export const REMOVENUMBERSFROMPOOL_REQUEST = {
  curl: `curl -X DELETE "https://numbers.messagebird.com/v1/pools/myPool/numbers?numbers=31612345678,31612345679,31612345670" \\
  -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM" \\
  -H "Content-Type: application/json"`,
};

export const REMOVENUMBERSFROMPOOL_RESPONSE = {
  bash: `HTTP/1.1 204 No Content`,
};
