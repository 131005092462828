export const TASKS_ENDPOINT = {
  bash: `https://tasks.messagebird.com`,
};

export const TASKS_OBJECT = {
  json: `  {
  "errors": [
    {
      "code": 1001,
      "message": "You are requesting with an invalid credential.",
      "parameter": null
    }
  ]
}`,
};

export const WORKFLOW_HTTP_METHODS = {
  bash: `GET /workflows
GET /workflows/{workflowId}
POST /workflows
PUT /workflows/{workflowId}
DELETE /workflows/{workflowId}`,
};

export const WORKFLOW_OBJECT = {
  json: `{
    "id": "3e5ee2c4-a51c-4a3b-a8cc-a251f1a4312d",
    "name": "My workflow",
    "filters": [
        {
            "id": "54d16e57-5757-45b2-8576-1f10c50240f0",
            "name": "Only sales voice call filter",
            "tasksExp": "1 == 1",
            "routingSteps": [
                {
                    "id": "17019f7d-09e8-47fb-ba36-0f73f2e89ee2",
                    "queueId": "0194e4db-7d32-4ad1-a7b3-f70c97bc871c",
                    "agentsExp": "1 == 1",
                    "timeout": 30
                },
                {
                    "id": "31245b0a-6e74-4c44-9708-424bac6870db",
                    "queueId": "7416cc20-0d62-42bb-8ca6-0f4587302756",
                    "agentsExp": "languages has \\"en\\"",
                    "timeout": 60
                }
            ]
        },
        {
            "id": "2c83e223-6080-4271-8086-185114a13114",
            "name": "Skill filtering",
            "tasksExp": "skill_level >= 3",
            "routingSteps": [
                {
                    "id": "0d431640-ab1d-4720-9878-d17c81299c2e",
                    "queueId": "2904e4db-7d32-4ad1-a7b3-f70c97bc87b5",
                    "agentsExp": "skills has \\"unix\\"",
                    "timeout": 0
                }
            ]
        }
    ],
    "defaultQueueId": "feb3cf71-0561-47f2-9e18-bcc0d81fee2a",
    "createdAt": "2018-10-10T09:00:00Z",
    "updatedAt": "2018-10-10T09:00:00Z"
  }`,
};

export const VIEWWORKFLOW_DEF = {
  bash: `GET /workflows/{workflowId}`,
};

export const VIEWWORKFLOW_REQUEST = {
  curl: `curl -X GET "https://tasks.messagebird.com/workflows/6beb6363-3fb7-4ebc-8a10-d3c4b022cbe8" \
  -H "Authorization: AccessKey YOUR_API_KEY" `,
};

export const VIEWWORKFLOW_RESPONSE = {
  json: `{
    "id": "6beb6363-3fb7-4ebc-8a10-d3c4b022cbe8",
    "name": "My workflow",
    "filters": [
        {
            "id": "54d16e57-5757-45b2-8576-1f10c50240f0",
            "name": "Only sales voice call filter",
            "tasksExp": "1 == 1",
            "routingSteps": [
                {
                    "id": "17019f7d-09e8-47fb-ba36-0f73f2e89ee2",
                    "queueId": "0194e4db-7d32-4ad1-a7b3-f70c97bc871c",
                    "agentsExp": "1 == 1",
                    "timeout": 30
                },
                {
                    "id": "31245b0a-6e74-4c44-9708-424bac6870db",
                    "queueId": "7416cc20-0d62-42bb-8ca6-0f4587302756",
                    "agentsExp": "languages has \\"en\\"",
                    "timeout": 60
                }
            ]
        },
        {
            "id": "2c83e223-6080-4271-8086-185114a13114",
            "name": "Skill filtering",
            "tasksExp": "skill_level >= 3",
            "routingSteps": [
                {
                    "id": "0d431640-ab1d-4720-9878-d17c81299c2e",
                    "queueId": "2904e4db-7d32-4ad1-a7b3-f70c97bc87b5",
                    "agentsExp": "skills has \\"unix\\"",
                    "timeout": 0
                }
            ]
        }
    ],
    "defaultQueueId": "feb3cf71-0561-47f2-9e18-bcc0d81fee2a",
    "createdAt": "2018-10-10T09:00:00Z",
    "updatedAt": "2018-10-10T09:00:00Z"
}`,
};

export const CREATEWORKFLOW_DEF = {
  bash: `POST /workflows`,
};

export const CREATEWORKFLOW_REQUEST = {
  curl: `curl -X POST "https://tasks.messagebird.com/workflows" \
     -H 'Content-Type: application/json' \
     -H "Authorization: AccessKey YOUR_API_KEY" \\
     -d $'{
    "name": "My workflow",
    "filters": [
        {
            "name": "Only sales voice call filter",
            "tasksExp": "1 == 1",
            "routingSteps": [
                {
                    "queueId": "0194e4db-7d32-4ad1-a7b3-f70c97bc871c",
                    "agentsExp": "1 == 1",
                    "timeout": 35
                },
                {
                    "queueId": "7416cc20-0d62-42bb-8ca6-0f4587302756",
                    "agentsExp": "languages has \\"en\\""
                }
            ]
        },
        {
            "name": "Skill filtering",
            "tasksExp": "skill_level >= 3",
            "routingSteps": [
                {
                    "queueId": "2904e4db-7d32-4ad1-a7b3-f70c97bc87b5",
                    "agentsExp": "skills has \\"unix\\""
                }
            ]
        }
    ],
    "defaultQueueId": "feb3cf71-0561-47f2-9e18-bcc0d81fee2a",
    "createdAt": "2018-10-10T09:00:00Z",
    "updatedAt": "2018-10-10T09:00:00Z"
  }'
`,
};

export const CREATEWORKFLOW_RESPONSE = {
  json: `HTTP/1.1 201 Created

{
  "id": "3e5ee2c4-a51c-4a3b-a8cc-a251f1a4312d",
  "name": "My workflow",
  "filters": [
      {
          "id": "54d16e57-5757-45b2-8576-1f10c50240f0",
          "name": "Only sales voice call filter",
          "tasksExp": "1 == 1",
          "routingSteps": [
              {
                  "id": "17019f7d-09e8-47fb-ba36-0f73f2e89ee2",
                  "queueId": "0194e4db-7d32-4ad1-a7b3-f70c97bc871c",
                  "agentsExp": "1 == 1",
                  "timeout": 35
              },
              {
                  "id": "31245b0a-6e74-4c44-9708-424bac6870db",
                  "queueId": "7416cc20-0d62-42bb-8ca6-0f4587302756",
                  "agentsExp": "languages has \\"en\\""
              }
          ]
      },
      {
          "id": "2c83e223-6080-4271-8086-185114a13114",
          "name": "Skill filtering",
          "tasksExp": "skill_level >= 3",
          "routingSteps": [
              {
                  "id": "0d431640-ab1d-4720-9878-d17c81299c2e",
                  "queueId": "2904e4db-7d32-4ad1-a7b3-f70c97bc87b5",
                  "agentsExp": "skills has \\"unix\\""
              }
          ]
      }
  ],
  "defaultQueueId": "feb3cf71-0561-47f2-9e18-bcc0d81fee2a",
  "createdAt": "2018-10-10T09:00:00Z",
  "updatedAt": "2018-10-10T09:00:00Z"
}`,
};

export const UPDATEWORKFLOW_DEF = {
  bash: `PUT /workflows/{workflowId}`,
};

export const UPDATEWORKFLOW_REQUEST = {
  curl: `curl -X PUT "https://tasks.messagebird.com/workflows/3e5ee2c4-a51c-4a3b-a8cc-a251f1a4312d" \
     -H 'Content-Type: application/json' \
     -H "Authorization: AccessKey YOUR_API_KEY" \\
     -d $'{
    "name": "My workflow",
    "filters": [
        {
            "name": "Only sales voice call filter",
            "tasksExp": "1 == 1",
            "routingSteps": [
                {
                    "queueId": "0194e4db-7d32-4ad1-a7b3-f70c97bc871c",
                    "agentsExp": "1 == 1",
                    "timeout": 35
                },
                {
                    "queueId": "7416cc20-0d62-42bb-8ca6-0f4587302756",
                    "agentsExp": "languages has \\"en\\""
                }
            ]
        },
        {
            "name": "Skill filtering",
            "tasksExp": "skill_level >= 3",
            "routingSteps": [
                {
                    "queueId": "2904e4db-7d32-4ad1-a7b3-f70c97bc87b5",
                    "agentsExp": "skills has \\"unix\\""
                }
            ]
        }
    ],
    "defaultQueueId": "feb3cf71-0561-47f2-9e18-bcc0d81fee2a",
    "createdAt": "2018-10-10T09:00:00Z",
    "updatedAt": "2018-10-10T09:00:00Z"
  }'
`,
};

export const UPDATEWORKFLOW_RESPONSE = {
  json: `HTTP/1.1 200 OK

{
  "id": "3e5ee2c4-a51c-4a3b-a8cc-a251f1a4312d",
  "name": "My updated workflow",
  "filters": [
      {
          "id": "54d16e57-5757-45b2-8576-1f10c50240f0",
          "name": "Only sales voice call filter",
          "tasksExp": "1 == 1",
          "routingSteps": [
              {
                  "id": "17019f7d-09e8-47fb-ba36-0f73f2e89ee2",
                  "queueId": "0194e4db-7d32-4ad1-a7b3-f70c97bc871c",
                  "agentsExp": "1 == 1"
              },
              {
                  "id": "31245b0a-6e74-4c44-9708-424bac6870db",
                  "queueId": "7416cc20-0d62-42bb-8ca6-0f4587302756",
                  "agentsExp": "languages has \\"en\\""
              }
          ]
      },
      {
          "id": "2c83e223-6080-4271-8086-185114a13114",
          "name": "Skill filtering",
          "tasksExp": "skill_level >= 3",
          "routingSteps": [
              {
                  "id": "0d431640-ab1d-4720-9878-d17c81299c2e",
                  "queueId": "2904e4db-7d32-4ad1-a7b3-f70c97bc87b5",
                  "agentsExp": "skills has \\"unix\\""
              }
          ]
      }
  ],
  "defaultQueueId": "feb3cf71-0561-47f2-9e18-bcc0d81fee2a",
  "createdAt": "2018-10-10T09:00:00Z",
  "updatedAt": "2018-10-10T09:00:00Z"
}`,
};

export const DELETEWORKFLOW_DEF = {
  bash: `DELETE /workflows/{workflowId}`,
};

export const DELETEWORKFLOW_REQUEST = {
  curl: `curl -X DELETE "https://tasks.messagebird.com/workflows/6beb6363-3fb7-4ebc-8a10-d3c4b022cbe8" \
  -H "Authorization: AccessKey YOUR_API_KEY"`,
};

export const DELETEWORKFLOW_RESPONSE = {
  json: `HTTP/1.1 204 No Content`,
};

export const QUEUE_HTTP_METHODS = {
  bash: `GET /queues
GET /queues/{queueId}
POST /queues
PUT /queues/{queueId}
DELETE /queues/{queueId}
  `,
};

export const QUEUE_OBJECT = {
  json: `{
  "id": "2ca814f5-d512-4fb2-b11c-4cb0c82c008f",
  "name": "English Sales Queue",
  "agentsExp": "languages has \\"en\\" and skills has \\"sales\\"",
  "createdAt": "2018-10-10T09:00:00Z",
  "updatedAt": "2018-10-10T09:00:00Z"
}`,
};

export const VIEWQUEUE_DEF = {
  bash: `GET /queues/{queueId}`,
};

export const VIEWQUEUE_REQUEST = {
  curl: `curl -X GET "https://tasks.messagebird.com/queues/6beb6363-3fb7-4ebc-8a10-d3c4b022cbe8" \
  -H "Authorization: AccessKey YOUR_API_KEY" `,
};

export const VIEWQUEUE_RESPONSE = {
  json: `{
    "id": "6beb6363-3fb7-4ebc-8a10-d3c4b022cbe8",
    "name": "English Sales Queue",
    "agentsExp": "languages has \\"en\\" and skills has \\"sales\\"",
    "createdAt": "2018-10-10T09:00:00Z",
    "updatedAt": "2018-10-10T09:00:00Z"
}`,
};

export const CREATEQUEUE_DEF = {
  bash: `POST /queues`,
};

export const CREATEQUEUE_REQUEST = {
  curl: `curl -X POST "https://tasks.messagebird.com/queues" \
     -H 'Content-Type: application/json' \
     -H "Authorization: AccessKey YOUR_API_KEY" \\
     -d $'{
    "name": "Sales",
    "agentsExp": "skills has \\"sales\\""
  }'
`,
};

export const CREATEQUEUE_RESPONSE = {
  json: `HTTP/1.1 201 Created

  {
      "id": "2ca814f5-d512-4fb2-b11c-4cb0c82c008f",
      "name": "Sales Foobar Queue",
      "agentsExp": "skills has \\"sales\\"",
      "createdAt": "2018-10-10T09:00:00Z",
      "updatedAt": "2018-10-10T09:00:00Z"
  }`,
};

export const UPDATEQUEUE_DEF = {
  bash: `PUT /queues/{queueId}`,
};

export const UPDATEQUEUE_REQUEST = {
  curl: `curl -X PUT "https://tasks.messagebird.com/queues/2ca814f5-d512-4fb2-b11c-4cb0c82c008f" \
     -H 'Content-Type: application/json' \
     -H "Authorization: AccessKey YOUR_API_KEY" \\
     -d $'{
	"name": "Sales Technical Foobar",
    "agentsExp": "skills has \\"technical\\" and skills has \\"sales\\""
  }'
`,
};

export const UPDATEQUEUE_RESPONSE = {
  json: `HTTP/1.1 200 OK

{
  "id": "2ca814f5-d512-4fb2-b11c-4cb0c82c008f",
  "name": "Sales Technical Foobar",
  "agentsExp": "skills has \\"technical\\" and skills has \\"sales\\"",
  "createdAt": "2018-10-10T09:00:00Z",
  "updatedAt": "2018-10-10T09:01:00Z"
}`,
};

export const DELETEQUEUE_DEF = {
  bash: `DELETE /queues/{queueId}`,
};

export const DELETEQUEUE_REQUEST = {
  curl: `curl -X DELETE "https://tasks.messagebird.com/queues/6beb6363-3fb7-4ebc-8a10-d3c4b022cbe8" \
  -H "Authorization: AccessKey YOUR_API_KEY"`,
};

export const DELETEQUEUE_RESPONSE = {
  json: `HTTP/1.1 204 No Content`,
};

export const AGENT_HTTP_METHODS = {
  bash: `GET /agents
GET /agents/{agentId}
POST /agents
PUT /agents/{agentId}
DELETE /agents/{agentId}
  `,
};

export const AGENT_OBJECT = {
  json: `{
    "id": "ff1cd408-8251-4d64-b147-aa3f61a95254",
    "name": "Alice",
    "attributes": {
        "skills": [
            "sales"
        ],
        "languages": [
            "en",
            "nl"
        ]
    },
    "createdAt": "2018-10-10T09:00:00Z",
    "updatedAt": "2018-10-10T09:01:00Z"
}`,
};

export const LISTAGENTS_DEF = {
  bash: `GET /agents`,
};

export const LISTAGENTS_REQUEST = {
  curl: `curl -X GET "https://tasks.messagebird.com/queues" \
  -H "Authorization: AccessKey YOUR_API_KEY" `,
};

export const LISTAGENTS_RESPONSE = {
  json: `{
    {
      "id": "ff1cd408-8251-4d64-b147-aa3f61a95546",
      "name": "John",
      "attributes": {
          "skills": [
              "finance"
          ],
          "languages": [
              "en",
              "es"
          ]
      },
      "createdAt": "2018-10-10T09:00:00Z",
      "updatedAt": "2018-10-10T09:01:00Z"
  },
  {
    "id": "ff1cd408-8251-4d64-b147-aa3f61a95254",
    "name": "Alice",
    "attributes": {
        "skills": [
            "sales"
        ],
        "languages": [
            "en",
            "nl"
        ]
    },
    "createdAt": "2018-10-10T09:00:00Z",
    "updatedAt": "2018-10-10T09:01:00Z"
}
}`,
};

export const VIEWAGENT_DEF = {
  bash: `GET /agents/{agentId}`,
};

export const VIEWAGENT_REQUEST = {
  curl: `curl -X GET "https://tasks.messagebird.com/agents/6beb6363-3fb7-4ebc-8a10-d3c4b022cbe8" \
  -H "Authorization: AccessKey YOUR_API_KEY" `,
};

export const VIEWAGENT_RESPONSE = {
  json: `{
    "id": "6beb6363-3fb7-4ebc-8a10-d3c4b022cbe8",
    "name": "Alice",
    "attributes": {
        "skills": [
            "sales"
        ],
        "languages": [
            "en",
            "nl"
        ]
    },
    "createdAt": "2018-10-10T09:00:00Z",
    "updatedAt": "2018-10-10T09:01:00Z"
}`,
};

export const CREATEAGENT_DEF = {
  bash: `POST /agents`,
};

export const CREATEAGENT_REQUEST = {
  curl: `curl -X POST "https://tasks.messagebird.com/agents" \
     -H 'Content-Type: application/json' \
     -H "Authorization: AccessKey YOUR_API_KEY" \\
     -d $'{
    "name": "Alice",
    "attributes": {
        "skills": [
            "sales"
        ],
        "languages": [
            "en"
        ]
    }
  }'

   -d $'{
    "name": "Sales",
    "agentsExp": "skills has \\"sales\\""
  }'
`,
};

export const CREATEAGENT_RESPONSE = {
  json: `HTTP/1.1 201 Created

  {
      "id": "ff1cd408-8251-4d64-b147-aa3f61a95254",
      "name": "Alice",
      "attributes": {
          "skills": [
              "sales"
          ],
          "languages": [
              "en",
              "nl"
          ]
      },
      "createdAt": "2018-10-10T09:00:00Z",
      "updatedAt": "2018-10-10T09:01:00Z"
  }`,
};

export const UPDATEAGENT_DEF = {
  bash: `PUT /agents/{agentId}`,
};

export const UPDATEAGENT_REQUEST = {
  curl: `curl -X PUT "https://tasks.messagebird.com/agents/ff1cd408-8251-4d64-b147-aa3f61a95254" \
     -H 'Content-Type: application/json' \
     -H "Authorization: AccessKey YOUR_API_KEY" \\
     -d $'{
    "attributes": {
        "skills": [
            "sales"
        ],
        "languages": [
            "en",
            "nl"
        ]
    }
  }'
`,
};

export const UPDATEAGENT_RESPONSE = {
  json: `HTTP/1.1 200 OK

  {
      "id": "ff1cd408-8251-4d64-b147-aa3f61a95254",
      "name": "Alice",
      "attributes": {
          "skills": [
              "sales"
          ],
          "languages": [
              "en",
              "nl"
          ]
      },
      "createdAt": "2018-10-10T09:00:00Z",
      "updatedAt": "2018-10-10T09:01:00Z"
  }
  `,
};

export const DELETEAGENT_DEF = {
  bash: `DELETE /agents/{agentId}`,
};

export const DELETEAGENT_REQUEST = {
  curl: `curl -X DELETE "https://tasks.messagebird.com/agents/6beb6363-3fb7-4ebc-8a10-d3c4b022cbe8" \
  -H "Authorization: AccessKey YOUR_API_KEY"`,
};

export const DELETEAGENT_RESPONSE = {
  json: `HTTP/1.1 204 No Content`,
};

export const TASK_HTTP_METHODS = {
  bash: `GET /tasks
GET /tasks/{taskId}
POST /tasks
DELETE /tasks/{taskId}
  `,
};

export const TASK_OBJECT = {
  json: `{
    "id": "79504c09-c68d-4c8f-9258-b49363061757",
    "workflowId": "6598114d-050f-4404-aa9d-ed90025f889d",
    "queueId": "2ca814f5-d512-4fb2-b11c-4cb0c82c008f",
    "channelType": "voice",
    "attributes": {
        "type": "salesVoiceCall",
        "invocationId": "9bb5006d-7791-4d30-b1b9-f90991d07614",
        "language": "en"
    },
    "status": "waiting",
    "createdAt": "2018-10-10T09:00:00Z",
    "updatedAt": "2018-10-10T09:00:00Z"
}`,
};

export const LISTTASKS_DEF = {
  bash: `GET /tasks`,
};

export const LISTTASKS_REQUEST = {
  curl: `curl -X GET "https://tasks.messagebird.com/tasks" \
  -H "Authorization: AccessKey YOUR_API_KEY" `,
};

export const LISTTASKS_RESPONSE = {
  json: `{
    {
      "id": "79504c09-c68d-4c8f-9258-b49363061757",
      "workflowId": "6598114d-050f-4404-aa9d-ed90025f889d",
      "queueId": "2ca814f5-d512-4fb2-b11c-4cb0c82c008f",
      "channelType": "voice",
      "attributes": {
          "type": "salesVoiceCall",
          "invocationId": "9bb5006d-7791-4d30-b1b9-f90991d07614",
          "language": "en"
      },
      "status": "waiting",
      "createdAt": "2018-10-10T09:00:00Z",
      "updatedAt": "2018-10-10T09:00:00Z"
  },
  {
    "id": "79504c09-c68d-4c7s-9258-b49363061757",
    "workflowId": "6598114d-050f-4404-aa9d-ed90025f889d",
    "queueId": "2ca814f5-d512-4fb2-b11c-4cb0c82c008f",
    "channelType": "email",
    "attributes": {
        "type": "salesVoiceCall",
        "invocationId": "9bb5006d-7791-4d30-b1b9-g468732vjr",
        "language": "fr"
    },
    "status": "waiting",
    "createdAt": "2018-10-10T09:00:00Z",
    "updatedAt": "2018-10-10T09:00:00Z"
}
}`,
};

export const VIEWTASK_DEF = {
  bash: `GET /tasks/{taskId}`,
};

export const VIEWTASK_REQUEST = {
  curl: `curl -X GET "https://tasks.messagebird.com/tasks/6beb6363-3fb7-4ebc-8a10-d3c4b022cbe8" \
  -H "Authorization: AccessKey YOUR_API_KEY" `,
};

export const VIEWTASK_RESPONSE = {
  json: `{
    "id": "79504c09-c68d-4c8f-9258-b49363061757",
    "workflowId": "6598114d-050f-4404-aa9d-ed90025f889d",
    "queueId": "2ca814f5-d512-4fb2-b11c-4cb0c82c008f",
    "channelType": "voice",
    "attributes": {
        "type": "salesVoiceCall",
        "invocationId": "9bb5006d-7791-4d30-b1b9-f90991d07614",
        "language": "en"
    },
    "status": "waiting",
    "createdAt": "2018-10-10T09:00:00Z",
    "updatedAt": "2018-10-10T09:00:00Z"
}`,
};

export const CREATETASK_DEF = {
  bash: `POST /tasks`,
};

export const CREATETASK_REQUEST = {
  curl: `curl -X POST "https://tasks.messagebird.com/tasks" \
     -H 'Content-Type: application/json' \
     -H "Authorization: AccessKey YOUR_API_KEY" \\
     -d $'{
    "workflowId": "6598114d-050f-4404-aa9d-ed90025f889d",
    "channelType": "voice",
    "attributes": {
        "type": "salesVoiceCall",
        "invocationId": "abc123",
        "language": "en"
    }
  }'
`,
};

export const CREATETASK_RESPONSE = {
  json: `HTTP/1.1 201 Created

  {
      "id": "79504c09-c68d-4c8f-9258-b49363061757",
      "workflowId": "6598114d-050f-4404-aa9d-ed90025f889d",
      "channelType": "voice",
      "queueId": "2ca814f5-d512-4fb2-b11c-4cb0c82c008f",
      "attributes": {
          "type": "salesVoiceCall",
          "invocationId": "abc123",
          "language": "en"
      },
      "status": "waiting",
      "createdAt": "2018-10-10T09:00:00Z",
      "updatedAt": "2018-10-10T09:00:00Z"
  }`,
};

export const DELETETASK_DEF = {
  bash: `DELETE /tasks/{taskId}`,
};

export const DELETETASK_REQUEST = {
  curl: `curl -X DELETE "https://tasks.messagebird.com/tasks/6beb6363-3fb7-4ebc-8a10-d3c4b022cbe8" \
  -H "Authorization: AccessKey YOUR_API_KEY"`,
};

export const DELETETASK_RESPONSE = {
  json: `HTTP/1.1 204 No Content`,
};

export const RESERVATION_HTTP_METHODS = {
  bash: `POST /reservations/{reservationId}/accept
POST /reservations/{reservationId}/reject
POST /reservations/{reservationId}/complete
  `,
};

export const RESERVATION_OBJECT = {
  json: `{
    "id": "8c369e81-447a-4d9d-9c7f-00484543b839",
    "taskId": "c9e81952-cd25-4883-83b7-2ef760849b3a",
    "agentId": "0cc45119-a0cd-41ca-8580-9ea0e3292f5d",
    "status": "accepted",
    "createdAt": "2019-05-01T11:22:57Z",
    "updatedAt": "2019-05-01T13:24:54.150622+02:00"
}`,
};

export const ACCEPTRESERVATION_DEF = {
  bash: `POST /tasks/{reservationId}/accept`,
};

export const ACCEPTRESERVATION_REQUEST = {
  curl: `curl -X POST "https://tasks.messagebird.com/reservations/8c369e81-447a-4d9d-9c7f-00484543b839/accept" \
  -H 'Authorization: Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c'
`,
};

export const ACCEPTRESERVATION_RESPONSE = {
  json: `HTTP/1.1 200 OK

  {
      "id": "8c369e81-447a-4d9d-9c7f-00484543b839",
      "taskId": "c9e81952-cd25-4883-83b7-2ef760849b3a",
      "agentId": "0cc45119-a0cd-41ca-8580-9ea0e3292f5d",
      "status": "accepted",
      "createdAt": "2019-05-01T11:22:57Z",
      "updatedAt": "2019-05-01T13:24:54.150622+02:00"
  }`,
};

export const REJECTRESERVATION_DEF = {
  bash: `POST /tasks/{reservationId}/reject`,
};

export const REJECTRESERVATION_REQUEST = {
  curl: `curl -X POST "https://tasks.messagebird.com/reservations/8c369e81-447a-4d9d-9c7f-00484543b839/reject" \
  -H 'Authorization: Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c'
`,
};

export const REJECTRESERVATION_RESPONSE = {
  json: `HTTP/1.1 200 OK

  {
      "id": "8c369e81-447a-4d9d-9c7f-00484543b839",
      "taskId": "c9e81952-cd25-4883-83b7-2ef760849b3a",
      "agentId": "0cc45119-a0cd-41ca-8580-9ea0e3292f5d",
      "status": "rejected",
      "createdAt": "2019-05-01T11:22:57Z",
      "updatedAt": "2019-05-01T13:24:54.150622+02:00"
  }`,
};

export const COMPLETERESERVATION_DEF = {
  bash: `POST /tasks/{reservationId}/complete`,
};

export const COMPLETERESERVATION_REQUEST = {
  curl: `curl -X POST "https://tasks.messagebird.com/reservations/8c369e81-447a-4d9d-9c7f-00484543b839/complete" \
  -H 'Authorization: Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c'
`,
};

export const COMPLETERESERVATION_RESPONSE = {
  json: `HTTP/1.1 200 OK

  {
      "id": "8c369e81-447a-4d9d-9c7f-00484543b839",
      "taskId": "c9e81952-cd25-4883-83b7-2ef760849b3a",
      "agentId": "0cc45119-a0cd-41ca-8580-9ea0e3292f5d",
      "status": "completed",
      "createdAt": "2019-05-01T11:22:57Z",
      "updatedAt": "2019-05-01T13:24:54.150622+02:00"
  }`,
};

export const UPDATENUMBERS_DEF = {
  bash: `PATCH /v1/phone-numbers/{phoneNumber}`,
};

export const UPDATENUMBERS_REQUEST = {
  curl: `curl -X PATCH "https://numbers.messagebird.com/v1/phone-numbers/31612345670" \\
  -H "Authorization: AccessKey YOUR_API_KEY" \\
  -H "Content-Type: application/json" \\
  --data '{"tags":["tag1"]}'`,
};

export const UPDATENUMBERS_RESPONSE = {
  json: `{
    "number": "31612345670",
    "country": "NL",
    "region": "Texel",
    "locality": "Texel",
    "features": [
        "sms",
        "voice"
    ],
    "tags": ["tag1"],
    "type": "mobile",
    "status": "active"
}`,
};

export const CANCELNUMBERS_DEF = {
  bash: `DELETE /v1/phone-numbers/{phoneNumber}`,
};

export const CANCELNUMBERS_REQUEST = {
  curl: `curl -X DELETE "https://numbers.messagebird.com/v1/phone-numbers/31612345670" \\
  -H "Authorization: AccessKey YOUR_API_KEY" \\
  -H "Content-Type: application/json"`,
};

export const CANCELNUMBERS_RESPONSE = {
  bash: `HTTP/1.1 204 No Content`,
};
