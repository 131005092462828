import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import Questions from 'components/Footer/Questions';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`WhatsApp guidelines for customer's opt-ins`}</h1>
    <h3>{`How to get customer’s opt-in for WhatsApp`}</h3>
    <p>{`The first step towards being able to message your customers over WhatsApp Business is getting them to opt-in. In this quickstart, you’ll learn what exactly an opt-in is, the opt-in WhatsApp Business guidelines, and how to collect them through the appropriate user interface and onboarding flow.`}</p>
    <h3>{`The golden rule: active opt-in`}</h3>
    <p>{`An opt-in practice is when a user agrees to receive messages over WhatsApp from your business by providing you with their mobile phone number through a third-party channel. This can be any channel your business uses to communicate with customers today — your website, app, email, SMS, etc. Opt-in is required for both new and existing customers and must be collected before you message a user via WhatsApp.`}</p>
    <h3>{`User interface`}</h3>
    <p>{`The opt-in must be an `}<em parentName="p">{`active opt-in,`}</em>{` that is, it must be triggered by a user action, such as entering a phone number or checking a box to indicate consent. The guidelines are:`}</p>
    <ul>
      <li parentName="ul">{`The user must be asked to opt-in through a visual element -such as a checkbox- shown next to the WhatsApp name and logo on the chosen third-party channel.`}</li>
      <li parentName="ul">{`The customer must also have control over what number is being used through explicit action (type in).`}</li>
      <li parentName="ul">{`Explain clearly what information will be received adjacent to the UI elements.`}</li>
    </ul>
    <p>{`The objective of all the rules is that a clear opt-in messaging allows the customer to know what type of messaging the person is signing up for. Here’s the magic formula: “receive `}{`[noun]`}{`, `}{`[logo and name]`}{`, on `}{`[number]`}{`". Keep in mind that `}{`[noun]`}{` is the type of information related to the flow.`}</p>
    <h3>{`Creating the onboarding flow`}</h3>
    <ul>
      <li parentName="ul">{`You can use any third-party channel to get your customers to opt-in, but you must always follow the guideline; there has to be a flow that clearly indicates to the users the number what they are consenting to. An example of successfully using another channel would be sending an SMS that links to an opt-in form that complies the guidelines.`}</li>
      <li parentName="ul">{`The checkbox should `}<em parentName="li">{`only`}</em>{` be used to ask users to opt-in for receiving messages via WhatsApp. For example, you would need to use different checkbox for SMS, and a separate option for WhatsApp with the name and logo.`}</li>
      <li parentName="ul">{`If your firm promotes calling customers on WhatsApp to collect a set of customer numbers, it counts as opt-in only if you create a flow - a form hosted on their website - that follows our guidelines. The user should understand that by calling a number, they agree to opt into receiving specific messages on WhatsApp`}<strong parentName="li">{`.`}</strong></li>
      <li parentName="ul">{`When it comes to multi-step flows, if the action of typing in the phone number happens in previous steps of onboarding flow, but the active opt-in for WhatsApp occurs on the next screen or later in the user onboarding, you don’t need to show phone number again and make them re-verify it. This case is particularly useful for new users.`}</li>
    </ul>
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      