import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import Notification from 'components/Notification';
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Get Your Free Test Credits & API keys`}</h1>
    <p>{`In this Voice Messaging API Quickstart, you'll learn how to sign up and log in to `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/user/index"
      }}>{`your MessageBird Dashboard`}</a>{`, and how to collect, manage and use your free test credits and API keys.`}</p>
    <h2>{`Step 1: Sign up to MessageBird`}</h2>
    <p>{`First thing’s first, sign up for your free MessageBird account at `}<a parentName="p" {...{
        "href": "https://www.messagebird.com/en/"
      }}>{`www.messagebird.com`}</a>{`.`}</p>
    <h2>{`Step 2: Collect your free test credits`}</h2>
    <p>{`You'll be asked to verify your mobile phone number, simply to check that you're human. In return, you'll receive 10 free test credits for testing the MessageBird's APIs and all the Dashboard tools.`}</p>
    <Notification type="INFO" mdxType="Notification">
You can only use your free test SMS credits to send text messages to your verified number. If you want to send live SMS messages to numbers other than your verified number, you first need to <a href="https://dashboard.messagebird.com/en/financial/buy-balance">top up your account balance</a>.
    </Notification>
    <h2>{`Step 3: Collect, manage, and use your free API free`}</h2>
    <p>{`You can create, manage, and retrieve your API keys (both test and live) from the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/settings"
      }}>{`MessageBird Developers dashboard`}</a>{`.`}</p>
    <Notification type="INFO" mdxType="Notification">
Learn about the <a href="https://support.messagebird.com/hc/en-us/articles/360000670709-What-is-the-difference-between-a-live-key-and-a-test-key-">difference between test and live API Keys</a>.
    </Notification>
    <p>{`Congrats! You're all ready to start testing the Voice Messaging API.`}</p>
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'Programmatically send outbound text to messages',
      to: '/quickstarts/voice-messaging/send-outbound-voice-message-curl'
    }, {
      name: 'Voice Messaging API Reference',
      to: '/api/voice-messaging'
    }, {
      name: 'Official MessageBird SDKs',
      to: '/libraries'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      