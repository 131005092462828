import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Docs.tsx";
import Notification from 'components/Notification';
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Getting Started with WhatsApp Business`}</h1>
    <p>{`Let's get everything ready so you can start using the WhatsApp Business API. In this quickstart, we'll walk you through the WhatsApp Business onboarding process in just 10 easy steps.`}</p>
    <h2>{`Step 1: Set up a Facebook Business Manager Account`}</h2>
    <p>{`Let's create your WhatsApp Business account. We'll need your Facebook Business ID to do so. If you don’t have a Facebook Business ID yet, set up a Facebook Manager Account by logging in or signing up on `}<a parentName="p" {...{
        "href": "https://business.facebook.com/"
      }}>{`Facebook's Business Manager`}</a>{`.`}</p>
    <h2>{`Step 2: Share your Business Manager Account ID with MessageBird`}</h2>
    <p>{`Your Facebook Business Account ID is required to connect your business account with the WhatsApp Product. Don't worry, our Support team will coordinate these steps with you.`}</p>
    <p>{`After that, you'll receive a notification in your Facebook Business account, click on it and accept the access invitation to the MessageBird WhatsApp platform.`}</p>
    <h2>{`Step 3: Choose a number for WhatsApp`}</h2>
    <p>{`We now need to connect the phone number which you'll be using for WhatsApp.`}</p>
    <p>{`This number will be the customer-facing number to which customers can send inbound messages to and from where you can send outbound messages to customers.`}</p>
    <p>{`It can be a `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/articles/208011689-Buy-a-Number-"
      }}>{`Virtual Mobile Number (VMN) from MessageBird`}</a>{` or one of your current numbers—this can either be mobile or a landline. `}</p>
    <p>{`Check out the `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/articles/360000457237-In-which-countries-can-I-get-a-Number-"
      }}>{`countries`}</a>{` where you can get a MessageBird VMN number. Don't hesitate to `}<a parentName="p" {...{
        "href": "https://www.messagebird.com/en/contact"
      }}>{`contact our Support team`}</a>{` if you have any doubts regarding VMNs. `}</p>
    <Notification type="INFO" mdxType="Notification">
Keep in mind that if you decide to use your own number, it should be a clean number, that is, a number which has not been associated with WhatsApp before. 
Read more about <a href="https://support.messagebird.com/hc/en-us/articles/360000244558-Picking-a-number-for-use-with-WhatsApp" target="_blank" rel="noopener noreferrer">how to pick a number for WhatsApp Business</a>.
    </Notification>
    <h2>{`Step 4: Verify your number with WhatsApp`}</h2>
    <p>{`It's time to verify your selected number(s) with WhatsApp.
We will ask you for your selected number(s) and the associated business name. Once you send them over, we will take care of your number verification and of adding your chosen business name to the number(s). Keep in mind that this name will be the name displayed on WhatsApp. `}</p>
    <h2>{`Step 5: Number verified`}</h2>
    <p>{`When your number is verified, you'll receive your Number VNAME certificate. You'll need this when connecting your account to the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/user/index"
      }}>{`MessageBird Dashboard`}</a>{`.`}</p>
    <h2>{`Step 6: Registering a Message Template`}</h2>
    <p>{`To start communicating with your customers, WhatsApp requires you to use a pre-approved `}<a parentName="p" {...{
        "href": "/docs/conversations/whatsapp/message-templates-curl"
      }}>{`Message Template`}</a>{` (previously referred to as Highly Structured Message or HSM). `}</p>
    <p>{`Please send us the Message Template(s) you would like to register. We will submit these for review to WhatsApp. Keep in mind that once your Message Template has been approved, it can not be edited anymore. However, you can submit new Message Templates for approval at any time.`}</p>
    <h2>{`Step 7: Connect WhatsApp to your MessageBird account`}</h2>
    <p>{`It’s time to connect your number to your MessageBird account!`}</p>
    <p>{`Go to '`}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/channels"
      }}>{`Channels`}</a>{`' in the sidebar of your MessageBird Dashboard. Then click on 'Add channel' under WhatsApp and complete the following three steps:`}</p>
    <ul>
      <li parentName="ul">{`Add your Business Name: This is the same name provided during your number registration.`}</li>
      <li parentName="ul">{`Add your Business Number`}</li>
      <li parentName="ul">{`Paste or upload your VNAME certificate associated with this number.`}</li>
      <li parentName="ul">{`Click on 'Install Now'.`}</li>
    </ul>
    <h2>{`Step 8: Install your WhatsApp channel`}</h2>
    <p>{`It's time to install WhatsApp as a channel.`}</p>
    <p>{`You'll be forwarded to your channel's overview and will see that your channel is currently 'pending'.`}</p>
    <p>{`Please refresh the page to see if the channel has been installed. Keep in mind that this stage can take up to 10 minutes.`}</p>
    <p>{`Check out to our step-by-step `}<a parentName="p" {...{
        "href": "https://developers.messagebird.com/docs/conversations/install-channel-whatsapp/"
      }}>{`Install WhatsApp as a Channel quickstart`}</a>{` if you need further guidance.`}</p>
    <Notification type="INFO" mdxType="Notification">
If the status 'Pending' is displayed for longer than 30 minutes, <a href="https://www.messagebird.com/en/contact" target="_blank" rel="noopener noreferrer">please contact our Support team</a>.
    </Notification>
    <h2>{`Step 9: Activate your WhatsApp channel`}</h2>
    <p>{`Once your channel has been installed, you'll get the option to 'Activate Channel'. `}</p>
    <p>{`We now need to verify the number on our side; if you are using a MessageBird VMN, please open a new window to view your incoming verification token, this code will be sent through the MessageBird Dashboard under your ‘Received messages’.`}</p>
    <p>{`And that's it! WhatsApp is now installed 🎉`}</p>
    <p>{`Once the number has been verified, you're all set to start using WhatsApp Business. Connect your CRM with our `}<a parentName="p" {...{
        "href": "https://developers.messagebird.com/docs/conversations"
      }}>{`Programmable Conversations API`}</a>{` to get the full use of our options!`}</p>
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'Message Templates',
      to: '/docs/conversations/whatsapp/message-templates-curl'
    }, {
      name: 'Programmable Conversations API Reference',
      to: '/api/conversations'
    }, {
      name: 'Official MessageBird SDKs',
      to: '/libraries'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      