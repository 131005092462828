import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/ApiRef.js";
import { APIWrapper, LeftColumn, RightColumn } from 'components/ApiRef';
import CodeSnippet from 'components/CodeSnippet';
import Notification from 'components/Notification';
import * as CODE from '../../constants/numbers';
import Helmet from 'react-helmet';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Helmet mdxType="Helmet">
      <meta name="robots" content="noindex" />
    </Helmet>
    <h1>{`Numbers API`}</h1>
    <p>{`The MessageBird Numbers API gives you an overview of all available MessageBird phone numbers and allows you to purchase as well as manage all your phone numbers. `}</p>
    <p>{`The Numbers API uses `}<inlineCode parentName="p">{`HTTP verbs`}</inlineCode>{` and a `}<inlineCode parentName="p">{`RESTful`}</inlineCode>{` endpoint structure with an access key that is used as the API Authorization. Request and response payloads are formatted as JSON using UTF-8 encoding and URL encoded values.`}</p>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`API Endpoint`}</h2>
        <p>{`All URLs referenced in MessageBird's Numbers API documentation have the base URL in the code snippet on the right side:`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>API Endpoint</h4>
  <CodeSnippet code={CODE.NUMBERS_ENDPOINT} mdxType="CodeSnippet" />
  <Notification type="WARNING" mdxType="Notification">
  All requests must be over SSL.
  </Notification>
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <h2>{`Authorization`}</h2>
    <p>{`With each API call you'll need to set an access key to authenticate yourself. API keys can be created or retrieved through the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`MessageBird Dashboard`}</a>{`.`}</p>
    <p>{`To provide the access key, set the `}<inlineCode parentName="p">{`Authorization`}</inlineCode>{` header in the form of `}<inlineCode parentName="p">{`AccessKey {accessKey}`}</inlineCode>{`.`}</p>
    <hr></hr>
    <h2>{`Formats and Headers`}</h2>
    <p>{`All API responses are in JSON. You're required to send the `}<inlineCode parentName="p">{`Accept`}</inlineCode>{` header with the value `}<inlineCode parentName="p">{`application/json`}</inlineCode>{` with each request.`}</p>
    <p>{`POST and PUT requests to the API must contain a JSON-formatted payload in the request body.`}</p>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <hr></hr>
        <h2>{`Errors`}</h2>
        <p>{`MessageBird uses standard HTTP status codes to indicate success or failure of an API request. Codes in the 2xx range indicate that a request was successfully processed and codes in the 4xx range indicate that there was an error that resulted from the information sent (e.g. authentication, no balance or a missing or wrong parameter).`}</p>
        <p>{`In the case of an error, the body of the response includes a JSON-formatted response that tells you exactly what is wrong.`}</p>
        <h3>{`Attributes`}</h3>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Attribute`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`errors[].code`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`An integer that represents the error type.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`errors[].description`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A human-readable description of the error. You can use this to let the user know what they can do about the error.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`errors[].parameter`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The parameter in your request related to the error if the error is parameter specific.`}</td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Error object example</h4>
  <CodeSnippet code={CODE.NUMBERS_OBJECT} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <hr></hr>
        <h2>{`Searching phone numbers available for purchase`}</h2>
        <p>{`Search for local, toll-free and mobile phone numbers that are available for you to purchase. You can search for phone numbers by country that match a pattern, are of a certain type or are in a specific region.`}</p>
        <p><strong parentName="p">{`URL Parameters (required)`}</strong></p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Parameter`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`countryCode`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`An ISO 3166-1 alpha-2 formatted country code. Relates to the country calling code of the number.`}</td>
            </tr>
          </tbody>
        </table>
        <p><strong parentName="p">{`Query Parameters (optional)`}</strong></p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Parameter`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`number`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`One or multiple numbers to be that the listed phone numbers should contain.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`features`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`array`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The feature of a phone number. Possible values: `}<inlineCode parentName="td">{`sms`}</inlineCode>{`, `}<inlineCode parentName="td">{`voice`}</inlineCode>{`, `}<inlineCode parentName="td">{`mms`}</inlineCode>{`.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`type`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The type of a phone number. Possible values: `}<inlineCode parentName="td">{`landline`}</inlineCode>{`, `}<inlineCode parentName="td">{`mobile`}</inlineCode>{`, `}<inlineCode parentName="td">{`premium_rate`}</inlineCode>{` and `}<inlineCode parentName="td">{`toll_free`}</inlineCode>{`.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`limit`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Limit the results (default: 20, max.: 100).`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`search_pattern`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Approach of searching the number. Possible values: start (result starts with the provided number fragment); anywhere (result has provided number fragment somewhere in it); end (result ends with the provided pattern). (default: '')`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`exclude_numbers_require_verification`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`boolean`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Exclude numbers that require documentation. Possible values: (`}<inlineCode parentName="td">{`true`}</inlineCode>{`, `}<inlineCode parentName="td">{`false`}</inlineCode>{`). (default: `}<inlineCode parentName="td">{`false`}</inlineCode>{`)`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`prices`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`boolean`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Include numbers prices. Possible values: (`}<inlineCode parentName="td">{`true`}</inlineCode>{`, `}<inlineCode parentName="td">{`false`}</inlineCode>{`). (default: `}<inlineCode parentName="td">{`false`}</inlineCode>{`)`}</td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.SEARCHNUMBERS_DEF} mdxType="CodeSnippet" />
        <h4>Example request</h4>
        <CodeSnippet code={CODE.SEARCHNUMBERS_REQUEST} mdxType="CodeSnippet" />
        <h4>Example response</h4>
  <CodeSnippet code={CODE.SEARCHNUMBERS_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <hr></hr>
        <h2>{`Purchasing a phone number`}</h2>
        <p>{`By combining the `}<inlineCode parentName="p">{`/v1/available-phone-numbers`}</inlineCode>{` lookup with `}<inlineCode parentName="p">{`/v1/phone-numbers`}</inlineCode>{`, you can purchase a phone number. Once you've found a phone number you'd like to purchase you simply POST the number to the `}<inlineCode parentName="p">{`/v1/phone-numbers`}</inlineCode>{`.`}</p>
        <p><strong parentName="p">{`Query Parameters`}</strong></p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Parameter`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`number`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The phone number you want to purchase as presented in the response of `}<inlineCode parentName="td">{`/available-phone-numbers`}</inlineCode>{`.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`countryCode`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Alpha-2 country code (example: NL)`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`billingIntervalMonths`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`How often you will be charged for using the number. Valid values: `}<inlineCode parentName="td">{`1`}</inlineCode>{`, `}<inlineCode parentName="td">{`3`}</inlineCode>{`, `}<inlineCode parentName="td">{`6`}</inlineCode>{`, `}<inlineCode parentName="td">{`9`}</inlineCode>{`, `}<inlineCode parentName="td">{`12`}</inlineCode></td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.PURCHASENUMBERS_DEF} mdxType="CodeSnippet" />
        <h4>Example request</h4>
        <CodeSnippet code={CODE.PURCHASENUMBERS_REQUEST} mdxType="CodeSnippet" />
        <h4>Example response</h4>
  <CodeSnippet code={CODE.PURCHASENUMBERS_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <hr></hr>
        <h2>{`Fetching all purchased phone numbers`}</h2>
        <p>{`Retrieve a list of all your purchased phone numbers, filterable by a variety of criteria including phone number type, feature, tags or region.`}</p>
        <p><strong parentName="p">{`Query parameters`}</strong></p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Parameter`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`limit`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Limit the amount of results per page.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`offset`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Skip first `}<inlineCode parentName="td">{`n`}</inlineCode>{` results.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`features`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`array`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Features for which search is done (example `}<inlineCode parentName="td">{`?features=sms&features=voice`}</inlineCode>{`).`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`tags`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`array`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Tags for which search is done (example `}<inlineCode parentName="td">{`?tags=mobile&tags=primary`}</inlineCode>{`).`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`number`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Fragment of number.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`region`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Fragment of region data.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`locality`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Fragment of locality data.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`type`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Number type (example: landline, mobile).`}</td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.FETCHNUMBERS_DEF} mdxType="CodeSnippet" />
        <h4>Example request</h4>
        <CodeSnippet code={CODE.FETCHNUMBERS_REQUEST} mdxType="CodeSnippet" />
        <h4>Example response</h4>
  <CodeSnippet code={CODE.FETCHNUMBERS_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <hr></hr>
        <h2>{`Fetching a purchased phone number`}</h2>
        <p>{`Retrieve a specific phone number from your inventory of purchased numbers.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.FETCH1NUMBERS_DEF} mdxType="CodeSnippet" />
        <h4>Example request</h4>
        <CodeSnippet code={CODE.FETCH1NUMBERS_REQUEST} mdxType="CodeSnippet" />
        <h4>Example response</h4>
  <CodeSnippet code={CODE.FETCH1NUMBERS_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <hr></hr>
        <h2>{`Updating a purchased phone number`}</h2>
        <p>{`Update certain attributes of your purchased phone numbers. Note: at the moment, we only support updating `}<inlineCode parentName="p">{`tags`}</inlineCode>{` that can be used to group or label numbers.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.UPDATENUMBERS_DEF} mdxType="CodeSnippet" />
        <h4>Example request</h4>
        <CodeSnippet code={CODE.UPDATENUMBERS_REQUEST} mdxType="CodeSnippet" />
        <h4>Example response</h4>
  <CodeSnippet code={CODE.UPDATENUMBERS_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <hr></hr>
        <h2>{`Searching available for back order products`}</h2>
        <p>{`Search for unified communication phone numbers that are available for you to back order. `}</p>
        <p><strong parentName="p">{`URL Parameters (required)`}</strong></p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Parameter`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`countryCode`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`An ISO 3166-1 alpha-2 formatted country code. Relates to the country calling code of the number.`}</td>
            </tr>
          </tbody>
        </table>
        <p><strong parentName="p">{`Query Parameters (optional)`}</strong></p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Parameter`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`features`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`array`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The feature of a phone number. Possible values: `}<inlineCode parentName="td">{`sms`}</inlineCode>{`, `}<inlineCode parentName="td">{`voice`}</inlineCode>{`, `}<inlineCode parentName="td">{`mms`}</inlineCode>{`.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`prefix`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The prefix of a phone numbers.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`type`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The type of a phone number. Possible values: `}<inlineCode parentName="td">{`landline`}</inlineCode>{`, `}<inlineCode parentName="td">{`mobile`}</inlineCode>{`, `}<inlineCode parentName="td">{`premium_rate`}</inlineCode>{` and `}<inlineCode parentName="td">{`toll_free`}</inlineCode>{`.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`limit`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Limit the results (default: 20, max.: 100).`}</td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.SEARCHPRODUCTS_DEF} mdxType="CodeSnippet" />
        <h4>Example request</h4>
        <CodeSnippet code={CODE.SEARCHPRODUCTS_REQUEST} mdxType="CodeSnippet" />
        <h4>Example response</h4>
  <CodeSnippet code={CODE.SEARCHPRODUCTS_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <hr></hr>
        <h2>{`Fetching back order product details`}</h2>
        <p>{`In order to place back order you need to specify desired prefix.
Available prefixes could be found in `}<inlineCode parentName="p">{`prefixes`}</inlineCode>{` field from `}<inlineCode parentName="p">{`GET v1/products/{productID}`}</inlineCode>{` response.`}</p>
        <p><strong parentName="p">{`URL Parameters (required)`}</strong></p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Parameter`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`productID`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Product ID returned from search products request`}</td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.SEARCHPREFIXES_DEF} mdxType="CodeSnippet" />
        <h4>Example request</h4>
        <CodeSnippet code={CODE.SEARCHPREFIXES_REQUEST} mdxType="CodeSnippet" />
        <h4>Example response</h4>
  <CodeSnippet code={CODE.SEARCHPREFIXES_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <hr></hr>
        <h2>{`Place a back order`}</h2>
        <p>{`By combining the `}<inlineCode parentName="p">{`/v1/products`}</inlineCode>{` and `}<inlineCode parentName="p">{`/v1/products/{productID}`}</inlineCode>{` lookup with `}<inlineCode parentName="p">{`/v1/backorder`}</inlineCode>{`, you can place a back order for multiple phone numbers.
Once you've found a product that provides number you'd like to purchase you simply POST the product and prefix to the `}<inlineCode parentName="p">{`/v1/backorder`}</inlineCode>{`.`}</p>
        <p><strong parentName="p">{`Query Parameters`}</strong></p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Parameter`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`productID`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The product id of the number you want to purchase as presented in the response `}<inlineCode parentName="td">{`/v1/products`}</inlineCode></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`prefix`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The prefix of the number you want to purchase as presented in the response of `}<inlineCode parentName="td">{`/v1/products/{productID}`}</inlineCode>{`.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`quantity`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Amount of number you want to purchase (maximum 100)`}</td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.PLACEBACKORDER_DEF} mdxType="CodeSnippet" />
        <h4>Example request</h4>
        <CodeSnippet code={CODE.PLACEBACKORDER_REQUEST} mdxType="CodeSnippet" />
        <h4>Example response</h4>
  <CodeSnippet code={CODE.PLACEBACKORDER_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <hr></hr>
        <h2>{`Fetching a placed back order`}</h2>
        <p>{`Retrieve a specific back order from your inventory.
In order to start working on some backorders additional KYC information might be required.
In that case backorder `}<inlineCode parentName="p">{`status`}</inlineCode>{` will be `}<inlineCode parentName="p">{`blocked`}</inlineCode>{` and within reason_codes you may find what is missing:
`}<inlineCode parentName="p">{`MISSING_KYC`}</inlineCode>{` shows that back order requires addition KYC documents.
`}<inlineCode parentName="p">{`MISSING_EUD`}</inlineCode>{` shows that back order requires addition KYC user information.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.FETCH1BACKORDER_DEF} mdxType="CodeSnippet" />
        <h4>Example request</h4>
        <CodeSnippet code={CODE.FETCH1BACKORDER_REQUEST} mdxType="CodeSnippet" />
        <h4>Example response</h4>
  <CodeSnippet code={CODE.FETCH1BACKORDER_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <hr></hr>
        <h2>{`Fetching back order KYC documents requirements`}</h2>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.FETCHBACKORDERKYC_DEF} mdxType="CodeSnippet" />
        <h4>Example request</h4>
        <CodeSnippet code={CODE.FETCHBACKORDERKYC_REQUEST} mdxType="CodeSnippet" />
        <h4>Example response</h4>
  <CodeSnippet code={CODE.FETCHBACKORDERKYC_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <hr></hr>
        <h2>{`Uploading back order KYC documents`}</h2>
        <p>{`Upload a back order base64 encoded KYC document.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.UPLOADBACKORDERKYC_DEF} mdxType="CodeSnippet" />
        <h4>Example request</h4>
        <CodeSnippet code={CODE.UPLOADBACKORDERKYC_REQUEST} mdxType="CodeSnippet" />
        <h4>Example response</h4>
  <CodeSnippet code={CODE.UPLOADBACKORDERKYC_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <hr></hr>
        <h2>{`Fetching back order EUD requirements`}</h2>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.FETCHBACKORDEREUD_DEF} mdxType="CodeSnippet" />
        <h4>Example request</h4>
        <CodeSnippet code={CODE.FETCHBACKORDEREUD_REQUEST} mdxType="CodeSnippet" />
        <h4>Example response</h4>
  <CodeSnippet code={CODE.FETCHBACKORDEREUD_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <hr></hr>
        <h2>{`Uploading back order EUD information`}</h2>
        <p>{`Upload a back order End User Details details.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.UPLOADBACKORDEREUD_DEF} mdxType="CodeSnippet" />
        <h4>Example request</h4>
        <CodeSnippet code={CODE.UPLOADBACKORDEREUD_REQUEST} mdxType="CodeSnippet" />
        <h4>Example response</h4>
  <CodeSnippet code={CODE.UPLOADBACKORDEREUD_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <hr></hr>
        <h2>{`Cancel a purchased phone number`}</h2>
        <p>{`Release this phone number from your account. You will no longer have access to this phone number and MessageBird will no longer receive calls or SMS/MMS messages on this number. You will stop being billed the monthly phone number fee and the phone number will eventually be recycled and potentially given to another customer, so use with care.`}</p>
        <p>{`If you make a mistake, contact us. We may be able to give you the number back.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.CANCELNUMBERS_DEF} mdxType="CodeSnippet" />
  <h4>Example request</h4>
  <CodeSnippet code={CODE.CANCELNUMBERS_REQUEST} mdxType="CodeSnippet" />
  <h4>Example response</h4>
  <CodeSnippet code={CODE.CANCELNUMBERS_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <h2>{`Number Pools`}</h2>
    <p>{`Number pools are set of pools that can be used in applications that require to use numbers dynamically. Currently, it
can be only used with Voice API and SIP calls to randomise the caller ID.`}</p>
    <h3>{`Pool object`}</h3>
    <p>{`A pool is composed by the following attributes`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A random UUID used internally to uniquely identify a pool.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`name`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A unique name identifying a pool. A pool name can be of alphanumeric characters, `}<inlineCode parentName="td">{`-`}</inlineCode>{` and `}<inlineCode parentName="td">{`_`}</inlineCode>{`, from 3 to 100 characters.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`service`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The `}<a parentName="td" {...{
              "href": "/api/numbers/#pool-service"
            }}>{`pool service`}</a>{`. Possible values: `}<inlineCode parentName="td">{`randomcli`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`configuration`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`object`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The `}<a parentName="td" {...{
              "href": "/api/numbers/#pool-configuration"
            }}>{`pool configuration object`}</a>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`numbersCount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`int`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The amount of numbers in the pool.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`createdAt`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`RFC3339 timestamp of when the pool has been created.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`updatedAt`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`RFC3339 timestamp of when the pool has been updated.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Pool service`}</h3>
    <p>{`The pool service defines the service you want to use the numbers pool with. Currently only `}<inlineCode parentName="p">{`randomcli`}</inlineCode>{` is supported, in
which case the outbound caller ID number is selected randomly from the pool.`}</p>
    <h3>{`Pool configuration`}</h3>
    <p>{`The pool configuration affects the way a number will be selected within a specific pool service.`}</p>
    <h4>{`Random CLI Configuration`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`byCountry`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Select only numbers that belong to the same country of the destination.`}</td>
        </tr>
      </tbody>
    </table>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <hr></hr>
        <h2>{`Create a number pool`}</h2>
        <p>{`You can create a number pool for some service with service-specific configurations.`}</p>
        <p><strong parentName="p">{`Required parameters`}</strong></p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Parameter`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`poolName`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A number pool's name. The name should satisfy the `}<inlineCode parentName="td">{`[\\w\\-]{3,100}`}</inlineCode>{` regular expression.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`service`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A service you want the pool to use for a call. Example: `}<inlineCode parentName="td">{`randomcli`}</inlineCode>{`.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`configuration`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`object`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Service-specific configurations.`}</td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
        <h4>{`Response`}</h4>
        <p>{`Returns a Pool object if the request was successful. If the request failed, an `}<a parentName="p" {...{
            "href": "/api#api-errors"
          }}>{`error object`}</a>{` will be returned.`}</p>
        <h4>Definition</h4>
        <CodeSnippet code={CODE.CREATEPOOL_DEF} mdxType="CodeSnippet" />
        <h4>Example request</h4>
        <CodeSnippet code={CODE.CREATEPOOL_REQUEST} mdxType="CodeSnippet" />
        <h4>Example response</h4>
        <CodeSnippet code={CODE.CREATEPOOL_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <hr></hr>
        <h2>{`Fetch a number pool`}</h2>
        <p>{`Fetch details of a single number pool.`}</p>
        <p><strong parentName="p">{`URL Parameters`}</strong></p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Parameter`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`poolName`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The name of the pool to fetch.`}</td>
            </tr>
          </tbody>
        </table>
        <h4>{`Response`}</h4>
        <p>{`Returns a Pool object. If the request failed, an `}<a parentName="p" {...{
            "href": "/api#api-errors"
          }}>{`error object`}</a>{` will be returned.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
        <h4>Definition</h4>
        <CodeSnippet code={CODE.FETCH1POOL_DEF} mdxType="CodeSnippet" />
        <h4>Example request</h4>
        <CodeSnippet code={CODE.FETCH1POOL_REQUEST} mdxType="CodeSnippet" />
        <h4>Example response</h4>
        <CodeSnippet code={CODE.FETCH1POOL_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <hr></hr>
        <h2>{`Edit a number pool`}</h2>
        <p>{`Edit a pool and its configuration. Be careful when renaming a pool because all requests for the old name will fail.`}</p>
        <p><strong parentName="p">{`URL Parameters`}</strong></p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Parameter`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`poolName`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The name of the pool to edit.`}</td>
            </tr>
          </tbody>
        </table>
        <p><strong parentName="p">{`Payload parameters`}</strong></p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Parameter`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`poolName`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`New name for the pool. (optional)`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`configuration`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`object`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`New service-based pool configuration. (optional)`}</td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
        <h3>{`Response`}</h3>
        <p>{`If the request failed, an `}<a parentName="p" {...{
            "href": "/api#api-errors"
          }}>{`error object`}</a>{` will be returned.`}</p>
        <h4>Definition</h4>
        <CodeSnippet code={CODE.UPDATEPOOL_DEF} mdxType="CodeSnippet" />
        <h4>Example request</h4>
        <CodeSnippet code={CODE.UPDATEPOOL_REQUEST} mdxType="CodeSnippet" />
        <h4>Example response</h4>
        <CodeSnippet code={CODE.UPDATEPOOL_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <hr></hr>
        <h2>{`Remove a number pool`}</h2>
        <p>{`Removes a number pool and detaches its numbers.`}</p>
        <p><strong parentName="p">{`Query Parameters`}</strong></p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Parameter`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`poolName`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The name of the number pool.`}</td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.DELETENUMBERPOOL_DEF} mdxType="CodeSnippet" />
  <h4>Example request</h4>
  <CodeSnippet code={CODE.DELETENUMBERPOOL_REQUEST} mdxType="CodeSnippet" />
  <h4>Example response</h4>
  <CodeSnippet code={CODE.DELETENUMBERPOOL_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <hr></hr>
        <h2>{`List pools`}</h2>
        <p>{`List and filter number pools. Returns a list of number pools with their details if the request was successful. If the request failed, an `}<a parentName="p" {...{
            "href": "/api#api-errors"
          }}>{`error object`}</a>{` will be returned.`}</p>
        <p><strong parentName="p">{`Query Parameters (optional)`}</strong></p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Parameter`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`poolName`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The name of the number pool.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`service`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The name of the service.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`limit`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The maximum number of pools to show. The default is 20.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`offset`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The amount of pools to skip. The default is 0.`}</td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.LISTNUMBERPOOLS_DEF} mdxType="CodeSnippet" />
  <h4>Example request</h4>
  <CodeSnippet code={CODE.LISTNUMBERPOOLS_REQUEST} mdxType="CodeSnippet" />
  <h4>Example response</h4>
  <CodeSnippet code={CODE.LISTNUMBERPOOLS_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <hr></hr>
        <APIWrapper mdxType="APIWrapper">
          <LeftColumn mdxType="LeftColumn">
            <hr></hr>
            <h2>{`List pool numbers`}</h2>
            <p>{`List numbers that belong to a specific pool.`}</p>
            <p><strong parentName="p">{`URL parameters`}</strong></p>
            <table>
              <thead parentName="table">
                <tr parentName="thead">
                  <th parentName="tr" {...{
                    "align": null
                  }}>{`Parameter`}</th>
                  <th parentName="tr" {...{
                    "align": null
                  }}>{`Type`}</th>
                  <th parentName="tr" {...{
                    "align": null
                  }}>{`Description`}</th>
                </tr>
              </thead>
              <tbody parentName="table">
                <tr parentName="tbody">
                  <td parentName="tr" {...{
                    "align": null
                  }}><inlineCode parentName="td">{`poolName`}</inlineCode></td>
                  <td parentName="tr" {...{
                    "align": null
                  }}>{`string`}</td>
                  <td parentName="tr" {...{
                    "align": null
                  }}>{`A number pool's name.`}</td>
                </tr>
              </tbody>
            </table>
            <p><strong parentName="p">{`Query parameters (optional)`}</strong></p>
            <table>
              <thead parentName="table">
                <tr parentName="thead">
                  <th parentName="tr" {...{
                    "align": null
                  }}>{`Parameter`}</th>
                  <th parentName="tr" {...{
                    "align": null
                  }}>{`Type`}</th>
                  <th parentName="tr" {...{
                    "align": null
                  }}>{`Description`}</th>
                </tr>
              </thead>
              <tbody parentName="table">
                <tr parentName="tbody">
                  <td parentName="tr" {...{
                    "align": null
                  }}><inlineCode parentName="td">{`limit`}</inlineCode></td>
                  <td parentName="tr" {...{
                    "align": null
                  }}>{`integer`}</td>
                  <td parentName="tr" {...{
                    "align": null
                  }}>{`A maximum amount of numbers to show. The default limit is 20.`}</td>
                </tr>
                <tr parentName="tbody">
                  <td parentName="tr" {...{
                    "align": null
                  }}><inlineCode parentName="td">{`offset`}</inlineCode></td>
                  <td parentName="tr" {...{
                    "align": null
                  }}>{`integer`}</td>
                  <td parentName="tr" {...{
                    "align": null
                  }}>{`An amount of numbers to skip. The default offset is 0.`}</td>
                </tr>
                <tr parentName="tbody">
                  <td parentName="tr" {...{
                    "align": null
                  }}><inlineCode parentName="td">{`number`}</inlineCode></td>
                  <td parentName="tr" {...{
                    "align": null
                  }}>{`string`}</td>
                  <td parentName="tr" {...{
                    "align": null
                  }}>{`A string to be used for fetching numbers through partial match against this parameter.`}</td>
                </tr>
              </tbody>
            </table>
          </LeftColumn>
          <RightColumn mdxType="RightColumn">
            <h4>{`Response`}</h4>
            <p>{`If the request failed, an `}<a parentName="p" {...{
                "href": "/api#api-errors"
              }}>{`error object`}</a>{` will be returned.`}</p>
            <h4>Definition</h4>
            <CodeSnippet code={CODE.LISTPOOLNUMBERS_DEF} mdxType="CodeSnippet" />
            <h4>Example request</h4>
            <CodeSnippet code={CODE.LISTPOOLNUMBERS_REQUEST} mdxType="CodeSnippet" />
            <h4>Example response</h4>
            <CodeSnippet code={CODE.LISTPOOLNUMBERS_RESPONSE} mdxType="CodeSnippet" />
          </RightColumn>
        </APIWrapper>
        <h2>{`Add numbers to a pool`}</h2>
        <p>{`Add numbers to a pool.`}</p>
        <p><strong parentName="p">{`Query parameters`}</strong></p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Parameter`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`poolName`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The name of the number pool.`}</td>
            </tr>
          </tbody>
        </table>
        <p><strong parentName="p">{`Payload parameters`}</strong></p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Parameter`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`numbers`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`array`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`An array of numbers to be attached to the pool. Each number must satisfy the `}<inlineCode parentName="td">{`^\\+?\\d+$`}</inlineCode>{` regular expression. The total amount of numbers must not exceed 1000.`}</td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
        <h4>{`Response`}</h4>
        <p>{`If the request failed, an `}<a parentName="p" {...{
            "href": "/api#api-errors"
          }}>{`error object`}</a>{` will be returned. In case some of the numbers could not be added, a combined success and fail response will be returned.`}</p>
        <h4>Definition</h4>
        <CodeSnippet code={CODE.ADDNUMBERSTOPOOL_DEF} mdxType="CodeSnippet" />
        <h4>Example request</h4>
        <CodeSnippet code={CODE.ADDNUMBERSTOPOOL_REQUEST} mdxType="CodeSnippet" />
        <h4>Example response</h4>
        <CodeSnippet code={CODE.ADDNUMBERSTOPOOL_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <hr></hr>
        <h2>{`Remove numbers from a pool`}</h2>
        <p>{`Remove all the specified numbers from a pool.`}</p>
        <p><strong parentName="p">{`URL parameters`}</strong></p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Parameter`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`poolName`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A number pool's name.`}</td>
            </tr>
          </tbody>
        </table>
        <p><strong parentName="p">{`Query parameters`}</strong></p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Parameter`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`numbers`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A comma-separated list of numbers to be removed from the pool. Each number must satisfy the `}<inlineCode parentName="td">{`^\\+?\\d+$`}</inlineCode>{` regular expression. The total amount of numbers must not exceed 1000.`}</td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
        <h4>{`Response`}</h4>
        <p>{`If the request failed, an `}<a parentName="p" {...{
            "href": "/api#api-errors"
          }}>{`error object`}</a>{` will be returned.`}</p>
        <h4>Definition</h4>
        <CodeSnippet code={CODE.REMOVENUMBERSFROMPOOL_DEF} mdxType="CodeSnippet" />
        <h4>Example request</h4>
        <CodeSnippet code={CODE.REMOVENUMBERSFROMPOOL_REQUEST} mdxType="CodeSnippet" />
        <h4>Example response</h4>
        <CodeSnippet code={CODE.REMOVENUMBERSFROMPOOL_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      