import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Implementing SMS two-factor authentication (2FA) with MessageBird`}</h1>
    <h3>{`⏱ 15 min build time      ||      `}<a parentName="h3" {...{
        "href": "https://github.com/messagebirdguides/verify-guide-python"
      }}>{`Download the Code`}</a></h3>
    <h2>{`Why build two-factor authentication?`}</h2>
    <p>{`In this MessageBird Developer Tutorial you’ll learn how to improve your security building an SMS-based two factor authentication solution with the `}<a parentName="p" {...{
        "href": "/api/verify"
      }}>{`MessageBird Verify API`}</a>{`. The runnable application we’ll build is a prototype in Python for our fictitious online banking application, `}<em parentName="p">{`BirdBank`}</em>{`.`}</p>
    <p>{`Enterprises are increasingly challenged to keep sensitive information from falling into the wrong hands. This means that we can no longer trust old online authentication systems that rely solely on usernames and passwords, especially as security breaches grow in frequency, severity and sophistication.`}</p>
    <p>{`With the `}<a parentName="p" {...{
        "href": "/api/verify"
      }}>{`MessageBird Verify API`}</a>{`, you can implement two factor authentication (2FA) solutions to provide an additional layer of account security by verifying the user's password with a second authentication token and in turn, secure customer data, block fraudulent accounts, and safeguard key transactions in a matter of minutes. The most common use case involves the application of one-time passwords (OTP) generated by hardware tokens,  authenticator apps or directly sent to the user's mobile phone via SMS messaging.`}</p>
    <p>{`We'll walk you through the following steps:`}</p>
    <ul>
      <li parentName="ul">{`Asking for the phone number`}</li>
      <li parentName="ul">{`Sending a verification code`}</li>
      <li parentName="ul">{`Verifying the code`}</li>
    </ul>
    <p><strong parentName="p">{`Pro-tip:`}</strong>{` Follow this tutorial to build the whole application from scratch or, if you want to see it in action right away, you can download, clone or fork the sample application from our `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/verify-guide-python"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`.`}</p>
    <h2>{`Getting started`}</h2>
    <p>{`To build our sample application we'll use Python, the `}<a parentName="p" {...{
        "href": "http://flask.pocoo.org/"
      }}>{`Flask framework`}</a>{`, `}<a parentName="p" {...{
        "href": "http://jinja.pocoo.org/docs/2.10/"
      }}>{`Jinja2 templates`}</a>{`, as well as `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/messagebird"
      }}>{`MessageBird's REST API for Python`}</a>{`. Since this application uses HTML forms, we’ll also use the `}<a parentName="p" {...{
        "href": "https://wtforms.readthedocs.io/en/stable/"
      }}>{`WTForms`}</a>{` forms rendering library and the `}<a parentName="p" {...{
        "href": "https://flask-wtf.readthedocs.io/en/stable/"
      }}>{`Flask-WTF library`}</a>{`, which integrates WTForms with Flask.`}</p>
    <p>{`We’ll use Python's pip package manager to install the required packages for Flask. Please `}<a parentName="p" {...{
        "href": "https://pip.pypa.io/en/stable/installing/"
      }}>{`install pip`}</a>{` if you don’t have it already.`}</p>
    <h2>{`Project setup`}</h2>
    <h3>{`Application directory`}</h3>
    <p>{`Let's first create a new directory to store our sample application. This file will contain your main application file, your configuration file, and the Jinja2 templates used by the application.`}</p>
    <h3>{`Dependencies`}</h3>
    <p>{`Once Python and pip are installed, let's go ahead and install Flask, WTForms, and Flask-WTF with the following commands:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`pip install Flask
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`pip install WTForms
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`pip install Flask-WTF
`}</code></pre>
    <p>{`You’ll also need to install the MessageBird Python library, which can also be obtained from pip with the following command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`pip install messagebird


#### Create your API Key 🔑

To enable the MessageBird REST API, we need to provide an access key for the API. MessageBird provides keys in *live* and *test* modes. To get this application running, we’ll need to create and use a live API access key. You can read more about [the difference between test and live API keys](https://support.messagebird.com/hc/en-us/articles/360000670709-What-is-the-difference-between-a-live-key-and-a-test-key-) in our Help Center.

Let's create your live API access key. First, go to the [MessageBird Dashboard](https://dashboard.messagebird.com/en/user/index); if you have already created an API key it will be shown right there. If you don’t see any key on the Dashboard or if you're unsure whether this key is in *live* mode, go to the *Developers* section in the MessageBird Dashboard and open the [API access (REST) tab](https://dashboard.messagebird.com/en/developers/access). There you can create new API keys and manage your existing ones.

If you are having any issues creating your API key, please reach out to support@messagebird.com; we’ll make sure to help you out.

**Pro-tip:** Hardcoding your credentials in the code is a risky practice that should never be used in production applications. A better method, also recommended by the [Twelve-Factor App Definition](https://12factor.net/), is to use environment variables.

Flask allows you to specify credentials in a configuration file that’s separate from the application. In our example, we created a configuration file named \`config_file.cfg\` that’s saved in the application directory. Specify your API key in this file as follows:

\`\`\`env
SECRET_KEY='string'
`}</code></pre>
    <p>{`Let's replace the string with your live API key.`}</p>
    <p>{`You can add more settings in the configuration file. For example, to run the application in debug mode, you can add the following line:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-env"
      }}>{`DEBUG=True
`}</code></pre>
    <h3>{`Main file`}</h3>
    <p>{`You now have your API key, so let's get started with the main file. In the main directory create an `}<inlineCode parentName="p">{`app.py`}</inlineCode>{` file, which will define the routes used by the web application. This file starts off by including the following dependencies:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`from flask import Flask, render_template, request, flash, url_for, redirect
import messagebird
from forms import SubmitPhoneNumber, EnterCode
`}</code></pre>
    <p>{`The first two lines import the MessageBird library and the relevant Flask modules. The third line imports classes from a file containing form definitions, which we’ll explain later.`}</p>
    <p>{`Then, we create an instance of the application and load the API key from the configuration file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`app = Flask(__name__)
app.config.from_pyfile('config_file.cfg')
`}</code></pre>
    <p>{`Next, let’s create an instance of MessageBird's Python client using our API key:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`client = messagebird.Client(app.config['SECRET_KEY'])
`}</code></pre>
    <h3>{`HTML pages`}</h3>
    <p>{`Our Jinja2 templates represent the logic of the application's HTML pages separately from the logic of the code. In the application directory, let’s create a subdirectory named `}<inlineCode parentName="p">{`templates`}</inlineCode>{`, in which we’ll store our templates. The template for the first page, which asks for the user's phone number, is `}<a parentName="p" {...{
        "href": "templates/index.html"
      }}>{`index.html`}</a>{`.`}</p>
    <p>{`After the user successfully enters their phone number, they’re redirected to a verification page, where they can enter the verification code that they’ve just received on their phone. Our template for this page is `}<a parentName="p" {...{
        "href": "templates/entercode.html"
      }}>{`entercode.html`}</a>{`.`}</p>
    <p>{`In Jinja2 templates, the curly brackets `}<inlineCode parentName="p">{`{}`}</inlineCode>{` represent sections of the page where what appears depends on the logic of the Python code. The variables within the curly brackets will be defined in the Python code. As we step through the code, you’ll see how the variables in the main application file, `}<inlineCode parentName="p">{`app.py`}</inlineCode>{`, relate to those in the templates.`}</p>
    <h2>{`Asking for the phone number`}</h2>
    <p>{`The first step in verifying a user's phone number is asking them to provide their phone number. We do this by having a template for a HTML form in `}<inlineCode parentName="p">{`index.html`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`{% with messages = get_flashed_messages() %}
 {% if messages %}
   <ul class=flashes>
   {% for message in messages %}
     <li>{{ message }}</li>
   {% endfor %}
   </ul>
 {% endif %}
{% endwith %}
<h1>MessageBird Verify Example</h1>
<p>Enter your phone number:</p>
<form action="{{ url_for('submitPhone') }}" method="post">
<dl>
 {{ initial_form.number }}
 {{ initial_form.submit }}
</dl>
</form>
`}</code></pre>
    <p>{`The section that begins with `}<inlineCode parentName="p">{`{% with messages`}</inlineCode>{` lets us display error messages—we’ll explain how this works later. The bottom half of the template represents the HTML form that will collect the user's phone number.`}</p>
    <p>{`Corresponding to the form in the template is a class, `}<inlineCode parentName="p">{`SubmitPhoneNumber`}</inlineCode>{`, that defines the types of fields in the form. To define this class, create a file named `}<inlineCode parentName="p">{`forms.py`}</inlineCode>{` in the application directory. The classes defined in this file will have the following dependencies:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`from flask_wtf import FlaskForm
from wtforms import StringField, SubmitField, HiddenField
from wtforms.fields.html5 import TelField #telephone number field
`}</code></pre>
    <p>{`In `}<inlineCode parentName="p">{`forms.py`}</inlineCode>{`, `}<inlineCode parentName="p">{`SubmitPhoneNumber`}</inlineCode>{` is defined as follows:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`class SubmitPhoneNumber(FlaskForm):
   number = TelField('Phone number')
   submit = SubmitField('Send code')
`}</code></pre>
    <p>{`The form is simple, having just one input field and one submit button. Providing `}<inlineCode parentName="p">{`TelField`}</inlineCode>{` as the type of our input field allows some browsers, especially those on mobile devices, to optimize for a telephone number input, for example by displaying a number pad.`}</p>
    <p>{`Now, it's time to add a route to `}<inlineCode parentName="p">{`app.py`}</inlineCode>{` to display the page:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`@app.route('/', methods=['GET', 'POST'])
def submitPhone():
   initial_form = SubmitPhoneNumber(request.form)
   code_form = EnterCode()

   #when form is posted, try to obtain server response
   if request.method=="POST":
       try:
           verify = client.verify_create(initial_form.number.data)
           #on success we render verify.id on the hidden field on the verification page (/EnterCode)
           return redirect(url_for('enterCode', code_form=code_form, verify_id=verify.id))

       #on failure, log error to console and render error description for error on same page.
       except messagebird.client.ErrorException as e:
           for error in e.errors:
               flash('  description : %s\\n' % error.description)
           return render_template('index.html', initial_form=initial_form)

   return render_template('index.html', initial_form=initial_form)
`}</code></pre>
    <p>{`This route starts by instantiating the classes of both forms in the application. The verification form class, `}<inlineCode parentName="p">{`EnterCode`}</inlineCode>{`, must also be instantiated because it’s an argument of the route for the next page. This route will be taken if the phone number submission is successful.`}</p>
    <p>{`Next, the route creates a new `}<a parentName="p" {...{
        "href": "/api/lookup"
      }}>{`HLR lookup`}</a>{` object using the `}<inlineCode parentName="p">{`verify_create`}</inlineCode>{` method. If successful, the user is directed to the page where they can enter the verification code they’ll receive on their phone.`}</p>
    <p>{`If we fail to create the HLR lookup object, the application will display the error messages in the region defined in the template.`}</p>
    <h2>{`Sending the verification code`}</h2>
    <p>{`Once we've collected the number, we can send a verification message to the user's mobile device. The MessageBird Verify API takes care of generating a random token, so you don't have to do this yourself. Codes are numeric and six digits by default; if you want to customize the length of the code or configure other options, you can check out our `}<a parentName="p" {...{
        "href": "/api/verify#verify-request"
      }}>{`Verify API documentation`}</a>{`.`}</p>
    <p>{`The form for collecting the verification code is defined by the following template in `}<inlineCode parentName="p">{`entercode.html`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`{% with messages = get_flashed_messages() %}
 {% if messages %}
   <ul class=flashes>
   {% for message in messages %}
     <li>{{ message }}</li>
   {% endfor %}
   </ul>
 {% endif %}
{% endwith %}

<div>
 <h1>Enter your verification code</h1>
 <form action="{{ url_for('enterCode', code_form=code_form, verify_id=code_form.verify_id.data) }}" method="post">
   <dl>
   {{ code_form.verify_id }}
   {{ code_form.token }}
   {{ code_form.submit }}
   </dl>
 </form>
</div>
`}</code></pre>
    <p>{`As with `}<inlineCode parentName="p">{`index.html`}</inlineCode>{`, the top part of the template displays any error messages that occur when the form is submitted, while the bottom half contains the form's fields. The user will enter the verification code in the `}<inlineCode parentName="p">{`token`}</inlineCode>{` field. The `}<inlineCode parentName="p">{`verify_id`}</inlineCode>{` field contains the ID that was returned by the server when the user submitted their phone number, and this ID is passed as an argument into the route for `}<inlineCode parentName="p">{`entercode.html`}</inlineCode>{`.`}</p>
    <p>{`As with the previous form, the form fields are defined as a class, `}<inlineCode parentName="p">{`EnterCode`}</inlineCode>{`, in `}<inlineCode parentName="p">{`forms.py`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`class EnterCode(FlaskForm):
   verify_id = HiddenField('ID')
   token = StringField('Enter your verification code:')
   submit = SubmitField('Check code')
`}</code></pre>
    <p>{`The form in `}<inlineCode parentName="p">{`index.html`}</inlineCode>{` submits the phone number via a HTTP POST to the `}<inlineCode parentName="p">{`/enterCode`}</inlineCode>{` route, so let's define `}<inlineCode parentName="p">{`app.py`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`@app.route('/EnterCode/<verify_id>', methods=['GET', 'POST'])
def enterCode(verify_id):
   code_form = EnterCode(request.form)

   #prefill verify ID in hidden field
   code_form.verify_id.data = verify_id

   #when form is posted, try to obtain server response
   if  request.method=="POST":
       try:
           verify = client.verify_verify(code_form.verify_id.data, code_form.token.data)
           #on success, log response to console and render a (new) success page.
           return render_template('success.html')
       #on failure, log error to console and flash error description on same page.
       except messagebird.client.ErrorException as e:
           for error in e.errors:
               flash('  description : %s\\n' % error.description)
           return redirect(url_for('enterCode', code_form=code_form, verify_id=verify_id))

   return render_template('entercode.html', code_form=code_form, verify_id=verify_id)
`}</code></pre>
    <p>{`Before we move on, let's quickly dive into what happens here: 🤔`}</p>
    <p>{`First, we instantiate the `}<inlineCode parentName="p">{`EnterCode`}</inlineCode>{` class, which defines the form's fields. We want to submit the verification code together with the ID returned from the first `}<inlineCode parentName="p">{`verify_create`}</inlineCode>{` request, so we store the ID in a hidden field on the form. The ID is also an argument in the route. After the user enters the verification code and clicks the submit button, the ID and the code are sent to the server using `}<inlineCode parentName="p">{`verify_verify`}</inlineCode>{`. If the submission succeeds, we show the user a page with a simple success message, as defined in `}<inlineCode parentName="p">{`success.html`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<html>
 <head>
   <title></title>
 </head>
 <body>
   <p>You have successfully verified your phone number.</p>
 </body>
</html>
`}</code></pre>
    <p>{`The route for the success page is defined simply in `}<inlineCode parentName="p">{`app.py`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`def success():
   return render_template('success.html')
`}</code></pre>
    <p>{`If we fail to POST the verification code successfully, error messages will be displayed above the form.`}</p>
    <h2>{`Testing`}</h2>
    <p>{`You’re done! You can add a few more lines of code in your `}<inlineCode parentName="p">{`app.py`}</inlineCode>{` to quickly test the application:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`if __name__ == '__main__':
   app.run()
`}</code></pre>
    <p>{`Now, let's take a look at the directory structure you created. It should look something like this:`}</p>
    <pre><code parentName="pre" {...{}}>{`templates
- index.html
- entercode.html
app.py
forms.py
config_file.cfg
`}</code></pre>
    <p>{`If you're all set, save all your files and run the application from the command line:`}</p>
    <pre><code parentName="pre" {...{}}>{`python app.py
`}</code></pre>
    <p>{`The, point your browser to `}<a parentName="p" {...{
        "href": "http://localhost:5000/"
      }}>{`http://localhost:5000/`}</a>{` and try to verify your own phone number.`}</p>
    <p>{`Awesome! You can now leverage the flow, code snippets and UI examples from this tutorial to build your own two factor authentication system. Don't forget to download the code from the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/verify-guide-python"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`.`}</p>
    <p><strong parentName="p">{`Nice work!`}</strong>{` 🎉`}</p>
    <p>{`You now have a running integration of MessageBird's Verify API using Python!`}</p>
    <h2>{`Start building!`}</h2>
    <p>{`Want to build something similar but not quite sure how to get started? Please feel free to let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`;  we'd love to help!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      