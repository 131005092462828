import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Setting up your local development environment with MessageBird`}</h1>
    <h3>{`⏱ 10 min read`}</h3>
    <p>{`In this MessageBird Developer Tutorial you’ll first learn how to get started with MessageBird by setting up first your local development environment.`}</p>
    <p>{`MessageBird provides a Python client to facilitate interactions between your Python application and the MessageBird APIs, so that you do not have to craft raw REST API requests.`}</p>
    <h2>{`Install Python and pip`}</h2>
    <p>{`Make sure that your version of Python is at least Python 2.7 (if you're using Python 2) or at least Python 3.4 (if you're using Python 3). You’ll also need the Python package manager `}<a parentName="p" {...{
        "href": "https://pypi.org/project/pip/"
      }}>{`pip`}</a>{`.`}</p>
    <p>{`Python is included in macOS and all major Linux distributions. To check your current Python version, open a terminal and run the following command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`python -V
`}</code></pre>
    <p>{`This command is case-sensitive, so make sure you use a capital V. If you have Python installed, the command will print your installed version of Python to the terminal.`}</p>
    <p>{`If you don’t have Python or pip installed, installing  Python 2.7.9 or later, or Python 3.4 or later, from `}<a parentName="p" {...{
        "href": "https://www.python.org/"
      }}>{`python.org`}</a>{` will also automatically install pip.`}</p>
    <h4>{`Mac`}</h4>
    <p>{`To install Python and pip on macOS, go to the `}<a parentName="p" {...{
        "href": "https://www.python.org/downloads/mac-osx/"
      }}>{`python.org Downloads page for Mac`}</a>{`, and select the installer that's compatible with your macOS version. The installer is a standard Mac package file with an installer UI that guides you through the installation process. If you install the latest version of Python 2 or 3, pip will be included in the install.`}</p>
    <h4>{`Linux`}</h4>
    <p>{`For Linux, installing Python through a package manager is the easiest option. If you do so, you should also `}<a parentName="p" {...{
        "href": "https://packaging.python.org/guides/installing-using-linux-tools/#installing-pip-setuptools-wheel-with-linux-package-managers"
      }}>{`install pip using your package manager`}</a>{`.`}</p>
    <h4>{`Windows`}</h4>
    <p>{`To install Python and pip on a Windows PC, go to the `}<a parentName="p" {...{
        "href": "https://www.python.org/downloads/windows/"
      }}>{`python.org Downloads page for Windows`}</a>{` and download a Windows installer for your version of Windows. The installer has a UI that guides you through the installation process. If you install the latest version of Python 2 or 3, pip will be included in the install.`}</p>
    <h2>{`Install the MessageBird client`}</h2>
    <p>{`You can use pip to install the MessageBird Python client. The client is open source and you can `}<a parentName="p" {...{
        "href": "https://github.com/messagebird/python-rest-api"
      }}>{`browse the code in this GitHub repository`}</a>{`; it's also `}<a parentName="p" {...{
        "href": "https://pypi.org/project/messagebird/"
      }}>{`listed on pypi.org`}</a>{` so that pip knows where to find it.`}</p>
    <p>{`Simply use the following command to install the MessageBird Python client:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`pip install messagebird
`}</code></pre>
    <p><strong parentName="p">{`Nice work!`}</strong>{` 🎉`}</p>
    <p>{`Your Python development environment and project directory are ready now!`}</p>
    <h2>{`Start building!`}</h2>
    <p>{`Let's head over to the next `}<strong parentName="p">{`MessageBird Developer Tutorial`}</strong>{` and `}<a parentName="p" {...{
        "href": "/tutorials/send-sms-node"
      }}>{`learn how to send your first SMS using Python`}</a>{`.`}</p>
    <p>{`Want to start building your solution but not quite sure how to get started? Feel free to let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we'd love to help!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      