import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Lookup`}</h1>
    <p>{`MessageBird's Lookup API enables you to validate and look up a mobile number. You supply our API with the phone numbers you’d like to check, our API performs an HLR Lookup on the mobile network and identifies the number format, and we fill in the number data, along with availability and provider. The Lookup API will help you keep your database clean. `}</p>
    <p>{`A phone number validation will tell you if the specified phone number looks valid. It will also include some alternative formats to display the phone number in. An HLR lookup allows you to view which mobile number (MSISDN) belongs to what operator in real-time and see whether the number is active.  `}</p>
    <p>{`The Lookup API uses `}<inlineCode parentName="p">{`HTTP verbs`}</inlineCode>{` and a `}<inlineCode parentName="p">{`RESTful`}</inlineCode>{` endpoint structure with an access key that is used as the API Authorization framework. Request and response payloads are formatted as JSON—although we provide a GET alternative for requests—using UTF-8 encoding and URL encoded values.`}</p>
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'Request a lookup',
      to: 'quickstarts/lookup/request-a-lookup/'
    }, {
      name: 'Request an HLR',
      to: '/quickstarts/lookup/request-an-hlr'
    }, {
      name: 'Lookup API Reference',
      to: '/api/lookup'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      