import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Implementing SMS two-factor authentication (2FA) with MessageBird`}</h1>
    <h3>{`⏱ 15 min build time      ||      `}<a parentName="h3" {...{
        "href": "https://github.com/messagebirdguides/verify-guide-java"
      }}>{`Download the Code`}</a></h3>
    <h2>{`Why build two-factor authentication?`}</h2>
    <p>{`In this MessageBird Developer Tutorial you’ll learn how to improve your security building an SMS-based two factor authentication solution with the `}<a parentName="p" {...{
        "href": "/api/verify"
      }}>{`MessageBird Verify API`}</a>{`. The runnable application we’ll build is a prototype in Java for our fictitious online banking application, `}<em parentName="p">{`BirdBank`}</em>{`.`}</p>
    <p>{`Enterprises are increasingly challenged to keep sensitive information from falling into the wrong hands. This means that we can no longer trust old online authentication systems that rely solely on usernames and passwords, especially as security breaches grow in frequency, severity and sophistication.`}</p>
    <p>{`With the `}<a parentName="p" {...{
        "href": "/api/verify"
      }}>{`MessageBird Verify API`}</a>{`, you can implement two factor authentication (2FA) solutions to provide an additional layer of account security by verifying the user's password with a second authentication token and in turn, secure customer data, block fraudulent accounts, and safeguard key transactions in a matter of minutes. The most common use case involves the application of one-time passwords (OTP) generated by hardware tokens,  authenticator apps or directly sent to the user's mobile phone via SMS messaging.`}</p>
    <p>{`We'll walk you through the following steps:`}</p>
    <ul>
      <li parentName="ul">{`Asking for the phone number`}</li>
      <li parentName="ul">{`Sending a verification code`}</li>
      <li parentName="ul">{`Verifying the code`}</li>
    </ul>
    <p><strong parentName="p">{`Pro-tip:`}</strong>{` Follow this tutorial to build the whole application from scratch or, if you want to see it in action right away, you can download, clone or fork the sample application from our `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/verify-guide-java"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`.`}</p>
    <h2>{`Getting started`}</h2>
    <p>{`We'll build our sample application using `}<a parentName="p" {...{
        "href": "https://www.oracle.com/technetwork/java/javase/downloads/jdk8-downloads-2133151.html"
      }}>{`Java 1.8`}</a>{`, the `}<a parentName="p" {...{
        "href": "http://sparkjava.com/"
      }}>{`Spark framework`}</a>{` with `}<a parentName="p" {...{
        "href": "http://sparkjava.com/documentation#views-and-templates"
      }}>{`Mustache templates`}</a>{` as well as the `}<a parentName="p" {...{
        "href": "https://mvnrepository.com/artifact/com.messagebird/messagebird-api"
      }}>{`MessageBird SDK`}</a>{`.`}</p>
    <p>{`Before we get started, make sure that the Java package manager Maven is installed. If not, you can easily `}<a parentName="p" {...{
        "href": "https://maven.apache.org/"
      }}>{`install it`}</a>{` for free.`}</p>
    <h2>{`Project setup`}</h2>
    <h3>{`Dependencies`}</h3>
    <p>{`First, create a new directory to store the sample application. Within this new directory, create a file called `}<inlineCode parentName="p">{`pom.xml`}</inlineCode>{` with the following dependencies:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<dependencies>
   <dependency>
       <groupId>com.sparkjava</groupId>
       <artifactId>spark-core</artifactId>
       <version>2.7.2</version>
   </dependency>
   <dependency>
       <groupId>com.sparkjava</groupId>
       <artifactId>spark-template-mustache</artifactId>
       <version>2.7.1</version>
   </dependency>
   <dependency>
       <groupId>org.slf4j</groupId>
       <artifactId>slf4j-simple</artifactId>
       <version>1.7.21</version>
   </dependency>
   <dependency>
       <groupId>io.github.cdimascio</groupId>
       <artifactId>java-dotenv</artifactId>
       <version>3.1.2</version>
   </dependency>
   <dependency>
       <groupId>com.messagebird</groupId>
       <artifactId>messagebird-api</artifactId>
       <version>2.0.0</version>
   </dependency>
</dependencies>
`}</code></pre>
    <p>{`This file defines all of the dependencies necessary for your sample application, including Spark and the MessageBird SDK.`}</p>
    <p>{`Your editor—such as IntelliJ—should ask whether you want to import these Maven dependencies into your project; you should do so!`}</p>
    <h4>{`Create your API Key 🔑`}</h4>
    <p>{`To enable the MessageBird SDK, we need to provide an access key for the API. MessageBird provides keys in `}<em parentName="p">{`live`}</em>{` and `}<em parentName="p">{`test`}</em>{` modes. To get this application running, we’ll need to create and use a live API access key. You can read more about `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/articles/360000670709-What-is-the-difference-between-a-live-key-and-a-test-key-"
      }}>{`the difference between test and live API keys`}</a>{` in our Help Center.`}</p>
    <p>{`Let's create your live API access key. First, go to the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/user/index"
      }}>{`MessageBird Dashboard`}</a>{`; if you have already created an API key it will be shown right there. If you don’t see any key on the Dashboard or if you're unsure whether this key is in `}<em parentName="p">{`live`}</em>{` mode, go to the `}<em parentName="p">{`Developers`}</em>{` section in the MessageBird Dashboard and open the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`API access (REST) tab`}</a>{`. There you can create new API keys and manage your existing ones.`}</p>
    <p>{`If you are having any issues creating your API key, please reach out to `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we’ll make sure to help you out.`}</p>
    <p><strong parentName="p">{`Pro-tip:`}</strong>{` Hardcoding your credentials in the code is a risky practice that should never be used in production applications. A better method, also recommended by the `}<a parentName="p" {...{
        "href": "https://12factor.net/"
      }}>{`Twelve-Factor App Definition`}</a>{`, is to use environment variables.`}</p>
    <p>{`We've added `}<a parentName="p" {...{
        "href": "https://mvnrepository.com/artifact/io.github.cdimascio/java-dotenv"
      }}>{`dotenv`}</a>{` to the sample application, so you can supply your API key in a file named `}<inlineCode parentName="p">{`.env`}</inlineCode>{`. Simply copy the provided file `}<inlineCode parentName="p">{`env.example`}</inlineCode>{` to `}<inlineCode parentName="p">{`.env`}</inlineCode>{` and add your API key like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`MESSAGEBIRD_API_KEY=YOUR-API-KEY
`}</code></pre>
    <h3>{`Main file`}</h3>
    <p>{`You now have your API key, so let's get started with the main file. First, create a `}<inlineCode parentName="p">{`TwoFA.java`}</inlineCode>{` file in the `}<inlineCode parentName="p">{`src`}</inlineCode>{` directory of your project. This file starts off by importing dependencies:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import spark.ModelAndView;
import spark.template.mustache.MustacheTemplateEngine;

import static spark.Spark.get;
import io.github.cdimascio.dotenv.Dotenv;
`}</code></pre>
    <p>{`The first line in the class file loads any required environment variables:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`Dotenv dotenv = Dotenv.load();
`}</code></pre>
    <p>{`Next, we're creating a new MessageBird client. This will be used to access the API.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`// Create a MessageBirdService
final MessageBirdService messageBirdService = new MessageBirdServiceImpl(dotenv.get("MESSAGEBIRD_API_KEY"));
// Add the service to the client
final MessageBirdClient messageBirdClient = new MessageBirdClient(messageBirdService);
`}</code></pre>
    <p>{`And finally, we’ll set up an endpoint which we can access:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`get '/' do
 'Hello!'
end
`}</code></pre>
    <p>{`If you run the server, you should see a message similar to this one:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`[Thread-0] INFO spark.embeddedserver.jetty.EmbeddedJettyServer - == Spark has ignited ...
[Thread-0] INFO spark.embeddedserver.jetty.EmbeddedJettyServer - >> Listening on 0.0.0.0:4567
`}</code></pre>
    <p>{`Head to `}<inlineCode parentName="p">{`localhost:4567`}</inlineCode>{` in your browser, and you should be greeted with a message! This shows that the basics of the app are working. 🤓`}</p>
    <h3>{`Views`}</h3>
    <p>{`We use Mustache to separate the logic of our code from the HTML pages. To do this, create a directory named `}<inlineCode parentName="p">{`templates`}</inlineCode>{` inside of `}<inlineCode parentName="p">{`resources`}</inlineCode>{`. Now, inside `}<inlineCode parentName="p">{`templates`}</inlineCode>{` create two files; first, create a file called `}<inlineCode parentName="p">{`header.mustache`}</inlineCode>{` with the following content:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<!doctype html>
<html>
<head>
   <title>MessageBird Verify Example</title>
</head>
<body>
<h1>MessageBird Verify Example</h1>
`}</code></pre>
    <p>{`Then, create a file called `}<inlineCode parentName="p">{`footer.mustache`}</inlineCode>{` with this content:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`</body>
</html>
`}</code></pre>
    <p>{`These represent the main layout which acts as a container for all pages of our application. We'll create the views for each page next.`}</p>
    <h2>{`Asking for the phone number`}</h2>
    <p>{`The first step in verifying a user's phone number is asking them to provide their phone number. Let's do exactly this by creating an HTML form and storing it as `}<inlineCode parentName="p">{`step1.mustache`}</inlineCode>{` inside the `}<inlineCode parentName="p">{`views`}</inlineCode>{` directory:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`{{> header}}

{{#errors}}
   <p>{{errors}}</p>
{{/errors}}

<p>Please enter your phone number (in international format, starting with +) to receive a verification code:</p>
<form method="post" action="/step2">
   <input type="tel" name="number"/>
   <input type="submit" value="Send code"/>
</form>

{{> footer}}
`}</code></pre>
    <p>{`The form is simple, having just one input field and one submit button. Providing `}<inlineCode parentName="p">{`tel`}</inlineCode>{` as the `}<inlineCode parentName="p">{`type`}</inlineCode>{` attribute of our input field allows some browsers, especially on mobile devices, to optimize for telephone number input, for example by displaying a number pad. The section starting with `}<inlineCode parentName="p">{`{{#errors}}`}</inlineCode>{` is needed to display errors—we'll come back to this in a minute.`}</p>
    <p>{`Now, it's time to change the initial route in `}<inlineCode parentName="p">{`app.rb`}</inlineCode>{` to display the page:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`get("/",
       (req, res) ->
       {
           return new ModelAndView(null, "step1.mustache");
       },
       new MustacheTemplateEngine()
);
`}</code></pre>
    <p>{`Running the application should display the form.`}</p>
    <h2>{`Sending a verification code`}</h2>
    <p>{`Once we've collected the number, we can send a verification message to a user's mobile device. The MessageBird Verify API takes care of generating a random token, so you don't have to do this yourself. Codes are numeric and six digits by default; if you want to customize the length of the code or configure other options, you can check out our `}<a parentName="p" {...{
        "href": "/api/verify#verify-request"
      }}>{`Verify API documentation`}</a>{`.`}</p>
    <p>{`The form we created in the last step submits the phone number via HTTP POST to `}<inlineCode parentName="p">{`/step2`}</inlineCode>{`, so let's define this route in our `}<inlineCode parentName="p">{`TwoFA.java`}</inlineCode>{` file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`post("/step2",
       (req, res) ->
       {
           String number = req.queryParams("number");
           Map<String, Object> model = new HashMap<>();

           try {
               VerifyRequest verifyRequest = new VerifyRequest(number);
               verifyRequest.setTimeout(120);

               final Verify verify = messageBirdClient.sendVerifyToken(verifyRequest);

               model.put("otpId", verify.getId());

               return new ModelAndView(model, "step2.mustache");
           } catch (UnauthorizedException | GeneralException ex) {
               model.put("errors", ex.toString());
               return new ModelAndView(model, "step2.mustache");
           }
       },
       new MustacheTemplateEngine()
);
`}</code></pre>
    <p>{`Before we move on, let's quickly dive into what happens here: 🤔`}</p>
    <p>{`First, the number is passed along from the request. We instantiate a new object, `}<inlineCode parentName="p">{`VerifyRequest`}</inlineCode>{`, with the number as the first parameter. Then, using the MessageBird client, we send a verification token using `}<inlineCode parentName="p">{`sendVerifyToken`}</inlineCode>{`.`}</p>
    <p>{`If this call fails, the MessageBird client throws one of two exceptions. A typical error could be that the user has entered an invalid phone number. For our application, we simply re-render the page from our first step and pass the description of the error into the template. Remember the `}<inlineCode parentName="p">{`{{#errors}}`}</inlineCode>{` section from the first step? In production applications, you'd most likely not expose the raw API error; instead, you could consider different possible problems and return an appropriate message in your own words. You might also want to prevent some errors from occurring by doing some input validation on the phone number yourself.`}</p>
    <p>{`In case the request was successful, we'll render a new page. Our API response contains an ID, `}<inlineCode parentName="p">{`otpId`}</inlineCode>{`, which we'll need for the next step, so we'll just add it to the form. Since the ID is meaningless without your API access key there are no security implications of doing so; however, in practice you'd be more likely to store this ID in a session object on the server. Just as before, we're logging the whole response to the console for debugging purposes. We still need to build the new page, so create a file called `}<inlineCode parentName="p">{`step2.mustache`}</inlineCode>{` in your `}<inlineCode parentName="p">{`views`}</inlineCode>{` directory:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`{{> header}}

{{#errors}}
   <p>{{errors}}</p>
{{/errors}}

<p>We have sent you a verification code!</p>
<p>Please enter the code here:</p>
<form method="post" action="/step3">
   <input type="hidden" name="id" value="{{otpId}}}"/>
   <input type="text" name="token"/>
   <input type="submit" value="Check code"/>
</form>

{{> footer}}
`}</code></pre>
    <p>{`The form is very similar to the first step. Keep in mind that we include a hidden field with our verification ID and once again have a conditional error section.`}</p>
    <h2>{`Verifying the code`}</h2>
    <p>{`The user will check their phone and enter the code into our form. What we need to do next is send the user's input along with the ID of the verification request to MessageBird's API and see whether the verification was successful or not. Let's declare this third step as a new route in our app:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`post("/step3",
       (req, res) ->
       {
           String id = req.queryParams("id");
           String token = req.queryParams("token");

           Map<String, Object> model = new HashMap<>();

           try {
               final Verify verify = messageBirdClient.verifyToken(id, token);

               return new ModelAndView(model, "step3.mustache");
           } catch (UnauthorizedException | GeneralException ex) {
               model.put("errors", ex.toString());
               return new ModelAndView(model, "step2.mustache");
           }
       },
       new MustacheTemplateEngine()
);
`}</code></pre>
    <p>{`This code looks very similar to the one in the second step. First, we're reading the input and then make a call to MessageBird's API. This time, it's the `}<inlineCode parentName="p">{`verifyToken`}</inlineCode>{` method which accepts `}<inlineCode parentName="p">{`id`}</inlineCode>{` and `}<inlineCode parentName="p">{`token`}</inlineCode>{` as its parameters.`}</p>
    <p>{`In case of an error, such as an invalid or expired token, we're showing that error on our page from the second step.`}</p>
    <p>{`In the success case, we simply show a new page. Create this page in your `}<inlineCode parentName="p">{`views`}</inlineCode>{` directory and call it `}<inlineCode parentName="p">{`step3.mustache`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{}}>{`{{> header}}

<p>You have successfully verified your phone number.</p>

{{> footer}}
`}</code></pre>
    <h2>{`Testing`}</h2>
    <p>{`You're done! To check if your application works, run it through your IDE.`}</p>
    <p>{`Then, point your browser to `}<a parentName="p" {...{
        "href": "http://localhost:4567/"
      }}>{`http://localhost:4567/`}</a>{` and try to verify your own phone number.`}</p>
    <p>{`Awesome! You can now leverage the flow, code snippets and UI examples from this tutorial to build your own two factor authentication system. Don't forget to download the code from the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/verify-guide-java"
      }}>{`MessageBird Developer Guides GitHub repository`}</a>{`.`}</p>
    <p><strong parentName="p">{`Nice work!`}</strong>{` 🎉`}</p>
    <p>{`You now have a running integration of MessageBird's Verify API using Java!`}</p>
    <h2>{`Start building!`}</h2>
    <p>{`Want to build something similar but not quite sure how to get started? Please feel free to let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`;  we'd love to help!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      