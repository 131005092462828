import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/ApiRef.js";
import Notification from 'components/Notification';
import { APIWrapper, LeftColumn, RightColumn } from 'components/ApiRef';
import CodeSnippet from 'components/CodeSnippet';
import Link from 'components/Link';
import * as CODE from '../../constants/personal-data';
import Helmet from 'react-helmet';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Helmet mdxType="Helmet">
      <meta name="robots" content="noindex" />
    </Helmet>
    <h1>{`Personal Data API `}<inlineCode parentName="h1">{`beta`}</inlineCode></h1>
    <Notification type="WARNING" mdxType="Notification">
Please note that any information on this page that doesn't specifically relate to the API description, including the introduction to the GDPR, comes without warranty and cannot be considered legal advice. You should work with a lawyer to achieve adequate GDPR compliance.
    </Notification>
    <p>{`The General Data Protection Regulation (GDPR) is a regulation from the European Union which became enforceable on 25th May 2018 as a replacement for the 1995 Data Protection Directive. It requires companies all over the world to give European citizens more control over their personal data. Personal data under GDPR is all data that relates to an individual and identifiable person.`}</p>
    <p>{`In GDPR terminology, Controllers are companies that determine the purpose for which data is processed. Often they have a direct relationship with their customers and collect and process their data. If you have a website, such as an online shop or even just a blog, you are a Controller. To learn more about your obligations as a Controller, such as the need to bake Privacy-by-Design into your product, you can `}<a parentName="p" {...{
        "href": "https://gdpr-info.eu/"
      }}>{`read the full text of the law online`}</a>{` or one of the many third-party pieces covering the regulation.`}</p>
    <p>{`A Processor is an entity that stores or processes personal data on behalf of a Controller. Processors include most API providers you have integrated into your application. If you have integrated MessageBird's APIs and send personal data in messages or use your customer's phone numbers, MessageBird is a Processor.`}</p>
    <p>{`Your customer is a Data Subject under GDPR. They have individual rights, which include the right to access (Article 15 GDPR) and data portability (Article 20 GDPR), right to rectification (Article 16 GDPR), right to erasure (often called right to be forgotten - Article 17 GDPR) and right to restrict processing (Article 18 GDPR).`}</p>
    <p>{`If your customer practices their right to access, as a Controller you need to provide any data you keep about them, including data that your Processors have stored. Also, if they want to invoke their right to erasure, you must delete any data that you are not legally required to keep, e.g. for accounting purposes, and also instruct your Processors to remove whatever personal data they hold. To help you fulfill your obligations, Processors have started offering specific GDPR-related APIs for programmatic submission of access and erasure requests.`}</p>
    <p>{`MessageBird provides the Personal Data API documented below through which you can send data requests based on multiple identifiers through which we can search for information about a specific Data Subject, including telephone numbers, email addresses, and IP addresses. All APIs work asynchronously and send their result to a webhook URL when we have either collected all data for an access request or when we deleted all data based on the right to erasure.`}</p>
    <h3>{`URL`}</h3>
    <p><a parentName="p" {...{
        "href": "https://rest.messagebird.com/personaldata"
      }}>{`https://rest.messagebird.com/personaldata`}</a></p>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h3>{`Available HTTP Methods`}</h3>
        <p>{`The `}<inlineCode parentName="p">{`/get`}</inlineCode>{`, `}<inlineCode parentName="p">{`/delete`}</inlineCode>{` and POST `}<inlineCode parentName="p">{`/requests`}</inlineCode>{` requests are asynchronous since gathering or removing data can take some time. The API call initiates processing which continues in the background. Completion will be reported to an HTTP callback, or webhook, you provide. The `}<inlineCode parentName="p">{`/list`}</inlineCode>{` and `}<inlineCode parentName="p">{`GET /requests`}</inlineCode>{` requests show all requests that are in progress.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Available HTTP Methods</h4>
  <CodeSnippet code={CODE.PD_METHODS} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <h3>{`Request object`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Request ID.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`field`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The personal data type used to find the data.`}<br />{`Possible values of the field attribute are:`}<br /><inlineCode parentName="td">{`phone_number`}</inlineCode><br /><inlineCode parentName="td">{`email`}</inlineCode><br /><inlineCode parentName="td">{`ipv4`}</inlineCode><br /><inlineCode parentName="td">{`ipv6`}</inlineCode><br /><inlineCode parentName="td">{`address`}</inlineCode><br /><inlineCode parentName="td">{`name`}</inlineCode><br /><inlineCode parentName="td">{`chat api contact ID`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`value`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The value of the personal data.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`callback`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`object`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An object of `}<em parentName="td">{`Callback`}</em>{` type.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`reason`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A free text with the reason for the request.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`kind`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`KIND_PERSONAL_GET`}</inlineCode>{` : GET personal data of an individual identified by field value, `}<strong parentName="td">{`default`}</strong><br /><inlineCode parentName="td">{`KIND_PERSONAL_DELETE`}</inlineCode>{` : DELETE personal data of an individual identified by the field value`}<br /><inlineCode parentName="td">{`KIND_USER_GET`}</inlineCode>{` : GET all data for the user`}<br /><inlineCode parentName="td">{`KIND_USER_DELETE`}</inlineCode>{` : DELETE all data for the user`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`state`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`STATE_SENT`}</inlineCode><br /><inlineCode parentName="td">{`STATE_FAILED`}</inlineCode><br /><inlineCode parentName="td">{`STATE_DONE`}</inlineCode><br /><inlineCode parentName="td">{`STATE_CALLBACK_OK`}</inlineCode><br /><inlineCode parentName="td">{`STATE_CALLBACK_FAIL`}</inlineCode><br /><strong parentName="td">{`read-only`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`dataArchiveUrl`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HTTPS link to the data archive, only populated when kind is `}<inlineCode parentName="td">{`KIND_PERSONAL_GET`}</inlineCode>{` and state `}<inlineCode parentName="td">{`STATE_DONE`}</inlineCode><br /><strong parentName="td">{`read-only`}</strong>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`signatureKey`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The key used to sign the callback, base64-encoded `}<br /><strong parentName="td">{`read-only`}</strong>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`createdAt`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The date and time of creation in RFC3339Nano format`}<br /><strong parentName="td">{`read-only`}</strong>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`updatedAt`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The date and time of last state update in RFC3339Nano format`}<br /><strong parentName="td">{`read-only`}</strong>{`.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Callback object`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`http`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HTTPS endpoint address which accepts POST requests.`}<br />{` Must respond with HTTP 200.`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`The user is required to supply `}<strong parentName="p">{`exactly one`}</strong>{` attribute in the Callback object.`}</p>
    <h3>{`Make a Request`}</h3>
    <p>{`For `}<inlineCode parentName="p">{`/delete`}</inlineCode>{`, `}<inlineCode parentName="p">{`/get`}</inlineCode>{` and `}<inlineCode parentName="p">{`POST /requests`}</inlineCode>{` use the same parameters.`}</p>
    <h4>{`Parameters:`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`field`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The personal data type used to find the data.`}<br />{`Possible values of the field attribute are:`}<br /><inlineCode parentName="td">{`phone_number`}</inlineCode><br /><inlineCode parentName="td">{`email`}</inlineCode><br /><inlineCode parentName="td">{`ipv4`}</inlineCode><br /><inlineCode parentName="td">{`ipv6`}</inlineCode><br /><inlineCode parentName="td">{`address`}</inlineCode><br /><inlineCode parentName="td">{`name`}</inlineCode><br /><inlineCode parentName="td">{`chat api contact ID`}</inlineCode><br /><strong parentName="td">{`mandatory`}</strong>{` for requests of `}<inlineCode parentName="td">{`kind=KIND_PERSONAL_GET`}</inlineCode>{` or `}<inlineCode parentName="td">{`kind=KIND_PERSONAL_DELETE`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`value`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The value of the personal data. `}<br /><strong parentName="td">{`mandatory`}</strong>{` for requests of `}<inlineCode parentName="td">{`kind=KIND_PERSONAL_GET`}</inlineCode>{` or `}<inlineCode parentName="td">{`kind=KIND_PERSONAL_DELETE`}</inlineCode><br /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`callback`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`object`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An object of `}<em parentName="td">{`Callback`}</em>{` type.`}<br /><strong parentName="td">{`mandatory`}</strong><br /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`reason`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A free text with the reason for the request.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`kind`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`For `}<inlineCode parentName="td">{`POST /requests`}</inlineCode>{` specify the kind of the request using this property: `}<br /><inlineCode parentName="td">{`KIND_PERSONAL_GET`}</inlineCode>{` default `}<br />{` `}<inlineCode parentName="td">{`KIND_PERSONAL_DELETE`}</inlineCode>{` `}<br />{` `}<inlineCode parentName="td">{`KIND_USER_GET`}</inlineCode>{` `}<br /><inlineCode parentName="td">{`KIND_USER_DELETE`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Example requests with cURL`}</h4>
        <p>{`Requesting data MessageBird holds of an individual that has the phone number "+3162372342" and is probably your customer:`}</p>
        <p>{`Requesting all personal data that MessageBird holds of you as a company:`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Example Request</h4>
  <CodeSnippet code={CODE.PD_EXAMPLE1} mdxType="CodeSnippet" />
    <h4>Example Request</h4>
  <CodeSnippet code={CODE.PD_EXAMPLE2} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Response`}</h4>
        <p>{`If the request was received successfully, a `}<inlineCode parentName="p">{`201 Created`}</inlineCode>{` response with the complete Request object is returned. You should store the `}<inlineCode parentName="p">{`id`}</inlineCode>{` and `}<inlineCode parentName="p">{`signatureKey`}</inlineCode>{` in order to match and verify the callback you’ll receive with the request you sent.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
        <h4>Response</h4>
        <CodeSnippet code={CODE.PD_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <h3>{`Callback responses`}</h3>
    <p>{`The Personal Data API sends a callback response once it finishes processing the request. The callback contains the Request ID, which was automatically generated by MessageBird for the request, and a result message. Get requests include a URL top to a ZIP file which contains the requested data.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`requestId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Request ID.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`requestKind`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`KIND_PERSONAL_GET`}</inlineCode>{` : GET personal data identified by field value, `}<strong parentName="td">{`default`}</strong><br /><inlineCode parentName="td">{`KIND_PERSONAL_DELETE`}</inlineCode>{` : DELETE personal data identified by the field value`}<br /><inlineCode parentName="td">{`KIND_USER_GET`}</inlineCode>{` : GET all data for the user`}<br /><inlineCode parentName="td">{`KIND_USER_DELETE`}</inlineCode>{` : DELETE all data for the user`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`result`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An informative string on the status of the request.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`data`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`URL to the data archive when the kind is `}<inlineCode parentName="td">{`KIND_PERSONAL_GET`}</inlineCode>{`, not populated otherwise.`}<br /><em parentName="td">{`Note: If the data retrieval process did not yield any personal data, the archive URL will be empty.`}</em></td>
        </tr>
      </tbody>
    </table>
    <h4>{`Callback Signature`}</h4>
    <p>{`When executing the callback, the header `}<inlineCode parentName="p">{`X-MessageBird-Signature`}</inlineCode>{` is set. This header contains:`}</p>
    <ul>
      <li parentName="ul">{`A unix epoch timestamp of when the callback got executed.`}</li>
      <li parentName="ul">{`An HMAC SHA256 of the timestamp and the request body combined, hashed using `}<inlineCode parentName="li">{`signatureKey`}</inlineCode>{`.`}</li>
    </ul>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <p>{`The header content is formatted as:`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
        <h4>Header Content</h4>
        <CodeSnippet code={CODE.PD_HEADER} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <p>{`where t=`}{`<`}{`unix epoch`}{`>`}{` and v1=`}{`<`}{`payload hmac sha256 signature`}{`>`}{`.`}</p>
    <h4>{`How to compute the callback signature`}</h4>
    <p>{`Parse the `}<inlineCode parentName="p">{`X-MessageBird-Signature`}</inlineCode>{` header content by splitting it on the character `}<inlineCode parentName="p">{`,`}</inlineCode>{` then splitting each token on character  `}<inlineCode parentName="p">{`=`}</inlineCode>{`  to retrieve the timestamp and the signature as string.`}</p>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <p>{`Prepare the payload to sign by concatenating the timestamp to the request body separating them with a dot:`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
        <h4>Example</h4>
        <CodeSnippet code={CODE.PD_HEADER1} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <h3>{`List ongoing requests`}</h3>
    <p>{`A request to `}<inlineCode parentName="p">{`/list`}</inlineCode>{` will show all requests made with the API and their status.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`requests`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`array`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An array of objects with Request type.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      