import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`MMS`}</h1>
    <p>{`MessageBird's MMS API enables you to send and receive MMS messages that include multimedia content to and from a selected group of countries. Currently, you can only send MMS within the US and Canada. `}</p>
    <p>{`Messages are identified by a unique ID. And with this ID you can always check the status of the MMS message through the provided endpoint.`}</p>
    <p>{`The MMS API uses HTTP verbs and a RESTful endpoint structure with an access key that is used as the API Authorization framework. Request and response payloads are formatted as JSON—although we provide a GET alternative for requests—using UTF-8 encoding and URL encoded values.`}</p>
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'Send an MMS',
      to: '/quickstarts/mms/send-mms'
    }, {
      name: 'MMS API Reference',
      to: '/api/mms'
    }, {
      name: 'Official MessageBird SDKs',
      to: '/libraries'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      