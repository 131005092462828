import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Docs.tsx";
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Replying to messages`}</h1>
    <p>{`In this Programmable Conversations API Quickstart, you'll learn how to programmatically reply to inbound messages using cURL by adding a new message to an existing conversation and sending it to the contact with whom you're communicating.`}</p>
    <p>{`Programmable Conversations allows you to reply to a user's message over any supported communication platform such as SMS, WhatsApp, Live Chat, WeChat, Messenger, Telegram, LINE, Instagram, Email, Google Business Messages, Twitter, and Viber. `}</p>
    <h2>{`Step 1: Handle inbound replies`}</h2>
    <p>{`Before we start, have you completed the `}<a parentName="p" {...{
        "href": "/docs/conversations/handle-inbound-messages-curl"
      }}>{`Programmable Conversations Quickstart: Receiving Messages`}</a>{`?`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`No?`}</strong>{` Make sure to do so before continuing with this quickstart.`}</li>
      <li parentName="ul"><strong parentName="li">{`Yes?`}</strong>{` Great! Go to step 2.`}</li>
    </ul>
    <h2>{`Step 2: Run the code`}</h2>
    <p>{`Run the following request in your terminal after replacing `}<inlineCode parentName="p">{`YOUR-API-KEY`}</inlineCode>{`  with your API key and `}<inlineCode parentName="p">{`CONVERSATION-ID`}</inlineCode>{` with the ID of an existing conversation. `}</p>
    <p>{`You can collect your live API key from the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`Developer Dashboard`}</a>{`. To get a list of active conversations with their IDs, simply head over to the `}<a parentName="p" {...{
        "href": "/docs/conversations/handle-inbound-messages-curl"
      }}>{`Receiving messages quickstart`}</a>{`.`}</p>
    <p>{`Once you entered all your credentials, go ahead and copy the above snippet to your terminal and hit enter!`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-curl"
      }}>{`curl -X "POST" "https://conversations.messagebird.com/v1/conversations/CONVERSATION-ID/messages" \\
-H "Authorization: AccessKey YOUR-API-KEY" \\
-H "Content-Type: application/json" \\
--data '{"type": "text","content":{"text": "Hello, this is a reply!"}}'
`}</code></pre>
    <h2>{`Step 3: View the response`}</h2>
    <p>{`Have a look at MessageBird’s HTTP response to your API call. If everything was correct, you'll receive a response similar to this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "id": "52ac5ef671d55bae0ad1532581a618c8",
  "conversationId": "f1e812e9afd6a875d813d51f45823edd",
  "channelId": "2ceefea7c1b8c79673215c42c1a6b916",
  "status": "pending",
  "type": "text",
  "direction": "sent",
  "content": {
    "text": "Hello, this is a reply!"
  },
  "createdDatetime": "2018-08-29T13:53:44.642664784Z",
  "updatedDatetime": "2018-08-29T13:53:44.673850825Z"
}
`}</code></pre>
    <p>{`If something went wrong, you’ll receive an error response from the MessageBird API. Don't worry, simply head over to `}<a parentName="p" {...{
        "href": "https://developers.messagebird.com/docs/conversations/troubleshooting"
      }}>{`Conversations API Troubleshooting`}</a>{` to understand the error.`}</p>
    <p>{`Nice work! 🎉  You can now programmatically reply to inbound messages with Programmable Conversations.`}</p>
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'Programmable Conversations API reference',
      to: '/api/conversations'
    }, {
      name: 'Offical MessageBird SDKs',
      to: '/libraries'
    }, {
      name: 'Troubleshooting Programmable Conversations',
      to: '/docs/conversations/troubleshooting'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      