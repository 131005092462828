import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import { Img } from 'components/shared';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`WhatsApp Referral`}</h1>
    <p>{`The WhatsApp referral is an additional property in the Conversations API message which contains information about from
where your customer initiated the conversation thread in WhatsApp. At the moment, the WhatsApp referral property will
be provided when:`}</p>
    <ul>
      <li parentName="ul">{`A user clicks on an advertisement with a Click to WhatsApp call-to-action`}</li>
      <li parentName="ul">{`A user clicks on the WhatsApp button in a Facebook post`}</li>
    </ul>
    <h2>{`Connect WhatsApp to Facebook`}</h2>
    <p>{`In order to enable the WhatsApp button in your Facebook posts, first you must connect your WhatsApp phone number with
your Facebook page. Go to your Facebook page settings (`}<inlineCode parentName="p">{`https://www.facebook.com/<page_name_or_id>/settings`}</inlineCode>{`) and, in
the left menu, click on WhatsApp. You should see the following page.`}</p>
    <Img src="/img/screenshots/quickstarts/whatsapp/connect-whatsapp-to-facebook.png" alt="Connect WhatsApp to Facebook" width="60%" align="middle" mdxType="Img" />
    <p>{`Enter your WhatsApp phone number and click on Continue. You will receive a WhatsApp message with a PIN code, which should
be filled in the next page.`}</p>
    <h2>{`Create a Facebook post with WhatsApp button`}</h2>
    <p>{`Once your Facebook page is connected to your WhatsApp phone number, you will be able to create Facebook posts with
WhatsApp buttons.`}</p>
    <Img src="/img/screenshots/quickstarts/whatsapp/create-fb-post-with-wa-button.png" alt="Create a Facebook post with a WhatsApp button" width="60%" align="middle" mdxType="Img" />
    <p>{`When creating your post, simply click on the WhatsApp button to enable it.`}</p>
    <h2>{`Receiving messages with WhatsApp referral`}</h2>
    <p>{`A Facebook post with a WhatsApp button looks like the following.`}</p>
    <Img src="/img/screenshots/quickstarts/whatsapp/facebook-post-with-whatsapp-button.png" alt="Facebook post with WhatsApp button" width="60%" align="middle" mdxType="Img" />
    <p>{`Once a user clicks on the WhatsApp button, they will be redirected to the WhatsApp app (mobile or web) and they will
be able to write a message.`}</p>
    <Img src="/img/screenshots/quickstarts/whatsapp/replying-fb-post-with-whatsapp.png" alt="Replying a Facebook post with WhatsApp" width="60%" align="middle" mdxType="Img" />
    <p>{`The message above, when set, will look like the following in Conversations API.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "id": "6d588352f210481ba4469c4d8e2a1696",
  "conversationId": "0b7c237df609487c9c41437dab502889",
  "platform": "whatsapp",
  "to": "+31000000000",
  "from": "+31000000000",
  "channelId": "54d17ah6-48be-h2d3-820c-4e98d04f023c",
  "type": "text",
  "referral": {
    "whatsapp": {
      "headline": "Test page",
      "body": "Sample of post with a WhatsApp button",
      "source": {
        "type": "post",
        "url": "https://fb.me/7OdfE45gk",
        "id": "615533673545722"
      },
      "media": {
        "type": "image",
        "url": "https://mediaserver..messagebird.com/v1/media/e6d3e43e-cg77-4518-8afd-8a9d36356f44"
      }
    }
  },
  "content": {
    "text": "Interesting product, could you please give me more details?"
  },
  "direction": "received",
  "status": "received",
  "createdDatetime": "2022-08-05T12:51:56.23337047Z",
  "updatedDatetime": "2022-08-05T12:51:51Z"
}
`}</code></pre>
    <p>{`Note that the `}<inlineCode parentName="p">{`referral`}</inlineCode>{` field is set along with a text message. The actual text message sent by the customer is set in
`}<inlineCode parentName="p">{`content.text`}</inlineCode>{`, while the property `}<inlineCode parentName="p">{`referral.whatsapp`}</inlineCode>{` contain the details about the Facebook post the used has clicked.
For more information about the WhatsApp referral object, please refer to the
`}<a parentName="p" {...{
        "href": "/api/conversations/#whatsappreferral-object"
      }}>{`Conversations API documentation`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      