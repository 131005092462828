import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Layout from './Base';
import { Container } from '../Container';
import Sidebar from '../Sidebar';
import ContentBlock from '../ContentBlock';
import { ContentPageFooter } from '../Footer/ContentPagesFooter/ContentPagesFooter';

const PageContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  position: sticky;
  top: 90px;
`;
const PageSection = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin-left: 16px;
`;

const PageSectionSidebar = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 2;
  height: 80vh;
  position: -webkit-sticky;
  position: sticky;
  top: 144px;
  align-self: flex-start;
`;

const Default = ({ children, sidebar, feedback, api, languages, ...props }) => (
  <Layout languages={languages} api {...props}>
    <Container>
      <ContentBlock>
        <PageContent>
          {sidebar && (
            <PageSectionSidebar>
              <Sidebar
                languages={languages}
                api={api}
                css={`
                  width: 220px;
                  padding: 0 20px;
                  overflow: auto;
                  @media (max-width: 767px) {
                    display: none;
                  }
                `}
              />
            </PageSectionSidebar>
          )}
          <PageSection
            css={`
              justify-content: space-between;
            `}
          >
            <div
              css={`
                @media (max-width: 767px) {
                  margin: 80px 10px;
                }
              `}
            >
              {children}
            </div>
            <ContentPageFooter />
          </PageSection>
        </PageContent>
      </ContentBlock>
    </Container>
  </Layout>
);

Default.propTypes = {
  children: PropTypes.node.isRequired,
  sidebar: PropTypes.bool,
  languages: PropTypes.arrayOf(PropTypes.string),
  feedback: PropTypes.bool,
};

Default.defaultProps = {
  sidebar: false,
  languages: undefined,
  feedback: true,
};

export default Default;
