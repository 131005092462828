import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/ApiRef.js";
import CodeSnippet from 'components/CodeSnippet';
import * as CODE from '../../constants/mms';
import { APIWrapper, LeftColumn, RightColumn } from 'components/ApiRef';
import Notification from 'components/Notification';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`MMS API`}</h1>
    <p>{`MessageBird's MMS API enables you to send and receive MMS messages that include multimedia content to and from a selected group of countries. Currently, you can only send MMS within the US and Canada. `}</p>
    <p>{`Messages are identified by a unique ID. And with this ID you can always check the status of the MMS message through the provided endpoint.`}</p>
    <p>{`The MMS API uses `}<inlineCode parentName="p">{`HTTP verbs`}</inlineCode>{` and a `}<inlineCode parentName="p">{`RESTful`}</inlineCode>{` endpoint structure with an access key that is used as the API Authorization framework. Request and response payloads are formatted as JSON—although we provide a GET alternative for requests—using UTF-8 encoding and URL encoded values.`}</p>
    <Notification type="INFO" mdxType="Notification">
  Keep in mind that sending and receiving MMS is only possible if the VMN is MMS enabled.
  .
    </Notification>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <hr></hr>
        <h2>{`API Endpoint`}</h2>
        <p>{`All URLs referenced in MessageBird's MMS API documentation have the base URL in the code snippet on the right side:`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>URI</h4>
  <CodeSnippet code={CODE.MMS_URI} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Available HTTP methods`}</h2>
        <p>{`The MessageBird API uses HTTP verbs to understand if you want to read (`}<inlineCode parentName="p">{`GET`}</inlineCode>{`), delete (`}<inlineCode parentName="p">{`DELETE`}</inlineCode>{`) or create (`}<inlineCode parentName="p">{`POST`}</inlineCode>{`) an object. When your web application cannot do a `}<inlineCode parentName="p">{`POST`}</inlineCode>{` or `}<inlineCode parentName="p">{`DELETE`}</inlineCode>{`, we provide the ability to set the method through the query parameter `}<inlineCode parentName="p">{`_method`}</inlineCode>{`.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Available HTTP Methods </h4>
  <CodeSnippet code={CODE.MMS_METHODS} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <hr></hr>
        <h2>{`The MMS message object`}</h2>
        <p>{`This object represents an MMS message at MessageBird.com`}</p>
        <h4>{`Attributes`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Attribute`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`id`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A unique ID which is created on the MessageBird platform and is returned upon creation of the object.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`href`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The URL of the created object.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`direction`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Tells you if the message is sent or received.`}<br /><inlineCode parentName="td">{`mt`}</inlineCode>{`: mobile terminated (sent to mobile)`}<br /><inlineCode parentName="td">{`mo`}</inlineCode>{`: mobile originated (received from mobile)`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`originator`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The sender/source address of the message. This has to be the dedicated MMS virtual mobile number (including country code) for either the US/CA.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`recipients`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`hash`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The hash with recipient information. Further explanation in the table below.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`subject`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The subject of the MMS message. Character limit: 256.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`body`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The body of the MMS message. Character limit: 2000.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`mediaUrls`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`array`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`An array with URL's to the media attachments you want to send as part of the MMS message.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`reference`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A client reference`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`scheduledDatetime`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`datetime`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The scheduled date and time of the message in RFC3339 format (Y-m-d\\TH:i:sP)`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`createdDatetime`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`datetime`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The date and time of the creation of the message in RFC3339 format (Y-m-d\\TH:i:sP)`}</td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Object example</h4>
  <CodeSnippet code={CODE.MMS_OBJECT} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <h4>{`The recipients sub-object`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`totalCount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The total count of recipients.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`totalSentCount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The count of recipients that have the message pending (status `}<inlineCode parentName="td">{`sent`}</inlineCode>{`, and `}<inlineCode parentName="td">{`buffered`}</inlineCode>{`).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`totalDeliveredCount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The count of recipients where the message is delivered (status `}<inlineCode parentName="td">{`delivered`}</inlineCode>{`).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`totalDeliveryFailedCount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The count of recipients where the delivery has failed (status `}<inlineCode parentName="td">{`delivery_failed`}</inlineCode>{`).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`array`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An array of recipient hashes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items[].recipient`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The msisdn of the recipient`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items[].status`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The status of the message sent to the recipient. Possible values: `}<inlineCode parentName="td">{`scheduled`}</inlineCode>{`, `}<inlineCode parentName="td">{`sent`}</inlineCode>{`, `}<inlineCode parentName="td">{`buffered`}</inlineCode>{`, `}<inlineCode parentName="td">{`delivered`}</inlineCode>{`, `}<inlineCode parentName="td">{`expired`}</inlineCode>{`, and `}<inlineCode parentName="td">{`delivery_failed`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items[].statusDatetime`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The datum time of the last status in RFC3339 format (Y-m-d\\TH:i:sP)`}</td>
        </tr>
      </tbody>
    </table>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`View a message`}</h2>
        <p>{`This request retrieves the information of an existing sent MMS message. You only need to supply the unique message id that was returned upon creation.`}</p>
        <h4>{`Required parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Parameter`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`id`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The message ID that has been returned upon creation of the object. `}<strong parentName="td">{`Required`}</strong></td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.VIEWMMS_DEF} mdxType="CodeSnippet" />
  <h4>Example request</h4>
  <CodeSnippet code={CODE.VIEWMMS_REQUEST} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Returns`}</h4>
        <p>{`Returns an MMS message object if the request was successful and message has been found. If the request failed, an `}<a parentName="p" {...{
            "href": "/api#api-errors"
          }}>{`error object`}</a>{` will be returned.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Example response</h4>
  <CodeSnippet code={CODE.VIEWMMS_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Send an MMS message`}</h2>
        <p>{`This request creates a new MMS message object. MessageBird returns the created message object with each request. Per request, a max of 50 recipients can be entered.`}</p>
        <h4>{`Required parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Parameter`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`originator`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The sender of the message. This can be a telephone number (including country code - E.164) or an alphanumeric string. In case of an alphanumeric string, the maximum length is 11 characters. `}<strong parentName="td">{`Required`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`recipients`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`array`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The array of recipients MSISDNs, E.164 formatted. `}<strong parentName="td">{`Required`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`body`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The body of the MMS message, UTF-8 encoded. `}<strong parentName="td">{`Body or mediaUrls is required`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`mediaUrls`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`array`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Array of URL's of attachments of the MMS message. See `}<a parentName="td" {...{
                  "href": "/api/mms-messaging#media-attachments"
                }}>{`media attachments`}</a>{`for more information about attachments. `}<strong parentName="td">{`Body or mediaUrls is required`}</strong></td>
            </tr>
          </tbody>
        </table>
        <h4>{`Optional parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Parameter`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`subject`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The subject of the MMS message, UTF-8 encoded`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`reference`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A client reference`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`scheduledDatetime`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`datetime`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The scheduled date and time of the message in RFC3339 format (Y-m-d\\TH:i:sP)`}</td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.SENDMMS_DEF} mdxType="CodeSnippet" />
        <h4>Example request</h4>
  <CodeSnippet code={CODE.SENDMMS_REQUEST} mdxType="CodeSnippet" />
    <h5><Notification type="WARNING" mdxType="Notification">Do note that values are not automatically urlencoded with the above example.
Use `--data-urlencode` if you want to urlencode the values.</Notification></h5>
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Returns`}</h4>
        <p>{`If successful, this request will return a message object. If the request failed, an `}<a parentName="p" {...{
            "href": "/api#api-errors"
          }}>{`error object`}</a>{` will be returned.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Example response</h4>
  <CodeSnippet code={CODE.SENDMMS_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Receive a message`}</h2>
        <p>{`The request to receive MMS MO (inbound) messages is a POST request from MessageBird to customer platform, and has the following POST parameters:`}</p>
        <h4>{`Parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Attribute`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`id`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A unique random UUID which is created on the MessageBird platform.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`recipient`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The recipient of the message (the VMN/long number).`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`originator`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The sender of the message.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`body`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The body of the MMS message.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`subject`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The subject of the MMS message.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`mediaUrls`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`array`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`An array with URLs to the media attachments (max 10).`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`mediaContentTypes`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`array`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`An array with the content/mime types of the attachments (same order/array index as mediaUrls).`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`createdDatetime`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The date and time of the creation of the message in RFC3339 format (Y-m-d\\TH:i:sP).`}</td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Example request</h4>
  <CodeSnippet code={CODE.RECMMS_REQUEST} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Handle a status report`}</h2>
        <p>{`Status reports are requests that are sent to your platform through a GET request. The requests hold information about the status of a message that you have sent through our API. Status reports are only provided for messages are sent to us with a `}<inlineCode parentName="p">{`reference`}</inlineCode>{` and accounts that have configured their `}<a parentName="p" {...{
            "href": "https://dashboard.messagebird.com/developers/settings"
          }}>{`status report url`}</a>{`.`}</p>
        <h4>{`Attributes`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Attribute`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`id`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The message ID that is created by the MessageBird platform when the message is submitted, and returned upon creation of the object.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`reference`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The client reference that has been sent to API upon creation of the object.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`recipient`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The recipient where this status report applies to.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`status`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The status of the message sent to the recipient. Possible values: `}<inlineCode parentName="td">{`scheduled`}</inlineCode>{`, `}<inlineCode parentName="td">{`sent`}</inlineCode>{`, `}<inlineCode parentName="td">{`buffered`}</inlineCode>{`, `}<inlineCode parentName="td">{`delivered`}</inlineCode>{`, `}<inlineCode parentName="td">{`expired`}</inlineCode>{`, and `}<inlineCode parentName="td">{`delivery_failed`}</inlineCode></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`statusDatetime`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`datetime`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The datum time of this status in RFC3339 format (Y-m-d\\TH:i:sP)`}</td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Example request</h4>
  <CodeSnippet code={CODE.STATUSMMS_REQUEST} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`GET http://your-own.url/script?id=efa6405d518d4c0c88cce11f7db775fb&reference=the-customers-reference&recipient=31612345678&status=delivered&statusDatetime=2017-09-01T10:00:05+00:00
`}</code></pre>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Response`}</h4>
        <p>{`Your platform should respond with a `}<inlineCode parentName="p">{`200 OK`}</inlineCode>{` HTTP header. When our platform doesn't receive a `}<inlineCode parentName="p">{`200 OK`}</inlineCode>{` header, we will try to deliver the receipt again (up to 10 times, with increasing interval between requests).`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Example response</h4>
  <CodeSnippet code={CODE.STATUSMMS_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <h2>{`Media attachments`}</h2>
    <h4>{`Limitations`}</h4>
    <ul>
      <li parentName="ul">{`An MMS message can carry up to 10 media attachments`}</li>
      <li parentName="ul">{`MMS media URL should be public accessible and respond within 5 seconds`}</li>
      <li parentName="ul">{`Maximum file size of an MMS media attachment is 1MB (1024KB)`}</li>
    </ul>
    <h4>{`Supported media types`}</h4>
    <p>{`The following media types are supported as MMS attachments.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Media`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Audio`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`audio/basic`}<br />{`audio/L24`}<br />{`audio/mp4`}<br />{`audio/mpeg`}<br />{`audio/ogg`}<br />{`audio/vorbis`}<br />{`audio/vnd.rn-realaudio`}<br />{`audio/vnd.wave`}<br />{`audio/3gpp`}<br />{`audio/3gpp2`}<br />{`audio/ac3`}<br />{`audio/vnd.wave`}<br />{`audio/webm`}<br />{`audio/amr-nb`}<br />{`audio/amr`}<br /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Video`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`video/mpeg`}<br />{`video/mp4`}<br />{`video/quicktime`}<br />{`video/webm`}<br />{`video/3gpp`}<br />{`video/3gpp2`}<br />{`video/3gpp-tt`}<br />{`video/H261`}<br />{`video/H263`}<br />{`video/H263-1998`}<br />{`video/H263-2000`}<br />{`video/H264`}<br /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Image`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`image/jpeg`}<br />{`image/gif`}<br />{`image/png`}<br />{`image/bmp`}<br /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text/vcard`}<br />{`text/csv`}<br />{`text/rtf`}<br />{`text/richtext`}<br />{`text/calendar`}<br /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Application`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`application/pdf`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      