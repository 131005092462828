import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import { Img } from 'components/shared';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`WhatsApp Product Messages`}</h1>
    <p><a parentName="p" {...{
        "href": "https://developers.facebook.com/docs/whatsapp/guides/commerce-guides/share-products-with-customers"
      }}>{`WhatsApp product messages`}</a>{`
enable you to share products with your customers, allow your customers to ask questions about specific products and order.
Currently, the following product messages are supported:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Single Product Message`}</strong>{`: an interactive message with a single product item from your business inventory. The product
is displayed in a Product Detail Page (PDP) format`}</li>
      <li parentName="ul"><strong parentName="li">{`Multi-Product Message`}</strong>{`: an interactive message with up to 30 product items from your business inventory.`}</li>
      <li parentName="ul"><strong parentName="li">{`Inbound Text Message`}</strong>{`: an inbound text message associated to a specific product. It can be used by your customers to
ask questions about your products.`}</li>
      <li parentName="ul"><strong parentName="li">{`Order Message`}</strong>{`: represents an order placed by your customer after receiving a single or multi-product message`}</li>
    </ul>
    <p>{`Before sending Single or Multi-Product Messages, it's necessary:`}</p>
    <ol>
      <li parentName="ol">{`To know the catalog ID and the product IDs you want
to use to send messages. If you don't know either the catalog ID or product IDs, please refer to the section `}<a parentName="li" {...{
          "href": "/quickstarts/whatsapp/whatsapp-product-messages#how-to-find-my-catalog-and-products?"
        }}>{`How to
find my catalog and products?`}</a>{`.`}</li>
      <li parentName="ol">{`Make sure `}<a parentName="li" {...{
          "href": "https://www.facebook.com/business/help/158662536425974"
        }}>{`your catalog is connected to your WhatsApp Business Account`}</a>{`.
At the moment, only one catalog can be connected per WhatsApp Business Account.`}</li>
    </ol>
    <h2>{`Sending a Single Product Message`}</h2>
    <p>{`In order to send a Single Product message, you must perform the following HTTP request to Conversations API.
The following example uses the endpoint `}<inlineCode parentName="p">{`POST /v1/send`}</inlineCode>{`, however, you can also use the other endpoints for sending messages,
please check the `}<a parentName="p" {...{
        "href": "/api/conversations#sending-messages"
      }}>{`Conversations API reference`}</a>{` for details about the request.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-curl"
      }}>{`curl -X "POST" "https://conversations.messagebird.com/v1/send" \\
     -H 'Authorization: AccessKey {your-access-key}' \\
     -H 'Content-Type: application/json; charset=utf-8' \\
     -d $'{
  "content": {
    "interactive": {
      "type": "product",
      "action": {
        "catalog_id": "1004272927097129",
        "product_retailer_id": "e86qq6z9hx"
      },
      "body": {
        "text": "Would you like to buy the MessageBird Pro Plan?"
      }
    }
  },
  "to": "+31600000000",
  "type": "interactive",
  "from": "0181d0bf46c5444aae0309f822b35ba6"
}'

`}</code></pre>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`type`}</inlineCode>{` must be `}<inlineCode parentName="li">{`interactive`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`content.interactive.type`}</inlineCode>{` must be `}<inlineCode parentName="li">{`product`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`content.interactive.action.catalog_id`}</inlineCode>{` must be set to your Facebook product catalog ID`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`content.interactive.action.product_retailer_id`}</inlineCode>{` must be the product ID in the Facebook product catalog`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`content.interactive.body.text`}</inlineCode>{` is optional and may contain a text message`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`to`}</inlineCode>{` must contain a valid WhatsApp phone number of the recipient`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`from`}</inlineCode>{` must contain a valid and active WhatsApp channel`}</li>
    </ul>
    <p>{`The message in your customer's WhatsApp app will look like the following:`}</p>
    <Img src="/img/screenshots/quickstarts/whatsapp/single-product-message.jpeg" align="middle" width="50%" mdxType="Img" />
    <p>{`When clicking in "View", this is what your customer will see:`}</p>
    <Img src="/img/screenshots/quickstarts/whatsapp/view-single-product-message.jpeg" align="middle" width="50%" mdxType="Img" />
    <h2>{`Sending a Multi-Product Message`}</h2>
    <p>{`In order to send a Multi-Product message, you must perform the following HTTP request to Conversations API.
The following example uses the endpoint `}<inlineCode parentName="p">{`POST /v1/send`}</inlineCode>{`, however, you can also use the other endpoints for sending messages,
please check the `}<a parentName="p" {...{
        "href": "/api/conversations#sending-messages"
      }}>{`Conversations API reference`}</a>{` for details about the request.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-curl"
      }}>{`curl -X "POST" "https://conversations.messagebird.com/v1/send" \\
     -H 'Authorization: AccessKey {your-access-key}' \\
     -H 'Content-Type: application/json; charset=utf-8' \\
     -d $'{
  "content": {
    "interactive": {
      "body": {
        "text": "Would you like to know more about Plans & Pricing?"
      },
      "type": "product_list",
      "header": {
        "type": "text",
        "text": "MessageBird Pricing"
      },
      "action": {
        "catalog_id": "1004272927097129",
        "sections": [
          {
            "title": "For small companies",
            "product_items": [
              {
                "product_retailer_id": "e86qq6z9hx"
              }
            ]
          },
          {
            "title": "For medium companies",
            "product_items": [
              {
                "product_retailer_id": "0srfued6bt"
              }
            ]
          }
        ]
      }
    }
  },
  "to": "+31600000000",
  "type": "interactive",
  "from": "0181d0bf46c5444aae0309f822b35ba6"
}'

`}</code></pre>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`type`}</inlineCode>{` must be `}<inlineCode parentName="li">{`interactive`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`content.interactive.type`}</inlineCode>{` must be `}<inlineCode parentName="li">{`product_list`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`content.interactive.action.catalog_id`}</inlineCode>{` must be set to your Facebook product catalog ID`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`content.interactive.header`}</inlineCode>{` is required for multi-product messages`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`content.interactive.header.type`}</inlineCode>{` must be `}<inlineCode parentName="li">{`text`}</inlineCode>{` for multi-product messages`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`content.interactive.header.text`}</inlineCode>{` must be set for multi-product messages`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`content.interactive.action.sections`}</inlineCode>{` contains an array of `}<a parentName="li" {...{
          "href": "/api/conversations/#whatsappinteractivesection-object"
        }}>{`WhatsAppInteractiveSection`}</a>{`, every section has a title and the products within`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`content.interactive.action.sections[].title`}</inlineCode>{` sets the section title`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`content.interactive.action.sections[].product_items`}</inlineCode>{` contains an array of `}<a parentName="li" {...{
          "href": "/api/conversations/#whatsappinteractiveproduct-object"
        }}>{`WhatsAppInteractiveProduct`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`content.interactive.action.sections[].product_items[].product_retailer_id`}</inlineCode>{` must have the product ID`}</li>
    </ul>
    <p>{`The message in your customer's WhatsApp app will look like the following:`}</p>
    <Img src="/img/screenshots/quickstarts/whatsapp/multi-product-message.jpeg" align="middle" width="50%" mdxType="Img" />
    <p>{`When clicking in "View items", this is what your customer will see:`}</p>
    <Img src="/img/screenshots/quickstarts/whatsapp/view-multi-product-message.jpeg" align="middle" width="50%" mdxType="Img" />
    <h2>{`Handling customer questions`}</h2>
    <p>{`Your customers will be able to ask questions about specific products by replying product messages.`}</p>
    <Img src="/img/screenshots/quickstarts/whatsapp/whatsapp-product-question.jpeg" align="middle" width="50%" mdxType="Img" />
    <p>{`The message above will be available in Conversations API as a received message with type `}<inlineCode parentName="p">{`whatsappText`}</inlineCode>{` as illustrated below.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "id": "f4195680a362416b9b3a721cb68d86cc",
  "conversationId": "01b6a227e1ba48bf976d70eb24d0dd58",
  "platform": "whatsapp",
  "to": "+31600000000",
  "from": "+31600000000",
  "channelId": "0181d0bb46c5449aae0309f822b35ba5",
  "type": "whatsappText",
  "content": {
    "whatsappText": {
      "text": {
        "body": "Looks amazing! What are the available payment methods?"
      },
      "context": {
        "from": "+31600000000",
        "referred_product": {
          "catalog_id": "1004272927097129",
          "product_retailer_id": "e86qq6z9hx"
        }
      }
    }
  },
  "direction": "received",
  "status": "received",
  "createdDatetime": "2022-01-18T15:08:36.646299029Z",
  "updatedDatetime": "2022-01-18T15:08:35Z"
}
`}</code></pre>
    <ul>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.whatsappText.text.body`}</inlineCode>{` contains the customer question`}</li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.whatsappText.context.referred_product.catalog_id`}</inlineCode>{` contains the Facebook Catalog ID of the product
which the customer is referring to`}</li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.whatsappText.context.referred_product.product_retailer_id`}</inlineCode>{` contains the Facebook Product ID of the
product which the customer is referring to`}</li>
    </ul>
    <h2>{`Handling customer orders`}</h2>
    <p>{`Like any shopping app, your customers will be able to add products to their shopping cart, and, once they are done, they
can send an order message with an optional text message.`}</p>
    <Img src="/img/screenshots/quickstarts/whatsapp/whatsapp-cart.jpeg" align="middle" width="50%" mdxType="Img" />
    <p>{`Once they've ordered, the order message is displayed like the following in their WhatsApp app.`}</p>
    <Img src="/img/screenshots/quickstarts/whatsapp/order-message.jpeg" align="middle" width="50%" mdxType="Img" />
    <p>{`And the order message is available through Conversations API as a received message with type `}<inlineCode parentName="p">{`whatsappOrder`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "id": "d1803742ff474ffbb9ee3aa8e14c69ab",
  "conversationId": "01b6a227e1ba48bf976d70eb24d0dd58",
  "platform": "whatsapp",
  "to": "+31600000000",
  "from": "+31600000000",
  "channelId": "0181d0bb46c5449aae0309f822b35ba5",
  "type": "whatsappOrder",
  "content": {
    "whatsappOrder": {
      "catalog_id": "1004272927097129",
      "text": "Ok, I would like to order this one",
      "product_items": [
        {
          "product_retailer_id": "e86qq6z9hx",
          "quantity": 1,
          "item_price": 45,
          "currency": "EUR"
        }
      ]
    }
  },
  "direction": "received",
  "status": "received",
  "createdDatetime": "2022-01-18T15:22:41.884661867Z",
  "updatedDatetime": "2022-01-18T15:22:41Z"
}
`}</code></pre>
    <ul>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.whatsappOrder.catalog_id`}</inlineCode>{` contains the Facebook Catalog ID of the product the customer is ordering`}</li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.whatsappOrder.text`}</inlineCode>{` contains an optional text message`}</li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.whatsappOrder.product_items`}</inlineCode>{` contains an array with the ordered products`}</li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.whatsappOrder.product_items[].product_retailer_id`}</inlineCode>{` contains the Facebook Product ID which the customer
is ordering`}</li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.whatsappOrder.product_items[].quantity`}</inlineCode>{` contains the quantity of a particular product`}</li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.whatsappOrder.product_items[].item_price`}</inlineCode>{` contains the unit price of the product`}</li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.whatsappOrder.product_items[].currency`}</inlineCode>{` contains the currency`}</li>
    </ul>
    <h2>{`How to find my catalog and products?`}</h2>
    <p>{`Before start sending WhatsApp Single or Multi-Product Messages, it's important to know how to find the catalog ID and
the product ID in Facebook Commerce Manager, as this information will be necessary when sending messages to your customers.`}</p>
    <p>{`In case your Facebook Business Account doesn't have a product catalog, please refer to the links below to learn how to
create the catalog, add products to it and the best practices managing it.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.facebook.com/business/help/890714097648074?id=725943027795860"
        }}>{`About Catalogs`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.facebook.com/business/help/1275400645914358?id=725943027795860"
        }}>{`Create a Catalog`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.facebook.com/business/help/384041892421495?id=725943027795860"
        }}>{`Ways to Add Items to a Catalog`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.facebook.com/business/help/2086567618225367?id=725943027795860"
        }}>{`Best Practices for a High Quality Catalog`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.facebook.com/business/help/703150963581848?id=533228987210412"
        }}>{`Navigate Commerce Manager`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.facebook.com/business/help/158662536425974"
        }}>{`Connect a Catalog to Your WhatsApp Business Account`}</a></li>
    </ul>
    <h3>{`Finding the Catalog ID`}</h3>
    <p><strong parentName="p">{`Step 1`}</strong>{` - Go to your `}<a parentName="p" {...{
        "href": "https://business.facebook.com/settings/"
      }}>{`Facebook Business Settings`}</a></p>
    <p><strong parentName="p">{`Step 2`}</strong>{` - Select your business`}</p>
    <p><strong parentName="p">{`Step 3`}</strong>{` - On the left side menu, click on the Data Sources section, and then, click on Catalogs`}</p>
    <Img src="/img/screenshots/quickstarts/whatsapp/catalog-menu.png" align="middle" mdxType="Img" />
    <p><strong parentName="p">{`Step 4`}</strong>{` - Select the catalog in the list`}</p>
    <Img src="/img/screenshots/quickstarts/whatsapp/catalog-list.png" align="middle" mdxType="Img" />
    <p><strong parentName="p">{`Step 5`}</strong>{` - The catalog ID is visible on the top, in blue, below the catalog title`}</p>
    <Img src="/img/screenshots/quickstarts/whatsapp/catalog-id.png" width="800px" align="middle" mdxType="Img" />
    <p><strong parentName="p">{`Step 6`}</strong>{` - Click to copy the catalog ID`}</p>
    <h3>{`Finding the product ID`}</h3>
    <p><strong parentName="p">{`Step 1`}</strong>{` - In the Product Catalogs page, click on the button "Open Commerce Manager" in the top right corner. Or, if you know
the catalog ID and your business ID, you can use the following URL and replace the placeholders:
`}<inlineCode parentName="p">{`https://business.facebook.com/commerce/catalogs/{catalog-id}/products?business_id={business-id}`}</inlineCode></p>
    <Img src="/img/screenshots/quickstarts/whatsapp/catalog-id.png" width="800px" align="middle" mdxType="Img" />
    <p><strong parentName="p">{`Step 2`}</strong>{` - On the left side menu, click on the Catalog section, and then, click on Items`}</p>
    <Img src="/img/screenshots/quickstarts/whatsapp/catalog-items-menu.png" align="middle" mdxType="Img" />
    <p><strong parentName="p">{`Step 3`}</strong>{` - The Items page displays every product in the catalog. The ID of every product is visible in the field "Content ID"
below the product name.`}</p>
    <Img src="/img/screenshots/quickstarts/whatsapp/items-page.png" width="800px" align="middle" mdxType="Img" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      