import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Sending outbound voice messages with MessageBird`}</h1>
    <h3>{`⏱ 10 min build time`}</h3>
    <p>{`In this MessageBird Developer Tutorial you’ll learn how send voice messages with the MessageBird Voice API.`}</p>
    <p>{`Before we get started, have you set up your Ruby development environment and project directory with the MessageBird SDK?`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`No!`}</strong>{` Make sure you `}<a parentName="li" {...{
          "href": "/tutorials/setup-local-dev-environment-node"
        }}>{`read this MessageBird Developer Tutorial`}</a>{` before getting started.`}</li>
      <li parentName="ul"><strong parentName="li">{`Yes!`}</strong>{` Great! Now you can make your first API request and send an SMS message with MessageBird using Ruby.`}</li>
    </ul>
    <h2>{`Getting started`}</h2>
    <p>{`First, let's create a new file in the directory of your `}<inlineCode parentName="p">{`Gemfile`}</inlineCode>{` file and call it `}<inlineCode parentName="p">{`voice_call.rb`}</inlineCode>{`. Start by importing the MessageBird Ruby library and creating an instance of the MessageBird client:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`require 'messagebird'

# Load and initialize MessageBird SDK
client = MessageBird::Client.new('YOUR-API-KEY')
`}</code></pre>
    <p>{`Keep in mind that you can create either a `}<strong parentName="p">{`test`}</strong>{` or `}<strong parentName="p">{`live`}</strong>{` API key:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`test`}</strong>{` API keys simulate responses from the MessageBird server, allowing you to test your code flow and error handling before sending real messages. You still need to have an internet connection to use MessageBird REST API features.`}</li>
      <li parentName="ul"><strong parentName="li">{`live`}</strong>{` API keys allow you to send actual messages to your recipients. We recommend that you don’t publish this key anywhere.
To start using the SDK, replace `}<inlineCode parentName="li">{`YOUR-API-KEY`}</inlineCode>{` with your API key.`}</li>
    </ul>
    <p><strong parentName="p">{`Pro-tip`}</strong>{`: We're hard-coding your API key in your program to keep the tutorials straightforward. For production applications, we recommended you storing the key in a configuration file or environment variable instead and passing the variable to your application. You'll see this in practice later in our MessageBird Developer Tutorials for common use cases.`}</p>
    <h2>{`Making a voice call`}</h2>
    <p>{`Let’s create a voice call with a message as follows:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`msg = client.voice_message_create('+319876543211',
   'Hey you, a little bird told me you wanted a call!',
   { voice: 'male' })
puts msg
`}</code></pre>
    <p>{`We're calling `}<inlineCode parentName="p">{`voice_message_create`}</inlineCode>{` with these parameters:`}</p>
    <ul>
      <li parentName="ul">{`The first parameter is the recipient's phone number, including the country code.`}</li>
      <li parentName="ul">{`The second parameter is the message that the recipient will hear on the call.`}</li>
      <li parentName="ul">{`The third parameter is a Ruby hash containing optional attributes that you can specify for this phone call. Here, we're setting one of the optional attributes, the gender of the voice in the call, to "male". You can find more information about other optional attributes in our `}<a parentName="li" {...{
          "href": "/api/voice-calling#calls"
        }}>{`Voice Calling API Reference`}</a>{`.`}</li>
    </ul>
    <p>{`If the call succeeds, the application will print details about the call to the console. We've used an example phone number for the recipient's phone number. Keep in mind that for your application to work, you should replace this number with a working number.`}</p>
    <p>{`If the attempt to create a voice call fails, we print the errors to the console with the following clause:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`begin
 msg = client.voice_message_create('+319876543211',
     'Hey you, a little bird told me you wanted a call!',
     { voice: 'male' })
 puts msg
rescue MessageBird::ErrorException => ex
 errors = ex.errors.each_with_object([]) do |error, memo|
   memo << "Error code #{error.code}: #{error.description}"
 end.join("\\n")
 puts 'ERROR'
 puts errors
end
`}</code></pre>
    <h2>{`Finishing your program`}</h2>
    <p>{`Awesome! Now you have a fully functioning Ruby program that makes a call to your set destination phone number.`}</p>
    <p>{`To test your application, run in the terminal:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`ruby voice_call.rb
`}</code></pre>
    <p>{`You should see the API response logged in the terminal, signaling that you've successfully made a call. If you used a live API key and had verified your number or added credits to your account, your phone will ring shortly and deliver the message when you pick up the phone.`}</p>
    <p>{`If you see an error from the script try to read and understand the message so the problem can be fixed. If you have trouble still, don’t hesitate to contact us at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we’ll help you out.`}</p>
    <p><strong parentName="p">{`Nice work!`}</strong>{` 🎉`}</p>
    <p>{`You just initiated your first voice message with the MessageBird API!`}</p>
    <h2>{`Start building!`}</h2>
    <p>{`Let's head over to the next MessageBird Developer Tutorial learn how to `}<a parentName="p" {...{
        "href": "/tutorials/handle-incoming-calls-and-sms"
      }}>{`set up and handle incoming voice calls`}</a>{`.`}</p>
    <p>{`Want to start building your solution but not quite sure how to get started? Feel free to let us know at
`}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we'd love to help`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      