import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/ApiRef.js";
import CodeSnippet from 'components/CodeSnippet';
import * as CODE from 'constants/contactsv2';
import { APIWrapper, LeftColumn, RightColumn } from 'components/ApiRef';
import Helmet from 'react-helmet';
import Notification from 'components/Notification';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Helmet mdxType="Helmet">
      <meta name="robots" content="noindex" />
    </Helmet>
    <h1>{`Contacts API (V2)`}</h1>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <p>{`MessageBird’s Contacts API (V2) allows you to create and manage a single omni-channel profile for any customer you message on any of MessageBird’s supported platforms. This means that regardless of which platform a customer is messaging you on, you will always be able to identify who they are. Contacts API V2 is available for all customers using Programmable Conversations to message their customers over Facebook Messenger, LINE, Telegram, WeChat, SMS, and WhatsApp.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>API Endpoint</h4>
  <CodeSnippet code={CODE.BASE_URL} mdxType="CodeSnippet" />
        <h4>Endpoints Summary</h4>
  <CodeSnippet code={CODE.ENDPOINTS_SUMMARY} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <h2>{`Authorization`}</h2>
    <p>{`With each API call you'll need to set an access key to authenticate yourself. API keys can be created or retrieved through the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`MessageBird Dashboard`}</a>{`.`}</p>
    <p>{`To provide the access key, set the `}<inlineCode parentName="p">{`Authorization`}</inlineCode>{` header in the form of `}<inlineCode parentName="p">{`AccessKey {accessKey}`}</inlineCode>{`.`}</p>
    <h2>{`Formats and Headers`}</h2>
    <p>{`All API responses are in JSON. You're required to send the `}<inlineCode parentName="p">{`Accept`}</inlineCode>{` header with the value `}<inlineCode parentName="p">{`application/json`}</inlineCode>{` with each request.`}</p>
    <p>{`POST and PUT requests to the API must contain a JSON-formatted payload in the request body.`}</p>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Errors`}</h2>
        <p>{`MessageBird uses standard HTTP status codes to indicate success or failure of an API request. Codes in the 2xx range indicate that a request was successfully processed and codes in the 4xx range indicate that there was an error that resulted from the information sent (e.g. authentication, no balance or a missing or wrong parameter).`}</p>
        <p>{`In the case of an error, the body of the response includes a JSON-formatted response that tells you exactly what is wrong.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Error Object Example</h4>
  <CodeSnippet code={CODE.ERROR_EXAMPLE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <h3>{`Attributes`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`errors[].code`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An integer that represents the error type.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`errors[].description`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A human-readable description of the error. You can use this to let the user know what they can do about the error.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`errors[].parameter`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The parameter in your request related to the error if the error is parameter specific.`}</td>
        </tr>
      </tbody>
    </table>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`The Contact object`}</h2>
        <p>{`A Contact represents an end-user or customer that you have been in touch with on a messaging platform. A single contact might be present across multiple platforms, e.g. you might have a customer "Jane Doe" and she can be reached on SMS, WhatsApp, and Telegram because she has reached out to your business across all of these platforms. Jane can exist as a single contact in the Contacts API to make repeated communication seamless, regardless of what platform you're talking over.`}</p>
        <h3>{`Attributes`}</h3>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Attribute`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`id`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A unique random ID which is created on the MessageBird platform and is returned upon creation of the contact.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`displayName`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A display name (e.g. "{firstName} {lastName}", a username, or phone number) for the contact, typically used when displaying a contact in UI.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`firstName`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Optional. The first name of the contact.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`lastName`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Optional. The last name of the contact.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`languages`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Array`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Optional. An array of language identifiers. Will be populated with ISO 639-1 codes for the language of this contact if the contact has messaged on a platform that provides locale data.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`country`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Optional. An identifier the country this contact resides in. Will be populated with an ISO 3166-1 code if the contact has messaged on a platform that provides country data.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`avatar`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Optional. A URL linking to an avatar for this contact.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`gender`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Optional. A gender identifier for the customer. Free text format.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`status`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The status of this contact, one of: `}<inlineCode parentName="td">{`active`}</inlineCode>{`, `}<inlineCode parentName="td">{`merged`}</inlineCode>{`.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`profiles`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Array of Profile`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Optional. An array of the profiles that link this Contact to a specific channel.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`attributes`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`JSON`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Optional. Custom key-value attributes that can be assigned to the contact. Any valid JSON object is accepted, but the maximum size of the object is 10kB.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`href`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The URL for this contact resource.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`createdAt`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`date`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`RFC3339 formatted	timestamp of the creation time of the contact.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`updatedAt`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`date`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`RFC3339 formatted timestamp of the time of the last update made to the contact.`}</td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Example Contact</h4>
  <CodeSnippet code={CODE.CONTACT_OBJ} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <h2>{`The Identifier object`}</h2>
    <p>{`An Identifier is a value used by other platforms to Identify a contact.
This can be a generic identifier such as a phone number or email address that are used with multiple platforms,
as well as some platform specific identifiers such as your Facebook ID.`}</p>
    <h3>{`Attributes`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`id`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A unique random ID used to identify this identifier on the MessageBird platform.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The type of identifier.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`value`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The identifier of the user on the provided channel.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Types`}</h3>
    <p>{`Here is a list of possible identifier types. This list will expand as we add new channels.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`phonenumber`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A valid MSISDN used by channels like WhatsApp and SMS.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`emailaddress`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Email Address used by channels identifying with email.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`facebook`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Facebook user ID, Can only be used with the Facebook channel.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`wechat`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`WeChat user ID. Can only be used with the WeChat channel.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`line`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Line user ID. Can only be used with the Line channel.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`telegram`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Telegram user ID. Can only be used with the Telegram channel.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`instagram`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Instagram user ID. Can only be used with the Instagram channel.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`twitter`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Twitter user ID. Can only be used with the Twitter channel.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`The Profile object`}</h2>
    <p>{`The Profile object is a link between your contact on MessageBird and one of your configured messaging channels, e.g. a Facebook page, Telegram bot, or WhatsApp Business Account. Identifiers depend on the platform, for example, a WhatsApp channel identifier is an MSISDN registered to a user's account on WhatsApp, a Facebook identifier is a page-scoped ID value provided by Facebook.`}</p>
    <p>{`There is no limit to the number of profiles a contact can have. Some platforms, such as Facebook Messenger, have a different user identifier per page. This means your contact can have a profile for each configured channel even if some of those channels belong to the same platform.`}</p>
    <h3>{`Attributes`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`id`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A unique random ID used to identify this profile on the MessageBird platform.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`channelId`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The id of the MessageBird channel for this profile.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`identifier`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The platform-specific identifier of the user on the provided channel.`}</td>
        </tr>
      </tbody>
    </table>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h3>{`List all Contacts`}</h3>
        <p>{`Retrieves all contacts for this account. By default, contacts are sorted by the `}<inlineCode parentName="p">{`createdAt`}</inlineCode>{` field so that all new contacts appear first.`}</p>
        <h4>{`Parameters`}</h4>
        <p>{`No parameters are required; however, filters and pagination can be provided through the following `}<em parentName="p">{`optional`}</em>{` query parameters:`}</p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Parameter`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Example`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`ids`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Comma separated list of contact ids to filter.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`ids=idA,idB,idC`}</inlineCode></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`channelId`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Only return contacts that have a profile associated with this channel.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`channelId=2ccb501a4b0e440a8779103478292f84`}</inlineCode></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`identifierExact`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Only return contacts with this Identifier associated to the contact.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`identifierExact=jane.tester@messagebird.com`}</inlineCode></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`status`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Contact status. `}<inlineCode parentName="td">{`active`}</inlineCode>{` or `}<inlineCode parentName="td">{`merged`}</inlineCode>{`. Default: `}<inlineCode parentName="td">{`active`}</inlineCode>{`.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`status=active`}</inlineCode></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`limit`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The number of maximum objects to return on each request. Default: `}<inlineCode parentName="td">{`20`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`limit=10`}</inlineCode></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`offset`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The number of objects to skip from the first. Default: `}<inlineCode parentName="td">{`0`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`offset=120`}</inlineCode></td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.CONTACTINDEX_DEF} mdxType="CodeSnippet" />
  <h4>Example request</h4>
  <CodeSnippet code={CODE.CONTACTINDEX_REQ} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <RightColumn mdxType="RightColumn">
  <h4>Example response</h4>
  <CodeSnippet code={CODE.CONTACTINDEX_RESP} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Create a Contact`}</h2>
        <p>{`Create a new contact with the provided data and profiles.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.CREATECONTACT_DEF} mdxType="CodeSnippet" />
  <h4>Example request</h4>
  <CodeSnippet code={CODE.CREATECONTACT_REQ} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <RightColumn mdxType="RightColumn">
  <h4>Example response</h4>
  <CodeSnippet code={CODE.CREATECONTACT_RESP} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`View a Contact`}</h2>
        <p>{`Find an existing contact by id.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.GETCONTACT_DEF} mdxType="CodeSnippet" />
  <h4>Example request</h4>
  <CodeSnippet code={CODE.GETCONTACT_REQ} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <RightColumn mdxType="RightColumn">
  <h4>Example response</h4>
  <CodeSnippet code={CODE.GETCONTACT_RESP} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Update a Contact`}</h2>
        <p>{`Update properties of an existing contact by id. As this is a PATCH request, only fields that require changes need to be provided in the request.`}</p>
        <p>{`The `}<inlineCode parentName="p">{`languages`}</inlineCode>{` array field will be updated by replacing the entire array with what is provided in the request.`}</p>
        <p>{`The `}<inlineCode parentName="p">{`attributes`}</inlineCode>{` field will be updated as an `}<a parentName="p" {...{
            "href": "https://tools.ietf.org/html/rfc7386"
          }}>{`RFC7386 merge patch`}</a>{`. Existing attributes will be merged with new attributes provided in the patch request. If a key exists in the existing attributes and the patched attributes, it will be overwritten with the value from the patched attributes. Deleting an attribute is possible by setting it's value to null in the patch request.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.UPDATECONTACT_DEF} mdxType="CodeSnippet" />
  <h4>Example request</h4>
  <CodeSnippet code={CODE.UPDATECONTACT_REQ} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <RightColumn mdxType="RightColumn">
  <h4>Example response</h4>
  <CodeSnippet code={CODE.UPDATECONTACT_RESP} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Update or Create Contact`}</h2>
        <p>{`This method looks for an existing contact with passed identifiers and updates it with new ones from the request. If contact with such identifiers does not exist, it will create one. It returns updated or created contact ID and a flag that indicates if a contact was created.
If updateContact argument set to false, contact fields will not be updated if the contact already exists. Pass false if you are unsure.`}</p>
        <p>{`Use it if you don't know if a contact exists, otherwise use PATCH contact.`}</p>
        <h3>{`Attributes`}</h3>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Attribute`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`updateContact`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`boolean`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Weather to update contact if exists or not`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`contact.id`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A unique random ID which is created on the MessageBird platform and is returned upon creation of the contact.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`contact.displayName`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A display name (e.g. "{firstName} {lastName}", a username, or phone number) for the contact, typically used when displaying a contact in UI.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`contact.firstName`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Optional. The first name of the contact.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`contact.lastName`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Optional. The last name of the contact.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`contact.email`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`DEPRECATED (Use Identifiers instead). The primary registered email address for this contact.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`contact.phone`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`DEPRECATED (Use Identifiers instead). The primary registered phone number for this contact.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`contact.languages`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Array`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Optional. An array of language identifiers. Will be populated with ISO 639-1 codes for the language of this contact if the contact has messaged on a platform that provides locale data.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`contact.country`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Optional. An identifier the country this contact resides in. Will be populated with an ISO 3166-1 code if the contact has messaged on a platform that provides country data.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`contact.avatar`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Optional. A URL linking to an avatar for this contact.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`contact.gender`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Optional. A gender identifier for the customer. Free text format.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`contact.status`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The status of this contact, one of: `}<inlineCode parentName="td">{`active`}</inlineCode>{`, `}<inlineCode parentName="td">{`merged`}</inlineCode>{`.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`contact.profiles`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Array of Profile`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Optional. An array of the profiles that link this Contact to a specific channel.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`contact.identifiers`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Array of Identifier`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Optional. An array of the identifiers that link this Contact.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`contact.attributes`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`JSON`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Optional. Custom key-value attributes that can be assigned to the contact. Any valid JSON object is accepted, but the maximum size of the object is 10kB.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`contact.href`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The URL for this contact resource.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`contact.createdAt`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`date`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`RFC3339 formatted	timestamp of the creation time of the contact.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`contact.updatedAt`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`date`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`RFC3339 formatted timestamp of the time of the last update made to the contact.`}</td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.UPDATEORCREATECONTACT_DEF} mdxType="CodeSnippet" />
  <h4>Example request</h4>
  <CodeSnippet code={CODE.UPDATEORCREATECONTACT_REQ} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <RightColumn mdxType="RightColumn">
  <h4>Example response</h4>
  <CodeSnippet code={CODE.UPDATEORCREATECONTACT_RESP} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Delete a Contact`}</h2>
        <p>{`Deletes a contact and its profiles. You only need to supply the unique id that was returned upon creation.`}</p>
        <Notification type="WARNING" mdxType="Notification">
This request will hard delete the all conversations and conversation's messages that belongs to the contact ID.
        </Notification>
        <h4>{`Required parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Parameter`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`id`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A unique random ID which is created on the MessageBird platform and is returned upon creation of the object. `}<strong parentName="td">{`Required`}</strong></td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.DELETECONTACT_DEF} mdxType="CodeSnippet" />
        <h4>Example request</h4>
  <CodeSnippet code={CODE.DELETECONTACT_REQ} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Returns`}</h4>
        <p>{`Returns an HTTP header of `}<inlineCode parentName="p">{`204 No Content`}</inlineCode>{` and an empty response if the request was successful. If the request failed, an `}<a parentName="p" {...{
            "href": "/api#api-errors"
          }}>{`error object`}</a>{` will be returned.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Example response</h4>
  <CodeSnippet code={CODE.DELETECONTACT_RESP} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Merge contacts`}</h2>
        <p>{`Merge contact into another contact. It can be used to combine data from two different contacts, which should represent the same user. The one that's passed in the URL will be the active one.`}</p>
        <p>{`Note that if merged contact had active conversations, they will be merged as well.`}</p>
        <h3>{`Attributes`}</h3>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Attribute`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`origin`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A unique random ID of the original contact. `}<strong parentName="td">{`Required`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`destination`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A unique random ID of the destination contact. `}<strong parentName="td">{`Required`}</strong></td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.MERGECONTACT_DEF} mdxType="CodeSnippet" />
  <h4>Example request</h4>
  <CodeSnippet code={CODE.MERGECONTACT_REQ} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <RightColumn mdxType="RightColumn">
  <h4>Example response</h4>
  <CodeSnippet code={CODE.MERGECONTACT_RESP} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`List all identifiers of a contact`}</h2>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.LISTIDENTIFIERS_DEF} mdxType="CodeSnippet" />
  <h4>Example request</h4>
  <CodeSnippet code={CODE.LISTIDENTIFIERS_REQ} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <RightColumn mdxType="RightColumn">
  <h4>Example response</h4>
  <CodeSnippet code={CODE.LISTIDENTIFIERS_RESP} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Get a specific identifier of a contact`}</h2>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.GETIDENTIFIER_DEF} mdxType="CodeSnippet" />
  <h4>Example request</h4>
  <CodeSnippet code={CODE.GETIDENTIFIER_REQ} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <RightColumn mdxType="RightColumn">
  <h4>Example response</h4>
  <CodeSnippet code={CODE.GETIDENTIFIER_RESP} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Add an Identifier to an existing Contact`}</h2>
        <p>{`Link a identifier of a provided type to an existing Contact. Use this endpoint to add any known identifiers to a contact (such as email, phone, etc.).`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.LINKIDENTIFIER_DEF} mdxType="CodeSnippet" />
  <h4>Example request</h4>
  <CodeSnippet code={CODE.LINKIDENTIFIER_REQ} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <RightColumn mdxType="RightColumn">
  <h4>Example response</h4>
  <CodeSnippet code={CODE.LINKIDENTIFIER_RESP} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Delete an identifier from a contact`}</h2>
        <p>{`Deletes the referenced identifier and the profiles linked to it.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.DELETEIDENTIFIER_DEF} mdxType="CodeSnippet" />
  <h4>Example request</h4>
  <CodeSnippet code={CODE.DELETEIDENTIFIER_REQ} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Import Contacts`}</h2>
        <p>{`This endpoint allows you to upload up to 100k of contacts in a `}<inlineCode parentName="p">{`csv`}</inlineCode>{` file format. The required structure of a file is shown below.
When you’re uploading a `}<inlineCode parentName="p">{`csv`}</inlineCode>{` file, keep in mind that the headers must be set according to the headers fields below.`}</p>
        <h3>{`File header fields`}</h3>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Header`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`phone`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Optional. The primary registered phone number for this contact.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`email`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Optional. The primary registered email address for this contact.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`firstName`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Optional. The first name of the contact.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`lastName`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Optional. The last name of the contact.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`displayName`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A display name (e.g. "{firstName} {lastName}", a username, or phone number) for the contact, typically used when displaying a contact in UI.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`custom:`}<inlineCode parentName="td">{`key`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Any custom attributes you want to include in the contact, where `}<inlineCode parentName="td">{`key`}</inlineCode>{` is a unique key in the contacts Attribute object. Eg: `}<inlineCode parentName="td">{`custom:account_id`}</inlineCode></td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.IMPORT_DEF} mdxType="CodeSnippet" />
  <h4>Example request</h4>
  <CodeSnippet code={CODE.IMPORT_REQ} mdxType="CodeSnippet" />
  <h4>Sample CSV File</h4>
  <CodeSnippet code={CODE.IMPORT_BODY_EXMP} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h3>{`Response`}</h3>
        <p>{`When a file is uploaded, its id and tracking URL are created accordingly. The status of the importing contacts should be tracked with the `}<inlineCode parentName="p">{`url`}</inlineCode>{` output.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Example response</h4>
  <CodeSnippet code={CODE.IMPORT_RESP} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Track an Imported File`}</h2>
        <p>{`To track the imported file, provide the unique random id that is returned when the file was imported.`}</p>
        <h4>{`Required parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Parameter`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`id`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A unique random id created on the MessageBird platform that is returned when a file is imported. `}<strong parentName="td">{`Required`}</strong></td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.TRACK_IMPORT_DEF} mdxType="CodeSnippet" />
  <h4>Example request</h4>
  <CodeSnippet code={CODE.TRACK_IMPORT_REQ} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h3>{`Response`}</h3>
        <p>{`If the request is successful, it will return 200—this means the id exists; the response body will be as below. If the request fails, it means that the id could not be found.`}</p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Attributes`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`status`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Status of the tracking. It can be `}<inlineCode parentName="td">{`completed`}</inlineCode>{`, `}<inlineCode parentName="td">{`failed`}</inlineCode>{`, `}<inlineCode parentName="td">{`queued`}</inlineCode>{` or `}<inlineCode parentName="td">{`in progress`}</inlineCode></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`message`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Describing the status of importing the file`}</td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Example response</h4>
  <CodeSnippet code={CODE.TRACK_IMPORT_RESP} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      