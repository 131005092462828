import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { MDXProvider } from '@mdx-js/react';
import {
  Paragraph,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  Pre,
  OrderedList,
  UnOrderedList,
} from '../Typography';
import Link from '../Link';
import { Table } from '../Tables/tableStyles';
import CodeSnippet from '../CodeSnippet';
import WithAnchor from '../WithAnchor';

const MarkdownCode = ({ className, children, inline, ...props }) => {
  const lang = className
    .split(' ')
    .filter((l) => l.indexOf('language-') > -1)
    .reduce(
      (acc, cur, idx, langs) => (langs[0] || '').replace('language-', ''),
      null,
    );
  if (!lang) {
    return (
      <Pre {...props} inline={inline}>
        {children}
      </Pre>
    );
  }
  return (
    <CodeSnippet
      {...props}
      lang={lang}
      code={{ [lang]: children }}
      inline={inline}
    />
  );
};

MarkdownCode.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  inline: PropTypes.bool,
};

MarkdownCode.defaultProps = {
  children: null,
  className: '',
  inline: false,
};

const MarkdownPre = (props) => {
  const { children } = props;
  if (children && children.props && children.props.name === 'code') {
    return children;
  }
  return <Pre {...props} />;
};

MarkdownPre.propTypes = {
  children: PropTypes.node,
};

MarkdownPre.defaultProps = {
  children: null,
};

const StyledLink = styled(Link)`
  font-weight: bold;
`;

const MarkdownLink = ({ href, ...props }) => {
  if (href && href.startsWith('mailto')) {
    return <a {...props} href={href} />;
  }
  return <StyledLink {...props} to={href} />;
};

MarkdownLink.propTypes = {
  href: PropTypes.string.isRequired,
};

const MarkdownCodeInline = (props) => <MarkdownCode {...props} inline />;

const MarkdownImg = styled.img`
  box-shadow: 0px 2px 8px 1px rgba(238, 244, 255, 1);
  margin-top: 40px;
  margin-bottom: 70px;
  max-width: 100%;
`;

const NotPre = styled.span`
  background-color: #eef4ff;
  border-radius: 4px;
  font-size: 90%;
  padding: 2px 4px;
  color: #687992;
`;

const Markdown = ({ children, customComponents, api }) => (
  <MDXProvider
    components={{
      h1: WithAnchor(H1),
      h2: WithAnchor(H2),
      h3: WithAnchor(H3),
      h4: WithAnchor(H4),
      h5: WithAnchor(H5),
      h6: WithAnchor(H6),
      p: Paragraph,
      table: Table,
      ol: OrderedList,
      ul: UnOrderedList,
      a: MarkdownLink,
      pre: MarkdownPre,
      code: MarkdownCode,
      inlineCode: api ? NotPre : MarkdownCodeInline,
      img: MarkdownImg,
      ...customComponents,
    }}
  >
    {children}
  </MDXProvider>
);

Markdown.propTypes = {
  children: PropTypes.node.isRequired,
  customComponents: PropTypes.shape({}).isRequired,
};

export default Markdown;
