import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Building a Basic IVR System with MessageBird`}</h1>
    <p>{`During a voice call, the caller can press keys that trigger so-called DTMF tones. By capturing this user input and responding accordingly, you can build sophisticated IVR (Interactive Voice Response) systems.`}</p>
    <p>{`In this MessageBird Tutorial you’ll learn the basics of support call centers and build a sophisticated IVR system powered by the MessageBird API. We'll cover a basic support call center scenario, where the caller is greeted with a message and asked to press the key for the department they want to speak with; after pressing the corresponding key, the call is transferred to the department’s phone number.`}</p>
    <h2>{`Step 1: Setting a call flow`}</h2>
    <p>{`We start with setting a call flow that defines steps for greeting the caller, pausing for 10 seconds, and capture and handle the keys pressed during the message or the pause.`}</p>
    <p>{`For this scenario, we'll have to introduce conditional logic to our call flow. After capturing user input, we want to execute specific steps only if the condition we set is evaluated as "true".`}</p>
    <p>{`The call flow looks like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<?xml version="1.0" encoding="UTF-8"?>
<Say language="en-US" voice="female" onKeypressVar="department" onKeypressGoto="transferToDepartment">Welcome to MessageBird. Press 1 for sales, or 2 for support.</Say>
<Pause length="10s" onKeypressVar="department" onKeypressGoto="transferToDepartment"/>
<Say language="en-US" voice="female">You didn't press anything. Good bye!</Say>
<Hangup/>

<If id="transferToDepartment" condition="department == 1">
  <Say language="en-US" voice="female">You're now transferred to the sales department.</Say>
  <Transfer destination="31971111111"/>
  <Hangup/>
</If>
<If condition="department == 2">
  <Say language="en-US" voice="female">You're now transferred to the support department.</Say>
  <Transfer destination="31972222222"/>
  <Hangup/>
</If>
<Say language="en-US" voice="female">You pressed something, but it wasn't 1 or 2. Good bye!</Say>
`}</code></pre>
    <p>{`During the greeting and the pause, using the `}<inlineCode parentName="p">{`onKeypressVar`}</inlineCode>{` parameter we store the key that was pressed in a variable called `}<em parentName="p">{`department`}</em>{`. Also, we define an `}<inlineCode parentName="p">{`onKeypressGoto`}</inlineCode>{` parameter that causes the call flow to skip to the step with the corresponding id value if a key is pressed.`}</p>
    <p>{`The If tag is used for evaluating if a step should be executed. The value of the condition parameter is of the form `}<inlineCode parentName="p">{`{variable} {operator} {value}`}</inlineCode>{`. In the XML call flow above we use department == 1. If the user pressed 1 before, this condition would evaluate as "true", and the nested steps will be executed. If not, the call flow will proceed to the next tag in the call flow. You can define multiple If tags which will be parsed in sequence.`}</p>
    <p>{`The cURL command to create the above call flow through the Voice API looks like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`curl -X "PUT" "https://voice.messagebird.com/numbers/:your_number/call-flow" \\
     -H "Authorization: AccessKey :your_access_key:" \\
     -H "Content-Type: application/xml" \\
     -d $'<?xml version="1.0" encoding="UTF-8"?>
<Say language="en-US" voice="female" onKeypressVar="department" onKeypressGoto="transferToDepartment">Welcome to MessageBird. Press 1 for sales, or 2 for support.</Say>
<Pause length="10s" onKeypressVar="department" onKeypressGoto="transferToDepartment"/>
<Say language="en-US" voice="female">You didn't press anything. Good bye!</Say>
<Hangup/>

<If id="transferToDepartment" condition="department == 1">
  <Say language="en-US" voice="female">You're now transferred to the sales department.</Say>
  <Transfer destination="31971111111"/>
  <Hangup/>
</If>
<If condition="department == 2">
  <Say language="en-US" voice="female">You're now transferred to the support department.</Say>
  <Transfer destination="31972222222"/>
  <Hangup/>
</If>

<Say language="en-US" voice="female">You pressed something, but it wasn't 1 or 2. Good bye!</Say>'
`}</code></pre>
    <h2>{`Step 2: Assign a number to the call flow`}</h2>
    <p>{`To have the call flow executed when a number you own is called, you first need to assign that number to the call flow. The endpoint to do this is:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`POST https://voice.messagebird.com/call-flows/:call_flow_id:/numbers
`}</code></pre>
    <p>{`The following is an example with cURL:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`curl -X "POST" "http://voice.messagebird.com/call-flows/:call_flow_id/numbers" \\
     -H "Authorization: AccessKey :your_access_key:" \\
     -d $'{
  "numbers": ["31612345678"]
}'
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`call_flow_id`}</inlineCode>{` parameter is the ID of the call flow created in step 1.
The string value in the `}<inlineCode parentName="p">{`numbers`}</inlineCode>{` array is the E.164 formatted number you want to assign. Keep in mind that this must be a number you previously purchased. Buying a MessageBird number is quite easy, in `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/articles/208011689-Buy-a-Number-"
      }}>{`this Help Center article`}</a>{` we explain to you how to do it. `}</p>
    <p>{`Awesome! After making this request, the call flow will be executed for every incoming call to the number. `}</p>
    <h2>{`Step 3: Testing the call flow`}</h2>
    <p>{`Dial the number you used in step 2. You should hear the greeting message and a pause of 10 seconds. If you press 1 or 2 during the greeting or the pause, you should hear a message stating you're now being transferred to the applicable department. If you don't press a key during the greeting or the pause, you should hear a message saying "You didn't press anything. Goodbye!". If you pressed a key that wasn't 1 or 2, you should hear a message that says just that.`}</p>
    <p>{`By default, steps are sequentially executed in order, but as you can see by looking at the XML call flow and the description above, you can alter the flow of step execution by skipping around the call flow based on key presses and by using If `}<em parentName="p">{`conditions`}</em>{` to evaluate them.`}</p>
    <h2>{`Keep in mind`}</h2>
    <p>{`This tutorial uses a fairly simple static call flow; the outcome of the keypress is always the same for every caller. You can also configure a "fetch" call flow that queries your web service via HTTP to obtain a call flow. This approach is outlined in the ‘`}<a parentName="p" {...{
        "href": "/api/voice-calling#dynamic-call-flows"
      }}>{`Dynamic call flows’ section`}</a>{` of our Voice Calling API documentation.`}</p>
    <p>{`By using a "fetch" call flow, you can alter the behavior of your call flow based on dynamic values, for example, the number that's calling or the time of day. Because your web service parses the incoming request, it can programmatically decide and assemble the call flow to return.`}</p>
    <p><strong parentName="p">{`Nice work!`}</strong>{` 🎉`}</p>
    <p>{`You just learned how to build a basic IVR system! `}</p>
    <h2>{`Start building!`}</h2>
    <p>{`Want to build something similar but not quite sure how to get started? Feel free to let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we'd love to help!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      