import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Docs.tsx";
import Notification from 'components/Notification';
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Make an Outbound Voice Call`}</h1>
    <p>{`In this Programmable Voice Calling Quickstart, you'll learn how to make an outbound voice call from your web application using the Voice Calling API.`}</p>
    <h2>{`Step 1: Set up your test environment`}</h2>
    <p>{`To start testing with the official Go SDK for Programmable Voice Calling, you need to first set up your test environment.`}</p>
    <p>{`The following command will install MessageBird:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`go get github.com/messagebird/go-rest-api
`}</code></pre>
    <Notification type="INFO" mdxType="Notification">
Want to learn how to set up your Go development environment? Check out the <a href="tutorials/setup-local-dev-environment-go">Go tutorial</a>
    </Notification>
    <h2>{`Step 2: Make your first voice call`}</h2>
    <p>{`Now, include the following code snippet in your `}<inlineCode parentName="p">{`main.go`}</inlineCode>{` file. You can now enter `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`your credentials`}</a>{` in the code snippet below, including `}<inlineCode parentName="p">{`YOUR-API-KEY`}</inlineCode>{` with your API key, and enter your mobile number as both the `}<inlineCode parentName="p">{`source`}</inlineCode>{` and `}<inlineCode parentName="p">{`destination`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`package main

import (
    "fmt"
    "log"

    "github.com/messagebird/go-rest-api"
    "github.com/messagebird/go-rest-api/voice"
)

func main() {
    //client := messagebird.New(os.Getenv("YOUR-API-KEY"))
    client := messagebird.New("YOUR-API-KEY")

    source, dest := "31123456789", "31123456789"
    callflow := voice.CallFlow{
        Title: "Test flow",
        Steps: []voice.CallFlowStep{
            &voice.CallFlowSayStep{
                Voice:    "female",
                Payload:  "Hey you, a little bird told me you wanted a call!",
                Language: "en-GB",
            },
        },
    }

    call, err := voice.InitiateCall(
        client,
        source,
        dest,
        callflow,
        nil,
    )
    if err != nil {
        switch errResp := err.(type) {
        case messagebird.ErrorResponse:
            for _, mbError := range errResp.Errors {
                fmt.Printf("Error: %#v\\n", mbError)
            }
        }

        return
    }
    // We're logging call for development purposes. You can safely discard this variable in production.
    log.Println(call)
}
`}</code></pre>
    <Notification type="INFO" mdxType="Notification">
You can access and manage your credentials in the <a href="https://dashboard.messagebird.com/en/developers/access">MessageBird Developer Dashboard</a>.
    </Notification>
    <p>{`Now, run the following command line:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`go run main.go
`}</code></pre>
    <p>{`Good job! You've just made your first outbound voice call with the official Go SDK with Programmable Voice Calling!`}</p>
    <h2>{`Step 3: View the response`}</h2>
    <p>{`Now, let’s view MessageBird’s HTTP response to your API call. If the set up was correct, you'll receive a response similar JSON to this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`2019/02/19 11: 49: 55 &{
    7138c9dd-bb9d-4217-abd6-fe335c940dc2 queued 31123456789 31123456789  2019-02-19 10: 49: 57 +0000 UTC 2019-02-19 10: 49: 57 +0000 UTC <nil>
}
`}</code></pre>
    <p>{`If something went wrong, you’ll receive an error response from the Voice Calling API. Don't worry, simply head over to `}<a parentName="p" {...{
        "href": "/docs/voice-calling/troubleshooting"
      }}>{`Programmable Voice Calling Troubleshooting`}</a>{` to understand the error.`}</p>
    <p>{`Congrats! You can now programmatically make outbound Voice Calls with MessageBird's Voice Calling API using Go.`}</p>
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'Programmatically handle inbound Voice Calls',
      to: '/docs/voice-calling/handle-inbound-calls'
    }, {
      name: 'Voice Calling API Use Case Tutorials and Sample Projects',
      to: '/tutorials?filter=voice'
    }, {
      name: 'Programmable Voice Calling API Reference',
      to: '/api/voice-calling'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      