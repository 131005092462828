import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Setting SMS server alerts with MessageBird`}</h1>
    <h3>{`⏱ 30 min build time      ||      `}<a parentName="h3" {...{
        "href": "https://github.com/messagebirdguides/sms-server-alerts-guide-php"
      }}>{`Download the Code`}</a></h3>
    <h2>{`Why build SMS server alerts?`}</h2>
    <p>{`In this MessageBird Developer Tutorial, you’ll learn how to quickly report error logs of your server via SMS server alerts with the MessageBird API to ensure a faster response time.`}</p>
    <p>{`For any online service advertising guaranteed uptime north of 99%, being available and reliable is extremely important; therefore, it is essential that any errors in the system are fixed as soon as possible, and the prerequisite for that is that error reports are delivered quickly to the engineers on duty. Providing those error logs via SMS ensures a faster response time compared to email reports and helps companies keep their uptime promises.`}</p>
    <p>{`We’ll show you how to build an integration of SMS alerts into a PHP application that uses the `}<a parentName="p" {...{
        "href": "https://packagist.org/packages/monolog/monolog"
      }}>{`Monolog`}</a>{` logging framework.`}</p>
    <h2>{`Logging Primer`}</h2>
    <p>{`Logging is the default approach for gaining insights into running applications. Before we start building our sample application, let's take a minute to understand two fundamental concepts of logging: levels and handlers.`}</p>
    <p><strong parentName="p">{`Levels`}</strong>{` indicate the severity of the log item. Common log levels are `}<em parentName="p">{`debug`}</em>{`, `}<em parentName="p">{`info`}</em>{`, `}<em parentName="p">{`warning`}</em>{`, and `}<em parentName="p">{`error`}</em>{`. For example, a user trying to login could have the `}<em parentName="p">{`info`}</em>{` level, a user entering the wrong password during login could be a `}<em parentName="p">{`warning`}</em>{` since it may be a potential attack, and a user not able to access the system due to a subsystem failure would trigger an `}<em parentName="p">{`error`}</em>{`.`}</p>
    <p><strong parentName="p">{`Handlers`}</strong>{` are different channels into which the logger writes its data. Typical channels are the console, files, log collection servers and service, and communication channels such as email, SMS, or push notifications.`}</p>
    <p>{`It's possible and common to set up multiple kinds of handlers for the same logger but set different levels for each. In our sample application, we write entries of all severities to the console and everything above `}<em parentName="p">{`info`}</em>{` to a log file; the application will send SMS notifications only for log items that have the `}<em parentName="p">{`error`}</em>{` level (or higher, when using more levels).`}</p>
    <h2>{`Getting started`}</h2>
    <p>{`First things first, our sample application is built in PHP and uses Monolog as the logging library, so if you're using a Mac, PHP is already installed; for Windows users you can `}<a parentName="p" {...{
        "href": "https://windows.php.net/download/"
      }}>{`get it from windows.php.net`}</a>{`; for Linux users, please check your system's default package manager. We have also included a middleware for the `}<a parentName="p" {...{
        "href": "https://www.slimframework.com/"
      }}>{`Slim`}</a>{` framework to demonstrate web application request logging. Many other frameworks already have some built-in Monolog support.`}</p>
    <p>{`You also need Composer, which is available from `}<a parentName="p" {...{
        "href": "https://getcomposer.org/download/"
      }}>{`getcomposer.org`}</a>{`.`}</p>
    <p>{`The source code is available in the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/sms-server-alerts-guide-php"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`, which you can either clone with git or from where you can download a ZIP file with the source code to your computer.`}</p>
    <p>{`To install Monolog, the `}<a parentName="p" {...{
        "href": "https://github.com/messagebird/php-rest-api"
      }}>{`MessageBird SDK for PHP`}</a>{`, and the framework, open a console pointed at the directory into which you've stored the sample code and run the following command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`composer install
`}</code></pre>
    <h2>{`Building a MessageBird log handler`}</h2>
    <p>{`Monolog enables developers to build custom handlers and use them with the logger just like built-in handlers such as the StreamHandler. The easiest way to build a new handler is writing a new class that extends `}<inlineCode parentName="p">{`Monolog\\Handler\\AbstractProcessingHandler`}</inlineCode>{`. It needs to implement a constructor for initialization as well as the `}<inlineCode parentName="p">{`write()`}</inlineCode>{` method.`}</p>
    <p>{`We have created one in the file `}<inlineCode parentName="p">{`MessageBirdHandler.php`}</inlineCode>{`.`}</p>
    <p>{`Our SMS alert functionality needs the following information to work:`}</p>
    <ul>
      <li parentName="ul">{`A functioning MessageBird API key.`}</li>
      <li parentName="ul">{`An originator, that is, a sender ID for the messages.`}</li>
      <li parentName="ul">{`One or more recipients, that is, the phone numbers of the system engineers that should be informed about problems with the server.`}</li>
    </ul>
    <p>{`To keep the custom handler self-contained and independent from the way the application wants to provide the configuration, we take all this as an options array in our constructor. Here's the code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`class MessageBirdHandler extends AbstractProcessingHandler {

   private $messagebird;
   private $message;

   public function __construct(array $options, $level = Logger::DEBUG, bool $bubble = true) {
       if (!isset($options['apiKey']) || !isset($options['originator']) || !isset($options['recipients']))
           throw new \\Exception("Incomplete configuration parameters. Required: apiKey, originator, recipients");

       $this->messagebird = new Client($options['apiKey']);
       $this->message = new Message;
       $this->message->originator = $options['originator'];
       $this->message->recipients = $options['recipients'];

       parent::__construct($level, $bubble);
   }
`}</code></pre>
    <p>{`As you can see, the constructor first verifies that all necessary configuration has been provided; then loads and initializes the MessageBird SDK client (`}<inlineCode parentName="p">{`MessageBird\\Client`}</inlineCode>{`) with the API key; it also initializes a `}<inlineCode parentName="p">{`MessageBird\\Objects\\Message`}</inlineCode>{` object as a template for all messages and assigns the originator and recipients to it (both are stored as members of the handler object); finally, it calls the parent constructor with the standard handler parameters `}<inlineCode parentName="p">{`$level`}</inlineCode>{` and `}<inlineCode parentName="p">{`$bubble`}</inlineCode>{`.`}</p>
    <p>{`Then, in the `}<inlineCode parentName="p">{`write()`}</inlineCode>{` method, we shorten the formatted log entry, to make sure it fits in the 160 characters of a single SMS so that notifications won't incur unnecessary costs or break limits, and assign it as the body of our message object which we prepared in the constructor:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`   protected function write(array $record) {
       // Shorten log entry
       $this->message->body = (strlen($record['formatted']) > 140)
           ? substr($record['formatted'], 0, 140) . ' ...'
           : $record['formatted'];
`}</code></pre>
    <p>{`Finally, we call `}<inlineCode parentName="p">{`messages->create()`}</inlineCode>{` to send an SMS notification. This method takes one parameter, our message object:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`   // Send notification with MessageBird SDK
   try {
       $this->messagebird->messages->create($this->message);
   } catch (Exception $e) {
       error_log(get_class($e).": ".$e->getMessage());
   }
}
`}</code></pre>
    <p>{`In the catch block for exceptions thrown by the MessageBird SDK, we only log the response to the console and don't do anything else (we can't record it with Monolog here because then we might get stuck in an infinite loop).`}</p>
    <h2>{`Initializing Monolog with our handler`}</h2>
    <p>{`For our sample application, we use `}<a parentName="p" {...{
        "href": "https://packagist.org/packages/vlucas/phpdotenv"
      }}>{`Dotenv`}</a>{` to load configuration data from a `}<inlineCode parentName="p">{`.env`}</inlineCode>{` file.`}</p>
    <p>{`Copy `}<inlineCode parentName="p">{`env.example`}</inlineCode>{` to `}<inlineCode parentName="p">{`.env`}</inlineCode>{` and store your information:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`MESSAGEBIRD_API_KEY=YOUR-API-KEY
MESSAGEBIRD_ORIGINATOR=Monolog
MESSAGEBIRD_RECIPIENTS=31970XXXXXXX,31970YYYYYYY
`}</code></pre>
    <p>{`You can create or retrieve an API key `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`in your MessageBird Dashboard`}</a>{`. The originator can be a phone number you registered through MessageBird or, for countries that support it, an alphanumeric sender ID with at most 11 characters. You can provide one or more comma-separated phone numbers as recipients. Keep in mind that alphanumeric senders are not supported in every country including the United States, so it’s important to check the `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/sections/360000108538-Country-info-Restrictions"
      }}>{`country restrictions`}</a>{`. If you can't use alphanumeric IDs, use a real phone number instead. You can check our `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/articles/115002628665-What-is-the-originator-"
      }}>{`originator article`}</a>{` in Help Center to learn more about this topic.`}</p>
    <p>{`Now, in `}<inlineCode parentName="p">{`index.php`}</inlineCode>{`, the primary file of our application, we start of by including initializing the framework and Dotenv. Then, we add Monolog to Slim's dependency injection container:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`// Initialize Logger
$container['logger'] = function() {
   $logger = new Monolog\\Logger('App');
   $logger->pushHandler(new Monolog\\Handler\\ErrorLogHandler(0, Monolog\\Logger::DEBUG));
   $logger->pushHandler(new Monolog\\Handler\\StreamHandler('app.log', Monolog\\Logger::INFO));
   $logger->pushHandler(new MessageBirdHandler([
       'apiKey' => getenv('MESSAGEBIRD_API_KEY'),
       'originator' => getenv('MESSAGEBIRD_ORIGINATOR'),
       'recipients' => explode(',', getenv('MESSAGEBIRD_RECIPIENTS'))
   ], Monolog\\Logger::ERROR));

   return $logger;
};
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`Monolog\\Logger`}</inlineCode>{` constructor takes a name for the logger as its parameter. Using the `}<inlineCode parentName="p">{`pushHandler()`}</inlineCode>{` method, you can define one or more handlers. As you see in the example, we have added three of them:`}</p>
    <ul>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`Monolog\\Handler\\ErrorLogHandler`}</inlineCode>{`, which logs everything starting with the `}<em parentName="li">{`debug`}</em>{` level to the default error log, that is, writing log entries to the console output of PHP's default web server.`}</li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`Monolog\\Handler\\StreamHandler`}</inlineCode>{`, which logs `}<em parentName="li">{`info`}</em>{` and higher into a file called `}<inlineCode parentName="li">{`app.log`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`Our previously created `}<inlineCode parentName="li">{`MessageBirdHandler`}</inlineCode>{` with all the configuration options taken from our environment file or environment variables using `}<inlineCode parentName="li">{`getenv()`}</inlineCode>{`. We convert the comma-separated recipients into an array with `}<inlineCode parentName="li">{`explode(',')`}</inlineCode>{`. This handler only sees log events with the `}<em parentName="li">{`error`}</em>{` level.`}</li>
    </ul>
    <h2>{`Creating a Slim middleware for request logging`}</h2>
    <p>{`After setting up a Slim app, you can call `}<inlineCode parentName="p">{`$app->add()`}</inlineCode>{` to specify middleware. Middlewares are extensions to Slim that touch each request and are useful for globally required functionality such as authentication or, in our example, logging:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`// Set up middleware to log requests and responses
$app->add(function($request, $response, callable $next) {
   $response = $next($request, $response);
   $code = $response->getStatusCode();
   $logLine = '[' . $code . '] ' . $request->getMethod() . ' ' . (string)$request->getUri();
   if ($code >= 500)
       $this->logger->error($logLine);
   elseif ($code < 500 && $code >= 400)
       $this->logger->warn($logLine);
   else
       $this->logger->info($logLine);

   return $response;
});
`}</code></pre>
    <p>{`Our Middleware does the following:`}</p>
    <ul>
      <li parentName="ul">{`It calls `}<inlineCode parentName="li">{`$next()`}</inlineCode>{` to process the request and other middleware first so that logging happens afterward.`}</li>
      <li parentName="ul">{`It formulates a log line which contains the status code, request method, and request URI. Feel free to add more information here.`}</li>
      <li parentName="ul">{`Depending on the status code, it calls the logging method for the appropriate level. Codes 500 and higher, used for server errors, call `}<inlineCode parentName="li">{`error()`}</inlineCode>{`. Codes between 400 and 500, used for client errors, call `}<inlineCode parentName="li">{`warn()`}</inlineCode>{`. Other codes that typically indicate success or desired behavior call `}<inlineCode parentName="li">{`info()`}</inlineCode>{`.`}</li>
    </ul>
    <h2>{`Using the logger directly`}</h2>
    <p>{`You’re not limited to using your Monolog setup for automated request and response logging from the Slim middleware. If you want to log any error or information from your application logic, you can always call the respective method on the `}<inlineCode parentName="p">{`$this->logger`}</inlineCode>{` object.`}</p>
    <p>{`We have added some code to demonstrate that:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`// Demo Error Route
$app->get('/makeLogEntries', function($request, $response) {
   $this->logger->debug("This is a test at debug level.");
   $this->logger->info("This is a test at info level.");
   $this->logger->warn("This is a test at warning level.");
   $this->logger->error("This is a test at error level.");

   return "You should see some log entries.";
});
`}</code></pre>
    <h2>{`Testing`}</h2>
    <p>{`You’re done! We have created two Slim test routes to simulate a 200 success response and a 500 server response. It’s time to test your application! Go to your console and type the following command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`php -S 0.0.0.0:8080 index.php
`}</code></pre>
    <p>{`Go to http://localhost:8080/. For the successful request; you’ll see a log entry on the console and in the file `}<inlineCode parentName="p">{`app.log`}</inlineCode>{`.`}</p>
    <p>{`Now, open http://localhost:8080/simulateError; for the error request, you should not only see a log entry on the console and in the file, but also a notification should arrive on your phone.`}</p>
    <p>{`To receive even more log entries, go to http://localhost:8080/makeLogEntries; it creates multiple log entries of different severity levels, at least one of which pings your phone.`}</p>
    <p>{`You can now take these elements and integrate them into a Node.js production application. Don't forget to download the code from the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/sms-server-alerts-guide-php"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`.`}</p>
    <p><strong parentName="p">{`Nice work!`}</strong>{` 🎉`}</p>
    <p>{`You've learned how to log with Winston and Express-Winston to create a custom MessageBird transport using PHP!`}</p>
    <h2>{`Start building!`}</h2>
    <p>{`Want to start building your solution but not quite sure how to get started? Feel free to let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we'd love to help!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      