import React from 'react';
import styled from 'styled-components';
import { Link } from '@reach/router';
import { node, bool } from 'prop-types';

import { COLOR } from '../../../constants';

const ItemComponent = styled(Link)`
  line-height: 18px;
  font-size: inherit;
  margin: 0;
  margin-bottom: 24px;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-shrink: 0;

  font-weight: ${(props) => (props.active ? 600 : 'inherit')};
  color: ${(props) => (props.active ? COLOR.BLUE : 'inherit')};

  :hover {
    color: ${(props) => (props.active ? COLOR.BLUE : COLOR.GREY_HOVER_MID)};
  }
`;

const Item = ({ children, ...props }) => {
  return <ItemComponent {...props}>{children}</ItemComponent>;
};

Item.propTypes = {
  children: node.isRequired,
  active: bool, // Is passed to the styled component
};

Item.defaultProps = {
  active: false,
};

export default Item;
