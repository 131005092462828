import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
import CodeSnippet from 'components/CodeSnippet';
import * as CODE from 'constants/live-chat-widget';
import { Img } from 'components/shared';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Installing the Omnichannel Widget`}</h1>
    <p>{`The Omnichannel Widget enables you to communicate with your teams on your homepage, in your app, on live chat or any messaging service — WhatsApp, Messenger, WeChat, SMS, and more.`}</p>
    <p>{`In this guide, you'll learn how to install the Omnichannel Widget on your website.`}</p>
    <h2>{`Step 1`}</h2>
    <p>{`First things first, install Live Chat as an Inbox Channel and configure the Omnichannel Widget to make it your own.`}</p>
    <h2>{`Step 2`}</h2>
    <p>{`Once you’re happy with your custom Omnichannel Widget configuration, it’s time to install the Omnichannel Widget code snippet on your website.`}</p>
    <h2>{`Step 3`}</h2>
    <p>{`To collect your unique Javascript code snippet, click Add widget to your website on the top-right of your screen above the widget preview.`}</p>
    <Img src="/img/screenshots/quickstarts/ocw/install_step3.png" alt="Install Omnichannel Widget" width="650px" align="middle" mdxType="Img" />
    <h2>{`Step 4`}</h2>
    <p>{`Your unique Javascript code snippet will then be displayed in a modal. We’ve prepopulated all your configurations and workspace data so all you need to do is copy-to-clipboard and embed this in your website code.`}</p>
    <Img src="/img/screenshots/quickstarts/ocw/install_step4.png" alt="Install Omnichannel Widget" width="650px" align="middle" mdxType="Img" />
    <h2>{`Step 5`}</h2>
    <p>{`The Omnichannel Widget Javascript API can be accessed through:`}</p>
    <CodeSnippet code={CODE.USAGE} mdxType="CodeSnippet" />
    <p>{`To initialize the `}<a parentName="p" {...{
        "href": "https://inbox.messagebird.com/manager/omnichannel-widget"
      }}>{`Omnichannel Widget`}</a>{` , you need to first embed your unique Omnichannel Widget installation snippet in either the header of your website (for all pages) or on the header of each page you wish to display the Omnichannel Widget.`}</p>
    <p>{`You can find your installation snippet in your `}<a parentName="p" {...{
        "href": "https://inbox.messagebird.com/manager/omnichannel-widget"
      }}>{`Omnichannel Widget Settings in Inbox.`}</a></p>
    <p>{`Here is an example of the installation snippet:`}</p>
    <CodeSnippet code={CODE.SNIPPET_CODE} mdxType="CodeSnippet" />
    <p>{`Here’s how to embed the Javascript code snippet on your website:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Copy the full Javascript code snippet`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Go to the `}<inlineCode parentName="p">{`<body>`}</inlineCode>{` section of your website`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Paste the full Javascript code snippet as the last line before the closing `}<inlineCode parentName="p">{`</body>`}</inlineCode>{` tag.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Repeat the process on each page you want the widget to show up.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Deploy your changes and tada! 🎉 The Omnichannel Widget is now live on your website.`}</p>
      </li>
    </ol>
    <h2>{`Step 6`}</h2>
    <p>{`You can now toggle between active and inactive on the top of your Omnichannel Widget configuration page to either display (active) or hide (inactive) the widget.`}</p>
    <Img src="/img/screenshots/quickstarts/ocw/install_step6.png" alt="Install Omnichannel Widget" width="650px" align="middle" mdxType="Img" />
    <p>{`And that’s it! 🎉 Now you know how to install the Omnichannel Widget on your website.`}</p>
    <Img src="/img/screenshots/quickstarts/ocw/install_step_final1.png" alt="Install Omnichannel Widget" width="650px" align="middle" mdxType="Img" />
    <Img src="/img/screenshots/quickstarts/ocw/install_step_final2.png" alt="Install Omnichannel Widget" width="350px" align="middle" mdxType="Img" />
    <h2>{`Advanced`}</h2>
    <p>{`The snippet is designed to be a backwards compatible `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTML/Element/script"
      }}>{`defer`}</a>{`.`}</p>
    <p>{`Its default behavior is to wait until your page has completely finished loading before downloading the widget and placing it on your page.`}</p>
    <p>{`If however, your site has a different performance profile or perhaps you would like to take control of when the widget is loaded you can use the snippet below.`}</p>
    <p>{`Please make sure to swap out the query param in the script url with the widget ID which can be found on settings page.`}</p>
    <CodeSnippet code={CODE.SNIPPET_SCRIPT_TAG} mdxType="CodeSnippet" />
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'Omnichannel Widget API Reference',
      to: '/api/omnichannel-widget'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      