import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/ApiRef.js";
import CodeSnippet from 'components/CodeSnippet';
import * as CODE from '../../constants/contacts';
import { APIWrapper, LeftColumn, RightColumn } from 'components/ApiRef';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Contacts API`}</h1>
    <p>{`MessageBird’s Contacts API helps you manage contacts that are identified by a unique random ID. Using this ID, you can create, view, update, or delete contacts. This API works as a collection of customer-specific contacts that allows you to group them and assign custom values that you can later use when sending SMS template messages.`}</p>
    <p>{`The Contacts API uses `}<inlineCode parentName="p">{`HTTP verbs`}</inlineCode>{` and a `}<inlineCode parentName="p">{`RESTful`}</inlineCode>{` endpoint structure with an access key that is used as the API Authorization. Request and response payloads are formatted as JSON using UTF-8 encoding and URL encoded values.`}</p>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`API Endpoint`}</h2>
        <p>{`All URLs referenced in MessageBird's Voice Calling API documentation have the base URL in the code snippet on the right side:`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>API Endpoint</h4>
  <CodeSnippet code={CODE.CONTACTS_URI} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Available HTTP methods`}</h2>
        <p>{`The MessageBird API uses HTTP verbs to understand if you want to read (`}<inlineCode parentName="p">{`GET`}</inlineCode>{`), delete (`}<inlineCode parentName="p">{`DELETE`}</inlineCode>{`) or create (`}<inlineCode parentName="p">{`POST`}</inlineCode>{`) an object. When your web application cannot do a `}<inlineCode parentName="p">{`POST`}</inlineCode>{` or `}<inlineCode parentName="p">{`DELETE`}</inlineCode>{`, we provide the ability to set the method through the query parameter `}<inlineCode parentName="p">{`_method`}</inlineCode>{`.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Available HTTP Methods</h4>
  <CodeSnippet code={CODE.CONTACTS_METHODS} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`The contact object`}</h2>
        <p>{`This object represents a contact at MessageBird.com`}</p>
        <h4>{`Attributes`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Attribute`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`id`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A unique random ID which is created on the MessageBird platform and is returned upon creation of the object.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`href`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The URL of the created object.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`msisdn`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`integer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The phone number of contact.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`firstName`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The first name of the contact.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`lastName`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The last name of the contact.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`customDetails.custom1`}</inlineCode>{`-`}<inlineCode parentName="td">{`customDetails.custom4`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Custom fields of the contact.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`groups`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`hash`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The hash with groups the contact belongs to. Further explanation in the table below.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`messages`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`hash`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The hash with messages sent to contact. Further explanation in the table below.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`createdDatetime`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`datetime`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The date and time of the creation of the contact in RFC3339 format (Y-m-d\\TH:i:sP).`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`updatedDatetime`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`datetime`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The date and time of the last update of the contact in RFC3339 format (Y-m-d\\TH:i:sP).`}</td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Object example</h4>
  <CodeSnippet code={CODE.CONTACTS_OBJECT} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <h4>{`The groups hash`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`totalCount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The total count of groups that contact belongs to.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`href`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`URL which can be used to retrieve list of groups contact belongs to.`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`The messages hash`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`totalCount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The total count of messages sent to contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`href`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`URL which can be used to retrieve list of messages sent to contact.`}</td>
        </tr>
      </tbody>
    </table>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Create a contact`}</h2>
        <p>{`Creates a new contact object. MessageBird returns the created contact object with each request.`}</p>
        <h4>{`Required parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Parameter`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`msisdn`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The phone number of the contact. `}<strong parentName="td">{`Required`}</strong></td>
            </tr>
          </tbody>
        </table>
        <h4>{`Optional parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Parameter`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`firstName`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The first name of the contact.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`lastName`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The last name of the contact.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`custom1`}</inlineCode>{`-`}<inlineCode parentName="td">{`custom4`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Custom fields of the contact.`}</td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.CREATECONTACTS_DEF} mdxType="CodeSnippet" />
        <h4>Example request</h4>
  <CodeSnippet code={CODE.CREATECONTACTS_REQUEST} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Returns`}</h4>
        <p>{`Returns a contact object if the request was successful. If the request failed, an `}<a parentName="p" {...{
            "href": "/api#api-errors"
          }}>{`error object`}</a>{` will be returned.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Example response</h4>
  <CodeSnippet code={CODE.CREATECONTACTS_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`View a contact`}</h2>
        <p>{`Retrieves the information of an existing contact. You only need to supply the unique contact ID that was returned upon creation or receiving.`}</p>
        <h4>{`Required parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Parameter`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`id`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A unique random ID which is created on the MessageBird platform and is returned upon creation of the object. `}<strong parentName="td">{`Required`}</strong></td>
            </tr>
          </tbody>
        </table>
        <h4>{`Optional parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Parameter`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}></th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`msisdn`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Alternatively you can provide an msisdn `}<em parentName="td">{`instead of`}</em>{` an id to view a contact. Does not work for other methods. `}<strong parentName="td">{`Not Required`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`name`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Another options is providing a name `}<em parentName="td">{`instead of`}</em>{` an id to view (a) contact(s). Does not work for other methods. `}<strong parentName="td">{`Not Required`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.VIEWCONTACTS_DEF} mdxType="CodeSnippet" />
  <h4>Example request</h4>
  <CodeSnippet code={CODE.VIEWCONTACTS_REQUEST} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Returns`}</h4>
        <p>{`Returns a contact object if the request was successful. If the request failed, an `}<a parentName="p" {...{
            "href": "/api#api-errors"
          }}>{`error object`}</a>{` will be returned.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Example response</h4>
  <CodeSnippet code={CODE.VIEWCONTACTS_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <hr></hr>
        <h2>{`Update a contact`}</h2>
        <p>{`Updates an existing contact. You only need to supply the unique id that was returned upon creation.`}</p>
        <h4>{`Required parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Parameter`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`id`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A unique random ID which is created on the MessageBird platform and is returned upon creation of the object. `}<strong parentName="td">{`Required`}</strong></td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.UPDATECONTACTS_DEF} mdxType="CodeSnippet" />
        <h4>Example request</h4>
  <CodeSnippet code={CODE.UPDATECONTACTS_REQUEST} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Returns`}</h4>
        <p>{`Returns an HTTP header of `}<inlineCode parentName="p">{`200 OK`}</inlineCode>{` and the contact object if the request was successful. If the request failed, an `}<a parentName="p" {...{
            "href": "/api#api-errors"
          }}>{`error object`}</a>{` will be returned.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Example response</h4>
  <CodeSnippet code={CODE.UPDATECONTACTS_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Delete a contact`}</h2>
        <p>{`Deletes an existing contact. You only need to supply the unique id that was returned upon creation.`}</p>
        <h4>{`Required parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Parameter`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`id`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A unique random ID which is created on the MessageBird platform and is returned upon creation of the object. `}<strong parentName="td">{`Required`}</strong></td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.DELETECONTACTS_DEF} mdxType="CodeSnippet" />
        <h4>Example request</h4>
  <CodeSnippet code={CODE.DELETECONTACTS_REQUEST} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Returns`}</h4>
        <p>{`Returns an HTTP header of `}<inlineCode parentName="p">{`204 No Content`}</inlineCode>{` and an empty response if the request was successful. If the request failed, an `}<a parentName="p" {...{
            "href": "/api#api-errors"
          }}>{`error object`}</a>{` will be returned.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Example response</h4>
  <CodeSnippet code={CODE.DELETECONTACTS_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      