import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/ApiRef.js";
import CodeSnippet from 'components/CodeSnippet';
import * as CODE from '../../constants/groups';
import { APIWrapper, LeftColumn, RightColumn } from 'components/ApiRef';
import Notification from 'components/Notification';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Groups API`}</h1>
    <p>{`Messagebird’s Groups API allows you to manage contact groups that are identified by a unique random ID. Use this ID to create, view, update or delete groups. `}</p>
    <p>{`The Groups API uses `}<inlineCode parentName="p">{`HTTP verbs`}</inlineCode>{` and a `}<inlineCode parentName="p">{`RESTful`}</inlineCode>{` endpoint structure with an access key that is used as the API Authorization framework. Request and response payloads are formatted as JSON—although we provide a GET alternative for requests—using UTF-8 encoding and URL encoded values.`}</p>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`API Endpoint`}</h2>
        <p>{`All URLs referenced in MessageBird's Groups API documentation have the base URL in the code snippet on the right side:`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>API Endpoint</h4>
  <CodeSnippet code={CODE.GROUPS_URI} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Available HTTP methods`}</h2>
        <p>{`The MessageBird API uses HTTP verbs to understand if you want to read (`}<inlineCode parentName="p">{`GET`}</inlineCode>{`), delete (`}<inlineCode parentName="p">{`DELETE`}</inlineCode>{`) or create (`}<inlineCode parentName="p">{`POST`}</inlineCode>{`) an object. When your web application cannot do a `}<inlineCode parentName="p">{`POST`}</inlineCode>{` or `}<inlineCode parentName="p">{`DELETE`}</inlineCode>{`, we provide the ability to set the method through the query parameter `}<inlineCode parentName="p">{`_method`}</inlineCode>{`.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Available HTTP Methods</h4>
  <CodeSnippet code={CODE.GROUPS_METHODS} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`The Group object`}</h2>
        <p>{`This object represents a contact group at MessageBird.com`}</p>
        <h4>{`Attributes`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Attribute`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`id`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A unique random ID which is created on the MessageBird platform and is returned upon creation of the object.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`href`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The URL of the created object.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`name`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The name of the group.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`contacts`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`hash`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The hash with contacts in group. Further explanation in the table below.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`createdDatetime`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`datetime`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The date and time of the creation of the group in RFC3339 format (Y-m-d\\TH:i:sP).`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`updatedDatetime`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`datetime`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The date and time of the last update of the group in RFC3339 format (Y-m-d\\TH:i:sP).`}</td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Object example</h4>
  <CodeSnippet code={CODE.GROUPS_OBJECT} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <h4>{`The contacts hash`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`totalCount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The total count of contacts in group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`href`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`URL which can be used to retrieve list of contacts in group.`}</td>
        </tr>
      </tbody>
    </table>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Create a group`}</h2>
        <p>{`Creates a new group object. MessageBird returns the created group object with each request.`}</p>
        <h4>{`Required parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Parameter`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`name`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The name of the group. `}<strong parentName="td">{`Required`}</strong></td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.CREATEGROUPS_DEF} mdxType="CodeSnippet" />
  <h4>Example request</h4>
  <CodeSnippet code={CODE.CREATEGROUPS_REQUEST} mdxType="CodeSnippet" />
  <Notification type="WARNING" mdxType="Notification">In this example, values are not automatically URL encoded.
Use --data-urlencode if you want to URL encode the values.</Notification>
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Returns`}</h4>
        <p>{`Returns a group object if the request was successful. If the request failed, an `}<a parentName="p" {...{
            "href": "/api#api-errors"
          }}>{`error object`}</a>{` will be returned.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Example response</h4>
  <CodeSnippet code={CODE.CREATEGROUPS_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`View a group`}</h2>
        <p>{`Retrieves the information of an existing group. You only need to supply the unique group ID that was returned upon creation or receiving.`}</p>
        <h4>{`Required parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Parameter`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`id`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A unique random ID which is created on the MessageBird platform and is returned upon creation of the object. `}<strong parentName="td">{`Required`}</strong></td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.VIEWGROUPS_DEF} mdxType="CodeSnippet" />
  <h4>Example request</h4>
  <CodeSnippet code={CODE.VIEWGROUPS_REQUEST} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Returns`}</h4>
        <p>{`Returns a group object if the request was successful. If the request failed, an `}<a parentName="p" {...{
            "href": "/api#api-errors"
          }}>{`error object`}</a>{` will be returned.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Example response</h4>
  <CodeSnippet code={CODE.VIEWGROUPS_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Update a group`}</h2>
        <p>{`Updates an existing group. You only need to supply the unique ID that was returned upon creation.`}</p>
        <h4>{`Required parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Parameter`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`id`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A unique random ID which is created on the MessageBird platform and is returned upon creation of the object. `}<strong parentName="td">{`Required`}</strong></td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.UPDATEGROUPS_DEF} mdxType="CodeSnippet" />
  <h4>Example request</h4>
  <CodeSnippet code={CODE.UPDATEGROUPS_REQUEST} mdxType="CodeSnippet" />
  <Notification type="WARNING" mdxType="Notification">In this example, values are not automatically URL encoded.
Use --data-urlencode if you want to URL encode the values.</Notification>
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Returns`}</h4>
        <p>{`Returns an HTTP header of `}<inlineCode parentName="p">{`200 OK`}</inlineCode>{` and the group object if the request was successful. If the request failed, an `}<a parentName="p" {...{
            "href": "/api#api-errors"
          }}>{`error object`}</a>{` will be returned.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Example response</h4>
  <CodeSnippet code={CODE.UPDATEGROUPS_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <hr></hr>
        <h2>{`Add contact to group`}</h2>
        <p>{`Adds contact to group. You need to supply the IDs of the group and contact.`}</p>
        <h4>{`Required parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Parameter`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`groupId`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The ID of the group. `}<strong parentName="td">{`Required`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`ids`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`array`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The ID of the contacts to add. Max. 50. `}<strong parentName="td">{`Required`}</strong></td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.ADDTOGROUPS_DEF} mdxType="CodeSnippet" />
  <h4>Example request</h4>
  <CodeSnippet code={CODE.ADDTOGROUPS_REQUEST} mdxType="CodeSnippet" />
  <Notification type="WARNING" mdxType="Notification">In this example, values are not automatically URL encoded.
Use --data-urlencode if you want to URL encode the values.</Notification>
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Returns`}</h4>
        <p>{`Returns an HTTP header of `}<inlineCode parentName="p">{`204 accepted`}</inlineCode>{` if the request was successful. If the request failed, an `}<a parentName="p" {...{
            "href": "/api#api-errors"
          }}>{`error object`}</a>{` will be returned.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Example response</h4>
  <CodeSnippet code={CODE.ADDTOGROUPS_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Remove contact from group`}</h2>
        <p>{`Removes a contact from group. You need to supply the IDs of the group and contact. Does not delete the contact.`}</p>
        <h4>{`Required parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Parameter`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`groupId`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The ID of the group. `}<strong parentName="td">{`Required`}</strong></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`contactId`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`array`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The ID of the contact to remove. `}<strong parentName="td">{`Required`}</strong></td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.REMOVEFROMGROUPS_DEF} mdxType="CodeSnippet" />
  <h4>Example request</h4>
  <CodeSnippet code={CODE.REMOVEFROMGROUPS_REQUEST} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Returns`}</h4>
        <p>{`Returns an HTTP header of `}<inlineCode parentName="p">{`204 accepted`}</inlineCode>{` if the request was successful. If the request failed, an `}<a parentName="p" {...{
            "href": "/api#api-errors"
          }}>{`error object`}</a>{` will be returned.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Example response</h4>
  <CodeSnippet code={CODE.REMOVEFROMGROUPS_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Delete a group`}</h2>
        <p>{`Deletes an existing group. You only need to supply the unique id that was returned upon creation.`}</p>
        <h4>{`Required parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Parameter`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`id`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A unique random ID which is created on the MessageBird platform and is returned upon creation of the object. `}<strong parentName="td">{`Required`}</strong></td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.DELETEGROUPS_DEF} mdxType="CodeSnippet" />
  <h4>Example request</h4>
  <CodeSnippet code={CODE.DELETEGROUPS_REQUEST} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h4>{`Returns`}</h4>
        <p>{`Returns an HTTP header of `}<inlineCode parentName="p">{`204 No Content`}</inlineCode>{` and an empty response if the request was successful. If the request failed, an `}<a parentName="p" {...{
            "href": "/api#api-errors"
          }}>{`error object`}</a>{` will be returned.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Example response</h4>
  <CodeSnippet code={CODE.DELETEGROUPS_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`List groups`}</h2>
        <p>{`To list all groups, you can do a GET request on the `}<inlineCode parentName="p">{`/groups`}</inlineCode>{` endpoint.`}</p>
        <h3>{`Query parameters`}</h3>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Param`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`limit`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`int`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Limit the amount of groups listed. Max: 200.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`offset`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`int`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Skip first `}<inlineCode parentName="td">{`n`}</inlineCode>{` results.`}</td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.LISTGROUPS_DEF} mdxType="CodeSnippet" />
  <h4>Example request</h4>
  <CodeSnippet code={CODE.LISTGROUPS_REQUEST} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      