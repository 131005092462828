module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"defaultLayouts":{"api":"/var/www/html/src/components/Layout/ApiRef.js","tutorials":"/var/www/html/src/components/Layout/Tutorials.tsx","docs":"/var/www/html/src/components/Layout/Docs.tsx","quickstarts":"/var/www/html/src/components/Layout/QuickStarts.tsx","default":"/var/www/html/src/components/Layout/HomePageLayout.js"},"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/var/www/html"},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"I2Py1njgrCIuI8Q7kTZba08d8knyzK1k","devKey":"OScMRfT0v9M5Inv1ji7mySnuGm2ZAdEJ","trackPage":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
