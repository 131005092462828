import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ContentLinks from '../ContentLinks';
import WithAnchor from '../WithAnchor';

const PageWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const PageTitle = WithAnchor(styled.h1`
  margin: 0;
  font-size: 34px;
  font-weight: 600;
  line-height: 32px;
`);

const PageDescription = styled.p`
  margin: ${({ withLinks }) => (withLinks ? '24px 0' : '24px 0 80px 0')};
  color: #687992;
  font-size: 16px;
  letter-spacing: 0.1px;
  line-height: 30px;
`;

const ProductContentPage = ({
  pageTitle,
  pageDescription,
  productLinks,
  children,
}) => {
  return (
    <PageWrap>
      <PageTitle>{pageTitle}</PageTitle>
      <PageDescription withLinks={productLinks}>
        {pageDescription}
      </PageDescription>
      {productLinks && <ContentLinks products={productLinks} />}
      {children}
    </PageWrap>
  );
};

ProductContentPage.propTypes = {
  pageTitle: PropTypes.string,
  pageDescription: PropTypes.string,
};

ProductContentPage.defaultProps = {
  pageTitle: 'Page Title',
  pageDescription:
    'In this SMS API Quickstart, you’ll learn how to use MessageBird’s Programmable SMS API to receive inbound SMS. ',
};

export default ProductContentPage;
