import { Img } from 'components/shared';
import Questions from 'components/Footer/Questions';
import Notification from 'components/Notification';
import Link from 'components/Link';
import * as React from 'react';
export default {
  Img,
  Questions,
  Notification,
  Link,
  React
};