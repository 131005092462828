import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Setting masked phone numbers with MessageBird`}</h1>
    <h3>{`⏱ 30 min build time || `}<a parentName="h3" {...{
        "href": "https://github.com/messagebirdguides/masked-numbers-guide"
      }}>{`Download the Code`}</a></h3>
    <h2>{`Why build a number masking application?`}</h2>
    <p>{`In this MessageBird Developer Tutorial, you’ll learn how to anonymize and protect your users’ personal information by building a basic masked numbers application powered by the MessageBird API.`}</p>
    <p>{`Online service platforms—such as ride-sharing, online food delivery, and logistics—facilitate the experience between customers and providers by matching both sides of the transaction to ensure everything runs smoothly and the transaction is completed.`}</p>
    <p>{`Sometimes it’s necessary for customers and providers to talk or message each other directly; a problem arises since, for many reasons, both parties may not feel comfortable sharing their personal phone number. A great solution to this issue is using anonymous proxy phone numbers that mask a user's personal phone number while also protect the platform's personal contact details. The result: a customer doesn't see their provider's phone number but a number that belongs to the platform and forwards their call to the provider, and vice versa for providers.`}</p>
    <p>{`Along this tutorial, we'll show you how to build a proxy system to mask phone numbers in Node.js for our fictitious ride-sharing platform, `}<em parentName="p">{`BirdCar`}</em>{`. The sample application includes a data model for customers, drivers, rides, and proxy phone numbers and allows setting up new rides from an admin interface for demonstration purposes.`}</p>
    <h2>{`Using a number pool`}</h2>
    <p>{`Before we dive into building the sample application, let's take a moment to understand the concept of a number pool. The idea is to set up a list of numbers by purchasing one or more `}<a parentName="p" {...{
        "href": "https://www.messagebird.com/numbers/"
      }}>{`virtual mobile numbers`}</a>{` from MessageBird and adding them to a database. Whenever a ride is created, the `}<em parentName="p">{`BirdCar`}</em>{` application will automatically search the pool for a driver that is available and then assign the ride.`}</p>
    <p>{`For simplicity and to allow testing with a single number, BirdCar assigns only one number to each ride, not one for each party. If the customer calls or texts this number, is connected to the driver; if the driver rings, the call or text is forwarded to the customer. The incoming caller or message sender identification sent from the network is used to determine which party calls and consequently find the other party's number.`}</p>
    <p>{`Relying on the caller identification has the additional advantage that you don’t have to purchase a new phone number for each transaction; instead, it is possible to assign the same one to multiple transactions as long as different people are involved. The ride can be looked up based on who is calling. It is also possible to recycle numbers even for the same customer or driver, that is, returning them to the pool, although we haven’t implemented this behavior in the sample code. In any case, the number should remain active for some time after a transaction has ended, just in case the driver and customer need to communicate afterwards; for example, if the customer has forgotten an item in the driver’s car. 😱`}</p>
    <h2>{`Getting started`}</h2>
    <p>{`First things first, Our sample application is built in Node.js using the `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/express"
      }}>{`Express`}</a>{` framework, so you’ll need Node and npm, you can easily `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/get-npm"
      }}>{`install them for free`}</a>{`.`}</p>
    <p>{`Our application also uses a relational database to store the data model. We bundled `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/sqlite3"
      }}>{`SQLite`}</a>{` with the application so that you don’t have to set up an RDBMS like MySQL, but if you extend the code for production use, you can still reuse the SQL queries with other databases.`}</p>
    <p>{`The source code is available in the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/masked-numbers-guide"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`, which you can either clone with git or from where you can download a ZIP file with the source code to your computer.`}</p>
    <p>{`Let's now open the directory where you've stored the sample code and run the following command to install the `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/messagebird"
      }}>{`MessageBird SDK`}</a>{` and other dependencies:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install
`}</code></pre>
    <h2>{`Prerequisites for receiving messages and calls`}</h2>
    <h3>{`Overview`}</h3>
    <p>{`The BirdCar system receives incoming messages and calls and forwards them. From a high-level viewpoint, receiving is relatively simple: an application defines a `}<em parentName="p">{`webhook URL`}</em>{`, which you assign to a number purchased in the MessageBird Dashboard using `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/flow-builder"
      }}>{`Flow Builder`}</a>{`. A webhook is a URL on your site that doesn't render a page to users but is like an API endpoint that can be triggered by other servers. Every time someone sends a message or calls that number, MessageBird collects it and forwards it to the webhook URL where you can process it.`}</p>
    <h3>{`Exposing your development server with localtunnel`}</h3>
    <p>{`When working with webhooks, an external service like MessageBird needs to access your application, so the webhook URL must be public; however, during development you're typically working in a local development environment that is not publicly available. Thankfully this is not a big deal since various tools and services allow you to quickly expose your development environment to the Internet by providing a tunnel from a public URL to your local machine. One of these tools is `}<a parentName="p" {...{
        "href": "https://localtunnel.me/"
      }}>{`localtunnel.me`}</a>{`, which is uniquely suited to Node.js developers since you can easily install it using npm:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install -g localtunnel
`}</code></pre>
    <p>{`You can start a tunnel by providing a local port number on which your application runs. Our application is configured to run on port 8080, so you can launch localtunnel with the following command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`lt --port 8080
`}</code></pre>
    <p>{`After you've started the tunnel, localtunnel displays your temporary public URL. We'll need that in a minute.`}</p>
    <p>{`Another common tool for tunneling your local machine is `}<a parentName="p" {...{
        "href": "https://ngrok.com/"
      }}>{`ngrok`}</a>{`, which works virtually in the same way; you can have a look at it if you're facing problems with localtunnel.`}</p>
    <h3>{`Getting an inbound number`}</h3>
    <p>{`A requirement for receiving messages is a dedicated inbound number. Virtual mobile numbers (VNM) look and work similar to regular mobile numbers; however, instead of being attached to a mobile device via a SIM card, they live in the cloud and can process inbound SMS and voice calls. MessageBird offers numbers from different countries for a low monthly fee; `}<a parentName="p" {...{
        "href": "https://www.messagebird.com/en/numbers"
      }}>{`feel free to explore our low-cost programmable and configurable numbers`}</a>{`.`}</p>
    <p>{`Purchasing a number is quite easy:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Go to the ‘`}<a parentName="p" {...{
            "href": "https://dashboard.messagebird.com/en/numbers"
          }}>{`Numbers`}</a>{`’ section in the left-hand side of your Dashboard and click the blue button ‘`}<a parentName="p" {...{
            "href": "https://dashboard.messagebird.com/en/vmn/buy-number"
          }}>{`Buy a number`}</a>{`’ in the top-right side of your screen.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Pick the country in which you and your customers are located, and make sure both the SMS and Voice capabilities are selected.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Choose one number from the selection and the duration for which you want to pay now.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Confirm by clicking ‘Buy Number’ in the bottom-right of your screen.`}</p>
      </li>
    </ol>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/maskednumbers-node/image1.png",
        "alt": "Buy a VMN"
      }}></img></p>
    <p>{`Awesome, you’ve set up your first virtual mobile number! 🎉
One is enough for testing, but for real usage of the masked number system, you'll need a larger pool of numbers; simply follow the same steps listed to purchase more.`}</p>
    <p><strong parentName="p">{`Pro-tip:`}</strong>{` Check out our Help Center for more information about `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/sections/201958489-Virtual-Numbers"
      }}>{`virtual mobile numbers`}</a>{` and `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/sections/360000108538-Country-info-Restrictions"
      }}>{`country restrictions`}</a>{`.`}</p>
    <h2>{`Connecting the number to a webhook for SMS`}</h2>
    <p>{`So you have a number now, but MessageBird has no idea what to do with it. That's why now you need to define a `}<em parentName="p">{`Flow`}</em>{` that links your number to your webhook. We’ll start with the flow for inbound SMS messages:`}</p>
    <h4>{`STEP ONE`}</h4>
    <p>{`Go to `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/flow-builder"
      }}>{`Flow Builder`}</a>{`, choose the template ‘Call HTTP endpoint with SMS’, and click ‘Try this flow’.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/maskednumbers-node/image2.png",
        "alt": "Buy a VMN"
      }}></img></p>
    <h4>{`STEP TWO`}</h4>
    <p>{`This template has two steps. Click on the first step ‘SMS’ and select the number or numbers you’d like to attach the flow to. Now, click on the second step ‘Forward to URL’ and choose POST as the method; copy the output from the ngrok command in the URL and add `}<inlineCode parentName="p">{`/webhook`}</inlineCode>{` at the end—this is the name of the route we use to handle incoming messages in our sample application. Click on ‘Save’ when ready.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/maskednumbers-node/image3.png",
        "alt": "Buy a VMN"
      }}></img></p>
    <h4>{`STEP THREE`}</h4>
    <p><strong parentName="p">{`Ready!`}</strong>{` Hit ‘Publish’ on the right top of the screen to activate your flow. Well done, another step closer to testing incoming messages! Your flow should look something like this:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/maskednumbers-node/image4.png",
        "alt": "Buy a VMN"
      }}></img></p>
    <p><strong parentName="p">{`Pro-tip:`}</strong>{` It might be useful to rename it this flow, because `}<em parentName="p">{`Untitled flow`}</em>{` won't be helpful in the long run. You can do this by clicking on the icon next to button ‘Back to Overview’ and pressing ‘Rename flow’.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/maskednumbers-node/image5.png",
        "alt": "Buy a VMN"
      }}></img></p>
    <p>{`Awesome! 🎉`}</p>
    <h3>{`Connecting the number to a webhook for voice`}</h3>
    <p>{`Let’s set up a second flow for incoming voice calls:`}</p>
    <h4>{`STEP ONE`}</h4>
    <p>{`Go back to `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/flow-builder"
      }}>{`Flow Builder`}</a>{` and hit the button ‘Create new flow’ and then ‘Create Custom Flow’.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/maskednumbers-node/image6.png",
        "alt": "Buy a VMN"
      }}></img></p>
    <h4>{`STEP TWO`}</h4>
    <p>{`Give your flow a name, choose ‘Phone Call’ as the trigger and hit ‘Next’.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/maskednumbers-node/image7.png",
        "alt": "Buy a VMN"
      }}></img></p>
    <h4>{`STEP THREE`}</h4>
    <p>{`Click on the first step ‘Phone Call’ and select the number or numbers you’d like to attach the flow to.`}</p>
    <h4>{`STEP FOUR`}</h4>
    <p>{`Add a new step by pressing the small ‘+’, choose ‘Fetch call flow from URL’ and paste the same localtunnel base URL into the form, but this time append `}<inlineCode parentName="p">{`/webhook-voice`}</inlineCode>{` to it—this is the name of the route we use to handle incoming calls in our sample application. Click on ‘Save’ when ready.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/maskednumbers-node/image8.png",
        "alt": "Buy a VMN"
      }}></img></p>
    <h4>{`STEP FIVE`}</h4>
    <p><strong parentName="p">{`Ready!`}</strong>{` Hit ‘Publish’ on the right top of the screen to activate your flow. Your flow should look something like this:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/maskednumbers-node/image9.png",
        "alt": "Buy a VMN"
      }}></img></p>
    <p>{`You're done setting up flows for your application! Now, we can begin writing
routes in your application for the `}<inlineCode parentName="p">{`/webhook`}</inlineCode>{` and `}<inlineCode parentName="p">{`/webhook-voice`}</inlineCode>{` URL paths
that these flows are using.`}</p>
    <h2>{`Configuring the MessageBird SDK`}</h2>
    <p>{`The MessageBird SDK and an API key are not required to receive messages; however, since we want to send and forward messages, we need to add and configure it. The SDK is defined in `}<inlineCode parentName="p">{`package.json`}</inlineCode>{` and loaded with a statement in `}<inlineCode parentName="p">{`HomePageFooter.js`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// Load and initialize MessageBird SDK
var messagebird = require('messagebird')(process.env.MESSAGEBIRD_API_KEY);
`}</code></pre>
    <p>{`You need to provide a MessageBird API key via an environment variable loaded with `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/dotenv"
      }}>{`dotenv`}</a>{`. We've prepared an `}<inlineCode parentName="p">{`env.example`}</inlineCode>{` file in the repository, which you should rename to `}<inlineCode parentName="p">{`.env`}</inlineCode>{` and add the required information. Here's an example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-env"
      }}>{`MESSAGEBIRD_API_KEY=YOUR-API-KEY
`}</code></pre>
    <p>{`First, go to the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/user/index"
      }}>{`MessageBird Dashboard`}</a>{`; if you have already created an API key it will be shown right there. If you don’t see any key on the Dashboard or if you're unsure whether this key is in `}<em parentName="p">{`live`}</em>{` mode, go to the `}<em parentName="p">{`Developers`}</em>{` section in the MessageBird Dashboard and open the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`API access (REST) tab`}</a>{`. There you can create new API keys and manage your existing ones.`}</p>
    <p>{`If you are having any issues creating your API key, please reach out to
`}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we’ll make sure to help you out.`}</p>
    <h2>{`Creating our data model and sample data`}</h2>
    <p>{`Our BirdCar application uses a relational model; we have the following four entities:`}</p>
    <ul>
      <li parentName="ul"><em parentName="li">{`Customers`}</em>{`, who have a name and a phone number.`}</li>
      <li parentName="ul"><em parentName="li">{`Drivers`}</em>{`, who also have a name and a phone number.`}</li>
      <li parentName="ul"><em parentName="li">{`Proxy Numbers`}</em>{`, which are the phone numbers in our pool.`}</li>
      <li parentName="ul"><em parentName="li">{`Rides`}</em>{`, which have a start, destination, and date and time. Every ride references precisely one `}<em parentName="li">{`Customer`}</em>{`, `}<em parentName="li">{`Driver`}</em>{`, and `}<em parentName="li">{`Proxy Number`}</em>{` through the use of foreign keys; every entity has a database table with an auto-incremented numeric ID as its primary key.`}</li>
    </ul>
    <p>{`Open the file `}<inlineCode parentName="p">{`createdb.js`}</inlineCode>{` in the repository. It contains four CREATE TABLE queries to set up the data model. Below that, you'll find some INSERT INTO queries to add sample customers, drivers, and proxy numbers. Update those queries like this:`}</p>
    <ul>
      <li parentName="ul">{`Enter your name and mobile phone number as a customer.`}</li>
      <li parentName="ul">{`Enter another working phone number, such as a secondary phone or a friend's number, as a driver.`}</li>
      <li parentName="ul">{`Enter the virtual mobile number you purchased in the MessageBird Dashboard. If you have more than one, copy the query code for each.`}</li>
    </ul>
    <p>{`After updating the file, save it and run the following command (if you already have localtunnel running open a second command prompt for it):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`node createdb.js
`}</code></pre>
    <p>{`Keep in mind that this command only works once so if you make changes and want to recreate the database, you must delete the file `}<inlineCode parentName="p">{`ridesharing.db`}</inlineCode>{` that the script creates before re-running it:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`rm ridesharing.db
node createdb.js
`}</code></pre>
    <h2>{`The admin interface`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`app.get('/')`}</inlineCode>{` route in `}<inlineCode parentName="p">{`HomePageFooter.js`}</inlineCode>{` and the associated HTML page in `}<inlineCode parentName="p">{`views/admin.handlebars`}</inlineCode>{` implement a simple homepage that lists the content from the database and provides a form to add a new ride. For creating a ride, an admin can select a customer and driver from a drop-down, and enter start, destination, date and time; the form submits this information to `}<inlineCode parentName="p">{`/createride`}</inlineCode>{`.`}</p>
    <h2>{`Creating a ride`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`app.post('/createride')`}</inlineCode>{` route defined in `}<inlineCode parentName="p">{`HomePageFooter.js`}</inlineCode>{` handles the following steps when creating a new ride:`}</p>
    <h3>{`Getting customer and driver information`}</h3>
    <p>{`The form fields contain only IDs for customer and driver, so we’ll make a query for each to find all the information that we need in subsequent steps:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javacript"
      }}>{`// Create a new ride
app.post('/createride', function(req, res) {
   // Find customer details
   db.get("SELECT * FROM customers WHERE id = $id", { $id : req.body.customer }, function(err, row) {
       var customer = row;

       // Find driver details
       db.get("SELECT * FROM drivers WHERE id = $id", { $id : req.body.driver }, function(err, row) {
           var driver = row;
`}</code></pre>
    <h2>{`Finding a number`}</h2>
    <p>{`We need to get a number from the pool that was never been assigned to a ride for the customer or the driver. To check this, let’s write a SQL query with two subqueries:`}</p>
    <ul>
      <li parentName="ul">{`Find all numbers for rides from the selected customer (subquery 1)`}</li>
      <li parentName="ul">{`Find all numbers for rides from the selected driver (subquery 2)`}</li>
      <li parentName="ul">{`Find all numbers that are in neither of those lists and return one of them (main query)`}</li>
    </ul>
    <p>{`In Javascript and SQL, this check looks like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// Find a number that has not been used by the driver or the customer
db.get("SELECT * FROM proxy_numbers "
   + "WHERE id NOT IN (SELECT number_id FROM rides WHERE customer_id = $customer) "
   + "AND id NOT IN (SELECT number_id FROM rides WHERE driver_id = $driver)", {
       $customer : customer.id,
       $driver : driver.id,
}, function(err, row) {
`}</code></pre>
    <p>{`It's possible that no row was found; in that case, we alert the admin that the number pool is depleted and they should buy more numbers:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`if (row == null) {
   // No number found!
   res.send("No number available! Please extend your pool.");
`}</code></pre>
    <h3>{`Storing the ride`}</h3>
    <p>{`Once a number was found, that is, our query returned a row, it’s time to insert a new ride into the database using the information from the form:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`} else {
  var proxyNumber = row;

  // Store ride in database
  db.run("INSERT INTO rides (start, destination, datetime, customer_id, driver_id, number_id) VALUES ($start, $destination, $datetime, $customer, $driver,$number)", {
      $start : req.body.start,
      $destination : req.body.destination,
      $datetime : req.body.datetime,
      $customer : customer.id,
      $driver : driver.id,
      $number : proxyNumber.id
  });
`}</code></pre>
    <h3>{`Notifying customer and driver`}</h3>
    <p>{`We should now send a message to both the customer and the driver to confirm the ride. This message should originate from the proxy number, so they can quickly reply to this message to reach the other party. For sending messages, the MessageBird SDK provides the `}<inlineCode parentName="p">{`messagebird.messages.create()`}</inlineCode>{` function. We need to call the function twice because we're sending two different versions of the message:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// Notify the customer
messagebird.messages.create(
  {
    originator: proxyNumber.number,
    recipients: [customer.number],
    body: driver.name + ' will pick you up at ' + req.body.datetime + '. Reply to this message to contact the driver.',
  },
  function(err, response) {
    console.log(err, response);
  },
);

// Notify the driver
messagebird.messages.create(
  {
    originator: proxyNumber.number,
    recipients: [driver.number],
    body:
      customer.name + ' will wait for you at ' + req.body.datetime + '. Reply to this message to contact the customer.',
  },
  function(err, response) {
    console.log(err, response);
  },
);
`}</code></pre>
    <p>{`The response, or error, if any, is logged to the console, MessageBird doesn’t read or take any action based on them. In production applications you should definitely check if the messages were sent successfully and implement some more sophisticated error handling.`}</p>
    <h2>{`Receiving and forwarding messages`}</h2>
    <p>{`When a customer or driver replies to the message confirming their ride, the response should go to the other party. As we have instructed MessageBird to post to `}<inlineCode parentName="p">{`/webhook`}</inlineCode>{`, we need to implement the `}<inlineCode parentName="p">{`app.post('/webhook');`}</inlineCode>{` route.`}</p>
    <p>{`First, we read the input sent from MessageBird. We're interested in three fields: `}<em parentName="p">{`originator`}</em>{`, `}<em parentName="p">{`payload`}</em>{` (the message text), and `}<em parentName="p">{`recipient`}</em>{` (the virtual number to which the user sent their message), so that we can find the ride based on this information:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// Handle incoming messages
app.post('/webhook', function(req, res) {
   // Read input sent from MessageBird
   var number = req.body.originator;
   var text = req.body.payload;
   var proxy = req.body.recipient;
`}</code></pre>
    <h3>{`Looking up receiver`}</h3>
    <p>{`To find the ride we use an SQL query which joins all four tables. We're interested in all entries in which the proxy number matches the `}<inlineCode parentName="p">{`recipient`}</inlineCode>{` field from the webhook, and the `}<inlineCode parentName="p">{`originator`}</inlineCode>{` matches `}<em parentName="p">{`either`}</em>{` the driver's number or the customer's number:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`db.get("SELECT c.number AS customer_number, d.number AS driver_number, p.number AS proxy_number "
   + "FROM rides r JOIN customers c ON r.customer_id = c.id JOIN drivers d ON r.driver_id = d.id JOIN proxy_numbers p ON p.id = r.number_id "
   + "WHERE proxy_number = $proxy AND (driver_number = $number OR customer_number = $number)", {
       $number : number,
       $proxy : proxy
   }, function(err, row) {
`}</code></pre>
    <p>{`After we've found the ride based on an `}<em parentName="p">{`or-condition`}</em>{`, we need to check again which party was the actual sender and determine the recipient (the other party) from there:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`if (row) {
   // Got a match!
   // Need to find out whether customer or driver sent this and forward to the other side
   var recipient = "";
   if (number == row.customer_number)
       recipient = row.driver_number;
   else
   if (number == row.driver_number)
       recipient = row.customer_number;
`}</code></pre>
    <h3>{`Forwarding message`}</h3>
    <p>{`We use `}<inlineCode parentName="p">{`messagebird.messages.create()`}</inlineCode>{` to forward the message. The proxy number is used as the originator, and we send the original text to the recipient as determined above:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// Forward the message through the MessageBird API
messagebird.messages.create(
  {
    originator: proxy,
    recipients: [recipient],
    body: text,
  },
  function(err, response) {
    console.log(err, response);
  },
);
`}</code></pre>
    <p>{`If we don't find a ride, we log an error to the console:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`} else {
               // Cannot match numbers
               console.log("Could not find a ride for customer/driver " + number + " that uses proxy " + proxy + ".");
           }
`}</code></pre>
    <h2>{`Receiving and forwarding voice calls`}</h2>
    <p>{`When a customer or driver calls the proxy number from which they received the confirmation, the system should transfer the call to the other party. As we have instructed MessageBird to fetch instructions from `}<inlineCode parentName="p">{`/webhook-voice`}</inlineCode>{`, we need to implement the `}<inlineCode parentName="p">{`app.get('/webhook-voice');`}</inlineCode>{` route. Keep in mind that unlike the SMS webhook, where we have configured POST, custom call flows are always retrieved with GET.`}</p>
    <p>{`First, the input sent from MessageBird should be read because we're interested in the source and destination of the call so that we can find the ride based on this information:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// Handle incoming calls
app.get('/webhook-voice', function(req, res) {
   // Read input sent from MessageBird
   var number = req.query.source;
   var proxy = req.query.destination;
`}</code></pre>
    <p>{`As we’ll return a new call flow encoded in XML format, let’s set the response header accordingly:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// Answer will always be XML
res.set('Content-Type', 'application/xml');
`}</code></pre>
    <h3>{`Looking up receiver`}</h3>
    <p>{`This works exactly as described for the SMS webhooks; therefore, the SQL query and surrounding Javascript code are mostly a verbatim copy. If you’re extending the sample to build a production application, it could be a good idea to make a function as an abstraction around it to avoid duplicate code.`}</p>
    <h3>{`Transferring call`}</h3>
    <p>{`To transfer the call, we return a short snippet of XML to MessageBird and also log the action to the console:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// Create call flow to instruct transfer
console.log('Transferring call to ' + recipient);
res.send('<?xml version="1.0" encoding="UTF-8"?>' + '<Transfer destination="' + recipient + '" mask="true" />');
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`<Transfer />`}</inlineCode>{` element takes two attributes: `}<em parentName="p">{`destination`}</em>{` indicates the number to transfer the call to—which we've determined as described above—and `}<em parentName="p">{`mask`}</em>{` instructs MessageBird to use the proxy number instead of the original caller ID.`}</p>
    <p>{`If we don't find a ride, we return a different XML snippet with a `}<inlineCode parentName="p">{`<Say />`}</inlineCode>{` element, which is used to read some instructions to the caller:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`} else {
   // Cannot match numbers
   res.send('<?xml version="1.0" encoding="UTF-8"?>'
       + '<Say language="en-GB" voice="female">Sorry, we cannot identify your transaction. Make sure you call in from the number you registered.</Say>');
}
`}</code></pre>
    <p>{`This element takes two attributes, `}<em parentName="p">{`language`}</em>{` and `}<em parentName="p">{`voice`}</em>{`, that define the configuration for speech synthesis. The text itself goes between the opening and closing XML element.`}</p>
    <h2>{`Testing`}</h2>
    <p>{`Make sure you’ve set up at least one number correctly with two flows to forward both incoming messages and incoming phone calls to a localtunnel URL, and that the tunnel is still running. Keep in mind that whenever you start a fresh tunnel, you'll get a new URL, so you also have to update it in the flows accordingly. You can also configure a more permanent URL using the `}<inlineCode parentName="p">{`-s`}</inlineCode>{` attribute with the `}<inlineCode parentName="p">{`lt`}</inlineCode>{` command.`}</p>
    <p>{`To start the sample application you have to enter another command, but your existing console window is now busy running your tunnel, so you need to open another one. With Mac you can press `}<em parentName="p">{`Command + Tab`}</em>{` to open a second tab that's already pointed to the correct directory. With other operating systems you may have to open another console window manually. Either way, once you've got a command prompt, type the following to start the application:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`node HomePageFooter.js
`}</code></pre>
    <p>{`Open `}<a parentName="p" {...{
        "href": "http://localhost:8080/"
      }}>{`http://localhost:8080/`}</a>{` in your browser and create a ride between the customer and driver you configured in `}<inlineCode parentName="p">{`dbcreate.js`}</inlineCode>{`. If everything works out correctly, two phones should receive a message. Reply to the incoming message on one phone and you'll receive this reply on the other phone, but magically coming from the proxy number. Lovely! You can also test voice call forwarding as well: simply call the proxy number from one phone and magically see the other phone ring.`}</p>
    <p>{`If you didn't get the messages or the forwarding doesn't work, check the console output from Node to see if there's any problem with the API—such as an incorrect API key or a typo in one of the numbers—and try again.`}</p>
    <p>{`Awesome! Use the flow, code snippets, and UI examples from this tutorial as an inspiration to build your own application. Don't forget to download the code from the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/masked-numbers-guide"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`.`}</p>
    <p><strong parentName="p">{`Nice work!`}</strong>{` 🎉`}</p>
    <p>{`You've just built your own number masking system with MessageBird with Node.js!`}</p>
    <h2>{`Start building!`}</h2>
    <p>{`Want to build something similar but not quite sure how to get started? Feel free to let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we'd love to help!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      