/* eslint-disable no-useless-escape */
export const HLR_URI = {
  bash: `https://rest.messagebird.com/hlr`,
};

export const HLR_METHODS = {
  bash: `POST /hlr
GET /hlr
GET /hlr/{hlrId}
DELETE /hlr/{hlrId}`,
};

export const HLR_OBJECT = {
  json: `{
  "id":"0da180b035398662ceb3c42h04904985",
  "href":"https:\/\/rest.messagebird.com\/hlr\/0da180b035398662ceb3c42h04904985",
  "msisdn":31612345678,
  "network":20406,
  "reference":"YourReference",
  "details": {
    "status_desc": null,
    "imsi": "204080010948431",
    "country_iso": "NLD",
    "country_name": "Netherlands",
    "location_msc": "316530",
    "location_iso": 'nl',
    "ported": 0,
    "roaming": 0
  },
  "status":"active",
  "createdDatetime":"2016-05-04T07:32:46+00:00",
  "statusDatetime":"2016-05-04T07:32:46+00:00"
}`,
};

export const REQUESTHLR_DEF = {
  bash: `POST https://rest.messagebird.com/hlr`,
};

export const REQUESTHLR_REQUEST = {
  curl: `curl -X POST https://rest.messagebird.com/hlr \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -d "msisdn=31612345678" \\
  -d "reference=YourReference"`,
  php: `$MessageBird = new \\MessageBird\\Client('ACCESS_KEY');

$Hlr = new \\MessageBird\\Objects\\Hlr();
$Hlr->msisdn = 31612345678;
$Hlr->reference = "Reference";

$MessageBird->hlr->create($Hlr);`,
  ruby: `client = MessageBird::Client.new(YOUR_ACCESS_KEY)
client.hlr_create('0612345678', 'MyReference')`,
  csharp: `const string YourAccessKey = "YOUR_ACCESS_KEY";
const long Msisdn = 31612345678;

Client client = Client.CreateDefault(YourAccessKey);
Hlr hlr = client.RequestHlr(Msisdn, "Custom reference");`,
  go: `hlr, err := hlr.Create(client, "31612345678", "MyReference")`,
  python: `client = messagebird.Client(ACCESS_KEY)
hlr = client.hlr_create(
  '31612345678',
  'MyReference'
)`,
  java: `import com.messagebird.MessageBirdClient;
import com.messagebird.MessageBirdService;
import com.messagebird.MessageBirdServiceImpl;
import com.messagebird.exceptions.NotFoundException;
import com.messagebird.objects.Hlr;
import com.messagebird.exceptions.GeneralException;
import com.messagebird.exceptions.UnauthorizedException;

import java.math.BigInteger;

public class ExampleReadHlr {

    public static void main(String[] args) {

        if (args.length < 2) {
            System.out.println("Please specify your access key and phone in that order example : java -jar <this jar file≶ test_accesskey 31612345678");
            return;
        }

        // First create your service object
        final MessageBirdService wsr = new MessageBirdServiceImpl(args[0]);

        // Add the service to the client
        final MessageBirdClient messageBirdClient = new MessageBirdClient(wsr);

        try {
            // Get Hlr using msgId and msisdn
            System.out.println("Retrieving HLR:");
            final Hlr hlr1 = messageBirdClient.getRequestHlr(new BigInteger(0612345678), "ExampleReadHlr reference");
            System.out.println(hlr1.toString());

            // Get Hlr using the id only
            System.out.println("Now using returned id to get Hlr:");
            final Hlr hlr2 = messageBirdClient.getViewHlr(hlr1.getId());
            System.out.println(hlr1.toString());

        } catch (UnauthorizedException unauthorized) {
            if (unauthorized.getErrors()!=null) {
                System.out.println(unauthorized.getErrors().toString());
            }
            unauthorized.printStackTrace();
        } catch (GeneralException generalException) {
            if (generalException.getErrors() !=null) {
                System.out.println(generalException.getErrors().toString());
            }
            generalException.printStackTrace();
        } catch (NotFoundException notFoundException) {
            if (notFoundException.getErrors() !=null) {
                System.out.println(notFoundException.getErrors().toString());
            }
            notFoundException.printStackTrace();
        }
    }
}`,
  node: `var messagebird = require('messagebird')('YOUR_ACCESS_KEY');

messagebird.hlr.create('31612345678', 'MessageBird', function (err, response) {
  if (err) {
    return console.log(err);
  }
  console.log(response);
});`,
};

export const REQUESTHLR_RESPONSE = {
  curl: `{
  "id":"0da180b035398662ceb3c42h04904985",
  "href":"https:\/\/rest.messagebird.com\/hlr\/0da180b035398662ceb3c42h04904985",
  "msisdn":31612345678,
  "network":null,
  "details":[],
  "reference":"YourReference",
  "status":"sent",
  "createdDatetime":"2016-05-04T07:32:46+00:00",
  "statusDatetime":"2016-05-04T07:32:46+00:00"
}`,
  php: `{
  'id' => '9772ef10153a19646413512h43680562',
  'href' => 'https://rest.messagebird.com/hlr/9772ef10153a19646413512h43680562',
  'msisdn' => 31612345678,
  'network' => NULL,
  'details' => array(),
  'reference' => 'Reference',
  'status' => 'sent',
  'createdDatetime' => '2016-05-04T07:32:46+00:00',
  'statusDatetime' => '2016-05-04T07:32:46+00:00',
}`,
  ruby: `#<MessageBird::HLR:0x007f8d5b8dafc8
@createdDatetime=2016-05-04 07:32:46 +0000,
@href="https://rest.messagebird.com/hlr/4933bed0453ba7455031712h16830892",
@id="4933bed0453ba7455031712h16830892",
@msisdn=31612345678,
@network=nil,
@reference="MyReference",
@status="sent",
@statusDatetime=2016-05-04 07:32:46 +0000>`,
  csharp: `{
  "id":"0da180b035398662ceb3c42h04904985",
  "href":"https:\/\/rest.messagebird.com\/hlr\/0da180b035398662ceb3c42h04904985",
  "msisdn":31612345678,
  "network":null,
  "details":[],
  "reference":"YourReference",
  "status":"sent",
  "createdDatetime":"2016-05-04T07:32:46+00:00",
  "statusDatetime":"2016-05-04T07:32:46+00:00"
}`,
  go: `&hlr.HLR{
  ID:"2688d6ec21a247d9b2000d1634373ec8",
  HRef:"https://rest.messagebird.com/hlr/2688d6ec21a247d9b2000d1634373ec8",
  MSISDN:31612345678,
  Reference:"MyReference",
  Status:"sent",
  CreatedDatetime:(*time.Time)(0xc420142200),
  StatusDatetime:(*time.Time)(0xc420142260),
}`,
  python: `{
  'id' : '9772ef10153a19646413512h43680562',
  'href' : 'https://rest.messagebird.com/hlr/9772ef10153a19646413512h43680562',
  'msisdn' : 31612345678,
  'network' : None,
  'reference' : 'MyReference',
  'status' : 'sent',
  'createdDatetime' : '2016-05-04 07:32:46',
  'statusDatetime' : '2016-05-04 07:32:46',
}`,
  java: `{
  id='ce654390654e1ce5530e9a8h56512055',
  href='https://rest.messagebird.com/hlr/ce654390654e1ce5530e9a8h56512055',
  msisdn=31612345678, network='null',
  reference='ExampleReadHlr reference',
  status='sent',
  createdDatetime=2016-05-04T07:32:46+00:00,
  statusDatetime=2016-05-04T07:32:46+00:00
}`,
  node: `var messagebird = require('messagebird')('YOUR_ACCESS_KEY');

messagebird.hlr.read('9772ef10153a19646413512h43680562', function (err, response) {
  if (err) {
    return console.log(err);
  }
  console.log(response);
});`,
};

export const VIEWHLR_DEF = {
  bash: `GET https://rest.messagebird.com/hlr/{hlrId}`,
};

export const VIEWHLR_REQUEST = {
  curl: `curl -X GET https://rest.messagebird.com/hlr/0da180b035398662ceb3c42h04904985 \\ 
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM'`,
  php: `$MessageBird = new \\MessageBird\\Client('ACCESS_KEY');
$MessageBird->hlr->read('9772ef10153a19646413512h43680562');`,
  ruby: `client = MessageBird::Client.new(YOUR_ACCESS_KEY)
client.hlr('4933bed0453ba7455031712h16830892')`,
  csharp: `const string YourAccessKey = "YOUR_ACCESS_KEY";
const string HlrId = "4933bed0453ba7455031712h16830892";

Client client = Client.CreateDefault(YourAccessKey);
Hlr hlr = client.ViewHlr(HlrId);`,
  go: `hlr, err := hlr.Read(client, "2688d6ec21a247d9b2000d1634373ec8")`,
  python: `client = messagebird.Client(ACCESS_KEY)
hlr = client.hlr('9772ef10153a19646413512h43680562')`,
  java: `import com.messagebird.MessageBirdClient;
import com.messagebird.MessageBirdService;
import com.messagebird.MessageBirdServiceImpl;
import com.messagebird.exceptions.NotFoundException;
import com.messagebird.objects.Hlr;
import com.messagebird.exceptions.GeneralException;
import com.messagebird.exceptions.UnauthorizedException;

import java.math.BigInteger;

public class ExampleReadHlr {

    public static void main(String[] args) {

        if (args.length < 2) {
            System.out.println("Please specify your access key and phone in that order example : java -jar  test_accesskey 31612345678");
            return;
        }

        // First create your service object
        final MessageBirdService wsr = new MessageBirdServiceImpl(args[0]);

        // Add the service to the client
        final MessageBirdClient messageBirdClient = new MessageBirdClient(wsr);

        try {
            // Get Hlr using msgId and msisdn
            System.out.println("Retrieving HLR:");
            final Hlr hlr1 = messageBirdClient.getRequestHlr(new BigInteger(args[1]), "ExampleReadHlr reference");
            System.out.println(hlr1.toString());

            // Get Hlr using the id only
            System.out.println("Now using returned id to get Hlr:");
            final Hlr hlr2 = messageBirdClient.getViewHlr(hlr1.getId());
            System.out.println(hlr1.toString());

        } catch (UnauthorizedException unauthorized) {
            if (unauthorized.getErrors()!=null) {
                System.out.println(unauthorized.getErrors().toString());
            }
            unauthorized.printStackTrace();
        } catch (GeneralException generalException) {
            if (generalException.getErrors() !=null) {
                System.out.println(generalException.getErrors().toString());
            }
            generalException.printStackTrace();
        } catch (NotFoundException notFoundException) {
            if (notFoundException.getErrors() !=null) {
                System.out.println(notFoundException.getErrors().toString());
            }
            notFoundException.printStackTrace();
        }
    }
}`,
  node: `var messagebird = require('messagebird')('YOUR_ACCESS_KEY');

messagebird.hlr.read('9772ef10153a19646413512h43680562', function (err, response) {
  if (err) {
    return console.log(err);
  }
  console.log(response);
});`,
};

export const VIEWHLR_RESPONSE = {
  curl: `{
  "id":"0da180b035398662ceb3c42h04904985",
  "href":"https:\/\/rest.messagebird.com\/hlr\/0da180b035398662ceb3c42h04904985",
  "msisdn":31612345678,
  "network":20406,
  "reference":"YourReference",
  "details": {
    "status_desc": null,
    "imsi": "204080010948431",
    "country_iso": "NLD",
    "country_name": "Netherlands",
    "location_msc": "316530",
    "location_iso": 'nl',
    "ported": 0,
    "roaming": 0
  },
  "status":"active",
  "createdDatetime":"2016-05-04T07:32:46+00:00",
  "statusDatetime":"2016-05-04T07:32:46+00:00"
}`,
  php: `{
  'id' => '9772ef10153a19646413512h43680562',
  'msisdn' => 31612345678,
  'network' => 20104,
  'reference' => 'Reference',
  'details' => array(
    'status_desc' => NULL,
    'imsi' => '204080010948431',
    'country_iso' => 'NLD',
    'country_name' => 'Netherlands',
    'location_msc' => '316530',
    'location_iso' => 'nl',
    'ported' => 0,
    'roaming' => 0
  ),
  'status' => 'active',
  'createdDatetime' => '2016-05-04T07:32:46+00:00',
  'statusDatetime' => '2016-05-04T07:32:46+00:00',
}`,
  ruby: `#<MessageBird::HLR:0x007f8db91ebe20
@createdDatetime=2014-07-07 12:58:18 +0200,
@href="https://rest.messagebird.com/hlr/4933bed0453ba7455031712h16830892",
@id="4933bed0453ba7455031712h16830892",
@msisdn=31612345678,
@network="20408",
@reference="MyReference",
@status="absent",
@statusDatetime=2016-05-04 07:32:46 +0000>`,
  csharp: `{
  "id":"4933bed0453ba7455031712h16830892",
  "href":"https:\/\/rest.messagebird.com\/hlr\/4933bed0453ba7455031712h16830892",
  "msisdn":31612345678,
  "network":20406,
  "reference":"YourReference",
  "details": {
    "status_desc": null,
    "imsi": "204080010948431",
    "country_iso": "NLD",
    "country_name": "Netherlands",
    "location_msc": "316530",
    "location_iso": 'nl',
    "ported": 0,
    "roaming": 0
  },
  "status":"active",
  "createdDatetime":"2016-05-04T07:32:46+00:00",
  "statusDatetime":"2016-05-04T07:32:46+00:00"
}`,
  go: `&hlr.HLR{
  ID:"2688d6ec21a247d9b2000d1634373ec8",
  HRef:"https://rest.messagebird.com/hlr/2688d6ec21a247d9b2000d1634373ec8",
  MSISDN:31612345678,
  Network:20408,
  Reference:"MyReference",
  Status:"absent",
  CreatedDatetime:(*time.Time)(0xc4204de2c0),
  StatusDatetime:(*time.Time)(0xc4204de320),
}`,
  python: `{
  'id':'9772ef10153a19646413512h43680562',
  'href':'https:\/\/rest.messagebird.com\/hlr\/9772ef10153a19646413512h43680562',
  'msisdn':31612345678,
  'network':20406,
  'reference':'YourReference',
  'details': {
    'status_desc': null,
    'imsi': '204080010948431',
    'country_iso': 'NLD',
    'country_name': 'Netherlands',
    'location_msc': '316530',
    'location_iso': 'nl',
    'ported': 0,
    'roaming': 0
  },
  'status':'active',
  'createdDatetime':'2016-05-04T07:32:46+00:00',
  'statusDatetime':'2016-05-04T07:32:46+00:00'
}`,
  java: `{
  id='ce654390654e1ce5530e9a8h56512055',
  href='https://rest.messagebird.com/hlr/ce654390654e1ce5530e9a8h56512055',
  msisdn=31612345678, network='null',
  reference='ExampleReadHlr reference',
  status='sent',
  createdDatetime=2016-05-04T07:32:46+00:00,
  statusDatetime=2016-05-04T07:32:46+00:00
}`,
  node: `{
  'id':'9772ef10153a19646413512h43680562',
  'href':'https:\/\/rest.messagebird.com\/hlr\/9772ef10153a19646413512h43680562',
  'msisdn':31612345678,
  'network':20406,
  'reference':'YourReference',
  'details': {
    'status_desc': null,
    'imsi': '204080010948431',
    'country_iso': 'NLD',
    'country_name': 'Netherlands',
    'location_msc': '316530',
    'location_iso': 'nl',
    'ported': 0,
    'roaming': 0
  },
  'status':'active',
  'createdDatetime':'2016-05-04T07:32:46+00:00',
  'statusDatetime':'2016-05-04T07:32:46+00:00'
}`,
};

export const HANDLEHLR_REQUEST = {
  bash: `GET http://your-own.url/script?id=e8077d803532c0b5937c639b60216938&msisdn=31612345678&reference=YourReference&status=active&createdDatetime=2016-05-04T07:32:46+00:00&statusDatetime=2016-05-04T07:32:46+00:00`,
};

export const HANDLEHLR_RESPONSE = {
  bash: `200 OK`,
};
