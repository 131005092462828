import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Building an SMS-based customer support system with MessageBird`}</h1>
    <h3>{`⏱ 30 min build time || `}<a parentName="h3" {...{
        "href": "https://github.com/messagebirdguides/customer-support-guide-java"
      }}>{`Download the Code`}</a></h3>
    <h2>{`Why build SMS customer support?`}</h2>
    <p>{`In this MessageBird Developer Tutorial, we'll show you how to provide an excellent user experience by managing your inbound support tickets with this real-time SMS communication application between consumers and companies powered by the `}<a parentName="p" {...{
        "href": "/api/sms-messaging"
      }}>{`MessageBird SMS Messaging API`}</a>{`.`}</p>
    <p>{`People love communicating in real time, regardless of whether it’s their friends or to a business. Real time support in a comfortable medium helps to create an excellent support experience that can contribute to retaining users for life.`}</p>
    <p>{`On the business side, Support teams need to organize communication with their customers, often using ticket systems to combine all messages for specific cases in a shared view for support agents.`}</p>
    <p>{`We'll walk you through the following steps:`}</p>
    <ul>
      <li parentName="ul">{`Customers can send any message to a virtual mobile number (VMN) created and published by the company. Their message becomes a support ticket, and they receive an automated confirmation with a ticket ID for their reference.`}</li>
      <li parentName="ul">{`Any subsequent message from the same number is added to the same support ticket; there's no additional confirmation.`}</li>
      <li parentName="ul">{`Support agents can view all messages in a web view and reply to them.`}</li>
    </ul>
    <h2>{`Getting started`}</h2>
    <p>{`First things first, our sample application is built in Java using the `}<a parentName="p" {...{
        "href": "http://sparkjava.com/"
      }}>{`Spark framework`}</a>{`, so you need to install `}<a parentName="p" {...{
        "href": "https://www.oracle.com/technetwork/java/javase/downloads/jdk8-downloads-2133151.html"
      }}>{`Java`}</a>{` and `}<a parentName="p" {...{
        "href": "https://maven.apache.org/"
      }}>{`Maven`}</a>{`.`}</p>
    <p>{`The source code is available in the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/customer-support-guide-java"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`, which you can either clone with git or from where you can download a ZIP file with the source code to your computer.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`pom.xml`}</inlineCode>{` file has all the dependencies the project needs. Your IDE should be configured to automatically install them.`}</p>
    <p>{`The sample application uses `}<a parentName="p" {...{
        "href": "https://mongodb.github.io/mongo-java-driver/"
      }}>{`MongoDB`}</a>{` to provide an in-memory database for testing, so you don't need to configure an external database.`}</p>
    <h2>{`Prerequisites for receiving messages`}</h2>
    <h3>{`Overview`}</h3>
    <p>{`The support system receives incoming messages. From a high-level viewpoint, receiving is relatively simple: an application defines a `}<em parentName="p">{`webhook URL`}</em>{`, which you assign to a number purchased in the MessageBird Dashboard using `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/flow-builder"
      }}>{`Flow Builder`}</a>{`. A webhook is a URL on your site that doesn't render a page to users but is like an API endpoint that can be triggered by other servers. Every time someone sends a message to that number, MessageBird collects it and forwards it to the webhook URL where you can process it.`}</p>
    <h3>{`Exposing your development server with ngrok`}</h3>
    <p>{`When working with webhooks, an external service like MessageBird needs to access your application, so the URL must be public; however, during development you're typically working in a local development environment that is not publicly available. Thankfully this is not a big deal since various tools and services allow you to quickly expose your development environment to the Internet by providing a tunnel from a public URL to your local machine. One of the most popular tools is `}<a parentName="p" {...{
        "href": "https://ngrok.com/"
      }}>{`ngrok`}</a>{`.`}</p>
    <p>{`You can download `}<a parentName="p" {...{
        "href": "https://ngrok.com/download"
      }}>{`ngrok`}</a>{` for free as a single-file binary for almost every operating system, or optionally sign up for an account to access additional features.`}</p>
    <p>{`Let’s start a tunnel by providing a local port number on which the application runs. We’ll run our Java server on port 4567, so you can launch your tunnel with this command:`}</p>
    <pre><code parentName="pre" {...{}}>{`ngrok http 4567
`}</code></pre>
    <p>{`After you've launched the tunnel, ngrok displays your temporary public URL along with some other information. We'll need that URL in a minute.`}</p>
    <p>{`Another common tool for tunneling your local machine is `}<a parentName="p" {...{
        "href": "https://localtunnel.me/"
      }}>{`localtunnel.me`}</a>{`, which works virtually in the same way; you can have a look at it if you're facing problems with with ngrok. Keep in mind that it requires you to install `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/"
      }}>{`NPM`}</a>{` first.`}</p>
    <h3>{`Getting an inbound number`}</h3>
    <p>{`A requirement for receiving messages is a dedicated inbound number. Virtual mobile numbers look and work similar to regular mobile numbers; however, instead of being attached to a mobile device via a SIM card, they live in the cloud and can process inbound SMS and voice calls. MessageBird offers numbers from different countries for a low monthly fee; `}<a parentName="p" {...{
        "href": "https://www.messagebird.com/en/numbers"
      }}>{`feel free to explore our low-cost programmable and configurable numbers`}</a>{`.`}</p>
    <p>{`Purchasing a number is quite easy:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Go to the ‘`}<a parentName="p" {...{
            "href": "https://dashboard.messagebird.com/en/numbers"
          }}>{`Numbers`}</a>{`’ section in the left-hand side of your Dashboard and click the blue button ‘`}<a parentName="p" {...{
            "href": "https://dashboard.messagebird.com/en/vmn/buy-number"
          }}>{`Buy a number`}</a>{`’ in the top-right side of your screen.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Pick the country in which you and your customers are located, and make sure the SMS capability is selected.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Choose one number from the selection and the duration for which you want to pay now.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Confirm by clicking ‘Buy Number’ in the bottom-right of your screen.`}</p>
      </li>
    </ol>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/support-java/image1.png",
        "alt": "Buy a VMN"
      }}></img></p>
    <p>{`Awesome, you’ve set up your first virtual mobile number! 🎉`}</p>
    <p><strong parentName="p">{`Pro-tip:`}</strong>{` Check out our Help Center for more information about `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/sections/201958489-Virtual-Numbers"
      }}>{`virtual mobile numbers`}</a>{` and `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/sections/360000108538-Country-info-Restrictions"
      }}>{`country restrictions`}</a>{`.`}</p>
    <h3>{`Connect your number to the webhook`}</h3>
    <p>{`So you have a number now, but MessageBird has no idea what to do with it. That's why now you need to define a `}<em parentName="p">{`Flow`}</em>{` that links your number to your webhook. This is how you do it:`}</p>
    <h4>{`STEP ONE`}</h4>
    <p>{`On the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/numbers"
      }}>{`Numbers`}</a>{` section of the MessageBird Dashboard, click the "Add new flow" icon next to the number you purchased.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/support-java/image2.png",
        "alt": "FlowBuilder"
      }}></img></p>
    <h4>{`STEP TWO`}</h4>
    <p>{`Hit ‘Create Custom Flow’ and give your flow a name, choose ‘SMS’ as the trigger and hit ‘Next’.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/support-java/image3.png",
        "alt": "FlowBuilder"
      }}></img></p>
    <h4>{`STEP THREE`}</h4>
    <p>{`The number is already attached to the first step ‘SMS’. Add a new step by pressing the small ‘+’, choose ‘Fetch to URL’ and select ‘POST’ as the method; copy the output from the output of the ngrok command in the URL and add `}<inlineCode parentName="p">{`/webhook`}</inlineCode>{` to it—this is the name of the route we use to handle incoming messages in our sample application. Click on ‘Save’ when ready.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/support-java/image4.png",
        "alt": "FlowBuilder"
      }}></img></p>
    <h4>{`STEP FOUR`}</h4>
    <p>{`Ready! Hit ‘Publish’ on the right top of the screen to activate your flow. Well done, another step closer to testing incoming messages! Your flow should look something like this:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/support-java/image5.png",
        "alt": "FlowBuilder"
      }}></img></p>
    <p><strong parentName="p">{`Pro-tip:`}</strong>{` It might be useful to rename it this flow, because `}<em parentName="p">{`Untitled flow`}</em>{` won't be helpful in the long run. You can do this by clicking on the icon next to button ‘Back to Overview’ and pressing ‘Rename flow’.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/support-java/image6.png",
        "alt": "Buy a VMN"
      }}></img></p>
    <h2>{`Configuring the MessageBird SDK`}</h2>
    <p>{`The MessageBird SDK and an API key are not required to receive messages; however, since we want to send replies, we need to add and configure it. The SDK is defined in `}<inlineCode parentName="p">{`pom.xml`}</inlineCode>{` and loaded with a statement in `}<inlineCode parentName="p">{`SMSCustomerSupport.java`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`// Create a MessageBirdService
final MessageBirdService messageBirdService = new MessageBirdServiceImpl("YOUR-API-KEY");
// Add the service to the client
final MessageBirdClient messageBirdClient = new MessageBirdClient(messageBirdService);
`}</code></pre>
    <p>{`You need to provide a MessageBird API key, as well as the phone number you registered so that you can use it as the originator, via environment variables loaded with `}<a parentName="p" {...{
        "href": "https://mvnrepository.com/artifact/io.github.cdimascio/java-dotenv"
      }}>{`dotenv`}</a>{`. We've prepared an env.example file in the repository, which you should rename to .env and add the required information. Here's an example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`MESSAGEBIRD_API_KEY=YOUR-API-KEY
MESSAGEBIRD_ORIGINATOR=+31970XXXXXXX
`}</code></pre>
    <p>{`Let's create your live API access key. First, go to the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/user/index"
      }}>{`MessageBird Dashboard`}</a>{`; if you have already created an API key it will be shown right there. If you don’t see any key on the Dashboard or if you're unsure whether this key is in `}<em parentName="p">{`live`}</em>{` mode, go to the `}<em parentName="p">{`Developers`}</em>{` section in the MessageBird Dashboard and open the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`API access (REST) tab`}</a>{`. There you can create new API keys and manage your existing ones.`}</p>
    <p>{`If you are having any issues creating your API key, please reach out to `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we’ll make sure to help you out.`}</p>
    <h2>{`Receiving messages`}</h2>
    <p>{`Now we're fully prepared for receiving inbound messages, so let’s implement the `}<inlineCode parentName="p">{`post '/webhook'`}</inlineCode>{` route:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`// Handle incoming webhooks
post("/webhook",
   (req, res) ->
   {
       JSONParser parser = new JSONParser();
       JSONObject requestPayload;

       requestPayload = (JSONObject) parser.parse(req.body());

       // Read request
       String number = (String) requestPayload.get("originator");
       String text = (String) requestPayload.get("body");

`}</code></pre>
    <p>{`The webhook receives multiple request parameters from MessageBird; however, we're interested in two of them: the `}<inlineCode parentName="p">{`originator`}</inlineCode>{` which is the number of the user who sent the message (it’s important don't confuse it with the `}<em parentName="p">{`originator`}</em>{` you configured which is for `}<em parentName="p">{`outgoing`}</em>{` messages), and the `}<inlineCode parentName="p">{`body`}</inlineCode>{`, which is the content of the text message.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`// Find tickets for number in our database
MongoCollection<Document> tickets = database.getCollection("tickets");
Document doc = tickets.find(eq("number", number)).first();
`}</code></pre>
    <p>{`The number is used to look up the ticket; if none exists, we create a new ticket and add one inbound message to it:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`if (doc == null) {
   // Creating a new ticket
   JSONObject message = new JSONObject();
   JSONArray messages = new JSONArray();
   message.put("direction", "in");
   message.put("content", text);
   messages.add(message);

   Document entry = new Document("number", number)
           .append("open", true)
           .append("messages", messages);
   tickets.insertOne(entry);
`}</code></pre>
    <p>{`As you can see, we store the whole message history in a single Mongo document using an array called `}<inlineCode parentName="p">{`messages`}</inlineCode>{`. In the callback for the Mongo insert function we send the ticket confirmation to the user:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`// After creating a new ticket, send a confirmation
String shortId = entry.getObjectId("_id").toString().substring(18, 24);

// Notify the user
messageBirdClient.sendMessage(dotenv.get("MESSAGEBIRD_ORIGINATOR"), "Thanks for contacting customer support! Your ticket ID is " + shortId, phones);
`}</code></pre>
    <p>{`Let's unpack this. First, we take an excerpt of the autogenerated MongoDB ID because the full ID is too long and the last 6 digits are unique enough for our purpose. Then, we call `}<inlineCode parentName="p">{`messageBirdClient.sendMessage`}</inlineCode>{` to send a confirmation message. Three parameters are passed to the API:`}</p>
    <ul>
      <li parentName="ul">{`Our configured `}<inlineCode parentName="li">{`originator`}</inlineCode>{`, so that the receiver sees a reply from the number which they contacted in the first place.`}</li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`body`}</inlineCode>{` of the message, which contains the ticket ID.`}</li>
      <li parentName="ul">{`A `}<inlineCode parentName="li">{`phones`}</inlineCode>{` array with the number from the incoming message so that the reply goes back to the right person.`}</li>
    </ul>
    <p>{`So, what if a ticket already exists? In this case, our `}<inlineCode parentName="p">{`else`}</inlineCode>{` block, we'll add a new message to the array and store the updated document; there’s no need to send another confirmation.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`else {
   // Add an inbound message to the existing ticket
   JSONObject message = new JSONObject();
   message.put("direction", "in");
   message.put("content", text);
   ArrayList<JSONObject> messages = (ArrayList<JSONObject>) doc.get("messages");
   messages.add(message);

   Bson query = combine(set("open", true), set("messages", messages));
   tickets.updateOne(eq("number", number), query);
}
`}</code></pre>
    <p>{`Servers sending webhooks typically expect you to return a response with a default 200 status code to indicate that their webhook request was received, but they don’t parse the response. Therefore, we send the string `}<em parentName="p">{`OK`}</em>{` at the end of the route handler, regardless of the case that we handled.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`// Return any response, MessageBird won't parse this
res.status(200);
return "";
`}</code></pre>
    <h2>{`Sending messages`}</h2>
    <p>{`Customer support team members can view incoming tickets from an admin view. We have implemented a simple admin view in the `}<inlineCode parentName="p">{`get /admin`}</inlineCode>{` route. The approach is straightforward: request all documents representing open tickets from MongoDB, convert IDs as explained above and then pass them to a `}<a parentName="p" {...{
        "href": "http://sparkjava.com/documentation#views-and-templates"
      }}>{`Handlebars templates`}</a>{`.`}</p>
    <p>{`The template is stored in `}<inlineCode parentName="p">{`resources/templates/admin.handlebars`}</inlineCode>{`. Apart from the HTML that renders the documents, there’s a small Javascript section in it that refreshes the page every 10 seconds; thanks to this, you can keep the page open and will receive messages automatically with only a small delay and without the implementation of Websockets.`}</p>
    <p>{`This is the implementation of the route:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`get("/admin",
   (req, res) ->
   {
       Map<String, Object> model = new HashMap<>();

       // Find all open tickets
       MongoCollection<Document> tickets = database.getCollection("tickets");
       FindIterable<Document> iterable = tickets.find(eq("open", true));
       MongoCursor<Document> cursor = iterable.iterator();

       JSONObject ticket = new JSONObject();
       JSONArray results = new JSONArray();

       while (cursor.hasNext()) {
           Document d = cursor.next();
           ticket.put("shortId", d.get("_id").toString().substring(18, 24));
           ticket.put("_id", d.get("_id").toString());
           ticket.put("number", d.get("number"));
           ticket.put("messages", d.get("messages"));
           results.add(ticket);
       }

       // Show a page with tickets
       model.put("tickets", results.toArray());
       return new ModelAndView(model, "admin.handlebars");
   },

   new HandlebarsTemplateEngine()
);
`}</code></pre>
    <h2>{`Replying to messages`}</h2>
    <p>{`The admin template also contains a form for each ticket through which you can send replies. The implementation uses `}<inlineCode parentName="p">{`messageBirdClient.sendMessage`}</inlineCode>{`, analogous to the confirmation messages we're sending for new tickets. If you're curious about the details, you can look at the `}<inlineCode parentName="p">{`post /reply`}</inlineCode>{` implementation route in `}<inlineCode parentName="p">{`SMSCustomerSupport.java`}</inlineCode>{`.`}</p>
    <h2>{`Testing`}</h2>
    <p>{`You’re done! It’s time to test your application.`}</p>
    <p>{`Double-check that you’ve set up your number correctly with a flow that forwards incoming messages to a ngrok URL and that the tunnel is still running. Keep in mind that whenever you start a fresh tunnel with the ngrok command, you'll get a new URL, so you have to update it in the flow accordingly.`}</p>
    <p>{`To start the application, build and run it through your IDE.`}</p>
    <p>{`Open http://localhost:4567/admin in your browser; you should see an empty list of tickets. Then, take out your phone, launch the SMS app, and send a message to your virtual mobile number; around 10-20 seconds later, you should see your message in the browser. Amazing! Try again with another message which will be added to the ticket, or send a reply.`}</p>
    <p>{`Use the flow, code snippets, and UI examples from this tutorial as an inspiration to build your own SMS Customer Support system. Don't forget to download the code from the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/customer-support-guide-java"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`.`}</p>
    <p><strong parentName="p">{`Nice work!`}</strong>{` 🎉`}</p>
    <p>{`You now have a running SMS Customer Support application with MessageBird using Java!`}</p>
    <h2>{`Start building!`}</h2>
    <p>{`Want to start building your solution but not quite sure how to get started? Feel free to let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we'd love to help!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      