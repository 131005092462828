import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import Notification from 'components/Notification';
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Send an Outbound MMS`}</h1>
    <p>{`In this MMS API Quickstart, we’ll show you how to send MMS (Multimedia Messaging Service) from your web application using MessageBird’s MMS API.`}</p>
    <h2>{`Step 1: Sign up for free`}</h2>
    <p>{`Before we get started, `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/login"
      }}>{`log in to the MessageBird Connectivity Platform`}</a>{` and collect your live API key from the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`Developer Dashboard`}</a>{`.`}</p>
    <h2>{`Step 2: Send your first MMS`}</h2>
    <p>{`Next, enter `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`your credentials`}</a>{` and mobile number - as both the `}<inlineCode parentName="p">{`originator`}</inlineCode>{` and `}<inlineCode parentName="p">{`recipient`}</inlineCode>{` - to the below snippet and copy it to your terminal. Now, hit enter!`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-curl"
      }}>{`curl -X POST https://rest.messagebird.com/mms \\
       -H 'Authorization: AccessKey YOUR-API-KEY' \\
       -d "recipients=31XXXXXXXXXX" \\
       -d "originator=YourName" \\
       -d "subject=Check out this cool MMS" \\
       -d "body=Have you seen this logo?" \\
       -d "mediaUrls[]=https://developers.messagebird.com/img/logos/mb-400.jpg"
`}</code></pre>
    <Notification type="INFO" mdxType="Notification">
  You can access and manage your credentials in the{' '}
  <a href="https://dashboard.messagebird.com/en/developers/access">MessageBird Developer Dashboard</a>.
    </Notification>
    <p>{`You’ve sent your first MMS with MessageBird’s MMS API!`}</p>
    <h2>{`Step 3: View the response`}</h2>
    <p>{`Now, let’s view MessageBird’s HTTP response to your API call. If the set up was correct, you will receive a response similar to this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "id": "efa6405d518d4c0c88cce11f7db775fb",
  "href": "https://rest.messagebird.com/mms/efa6405d518d4c0c88cce11f7db775fb",
  "direction": "mt",
  "originator": "YourName",
  "subject": "Check out this cool MMS",
  "body": "Have you seen this logo?",
  "reference": null,
  "mediaUrls": [
    "https://developers.messagebird.com/img/logos/mb-400.jpg"
  ],
  "scheduledDatetime": null,
  "createdDatetime": "2017-09-01T10:00:00+00:s00",
  "recipients": {
    "totalCount": 1,
    "totalSentCount": 1,
    "totalDeliveredCount": 0,
    "totalDeliveryFailedCount": 0,
    "items": [
      {
        "recipient": 31XXXXXXXXX,
        "status": "sent",
        "statusDatetime": "2017-09-01T10:00:00+00:00"
      }
    ]
  }
}
`}</code></pre>
    <p>{`If something went wrong, you’ll receive an error response from the MessageBird API. Don't worry, head over to the `}<a parentName="p" {...{
        "href": "/docs/mms-messaging/troubleshooting"
      }}>{`MMS API Troubleshooting`}</a>{` to understand the error.`}</p>
    <p>{`Congrats! You can now programmatically send outbound MMS with MessageBird's MMS API.`}</p>
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'MMS API troubleshooting',
      to: '/quickstarts/mms/troubleshooting'
    }, {
      name: 'MMS API Reference',
      to: '/api/mms-messaging'
    }, {
      name: 'Official MessageBird SDKs',
      to: '/libraries'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      