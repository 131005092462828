import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Setting SMS instant lead alerts with MessageBird`}</h1>
    <h3>{`⏱ 15 min build time || `}<a parentName="h3" {...{
        "href": "https://github.com/messagebirdguides/lead-alerts-guide-java"
      }}>{`Download the Code`}</a></h3>
    <h2>{`Why build instant lead alerts for sales?`}</h2>
    <p>{`In this MessageBird Developer Tutorial, you’ll learn how to build SMS lead alerts with the `}<strong parentName="p"><a parentName="strong" {...{
          "href": "/api/sms-messaging"
        }}>{`MessageBird SMS Messaging API`}</a></strong>{` and convert more leads by instantly notifying your sales team. In this tutorial the sample application will be for our fictitious car dealership, `}<em parentName="p">{`M.B. Cars`}</em>{`.`}</p>
    <p>{`Even though a lot of business transactions happen on the web, from both a business and user perspective, it's still often preferred to switch the channel and talk on the phone. Especially when it comes to high-value transactions in industries such as real estate or mobility, personal contact is essential.`}</p>
    <p>{`One way to streamline this workflow is by building callback forms on your website. Through these forms, customers can enter their contact details and receive a call to their phone, thus skipping queues where prospective leads need to stay on hold.`}</p>
    <p>{`Callback requests reflect a high level of purchase intent and should be dealt with as soon as possible to increase the chance of converting a lead; therefore, it's essential to get them pushed to a sales agent quickly. SMS messaging has proven to be one of the most instant and effective channels for this use case.`}</p>
    <h2>{`Getting started`}</h2>
    <p>{`First things first, our sample application is built using `}<a parentName="p" {...{
        "href": "https://www.oracle.com/technetwork/java/javase/downloads/jdk8-downloads-2133151.html"
      }}>{`Java 1.8`}</a>{` and `}<a parentName="p" {...{
        "href": "https://maven.apache.org/"
      }}>{`Maven`}</a>{`.`}</p>
    <p>{`The source code is available in the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/lead-alerts-guide-java"
      }}>{`MessageBird Developer Guides GitHub repository`}</a>{` from which it can be cloned or downloaded into your development environment.`}</p>
    <p>{`After saving the code, import the project into your IDE. Maven should automatically download all the dependencies necessary.`}</p>
    <h2>{`Configuring the MessageBird SDK`}</h2>
    <p>{`The MessageBird SDK is used to send messages and it's added as a dependency and loaded with the following lines in `}<inlineCode parentName="p">{`LeadAlerts.java`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`
// Create a MessageBirdService

final MessageBirdService messageBirdService = new MessageBirdServiceImpl(dotenv.get("MESSAGEBIRD_API_KEY"));

// Add the service to the client

final MessageBirdClient messageBirdClient = new MessageBirdClient(messageBirdService);

`}</code></pre>
    <p>{`You need an API key, which you can retrieve from the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`MessageBird Dashboard`}</a>{`. As you can see in the code example above, the key is set as a parameter when including the SDK, and it's loaded from an environment variable called `}<inlineCode parentName="p">{`MESSAGEBIRD_API_KEY`}</inlineCode>{`. With `}<a parentName="p" {...{
        "href": "https://mvnrepository.com/artifact/io.github.cdimascio/java-dotenv"
      }}>{`dotenv`}</a>{` you can define these variables in a `}<inlineCode parentName="p">{`.env`}</inlineCode>{` file.`}</p>
    <p>{`The repository contains an `}<inlineCode parentName="p">{`.env.example`}</inlineCode>{` file which you can copy to `}<inlineCode parentName="p">{`.env`}</inlineCode>{` and then enter your information.`}</p>
    <p>{`Apart from the API key, we also specify the originator, which is what is displayed as the sender of the messages. Please note that alphanumeric sender IDs like the one in our example file don't work in all countries, including the United States, so it’s important to check the `}<strong parentName="p"><a parentName="strong" {...{
          "href": "https://support.messagebird.com/hc/en-us/sections/360000108538-Country-info-Restrictions"
        }}>{`country restrictions`}</a></strong>{`. If you can't use alphanumeric IDs, use a real phone number instead. You can check our `}<strong parentName="p"><a parentName="strong" {...{
          "href": "https://support.messagebird.com/hc/en-us/articles/115002628665-What-is-the-originator-"
        }}>{`originator article`}</a></strong>{` in Help Center to learn more about this topic.`}</p>
    <p>{`Additionally, we specify the sales agent's telephone numbers. These are the recipients that will receive the SMS alerts when a potential customer submits the callback form. You can separate multiple numbers with commas.`}</p>
    <p>{`Here's an example of a valid `}<inlineCode parentName="p">{`.env`}</inlineCode>{` file for our sample application:`}</p>
    <pre><code parentName="pre" {...{}}>{`
MESSAGEBIRD_API_KEY=YOUR-API-KEY

MESSAGEBIRD_ORIGINATOR=Mbcars

SALES_AGENT_NUMBERS=+31970XXXXXXX,+31970YYYYYYY

`}</code></pre>
    <h2>{`Showing a landing page`}</h2>
    <p>{`The landing page is a simple HTML page with information about our company, a call to action and a form with two input fields, name and number, and a submit button. We use Handlebars templates so we can compose the view with a layout and have the ability to show dynamic content. You can see the landing page in the file `}<inlineCode parentName="p">{`resources/templates/landing.mustache`}</inlineCode>{`, which extends the layouts stored in `}<inlineCode parentName="p">{`resources/templates/header.mustache`}</inlineCode>{` and `}<inlineCode parentName="p">{`resources/templates/footer.mustache`}</inlineCode>{`. The `}<inlineCode parentName="p">{`get '/'`}</inlineCode>{` route in `}<inlineCode parentName="p">{`LeadAlerts.java`}</inlineCode>{` is responsible for rendering it.`}</p>
    <h2>{`Handling callback requests`}</h2>
    <p>{`When the user submits the form, the `}<inlineCode parentName="p">{`post '/callme'`}</inlineCode>{` route receives their name and number. First, we do some input validation:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`
// Handle request

post("/callme", (req, res) ->

{

// Read request

String name = req.queryParams("name");

String number = req.queryParams("number");

Map<String, Object> model = new HashMap<>();

if (name.isBlank() || number.isBlank()) {

    model.put("errors", "Please fill all required fields!");

    model.put("name", name);

    model.put("number", number);

    return new ModelAndView(model, "landing.mustache");

}

`}</code></pre>
    <p>{`Then, we define where to send the message. As you've seen above, we specified multiple recipient numbers in the `}<inlineCode parentName="p">{`SALES_AGENT_NUMBERS`}</inlineCode>{` environment variable. `}<em parentName="p">{`M.B. Cars`}</em>{` have decided to randomly distribute incoming calls to their staff so that every salesperson receives roughly the same amount of leads. Here's the code for the random distribution:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`
// Choose one of the sales agent numbers randomly

// a) Convert comma-separated values to array

List<String> numbers = Arrays.asList(dotenv.get("SALES_AGENT_NUMBERS").split("\\\\s*,\\\\s*"));

// b) Random number between 0 and (number count - 1)

int randomNum = ThreadLocalRandom.current().nextInt(0, numbers.size());

// c) Pick number

String recipient = numbers.get(randomNum);

`}</code></pre>
    <p>{`Now we can formulate a message for the agent and send it through the MessageBird SDK using the `}<inlineCode parentName="p">{`messageBirdClient.sendMessage`}</inlineCode>{` method:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`
// Send lead message with MessageBird API

final MessageResponse response = messageBirdClient.sendMessage(dotenv.get("MESSAGEBIRD_ORIGINATOR"), body, phones);

`}</code></pre>
    <p>{`The arguments are as follows:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`originator`}</inlineCode>{`: This is the first parameter. It represents a sender ID for the SMS. The sender ID comes from the environment variable defined earlier.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`body`}</inlineCode>{`: This is the second parameter. The text of the message that includes the input from the form.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`recipients`}</inlineCode>{`: This is the third parameter. It's an array of one or more phone numbers to send the message to.`}</p>
      </li>
    </ul>
    <p>{`Inside this function, we handle the error case by showing the previous form again and informing the user that something went wrong. In the success case, we show a basic confirmation page which you can see in `}<inlineCode parentName="p">{`templates/sent.mustache`}</inlineCode>{`. This is how the response is handled:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`
try {

// Send lead message with MessageBird API

    final MessageResponse response = messageBirdClient.sendMessage(dotenv.get("MESSAGEBIRD_ORIGINATOR"), body, phones);

    return new ModelAndView(model, "sent.mustache");

} catch (UnauthorizedException | GeneralException ex) {

    model.put("errors", ex.toString());

    return new ModelAndView(model, "sent.mustache");

}

`}</code></pre>
    <h2>{`Testing`}</h2>
    <p>{`Have you created your `}<inlineCode parentName="p">{`.env`}</inlineCode>{` file with a working key and added one more phone number to the existing phone numbers, as explained above in `}<em parentName="p">{`Configuring the MessageBird SDK`}</em>{`, to receive the lead alert? Lovely!`}</p>
    <p>{`Now, build and run the application through your IDE.`}</p>
    <p>{`Go to http://localhost:4567/ to see the form and request a lead!`}</p>
    <p>{`Awesome! You can now use the flow, code snippets, and UI examples from this tutorial as an inspiration to build your own SMS-based lead alerts application. Don't forget to download the code from the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/lead-alerts-guide-java"
      }}>{`MessageBird Developer Guides GitHub repository`}</a>{`.`}</p>
    <p><strong parentName="p">{`Nice work! 🎉`}</strong></p>
    <p>{`You've just built your own instant lead alerts application with MessageBird using Java!`}</p>
    <h2>{`Start building!`}</h2>
    <p>{`Want to build something similar but not quite sure how to get started? Please feel free to let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`, we'd love to help!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      