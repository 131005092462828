import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Making outbound voice calls with MessageBird`}</h1>
    <h3>{`⏱ 10 min build time`}</h3>
    <p>{`In this MessageBird Developer Tutorial you’ll learn how to make calls with the MessageBird Voice API.`}</p>
    <p>{`Before we get started, have you set up your Go development environment and
project directory with the MessageBird SDK?`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`No!`}</strong>{` Make sure you to set this up before continuing; you can read this `}<a parentName="li" {...{
          "href": "/tutorials/setup-local-dev-environment-go"
        }}>{`MessageBird Developer Tutorial`}</a>{` to learn how to do so.`}</li>
      <li parentName="ul"><strong parentName="li">{`Yes!`}</strong>{` Great! We’ll show you how to make your first API request and begin establishing Voice Calls with MessageBird using Go.`}</li>
    </ul>
    <h2>{`Getting started`}</h2>
    <p>{`First, let's create a new Go project directory. You can do this anywhere, but best practice says that you should make this folder in your `}<inlineCode parentName="p">{`$GOPATH/src/github.com/<your-username>`}</inlineCode>{` folder (your project folder can be `}<inlineCode parentName="p">{`$GOPATH/src/github.com/birddev/make-call`}</inlineCode>{`).`}</p>
    <p>{`If you're not sure where your `}<inlineCode parentName="p">{`$GOPATH`}</inlineCode>{` is located, running `}<inlineCode parentName="p">{`go env GOPATH`}</inlineCode>{` will display it in your terminal.`}</p>
    <p>{`In your project root, let's create a `}<inlineCode parentName="p">{`main.go`}</inlineCode>{` file. You can use our template `}<inlineCode parentName="p">{`main.go`}</inlineCode>{`file from oue `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/voice-call-guide-go"
      }}>{`MessageBird Developer Tutorials Github Repository`}</a>{`. If you're creating yours from scratch, just open the `}<inlineCode parentName="p">{`main.go`}</inlineCode>{` in your text editor of choice and stub out our program by writing the following code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`package main

import (
   "log"

   "github.com/messagebird/go-rest-api"
)

func main(){
   client := messagebird.New("<your-api-key>")
   log.Println("Hello!")
}
`}</code></pre>
    <p>{`Here, we've imported the MessageBird SDK for Go and created a `}<inlineCode parentName="p">{`client`}</inlineCode>{` object
that we'll use to interact with the MessageBird REST API. To create our `}<inlineCode parentName="p">{`client`}</inlineCode>{`
object, let’s make a `}<inlineCode parentName="p">{`messagebird.New()`}</inlineCode>{` call which expects a single argument, your `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`API key`}</a>{`. Keep in mind that you can create either a `}<strong parentName="p">{`test`}</strong>{` or `}<strong parentName="p">{`live`}</strong>{` API key:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`test`}</strong>{` API keys simulate responses from the MessageBird server, allowing you to test your code flow and error handling before sending real messages. You still need an internet connection to use MessageBird REST API features.`}</li>
      <li parentName="ul"><strong parentName="li">{`live`}</strong>{` API keys allow you to send actual messages to your recipients. We recommend that you don’t publish this key anywhere.`}</li>
    </ul>
    <p>{`In order to start using the SDK, replace `}<inlineCode parentName="p">{`<your-api-key>`}</inlineCode>{` with your API
key.`}</p>
    <p>{`To keep this tutorial straightforward, we'll be hardcoding the MessageBird API key in our application; however, for production applications, we recommended storing
the key in a configuration file or environment variable instead and passing this variable  to your application. You'll see this in practice later in our MessageBird Developer Tutorials for building common use cases.`}</p>
    <h2>{`Making a voice call`}</h2>
    <p>{`The SDK has a `}<inlineCode parentName="p">{`voice`}</inlineCode>{` library that allows you to initiate a call using MessageBird's REST API. To make a call:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Add the `}<inlineCode parentName="p">{`voice`}</inlineCode>{` package to your import statement in `}<inlineCode parentName="p">{`main.go`}</inlineCode>{`:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-go"
          }}>{`import (
    "log"

    "github.com/messagebird/go-rest-api"
    "github.com/messagebird/go-rest-api/voice"
)
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`We'll stub out and log the output of our `}<inlineCode parentName="p">{`voice.InitiateCall()`}</inlineCode>{` function call. Modify the `}<inlineCode parentName="p">{`main()`}</inlineCode>{` block in `}<inlineCode parentName="p">{`main.go`}</inlineCode>{` to look like this:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-go"
          }}>{`func main(){
    client := messagebird.New("<your-api-key>")

    call, err := voice.InitiateCall(
        client,
        source,
        destination,
        callflow,
        nil,
    )
    if err != nil {
        log.Println(err)
    }
    // We're logging call for development purposes. You can safely discard this variable in production.
    log.Println(call)
}
`}</code></pre>
      </li>
    </ol>
    <p>{`Here, we're calling `}<inlineCode parentName="p">{`voice.InitiateCall()`}</inlineCode>{` with these parameters:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`client`}</inlineCode>{`: This is the client object that we've created earlier.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`source`}</inlineCode>{`: Is displayed as the caller's ID on the receiving end. The number must be owned or `}<a parentName="li" {...{
          "href": "https://support.messagebird.com/hc/en-us/articles/4412586921233"
        }}>{`verified`}</a>{` in your account.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`destination`}</inlineCode>{`: The "destination" of the call, which is a phone number written in the `}<a parentName="li" {...{
          "href": "https://support.messagebird.com/hc/en-us/articles/115003950149-Numbers-format-"
        }}>{`international format with country code`}</a>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`callflow`}</inlineCode>{`: A call flow object that describes to the MessageBird servers the events that should occur during a call.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`nil`}</inlineCode>{`: Optional attributes that you can specify for this phone call. Here, we're setting it as `}<inlineCode parentName="li">{`nil`}</inlineCode>{` because we have no additional attributes to add. You can find more information about these optional attributes in our `}<a parentName="li" {...{
          "href": "/api/voice-calling#calls"
        }}>{`Voice Calling API documentation`}</a>{`.`}</li>
    </ul>
    <p>{`Now that we've got `}<inlineCode parentName="p">{`voice.InitiateCall()`}</inlineCode>{` stubbed out, we can define these parameters. Add these lines of code just on top of the `}<inlineCode parentName="p">{`voice.InitiateCall()`}</inlineCode>{` block:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`// We've already defined client

source, destination := "+319876543211", "+319876543210"

callflow := voice.CallFlow{
       Steps: []voice.CallFlowStep{
           &voice.CallFlowSayStep{
               Voice:    "female",
               Payload:  "Hey you, a little bird told me you wanted a call!",
               Language: "en-GB",
           },
       },
   }
`}</code></pre>
    <p>{`We've defined our `}<inlineCode parentName="p">{`source`}</inlineCode>{` and `}<inlineCode parentName="p">{`destination`}</inlineCode>{` using example phone numbers. For your application to work, you should replace these with working phone numbers.`}</p>
    <p>{`Awesome! Let's write our call flow:`}</p>
    <p>{`The call flow specifies one or more steps that will execute once the receiver picks up the call. They can be different actions, including playing audio files, reading a block of text with a synthesized voice (TTS), or forwarding the call to another phone.`}</p>
    <p>{`Inside our `}<inlineCode parentName="p">{`voice.CallFlow{}`}</inlineCode>{` struct, we have the following keys:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`Steps`}</inlineCode>{`: The rest of the call flow is an array of "steps", or `}<inlineCode parentName="li">{`voice.CallFlowStep`}</inlineCode>{` structs. The MessageBird SDK for Go provides a different struct type for each action type that a call flow step makes. Here, we're adding only one call flow step to the array: a `}<inlineCode parentName="li">{`voice.CallFlowSayStep`}</inlineCode>{` struct to tell MessageBird to make a call that speaks a given line of text.`}</li>
    </ul>
    <p>{`We need to set three parameters for our `}<inlineCode parentName="p">{`voice.CallFlowSayStep`}</inlineCode>{` struct:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`Voice`}</inlineCode>{`: The voice to use when making the call—it can be set to either "male" or "female".`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Payload`}</inlineCode>{`: This is the text will be spoken during the call.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Language`}</inlineCode>{`: The language of the text to be spoken, written as "en_gb" or similar.`}</li>
    </ul>
    <p>{`Keep in mind that these three parameters are required when setting a `}<inlineCode parentName="p">{`voice.CallFlowSayStep`}</inlineCode>{` call flow step.`}</p>
    <p>{`You can learn more about calls, call flows and steps, the types of actions that are available in our `}<a parentName="p" {...{
        "href": "/api/voice-calling"
      }}>{`Voice Calling API documentation`}</a>{`.`}</p>
    <h2>{`Finishing your program`}</h2>
    <p>{`Awesome! You have a fully functioning Go program that makes a call to your set destination phone number. Let's go ahead and save it.`}</p>
    <p>{`To test your application, run in the terminal:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`go run main.go
`}</code></pre>
    <p>{`If everything works fine, you should see the API response logged in the terminal, signalling that you've successfully made a call. If you used a live API key and had verified your number or added `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/articles/115002599309-Test-credits"
      }}>{`test credits to your account`}</a>{` your phone will ring shortly and deliver the message when you pick up the phone.`}</p>
    <p>{`If you see an error from the script try to read and understand the message so the problem can be fixed. If you have trouble still, don’t hesitate to contact us at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we’ll help you out.`}</p>
    <p><strong parentName="p">{`Nice work!`}</strong>{` 🎉`}</p>
    <p>{`You just initiated your first voice call with the MessageBird `}<a parentName="p" {...{
        "href": "/api/voice-calling"
      }}>{`Voice Calling API`}</a>{` using Go!`}</p>
    <h2>{`Start building!`}</h2>
    <p>{`Head over to the next MessageBird Developer Tutorial and learn how
to `}<a parentName="p" {...{
        "href": "/tutorials/handle-incoming"
      }}>{`set up and handle incoming voice
calls`}</a>{`.`}</p>
    <p>{`Want to start building your solution but not quite sure how to get
started? Please feel free to let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we'd love to help!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      