import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import Notification from 'components/Notification';
import { Img } from 'components/shared';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Facebook Quick Replies`}</h1>
    <p><a parentName="p" {...{
        "href": "https://developers.facebook.com/docs/messenger-platform/send-messages/quick-replies"
      }}>{`Facebook Quick Replies`}</a>{` allows
your customers to easily reply your messages using pre-defined responses in form of buttons. Quick reply messages may
contain multiple buttons with a title and an optional image, and they can also be used for requesting the recipient's
phone number and e-mail address.`}</p>
    <p>{`Once the quick reply button is clicked, all buttons are dismissed and an inbound message is created according to the
recipient's selection.`}</p>
    <h2>{`Text quick reply`}</h2>
    <p>{`In order to send a text quick reply, you can use any of
`}<a parentName="p" {...{
        "href": "https://developers.messagebird.com/api/conversations/#sending-messages"
      }}>{`the endpoints for sending messages in Conversations API`}</a>{`
with the message type `}<inlineCode parentName="p">{`facebookQuickReply`}</inlineCode>{`. The example below illustrates how to send it using the endpoint `}<inlineCode parentName="p">{`POST /v1/send`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-curl"
      }}>{`curl -X "POST" "https://conversations.messagebird.com/v1/send" \\
     -H 'Authorization: AccessKey {your-access-key}' \\
     -H 'Content-Type: application/json; charset=utf-8' \\
     -d $'{
  "content": {
    "facebookQuickReply": {
      "text": "Text quick reply sample.",
      "quick_replies": [
        {
          "title": "Title 1",
          "content_type": "text",
          "payload": "payload-1"
        },
        {
          "title": "Title 2",
          "content_type": "text",
          "payload": "payload-2"
        }
      ]
    }
  },
  "to": "4190112347766899",
  "type": "facebookQuickReply",
  "from": "99dde520cd834661a1771ad08044be8b"
}'
`}</code></pre>
    <ul>
      <li parentName="ul">{`The message `}<inlineCode parentName="li">{`type`}</inlineCode>{` must be `}<inlineCode parentName="li">{`facebookQuickReply`}</inlineCode></li>
      <li parentName="ul">{`For text quick replies, the field `}<inlineCode parentName="li">{`content.facebookQuickReply.text`}</inlineCode>{` must be set`}</li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.facebookQuickReply.quick_replies`}</inlineCode>{` must contain an array of `}<a parentName="li" {...{
          "href": "/api/conversations/#facebookquickreply-object"
        }}>{`FacebookQuickReply`}</a></li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.facebookQuickReply.quick_replies[].title`}</inlineCode>{` must contain the text to be displayed in the quick reply button`}</li>
      <li parentName="ul">{`For text quick replies, the field `}<inlineCode parentName="li">{`content.facebookQuickReply.quick_replies[].content_type`}</inlineCode>{` must be `}<inlineCode parentName="li">{`text`}</inlineCode></li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.facebookQuickReply.quick_replies[].payload`}</inlineCode>{` must contain a string that's send through an inbound message`}</li>
    </ul>
    <p>{`The message sent by the previous code snippet looks like the following.`}</p>
    <Img src="/img/screenshots/quickstarts/facebook/text-quick-reply-sample-1.png" align="middle" mdxType="Img" />
    <p>{`The message looks like the following in Conversations API.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "id": "e700dfde6bd74ff3ad4c95223fd90166",
  "conversationId": "6279c2012f7d4df393cfa1f872518518",
  "platform": "facebook",
  "to": "4190112347766899",
  "from": "101397625015964",
  "channelId": "99d0e570cd834661a1771ab08044be8b",
  "type": "facebookQuickReply",
  "content": {
    "facebookQuickReply": {
      "text": "Text quick reply sample.",
      "quick_replies": [
        {
          "content_type": "text",
          "title": "Title 1",
          "payload": "payload-1"
        },
        {
          "content_type": "text",
          "title": "Title 2",
          "payload": "payload-2"
        }
      ]
    }
  },
  "direction": "sent",
  "status": "sent",
  "createdDatetime": "2022-02-03T16:28:19.187062243Z",
  "updatedDatetime": "2022-02-03T16:28:19.214867842Z"
}
`}</code></pre>
    <p>{`When the recipient clicks on a specific quick reply button, all other buttons disappear and the reply is visible in
the recipient's Facebook Messenger app like the following.`}</p>
    <Img src="/img/screenshots/quickstarts/facebook/text-quick-reply-response-1.png" align="middle" mdxType="Img" />
    <p>{`The recipient's reply message looks like the following in Conversations API.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "id": "b7a42fe4d0a64a508225385d25f35f4e",
  "conversationId": "6279c2012f7d4df393cfa1f872518518",
  "platform": "facebook",
  "to": "101397625015964",
  "from": "4190117307726093",
  "channelId": "99d0e570cd834661a1771ab08044be8b",
  "type": "facebookQuickReply",
  "content": {
    "facebookQuickReply": {
      "text": "Title 1",
      "quick_replies": [
        {
          "payload": "payload-1"
        }
      ]
    }
  },
  "direction": "received",
  "status": "received",
  "createdDatetime": "2022-02-03T16:34:15.239Z",
  "updatedDatetime": "2022-02-03T16:34:15.239Z"
}
`}</code></pre>
    <h2>{`Phone number quick reply`}</h2>
    <p>{`In order to send a phone number quick reply, you can use any of
`}<a parentName="p" {...{
        "href": "https://developers.messagebird.com/api/conversations/#sending-messages"
      }}>{`the endpoints for sending messages in Conversations API`}</a>{`
with the message type `}<inlineCode parentName="p">{`facebookQuickReply`}</inlineCode>{`. The example below illustrates how to send it using the endpoint `}<inlineCode parentName="p">{`POST /v1/send`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-curl"
      }}>{`curl -X "POST" "https://conversations.messagebird.com/v1/send" \\
     -H 'Authorization: AccessKey {your-access-key}' \\
     -H 'Content-Type: application/json; charset=utf-8' \\
     -d $'{
  "content": {
    "facebookQuickReply": {
      "text": "Can you please share your phone number?",
      "quick_replies": [
        {
          "content_type": "user_phone_number"
        }
      ]
    }
  },
  "to": "3298243536965995",
  "type": "facebookQuickReply",
  "from": "2cc5f8a4020747be80c6b1f70c9f6eb3"
}'
`}</code></pre>
    <p>{`The message sent in the code snippet above looks like the following in the Facebook Messenger.`}</p>
    <Img src="/img/screenshots/quickstarts/facebook/phone-quick-reply-sample.png" align="middle" mdxType="Img" />
    <p>{`And the message looks like the following in Conversations API.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "id": "382303c0f10c454d8b7f7e17da222b0e",
  "conversationId": "fc1589ad98c34841a2aa40b5d79c431b",
  "platform": "facebook",
  "to": "3298243536965995",
  "from": "103955145046384",
  "channelId": "2cc5f8a4020747be80c6b1f70c9f6eb3",
  "type": "facebookQuickReply",
  "content": {
    "facebookQuickReply": {
      "text": "Can you please share your phone number?",
      "quick_replies": [
        {
          "content_type": "user_phone_number"
        }
      ]
    }
  },
  "direction": "sent",
  "status": "sent",
  "createdDatetime": "2022-02-10T10:08:52.494355828Z",
  "updatedDatetime": "2022-02-10T10:08:52.530605461Z"
}
`}</code></pre>
    <p>{`Once the recipient clicks on their phone number, the reply looks like the following in Facebook Messenger.`}</p>
    <Img src="/img/screenshots/quickstarts/facebook/phone-quick-reply-response-sample.png" align="middle" mdxType="Img" />
    <p>{`And the inbound message looks like the following in Conversations API.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "id": "88b88a40e3134fb093786f6fa5932c96",
  "conversationId": "fc1589ad98c34841a2aa40b5d79c431b",
  "platform": "facebook",
  "to": "103955145046384",
  "from": "3298243536965995",
  "channelId": "2cc5f8a4020747be80c6b1f70c9f6eb3",
  "type": "facebookQuickReply",
  "content": {
    "facebookQuickReply": {
      "text": "+0000000000000",
      "quick_replies": [
        {
          "payload": "+0000000000000"
        }
      ]
    }
  },
  "direction": "received",
  "status": "received",
  "createdDatetime": "2022-02-10T10:12:57.437Z",
  "updatedDatetime": "2022-02-10T10:12:57.437Z"
}
`}</code></pre>
    <h2>{`Email quick reply`}</h2>
    <p>{`In order to send a email quick reply, you can use any of
`}<a parentName="p" {...{
        "href": "https://developers.messagebird.com/api/conversations/#sending-messages"
      }}>{`the endpoints for sending messages in Conversations API`}</a>{`
with the message type `}<inlineCode parentName="p">{`facebookQuickReply`}</inlineCode>{`. The example below illustrates how to send it using the endpoint `}<inlineCode parentName="p">{`POST /v1/send`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-curl"
      }}>{`curl -X "POST" "https://conversations.messagebird.com/v1/send" \\
     -H 'Authorization: AccessKey {your-access-key}' \\
     -H 'Content-Type: application/json; charset=utf-8' \\
     -d $'{
  "content": {
    "facebookQuickReply": {
      "text": "Can you please share your email?",
      "quick_replies": [
        {
          "content_type": "user_email"
        }
      ]
    }
  },
  "to": "3298243536965995",
  "type": "facebookQuickReply",
  "from": "2cc5f8a4020747be80c6b1f70c9f6eb3"
}'
`}</code></pre>
    <p>{`The message sent in the code snippet above looks like the following in the Facebook Messenger.`}</p>
    <Img src="/img/screenshots/quickstarts/facebook/email-quick-reply-sample.png" align="middle" mdxType="Img" />
    <p>{`And the message looks like the following in Conversations API.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "id": "1e3cd5913ba043af87cac009d8efe7fb",
  "conversationId": "fc1589ad98c34841a2aa40b5d79c431b",
  "platform": "facebook",
  "to": "3298243536965995",
  "from": "103955145046384",
  "channelId": "2cc5f8a4020747be80c6b1f70c9f6eb3",
  "type": "facebookQuickReply",
  "content": {
    "facebookQuickReply": {
      "text": "Can you please share your email?",
      "quick_replies": [
        {
          "content_type": "user_email"
        }
      ]
    }
  },
  "direction": "sent",
  "status": "sent",
  "createdDatetime": "2022-02-10T12:27:13.431851501Z",
  "updatedDatetime": "2022-02-10T12:27:13.499705107Z"
}
`}</code></pre>
    <p>{`Once the recipient clicks on their phone number, the reply looks like the following in Facebook Messenger.`}</p>
    <Img src="/img/screenshots/quickstarts/facebook/email-quick-reply-response-sample.png" align="middle" mdxType="Img" />
    <p>{`And the inbound message looks like the following in Conversations API.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "id": "017c958f39324621ba916946837c7d98",
  "conversationId": "fc1589ad98c34841a2aa40b5d79c431b",
  "platform": "facebook",
  "to": "103955145046384",
  "from": "3298243536965995",
  "channelId": "2cc5f8a4020747be80c6b1f70c9f6eb3",
  "type": "facebookQuickReply",
  "content": {
    "facebookQuickReply": {
      "text": "support@messagebird.com",
      "quick_replies": [
        {
          "payload": "support@messagebird.com"
        }
      ]
    }
  },
  "direction": "received",
  "status": "received",
  "createdDatetime": "2022-02-10T12:28:48.508Z",
  "updatedDatetime": "2022-02-10T12:28:48.508Z"
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      