import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import Notification from 'components/Notification';
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Make a voice call`}</h1>
    <p>{`In this Programmable Voice Calling Quickstart, you'll learn how to make an outbound voice call from your web application using the Voice Calling API.`}</p>
    <h2>{`Step 1: Sign up for free`}</h2>
    <p>{`Before we get started, `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/login"
      }}>{`log in to the MessageBird Connectivity Platform`}</a>{` and collect your live API key from the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`Developer Dashboard`}</a>{`.`}</p>
    <h2>{`Step 2: Make your first voice call`}</h2>
    <p>{`Now, enter `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`your credentials`}</a>{` in the code snippet below, including `}<inlineCode parentName="p">{`YOUR-API-KEY`}</inlineCode>{` with your API key, and enter your mobile number as both the `}<inlineCode parentName="p">{`source`}</inlineCode>{` and `}<inlineCode parentName="p">{`destination`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-curl"
      }}>{`curl https://voice.messagebird.com/calls \\
    -H "Authorization: AccessKey YOUR-API-KEY" \\
    -d $'{
      "source" : "31XXXXXXXXX",
      "destination" : "31XXXXXXXXX",
      "callFlow" : {
          "steps" : [
              {
                  "action" : "say",
                  "options" : {
                      "payload" : "Hey, this is your first voice call",
                      "language" : "en-GB",
                      "voice" : "female"
                  }
              }
          ]
      }
  }'
`}</code></pre>
    <p>{`Next, simply copy the above snippet to your terminal and hit enter.`}</p>
    <p>{`Good job! You've just made your first outbound voice call with Programmable Voice Calling!`}</p>
    <h2>{`Step 3: View the response`}</h2>
    <p>{`Now, let’s view MessageBird’s HTTP response to your API call. If the set up was correct, you'll receive a response similar to this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "data": [
    {
      "id": "33dcf70e-0c06-44b3-9c12-98b38e0ea243",
      "status": "queued",
      "source": "31XXXXXXXXX",
      "destination": "31XXXXXXXXX",
      "createdAt": "2019-02-19T10:07:42Z",
      "updatedAt": "2019-02-19T10:07:42Z",
      "endedAt": null
    }
  ],
  "_links": {
    "self": "/calls/33dcf70e-0c06-44b3-9c12-98b38e0ea243"
  },
  "pagination": {
    "totalCount": 0,
    "pageCount": 0,
    "currentPage": 0,
    "perPage": 0
  }
}
`}</code></pre>
    <p>{`If something went wrong, you’ll receive an error response from the Voice Calling API. Don't worry, simply head over to `}<a parentName="p" {...{
        "href": "/docs/voice-calling/troubleshooting"
      }}>{`Programmable Voice Calling Troubleshooting`}</a>{` to understand the error.`}</p>
    <p>{`Congrats! You can now programmatically make outbound Voice Calls with the MessageBird Voice Calling API using cURL.`}</p>
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'Receive voice calls',
      to: '/quickstarts/voice-calling/receive-calls'
    }, {
      name: 'Voice Calling Tutorials',
      to: '/tutorials?filter=voice'
    }, {
      name: 'Voice Calling API Reference',
      to: '/api/voice-calling'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      