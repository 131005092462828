import React from 'react';
// @ts-ignore
import { slide as Menu } from 'react-burger-menu';
import Sidebar from '../Sidebar';
import { Link } from 'gatsby';

import { Container } from '../Container';
import styled from 'styled-components';

const MobileNavWrap = styled.div`
  position: fixed !important;
  width: 100%;
  background-color: #fff;
  display: none;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  z-index: 3;
  @media screen and (max-width: 767px) {
    display: flex;
  }
  .bm-menu-wrap {
    background: white;
    top: 0px;
  }
  .bm-burger-button,
  .bm-cross-button {
    position: fixed;
    width: 24px !important;
    height: 20px !important;
    right: 10px !important;
    top: 16px !important;
    button {
      outline: 0 !important ;
    }
  }
  .bm-burger-bars {
    background-color: black;
  }
  .bm-item {
    display: flex !important;
    outline: 0 !important;
    padding: 30px;
  }
`;

// eslint-disable-next-line no-redeclare
const MobileNavigation: React.FC<MobileProps> = ({
  api,
  tutorials,
  languages,
}) => {
  return (
    <Container css={'z-index:1000;'}>
      <MobileNavWrap>
        <Link to="/">
          <img
            className="logo"
            src="/img/glyph.svg"
            height="26"
            alt="Messagebird logo"
          />
        </Link>
        <Menu
          noOverlay
          right
          customCrossIcon={<img alt="Close mobile menu" src="/img/close.svg" />}
        >
          <Sidebar tutorials={tutorials} api={api} languages={languages} />
        </Menu>
      </MobileNavWrap>
    </Container>
  );
};

interface MobileProps {
  api?: boolean;
  tutorials?: boolean;
  languages?: any;
}

export default MobileNavigation;
