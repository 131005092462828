import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Transferring Voice Calls with MessageBird`}</h1>
    <p>{`In this MessageBird Tutorial you’ll learn how to handle and forward incoming voice calls by building a call flow powered by the MessageBird API.`}</p>
    <h2>{`Step 1: Create the call flow`}</h2>
    <p>{`A call flow describes the operations to be executed when a voice call is received or placed; the flow has one or more steps that are executed in sequence. A step action can be to transfer a call, pronounce a text to speech (TTS) message, playback an audio file, record the call or hang up.`}</p>
    <p>{`Since we're interested in doing a transfer, we're going to create a call flow with one single step that transfers the call. The JSON object of the call flow we're going to "POST" to the API looks like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "steps": [
    {
      "action": "transfer",
      "options": {
        "destination": "31612345678"
      }
    }
  ]
}
`}</code></pre>
    <p>{`Here’s the cURL command to create this call flow through the Voice API:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`curl -X "POST" "https://voice.messagebird.com/call-flows" \\
     -H "Content-Type: application/json" \\
     -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM" \\
     -d $'{
          "steps": [
            {
              "options": {
                "destination": "31612345678"
              },
              "action": "transfer"
            }
          ]
        }'
`}</code></pre>
    <h2>{`Step 2: Assign a number to the call flow`}</h2>
    <p>{`To have the call flow executed when a number you own is called, you first need to assign that number to the call flow. The endpoint to do this is:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`POST https://voice.messagebird.com/call-flows/:call_flow_id:/numbers
`}</code></pre>
    <p>{`Here’s an example with cURL:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`curl -X "POST" "http://voice.messagebird.com/call-flows/:call_flow_id/numbers" \\
     -H "Authorization: AccessKey :your_access_key:" \\
     -d $'{
  "numbers": ["31611111111"]
}'
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`call_flow_id`}</inlineCode>{` parameter is the ID of the call flow created in step 1.`}</p>
    <p>{`The string value in the `}<inlineCode parentName="p">{`numbers`}</inlineCode>{` array is the E.164 formatted number you want to assign. Keep in mind that this must be a number you previously purchased. Buying a MessageBird number is quite easy, in`}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/articles/208011689-Buy-a-Number-"
      }}>{`this Help Center article`}</a>{` we explain to you how to do it.`}</p>
    <p>{`Awesome! After making this request, the call flow will be executed for every incoming call to the number.`}</p>
    <h2>{`Step 3: Dial the number`}</h2>
    <p>{`Dial the number you used in step 2 (:your_number). The call should be transferred to the number you defined in the destination parameter of the steps[].options object.`}</p>
    <h2>{`Step 4: Query call information`}</h2>
    <p>{`To get information about voice calls that were received, you can either query the API manually (`}<a parentName="p" {...{
        "href": "/api/voice-calling#calls"
      }}>{`List all calls`}</a>{`), or you can subscribe to call status events by using webhooks (`}<a parentName="p" {...{
        "href": "/api/voice-calling#webhooks"
      }}>{`Webhooks`}</a>{` and `}<a parentName="p" {...{
        "href": "/api/voice-calling#callbacks"
      }}>{`Handle callbacks`}</a>{`).`}</p>
    <h2>{`Keep in mind`}</h2>
    <p>{`This tutorial uses a simple static call flow; the destination of the transfer is always the same. If you want to transfer a call dynamically, for example, based on the number that is calling or the time of day, you can also configure a "fetch" call flow that queries your web service via HTTP to obtain a call flow. This approach is outlined in the ‘`}<a parentName="p" {...{
        "href": "/api/voice-calling#dynamic-call-flows"
      }}>{`Dynamic call flows’ section`}</a>{` of our Voice Calling API documentation.`}</p>
    <p><strong parentName="p">{`Nice work!`}</strong>{` 🎉`}</p>
    <p>{`You just learned how to configure a flow for transferring voice calls!`}</p>
    <h2>{`Start building!`}</h2>
    <p>{`Want to build something similar but not quite sure how to get started? Feel free to let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we'd love to help!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      