import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Docs.tsx";
import Notification from 'components/Notification';
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Send a Text To Speech Message`}</h1>
    <p>{`In this Voice Messaging API Quickstart, you'll learn how to programmatically send your first outbound text to speech message via the API.`}</p>
    <h2>{`Step 1: Set up your test environment`}</h2>
    <p>{`To start testing the Voice Messaging API using Python, you need to first set up your test environment.`}</p>
    <p>{`The following command will install MessageBird:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`pip install messagebird
`}</code></pre>
    <p>{`Now, let's create a `}<inlineCode parentName="p">{`send_voice.py`}</inlineCode>{` file in your project directory.`}</p>
    <Notification type="INFO" mdxType="Notification">
Want to learn how to set up your Python development environment? Check out the <a href="tutorials/setup-local-dev-environment-python">Python tutorial</a>
    </Notification>
    <h2>{`Step 2: Send your first text to speech message`}</h2>
    <p>{`Next, enter `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`your credentials`}</a>{` and mobile number - as both the `}<inlineCode parentName="p">{`originator`}</inlineCode>{` and `}<inlineCode parentName="p">{`recipient`}</inlineCode>{` - to the snippet below and copy it to your `}<inlineCode parentName="p">{`send_voice.py`}</inlineCode>{` file`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`client = messagebird.Client(ACCESS_KEY)
voice_message = client.voice_message_create('31612345678', 'Hi! This is your first text to speech message!', { 'language' : 'en-gb', 'voice': 'male' })
`}</code></pre>
    <Notification type="INFO" mdxType="Notification">
You can access and manage your credentials in the <a href="https://dashboard.messagebird.com/en/developers/access">MessageBird Developer Dashboard</a>.
    </Notification>
    <p>{`Now, run the following command line:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`python send_voice.py
`}</code></pre>
    <p>{`Good job! You’ve sent your first text to speech message with the Voice Messaging API!`}</p>
    <h2>{`Step 3: View the response`}</h2>
    <p>{`Now, let’s view MessageBird’s HTTP response to your API call. If the set up was correct, you'll receive a response similar to this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "id":"e8077d803532c0b5937c639b60216938",
  "href":"https://rest.messagebird.com/voicemessages/e8077d803532c0b5937c639b60216938",
  "originator":null,
  "body":"Hi! This is your first text to speech message!",
  "reference":"the-client-reference",
  "language":"en-gb",
  "voice":"male",
  "repeat":1,
  "ifMachine":"continue",
  "machineTimeout":7000,
  "scheduledDatetime":null,
  "createdDatetime":"2016-05-03T14:26:57+00:00",
  "recipients":{
    "totalCount":1,
    "totalSentCount":1,
    "totalDeliveredCount":0,
    "totalDeliveryFailedCount":0,
    "items":[
      {
        "recipient":31612345678,
        "status":"calling",
        "statusDatetime":"2016-05-03T14:26:57+00:00"
      }
    ]
  }
}
`}</code></pre>
    <p>{`If something went wrong, you’ll receive an error response from the MessageBird API. Don't worry, simply head over to `}<a parentName="p" {...{
        "href": "/docs/voice-messaging/troubleshooting"
      }}>{`Voice Messaging API Troubleshooting`}</a>{` to understand the error.`}</p>
    <p>{`Congrats! You can now programmatically send outbound text to speech messages with the Voice Messaging API using Python.`}</p>
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'Voice Messaging API Reference',
      to: '/api/voice-messaging'
    }, {
      name: 'Official MessageBird SDKs',
      to: '/libraries'
    }, {
      name: 'Voice Messaging API Troubleshooting',
      to: '/docs/voice-messaging/troubleshooting'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      