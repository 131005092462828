import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Docs.tsx";
import Notification from 'components/Notification';
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Send a Verification Token`}</h1>
    <p>{`In this Verify API Quickstart, you'll learn how to send your first verification token from your web application using the MessageBird Verify API.`}</p>
    <h2>{`Step 1: Set up your test environment`}</h2>
    <p>{`To start testing with the official Ruby SDK for MessageBird’s Verify API, you need to first set up your test environment.`}</p>
    <p>{`The following command will install MessageBird:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`gem install messagebird-rest
`}</code></pre>
    <p>{`Now, let's create a  `}<inlineCode parentName="p">{`verify.rb`}</inlineCode>{` file in the directory of your `}<inlineCode parentName="p">{`Gemfile`}</inlineCode>{` .`}</p>
    <Notification type="INFO" mdxType="Notification">
Want to learn how to set up your Ruby development environment? Check out the <a href="tutorials/setup-local-dev-environment">Ruby tutorial</a>
    </Notification>
    <h2>{`Step 2: Send your first verification token`}</h2>
    <p>{`Next, enter `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`your credentials`}</a>{` and mobile number - as both the `}<inlineCode parentName="p">{`originator`}</inlineCode>{` and `}<inlineCode parentName="p">{`recipient`}</inlineCode>{` - to the snippet below and copy it to your `}<inlineCode parentName="p">{`verify.rb`}</inlineCode>{` file.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`verify = client.verify_create(31XXXXXXXX, :originator => 'YourName')
`}</code></pre>
    <Notification type="INFO" mdxType="Notification">
You can access and manage your credentials in the <a href="https://dashboard.messagebird.com/en/developers/access">MessageBird Developer Dashboard</a>.
    </Notification>
    <p>{`Now, run the following command line:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`ruby verify.rb
`}</code></pre>
    <p>{`Good job! You’ve sent your first verification token with the official Ruby SDK for MessageBird’s Verify API.`}</p>
    <h2>{`Step 3: View the response`}</h2>
    <p>{`Now, let’s view MessageBird’s HTTP response to your API call. If the set up was correct, you'll receive a response similar to this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`#<MessageBird::Verify:0x00007f869eafe2c8
@createdDatetime=2017-05-26 20:06:07 +0000,
@href="https://rest.messagebird.com/verify/15498233759288aaf929661v21936686",
@id="15498233759288aaf929661v21936686",
@recipient=31XXXXXXXX,
@status="sent",
@validUntilDatetime=2017-05-26 20:06:37 +0000>
`}</code></pre>
    <p>{`If something went wrong, you’ll receive an error response from the MessageBird API. Don't worry, simply head over to `}<a parentName="p" {...{
        "href": "/docs/verify/troubleshooting"
      }}>{`Verify API Troubleshooting`}</a>{` to understand the error.`}</p>
    <p>{`Congrats! You can now programmatically send a verification token with the MessageBird Verify API using Ruby.`}</p>
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'Programmatically verify a token via the API',
      to: '/docs/verify/verify-a-token-ruby'
    }, {
      name: 'Verify API Use Case Tutorials and Sample Projects',
      to: '/tutorials?filter=verify'
    }, {
      name: 'Verify API Reference',
      to: '/api/verify'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      