import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import Notification from 'components/Notification';
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Send a Verification Token`}</h1>
    <p>{`In this Verify API Quickstart, you'll learn how to send your first verification token from your web application using the MessageBird Verify API.`}</p>
    <h2>{`Step 1: Set up your test environment`}</h2>
    <p>{`To start testing with the official Go SDK for MessageBird’s Verify API, you need to first set up your test environment.`}</p>
    <p>{`The following command will install MessageBird:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`go get github.com/messagebird/go-rest-api
`}</code></pre>
    <p>{`Now, let's create a `}<inlineCode parentName="p">{`main.go`}</inlineCode>{` file in your directory.`}</p>
    <Notification type="INFO" mdxType="Notification">
Want to learn how to set up your Go development environment? Check out the <a href="tutorials/setup-local-dev-environment-go">Go tutorial</a>
    </Notification>
    <h2>{`Step 2: Send your first verification token`}</h2>
    <p>{`Next, enter `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`your credentials`}</a>{` and mobile number - as both the `}<inlineCode parentName="p">{`originator`}</inlineCode>{` and `}<inlineCode parentName="p">{`recipient`}</inlineCode>{` - to the snippet below and copy it to your `}<inlineCode parentName="p">{`main.go`}</inlineCode>{` file.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`v, err := verify.Create(c, "31XXXXXXX", &verify.VerifyParams{
        Originator: "YourName",
})
`}</code></pre>
    <Notification type="INFO" mdxType="Notification">
You can access and manage your credentials in the <a href="https://dashboard.messagebird.com/en/developers/access">MessageBird Developer Dashboard</a>.
    </Notification>
    <p>{`Now, run the following command line:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`go run main.go
`}</code></pre>
    <p>{`Good job! You’ve sent your first verification token with the official Go SDK for MessageBird’s Verify API.`}</p>
    <h2>{`Step 3: View the response`}</h2>
    <p>{`Now, let’s view MessageBird’s HTTP response to your API call. If the set up was correct, you'll receive a response similar to this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`&verify.Verify{
    ID:"4e213b01155d1e35a9d9571v00162985",
    HRef:"https://rest.messagebird.com/verify/4e213b01155d1e35a9d9571v00162985",
    Reference:"",
    Status:"sent",
    Messages:map[string]string{
        "href":"https://rest.messagebird.com/messages/31bce2a1155d1f7c1db9df6b32167259",
    },
    CreatedDatetime:(*time.Time)(0xc00000c3c0),
    ValidUntilDatetime:(*time.Time)(0xc00000c400),
    Recipient:31XXXXXXXX,
}
`}</code></pre>
    <p>{`If something went wrong, you’ll receive an error response from the MessageBird API. Don't worry, simply head over to `}<a parentName="p" {...{
        "href": "/docs/verify/troubleshooting"
      }}>{`Verify API Troubleshooting`}</a>{` to understand the error.`}</p>
    <p>{`Congrats! You can now programmatically send a verification token with the MessageBird Verify API using Go.`}</p>
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'Verify a token via the API',
      to: '/quickstarts/verify/verify-a-token-go'
    }, {
      name: 'Verify Tutorials',
      to: '/tutorials?filter=verify'
    }, {
      name: 'Verify API Reference',
      to: '/api/verify'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      