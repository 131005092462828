import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/ApiRef.js";
import CodeSnippet from 'components/CodeSnippet';
import * as CODE from 'constants/live-chat-widget';
import { APIWrapper, LeftColumn, RightColumn } from 'components/ApiRef';
import Notification from 'components/Notification';
import Link from 'components/Link';
import Helmet from 'react-helmet';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Helmet mdxType="Helmet">
  <meta name="robots" content="noindex" />
    </Helmet>
    <h1>{`Omnichannel Widget Javascript API`}</h1>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <p>{`MessageBird’s Omnichannel Widget Javascript API allows you to customize the design, behaviour, and visibility of the `}<a parentName="p" {...{
            "href": "https://inbox.messagebird.com/manager/omnichannel-widget"
          }}>{`Omnichannel Widget`}</a>{` displayed on your website.`}</p>
        <p>{`You can configure parts of the Omnichannel Widget directly in your `}<a parentName="p" {...{
            "href": "https://inbox.messagebird.com/manager/omnichannel-widget"
          }}>{`Omnichannel Widget Settings in Inbox.`}</a></p>
        <h1>{`Getting started`}</h1>
        <p>{`The Omnichannel Widget Javascript API can be accessed through:`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
    <CodeSnippet code={CODE.USAGE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <p>{`To initialize the `}<a parentName="p" {...{
            "href": "https://inbox.messagebird.com/manager/omnichannel-widget"
          }}>{`Omnichannel Widget`}</a>{` , you need to first embed your unique Omnichannel Widget installation snippet in either the header of your website (for all pages) or on the header of each page you wish to display the Omnichannel Widget.`}</p>
        <p>{`You can find your installation snippet in your `}<a parentName="p" {...{
            "href": "https://inbox.messagebird.com/manager/omnichannel-widget"
          }}>{`Omnichannel Widget Settings in Inbox.`}</a></p>
        <p>{`Here is an example of the installation snippet:`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
    <CodeSnippet code={CODE.SNIPPET_CODE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <RightColumn mdxType="RightColumn">
        <h1>{`Browser Support`}</h1>
        <p>{`We want to make sure the livechat widget is available to as many end users as possible. However, in order to focus on making the widget the best experience for all consumers. We need to cut off support for certain browsers.`}</p>
        <p>{`We use the `}<a parentName="p" {...{
            "href": "https://caniuse.com/usage-table"
          }}>{`can I use usage table`}</a>{` combined with our own anonymised metrics to have set our support levels.`}</p>
        <p>{`If you have a large set of customers who fall outside of the specified support versions, please reach out and we will see what we can do to accomicate you.`}</p>
        <p>{`For Desktop browsers we support the following and later:`}</p>
        <ul>
          <li parentName="ul">{`Edge: 80+`}</li>
          <li parentName="ul">{`Firefox: 67+`}</li>
          <li parentName="ul">{`Chrome: 63+`}</li>
          <li parentName="ul">{`Safari: 10.1+`}</li>
          <li parentName="ul">{`Opera: 37+`}</li>
        </ul>
        <p>{`Please note that we do not support Internet Explorer`}</p>
        <p>{`Mobile:`}</p>
        <ul>
          <li parentName="ul">{`Android: latest Samsung + Chrome + Firefox Browsers`}</li>
          <li parentName="ul">{`IOS: IOS 7+ / Safari 7+`}</li>
        </ul>
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h1>{`API Reference`}</h1>
        <h4><inlineCode parentName="h4">{`.init(widgetId: string): Promise`}</inlineCode></h4>
        <p>{`Will load and initialize the widget, you can either pass the widgetId to the init function as a param or set the widgetId to MessagebirdChatWidgetSettings.widgetId. If these are different values it will prioritize the argument passed in over the one set on the settings object. This can be called before the widget has fully loaded.`}</p>
        <h4><inlineCode parentName="h4">{`.toggleChat()`}</inlineCode></h4>
        <p>{`Opens/closes the widget main screen (excluding the icon)`}</p>
        <h4><inlineCode parentName="h4">{`.hide()`}</inlineCode></h4>
        <p>{`Hides the whole widget (including the icon). This can be called before the widget has fully loaded.`}</p>
        <h4><inlineCode parentName="h4">{`.show()`}</inlineCode></h4>
        <p>{`Shows the whole widget (including the icon)`}</p>
        <h4><inlineCode parentName="h4">{`.element`}</inlineCode></h4>
        <p>{`Returns a DOM element of the widget`}</p>
        <h4><inlineCode parentName="h4">{`.getVisitorId()`}</inlineCode></h4>
        <p>{`Returns a Promise containing the livechat visitor ID of the user of the widget. Please note that this can only be called once the livechat has been loaded onto the page. If its a first time user who has never interacted with the widget before it will only return a value when the user interacted / opens the widget.`}</p>
        <h4><inlineCode parentName="h4">{`.setAttributes()`}</inlineCode></h4>
        <p>{`Takes an object with the keys and values you would like to have on your visitors contact profile in inbox. The values must only be primitive values, ie a string, number or a boolean.`}</p>
        <p>{`These values will only be attached to the visitor when the visitor sends a message from the livechat widget. It will also only attach the values to current visitor in the livechat.`}</p>
        <CodeSnippet code={CODE.SET_ATTRIBUTES} mdxType="CodeSnippet" />
        <h4><inlineCode parentName="h4">{`.identify(uid: string, attributes?: Record<string, string | boolean | number>): Promise`}</inlineCode></h4>
        <p>{`Takes an unique identifier as an argument. It will then log in the widget user with the identifier passed as an its user returning a promise and resolving when complete.`}</p>
        <CodeSnippet code={CODE.IDENTIFY_WITHOUT_ATTRIBUTES} mdxType="CodeSnippet" />
        <p>{`identify can also take a second argument of attributes which is the exact same as the setAttributes methods.`}</p>
        <CodeSnippet code={CODE.IDENTIFY} mdxType="CodeSnippet" />
        <h4><inlineCode parentName="h4">{`.logout(): Promise`}</inlineCode></h4>
        <p>{`Logout will reset the widget, clearing all locally stored data and creates a fresh new anonymous visitor. the method will return a Promise and resolve when its complete.`}</p>
        <p>{`This is useful if your using the identify feature and you want to make sure the user in widget is logged out when the user on your site it.`}</p>
        <CodeSnippet code={CODE.LOGOUT} mdxType="CodeSnippet" />
        <h4><inlineCode parentName="h4">{`.shutdown(): Promise`}</inlineCode></h4>
        <p>{`Like logout, calling this method will reset the widget, clearing all locally stored data and creating a fresh new anonymous visitor. This method will also hide / remove the widget from the page.`}</p>
        <CodeSnippet code={CODE.LOGOUT} mdxType="CodeSnippet" />
        <h4><inlineCode parentName="h4">{`startConversation(text: string)`}</inlineCode></h4>
        <p>{`startConversation will open the widget in the chat view and send a message from the visitor to the widget. This would be useful if you want to start certain flows programmatically for example via a button on your page.`}</p>
        <CodeSnippet code={CODE.START_CONVERSATION_EXAMPLE} mdxType="CodeSnippet" />
        <h4><inlineCode parentName="h4">{`setLanguage(language: string)`}</inlineCode></h4>
        <p>{`setLanguage will force the widget to change to the language passed in as an argument. The widget expects the languages in the ISO 639-1 Code format. It will respect the rules set up in the settings page, for example, if the language has been disabled the Widget will not update to that language.`}</p>
        <CodeSnippet code={CODE.SET_LANGUAGE_EXAMPLE} mdxType="CodeSnippet" />
        <h4><inlineCode parentName="h4">{`.on(event, callback)`}</inlineCode></h4>
        <p>{`Pass a callback function to a set of preset events. This can be called before the widget has fully loaded.`}</p>
        <h5>{`event 'ready'`}</h5>
        <p>{`This will be called once the widget has loaded. Nothing is passed to the callback.`}</p>
        <CodeSnippet code={CODE.ON_READY_EXAMPLE} mdxType="CodeSnippet" />
        <h5>{`event 'visitorCreated'`}</h5>
        <p>{`This will return the visitorID of the user. If its a first time user who has never interacted with the widget before it will only return a value when the user interacted / opens the widget.`}</p>
        <CodeSnippet code={CODE.ON_VISITOR_CREATED_EXAMPLE} mdxType="CodeSnippet" />
        <h5>{`event 'toggle'`}</h5>
        <p>{`This will fire each time the widget is opened or closed, the callback will provide the state of the widget ie, if the widget is closed and the user clicks to open the widget the argument via the callback will be true.`}</p>
        <CodeSnippet code={CODE.ON_TOGGLE_EXAMPLE} mdxType="CodeSnippet" />
      </LeftColumn>
    </APIWrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      