import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Setting SMS notifications with MessageBird`}</h1>
    <h3>{`⏱ 15 min build time      ||      `}<a parentName="h3" {...{
        "href": "https://github.com/messagebirdguides/notifications-guide-go"
      }}>{`Download the Code`}</a></h3>
    <h2>{`Why build SMS order notifications?`}</h2>
    <p>{`In this MessageBird Developer Tutorial we'll show you how to keep your customers up to date with this easy-to-build runnable order notification application powered by the `}<a parentName="p" {...{
        "href": "/api/sms-messaging"
      }}>{`MessageBird SMS Messaging API`}</a>{`.
Have you ever ordered home delivery to find yourself wondering whether your order was received correctly and how long it will take to arrive? Some experiences are seamless and others... not so much.`}</p>
    <p>{`For on-demand industries such as food delivery, ride-sharing and logistics, excellent customer service during the ordering process is essential. One easy way to stand out from the crowd is providing proactive communication to keep your customers in the loop about the status of their orders. Regardless of whether your customer is waiting for a package delivery or growing hangry (a combination of hungry and angry) awaiting their food delivery, sending timely SMS order notifications is a great strategy to create a seamless user experience.`}</p>
    <p>{`The `}<a parentName="p" {...{
        "href": "/api/sms-messaging"
      }}>{`MessageBird SMS Messaging API`}</a>{` provides an easy way to fully automate and integrate a notifications application into your order handling software. Busy employees can trigger the notifications application with the push of a single button. No more confused hangry customers and great user experience, just like that!`}</p>
    <h2>{`Getting started`}</h2>
    <p>{`The application is a prototype order management system build in Go for our fictitious food delivery company, `}<em parentName="p">{`Birdie NomNom Foods`}</em>{`.`}</p>
    <p>{`Birdie NomNom Foods have set up the following workflow:`}</p>
    <ul>
      <li parentName="ul">{`New incoming orders are in a `}<strong parentName="li">{`pending`}</strong>{` state.`}</li>
      <li parentName="ul">{`Once the kitchen starts preparing an order, it moves to the `}<strong parentName="li">{`confirmed`}</strong>{` state. A message is sent to the customer to inform them about this.`}</li>
      <li parentName="ul">{`When the food is made and handed over to the delivery driver, staff marks the order as `}<strong parentName="li">{`delivered`}</strong>{`. A message is sent to the customer to let them know it will arrive briefly.`}</li>
      <li parentName="ul">{`If preparation takes longer than expected, it can be moved to a `}<em parentName="li">{`delayed_`}</em>{`state. A message is sent to the customer asking them to hang on just a little while longer.`}</li>
    </ul>
    <p>{`Thanks to this workflow, Birdie NomNom Foods saves time, money, and energy that would otherwise be spent answering `}<em parentName="p">{`"Where's my order?"`}</em>{` calls.`}</p>
    <p><strong parentName="p">{`Pro-tip:`}</strong>{` Follow this tutorial to build the whole application from scratch or, if you want to see it in action right away, you can download, clone or fork the sample application from the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/notifications-guide-go"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`.`}</p>
    <p>{`We'll be building our single-page web application with:`}</p>
    <ul>
      <li parentName="ul">{`The latest version of `}<a parentName="li" {...{
          "href": "https://golang.org"
        }}>{`Go`}</a></li>
      <li parentName="ul">{`The `}<a parentName="li" {...{
          "href": "https://github.com/messagebird/go-rest-api"
        }}>{`MessageBird's REST API package for Go`}</a></li>
    </ul>
    <h3>{`Structure of your application`}</h3>
    <p>{`We'll need the following components for our application to be viable:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`A data source`}</strong>{`: This should be a database or a REST endpoint containing information about our customers and their orders. For this tutorial, we'll be mocking up data already imported from an external data source.`}</li>
      <li parentName="ul"><strong parentName="li">{`A web interface to manage orders`}</strong>{`: The web interface will display information on customer orders, allow us to change the order status, and send SMS messages to customers.`}</li>
      <li parentName="ul"><strong parentName="li">{`Route handler that contains message sending logic`}</strong>{`: This handler would contain logic that:`}
        <ol parentName="li">
          <li parentName="ol">{`Checks our order against the orders database.`}</li>
          <li parentName="ol">{`Populates and makes a `}<inlineCode parentName="li">{`NewMessage()`}</inlineCode>{` request with the appropriate information.`}</li>
        </ol>
      </li>
    </ul>
    <h3>{`Project setup`}</h3>
    <p>{`Let's start by creating a folder for your application. In this folder, create the
following subfolders:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`views`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`views/layouts`}</inlineCode></li>
    </ul>
    <p>{`Because we're dealing with data that we'd like to display as tables, we want to be able to serve CSS to make our tables readable. Create a "static" subfolder to contain our CSS and future static assets:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`static`}</inlineCode></li>
    </ul>
    <p>{`We'll use the following packages from the Go standard library to build our routes and views:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`net/http`}</inlineCode>{`: A HTTP package for building our routes and a simple http server.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`html/template`}</inlineCode>{`: A HTML template library for building views.`}</li>
    </ul>
    <p>{`From the MessageBird Go REST API package, we'll import the following packages:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`github.com/messagebird/go-rest-api`}</inlineCode>{`: The MessageBird core client package.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`github.com/messagebird/go-rest-api/sms`}</inlineCode>{`: The MessageBird SMS messaging package.`}</li>
    </ul>
    <h3>{`Create your API Key 🔑`}</h3>
    <p>{`To start making API calls, we need to generate an access key. MessageBird provides keys in `}<em parentName="p">{`live`}</em>{` and `}<em parentName="p">{`test`}</em>{` modes. For this tutorial you’ll need to use a live key; otherwise, you won’t be able to test the complete flow. You can read more about `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/articles/360000670709-What-is-the-difference-between-a-live-key-and-a-test-key-"
      }}>{`the difference between test and live API keys`}</a>{` in our Help Center.`}</p>
    <p>{`Let's create your live API access key. First, go to the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/user/index"
      }}>{`MessageBird Dashboard`}</a>{`; if you have already created an API key it will be shown right there. If you don’t see any key on the Dashboard or if you're unsure whether this key is in `}<em parentName="p">{`live`}</em>{` mode, go to the `}<em parentName="p">{`Developers`}</em>{` section in the MessageBird Dashboard and open the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`API access (REST) tab`}</a>{`. There you can create new API keys and manage your existing ones.`}</p>
    <p>{`If you are having any issues creating your API key, please reach out to `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we’ll make sure to help you out.`}</p>
    <p><strong parentName="p">{`Pro-tip:`}</strong>{` To keep our demonstration code simple, we’ll be saving our API key in `}<inlineCode parentName="p">{`main.go`}</inlineCode>{`. However, hardcoding your credentials in the code is a risky practice that should never be used in production applications. A better method, also recommended by the `}<a parentName="p" {...{
        "href": "https://12factor.net/"
      }}>{`Twelve-Factor App Definition`}</a>{`, is to use environment variables. You can use open source packages such as `}<a parentName="p" {...{
        "href": "https://github.com/joho/godotenv"
      }}>{`GoDotEnv`}</a>{` to read your API key from a `}<inlineCode parentName="p">{`.env`}</inlineCode>{` file into your Go application. Your `}<inlineCode parentName="p">{`.env`}</inlineCode>{` file should be written as follows:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`MESSAGEBIRD_API_KEY=YOUR-API-KEY
`}</code></pre>
    <p>{`To use `}<a parentName="p" {...{
        "href": "https://github.com/joho/godotenv"
      }}>{`GoDotEnv`}</a>{` in your application, install it by running:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`go get -u github.com/joho/godotenv
`}</code></pre>
    <p>{`Then, import it in your application:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`import (
 // Other imported packages
 "os"

 "github.com/joho/godotenv"
)

func main(){
 // GoDotEnv loads any ".env" file located in the same directory as main.go
 err := godotenv.Load()
 if err != nil {
   log.Fatal("Error loading .env file")
 }

 // Store the value for the key "MESSAGEBIRD_API_KEY" in the loaded '.env' file.
 apikey := os.Getenv("MESSAGEBIRD_API_KEY")

 // The rest of your application ...
}
`}</code></pre>
    <h2>{`Initialize the MessageBird client`}</h2>
    <p>{`Let's now install the `}<a parentName="p" {...{
        "href": "https://github.com/messagebird/go-rest-api"
      }}>{`MessageBird's REST API package for Go`}</a>{` by running:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`go get -u github.com/messagebird/go-rest-api
`}</code></pre>
    <p>{`In your project folder, create a `}<inlineCode parentName="p">{`main.go`}</inlineCode>{` file, and write the following code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`package main

import (
 "github.com/messagebird/go-rest-api"
)

// Client ...
// We're initializing "Client" as a global variable so that we can access its methods in our handlers.
var Client *messagebird.Client

func main(){
 Client = messagebird.New("<enter-your-api-key>")
}
`}</code></pre>
    <h2>{`Setting up your data source`}</h2>
    <p>{`To keep this tutorial simple, we'll be using a placeholder data source instead of an actual remote data source.`}</p>
    <p>{`Just above `}<inlineCode parentName="p">{`main()`}</inlineCode>{`, add the following code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`// CurrentOrders contains a list of Orders.
// This is defined globally so that HTTP handlers can access them easily.
var CurrentOrders []Order
`}</code></pre>
    <p>{`Then, add the following code after the body of `}<inlineCode parentName="p">{`main()`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`// Order structures our individual orders.
type Order struct{
   ID    string
   Name  string
   Phone string
   Items []string
   // Possible values for Status:
   // "pending", "delayed", "confirmed", "delivered"
   Status string
}

func initDB() {
   CurrentOrders = []Order{
       {"c2972b5b4eef349fb1e5cc3e3150a2b6", "Hannah Hungry", "+319876543210", []string{"1 x Hipster Burger", "Fries"}, "pending"},
       {"1b992e39dc55f0c79dbe613b3ad02f29", "Mike Madeater", "+319876543211", []string{"1 x Chef Special Mozzarella Pizza"}, "delayed"},
       {"81dc9bdb52d04dc20036dbd8313ed055", "Don Cheetos", "+319876543212", []string{"1 x Awesome Cheese Platter"}, "confirmed"},
       {"81dc9bdb52d04dc20036dbd8313ed055", "Ace Adventures", "+319876543213", []string{"1 x Variegated Salami Combo Box"}, "delivered"},
   }
}
`}</code></pre>
    <p>{`Here, we've created a placeholder database in the form of a list of orders, each containing an "ID", "Name", "Phone", "Items", and "Status" field.`}</p>
    <h2>{`Dealing with our routes`}</h2>
    <p>{`Next, we'll define our routes so that we can quickly test if the customer information we're getting from our data source can be displayed correctly.`}</p>
    <h3>{`Define routes`}</h3>
    <p>{`We want three routes:`}</p>
    <ul>
      <li parentName="ul">{`First route displays and updates all current orders and their status.`}</li>
      <li parentName="ul">{`Second route sends notifications to the respective customer for each order.`}</li>
      <li parentName="ul">{`Third route is for serving our CSS files, and any other static assets we may eventually need.`}</li>
    </ul>
    <p>{`Modify `}<inlineCode parentName="p">{`main.go`}</inlineCode>{` to look like the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`func main() {
   initDB()
   Client = messagebird.New("<enter-your-api-key>")

   // Routes
   http.HandleFunc("/", orderPage)
   http.HandleFunc("/notifyCustomer", orderNotify)

   // Define route for static resources
   staticDir := http.FileServer(http.Dir("static"))
   http.Handle("/static/", http.StripPrefix("/static/", staticDir))

   // Serve
   port := ":8080"
   log.Println("Serving application on", port)
   err := http.ListenAndServe(port, nil)
   if err != nil {
       log.Println(err)
   }
}
`}</code></pre>
    <h3>{`Writing our templates`}</h3>
    <p>{`We're going to write two templates:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`views/layouts/default.gohtml`}</inlineCode>{`: This is our base template; it’s useful when you need to expand your project quickly.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`views/orders.gohtml`}</inlineCode>{`: This is the template that displays our orders and actions available for each order.`}</li>
    </ul>
    <h4><inlineCode parentName="h4">{`default.gohtml`}</inlineCode></h4>
    <p>{`For `}<inlineCode parentName="p">{`default.gohtml`}</inlineCode>{`, write the following code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`{{ define "default" }}
<!DOCTYPE html>
 <head>
   <meta charset="utf-8">
   <meta http-equiv="X-UA-Compatible" content="IE=edge">
   <title>MessageBird Verify Example</title>
   <meta name="description" content="">
   <meta name="viewport" content="width=device-width, initial-scale=1">
   <link rel="stylesheet" href="/static/main.css" type="text/css"/>
 </head>
 <body>
   <main>
   <h1>MessageBird Notification Example</h1>
   {{ template "yield" . }}
   </main>
 </body>
</html>
{{ end }}
`}</code></pre>
    <p>{`Keep in mind that we've defined a stylesheet resource at `}<inlineCode parentName="p">{`/static/main.css`}</inlineCode>{`. Let's create that stylesheet now. Make a `}<inlineCode parentName="p">{`main.css`}</inlineCode>{` file in your `}<inlineCode parentName="p">{`static`}</inlineCode>{` subfolder, and write in it the following CSS:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`/* This makes our tables a bit prettier */
table {
 border-collapse:collapse;
 width:90%;
 overflow-x:auto;
}
thead {
 text-align:left;
}
table td, table th {
 border-bottom: 1px solid black;
 padding:5px;
}
`}</code></pre>
    <h4><inlineCode parentName="h4">{`orders.gohtml`}</inlineCode></h4>
    <p><inlineCode parentName="p">{`orders.gohtml`}</inlineCode>{` is more complicated. Let's start out with a basic template:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`{{ define "yield" }}
{{ . }}
{{ end }}
`}</code></pre>
    <p>{`We know the shape of our data: we're looking at a list of structs of type "Order". Each order has "ID", "Name", "Phone", "Items", and "Status" field. We're using the "ID" field to make sure that we're addressing a unique order, but we don't need to display it.`}</p>
    <p>{`To get to each individual field in each order, we can write our template in `}<inlineCode parentName="p">{`orders.gohtml`}</inlineCode>{` as follows:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`{{ define "yield" }}
<h2>List of current orders</h2>
<table>
   <thead>
       <th>Name</th>
       <th>Phone</th>
       <th>Items</th>
       <th>Status</th>
       <th>Action</th>
   </thead>
   <tbody>
       {{ range .}}
       <tr>
           <td>{{ .Name }}</td>
           <td>{{ .Phone }}</td>
           <td>
           <ul>
           {{ range .Items }}
           <li>{{ . }}</li>
           {{ end }}
           </ul>
           <td>{{ .Status }}</td>
           <td>
           <button>Actions</button>
           </td>
       </tr>
       {{ end }}
   </tbody>
</table>
{{ end }}
`}</code></pre>
    <p><inlineCode parentName="p">{`{{ range . }}`}</inlineCode>{` iterates through the list of structs in `}<inlineCode parentName="p">{`CurrentOrders`}</inlineCode>{` that we'll pass to the template when calling `}<inlineCode parentName="p">{`ExecuteTemplate()`}</inlineCode>{`, and produces a table with our order information filled in.`}</p>
    <p>{`Finally, we need a way to make "POST" requests to our `}<inlineCode parentName="p">{`/notifyCustomer`}</inlineCode>{` route from the `}<inlineCode parentName="p">{`orders.gohtml`}</inlineCode>{` template. We need two `}<inlineCode parentName="p">{`<form>`}</inlineCode>{` fields:`}</p>
    <ul>
      <li parentName="ul">{`One for updating the status of our orders`}</li>
      <li parentName="ul">{`Another for sending notifications to customers.`}</li>
    </ul>
    <p>{`Modify `}<inlineCode parentName="p">{`orders.gohtml`}</inlineCode>{` to look like the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`{{ define "yield" }}
<h2>List of current orders</h2>
<table>
 <thead>
   <th>Name</th>
   <th>Phone</th>
   <th>Items</th>
   <th>Status</th>
   <th>Action</th>
 </thead>
<tbody>
 {{ range .}}
 <tr>
   <td>{{ .Name }}</td>
   <td>{{ .Phone }}</td>
   <td>
     <ul>
     {{ range .Items }}
     <li>{{ . }}</li>
     {{ end }}
     </ul>
   <td>{{ .Status }}</td>
   <td>
     <form action="/" method="post">
     <label for="orderStatus">Select order status:</label>
     <select name="orderStatus">
     <option value="" selected="selected">--Order status--</option>
     <option value="{{.ID}}_pending">Pending</option>
     <option value="{{.ID}}_delayed">Delayed</option>
     <option value="{{.ID}}_confirmed">Confirmed</option>
     <option value="{{.ID}}_delivered">Delivered</option>
     </select>
     <button type="submit">Update</button>
     </form>
     <form action="/notifyCustomer" method="post">
     <button type="submit" name="sendMessageTo" value="{{.ID}}">Notify Customer</button>
     </form>
   </td>
 </tr>
 {{ end }}
</tbody>
</table>
{{ end }}
`}</code></pre>
    <h3>{`Writing our handlers`}</h3>
    <p>{`Now, let’s write our `}<inlineCode parentName="p">{`orderPage`}</inlineCode>{` and `}<inlineCode parentName="p">{`orderNotify`}</inlineCode>{` handlers.`}</p>
    <p>{`To keep our code `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Don%27t_repeat_yourself"
      }}>{`DRY`}</a>{`, we'll write a helper function that does a few templating tasks for us. At the bottom of `}<inlineCode parentName="p">{`main.go`}</inlineCode>{`, add the following code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`// RenderDefaultTemplate ...
func RenderDefaultTemplate(w http.ResponseWriter, thisView string, data interface{}) {
   renderthis := []string{thisView, "views/layouts/default.gohtml"}
   t, err := template.ParseFiles(renderthis...)
   if err != nil {
       log.Fatal(err)
   }
   err = t.ExecuteTemplate(w, "default", data)
   if err != nil {
       log.Fatal(err)
   }
}
`}</code></pre>
    <h4><inlineCode parentName="h4">{`orderPage`}</inlineCode>{` handler`}</h4>
    <p>{`Add the following code just above `}<inlineCode parentName="p">{`main()`}</inlineCode>{` in `}<inlineCode parentName="p">{`main.go`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`func orderPage(w http.ResponseWriter, r *http.Request){
   RenderDefaultTemplate(w, "views/orders.gohtml", CurrentOrders)
}
`}</code></pre>
    <p>{`This renders and executes our `}<inlineCode parentName="p">{`orders.gohtml`}</inlineCode>{` and `}<inlineCode parentName="p">{`default.gohtml`}</inlineCode>{` templates, and passes the `}<inlineCode parentName="p">{`CurrentOrders`}</inlineCode>{` object to the resulting view.`}</p>
    <p>{`To add the ability to update our orders, we have to check for a "POST" request submitted to the this (`}<inlineCode parentName="p">{`"/"`}</inlineCode>{`) route. Rewrite `}<inlineCode parentName="p">{`orderPage()`}</inlineCode>{` so it looks like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`func orderPage(w http.ResponseWriter, r *http.Request) {
   if r.Method == "POST" {
       r.ParseForm()
       if len(r.Form) == 0 {
           log.Println("Empty form.")
       }
       s := strings.Split(r.FormValue("orderStatus"), "_")
       for i, v := range CurrentOrders {
           if v.ID == s[0] {
               CurrentOrders[i].Status = s[1]
           }
       }
   }
   RenderDefaultTemplate(w, "views/orders.gohtml", CurrentOrders)
}
`}</code></pre>
    <p>{`Here, we parse the submitted form for values if we detect that a "POST" method is sent to the `}<inlineCode parentName="p">{`orderPage`}</inlineCode>{` handler. This gives us the "ID" of the order being updated, and the resulting "Status" the order should have.`}</p>
    <p>{`We match our form's order ID to its corresponding order "ID" in the `}<inlineCode parentName="p">{`CurrentOrders`}</inlineCode>{` list, and update the "Status" field for that order "ID". Once that's done, the handler continues to call `}<inlineCode parentName="p">{`RenderDefaultTemplate()`}</inlineCode>{`, rendering the updated information.`}</p>
    <h4><inlineCode parentName="h4">{`orderNotify`}</inlineCode>{` handler`}</h4>
    <p>{`In our `}<inlineCode parentName="p">{`orderNotify`}</inlineCode>{` handler, we need to:`}</p>
    <ol>
      <li parentName="ol">{`Get the order "ID" that we want to send a notification for.`}</li>
      <li parentName="ol">{`Get the order "Status".`}</li>
      <li parentName="ol">{`Get the phone number of the customer for that order "ID".`}</li>
      <li parentName="ol">{`Send a message to that phone number.`}</li>
    </ol>
    <p>{`First, we'll need to add the MessageBird `}<inlineCode parentName="p">{`sms`}</inlineCode>{` package to our `}<inlineCode parentName="p">{`import`}</inlineCode>{` statement:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`import (
 "html/template"
 "log"
 "net/http"
 "strings"

 "github.com/messagebird/go-rest-api"
 "github.com/messagebird/go-rest-api/sms"
)
`}</code></pre>
    <p>{`Under our `}<inlineCode parentName="p">{`orderPage()`}</inlineCode>{` handler, add the following code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`func orderNotify(w http.ResponseWriter, r *http.Request){
   err := r.ParseForm()
   if err != nil {
       log.Println(err)
   }
   s := r.FormValue("sendMessageTo")

   for _, v := range CurrentOrders {
       if v.ID == s {
           msgToSend := isOrderConfirmed(v.Status, v.Name)
           _, err := sms.Create(Client, "NomNom", []string{v.Phone}, msgToSend, nil)
           if err != nil {
               log.Println(err)
           }
       }
   }
   RenderDefaultTemplate(w, "views/orders.gohtml", CurrentOrders)
}
`}</code></pre>
    <p>{`So, here we parse our form for the "sendMessageTo" field that contains the order "ID" we need to send a notification for. Then we iterate through our `}<inlineCode parentName="p">{`CurrentOrders`}</inlineCode>{` object, and find the order that matches the "ID" sent through our form. Once we've found the relevant order, we can use its fields to construct a message to send to our customer.`}</p>
    <p>{`Notice that we're calling a `}<inlineCode parentName="p">{`isOrderConfirmed()`}</inlineCode>{` helper function to construct our `}<inlineCode parentName="p">{`msgToSend`}</inlineCode>{` parameter. Add the code for `}<inlineCode parentName="p">{`isOrderConfirmed()`}</inlineCode>{` just under the body of `}<inlineCode parentName="p">{`orderNotify()`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`func isOrderConfirmed(orderStatus string, recipientName string) string {
   switch orderStatus {
   case "pending":
       return "Hello, " + recipientName + ", thanks for ordering at OmNomNom Foods! We're still working on your order. Please be patient with us!"
   case "confirmed":
       return "Hello, " + recipientName + ", thanks for ordering at OmNomNom Foods! We are now preparing your food with love and fresh ingredients and will keep you updated."
   case "delayed":
       return "Hello, " + recipientName + ", sometimes good things take time! Unfortunately your order is slightly delayed but will be delivered as soon as possible."
   case "delivered":
       return "Hello, " + recipientName + ", you can start setting the table! Our driver is on their way with your order! Bon appetit!"
   default:
       return "We can't find your order! Please call our customer support for assistance."
   }
}
`}</code></pre>
    <p>{`Our `}<inlineCode parentName="p">{`isOrderConfirmed()`}</inlineCode>{` helper matches the status of the order to a list of predefined messages, and returns a message string (complete with the customer's name). We assign this to the `}<inlineCode parentName="p">{`msgToSend`}</inlineCode>{` variable for use when triggering the SMS notification.`}</p>
    <p>{`Finally, we trigger an SMS notification by making a `}<inlineCode parentName="p">{`sms.Create()`}</inlineCode>{` call to the MessageBird servers with the following line in `}<inlineCode parentName="p">{`orderNotify()`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`_, err := sms.Create(Client, "NomNom", []string{v.Phone}, msgToSend, nil)
`}</code></pre>
    <h2>{`Testing`}</h2>
    <p>{`You’re done! We now have a fully working application, but we won't be able to test our application because it's still using dummy data taken from our `}<inlineCode parentName="p">{`CurrentOrders`}</inlineCode>{` object. Plus, if you're using a test API key, our code in `}<inlineCode parentName="p">{`main.go`}</inlineCode>{` doesn't give us visible feedback for each `}<inlineCode parentName="p">{`sms.Create()`}</inlineCode>{` call.`}</p>
    <p>{`To set up a development copy of the code to test if our implementation of `}<inlineCode parentName="p">{`sms.Create()`}</inlineCode>{` works, we can modify a few things:`}</p>
    <ol>
      <li parentName="ol">{`Change the "Phone" field in your `}<inlineCode parentName="li">{`CurrentOrders`}</inlineCode>{` object to a test phone number that can receive messages. This phone number should also be saved in your MessageBird Dashboard as a contact.`}</li>
      <li parentName="ol">{`Modify our `}<inlineCode parentName="li">{`sms.Create()`}</inlineCode>{` call in `}<inlineCode parentName="li">{`orderNotify()`}</inlineCode>{` so that we log the message object returned:`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`msg, err := sms.Create(Client, "NomNom", []string{v.Phone}, msgToSend, nil)
   if err != nil {
       log.Println(err)
   } else {
       log.Println(msg)
   }
`}</code></pre>
    <p>{`Now, a successful `}<inlineCode parentName="p">{`sms.Create()`}</inlineCode>{` call would log a message object that looks like the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`&{ae554eccfa9047d9aa7cbe261f65d80b https://rest.messagebird.com/messages/ae554eccfa9047d9aa7cbe261f65d80b mt sms NomNom Hello, Don Cheetos,thanks for ordering at OmNomNom Foods! We are now preparing your food with love and fresh ingredients and will keep you updated.  <nil> 10 map[] plain 1  <nil> +0000 +0000 {1 1 0 0 [{6596963426 sent 2018-08-19 17:58:20 +0000 +0000}]} []}
`}</code></pre>
    <p>{`Ready! You can begin to test your application! Run your application in the terminal:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`go run main.go
`}</code></pre>
    <ol>
      <li parentName="ol">{`Point your browser at http://localhost:8080/ to see the table of orders.`}</li>
      <li parentName="ol">{`For any order displayed, select a new order status and click "Update" to update the order status. The page should display the updated order status.`}</li>
      <li parentName="ol">{`For any order displayed, click on "Send Notification" to send an SMS notification to the customer. You should see a message object logged in the terminal each time you send a message. Tada!`}</li>
    </ol>
    <p>{`Awesome! You can now use the flow, code snippets and UI examples from this tutorial as an inspiration to build your own SMS Notifications system. Don't forget to download the code from the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/notifications-guide-go"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`.`}</p>
    <p><strong parentName="p">{`Nice work!`}</strong>{` 🎉`}</p>
    <p>{`You now have a running SMS Notifications application with MessageBird using Go!`}</p>
    <h2>{`Start building!`}</h2>
    <p>{`Want to build something similar but not quite sure how to get started? Please feel free to let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`;  we'd love to help!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      