export const ENDPOINT = {
  json: 'https://email.messagebird.com/v1',
};

export const MESSAGING_API_ENDPOINT = {
  json: 'https://messaging.messagebird.com/v1',
};

export const ENDPOINTS_SUMMARY = {
  json: `
    POST /v1/channels
    GET /v1/channels
    GET /v1/channels/{id}
    PATCH /v1/channels/{id}
    DELETE /v1/channels/{id}
    POST /v1/send
  `,
};

export const ERROR_EXAMPLE = {
  json: `
{
  "errors": [
    {
      "code": 1001,
      "description": "You are requesting with an invalid credential.",
      "parameter": null
    }
  ]
}`,
};

export const CHANNEL_OBJ = {
  json: `
{
		"id":"0fc77eed542d4397b8eac6725b626472",
		"name":"My Domain",
		"domainName":"mail.mydomain.nl",
		"dkim":{
				"public":"MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDTu/keSOLIZfDAcccfqwXg9QSLrwNfyqGxt9nsdO60ZQjr0k3/li1CaPgOowAtLg1b4jbhPeIED3/TzxJN7o6kD7zR/eintEQPskPsb89TCeXAzkfOebTE6AWacEqI4IPHljGXhj3NMStMvfrtdbV4o8to5EWrIB5YcyyZE52QxQIDAQAB",
				"selector":"scph0319._domainkey.mail.mydomain.nl",
				"headers":"from:to:subject:date",
				"signingDomain":"mail.mydomain.nl",
				"record":"k=rsa; h=sha256; p=MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDTu/keSOLIZfDAcccfqwXg9QSLrwNfyqGxt9nsdO60ZQjr0k3/li1CaPgOowAtLg1b4jbhPeIED3/TzxJN7o6kD7zR/eintEQPskPsb89TCeXAzkfOebTE6AWacEqI4IPHljGXhj3NMStMvfrtdbV4o8to5EWrIB5YcyyZE52QxQIDAQAB"
		},
		"status":"action_required",
		"createdDatetime":"2019-03-29T15:47:02Z",
		"updatedDatetime":"2019-04-02T14:01:34Z"
},
`,
};

export const MESSAGE_OBJ = {
  json: `
{
  "id": "884740337d0d4ceb97883e8adf5d7b71",
  "to": [
		{
      "name": "John Doe",
      "address": "john.doe@example.org",
      "variables": {
        "firstname": "John",
        "orderNo": "ABC-321"
      }
    },
  ],
  "cc": [
		{
      "name": "Michael Doe",
      "address": "michael.doe@example.org",    
    },
  ],
  "from": {
    "name": "MessageBird",
    "address": "noreply@email.customer.com"
  },
  "content": {
			"html":"<b>Hello {{firstname}}!</b><br><br>Your order <b>{{orderNo}}</b> has been accepted.",
			"text":"Hello {{firstName}}! Your order {{orderNo}} has been accepted."
	},
  "replyTo": "support@customer.com",
  "headers": {
    "X-MessageBird-Greeting": "Hello {{firstname}}!"
  },
  "createdDatetime": "2019-03-26T12:20:48Z",
  "reportUrl": "http://customer.com/webhooks/inbound-email"
}
`,
};

export const CREATECHANNEL_DEF = {
  bash: `POST ${ENDPOINT.json}/channels`,
};

export const CREATECHANNEL_REQ = {
  curl: `
curl -X POST ${ENDPOINT.json}/channels
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0Qy'
  -H 'Content-Type:application/json'
  -d '{
    "name": "My Domain",
    "domainName": "mydomain.nl"
  }'
`,
};

export const CREATECHANNEL_RESP = {
  json: `
{
	"id":"62722892655145ef8f6a9583ba4b2a05",
	"name":"My Domain",
	"domainName":"mydomain.nl",
	"dkim":{
			"public":"MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDO8iO0m1cE1TSnrIkkYh2qUBgUj/nKtBs1pz/XSlvx3w2w+v1f0ZF657FISXVW8xqaDRrT49O/JRw+tkz7AY92G4zLySup5gEV9c9R3q/U1sewQ9slEyAkPlMz2Prw/w29zGD4PZPGcNc6qZIlnbHTrWQnm+Pm+7Lh8g5Qh3abpQIDAQAB",
			"selector":"scph0319._domainkey.mail.mydomain.nl",
			"headers":"from:to:subject:date",
			"signingDomain":"mydomain.nl",
			"record":"k=rsa; h=sha256; p=MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDO8iO0m1cE1TSnrIkkYh2qUBgUj/nKtBs1pz/XSlvx3w2w+v1f0ZF657FISXVW8xqaDRrT49O/JRw+tkz7AY92G4zLySup5gEV9c9R3q/U1sewQ9slEyAkPlMz2Prw/w29zGD4PZPGcNc6qZIlnbHTrWQnm+Pm+7Lh8g5Qh3abpQIDAQAB"
	},
	"status":"action_required",
	"createdDatetime":"2019-04-05T13:34:49Z",
	"updatedDatetime":"2019-04-05T13:34:49Z"
}
`,
};

export const SEND_DEF = {
  bash: `POST ${ENDPOINT.json}/send`,
};

export const SEND_REQ_HTML_SINGLE = {
  curl: `
curl -X POST ${ENDPOINT.json}/send
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM'
  -H 'Content-Type:application/json'
  -d '{
  "subject":"A great message!",
  "to":[
    {
      "name": "Customer",
      "address": "one@happycustomer.com"
    }
   ],
   "from": {
     "name": "You",
     "address": "email@yourdomain.com"
   },
   "content":{
     "html": "Hello! This is a test message. <img src=\"cid:my-inline-image\"> <b>Best from Amsterdam</b>"
   },
   "inlineImages": [
    {
      "ID": "6505d283-7a8d-493c-9ea0-6d38c6bd495a"
      "contentId": "my-inline-image"
    }
   ],
   "attachments": [
     {
        "ID": "ebf6acee-d7ae-4375-b726-e247318d3377"
     }
   ],   
   "reportUrl":"http://mywebhooksreceiver.com/"
  }'
`,
};

export const SEND_REQ_TEMPLATE = {
  curl: `
curl -X POST ${ENDPOINT.json}/send
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM'
  -H 'Content-Type:application/json'
  -d '{
  "subject":"A great message!",
  "to":[
    {
      "name": "Customer 1",
      "address": "one@happycustomer.com",
      "variables": {
        "location": "Amsterdam",
        "visit_us": "https://yourdomain.com/landingpage?utm_source=email"
      }
    },
    {
      "name": "Customer 2",
      "address": "two@happycustomer.com",
      "variables": {
        "location": "San Francisco",
        "visit_us": "https://yourdomain.com/landingpage?utm_source=email"
      }
    },
   ],
   "from": {
     "name": "You",
     "address": "email@yourdomain.com"
   },
   "content":{
     "html": "Hello! This is a test message. <b>Best from {{location}}!</b><br><a href="{{{visit_us}}}">Visit us!</a>",
     "text": "Hello! This is a test message. Best from {{location}}! Visit us: {{{visit_us}}}"
   },
   "reportUrl":"http://mywebhooksreceiver.com/"
  }'
`,
};

export const SEND_RESP = {
  json: `
{
  "id": "ff142bd7a8f34db6badae439740022b6",
}
`,
};

export const UPLOAD_FILE_DEF = {
  bash: `POST ${MESSAGING_API_ENDPOINT.json}/files`,
};

export const UPLOAD_FILE_REQ = {
  curl: `
curl -X POST ${MESSAGING_API_ENDPOINT.json}/files
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM'
  -H 'Content-Disposition: attachment; filename="testing_image.png"'
  -H 'Content-Type: image/png'
  --data-binary @input_image.png
`,
};

export const UPLOAD_FILE_RES = {
  json: `
{
  "id": "ebf6acee-d7ae-4375-b726-e247318d3377",
}
`,
};

export const FETCH_FILE_DEF = {
  bash: `GET ${MESSAGING_API_ENDPOINT.json}/files/<file-id>`,
};

export const FETCH_FILE_REQ = {
  curl: `
curl -X GET ${MESSAGING_API_ENDPOINT.json}/files/ebf6acee-d7ae-4375-b726-e247318d3377
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM'
  --output uploaded_image.png
`,
};

export const WEBHOOK_EXAMPLE_MESSAGE_ACCEPTED = {
  json: `
{
	"type": "message.accepted",
	"id": "299fbcc742ff4df28ed07f64564fe2f8",
	"recipient": "customer@messagebird.com",
	"timestamp": "2019-04-05T14:07:51Z"
}
`,
};

export const WEBHOOK_EXAMPLE_MESSAGE_SENT = {
  json: `
{
	"type": "message.sent",
	"id": "299fbcc742ff4df28ed07f64564fe2f8",
	"recipient": "customer@messagebird.com",
	"timestamp": "2019-04-05T14:07:51Z"
}
`,
};

export const WEBHOOK_EXAMPLE_MESSAGE_OPENED = {
  json: `
{
	"type": "message.opened",
	"id": "299fbcc742ff4df28ed07f64564fe2f8",
	"recipient": "customer@messagebird.com",
	"timestamp": "2019-04-05T14:07:51Z"
}
`,
};

export const WEBHOOK_EXAMPLE_MESSAGE_FAILED = {
  json: `
{
	"type": "message.failed",
	"id": "299fbcc742ff4df28ed07f64564fe2f8",
	"recipient": "customer@messagebird.com",
	"timestamp": "2019-04-05T14:07:51Z",
	"error": {
		"code": 11,
		"description": "Reported as spam"
	}
}
`,
};

export const WEBHOOK_EXAMPLE_LINK_CLICKED = {
  json: `
{
	"type": "link.clicked",
	"id": "299fbcc742ff4df28ed07f64564fe2f8",
	"recipient": "customer@messagebird.com",
	"timestamp": "2019-04-05T14:07:51Z",
	"link": {
		"url": "https://www.mesagebird.com"
	}
}
`,
};

export const DMARC_RECORD = {
  bash: `v=DMARC1; p=none; pct=100; rua=mailto:agg@myexmapledomain.com; ruf=mailto:for@myexampledomain.com;`,
};

export const WEBHOOK_EXAMPLE_MESSAGE_RECEIVED = {
  json: `
{
    "type": "message.received",
    "message": {
      "id": "2fa764dd4f0f4a478c55d357c03dbf25",
      "direction": "inbound",
      "threadId": "9ee97e35970a402c8c776ab446fc17a1",
      "messageId": "<CAHGjuC7R=eTA_i9_TsDTDO4yzt+mKqfKuGMLCayxBKJrA+aMhA@messagebird.com>",
      "inReplyTo": "<ad27e9240ba241a792ce54dfd330f360-484d6b263c4d4abc8a64e29cd799c71e@messagebird.com>",
      "email": {
          "channelId": "299fbcc742ff4df28ed07f64564fe2f8",
          "id": "299fbcc742ff4df28ed07f64564fe2f8",
          "from": {
              "address": "customer@messagebird.com"
          },
          "to": [
              {
                  "name": "MessageBird Support",
                  "address": "support@messagebird.com"
              }
          ],
          "subject": "How do I receive webhook notifications?",
          "content": {
              "html": "Hi there! <img src=\"cid:mb_logo1\">",
              "text": ""
          },
          "headers": {
              "Content-Type": "multipart/alternative; boundary=\\"0000000000006535a30587bb58d3\\"",
              "Date": "Tue, 30 Apr 2019 10:39:44 +0200",
              "From": "Customer <customer@messagebird.com>",
              "MIME-Version": "1.0",
              "Message-ID": "<CAHGjuC7R=eTA_i9_TsDTDO4yzt+mKqfKuGMLCayxBKJrA+aMhA@messagebird.com>",
              "Return-Path": "<support+caf_=fwd=messagebird.dev@messagebird.com>",
              "Subject": "How do I receive webhook notifications?",
              "To": "MessageBird Support <support@messagebird.com>"
          },
          "inlineImages": [
              {
                  "id": "97240ffbd206443b9c209e0cbb4cefee",
                  "name": "mg-logo.png",
                  "type": "image/png",
                  "url": "https://messaging-staging.messagebird.com/v1/files/1a3ca47e-f4a3-4d16-9f87-00a7e54464c4",
                  "length": 2797,
                  "contentId": "mb_logo1"
              }
          ],
          "attachments": [
              {
                   "id": "f7aeb180610945fd9a49182264519501",
                   "name": "messagebird.gif",
                   "type": "image/gif",
                   "url": "https://messaging-staging.messagebird.com/v1/files/62dbfe39-2db8-40f8-8037-3d9e116da984",
                   "length": 11961,
                   "messageId": "2fa764dd4f0f4a478c55d357c03dbf25"
              }
          ],
          "createdDatetime": "2019-05-15T15:47:23Z"
        }
    },
    "timestamp": "2019-05-08T13:19:06.019325+02:00"
}
`,
};
