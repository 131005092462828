export const CREATE_REQUEST = {
  bash: `  
  curl -X POST "https://partner-accounts.messagebird.com/child-accounts" \\
   -H "Content-Type: application/json" \\
   -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM" \\
   -d '{"name":"MessageBird Ltd."}'
  `,
  php: `
  <?php
    require_once(__DIR__ . '/../autoload.php');
    
    $messageBird = new \\MessageBird\\Client('test_gshuPaZoeEG6ovbc8M79w0QyM');
    
    $account = new \\MessageBird\\Objects\\PartnerAccount\\Account();
    $account->name = 'Name Test';
    
    try {
        $partnerAccountResult = $messageBird->partnerAccounts->create($account);
    } catch (\\MessageBird\\Exceptions\\AuthenticateException $e) {
        echo 'wrong login';
    } catch (\\Exception $e) {
        echo $e->getMessage();
    }
  `,
};

export const CREATE_RESPONSE = {
  json: `{
  "id": 6249799,
  "name": "Partner Account 3 Sub 1",
  "accessKeys": [
    {
      "id": "6912036c-dd42-489b-8588-8c430aec37ef",
      "key": "Hb85uQhmgvXXlHK9h3SHaAC4V",
      "mode": "live"
    },
    {
      "id": "cc620896-33fb-415c-9af1-909123937321",
      "key": "idG2gFSMayEiFRftcStmBxc71",
      "mode": "test"
    }
  ],
  "signingKey": "7qxJg4lsDKLAEBXAdxyarcwwvDn7YB00",
  "invoiceAggregation": true
}`,
};

export const GET_REQUEST_SINGLE = {
  bash: `  
  curl -X GET "https://partner-accounts.messagebird.com/child-accounts/6249609" \\
      -H "Content-Type: application/json" \\
      -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM"
  `,
  php: `
  <?php
    require_once(__DIR__ . '/../autoload.php');
    
    $messageBird = new \\MessageBird\\Client('test_gshuPaZoeEG6ovbc8M79w0QyM');
    $childAccountId = 6249609;
    
    try {
        $partnerAccountResult = $messageBird->partnerAccounts->read($childAccountId);
    } catch (\\MessageBird\\Exceptions\\AuthenticateException $e) {
        echo 'wrong login';
    } catch (\\Exception $e) {
        echo $e->getMessage();
    }
  `,
};

export const GET_RESPONSE_SINGLE = {
  json: `{
  "id": 6249609,
  "name": "Partner Account 1 Sub 1",
  "invoiceAggregation": true
}`,
};
export const GET_REQUEST_ALL = {
  bash: `  
  curl -X GET "https://partner-accounts.messagebird.com/child-accounts" \\
   -H "Content-Type: application/json" \\
   -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM"
  `,
  php: `
  <?php
    require_once(__DIR__ . '/../autoload.php');
    
    $messageBird = new \\MessageBird\\Client('test_gshuPaZoeEG6ovbc8M79w0QyM');
    
    try {
        $partnerAccountResult = $messageBird->partnerAccounts->list();
    } catch (\\MessageBird\\Exceptions\\AuthenticateException $e) {
        echo 'wrong login';
    } catch (\\Exception $e) {
        echo $e->getMessage();
    }
  `,
};

export const GET_RESPONSE_ALL = {
  json: `[
  {
    "id": 6249623,
    "name": "Partner Account 1 Sub 1"
  },
  {
    "id": 6249654,
    "name": "Partner Account 1 Sub 2"
  },
  {
    "id": 62496654,
    "name": "Partner Account 1 Sub 3"
  }
]`,
};

export const DELETE_REQUEST = {
  bash: `  
  curl -X "DELETE" "https://partner-accounts.messagebird.com/child-accounts/6249799" \\
   -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
   -H 'Content-Type: application/json'
  `,
  php: `
  <?php
    require_once(__DIR__ . '/../autoload.php');
    
    $messageBird = new \\MessageBird\\Client('test_gshuPaZoeEG6ovbc8M79w0QyM');
    $childAccount = 6249609;
    
    try {
        $messageBird->partnerAccounts->delete($childAccount);
    } catch (\\MessageBird\\Exceptions\\AuthenticateException $e) {
        echo 'wrong login';
    } catch (\\Exception $e) {
        echo $e->getMessage();
    }
  `,
};

export const EDIT_REQUEST = {
  bash: `  
  curl -X "PATCH" "https://partner-accounts.messagebird.com/child-accounts/6249799" \\
   -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
   -H 'Content-Type: application/json'
   -d '{"name":"MessageBird Ltd."}'
  `,
  php: `
  <?php
    require_once(__DIR__ . '/../autoload.php');
    
    $messageBird = new \\MessageBird\\Client('test_gshuPaZoeEG6ovbc8M79w0QyM');
    
    $account = new \\MessageBird\\Objects\\PartnerAccount\\Account();
    $account->name = 'Name Updated';

    try {
        $partnerAccountResult = $messageBird->partnerAccounts->update($account, 6249799);
    } catch (\\MessageBird\\Exceptions\\AuthenticateException $e) {
        echo 'wrong login';
    } catch (\\Exception $e) {
        echo $e->getMessage();
    }
  `,
};

export const DELETE_RESPONSE = {
  json: `{}`,
};

export const AUTHORIZATION_ERROR = {
  json: `{
    "message": "Not privileged to request the resource."
}`,
};

export const EDIT_RESPONSE = {
  json: `{
  "id": 6249609,
  "name": "Partner Account 1 Sub 2"
}`,
};

export const API_ENDPOINT = {
  bash: `https://partner-accounts.messagebird.com/`,
};

export const ERROR_EXAMPLE = {
  json: `{
    "type": "https:\\/\\/tools.ietf.org\\/html\\/rfc2616#section-10",
    "title": "An error occurred",
    "detail": "Client error Customer Id not found"
  }`,
};

export const DELETE_DEFINITION = {
  bash: `  
  DELETE https://partner-accounts.messagebird.com/child-accounts/{id}
  `,
};

export const GET_DEFINITION = {
  bash: `  
  GET https://partner-accounts.messagebird.com/child-accounts/{id}
  `,
};

export const GET_ALL_DEFINITION = {
  bash: `  
  GET https://partner-accounts.messagebird.com/child-accounts/
  `,
};

export const POST_DEFINITION = {
  bash: `  
  POST https://partner-accounts.messagebird.com/child-accounts/
  `,
};

export const EDIT_DEFINITION = {
  bash: `  
  PATCH https://partner-accounts.messagebird.com/child-accounts/{id}
  `,
};
