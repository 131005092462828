import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Setting masked phone numbers with MessageBird`}</h1>
    <h3>{`⏱ 45 min build time || `}<a parentName="h3" {...{
        "href": "https://github.com/messagebirdguides/masked-numbers-guide-go"
      }}>{`Download the Code`}</a></h3>
    <h2>{`Why build a number masking application?`}</h2>
    <p>{`In this MessageBird Developer Tutorial, you’ll learn how to anonymize and protect your users’ personal information by building a basic masked numbers application powered by the MessageBird API.`}</p>
    <p>{`Online service platforms—such as ride-sharing, online food delivery, and logistics—facilitate the experience between customers and providers by matching both sides of the transaction to ensure everything runs smoothly and the transaction is completed.`}</p>
    <p>{`Sometimes it’s necessary for customers and providers to talk or message each other directly; a problem arises since, for many reasons, both parties may not feel comfortable sharing their personal phone number. A great solution to this issue is using anonymous proxy phone numbers that mask a user's personal phone number while also protect the platform's personal contact details. The result: a customer doesn't see their provider's phone number but a number that belongs to the platform and forwards their call to the provider, and vice versa for providers.`}</p>
    <p>{`Along this tutorial, we'll show you how to build a proxy system to mask phone numbers in Go for our fictitious ride-sharing platform, `}<em parentName="p">{`BirdCar`}</em>{`. The sample application includes a data model for customers, drivers, rides, and proxy phone numbers and allows setting up new rides from an admin interface for demonstration purposes.`}</p>
    <h2>{`Getting started`}</h2>
    <p>{`First things first, our sample application is build in Go, so you need to install `}<a parentName="p" {...{
        "href": "https://golang.org"
      }}>{`Go`}</a>{` and the `}<a parentName="p" {...{
        "href": "https://github.com/messagebird/go-rest-api"
      }}>{`MessageBird Go SDK`}</a>{`.`}</p>
    <p>{`Now, let’s install the MessageBird Go SDK with the `}<inlineCode parentName="p">{`go get`}</inlineCode>{` command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`go get -u -v github.com/messagebird/go-rest-api
`}</code></pre>
    <p>{`To keep the tutorial straightforward, we'll be using the Go standard library for
most of our application and a SQLite3 database to store the data model that
powers our ridesharing application. You may want to use a different RDBMS for a
production-ready implementation; the SQL statements used in this tutorial should be
transferable to any RDBMS that uses SQL.`}</p>
    <p>{`To work with and connect to a SQLite3 database, we'll need to install
`}<a parentName="p" {...{
        "href": "https://www.github.com/mattn"
      }}>{`mattn`}</a>{`\\'s SQLite3 driver for Go,
`}<a parentName="p" {...{
        "href": "https://github.com/mattn/go-sqlite3"
      }}><inlineCode parentName="a">{`go-sqlite3`}</inlineCode></a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`go get -u -v github.com/mattn/go-sqlite3
`}</code></pre>
    <p>{`You need to have `}<inlineCode parentName="p">{`gcc`}</inlineCode>{` installed in order to build your application
with `}<inlineCode parentName="p">{`go-sqlite3`}</inlineCode>{`. Check out `}<a parentName="p" {...{
        "href": "https://github.com/mattn/go-sqlite3"
      }}><inlineCode parentName="a">{`go-sqlite3`}</inlineCode>{`
documentation`}</a>{` for more information about it.`}</p>
    <p>{`Ready? Let’s move on to
`}<a parentName="p" {...{
        "href": "/tutorials/masked-numbers-go#structuring-our-application"
      }}>{`structuring our application`}</a>{`.`}</p>
    <h2>{`Structuring our application`}</h2>
    <p>{`Our BirdCar ridesharing service seeks to pair customers who need a car ride with
drivers looking for passengers. When a customer contacts BirdCar to request for
a ride, our application should:`}</p>
    <ol>
      <li parentName="ol">{`Ask the customer for details of the ride they need.`}</li>
      <li parentName="ol">{`Pair the customer up with a driver.`}</li>
      <li parentName="ol">{`Check our database for a VMN that we can assign to the ride as a proxy number.`}</li>
      <li parentName="ol">{`Once we find an available VMN to use as a proxy number, we send an SMS
notification to both the customer and driver from that proxy number to let them
know that they can use this number to contact the other party for this ride.`}</li>
      <li parentName="ol">{`We then write the ride's details to our database.`}</li>
      <li parentName="ol">{`When one party calls or sends an SMS message to a proxy number, our application relays that call or SMS message to the other party for that ride.`}</li>
    </ol>
    <p>{`To get our application to do all of the above, we need to build the following:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/masked-numbers-go#building-our-data-model"
        }}><strong parentName="a">{`Data model`}</strong></a>{`: To store ride information.`}</li>
    </ul>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/tutorials/masked-numbers-go#messagebird-flows"
          }}><strong parentName="a">{`MessageBird Flows`}</strong></a>{`:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`A MessageBird flow that forwards SMS received by our VMNs to a webhook URL.`}</li>
          <li parentName="ul">{`A MessageBird flow that forwards calls received by our VMNs to another webhook URL.`}</li>
        </ul>
      </li>
    </ul>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/tutorials/masked-numbers-go#web-application"
          }}><strong parentName="a">{`Web Application`}</strong></a>{`: Our web application should:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Read from and write to our database.`}</li>
          <li parentName="ul">{`Publish an administrator's interface to manage BirdCar rides.`}</li>
          <li parentName="ul">{`Handle POST and GET requests from MessageBird flows.`}</li>
          <li parentName="ul">{`When a ride is added, send an SMS notification to the customer and driver for that ride.`}</li>
          <li parentName="ul">{`When a customer or driver sends an SMS message to a VMN, our application should detect a POST request at a given URL and relay the message to its intended recipient.`}</li>
          <li parentName="ul">{`When a customer or driver calls a VMN, our application should detect a GET request at a girl URL and relay the call to its intended recipient.`}</li>
        </ul>
      </li>
    </ul>
    <p>{`With this in mind, we can start building your application. We'll write our Go
code in four separate files to make it easier to read and understand:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`main.go`}</inlineCode>{` contains our application's `}<inlineCode parentName="li">{`main()`}</inlineCode>{` block.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`db.go`}</inlineCode>{` contains the code that interacts with our SQLite3 database.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`routes.go`}</inlineCode>{` contains code that defines our HTTP routes.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`routeHelpers.go`}</inlineCode>{` contains code that defines helper functions we'll use when
writing our HTTP routes.`}</li>
    </ul>
    <p>{`These files should be located at your project root. To start your application, run
the following command in the terminal:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`go run *.go
`}</code></pre>
    <p>{`First, we'll initialize and configure the MessageBird Go SDK in `}<inlineCode parentName="p">{`main.go`}</inlineCode>{`.`}</p>
    <h2>{`Configuring the MessageBird Go SDK`}</h2>
    <p>{`We'll need to configure the MessageBird Go SDK with a valid API key in order to
make calls and send SMS messages with the MessageBird REST API.`}</p>
    <p>{`First, create a file named `}<inlineCode parentName="p">{`.env`}</inlineCode>{` in your project directory. Then, get your
MessageBird API key from the
`}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`API access (REST) tab`}</a>{`
in the `}<em parentName="p">{`Developers`}</em>{` section of the MessageBird Dashboard, and write it into your
`}<inlineCode parentName="p">{`.env`}</inlineCode>{` file like so:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`MESSAGEBIRD_API_KEY=<enter-your-api-key-here>
`}</code></pre>
    <p>{`If you are having any issues creating your API key, please reach out to `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we’ll make sure to help you out.`}</p>
    <p>{`Run the following commands in your terminal to load your API key as the
`}<inlineCode parentName="p">{`MESSAGEBIRD_API_KEY`}</inlineCode>{` environment variable:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`source .env
export MESSAGEBIRD_API_KEY
`}</code></pre>
    <p>{`Once that's done, add the following code to `}<inlineCode parentName="p">{`main.go`}</inlineCode>{` to initialize the
MessageBird Go SDK:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`// main.go
package main

import (
  "log"

  messagebird "github.com/messagebird/go-rest-api"
)

func main(){
  mb := messagebird.New(os.Getenv("MESSAGEBIRD_API_KEY"))

}
`}</code></pre>
    <p>{`You can also use a library like `}<a parentName="p" {...{
        "href": "https://github.com/joho/godotenv"
      }}>{`GoDotEnv`}</a>{` to
automatically load environment variables from a configuration file.`}</p>
    <h2>{`Building our data model`}</h2>
    <p>{`Because Go is strict about handling data types, we'll be building our data model
first to help us understand how to build the rest of our application around our
data.`}</p>
    <p>{`Judging from our `}<a parentName="p" {...{
        "href": "/tutorials/masked-numbers-go#structuring-our-application"
      }}>{`application structure`}</a>{`, we know that our database should contain the following tables:`}</p>
    <ul>
      <li parentName="ul">{`Customer data`}</li>
      <li parentName="ul">{`Driver data`}</li>
      <li parentName="ul">{`Proxy number pool`}</li>
      <li parentName="ul">{`Ride information`}</li>
    </ul>
    <p>{`In the following sections, we'll write the SQL statements to
`}<a parentName="p" {...{
        "href": "/tutorials/masked-numbers-go#initialize-ridesharing-database"
      }}>{`initialize our ridesharing database`}</a>{` and
then figure out how to read data from it into
`}<a parentName="p" {...{
        "href": "/tutorials/masked-numbers-go#set-up-data-structures"
      }}>{`data structures`}</a>{` that we set up in our application.`}</p>
    <h3>{`Initialize ridesharing database`}</h3>
    <p>{`First, let's initialize our ridesharing database. Add the following code to our `}<inlineCode parentName="p">{`db.go`}</inlineCode>{` file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`// db.go
package main

import (
  "database/sql"
  "log"

  _ "github.com/mattn/go-sqlite3"
)

func must(err error) {
  if err != nil {
    log.Fatal(err)
  }
}

func dbInsert(queries []string) {
  db, err := sql.Open("sqlite3", "./ridesharing.db")
  must(err)
  for _, i := range queries {
    statement, err := db.Prepare(i)
    must(err)
    _, err = statement.Exec()
    must(err)
  }
  defer db.Close()
}

// initExampleDB inserts example data into the sqlite db
func initExampleDB() {
  createTables := []string{
    "CREATE TABLE IF NOT EXISTS customers(id INTEGER PRIMARY KEY, name TEXT, number TEXT UNIQUE)",
    "CREATE TABLE IF NOT EXISTS drivers (id INTEGER PRIMARY KEY, name TEXT, number TEXT UNIQUE)",
    "CREATE TABLE IF NOT EXISTS proxy_numbers (id INTEGER PRIMARY KEY, number TEXT UNIQUE)",
    "CREATE TABLE IF NOT EXISTS " +
      "rides (id INTEGER PRIMARY KEY, " +
      "start TEXT, destination TEXT, datetime TEXT, customer_id INTEGER, driver_id INTEGER, number_id INTEGER, " +
      "FOREIGN KEY (customer_id) REFERENCES customers(id), FOREIGN KEY (driver_id) REFERENCES drivers(id))",
  }
  dbInsert(createTables)
}
`}</code></pre>
    <p>{`Awesome! In the code snippet above, we've:`}</p>
    <ul>
      <li parentName="ul">{`Defined two helper functions: `}<inlineCode parentName="li">{`must()`}</inlineCode>{`, which logs any errors encounters and
exits the program, and `}<inlineCode parentName="li">{`dbInsert()`}</inlineCode>{`, which prepares and executes a list of SQL
statements passed into it.`}</li>
      <li parentName="ul">{`We then write a series of SQL statements to execute, and pass that into a
`}<inlineCode parentName="li">{`dbInsert()`}</inlineCode>{` call.`}</li>
      <li parentName="ul">{`In our SQL statements, we've:
`}<em parentName="li">{` Created four tables: `}<inlineCode parentName="em">{`customers`}</inlineCode>{`, `}<inlineCode parentName="em">{`drivers`}</inlineCode>{`, `}<inlineCode parentName="em">{`proxy_numbers`}</inlineCode>{`, and `}<inlineCode parentName="em">{`rides`}</inlineCode>
        </em>{` Made sure that each SQL `}<inlineCode parentName="li">{`CREATE TABLE`}</inlineCode>{` statement is idempotent by writing
`}<inlineCode parentName="li">{`IF NOT EXISTS`}</inlineCode>{`, so we don't attempt to insert tables that already exist into our database. This means that `}<inlineCode parentName="li">{`initExampleDB()`}</inlineCode>{` can be safely run multiple times, even if we've already initialized the database.`}</li>
      <li parentName="ul">{`We've set phone numbers (`}<inlineCode parentName="li">{`numbers`}</inlineCode>{`) as `}<inlineCode parentName="li">{`UNIQUE`}</inlineCode>{` to make sure that we don't
get duplicate phone numbers. This is important because we’ll be using phone numbers to identify who to relay SMS messages and phone calls to.`}</li>
    </ul>
    <p>{`Next, we'll add example data into our database. When writing your
production-ready application, remember to replace these with actual data. Add
the following lines of code to the bottom of your `}<inlineCode parentName="p">{`initExampleDB()`}</inlineCode>{` block:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`// db.go
func initExampleDB(){
    // ...
    insertData := []string{
        "INSERT INTO customers (name, number) VALUES ('Caitlyn Carless', '319700000') ON CONFLICT (number) DO UPDATE SET name=excluded.name",
        "INSERT INTO customers (name, number) VALUES ('Danny Bikes', '319700001') ON CONFLICT (number) DO UPDATE SET name=excluded.name",
        "INSERT INTO drivers (name, number) VALUES ('David Driver', '319700002') ON CONFLICT (number) DO UPDATE SET name=excluded.name",
        "INSERT INTO drivers (name, number) VALUES ('Eileen LaRue', '319700003') ON CONFLICT (number) DO UPDATE SET name=excluded.name",
        "INSERT INTO proxy_numbers (number) VALUES ('319700004') ON CONFLICT (number) DO NOTHING",
        "INSERT INTO proxy_numbers (number) VALUES ('319700005') ON CONFLICT (number) DO NOTHING",
    }
    dbInsert(insertData)
}
`}</code></pre>
    <p>{`We've added `}<inlineCode parentName="p">{`ON CONFLICT (number) ...`}</inlineCode>{` to each SQL statement because,
unlike names, we need phone numbers in our database to be unique (we already covered that above). You may want to replace the phone numbers in the examples with working phone numbers to allow you to test your application. But for now, we're just concerned with getting the shape of our data right, so we can begin
writing code to read data from our database into our ridesharing application.`}</p>
    <h3>{`Set up data structures`}</h3>
    <p>{`Now that we've written the code to initialize our database, we can start writing
code to read data out from it into our application. The `}<inlineCode parentName="p">{`sql`}</inlineCode>{` package from the
Go standard library allows us to run SQL queries on a database by calling
`}<inlineCode parentName="p">{`db.Query("SELECT * FROM your_table")`}</inlineCode>{`, which then gives you a `}<inlineCode parentName="p">{`Rows`}</inlineCode>{` struct
that you have to unpack.`}</p>
    <p>{`In this section, we'll learn:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/masked-numbers-go#how-to-read-from-a-database-with-go"
        }}>{`How to read from a Database with Go`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/masked-numbers-go#defining-the-structs-that-contain-data"
        }}>{`Defining the structs that contain data`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/masked-numbers-go#load-data-into-data-structures"
        }}>{`Load data into data structures`}</a></li>
    </ul>
    <h4>{`How to read from a database with Go`}</h4>
    <p>{`Because Go is a strictly typed language, the code to read data from databases is
slightly more verbose. For example, if we run a `}<inlineCode parentName="p">{`SELECT`}</inlineCode>{` query to read from our
`}<inlineCode parentName="p">{`customers`}</inlineCode>{` table, we have to unpack the `}<inlineCode parentName="p">{`Rows`}</inlineCode>{` struct we receive by writing the
following code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`// Example

// These variables can have any name, but must be the same type as the data we're going to copy into it.
var (
    customerID int
    customerName string // 'text' type in the database
    customerNumber string // 'text' type in the database
)
rows, _ := db.Query("SELECT * FROM customers")
for rows.Next() {
    rows.Scan(&customerID,&customerName,&customerNumber)
    log.Printf("ID: %d\\nName: %s\\nPhone Number: %s\\n", customerID, customerName, customerNumber)
}
`}</code></pre>
    <p>{`The code snippet above does the following:`}</p>
    <ol>
      <li parentName="ol">{`It runs a database query (we're discarding the error to keep our example
brief) and saves the `}<inlineCode parentName="li">{`*sql.Rows`}</inlineCode>{` struct that's returned to the `}<inlineCode parentName="li">{`rows`}</inlineCode>{` variable.`}</li>
      <li parentName="ol">{`It then iterates through the records stored in `}<inlineCode parentName="li">{`rows`}</inlineCode>{` with a
`}<inlineCode parentName="li">{`for rows.Next()`}</inlineCode>{` loop. When `}<inlineCode parentName="li">{`rows.Next()`}</inlineCode>{` returns false, it means we've run
out of records to process and can exit the loop.`}</li>
      <li parentName="ol">{`For each record we find in `}<inlineCode parentName="li">{`rows`}</inlineCode>{`, we call `}<inlineCode parentName="li">{`rows.Scan()`}</inlineCode>{` to scan the columns
of that record.`}</li>
      <li parentName="ol">{`For each column that the record contains, we pass in the address of the
variable we want to copy—the value contained in that column for that record.
For example, for a record that has the columns "id" and "animal", we copy the
values contained in these columns to variables we've already defined by calling
`}<inlineCode parentName="li">{`rows.Scan(&idVariable, &animalVariable)`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`The variables whose addresses we pass into our `}<inlineCode parentName="li">{`rows.Scan()`}</inlineCode>{` call must
fulfill the following:
`}<em parentName="li">{` Each of the variables whose address we pass into the `}<inlineCode parentName="em">{`rows.Scan()`}</inlineCode>{` call
must be of the correct type for that corresponding column. For example, if
we're trying to copy out a `}<inlineCode parentName="em">{`text`}</inlineCode>{` value from the record, we must pass in a
variable of type `}<inlineCode parentName="em">{`string`}</inlineCode>{`.
`}</em>{` `}<inlineCode parentName="li">{`rows.Scan()`}</inlineCode>{` must have exactly the same number of addresses passed into it as the number of columns in the record.
`}<em parentName="li">{` If `}<inlineCode parentName="em">{`rows.Scan()`}</inlineCode>{` contains a different number of addresses than the number of columns the record contains (more or fewer than expected), it returns an error and no values are copied out — causing your program to seem to have read an empty record.
`}</em>{` For example, for a record with the columns "id" and "animal", attempting to call `}<inlineCode parentName="li">{`rows.Scan(&firstVar, &secondVar, &thirdVar)`}</inlineCode>{` won’t copy any values to `}<inlineCode parentName="li">{`firstVar`}</inlineCode>{`, `}<inlineCode parentName="li">{`secondVar`}</inlineCode>{`, or `}<inlineCode parentName="li">{`thirdVar`}</inlineCode>{` — instead, it returns the error: `}<inlineCode parentName="li">{`sql: expected 2 destination arguments in Scan, not 3`}</inlineCode>{`.`}</li>
    </ol>
    <h4>{`Defining the structure that contain data`}</h4>
    <p>{`We need to read from the four tables we've created in our database—`}<inlineCode parentName="p">{`customers`}</inlineCode>{`, `}<inlineCode parentName="p">{`drivers`}</inlineCode>{`, `}<inlineCode parentName="p">{`proxy_numbers`}</inlineCode>{`, `}<inlineCode parentName="p">{`rides`}</inlineCode>{`—and store them in some kind of data structure within our Go application. But before attempting to read data out from the database into our application, we have to define the shape of the data we expect to get from the database.`}</p>
    <p>{`In your production application, you may want to implement a form of paging where you wouldn't read and copy all the data from your database into your application at one go. But to keep this tutorial straightforward, we'll have our application load the entire database into one struct that we pass to our application when rendering views.`}</p>
    <p>{`We'll do this by describing the shape of the structs we'll be using to store data from these tables as struct `}<inlineCode parentName="p">{`type`}</inlineCode>{`s. At the bottom of your `}<inlineCode parentName="p">{`db.go`}</inlineCode>{` file, add the following lines of code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`// db.go
// Person is a person, to whom we assign a ID, Name, and Number.
// Used to represent Customers and Drivers
type Person struct {
    ID     int
    Name   string
    Number string
}

// ProxyNumberType templates proxy numbers
type ProxyNumberType struct {
    ID     int
    Number string
}

// RideType templates rides
type RideType struct {
    ID              int
    Start           string
    Destination     string
    DateTime        string
    ThisCustomer    Person          // foreign key
    ThisDriver      Person          // foreign key
    ThisProxyNumber ProxyNumberType // foreign key
    NumGrp          [][]int         // Number groups for proxy number rotation
}

// RideSharingDB outlines overall rideshare data structure
type RideSharingDB struct {
    Customers    map[int]Person
    Drivers      map[int]Person
    ProxyNumbers map[int]ProxyNumberType
    Rides        map[int]RideType
    Message      string // For misc messages to be displayed in rendered page
}
`}</code></pre>
    <p>{`In the above code example:`}</p>
    <ul>
      <li parentName="ul">{`We are defining several struct types to contain data we expect to get from
the database.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`type Person struct`}</inlineCode>{` describes a "person" as stored in our `}<inlineCode parentName="li">{`customers`}</inlineCode>{` and `}<inlineCode parentName="li">{`drivers`}</inlineCode>{` table.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`type ProxyNumberType`}</inlineCode>{` describes a "Proxy Number" or a "VMN" as stored in our `}<inlineCode parentName="li">{`proxy_number`}</inlineCode>{` table.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`type RideType struct`}</inlineCode>{` describes a single "Ride" as stored in our `}<inlineCode parentName="li">{`rides`}</inlineCode>{` table.
`}<em parentName="li">{` Notice that we're "inheriting" types in our `}<inlineCode parentName="em">{`RideType`}</inlineCode>{` struct for the
`}<inlineCode parentName="em">{`ThisCustomer`}</inlineCode>{`, `}<inlineCode parentName="em">{`ThisDriver`}</inlineCode>{`, and `}<inlineCode parentName="em">{`ThisProxyNumber`}</inlineCode>{` fields. This allows us to nest data for each ride, instead of relying on `}<inlineCode parentName="em">{`JOIN`}</inlineCode>{` statements to get information about the foreign keys that these columns refer to in the `}<inlineCode parentName="em">{`rides`}</inlineCode>{` table. We can do this because we don't intend to write persistent changes to `}<inlineCode parentName="em">{`RideType`}</inlineCode>{` — all persistent changes to our data is written directly to our database.
`}</em>{` We also have an additional field named `}<inlineCode parentName="li">{`NumGrp`}</inlineCode>{`. This field is used for our `}<a parentName="li" {...{
          "href": "/tutorials/masked-numbers-go#proxy-number-rotation"
        }}>{`proxy number rotation`}</a>{` implementation that we’ll write later.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`type RideSharingDB struct`}</inlineCode>{` describes a struct that is an aggregate of all the data that we need to pass to our rendered views.
`}<em parentName="li">{` In it, notice that we've shadowed our tables with `}<inlineCode parentName="em">{`map`}</inlineCode>{` types. We'll get into how this works when we write the code for populating these data structures with data from the database.
`}</em>{` We also define a `}<inlineCode parentName="li">{`Message`}</inlineCode>{` type in this struct, which we’ll use to pass error messages or similar to be displayed in our rendered views.`}</li>
    </ul>
    <h3>{`Load data into data structures`}</h3>
    <p>{`Once we've defined our data structures, we need to write a helper method that loads data into any RideSharingDB struct that we define and return it for the rest of our application to use.`}</p>
    <p>{`Keep in mind that for brevity, we're not including the full code snippet. For the complete example application, go to the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/masked-numbers-guide-go"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`.`}</p>
    <p>{`Add to the bottom of your `}<inlineCode parentName="p">{`db.go`}</inlineCode>{` file the following lines of code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`// db.go
func (dbdata *RideSharingDB) loadDB() error {
    db, err := sql.Open("sqlite3", "./ridesharing.db")
    if err != nil {
        return err
    }
    defer db.Close()

    hereCustomers := make(map[int]Person)
    hereDrivers := make(map[int]Person)
    hereProxyNumbers := make(map[int]ProxyNumberType)
    hereRides := make(map[int]RideType)

    q := "SELECT * FROM customers"
    rows, err := db.Query(q)
    if err != nil {
        return err
    }
    for rows.Next() {
        var thisPerson Person
        err := rows.Scan(&thisPerson.ID, &thisPerson.Name, &thisPerson.Number)
        if err != nil {
            log.Println(err)
        }
        hereCustomers[thisPerson.ID] = thisPerson
    }

 // ...
 // We're only including part of the code necessary for your application
 // to work. For the full code example, go to:
 // https://github.com/messagebirdguides
 // ...

    q4 := "SELECT * FROM rides"
    rows4, err := db.Query(q4)
    if err != nil {
        return err
    }
    for rows4.Next() {
        var thisRide RideType
        err := rows4.Scan(&thisRide.ID, &thisRide.Start, &thisRide.Destination, &thisRide.DateTime, &thisRide.ThisCustomer.ID, &thisRide.ThisDriver.ID, &thisRide.ThisProxyNumber.ID)
        if err != nil {
            log.Println(err)
        }

        for k1, v1 := range hereCustomers {
            if k1 == thisRide.ThisCustomer.ID {
                thisRide.ThisCustomer.Name = v1.Name
                thisRide.ThisCustomer.Number = v1.Number
            }
        }
        for k2, v2 := range hereDrivers {
            if k2 == thisRide.ThisDriver.ID {
                thisRide.ThisDriver.Name = v2.Name
                thisRide.ThisDriver.Number = v2.Number
            }
        }
        for k3, v3 := range hereProxyNumbers {
            if k3 == thisRide.ThisProxyNumber.ID {
                thisRide.ThisProxyNumber.Number = v3.Number
            }
        }
        thisRide.NumGrp = append(thisRide.NumGrp, []int{thisRide.ThisCustomer.ID, thisRide.ThisProxyNumber.ID})
        thisRide.NumGrp = append(thisRide.NumGrp, []int{thisRide.ThisDriver.ID, thisRide.ThisProxyNumber.ID})
        hereRides[thisRide.ID] = thisRide
    }
    *dbdata = RideSharingDB{hereCustomers, hereDrivers, hereProxyNumbers, hereRides, ""}
    return nil
}
`}</code></pre>
    <p>{`In the code sample above:`}</p>
    <ol>
      <li parentName="ol">{`We're writing a method, with our RideSharingDB struct type as the method
receiver. This allows us to load data into any `}<inlineCode parentName="li">{`RideSharingDB`}</inlineCode>{` struct with this
method.`}</li>
      <li parentName="ol">{`We're loading the ridesharing database into the variable `}<inlineCode parentName="li">{`db`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`Then we initialize the maps that will contain data that we read from our
tables by using `}<inlineCode parentName="li">{`make()`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`Once we've done that, we write our queries.`}</li>
      <li parentName="ol">{`For each query, we define a query statement `}<inlineCode parentName="li">{`q`}</inlineCode>{` which we pass into `}<inlineCode parentName="li">{`db.Query()`}</inlineCode>{` and get a `}<inlineCode parentName="li">{`rows`}</inlineCode>{` struct.`}</li>
      <li parentName="ol">{`For each `}<inlineCode parentName="li">{`rows`}</inlineCode>{` struct we get, we write a `}<inlineCode parentName="li">{`for rows.Next()`}</inlineCode>{` loop that loops
through each record in the table we've read.`}</li>
      <li parentName="ol">{`For each record we read, we define a container variable (thisPerson)
that we copy values from the record into, and then append that variable to the
corresponding map we've initialized in step 3, using the `}<inlineCode parentName="li">{`ID`}</inlineCode>{` of that record as
the map key.`}</li>
      <li parentName="ol">{`Once we've done this for all four tables, we rewrite the RideSharingDB
struct that is attached to this method with the data we've read off the
database with the following line of code:`}
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`*dbdata = RideSharingDB{hereCustomers, hereDrivers, hereProxyNumbers, hereRides, ""}`}</inlineCode></li>
        </ul>
      </li>
    </ol>
    <p>{`When this helper method is called, it loads data from the database into the struct it’s attached to.`}</p>
    <p>{`For example, if we define a struct with `}<inlineCode parentName="p">{`thisDatabase := new(RideSharingDB)`}</inlineCode>{`, and then call `}<inlineCode parentName="p">{`thisDatabase.loadDB()`}</inlineCode>{`, it reads data from the database and loads it into the corresponding fields—`}<inlineCode parentName="p">{`thisDatabase.Customers`}</inlineCode>{`, `}<inlineCode parentName="p">{`thisDatabase.Drivers`}</inlineCode>{`, `}<inlineCode parentName="p">{`thisDatabase.ProxyNumbers`}</inlineCode>{`, `}<inlineCode parentName="p">{`thisDatabase.Rides`}</inlineCode>{`—to populate it.`}</p>
    <p>{`We then can pass `}<inlineCode parentName="p">{`thisDatabase`}</inlineCode>{` into any template to display the data in a rendered view.`}</p>
    <h2>{`MessageBird flows`}</h2>
    <p>{`Now that we've got all our data structures set-up, we can move on to configuring
our MessageBird Dashboard to receive calls and SMS messages, and then forwarding
them to their intended recipients.`}</p>
    <p>{`To do this, we're going to:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/masked-numbers-go#expose-local-development-server"
        }}>{`Expose our local development server with localtunnel.me`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/masked-numbers-go#prepare-vmns"
        }}>{`Prepare one or more VMNs`}</a></li>
      <li parentName="ul">{`For each VMN you will be using in your `}<a parentName="li" {...{
          "href": "/tutorials/masked-numbers-go#proxy-number-rotation"
        }}>{`number pool`}</a>{`, you have to:
`}<em parentName="li">{` `}<a parentName="em" {...{
            "href": "/tutorials/masked-numbers-go#connect-the-vmn-to-a-webhook-for-sms"
          }}>{`Connect the VMN to a Webhook for SMS`}</a>
        </em>{` `}<a parentName="li" {...{
          "href": "/tutorials/masked-numbers-go#connect-the-vmn-to-a-webhook-for-voice"
        }}>{`Connect the VMN to a Webhook for Voice`}</a></li>
    </ul>
    <h3>{`Expose local development server`}</h3>
    <p>{`We need to expose our development environment to the MessageBird servers for the `}<a parentName="p" {...{
        "href": "/tutorials/masked-numbers-go#messagebird-flows"
      }}>{`MessageBird flows`}</a>{` to work. You can use tools such as `}<a parentName="p" {...{
        "href": "https://localtunnel.me"
      }}>{`localtunnel.me`}</a>{` or `}<a parentName="p" {...{
        "href": "https://ngrok.com/"
      }}>{`ngrok`}</a>{` that provides a public URL to connect to a locally running server.`}</p>
    <p>{`You can install `}<a parentName="p" {...{
        "href": "https://localtunnel.me"
      }}>{`localtunnel.me`}</a>{` with npm:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install -g localtunnel
`}</code></pre>
    <p>{`To expose a server running on port 8080, run:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`lt --port 8080
`}</code></pre>
    <p>{`The terminal then displays the URL at which you can access your application:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`your url is: https://<assigned_subdomain>.localtunnel.me
`}</code></pre>
    <p>{`Whenever you run the `}<inlineCode parentName="p">{`lt`}</inlineCode>{` command, localtunnel.me starts a new `}<inlineCode parentName="p">{`lt`}</inlineCode>{` instance that has a different unique URL assigned. Because you have to assign a static URL for MessageBird to make webhook requests, quitting and running `}<inlineCode parentName="p">{`lt`}</inlineCode>{` again will change the URL for your local development server, causing MessageBird to be unable to contact it until you update your flows with your new URL.`}</p>
    <h3>{`Getting an inbound number`}</h3>
    <p>{`A requirement for receiving messages is a dedicated inbound number. Virtual mobile numbers (VNM) look and work similar to regular mobile numbers; however, instead of being attached to a mobile device via a SIM card, they live in the cloud and can process inbound SMS and voice calls. MessageBird offers numbers from different countries for a low monthly fee; `}<a parentName="p" {...{
        "href": "https://www.messagebird.com/en/numbers"
      }}>{`feel free to explore our low-cost programmable and configurable numbers`}</a>{`.`}</p>
    <p>{`Purchasing a number is quite easy:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Go to the ‘`}<a parentName="p" {...{
            "href": "https://dashboard.messagebird.com/en/numbers"
          }}>{`Numbers`}</a>{`’ section in the left-hand side of your Dashboard and click the blue button ‘`}<a parentName="p" {...{
            "href": "https://dashboard.messagebird.com/en/vmn/buy-number"
          }}>{`Buy a number`}</a>{`’ in the top-right side of your screen.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Pick the country in which you and your customers are located, and make sure both the SMS and Voice capabilities are selected.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Choose one number from the selection and the duration for which you want to pay now.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Confirm by clicking ‘Buy Number’ in the bottom-right of your screen.`}</p>
      </li>
    </ol>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/masked-go/image1.png",
        "alt": "Buy a VMN"
      }}></img></p>
    <p>{`Awesome, you’ve set up your first virtual mobile number! 🎉`}</p>
    <p>{`One is enough for testing, but for real usage of the masked number system, you'll need a larger pool of numbers; simply follow the same steps listed to purchase more.`}</p>
    <p><strong parentName="p">{`Pro-tip:`}</strong>{` Check out our Help Center for more information about `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/sections/201958489-Virtual-Numbers"
      }}>{`virtual mobile numbers`}</a>{` and `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/sections/360000108538-Country-info-Restrictions"
      }}>{`country restrictions`}</a>{`.`}</p>
    <h3>{`Connect the number to a webhook for SMS`}</h3>
    <p>{`So you have a number now, but MessageBird has no idea what to do with it. That's why now you need to define a `}<em parentName="p">{`Flow`}</em>{` that links your number to your webhook. We’ll start with the flow for inbound SMS messages:`}</p>
    <h4>{`STEP ONE`}</h4>
    <p>{`Go to `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/flow-builder"
      }}>{`Flow Builder`}</a>{`, choose the template ‘Call HTTP endpoint with SMS’, and click ‘Try this flow’.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/masked-go/image2.png",
        "alt": "FlowBuilder"
      }}></img></p>
    <h4>{`STEP TWO`}</h4>
    <p>{`This template has two steps. Click on the first step ‘SMS’ and select the number or numbers you’d like to attach the flow to. Now, click on the second step ‘Forward to URL’ and choose POST as the method; copy the output from your
`}<a parentName="p" {...{
        "href": "/tutorials/masked-numbers-go#expose-local-development-server"
      }}>{`localtunnel.me URL`}</a>{` into the `}<strong parentName="p">{`URL`}</strong>{` field—this is the name of the route we will use to handle incoming messages. Click `}<strong parentName="p">{`Save`}</strong>{`.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/masked-go/image3.png",
        "alt": "FlowBuilder"
      }}></img></p>
    <h4>{`STEP THREE`}</h4>
    <p><strong parentName="p">{`Ready!`}</strong>{` Hit ‘Publish’ on the right top of the screen to activate your flow. Well done, another step closer to testing incoming messages! Your flow should look something like this:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/masked-go/image4.png",
        "alt": "FlowBuilder"
      }}></img></p>
    <p><strong parentName="p">{`Pro-tip:`}</strong>{` It might be useful to rename it this flow, because `}<em parentName="p">{`Untitled flow`}</em>{` won't be helpful in the long run. You can do this by clicking on the icon next to button ‘Back to Overview’ and pressing ‘Rename flow’.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/masked-go/image5.png",
        "alt": "FlowBuilder"
      }}></img></p>
    <h3>{`Connect the number to a webhook for voice`}</h3>
    <p>{`Let’s set up a second flow for incoming voice calls:`}</p>
    <h4>{`STEP ONE`}</h4>
    <p>{`Go back to `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/flow-builder"
      }}>{`Flow Builder`}</a>{` and hit the button ‘Create new flow’ and then ‘Create Custom Flow’.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/masked-go/image6.png",
        "alt": "FlowBuilder"
      }}></img></p>
    <h4>{`STEP TWO`}</h4>
    <p>{`Give your flow a name, choose ‘Phone Call’ as the trigger and hit ‘Next’.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/masked-go/image7.png",
        "alt": "Flowbuilder"
      }}></img></p>
    <h4>{`STEP THREE`}</h4>
    <p>{`Click on the first step ‘Phone Call’ and select the number or numbers you’d like to attach the flow to.`}</p>
    <h4>{`STEP FOUR`}</h4>
    <p>{`Add a new step by pressing the small ‘+’, choose ‘Fetch call flow from URL’ and paste the same localtunnel base URL into the form, but this time append `}<inlineCode parentName="p">{`/webhook-voice`}</inlineCode>{` to it—this is the name of the route we use to handle incoming calls in our sample application. Click on ‘Save’ when ready.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/masked-go/image8.png",
        "alt": "FlowBuilder"
      }}></img></p>
    <h4>{`STEP FIVE`}</h4>
    <p><strong parentName="p">{`Ready!`}</strong>{` Hit ‘Publish’ on the right top of the screen to activate your flow. Your flow should look something like this:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/masked-go/image9.png",
        "alt": "FlowBuilder"
      }}></img></p>
    <p>{`You're done setting up flows for your application! Now, we can begin writing routes in your application for the `}<inlineCode parentName="p">{`/webhook`}</inlineCode>{` and `}<inlineCode parentName="p">{`/webhook-voice`}</inlineCode>{` URL paths that these flows are using.`}</p>
    <h2>{`Web application`}</h2>
    <p>{`Now we can start writing the web server component of your application. We won't go through how to write Go HTML templates or the basics of HTTP routing; instead, we'll be focusing on routing logic.`}</p>
    <p>{`First, let's review what we need our web server to do:`}</p>
    <ul>
      <li parentName="ul">{`Provide a administrative interface for managing BirdCar rides. On this administrative interface, we should be able to:
`}<em parentName="li">{` Browse all known customers, drivers, and proxy numbers.
`}</em>{` Create new rides.`}</li>
      <li parentName="ul">{`Listen on our webhook URLs for the following:
`}<em parentName="li">{` Listens for POST requests on `}<inlineCode parentName="em">{`/webhook`}</inlineCode>{` to handle any SMS messages
forwarded to the web server from our VMNs by the MessageBird API.
`}</em>{` Listens for GET requests on `}<inlineCode parentName="li">{`/webhook-voice`}</inlineCode>{` to handle any voice calls
forwarded to the web server from our VMNs by the MessageBird API.`}</li>
    </ul>
    <p>{`Our web server code can be found in the following locations in the sample code repository:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`views/`}</inlineCode>{`: This contains all our Go HTML templates. `}<inlineCode parentName="li">{`default.gohtml`}</inlineCode>{` contains the code for our base layout, while `}<inlineCode parentName="li">{`landing.gohtml`}</inlineCode>{` contains the code for our landing page template. When rendered, `}<inlineCode parentName="li">{`landing.gohtml`}</inlineCode>{` uses the Go HTML templating syntax to pull data from the struct (of type `}<inlineCode parentName="li">{`RideSharingDB`}</inlineCode>{`) that we pass when executing the template.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`routes.go`}</inlineCode>{`: Contains our route handlers. Here, we'll be writing code that handles the POST and GET requests that our web server receives, as well as send MessageBird SMS messages and make voice calls when needed.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`routeHelpers.go`}</inlineCode>{`: Contains code for helpers that we use in `}<inlineCode parentName="li">{`routes.go`}</inlineCode>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`main.go`}</inlineCode>{`: We'll need to add code here that initializes our database, defines our routes, and starts the web server.`}</li>
    </ul>
    <p>{`In this section, we'll cover the following topics:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/masked-numbers-go#stubbing-out-routes"
        }}>{`Stubbing out routes`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/masked-numbers-go#writing-a-template-rendering-helper"
        }}>{`Writing a template rendering helper`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/masked-numbers-go#building-an-admin-interface"
        }}>{`Building an admin interface`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/masked-numbers-go#writing-a-proxy-number-availability-helper"
        }}>{`Writing a proxy number availability helper`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/masked-numbers-go#writing-a-helper-to-send-sms-messages"
        }}>{`Writing a helper to send SMS messages`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/masked-numbers-go#writing-our-message-webhook-handler"
        }}>{`Writing our message webhook handler`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/masked-numbers-go#writing-our-voice-call-webhook-handler"
        }}>{`Writing our voice call webhook handler`}</a></li>
    </ul>
    <h3>{`Stubbing out routes`}</h3>
    <p>{`First, we'll stub out our routes in `}<inlineCode parentName="p">{`main.go`}</inlineCode>{`. Rewrite your `}<inlineCode parentName="p">{`main()`}</inlineCode>{` block in `}<inlineCode parentName="p">{`main.go`}</inlineCode>{` to look like the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`// main.go

// Remember to add the \`net/http\` package to your import statement.
func main() {
    dbdata := new(RideSharingDB)
    initExampleDB()

    mb := messagebird.New(os.Getenv("MESSAGEBIRD_API_KEY"))

    mux := http.NewServeMux()
    mux.Handle("/", landing(dbdata))
    mux.Handle("/createride", createRideHandler(dbdata, mb))
    mux.Handle("/webhook", messageHookHandler(dbdata, mb))
    mux.Handle("/webhook-voice", voiceHookHandler(dbdata, mb))

    port := ":8080"
    log.Println("Serving on", port)
    err := http.ListenAndServe(port, mux)
    if err != nil {
        log.Fatal(err)
    }
}
`}</code></pre>
    <p>{`Here, we've:`}</p>
    <ul>
      <li parentName="ul">{`Initialized a `}<inlineCode parentName="li">{`dbdata`}</inlineCode>{` struct that uses our `}<inlineCode parentName="li">{`RideSharingDB`}</inlineCode>{` type. We'll be passing this to our handlers to update and display in rendered views.`}</li>
      <li parentName="ul">{`We've also initialized our MessageBird Go client, and saved it as `}<inlineCode parentName="li">{`mb`}</inlineCode>{`. We'll also pass this to our handlers to make requests to the MessageBird API.`}</li>
      <li parentName="ul">{`Then, we stub out four routes:
`}<em parentName="li">{` `}<inlineCode parentName="em">{`/`}</inlineCode>{`: This is our default route, and will be handled by `}<inlineCode parentName="em">{`landing()`}</inlineCode>{`.
`}</em>{` `}<inlineCode parentName="li">{`/createride`}</inlineCode>{`: This is the route for creating new rides,
and will be handled by `}<inlineCode parentName="li">{`createRideHandler()`}</inlineCode>{`.
`}<em parentName="li">{` `}<inlineCode parentName="em">{`/webhook`}</inlineCode>{`: This is the route on which we'll be listening for
POST requests from the MessageBird server when one of our VMNs
receives an SMS message, and will be handled by `}<inlineCode parentName="em">{`messageHookHandler()`}</inlineCode>{`.
`}</em>{` `}<inlineCode parentName="li">{`/webhook-voice`}</inlineCode>{`: This is the route on which we'll be listening for
GET requests from the MessageBird server when one of our VMNs
receives a call, and will be handled by `}<inlineCode parentName="li">{`voiceHookHandler()`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`With all that done, we then initialize our web server with by calling
`}<inlineCode parentName="li">{`http.ListenAndServe()`}</inlineCode>{`.`}</li>
    </ul>
    <h3>{`Writing a template rendering helper`}</h3>
    <p>{`The code that loads and executes our templates to render a view can be offloaded to a helper, that we'll write in our `}<inlineCode parentName="p">{`routeHelpers.go`}</inlineCode>{` file.`}</p>
    <p>{`In there, we've written our `}<inlineCode parentName="p">{`renderDefaultTemplate()`}</inlineCode>{` helper:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`// routeHelpers.go

// ...

func renderDefaultTemplate(w http.ResponseWriter, thisView string, data interface{}) {
    renderthis := []string{thisView, "views/layouts/default.gohtml"}
    t, err := template.ParseFiles(renderthis...)
    if err != nil {
        log.Fatal(err)
    }
    err = t.ExecuteTemplate(w, "default", data)
    if err != nil {
        log.Fatal(err)
    }
}
`}</code></pre>
    <p>{`Using this, we can render a view in a `}<inlineCode parentName="p">{`http.HandlerFunc()`}</inlineCode>{` by writing `}<inlineCode parentName="p">{`renderDefaultTemplate(w, <template-file-to-render>, <data-to-display>)`}</inlineCode>{`. For example, for our `}<inlineCode parentName="p">{`landing()`}</inlineCode>{` handler, we write:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`func landing(dbdata *RideSharingDB) http.HandlerFunc {
    return func(w http.ResponseWriter, r *http.Request) {
        // ...
        renderDefaultTemplate(w, "views/landing.gohtml", dbdata)
    }
}
`}</code></pre>
    <p>{`Writing our `}<inlineCode parentName="p">{`renderDefaultTemplate()`}</inlineCode>{` helper this way also means that we can use the same line of code to update the page whenever whenever our ridesharing database is updated:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`// Example
func updateExample(dbdata *RideSharingDB) http.HandlerFunc {
    return func(w http.ResponseWriter, r *http.Request) {
        db, _ := dbdata.loadDB()
        if r.Method == "POST" {
            // Get a message from POST data.
            r.ParseForm()
            // Copies message to our dbdata struct Message field.
            message := r.FormValue("text_from_POST_submission")
            dbdata.Message = message
            // Render view, with updated dbdata struct.
            renderDefaultTemplate(w, "views/landing.gohtml", dbdata)
            // Must return, or handler will instruct our application to
            // continue running subsequent code.
            return
        }
        renderDefaultTemplate(w, "views/landing.gohtml", dbdata)
    }
}
`}</code></pre>
    <h3>{`Building an admin interface`}</h3>
    <p>{`Next, we'll create the administrator's interface for our web application. To keep things brief, we won't cover the sample code in that much detail. To check the full code sample, go to the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/masked-numbers-guide-go"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`.`}</p>
    <p>{`Our `}<inlineCode parentName="p">{`landing.gohtml`}</inlineCode>{` needs to render the following fields from our `}<inlineCode parentName="p">{`dbdata *RideSharingDB`}</inlineCode>{` struct for our admin interface:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`dbdata.Customers`}</inlineCode>{`: Our list of known customers is displayed as a dropdown menu from which an administrator can select a customer when creating a new ride.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`dbdata.Drivers`}</inlineCode>{`: Our list of known drivers is displayed as a dropdown menu from which an administrator can select a drivers when creating a new ride.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`dbdata.ProxyNumbers`}</inlineCode>{`: Our list of VMNs in our proxy number pool is displayed as a table. Our ridesharing service should randomly assign an available proxy number when a new ride is created.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`dbdata.Rides`}</inlineCode>{`: Our list of rides is displayed as a table.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`dbdata.Messages`}</inlineCode>{`: This should contain any messages, usually error messages, that we want to display on our rendered view. By default, this should be set to an empty string value (`}<inlineCode parentName="li">{`""`}</inlineCode>{`).`}</li>
    </ul>
    <p>{`Our `}<inlineCode parentName="p">{`"/"`}</inlineCode>{` route, which renders our admin interface, should do only two things:`}</p>
    <p><em parentName="p">{`1. Load our ridesharing database.
`}</em>{`2. Execute and render our templates, having passed in data we've loaded from our database. Our `}<inlineCode parentName="p">{`renderDefaultTemplate()`}</inlineCode>{` helper `}<a parentName="p" {...{
        "href": "/tutorials/masked-numbers-go#write-template-rendering-helper"
      }}>{`helps us with this`}</a>{`.`}</p>
    <p>{`Other route handlers that display a page follow a similar execution path. For example, our `}<inlineCode parentName="p">{`/createride`}</inlineCode>{` route does the following:`}</p>
    <p><em parentName="p">{`1. Load our ridesharing database.
`}</em>{`2. Collects data submitted through a POST request.
`}<em parentName="p">{`3. Updates our ridesharing database.
`}</em>{`4. Re-loads our ridesharing database.
`}<em parentName="p">{`5. Notifies the customer and driver that they've been assigned a new ride and VMN.
`}</em>{`6. Executes and renders our templates, having passed in updated data we've loaded from our database.`}</p>
    <p>{`In the process above,º only steps 2, 3, and 5 require new code.`}</p>
    <p>{`For more details on how we do this for the rest of the routes and handlers, simply see the sample code in the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/masked-numbers-guide-go"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`.`}</p>
    <h3>{`Writing a proxy number availability helper`}</h3>
    <p>{`In our `}<inlineCode parentName="p">{`routes.go`}</inlineCode>{` file, the handler for our `}<inlineCode parentName="p">{`/createride`}</inlineCode>{` route creates a new ride by reading submitted form data that contains a customer ID and driver ID, and from that compiles the information we need for the new ride. Part of the information set that we need to create a ride is the VMN we should assign to the ride.`}</p>
    <p>{`The VMN assigned to the ride acts as a proxy number, which the customer or driver for that ride can call to contact the other party instead of contacting that party directly. We need to write a function that decides which VMN is available for assignment, and returns it for use by our application.`}</p>
    <p>{`In some proxy number systems, a unique VMN is assigned per user, or per transaction. We want to be a bit more economical than that, and instead use a proxy number system that assigns a unique VMN per set of customers and drivers.`}</p>
    <p>{`To illustrate, let's say we have customers A and B, and drivers C and D:`}</p>
    <ul>
      <li parentName="ul">{`A ride assigned to customer A and driver C will use VMN_1.`}</li>
      <li parentName="ul">{`A ride assigned to customer A and driver D will use VMN_2.`}</li>
      <li parentName="ul">{`A ride assigned to customer B and driver C will use VMN_3.`}</li>
      <li parentName="ul">{`But for a ride assigned to customer B and driver D, we can reuse VMN_1 because it hasn’t been previously associated with either customer B or driver D.`}</li>
    </ul>
    <p>{`By using this system, we can:`}</p>
    <ul>
      <li parentName="ul">{`Rotate VMNs, instead of having to keep a large pool of VMNs for unique assignments.`}</li>
      <li parentName="ul">{`Identify rides with a combination of a customer's phone number and the VMN used, or a driver's phone number and the VMN used. We'll use this to our advantage when writing our `}<inlineCode parentName="li">{`/webhook`}</inlineCode>{` and `}<inlineCode parentName="li">{`/webhook-voice`}</inlineCode>{` route handlers.`}</li>
    </ul>
    <p>{`To write our helper function, we'll start out with defining our function's inputs.
We know that we'll use this function in a handler, where we'll be getting the ID of one customer and one driver, so we'll write our helper function to take our database struct (`}<inlineCode parentName="p">{`dbdata *RideSharingDB`}</inlineCode>{`), a customer ID (`}<inlineCode parentName="p">{`customerID int`}</inlineCode>{`), and a driver ID (`}<inlineCode parentName="p">{`driverID int`}</inlineCode>{`), and returns a proxy number (of `}<inlineCode parentName="p">{`ProxyNumberType`}</inlineCode>{` type) or an error. Add the following code to the bottom of your `}<inlineCode parentName="p">{`routeHelpers.go`}</inlineCode>{` file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`// routeHelpers.go
// ...
func getAvailableProxyNumber(dbdata *RideSharingDB, customerID int, driverID int) (ProxyNumberType, error) {
    return ProxyNumberType{}, nil
}
`}</code></pre>
    <p>{`We also know that we can assign any VMN to the next ride if it’s the first ride in the database. Modify `}<inlineCode parentName="p">{`getAvailableProxyNumber()`}</inlineCode>{` to look like the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`// routeHelpers.go
// ...
func getAvailableProxyNumber(dbdata *RideSharingDB, customerID int, driverID int) (ProxyNumberType, error) {
    // If no rides, then return a random Proxy Number.
    if len(dbdata.Rides) == 0 {
        // Because Go doesn't read maps in sequence, we can use a for loop to select a random number
        for _, v := range dbdata.ProxyNumbers {
            return v, nil
        }
        // If we're here, then we've failed to get a proxy number; return error
        return (ProxyNumberType{}), fmt.Errorf("no available proxy numbers")
    }
    // If we're here, then we've failed to get a proxy number; return error
    return (ProxyNumberType{}), fmt.Errorf("no available proxy numbers")
}
`}</code></pre>
    <p>{`Next, we know that we want to identify rides by a combination of the customer's phone number, driver's phone number, and the VMN for that ride. Remember that extra struct field that we `}<a parentName="p" {...{
        "href": "/tutorials/masked-numbers-go#defining-the-structs-that-contain-data"
      }}>{`defined in our `}<inlineCode parentName="a">{`RideType`}</inlineCode>{` struct`}</a>{`, `}<inlineCode parentName="p">{`NumGrp`}</inlineCode>{`? If we go back to the `}<a parentName="p" {...{
        "href": "/tutorials/masked-numbers-go#load-data-into-data-structures"
      }}>{`Load Data into Data Structures`}</a>{` section, we'll see close to the bottom of our `}<inlineCode parentName="p">{`loadDB()`}</inlineCode>{` block that we have these lines of code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`// db.go
func (dbdata *RideSharingDB) loadDB() error {
    // ...
    thisRide.NumGrp = append(thisRide.NumGrp, []int{thisRide.ThisCustomer.ID, thisRide.ThisProxyNumber.ID})
    thisRide.NumGrp = append(thisRide.NumGrp, []int{thisRide.ThisDriver.ID, thisRide.ThisProxyNumber.ID})
    // ...
}
`}</code></pre>
    <p>{`This means that for every ride, we're populating its `}<inlineCode parentName="p">{`NumGrp`}</inlineCode>{` field with a list of `}<inlineCode parentName="p">{`[]int`}</inlineCode>{`s that tells us which combinations of customer IDs, driver IDs, and proxy number IDs that ride contains. This allows us to quickly check if a ride contains a given combination with the following `}<inlineCode parentName="p">{`containsNumGrp()`}</inlineCode>{` function:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`// routeHelpers.go
func getAvailableProxyNumber(/*...*/) (/*...*/){
    // ...
    // Checks if []int contains an int
    containsNumGrp := func(arr [][]int, findme []int) bool {
        for _, v := range arr {
            if reflect.DeepEqual(v, findme) {
                return true
            }
        }
        return false
    }
    // ...
}
// ...
`}</code></pre>
    <p>{`Next, we create a flat list of the contents of all the `}<inlineCode parentName="p">{`NumGrp`}</inlineCode>{` fields in our database, so that it’s easily accessible via a `}<inlineCode parentName="p">{`rideProxySets`}</inlineCode>{` variable:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`// routeHelpers.go
func getAvailableProxyNumber(/*...*/) (/*...*/){
    // ...
    var rideProxySets [][]int
    for _, v1 := range dbdata.Rides {
        for _, v := range v1.NumGrp {
            rideProxySets = append(rideProxySets, v)
        }
    }
    // ...
}
`}</code></pre>
    <p>{`Once that’s done, we're finally ready to perform the actual check. At the bottom of our `}<inlineCode parentName="p">{`getAvailableProxyNumber()`}</inlineCode>{` block, add the following lines of code just before the final return statement:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`// routeHelpers.go
func getAvailableProxyNumber(/*...*/) (/*...*/){
    //...
    for _, v2 := range dbdata.ProxyNumbers {
        // Check if both customer/driver+proxy number sets do not exist in current proxy sets
        if !containsNumGrp(rideProxySets, []int{customerID, v2.ID}) && !containsNumGrp(rideProxySets, []int{driverID, v2.ID}) {
            return v2, nil
        }
    }
    // If we end up here, then we've failed to get a proxy number
    return (ProxyNumberType{}), fmt.Errorf("no available proxy numbers")
}
// ...
`}</code></pre>
    <p>{`The final block of code should look like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`// routeHelpers.go
func getAvailableProxyNumber(dbdata *RideSharingDB, customerID int, driverID int) (ProxyNumberType, error) {
    if len(dbdata.Rides) == 0 {
        for _, v := range dbdata.ProxyNumbers {
            return v, nil
        }
        return (ProxyNumberType{}), fmt.Errorf("no available proxy numbers")
    }

    containsNumGrp := func(arr [][]int, findme []int) bool {
        for _, v := range arr {
            if reflect.DeepEqual(v, findme) {
                return true
            }
        }
        return false
    }

    var rideProxySets [][]int
    for _, v1 := range dbdata.Rides {
        for _, v := range v1.NumGrp {
            rideProxySets = append(rideProxySets, v)
        }
    }

    for _, v2 := range dbdata.ProxyNumbers {
        if !containsNumGrp(rideProxySets, []int{customerID, v2.ID}) && !containsNumGrp(rideProxySets, []int{driverID, v2.ID}) {
            return
        }
    }

    return (ProxyNumberType{}), fmt.Errorf("no available proxy numbers")
}
`}</code></pre>
    <p>{`In our `}<inlineCode parentName="p">{`routes.go`}</inlineCode>{` file, we call the `}<inlineCode parentName="p">{`getAvailableProxyNumber()`}</inlineCode>{` helper like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`// routes.go
// ...
availableProxy, err := getAvailableProxyNumber(dbdata, customerIDint, driverIDint)
if err != nil {
    dbdata.Message = fmt.Sprintf("We encountered an error: %v", err)
    log.Println(err)
    renderDefaultTemplate(w, "views/landing.gohtml", dbdata)
    return
}
// ...
`}</code></pre>
    <h3>{`Writing a helper to send SMS messages`}</h3>
    <p>{`We'll also want to write a helper for sending SMS messages using the MessageBird API, so that we can encapsulate the error handling inside a function call. At the bottom of `}<inlineCode parentName="p">{`routeHelpers.go`}</inlineCode>{`, add the following lines of code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`// routeHelpers.go
// mbError handles MessageBird REST API errors
func mbError(err error) {
    if err != nil {
        switch errResp := err.(type) {
        case messagebird.ErrorResponse:
            for _, mbError := range errResp.Errors {
                log.Printf("Error: %#v\\n", mbError)
            }
        }

        return
    }
}

// mbSender sends SMS messages
func mbSender(mb *messagebird.Client, originator string, recipient []string, msgbody string, params *sms.Params) {
    msg, err := sms.Create(
        mb,
        originator,
        recipient,
        msgbody,
        params,
    )
    if err != nil {
        mbError(err)
        log.Printf("Could not send sms notification to %s", recipient)
    } else {
        log.Print(msg)
    }
}
`}</code></pre>
    <p>{`Once you've done this, you can call the `}<inlineCode parentName="p">{`mbSender()`}</inlineCode>{` function to send SMS messages to
a destination number, like what we've done with `}<inlineCode parentName="p">{`createRideHandler()`}</inlineCode>{` in `}<inlineCode parentName="p">{`routes.go`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`func createRideHandler(/*...*/) http.HandlerFunc {
    // ...
    // Notify this customer
    mbSender(
        mb,
        availableProxy.Number,
        []string{dbdata.Customers[customerIDint].Number},
        fmt.Sprintf("%s will pick you up at %s. Reply to this message to contact the driver.", dbdata.Drivers[driverIDint].Name, dateTime),
        nil,
    )

    // Notify this driver
    mbSender(
        mb,
        availableProxy.Number,
        []string{dbdata.Drivers[driverIDint].Number},
        fmt.Sprintf("%s will pick you up at %s. Reply to this message to contact the driver.", dbdata.Customers[customerIDint].Name, dateTime),
        nil,
    )
    // ...
}
`}</code></pre>
    <h3>{`Writing our message webhook handler`}</h3>
    <p>{`Now, we'll write the handler that handles the POST requests we'll be getting from the MessageBird server when our VMNs receive an SMS message.`}</p>
    <p>{`Our webhook handler needs to do the following:`}</p>
    <p><em parentName="p">{`1. Load our ridesharing database.
`}</em>{`2. Check if we're receiving a POST request.
`}{`*`}{`3. If we're receiving a POST request, parse the form data submitted. When the MessageBird servers receives and forwards an SMS message to a defined webhook URL, our web application receives it as a map similar to the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`map[message_id:[7a76afeaef3743d28d0e2d9362xxxxxx] originator:[1613209xxxx] reference:[4774934xxxx] createdDatetime:[2018-09-24T08:30:59+00:00] id:[f91908b75f9e4b1fba3b96dc4499xxxx] message:[this is a test message] receiver:[1470800xxxx] body:[this is a test message] date:[1537806659] payload:[this is a test message] sender:[1613209xxxx] date_utc:[1537777859] recipient:[1470800xxxx]]
`}</code></pre>
    <p><em parentName="p">{`4. We check the parsed form data for an "originator" (sender of the message),
a "receiver" (the VMN that received the message), and a "payload" (the body of the sent SMS message).
`}</em>{`5. We figure out if the "originator" is a customer or driver. To do this, we'll
add two helper functions to `}<inlineCode parentName="p">{`routeHelpers.go`}</inlineCode>{` and call them in our handler:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`func checkIfCustomer(dbdata *RideSharingDB, checkme string) bool {
  for _, v := range dbdata.Customers {
    if v.Number == checkme {
      return true
    }
  }
  return false
}

func checkIfDriver(dbdata *RideSharingDB, checkme string) bool {
  for _, v := range dbdata.Drivers {
    if v.Number == checkme {
      return true
    }
  }
  return false
}
`}</code></pre>
    <p>{`*`}{`6. If the "originator" is a customer, then we call `}<inlineCode parentName="p">{`sms.Create()`}</inlineCode>{` to send the "payload" to the driver for that ride. If the "originator" is a driver, then we send the "payload" to the customer.`}</p>
    <p>{`The handler you'll end up writing should look like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`func messageHookHandler(dbdata *RideSharingDB, mb *messagebird.Client) http.HandlerFunc {
    return func(w http.ResponseWriter, r *http.Request) {
        err := dbdata.loadDB()
        if err != nil {
            w.WriteHeader(http.StatusInternalServerError)
            fmt.Fprintf(w, "Server encountered an error: %v", err)
            return
        }

        if r.Method == "POST" {
            // Read response from MessageBird REST API servers
            r.ParseForm()
            originator := r.FormValue("originator")
            receiver := r.FormValue("receiver")
            payload := r.FormValue("payload")

            // Check rides for proxy number used
            // Proxy number should be unique in list of rides
            for _, v := range dbdata.Rides {
                if v.ThisProxyNumber.Number == receiver {
                    switch {
                    case checkIfCustomer(dbdata, originator):
                        // forward message to driver
                        mbSender(
                            mb,
                            receiver,
                            []string{v.ThisDriver.Number},
                            payload,
                            nil,
                        )
                        return
                    case checkIfDriver(dbdata, originator):
                        // forward message to customer
                        mbSender(
                            mb,
                            receiver,
                            []string{v.ThisCustomer.Number},
                            payload,
                            nil,
                        )
                        return
                    default:
                        log.Printf("Could not find ride for customer/driver %s that uses proxy %s", originator, receiver)
                    }
                } else {
                    log.Printf("Unknown proxy number: %s", receiver)
                }
            }
            // Return any response, MessageBird won't parse this
            fmt.Fprint(w, "OK")
            return
        }
    }
}
`}</code></pre>
    <h3>{`Writing our voice call webhook handler`}</h3>
    <p>{`When the MessageBird servers receive a voice call on a VMN for which we've set up a `}<a parentName="p" {...{
        "href": "/tutorials/masked-numbers-go#connect-the-vmn-to-a-webhook-for-voice"
      }}>{`MessageBird voice flow`}</a>{` for earlier, it makes a GET request on the URL we've defined for that flow. When it makes that request, it expects an XML response that defines a `}<a parentName="p" {...{
        "href": "/api/voice-calling/#call-flows"
      }}>{`call flow`}</a>{`. That call flow contains instructions for MessageBird to make a voice call. If you want to check out more information on how to write XML call flows, you can go to the `}<a parentName="p" {...{
        "href": "/api/voice-calling/#call-flows"
      }}>{`MessageBird API Reference`}</a></p>
    <p>{`The handler that we're writing for the `}<inlineCode parentName="p">{`/webhook-voice`}</inlineCode>{` route needs to parse that GET request and respond with the correct XML call flow.`}</p>
    <p>{`Our handler should do the following:`}</p>
    <p><em parentName="p">{`1. Load our ridesharing database.
`}</em>{`2. Set our "Content-Type" HTTP header to `}<inlineCode parentName="p">{`application/xml`}</inlineCode>{`.
`}{`*`}{`3. Parse the GET request with `}<inlineCode parentName="p">{`r.ParseForm()`}</inlineCode>{`. When the MessageBird servers receives and forwards a voice call to a defined webhook URL, our web application receives it as a map similar to the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`map[callID:[2894efe1-63b7-4d37-b006-3aab7fxxxxxx] destination:[1470800xxxx] numberID:[272cca7c-c2d6-4781-9e92-168ba0xxxxxx] source:[1613209xxxx] variables:[{}]]
`}</code></pre>
    <p><em parentName="p">{`4. Using the "destination" (the VMN that received the voice call) and the "source" (the caller), we check if the "source" is a customer or driver.
`}</em>{`5. If the "source" is a customer, we respond with an XML call flow that transfers the call to the driver for that ride, and vice-versa. To do this, we write a response to the `}<inlineCode parentName="p">{`http.ResponseWriter`}</inlineCode>{` with the following lines of code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`// where we've saved the number to call as 'forwardToThisNumber'
fmt.Fprintf(w, "<?xml version='1.0' encoding='UTF-8'?><Transfer destination='%s' make='true' />", forwardToThisNumber)
return
`}</code></pre>
    <p>{`*`}{`6. If we cannot find the ride or any target party to transfer the call to, we respond with an XML call flow that tells the caller that the call transfer has failed.`}</p>
    <p>{`We should end up with a `}<inlineCode parentName="p">{`voiceHookHandler()`}</inlineCode>{` that looks like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`// routes.go
func voiceHookHandler(dbdata *RideSharingDB, mb *messagebird.Client) http.HandlerFunc {
    return func(w http.ResponseWriter, r *http.Request) {
        // XML-only response
        w.Header().Set("Content-Type", "application/xml")

        err := dbdata.loadDB()
        if err != nil {
            w.WriteHeader(http.StatusInternalServerError)
            fmt.Fprintf(w, "Server encountered an error: %v", err)
            return
        }

        r.ParseForm()
        proxyNumber := r.FormValue("destination")
        caller := r.FormValue("source")

        var forwardToThisNumber string

        transactionFailXML := fmt.Sprint("<?xml version='1.0' encoding='UTF-8'?>" +
            "<Say language='en-GB' voice='female'>Sorry, we cannot identify your transaction. " +
            "Please make sure you have call in from the number you registered.</Say><Hangup />")

        for _, v := range dbdata.Rides {
            if v.ThisProxyNumber.Number == proxyNumber {
                switch {
                case checkIfCustomer(dbdata, caller):
                    // Forward call to driver
                    forwardToThisNumber = v.ThisDriver.Number
                case checkIfDriver(dbdata, caller):
                    // Forward call to customer
                    forwardToThisNumber = v.ThisCustomer.Number
                default:
                    // Speaks transaction fail message and returns
                    fmt.Fprint(w, transactionFailXML)
                    log.Printf("Transfer to %s failed.", forwardToThisNumber)
                    return
                }
            } else {
                // Speaks transaction fail message and returns
                fmt.Fprint(w, transactionFailXML)
                log.Printf("Transfer to %s failed.", forwardToThisNumber)
                return
            }
        }
        // If we get to this point, assume all is in order and attempt to transfer the call
        log.Println("Transferring call to ", forwardToThisNumber)
        fmt.Fprintf(w, "<?xml version='1.0' encoding='UTF-8'?><Transfer destination='%s' make='true' />", forwardToThisNumber)
        return
    }
}
`}</code></pre>
    <h2>{`Testing`}</h2>
    <p>{`You’re done! It’s time to test your application. Before doing so, double-check if:`}</p>
    <ul>
      <li parentName="ul">{`You've set up at least one VMN.`}</li>
      <li parentName="ul">{`Your VMNs should have two flows—the first waits for SMS messages and forwards them as a POST request to our application; the second waits for voice calls, and when it receives one requests a call flow from our application.`}</li>
      <li parentName="ul">{`Your localtunnel.me tunnel is still running. Remember that whenever you start a fresh tunnel, you'll get a new URL, so you have to update the flows accordingly. You can also configure a more permanent URL using the `}<inlineCode parentName="li">{`-s`}</inlineCode>{` attribute with the `}<inlineCode parentName="li">{`lt`}</inlineCode>{` command.`}</li>
    </ul>
    <p>{`Are you ready? Awesome! To start your ridesharing application, open a new terminal session and run the following command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`go run *.go
`}</code></pre>
    <p>{`Go to http://localhost:8080 and select a customer, a driver, and create a ride. If everything is working, the phone numbers for the selected customer and driver should receive an SMS notification.`}</p>
    <p>{`If you send an SMS message from the customer's phone number to the VMN, that SMS message should be automatically forwarded to the driver's phone, and vice-versa. Similarly, using the customer's phone to call the assigned VMN would automatically forward that call to the driver's phone, and vice-versa.`}</p>
    <p>{`Awesome! You can now use the flow, code snippets, and UI examples from this tutorial as an inspiration to build your own application. Don't forget to download the code from the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/masked-numbers-guide-go"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`.`}</p>
    <p><strong parentName="p">{`Nice work!`}</strong>{` 🎉`}</p>
    <p>{`You've just built your own number masking system with MessageBird using Go!`}</p>
    <h2>{`Start building!`}</h2>
    <p>{`Want to build something similar but not quite sure how to get started? Please feel free to let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we'd love to help!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      