import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Location } from '@reach/router';

import { Button, SecondaryButton, Textarea } from '../shared';
import { COLOR } from '../../constants';
import { track } from '../../helpers/tracking';

const feedback_icons = {
  dislike: '/img/icons/thumbsDown.svg',
  like: '/img/icons/thumbsUp.svg',
};

const Question = styled.div`
  color: #637381;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0px;
  line-height: 18px;
`;

const InteractionItem = styled.div`
  margin: 0 8px;
`;

const Interaction = styled.div`
  display: inline-flex;
  padding: 0 8px;
  @media screen and (max-width: 768px) {
    margin: 0 -8px;
    margin-bottom: -16px;
    padding: 16px 0;
    display: flex;
  }
  ${({ block }) =>
    block &&
    `
    padding: 8px 0;
    margin-bottom: -16px;
    display: flex;
    width: 80%;
    flex-direction: column;
    ${InteractionItem} {
      margin: 8px 0;
      text-align: right;
    }
  `}
`;

Interaction.Item = InteractionItem;

const Scene = styled.div`
  display: flex;
  align-items: center;
  border-radius: 4px;
  ${({ block }) => block && 'flex-direction: column;'}
`;
const Icon = styled.img`
  display: block;
`;

const SceneStart = ({ onHelpful, onNotHelpful }) => (
  <Scene>
    <Question>Is this page useful?</Question>
    <Interaction>
      <Interaction.Item>
        <SecondaryButton small onClick={onHelpful}>
          <Icon src={feedback_icons.like} />
        </SecondaryButton>
      </Interaction.Item>
      <Interaction.Item>
        <SecondaryButton small onClick={onNotHelpful}>
          <Icon src={feedback_icons.dislike} />
        </SecondaryButton>
      </Interaction.Item>
    </Interaction>
  </Scene>
);

SceneStart.propTypes = {
  onHelpful: PropTypes.func.isRequired,
  onNotHelpful: PropTypes.func.isRequired,
};

const SceneRecommendations = ({ onSubmit }) => {
  const [review, setReview] = React.useState('');
  //block set flex-direction=column to display feedback input beneath
  return (
    <Scene block>
      <Question>Do you have any recommendations to improve our docs?</Question>
      <Interaction block>
        <Interaction.Item>
          <Textarea
            onChange={(e) => setReview(e.target.value)}
            value={review}
          />
        </Interaction.Item>
        <Interaction.Item>
          <Button small onClick={() => onSubmit(review)}>
            Submit feedback
          </Button>
        </Interaction.Item>
      </Interaction>
    </Scene>
  );
};

SceneRecommendations.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

const SceneReview = ({ onReview }) => (
  <Scene>
    <Question>Thanks for your feedback! Help us improve even more</Question>
    <Interaction>
      <Interaction.Item>
        <Button small onClick={onReview}>
          Review us on G2Crowd
        </Button>
      </Interaction.Item>
    </Interaction>
  </Scene>
);

SceneReview.propTypes = {
  onReview: PropTypes.func.isRequired,
};

const SceneFinish = () => (
  <Scene>
    <Question>Thanks for your feedback!</Question>
  </Scene>
);

const Feedback = ({ path }) => {
  const [scene, setScene] = React.useState('start');
  switch (scene) {
    case 'nothelpful':
      return (
        <SceneRecommendations
          onSubmit={(review) => {
            track('dev:feedbackSubmitted', {
              helpful: 'nothelpful',
              review,
              page: path,
            });
            setScene('finish');
          }}
        />
      );
    case 'helpful':
      return (
        <SceneReview
          onReview={() => {
            track('dev:feedbackSubmitted', {
              helpful: 'helpful',
              review: null,
              page: path,
            });
            setScene('finish');
            window.open(
              'https://www.g2crowd.com/products/messagebird/reviews',
              '_blank',
            );
          }}
        />
      );
    case 'finish':
      return <SceneFinish />;
    case 'start':
    default:
      return (
        <SceneStart
          onNotHelpful={() => setScene('nothelpful')}
          onHelpful={() => setScene('helpful')}
        />
      );
  }
};

Feedback.propTypes = {
  path: PropTypes.string.isRequired,
};

const FeedbackWithLocation = (props) => (
  <Location>
    {({ location: { pathname } }) => (
      <div {...props}>
        <Feedback path={pathname} />
      </div>
    )}
  </Location>
);

export default FeedbackWithLocation;
