import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/ApiRef.js";
import CodeSnippet from 'components/CodeSnippet';
import * as CODE from '../../constants/integrations';
import { Img } from 'components/shared';
import Notification from "../../components/Notification";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Integrations API`}</h1>
    <p>{`The MessageBird's Integration API provides endpoints for creating and fetching message templates.`}</p>
    <p>{`The Integration API uses `}<inlineCode parentName="p">{`HTTP verbs`}</inlineCode>{` and a `}<inlineCode parentName="p">{`RESTful`}</inlineCode>{` endpoints structure with an access key that is used as the API
Authorization. Request and response payloads are formatted as JSON using UTF-8 encoding and URL encoded values.`}</p>
    <hr></hr>
    <h2>{`Base URL`}</h2>
    <p>{`All the endpoints referenced in this documentation have the following base URL:`}</p>
    <CodeSnippet code={CODE.BASE_URL} mdxType="CodeSnippet" />
    <hr></hr>
    <h2>{`Authorization`}</h2>
    <p>{`You'll need to set an access key to authenticate yourself. You can create, manage, and retrieve your API keys
from the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`MessageBird Dashboard`}</a>{`.`}</p>
    <p>{`To provide the access key, set the `}<inlineCode parentName="p">{`Authorization`}</inlineCode>{` header in the form of `}<inlineCode parentName="p">{`AccessKey {accessKey}`}</inlineCode>{`.`}</p>
    <hr></hr>
    <h2>{`Formats and Headers`}</h2>
    <p>{`All API responses are in JSON. You're required to send the `}<inlineCode parentName="p">{`Accept`}</inlineCode>{` header with the value `}<inlineCode parentName="p">{`application/json`}</inlineCode>{` with each request.`}</p>
    <p>{`Both POST and PUT requests to the API must contain a JSON-formatted payload in the request body.`}</p>
    <hr></hr>
    <h2>{`Errors`}</h2>
    <p>{`MessageBird uses standard HTTP status codes to indicate success or failure of an API request:`}</p>
    <ul>
      <li parentName="ul">{`Codes in the `}<inlineCode parentName="li">{`2xx`}</inlineCode>{` range indicate that a request was processed successfully`}</li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`4xx`}</inlineCode>{` range indicates that there was a client side error - for example, due to authentication or a
missing or wrong parameter. Don't worry, the body of the response includes a JSON-formatted response that tells
you exactly what is wrong. If you're stuck, feel free to `}<a parentName="li" {...{
          "href": "https://www.messagebird.com/en/contact"
        }}>{`contact support`}</a>{`;
we're happy to help you out.`}</li>
      <li parentName="ul">{`A `}<inlineCode parentName="li">{`5xx`}</inlineCode>{` status code indicates that something went wrong on server-side, and you can retry using standard exponential
backoff`}</li>
    </ul>
    <hr></hr>
    <h2>{`Templates`}</h2>
    <p>{`Some platforms, like WhatsApp and Facebook, don't allow business to send messages outside the support window, which is
24hs after the end-user send an inbound message. The only message types allowed outside support window is template
message, which must be pre-approved by the platform. Using MessageBird's Integrations API, you can create, fetch and
delete your templates. With WhatsApp templates, they are created under a WhatsApp Business Account (WABA) and can only be used for
phone numbers within the same WABA.`}</p>
    <Notification type="INFO" mdxType="Notification">
    Currently the template management is only supported to WhatsApp platform.
    </Notification>
    <Notification type="WARNING" mdxType="Notification">
    Currently, templates created in the Facebook Templates Manager are not automatically created in Integrations API,
    and, therefore, they won't be available in other MessageBird products like Inbox and FlowBuilder, however,
    those templates can still be used for sending template messages through Conversations API.
    </Notification>
    <h3>{`Create template`}</h3>
    <p>{`Create a template.`}</p>
    <Notification type="WARNING" mdxType="Notification">
    Template categories are changing from March 15th - please see <a href="/api/integrations/#templatecategory-object">TemplateCategory</a>
    </Notification>
    <Notification type="INFO" mdxType="Notification">
    Templates can have maximum 1 header, 1 body, 1 footer and 2 buttons.
    </Notification>
    <Notification type="WARNING" mdxType="Notification">
    If you choose to provide examples for template components, then all of them should have the same number of examples.
    A template request with a mismatch on the number of examples provided across components will be rejected. 
    </Notification>
    <CodeSnippet code={CODE.CREATE_WHATSAPP_TEMPLATE_ENDPOINT} mdxType="CodeSnippet" />
    <h4>{`Request`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`name`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The name of template.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`language`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/integrations/#templatelanguage-object"
            }}>{`TemplateLanguage`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The language of the template.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`components`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Array of `}<a parentName="td" {...{
              "href": "/api/integrations/#templatecomponent-object"
            }}>{`TemplateComponent`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An array of template components.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`category`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/integrations/#templatecategory-object"
            }}>{`TemplateCategory`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The template category.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`wabaId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The ID of the WABA for which the template should be created. If not supplied, the first WABA linked to your account will be used`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`allowCategoryChange`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This param allows Meta to assign correct `}<inlineCode parentName="td">{`category`}</inlineCode>{` they determined to be appropriate. The param starts working from March 15, 2023`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`The following example illustrates how to create a template with an image header, a body, a footer and two buttons: a
phone number button and a URL button with a variable.`}</p>
    <CodeSnippet code={CODE.WHATSAPP_TEMPLATE_CREATE_REQUEST} mdxType="CodeSnippet" />
    <p>{`The template looks like the following.`}</p>
    <Img src="/img/screenshots/integrations/sample-template-with-components.png" alt="Sample template with components" width="400px" align="middle" mdxType="Img" />
    <h4>{`Response`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`name`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Template name.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`language`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/integrations/#templatelanguage-object"
            }}>{`TemplateLanguage`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The language of template.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`category`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/integrations/#templatecategory-object"
            }}>{`TemplateCategory`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The template category.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`components`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Array of `}<a parentName="td" {...{
              "href": "/api/integrations/#templatecomponent-object"
            }}>{`TemplateComponent`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An array of template components.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`status`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/integrations/#templatestatus-object"
            }}>{`TemplateStatus`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The template status, in this case: `}<inlineCode parentName="td">{`NEW`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`wabaId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The Template WABA ID.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`namespace`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The Tempalte WABA Namespace.`}</td>
        </tr>
      </tbody>
    </table>
    <CodeSnippet code={CODE.WHATSAPP_TEMPLATE_CREATE_RESPONSE} mdxType="CodeSnippet" />
    <h5>{`Response codes`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`201`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Template created, it can't be used yet because must be approved by Facebook.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`400`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The HTTP request is malformed, the details should be available in the HTTP response body.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`401`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The request is not authenticated, please check your access key.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`429`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Too many requests, please retry using standard exponential backoff`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`500`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Internal server error, please retry using standard exponential backoff`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Update template`}</h3>
    <p>{`Update a given template by name and language.`}</p>
    <Notification type="WARNING" mdxType="Notification">
    Template categories are changing from March 15th - please see <a href="/api/integrations/#templatecategory-object">TemplateCategory</a>
    </Notification>
    <Notification type="INFO" mdxType="Notification">
    Templates can have maximum 1 header, 1 body, 1 footer and 2 buttons.
    </Notification>
    <Notification type="WARNING" mdxType="Notification">
    If you choose to provide examples for template components, then all of them should have the same number of examples.
    A template request with a mismatch on the number of examples provided across components will be rejected.
    </Notification>
    <CodeSnippet code={CODE.UPDATE_WHATSAPP_TEMPLATE_ENDPOINT} mdxType="CodeSnippet" />
    <h4>{`Request`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`components`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Array of `}<a parentName="td" {...{
              "href": "/api/integrations/#templatecomponent-object"
            }}>{`TemplateComponent`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An array of template components.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`category`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/integrations/#templatecategory-object"
            }}>{`TemplateCategory`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The template category.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`wabaId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The ID of the WABA for which the template should be created. If not supplied, the first WABA linked to your account will be used`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`The following example illustrates how to update a template with an image header, a body, a footer and two buttons: a
phone number button and a URL button with a variable.`}</p>
    <CodeSnippet code={CODE.WHATSAPP_TEMPLATE_UPDATE_REQUEST} mdxType="CodeSnippet" />
    <h4>{`Response`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`name`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Template name.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`language`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/integrations/#templatelanguage-object"
            }}>{`TemplateLanguage`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The language of template.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`category`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/integrations/#templatecategory-object"
            }}>{`TemplateCategory`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The template category.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`components`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Array of `}<a parentName="td" {...{
              "href": "/api/integrations/#templatecomponent-object"
            }}>{`TemplateComponent`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An array of template components.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`status`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/integrations/#templatestatus-object"
            }}>{`TemplateStatus`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The template status, in this case: `}<inlineCode parentName="td">{`NEW`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`wabaId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The Template WABA ID.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`namespace`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The Tempalte WABA Namespace.`}</td>
        </tr>
      </tbody>
    </table>
    <CodeSnippet code={CODE.WHATSAPP_TEMPLATE_UPDATE_RESPONSE} mdxType="CodeSnippet" />
    <h5>{`Response codes`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`200`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Template updated.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`400`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The HTTP request is malformed, the details should be available in the HTTP response body.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`400`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Template not found.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`401`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The request is not authenticated, please check your access key.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`429`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Too many requests, please retry using standard exponential backoff`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`500`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Internal server error, please retry using standard exponential backoff`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`List templates`}</h3>
    <p>{`List all your templates.`}</p>
    <CodeSnippet code={CODE.LIST_TEMPLATES_ENDPOINT} mdxType="CodeSnippet" />
    <h4>{`Request`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`offset`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of objects to skip from the first.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No. Default: `}<inlineCode parentName="td">{`0`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`limit`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of maximum objects to return on each request. Maximum: `}<inlineCode parentName="td">{`50`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No. Default: `}<inlineCode parentName="td">{`50`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`wabaId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional filter for templates belonging to a specific WABA. Do not specify channelId if wabaId was provided.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`channelId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional filter for templates that can be sent via the specified channel. Do not specify wabaId if channelId was provided.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
      </tbody>
    </table>
    <CodeSnippet code={CODE.LIST_TEMPLATES_REQUEST_EXAMPLE} mdxType="CodeSnippet" />
    <h4>{`Response`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`offset`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of objects skipped.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`limit`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of maximum objects returned on each request.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`count`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of templates returned.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`totalCount`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of total templates that can be retrieved through pagination.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`items`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Array of `}<a parentName="td" {...{
              "href": "/api/integrations/#template-object"
            }}>{`Template`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`List of templates.`}</td>
        </tr>
      </tbody>
    </table>
    <CodeSnippet code={CODE.LIST_TEMPLATES_RESPONSE_EXAMPLE} mdxType="CodeSnippet" />
    <h3>{`List templates by name`}</h3>
    <p>{`List your templates given a name.`}</p>
    <CodeSnippet code={CODE.LIST_TEMPLATES_BY_NAME_ENDPOINT} mdxType="CodeSnippet" />
    <h4>{`Request`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`name`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The name of the template.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`wabaId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional filter for templates belonging to a specific WABA. If not supplied, the template will be fetched using the first WABA linked to your account. Do not specify channelId if wabaId was provided.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`channelId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional filter for templates that can be sent via the specified channel. If not supplied, the template will be fetched using the first WABA linked to your account. Do not specify wabaId if channelId was provided.`}</td>
        </tr>
      </tbody>
    </table>
    <CodeSnippet code={CODE.LIST_TEMPLATES_BY_NAME_REQUEST_EXAMPLE} mdxType="CodeSnippet" />
    <h4>{`Response`}</h4>
    <p>{`An array of `}<a parentName="p" {...{
        "href": "/api/integrations/#template-object"
      }}>{`Template`}</a>{` objects.`}</p>
    <CodeSnippet code={CODE.LIST_TEMPLATES_BY_NAME_RESPONSE_EXAMPLE} mdxType="CodeSnippet" />
    <h5>{`Response codes`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`200`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Success`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`400`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The HTTP request is malformed, the details should be available in the HTTP response body.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`401`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The request is not authenticated, please check your access key.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`404`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Template not found.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`429`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Too many requests, please retry using standard exponential backoff`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`500`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Internal server error, please retry using standard exponential backoff`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Fetch template by name and language`}</h3>
    <p>{`Fetch the template details given a name and language.`}</p>
    <CodeSnippet code={CODE.LIST_TEMPLATES_BY_NAME_AND_LANGUAGE_ENDPOINT} mdxType="CodeSnippet" />
    <h3>{`Request`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`name`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The name of the template.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`language`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/integrations/#templatelanguage-object"
            }}>{`TemplateLanguage`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The template language.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`wabaId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional filter for templates belonging to a specific WABA. If not supplied, the template will be fetched using the first WABA linked to your account. Do not specify channelId if wabaId was provided.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`channelId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional filter for templates that can be sent via the specified channel. If not supplied, the template will be fetched using the first WABA linked to your account. Do not specify wabaId if channelId was provided.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
      </tbody>
    </table>
    <CodeSnippet code={CODE.LIST_TEMPLATES_BY_NAME_AND_LANGUAGE_REQUEST_EXAMPLE} mdxType="CodeSnippet" />
    <h4>{`Response`}</h4>
    <p>{`A `}<a parentName="p" {...{
        "href": "/api/integrations/#template-object"
      }}>{`Template`}</a>{` object.`}</p>
    <CodeSnippet code={CODE.LIST_TEMPLATES_BY_NAME_AND_LANGUAGE_RESPONSE_EXAMPLE} mdxType="CodeSnippet" />
    <h5>{`Response codes`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`200`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Success`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`400`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The HTTP request is malformed, the details should be available in the HTTP response body.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`401`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The request is not authenticated, please check your access key.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`404`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Template not found.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`429`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Too many requests, please retry using standard exponential backoff`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`500`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Internal server error, please retry using standard exponential backoff`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Delete templates by name`}</h3>
    <p>{`It deletes a template and all its languages.`}</p>
    <CodeSnippet code={CODE.DELETE_TEMPLATE_BY_NAME_ENDPOINT} mdxType="CodeSnippet" />
    <h4>{`Request`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`name`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The name of the template.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`wabaId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional filter for templates belonging to a specific WABA. If not supplied, the template will be fetched using the first WABA linked to your account. Do not specify channelId if wabaId was provided.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`channelId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional filter for templates that can be sent via the specified channel. If not supplied, the template will be fetched using the first WABA linked to your account. Do not specify wabaId if channelId was provided.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
      </tbody>
    </table>
    <CodeSnippet code={CODE.DELETE_TEMPLATE_BY_NAME_REQUEST_EXAMPLE} mdxType="CodeSnippet" />
    <h4>{`Response`}</h4>
    <p>{`It doesn't provide any response unless there's an error.`}</p>
    <h5>{`Response codes`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`202`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Template scheduled for deletion`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`400`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The HTTP request is malformed, the details should be available in the HTTP response body.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`401`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The request is not authenticated, please check your access key.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`404`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Template not found.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`422`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://www.facebook.com/business/help/722393685250070"
            }}>{`WhatsApp sample templates`}</a>{` cannot be deleted.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`429`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Too many requests, please retry using standard exponential backoff`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`500`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Internal server error, please retry using standard exponential backoff`}</td>
        </tr>
      </tbody>
    </table>
    <h5>{`Response codes`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`202`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Template scheduled for deletion`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`400`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The HTTP request is malformed, the details should be available in the HTTP response body.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`401`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The request is not authenticated, please check your access key.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`404`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Template not found.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`422`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://www.facebook.com/business/help/722393685250070"
            }}>{`WhatsApp sample templates`}</a>{` cannot be deleted.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`429`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Too many requests, please retry using standard exponential backoff`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`500`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Internal server error, please retry using standard exponential backoff`}</td>
        </tr>
      </tbody>
    </table>
    <hr></hr>
    <h2>{`Objects Reference`}</h2>
    <h3>{`Template object`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Template unique UUID`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`name`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Template name. Must be less than 100 characters and can contain only letters, numbers and underscore.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`language`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/integrations/#templatelanguage-object"
            }}>{`TemplateLanguage`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Template language`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`status`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/integrations/#templatestatus-object"
            }}>{`TemplateStatus`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Template status`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`components`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`List of `}<a parentName="td" {...{
              "href": "/api/integrations/#templatecomponent-object"
            }}>{`TemplateComponent`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`List of components`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`category`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/integrations/#templatecategory-object"
            }}>{`TemplateCategory`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Template category`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`previousCategory`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/integrations/#templatecategory-object"
            }}>{`TemplateCategory`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Previous `}<inlineCode parentName="td">{`category`}</inlineCode>{` value before Meta's template approval. The param starts working from April 1, 2023`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`rejectedReason`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/integrations/#templaterejectedreason-object"
            }}>{`TemplateRejectedReason`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Rejection reason. Available if the template is rejected`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`wabaId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`WABA ID`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`namespace`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`WABA Namespace`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`createdAt`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Creation timestamp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`updatedAt`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Last updated timestamp`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`TemplateLanguage object`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Language`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`af`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Afrikaans`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`sq`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Albanian`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ar`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Arabic`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`az`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Azerbaijani`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`bn`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Bengali`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`bg`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Bulgarian`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ca`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Catalan`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`zh_CN`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Chinese (CHN)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`zh_HK`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Chinese (HKG)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`zh_TW`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Chinese (TAI)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`hr`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Croatian`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`cs`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Czech`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`da`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Danish`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`nl`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Dutch`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`en`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`English`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`en_GB`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`English (UK)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`en_US`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`English (US)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`et`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Estonian`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`fil`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Filipino`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`fi`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Finnish`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`fr`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`French`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`de`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`German`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`el`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Greek`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`gu`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gujarati`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`he`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Hebrew`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`hi`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Hindi`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`hu`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Hungarian`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Indonesian`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ga`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Irish`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`it`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Italian`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ja`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Japanese`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`kn`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kannada`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`kk`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kazakh`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ko`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Korean`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`lo`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Lao`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`lv`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Latvian`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`lt`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Lithuanian`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`mk`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Macedonian`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ms`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Malay`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`mr`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Marathi`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`nb`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Norwegian`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`fa`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Persian`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`pl`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Polish`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`pt_BR`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Portuguese (BR)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`pt_PT`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Portuguese (POR)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`pa`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Punjabi`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ro`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Romanian`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ru`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Russian`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`sr`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Serbian`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`sk`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Slovak`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`sl`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Slovenian`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`es`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Spanish`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`es_AR`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Spanish (ARG)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`es_ES`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Spanish (SPA)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`es_MX`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Spanish (MEX)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`sw`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Swahili`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`sv`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Swedish`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ta`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tamil`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`te`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Telugu`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`th`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Thai`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`tr`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Turkish`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`uk`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Ukrainian`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ur`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Urdu`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`uz`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Uzbek`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`vi`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Vietnamese`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`TemplateRejectedReason object`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ABUSIVE_CONTENT`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Abusive content`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`INVALID_FORMAT`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Invalid format`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`NONE`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`PROMOTIONAL`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Promotional`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`TAG_CONTENT_MISMATCH`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tag content mismatch`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`NON_TRANSIENT_ERROR`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Non transient error`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`TemplateStatus object`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`NEW`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`New template. It can't be used yet`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`APPROVED`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Approved template. It can be used to send template messages`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`PENDING`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Approval pending. It can't be used yet`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`REJECTED`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The template was rejected by Facebook`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`PENDING_DELETION`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The template is pending for deletion`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`DELETED`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The template is deleted`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`TemplateCategory object`}</h3>
    <Notification type="WARNING" mdxType="Notification">
    The following template categories have been deprecated and will become read only on existing templates from April 1, 2023.
    </Notification>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`OTP`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One time password`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`TRANSACTIONAL`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Transactional`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`MARKETING`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Marketing`}</td>
        </tr>
      </tbody>
    </table>
    <Notification type="WARNING" mdxType="Notification">
    The following template categories will start working from  March 15, 2023.
    Please use the current categories when creating templates.
    </Notification>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`AUTHENTICATION`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Authentication`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`UTILITY`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Utility`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`MARKETING`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Marketing`}</td>
        </tr>
      </tbody>
    </table>
    <Notification type="WARNING" mdxType="Notification">
    The following template categories have been deprecated and will become read only on existing templates from September 2022.
    Please use the new categories when creating templates.
    </Notification>
    <h3>{`Deprecated TemplateCategory object`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ACCOUNT_UPDATE`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Account updates`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`PAYMENT_UPDATE`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Payment updates`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`PERSONAL_FINANCE_UPDATE`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Personal finance update`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`SHIPPING_UPDATE`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Shipping update`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`RESERVATION_UPDATE`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Reservation updates`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ISSUE_RESOLUTION`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Issue resolution updates`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`APPOINTMENT_UPDATE`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Appointment updates`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`TRANSPORTATION_UPDATE`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Transportation updates`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`TICKET_UPDATE`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Customer support ticket updates`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ALERT_UPDATE`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Alert updates`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`AUTO_REPLY`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Auto reply`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`TemplateComponent object`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/integrations/#templatecomponenttype-object"
            }}>{`TemplateComponentType`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Component type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`format`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/integrations/#templatecomponentformat-object"
            }}>{`TemplateComponentFormat`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Component format`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Only for header components`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Text of the message to be sent`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Only for body components`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`buttons`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`List of `}<a parentName="td" {...{
              "href": "/api/integrations/#templatecomponentbutton-object"
            }}>{`TemplateComponentButton`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`List of buttons`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Only for buttons components`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`example`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/integrations/#templateexample-object"
            }}>{`TemplateExample`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Example for the component that shows the intended use of the component`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`cards`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Array of `}<a parentName="td" {...{
              "href": "/api/integrations/#carouselcard-object"
            }}>{`CarouselCard`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`List of cards`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Only for carousel components`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`TemplateComponentType object`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`BODY`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Body component`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`HEADER`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Header component`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`FOOTER`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Footer component`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`BUTTONS`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Buttons component`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`CAROUSEL`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Carousel component`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`TemplateComponentFormat object`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`TEXT`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Text component format`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IMAGE`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Image component format`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`DOCUMENT`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Document component format`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`VIDEO`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Video component format`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`TemplateComponentButton object`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/api/integrations/#templatecomponentbuttontype-object"
            }}>{`TemplateComponentButtonType`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Button type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Text to be displayed on the button. Required when`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`url`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The URL which the user will be redirected when clicking the button`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Only for URL buttons`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`phone_number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The phone number which will be called when clicking the button`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Only for phone number buttons`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`example`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Array of string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A list of example values that shows the intended use of the button`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`URL and QUICK_REPLY buttons`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`TemplateComponentButtonType object`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`PHONE_NUMBER`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Phone call button`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`URL`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`URL button`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`QUICK_REPLY`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Quick reply button`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`TemplateExample object`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`header_text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Array of string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Example values for HEADER type components, TEXT format`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required for TEXT format when variables are used`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`body_text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Array of Array of string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Example set of values for the body text variables`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`header_url`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Array of string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Example values for IMAGE-VIDEO-DOCUMENT type components`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required for IMAGE-VIDEO-DOCUMENT format`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`CarouselCard object`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`components`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Array of `}<a parentName="td" {...{
              "href": "/api/integrations/#templatecomponent-object"
            }}>{`TemplateComponent`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`List of components`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      