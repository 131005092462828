import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import styled from 'styled-components';
import { LANGS } from '../../../constants';

const CodeDropdownSelect = styled(Select)`
  max-width: 120px;
  width: 100%;
  .codeLangDropdown__control,
  .codeLangDropdown__control:hover {
    border: 1px solid #cee3ff;
    border-radius: 4px;
  }
  .codeLangDropdown__placeholder {
    color: #9aa8bd;
    font-size: 14px;
    line-height: 18px;
  }
  .codeLangDropdown__indicator-separator {
    display: none;
  }
  .codeLangDropdown__dropdown-indicator {
    svg {
      fill: #0089ff;
    }
  }
`;

const colourStyles = {
  control: () => {
    return {
      backgroundColor: '#192953',
      width: '90px',
      height: '24px',
      display: 'flex',
      alignItems: 'baseline',
      border: '1px solid',
      borderRadius: '4px',
      cursor: 'pointer',
    };
  },
  singleValue: () => {
    return { color: 'white', fontSize: '12px' };
  },
  option: () => {
    return {
      color: 'white',
      padding: '2px 8px',
      fontSize: '12px',
      cursor: 'pointer',
    };
  },
  indicatorSeparator: () => {
    return {
      display: 'none',
    };
  },
  menu: () => {
    return {
      backgroundColor: '#192953',
      position: 'absolute',
      zIndex: '1',
      width: '90px',
    };
  },
};

const CodeLanguageDropdown = ({
  tutorials,
  language,
  languages,
  handleExtChange,
  ...props
}) => {
  const [lang, setLang] = React.useState('');
  const currentLanguage = language || (languages.includes(lang) && lang);
  React.useEffect(
    () =>
      languages &&
      setLang(
        languages.filter(({ value }) => value === language)[0] || languages[0],
      ),
    [language, languages],
  );
  function handleLangChange(option) {
    setLang(option);
    if (handleExtChange) {
      handleExtChange(option.value);
    }
  }
  if (tutorials) {
    return (
      <CodeDropdownSelect
        isSearchable={false}
        value={{
          value: currentLanguage,
          label: LANGS[currentLanguage],
        }}
        options={(languages || Object.keys(LANGS)).map((l) => ({
          value: l,
          label: LANGS[l],
        }))}
        onChange={handleLangChange}
        classNamePrefix="codeLangDropdown"
        {...props}
      />
    );
  } else {
    return (
      <Select
        isSearchable={false}
        value={{
          value: currentLanguage,
          label: LANGS[currentLanguage],
        }}
        options={(languages || Object.keys(LANGS)).map((l) => ({
          value: l,
          label: LANGS[l],
        }))}
        styles={colourStyles}
        onChange={handleLangChange}
        classNamePrefix="codeLangDropdown"
        {...props}
      />
    );
  }
};

CodeLanguageDropdown.propTypes = {
  language: PropTypes.string,
  languages: PropTypes.arrayOf(PropTypes.string),
  handleExtChange: PropTypes.func,
};

CodeLanguageDropdown.defaultProps = {
  handleExtChange: () => {},
  languages: Object.keys(LANGS),
  language: null,
};

export default CodeLanguageDropdown;
