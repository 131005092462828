export const ERROR_OBJECT = {
  json: `{
  "errors": [
    {
      "code": 1001,
      "description": "You are requesting with an invalid credential.",
      "parameter": null
    }
  ]
}`,
};

export const UPLOAD_FILE_ENDPOINT = {
  bash: `POST https://messaging.messagebird.com/v1/files`,
};

export const UPLOAD_FILE_REQUEST_EXAMPLE = {
  curl: `curl -X POST https://messaging.messagebird.com/v1/files \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -H 'Content-Disposition: attachment; filename="contract.pdf"' \\
  -H 'Content-Type: application/pdf' \\
  --data-binary @contract.pdf`,
};

export const UPLOAD_FILE_RESPONSE_EXAMPLE = {
  curl: `{"id":"99449497-535c-46c1-b57c-ddd193edfc5b","name":"contract.pdf"}`,
};

export const FETCH_FILE_ENDPOINT = {
  bash: `GET https://messaging.messagebird.com/v1/files/{id}`,
};

export const FETCH_FILE_REQUEST_EXAMPLE = {
  curl: `curl -X GET https://messaging.messagebird.com/v1/files/98415197-535d-46c1-b57c-ddd193eefc5b -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM'`,
};

export const DELETE_FILE_ENDPOINT = {
  bash: `DELETE https://messaging.messagebird.com/v1/files/{id}`,
};

export const DELETE_FILE_REQUEST_EXAMPLE = {
  curl: `curl -X DELETE https://messaging.messagebird.com/v1/files/98415197-535d-46c1-b57c-ddd193eefc5b -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM'`,
};
