import React, { createContext, useState } from 'react';

const PageHeadersContext = createContext({
  duplicateIds: {},
  setDuplicateIds: () => {
    return {};
  },
  headersIds: [],
  setHeadersIds: () => [],
  activeRoute: '',
  setActiveRoute: () => '',
});
export default PageHeadersContext;

export const HeadersContext = ({ children }) => {
  const [duplicateIds, setDuplicateIds] = useState({});
  const [headersIds, setHeadersIds] = useState([]);
  const [activeRoute, setActiveRoute] = useState('');
  return (
    <PageHeadersContext.Provider
      value={{
        duplicateIds,
        setDuplicateIds,
        headersIds,
        setHeadersIds,
        activeRoute,
        setActiveRoute,
      }}
    >
      {children}
    </PageHeadersContext.Provider>
  );
};
