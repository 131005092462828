import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Voice Messaging`}</h1>
    <p>{`MessageBird's Voice Messaging API enables you to transform text messages into voice messages to any country. Each voice message is identified by a unique random ID that can be used to check the status of the voice message. You can select from 26 languages and different attributes such as male or female voice, speaking rate, repeat, schedule, and more.`}</p>
    <p>{`The Voice Messaging API uses `}<inlineCode parentName="p">{`HTTP verbs`}</inlineCode>{` and a `}<inlineCode parentName="p">{`RESTful`}</inlineCode>{` endpoint structure with an access key that is used as the API Authorization framework. Request and response payloads are formatted as JSON—although we provide a GET alternative for requests—using UTF-8 encoding and URL encoded values.`}</p>
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'Send a TTS (text-to-speech) message',
      to: 'quickstarts/voice-messaging/send-outbound-voice-message-curl/'
    }, {
      name: 'Troubleshooting',
      to: 'quickstarts/voice-messaging/troubleshooting/'
    }, {
      name: 'Voice Messaging API Reference',
      to: '/api/voice-messaging'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      