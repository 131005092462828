import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Setting SMS reminders with MessageBird`}</h1>
    <h3>{`⏱ 15 min build time      ||      `}<a parentName="h3" {...{
        "href": "https://github.com/messagebirdguides/reminders-guide-python"
      }}>{`Download the Code`}</a></h3>
    <h2>{`Why build SMS appointment reminders?`}</h2>
    <p>{`In this MessageBird Developer Tutorial, you’ll learn how to build an SMS appointment reminder application and improve your users’ experience with the `}<a parentName="p" {...{
        "href": "/api/sms-messaging"
      }}>{`MessageBird SMS Messaging API`}</a>{`.`}</p>
    <p>{`Booking appointments online from a website or mobile app is quick and easy. Customers just have to select their desired date and time, personal details and hit a button. The problem, however, is that easy-to-book appointments are often just as easy to forget.`}</p>
    <p>{`For appointment-based services, no-shows are annoying and costly because of the time and revenue lost waiting for a customer instead of serving them or another customer. Timely SMS reminders simple and discrete nudges, which can go a long way in the prevention of costly no-shows.`}</p>
    <h2>{`Getting started`}</h2>
    <p>{`This sample application is built in Python and represents the order website for our fictitious online beauty salon, BeautyBird. To reduce the growing number of no-shows, BeautyBird now collects appointment bookings through a form on their website and schedules timely SMS reminders to be sent out three hours before the selected date and time.`}</p>
    <p>{`To run the full sample application, head over to the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/reminders-guide-python"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{` to clone or download the source code as a ZIP archive. You will need Python 2 or 3 to run the sample application, the `}<a parentName="p" {...{
        "href": "http://flask.pocoo.org/"
      }}>{`Flask framework`}</a>{` for making Python web applications, and the `}<a parentName="p" {...{
        "href": "http://pytz.sourceforge.net/"
      }}>{`pytz library`}</a>{` for handling calculations with local timezones.`}</p>
    <p>{`Now, to install the required packages, we use the Python package manager `}<a parentName="p" {...{
        "href": "https://pypi.org/project/pip/"
      }}>{`pip`}</a>{`. Let's open a console pointed at the directory into which you've placed the sample application and run the following commands to install the `}<a parentName="p" {...{
        "href": "https://github.com/messagebird/python-rest-api"
      }}>{`MessageBird SDK for PHP`}</a>{` and the libraries we mentioned above:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`pip install messagebird
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`pip install flask
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`pip install pytz
`}</code></pre>
    <h2>{`Configuring the MessageBird client`}</h2>
    <p>{`The MessageBird API key needs to be provided as a parameter. API keys can be created or retrieved from the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`API access (REST) tab`}</a>{` in the `}<em parentName="p">{`Developers section`}</em>{` of the MessageBird Dashboard. There you can create new API keys and manage your existing ones.`}</p>
    <p>{`If you are having any issues creating your API key, please reach out to `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we’ll make sure to help you out.`}</p>
    <p><strong parentName="p">{`Pro-tip:`}</strong>{` Hardcoding your credentials in the code is a risky practice that should never be used in production applications. A better method, also recommended by the `}<a parentName="p" {...{
        "href": "https://12factor.net/"
      }}>{`Twelve-Factor App Definition`}</a>{`, is to use environment variables. Our sample application stores the API key in a file named `}<inlineCode parentName="p">{`config_file.cfg`}</inlineCode>{` that contains the following line:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-env"
      }}>{`SECRET_KEY='YOUR-API-KEY'
`}</code></pre>
    <p>{`When we initialize the application in `}<inlineCode parentName="p">{`app.py`}</inlineCode>{`, we also tell it where to find configuration variables:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`app = Flask(__name__)
app.config.from_pyfile('config_file.cfg')
`}</code></pre>
    <p>{`The MessageBird Python client is loaded with the following statement in `}<inlineCode parentName="p">{`app.py`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`client = messagebird.Client(app.config['SECRET_KEY'])
`}</code></pre>
    <h2>{`Other configuration variables`}</h2>
    <p>{`We assume that BeautyBird's customers are all located in the same time zone (Europe / Amsterdam time for this application). We also assume that their phone numbers have the same country code, so that they don’t have to enter a country code in the appointment form.`}</p>
    <p>{`Since the time zone and country code apply throughout the application, we also define them in the configuration file `}<inlineCode parentName="p">{`config_file.cfg`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-env"
      }}>{`COUNTRY_CODE='31'
TIMEZONE='Europe/Amsterdam'
`}</code></pre>
    <p>{`Timezones should be specified as strings that exist in pytz's `}<a parentName="p" {...{
        "href": "http://pytz.sourceforge.net/#helpers"
      }}>{`list of timezones`}</a>{`. In `}<inlineCode parentName="p">{`app.py`}</inlineCode>{`, we load the time zone into a variable as follows:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`local_time = timezone(app.config['TIMEZONE'])
`}</code></pre>
    <p>{`We also define a datetime format that fits with the conventions of the customer's locality. In `}<inlineCode parentName="p">{`config_file.cfg`}</inlineCode>{`, the following line specifies the datetime format the customer will see in SMS messages and the confirmation page:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-env"
      }}>{`DATETIME_FORMAT='%m-%d-%Y %I:%M%p'
`}</code></pre>
    <p>{`We load this format into a variable in `}<inlineCode parentName="p">{`app.py`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`fmt = app.config['DATETIME_FORMAT']
`}</code></pre>
    <h2>{`Collecting user input`}</h2>
    <p>{`In order to send SMS messages to users, you need to collect their phone number as part of the booking process. We have created a sample form that asks the user for their name, desired treatment, number, date and time. For HTML forms it's recommended to use `}<inlineCode parentName="p">{`type="tel"`}</inlineCode>{` for the phone number input. You can see the template for the complete form in the file `}<inlineCode parentName="p">{`templates/index.html`}</inlineCode>{` and the route that drives it is defined as `}<inlineCode parentName="p">{`@app.route('/', methods=['GET', 'POST'])`}</inlineCode>{` in `}<inlineCode parentName="p">{`app.py`}</inlineCode>{`.`}</p>
    <h2>{`Storing appointments and scheduling reminders`}</h2>
    <p>{`The user's input is sent to the route `}<inlineCode parentName="p">{`@app.route('/', methods=['GET', 'POST'])`}</inlineCode>{` defined in `}<inlineCode parentName="p">{`app.py`}</inlineCode>{`. The implementation covers the following steps:`}</p>
    <h3>{`Step 1: Check their input`}</h3>
    <p>{`Validate that the user has entered a value for every field in the form. The fields are all marked as `}<inlineCode parentName="p">{`required`}</inlineCode>{` in `}<inlineCode parentName="p">{`index.html`}</inlineCode>{` for this reason.`}</p>
    <h3>{`Step 2: Check the appointment date and time`}</h3>
    <p>{`Confirm that the date and time are valid and at least three hours and five minutes in the future—BeautyBird won't take bookings on shorter notice. Also, since we want to schedule reminders three hours before the treatment, anything else doesn't make sense from a testing perspective. We use the `}<a parentName="p" {...{
        "href": "https://docs.python.org/2/library/datetime.html"
      }}>{`datetime`}</a>{` library that comes with Python and the pytz library we installed earlier to convert the local time entered by customers into UTC time to avoid complications with daylight savings. Calculations are done in UTC, then converted back into local time in the reminder messages and confirmation page.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`if request.method=="POST":

   #retrieve date and time, and convert into python datetime object
   appt_date = datetime.strptime(request.form['appt-date'], '%Y-%m-%d')
   appt_time = datetime.strptime(request.form['appt-time'], '%H:%M').time()
   appointmentDT = datetime.combine(appt_date,appt_time)

   #create datetime with local timezone, then convert to UTC for arithmetic
   #converting to UTC for calculations avoids complications from daylight savings
   local_appointmentDT = local_time.localize(appointmentDT)
   utc_appointmentDT = local_appointmentDT.astimezone(pytz.utc)

   #calculate reminder time and convert to RFC 3339 format. RFC 3339 format is required for scheduling the message when submitting request to MessageBird client.
   utc_reminderDT = utc_appointmentDT - timedelta(hours=3)
   #removes extraneous '+00:00' from end of ISO string and appends 'Z' indicating UTC timezone.
   iso_reminderDT = utc_reminderDT.isoformat("T")[:-6] + 'Z'

   #get current UTC time for checking that appointment date/time is at least 3:05 hours in future
   current_utc = pytz.utc.localize(datetime.utcnow())

   #check that date/time is at least 3:05 hours in the future; throw error if it isn't
   if (utc_appointmentDT - timedelta(hours=3, minutes=5) < current_utc):
       flash('Appointment time must be at least 3:05 hours from now')
       return render_template('index.html')
`}</code></pre>
    <h3>{`Step 3: Check their phone number`}</h3>
    <p>{`Check whether the phone number is correct. This can be done with the `}<a parentName="p" {...{
        "href": "/api/lookup#lookup-request"
      }}>{`MessageBird Lookup API`}</a>{`, which takes a phone number entered by a user, validates the format and returns information about the number, such as whether it is a mobile or fixed line number. This API doesn't enforce a specific format for the number but rather understands a variety of different variants for writing a phone number, for example using different separator characters between digits, giving your users the flexibility to enter their number in various ways.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`#check if phone number entered is valid, using country code from config file:
try:
   lookup = client.lookup(app.config['COUNTRY_CODE'] + request.form['phone'])
   #If lookup is successful but returned phone number types don't include 'mobile', prompt for new number
   if ('mobile' not in lookup.type):
       flash("The number you entered is not a mobile number. Please re-enter a mobile number.")
       return render_template('index.html')
`}</code></pre>
    <p>{`We prepend the country code specified in the configuration file to the phone number the customer enters. Then we try submitting the request to the MessageBird API; if the lookup succeeds but the phone number types returned don't include "mobile", we flash an error message.`}</p>
    <p>{`In our `}<inlineCode parentName="p">{`except`}</inlineCode>{` statement, we specify what happens if the lookup fails. We handle the following cases:`}</p>
    <ul>
      <li parentName="ul">{`An error (code 21) occurred, which means MessageBird was unable to parse the phone number.`}</li>
      <li parentName="ul">{`Another error code occurred, which means something else went wrong in the API.`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`except messagebird.client.ErrorException as e:
   if(e.errors[0].code == 21):
       flash('Please enter a valid phone number.')
   else:
       flash('Something went wrong while checking your phone number.')
    return render_template('index.html')
except: #miscellaneous exceptions
   flash('Something went wrong while checking your phone number.')
   return render_template('index.html')
`}</code></pre>
    <p>{`In either case, we display an appropriate message at the top of the web page. The area displaying error messages is defined in `}<inlineCode parentName="p">{`templates/index.html`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<!--If form submission results in error, flash error message -->
{% with messages = get_flashed_messages() %}
 {% if messages %}
   <ul class=flashes>
   {% for message in messages %}
     <li>{{ message }}</li>
   {% endfor %}
   </ul>
 {% endif %}
{% endwith %}
`}</code></pre>
    <h3>{`Step 4: Schedule the reminder`}</h3>
    <p>{`Earlier, we had calculated the time at which the reminder should be sent, and stored it in the variable `}<inlineCode parentName="p">{`iso_reminderDT`}</inlineCode>{`. We’ll now use this in our call to
MessageBird's API:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`#if phone number and entered date/time are valid, submit to MessageBird client
try:
   verify = client.message_create('BeautyBird',
                                   app.config['COUNTRY_CODE'] + request.form['phone'],
                                   request.form['customer_name'] + ', you have an appointment at BeautyBird at ' + appointmentDT.strftime(fmt),
                                   {'scheduledDatetime': iso_reminderDT})
`}</code></pre>
    <p>{`Let's break down the parameters that are set with this call of `}<inlineCode parentName="p">{`message.create()`}</inlineCode>{`:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`BeautyBird`}</inlineCode>{`: The sender ID. You can use a mobile number here, or an alphanumeric ID, like in the example.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`app.config['COUNTRY_CODE'] + request.form['phone']`}</inlineCode>{`: An array of phone numbers. We just need one number in this example.`}</li>
      <li parentName="ul">{`The body of the message that the customer will see. We format the datetime in this message according to the configuration variable specified in `}<inlineCode parentName="li">{`config_file.cfg`}</inlineCode>{` and loaded into the `}<inlineCode parentName="li">{`fmt`}</inlineCode>{` variables in `}<inlineCode parentName="li">{`app.py`}</inlineCode>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`scheduledDatetime`}</inlineCode>{`: This instructs MessageBird not to send the message immediately but at a given timestamp, which we've defined previously as the variable `}<inlineCode parentName="li">{`iso_reminderDT`}</inlineCode>{`.`}</li>
    </ul>
    <h3>{`Step 5: Store the appointment`}</h3>
    <p>{`The application's logic continues in the `}<inlineCode parentName="p">{`try`}</inlineCode>{` statement for the API call, where we store the appointment, then in an `}<inlineCode parentName="p">{`except`}</inlineCode>{` statement to handle failed API calls:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`    #push the appointment to the list of appointments created earlier. In a production application, the appointment should be entered in a database.
    appointment = { 'name' : request.form['customer_name'],
                   'treatment': request.form['treatment'],
                   'number': request.form['phone'],
                   'appointmentDT': utc_appointmentDT,
                   'reminderDT': utc_reminderDT}
    appointment_list.append(appointment)
    #redirect the user to the confirmation page
    return render_template('success.html',
                            name=request.form['customer_name'],
                           treatment=request.form['treatment'],
                           phone=request.form['phone'],
                           appointmentDT=appointmentDT.strftime(fmt))

#on failure, flash error on webpage.
except messagebird.client.ErrorException as e:
for error in e.errors:
   flash('  description : %s\\n' % error.description)
   return render_template('index.html')
`}</code></pre>
    <p>{`For the purpose of the sample application, we simply "persist" the appointment to a global list in memory. In production applications, you would write the appointment to a persistence layer such as a file or database. We also show a confirmation page, which is defined in `}<inlineCode parentName="p">{`templates/success.html`}</inlineCode>{`.`}</p>
    <h2>{`Testing`}</h2>
    <p>{`You’re done! To test your application, let's run the following command from your console:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`python app.py
`}</code></pre>
    <p>{`Then, point your browser at `}<inlineCode parentName="p">{`http://localhost:5000/`}</inlineCode>{` to see the form and schedule your appointment! If you've used a live API key, a message will arrive to your phone three hours before the appointment! But don't actually leave the house, this is just a demo. 😜`}</p>
    <p>{`Awesome! You can now use the flow, code snippets and UI examples from this tutorial as an inspiration to build your own SMS reminder system. Don't forget to download the code from the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/reminders-guide-python"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`.`}</p>
    <p><strong parentName="p">{`Nice work!`}</strong>{` 🎉`}</p>
    <p>{`You now have a running SMS appointment reminder application with MessageBird using Python!`}</p>
    <h2>{`Start building!`}</h2>
    <p>{`Want to start building your solution but not quite sure how to get started? Feel free to let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we'd love to help!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      