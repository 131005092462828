import styled from 'styled-components';
import { COLOR } from '../constants';

export const Button = styled.a`
  word-break: keep-all;
  word-wrap: normal;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  border-radius: 4px;
  background-color: #0089ff;
  box-shadow: 0 2px 4px 0 #eef4ff;
  padding: 16px 20px 14px;
  ${({ small }) =>
    small &&
    `
    padding: 9px 12px;
    line-height: 1em;
  `}
  cursor: pointer;
  color: #fff;
  &:hover,
  &:focus {
    color: #fff;
    background-color: #0065ef;
  }
`;

export const SecondaryButton = styled.a`
  background-color: transparent;
  transition: box-shadow 0.25s ease, border-color 0.25s ease;
  &:hover {
    box-shadow: 2px 2px 4px 0 #eef4ff;
    border-color: #cee3ff;
    cursor: pointer;
  }
`;

export const Text = styled.p`
  color: #687992;
  font-size: 16px !important;
  line-height: 25px;
  margin: 16px 0;
`;

export const Heading = styled.h1`
  line-height: 22px;
  font-weight: bold;
`;

export const HeadingSm = styled.p`
  color: #687992;
  font-size: 18px !important;
  line-height: 24px;
  text-align: center;
`;

export const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const CardWrap = styled(Flex)`
  margin-left: -15px;
  margin-right: -15px;
`;

export const CardContainer = styled.div`
  padding: 15px;
  flex: 1 1 50%;

  @media screen and (max-width: 479px) {
    flex: 1 1 100%;
  }
`;

export const Card = styled.div`
  padding: 28px;
  border: 1px solid #eef4ff;
  border-radius: 4px;
  min-height: 200px;
  cursor: pointer;
  transition: background-color 0.2s ease, box-shadow 0.2s ease;

  max-width: 320px;
  min-height: 210px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:hover {
    background-color: #e5efff;

    h5::after {
      opacity: 1;
    }
  }

  h5::after {
    content: url('/img/icons/arrow.svg');
    position: relative;
    left: 5px;
    top: 1px;
    opacity: 0;
    transition: opacity 0.2s ease;
  }
`;

export const CardTitle = styled.h5`
  color: #24374e;
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
  margin: 0;
  display: flex;
  align-items: center;
  span {
    margin-left: 16px;
    height: inherit;
    line-height: inherit;
  }
`;

export const Img = styled.img`
  margin: 40px 0;
  width: ${(props) => (props.width ? props.width : 'auto')};
  @media (max-width: 768px) {
    display: none;
  }
`;

export const Textarea = styled.textarea`
  border-radius: 4px;
  border: 1px solid ${COLOR.LIGHTBLUE};
  display: block;
  width: 100%;
  min-height: 161px;
  resize: none;
  padding: 4px;
`;
