import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Docs.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Troubleshooting Lookup API`}</h1>
    <p>{`MessageBird has multiple tools to enable you to quickly understand any unexpected behaviour between the Lookup API and your application.`}</p>
    <h2>{`General API Error Codes`}</h2>
    <p>{`If you received an error response and code from MessageBird, you can troubleshoot the error by exploring the general `}<a parentName="p" {...{
        "href": "/docs/handling-errors"
      }}>{`API Error Codes.`}</a></p>
    <p>{`In case of an API error, the body of the response includes a `}<inlineCode parentName="p">{`json`}</inlineCode>{` formatted response that tells you exactly what is wrong.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      