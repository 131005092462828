import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Docs.tsx";
import Notification from 'components/Notification';
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Receive Voice Calls`}</h1>
    <p>{`In this Programmable Voice Calling API Quickstart, you'll learn how to handle inbound voice calls using webhooks, `}<a parentName="p" {...{
        "href": "https://www.messagebird.com/en/flow-builder"
      }}>{`Flow Builder`}</a>{`, and MessageBird’s Programmable Voice Calling API.`}</p>
    <p>{`We’ll walk you through the following steps:`}</p>
    <ol>
      <li parentName="ol">{`Purchase a Virtual Mobile Number from MessageBird`}</li>
      <li parentName="ol">{`Set up a Webhook Handler`}</li>
      <li parentName="ol">{`Set up a flow in Flow Builder`}</li>
    </ol>
    <h2>{`Step 1: Purchase your Voice-enabled Virtual Mobile Number`}</h2>
    <ol>
      <li parentName="ol">{`Go to `}<a parentName="li" {...{
          "href": "https://dashboard.messagebird.com/en/numbers"
        }}>{`Numbers`}</a>{` in the MessageBird Dashboard and click on `}<a parentName="li" {...{
          "href": "https://dashboard.messagebird.com/en/vmn/buy-number"
        }}>{`Buy a number`}</a></li>
      <li parentName="ol">{`Pick the country in which you and your customers are located, and make sure the Voice capability is selected.`}</li>
      <li parentName="ol">{`Choose one number from the selection`}</li>
    </ol>
    <h2>{`Step 2: Set up your Webhook Handler`}</h2>
    <p>{`To retrieve a Call Flow from a URL (i.e. set up a call webhook), you must use the HTTP `}<inlineCode parentName="p">{`GET`}</inlineCode>{` method in your application.`}</p>
    <p>{`When a person calls a virtual number assigned to such a URL via Flow Builder, you'll need to make an HTTP request to your application and retrieve the call flow - this will be what the caller hears on the other end of the call.`}</p>
    <p>{`Since it's a `}<inlineCode parentName="p">{`GET`}</inlineCode>{`, you'll need to send parameters in the query string.`}</p>
    <p>{`For most applications, you should only need the `}<inlineCode parentName="p">{`source`}</inlineCode>{` field - which indicates the phone number that's calling.`}</p>
    <p>{`Your application should return a call flow encoded as JSON or XML with the appropriate content type, for example, `}<inlineCode parentName="p">{`application/json`}</inlineCode>{` for a JSON-encoded call flow or `}<em parentName="p">{`application/xml`}</em>{` for an XML-encoded call flow.`}</p>
    <p>{`Here’s an example of an XML-encoded call flow with a single step:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<?xml version="1.0" encoding="UTF-8"?>
<Say language="en-GB" voice="female">Hello, thanks for calling!</Say>
`}</code></pre>
    <p>{`We recommend that you check the `}<a parentName="p" {...{
        "href": "/api/voice-calling"
      }}>{`Voice Calling API documentation`}</a>{` for a list of available step actions and options.`}</p>
    <h2>{`Step 3: Set up Flow Builder for inbound calls`}</h2>
    <p>{`Now, configure a simple flow using Flow Builder to handle inbound voice calls on a virtual number:`}</p>
    <ol>
      <li parentName="ol">{`Go to `}<a parentName="li" {...{
          "href": "https://dashboard.messagebird.com/en/flow-builder"
        }}>{`Flow Builder`}</a>{`, hit the button `}<inlineCode parentName="li">{`Create new flow`}</inlineCode>{` and then `}<inlineCode parentName="li">{`Create Custom Flow`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`Select `}<inlineCode parentName="li">{`Phone Call`}</inlineCode>{` then select the your number or numbers.`}</li>
      <li parentName="ol">{`Add a new step, choose `}<inlineCode parentName="li">{`Fetch call flow from URL`}</inlineCode>{`, and paste the same local-tunnel base URL into the form, but this time append `}<inlineCode parentName="li">{`/webhook-voice`}</inlineCode>{` to it.`}</li>
      <li parentName="ol">{`Hit `}<inlineCode parentName="li">{`Publish`}</inlineCode>{` to activate the flow.`}</li>
    </ol>
    <p>{`Congrats! You can now programmatically handle inbound voice calls using Webhooks, Flow Builder, and the Programmable Voice Calling API.`}</p>
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'Voice Calling API Use Case Tutorials and Sample Projects',
      to: '/tutorials?filter=voice'
    }, {
      name: 'Programmable Voice Calling API Reference',
      to: '/api/voice-calling'
    }, {
      name: 'Official MessageBird SDKs',
      to: '/libraries'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      