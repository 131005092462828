import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import { Img } from 'components/shared';
import Questions from 'components/Footer/Questions';
import Notification from 'components/Notification';
import Link from 'components/Link';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Send WhatsApp template messages with buttons`}</h1>
    <p>{`Conversations API provides the ability to our customer to send WhatsApp template messages with buttons. Please keep in
mind that, in order to send any template message, first you must have your template pre-approved by Facebook. You can
manage your templates using the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/whatsapp-template-manager"
      }}>{`WhatsApp Template Manager`}</a>{`.`}</p>
    <h2>{`Button types`}</h2>
    <p>{`Currently, there are two supported button types: quick reply and call to action.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Quick replies`}</strong>{`: enables customers to easily reply using pre-defined answer.The replies to these buttons will reference the original message using `}<Link to="/api/conversations/#replyto-object" mdxType="Link">{`ReplyTo`}</Link>{`.`}</li>
      <li parentName="ul"><strong parentName="li">{`Call to actions`}</strong>{`: enables customers to easily trigger pre-defined actions`}</li>
    </ul>
    <p>{`There are two types of call to action buttons: call to a phone number and visit website.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Call to a phone`}</strong>{`: enables customers to easily call to a pre-defined phone number`}</li>
      <li parentName="ul"><strong parentName="li">{`Visit website`}</strong>{`: enables customer to visit a pre-defined website`}</li>
    </ul>
    <Notification type="INFO" mdxType="Notification">
    Unfortunately is not possible yet to use variables in the pre-defined quick reply and call to a phone.
    </Notification>
    <h2>{`Starting simple: template message with buttons`}</h2>
    <p>{`Let's assume you have the following template message approved by Facebook in your
`}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/whatsapp-template-manager"
      }}>{`WhatsApp Template Manager`}</a>{`.`}</p>
    <Img src="/img/screenshots/quickstarts/whatsapp/template-with-buttons.png" alt="Template with buttons" width="800px" align="middle" mdxType="Img" />
    <p>{`In order to send a WhatsApp message using this template, you must perform the following HTTP request to Conversations API.
The following example uses the endpoint `}<inlineCode parentName="p">{`POST /v1/send`}</inlineCode>{`, however, you can also use the other endpoints for sending messages,
please check the `}<a parentName="p" {...{
        "href": "/api/conversations"
      }}>{`Conversations API reference`}</a>{` for details about the request.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-curl"
      }}>{`curl -X "POST" "https://conversations.messagebird.com/v1/send" \\
     -H 'Authorization: AccessKey <your-access-key>' \\
     -H 'Content-Type: application/json; charset=utf-8' \\
     -d $'{
  "content": {
    "hsm": {
      "namespace": "20a32cd4_f095_b0r0_d255_35677259aaxd",
      "language": {
        "code": "en"
      },
      "templateName": "buttons_test"
    }
  },
  "to": "+9999999999",
  "type": "hsm",
  "from": "0181d0bb76c54g9aae4309s822d35ba5"
}'
`}</code></pre>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`content.hsm.namespace`}</inlineCode>{` can be found in the WhatsApp templates Manager of your Facebook Business account, or, in case
you don't have access to it, please contact our `}<a parentName="li" {...{
          "href": "https://www.messagebird.com/en/contact"
        }}>{`support team`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`content.hsm.language.code`}</inlineCode>{` is the language which the template message should be sent`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`content.hsm.templateName`}</inlineCode>{` is the template name`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`to`}</inlineCode>{` is the phone number of the recipient`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`type`}</inlineCode>{` must be `}<inlineCode parentName="li">{`hsm`}</inlineCode>{` because this is a template message`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`from`}</inlineCode>{` must be your WhatsApp channel ID`}</li>
    </ul>
    <h2>{`Send a message with buttons and variables`}</h2>
    <p>{`The value of template messages is in the fact that you can use variables to customise them. Let's now assume you
have the following template message approved by Facebook in your
`}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/whatsapp-template-manager"
      }}>{`WhatsApp Template Manager`}</a>{`.`}</p>
    <Img src="/img/screenshots/quickstarts/whatsapp/template-with-buttons-and-variables.png" alt="Template with buttons and variables" width="800px" align="middle" mdxType="Img" />
    <p>{`In order to send a template message using this template, you must perform the following HTTP request to Conversations API.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-curl"
      }}>{`curl -X "POST" "https://conversations.messagebird.com/v1/send" \\
     -H 'Authorization: AccessKey <access-key>' \\
     -H 'Content-Type: application/json; charset=utf-8' \\
     -d $'{
  "content": {
    "hsm": {
      "language": {
        "code": "en"
      },
      "namespace": "20a32cd4_f095_b0r0_d255_35677259aaxd",
      "params": [
        {
          "default": "Robert"
        }
      ],
      "templateName": "issue_resolution_confirmation"
    }
  },
  "to": "+9999999999",
  "type": "hsm",
  "from": "0181d0bb76c54g9aae4309s822d35ba5"
}'
`}</code></pre>
    <p>{`As you can see, the only different is that, as this template needs variables, we must provide a list of `}<inlineCode parentName="p">{`params`}</inlineCode>{`. The
number of items in `}<inlineCode parentName="p">{`params`}</inlineCode>{` must match the number of variables in the template, otherwise your template message will
be rejected.`}</p>
    <h2>{`Send a message with a variable URL button`}</h2>
    <p>{`The previous example illustrates how to send a message using a template with static buttons, however, variables can
be used in URL buttons as well. Let's assume you have the following template message approved by Facebook in your
`}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/whatsapp-template-manager"
      }}>{`WhatsApp Template Manager`}</a>{`.`}</p>
    <Img src="/img/screenshots/quickstarts/whatsapp/template-with-variable-url-button.png" alt="Template with a variable URL button" width="800px" align="middle" mdxType="Img" />
    <p>{`Please note that this template has a Call To Action button, with type Visit Website and the URL type is Dynamic, which
means the Website URL contains a variable (`}<inlineCode parentName="p">{`{{1}}`}</inlineCode>{`) at the end of the URL. In this case, the URL is `}<inlineCode parentName="p">{`http://www.messagebird.com/feedback/{{1}}`}</inlineCode>{`. The variable `}<inlineCode parentName="p">{`{{1}}`}</inlineCode>{` allows
us to customise its value whenever sending a message.`}</p>
    <Notification type="INFO" mdxType="Notification">
    For URL buttons, only one variable can be used and it can only be placed at the end of the URL.
    </Notification>
    <p>{`In order to send a template message using this template, you must perform the following HTTP request to Conversations API.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-curl"
      }}>{`curl -X "POST" "https://conversations.messagebird.com/v1/send" \\
     -H 'Authorization: AccessKey <access-key>' \\
     -H 'Content-Type: application/json; charset=utf-8' \\
     -d $'{
  "content": {
    "hsm": {
      "language": {
        "code": "en"
      },
      "components": [
        {
          "type": "button",
          "sub_type": "url",
          "parameters": [
            {
              "type": "text",
              "text": "23493282245"
            }
          ]
        }
      ],
      "namespace": "20a32cd4_f095_b0r0_d255_35677259aaxd",
      "templateName": "test_template_with_dynamic_url_2"
    }
  },
  "to": "+9999999999",
  "type": "hsm",
  "from": "0181d0bb76c54g9aae4309s822d35ba5"
}'
`}</code></pre>
    <p>{`Observe that, in order to provide a value to the variable inside the URL, the request contains a JSON object inside the
`}<inlineCode parentName="p">{`content.components`}</inlineCode>{`.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`type`}</inlineCode>{` defines the type of the component, in this case `}<inlineCode parentName="li">{`button`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`sub_type`}</inlineCode>{` defines the type of button, in this case a `}<inlineCode parentName="li">{`url`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`parameters`}</inlineCode>{` contains a list of parameters to provide values to variables`}
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`type`}</inlineCode>{` defines the type of value, in this case `}<inlineCode parentName="li">{`text`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`text`}</inlineCode>{` provides the value that must be used in the variable. In this case, the full URL is `}<inlineCode parentName="li">{`http://www.messagebird.com/feedback/23493282245`}</inlineCode></li>
        </ul>
      </li>
    </ul>
    <h1>{`Send authentication messages`}</h1>
    <p>{`Starting from 1st May 2023 Meta released a new policy for WhatsApp Business API.`}<br />{`
This policy requires all businesses to use WhatsApp's preset authentication message templates for all authentication use cases.`}<br />{`
Authentication templates enable businesses to verify users using one-time passcodes (4-8 digit alphanumeric codes).`}<br />{`
These templates can be used for various login steps (e.g., account verification, recovery, integrity challenges). `}<br />{`
Businesses must use WhatsApp's preset authentication message templates. They can include optional add-ons like security disclaimers and expiry warnings. `}<br />{`
Templates should have a one-time password button (copy code or one-tap).`}<br />{`
Authentication templates don't allow URLs, media, emojis, and have a 15-character limit for parameters.`}<br />{`
Let's now assume you have the following template message approved by Facebook in your
`}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/whatsapp-template-manager"
      }}>{`WhatsApp Template Manager`}</a>{`.`}<br /></p>
    <Img src="/img/screenshots/quickstarts/whatsapp/whatsapp-authentication-template.png" alt="Authentication template" width="800px" align="middle" mdxType="Img" />
    <p>{`In order to send a template message using this template, you must perform the following HTTP request to Conversations API.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-curl"
      }}>{`curl -X "POST" "https://conversations.messagebird.com/v1/send" \\
     -H 'Authorization: AccessKey <access-key>' \\
     -H 'Content-Type: application/json; charset=utf-8' \\
     -d $'{
  "to": "+3197006520256",
  "from": "320b489e-d1dc-409a-900d-fd5812f8078a",
  "type": "hsm",
  "content": {
    "hsm": {
      "namespace": "20332cd4_f095_b080_d255_35677159aaff",
      "templateName": "auth_one_tap_otp_button",
      "language": {
        "code": "en_US"
      },
      "components": [
        {
          "type": "body",
          "parameters": [
            {
              "type": "text",
              "text": "12345"
            }
          ]
        },
        {
          "type": "button",
          "sub_type": "url",
          "index": 0,
          "parameters": [
            {
              "type": "text",
              "text": "12345"
            }
          ]
        }
      ]
    }
  }
}
'
`}</code></pre>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`content.hsm.namespace`}</inlineCode>{` can be found in the WhatsApp templates Manager of your Facebook Business account, or, in case
you don't have access to it, please contact our `}<a parentName="li" {...{
          "href": "https://www.messagebird.com/en/contact"
        }}>{`support team`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`content.hsm.language.code`}</inlineCode>{` is the language which the template message should be sent`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`content.hsm.templateName`}</inlineCode>{` is the template name`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`to`}</inlineCode>{` is the phone number of the recipient`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`type`}</inlineCode>{` must be `}<inlineCode parentName="li">{`hsm`}</inlineCode>{` because this is a template message`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`from`}</inlineCode>{` must be your WhatsApp channel ID`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`content.hsm.components`}</inlineCode>{` contains a list of components that will be used to replace the variables in the template.`}
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`type`}</inlineCode>{` defines the type of the component, in this case `}<inlineCode parentName="li">{`body`}</inlineCode>{` and `}<inlineCode parentName="li">{`button`}</inlineCode>{`. Both components must be present in the template`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`sub_type`}</inlineCode>{` defines the type of button, in this case a `}<inlineCode parentName="li">{`url`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`parameters`}</inlineCode>{` contains a list of parameters to provide values to variables`}
            <ul parentName="li">
              <li parentName="ul"><inlineCode parentName="li">{`type`}</inlineCode>{` defines the type of value, in this case `}<inlineCode parentName="li">{`text`}</inlineCode></li>
              <li parentName="ul"><inlineCode parentName="li">{`text`}</inlineCode>{` provides the value that must be used in the variable. For both `}<inlineCode parentName="li">{`body`}</inlineCode>{` and `}<inlineCode parentName="li">{`button`}</inlineCode>{` components, the value is should be the same.`}</li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      