import styled from 'styled-components';
import { bool } from 'prop-types';
import { COLOR } from '../../../constants';

const SubItems = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  color: ${COLOR.MIDGREY};
  overflow: auto;
  transition: max-height 0.2s ease, opacity 0.4s ease;

  opacity: ${({ isExpanded }) => (isExpanded ? 1 : 0)};
  max-height: ${({ isExpanded, height }) => (isExpanded ? height : 0)}px;
  padding-left: ${({ nested }) => nested && '16px'};

  > a:hover {
    color: ${COLOR.GREY};
  }
`;

SubItems.propTypes = {
  isExpanded: bool,
};

SubItems.defaultProps = {
  isExpanded: true,
};

export default SubItems;
