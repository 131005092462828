import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Setting SMS notifications with MessageBird`}</h1>
    <h3>{`⏱ 15 min build time      ||      `}<a parentName="h3" {...{
        "href": "https://github.com/messagebirdguides/notifications-guide-php"
      }}>{`Download the Code`}</a></h3>
    <h2>{`Why build SMS order notifications?`}</h2>
    <p>{`In this MessageBird Developer Tutorial we'll show you how to keep your customers up to date with this easy-to-build runnable order notification application powered by the `}<a parentName="p" {...{
        "href": "/api/sms-messaging"
      }}>{`MessageBird SMS Messaging API`}</a>{`.
Have you ever ordered home delivery to find yourself wondering whether your order was received correctly and how long it will take to arrive? Some experiences are seamless and others... not so much.`}</p>
    <p>{`For on-demand industries such as food delivery, ride-sharing and logistics, excellent customer service during the ordering process is essential. One easy way to stand out from the crowd is providing proactive communication to keep your customers in the loop about the status of their orders. Regardless of whether your customer is waiting for a package delivery or growing hangry (a combination of hungry and angry) awaiting their food delivery, sending timely SMS order notifications is a great strategy to create a seamless user experience.`}</p>
    <p>{`The `}<a parentName="p" {...{
        "href": "/api/sms-messaging"
      }}>{`MessageBird SMS Messaging API`}</a>{` provides an easy way to fully automate and integrate a notifications application into your order handling software. Busy employees can trigger the notifications application with the push of a single button. No more confused hangry customers and great user experience, just like that!`}</p>
    <h2>{`Getting started`}</h2>
    <p>{`The application is a prototype order management system build in PHP for our fictitious food delivery company, `}<em parentName="p">{`Birdie NomNom Foods`}</em>{`.`}</p>
    <p>{`Birdie NomNom Foods have set up the following workflow:`}</p>
    <ul>
      <li parentName="ul">{`New incoming orders are in a `}<strong parentName="li">{`pending`}</strong>{` state.`}</li>
      <li parentName="ul">{`Once the kitchen starts preparing an order, it moves to the `}<strong parentName="li">{`confirmed`}</strong>{` state. A message is sent to the customer to inform them about this.`}</li>
      <li parentName="ul">{`When the food is made and handed over to the delivery driver, staff marks the order as `}<strong parentName="li">{`delivered`}</strong>{`. A message is sent to the customer to let them know it will arrive briefly.`}</li>
      <li parentName="ul">{`If preparation takes longer than expected, it can be moved to a `}<em parentName="li">{`delayed_`}</em>{`state. A message is sent to the customer asking them to hang on just a little while longer.`}</li>
    </ul>
    <p>{`Thanks to this workflow, Birdie NomNom Foods saves time, money, and energy that would otherwise be spent answering `}<em parentName="p">{`"Where's my order?"`}</em>{` calls.`}</p>
    <p>{`To run the sample application, you need to have PHP installed on your machine. If you're using a Mac, PHP is already installed. For Windows users, you can `}<a parentName="p" {...{
        "href": "https://windows.php.net/download/"
      }}>{`get it from windows.php.net`}</a>{`. For Linux users, please check your system's default package manager. You also need Composer, which is available from `}<a parentName="p" {...{
        "href": "https://getcomposer.org/download/"
      }}>{`getcomposer.org`}</a>{`, to install the `}<a parentName="p" {...{
        "href": "https://github.com/messagebird/php-rest-api"
      }}>{`MessageBird SDK for PHP`}</a>{` and other dependencies.`}</p>
    <p>{`Download the sample application by cloning the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/notifications-guide-php"
      }}>{`MessageBird Developers Tutorial GitHub repository`}</a>{` or retrieving and extracting the ZIP file.`}</p>
    <p>{`Then, open a console pointed at the directory into which you've stored the sample application and run the following command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`composer install
`}</code></pre>
    <p>{`Apart from the MessageBird SDK, Composer will install the `}<a parentName="p" {...{
        "href": "https://packagist.org/packages/slim/slim"
      }}>{`Slim framework`}</a>{`, the `}<a parentName="p" {...{
        "href": "https://packagist.org/packages/slim/twig-view"
      }}>{`Twig templating engine`}</a>{`, and the `}<a parentName="p" {...{
        "href": "https://packagist.org/packages/vlucas/phpdotenv"
      }}>{`Dotenv configuration library`}</a>{`. These libraries add some structure to the project while keeping the sample application straightforward to understand without the overhead of a full-scale web framework.`}</p>
    <h2>{`Create your API Key 🔑`}</h2>
    <p>{`To enable the MessageBird REST API, we need to provide an access key for the API. MessageBird provides keys in `}<em parentName="p">{`live`}</em>{` and `}<em parentName="p">{`test`}</em>{` modes. To get this application running, we’ll need to create and use a live API access key. You can read more about `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/articles/360000670709-What-is-the-difference-between-a-live-key-and-a-test-key-"
      }}>{`the difference between test and live API keys`}</a>{` in our Help Center.`}</p>
    <p>{`Let's create your live API access key. First, go to the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/user/index"
      }}>{`MessageBird Dashboard`}</a>{`; if you have already created an API key it will be shown right there. If you don’t see any key on the Dashboard or if you're unsure whether this key is in `}<em parentName="p">{`live`}</em>{` mode, go to the `}<em parentName="p">{`Developers`}</em>{` section in the MessageBird Dashboard and open the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`API access (REST) tab`}</a>{`. There you can create new API keys and manage your existing ones.`}</p>
    <p>{`If you are having any issues creating your API key, please reach out to `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we’ll make sure to help you out.`}</p>
    <h2>{`Configuring the MessageBird SDK`}</h2>
    <p>{`The SDK is defined as a dependency in `}<inlineCode parentName="p">{`composer.json`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
   "require" : {
       "messagebird/php-rest-api" : "^1.9.4"
       ...
   }
}
`}</code></pre>
    <p>{`An application can access the SDK, which is made available through Composer autoloading, by creating an instance of the `}<inlineCode parentName="p">{`MessageBird\\Client`}</inlineCode>{` class. The constructor takes a single argument, your `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`API key`}</a>{`. For our Slim-based application, we add the SDK on the dependency injection container:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`// Load and initialize MessageBird SDK
$container['messagebird'] = function() {
   return new MessageBird\\Client(getenv('MESSAGEBIRD_API_KEY'));
};
`}</code></pre>
    <p>{`Using `}<inlineCode parentName="p">{`getenv()`}</inlineCode>{` we load the API key from an environment variable as it's a bad practice to keep credentials in the source code. To make the key available in the environment variable we need to initialize Dotenv and then add the key to a `}<inlineCode parentName="p">{`.env`}</inlineCode>{` file. You can copy the `}<inlineCode parentName="p">{`env.example`}</inlineCode>{` file provided in the repository to `}<inlineCode parentName="p">{`.env`}</inlineCode>{` and then add your API key like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-env"
      }}>{`MESSAGEBIRD_API_KEY=YOUR-API-KEY
`}</code></pre>
    <p>{`Let's create your live API access key. First, go to the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/user/index"
      }}>{`MessageBird Dashboard`}</a>{`; if you have already created an API key it will be shown right there. If you don’t see any key on the Dashboard or if you're unsure whether this key is in `}<em parentName="p">{`live`}</em>{` mode, go to the `}<em parentName="p">{`Developers`}</em>{` section in the MessageBird Dashboard and open the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`API access (REST) tab`}</a>{`. There you can create new API keys and manage your existing ones.`}</p>
    <p>{`If you are having any issues creating your API key, please reach out to `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we’ll make sure to help you out.`}</p>
    <h2>{`Creating a data model and sample data`}</h2>
    <p>{`Our sample application uses a relational database to store the customer details and status of orders. It’s configured to use a single-file `}<a parentName="p" {...{
        "href": "https://www.sqlite.org/"
      }}>{`SQLite`}</a>{` database, which is natively supported by PHP through PDO so that it works out of the box without the need to configure an external RDBMS like MySQL.`}</p>
    <p>{`The schema is a single table named `}<em parentName="p">{`orders`}</em>{` with the fields `}<em parentName="p">{`id`}</em>{`, `}<em parentName="p">{`name`}</em>{`, `}<em parentName="p">{`phone`}</em>{`, `}<em parentName="p">{`items`}</em>{` and `}<em parentName="p">{`status`}</em>{`.`}</p>
    <p>{`The file `}<inlineCode parentName="p">{`init.php`}</inlineCode>{` in the repository contains the code to set up the database table and insert two rows of sample data. Open this file with your text editor or IDE and update the queries; you should replace the sample data with a phone number you own for at least one of the rows marked like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`   'phone' => '+319876543210', // <- put your number here for testing
`}</code></pre>
    <p>{`After updating the file, save it and run the following command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`php init.php
`}</code></pre>
    <p>{`Keep in mind that this command only works once. If you make changes and want to recreate the database, you must delete the file `}<inlineCode parentName="p">{`orders.sqlite`}</inlineCode>{` that the script creates before re-running it:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`rm orders.sqlite
php init.php
`}</code></pre>
    <h2>{`Listing orders`}</h2>
    <p>{`The application contains a view to list orders and let the staff trigger notifications. You can browse the HTML for this admin interface in `}<inlineCode parentName="p">{`views/orders.html.twig`}</inlineCode>{` and the implementation in `}<inlineCode parentName="p">{`index.php`}</inlineCode>{` that renders this view is this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`// Display page to list orders
$app->get('/', function($request, $response) {
   $orders = $this->db->query('SELECT * FROM orders');

   return $this->view->render($response, 'orders.html.twig',
       [ 'orders' => $orders ]);
});
`}</code></pre>
    <h2>{`Notifying customers by triggering an SMS`}</h2>
    <p>{`The sample application triggers SMS delivery in the `}<inlineCode parentName="p">{`/updateOrder`}</inlineCode>{` route after updating the stored data. The definition of the route starts like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`// Execute action to update order
$app->post('/updateOrder', function($request, $response) {
   // Read request
   $id = $request->getParsedBodyParam('id');
   $newStatus = $request->getParsedBodyParam('status');

   // Get order
   $stmt = $this->db->prepare('SELECT * FROM orders WHERE id = :id');
   $stmt->execute([ 'id' => $id ]);
   if ($stmt->columnCount() > 0) {
       $order = $stmt->fetch();
`}</code></pre>
    <p>{`Updating the stored data is done with an SQL UPDATE query:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`       // Update order
       $stmt = $this->db->prepare('UPDATE orders SET status = :status WHERE id = :id');
       $stmt->execute([ 'id' => $id, 'status' => $newStatus ]);
`}</code></pre>
    <p>{`Depending on the value of `}<inlineCode parentName="p">{`$newStatus`}</inlineCode>{`, let’s formulate a different notification text and store it in the `}<inlineCode parentName="p">{`$body`}</inlineCode>{` variable:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`       // Compose a message, based on status
       $body = "";
       switch ($newStatus) {
           case 'confirmed':
               $body = $order['name'] . ", thanks for ordering at BirdieNomNom Foods! We are now preparing your food with love and fresh ingredients and will keep you updated.";
               break;
           case 'delayed':
               $body = $order['name'] . ", sometimes good things take time! Unfortunately your order is slightly delayed but will be delivered as soon as possible.";
               break;
           case 'delivered':
               $body = $order['name'] . ", you can start setting the table! Our driver is on their way with your order! Bon appetit!";
               break;
       }
`}</code></pre>
    <p>{`Sending a message with the MessageBird SDK is straightforward: call the `}<inlineCode parentName="p">{`messages->create()`}</inlineCode>{` method with a `}<inlineCode parentName="p">{`MessageBird\\Objects\\Message`}</inlineCode>{` object. This  object needs at least the following attributes:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`originator`}</inlineCode>{`: A sender ID for the SMS, either a telephone number `}<a parentName="li" {...{
          "href": "https://support.messagebird.com/hc/en-us/articles/115003950149-Numbers-format-"
        }}>{`including country code`}</a>{`, or an alphanumeric string with at most 11 characters. Keep in mind that alphanumeric senders are not supported in every country including the United States, so it’s important to check the `}<a parentName="li" {...{
          "href": "https://support.messagebird.com/hc/en-us/sections/360000108538-Country-info-Restrictions"
        }}>{`country restrictions`}</a>{`. If you can't use alphanumeric IDs, use a real phone number instead. You can check our `}<a parentName="li" {...{
          "href": "https://support.messagebird.com/hc/en-us/articles/115002628665-What-is-the-originator-"
        }}>{`originator article`}</a>{` in Help Center to learn more about this topic.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`recipients`}</inlineCode>{`: One or more phone numbers to send the message to. In our example, we use a single phone number and take it from the order database.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`body`}</inlineCode>{`: The content of the message. For this application, we use the `}<inlineCode parentName="li">{`$body`}</inlineCode>{` generated in the previous step.`}</li>
    </ul>
    <p>{`Check out our `}<a parentName="p" {...{
        "href": "/api/messaging#messaging-send"
      }}>{`API documentation`}</a>{` for multiple optional parameters.`}</p>
    <p>{`Here's how to create the `}<inlineCode parentName="p">{`Message`}</inlineCode>{` object in PHP:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`       // Create message object
       $message = new MessageBird\\Objects\\Message;
       $message->originator = 'BirdieNomNo';
       $message->recipients = [ $order['phone'] ];
       $message->body = $body;
`}</code></pre>
    <p>{`Now we can send the prepared object. As the MessageBird SDK throws exceptions for any error, the next section is surrounded by a try-catch block. Using `}<inlineCode parentName="p">{`$this->messagebird`}</inlineCode>{` we access the previously initialized SDK object and then call the `}<inlineCode parentName="p">{`messages->create()`}</inlineCode>{` method. If everything  runs smoothly and the API accepted our request, we redirect the user back to the initial page. If there's an error, we catch the exception and log the message. Here’s the code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`       // Send the message through MessageBird's API
       try {
           $this->messagebird->messages->create($message);

           // Request was successful, return to previous view
           return $response->withRedirect('/', 301);

       } catch (Exception $e) {
           // Request has failed
           error_log($e->getMessage());
           return "Error occurred while sending message!";
       }
`}</code></pre>
    <h2>{`Testing`}</h2>
    <p>{`You’re done! You can test the application with PHP's built-in web server. Before launching the application, check again whether you have already updated and run the database initialization script as described above in the "Creating a data model and sample data" section. Then, enter the following command on the console to start:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`php -S 0.0.0.0:8080 index.php
`}</code></pre>
    <p>{`Next, point your browser at http://localhost:8080/ to see the list of orders.`}</p>
    <p>{`Click on one of the buttons in the `}<em parentName="p">{`Action`}</em>{` column to trigger a status change and, at the same time, automatically send a message. Tada!`}</p>
    <p>{`Awesome! You can now use the flow, code snippets and UI examples from this tutorial as an inspiration to build your own SMS Notifications system. Don't forget to download the code from the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/notifications-guide-php"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`.`}</p>
    <p><strong parentName="p">{`Nice work!`}</strong>{` 🎉`}</p>
    <p>{`You now have a running SMS Notifications application with MessageBird using PHP!`}</p>
    <h2>{`Start building!`}</h2>
    <p>{`Want to build something similar but not quite sure how to get started? Please feel free to let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`;  we'd love to help!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      