import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Implementing SMS two-factor authentication (2FA) with MessageBird`}</h1>
    <h3>{`⏱ 15 min build time      ||      `}<a parentName="h3" {...{
        "href": "https://github.com/messagebirdguides/verify-guide-php"
      }}>{`Download the Code`}</a></h3>
    <h2>{`Why build two-factor authentication?`}</h2>
    <p>{`In this MessageBird Developer Tutorial you’ll learn how to improve your security building an SMS-based two factor authentication solution with the `}<a parentName="p" {...{
        "href": "/api/verify"
      }}>{`MessageBird Verify API`}</a>{`. The runnable application we’ll build is a prototype in PHP for our fictitious online banking application, `}<em parentName="p">{`BirdBank`}</em>{`.`}</p>
    <p>{`Enterprises are increasingly challenged to keep sensitive information from falling into the wrong hands. This means that we can no longer trust old online authentication systems that rely solely on usernames and passwords, especially as security breaches grow in frequency, severity and sophistication.`}</p>
    <p>{`With the `}<a parentName="p" {...{
        "href": "/api/verify"
      }}>{`MessageBird Verify API`}</a>{`, you can implement two factor authentication (2FA) solutions to provide an additional layer of account security by verifying the user's password with a second authentication token and in turn, secure customer data, block fraudulent accounts, and safeguard key transactions in a matter of minutes. The most common use case involves the application of one-time passwords (OTP) generated by hardware tokens,  authenticator apps or directly sent to the user's mobile phone via SMS messaging.`}</p>
    <p>{`We'll walk you through the following steps:`}</p>
    <ul>
      <li parentName="ul">{`Asking for the phone number`}</li>
      <li parentName="ul">{`Sending a verification code`}</li>
      <li parentName="ul">{`Verifying the code`}</li>
    </ul>
    <p><strong parentName="p">{`Pro-tip:`}</strong>{` Follow this tutorial to build the whole application from scratch or, if you want to see it in action right away, you can download, clone or fork the sample application from our `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/verify-guide-php"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`.`}</p>
    <h2>{`Getting started`}</h2>
    <p>{`First things first, we need to have PHP installed to run this sample 2FA application:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`For Mac users, PHP will already be installed. `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`For Windows users, you can download PHP from `}<a parentName="p" {...{
            "href": "https://windows.php.net/download/"
          }}>{`windows.php.net`}</a>{`. `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`For Linux users, please check your system's default package manager. `}</p>
      </li>
    </ul>
    <p>{`You also need Composer to install the `}<a parentName="p" {...{
        "href": "https://github.com/messagebird/php-rest-api"
      }}>{`MessageBird SDK for PHP`}</a>{` and other dependencies, which is available at `}<a parentName="p" {...{
        "href": "https://getcomposer.org/download/"
      }}>{`getcomposer.org`}</a>{`.`}</p>
    <p>{`Download the sample application by cloning the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/verify-guide-php"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{` or retrieving and extracting the ZIP file.`}</p>
    <p>{`Next, let's open a console pointed at the directory into which you've stored the sample application and run the following command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`composer install
`}</code></pre>
    <p>{`Apart from the MessageBird SDK, Composer will install the `}<a parentName="p" {...{
        "href": "https://packagist.org/packages/slim/slim"
      }}>{`Slim framework`}</a>{`, the `}<a parentName="p" {...{
        "href": "https://packagist.org/packages/slim/twig-view"
      }}>{`Twig templating engine`}</a>{`, and the `}<a parentName="p" {...{
        "href": "https://packagist.org/packages/vlucas/phpdotenv"
      }}>{`Dotenv configuration library`}</a>{`. We're using these libraries to add some structure to the project while keeping the sample easy to understand and without the overhead of a full-scale web framework.`}</p>
    <h2>{`Configuring the MessageBird SDK 🔑`}</h2>
    <p>{`The SDK is defined as a dependency in `}<inlineCode parentName="p">{`composer.json`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
   "require" : {
       "messagebird/php-rest-api" : "^1.9.4"
       ...
   }
}
`}</code></pre>
    <p>{`Composer autoloading makes the SDK available to the application and is initialized by creating an instance of the `}<inlineCode parentName="p">{`MessageBird\\Client`}</inlineCode>{` class. The constructor takes a single argument: an API key. For our Slim-based example, we add the SDK on the dependency injection container:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`// Load and initialize MessageBird SDK
$container['messagebird'] = function() {
   return new MessageBird\\Client(getenv('MESSAGEBIRD_API_KEY'));
};
`}</code></pre>
    <p><strong parentName="p">{`Pro-tip`}</strong>{`: Hardcoding your credentials in the code is a risky practice that should never be used in production applications. A better method, also recommended by the `}<a parentName="p" {...{
        "href": "https://12factor.net/"
      }}>{`Twelve-Factor App Definition`}</a>{`, is to use environment variables. We've added dotenv to the sample application, so you can supply your API key in a file named .env, too.`}</p>
    <p>{`So let's use `}<inlineCode parentName="p">{`getenv()`}</inlineCode>{` to load the API key from the environment variable. To make the key available in the environment variable we need to initialize Dotenv and add the key to a `}<inlineCode parentName="p">{`.env`}</inlineCode>{` file. You can copy the `}<inlineCode parentName="p">{`env.example`}</inlineCode>{` file provided in the repository to `}<inlineCode parentName="p">{`.env`}</inlineCode>{` and then add your API key like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-env"
      }}>{`MESSAGEBIRD_API_KEY=YOUR-API-KEY
`}</code></pre>
    <p>{`API keys can be created or retrieved from the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`API access (REST) tab`}</a>{` in the `}<em parentName="p">{`Developers section`}</em>{` of your MessageBird Dashboard.`}</p>
    <h2>{`Asking for the phone number`}</h2>
    <p>{`The first step in verifying a user's phone number is asking them to provide their phone number. In `}<inlineCode parentName="p">{`views/step1.html.twig`}</inlineCode>{` you'll find a basic HTML form with a single input field and a button to submit the data using the POST method to `}<inlineCode parentName="p">{`/step2`}</inlineCode>{`. Providing `}<inlineCode parentName="p">{`tel`}</inlineCode>{` as the `}<inlineCode parentName="p">{`type`}</inlineCode>{` attribute of our input field allows some browsers, especially on mobile devices, to optimize for telephone number input, for example by displaying a number pad.`}</p>
    <p>{`The following route in `}<inlineCode parentName="p">{`index.php`}</inlineCode>{` displays the form:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`// Display page to ask the user for their phone number
$app->get('/', function($request, $response) {
   return $this->view->render($response, 'step1.html.twig');
});
`}</code></pre>
    <h2>{`Sending a verification code`}</h2>
    <p>{`Once we've collected the number, we can send a verification message to our user's mobile device. The MessageBird Verify API takes care of generating a random token, so you don't have to do this yourself. Codes are numeric and six digits by default; if you want to customize the length of the code or configure other options, you can check out our `}<a parentName="p" {...{
        "href": "/api/verify#verify-request"
      }}>{`Verify API documentation`}</a>{`.`}</p>
    <p>{`The form we created in the last step submits the phone number to `}<inlineCode parentName="p">{`/step2`}</inlineCode>{`, so let's define this route in our `}<inlineCode parentName="p">{`index.php`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`// Handle phone number submission
$app->post('/step2', function($request, $response) {
   // Create verify object
   $verify = new MessageBird\\Objects\\Verify;
   $verify->recipient = $request->getParsedBodyParam('number');
   $verify->originator = 'Code';
   $verify->template = "Your verification code is %token.";

   // Make request to Verify API
   try {
       $result = $this->messagebird->verify->create($verify);
   } catch (Exception $e) {
       // Request has failed
       return $this->view->render($response, 'step1.html.twig', [
           'error' => get_class($e).": ".$e->getMessage()
       ]);
   }

   // Request was successful, return step2 form
   return $this->view->render($response, 'step2.html.twig', [
       'id' => $result->getId()
   ]);
});
`}</code></pre>
    <p>{`Before we move on, let's quickly dive into what happens here: 🤔`}</p>
    <p>{`First, we're creating a `}<inlineCode parentName="p">{`MessageBird\\Objects\\Verify`}</inlineCode>{` object to encapsulate the parameters for our API request. We set the number from the form as the `}<inlineCode parentName="p">{`recipient`}</inlineCode>{` attribute and specify a template for the message. The value for the `}<inlineCode parentName="p">{`template`}</inlineCode>{` attribute contains the placeholder `}<inlineCode parentName="p">{`%token`}</inlineCode>{`, which is replaced with the generated token on MessageBird's end. If we omitted this, the message would contain the token and nothing else.`}</p>
    <p>{`Also, using `}<inlineCode parentName="p">{`originator`}</inlineCode>{` we specify the sender ID of the text message with the code. The value should either be a valid telephone number `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/articles/115003950149-Numbers-format-"
      }}>{`international format with country code`}</a>{` , or an alphanumeric string with at most 11 characters. If we omitted this, it would default to the string `}<em parentName="p">{`Code`}</em>{`. Keep in mind that alphanumeric senders are not supported in every country including the United States, so it’s important to check the `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/sections/360000108538-Country-info-Restrictions"
      }}>{`country restrictions`}</a>{`.`}</p>
    <p>{`The MessageBird SDK throws exceptions for any error. Therefore, the next section is contained in a try-catch block. Using `}<inlineCode parentName="p">{`$this->messagebird`}</inlineCode>{` we access the previously initialized SDK object and then call the `}<inlineCode parentName="p">{`verify->create()`}</inlineCode>{` method with the parameter object. If the API call fails for any reason, for example, because the user has entered an invalid phone number, the catch-block executes, and we render the form from the first step again but include the error message. In production applications, you'd most likely not expose the raw API error; instead, you could consider different possible problems and return an appropriate message in your own words. You might also want to prevent some errors from happening by doing some input validation on the phone number yourself.`}</p>
    <p>{`In case the request was successful, code execution continues after the catch-block, and we'll render a new page. Our API response contains an ID, which we'll need for the next step, so we'll add it to the form. Since the ID is meaningless without your API access key, there are no security implications of doing so; however, in practice you'd be more likely to store this ID in a session object on the server. You can see the form in the file `}<inlineCode parentName="p">{`views/step2.html.twig`}</inlineCode>{`, it actually looks similar to the previous form but includes a hidden field with our verification ID.`}</p>
    <h2>{`Verifying the code`}</h2>
    <p>{`Once the code is delivered, our user will check their phone, enter their verification code and submit the form. We now need to send the user's input along with the ID of the verification request to MessageBird's API and see whether the verification was successful or not. Let's declare this third step as a new route in our `}<inlineCode parentName="p">{`index.php`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`// Verify whether the token is correct
$app->post('/step3', function($request, $response) {
   $id = $request->getParsedBodyParam('id');
   $token = $request->getParsedBodyParam('token');

   // Make request to Verify API
   try {
       $this->messagebird->verify->verify($id, $token);
   } catch (Exception $e) {
       // Request has failed
       return $this->view->render($response, 'step2.html.twig', [
           'id' => $id,
           'error' => get_class($e).": ".$e->getMessage()
       ]);
   }

   // Request was successful
   return $this->view->render($response, 'step3.html.twig');
});
`}</code></pre>
    <p>{`In this step, we retrieve the `}<inlineCode parentName="p">{`id`}</inlineCode>{` and `}<inlineCode parentName="p">{`token`}</inlineCode>{` inputs from the form. The `}<inlineCode parentName="p">{`verify->verify()`}</inlineCode>{` method accepts these two parameters, so we don’t need to create an object first but pass them directly. As before, there's a catch-block to handle errors such as an invalid token entered by the user. In this case, we show the same form again with the error.`}</p>
    <p>{`If verification was successful, we render a simple confirmation page. The template `}<inlineCode parentName="p">{`views/step3.html.twig`}</inlineCode>{` only contains a static message.`}</p>
    <h2>{`Testing`}</h2>
    <p>{`You’re done! To test the application with PHP's built-in web server, enter the following command on the console to start:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`php -S 0.0.0.0:8080 index.php
`}</code></pre>
    <p>{`Then, point your browser to `}<a parentName="p" {...{
        "href": "http://localhost:8080/"
      }}>{`http://localhost:8080/`}</a>{` and try to verify your own phone number.`}</p>
    <p>{`Awesome! You can now leverage the flow, code snippets and UI examples from this tutorial to build your own two factor authentication system. Don't forget to download the code from the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/verify-guide-php"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`.`}</p>
    <p><strong parentName="p">{`Nice work!`}</strong>{` 🎉`}</p>
    <p>{`You now have a running integration of MessageBird's Verify API using PHP!`}</p>
    <h2>{`Start building!`}</h2>
    <p>{`Want to build something similar but not quite sure how to get started? Please feel free to let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`, we'd love to help!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      