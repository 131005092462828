import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
import CodeSnippet from 'components/CodeSnippet';
import * as CODE from 'constants/live-chat-widget';
import { Img } from 'components/shared';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Toggling, Hiding Showing and Positioning the Widget`}</h1>
    <p>{`In this guide, you'll learn all the ways the you can trigger, hide and then show Omnichannel Widget on your website.`}</p>
    <h2>{`Hiding`}</h2>
    <p>{`If you want to completely hide the widget, you can use the `}<inlineCode parentName="p">{`.hide()`}</inlineCode>{` method to achive this. This method can be called at any time after the Omnichannel Widget Snippet has been executed on your page.`}</p>
    <p>{`This is useful if you only want to hide the widget on a certain page for example. Hiding the Widget completely from users before the page is interactive.`}</p>
    <CodeSnippet code={{
      json: `window.MessageBirdChatWidget.hide()`
    }} mdxType="CodeSnippet" />
    <h2>{`Showing`}</h2>
    <p>{`This method is only useful if the widget has been hidden previous. This is the opposite of the `}<inlineCode parentName="p">{`.hide()`}</inlineCode>{` method.`}</p>
    <CodeSnippet code={{
      json: `window.MessageBirdChatWidget.show()`
    }} mdxType="CodeSnippet" />
    <h2>{`Toggling the Widget`}</h2>
    <p>{`If you want to open the Widget programmatically, you can use this method to change it from its current open or closed state.`}</p>
    <p>{`Using the api you can toggle the widget to either open or closed depending on whether it is open or closed.`}</p>
    <CodeSnippet code={{
      json: `window.MessageBirdChatWidget.toggleChat()`
    }} mdxType="CodeSnippet" />
    <p>{`You can also force the widget to open or close by passing `}<inlineCode parentName="p">{`true`}</inlineCode>{` to open and `}<inlineCode parentName="p">{`false`}</inlineCode>{` to close the widget as an argument to the method.`}</p>
    <CodeSnippet code={{
      json: `window.MessageBirdChatWidget.toggleChat(true)`
    }} mdxType="CodeSnippet" />
    <p>{`Note that the widget will remain visible on the page when using these methods.`}</p>
    <h2>{`Opening the Widget when a user clicks a button`}</h2>
    <p>{`Using the api outlined above its possible to add have the widget open when users click an element on your page.`}</p>
    <p>{`For example, if you have a button like this on your page:`}</p>
    <CodeSnippet code={{
      html: `<button id="livechat-widget-opener">Click Here to Speak to Us over Livechat</button>`
    }} mdxType="CodeSnippet" />
    <p>{`Its possible to then use javascript, like in the snippet bellow, to add an EventListener to listen to click events on the button and trigger the Widget to open and close.`}</p>
    <CodeSnippet code={{
      js: `
    // get reference to element from the dom
    const livechatButton = document.getElementById('livechat-widget-opener');
    // add event listener to button
    livechatButton?.addEventListener('click', () => {
        if (window.MessageBirdChatWidget) {
            window.MessageBirdChatWidget.toggleChat(true);
        }
    })
    `
    }} mdxType="CodeSnippet" />
    <h2>{`Positioning the Widget`}</h2>
    <p>{`When the widget loads onto the page, it will check the page for a dom element with the `}<inlineCode parentName="p">{`id`}</inlineCode>{` of `}<inlineCode parentName="p">{`live-chat-widget`}</inlineCode>{`. If this element already exists the Widget will mount onto this element.`}</p>
    <p>{`If a dom element with `}<inlineCode parentName="p">{`live-chat-widget`}</inlineCode>{` does not exist. The Widget will create element and mount itself on said element.`}</p>
    <p>{`This element will always be used as the container for the Widget on the host webpage.`}</p>
    <p>{`Using CSS its possible to change the position of the Widget on your page or to integrate it seamlessly with your page layouts by changing properties on this element.`}</p>
    <CodeSnippet code={{
      html: `<div id="live-chat-widget" />`
    }} mdxType="CodeSnippet" />
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'Omnichannel Widget API Reference',
      to: '/api/omnichannel-widget'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      