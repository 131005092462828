import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Setting SMS instant lead alerts with MessageBird`}</h1>
    <h3>{`⏱ 15 min build time      ||      `}<a parentName="h3" {...{
        "href": "https://github.com/messagebirdguides/lead-alerts-guide"
      }}>{`Download the Code`}</a></h3>
    <h2>{`Why build instant lead alerts for sales?`}</h2>
    <p>{`In this MessageBird Developer Tutorial, you’ll learn how to build SMS lead alerts with the `}<a parentName="p" {...{
        "href": "/api/sms-messaging"
      }}>{`MessageBird SMS Messaging API`}</a>{` and convert more leads by instantly notifying your sales team. In this tutorial the sample application will be for our fictitious car dealership, `}<em parentName="p">{`M.B. Cars`}</em>{`.`}</p>
    <p>{`Even though a lot of business transactions happen on the web, from both a business and user perspective, it's still often preferred to switch the channel and talk on the phone. Especially when it comes to high-value transactions in industries such as real estate or mobility, personal contact is essential.`}</p>
    <p>{`One way to streamline this workflow is by building callback forms on your website. Through these forms, customers can enter their contact details and receive a call to their phone, thus skipping queues where prospective leads need to stay on hold.`}</p>
    <p>{`Callback requests reflect a high level of purchase intent and should be dealt with as soon as possible to increase the chance of converting a lead; therefore, it's essential to get them pushed to a sales agent quickly. SMS messaging has proven to be one of the most instant and effective channels for this use case.`}</p>
    <h2>{`Getting started`}</h2>
    <p>{`First things first, our sample application is built in Node.js using the `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/express"
      }}>{`Express`}</a>{` framework, so you’ll need Node and npm, you can easily `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/get-npm"
      }}>{`install them for free`}</a>{`.`}</p>
    <p>{`The source code is available in the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/lead-alerts-guide"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{` from which it can be cloned or downloaded into your development environment.
After saving the code, open a console for the download directory and run the following command which downloads the Express framework, MessageBird SDK and other dependencies defined in the `}<inlineCode parentName="p">{`package.json`}</inlineCode>{` file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install
`}</code></pre>
    <h2>{`Configuring the MessageBird SDK`}</h2>
    <p>{`The MessageBird SDK is used to send messages. It's added as a dependency and loaded with the following lines in `}<inlineCode parentName="p">{`index.js`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// Load and initialize MessageBird SDK
var messagebird = require('messagebird')(process.env.MESSAGEBIRD_API_KEY);
`}</code></pre>
    <p>{`You need an API key, you can create or retrieve it from the ‘`}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`API access (REST)’ tab`}</a>{` in the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/settings"
      }}>{`Developers section`}</a>{` of the MessageBird Dashboard. As you can see in the code example above, the key is set as a parameter when including the SDK, and it's loaded from an environment variable called `}<inlineCode parentName="p">{`MESSAGEBIRD_API_KEY`}</inlineCode>{`. With `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/dotenv"
      }}>{`dotenv`}</a>{` you can define these variables in a `}<inlineCode parentName="p">{`.env`}</inlineCode>{` file.`}</p>
    <p>{`The repository contains an `}<inlineCode parentName="p">{`env.example`}</inlineCode>{` file which you can copy to `}<inlineCode parentName="p">{`.env`}</inlineCode>{` and then enter your information.`}</p>
    <p>{`Apart from the API key, we also specify the originator, which is what is displayed as the sender of the messages. Please note that alphanumeric sender IDs like the one in our example file don't work in all countries, including the United States, so it’s important to check the `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/sections/360000108538-Country-info-Restrictions"
      }}>{`country restrictions`}</a>{`. If you can't use alphanumeric IDs, use a real phone number instead. You can check our `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/articles/115002628665-What-is-the-originator-"
      }}>{`originator article`}</a>{` in Help Center to learn more about this topic.`}</p>
    <p>{`Additionally, we specify the sales agent's telephone numbers. These are the recipients that will receive the SMS alerts when a potential customer submits the callback form.  You can separate multiple numbers with commas.`}</p>
    <p>{`Here's an example of a valid `}<inlineCode parentName="p">{`.env`}</inlineCode>{` file for our sample application:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-env"
      }}>{`MESSAGEBIRD_API_KEY=YOUR-API-KEY
MESSAGEBIRD_ORIGINATOR=MBCarsWebsite
SALES_AGENT_NUMBERS=+31970XXXXXXX,+31970YYYYYYY
`}</code></pre>
    <h2>{`Showing a landing page`}</h2>
    <p>{`The landing page is a simple HTML page with information about our company, a call to action and a form with two input fields, name and number, and a submit button. We use Handlebars templates to compose the view with a layout and have the ability to show dynamic content. You can see the landing page in the file `}<inlineCode parentName="p">{`views/landing.handlebars`}</inlineCode>{`, which extends the layout stored in `}<inlineCode parentName="p">{`views/layouts/main.handlebars`}</inlineCode>{`. The `}<inlineCode parentName="p">{`app.get('/')`}</inlineCode>{` route in `}<inlineCode parentName="p">{`index.js`}</inlineCode>{` is responsible for rendering it.`}</p>
    <h2>{`Handling callback requests`}</h2>
    <p>{`When the user submits the form, the `}<inlineCode parentName="p">{`app.post('/callme')`}</inlineCode>{` route receives their name and number. First, let’s do some input validation:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// Handle callback request
app.post('/callme', function(req, res) {
   // Check if user has provided input for all form fields
   if (!req.body.name || !req.body.number
       || req.body.name == '' || req.body.number == '') {
           // If not, show an error
           res.render('landing', {
               error : "Please fill all required fields!",
               name : req.body.name,
               number: req.body.number,
           });
           return;
   }
`}</code></pre>
    <p>{`Then, we define where to send the message. As you've seen above, we specified multiple recipient numbers in the `}<inlineCode parentName="p">{`SALES_AGENT_NUMBERS`}</inlineCode>{` environment variable. `}<em parentName="p">{`M.B. Cars`}</em>{` has decided to randomly distribute incoming calls to their staff so that every salesperson receives roughly the same amount of leads. Here's the code for the random distribution:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// Choose one of the sales agent numbers randomly
// a) Convert comma-separated values to array
var numbers = process.env.SALES_AGENT_NUMBERS.split(',');
// b) Random number between 0 and (number count - 1)
var randomIndex = Math.floor((Math.random() * numbers.length) + 1) - 1;
// c) Pick number
var recipient = numbers[randomIndex];
`}</code></pre>
    <p>{`Now we can formulate a message for the agent and send it through the MessageBird SDK using the `}<inlineCode parentName="p">{`messagebird.messages.create()`}</inlineCode>{` method:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// Send lead message with MessageBird API
messagebird.messages.create({
   originator : process.env.MESSAGEBIRD_ORIGINATOR,
   recipients : [ recipient ],
   body : "You have a new lead: " + req.body.name + ". Call them at " + req.body.number
}, function (err, response) {
   // …
`}</code></pre>
    <p>{`There are three parameters:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`originator`}</inlineCode>{`: The sender ID comes from the environment variable defined earlier.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`recipients`}</inlineCode>{`: The API supports an array of recipients; we're sending to only one, but this parameter is still specified as an array.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`body`}</inlineCode>{`: The text of the message that includes the input from the form.`}</li>
    </ul>
    <p>{`The API request is asynchronous and executes a callback function once it's complete. Inside this function, we handle the error case by showing the previous form again and inform the user that something went wrong. In the success case, we show a basic confirmation page which you can see in `}<inlineCode parentName="p">{`views/sent.handlebars`}</inlineCode>{`. In both cases, there's also a `}<inlineCode parentName="p">{`console.log()`}</inlineCode>{` statement that sends the API response to the console for debugging. This is the callback function:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`if (err) {
   // Message could not be sent
   console.log(err);
   res.render('landing', {
       error : "An error occurred while requesting a callback!",
       name : req.body.name,
       number: req.body.number,
   });
} else {
   // Message was sent successfully
   console.log(response);
   res.render('sent');
}
`}</code></pre>
    <h2>{`Testing`}</h2>
    <p>{`Have you created your `}<inlineCode parentName="p">{`.env`}</inlineCode>{` file with a working key and added one more phone number to the existing phone numbers to receive the lead alert, as explained above in `}<em parentName="p">{`Configuring the MessageBird SDK`}</em>{`? Lovely!`}</p>
    <p>{`You're done! To test your application, run the following command from your console:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm start
`}</code></pre>
    <p>{`Go to `}<a parentName="p" {...{
        "href": "http://localhost:8080/"
      }}>{`http://localhost:8080/`}</a>{` to see the form and request a lead!`}</p>
    <p>{`Awesome! You can now use the flow, code snippets, and UI examples from this tutorial as an inspiration to build your own SMS-based lead alerts application. Don't forget to download the code from the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/lead-alerts-guide"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`.`}</p>
    <p><strong parentName="p">{`Nice work!`}</strong>{` 🎉`}</p>
    <p>{`You’ve just built your own instant lead alerts application with MessageBird using Node.js!`}</p>
    <h2>{`Start building!`}</h2>
    <p>{`Want to build something similar but not quite sure how to get started? Feel free to let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we'd love to help!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      