import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Sending outbound SMS messages with MessageBird`}</h1>
    <h3>{`⏱ 5 min build time`}</h3>
    <p>{`In this MessageBird Developer Tutorial you’ll learn how to send your first SMS using the `}<a parentName="p" {...{
        "href": "/api/sms-messaging"
      }}>{`MessageBird SMS Messaging API`}</a>{`.`}</p>
    <p>{`Before we get started, have you set up your Go development environment and
project directory with the MessageBird SDK?`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`No!`}</strong>{` Make sure you to set this up before continuing; you can read this`}<a parentName="li" {...{
          "href": "/tutorials/setup-local-dev-environment-go"
        }}>{` MessageBird Developer Tutorial`}</a>{` to learn how to do so.`}</li>
      <li parentName="ul"><strong parentName="li">{`Yes!`}</strong>{` Great! Now you can make your first API request and send an SMS message with MessageBird using Go.`}</li>
    </ul>
    <h2>{`Getting started`}</h2>
    <p>{`First, let's create a new Go project directory. You can do this anywhere, but best practice says that you should make this folder in your `}<inlineCode parentName="p">{`$GOPATH/src/github.com/<your-username>`}</inlineCode>{` folder (your project folder can be `}<inlineCode parentName="p">{`$GOPATH/src/github.com/birddev/make-call`}</inlineCode>{`).`}</p>
    <p>{`If you're not sure where your `}<inlineCode parentName="p">{`$GOPATH`}</inlineCode>{` is located, running `}<inlineCode parentName="p">{`go env GOPATH`}</inlineCode>{` will display it in your terminal.`}</p>
    <p>{`In your project root, let's create a `}<inlineCode parentName="p">{`main.go`}</inlineCode>{` file and open it in your text editor of choice and stub out our program by writing the following code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`package main

import (
   "log"

   "github.com/messagebird/go-rest-api"
)

func main(){
   client := messagebird.New("<your-api-key>")
   log.Println("Hello!")
}
`}</code></pre>
    <p>{`Here, we've imported the MessageBird SDK for Go and created a `}<inlineCode parentName="p">{`client`}</inlineCode>{` object
that we'll use to interact with the MessageBird REST API. To create our `}<inlineCode parentName="p">{`client`}</inlineCode>{`
object, let’s make a `}<inlineCode parentName="p">{`messagebird.New()`}</inlineCode>{` call which expects a single argument, your `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`API key`}</a>{`. Keep in mind that you can create either a `}<strong parentName="p">{`test`}</strong>{` or `}<strong parentName="p">{`live`}</strong>{` API key:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`test`}</strong>{` API keys simulate responses from the MessageBird server, allowing you to test your code flow and error handling, before sending real messages. You still need an internet connection to use MessageBird REST API features.`}</li>
      <li parentName="ul"><strong parentName="li">{`live`}</strong>{` API keys allow you to send actual messages to your recipients. We recommend that you don’t publish this key anywhere.`}</li>
    </ul>
    <p>{`In order to start using the SDK, replace `}<inlineCode parentName="p">{`<your-api-key>`}</inlineCode>{` with your API
key.`}</p>
    <p>{`To keep this tutorial straightforward, we'll be hardcoding the MessageBird API key in our application. But for production applications, we recommended storing
the key in a configuration file or environment variable instead and pass
this variable with the key to the require function. You'll see this in
practice later in our MessageBird Developer Tutorials for building common
use cases.`}</p>
    <h2>{`Sending an SMS message`}</h2>
    <p>{`To send a message with the SDK, we have to:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Import the `}<inlineCode parentName="p">{`sms`}</inlineCode>{` package from the MessageBird SDK. Modify your `}<inlineCode parentName="p">{`import`}</inlineCode>{` statement to look like the following:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-go"
          }}>{`import (
    "log"

    "github.com/messagebird/go-rest-api"
    "github.com/messagebird/go-rest-api/sms"
)
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Call `}<inlineCode parentName="p">{`sms.Create()`}</inlineCode>{`, which takes 5 parameters:`}</p>
      </li>
    </ol>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Client`}</strong>{`: Tells the SDK which `}<inlineCode parentName="li">{`messagebird.Client`}</inlineCode>{` instance to use. Here, we should pass in the `}<inlineCode parentName="li">{`client`}</inlineCode>{` object we created earlier.`}</li>
      <li parentName="ul"><strong parentName="li">{`Originator`}</strong>{`: Is the sender for the message; it can be a telephone number including country code or an alphanumeric string (with a maximum length of 11 characters). You can use the number you bought as part of our `}<a parentName="li" {...{
          "href": "/tutorials/getting-started-101"
        }}>{`Getting Started tutorial`}</a>{` as originator. Keep in mind that alphanumeric senders are not supported in every country including the United States, so it’s important to check the `}<a parentName="li" {...{
          "href": "https://support.messagebird.com/hc/en-us/sections/360000108538-Country-info-Restrictions"
        }}>{`country restrictions`}</a>{`. If you can't use alphanumeric IDs, use a real phone number instead. You can check our `}<a parentName="li" {...{
          "href": "https://support.messagebird.com/hc/en-us/articles/115002628665-What-is-the-originator-"
        }}>{`originator article`}</a>{` in Help Center to learn more about this topic.`}</li>
      <li parentName="ul"><strong parentName="li">{`List of recipients`}</strong>{`: This is a list of recipient phone numbers that your message will go out to, and must be written as an array of strings (`}<inlineCode parentName="li">{`[]string`}</inlineCode>{`). You have to write this list as an array even if you only have one recipient, and you can send a message up to 50 recipients at the same time. All numbers should be in `}<a parentName="li" {...{
          "href": "https://support.messagebird.com/hc/en-us/articles/115003950149-Numbers-format-"
        }}>{`international format with country code`}</a>{`. For testing, add your own mobile telephone number here so you can receive the test message on your phone.`}</li>
      <li parentName="ul"><strong parentName="li">{`Body`}</strong>{`: Is the content of the message. Keep in mind that SMS messages are limited to 160 characters, and if you enter a longer value for the body it will be split into multiple parts which are each sent and billed separately.`}</li>
      <li parentName="ul"><strong parentName="li">{`Optional parameters`}</strong>{`: You can specify additional parameters such the message type `}<a parentName="li" {...{
          "href": "/tutorials/account-security-voice-go"
        }}>{`text or text-to-voice`}</a>{` or a `}<a parentName="li" {...{
          "href": "/tutorials/reminders-go"
        }}>{`scheduled time`}</a>{` for your message to be sent. For more information on the optional parameters you can see our `}<a parentName="li" {...{
          "href": "/api/sms-messaging#send-a-message"
        }}>{`SMS Messaging API documentation`}</a>{`.`}</li>
    </ul>
    <p>{`Here's an example of what an `}<inlineCode parentName="p">{`sms.Create()`}</inlineCode>{` call would look like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`func main(){
   client := messagebird.Create("<your-api-key>")

   msg, err := sms.Create(
       client,
       "319876543210",
       []string{"+319876543211"},
       "Hello World, I am a text message and I was hatched by Go code!",
       nil,
   )
   if err != nil {
       log.Println(err)
   }
   // You can log the msg variable for development, or discard it by assigning it to \`_\`
   log.Println(msg)
}
`}</code></pre>
    <p>{`Make sure to replace the values in the sample code with adequate data for testing.`}</p>
    <p>{`Great! Your first Go program is ready. Let's go ahead and save it.`}</p>
    <p>{`Keep in mind that in production applications you should not expose the raw values for `}<inlineCode parentName="p">{`err`}</inlineCode>{` and provide user-friendly error handling instead, but the current implementation is great for testing.`}</p>
    <p>{`Enough said, let's try running it in the terminal:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`go run main.go
`}</code></pre>
    <p>{`If everything works, and if you're logging `}<inlineCode parentName="p">{`msg`}</inlineCode>{` like in the example code above, you should see the API response as output in the terminal. If you used a live API key and have `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/articles/115002599309-Test-credits"
      }}>{`verified your number or added test credits to your
account`}</a>{`
the message will arrive on your phone in seconds.`}</p>
    <p><strong parentName="p">{`Nice work!`}</strong>{` 🎉`}</p>
    <p>{`You've just sent your first SMS message with `}<a parentName="p" {...{
        "href": "/api/sms-messaging"
      }}>{`MessageBird's SMS Messaging API`}</a>{` using Go!`}</p>
    <h2>{`Start building!`}</h2>
    <p>{`Head over to the next MessageBird Developer Tutorial and `}<a parentName="p" {...{
        "href": "/tutorials/handle-incoming-calls-and-sms"
      }}>{`learn how to set
up inbound messaging
functionality`}</a>{`.`}</p>
    <p>{`Want to start building your solution but not quite sure how to get started? Feel free to let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`;`}</p>
    <p>{`we'd love to help!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      