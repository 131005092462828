import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import Cookies from 'js-cookie';

import { Container } from '../Container';
import * as Local from './styled';
import { DOCSEARCH_KEY } from '../../config/keys';
import Search from './Search';
import { track } from '../../helpers/tracking';
import styled from 'styled-components';

// Get the cookie set by marketing-pages
const lang =
  typeof Cookies.get('lang') !== 'undefined' ? Cookies.get('lang') : 'en';

const Header = (props) => {
  useEffect(() => {
    if (window) {
      import('docsearch.js').then(({ default: docsearch }) => {
        docsearch({
          apiKey: DOCSEARCH_KEY,
          indexName: 'messagebird',
          inputSelector: '.algolia-search',
          debug: false, // Set debug to true if you want to inspect the dropdown
        });
      });
    }
  }, []);

  return (
    <Container header>
      <Local.HeaderWrap location={props.location}>
        <Local.Menu
          css={`
            flex-basis: 200px;
          `}
        >
          <Link
            css={'display: flex;align-items: center;'}
            to="/"
            target="_blank"
          >
            <img
              className="logo"
              src="/img/glyph.svg"
              height="26"
              alt="Messagebird logo"
            />
            <LogoText>for Developers</LogoText>
          </Link>
        </Local.Menu>
        <Local.Menu
          css={`
            flex-grow: 1;
            margin-left: 16px;
          `}
        >
          <Local.MenuItem
            to="/quickstarts/sms-overview/"
            activeClassName="active"
          >
            Quickstarts
          </Local.MenuItem>
          <Local.MenuItem to="/api/" activeClassName="active">
            API Reference
          </Local.MenuItem>
          <Local.MenuItem to="/libraries" activeClassName="active">
            SDKs
          </Local.MenuItem>
          <Local.MenuItem to="/tutorials" activeClassName="active">
            Tutorials
          </Local.MenuItem>
          <AbsoluteLink
            href={`https://dashboard.messagebird.com/${lang}/login`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span css={'margin-right: 8px;'}>Dashboard</span>
            <div>
              <Icon src={header_icons.externalLink} />
            </div>
          </AbsoluteLink>
        </Local.Menu>
        <Search />
      </Local.HeaderWrap>
    </Container>
  );
};

export { Header };

const LogoText = styled.span`
  margin-left: 16px;
  white-space: nowrap;
  font-size: 18px;
  line-height: 20px;
  font-weight: normal;
  color: #24374e;
  &:hover {
    color: #24374e;
  }
`;

const AbsoluteLink = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
  font-weight: normal;
  color: #24374e;
  &:hover {
    color: #0089ff;
    fill: #0089ff;
  }
`;

const Icon = styled.img`
  &:hover,
  &.active {
    fill: #0089ff;
  }
`;
const header_icons = {
  externalLink: '/img/icons/externalLink.svg',
};
