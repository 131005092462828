import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Facebook Messaging Referrals`}</h1>
    <p>{`Facebook Messaging Referrals is a Facebook-specific inbound-only message type that provides information about how the
recipient got into an existing thread via one of the following ways:`}</p>
    <ul>
      <li parentName="ul">{`An `}<a parentName="li" {...{
          "href": "https://developers.facebook.com/docs/messenger-platform/discovery/m-me-links"
        }}>{`m.me link with a referral parameter`}</a></li>
      <li parentName="ul">{`Clicking on a `}<a parentName="li" {...{
          "href": "https://developers.facebook.com/docs/messenger-platform/discovery/ctm-ads"
        }}>{`Facebook Messenger conversation advertisement`}</a></li>
      <li parentName="ul">{`Starting or resuming a conversation thread from the `}<a parentName="li" {...{
          "href": "https://developers.facebook.com/docs/messenger-platform/discovery/facebook-chat-plugin"
        }}>{`Facebook Chat Plugin`}</a></li>
    </ul>
    <h2>{`m.me links with a referral parameter`}</h2>
    <p><a parentName="p" {...{
        "href": "https://developers.facebook.com/docs/messenger-platform/discovery/m-me-links/"
      }}>{`m.me links`}</a>{` provides your business a way
to redirect customers to communicate with a specific Facebook page. When your customers click on an m.me link, your business
will receive an inbound message with type `}<inlineCode parentName="p">{`facebookReferral`}</inlineCode>{` from Conversations API. In case the link contains
`}<a parentName="p" {...{
        "href": "https://developers.facebook.com/docs/messenger-platform/discovery/m-me-links/#parameters"
      }}>{`a referral parameter`}</a>{` (`}<inlineCode parentName="p">{`ref`}</inlineCode>{`),
this information is also included in the inbound message.`}</p>
    <p>{`For example, a customer clicking on the link `}<inlineCode parentName="p">{`http://m.me/<PAGE_NAME>?ref=my-referral-parameter`}</inlineCode>{` will receive an
`}<inlineCode parentName="p">{`facebookReferral`}</inlineCode>{` inbound message similar to the following one.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "id": "c3fefc2a9e754e878777b557f2e9d977",
  "conversationId": "9702e94e560845868c7fb03a1cd23928",
  "platform": "facebook",
  "to": "103865305630237",
  "from": "5306019189461056",
  "channelId": "d8f3d7ab604b4bac8faa96d02ed83b0f",
  "type": "facebookReferral",
  "content": {
    "facebookReferral": {
      "ref": "my-referral-parameter",
      "source": "SHORTLINK",
      "type": "OPEN_THREAD"
    }
  },
  "direction": "received",
  "status": "received",
  "createdDatetime": "2022-04-28T12:21:02.792Z",
  "updatedDatetime": "2022-04-28T12:21:02.792Z"
}
`}</code></pre>
    <p>{`Please note that the `}<inlineCode parentName="p">{`content.facebookReferral.ref`}</inlineCode>{` field contains the information from the URL `}<inlineCode parentName="p">{`ref`}</inlineCode>{` query parameter.
Please refer to the `}<a parentName="p" {...{
        "href": "/api/conversations#facebookreferral-object"
      }}>{`facebookReferral object`}</a>{` for more information about
this message type. You can also refer to the
`}<a parentName="p" {...{
        "href": "https://developers.facebook.com/docs/messenger-platform/discovery/m-me-links/#requirements"
      }}>{`Facebook m.me link requirements`}</a>{`
to understand what's necessary for using m.me links.`}</p>
    <h2>{`Facebook Messenger conversation advertisement`}</h2>
    <p>{`When customers click on a `}<a parentName="p" {...{
        "href": "https://developers.facebook.com/docs/messenger-platform/discovery/ctm-ads"
      }}>{`Facebook Messenger conversation advertisement`}</a>{`,
Conversations API also delivers an inbound message with type `}<inlineCode parentName="p">{`facebookReferral`}</inlineCode>{` similar to the following one.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "id": "d16d39a760314d1fb026f10e6835c58e",
  "conversationId": "2b9fd2d6b87041ceb6485d8f8c97ab77",
  "platform": "facebook",
  "to": "103955145046384",
  "from": "3919556244813081",
  "channelId": "2cc5f8a4020747be80c6b1f70c9f6eb3",
  "type": "facebookReferral",
  "content": {
    "facebookReferral": {
      "ref": "my-referral-parameter",
      "source": "ADS",
      "type": "OPEN_THREAD",
      "ad_id": "6045246247433",
      "ads_context_data": {
        "ad_title": "My advertisement title",
        "photo_url": "https://www.yourcompany.com/photo.png",
        "video_url": "https://www.yourcompany.com/video.mp4",
        "post_id": "392982738592",
        "product_id": "8478935323493"
      }
    }
  },
  "direction": "received",
  "status": "received",
  "createdDatetime": "2022-04-22T14:09:37.449Z",
  "updatedDatetime": "2022-04-22T14:09:37.449Z"
}
`}</code></pre>
    <h2>{`Facebook Chat plugin`}</h2>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://developers.facebook.com/docs/messenger-platform/discovery/facebook-chat-plugin"
      }}>{`Facebook Chat plugin`}</a>{` allows
you to integrate Facebook Messenger into your company's website. When your customers, browsing your website, start or
resume a conversation thread using the Facebook Chat plugin, Conversations API will deliver an inbound message with type
`}<inlineCode parentName="p">{`facebookReferral`}</inlineCode>{` similar to the following one.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "id": "d16d39a760314d1fb026f10e6835c58e",
  "conversationId": "2b9fd2d6b87041ceb6485d8f8c97ab77",
  "platform": "facebook",
  "to": "103955145046384",
  "from": "3919556244813081",
  "channelId": "2cc5f8a4020747be80c6b1f70c9f6eb3",
  "type": "facebookReferral",
  "content": {
    "facebookReferral": {
      "ref": "my-referral-parameter",
      "source": "CUSTOMER_CHAT_PLUGIN",
      "type": "OPEN_THREAD"
    }
  },
  "direction": "received",
  "status": "received",
  "createdDatetime": "2022-04-22T14:09:37.449Z",
  "updatedDatetime": "2022-04-22T14:09:37.449Z"
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      