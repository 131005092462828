import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Omnichannel Widget Overview`}</h1>
    <p>{`MessageBird’s `}<a parentName="p" {...{
        "href": "/api/omnichannel-widget"
      }}>{`Omnichannel Widget Javascript API`}</a>{` allows you to customize the design, behaviour, and visibility of the `}<a parentName="p" {...{
        "href": "https://inbox.messagebird.com/manager/omnichannel-widget"
      }}>{`Omnichannel Widget`}</a>{` displayed on your website.`}</p>
    <p>{`You can configure parts of the Omnichannel Widget directly in your `}<a parentName="p" {...{
        "href": "https://inbox.messagebird.com/manager/omnichannel-widget"
      }}>{`Omnichannel Widget Settings in Inbox.`}</a></p>
    <p>{`In this section, we’ll walk you through examples of the most popular use cases for the Omnichannel Widget.`}</p>
    <h2><a parentName="h2" {...{
        "href": "/quickstarts/omnichannel-widget/install"
      }}>{`Installation`}</a></h2>
    <p>{`How to install the Omnichannel Widget on your website`}</p>
    <h2><a parentName="h2" {...{
        "href": "/quickstarts/omnichannel-widget/visibility"
      }}>{`Visibility`}</a></h2>
    <p>{`How to trigger, hide and then show Omnichannel Widget on your website.`}</p>
    <h2><a parentName="h2" {...{
        "href": "/quickstarts/omnichannel-widget/attributes"
      }}>{`Attributes`}</a></h2>
    <p>{`How to pass custom customer data, or “attribute”'s, to the Omnichannel Widget to help you identify users when they reach out over Live Chat`}</p>
    <h2><a parentName="h2" {...{
        "href": "/quickstarts/omnichannel-widget/identify"
      }}>{`Identify`}</a></h2>
    <p>{`How to pass custom customer data, or “attribute”'s, to the Omnichannel Widget to help you identify users when they reach out over Live Chat`}</p>
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'Omnichannel Widget API Reference',
      to: '/api/omnichannel-widget'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      