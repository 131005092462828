import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useLocation } from '@reach/router';

import Markdown from '../Markdown';
import Header from '../Header';
import CookieBtn from '../CookieBtn';
import { ResetStyle } from '../Reset';

const sanitizeTitle = ({ rawTitle }) => {
  // remove special characters
  const azTitle = rawTitle.replace(/[^a-zA-Z ]/g, ' ');
  // uppercase first letters
  const ucTitle = azTitle.replace(/(^\w{1})|(\s{1}\w{1})/g, (match) =>
    match.toUpperCase(),
  );
  // cap some words (SMS, MMS, API, HLR)
  const capTitle = ucTitle
    .replace('Api', 'API')
    .replace('Mms', 'MMS')
    .replace('Sms', 'SMS')
    .replace('Hlr', 'HLR');
  // add fallback for empty string
  const title = capTitle.length > 1 ? capTitle : 'Developers';
  return title;
};

export const Base = ({
  children,
  fullWidth,
  noHeader,
  noFooter,
  mdxComponents,
  location,
  api,
}) => {
  const loc = useLocation();
  const rawTitle = loc.hash !== '' ? loc.hash : loc.pathname;
  const title = sanitizeTitle({ rawTitle });

  return (
    <div>
      <ResetStyle />
      <Helmet
        title={`MessageBird | ${title}`}
        meta={[
          {
            name: 'description',
            content:
              'Build powerful apps using the fastest and most reliable cloud communications APIs',
          },
          {
            name: 'keywords',
            content:
              'MessageBird, SMS, Voice, Conversations, Whatsapp, API, Developers',
          },
        ]}
      >
        <html lang="en" />
      </Helmet>
      {!noHeader && <Header location={location} />}
      <Markdown api customComponents={mdxComponents}>
        {children}
      </Markdown>
      <CookieBtn />
    </div>
  );
};

Base.propTypes = {
  children: PropTypes.node.isRequired,
  fullWidth: PropTypes.bool,
  noHeader: PropTypes.bool,
  noFooter: PropTypes.bool,
  mdxComponents: PropTypes.shape({}),
};

Base.defaultProps = {
  fullWidth: false,
  noFooter: false,
  noHeader: false,
  mdxComponents: {},
};

export default Base;
