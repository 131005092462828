import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/ApiRef.js";
import CodeSnippet from 'components/CodeSnippet';
import * as CODE from '../../constants/conversations';
import Notification from 'components/Notification';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`WhatsApp Business API`}</h1>
    <p>{`MessageBird's WhatsApp Business allows you to send and receive WhatsApp messages for alerts, notifications, customer
support, as well as two-factor authentication. You’ll be able to add all WhatsApp Business features through a single API.`}</p>
    <p>{`The WhatsAPP API uses HTTP verbs and a RESTful endpoint structure with an access key that is used as the API Authorization.
Request and response payloads are formatted as JSON using UTF-8 encoding and URL encoded values.`}</p>
    <h2>{`Template Messages for WhatsApp`}</h2>
    <p>{`Template messages are used to start a conversation with a customer or reply to a customer's message outside the 24-hour
window.`}</p>
    <p>{`They use one of your pre-approved WhatsApp Templates (alerts, appointment reminders, shipping notifications, etc) and
need to be submitted and approved by WhatsApp before they can be used. You can submit WhatsApp Templates via the WhatsApp
Template Manager on the MessageBird Dashboard.`}</p>
    <p>{`As they are common reusable messages a business may want to send, this allows a firm to send just the template identifier
along with the appropriate parameters instead of the full message content. It also allows for localization of the message
and decreases the possibility of being blocked on the first contact as the message is pre-approved by WhatsApp.`}</p>
    <p>{`To initiate a conversation with a client, WhatsApp requires you to use a pre-approved message template called a Message
Template (previously called a Highly Structured Message or HSM).`}</p>
    <p>{`Message Templates are message formats for common reusable messages a business may want to send. This allows a firm to
send just the template identifier along with the appropriate parameters instead of the full message content. It also
allows for localization of the message and decreases the possibility of being blocked on the first contact as the message
is pre-approved by WhatsApp.`}</p>
    <h3>{`How to Send a Message Template (HSM)`}</h3>
    <p>{`A Message Template (HSM message) content can be provided anywhere where other message content is allowed,
via `}<inlineCode parentName="p">{`POST /v1/send`}</inlineCode>{`, `}<inlineCode parentName="p">{`POST /conversations/start`}</inlineCode>{` or `}<inlineCode parentName="p">{`POST /conversations/{id}/messages`}</inlineCode>{` (the `}<inlineCode parentName="p">{`{id}`}</inlineCode>{` refers to the conversation ID).`}</p>
    <p>{`Check out the steps on
`}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/articles/360000243978-What-is-an-Highly-Structured-Message-HSM-"
      }}>{`how to submit your Message Template (HSM messages)`}</a>{`
for pre-approval by WhatsApp and our step-by-step quickstart on
`}<a parentName="p" {...{
        "href": "https://developers.messagebird.com/docs/conversations/whatsapp/message-templates-curl/"
      }}>{`how to write and send a WhatsApp Templates`}</a>{`.`}</p>
    <h4>{`Example template message`}</h4>
    <CodeSnippet code={CODE.WHATSAPP_MESSAGE} mdxType="CodeSnippet" />
    <h4>{`Example request using POST /send`}</h4>
    <CodeSnippet code={CODE.WHATSAPP_SEND_REQUEST} mdxType="CodeSnippet" />
    <h4>{`Example response`}</h4>
    <CodeSnippet code={CODE.WHATSAPP_SEND_RESPONSE} mdxType="CodeSnippet" />
    <a name="template-messages"></a>
    <h4>{`Example request using POST /conversations/start`}</h4>
    <CodeSnippet code={CODE.WHATSAPP_START_REQUEST} mdxType="CodeSnippet" />
    <h3>{`Example response`}</h3>
    <CodeSnippet code={CODE.WHATSAPP_START_RESPONSE} mdxType="CodeSnippet" />
    <h3>{`How to Send a Media Template Message`}</h3>
    <Notification type="INFO" mdxType="Notification">
    Media templates must be pre-approved by WhatsApp. Media templates can be submitted to WhatsApp by sending an email
    to our Customer Support team (support@messagebird.com), who will ask for the required template data. Once this is
    received, the Support team will then confirm with you if the template has been approved by WhatsApp.
    Please note: media template submissions are not currently possible through the WhatsApp Template Manager.
    </Notification>
    <p>{`The Media Template Message content must be provided in the `}<inlineCode parentName="p">{`content.hsm`}</inlineCode>{` property via `}<inlineCode parentName="p">{`POST /send`}</inlineCode>{`,
`}<inlineCode parentName="p">{`POST /conversations/start`}</inlineCode>{` or `}<inlineCode parentName="p">{`POST /conversations/{id}/messages`}</inlineCode>{`. Currently, we support two media types -
document and image.`}</p>
    <h4>{`Example media template message`}</h4>
    <CodeSnippet code={CODE.MEDIA_WHATSAPP_MESSAGE} mdxType="CodeSnippet" />
    <h4>{`Example request using POST /send`}</h4>
    <CodeSnippet code={CODE.MEDIA_WHATSAPP_SEND_REQUEST} mdxType="CodeSnippet" />
    <h4>{`Example response`}</h4>
    <CodeSnippet code={CODE.MEDIA_WHATSAPP_SEND_RESPONSE} mdxType="CodeSnippet" />
    <h4>{`Example request using POST /conversations/start`}</h4>
    <CodeSnippet code={CODE.MEDIA_WHATSAPP_START_REQUEST} mdxType="CodeSnippet" />
    <h4>{`Example response`}</h4>
    <CodeSnippet code={CODE.MEDIA_WHATSAPP_START_RESPONSE} mdxType="CodeSnippet" />
    <h2>{`MessageBird WhatsApp Sandbox`}</h2>
    <p>{`The MessageBird WhatsApp Sandbox allows you to try and prototype WhatsApp Business in a developer environment without
going through the approval process.`}</p>
    <Notification type="INFO" mdxType="Notification">
    Check out our quickstart for Getting Started with MessageBird's WhatsApp Sandbox in 6 easy steps.
    </Notification>
    <p>{`A Message Template (HSM message) content can be provided anywhere where other message content is allowed, via
`}<inlineCode parentName="p">{`POST /conversations/start`}</inlineCode>{` or `}<inlineCode parentName="p">{`POST /conversations/{id}/messages`}</inlineCode></p>
    <p>{`The `}<inlineCode parentName="p">{`id`}</inlineCode>{` in the URL refers to the conversation ID.`}</p>
    <p>{`Check out our `}<a parentName="p" {...{
        "href": "https://developers.messagebird.com/quickstarts/whatsapp/getting-started-sandbox/"
      }}>{`quickstart`}</a>{` for Getting
Started with MessageBird's WhatsApp Sandbox in 6 easy steps.`}</p>
    <h3>{`Webhooks with the MessageBird WhatsApp Sandbox`}</h3>
    <p>{`You can now use Conversations Webhooks endpoint with the WhatsApp Sandbox to enable the delivery of real-time
notifications to any endpoint on your own server.`}</p>
    <p>{`The WhatsApp Sandbox currently supports the following webhook events:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`message.created`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A new message has been created. Triggered for both sent and received messages.`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Head over to `}<a parentName="p" {...{
        "href": "/api/conversations#webhooks"
      }}>{`Webhooks`}</a>{` for more details.`}</p>
    <p>{`Please keep in mind that we do not support `}<inlineCode parentName="p">{`message.updated`}</inlineCode>{` events for the sandbox channel at this time.`}</p>
    <h2>{`WhatsApp errors`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Message`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`301`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The message failed to send. Please check your message is valid, including any media, and/or try again later.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`302`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The contact is not registered on WhatsApp.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`470`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Failed to send message because you are outside the support window for freeform messages to this user. Please use a valid message template.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`308`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Message rejected because it exceeded the TTL before it was sent to WhatsApp.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      