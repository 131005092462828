import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Facebook Messenger`}</h1>
    <p>{`Conversations API allows your business to send and receive Facebook Messenger messages for alerts, notifications,
customer support, as well as two-factor authentication. You’ll be able to add all Facebook Messenger features through
a single API.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      