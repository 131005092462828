import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Setting up your local development environment with MessageBird`}</h1>
    <h3>{`⏱ 10 min read`}</h3>
    <p>{`In this MessageBird Developer Tutorial you’ll first learn how to get started with MessageBird by setting up first your local development environment.`}</p>
    <p>{`MessageBird provides an SDK (Software Development Kit) for Java. This helper library facilitates interactions between your Java application's code and the MessageBird APIs, so you don’t have to craft raw REST API requests. Along this tutorial we’ll explain how to get started with MessageBird in Java.`}</p>
    <h2>{`Install Java and Maven`}</h2>
    <p>{`Make sure that you have Java version 1.5 at least—as this is the minimum version of Java that the SDK requires. You also need Maven, a package manager for Java. We recommend you to always use the latest versions of both Java and Maven.`}</p>
    <p>{`To verify which version do you have, open a terminal and run the following two commands:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`java --version
mvn --version
`}</code></pre>
    <p>{`In both cases, your terminal should return a version number, for example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ java --version
openjdk 11 2018-09-25
OpenJDK Runtime Environment 18.9 (build 11+28)
OpenJDK 64-Bit Server VM 18.9 (build 11+28, mixed mode)
$ mvn --version
Apache Maven 3.0.5 (...)
Maven home: ...
Java version: 1.8.0_60, vendor: Oracle Corporation
Java home: ...
Default locale: en_US, platform encoding: Cp1252
OS name: "windows 7", version: "6.1", arch: "amd64", family: "dos"
`}</code></pre>
    <p>{`If you do not get a version number but something like `}<inlineCode parentName="p">{`java: command not found`}</inlineCode>{` instead, you need to install Java and Maven first. Simply follow the instructions on `}<a parentName="p" {...{
        "href": "https://www.oracle.com/technetwork/java/javase/downloads/jdk8-downloads-2133151.html"
      }}>{`Java 1.8`}</a>{`, which will quickly guide you through the process.`}</p>
    <h2>{`Installing the MessageBird SDK`}</h2>
    <p>{`You use Maven to install the MessageBird SDK for Java. The SDK is open source and you can `}<a parentName="p" {...{
        "href": "https://github.com/messagebird/java-rest-api"
      }}>{`browse the code in this GitHub repository`}</a>{`; it is also `}<a parentName="p" {...{
        "href": "https://mvnrepository.com/artifact/com.messagebird/messagebird-api"
      }}>{`listed on the Maven repository website`}</a>{` so that Maven knows where to find it. SDKs and other libraries are always installed for each project as a dependency.`}</p>
    <p>{`Each Java project that uses Maven needs a `}<inlineCode parentName="p">{`pom.xml`}</inlineCode>{` file that includes information both about the current package (your project, such as name and version), as well as the list of dependencies that it relies on (such as the MessageBird SDK).`}</p>
    <p>{`Create a new project directory or open an existing project directory in your terminal.`}</p>
    <p>{`Once you have a `}<inlineCode parentName="p">{`pom.xml`}</inlineCode>{` file, add the `}<inlineCode parentName="p">{`dependency`}</inlineCode>{` block to `}<inlineCode parentName="p">{`dependencies`}</inlineCode>{` to have Maven install the package:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<dependencies>
   <dependency>
       <groupId>com.messagebird</groupId>
       <artifactId>messagebird-api</artifactId>
       <version>2.0.0</version>
   </dependency>
</dependencies>
`}</code></pre>
    <p><strong parentName="p">{`Nice work!`}</strong>{` 🎉`}</p>
    <p>{`Your Java development environment and project directory are ready now!`}</p>
    <h2>{`Start building!`}</h2>
    <p>{`Let's head over to the next MessageBird Developer Tutorials and `}<a parentName="p" {...{
        "href": "/tutorials/send-sms-node"
      }}>{`learn how to send your first SMS using Java`}</a>{`.`}</p>
    <p>{`Want to start building your solution but not quite sure how to get started? Feel free to let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we'd love to help!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      