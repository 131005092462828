import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Docs.tsx";
import Notification from 'components/Notification';
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Troubleshooting WhatsApp`}</h1>
    <p>{`When a message cannot be sent over MessageBird's WhatsApp Business API, the status will change to “rejected” or “failed”, and any additional details available will be provided via an error object attached to the message that indicates the code and a description of the error. `}</p>
    <p>{`The API response will look similar to this: `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "id": "5f3437fdb8444583aea093a047ac014b",
  "conversationId": "2e15efafec384e1c82e9842075e87beb",
  "channelId": "853eeb5348e541a595da93b48c61a1ae",
  "type": "text",
  "content": {
  "text": "This is a test WhatsApp message"
  },
  "direction": "sent",
  "status": "failed",
  "error": {
  "code": 302,
  "description": "The contact is not registered on WhatsApp."
  }
  "createdDatetime": "2018-08-28T15:52:41Z",
  "updatedDatetime": "2018-08-28T15:52:58Z"
}
`}</code></pre>
    <p>{`WhatsApp error codes for common delivery errors are listed below:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Message`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`301`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The message failed to send. Please check your message is valid, including any media, and/or try again later.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`302`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The contact is not registered on WhatsApp.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`470`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Failed to send message because you are outside the support window for freeform messages to this user. Please use a valid message template.`}</td>
        </tr>
      </tbody>
    </table>
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'Programmable Conversations API Reference',
      to: '/api/conversations'
    }, {
      name: 'Use Case Tutorials',
      to: '/tutorials'
    }, {
      name: 'Official MessageBird SDKs',
      to: '/libraries'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      