import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import Notification from 'components/Notification';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Questions = makeShortcode("Questions");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Troubleshooting Programmable Conversations`}</h1>
    <p>{`MessageBird offers multiple tools to help you to quickly understand any unexpected behavior between Programmable Conversations, the specific Channel, and your application.`}</p>
    <h2>{`Errors`}</h2>
    <p>{`MessageBird uses standard HTTP status codes to indicate success or failure of an API request. Codes in the 2xx range indicate that a request was successfully processed and codes in the 4xx range indicate that there was an error that resulted from the information sent (e.g. authentication, no balance or a missing or wrong parameter).`}</p>
    <h3>{`Attributes`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`errors[].code`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An integer that represents the error type.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`errors[].description`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A human-readable description of the error. You can use this to let the user know what they can do about the error.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`errors[].parameter`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The parameter in your request related to the error if the error is parameter specific.`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`In the case of an error, the body of the response includes a `}<inlineCode parentName="p">{`json`}</inlineCode>{` formatted response that tells you exactly what's wrong.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "errors": [
    {
      "code": 1001,
      "message": "You are requesting with an invalid credential.",
      "parameter": null
    }
  ]
}
`}</code></pre>
    <h2>{`Message errors`}</h2>
    <p>{`When a message cannot be sent, the status will change to “rejected” or “failed”, and any additional details available will be provided via an error object attached to the message which indicates the code and a description of the error. Platform-specific error codes for common delivery errors are listed below.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "id": "5f3437fdb8444583aea093a047ac014b",
  "conversationId": "2e15efafec384e1c82e9842075e87beb",
  "channelId": "853eeb5348e541a595da93b48c61a1ae",
  "type": "text",
  "content": {
    "text": "This is a test WhatsApp message"
  },
  "direction": "sent",
  "status": "failed",
  "error": {
    "code": 302,
    "description": "The contact is not registered on WhatsApp."
  }
  "createdDatetime": "2018-08-28T15:52:41Z",
  "updatedDatetime": "2018-08-28T15:52:58Z"
}
`}</code></pre>
    <h2>{`WhatsApp errors`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Message`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`301`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The message failed to send. Please check your message is valid, including any media, and/or try again later.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`302`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The contact is not registered on WhatsApp.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`470`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Failed to send message because you are outside the support window for freeform messages to this user. Please use a valid message template.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`General API error codes`}</h2>
    <p>{`If you've received an error response and code from MessageBird that's not listed above, you can troubleshoot the error by exploring the general `}<a parentName="p" {...{
        "href": "/api#api-errors"
      }}>{`API Error Codes.`}</a></p>
    <p>{`In case of an API error, the body of the response includes a `}<inlineCode parentName="p">{`json`}</inlineCode>{` formatted response that tells you exactly what is wrong.`}</p>
    <h2>{`Contact technical support`}</h2>
    <p>{`If you’re still having problems troubleshooting the Programmable Conversations API, please don’t hesitate to contact us at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we're here to help you out.`}</p>
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      