import React from 'react';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';

export const Link = ({ to, ...props }) => {
  if (to && to.indexOf('http') !== 0) {
    return <GatsbyLink {...props} to={to} />;
  }
  // eslint-disable-next-line jsx-a11y/anchor-has-content
  return <a {...props} href={to} target="_blank" rel="noopener noreferrer" />;
};

Link.propTypes = {
  to: PropTypes.string.isRequired,
};

export default Link;
