import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import Notification from 'components/Notification';
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Request an HLR`}</h1>
    <p>{`In this Lookup API Quickstart, we'll show you how to programmatically request an HLR via the Lookup API.`}</p>
    <h2>{`Step 1: Sign up for free`}</h2>
    <p>{`Before we get started, `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/login"
      }}>{`log in to the MessageBird Connectivity Platform`}</a>{` and collect your live API key from the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`Developer Dashboard`}</a>{`.`}</p>
    <h2>{`Step 2: Request your first HLR`}</h2>
    <p>{`Next, enter `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`your credentials`}</a>{` and mobile number to the below snippet and copy it to your terminal. Now, hit enter!`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-curl"
      }}>{`curl -X POST https://rest.messagebird.com/lookup/31XXXXXXXXXX/hlr \\
         -H 'Authorization: AccessKey YOUR-API-KEY' \\
         -d "reference=YourReference"
`}</code></pre>
    <p>{`You’ve requested a Lookup with Lookup API!`}</p>
    <h2>{`Step 3: View the response`}</h2>
    <p>{`Now, let’s view MessageBird’s HTTP response to your API call. If the set up was correct, you will receive a response similar to this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`{
  "id":"<ID>",
  "href":"https:\\/\\/rest.messagebird.com\\/hlr\\/<ID>",
  "msisdn":31XXXXXXXXXX,
  "network":null,
  "details":[],
  "reference":"YourReference",
  "status":"sent",
  "createdDatetime":"2016-05-04T07:32:46+00:00",
  "statusDatetime":"2016-05-04T07:32:46+00:00"
}
`}</code></pre>
    <p>{`If something went wrong, you’ll receive an error response from the MessageBird API. Don't worry, head over to `}<a parentName="p" {...{
        "href": "/docs/lookup/troubleshooting"
      }}>{`Troubleshooting Lookup`}</a>{` to understand the error.`}</p>
    <p>{`Congrats! You can now programmatically request an HLR with MessageBird's HLR API using cURL.`}</p>
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'View an HLR',
      to: '/quickstarts/lookup/view-an-hlr'
    }, {
      name: 'Troubleshooting Lookup',
      to: '/quickstarts/lookup/troubleshooting'
    }, {
      name: 'Lookup API Reference',
      to: '/api/lookup'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      