import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import PageHeadersContext from '../../contexts/PageHeadersContext';

const A = styled.a`
  display: block;
  position: relative;
  top: -85px;
  visibility: hidden;
`;

const WithAnchor = (Component) => {
  const ComponentWithAnchor = ({ children, ...props }) => {
    const { duplicateIds } = useContext(PageHeadersContext);
    const duplicateAnchors = { ...duplicateIds };
    let base = React.Children.toArray(children)
      .filter((child) => typeof child === 'string')
      .join(' ')
      .trim();
    let suffix;
    const isBaseDuplicated = (base) =>
      Object.keys(duplicateAnchors).includes(base);

    if (isBaseDuplicated(base)) {
      suffix = duplicateAnchors[base] === 0 ? '' : duplicateAnchors[base];
      duplicateIds[base] -= 1;
      base += suffix;
    }
    const slug = base.replace(/[\s\t\n\r]/g, '-').toLowerCase();

    useEffect(() => {
      //keep the same  suffix even when  component was rerendered
      const baseWithoutSuffix = base.split('-')[0];
      if (isBaseDuplicated(baseWithoutSuffix)) {
        duplicateIds[base.split('-')[0]] += 1;
      }
    });
    return (
      <>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content */}
        <A id={slug} />
        <Component {...props}>{children}</Component>
      </>
    );
  };
  ComponentWithAnchor.propTypes = {
    children: PropTypes.node.isRequired,
  };
  return ComponentWithAnchor;
};

export default WithAnchor;
