import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`WhatsApp Sticker Messages`}</h1>
    <p>{`WhatsApp sticker messages can be sent and received through MessageBird WhatsApp channels. These are the requirements/limitations
of WhatsApp sticker messages:`}</p>
    <ul>
      <li parentName="ul">{`The sticker image must be available in a public URL or uploaded to MessageBird (refer to the next section)`}</li>
      <li parentName="ul">{`The image format must be `}<inlineCode parentName="li">{`image/webp`}</inlineCode></li>
      <li parentName="ul">{`The image size can't be bigger than 100 KB`}</li>
      <li parentName="ul">{`The image dimensions must be exactly 512 x 512`}</li>
      <li parentName="ul">{`Animated stickers are not supported at the moment`}</li>
      <li parentName="ul">{`Native first party and third party WhatsApp stickers are not supported for outbound messages`}</li>
    </ul>
    <h2>{`Upload sticker image to MessageBird`}</h2>
    <p>{`In case you don't have the sticker image available in a public URL, you can upload the image to MessageBird using the
following request.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-curl"
      }}>{`curl -X POST https://messaging.messagebird.com/v1/files \\
  -H 'Authorization: AccessKey {your-access-key}' \\
  -H 'Content-Disposition: attachment; filename="{filename.webp}"' \\
  -H 'Content-Type: image/webp' \\
  --data-binary @{filename.webp}
`}</code></pre>
    <p>{`Don't forget to replace `}<inlineCode parentName="p">{`{your-access-key}`}</inlineCode>{` by your live access key, and `}<inlineCode parentName="p">{`{filename}`}</inlineCode>{` by the file name. The HTTP
response should look like the following.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{"id":"99449497-535c-46c1-b57c-ddd193edfc5b","name":"{filename.webp}"}
`}</code></pre>
    <p>{`The HTTP response includes the ID of the uploaded file, which can be retrieved using the URL
`}<a parentName="p" {...{
        "href": "https://messaging.messagebird.com/v1/files/99449497-535c-46c1-b57c-ddd193edfc5b"
      }}>{`https://messaging.messagebird.com/v1/files/99449497-535c-46c1-b57c-ddd193edfc5b`}</a>{`.
This URL can be used in the field `}<inlineCode parentName="p">{`content.whatsappSticker.link`}</inlineCode>{` to send the sticker message.`}</p>
    <h2>{`Sending a sticker message`}</h2>
    <p>{`In order to send sticker message, you can use any of
`}<a parentName="p" {...{
        "href": "https://developers.messagebird.com/api/conversations/#sending-messages"
      }}>{`the endpoints for sending messages in Conversations API`}</a>{`
with the message type `}<inlineCode parentName="p">{`whatsappSticker`}</inlineCode>{`. The example below illustrates how to send it using the endpoint `}<inlineCode parentName="p">{`POST /v1/send`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-curl"
      }}>{`curl -X "POST" "https://conversations.messagebird.com/v1/send" \\
     -H 'Authorization: AccessKey {your-access-key}' \\
     -H 'Content-Type: application/json; charset=utf-8' \\
     -d $'{
  "content": {
    "whatsappSticker": {
      "link": "{sticker-image-url}"
    }
  },
  "to": "{destination}",
  "type": "whatsappSticker",
  "from": "{channel-id}"
}'
`}</code></pre>
    <p>{`It's important to note the following:`}</p>
    <ul>
      <li parentName="ul">{`Replace `}<inlineCode parentName="li">{`{your-access-key}`}</inlineCode>{` by your live access key`}</li>
      <li parentName="ul">{`Replace `}<inlineCode parentName="li">{`{sticker-image-url}`}</inlineCode>{` by a valid and public URL, or use the URL provided by Messaging API as demonstrated in
the previous section`}</li>
      <li parentName="ul">{`Replace `}<inlineCode parentName="li">{`{destination}`}</inlineCode>{` by a valid WhatsApp phone number`}</li>
      <li parentName="ul">{`The message `}<inlineCode parentName="li">{`type`}</inlineCode>{` must be `}<inlineCode parentName="li">{`whatsappSticker`}</inlineCode></li>
      <li parentName="ul">{`Replace `}<inlineCode parentName="li">{`{channel-id}`}</inlineCode>{` by an ID of an active WhatsApp channel`}</li>
    </ul>
    <p>{`The HTTP response code should be `}<inlineCode parentName="p">{`200 OK`}</inlineCode>{`, and the response body should contain the message ID.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "id": "3b75218770cs44dha3je77896fa86d62",
  "status": "accepted"
}
`}</code></pre>
    <p>{`Optionally, you can use the endpoint for fetching message details (`}<inlineCode parentName="p">{`GET /v1/messages/{id}`}</inlineCode>{`).`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-curl"
      }}>{`curl "https://conversations.messagebird.com/v1/messages/3b75218770cs44dha3je77896fa86d62" \\
     -H 'Authorization: AccessKey {your-access-key}' \\
     -H 'Content-Type: application/json; charset=utf-8'
`}</code></pre>
    <p>{`The HTTP response should look like the following.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "id": "3b75218770cs44dha3je77896fa86d62",
  "conversationId": "0b7c237df609487c9c41437tab502889",
  "platform": "whatsapp",
  "to": "+31600000000",
  "from": "+31600000000",
  "channelId": "54d17af6-98be-42u3-420c-4e98d04f023c",
  "type": "whatsappSticker",
  "content": {
    "whatsappSticker": {
      "link": "https://messaging.messagebird.com/v1/files/99449497-535c-46c1-b57c-ddd193edfc5b"
    }
  },
  "direction": "sent",
  "status": "delivered",
  "createdDatetime": "2021-11-19T16:24:53.00257632Z",
  "updatedDatetime": "2021-11-19T16:24:57Z"
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      