import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import { Img } from 'components/shared';
import Notification from 'components/Notification';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Facebook Generic Templates`}</h1>
    <p><a parentName="p" {...{
        "href": "https://developers.facebook.com/docs/messenger-platform/send-messages/template/generic"
      }}>{`Facebook generic templates`}</a>{`
allows your business to send structured messages with a title, subtitle, image and up to three buttons.`}</p>
    <h2>{`Sending a generic template`}</h2>
    <p>{`In order to send a generic template, you can use any of
`}<a parentName="p" {...{
        "href": "https://developers.messagebird.com/api/conversations/#sending-messages"
      }}>{`the endpoints for sending messages in Conversations API`}</a>{`
with the message type `}<inlineCode parentName="p">{`facebookGenericTemplate`}</inlineCode>{`. The example below illustrates how to send it using the endpoint `}<inlineCode parentName="p">{`POST /v1/send`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-curl"
      }}>{`curl -X "POST" "https://conversations.messagebird.com/v1/send" \\
     -H 'Authorization: AccessKey {your-access-key}' \\
     -H 'Content-Type: application/json; charset=utf-8' \\
     -d $'{
  "content": {
    "facebookGenericTemplate": {
      "attachment": {
        "type": "template",
        "payload": {
          "image_aspect_ratio": "horizontal",
          "template_type": "generic",
          "elements": [
            {
              "title": "Welcome to MessageBird!",
              "subtitle": "Would you like to know more about Plans & Pricing?",
              "image_url": "https://www.messagebird.com/img/og/messagebird.gif",
              "buttons": [
                {
                  "type": "postback",
                  "payload": "custom-value",
                  "title": "Talk to us"
                },
                {
                  "type": "web_url",
                  "url": "https://www.messagebird.com",
                  "title": "Visit website"
                },
                {
                  "type": "phone_number",
                  "title": "Call us",
                  "payload": "+31000000000"
                }
              ]
            }
          ]
        }
      }
    }
  },
  "to": "4190117307726093",
  "type": "facebookGenericTemplate",
  "from": "99d0e570cd834661a1771ab08044be8b"
}'
`}</code></pre>
    <ul>
      <li parentName="ul">{`The message `}<inlineCode parentName="li">{`type`}</inlineCode>{` must be `}<inlineCode parentName="li">{`facebookGenericTemplate`}</inlineCode></li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.facebookGenericTemplate`}</inlineCode>{` must be set with a `}<a parentName="li" {...{
          "href": "/api/conversations/#facebookmessage-object"
        }}>{`FacebookMessage`}</a></li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.facebookGenericTemplate.attachment`}</inlineCode>{` must be set with a `}<a parentName="li" {...{
          "href": "/api/conversations/#facebookattachment-object"
        }}>{`FacebookAttachment`}</a></li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.facebookGenericTemplate.attachment.type`}</inlineCode>{` must be `}<inlineCode parentName="li">{`template`}</inlineCode>{` for template messages`}</li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.facebookGenericTemplate.attachment.payload`}</inlineCode>{` must be set with a `}<a parentName="li" {...{
          "href": "/api/conversations/#facebookattachmentpayload-object"
        }}>{`FacebookAttachmentPayload`}</a></li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.facebookGenericTemplate.attachment.payload.image_aspect_ratio`}</inlineCode>{` defines if the image aspect ratio is horizontal or square`}</li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.facebookGenericTemplate.attachment.payload.template_type`}</inlineCode>{` must be `}<inlineCode parentName="li">{`media`}</inlineCode>{` for media template messages`}</li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.facebookGenericTemplate.attachment.payload.elements`}</inlineCode>{` must contain at least one `}<a parentName="li" {...{
          "href": "/api/conversations/#facebookelement-object"
        }}>{`FacebookElement`}</a></li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.facebookGenericTemplate.attachment.payload.elements[].title`}</inlineCode>{` must contain a title for the element`}</li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.facebookGenericTemplate.attachment.payload.elements[].subtitle`}</inlineCode>{` contains an optional subtitle`}</li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.facebookGenericTemplate.attachment.payload.elements[].image_url`}</inlineCode>{` must contain a valid image URL`}</li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.facebookGenericTemplate.attachment.payload.elements[].buttons`}</inlineCode>{` must contain a maximum of three `}<a parentName="li" {...{
          "href": "/api/conversations/#facebookbutton-object"
        }}>{`FacebookButton`}</a></li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.facebookGenericTemplate.attachment.payload.elements[].buttons[].type`}</inlineCode>{` must contain one of the values of `}<a parentName="li" {...{
          "href": "/api/conversations/#facebookbuttontype-object"
        }}>{`FacebookButtonType`}</a></li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.facebookGenericTemplate.attachment.payload.elements[].buttons[].payload`}</inlineCode>{` depends on the button `}<inlineCode parentName="li">{`type`}</inlineCode>{`:
if it's `}<inlineCode parentName="li">{`postback`}</inlineCode>{`, it must contain the payload to be sent through a `}<a parentName="li" {...{
          "href": "/api/conversations/#facebookpostback-object"
        }}>{`FacebookPostback`}</a>{` inbound message;
if it's `}<inlineCode parentName="li">{`phone_number`}</inlineCode>{`, it must contain a valid phone number to be called when the button is clicked`}</li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.facebookGenericTemplate.attachment.payload.elements[].buttons[].title`}</inlineCode>{` must contain the button label`}</li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.facebookGenericTemplate.attachment.payload.elements[].buttons[].url`}</inlineCode>{` must contain a valid URL which the user will be redirected to when clicking at the button`}</li>
    </ul>
    <p>{`The message sent by the previous code snippet looks like the following.`}</p>
    <Img src="/img/screenshots/quickstarts/facebook/generic-template.png" align="middle" mdxType="Img" />
    <p>{`The message looks like the following in Conversations API.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "id": "b8bb66a07fcc46c2b56f8422587200b5",
  "conversationId": "6279c2012f7d4df393cfa1f872518518",
  "platform": "facebook",
  "to": "4190117307726093",
  "from": "101397625015964",
  "channelId": "99d0e570cd834661a1771ab08044be8b",
  "type": "facebookGenericTemplate",
  "content": {
    "facebookGenericTemplate": {
      "attachment": {
        "type": "template",
        "payload": {
          "template_type": "generic",
          "elements": [
            {
              "buttons": [
                {
                  "type": "postback",
                  "title": "Talk to us",
                  "payload": "custom-value"
                },
                {
                  "type": "web_url",
                  "url": "https://www.messagebird.com",
                  "title": "Visit website"
                },
                {
                  "type": "phone_number",
                  "title": "Call us",
                  "payload": "+31000000000"
                }
              ],
              "title": "Welcome to MessageBird!",
              "subtitle": "Would you like to know more about Plans \\u0026 Pricing?",
              "image_url": "https://www.messagebird.com/img/og/messagebird.gif"
            }
          ]
        }
      }
    }
  },
  "direction": "sent",
  "status": "sent",
  "createdDatetime": "2022-03-21T15:46:39.403957027Z",
  "updatedDatetime": "2022-03-21T15:46:39.403957027Z"
}
`}</code></pre>
    <h2>{`Handling the postback button response`}</h2>
    <p>{`When sending a generic template message with a `}<inlineCode parentName="p">{`postback`}</inlineCode>{` button, like in the code snippet illustrated previously, you
must set a custom payload in the `}<inlineCode parentName="p">{`payload`}</inlineCode>{` field of the button. The value set in this field is sent through an inbound
message with type `}<a parentName="p" {...{
        "href": "/api/conversations/#facebookpostback-object"
      }}>{`FacebookPostBack`}</a>{`.`}</p>
    <Notification type="INFO" mdxType="Notification">
    The value in the payload field is limited to 1000 characters.
    </Notification>
    <p>{`Assuming a `}<inlineCode parentName="p">{`postback`}</inlineCode>{` button with a `}<inlineCode parentName="p">{`payload`}</inlineCode>{` field set to `}<inlineCode parentName="p">{`custom-value`}</inlineCode>{` like the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "type": "postback",
  "title": "Talk to us",
  "payload": "custom-value"
}
`}</code></pre>
    <p>{`When the user clicks on the button, then the following inbound message is created in the conversation.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "id": "3b9d18e21fd3468fa35004f7733e33bc",
  "conversationId": "6279c2012f7d4df393cfa1f872518518",
  "platform": "facebook",
  "to": "101397625015964",
  "from": "4190117307726093",
  "channelId": "99d0e570cd834661a1771ab08044be8b",
  "type": "facebookPostback",
  "content": {
    "facebookPostback": {
      "title": "Talk to us",
      "payload": "custom-value"
    }
  },
  "direction": "received",
  "status": "received",
  "createdDatetime": "2022-03-21T16:05:46.432Z",
  "updatedDatetime": "2022-03-21T16:05:46.432Z"
}
`}</code></pre>
    <ul>
      <li parentName="ul">{`The message `}<inlineCode parentName="li">{`type`}</inlineCode>{` is `}<inlineCode parentName="li">{`facebookPostback`}</inlineCode></li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.facebookPostback.title`}</inlineCode>{` contains the button label that was clicked`}</li>
      <li parentName="ul">{`The field `}<inlineCode parentName="li">{`content.facebookPostback.payload`}</inlineCode>{` contains the custom payload value of the button that was clicked`}</li>
    </ul>
    <h2>{`Default action on click`}</h2>
    <p>{`Generic templates also support the ability of triggering a default action when the template (not a button) is clicked. In
order to enable this feature, you must set the field `}<inlineCode parentName="p">{`default_action`}</inlineCode>{` in the `}<a parentName="p" {...{
        "href": "/api/conversations/#facebookelement-object"
      }}>{`FacebookElement`}</a>{`
object. The example below illustrates how to send a message with a default action.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-curl"
      }}>{`curl -X "POST" "https://conversations.messagebird.com/v1/send" \\
     -H 'Authorization: AccessKey {your-access-key}' \\
     -H 'Content-Type: application/json; charset=utf-8' \\
     -d $'{
  "content": {
    "facebookGenericTemplate": {
      "attachment": {
        "type": "template",
        "payload": {
          "image_aspect_ratio": "horizontal",
          "template_type": "generic",
          "elements": [
            {
              "default_action": {
                "type": "web_url",
                "url": "https://www.messagebird.com"
              },
              "title": "Welcome to MessageBird!",
              "subtitle": "Would you like to know more about Plans & Pricing?",
              "image_url": "https://www.messagebird.com/img/og/messagebird.gif",
              "buttons": [
                {
                  "type": "postback",
                  "payload": "custom-value",
                  "title": "Talk to us"
                },
                {
                  "type": "web_url",
                  "url": "https://www.messagebird.com",
                  "title": "Visit website"
                },
                {
                  "type": "phone_number",
                  "title": "Call us",
                  "payload": "+31000000000"
                }
              ]
            }
          ]
        }
      }
    }
  },
  "to": "4190117307726093",
  "type": "facebookGenericTemplate",
  "from": "99d0e570cd834661a1771ab08044be8b"
}'
`}</code></pre>
    <p>{`The only difference between this code snippet and the previous one is that, in this one, the `}<inlineCode parentName="p">{`default_action`}</inlineCode>{` is defined
with the `}<inlineCode parentName="p">{`type`}</inlineCode>{` set to `}<inlineCode parentName="p">{`web_url`}</inlineCode>{` and the `}<inlineCode parentName="p">{`url`}</inlineCode>{` field set to `}<inlineCode parentName="p">{`https://www.messagebird.com`}</inlineCode>{`. Therefore, when the user clicks
on the template, they will be redirected to the MessageBird website. In the Facebook Messenger app, the message looks like
exactly the same as previously demonstrated.`}</p>
    <h2>{`Sending a carousel of generic templates`}</h2>
    <p>{`Conversations API also supports sending a carousel of generic templates. In order to do that, you must set multiple
`}<a parentName="p" {...{
        "href": "/api/conversations/#facebookelement-object"
      }}>{`FacebookElement`}</a>{` in the field `}<inlineCode parentName="p">{`elements`}</inlineCode>{`. You can use any of
`}<a parentName="p" {...{
        "href": "https://developers.messagebird.com/api/conversations/#sending-messages"
      }}>{`the endpoints for sending messages in Conversations API`}</a>{`
with the message type `}<inlineCode parentName="p">{`facebookGenericTemplate`}</inlineCode>{`. The example below illustrates how to send it using the endpoint `}<inlineCode parentName="p">{`POST /v1/send`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-curl"
      }}>{`curl -X "POST" "https://conversations.messagebird.com/v1/send" \\
     -H 'Authorization: AccessKey {your-access-key}' \\
     -H 'Content-Type: application/json; charset=utf-8' \\
     -d $'{
  "content": {
    "facebookGenericTemplate": {
      "attachment": {
        "type": "template",
        "payload": {
          "image_aspect_ratio": "horizontal",
          "template_type": "generic",
          "elements": [
            {
              "buttons": [
                {
                  "type": "web_url",
                  "url": "https://messagebird.com/en/pricing",
                  "title": "See pricing"
                }
              ],
              "title": "Solutions Plans",
              "image_url": "https://www.messagebird.com/img/og/solutions.png"
            },
            {
              "buttons": [
                {
                  "type": "web_url",
                  "url": "https://messagebird.com/en/pricing/api",
                  "title": "See API pricing"
                }
              ],
              "title": "API Pricing",
              "image_url": "https://www.messagebird.com/img/og/api.jpg"
            },
            {
              "buttons": [
                {
                  "type": "web_url",
                  "url": "https://messagebird.com/en/pricing/support",
                  "title": "See Support pricing"
                }
              ],
              "title": "Support Plans",
              "image_url": "https://www.messagebird.com/img/og/support.png"
            }
          ]
        }
      }
    }
  },
  "to": "4190117307726093",
  "type": "facebookGenericTemplate",
  "from": "99d0e570cd834661a1771ab08044be8b"
}'
`}</code></pre>
    <p>{`As you can see, the `}<inlineCode parentName="p">{`elements`}</inlineCode>{` field contains three `}<a parentName="p" {...{
        "href": "/api/conversations/#facebookelement-object"
      }}>{`FacebookElement`}</a>{` objects,
and every one of those elements is displayed at once in the carousel. The following images illustrate how the message
sent in the previous code snippet is displayed in the Facebook Messenger app.`}</p>
    <Img src="/img/screenshots/quickstarts/facebook/carousel-message-part1.png" align="middle" mdxType="Img" />
    <Img src="/img/screenshots/quickstarts/facebook/carousel-message-part2.png" align="middle" mdxType="Img" />
    <Img src="/img/screenshots/quickstarts/facebook/carousel-message-part3.png" align="middle" mdxType="Img" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      