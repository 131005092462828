import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import CodeSnippet from 'components/CodeSnippet';
import * as CODE from '../../../constants/conversations';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Email Attachments and Inline Images`}</h1>
    <p>{`In order to send email messages with attachments or inline images, you must first upload them into Messaging API. It is
mandatory to provide the `}<inlineCode parentName="p">{`Content-Type`}</inlineCode>{` in the HTTP headers, and also chucked transmission is not supported yet.`}</p>
    <CodeSnippet code={CODE.MAPI_UPLOAD_FILE_ENDPOINT} mdxType="CodeSnippet" />
    <p>{`Here's a sample request of how can you upload a file to Messaging API.`}</p>
    <CodeSnippet code={CODE.UPLOAD_FILE_REQUEST_EXAMPLE} mdxType="CodeSnippet" />
    <p>{`The HTTP response will look like the following.`}</p>
    <CodeSnippet code={CODE.UPLOAD_FILE_RESPONSE_EXAMPLE} mdxType="CodeSnippet" />
    <p>{`The HTTP response contains the media ID, which can be used when sending the email message through Conversations API.`}</p>
    <p>{`Here's an example of sending an email message with an attachment. Note that the media ID must be provided in the field
`}<inlineCode parentName="p">{`content.email.attachments.id`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-curl"
      }}>{`curl -X "POST" "https://conversations.messagebird.com/v1/send" \\
     -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
     -H 'Content-Type: application/json; charset=utf-8' \\
     -d $'{
  "content": {
    "email": {
      "attachments": [
        {
          "id": "742599bd-1a46-42b4-b382-114f9b4fb54e"
        }
      ],
      "subject": "Sample message with attachment",
      "to": [
        {
          "name": "John Doe",
          "address": "john.doe@sample.com"
        }
      ],
      "content": {
        "html": "Hello! Here is a sample message with attachment."
      },
      "from": {
        "name": "Customer Support",
        "address": "support@yourcompany.com"
      }
    }
  },
  "to": "john.doe@sample.com",
  "type": "email",
  "from": "452859f5f5734db8808753acg4f49908"
}'
`}</code></pre>
    <p>{`Here's an example of sending an email message with an inline image. Please note that:`}</p>
    <ul>
      <li parentName="ul">{`The media ID must be provided in the field `}<inlineCode parentName="li">{`content.email.inlineImages.id`}</inlineCode></li>
      <li parentName="ul">{`Every inline image must have a `}<inlineCode parentName="li">{`contentID`}</inlineCode>{` (`}<inlineCode parentName="li">{`content.email.inlineImages.contentID`}</inlineCode>{`). The `}<inlineCode parentName="li">{`contentID`}</inlineCode>{` must be used in
the `}<inlineCode parentName="li">{`src`}</inlineCode>{` property of the image tag within the message content (`}<inlineCode parentName="li">{`content.email.content.html`}</inlineCode>{`): `}<inlineCode parentName="li">{`<img src="cid:mb-logo">`}</inlineCode>{`.`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-curl"
      }}>{`curl -X "POST" "https://conversations.messagebird.com/v1/send" \\
     -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
     -H 'Content-Type: application/json; charset=utf-8' \\
     -d $'{
  "content": {
    "email": {
      "subject": "Sample message with inline image",
      "to": [
        {
          "name": "John Doe",
          "address": "john.doe@sample.com"
        }
      ],
      "content": {
        "html": "<p>Hello! Here is a sample message with an inline image:</p><img src=\\\\"cid:mb-logo\\\\">"
      },
      "inlineImages": [
        {
          "id": "742599bd-1a46-42b4-b382-114f9b4fb54e",
          "contentID": "mb-logo"
        }
      ],
      "from": {
        "name": "Customer Support",
        "address": "support@yourcompany.com"
      }
    }
  },
  "to": "john.doe@sample.com",
  "type": "email",
  "from": "452859f5f5734db8808753acg4f49908"
}'
`}</code></pre>
    <p>{`In case you need to fetch the media in Messaging API, you can do it by using the following endpoint.`}</p>
    <CodeSnippet code={CODE.MAPI_FETCH_FILE_ENDPOINT} mdxType="CodeSnippet" />
    <p>{`Here is a sample request you can use to fetch a specific file from Messaging API.`}</p>
    <CodeSnippet code={CODE.FETCH_FILE_REQUEST_EXAMPLE} mdxType="CodeSnippet" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      