/* eslint-disable no-useless-escape */
export const BASE_URL = {
  bash: `https://integrations.messagebird.com`,
};

export const INTEGRATIONS_GET_ALL = {
  bash: `https://integrations.messagebird.com/v1/public/integrations`,
};

export const INTEGRATIONS_GET_ALL_METHODS = {
  bash: `GET /integrations`,
};

export const INTEGRATIONS_GET_ALL_DEF = {
  bash: `GET https://integrations.messagebird.com/v1/public/integrations`,
};

export const INTEGRATIONS_GET_ALL_REQUEST = {
  curl: `curl -X GET "https://integrations.messagebird.com/v1/public/integrations?limit=50&offset=0" \\
  -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM" \\
  -H "Content-Type: application/json" `,
};

export const INTEGRATIONS_GET_ALL_RESPONSE = {
  curl: `{
  "count": 1,
  "items": [
    {
      "id": "sample-id",
      "integrationId": "sample-id",
      "name": "Inbox Google Business Messaging",
      "workspaceId": 1111,
      "createdAt": "2021-07-24T09:42:01Z",
      "updatedAt": "2021-07-24T09:42:01Z",
      "archivedAt": null,
      "version": "1.0",
      "status": "pending",
      "slug": "googlebm",
    }
  ],
  "limit": 1,
  "offset": 0,
  "totalCount": 5
}`,
};

//
export const INTEGRATIONS_GET_BY_NAME = {
  json: `https://integrations.messagebird.com/v1/public/integrations/{slug}`,
};

export const INTEGRATIONS_GET_BY_NAME_METHODS = {
  bash: `GET /integrations`,
};

export const INTEGRATIONS_GET_BY_NAME_DEF = {
  bash: `GET https://integrations.messagebird.com/v1/public/integrations/{slug}`,
};

export const INTEGRATIONS_GET_BY_NAME_REQUEST = {
  curl: `curl -X GET "https://integrations.messagebird.com/v1/public/integrations/googlebm?limit=50&offset=0" \\
  -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM" \\
  -H "Content-Type: application/json" `,
};

export const INTEGRATIONS_GET_BY_NAME_RESPONSE = {
  curl: `{
  "count": 1,
  "items": [
    {
      "id": "sample-id",
      "integrationId": "sample-id",
      "name": "Inbox Google Business Messaging",
      "workspaceId": 1111,
      "createdAt": "2021-07-24T09:42:01Z",
      "updatedAt": "2021-07-24T09:42:01Z",
      "archivedAt": null,
      "version": "1.0",
      "status": "pending",
      "slug": "googlebm",
    }
  ],
  "limit": 1,
  "offset": 0,
  "totalCount": 5
}`,
};

//
export const CREATE_WHATSAPP_TEMPLATE_ENDPOINT = {
  bash: `POST /v2/platforms/whatsapp/templates`,
};

export const WHATSAPP_TEMPLATE_CREATE_REQUEST = {
  curl: `curl -X "POST" "https://integrations.messagebird.com/v2/platforms/whatsapp/templates" \\
     -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
     -H 'Content-Type: application/json; charset=utf-8' \\
     -d $'{
  "language": "en",
  "components": [
    {
      "type": "HEADER",
      "format": "IMAGE",
      "example": {
        "header_url": ["https://mycdn.com/image.jpeg"]
      }
    },
    {
      "type": "BODY",
      "text": "Hello {{1}}! This is a sample template. {{2}}",
      "example": {
        "body_text": [["John", "bye!"]]
      }
    },
    {
      "type": "FOOTER",
      "text": "Sample footer"
    },
    {
      "type": "BUTTONS",
      "buttons": [
        {
          "type": "PHONE_NUMBER",
          "text": "Call us!",
          "phone_number": "+31 0 0000-0000"
        },
        {
          "type": "URL",
          "text": "Watch",
          "url": "https://www.youtube.com/watch?v={{1}}",
          "example": ["https://www.youtube.com/watch?v=abcd"]
        }
      ]
    }
  ],
  "name": "sample_whatsapp_template",
  "category": "TRANSACTIONAL"
}'`,
};

export const WHATSAPP_TEMPLATE_CREATE_RESPONSE = {
  curl: `{
  "name": "sample_whatsapp_template",
  "language": "en",
  "status": "NEW",
  "components": [
    {
      "type": "HEADER",
      "format": "IMAGE",
      "example": {
        "header_url": ["https://mycdn.com/image.jpeg"]
      }
    },
    {
      "type": "BODY",
      "text": "Hello {{1}}! This is a sample template. {{2}}",
      "example": {
        "body_text": [["John", "bye!"]]
      }
    },
    {
      "type": "FOOTER",
      "text": "Sample footer"
    },
    {
      "type": "BUTTONS",
      "buttons": [
        {
          "type": "PHONE_NUMBER",
          "text": "Call us!",
          "phone_number": "+31 0 0000-0000"
        },
        {
          "type": "URL",
          "text": "Watch",
          "url": "https://www.youtube.com/watch?v={{1}}",
          "example": ["https://www.youtube.com/watch?v=abcd"]
        }
      ]
    }
  ],
  "category": "TRANSACTIONAL",
  "createdAt": "2021-08-06T06:34:40.815772829Z",
  "updatedAt": "2021-08-06T06:34:40.815772829Z"
}`,
};

export const UPDATE_WHATSAPP_TEMPLATE_ENDPOINT = {
  bash: `PUT /v2/platforms/whatsapp/templates/{name}/{language}`,
};

export const WHATSAPP_TEMPLATE_UPDATE_REQUEST = {
  curl: `curl -X "PUT" "https://integrations.messagebird.com/v2/platforms/whatsapp/templates/sample_whatsapp_template/en" \\
     -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
     -H 'Content-Type: application/json; charset=utf-8' \\
     -d $'{
  "components": [
    {
      "type": "HEADER",
      "format": "IMAGE",
      "example": {
        "header_url": ["https://mycdn.com/image.jpeg"]
      }
    },
    {
      "type": "BODY",
      "text": "Hello {{1}}! This is a sample template. {{2}}",
      "example": {
        "body_text": [["John", "bye!"]]
      }
    },
    {
      "type": "FOOTER",
      "text": "Sample footer"
    },
    {
      "type": "BUTTONS",
      "buttons": [
        {
          "type": "PHONE_NUMBER",
          "text": "Call us!",
          "phone_number": "+31 0 0000-0000"
        },
        {
          "type": "URL",
          "text": "Watch",
          "url": "https://www.youtube.com/watch?v={{1}}",
          "example": ["https://www.youtube.com/watch?v=abcd"]
        }
      ]
    }
  ],
  "category": "MARKETING"
}'`,
};

export const WHATSAPP_TEMPLATE_UPDATE_RESPONSE = {
  curl: `{
  "name": "sample_whatsapp_template",
  "language": "en",
  "status": "APPROVED",
  "components": [
    {
      "type": "HEADER",
      "format": "IMAGE",
      "example": {
        "header_url": ["https://mycdn.com/image.jpeg"]
      }
    },
    {
      "type": "BODY",
      "text": "Hello {{1}}! This is a sample template. {{2}}",
      "example": {
        "body_text": [["John", "bye!"]]
      }
    },
    {
      "type": "FOOTER",
      "text": "Sample footer"
    },
    {
      "type": "BUTTONS",
      "buttons": [
        {
          "type": "PHONE_NUMBER",
          "text": "Call us!",
          "phone_number": "+31 0 0000-0000"
        },
        {
          "type": "URL",
          "text": "Watch",
          "url": "https://www.youtube.com/watch?v={{1}}",
          "example": ["https://www.youtube.com/watch?v=abcd"]
        }
      ]
    }
  ],
  "category": "MARKETING",
  "createdAt": "2021-08-06T06:34:40.815772829Z",
  "updatedAt": "2021-08-06T06:34:40.815772829Z"
}`,
};

export const LIST_TEMPLATES_ENDPOINT = {
  bash: `GET /v3/platforms/whatsapp/templates`,
};

export const WHATSAPP_TEMPLATE_GET_ALL_METHODS = {
  bash: `GET /whatsapp/templates`,
};

export const WHATSAPP_TEMPLATE_GET_ALL_DEF = {
  bash: `GET https://integrations.messagebird.com/v1/public/whatsapp/templates`,
};

export const LIST_TEMPLATES_REQUEST_EXAMPLE = {
  curl: `curl -X GET "https://integrations.messagebird.com/v3/platforms/whatsapp/templates" \\
  -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM" \\
  -H "Content-Type: application/json"`,
};

export const LIST_TEMPLATES_RESPONSE_EXAMPLE = {
  curl: `{
  "count": 2,
  "limit": 50,
  "offset": 0,
  "totalCount": 2
  "items": [
    {
      "name": "sample_whatsapp_template",
      "language": "en",
      "status": "REJECTED",
      "components": [
        {
          "type": "HEADER",
          "format": "IMAGE"
        },
        {
          "type": "BODY",
          "text": "Hello {{1}}! This is a sample template."
        },
        {
          "type": "FOOTER",
          "text": "Sample footer"
        },
        {
          "type": "BUTTONS",
          "buttons": [
            {
              "type": "PHONE_NUMBER",
              "text": "Call us!",
              "phone_number": "+31 0 0000-0000"
            },
            {
              "type": "URL",
              "text": "Watch",
              "url": "https://www.youtube.com/watch?v={{1}}"
            }
          ]
        }
      ],
      "category": "TRANSACTIONAL",
      "rejectedReason": "(#192) Param components[3]['buttons'][0]['phone_number'] is not a valid phone number.",
      "createdAt": "2021-08-06T06:34:40.815772829Z",
      "updatedAt": "2021-08-06T06:35:11.74973411Z"
    },
    {
      "name": "hsm_test2121213232",
      "language": "en_US",
      "status": "REJECTED",
      "components": [
        {
          "type": "BODY",
          "text": "Hello {{1}}! Let's send out first message template"
        }
      ],
      "id": "1964838697016877",
      "category": "RESERVATION_UPDATE",
      "rejectedReason": "INVALID_FORMAT",
      "createdAt": "2021-07-26T16:10:55.718436747Z",
      "updatedAt": "2021-07-26T16:28:17.558421505Z"
    },
    {
      "name": "name121132112",
      "language": "en",
      "status": "REJECTED",
      "components": [
        {
          "type": "BODY",
          "format": "TEXT",
          "text": "hello world!"
        },
        {
          "type": "BUTTONS",
          "format": "TEXT",
          "buttons": [
            {
              "type": "QUICK_REPLY",
              "text": "Do you really?"
            }
          ]
        }
      ],
      "category": "TRANSACTIONAL",
      "rejectedReason": "component of type BODY has unexpected field(s) (format)",
      "createdAt": "2021-07-21T14:31:42.791116435Z",
      "updatedAt": "2021-07-21T14:37:13.002058598Z"
    }
  ]
}`,
};

//
export const LIST_TEMPLATES_BY_NAME_ENDPOINT = {
  bash: `GET /v2/platforms/whatsapp/templates/{name}`,
};

export const LIST_TEMPLATES_BY_NAME_REQUEST_EXAMPLE = {
  curl: `curl -X GET "https://integrations.messagebird.com/v2/platforms/whatsapp/templates/sample_whatsapp_template" \\
  -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM" \\
  -H "Content-Type: application/json"`,
};

export const LIST_TEMPLATES_BY_NAME_RESPONSE_EXAMPLE = {
  json: `[
  {
    "name": "sample_whatsapp_template",
    "language": "en",
    "status": "REJECTED",
    "components": [
      {
        "type": "HEADER",
        "format": "IMAGE"
      },
      {
        "type": "BODY",
        "text": "Hello {{1}}! This is a sample template."
      },
      {
        "type": "FOOTER",
        "text": "Sample footer"
      },
      {
        "type": "BUTTONS",
        "buttons": [
          {
            "type": "PHONE_NUMBER",
            "text": "Call us!",
            "phone_number": "+31 0 0000-0000"
          },
          {
            "type": "URL",
            "text": "Watch",
            "url": "https://www.youtube.com/watch?v={{1}}"
          }
        ]
      }
    ],
    "category": "TRANSACTIONAL",
    "rejectedReason": "(#192) Param components[3]['buttons'][0]['phone_number'] is not a valid phone number.",
    "createdAt": "2021-08-06T06:34:40.815772829Z",
    "updatedAt": "2021-08-06T06:35:11.74973411Z"
  }
]`,
};

export const LIST_TEMPLATES_BY_NAME_AND_LANGUAGE_ENDPOINT = {
  bash: 'GET /v2/platforms/whatsapp/templates/{name}/{language}',
};

export const LIST_TEMPLATES_BY_NAME_AND_LANGUAGE_REQUEST_EXAMPLE = {
  curl: `curl -X GET "https://integrations.messagebird.com/v2/platforms/whatsapp/templates/sample_whatsapp_template/en" \\
  -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM" \\
  -H "Content-Type: application/json"`,
};

export const LIST_TEMPLATES_BY_NAME_AND_LANGUAGE_RESPONSE_EXAMPLE = {
  json: `{
  "name": "sample_whatsapp_template",
  "language": "en",
  "status": "REJECTED",
  "components": [
    {
      "type": "HEADER",
      "format": "IMAGE"
    },
    {
      "type": "BODY",
      "text": "Hello {{1}}! This is a sample template."
    },
    {
      "type": "FOOTER",
      "text": "Sample footer"
    },
    {
      "type": "BUTTONS",
      "buttons": [
        {
          "type": "PHONE_NUMBER",
          "text": "Call us!",
          "phone_number": "+31 0 0000-0000"
        },
        {
          "type": "URL",
          "text": "Watch",
          "url": "https://www.youtube.com/watch?v={{1}}"
        }
      ]
    }
  ],
  "category": "TRANSACTIONAL",
  "rejectedReason": "(#192) Param components[3]['buttons'][0]['phone_number'] is not a valid phone number.",
  "createdAt": "2021-08-06T06:34:40.815772829Z",
  "updatedAt": "2021-08-06T06:35:11.74973411Z"
}`,
};

export const DELETE_TEMPLATE_BY_NAME_ENDPOINT = {
  bash: 'DELETE /v2/platforms/whatsapp/templates/{name}',
};

export const DELETE_TEMPLATE_BY_NAME_REQUEST_EXAMPLE = {
  curl: `curl -X DELETE "https://integrations.messagebird.com/v2/platforms/whatsapp/templates/sample_whatsapp_template" \\
  -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM" \\
  -H "Content-Type: application/json"`,
};

export const DELETE_TEMPLATE_BY_NAME_AND_LANGUAGE_ENDPOINT = {
  bash: 'DELETE /v2/platforms/whatsapp/templates/{name}/{language}',
};

export const DELETE_TEMPLATE_BY_NAME_AND_LANGUAGE_REQUEST_EXAMPLE = {
  curl: `curl -X DELETE "https://integrations.messagebird.com/v2/platforms/whatsapp/templates/sample_whatsapp_template/en" \\
  -H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM" \\
  -H "Content-Type: application/json"`,
};
