import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Docs.tsx";
import Notification from 'components/Notification';
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Send your first WhatsApp Message`}</h1>
    <p>{`In this WhatsApp Business API Quickstart, you'll learn how to send a new WhatsApp message to a WhatsApp user using Programmable Conversations.`}</p>
    <h2>{`Step 1: Request Early Access`}</h2>
    <p>{`To start testing MessageBird's WhatsApp Business API using Programmable Conversations, you need to first request and be granted early access. `}</p>
    <Notification type="INFO" mdxType="Notification">
      <a href="https://messagebird.com/en/whatsapp/">Request early access to MessageBird's WhatsApp Business API</a>.
    </Notification>
    <p>{`Before we get started, make sure you've read and completed all steps in our `}<a parentName="p" {...{
        "href": "/docs/conversations/whatsapp/getting-started"
      }}>{`Getting Started with WhatsApp`}</a>{` Quickstart.`}</p>
    <h2>{`Step 2: Send your first WhatsApp message`}</h2>
    <p>{`First, you need to collect your live API key from the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`MessageBird Developer Dashboard`}</a>{` and your Channel-ID from your `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/channels"
      }}>{`Channel Directory`}</a>{`.`}</p>
    <p>{`Let's now enter your credentials, WhatsApp Channel-ID, and mobile number to the code snippet below:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-curl"
      }}>{`curl -X "POST" "https://conversations.messagebird.com/v1/send" \\
-H "Authorization: AccessKey YOUR-API-KEY" \\
-H "Content-Type: application/json" \\
--data '{ "to":"+31XXXXXXXXX", "from":"WHATSAPP-CHANNEL-ID", "type":"text", "content":{ "text":"Hello!", "disableUrlPreview": false } }'
`}</code></pre>
    <p>{`Once your credentials are entered, go ahead and copy the above snippet to your terminal and hit enter!`}</p>
    <p>{`Note: If you would like to disable URL preview (only available in text messages) you should set the value of `}<inlineCode parentName="p">{`disableUrlPreview`}</inlineCode>{` to `}<inlineCode parentName="p">{`true`}</inlineCode>{`. Default is `}<inlineCode parentName="p">{`false`}</inlineCode>{`.`}</p>
    <h2>{`Step 3: View the response`}</h2>
    <p>{`Now, let's view the MessageBird’s HTTP response to your API call. If the set up was correct, you'll receive a response similar to this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "id": "24500a370c86916fe8aef77e4c24b6a6",
  "status": "accepted",
  "fallback": null
}
`}</code></pre>
    <p>{`If something went wrong, you’ll receive an error response from the MessageBird API. Don't worry, simply head over to `}<a parentName="p" {...{
        "href": "/docs/conversations/whatsapp/troubleshooting-whatsapp"
      }}>{`Troubleshooting WhatsApp`}</a>{` to understand the error.`}</p>
    <p>{`Congrats! You can now send WhatsApp messages with Programmable Conversations using cURL.`}</p>
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'Programmatically handle inbound messages',
      to: '/docs/conversations/handle-inbound-messages-curl'
    }, {
      name: 'Programmatically reply to inbound messages',
      to: '/docs/conversations/reply-to-messages-curl'
    }, {
      name: 'Programmable Conversations API Reference',
      to: '/api/conversations'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      