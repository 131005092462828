import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Docs.tsx";
import { Img } from 'components/shared';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`SMS API Troubleshooting`}</h1>
    <p>{`MessageBird offers multiple tools to help you to quickly understand any unexpected behavior between the MessageBird SMS API and your application.`}</p>
    <h2>{`Explore the SMS logs in the Dashboard`}</h2>
    <p>{`If you run into issues with SMS message delivery, the best place to start troubleshooting is the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/messages/list"
      }}>{`SMS Logs in the MessageBird Dashboard.`}</a></p>
    <Img src="/img/screenshots/quickstarts/message-logs.png" alt="Message Logs" width="650px" align="middle" mdxType="Img" />
    <p>{`There you can find an overview of the sent and received messages for the last 30 days, and you can `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/messages/export"
      }}>{`export`}</a>{` messages older than 30 days.`}</p>
    <p>{`Select the eye symbol on the far right side of the logs to drill into each specific message for information such as `}<inlineCode parentName="p">{`ID`}</inlineCode>{`, `}<inlineCode parentName="p">{`Originator`}</inlineCode>{`, `}<inlineCode parentName="p">{`Send Time`}</inlineCode>{`, `}<inlineCode parentName="p">{`Message`}</inlineCode>{`, and `}<inlineCode parentName="p">{`Summary`}</inlineCode>{`.`}</p>
    <p>{`You can filter the SMS logs by Status (e.g., filter by undelivered) using the `}<inlineCode parentName="p">{`Filter`}</inlineCode>{` button on the top-right.`}</p>
    <h2>{`General API error codes`}</h2>
    <p>{`If you've received an error response and code from MessageBird, you can troubleshoot the error by exploring the general `}<a parentName="p" {...{
        "href": "/docs/handling-errors"
      }}>{`API Error Codes.`}</a></p>
    <p>{`In case of an API error, the body of the response includes a `}<inlineCode parentName="p">{`json`}</inlineCode>{` formatted response that tells you exactly what's wrong.`}</p>
    <h2>{`Contact technical support`}</h2>
    <p>{`If you’re still having problems troubleshooting the SMS API, please don’t hesitate to contact us at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we're here to help you out.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      