import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Docs.tsx";
import Notification from 'components/Notification';
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
import { Img } from 'components/shared';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Number restrictions`}</h1>
    <p>{`Some restrictions do apply when using Numbers. You can find specific restrictions per country on our `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/sections/360000574598-Numbers"
      }}>{`Country Features page`}</a>{`.`}</p>
    <p>{`Numbers are best used for local, two-way communication and for receiving SMS from local mobiles rather than internationally.`}</p>
    <p><strong parentName="p">{`SMS vs. Voice Numbers`}</strong></p>
    <p>{`Be sure to specify which number capabilities you need when you buy a Number.`}</p>
    <p>{`If a Number you have is neither Voice nor SMS-enabled, you’ll need to purchase another number in order to have that functionality.`}</p>
    <p><strong parentName="p">{`United States and Canada`}</strong></p>
    <p>{`American and Canadian Numbers have a daily limit of 500 SMS per day, and 1 SMS per second.`}</p>
    <p>{`If you want to send more than 500 SMS per day or require a higher throughput, you can buy additional Numbers.`}</p>
    <p>{`For example: 3 Numbers = 3 SMS per second and 1500 SMS per day.`}</p>
    <p>{`If you’re using our API and your dedicated Number, you will have to bear this in mind when setting up your configuration.`}</p>
    <p>{`If you ignore these regulations, the operator may block your Number. For the Shared Number, we take care of the limitations for you by throttling.`}</p>
    <p>{`For a full overview of USA and Canada related restrictions, you can check out the `}{`[Country restrictions]`}{`(`}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/sections/360000108538-Country-info-Restrictions"
      }}>{`https://support.messagebird.com/hc/en-us/sections/360000108538-Country-info-Restrictions`}</a>{` m).`}</p>
    <p><strong parentName="p">{`Receiving Verification Codes`}</strong></p>
    <p>{`Numbers are designed for two-way communication with a SIM card phone number, not for receiving from an application system like the ones that generate verification codes.`}</p>
    <p>{`Virtual Numbers cannot be to be used to verify user accounts for receiving login codes and especially those of financial institutions. This is strictly prohibited and the use of a Number to receive OTP will result in the suspension of the account.`}</p>
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'Buy a number',
      to: '/docs/numbers/buy-a-number/'
    }, {
      name: 'Numbers API Reference',
      to: '/api/numbers'
    }, {
      name: 'Official MessageBird SDKs',
      to: '/libraries'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      