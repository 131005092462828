import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from '@rebass/grid';
import styled from 'styled-components';
import { COLOR, NOTIFICATION_COLORS } from '../../constants';

const NOTIFICATION_ICONS = {
  GOOD: '/img/icons/icn_success.svg',
  BAD: '/img/icons/icn_error.svg',
  WARNING: '/img/icons/icn_alert.svg',
  INFO: '/img/icons/icn_info.svg',
};

const NotificationWrapper = styled(Flex)`
  border-radius: 4px;
  background-color: ${({ type }) => NOTIFICATION_COLORS[type]};
  padding: 16px;
  margin-bottom: 40px;
  img {
    margin-right: 16px;
    transform: translateY(2px);
  }
`;

const NotificationMessage = styled.p`
  margin: 0;
  color: ${COLOR.DARKGREY};
  font-size: 16px;
  line-height: 28px;
  align-self: center;
`;

const Notification = ({ type, children, ...props }) => (
  <>
    {type && (
      <NotificationWrapper {...props} type={type} className="notification">
        <div>
          <img alt="Notification icon" src={NOTIFICATION_ICONS[type]} />
        </div>

        <NotificationMessage>{children}</NotificationMessage>
      </NotificationWrapper>
    )}
  </>
);

Notification.propTypes = {
  type: PropTypes.oneOf(['INFO', 'BAD', 'GOOD', 'WARNING']),
  children: PropTypes.node.isRequired,
};

Notification.defaultProps = {
  type: 'GOOD',
};

export default Notification;
