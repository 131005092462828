import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/ApiRef.js";
import CodeSnippet from 'components/CodeSnippet';
import * as CODE from '../../constants/verify';
import { APIWrapper, LeftColumn, RightColumn } from 'components/ApiRef';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Verify API`}</h1>
    <p>{`MessageBird's Verify API enables you to verify a mobile phone number or email with two-factor authentication. Create a new Verify object through the API to start the verification process of a recipient. MessageBird will take care of creating a token and making sure that the message gets delivered to the recipient.`}</p>
    <p>{`The Verify API uses `}<inlineCode parentName="p">{`HTTP verbs`}</inlineCode>{` and a `}<inlineCode parentName="p">{`RESTful`}</inlineCode>{` endpoint structure with an access key that is used as the API Authorization. Request and response payloads are formatted as JSON using UTF-8 encoding and URL encoded values.`}</p>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`API Endpoint`}</h2>
        <p>{`All URLs referenced in MessageBird's Verify API documentation have the base URL in the code snippet on the right side:`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>API Endpoint</h4>
  <CodeSnippet code={CODE.VERIFY_URI} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Available HTTP methods`}</h2>
        <p>{`The MessageBird API uses HTTP verbs to understand if you want to read (`}<inlineCode parentName="p">{`GET`}</inlineCode>{`), delete (`}<inlineCode parentName="p">{`DELETE`}</inlineCode>{`) or create (`}<inlineCode parentName="p">{`POST`}</inlineCode>{`) an object. When your web application cannot do a `}<inlineCode parentName="p">{`POST`}</inlineCode>{` or `}<inlineCode parentName="p">{`DELETE`}</inlineCode>{`, we provide the ability to set the method through the query parameter `}<inlineCode parentName="p">{`_method`}</inlineCode>{`.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Available HTTP methods</h4>
  <CodeSnippet code={CODE.VERIFY_METHODS} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`The Verify object`}</h2>
        <p>{`This object represents a One-Time Password, or verification token, at `}<a parentName="p" {...{
            "href": "https://messagebird.com"
          }}>{`MessageBird.com`}</a></p>
        <h3>{`Attributes`}</h3>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Attribute`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`id`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A unique random ID which is created on the MessageBird platform and is returned upon creation of the object`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`href`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The URL of the created object.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`recipient`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The msisdn of the recipient or email.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`originator`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The msisdn of the originator or email.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`reference`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Your reference. Must be alphanumeric.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`type`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Your type of message. Values can be: `}<inlineCode parentName="td">{`sms`}</inlineCode>{`, `}<inlineCode parentName="td">{`flash`}</inlineCode>{`, `}<inlineCode parentName="td">{`tts`}</inlineCode>{`, `}<inlineCode parentName="td">{`email`}</inlineCode></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`status`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The status of the `}<strong parentName="td">{`verification`}</strong>{`. Possible values: `}<inlineCode parentName="td">{`sent`}</inlineCode>{`, `}<inlineCode parentName="td">{`expired`}</inlineCode>{`, `}<inlineCode parentName="td">{`failed`}</inlineCode>{`, `}<inlineCode parentName="td">{`verified`}</inlineCode>{`, and `}<inlineCode parentName="td">{`deleted`}</inlineCode></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`messages`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`hash`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A hash containing one id and href entry referring to the URL of the created object. The entry can either refer to either the `}<inlineCode parentName="td">{`messages`}</inlineCode>{`, `}<inlineCode parentName="td">{`voicemessages`}</inlineCode>{` or the `}<inlineCode parentName="td">{`verify/messages/email`}</inlineCode>{` endpoints. To know the `}<strong parentName="td">{`status of delivery`}</strong>{` of the respective message use one of those endpoints`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`createdDatetime`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`datetime`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The date and time of the creation of the Verify object in RFC3339 format (Y-m-d\\TH:i:sP)`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`validUntilDatetime`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`datetime`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The date and time indicating the expiration time of the Verify object in RFC3339 format (Y-m-d\\TH:i:sP)`}</td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Object example</h4>
  <CodeSnippet code={CODE.VERIFY_OBJECT} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Request a verify`}</h2>
        <p>{`Creates a new Verify object and sends a verification token to a recipient. MessageBird returns the created Verify object with each request.`}</p>
        <h4>{`Required parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Parameter`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`recipient`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The telephone number or email that you want to verify. `}<strong parentName="td">{`Required`}</strong></td>
            </tr>
          </tbody>
        </table>
        <h4>{`Optional parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Parameter`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`originator`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The sender of the message. This can be email (channel should be created for that domain name) or a telephone number (including country code) or an alphanumeric string. In case of an alphanumeric string, the maximum length is 11 characters.`}<br /><em parentName="td">{`Default: Code`}</em></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`reference`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A client reference. Must be alphanumeric.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`type`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The type of message. Values can be: `}<inlineCode parentName="td">{`sms`}</inlineCode>{`, `}<inlineCode parentName="td">{`flash`}</inlineCode>{`, `}<inlineCode parentName="td">{`tts`}</inlineCode>{`, `}<inlineCode parentName="td">{`email`}</inlineCode><br /><em parentName="td">{`Default: sms`}</em></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`template`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The template of the message body. Needs to contain `}<inlineCode parentName="td">{`%token`}</inlineCode>{` for the verification code to be included.`}<br />{`Default: Your code is: %token`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`datacoding`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The datacoding used by the template, can be `}<inlineCode parentName="td">{`plain`}</inlineCode>{` (GSM 03.38 characters only), `}<inlineCode parentName="td">{`unicode`}</inlineCode>{` (contains non-GSM 03.38 characters) or `}<inlineCode parentName="td">{`auto`}</inlineCode>{`, we will then set unicode or plain depending on the body content.`}<br /><em parentName="td">{`Default: plain`}</em></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`timeout`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`int`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The time in seconds it takes the OTP code to expire. You could set that parameter to value that works better for you between 30 and 172801 seconds (2 days) Default: 30`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`tokenLength`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`int`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The number of characters in the verification code. Must be between 6 and 10. `}<em parentName="td">{`Default: 6`}</em></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`maxAttempts`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`int`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The maximum number of token input attempts a user can do before the Verify object is marked as `}<inlineCode parentName="td">{`failed`}</inlineCode>{`. Must be between 1 and 10. `}<em parentName="td">{`Default: 1`}</em></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`voice`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The voice in which the messages needs to be read to the recipient. Possible values are: `}<inlineCode parentName="td">{`male`}</inlineCode>{`, `}<inlineCode parentName="td">{`female`}</inlineCode>{`.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`language`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The language in which the message needs to be read to the recipient. Possible values are: `}<inlineCode parentName="td">{`cy-gb`}</inlineCode>{`,`}<inlineCode parentName="td">{`da-dk`}</inlineCode>{`,`}<inlineCode parentName="td">{`de-de`}</inlineCode>{`,`}<inlineCode parentName="td">{`el-gr`}</inlineCode>{`,`}<inlineCode parentName="td">{`en-au`}</inlineCode>{`,`}<inlineCode parentName="td">{`en-gb`}</inlineCode>{`,`}<inlineCode parentName="td">{`en-gb-wls`}</inlineCode>{`,`}<inlineCode parentName="td">{`en-in`}</inlineCode>{`,`}<inlineCode parentName="td">{`en-us`}</inlineCode>{`,`}<inlineCode parentName="td">{`es-es`}</inlineCode>{`,`}<inlineCode parentName="td">{`es-mx`}</inlineCode>{`,`}<inlineCode parentName="td">{`es-us`}</inlineCode>{`,`}<inlineCode parentName="td">{`fr-ca`}</inlineCode>{`,`}<inlineCode parentName="td">{`fr-fr`}</inlineCode>{`,`}<inlineCode parentName="td">{`id-id`}</inlineCode>{`,`}<inlineCode parentName="td">{`is-is`}</inlineCode>{`,`}<inlineCode parentName="td">{`it-it`}</inlineCode>{`,`}<inlineCode parentName="td">{`ja-jp`}</inlineCode>{`,`}<inlineCode parentName="td">{`ko-kr`}</inlineCode>{`,`}<inlineCode parentName="td">{`ms-my`}</inlineCode>{`,`}<inlineCode parentName="td">{`nb-no`}</inlineCode>{`,`}<inlineCode parentName="td">{`nl-nl`}</inlineCode>{`,`}<inlineCode parentName="td">{`pl-pl`}</inlineCode>{`,`}<inlineCode parentName="td">{`pt-br`}</inlineCode>{`,`}<inlineCode parentName="td">{`pt-pt`}</inlineCode>{`,`}<inlineCode parentName="td">{`ro-ro`}</inlineCode>{`,`}<inlineCode parentName="td">{`ru-ru`}</inlineCode>{`,`}<inlineCode parentName="td">{`sv-se`}</inlineCode>{`,`}<inlineCode parentName="td">{`ta-in`}</inlineCode>{`,`}<inlineCode parentName="td">{`th-th`}</inlineCode>{`,`}<inlineCode parentName="td">{`tr-tr`}</inlineCode>{`,`}<inlineCode parentName="td">{`vi-vn`}</inlineCode>{`,`}<inlineCode parentName="td">{`zh-cn`}</inlineCode>{`,`}<inlineCode parentName="td">{`zh-hk`}</inlineCode>{`.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`subject`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The subject of the email. `}<em parentName="td">{`Default: Your OTP token`}</em></td>
            </tr>
          </tbody>
        </table>
        <h4>{`Response`}</h4>
        <p>{`Returns a Verify object if the request was successful. If the request failed, an `}<a parentName="p" {...{
            "href": "/api#api-errors"
          }}>{`error object`}</a>{` will be returned.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.REQUESTVERIFY_DEF} mdxType="CodeSnippet" />
  <h4>Example request</h4>
  <CodeSnippet code={CODE.REQUESTVERIFY_REQUEST} mdxType="CodeSnippet" />
  <h4>Example response</h4>
  <CodeSnippet code={CODE.REQUESTVERIFY_RESPONSE} mdxType="CodeSnippet" />
  <h4>Example request for email</h4>
  <CodeSnippet code={CODE.REQUEST_EMAIL_VERIFY_REQUEST} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Verify a token`}</h2>
        <p>{`Verifies a sent verification token. Can only be done once for each token.`}</p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Parameter`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`required`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`id`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A unique random ID which is created on the MessageBird platform and is returned upon creation of the object.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Yes`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`token`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`An unique token which was sent to the `}<inlineCode parentName="td">{`recipient`}</inlineCode>{` upon creation of the object. `}<strong parentName="td">{`Required`}</strong></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Yes`}</td>
            </tr>
          </tbody>
        </table>
        <h4>{`Response`}</h4>
        <p>{`Returns a Verify object if the request was successful. If the request failed, an `}<a parentName="p" {...{
            "href": "/api#api-errors"
          }}>{`error object`}</a>{` will be returned.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.VERIFYVERIFY_DEF} mdxType="CodeSnippet" />
  <h4>Example request</h4>
  <CodeSnippet code={CODE.VERIFYVERIFY_REQUEST} mdxType="CodeSnippet" />
  <h4>Example response</h4>
        <CodeSnippet code={CODE.VERIFYVERIFY_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`View a Verify object`}</h2>
        <p>{`Retrieves the information of an existing Verify object. You only need to supply the unique id that was returned upon creation.`}</p>
        <h4>{`Required parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Parameter`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`required`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`id`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A unique random ID which is created on the MessageBird platform and is returned upon creation of the object.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Yes`}</td>
            </tr>
          </tbody>
        </table>
        <h3>{`Response`}</h3>
        <p>{`Returns a Verify object if the request was successful. If the request failed, an `}<a parentName="p" {...{
            "href": "/api#api-errors"
          }}>{`error object`}</a>{` will be returned.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.VIEWVERIFY_DEF} mdxType="CodeSnippet" />
  <h4>Example request</h4>
  <CodeSnippet code={CODE.VIEWVERIFY_REQUEST} mdxType="CodeSnippet" />
  <h4>Example response</h4>
  <CodeSnippet code={CODE.VIEWVERIFY_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <hr></hr>
        <h2>{`Delete a Verify object`}</h2>
        <p>{`Deletes an existing Verify object. You only need to supply the unique id that was returned upon creation.`}</p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Parameter`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`required`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`id`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A unique random ID which is created on the MessageBird platform and is returned upon creation of the object.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Yes`}</td>
            </tr>
          </tbody>
        </table>
        <h3>{`Response`}</h3>
        <p>{`Returns an HTTP header of `}<inlineCode parentName="p">{`204 No Content`}</inlineCode>{` and an empty response if the request was successful. If the request failed, an `}<a parentName="p" {...{
            "href": "/api#api-errors"
          }}>{`error object`}</a>{` will be returned.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.DELETEVERIFY_DEF} mdxType="CodeSnippet" />
  <h4>Example request</h4>
  <CodeSnippet code={CODE.DELETEVERIFY_REQUEST} mdxType="CodeSnippet" />
  <h4>Example response</h4>
  <CodeSnippet code={CODE.DELETEVERIFY_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      