import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Creating a Transcription Request for a Voice Recording with MessageBird`}</h1>
    <p>{`A `}<inlineCode parentName="p">{`transcription`}</inlineCode>{` is a textual representation of a recording that has speech. The MessageBird API provides an easy way to create a `}<inlineCode parentName="p">{`transcription`}</inlineCode>{` for a recording file that was previously generated for a specific call or call leg.`}</p>
    <p>{`In this MessageBird Tutorial you’ll learn how to create a `}<inlineCode parentName="p">{`transcription`}</inlineCode>{` request for a voice recording with our API.`}</p>
    <h2>{`Step 1: Create the Transcription Request`}</h2>
    <p>{`To create a `}<inlineCode parentName="p">{`transcription`}</inlineCode>{` request with MessageBird Voice API, you need to know the ID of the recording. You can find more information about the `}<inlineCode parentName="p">{`transcription`}</inlineCode>{` object in the `}<a parentName="p" {...{
        "href": "/api/voice-calling#transcriptions"
      }}>{`'Transcriptions' section`}</a>{` of our Voice Calling API documentation.`}</p>
    <p>{`The Voice API endpoint for creating a transcription request for a leg is:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`https://voice.messagebird.com/recordings/{recordingID}/transcriptions/
`}</code></pre>
    <p>{`This is a transcription request example with cURL:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`curl -X "POST"
"http://voice.messagebird.com/recordings/{recordingID}/transcriptions" \\
  -H "Authorization: AccessKey :your_access_key:"
`}</code></pre>
    <p>{`Here’s an example result to a request like that:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "data": [
    {
      "id": "{transcriptionID}",
      "recordingID": "{recordingID}",
      "createdAt": "2017-06-26T10:12:14Z",
      "updatedAt": "2017-06-26T10:12:14Z"
    }
  ],
  "_links": {
    "self": "/transcriptions/{transcriptionID}"
    "file": "/transcriptions/{transcriptionID}.txt"
  }
}
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`your_access_key`}</inlineCode>{` is your API access token that can be found under your `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/app/en-gb/developers/access"
      }}>{`API access tab`}</a>{` in the MessageBird Dashboard. This request will initiate a `}<inlineCode parentName="p">{`transcription`}</inlineCode>{` for that recording.`}</p>
    <h2>{`Step 2: Get the transcription result`}</h2>
    <p>{`You can access the `}<inlineCode parentName="p">{`transcription`}</inlineCode>{` object that you previously created by making a GET request to the endpoint:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`http://voice.messagebird.com/transcriptions/{transcriptionID}
`}</code></pre>
    <p>{`The MessageBird API also provides an endpoint for downloading the .txt result of a transcription:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`http://voice.messagebird.com/transcriptions/{transcriptionID}.txt
`}</code></pre>
    <p>{`By marking a request in the previous endpoint, you can download the text file with the transcription result:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`## List Calls
curl
"http://voice.messagebird.com/transcriptions/{transcriptionID}.txt" \\
  -H "Authorization: AccessKey :your_access_key:"
`}</code></pre>
    <h2>{`Keep in mind`}</h2>
    <p>{`If the transcription has failed, we’ll return an error message. Reasons for a failed transcription may include not recognition of the voice in a recording due to background noise, unidentified language and low volume in the recorded message.`}</p>
    <p>{`You can create a transcription request immediately after a recording has ended by providing the transcription parameter as true in the record step of a call flow; you can read more about this in our `}<a parentName="p" {...{
        "href": "/tutorials/call-recording-curl"
      }}>{`Call Recording MessageBird Tutorial`}</a>{`.`}</p>
    <p>{`The transcriptions are being generated asynchronously, which means that when you POST a transcription request, the result will be available some time after the transcription ends; you may then get the transcription text file by marking the request we just described.`}</p>
    <p><strong parentName="p">{`Nice work!`}</strong>{` 🎉`}</p>
    <p>{`You just learned how to create a transcription for a recording with the MessageBird API!`}</p>
    <h2>{`Start building!`}</h2>
    <p>{`Want to build something similar but not quite sure how to get started? Feel free to let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we'd love to help!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      