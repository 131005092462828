import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import Notification from 'components/Notification';
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
import { Img } from 'components/shared';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Buy a Number`}</h1>
    <p>{`You can buy a Number directly from your Dashboard. Go to the left-hand side of your Dashboard screen and hit Numbers → '`}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/numbers/modal/buy-number"
      }}>{`Buy a number`}</a>{`' blue button on the top-right side of your screen. `}</p>
    <p>{`You can select the country, capability, and number.`}</p>
    <Img src="/img/screenshots/quickstarts/convo/buyanumber2.png" alt="Buy a Number 2" width="650px" align="middle" mdxType="Img" />
    <p>{`Numbers are paid for by a subscription. The minimum subscription is 3 months. When you buy a Number, the amount is deducted from your balance and you can choose the billing interval (3, 6 or 12 months).`}</p>
    <p>{`The subscription will be renewed automatically, deducting the amount for the coming months’ subscription from your balance.`}</p>
    <p>{`If you do not have enough balance in the account to renew your subscription, we will send you two top-up reminders; 1 month and 2 weeks before the renewal of the subscription. If you have an insufficient balance on the account to renew the subscription, the Number will be disconnected and you will be notified by email.`}</p>
    <p>{`For the Benelux: If your account is a credit based account, it is not possible to purchase a Number directly from your balance. An invoice will be generated in your account when the subscription is due to end and can be paid in the ‘`}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/financial/subscriptions"
      }}>{`Finances`}</a>{`’ section of your account. If the invoice is not paid within 30 days, the Number will be disconnected and you will be notified by email.`}</p>
    <p>{`You can end your subscription any time directly through the Dashboard in the `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/articles/208011689-Buy-a-Number-"
      }}>{`Financial section`}</a>{`.`}</p>
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'Numbers restrictions',
      to: '/quickstarts/numbers/numbers-restrictions/'
    }, {
      name: 'Numbers API Reference',
      to: '/api/numbers'
    }, {
      name: 'Official MessageBird SDKs',
      to: '/libraries'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      