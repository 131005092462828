import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import Questions from 'components/Footer/Questions';
import Notification from 'components/Notification';
import Link from 'components/Link';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Send WhatsApp interactive messages`}</h1>
    <p>{`Conversations API provides the ability to our customer to send WhatsApp interactive messages. Replies on those messages will use `}<Link to="/api/conversations/#replyto-object" mdxType="Link">{`ReplyTo`}</Link>{`. `}</p>
    <h2>{`Sending interactive message with quick reply`}</h2>
    <p>{`In order to send a WhatsApp interactive message, you must perform the following HTTP request to Conversations API.
The following example uses the endpoint `}<inlineCode parentName="p">{`POST /v1/send`}</inlineCode>{`, however, you can also use the other endpoints for sending messages,
please check the `}<a parentName="p" {...{
        "href": "/api/conversations"
      }}>{`Conversations API reference`}</a>{` for details about the request.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-curl"
      }}>{`curl -X "POST" "https://conversations.messagebird.com/v1/send" \\
     -H 'Authorization: AccessKey <access-key>' \\
     -H 'Content-Type: application/json; charset=utf-8' \\
     -d $'{
    "content": {
        "interactive": {
            "type": "button",
            "header": {
                "type": "image",
                "image": {
                    "url": "url"
                }
            },
            "body": {
                "text": "Do you like this t shirt?"
            },
            "action": {
                "buttons": [
                    {
                        "id": "yes-unique-id",
                        "type": "reply",
                        "title": "Yes"
                    },
                    {
                        "id": "no-unique-id",
                        "type": "reply",
                        "title": "No"
                    }
                ]
            },
            "footer": {
                "text": "We have this one in other colors."
            }
        }
    },
    "to": "+9999999999",
    "type": "interactive",
    "from": "0181d0bb76c54g9aae4309s822d35ba5"
}'
`}</code></pre>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`to`}</inlineCode>{` is the phone number of the recipient`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`type`}</inlineCode>{` must be `}<inlineCode parentName="li">{`interactive`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`from`}</inlineCode>{` must be your WhatsApp channel ID`}</li>
    </ul>
    <p>{`The HTTP response should be `}<inlineCode parentName="p">{`202 Accepted`}</inlineCode>{` and it should contain the message ID.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "id": "4a968dd619f54d9582440d15aaa791ec"
}
`}</code></pre>
    <h2>{`Receiving interactive message reply on quick reply`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`    {
      "id": "25f6585e4b4f42cbb43e8d1eafa8f999",
      "conversationId": "df59ede508c7404d8d495c1172d79888",
      "platform": "whatsapp",
      "to": "+9999999990",
      "from": "+9999999999",
      "channelId": "0181d0bb76c54g9aae4309s822d35ba5",
      "type": "interactive",
      "content": {
        "interactive": {
          "type": "button_reply",
          "reply": {
            "id": "yes-unique-id",
            "text": "Yes"
          }
        }
      },
      "replyTo": {
        "id": "4a968dd619f54d9582440d15aaa791ec"
      },
      "direction": "received",
      "status": "received",
      "createdDatetime": "2021-09-15T08:53:43Z",
      "updatedDatetime": "2021-09-15T08:53:45.220967583Z"
    }
`}</code></pre>
    <h2>{`Sending interactive message with list messages`}</h2>
    <p>{`In order to send a WhatsApp interactive message using, you must perform the following HTTP request to Conversations API.
The following example uses the endpoint `}<inlineCode parentName="p">{`POST /v1/send`}</inlineCode>{`, however, you can also use the other endpoints for sending messages,
please check the `}<a parentName="p" {...{
        "href": "/api/conversations"
      }}>{`Conversations API reference`}</a>{` for details about the request.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-curl"
      }}>{`curl -X "POST" "https://conversations.messagebird.com/v1/send" \\
     -H 'Authorization: AccessKey <your-access-key>' \\
     -H 'Content-Type: application/json; charset=utf-8' \\
     -d $'{
    "content": {
        "interactive": {
            "type": "list",
            "header": {
                "type": "text",
                "text": "What you would like to do buy today?"
            },
            "body": {
                "text": "To begin, tap Main Menu and choose from one of the following actions"
            },
            "action": {
                "button": "Main Menu",
                "sections": [
                    {
                        "title": "content-title-here",
                        "rows": [
                            {
                                "id": "unique-row-identifier-1",
                                "title": "Buy bundles"
                            }
                        ]
                    },
                    {
                        "title": "content-title-here",
                        "rows": [
                            {
                                "id": "unique-row-identifier-2",
                                "title": "Buy airtime",
                                "description": "optional description"
                            }
                        ]
                    }
                ]
            }
        }
    },
    "to": "+9999999999",
    "type": "interactive",
    "from": "0181d0bb76c54g9aae4309s822d35ba5"
}'
`}</code></pre>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`to`}</inlineCode>{` is the phone number of the recipient`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`type`}</inlineCode>{` must be `}<inlineCode parentName="li">{`interactive`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`from`}</inlineCode>{` must be your WhatsApp channel ID`}</li>
    </ul>
    <p>{`The HTTP response should be `}<inlineCode parentName="p">{`202 Accepted`}</inlineCode>{` and it should contain the message ID.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "id": "4a968dd619f54d9582440d15aaa791ec"
}
`}</code></pre>
    <h2>{`Receiving interactive message reply on list messages`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
      "id": "e8c1bb83072a463cb7415d1523eeb889",
      "conversationId": "df59ede508c7404d8d495c1172d79888",
      "platform": "whatsapp",
      "to": "+9999999990",
      "from": "+9999999999",
      "channelId": "0181d0bb76c54g9aae4309s822d35ba5",
      "type": "interactive",
      "content": {
        "interactive": {
          "type": "list_reply",
          "reply": {
            "id": "id2",
            "text": "Buy airtime",
            "description": "THIS IS DESCRIPTION"
          }
        }
      },
      "replyTo": {
        "id": "4a968dd619f54d9582440d15aaa791ec"
      },
      "direction": "received",
      "status": "received",
      "createdDatetime": "2021-09-15T08:56:14Z",
      "updatedDatetime": "2021-09-15T08:56:15.250899453Z"
    }
`}</code></pre>
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      