import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`HTTP Request in Flow Builder`}</h1>
    <h3>{`⏱ 4 min read`}</h3>
    <p>{`In this MessageBird Developer tutorial you’ll learn how to use the `}<inlineCode parentName="p">{`HTTP Request`}</inlineCode>{` step in Flow Builder to make HTTP requests to external APIs.`}</p>
    <h3>{`HTTP Request step`}</h3>
    <p>{`This step makes an HTTP request to a URL from Flow Builder, for example to send data. If you are interested in using the response body in your flow, you should use `}<a parentName="p" {...{
        "href": "https://developers.messagebird.com/tutorials/fetch-variables-in-flow-builder"
      }}>{`Fetch variables step`}</a>{` instead.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/flows/http-request-step.png",
        "alt": "HTTP request"
      }}></img></p>
    <h3>{`HTTP request step options`}</h3>
    <p><img parentName="p" {...{
        "src": "/img/screenshots/flows/http-request-options.png",
        "alt": "HTTP request step options"
      }}></img></p>
    <h4>{`Method`}</h4>
    <p>{`The HTTP request method used for the request.
Please read `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods"
      }}>{`HTTP request methods documentation`}</a>{` for more information. `}</p>
    <p>{`Expected value: one of the following: GET, POST, PUT, DELETE, PATCH, HEAD`}</p>
    <h3>{`URL`}</h3>
    <p>{`URL address to your website or some external HTTP endpoint`}</p>
    <p>{`Expected value: A hardcoded URL (starting with http:// or https://) or a variable. If a variable is passed, validation will happen during step execution.`}</p>
    <h4>{`OAuth Integration (Optional)`}</h4>
    <p>{`Flowbuilder supports OAuth2 authorization to be used at the HTTP Request step. Your OAuth2 integrations will be listed in this dropdown so that you can include them in the request.`}</p>
    <p>{`Expected value: Empty, or one of the integrations listed in the dropdown.`}</p>
    <h3>{`Retries`}</h3>
    <p>{`You can specify number of retries in case HTTP request fails.`}</p>
    <p>{`Expected value: A number from 0 to 10.`}</p>
    <h3>{`Body`}</h3>
    <p>{`You can define the body of your requests with this option. This option is used together with the “Set Content-Type header” field so that the receiver of your request will be able to decode it correctly.`}</p>
    <p>{`Expected value: this value is not validated. Please make sure to check that the body and content-type of the request is valid for the HTTP endpoint you are planning to fetch the variables from.
Please read `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Type"
      }}>{`HTTP Content-Type header documentation`}</a>{` for more information.  `}</p>
    <h3>{`Headers`}</h3>
    <p>{`This is a dictionary of key-value pairs. The key is header name and the value is the header value used for sending HTTP requests.
To know more about HTTP Headers and their purpose please read `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers"
      }}>{`HTTP headers documentation`}</a>{`.`}</p>
    <h3>{`Add variables`}</h3>
    <p>{`If enabled, all currently available variables will be added to the request body for POST or query string for GET requests`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      