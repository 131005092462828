import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Setting SMS notifications with MessageBird`}</h1>
    <h3>{`⏱ 15 min build time      ||      `}<a parentName="h3" {...{
        "href": "https://github.com/messagebirdguides/notifications-guide-java"
      }}>{`Download the Code`}</a></h3>
    <h2>{`Why build SMS order notifications?`}</h2>
    <p>{`In this MessageBird Developer Tutorial we'll show you how to keep your customers up to date with this easy-to-build runnable order notification application powered by the MessageBird SMS Messaging API. Have you ever ordered home delivery to find yourself wondering whether your order was received correctly and how long it will take to arrive? Some experiences are seamless and others... not so much.`}</p>
    <p>{`For on-demand industries such as food delivery, ride-sharing and logistics, excellent customer service during the ordering process is essential. One easy way to stand out from the crowd is providing proactive communication to keep your customers in the loop about the status of their orders. Regardless of whether your customer is waiting for a package delivery or growing hangry (a combination of hungry and angry) awaiting their food delivery, sending timely SMS order notifications is a great strategy to create a seamless user experience.`}</p>
    <p>{`The MessageBird SMS Messaging API provides an easy way to fully automate and integrate a notifications application into your order handling software. Busy employees can trigger the notifications application with the push of a single button. No more confused hangry customers and great user experience, just like that!`}</p>
    <h2>{`Getting Started`}</h2>
    <p>{`In this MessageBird Developer Guide, we'll show you how to build a runnable Order Notifications application in Java. The application is a prototype order management system deployed by our fictitious food delivery company, `}<em parentName="p">{`Birdie NomNom Foods`}</em>{`.`}</p>
    <p>{`Birdie NomNom Foods have set up the following workflow:`}</p>
    <ul>
      <li parentName="ul">{`New incoming orders are in a `}<em parentName="li">{`pending`}</em>{` state.`}</li>
      <li parentName="ul">{`Once the kitchen starts preparing an order, it moves to the `}<em parentName="li">{`confirmed`}</em>{` state. A message is sent to the customer to inform them about this.`}</li>
      <li parentName="ul">{`When the food is made and handed over to the delivery driver, staff marks the order `}<em parentName="li">{`delivered`}</em>{`. A message is sent to the customer to let them know it will arrive momentarily.`}</li>
      <li parentName="ul">{`If preparation takes longer than expected, it can be moved to a `}<em parentName="li">{`delayed`}</em>{` state. A message is sent to the customer asking them to hang on just a little while longer. Thanks to this, Birdie NomNom Foods saves time spent answering `}<em parentName="li">{`"Where's my order?`}</em>{`" calls.`}</li>
    </ul>
    <p>{`To run the application, you will need `}<a parentName="p" {...{
        "href": "https://www.oracle.com/technetwork/java/javase/downloads/jdk8-downloads-2133151.html"
      }}>{`Java 1.8`}</a>{` and `}<a parentName="p" {...{
        "href": "https://maven.apache.org/"
      }}>{`Maven`}</a>{`.`}</p>
    <p><strong parentName="p">{`Pro-tip:`}</strong>{` Follow this tutorial to build the whole application from scratch or, if you want to see it in action right away, you can download, clone or fork the sample application from the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/notifications-guide-java"
      }}>{`MessageBird Developer Guides GitHub repository`}</a>{`.`}</p>
    <h2>{`Configuring the MessageBird SDK`}</h2>
    <p>{`Now, let's open `}<inlineCode parentName="p">{`src/main/java/OrderNotifications.java`}</inlineCode>{`, the main file of the sample application. You'll find the following lines:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.messagebird.MessageBirdClient;
import com.messagebird.MessageBirdService;
import com.messagebird.MessageBirdServiceImpl;

import io.github.cdimascio.dotenv.Dotenv;

Dotenv dotenv = Dotenv.load();

// Create a MessageBirdService
final MessageBirdService messageBirdService = new MessageBirdServiceImpl(dotenv.get("MESSAGEBIRD_API_KEY"));

// Add the service to the client
final MessageBirdClient messageBirdClient = new MessageBirdClient(messageBirdService);
`}</code></pre>
    <p>{`Next, we'll replace the string `}<em parentName="p">{`YOUR_API_KEY`}</em>{` with a `}<em parentName="p">{`live`}</em>{` access key from your MessageBird account. You can create or retrieve a key from the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`API access (REST) tab`}</a>{` in the `}<em parentName="p">{`Developers`}</em>{` section. It's also possible to use a `}<em parentName="p">{`test`}</em>{` key to test the application. In this case, you can see the API output on the console, but no live SMS messages will be sent.`}</p>
    <p><strong parentName="p">{`Pro-tip:`}</strong>{` Hardcoding your credentials in the code is a risky practice that should never be used in production applications.  A better method, also recommended by the `}<a parentName="p" {...{
        "href": "https://12factor.net/"
      }}>{`Twelve-Factor App Definition`}</a>{`, is to use environment variables.`}</p>
    <p>{`We've added `}<a parentName="p" {...{
        "href": "https://mvnrepository.com/artifact/io.github.cdimascio/java-dotenv"
      }}>{`dotenv`}</a>{` to the sample application, so you can supply your API key in a file named `}<inlineCode parentName="p">{`.env`}</inlineCode>{`. You can copy the provided file `}<inlineCode parentName="p">{`env.example`}</inlineCode>{` to `}<inlineCode parentName="p">{`.env`}</inlineCode>{` and add your API key like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`MESSAGEBIRD_API_KEY=YOUR-API-KEY
`}</code></pre>
    <h2>{`Notifying Customer by Triggering an SMS`}</h2>
    <p>{`The sample application triggers SMS delivery in the `}<inlineCode parentName="p">{`/updateOrder`}</inlineCode>{` route together with updating the stored data.`}</p>
    <p>{`Sending a message with the MessageBird SDK is straightforward - we simply call the `}<inlineCode parentName="p">{`sendMessage`}</inlineCode>{` method with a few arguments:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`final MessageResponse response = messageBirdClient.sendMessage("NomNom", body, phones);
`}</code></pre>
    <p>{`The arguments are as follows:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`originator`}</inlineCode>{`: This is the first parameter. It represents a sender ID for the SMS, either a telephone number (including country code) or an alphanumeric string with at most 11 characters.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`body`}</inlineCode>{`: This is the second parameter. It's the content of the message.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`phones`}</inlineCode>{`: This is the third parameter. It's an array of one or more phone numbers to send the message to.`}</li>
    </ul>
    <h2>{`Testing the Application`}</h2>
    <p>{`The sample application works on a set of test data defined in a variable called `}<inlineCode parentName="p">{`orderDatabase`}</inlineCode>{`. To test the full flow, replace one of the phone numbers with your own to receive the message on your phone:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`// Set up Order "Database"
List<Map<String, Object>> orderDatabase = new ArrayList<Map<String, Object>>();

Map<String, Object> order = new HashMap<>();

order.put("name", "Hannah Hungry");
order.put("home", "+319876543210"); // <- put your number here for testing
order.put("items", "1 x Hipster Burger + Fries");
order.put("status", "pending");

orderDatabase.add(order);

order = new HashMap<>();
order.put("name", "Mike Madeater");
order.put("home", "+319876543211"); // <- put your number here for testing
order.put("items", "1 x Chef Special Mozzarella Pizza");
order.put("status", "pending");

orderDatabase.add(order);
`}</code></pre>
    <p>{`Now, build and run the application through your IDE.`}</p>
    <p>{`Then, point your browser at http://localhost:4567/ to see the list of orders.`}</p>
    <p>{`Click on one of the buttons in the `}<em parentName="p">{`Action`}</em>{` column to trigger a status change and, at the same time, automatically send a message. Tada!`}</p>
    <h2>{`Nice work!`}</h2>
    <p>{`You now have a running SMS Notifications application!`}</p>
    <p>{`You can now use the flow, code snippets and UI examples from this tutorial as an inspiration to build your own SMS Notifications system. Don't forget to download the code from the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/notifications-guide-java"
      }}>{`MessageBird Developer Guides GitHub repository`}</a>{`.`}</p>
    <h2>{`Next steps`}</h2>
    <p>{`Want to build something similar but not quite sure how to get started? Please feel free to let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`, we'd love to help!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      