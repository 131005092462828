import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Docs.tsx";
import Notification from 'components/Notification';
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Verify a Token`}</h1>
    <p>{`In this Verify API Quickstart, you'll learn how to verify your first token from your web application using the MessageBird Verify API.`}</p>
    <h2>{`Step 1: Send a verification token`}</h2>
    <p>{`Did you complete the `}<a parentName="p" {...{
        "href": "/docs/verify/send-a-verification-token-go"
      }}>{`Verify Quickstart: Send a Verification Token`}</a>{`?`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`No!`}</strong>{` It's a prerequisite, so make sure to do so before continuing.`}</li>
      <li parentName="ul"><strong parentName="li">{`Yes!`}</strong>{` Great, go to step 2.`}</li>
    </ul>
    <h2>{`Step 2: Verify your first token`}</h2>
    <p>{`Enter `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`your credentials`}</a>{` and mobile number - as both the `}<inlineCode parentName="p">{`originator`}</inlineCode>{` and `}<inlineCode parentName="p">{`recipient`}</inlineCode>{` - to the snippet below, copy it to your project and run from the command line.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`    v, err := verify.VerifyToken(
        client,
        "4e213b01155d1e35a9d9571v00162985",
        "123456",
    )
`}</code></pre>
    <Notification type="INFO" mdxType="Notification">
You can access and manage your credentials in the <a href="https://dashboard.messagebird.com/en/developers/access">MessageBird Developer Dashboard</a>.
    </Notification>
    <p>{`Good job! You've programmatically verified your first token using MessageBird’s Verify API!`}</p>
    <h2>{`Step 3: View the response`}</h2>
    <p>{`Now, let’s view MessageBird’s HTTP response to your API call. If the set up was correct, you'll receive a response similar to this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`    &verify.Verify{
       ID:"4e213b01155d1e35a9d9571v00162985",
       HRef:"https://rest.messagebird.com/verify/4e213b01155d1e35a9d9571v00162985",
       Reference:"",
       Status:"verified",
       Messages:map[string]string{"href":"https://rest.messagebird.com/messages/31bce2a1155d1f7c1db9df6b32167259"},
       CreatedDatetime:(*time.Time)(0xc00000c3a0),
       ValidUntilDatetime:(*time.Time)(0xc00000c3e0),
       Recipient:31612345678,
    }
`}</code></pre>
    <p>{`If something went wrong, you’ll receive an error response from the MessageBird API. Don't worry, simply head over to `}<a parentName="p" {...{
        "href": "/docs/verify/troubleshooting"
      }}>{`Verify API Troubleshooting`}</a>{` to understand the error.`}</p>
    <p>{`Congrats! You can now programmatically verify a token with MessageBird's Verify API using Go.`}</p>
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'Build SMS two-factor authentication',
      to: '/tutorials/verify-node'
    }, {
      name: 'Build Voice two-factor authentication',
      to: '/tutorials/account-security-voice-node'
    }, {
      name: 'Verify API Reference',
      to: '/api/verify'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      