import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/ApiRef.js";
import { Link } from 'gatsby';
import ProductContentPage from 'components/ProductContentPage';
import ContentBlock from 'components/ContentBlock';
import { Paragraph, H2 } from 'components/Typography';
import CodeSnippet from 'components/CodeSnippet';
import * as CODE from '../../constants/sms-batches';
import Notification from 'components/Notification';
import { APIWrapper, LeftColumn, RightColumn } from 'components/ApiRef';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`SMS Batch API`}</h1>
    <p>{`MessageBird’s SMS Batch API allows you to send and receive SMS messages to and from any country in the world through a REST API. Each message is identified by a unique random ID so that users can always check the status of a message using the given endpoint.`}</p>
    <p>{`The SMS Batch API uses `}<inlineCode parentName="p">{`HTTP verbs`}</inlineCode>{` and a `}<inlineCode parentName="p">{`RESTful`}</inlineCode>{` endpoint structure with an access key that is used as the API Authorization. Request and response payloads are formatted as JSON using UTF-8 encoding and URL encoded values.`}</p>
    <p>{`Compared to `}<a href="https://developers.messagebird.com/api/sms-messaging/" target="_blank_">{`SMS API`}</a>{` operating with a single message object, SMS Batch API allows to send different messages to multiple recipients within a single request.`}</p>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`API Endpoint`}</h2>
        <p>{`All URLs referenced in MessageBird's SMS API documentation have the base URL in the code snippet on the right side:`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>API Endpoint</h4>
  <CodeSnippet code={CODE.API_ENDPOINT} mdxType="CodeSnippet" />
  
      </RightColumn>
    </APIWrapper>
    <hr></hr>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <h2>{`Available HTTP methods`}</h2>
        <p>{`The MessageBird API uses HTTP verb to understand when you want to create ('POST') an array of message objects. When your web application cannot do a `}<inlineCode parentName="p">{`POST`}</inlineCode>{` request, we provide the ability to set the method through the query parameter `}<inlineCode parentName="p">{`_method`}</inlineCode>{`.`}</p>
        <p>{`The Authorisation and specific parameters are required for a request. Check with additional information `}<a href="https://developers.messagebird.com/api/sms-messaging/#available-http-methods" target="_blank_">{`here`}</a>{`.`}</p>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Available HTTP methods</h4>
  <CodeSnippet code={CODE.HTTP_METHODS} mdxType="CodeSnippet" />
  <h5>There are other endpoints available to manage created message objects. See <a href="https://developers.messagebird.com/api/sms-messaging/#available-http-methods" target="_blank_">SMS API documentation</a> for details.</h5>
      </RightColumn>
    </APIWrapper>
    <APIWrapper mdxType="APIWrapper">
      <LeftColumn mdxType="LeftColumn">
        <hr></hr>
        <h2>{`Send outbound SMS`}</h2>
        <p>{`MessageBird's Programmable SMS API enables you to programmatically send SMS messages from your web application.`}</p>
        <p>{`First, you need to create an array of new message objects.
MessageBird returns the created message objects with each request. A maximum of 50 recipients can be entered per message object.`}</p>
        <p>{`Every message object will be treated by API as an individual one, which allows to view and manage created messages separately. See `}<a href="https://developers.messagebird.com/api/sms-messaging/#available-http-methods" target="_blank_">{`SMS API documentation`}</a>{` for details.`}</p>
        <p>{`Send your first SMS messages with this example request.`}</p>
        <h5>{`Required parameters`}</h5>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`attribute`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`required`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`messages`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`array`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`An array of SMS messages object, the maximum size is 100 elements. See the structure and available parameters for a message object `}<a href="https://developers.messagebird.com/api/sms-messaging/#the-message-object" target="_blank_">{`here`}</a>{`.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Yes`}</td>
            </tr>
          </tbody>
        </table>
      </LeftColumn>
      <RightColumn mdxType="RightColumn">
  <h4>Definition</h4>
  <CodeSnippet code={CODE.SEND_DEF} mdxType="CodeSnippet" />
        <h4>Example Request</h4>
  <CodeSnippet code={CODE.SEND_EXAMPLE} mdxType="CodeSnippet" />
  <Notification type="WARNING" mdxType="Notification">
  Do note that values are not automatically urlencoded with the above example. Use --data-urlencode if you want to urlencode the values.
  </Notification>
        <h4>Example Response</h4>
  <CodeSnippet code={CODE.SEND_RESPONSE} mdxType="CodeSnippet" />
      </RightColumn>
    </APIWrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      