import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Send your first WhatsApp Message`}</h1>
    <p>{`In this WhatsApp Business API Quickstart, you'll learn how to send a new WhatsApp message to a WhatsApp user using Programmable Conversations.`}</p>
    <h2>{`Step 1: Send your first WhatsApp message`}</h2>
    <p>{`First, you need to collect your live API key from the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`MessageBird Developer Dashboard`}</a>{` and your Channel-ID from your `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/channels"
      }}>{`Channel Directory`}</a>{`.`}</p>
    <p>{`Let's now enter your credentials, WhatsApp Channel-ID, and mobile number to the code snippet below. Now, copy this snippet to your project, for example, `}<inlineCode parentName="p">{`send_whatsapp.js`}</inlineCode>{`. `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var messagebird = require('messagebird')('YOUR-API-KEY');

var params = {
  'to': '+31XXXXXXXXXX',
  'from': 'WHATSAPP-CHANNEL-ID',
  'type': 'text',
  'content': {
    'text': 'Hello!',
    'disableUrlPreview': false
  }
};

messagebird.conversations.send(params, function (err, response) {
  if (err) {
    return console.log(err);
  }
  console.log(response);
});
`}</code></pre>
    <p>{`Once your credentials are entered, go ahead and copy the above snippet to your project and run the application from the command line:`}</p>
    <p>{`Note: If you would like to disable URL preview (only available in text messages) you should set the value of `}<inlineCode parentName="p">{`disableUrlPreview`}</inlineCode>{` to `}<inlineCode parentName="p">{`true`}</inlineCode>{`. Default is `}<inlineCode parentName="p">{`false`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`node send_whatsapp.js
`}</code></pre>
    <h2>{`Step 2: View the response`}</h2>
    <p>{`Now, let's view the MessageBird’s HTTP response to your API call. If the set up was correct, you'll receive a response similar to this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json{"
      }}>{`  "id": "24500a370c86916fe8aef77e4c24b6a6",
  "status": "accepted",
  "fallback": null
}
`}</code></pre>
    <p>{`If something went wrong, you’ll receive an error response from the MessageBird API. Don't worry, simply head over to `}<a parentName="p" {...{
        "href": "/docs/conversations/whatsapp/troubleshooting-whatsapp"
      }}>{`Troubleshooting WhatsApp`}</a>{` to understand the error.`}</p>
    <p>{`Congrats! You can now send WhatsApp messages with Programmable Conversations using NodeJS.`}</p>
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'WhatsApp Message Templates',
      to: '/quickstarts/whatsapp/message-templates-curl'
    }, {
      name: 'WhatsApp guidelines for customer opt-ins',
      to: '/quickstarts/whatsapp/whatsapp-customer-optins'
    }, {
      name: 'WhatsApp troubleshooting',
      to: '/quickstarts/whatsapp/troubleshooting-whatsapp'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      