import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import Notification from 'components/Notification';
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
import { Img } from 'components/shared';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Messenger guidelines for customer opt-ins`}</h1>
    <h3>{`How to get customer’s opt-in for Messenger`}</h3>
    <p>{`The first step towards being able to message your customers via Facebook Messenger is getting them to opt-in. In this quickstart, you'll learn what exactly an opt-in is, the opt-in Facebook guidelines, and two possible ways to collect them.`}</p>
    <h3>{`The golden rule: active opt-in`}</h3>
    <p>{`An opt-in practice is when a user agrees to receive messages over Facebook Messenger from your business. Opt-in is required for both new and existing customers and must be collected before you message a user via Facebook Messenger.`}</p>
    <h3>{`Messenger checkbox plugin`}</h3>
    <p>{`One way to get opt-in is via the Messenger plugin. The messenger plugin allows you to display a checkbox for forms on your website that will enable users to opt-in to receive messages from your bot via Messenger. If the user is currently logged in to Facebook, their profile photo and name will be displayed next to the checkbox; if the user isn't logged in to Facebook or wants to log in as a different user, they can authenticate.`}</p>
    <p>{`The checkbox plugin can be used for just about any form-based flow on your website. For example, you could include the plugin because you wish to send receipts and order updates to your customers.`}</p>
    <p>{`Best practices for messenger plugin`}</p>
    <p>{`If you choose to go with a checkbox plugin, you must:`}</p>
    <ul>
      <li parentName="ul">{`Include the Facebook for Javascript SDK in the page where the plugin will be rendered. For instructions on including the SDK, check out the `}<a parentName="li" {...{
          "href": "https://developers.facebook.com/docs/javascript/quickstart/"
        }}>{`Facebook for Javascript SDK Quick Start`}</a>{`.`}</li>
      <li parentName="ul">{`Make sure your usage of the plugin complies with local laws, including those that require affirmative user action to opt-in to promotional or direct marketing messages.`}</li>
      <li parentName="ul">{`Follow `}<a parentName="li" {...{
          "href": "https://developers.facebook.com/docs/messenger-platform/discovery/checkbox-plugin/#best_practices"
        }}>{`Facebook guidelines`}</a>{` regarding usage and placement of the plugin.`}</li>
    </ul>
    <p>{`If you would like to build a checkbox plugin, check out the `}<a parentName="p" {...{
        "href": "https://developers.facebook.com/docs/messenger-platform/discovery/checkbox-plugin/"
      }}>{`step-by-step Messenger guide`}</a>{` which teaches you exactly how to do it.`}</p>
    <h3>{`Messenger bot`}</h3>
    <p>{`Another option to get opt-in is with a Messenger bot.`}</p>
    <p>{`Facebook authorizes your Messenger bot to access a person's profile information in the following events:`}</p>
    <ul>
      <li parentName="ul">{`The person starts the conversation via `}<a parentName="li" {...{
          "href": "https://developers.facebook.com/docs/messenger-platform/messenger-profile/get-started-button"
        }}>{`a welcome screen`}</a>{` and tapped the `}<inlineCode parentName="li">{`Get Started`}</inlineCode>{` button.`}</li>
      <li parentName="ul">{`The person starts the conversation by clicking a `}<inlineCode parentName="li">{`Send to Messenger`}</inlineCode>{` button.`}</li>
      <li parentName="ul">{`The person starts the conversation by sending a message.`}</li>
      <li parentName="ul">{`The person starts the conversation by accepting a page's message request.`}</li>
      <li parentName="ul">{`Your Messenger bot uses the `}<inlineCode parentName="li">{`[askPermission()`}</inlineCode>{` function](`}<a parentName="li" {...{
          "href": "https://developers.facebook.com/docs/messenger-platform/webview/permissions"
        }}>{`https://developers.facebook.com/docs/messenger-platform/webview/permissions`}</a>{`) of the Messenger Extensions SDK in the WebView to ask for the `}<inlineCode parentName="li">{`user_profile`}</inlineCode>{` permission.`}</li>
    </ul>
    <p>{`Some entry points allow you or people to initiate a conversation without giving your bot authorization to the person's public profile. In those cases, your Messenger bot will be granted permission after the user replied to the initial message. There are several situations where this may occur, such as:`}</p>
    <ul>
      <li parentName="ul">{`Conversations started via a `}<a parentName="li" {...{
          "href": "https://developers.facebook.com/docs/messenger-platform/plugin-reference"
        }}>{`web plugin`}</a></li>
      <li parentName="ul">{`Conversations started via `}<a parentName="li" {...{
          "href": "https://developers.facebook.com/docs/messenger-platform/guides/customer-matching"
        }}>{`customer matching`}</a></li>
      <li parentName="ul">{`Conversations started by opening shared content`}</li>
      <li parentName="ul">{`Interactions started by opening a `}<a parentName="li" {...{
          "href": "https://developers.facebook.com/docs/messenger-platform/guides/chat-extensions"
        }}>{`Chat Extension`}</a>{`.`}</li>
      <li parentName="ul">{`Interactions with `}<a parentName="li" {...{
          "href": "https://developers.facebook.com/docs/messenger-platform/guides/ads"
        }}>{`Messenger Conversation Ads`}</a>{` without a user reply`}</li>
    </ul>
    <p>{`If you would like to build a Messenger bot, check out the Messenger guide which teaches you `}<a parentName="p" {...{
        "href": "https://developers.facebook.com/products/messenger/tutorials/how-to-get-started-with-built-in-nlp"
      }}>{`how to design your bot's conversational flow`}</a>{`.`}</p>
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'Install Channels: Email',
      to: '/docs/conversations/install-channel-email'
    }, {
      name: 'Programmable Conversations API Reference',
      to: '/api/conversations'
    }, {
      name: 'Official MessageBird SDKs',
      to: '/libraries'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      