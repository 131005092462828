import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import Notification from 'components/Notification';
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Send an SMS`}</h1>
    <p>{`In this SMS API Quickstart, you'll learn how to send an SMS from your web application using the SMS API.`}</p>
    <h2>{`Step 1: Set up your test environment`}</h2>
    <p>{`To start testing with the official C# SDK for the SMS API, you need to first set up your test environment.`}</p>
    <p>{`The following command will install MessageBird:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`install via NuGet
`}</code></pre>
    <h2>{`Step 2: Send your first SMS`}</h2>
    <p>{`Next, enter `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`your credentials`}</a>{` and mobile number - as both the `}<inlineCode parentName="p">{`originator`}</inlineCode>{` and `}<inlineCode parentName="p">{`recipient`}</inlineCode>{` - to the snippet below and copy it to your project.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-csharp"
      }}>{`const string YourAccessKey = "YOUR-API-Key";
Client client = Client.CreateDefault(YourAccessKey);
const long Msisdn = +31XXXXXXXXX;

MessageBird.Objects.Message message =
client.SendMessage("MessageBird", "Hi! This is your first message", new[] { Msisdn });
`}</code></pre>
    <Notification type="INFO" mdxType="Notification">
You can access and manage your credentials in the <a href="https://dashboard.messagebird.com/en/developers/access">MessageBird Developer Dashboard</a>.
    </Notification>
    <p>{`Now, run the application from your IDE.`}</p>
    <p>{`Good job! You’ve sent your first SMS with the MessageBird SMS API!`}</p>
    <h2>{`Step 3: View the response`}</h2>
    <p>{`Now, let’s view MessageBird’s HTTP response to your API call. If the set up was correct, you'll receive a response similar to this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "id": "e60a6f90453a19019c56ed6b20170831",
  "href": "https://rest.messagebird.com/messages/e60a6f90453a19019c56ed6b20170831",
  "direction": "mt",
  "type": "sms",
  "originator": "MessageBird",
  "body": "Hi! This is your first message",
  "reference": null,
  "validity": null,
  "gateway": 240,
  "typeDetails": {},
  "datacoding": "plain",
  "mclass": 1,
  "scheduledDatetime": null,
  "createdDatetime": "2016-05-03T14:26:57+00:00",
  "recipients": {
    "totalCount": 1,
    "totalSentCount": 1,
    "totalDeliveredCount": 0,
    "totalDeliveryFailedCount": 0,
    "items": [
      {
        "recipient": 31XXXXXXXXX,
        "status": "sent",
        "statusDatetime": "2016-05-03T14:26:57+00:00",
      },
    ]
  }
}
`}</code></pre>
    <p>{`If something went wrong, you’ll receive an error response from the MessageBird API. Don't worry, simply head over to the `}<a parentName="p" {...{
        "href": "/docs/sms-messaging/troubleshooting"
      }}>{`SMS API Troubleshooting`}</a>{` to understand the error.`}</p>
    <p>{`Congrats! You can now programmatically send SMS with the MessageBird SMS API using C#.`}</p>
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'Receive an SMS',
      to: '/quickstarts/sms/receive-sms'
    }, {
      name: 'SMS Tutorials',
      to: '/tutorials?filter=sms'
    }, {
      name: 'SMS API Reference',
      to: '/api/sms-messaging'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      