import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Docs.tsx";
import { Img } from 'components/shared';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Voice Calling API Troubleshooting`}</h1>
    <p>{`MessageBird offers multiple tools to enable you to quickly understand any unexpected behavior between the MessageBird Voice Calling API and your application.`}</p>
    <h2>{`Explore the call logs in the Dashboard`}</h2>
    <p>{`If you run into issues with your voice calls during their execution, the best place to start troubleshooting is the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/voice-calls/logs"
      }}>{`Voice Call Logs in the MessageBird Dashboard.`}</a>{`.`}</p>
    <Img src="/img/screenshots/quickstarts/call-logs.png" alt="Call Logs" width="650px" align="middle" mdxType="Img" />
    <p>{`There you can find an overview of issues detected with your voice calls that could not directly be returned by the API as the calls were already in progress.`}</p>
    <p>{`You can also head to the Voice Calls section to view all voice calls for the last 30 days alongside their statuses.`}</p>
    <h2>{`Error codes`}</h2>
    <p>{`MessageBird uses standard HTTP status codes to indicate success or failure of an API request. Codes in the 2xx range indicate that a request was successfully processed and codes in the 4xx range indicate that there was an error that resulted from the information sent (e.g. authentication, no balance or a missing or wrong parameter).`}</p>
    <p>{`In case of an error, the body of the response includes a `}<inlineCode parentName="p">{`json`}</inlineCode>{` formatted response that tells you exactly what's wrong. See the reference of `}<a parentName="p" {...{
        "href": "/api/voice-calling#errors"
      }}>{`Errors`}</a>{` for more details.`}</p>
    <h3>{`HTTP status codes`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`HTTP status code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`200 Found`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The requested resource was found.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`201 Created`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A resource has been created.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`204 No Content`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The requested resource is empty.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`400 Bad Request`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The request contains invalid/missing data, or is malformed.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`401 Unauthorized`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The access key is missing or incorrect.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`403 Forbidden`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Access to the requested resource was forbidden.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`404 Not Found`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The resource could not be found.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`405 Method Not Allowed`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The HTTP method is not allowed.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`500 Internal Server Error`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Something went wrong on our end, please try again.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Contact technical support`}</h2>
    <p>{`If you’re still having problems troubleshooting the Voice Calling API, please don’t hesitate to contact us at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we're here to help you out.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      