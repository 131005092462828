import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Setting SMS server alerts with MessageBird`}</h1>
    <h3>{`⏱ 30 min build time      ||      `}<a parentName="h3" {...{
        "href": "https://github.com/messagebirdguides/sms-server-alerts-guide-go"
      }}>{`Download the Code`}</a></h3>
    <h2>{`Why build SMS server alerts?`}</h2>
    <p>{`In this MessageBird Developer Tutorial, you’ll learn how to quickly report error logs of your server via SMS server alerts with the MessageBird API to ensure a faster response time.`}</p>
    <p>{`For any online service advertising guaranteed uptime north of 99%, being available and reliable is extremely important; therefore, it is essential that any errors in the system are fixed as soon as possible, and the prerequisite for that is that error reports are delivered quickly to the engineers on duty. Providing those error logs via SMS ensures a faster response time compared to email reports and helps companies keep their uptime promises.`}</p>
    <p>{`We’ll show you how to build an integration of SMS alerts into a Go application that uses the
`}<a parentName="p" {...{
        "href": "https://www.github.com/sirupsen/logrus"
      }}>{`Logrus`}</a>{` logging library.`}</p>
    <h2>{`Logging with Go`}</h2>
    <p>{`Logging with Go comes with a few "gotchas". Typically, we send logs to the
terminal from our Go application using the `}<inlineCode parentName="p">{`log`}</inlineCode>{` standard library. But
`}<inlineCode parentName="p">{`log`}</inlineCode>{` has a few limitations:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`log`}</inlineCode>{`, by default, timestamps your output and sends it to STDERR. To
change this, we have add the following line of code:`}</p>
        <pre parentName="li"><code parentName="pre" {...{}}>{`\`\`\`go
log.SetOutput(os.Stdout)

// You can also set your log output to a file:
// file, err := os.OpenFile("tmp.log", os.O_APPEND|os.O_CREATE|os.O_WRONLY, 0644)
// if err != nil {
//   log.Println(err)
// }
// defer file.Close()
// log.SetOutput(file)
\`\`\`
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`log`}</inlineCode>{` doesn't have built in log levels. Log levels allow us to specify a severity of a log message. For example, an `}<strong parentName="p">{`INFO`}</strong>{` log level would be for informational logs, as opposed to an `}<strong parentName="p">{`ERROR`}</strong>{` log level that would indicate an application error has occurred.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`log`}</inlineCode>{` only lets us designate one output destination for each instance of a logger (`}<inlineCode parentName="p">{`*log.Logger`}</inlineCode>{`). This means that to send a log event to two or more different outputs at a time, you would have to do something like this:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-go"
          }}>{`LogThis := log.New(os.Stdout, "INFO: ", log.Ldate|log.Ltime|log.Lshortfile)
LogThisToError := log.New(os.Stderr, "ERROR: ", log.Ldate|log.Ltime|log.LShortfile)
file,_ := os.OpenFile("logfile.log", os.O_APPEND|os.O_CREATE|os.O_WRONLY, 0644)
LogThisToFile := log.New(file, "ERROR: ", log.Ldate|log.Ltime|log.LShortfile)

LogThis("I have to log the same output many times.")
LogThisToError("I have to log the same output many times.")
LogThisToFile("I have to log the same output many times.")
`}</code></pre>
      </li>
    </ul>
    <p>{`While this is an entirely feasible way to set up logging in Go, we prefer
a simpler solution: using the `}<a parentName="p" {...{
        "href": "https://github.com/sirupsen/logrus"
      }}>{`Logrus`}</a>{`
logging package. 🤓`}</p>
    <h3>{`Introducing Logrus`}</h3>
    <p><a parentName="p" {...{
        "href": "https://github.com/sirupsen/logrus"
      }}>{`Logrus`}</a>{` is a Go logging package that:`}</p>
    <ul>
      <li parentName="ul">{`Has built in support for log levels. Instead of creating a new `}<inlineCode parentName="li">{`log`}</inlineCode>{`
instance to manage each log level, levels are instead methods in `}<inlineCode parentName="li">{`logrus`}</inlineCode>{`.
For example, to log an Error we write `}<inlineCode parentName="li">{`logrus.Error(err)`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`The default logger can be extended with "hooks". Hooks allow us to
attach code to log events, which can be useful when we need extra logging
features like sending an SMS notification on certain log events.`}</li>
      <li parentName="ul">{`Is compatible with the standard library logger. Everything that you can do with the standard library logger, you can do with Logrus; this means that you can safely rewrite `}<inlineCode parentName="li">{`log`}</inlineCode>{` with the Logrus logger with this import statement:`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`import log "github.com/sirupsen/logrus"

func main(){
    log.Println("This line of code will run with the Logrus logger or the standard library logger.")
}
`}</code></pre>
    <p>{`Logrus also has a library of hooks that you can use integrate your Go application logging with a variety of other services at `}<a parentName="p" {...{
        "href": "https://github.com/sirupsen/logrus/wiki/Hooks"
      }}>{`https://github.com/sirupsen/logrus/wiki/Hooks`}</a>{`.`}</p>
    <h3>{`Our application logging requirements`}</h3>
    <p>{`Our sample application is a web server that monitors its own health.
If it detects that a route produces a "server error" HTTP response status code
class (`}<inlineCode parentName="p">{`5xx`}</inlineCode>{` status codes), our application:`}</p>
    <ul>
      <li parentName="ul">{`Displays the error in the terminal,`}</li>
      <li parentName="ul">{`Logs the error to a file,`}</li>
      <li parentName="ul">{`And sends an SMS notification to a designated recipient.`}</li>
    </ul>
    <p>{`At the same time, we also want to continue sending informational logs to the terminal; yet, we don't want to write these logs to the log file, or trigger our application to send an SMS notification.`}</p>
    <p>{`So, we want to customize our logger to be able to:`}</p>
    <ul>
      <li parentName="ul">{`Must at least differentiate between error-level logs and informational logs (info-level).`}</li>
      <li parentName="ul">{`Send logs to specific outputs according to their log levels.`}</li>
    </ul>
    <p>{`To fulfill the above requirements, we'll be using the Logrus library and writing a custom hook that will send SMS notifications to a specified recipient only when our HTTP server encounters a category 5xx HTTP status code.`}</p>
    <h2>{`Getting started`}</h2>
    <p>{`First things first, our sample application is build in Go, so you need to install `}<a parentName="p" {...{
        "href": "https://golang.org"
      }}>{`Go`}</a>{`, `}<a parentName="p" {...{
        "href": "https://github.com/sirupsen/logrus"
      }}>{`Logrus`}</a>{` 1.0.6 and newer, and the `}<a parentName="p" {...{
        "href": "https://github.com/messagebird/go-rest-api"
      }}>{`MessageBird REST API package for Go`}</a>{`.	`}</p>
    <p>{`Now, let's install Logrus and the MessageBird Go SDK with the `}<inlineCode parentName="p">{`go get`}</inlineCode>{` command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`go get -u -v github.com/sirupsen/logrus
go get -u -v github.com/messagebird/go-rest-api
`}</code></pre>
    <p>{`The source code is available in the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/lead-alerts-guide-go"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`, from which it can be cloned or downloaded into your development environment.`}</p>
    <h3>{`Create your API Key 🔑`}</h3>
    <p>{`To start making API calls, we need to generate an access key. MessageBird provides keys in `}<em parentName="p">{`live`}</em>{` and `}<em parentName="p">{`test`}</em>{` modes. For this tutorial you’ll need to use a live key; otherwise, you won’t be able to test the complete flow. You can read more about `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/articles/360000670709-What-is-the-difference-between-a-live-key-and-a-test-key-"
      }}>{`the difference between test and live API keys`}</a>{` in our Help Center.`}</p>
    <p>{`Let's create your live API access key. First, go to the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/user/index"
      }}>{`MessageBird Dashboard`}</a>{`; if you have already created an API key it will be shown right there. If you don’t see any key on the Dashboard or if you're unsure whether this key is in `}<em parentName="p">{`live`}</em>{` mode, go to the `}<em parentName="p">{`Developers`}</em>{` section in the MessageBird Dashboard and open the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`API access (REST) tab`}</a>{`. There you can create new API keys and manage your existing ones.`}</p>
    <p>{`If you are having any issues creating your API key, please reach out to `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we’ll make sure to help you out.`}</p>
    <p><strong parentName="p">{`Pro-tip:`}</strong>{` To keep our demonstration code simple, we’ll be saving our API key in `}<inlineCode parentName="p">{`main.go`}</inlineCode>{`. However, hardcoding your credentials in the code is a risky practice that should never be used in production applications. A better method, also recommended by the `}<a parentName="p" {...{
        "href": "https://12factor.net/"
      }}>{`Twelve-Factor App Definition`}</a>{`, is to use environment variables. You can use open source packages such as `}<a parentName="p" {...{
        "href": "https://github.com/joho/godotenv"
      }}>{`GoDotEnv`}</a>{` to read your API key from a `}<inlineCode parentName="p">{`.env`}</inlineCode>{` file into your Go application. Your `}<inlineCode parentName="p">{`.env`}</inlineCode>{` file should be written as follows:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`MESSAGEBIRD_API_KEY=YOUR-API-KEY
`}</code></pre>
    <p>{`To use `}<a parentName="p" {...{
        "href": "https://github.com/joho/godotenv"
      }}>{`GoDotEnv`}</a>{` in your application, let's type the following command to install it:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`go get -u github.com/joho/godotenv
`}</code></pre>
    <p>{`Then, let's import it in your application:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`import (
 // Other imported packages
 "os"

 "github.com/joho/godotenv"
)

func main(){
 // GoDotEnv loads any ".env" file located in the same directory as main.go
 err := godotenv.Load()
 if err != nil {
   log.Fatal("Error loading .env file")
 }

 // Store the value for the key "MESSAGEBIRD_API_KEY" in the loaded '.env' file.
 apikey := os.Getenv("MESSAGEBIRD_API_KEY")

 // The rest of your application ...
}
`}</code></pre>
    <h3>{`Initialize the MessageBird client`}</h3>
    <p>{`Let's now install the `}<a parentName="p" {...{
        "href": "https://github.com/messagebird/go-rest-api"
      }}>{`MessageBird's REST API package for Go`}</a>{` by running:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`go get -u -v github.com/messagebird/go-rest-api
`}</code></pre>
    <p>{`In your project folder which we created earlier, let's create a `}<inlineCode parentName="p">{`main.go`}</inlineCode>{` file, and write the following code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`package main

import (
 "os"

 "github.com/messagebird/go-rest-api"
)

func main(){
 client := messagebird.New("<enter-your-api-key>")
}
`}</code></pre>
    <h2>{`Structuring our application`}</h2>
    <p>{`Now that we've initialized the MessageBird client, we can build our web server. `}</p>
    <p>{`Our web server:`}</p>
    <ul>
      <li parentName="ul">{`Doesn't need complex routing. We're just using it to demonstrate how to trigger an SMS notification on a server error, so we just need it have any rendered views display the HTTP status code the server responded with.`}</li>
      <li parentName="ul">{`Needs to encounter an error on demand. To do this, we'll build a route that takes a HTTP status code as part of the URL path, and renders a page for that status code and writes the appropriate HTTP response headers.`}</li>
    </ul>
    <p>{`Once our web server code is written, we need to configure our logger by:`}</p>
    <ul>
      <li parentName="ul">{`Overwriting the standard library logger `}<inlineCode parentName="li">{`log`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`Writing a Logrus hook that allows us to select an output and which log events will be written to that output.`}</li>
      <li parentName="ul">{`Writing a custom MessageBird `}<inlineCode parentName="li">{`type`}</inlineCode>{` that we can pass to our Logrus hook as an output destination.`}</li>
    </ul>
    <h3>{`Building our web server`}</h3>
    <p>{`First, let's build a simple web server with a default route and a route that simulates a HTTP status code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`package main

import (
    "fmt"
    "net/http"

    log "github.com/sirupsen/logrus"
)

func defaultPath(w http.ResponseWriter, r *http.Request){
    fmt.Fprintln(w, "Hello. "+
   "Please enter a valid status code in the path to simulate a HTTP server status. "+
   "E.g. www.example.com/simulate/404")
}

func simulateHTTPStatus(w http.ResponseWriter, r *http.Request){
    fmt.Fprintln(w, "Hello. "+
   "Please enter a valid status code in the path to simulate a HTTP server status. "+
   "E.g. www.example.com/simulate/404")
}

func main(){
 client := messagebird.New("<enter-your-api-key>")

    http.HandleFunc("/",defaultPath)
    http.HandleFunc("/simulate/",simulateHTTPStatus)

    err := http.ListenAndServe(":8080",nil)
    if err != nil {
        log.Errorln(err)
    }
}
`}</code></pre>
    <p>{`Notice that we're already using the Logrus logger in place of the standard library logger `}<inlineCode parentName="p">{`log`}</inlineCode>{`. The default Logrus logger works well out-of-the-box, and allows us to add customizations progressively; thus, it makes sense to start using it early so we have our logging infrastructure in place while as we build our application.`}</p>
    <p>{`We're also stubbing out two routes and their respective handlers: `}<inlineCode parentName="p">{`simulateHTTPStatus`}</inlineCode>{` should simulate a HTTP status code on the `}<inlineCode parentName="p">{`/simulate/`}</inlineCode>{` URL path, and `}<inlineCode parentName="p">{`defaultPath`}</inlineCode>{` handles all other routes on our web server.`}</p>
    <p>{`Next, we need to get our `}<inlineCode parentName="p">{`simulateHTTPStatus`}</inlineCode>{` handler to read from the URL path and figure out which HTTP status code to simulate. Modify the `}<inlineCode parentName="p">{`simulateHTTPStatus()`}</inlineCode>{` function to look like the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`func simulateHTTPStatus(w http.ResponseWriter, r *http.Request){
    path := strings.Split(r.URL.Path, "/")
    simulateCode, err := strconv.Atoi(path[2])
    if err != nil {
            log.Error(err)
        } else if len(path[2]) != 3 {
            output := fmt.Sprintf("Unknown status code used in path: %s", path[2])
            log.Warningln(output)
            fmt.Fprintln(w, output)
            fmt.Fprintln(w, "Hello. "+
                "Please enter a valid status code in the path to simulate a HTTP server status. "+
                "E.g. www.example.com/simulate/404")
            return
        }
}
`}</code></pre>
    <p>{`Here, we're setting up a route where entering `}<inlineCode parentName="p">{`www.example.com/simulate/500`}</inlineCode>{` would tell our server to simulate a 500 HTTP status code. To do this, we get the URL path with `}<inlineCode parentName="p">{`r.URL.Path`}</inlineCode>{` and split it into sections delimited by `}<inlineCode parentName="p">{`/`}</inlineCode>{`. Because we expect a fixed URL path format of `}<inlineCode parentName="p">{`www.example.com/simulate/<StatusCode>`}</inlineCode>{`, we can safely set our `}<inlineCode parentName="p">{`simulateCode`}</inlineCode>{` variable to `}<inlineCode parentName="p">{`path[2]`}</inlineCode>{`.`}</p>
    <p>{`If the user enters an unexpected URL path, we handle it either in `}<inlineCode parentName="p">{`simulateHTTPStatus`}</inlineCode>{` itself or fall back to the `}<inlineCode parentName="p">{`defaultPath`}</inlineCode>{` handler. Both render a page that tells the user to enter a path like "`}<a parentName="p" {...{
        "href": "http://www.exampe.com/simulate/404%22"
      }}>{`www.exampe.com/simulate/404"`}</a>{`.`}</p>
    <p>{`Once we're sure that we're getting a URL path that contains a HTTP status code that we can simulate, we can write the logic to handle it. Add the following code to the bottom of `}<inlineCode parentName="p">{`simulateHTTPStatus()`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`func simulateHTTPStatus(w http.ResponseWriter, r *http.Request){
    // ... previous code
    w.WriteHeader(simulateCode)

    // Handle all possible Server Error class of HTTP status codes
    if simulateCode >= 500 && simulateCode < 600 {
        output := fmt.Sprintf("Server error. [%s %s] %d %s", r.Method, r.URL.Path, simulateCode, http.StatusText(simulateCode))
        log.Errorln(output)
        fmt.Fprintln(w, output)
    } else {
        output := fmt.Sprintf("Everything's ok on our end.[%s %s] %d %s", r.Method, r.URL.Path, simulateCode, http.StatusText(simulateCode))
        log.Infoln(output)
        fmt.Fprintln(w, output)
    }
    return
}
`}</code></pre>
    <p>{`Above, we're writing our status code `}<inlineCode parentName="p">{`simulateCode`}</inlineCode>{` to our HTTP response header, and then checking if it is a category 5xx status code. If it's a category 5xx status code, we log an error. If it's not a category 5xx status code, then we log it as an informational log event.`}</p>
    <p>{`Now we've got all our web server logic set up, we can move on to writing our custom Logrus hooks to handle error logging according to our requirements.`}</p>
    <h3>{`Building our custom Logrus hook`}</h3>
    <p>{`To get to a point where our logger can send SMS notifications when an error is logged, we need to first write a custom hook that writes to a given output for the log levels we specify.`}</p>
    <p>{`In this Tutorial we won't write a dedicated hook like those found in the Logrus `}<a parentName="p" {...{
        "href": "https://github.com/sirupsen/logrus/wiki/Hooks"
      }}>{`list of hooks`}</a>{`; instead, we'll
write a generic hook that allows us to peek at how Logrus hooks work, and allows
us to quickly change logging behaviour from within our application itself.`}</p>
    <p>{`Add the following code under your `}<inlineCode parentName="p">{`main()`}</inlineCode>{` block:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`// WriterHook hooks into log events.
type WriterHook struct {
 Writer    io.Writer
 LogLevels []log.Level
}

// Fire tells WriterHook what to do when an event is logged.
func (hook *WriterHook) Fire(entry *log.Entry) error {
 line, err := entry.String()
 if err != nil {
   return err
 }
 _, err = hook.Writer.Write([]byte(line))
    if err != nil {
        return err
    }
 return nil
}

// Levels rewrites the Levels method to only include the
// log.Level specified in the WriterHook struct.
func (hook *WriterHook) Levels() []log.Level {
 return hook.LogLevels
}
`}</code></pre>
    <p>{`Here, we've:`}</p>
    <ul>
      <li parentName="ul">{`Added a new struct type named `}<inlineCode parentName="li">{`WriterHook`}</inlineCode>{`. `}<inlineCode parentName="li">{`WriterHook`}</inlineCode>{` takes two parameters, an `}<inlineCode parentName="li">{`io.Writer`}</inlineCode>{` and a list of log levels, that we'll use when writing our `}<inlineCode parentName="li">{`Fire()`}</inlineCode>{` implementation.`}</li>
      <li parentName="ul">{`Implemented a `}<inlineCode parentName="li">{`Fire()`}</inlineCode>{` method for `}<inlineCode parentName="li">{`WriterHook`}</inlineCode>{`. When a log event occurs,
Logrus calls `}<inlineCode parentName="li">{`Fire()`}</inlineCode>{` on all hooks attached to a logger
and passes the contents of that log event (`}<inlineCode parentName="li">{`entry *log.Entry`}</inlineCode>{`) into it.
In our `}<inlineCode parentName="li">{`Fire()`}</inlineCode>{` implementation, we parse the contents of the log event `}<inlineCode parentName="li">{`entry`}</inlineCode>{`,
and then send it to the `}<inlineCode parentName="li">{`io.Writer`}</inlineCode>{` we've attached to our hook.`}</li>
      <li parentName="ul">{`Implemented a `}<inlineCode parentName="li">{`Levels()`}</inlineCode>{` method for `}<inlineCode parentName="li">{`WriterHook`}</inlineCode>{` that just returns the list of
levels we specify when initializing the `}<inlineCode parentName="li">{`WriterHook`}</inlineCode>{` struct. Logrus reads a list of levels from a hook's `}<inlineCode parentName="li">{`Levels()`}</inlineCode>{` method,
and only triggers that hook for the levels contained within this list.`}</li>
    </ul>
    <p>{`Don't try to log messages within hooks because if you're not using a separate logger instance, your application will be sent into an infinite loop; instead, make sure that you handle all errors by returning them for either the Logrus library or the main application to handle.`}</p>
    <p>{`Once we've done this, we can add the hooks to our logger.`}</p>
    <p>{`First, let's declare a few lists of log levels to help us keep our hook definitions brief:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`var (
 logLevelsInfo = []log.Level{
   log.InfoLevel,
   log.WarnLevel,
 }
 logLevelsSevere = []log.Level{
   log.ErrorLevel,
   log.PanicLevel,
   log.FatalLevel,
 }
 logLevelsAll = []log.Level{
   log.InfoLevel,
   log.WarnLevel,
   log.ErrorLevel,
   log.PanicLevel,
   log.FatalLevel,
 }
)
`}</code></pre>
    <p>{`Then, modify `}<inlineCode parentName="p">{`main()`}</inlineCode>{` to look like the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`func main(){
    client := // ...

    log.SetOutput(ioutil.Discard)
    log.SetFormatter(&log.JSONFormatter{})

    logfile, err := os.OpenFile("mbservermon.log", os.O_APPEND|os.O_CREATE|os.O_WRONLY, 0644)
    if err != nil {
        log.Error(err)
    }
    defer func() {
        logfile.Sync()
        logfile.Close()
    }()

    log.AddHook(&WriterHook{
        Writer:    os.Stdout,
        LogLevels: logLevelsAll,
    })
    log.AddHook(&WriterHook{
        Writer:    logfile,
        LogLevels: logLevelsAll,
    })

    // ...
}
`}</code></pre>
    <p>{`In the code snippet above, we:`}</p>
    <ul>
      <li parentName="ul">{`Define three lists of log levels, which we will use to write our hooks.`}</li>
      <li parentName="ul">{`Discard default logging output for our logger `}<inlineCode parentName="li">{`log`}</inlineCode>{`,
as we're delegating all log output to the hooks we'll attach to the it.`}</li>
      <li parentName="ul">{`Set the logger's output format to JSON.`}</li>
    </ul>
    <p>{`This gives us log output that looks like:`}</p>
    <pre><code parentName="pre" {...{}}>{`\`\`\`JSON
{"level":"info","msg":"Serving on:1313","time":"2018-09-20T13:11:01+08:00"}
\`\`\`
You can also set the log output format to \`&log.TextFormatter{}\`, which gives us
log output that looks like:

\`\`\`
time="2018-09-21T01:01:01+08:00" level=info msg="Serving on:1313"
\`\`\`
`}</code></pre>
    <ul>
      <li parentName="ul">{`Initialize our log file as `}<inlineCode parentName="li">{`logfile`}</inlineCode>{`, which we will write logs to.`}</li>
      <li parentName="ul">{`Once we've done all of the above, we can write two hooks. The first hook tells
our logger to send log events from all levels to the terminal as STDOUT; the second hook tells our logger to write log events from all levels to `}<inlineCode parentName="li">{`logfile`}</inlineCode>{`.`}</li>
    </ul>
    <h3>{`Writing a custom `}<inlineCode parentName="h3">{`io.Writer`}</inlineCode>{` that sends SMS notifications`}</h3>
    <p>{`Now that we've got our basic logging functionality set up,
we can start writing code to send SMS notifications when our web server
encounters an error.`}</p>
    <p>{`First, we need to set our code up so that we can write to the MessageBird
REST API like it's a file. To do this, we'll write a `}<inlineCode parentName="p">{`MBContainer`}</inlineCode>{` struct type
that we attach a `}<inlineCode parentName="p">{`Write()`}</inlineCode>{` method to so that it qualifies as an `}<inlineCode parentName="p">{`io.Writer`}</inlineCode>{`.`}</p>
    <p>{`We've also added `}<em parentName="p">{`Client`}</em>{`, `}<em parentName="p">{`Originator`}</em>{`, and `}<em parentName="p">{`Recipient`}</em>{` parameters to the `}<inlineCode parentName="p">{`MBContainer`}</inlineCode>{` struct so that we can configure these from `}<inlineCode parentName="p">{`main()`}</inlineCode>{` when writing our hook.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`type MBContainer struct {
    Client     *messagebird.Client
    Originator string
    Recipients []string
}

func (mb *MBContainer) Write(p []byte) (int, error) {
    msgBody := string(p[:])
    if len(msgBody) > 160 {
        msgBody = msgBody[:159]
    }
    msg, err := sms.Create(
        mb.Client,
        mb.Originator,
        mb.Recipients,
        msgBody,
        nil,
    )
    if err != nil {
        return 1, err
    }
    fmt.Println("Message sent: %v", msg)
    return 0, nil
}
`}</code></pre>
    <p>{`In this `}<inlineCode parentName="p">{`Write()`}</inlineCode>{` method:`}</p>
    <ul>
      <li parentName="ul">{`We take the data written to `}<inlineCode parentName="li">{`Write()`}</inlineCode>{` and turn it into a `}<inlineCode parentName="li">{`msgBody`}</inlineCode>{` string`}</li>
      <li parentName="ul">{`Then, we check that `}<inlineCode parentName="li">{`msgBody`}</inlineCode>{` is not more than 160 characters, or MessageBird will split the log message into two SMS notifications.`}</li>
      <li parentName="ul">{`We then call `}<inlineCode parentName="li">{`sms.Create()`}</inlineCode>{` which tells the MessageBird REST API
to send it as an SMS notification.`}</li>
    </ul>
    <p>{`Once this is done, we can add a new hook to `}<inlineCode parentName="p">{`main()`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`func main(){
    // ...
    log.AddHook(&WriterHook{
        Writer:    os.Stdout,
        LogLevels: logLevelsAll,
    })
    log.AddHook(&WriterHook{
        Writer:    logfile,
        LogLevels: logLevelsAll,
    })

    // MessageBird hook
    log.AddHook(&WriterHook{
        Writer:    &MBContainer{client, "MBServerMon", []string{"<recipient_number_here>"}},
        LogLevels: logLevelsSevere,
    })

    // ...
}
`}</code></pre>
    <p>{`Here, we've written a new hook that takes an anonymous struct `}<inlineCode parentName="p">{`&MBContainer{}`}</inlineCode>{`
as the output to write to, and logs only "severe" log events to this output.
We've earlier defined "severe" log events to be of log levels `}<inlineCode parentName="p">{`log.ErrorLevel`}</inlineCode>{`,
`}<inlineCode parentName="p">{`log.PanicLevel`}</inlineCode>{`, and `}<inlineCode parentName="p">{`log.FatalLevel`}</inlineCode>{`.`}</p>
    <p>{`In `}<inlineCode parentName="p">{`&MBContainer{}`}</inlineCode>{`, we pass in our MessageBird `}<inlineCode parentName="p">{`client`}</inlineCode>{`, an "originator" `}<inlineCode parentName="p">{`MBServerMon`}</inlineCode>{` (which has to be a string with a maximum of 11 characters), and a list of recipients for the log messages.`}</p>
    <p><strong parentName="p">{`Note`}</strong>{`: Remember to replace `}<inlineCode parentName="p">{`<recipient_number_here>`}</inlineCode>{` in your code with the
phone number of the recipient who needs to receive server error notifications,
written in an international format (e.g. "+319876543210").`}</p>
    <p>{`That's all you need to do! You've now set up leveled logging for your web server
application that can send specific log levels to set outputs. Also, if your web server application encounters an error, it sends an
SMS notification to your system administrator to tell them to get to work on it!`}</p>
    <h2>{`Testing`}</h2>
    <p>{`You’re done! Let’s go ahead and test your application. Go to your terminal and run the following command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`go run main.go
`}</code></pre>
    <p>{`Go to http://localhost:8080, you should see the following message displayed:`}</p>
    <pre><code parentName="pre" {...{}}>{`Hello. Please enter a valid status code in the path to simulate a HTTP server status. E.g. www.example.com/simulate/404
`}</code></pre>
    <p>{`Now, open http://localhost:8080/simulate/404; this should trigger a warning log event that is displayed in your terminal and logged to your log file, but doesn’t trigger an SMS notification.`}</p>
    <p>{`Finally, go to http://localhost:8080/simulate/500; this should trigger a warning log event that is displayed in your terminal and logged to your log file. Additionally, it should send an SMS notification with the error message.`}</p>
    <p>{`Awesome! You can now take these elements and integrate them into a Go production application. Don't forget to download the code from the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/sms-server-alerts-guide-go"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`.`}</p>
    <p><strong parentName="p">{`Nice work!`}</strong>{` 🎉`}</p>
    <p>{`You now have a working Go web application that simulates HTTP status
codes, and an implementation of the Logrus logger that can send SMS notifications
on server error events! `}</p>
    <h2>{`Start building!`}</h2>
    <p>{`Want to build something similar but not quite sure how to get started? Please feel free to let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`;  we'd love to help!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      