import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import Notification from 'components/Notification';
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
import { Img } from 'components/shared';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`IP Warm-up`}</h1>
    <h3>{`What is IP warming?`}</h3>
    <p>{`IP Warm-up is the practice of gradually increasing email volume from a dedicated IP address according to a planned schedule. Why is it important? Internet Service Providers (ISPs) gradually legitimize email senders as a protection measure against spammers, phishing, fraud and the like. In order to gain full sending access from a new IP address, IP warming is key.`}</p>
    <h3>{`IP Warm-up matters`}</h3>
    <p>{`Your sender reputation determines how ISPs perceive you and process your emails:`}</p>
    <ul>
      <li parentName="ul">{`Your sender reputation controls access to your recipients’ inboxes. If you have a bad reputation, your emails will end up in spam folders or get blocked altogether. If you have a good reputation, your emails will arrive in valid inboxes successfully.`}</li>
      <li parentName="ul">{`B2B and B2C senders must follow the same Warm-up process.`}</li>
      <li parentName="ul">{`You cannot transfer your reputation from your previously used IP.`}</li>
      <li parentName="ul">{`Reputation impacts the associated domain and/or IP address. The reputation associated domain and/or IP address will be affected by:`}
        <ul parentName="li">
          <li parentName="ul">{`Spam complaints`}</li>
          <li parentName="ul">{`Invalid email addresses (hard bounces)`}</li>
          <li parentName="ul">{`Spam trap hits`}</li>
          <li parentName="ul">{`Authentication (SPF, DKIM, DMARC)`}</li>
          <li parentName="ul">{`Third-party blacklistings`}</li>
          <li parentName="ul">{`Engagement`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Opens, clicks, and authentication – DKIM, SPF, DMARC will positively impact your reputation. On the other hand, poor or insufficient permission (high recipient reports as spam), poor list quality (wrong email addresses), IP address and domain blacklisting, spam trap hits, and large spikes in volume will negatively impact your reputation.`}</li>
    </ul>
    <Img src="/img/screenshots/quickstarts/convo/ip-warmup.png" alt="IP Warm-up" width="650px" align="middle" mdxType="Img" />
    <p>{`Here's an example with an initial daily volume of 200 emails:`}</p>
    <Img src="/img/screenshots/quickstarts/convo/ip-warmup-table.png" alt="IP Warm-up" width="650px" align="middle" mdxType="Img" />
    <h3>{`Tips for the best IP Warm-up`}</h3>
    <p>{`ISPs perceive email from a new IP address as suspicious until they are able to establish a positive sending reputation, at which point the sender account is trusted not to spam or abuse recipients. Keep in mind that certain ISPs limit the number of messages delivered per day until the sender establishes a positive sending reputation. Normally, it takes at least 4 to 8 weeks to achieve maximum deliverability, sometimes longer if the ISPs perceive that the emails being sent have not resulted from opt-ins. During the Warm-up phase:`}</p>
    <ul>
      <li parentName="ul">{`For the first two weeks, focus on sending to subscribers who are least likely to bounce, such as recipients who have opted in most recently and consistent openers. For example, recipients who have opened emails in the past 30 days.`}</li>
      <li parentName="ul">{`During the third and fourth weeks, expand to your least active subscribers. For example, those who have opened emails in the past 60 days or have not opened them at all.`}</li>
      <li parentName="ul">{`The more consistent you are with volume, frequency, and bounce levels, the faster you'll be able to establish a positive sending reputation.`}</li>
      <li parentName="ul">{`For the first 6 weeks, don't send any emails to subscribers who haven't opened in the past 90 days.`}</li>
      <li parentName="ul">{`If your volume is 5 million subscribers or more, don't send more than double the previous volume after week 2.`}</li>
    </ul>
    <h3>{`ISP tips`}</h3>
    <p>{`Once you begin warming up it's normal to experience some `}<a parentName="p" {...{
        "href": "https://support.messagebird.com/hc/en-us/sections/360000110878-Group-Bulk-Messaging"
      }}>{`bulking`}</a>{` and blocking. It's important to monitor your metrics and plan accordingly during the Warm-up period. Here are a few tips:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Bulking`}</strong>{` at Yahoo, AOL, Gmail: normally clears up after a few sends with positive metrics (opening a message, adding an email address to a contact list, clicking through links, clicking to enable images, and read rate) but it can take time to get inbox delivery.`}</li>
      <li parentName="ul"><strong parentName="li">{`Delays`}</strong>{` at AOL, Microsoft and Comcast: delays (421 bounces) will retry for 72 hours and if it's not possible to deliver will bounce as a 5XX with the original 421 error in the bounce record. Keep in mind that delays are normal, and will lessen each day as your positive reputation develops; yet, if they are timing out in large quantities you should back down your volumes to that ISP by tightening up your engagement window.`}</li>
      <li parentName="ul">{`An ISP can block you if the list of subscribers isn't engaged enough. The key is to segment carefully and continue sending consistently.`}</li>
    </ul>
    <h3>{`Keep your list engaged`}</h3>
    <p>{`ISPs track how engaged subscribers are with an email and its sender. There are two different types of actions:`}</p>
    <ul>
      <li parentName="ul">{`Positive actions: such as opening a message, adding an email address to a contact list, clicking through links, clicking to enable images, and read rate (scrolls through the message).`}</li>
      <li parentName="ul">{`Negative actions: such as reporting an email as spam, deleting it, moving it to the junk folder, or ignoring it.`}</li>
    </ul>
    <h3>{`Focus on quality over quantity`}</h3>
    <ul>
      <li parentName="ul">{`Each email you send incurs a cost, regardless of whether the email is opened or not. Make the most of your budget by delivering content that people engage with.`}</li>
      <li parentName="ul">{`Run re-engagement campaigns to win back disengaged subscribers. Meanwhile, decrease the send frequency to disengaged subscribers compared to engaged subscribers.`}</li>
    </ul>
    <h3>{`Avoid spam traps`}</h3>
    <p>{`There are a few things you can do to avoid spam traps: do not purchase or rent lists, remove hard bounces, continuously re-engage your inactive subscribers, and remove disengaged subscribers if your re-engagement attempts were unsuccessful.`}</p>
    <p><strong parentName="p">{`Pristine Spam Traps`}</strong></p>
    <ul>
      <li parentName="ul">{`These email addresses are created solely to capture spammers (also known as honey pots). They’re never owned by a real person, don't subscribe to email programs, and of course, would never make a purchase. If you fall into pristine traps, this usually indicates you have a bad data partner or poor list acquisition practices.`}</li>
    </ul>
    <p><strong parentName="p">{`Recycled Spam Traps`}</strong></p>
    <ul>
      <li parentName="ul">{`These email addresses were once used by a real person but were abandoned and recycled by ISPs as spam traps. If you fall into a recycled spam trap, this typically indicates that your data process is not functioning effectively.`}</li>
    </ul>
    <h3>{`Key takeaways`}</h3>
    <ul>
      <li parentName="ul">{`Subscriber opt-ins are crucial, as they ensure that you don’t only acquire consent but set subscribers’ expectations from the beginning. If people do not want your mail, your reputation suffers.`}</li>
      <li parentName="ul">{`As your email lists age, eliminate disengaged subscribers.`}</li>
      <li parentName="ul">{`If you use an old domain that reputation can follow you; however ISPs like Gmail use the reputation of the domain coupled with the reputation of the IP.`}</li>
      <li parentName="ul">{`Permission is the cornerstone of building a positive reputation. Subscribers will report mail they are not expecting to receive, so only send as frequently as planned and only send the content that subscribers have opted in for.`}</li>
      <li parentName="ul">{`Follow the best practices listed in the `}<a parentName="li" {...{
          "href": "https://www.ftc.gov/tips-advice/business-center/guidance/can-spam-act-compliance-guide-business"
        }}>{`Can-Spam Act`}</a>{` and the `}<a parentName="li" {...{
          "href": "https://crtc.gc.ca/eng/com500/guide.htm"
        }}>{`CASL laws`}</a>{`.`}</li>
    </ul>
    <p>{`Now you’re all set to start your IP Warm-up, get a positive sender reputation, and email your customers!`}</p>
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'Install Channels: Email',
      to: '/docs/conversations/install-channel-email'
    }, {
      name: 'Programmable Conversations API Reference',
      to: '/api/conversations'
    }, {
      name: 'Official MessageBird SDKs',
      to: '/libraries'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      