import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import { Img } from 'components/shared';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`WhatsApp coupon template messages`}</h1>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://developers.facebook.com/docs/whatsapp/business-management-api/message-templates/coupon-templates"
      }}>{`WhatsApp coupon template messages`}</a>{`
are templates which contain a button to allow recipients to easily copy the coupon code to their clipboard. This kind of
template message has the following limitations:`}</p>
    <ul>
      <li parentName="ul">{`Currently not supported in the WhatsApp web client`}</li>
      <li parentName="ul">{`Coupon codes are limited to 15 characters`}</li>
      <li parentName="ul">{`The copy code button label can't be customized`}</li>
      <li parentName="ul">{`A template is limited to one copy code button`}</li>
    </ul>
    <h2>{`Creating a coupon template`}</h2>
    <p>{`The following JSON code illustrates how to create a template with a text header, a body and two buttons: a quick reply
and a copy code button. The values used as examples can be customised when sending the template messages. The following
JSON must be the payload of a POST request to the `}<a parentName="p" {...{
        "href": "/api/integrations/#create-template"
      }}>{`Integrations API create templates endpoint`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "name": "whatsapp_coupon_template",
  "wabaId": "5661415107263419",
  "category": "MARKETING",
  "language": "en_US",
  "components": [
    {
      "type": "HEADER",
      "format": "TEXT",
      "text": "BLACK FRIDAY is coming!"
    },
    {
      "type": "BODY",
      "text": "Hello {{1}}, use our coupon {{2}} to get {{3}} off during the Black Friday 2024!",
      "example": {
        "body_text": [
          [
            "Robert",
            "25OFF",
            "25%"
          ]
        ]
      }
    },
    {
      "type": "BUTTONS",
      "buttons": [
        {
          "type": "QUICK_REPLY",
          "text": "Unsubscribe"
        },
        {
          "type": "COPY_CODE",
          "example": ["250FF"]
        }
      ]
    }
  ]
}
`}</code></pre>
    <p>{`The template created in WhatsApp will look like the following:`}</p>
    <Img src="/img/screenshots/quickstarts/whatsapp/coupon-template-1.png" alt="WhatsApp coupon template" width="600px" align="middle" mdxType="Img" />
    <h2>{`Sending a WhatsApp coupon template message`}</h2>
    <p>{`The following JSON code illustrates how to send the WhatsApp coupon template created previously using the
`}<a parentName="p" {...{
        "href": "/api/conversations/#send-message"
      }}>{`Conversations API send message endpoint`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "type": "hsm",
  "content": {
    "hsm": {
      "namespace": "c5437a4f_80d2_4019_a6fd_170cce7d7b76",
      "templateName": "whatsapp_coupon_template",
      "language": {
        "code": "en_US"
      },
      "components": [
        {
          "type": "body",
          "parameters": [
            {
              "type": "text",
              "text": "Robert"
            },
            {
              "type": "text",
              "text": "BLACKFRIDAY"
            },
            {
              "type": "text",
              "text": "30%"
            }
          ]
        },
        {
          "type": "button",
          "sub_type": "COPY_CODE",
          "index": 1,
          "parameters": [
            {
              "type": "coupon_code",
              "coupon_code": "BLACKFRIDAY"
            }
          ]
        }
      ]
    }
  },
  "to": "+1234567890",
  "from": "b489e76b-c740-4c1c-97da-322390c426e0"
}
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`COPY_CODE`}</inlineCode>{` button must contain a parameter to indicate the coupon code.`}</p>
    <p>{`The following image shows how the coupon template message is displayed in WhatsApp:`}</p>
    <Img src="/img/screenshots/quickstarts/whatsapp/coupon-template-2.jpeg" alt="WhatsApp coupon template message" width="600px" align="middle" mdxType="Img" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      