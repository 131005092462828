import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import Questions from 'components/Footer/Questions';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Call and Leg statuses`}</h1>
    <p>{`When a call is placed, the status of the call and its constituent legs can be requested using the `}<a parentName="p" {...{
        "href": "https://developers.messagebird.com/api/voice-calling/#voice-calling-api"
      }}>{`Voice API`}</a>{`. Because the status of the call itself is informed by the status of its legs, more-granular information about the call's current status can be obtained by obtaining the legs records for a given call and inspecting the leg statuses. Information about the status for both calls and legs are updated in real time. The following tables provide a list of available statuses for both calls and legs.`}</p>
    <h2>{`Call statuses`}</h2>
    <p>{`Call statuses can be organized into "progression" states that indicate the current active progress of a call and "final" states that describe the ending status of a call regardless of how it progressed.`}</p>
    <h3>{`Progression statuses`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Status`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Meaning`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`queued`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`As soon as the call is created it will assume a queued state until the Voice API begins fulfilling the call.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`starting`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`After the call has been picked up by the Voice API but before ringing begins, the call will be in a 'starting' phase to indicate that it is no longer queued and is being actively handled.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ringing`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Indicates that the call is in a ringing status wherein the destination number has not yet accepted the call.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ongoing`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The call has been accepted by the destination number and the source number is no longer in a ringing state. Depending on the structure of the Call Flow used, this may have various different meanings (e.g., a pre-recorded message could be played, or a transfer to an agent could have been accepted).`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Final statuses`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Status`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Meaning`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`busy`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The destination of the call was busy and did not answer the call when it was in the `}<inlineCode parentName="td">{`ringing`}</inlineCode>{` progression state.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`hangup`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The call had entered the `}<inlineCode parentName="td">{`ongoing`}</inlineCode>{` progression state, and the call was disconnected afterwards in a hangup event.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`no_answer`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The call was attempting to connect to a destination number but the destination number neither accepted nor rejected the call.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`failed`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The call was unsuccessful and some error occurred that prevented the expected `}<inlineCode parentName="td">{`hangup`}</inlineCode>{` or `}<inlineCode parentName="td">{`busy`}</inlineCode>{` final statuses.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Leg statuses`}</h2>
    <h3>{`Progression statuses`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Status`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Meaning`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`starting`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The leg is starting to be handled by the Voice API but has not yet entered either the `}<inlineCode parentName="td">{`ringing`}</inlineCode>{` or `}<inlineCode parentName="td">{`ongoing`}</inlineCode>{` statuses`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ringing`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The leg is ringing before the destination number has accepted the call.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ongoing`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The leg is in progress and has not yet completed.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Final statuses`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Status`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Meaning`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`hangup`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If the leg was answered it will conclude in a hangup status at the end of the leg.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`busy`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The leg was attempting to connect to a destination number that rejected the call.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`no_answer`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The leg was attempting to connect to a destination number but the destination number neither accepted nor rejected the call.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`cancelled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The `}<inlineCode parentName="td">{`outbound`}</inlineCode>{` leg was either in a `}<inlineCode parentName="td">{`ringing`}</inlineCode>{` or `}<inlineCode parentName="td">{`ongoing`}</inlineCode>{` status, but has been ended by the initiating caller or an `}<inlineCode parentName="td">{`inbound`}</inlineCode>{` leg experienced a connectivity error.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`failed`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An error occurred during the call which resulted in an error.`}</td>
        </tr>
      </tbody>
    </table>
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      