export const COLOR = {
  WHITE: '#ffffff',
  BLACK: '#000000',
  BLUE: '#0089ff',
  LIGHTBLUE: '#eef4ff',
  BLUE_HOVER: '#0065ef',
  GREY_HOVER_MID: '#435369',
  BLUE_MID: '#96C4FF',
  GREY: '#9aa8bd',
  DARKGREY: '#24374E',
  MIDGREY: '#687992',
  LIGHTGREY: '#C3CDDD',
  NOTBLACK: '#001240',
  GREY_BACHGROUND: '#FAFAFA',
};

export const CODE_COLORS = {
  LIGHT_GREY: '#9aa8bd',
  GREY: '#687992',
  WHITE: '#fff',
  BLUE: '#687992',
  PEACH: '#FF9F90',
  GREEN: '#81D9BE',
  YELLOW: '#F7D885',
};

export const NOTIFICATION_COLORS = {
  INFO: '#E6F0FF',
  WARNING: '#FEF7E5',
  GOOD: '#E6FFF6',
  BAD: '#FFE9E6',
};

export const LANGS = {
  curl: 'Curl',
  php: 'PHP',
  node: 'Node',
  csharp: 'C#',
  java: 'Java',
  ruby: 'Ruby',
  go: 'Go',
  python: 'Python',
  json: 'JSON',
  bash: 'Curl',
  js: 'JS',
};

export const API_REF_GUTTER = `75px`;
