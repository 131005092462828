import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Docs.tsx";
import Notification from 'components/Notification';
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Get your free test credits & API keys`}</h1>
    <p>{`In this Conversations API quickstart, you'll learn how to sign up and log in to your `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/user/index"
      }}>{`MessageBird Dashboard`}</a>{`, and how to collect, manage and use your free API keys.`}</p>
    <h2>{`Step 1: Sign up for free`}</h2>
    <p>{`First things first, sign up for a free MessageBird account at `}<a parentName="p" {...{
        "href": "https://www.messagebird.com/"
      }}>{`www.messagebird.com`}</a>{`.`}</p>
    <h2>{`Step 2: Collect your free test SMS credits`}</h2>
    <p>{`You'll be asked to verify your mobile phone number. Once you do, you'll receive 10 free test SMS credits for testing the MessageBird's APIs and you'll have access to all the MessageBird Dashboard tools.`}</p>
    <Notification type="INFO" mdxType="Notification">
Keep in mind that you can only use your free test SMS credits to send text messages to your verified number. If you want to send live SMS messages to numbers other than your verified number, make sure to <a href="https://dashboard.messagebird.com/en/financial/buy-balance">top up your balance</a> first.
    </Notification>
    <h2>{`Step 3: Collect, manage, and use your free API keys`}</h2>
    <p>{`Create, manage, and retrieve your API keys (both test and live) from your `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/settings"
      }}>{`MessageBird Developers Dashboard`}</a>{`.`}</p>
    <Notification type="INFO" mdxType="Notification">
Learn about the <a href="https://support.messagebird.com/hc/en-us/articles/360000670709-What-is-the-difference-between-a-live-key-and-a-test-key-">difference between test and live API Keys</a>.
    </Notification>
    <p>{`Nice work! 🎉  You can now start enjoying the MessageBird Programmable Conversations API.`}</p>
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'Install your first channel',
      to: '/docs/conversations/install-channel'
    }, {
      name: 'Sending messages via the API',
      to: '/docs/conversations/send-messages-curl'
    }, {
      name: 'Receiving messages via the API',
      to: '/docs/conversations/handle-inbound-messages-curl'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      