import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import { Img } from 'components/shared';
import Questions from 'components/Footer/Questions';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Migrating a Phone Number`}</h1>
    <p>{`WhatsApp offer two types of products for business:`}</p>
    <ul>
      <li parentName="ul">{`The SMB app`}</li>
      <li parentName="ul">{`The WhatsApp Business API`}</li>
    </ul>
    <p>{`In this Quickstart, you'll learn how to migrate your phone number from the WhatsAPP SMB app in 5 simple steps, so that it can be used in the WhatsApp Business API instead.`}</p>
    <p>{`Before we go ahead, is the phone number already being used with the WhatsApp Business API?`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Yes!`}</strong>{` You have to pick a different phone number because it cannot be migrated.`}</li>
      <li parentName="ul"><strong parentName="li">{`No!`}</strong>{` Great! Go ahead with the Quickstart.`}</li>
    </ul>
    <p>{`You can use a phone number that's already registered in the Android, iOS, or Business Application versions of WhatsApp; however, if you add that phone number to your Business Manager, you'll see a `}<inlineCode parentName="p">{`Phone Number is Invalid`}</inlineCode>{` error. Don't worry, simply follow the 5 easy step of this Quickstart to delete the WhatsApp account associated with that phone number. Keep in mind that any incoming messages during this time will be queued by the WhatsApp servers and delivered once the registration is successful.`}</p>
    <p><strong parentName="p">{`Step 1`}</strong></p>
    <p>{`Open the WhatsApp or WhatsApp Business app on your Android or iOS.`}</p>
    <p><strong parentName="p">{`Step 2`}</strong></p>
    <p>{`Go to the 'Settings' page, and then click on 'Account'`}</p>
    <Img src="/img/screenshots/quickstarts/convo/Migrating1.png" alt="Migrating number" width="650px" align="middle" mdxType="Img" />
    <p><strong parentName="p">{`Step 3`}</strong></p>
    <p>{`We're almost done! Select 'Delete my account' `}</p>
    <Img src="/img/screenshots/quickstarts/convo/Migrating2.png" alt="Migrating number" width="650px" align="middle" mdxType="Img" />
    <p><strong parentName="p">{`Step 4`}</strong></p>
    <p>{`Confirm your country code, phone number, and click on 'Delete my account'.`}</p>
    <Img src="/img/screenshots/quickstarts/convo/Migrating3.png" alt="Migrating number" width="650px" align="middle" mdxType="Img" />
    <p><strong parentName="p">{`Step 5`}</strong></p>
    <p>{`Voilà! Now simply send your WhatsApp phone number to the MessageBird Support team. We'll register your number directly with WhatApp that same day or on the next working day.`}</p>
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      