import React, { useState } from 'react';
import { track } from '../helpers/tracking';

export const LangContext = React.createContext({
  language: 'curl',
  handleLangChange: () => {},
  availableLanguages: [],
  setAvailableLanguages: () => {},
});

// eslint-disable-next-line react/prop-types
const LanguageContext = ({ children }) => {
  const [language, setLanguage] = useState('curl');
  const [availableLanguages, setAvailableLanguages] = useState([]);
  const handleLangChange = (lang) => {
    track('dev:languageSwitched', { language: lang });
    setLanguage(lang);
  };
  return (
    <LangContext.Provider
      value={{
        language,
        handleLangChange,
        availableLanguages,
        setAvailableLanguages,
      }}
    >
      {children}
    </LangContext.Provider>
  );
};

export default LanguageContext;
