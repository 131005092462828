import React from 'react';
import * as Local from './styled';
import { Flex, HeadingSm, Button } from '../shared';
import { track } from '../../helpers/tracking';

const LogoFooter = () => (
  <Local.Wrap>
    <Flex>
      <HeadingSm>
        Trusted by over 450,000 developers and Fortune 500 companies
      </HeadingSm>
    </Flex>
    <Flex>
      <img alt="Facebook" src="/img/logos/facebook.svg" />
      <img alt="Uber" src="/img/logos/uber.svg" />
      <img alt="Deliveroo" src="/img/logos/deliveroo.svg" />
      <img alt="SAP" src="/img/logos/sap.svg" />
    </Flex>
  </Local.Wrap>
);

export { LogoFooter };
