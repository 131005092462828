import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Setting SMS reminders with MessageBird`}</h1>
    <h3>{`⏱ 15 min build time || `}<a parentName="h3" {...{
        "href": "https://github.com/messagebirdguides/reminders-guide"
      }}>{`Download the Code`}</a></h3>
    <h2>{`Why build SMS appointment reminders?`}</h2>
    <p>{`In this MessageBird Developer Tutorial, you’ll learn how to build an SMS appointment reminder application and improve your users’ experience with the `}<a parentName="p" {...{
        "href": "/api/sms-messaging"
      }}>{`MessageBird SMS Messaging API`}</a>{`.`}</p>
    <p>{`Booking appointments online from a website or mobile app is quick and easy. Customers just have to select their desired date and time, personal details and hit a button. The problem, however, is that easy-to-book appointments are often just as easy to forget.`}</p>
    <p>{`For appointment-based services, no-shows are annoying and costly because of the time and revenue lost waiting for a customer instead of serving them or another customer. Timely SMS reminders simple and discrete nudges, which can go a long way in the prevention of costly no-shows.`}</p>
    <h2>{`Getting started`}</h2>
    <p>{`This sample application in build Node.js and represents the order website for our fictitious online beauty salon, `}<em parentName="p">{`BeautyBird`}</em>{`. To reduce the growing number of no-shows, BeautyBird now collects appointment bookings through a form on their website and schedules timely SMS reminders to be sent out three hours before the selected date and time.`}</p>
    <p>{`To look at the full sample application or run it on your computer, go to the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/reminders-guide"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{` and clone it or download the source code as a ZIP archive.`}</p>
    <p>{`You’ll need Node and npm, you can easily `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/get-npm"
      }}>{`install them for free`}</a>{`.`}</p>
    <p>{`Open a console pointed at the directory into which you've placed the sample application and run the following command to install the `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/messagebird"
      }}>{`MessageBird SDK for Node.js`}</a>{` and other dependencies:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install
`}</code></pre>
    <h2>{`Configuring the MessageBird SDK`}</h2>
    <p>{`The SDK is loaded with the following `}<inlineCode parentName="p">{`require()`}</inlineCode>{` statement in`}<inlineCode parentName="p">{`index.js`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// Load and initialize MessageBird SDK
var messagebird = require('messagebird')(process.env.MESSAGEBIRD_API_KEY);
`}</code></pre>
    <p>{`The MessageBird API key needs to be provided as a parameter.`}</p>
    <p><strong parentName="p">{`Pro-tip`}</strong>{`: Hardcoding your credentials in the code is a risky practice that should never be used in production applications. A better method, also recommended by the `}<a parentName="p" {...{
        "href": "https://12factor.net/"
      }}>{`Twelve-Factor App Definition`}</a>{`, is to use environment variables.`}</p>
    <p>{`We've added `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/dotenv"
      }}>{`dotenv`}</a>{` to the sample application so that you can supply your API key in a file named `}<inlineCode parentName="p">{`.env`}</inlineCode>{` as well:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-env"
      }}>{`MESSAGEBIRD_API_KEY=YOUR-API-KEY
`}</code></pre>
    <p>{`API keys can be created or retrieved from the `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`API access (REST) tab`}</a>{` in the `}<em parentName="p">{`Developers section`}</em>{` of the MessageBird Dashboard. There you can create new API keys and manage your existing ones.`}</p>
    <p>{`If you are having any issues creating your API key, please reach out to
`}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we’ll make sure to help you out.`}</p>
    <h2>{`Collecting user input`}</h2>
    <p>{`To send SMS messages to users, you need to collect their phone number as part of the booking process. We’ve created a sample form that asks the user for their name, desired treatment, number, date and time. For HTML forms it's recommended to use `}<inlineCode parentName="p">{`type="tel"`}</inlineCode>{` for the phone number input. You can see the template for the complete form in the file `}<inlineCode parentName="p">{`views/home.handlebars`}</inlineCode>{` and the route that drives it’s defined as `}<inlineCode parentName="p">{`app.get('/')`}</inlineCode>{` in `}<inlineCode parentName="p">{`index.js`}</inlineCode>{`.`}</p>
    <h2>{`Storing appointments and scheduling reminders`}</h2>
    <p>{`The user's input is sent to the route `}<inlineCode parentName="p">{`app.post('/book')`}</inlineCode>{` defined in `}<inlineCode parentName="p">{`index.js.`}</inlineCode>{` The implementation covers the following steps:`}</p>
    <h3>{`Step 1: Check their input`}</h3>
    <p>{`Validate that the user has entered a value for every field in the form.`}</p>
    <h3>{`Step 2: Check the appointment date and time`}</h3>
    <p>{`Confirm that the date and time are valid and at least three hours and five minutes in the future—BeautyBird won't take bookings on shorter notice. Also, since we want to schedule reminders three hours before the treatment, anything else doesn't make sense from a testing perspective. We recommend you using a library such as `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/moment"
      }}>{`moment`}</a>{`, which makes working with date and time calculations a breeze. Don't worry; we've already integrated it into our sample application:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// Check if date/time is correct and at least 3:05 hours in the future
var earliestPossibleDT = moment().add({hours:3, minutes:5});
var appointmentDT = moment(req.body.date+" "+req.body.time);
if (appointmentDT.isBefore(earliestPossibleDT)) {
   // If not, show an error
   // …
`}</code></pre>
    <h3>{`Step 3: Check their phone number`}</h3>
    <p>{`Check whether the phone number is correct. This can be done with `}<a parentName="p" {...{
        "href": "/api/lookup#lookup-request"
      }}>{`MessageBird Lookup API`}</a>{`, which takes a phone number entered by a user, validates the format and returns information about the number, such as whether it is a mobile or fixed line number. This API doesn't enforce a specific format for the number but rather understands a variety of different variants for writing a phone number, for example using different separator characters between digits gives your users the flexibility to enter their number in various ways. In the SDK, you can call `}<inlineCode parentName="p">{`messagebird.lookup.read()`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// Check if phone number is valid
messagebird.lookup.read(req.body.number, process.env.COUNTRY_CODE, function (err, response) {
   // …
`}</code></pre>
    <p>{`The function takes three arguments: the phone number, a country code, and a callback function. Providing a default country code enables users to supply their number in a local format without the country code.`}</p>
    <p>{`To add a country code, add the following line to you `}<inlineCode parentName="p">{`.env`}</inlineCode>{` file, replacing NL with your own ISO country code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-env"
      }}>{`COUNTRY_CODE=NL
`}</code></pre>
    <p>{`In the `}<inlineCode parentName="p">{`callback`}</inlineCode>{` function, we handle four different cases:`}</p>
    <ul>
      <li parentName="ul">{`An error (code 21) occurred, which means MessageBird was unable to parse the phone number.`}</li>
      <li parentName="ul">{`Another error code occurred, which means something else went wrong in the API.`}</li>
      <li parentName="ul">{`No error occurred, but the value of the response type attribute is something other than mobile.`}</li>
      <li parentName="ul">{`Everything is ok, which means a mobile number was provided successfully.`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`if (err && err.errors[0].code == 21) {
       // This error code indicates that the phone number has an unknown format
       res.render('home', {
           error : "You need to enter a valid phone number!",
           name : req.body.name,
           treatment : req.body.treatment,
           number: req.body.number,
           date : req.body.date,
           time : req.body.time
       });
       return;
   } else
   if (err) {
       // Some other error occurred
       res.render('home', {
           error : "Something went wrong while checking your phone number!",
           name : req.body.name,
           treatment : req.body.treatment,
           number: req.body.number,
           date : req.body.date,
           time : req.body.time
       });
   } else
   if (response.type != "mobile") {
       // The number lookup was successful but it is not a mobile number
       res.render('home', {
           error : "You have entered a valid phone number, but it's not a mobile number! Provide a mobile number so we can contact you via SMS.",
           name : req.body.name,
           treatment : req.body.treatment,
           number: req.body.number,
           date : req.body.date,
           time : req.body.time
       });
   } else {
       // Everything OK
`}</code></pre>
    <p>{`The implementation for the following steps is contained within the last `}<inlineCode parentName="p">{`else`}</inlineCode>{` block.`}</p>
    <h3>{`Step 4: Schedule the reminder`}</h3>
    <p>{`Using `}<inlineCode parentName="p">{`moment`}</inlineCode>{`, we can easily specify the time for our reminder:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`// Schedule reminder 3 hours prior to the treatment
var reminderDT = appointmentDT.clone().subtract({hours: 3});
`}</code></pre>
    <p>{`Then it's time to call the MessageBird API:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`// Send scheduled message with MessageBird API
messagebird.messages.create({
   originator : "BeautyBird",
   recipients : [ response.phoneNumber ], // normalized phone number from lookup request
   scheduledDatetime : reminderDT.format(),
   body : req.body.name + ", here's a reminder that you have a " + req.body.treatment + " scheduled for " + appointmentDT.format('HH:mm') + ". See you soon!"
}, function (err, response) {
   // …
`}</code></pre>
    <p>{`Let's break down the parameters that are set with this call of `}<inlineCode parentName="p">{`messagebird.messages.create()`}</inlineCode>{`:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`originator`}</inlineCode>{`: It represents the sender ID. You can use a mobile number here, or an alphanumeric ID, like in the example. Keep in mind that alphanumeric senders are not supported in every country including the United States, so it’s important to check the `}<a parentName="li" {...{
          "href": "https://support.messagebird.com/hc/en-us/sections/360000108538-Country-info-Restrictions"
        }}>{`country restrictions`}</a>{`. If you can't use alphanumeric IDs, use a real phone number instead. You can check our `}<a parentName="li" {...{
          "href": "https://support.messagebird.com/hc/en-us/articles/115002628665-What-is-the-originator-"
        }}>{`originator article`}</a>{` in Help Center to learn more about this topic.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`recipients`}</inlineCode>{`: An array of phone numbers. We just need one number; we're using the normalized number returned from the Lookup API instead of the user-provided input.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`scheduledDatetime`}</inlineCode>{`: This instructs MessageBird not to send the message immediately but at a given timestamp, which we've defined previously. Using moment's `}<inlineCode parentName="li">{`format()`}</inlineCode>{` method we make sure the API can read this timestamp correctly.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`body`}</inlineCode>{`: It's the friendly text for the message.`}</li>
    </ul>
    <h3>{`Step 5: Store the appointment`}</h3>
    <p>{`The application's logic continues in the callback function for the `}<inlineCode parentName="p">{`messagebird.messages.create()`}</inlineCode>{` API call, where we need to handle both success and error cases:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`}, function (err, response) {
   if (err) {
       // Request has failed
       console.log(err);
       res.send("Error occured while sending message!");
   } else {
       // Request was successful
       console.log(response);

       // Create and persist appointment object
       var appointment = {
           name : req.body.name,
           treatment : req.body.treatment,
           number: req.body.number,
           appointmentDT : appointmentDT.format('Y-MM-DD HH:mm'),
           reminderDT : reminderDT.format('Y-MM-DD HH:mm')
       }
       AppointmentDatabase.push(appointment);

       // Render confirmation page
       res.render('confirm', appointment);
   }
});
`}</code></pre>
    <p>{`As you can see, for the purpose of the sample application we simply "persist" the appointment to a global variable in memory. This is where in practical applications you would write the appointment to a persistence layer such as a file or database. We also show a confirmation page, which is defined in `}<inlineCode parentName="p">{`views/confirm.handlebars`}</inlineCode>{`.`}</p>
    <h2>{`Testing`}</h2>
    <p>{`You’re done! To test your application, let's run the following command from your console:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`node index.js
`}</code></pre>
    <p>{`Then, open `}<a parentName="p" {...{
        "href": "http://localhost:8080/"
      }}>{`http://localhost:8080/`}</a>{` in your browser to see the form and schedule your appointment! If you've used a live API key, a message will arrive at your phone three hours before the appointment! But don't actually leave the house, this is just a demo. 😜`}</p>
    <p>{`Awesome! You can use the flow, code snippets, and UI examples from this tutorial as an inspiration to build your own SMS reminder system. Don't forget to download the code from the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/reminders-guide"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`.`}</p>
    <p><strong parentName="p">{`Nice work!`}</strong>{`🎉
You now have a running SMS appointment reminder application using Node.js!`}</p>
    <h2>{`Start building!`}</h2>
    <p>{`Want to build something similar but not quite sure how to get started? Feel free to let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we'd love to help!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      