import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Setting SMS server alerts with MessageBird`}</h1>
    <h3>{`⏱ 30 min build time      ||      `}<a parentName="h3" {...{
        "href": "https://github.com/messagebirdguides/sms-server-alerts-guide-ruby"
      }}>{`Download the Code`}</a></h3>
    <h2>{`Why build SMS server alerts?`}</h2>
    <p>{`In this MessageBird Developer Tutorial, you’ll learn how to quickly report error logs of your server via SMS server alerts with the MessageBird API to ensure a faster response time.`}</p>
    <p>{`For any online service advertising guaranteed uptime north of 99%, being available and reliable is extremely important; therefore, it is essential that any errors in the system are fixed as soon as possible, and the prerequisite for that is that error reports are delivered quickly to the engineers on duty. Providing those error logs via SMS ensures a faster response time compared to email reports and helps companies keep their uptime promises.`}</p>
    <p>{`We’ll show you how to build an integration of SMS alerts into a Ruby application that uses the `}<a parentName="p" {...{
        "href": "https://rubygems.org/gems/semantic_logger"
      }}>{`Semantic Logger`}</a>{` logging framework.`}</p>
    <h2>{`Logging Primer with Semantic Logger`}</h2>
    <p>{`Logging is the default approach for gaining insights into running applications. Before we start building our sample application, let's take a minute to understand two fundamental concepts of logging: levels and transports.`}</p>
    <p><strong parentName="p">{`Levels`}</strong>{` indicate the severity of the log item. Common log levels are `}<em parentName="p">{`debug`}</em>{`, `}<em parentName="p">{`info`}</em>{`, `}<em parentName="p">{`warning`}</em>{`, and `}<em parentName="p">{`error`}</em>{`. For example, a user trying to login could have the `}<em parentName="p">{`info`}</em>{` level, a user entering the wrong password during login could be a `}<em parentName="p">{`warning`}</em>{` since it may be a potential attack, and a user not able to access the system due to a subsystem failure would trigger an `}<em parentName="p">{`error`}</em>{`.`}</p>
    <p><strong parentName="p">{`Transports`}</strong>{` are different channels into which the logger writes its data. Typical channels are the console, files, log collection servers and services, and communication channels such as email, SMS, or push notifications.`}</p>
    <p>{`It's possible and common to set up multiple kinds of transport for the same logger but set different levels for each. In our sample application, we write entries of all severities to the console and a log file; the application will send SMS notifications only for log items that have the `}<em parentName="p">{`error`}</em>{` level (or higher, when using more levels).`}</p>
    <h2>{`Getting started`}</h2>
    <p>{`First things first, our sample application is built in Ruby and uses Semantic Logger as the logging library, so you need to install Ruby](`}<a parentName="p" {...{
        "href": "https://www.ruby-lang.org/en/"
      }}>{`https://www.ruby-lang.org/en/`}</a>{`) and `}<a parentName="p" {...{
        "href": "https://bundler.io/"
      }}>{`bundler`}</a>{`. We have also included an example using the `}<a parentName="p" {...{
        "href": "http://sinatrarb.com/"
      }}>{`Sinatra framework`}</a>{` to demonstrate web application request logging.`}</p>
    <p>{`The source code is available in the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/sms-server-alerts-guide-ruby"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`, which you can either clone with git or from where you can download a ZIP file with the source code to your computer.`}</p>
    <p>{`To install the `}<a parentName="p" {...{
        "href": "https://rubygems.org/gems/messagebird-rest"
      }}>{`MessageBird SDK for Ruby`}</a>{` and the other dependencies mentioned above, open a console pointed at the directory into which you've saved the sample application and run the following command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`bundle install
`}</code></pre>
    <h2>{`Building a MessageBird transport`}</h2>
    <p>{`Semantic Logger enables developers to build custom transports and use them with the logger just like built-in transports such as the file or console transports. They are extensions of the `}<inlineCode parentName="p">{`SemanticLogger::Subscriber`}</inlineCode>{` class and need to implement a constructor for initialization as well as the `}<inlineCode parentName="p">{`log()`}</inlineCode>{` method. We have created one in the file `}<inlineCode parentName="p">{`message_bird_transport.rb`}</inlineCode>{`.`}</p>
    <p>{`Our SMS alert functionality needs the following information to work:`}</p>
    <ul>
      <li parentName="ul">{`A functioning MessageBird API key.`}</li>
      <li parentName="ul">{`An originator, that is, a sender ID for the messages.`}</li>
      <li parentName="ul">{`One or more recipients, that is, the phone numbers of the system engineers that should be informed about problems with the server.`}</li>
    </ul>
    <p>{`To keep the custom transport self-contained and independent from the way the application wants to provide the information, we take all this as parameters in our constructor. Here's the code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`module SemanticLogger
 module Appender
   class MessageBirdTransport < SemanticLogger::Subscriber
     attr_reader :key, :originator, :recipients

     # Create Appender
     #
     # Parameters
     #   level: [:trace | :debug | :info | :warn | :error | :fatal]
     #     Override the log level for this appender.
     #     Default: :error
     #
     #   key: String
     #     The MessageBird API key
     #
     #   originator: String
     #     A sender ID for the messages being sent.
     #
     #   recipients: <String>
     #     One or more recipients to inform.
     #
     def initialize(level: :error, key:, originator:, recipients:, **args, &block)
       # Load and initialize MesageBird SDK
       @client = MessageBird::Client.new(key)
       @originator  = originator
       @recipients = recipients
       super(level: level, **args, &block)
     end
   end
 end
end
`}</code></pre>
    <p>{`As you can see, the constructor loads and initializes the MessageBird SDK with the key, stores the other the necessary configuration fields as members of the object, and then calls the `}<inlineCode parentName="p">{`super()`}</inlineCode>{`-constructor to keep basic custom transport behavior intact.`}</p>
    <p>{`In the `}<inlineCode parentName="p">{`log()`}</inlineCode>{` method, again we start with some default code from the basic custom transport class:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`# Send the notification
def log(log)
 context = formatter.call(log, self)
`}</code></pre>
    <p>{`Then, we shorten the log entry, to make sure it fits in the 160 characters of a single SMS so that notifications don't incur unnecessary costs or break limits:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`# Shorten log entry
text = (context[:message].length > 140) ? "#{context[:message]}..." : context[:message]
`}</code></pre>
    <p>{`Finally, we call `}<inlineCode parentName="p">{`client.message_create`}</inlineCode>{` to send an SMS notification. For the required parameters `}<em parentName="p">{`originator`}</em>{` and `}<em parentName="p">{`recipients`}</em>{` we use the values stored in the constructor, and for `}<em parentName="p">{`body`}</em>{` we use the (shortened) log text prefixed with the level:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`@client.message_create(@originator, @recipients, text)
`}</code></pre>
    <h2>{`Configuring our transport`}</h2>
    <p>{`In `}<inlineCode parentName="p">{`app.rb`}</inlineCode>{`, the primary file of our application, we start off by loading the dependencies and the custom transport class. We also use `}<a parentName="p" {...{
        "href": "https://rubygems.org/gems/dotenv"
      }}>{`dotenv`}</a>{` to load configuration data from an `}<inlineCode parentName="p">{`.env`}</inlineCode>{` file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`require 'dotenv'
require 'sinatra'
require 'messagebird'

require 'semantic_logger'
require_relative 'message_bird_transport'

set :root, File.dirname(__FILE__)

#  Load configuration from .env file
Dotenv.load if Sinatra::Base.development?
`}</code></pre>
    <p>{`Copy `}<inlineCode parentName="p">{`env.example`}</inlineCode>{` to `}<inlineCode parentName="p">{`.env`}</inlineCode>{` and store your information:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`MESSAGEBIRD_API_KEY=YOUR-API-KEY
MESSAGEBIRD_ORIGINATOR=Logger
MESSAGEBIRD_RECIPIENTS=31970XXXXXXX,31970YYYYYYY
`}</code></pre>
    <p>{`You can create or retrieve an API key `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`in your MessageBird account`}</a>{`. The originator can be a phone number you registered through MessageBird or, for countries that support it, an alphanumeric sender ID with at most 11 characters. You can provide one or more comma-separated phone numbers as recipients.`}</p>
    <p>{`Back in `}<inlineCode parentName="p">{`app.rb`}</inlineCode>{`, it's time to set up the logger:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`SemanticLogger.add_appender(io: STDOUT, level: :debug)
SemanticLogger.add_appender(file_name: 'app.log', level: :info)
appender = SemanticLogger::Appender::MessageBirdTransport.new(level: :error, key: ENV['MESSAGEBIRD_API_KEY'], originator: ENV['MESSAGEBIRD_ORIGINATOR'], recipients: ENV['MESSAGEBIRD_RECIPIENTS'].split(','))
SemanticLogger.add_appender(appender: appender)

logger = SemanticLogger['TestApp']
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`SemanticLogger.add_appender`}</inlineCode>{` method takes a variety of optional configuration parameters. As you see in the example, we have added three transports:`}</p>
    <ul>
      <li parentName="ul">{`The default Console transport, where we log everything starting with the `}<inlineCode parentName="li">{`debug`}</inlineCode>{` level.`}</li>
      <li parentName="ul">{`A default File transport, where we log `}<inlineCode parentName="li">{`info`}</inlineCode>{` and higher into a file called `}<inlineCode parentName="li">{`app.log`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`Our previously created custom `}<inlineCode parentName="li">{`MessageBirdTransport`}</inlineCode>{` with all the configuration options taken from our environment file. We convert the comma-separated recipients into an array with `}<inlineCode parentName="li">{`split(',')`}</inlineCode>{`. This transport only handles log events with the `}<inlineCode parentName="li">{`error`}</inlineCode>{` level.`}</li>
    </ul>
    <h2>{`Testing`}</h2>
    <p>{`We have added some test log entries in `}<inlineCode parentName="p">{`app.rb`}</inlineCode>{` and we also created a Sinatra route to simulate a 500 server response. It’s time to run the application! Go to your console and type the following command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`ruby app.rb
`}</code></pre>
    <p>{`You should see:`}</p>
    <ul>
      <li parentName="ul">{`Four messages printed on the console.`}</li>
      <li parentName="ul">{`Three log items written to the `}<inlineCode parentName="li">{`app.log`}</inlineCode>{` file (open it with a text editor or with `}<inlineCode parentName="li">{`tail`}</inlineCode>{` in a new console tab).`}</li>
      <li parentName="ul">{`One error message on your phone.`}</li>
    </ul>
    <p>{`Open http://localhost:4567/. For the successful request, you will see a log entry on the console and in the file.`}</p>
    <p>{`Now, open http://localhost:4567/simulateError and, along with the request error on your console and the log file, another notification will arrive at your phone.`}</p>
    <p>{`You can now take these elements and integrate them into a Ruby production application. Don't forget to download the code from the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/sms-server-alerts-guide-ruby"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`.`}</p>
    <p><strong parentName="p">{`Nice work!`}</strong>{` 🎉`}</p>
    <p>{`You've learned how to log with Sinatra and SemanticLogger to create a custom MessageBird transport using Ruby!`}</p>
    <h2>{`Start building!`}</h2>
    <p>{`Want to start building your solution but not quite sure how to get started? Feel free to let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we'd love to help!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      