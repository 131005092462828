export const BASE_URL = {
  bash: `https://contacts.messagebird.com/v2`,
};

export const ENDPOINTS_SUMMARY = {
  bash: `
// Contacts
GET /contacts
POST /contacts
GET /contacts/{id}
PATCH /contacts/{id}
DELETE /contacts/{id}

// Identifiers
POST /contacts/{id}/identifiers
DELETE /contacts/{id}/identifiers/{id}

// Imports
POST /import/contacts
GET  /import/contacts/{import_id}/status
`,
};

export const ERROR_EXAMPLE = {
  json: `{
  "errors": [
    {
      "code": 2,
      "description": "Request not allowed (incorrect access_key)",
      "parameter": "access_key"
    }
  ]
}`,
};

const contactJsonObject = `{
  "id": "f4a4af42f9894b56b6f7b3418443b2e1",
  "href": "https://contacts.messagebird.com/v2/contacts/f4a4af42f9894b56b6f7b3418443b2e1",
  "displayName": "Jane Tester",
  "firstName": "Jane",
  "lastName": "Tester",
  "identifiers": [
    {
      "id": "d4095cbec4be475c862f329b109e961e",
      "type": "emailaddress",
      "value": "jane.tester@messagebird.com"
    },
    {
      "id": "5abffd64e6aa445fa9947449e44c49f0",
      "type": "phonenumber",
      "value": "3112345678"
    }
  ],
  "languages": [
    "en",
    "nl"
  ],
  "timezone": "UTC+1",
  "country": "NL",
  "avatar": "https://example.com/avatar.png",
  "gender": "f",
  "profiles": [
    {
      "id": "de93f56a2d7442d3b1e9048637c7382e",
      "channelId": "d759a993508e42e49fa74afc0687cc4b",
      "identifier": "3112345678"
    }
  ],
  "attributes": {
    "membership": "platinum",
    "status": "vip",
    "customerNumber": "1234"
  },
  "status": "active",
  "createdAt": "2019-05-08T12:23:19Z",
  "updatedAt": "2019-05-08T12:23:19Z"
}`;

const contactObject = JSON.parse(contactJsonObject);

export const CONTACT_OBJ = {
  json: contactJsonObject,
};

export const CONTACTINDEX_DEF = {
  bash: `GET https://contacts.messagebird.com/v2/contacts`,
};

export const CONTACTINDEX_REQ = {
  curl: `
curl "https://contacts.messagebird.com/v2/contacts?channelId=d759a993508e42e49fa74afc0687cc4b&status=active&offset=40&limit=10" \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0Qy' \\
  -H 'Content-Type: application/json; charset=utf-8'
`,
};

export const CONTACTINDEX_RESP = {
  json: JSON.stringify(
    {
      totalCount: 43,
      count: 3,
      offset: 10,
      limit: 10,
      items: [
        contactObject,
        {
          '...': '...',
        },
        {
          '...': '...',
        },
      ],
    },
    null,
    '\t',
  ),
};

export const CREATECONTACT_DEF = {
  bash: `POST https://contacts.messagebird.com/v2/contacts`,
};

export const CREATECONTACT_REQ = {
  curl: `curl -X POST "https://contacts.messagebird.com/v2/contacts" \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0Qy' \\
  -H 'Content-Type: application/json; charset=utf-8' \\
  -d $'{
    "displayName": "Jane Tester",
    "firstName": "Jane",
    "lastName": "Tester",
    "avatar": "https://example.com/avatar.png",
    "languages": [
      "en",
      "nl"
    ],
    "country": "NL",
    "timezone": "UTC+1",
    "gender": "f",
    "attributes": {
      "membership": "platinum",
      "status": "vip",
      "customerNumber": "1234"
    }
  }'
`,
};

export const CREATECONTACT_RESP = {
  json: JSON.stringify(
    Object.assign({}, contactObject, {
      profiles: [],
    }),
    null,
    '\t',
  ),
};

export const GETCONTACT_DEF = {
  bash: `GET https://contacts.messagebird.com/v2/contacts/{id}`,
};

export const GETCONTACT_REQ = {
  curl: `
curl "https://contacts.messagebird.com/v2/contacts/f4a4af42f9894b56b6f7b3418443b2e1" \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0Qy' \\
  -H 'Content-Type: application/json; charset=utf-8'
`,
};

export const GETCONTACT_RESP = CONTACT_OBJ;

export const UPDATECONTACT_DEF = {
  bash: `PATCH https://contacts.messagebird.com/v2/contacts/{id}`,
};

export const UPDATECONTACT_REQ = {
  curl: `curl -X PATCH "https://contacts.messagebird.com/v2/contacts/0b0f86191edb46cbb47c108f2c11d5a6" \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0Qy' \\
  -H 'Content-Type: application/json; charset=utf-8' \\
  -d $'{
    "displayName": "Jane from MessageBird",
    "languages": ["en", "fr"],
    "attributes": {
      "company": "MessageBird",
      "team": "Product Engineering"
    }
  }'
`,
};

export const UPDATECONTACT_RESP = CONTACT_OBJ;

export const DELETECONTACT_DEF = {
  bash: `DELETE https://contacts.messagebird.com/v2/contacts/{id}`,
};

export const DELETECONTACT_REQ = {
  curl: `
curl -X DELETE https://rest.messagebird.com/v2/contacts/61afc0531573b08ddbe36e1c85602827 \\
-H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM'
`,
};

export const DELETECONTACT_RESP = {
  bash: `[empty]`,
};

export const LISTIDENTIFIERS_DEF = {
  bash: `GET https://contacts.messagebird.com/v2/contacts/{id}/identifiers`,
};

export const LISTIDENTIFIERS_REQ = {
  curl: `curl "https://contacts.messagebird.com/v2/contacts/f4a4af42f9894b56b6f7b3418443b2e1/identifiers" \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0Qy' \\
  -H 'Content-Type: application/json; charset=utf-8'
`,
};

export const LISTIDENTIFIERS_RESP = {
  json: `{
    "count" : 1,
    "offset" : 0,
    "limit" : 20,
    "items": [{
      "id": "35964e692de14a8ca79ef0facb8d6c28",
      "type": "emailaddress",
      "value": "mark@gmail.com",
      "createdAt": "2019-05-08T12:23:19Z" 
    }]
  }`,
};

export const GETIDENTIFIER_DEF = {
  bash: `GET https://contacts.messagebird.com/v2/contacts/{id}/identifiers/{identifier_id}`,
};

export const GETIDENTIFIER_REQ = {
  curl: `curl "https://contacts.messagebird.com/v2/contacts/f4a4af42f9894b56b6f7b3418443b2e1/identifiers/35964e692de14a8ca79ef0facb8d6c28" \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0Qy' \\
  -H 'Content-Type: application/json; charset=utf-8'
`,
};

export const GETIDENTIFIER_RESP = {
  json: `{
    "id": "35964e692de14a8ca79ef0facb8d6c28",
    "type": "emailaddress",
    "value": "mark@gmail.com",
    "createdAt": "2019-05-08T12:23:19Z" 
  }`,
};

export const LINKIDENTIFIER_DEF = {
  bash: `POST https://contacts.messagebird.com/v2/contacts/{id}/identifiers`,
};

export const LINKIDENTIFIER_REQ = {
  curl: `curl -X POST "https://contacts.messagebird.com/v2/contacts/f4a4af42f9894b56b6f7b3418443b2e1/identifiers" \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0Qy' \\
  -H 'Content-Type: application/json; charset=utf-8' \\
  -d $'{
    "type": "emailaddress",
    "value": "mark@gmail.com"
  }'
`,
};

export const LINKIDENTIFIER_RESP = {
  json: `{
    "id": "35964e692de14a8ca79ef0facb8d6c28",
    "type": "emailaddress",
    "value": "mark@gmail.com",
    "createdAt": "2019-05-08T12:23:19Z" 
  }`,
};

export const DELETEIDENTIFIER_DEF = {
  bash: `DELETE https://contacts.messagebird.com/v2/contacts/{id}/identifiers/{identifier_id}`,
};

export const DELETEIDENTIFIER_REQ = {
  curl: `curl -X DELETE "https://contacts.messagebird.com/v2/contacts/f4a4af42f9894b56b6f7b3418443b2e1/identifiers/35964e692de14a8ca79ef0facb8d6c28" \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0Qy' \\
  -H 'Content-Type: application/json; charset=utf-8'
`,
};

export const IMPORT_BODY_EXMP = {
  json: `
phone,email,firstName,lastName,displayName,custom:membership,custom:status,custom:customerNumber
+31612345678,johandoe@messagebird.com,John,Doe,John Doe,silver,,1111
+3112345678,,Jane,Tester,Jane Tester,platinum,vip,1234
`,
};

export const IMPORT_DEF = {
  bash: `POST https://contacts.messagebird.com/v2/import/contacts`,
};

export const IMPORT_REQ = {
  curl: `
curl -X POST https://contacts.messagebird.com/v2/import/contacts \\
-H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0Qy" \\
-H 'Content-Type: text/csv' \\
-H 'Content-Disposition: form-data; name="contacts"; filename="contacts.csv"' \\
-d '@test.csv'
`,
};

export const IMPORT_RESP = {
  json: `
{
  "id":"123123123123"
  "href":"https://contacts.messagebird.com/v2/import/contacts/123123123123/status"
}
`,
};

export const TRACK_IMPORT_DEF = {
  bash: `GET https://contacts.messagebird.com/v2/import/contacts/{import_id}/status`,
};

export const TRACK_IMPORT_REQ = {
  curl: `
curl "https://contacts.messagebird.com/v2/import/contacts/{import_id}/status" \\
-H "Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0Qy"
`,
};

export const TRACK_IMPORT_RESP = {
  json: `
{ 
  status: “in progress”,
  message: “importing”
}
`,
};

export const MERGECONTACT_DEF = {
  bash: `POST https://contacts.messagebird.com/v2/ops/contacts/merge`,
};

export const MERGECONTACT_REQ = {
  curl: `
curl -X POST "https://contacts.messagebird.com/v2/ops/contacts/merge" \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0Qy' \\
  -H 'Content-Type: application/json; charset=utf-8' \\
  -d $'{
    "origin": "5ff77bdc2f004451986be7e03894d52b",
    "destination": "f4a4af42f9894b56b6f7b3418443b2e1"`,
};

export const UPDATEORCREATECONTACT_DEF = {
  bash: `POST https://contacts.messagebird.com/v2/ops/contacts/upsert`,
};

export const UPDATEORCREATECONTACT_REQ = {
  curl: `
curl -X POST "https://contacts.messagebird.com/v2/ops/contacts/upsert" \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0Qy' \\
  -H 'Content-Type: application/json; charset=utf-8' \\
  -d $'{
    "updateContact": true,
    "contact": {
      "displayName": "Jane Tester",
      "firstName": "Jane",
      "lastName": "Tester",
      "avatar": "https://example.com/avatar.png",
      "languages": [
        "en",
        "nl"
      ],
      "country": "NL",
      "timezone": "UTC+1",
      "gender": "f",
      "attributes": {
        "membership": "platinum",
        "status": "vip",
        "customerNumber": "1234"
      }
    }
  }'
`,
};

export const MERGECONTACT_RESP = CONTACT_OBJ;

export const UPDATEORCREATECONTACT_RESP = {
  json: JSON.stringify(
    {
      contactId: 'f4a4af42f9894b56b6f7b3418443b2e1',
      created: true,
    },
    null,
    '\t',
  ),
};
