import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`The structure of a call`}</h1>
    <p>{`To understand how Messagebird handles calls, it can be helpful to be familiar with the general structure of how calls are created. This article will provide a short example to help lay the foundations of the terms that will be most helpful to understand how Messagebird fulfills calls and will provide links to documentation and other articles where you can dig deeper on the concepts touched on here.`}</p>
    <h2>{`An example with virtual phone numbers`}</h2>
    <p>{`A customer wants to call a customer service number for a support request, and dials the number listed online. They place the call and are transferred to the line of an agent who works at the company. They speak for a while and, having resolved the matter, both parties hang up.`}</p>
    <p>{`This interaction describes a common use case that Messagebird's `}<a parentName="p" {...{
        "href": "https://messagebird.com/en/flow-builder/"
      }}>{`FlowBuilder`}</a>{` helps to automate and one that we're used to from everyday life, but let's unpack it from the perspective of the Messagebird Voice API to highlight some key concepts.`}</p>
    <h2>{`Describing the structure`}</h2>
    <h3>{`1. Placing the call & the first leg`}</h3>
    <p>{`When the customer places the call, the customer's phone service provider directs the call to the destination number specified. In the event that this is a virtual number provided by Messagebird, we will receive the call and begin the process of handling it. During this phase, a `}<a parentName="p" {...{
        "href": "https://developers.messagebird.com/api/voice-calling/#legs"
      }}>{`leg`}</a>{` with an `}<inlineCode parentName="p">{`inbound`}</inlineCode>{` direction will be created, representing the customer's original call placed to the `}<inlineCode parentName="p">{`destination`}</inlineCode>{` number.`}</p>
    <p>{`When the leg is first created, it will proceed through various stages that represent the user's real-time progression through the call. This begins with a `}<inlineCode parentName="p">{`starting`}</inlineCode>{` status, followed by `}<inlineCode parentName="p">{`ringing`}</inlineCode>{` and finally `}<inlineCode parentName="p">{`ongoing`}</inlineCode>{` when the call has been picked up. It will remain in the ongoing state until some concluding event occurs like a `}<inlineCode parentName="p">{`hangup`}</inlineCode>{`. Likewise, the call status as a whole will also undergo status updates that reflect the call's real-time progress. This information can be accessed during a call with the assistance of the  `}<a parentName="p" {...{
        "href": "https://developers.messagebird.com/api/voice-calling/#voice-calling-api"
      }}>{`Voice API`}</a>{`, and a full list of statuses for both calls and legs can be found  `}<a parentName="p" {...{
        "href": "https://developers.messagebird.com/quickstarts/voice-calling/call-and-leg-statuses"
      }}>{`in this article`}</a>{`.`}</p>
    <h3>{`2. Call Flow execution`}</h3>
    <p>{`To determine what should happen next for the inbound call, Messagebird executes the `}<a parentName="p" {...{
        "href": "https://developers.messagebird.com/api/voice-calling/#call-flows"
      }}>{`Call Flow`}</a>{` associated with the number.`}</p>
    <p>{`The actions that should be taken are defined by the `}<a parentName="p" {...{
        "href": "https://developers.messagebird.com/api/voice-calling/#steps"
      }}>{`steps`}</a>{` property of the Call Flow which can either be done in FlowBuilder or via the Voice API. In the case described above, the only step will be a `}<inlineCode parentName="p">{`transfer`}</inlineCode>{` to the terminating number of the agent who should receive the call.`}</p>
    <h3>{`3. Transfer step & the second leg`}</h3>
    <p>{`When Messagebird executes the transfer step to the new `}<inlineCode parentName="p">{`destination`}</inlineCode>{` number, a new leg with an `}<inlineCode parentName="p">{`outbound`}</inlineCode>{` direction gets created as we initiate the transfer of call from the virtual number to the new target. The agent receives this call and the client and agent have their conversation.`}</p>
    <h3>{`4. Hangup`}</h3>
    <p>{`When the call ends, the status of the legs involved in the call will all be updated to `}<inlineCode parentName="p">{`hangup`}</inlineCode>{`, and the call status as a whole will be marked as `}<inlineCode parentName="p">{`ended`}</inlineCode>{`.`}</p>
    <h2>{`Review of terms`}</h2>
    <p>{`Many terms were outlined in the above example. Here's a quick reminder of each:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Term`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Meaning`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Call`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The highest-level container to describe a telephone conversation or flow. Is constituted by each of its individual Legs`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Leg`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A segment of a call between a number and the Messagebird platform.  Each leg taken as a collection will represent a Call.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Call Source`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number that originates the call.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Call Destination`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number or virtual number that is to receive the call.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Leg Direction`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Leg Direction describes the telephone event from the perspective of the Messagebird Voice API. An `}<inlineCode parentName="td">{`inbound`}</inlineCode>{` directed leg is one that is placed to a Messagebird virtual number or from a SIP trunk to the MessageBird platform and an `}<inlineCode parentName="td">{`outbound`}</inlineCode>{` directed leg is one placed from the MessageBird platform to the PSTN or SIP destination.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Call Status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The status of the call as a whole.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Leg Status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The status of each leg that comprises the call.`}</td>
        </tr>
      </tbody>
    </table>
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'Call and Leg Statuses',
      to: '/quickstarts/voice-calling/call-and-leg-statuses'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      