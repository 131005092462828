export const MMS_OBJECT = {
  json: `{
  "id": "efa6405d518d4c0c88cce11f7db775fb",
  "href": "https://rest.messagebird.com/mms/efa6405d518d4c0c88cce11f7db775fb",
  "direction": "mt",
  "originator": "+31207009850",
  "subject": "Great logo",
  "body": "Hi! Please have a look at this very nice logo of this cool company.",
  "reference": "the-customers-reference",
  "mediaUrls": [
    "https://developers.messagebird.com/img/logos/mb-400.jpg"
  ],
  "scheduledDatetime": null,
  "createdDatetime": "2017-09-01T10:00:00+00:00",
  "recipients": {
    "totalCount": 1,
    "totalSentCount": 1,
    "totalDeliveredCount": 0,
    "totalDeliveryFailedCount": 0,
    "items": [
      {
        "recipient": 31612345678,
        "status": "sent",
        "statusDatetime": "2017-09-01T10:00:00+00:00"
      }
    ]
  }
}`,
};

export const MMS_URI = {
  bash: `https://rest.messagebird.com/mms`,
};

export const MMS_METHODS = {
  bash: `POST /mms
GET /mms
GET /mms/{messageId}
DELETE /mms/{messageId}`,
};

export const SMS_AUTH1 = {
  json: `{
  "id": "efa6405d518d4c0c88cce11f7db775fb",
  "href": "https://rest.messagebird.com/mms/efa6405d518d4c0c88cce11f7db775fb",
  "direction": "mt",
  "originator": "+31207009850",
  "subject": "Great logo",
  "body": "Hi! Please have a look at this very nice logo of this cool company.",
  "reference": "the-customers-reference",
  "mediaUrls": [
    "https://developers.messagebird.com/img/logos/mb-400.jpg"
  ],
  "scheduledDatetime": null,
  "createdDatetime": "2017-09-01T10:00:00+00:00",
  "recipients": {
    "totalCount": 1,
    "totalSentCount": 1,
    "totalDeliveredCount": 0,
    "totalDeliveryFailedCount": 0,
    "items": [
      {
        "recipient": 31612345678,
        "status": "sent",
        "statusDatetime": "2017-09-01T10:00:00+00:00"
      }
    ]
  }
}`,
};

export const VIEWMMS_DEF = {
  bash: `GET https://rest.messagebird.com/mms/{messageId}`,
};

export const VIEWMMS_REQUEST = {
  curl: `curl -X GET https://rest.messagebird.com/mms/e8077d803532c0b5937c639b60216938 \\
   -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM'`,
  node: `var messagebird = require('messagebird')('YOUR_ACCESS_KEY');

messagebird.mms.read('efa6405d518d4c0c88cce11f7db775fb', function (err, response) {
  if (err) {
    return console.log(err);
  }
  console.log(response);
});`,
};

export const VIEWMMS_RESPONSE = {
  json: `{
  "id": "efa6405d518d4c0c88cce11f7db775fb",
  "href": "https://rest.messagebird.com/mms/efa6405d518d4c0c88cce11f7db775fb",
  "direction": "mt",
  "originator": "YourName",
  "subject": "Check out this cool MMS",
  "body": "Have you seen this logo?",
  "reference": null,
  "mediaUrls": [
    "https://developers.messagebird.com/img/logos/mb-400.jpg"
  ],
  "scheduledDatetime": null,
  "createdDatetime": "2017-09-01T10:00:00+00:00",
  "recipients": {
    "totalCount": 1,
    "totalSentCount": 0,
    "totalDeliveredCount": 1,
    "totalDeliveryFailedCount": 0,
    "items": [
      {
        "recipient": 31612345678,
        "status": "delivered",
        "statusDatetime": "2017-09-01T10:00:01+00:00"
      }
    ]
  }
}`,
};

export const SENDMMS_DEF = {
  bash: `POST https://rest.messagebird.com/mms`,
};

export const SENDMMS_REQUEST = {
  curl: `curl -X POST https://rest.messagebird.com/mms \\
   -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
   -d "recipients=31612345678" \\
   -d "originator=YourName" \\
   -d "subject=Check out this cool MMS" \\ 
   -d "body=Have you seen this logo?" \\
   -d "mediaUrls[]=https://developers.messagebird.com/img/logos/mb-400.jpg"`,
  node: `var messagebird = require('messagebird')('YOUR_ACCESS_KEY');

var params = {
  'recipients': '31612345678',
  'originator': 'YourName',
  'subject': 'Check out this cool MMS',
  'body': 'Have you seen this logo?',
  'mediaUrls': ['https://developers.messagebird.com/img/logos/mb-400.jpg']
};

messagebird.mms.create(params, function (err, response) {
  if (err) {
    return console.log(err);
  }
  console.log(response);
});`,
};

export const SENDMMS_RESPONSE = {
  json: `{
  "id": "efa6405d518d4c0c88cce11f7db775fb",
  "href": "https://rest.messagebird.com/mms/efa6405d518d4c0c88cce11f7db775fb",
  "direction": "mt",
  "originator": "YourName",
  "subject": "Check out this cool MMS",
  "body": "Have you seen this logo?",
  "reference": null,
  "mediaUrls": [
    "https://developers.messagebird.com/img/logos/mb-400.jpg"
  ],
  "scheduledDatetime": null,
  "createdDatetime": "2017-09-01T10:00:00+00:00",
  "recipients": {
    "totalCount": 1,
    "totalSentCount": 1,
    "totalDeliveredCount": 0,
    "totalDeliveryFailedCount": 0,
    "items": [
      {
        "recipient": 31612345678,
        "status": "sent",
        "statusDatetime": "2017-09-01T10:00:00+00:00"
      }
    ]
  }
}`,
};

export const RECMMS_REQUEST = {
  bash: `POST id=a5f3ca18-2935-11e7-ad46-08002720e7b4&originator=1123456789&recipient=1987654321&subject=MMS+reply&body=View+our+logo&mediaUrls[0]=https://storage.googleapis.com/mms-assets/20170424/a0b40b77-30f8-4603-adf1-00be9321885b-messagebird.png&mediaContentTypes[0]=image/png&createdDatetime=2017-04-24T20:15:30+00:00`,
};

export const STATUSMMS_REQUEST = {
  bash: `GET http://your-own.url/script?id=efa6405d518d4c0c88cce11f7db775fb&reference=the-customers-reference&recipient=31612345678&status=delivered&statusDatetime=2017-09-01T10:00:05+00:00`,
};

export const STATUSMMS_RESPONSE = {
  bash: `200 OK`,
};
