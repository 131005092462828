import styled from 'styled-components';
import { COLOR } from '../../../constants';

export const Heading = styled.h3`
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  color: ${COLOR.DARKGREY};
  margin-bottom: 24px;
`;
