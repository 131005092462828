import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { LangContext } from '../../contexts/LanguageContext';
import DocsContent from '../DocsContent';
import MobileNavigation from '../MobileNavigation';

const QuickStarts: React.FC<QuickStartsLayout> = ({ children, location }) => {
  const { language, availableLanguages } = useContext(LangContext);

  const data = useStaticQuery(
    graphql`
      query QuickstartsQuery {
        allSitePage(filter: { path: { regex: "/quickstarts/" } }) {
          edges {
            node {
              path
            }
          }
        }
      }
    `,
  );

  const allNodes = data.allSitePage.edges.map(({ node: { path } }) => path);

  return (
    <>
      <MobileNavigation languages={availableLanguages} />
      <DocsContent allDocs={allNodes} language={language} location={location}>
        {children}
      </DocsContent>
    </>
  );
};

interface QuickStartsLayout {
  children: React.ReactNode;
  location: string;
}

export default QuickStarts;
