import { Link } from 'gatsby';
import ProductContentPage from 'components/ProductContentPage';
import ContentBlock from 'components/ContentBlock';
import { Paragraph, H2 } from 'components/Typography';
import CodeSnippet from 'components/CodeSnippet';
import * as CODE from "../../../../src/constants/sms-batches";
import Notification from 'components/Notification';
import { APIWrapper, LeftColumn, RightColumn } from 'components/ApiRef';
import * as React from 'react';
export default {
  Link,
  ProductContentPage,
  ContentBlock,
  Paragraph,
  H2,
  CodeSnippet,
  CODE,
  Notification,
  APIWrapper,
  LeftColumn,
  RightColumn,
  React
};