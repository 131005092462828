import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/Tutorials.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Setting SMS reminders with MessageBird`}</h1>
    <h3>{`⏱ 15 min build time      ||      `}<a parentName="h3" {...{
        "href": "https://github.com/messagebirdguides/reminders-guide-ruby"
      }}>{`Download the Code`}</a></h3>
    <h2>{`Why build SMS appointment reminders?`}</h2>
    <p>{`In this MessageBird Developer Tutorial, you’ll learn how to build an SMS appointment reminder application and improve your users’ experience with the `}<a parentName="p" {...{
        "href": "/api/sms-messaging"
      }}>{`MessageBird SMS Messaging API`}</a>{`.`}</p>
    <p>{`Booking appointments online from a website or mobile app is quick and easy. Customers just have to select their desired date and time, personal details and hit a button. The problem, however, is that easy-to-book appointments are often just as easy to forget.`}</p>
    <p>{`For appointment-based services, no-shows are annoying and costly because of the time and revenue lost waiting for a customer instead of serving them or another customer. Timely SMS reminders simple and discrete nudges, which can go a long way in the prevention of costly no-shows.`}</p>
    <h2>{`Getting started`}</h2>
    <p>{`This sample application is built in Ruby and represents the order website for our fictitious online beauty salon, BeautyBird. To reduce the growing number of no-shows, BeautyBird now collects appointment bookings through a form on their website and schedules timely SMS reminders to be sent out three hours before the selected date and time.`}</p>
    <p>{`To look at the full sample application or run it on your computer, go to the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/reminders-guide-ruby"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`; you can either clone it or download and extract it a ZIP archive. Keep in mind that you’ll need `}<a parentName="p" {...{
        "href": "https://www.ruby-lang.org/en/"
      }}>{`Ruby`}</a>{` and `}<a parentName="p" {...{
        "href": "https://bundler.io/"
      }}>{`bundler`}</a>{` to run the example.`}</p>
    <p>{`Then, open a console pointed at the directory into which you've placed the sample application and run the following command to install the MessageBird SDK for Ruby and other dependencies:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`bundle install
`}</code></pre>
    <h2>{`Configuring the MessageBird SDK`}</h2>
    <p>{`The SDK is loaded with the following lines in `}<inlineCode parentName="p">{`app.rb`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`require 'dotenv'
require 'sinatra'
require 'messagebird'

set :root, File.dirname(__FILE__)
Dotenv.load if Sinatra::Base.development?

client = MessageBird::Client.new(ENV['MESSAGEBIRD_API_KEY'])
`}</code></pre>
    <p>{`The MessageBird API key needs to be provided as a parameter.`}</p>
    <p><strong parentName="p">{`Pro-tip:`}</strong>{` Hardcoding your credentials is a risky practice that should never be used in production applications. A better method, also recommended by the `}<a parentName="p" {...{
        "href": "https://12factor.net/"
      }}>{`Twelve-Factor App Definition`}</a>{`, is to use environment variables.`}</p>
    <p>{`We've added `}<a parentName="p" {...{
        "href": "https://rubygems.org/gems/dotenv"
      }}>{`dotenv`}</a>{` to the sample application, so you can supply your API key in a file named `}<inlineCode parentName="p">{`.env`}</inlineCode>{`. You can copy the provided file `}<inlineCode parentName="p">{`env.example`}</inlineCode>{` to `}<inlineCode parentName="p">{`.env`}</inlineCode>{` and add your API key like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`MESSAGEBIRD_API_KEY=YOUR-API-KEY
`}</code></pre>
    <p>{`API keys can be created or retrieved from the API access (REST) tab in the Developers section of your MessageBird Dashboard.`}</p>
    <h2>{`Collecting user input`}</h2>
    <p>{`To send SMS messages to users, you need to collect their phone number as part of the booking process. We’ve created a sample form that asks the user for their name, desired treatment, number, date and time. For HTML forms it's recommended to use `}<inlineCode parentName="p">{`type="tel"`}</inlineCode>{` for the phone number input. You can see the template for the complete form in the file `}<inlineCode parentName="p">{`views/home.erb`}</inlineCode>{` and the route that drives it is defined as `}<inlineCode parentName="p">{`get '/'`}</inlineCode>{` in `}<inlineCode parentName="p">{`app.rb`}</inlineCode>{`.`}</p>
    <h2>{`Storing appointments and scheduling reminders`}</h2>
    <p>{`The user's input is sent to the route `}<inlineCode parentName="p">{`post '/book'`}</inlineCode>{` defined in `}<inlineCode parentName="p">{`app.rb`}</inlineCode>{`. The implementation covers the following steps:`}</p>
    <h3>{`Step 1: Check their input`}</h3>
    <p>{`Validate that the user has entered a value for every field in the form.`}</p>
    <h3>{`Step 2: Check the appointment date and time`}</h3>
    <p>{`Confirm that the date and time are valid and at least three hours and five minutes in the future—BeautyBird won't take bookings on shorter notice. Also, since we want to schedule reminders three hours before the treatment, anything else doesn't make sense from a testing perspective.`}</p>
    <p>{`We recommend using a library such as `}<a parentName="p" {...{
        "href": "https://rubygems.org/gems/activesupport"
      }}><inlineCode parentName="a">{`activesupport/duration`}</inlineCode></a>{`, which makes working with date and time calculations a breeze. Don't worry, we've already integrated it into our sample application:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`# Check if date/time is correct and at least 3:05 hours in the future
earliest_possible_dt = Time.now + 3.hours + 5.minutes
appointment_dt = DateTime.parse("#{params[:date]} #{params[:time]}")
if (appointment_dt < earliest_possible_dt)
 # If not, show an error
 # ...
`}</code></pre>
    <h2>{`Step 3: Check their phone number`}</h2>
    <p>{`Check whether the phone number is correct. This can be done with the `}<a parentName="p" {...{
        "href": "/api/lookup#lookup-request"
      }}>{`MessageBird Lookup API`}</a>{`, which takes a phone number entered by a user, validates the format and returns information about the number, such as whether it is a mobile or fixed line number. This API doesn't enforce a specific format for the number but rather understands a variety of different variants for writing a phone number, for example using different separator characters between digits, giving your users the flexibility to enter their number in various ways. In the SDK, you can call `}<inlineCode parentName="p">{`client.lookup`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`# Check if phone number is valid
lookup = messagebird.lookup(params[:number], countryCode: process.env.COUNTRY_CODE)
`}</code></pre>
    <p>{`The function takes two arguments: the phone number and a country code. Providing a default country code enables users to supply their number in a local format, without the country code.`}</p>
    <p>{`To add a country code, add the following line to you `}<inlineCode parentName="p">{`.env`}</inlineCode>{` file, replacing NL with your own ISO country code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`COUNTRY_CODE=NL
`}</code></pre>
    <p>{`In the `}<inlineCode parentName="p">{`lookup`}</inlineCode>{` response, we handle four different cases:`}</p>
    <ul>
      <li parentName="ul">{`An error (code 21) occurred, which means MessageBird was unable to parse the phone number.`}</li>
      <li parentName="ul">{`Another error code occurred, which means something else went wrong in the API.`}</li>
      <li parentName="ul">{`No error occurred, but the value of the response type attribute is something other than mobile.`}</li>
      <li parentName="ul">{`Everything is OK, which means a mobile number was provided successfully.`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`begin
 locals = {
   name: params[:name],
   treatment: params[:treatment],
   number: params[:number],
   date: params[:date],
   time: params[:time]
 }

 lookup_response = client.lookup(params[:number], countryCode: ENV['COUNTRY_CODE'])

 if lookup_response.type != "mobile" # The number lookup was successful but it is not a mobile number
   locals[:errors] = "You have entered a valid phone number, but it's not a mobile number! Provide a mobile number so we can contact you via SMS."
   return erb :home, locals: locals
 else # Everything OK

 end
rescue MessageBird::InvalidPhoneNumberException => ex
 # This error code indicates that the phone number has an unknown format
 locals[:errors] = "You need to enter a valid phone number!"
 return erb :home, locals: locals
rescue MessageBird::ErrorException => ex
 # Some other error occurred
 locals[:errors] = "Something went wrong while checking your phone number!"

 return erb :home, locals: locals
end
`}</code></pre>
    <p>{`The implementation for the following steps is contained within the `}<inlineCode parentName="p">{`Everything OK`}</inlineCode>{` block.`}</p>
    <h2>{`Step 4: Schedule the reminder`}</h2>
    <p>{`Using `}<inlineCode parentName="p">{`activesupport`}</inlineCode>{`, we can easily specify the time for our reminder:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`#Schedule reminder 3 hours prior to the treatment
reminder_dt = appointment_dt - 3.hours
`}</code></pre>
    <p>{`Now it's time to call MessageBird's API:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`# Send scheduled message with MessageBird API
body = "#{params[:name]}, here's a reminder that you have a #{params[:treatment]} scheduled for #{appointment_dt.strftime('%H:%M')}. See you soon!"
message_response = client.message_create("BeautyBird", [lookup_response.phoneNumber], body, scheduledDatetime: appointment_dt)
`}</code></pre>
    <p>{`Let's break down the parameters that are set with this call of `}<inlineCode parentName="p">{`client.message_create`}</inlineCode>{`:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`originator`}</inlineCode>{`: This is the first parameter. It represents the sender ID. You can use a mobile number here, or an alphanumeric ID, like in the example.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`recipients`}</inlineCode>{`: This is the second parameter. It's an array of phone numbers. We just need one number, and we're using the normalized number returned from the Lookup API instead of the user-provided input.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`body`}</inlineCode>{`: This is the hird parameter. It's the friendly text for the message.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`scheduledDatetime`}</inlineCode>{`: This is one of the many options you can pass as a Hash. It instructs MessageBird not to send the message immediately but at a given timestamp, which we've defined previously.`}</li>
    </ul>
    <h2>{`Step 5: Store the appointment`}</h2>
    <p>{`The application's logic continues with the `}<inlineCode parentName="p">{`message_response`}</inlineCode>{`, where we need to handle both success and error cases:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`begin
 message_response = client.message_create("BeautyBird", [lookup.phoneNumber], body, scheduledDatetime: appointment_dt)

 # Request was successful
 puts message_response

 # Create and persist appointment object
 appointment = {
   name: params[:name],
   treatment: params[:treatment],
   number: params[:number],
   appointment_dt: appointment_dt.strftime('%Y-%m-%d HH:mm'),
   reminder_dr: reminder_dt.strftime('%Y-%m-%d %H:%M')
 }
 appointment_database << appointment

 # Render confirmation page
 return erb :confirm, locals: { appointment: appointment }
rescue MessageBird::ErrorException => ex
 errors = ex.errors.each_with_object([]) do |error, memo|
   memo << "Error code #{error.code}: #{error.description}"
 end.join("\\n")

 return erb :home, locals: { errors: errors }
end
`}</code></pre>
    <p>{`For the purpose of the sample application, we simply "persist" the appointment to a global variable in memory.In producction applications, you would write the appointment to a persistence layer such as a file or database. We also show a confirmation page, which is defined in `}<inlineCode parentName="p">{`views/confirm.erb`}</inlineCode>{`.`}</p>
    <h2>{`Testing`}</h2>
    <p>{`You’re done! To test your application, let's run the following command from your console:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`ruby app.rb
`}</code></pre>
    <p>{`Then, point your browser at `}<inlineCode parentName="p">{`localhost:4567`}</inlineCode>{` to see the form and schedule your appointment! If you've used a live API key, a message will arrive to your phone three hours before the appointment! But don't actually leave the house, this is just a demo. 😜`}</p>
    <p>{`Awesome! You can now use the flow, code snippets and UI examples from this tutorial as an inspiration to build your own SMS reminder system. Don't forget to download the code from the `}<a parentName="p" {...{
        "href": "https://github.com/messagebirdguides/reminders-guide-ruby"
      }}>{`MessageBird Developer Tutorials GitHub repository`}</a>{`.`}</p>
    <p><strong parentName="p">{`Nice work!`}</strong>{` 🎉`}</p>
    <p>{`You now have a running SMS appointment reminder application with MessageBird using Ruby!`}</p>
    <h2>{`Start building!`}</h2>
    <p>{`Want to start building your solution but not quite sure how to get started? Feel free to let us know at `}<a parentName="p" {...{
        "href": "mailto:support@messagebird.com"
      }}>{`support@messagebird.com`}</a>{`; we'd love to help!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      