import styled from 'styled-components';
import { Link } from 'gatsby';

export const HeaderWrap = styled.header`
  min-height: 72px;
  margin: 0 auto 72px;
  margin-bottom: ${(props) =>
    props.location && ['/', '/docs/'].includes(props.location.pathname)
      ? '0'
      : '72px'};

  max-width: 1008px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @media (max-width: 767px) {
    display: none;
  }
`;

export const Menu = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MenuItem = styled(Link)`
  margin-left: 20px;
  margin-right: 20px;
  font-weight: normal;
  color: #24374e;
  &:hover {
    color: #0089ff;
  }
  &:active {
    font-weight: bold;
  }
`;
