import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import { Link } from 'gatsby';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Img = makeShortcode("Img");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`How to create a Media Template`}</h1>
    <p>{`This quickstart guide will help you create more advanced templates via `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/whatsapp-template-manager/"
      }}>{`WhatsApp Template Manager`}</a>{`.
Make sure to read through `}<Link to="/quickstarts/whatsapp/message-templates-curl" mdxType="Link">{`Media Templates Quickstart`}</Link>{`
if you are not familiar with the concept of whatsapp template messages.`}</p>
    <p>{`Media templates are templates provided by Whatsapp that expand the content that you can send
to customers by including components. The components provided are:`}</p>
    <ul>
      <li parentName="ul">{`HEADER: Can be either text or media. The text and the media can have parameters.`}</li>
      <li parentName="ul">{`BODY: The main content of the template. Can have parameters.`}</li>
      <li parentName="ul">{`FOOTER: Extra text on the bottom of your message. Cannot have parameters.`}</li>
      <li parentName="ul">{`BUTTONS: Can be a quick reply (without parameters) or a call to action - visit url (with parameters) or call a number (without parameters).`}</li>
    </ul>
    <p>{`The templates go through Facebook and will be activated after it is confirmed that they don't violate Facebook's policy. In order to speed up the process and provide clarity, Facebook recommends providing
`}<inlineCode parentName="p">{`examples`}</inlineCode>{` which is also supported by Messagebird API and WhatsApp Template Manager.`}</p>
    <h2>{`Quick Replies`}</h2>
    <Img src="/img/screenshots/quickstarts/whatsapp/new-quick-replies.png" alt="Media template with buttons" width="800px" align="middle" mdxType="Img" />
    <h2>{`Call To Action`}</h2>
    <Img src="/img/screenshots/quickstarts/whatsapp/new-call-to-action.png" alt="Media template with buttons" width="800px" align="middle" mdxType="Img" />
    <h2>{`Media Header template`}</h2>
    <h3>{`Image media template`}</h3>
    <Img src="/img/screenshots/quickstarts/whatsapp/new-image-media-template.png" alt="Media template with buttons" width="800px" align="middle" mdxType="Img" />
    <h3>{`Video media template`}</h3>
    <Img src="/img/screenshots/quickstarts/whatsapp/new-video-media-template.png" alt="Media template with buttons" width="800px" align="middle" mdxType="Img" />
    <h3>{`Document media template`}</h3>
    <Img src="/img/screenshots/quickstarts/whatsapp/new-document-media-template.png" alt="Media template with buttons" width="800px" align="middle" mdxType="Img" />
    <p>{`For more information about Messagebird API, check out the `}<Link to="/api/integrations/#create-template" mdxType="Link">{`API Reference`}</Link></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      