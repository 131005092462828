export const GROUPS_URI = {
  bash: `https://rest.messagebird.com/groups`,
};

export const GROUPS_METHODS = {
  bash: `POST /groups
GET /groups
GET /groups/{groupId}
GET /groups/{groupId}/contacts
PATCH /groups/{groupId}
PUT /groups/{groupId}/contacts
DELETE /groups/{groupId}
DELETE /groups/{groupId}/contacts/{contactId}`,
};

export const GROUPS_OBJECT = {
  json: `{
  "id": "61afc0531573b08ddbe36e1c85602827",
  "href": "https://rest.messagebird.com/groups/61afc0531573b08ddbe36e1c85602827",
  "name": "Home",
  "contacts": {
    "totalCount": 0,
    "href": "https://rest.messagebird.com/groups/61afc0531573b08ddbe36e1c85602827/contacts"
  },
  "createdDatetime": "2016-04-29T09:42:26+00:00",
  "updatedDatetime": "2016-04-29T09:42:26+00:00"
}`,
};

export const CREATEGROUPS_DEF = {
  bash: `POST https://rest.messagebird.com/groups`,
};

export const CREATEGROUPS_REQUEST = {
  curl: `curl -X POST https://rest.messagebird.com/groups \\ 
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -d "name=Home"`,
};

export const CREATEGROUPS_RESPONSE = {
  json: `{
  "id": "61afc0531573b08ddbe36e1c85602827",
  "href": "https://rest.messagebird.com/groups/61afc0531573b08ddbe36e1c85602827",
  "name": "Home",
  "contacts": {
    "totalCount": 0,
    "href": "https://rest.messagebird.com/groups/61afc0531573b08ddbe36e1c85602827/contacts"
  },
  "createdDatetime": "2016-04-29T09:42:26+00:00",
  "updatedDatetime": "2016-04-29T09:42:26+00:00"
}`,
};

export const VIEWGROUPS_DEF = {
  bash: `GET https://rest.messagebird.com/groups/{groupId}`,
};

export const VIEWGROUPS_REQUEST = {
  curl: `curl -X GET https://rest.messagebird.com/groups/61afc0531573b08ddbe36e1c85602827 \\
   -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM'`,
};

export const VIEWGROUPS_RESPONSE = {
  json: `{
  "id": "61afc0531573b08ddbe36e1c85602827",
  "href": "https://rest.messagebird.com/groups/61afc0531573b08ddbe36e1c85602827",
  "name": "Home",
  "contacts": {
    "totalCount": 0,
    "href": "https://rest.messagebird.com/groups/61afc0531573b08ddbe36e1c85602827/contacts"
  },
  "createdDatetime": "2016-04-29T09:42:26+00:00",
  "updatedDatetime": "2016-04-29T09:42:26+00:00"
}`,
};

export const UPDATEGROUPS_DEF = {
  bash: `PATCH https://rest.messagebird.com/groups/{groupId}`,
};

export const UPDATEGROUPS_REQUEST = {
  curl: `curl -X PATCH https://rest.messagebird.com/groups/61afc0531573b08ddbe36e1c85602827 \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\
  -d "name=Work"`,
};

export const UPDATEGROUPS_RESPONSE = {
  json: `{
  "id": "61afc0531573b08ddbe36e1c85602827",
  "href": "https://rest.messagebird.com/groups/61afc0531573b08ddbe36e1c85602827",
  "name": "Work",
  "contacts": {
    "totalCount": 0,
    "href": "https://rest.messagebird.com/groups/61afc0531573b08ddbe36e1c85602827/contacts"
  },
  "createdDatetime": "2016-04-29T09:42:26+00:00",
  "updatedDatetime": "2016-04-29T09:42:26+00:00"
}`,
};

export const ADDTOGROUPS_DEF = {
  bash: `PUT  https://rest.messagebird.com/groups/{groupId}/contacts`,
};

export const ADDTOGROUPS_REQUEST = {
  curl: `curl -X PUT https://rest.messagebird.com/groups/61afc0531573b08ddbe36e1c85602827/contacts \\ 
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM' \\ 
  -d "ids[]=38afc3b2156e00498764d21c57738631" \\
  -d "ids[]=9a0ca1f2156e0028f511af2c13800145"`,
};

export const ADDTOGROUPS_RESPONSE = {
  bash: `[empty]`,
};

export const REMOVEFROMGROUPS_DEF = {
  bash: `DELETE https://rest.messagebird.com/groups/{groupId}/contacts/{contactId}`,
};

export const REMOVEFROMGROUPS_REQUEST = {
  curl: `curl -X DELETE https://rest.messagebird.com/groups/61afc0531573b08ddbe36e1c85602827/contacts/38afc3b2156e00498764d21c57738631 \\ 
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM'`,
};

export const REMOVEFROMGROUPS_RESPONSE = {
  bash: `[empty]`,
};

export const DELETEGROUPS_DEF = {
  bash: `DELETE https://rest.messagebird.com/groups/{groupId}`,
};

export const DELETEGROUPS_REQUEST = {
  curl: `curl -X DELETE https://rest.messagebird.com/groups/61afc0531573b08ddbe36e1c85602827 \\ 
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM'`,
};

export const DELETEGROUPS_RESPONSE = {
  bash: `[empty]`,
};

export const LISTGROUPS_DEF = {
  bash: `GET https://rest.messagebird.com/groups`,
};

export const LISTGROUPS_REQUEST = {
  bash:
    "curl -X GET https://rest.messagebird.com/groups?offset=0&limit=10 \\\n \
      -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM'",
};
