import { useContext, useEffect } from 'react';
import LocationContext from '../contexts/LocationContext';
import {
  apisNavigation,
  quickStartsNavigation,
} from '../components/Sidebar/constants';

// Get array of sidebar links
const extractActiveOnLinks = (item) => {
  const childActiveOnLinks = item.subMenu
    ? item.subMenu.flatMap((subItem) => extractActiveOnLinks(subItem))
    : [];
  return [item.route, ...childActiveOnLinks];
};
const extractNames = (item) => {
  const childActiveOnLinks = item.subMenu
    ? item.subMenu.flatMap((subItem) => extractActiveOnLinks(subItem))
    : [];
  return [item.name, ...childActiveOnLinks];
};

const quickstartsSidebarItemsUrls = quickStartsNavigation.flatMap((item) =>
  extractActiveOnLinks(item),
);
const apiSidebarItemsUrls = apisNavigation.map((item) => item.route);

const getItemName = (url) => {
  const array = url.split('/').filter((item) => item !== '');
  const name = array[array.length - 1]
    .split('-')
    .map((item) =>
      item === 'curl' ? '' : item.charAt(0).toUpperCase() + item.slice(1),
    )
    .join(' ');
  return name;
};

const useNextSidebarItem = () => {
  const { hash, pathname } = useContext(LocationContext) || {};
  const isQuickstartsPage = pathname.includes('/quickstarts');
  const isAPIpage = pathname.includes('/api');
  const getURLs = () => {
    if (isQuickstartsPage) {
      return quickstartsSidebarItemsUrls;
    } else if (isAPIpage) {
      return apiSidebarItemsUrls;
    } else {
      return [];
    }
  };
  const currentItemIndex = getURLs().indexOf(pathname + hash);
  const url = getURLs()[currentItemIndex + 1];
  if (url) {
    return {
      name: getItemName(url),
      url: url,
    };
  }
};
export default useNextSidebarItem;
