export const PD_METHODS = {
  json: `POST /personaldata/get (deprecated)
POST /personaldata/delete (deprecated)
GET /personaldata/list (deprecated)
GET /personaldata/requests
POST /personaldata/requests`,
};

export const PD_EXAMPLE1 = {
  json: `curl -H "Content-Type: application/json" 
-H Authorization: AccessKey your-key-here' 
-X POST -d '{"field":"phone_number","value":"+3162372342","reason":"testing 123","kind":"KIND_PERSONAL_GET"}' https://rest.messagebird.com/personaldata/requests`,
};

export const PD_EXAMPLE2 = {
  json: `curl -H "Content-Type: application/json" 
-H 'Authorization: AccessKey your-key-here' 
-X POST -d '{"reason":"testing 123","kind":"KIND_USER_GET"}' https://rest.messagebird.com/personaldata/requests`,
};

export const PD_RESPONSE = {
  json: `201 Created`,
};

export const PD_HEADER = {
  json: `t=1527080073,v1=1239e7e23c3e8b12aebbfc4b34fea7b989cd5002398995f5f056d5edfb62e009`,
};

export const PD_HEADER1 = {
  json: `<timestamp>.<request body>`,
};
