import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { LangContext } from '../../contexts/LanguageContext';
import PageHeadersContext from '../../contexts/PageHeadersContext';
import DocsContent from '../DocsContent';
import MobileNavigation from '../MobileNavigation';
import { getDuplicatesId, getAllIds } from '../../helpers/HeadersFinder';

const Docs: React.FC<DocsLayout> = ({ children, location }) => {
  const { language } = useContext(LangContext);
  const { setDuplicateIds, setHeadersIds } = useContext(PageHeadersContext);

  useEffect(() => {
    const allHeaders = getAllIds(children);
    const duplicatesAnchors = getDuplicatesId(allHeaders);
    setDuplicateIds(duplicatesAnchors);
    setHeadersIds(allHeaders);
  }, []);

  const data = useStaticQuery(
    graphql`
      query DocQuery {
        allSitePage(filter: { path: { regex: "/docs/" } }) {
          edges {
            node {
              path
            }
          }
        }
      }
    `,
  );

  const allNodes = data.allSitePage.edges.map(({ node: { path } }) => path);

  return (
    <>
      <MobileNavigation />
      <DocsContent allDocs={allNodes} language={language} location={location}>
        {children}
      </DocsContent>
    </>
  );
};
interface DocsLayout {
  children: React.ReactNode;
  location: string;
}

export default Docs;
