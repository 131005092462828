export const BALANCE_URI = {
  bash: `https://rest.messagebird.com/balance`,
};

export const BALANCE_METHODS = {
  bash: `GET /balance`,
};

export const BALANCE_OBJECT = {
  json: `{
  "payment": "prepaid",
  "type": "euros",
  "amount": 103
}`,
};

export const GETBALANCE_REQUEST = {
  curl: `curl -X GET https://rest.messagebird.com/balance \\
  -H 'Authorization: AccessKey test_gshuPaZoeEG6ovbc8M79w0QyM'`,
  php: `$MessageBird = new \\MessageBird\\Client('ACCESS_TOKEN');
$MessageBird->balance->read();`,
  ruby: `client = MessageBird::Client.new(YOUR_ACCESS_KEY)
client.balance`,
  csharp: `const string YourAccessKey = "YOUR_ACCESS_KEY";
const long PhoneNumber = 31612345678;

Client client = Client.CreateDefault(YourAccessKey);
Lookup lookup = client.DoLookup(PhoneNumber);`,
  go: `balance, err := balance.Read(client)`,
  python: `client = messagebird.Client(ACCESS_KEY)
balance = client.balance()`,
  java: `import com.messagebird.MessageBirdClient;
import com.messagebird.MessageBirdService;
import com.messagebird.MessageBirdServiceImpl;
import com.messagebird.exceptions.GeneralException;
import com.messagebird.exceptions.NotFoundException;
import com.messagebird.exceptions.UnauthorizedException;
import com.messagebird.objects.Balance;

public class ExampleReadBalance {

    public static void main(String[] args) {

        if (args.length == 0) {
            System.out.println("Please specify your access key example : java -jar <this jar file≶ test_accesskey");
            return;
        }

        // First create your service object
        final MessageBirdService wsr = new MessageBirdServiceImpl(args[0]);

        // Add the service to the client
        final MessageBirdClient messageBirdClient = new MessageBirdClient(wsr);

        try {
            // Get Balance
            System.out.println("Retrieving your balance:");
            final Balance balance = messageBirdClient.getBalance();

            // Display balance
            System.out.println(balance.toString());

        } catch (UnauthorizedException unauthorized) {
            if (unauthorized.getErrors() != null) {
                System.out.println(unauthorized.getErrors().toString());
            }
            unauthorized.printStackTrace();
        } catch (GeneralException generalException) {
            if (generalException.getErrors() != null) {
                System.out.println(generalException.getErrors().toString());
            }
            generalException.printStackTrace();
        } catch (NotFoundException notFoundException) {
            if (notFoundException.getErrors() !=null) {
                System.out.println(notFoundException.getErrors().toString());
            }
            notFoundException.printStackTrace();
        }
    }
}`,
  node: `var messagebird = require("messagebird")("YOUR_ACCESS_KEY");

messagebird.balance.read(function (err, response) {
if (err) {
    return console.log(err);
}
console.log(response);
});`,
};

export const GETBALANCE_RESPONSE = {
  curl: `{
  "payment": "prepaid",
  "type": "euros",
  "amount": 103
}`,
  php: `{
  'payment' => 'prepaid',
  'type' => 'euros',
  'amount' => 4929,
}`,
  ruby: `#<MessageBird::Balance:0x007f8d5c83f478
  @amount=9,
  @payment="prepaid",
  @type="credits">`,
  csharp: `{
  'payment': 'prepaid',
  'type': 'euros',
  'amount': 4929,
}`,
  go: `&balance.Balance{
  Payment:"prepaid",
  Type:"credits",
  Amount:9
}`,
  python: `{
  'payment' : 'prepaid',
  'type' : 'euros',
  'amount' : 4929,
}`,
  java: `Balance{payment='prepaid', type='credits', amount=103}`,
  node: `{
    "payment": "prepaid",
    "type": "euros",
    "amount": 4929,
}`,
};
