import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/var/www/html/src/components/Layout/QuickStarts.tsx";
import Notification from 'components/Notification';
import NextSteps from 'components/Footer/NextSteps';
import Questions from 'components/Footer/Questions';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Send an SMS`}</h1>
    <p>{`In this SMS API Quickstart, you'll learn how to send an SMS from your web application using the SMS API.`}</p>
    <h2>{`Step 1: Set up your test environment`}</h2>
    <p>{`To start testing with the official Java SDK for the SMS API, you need to first set up your test environment.`}</p>
    <p>{`The following command will install MessageBird.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`git clone https://github.com/messagebird/java-rest-api
cd java-rest-api/api
mvn install
`}</code></pre>
    <Notification type="INFO" mdxType="Notification">
Want to learn how to set up your Java development environment? Check out the <a href="tutorials/setup-local-dev-environment-java">Java tutorial</a>
    </Notification>
    <h2>{`Step 2: Send your first SMS`}</h2>
    <p>{`Next, enter `}<a parentName="p" {...{
        "href": "https://dashboard.messagebird.com/en/developers/access"
      }}>{`your credentials`}</a>{` and mobile number - as both the `}<inlineCode parentName="p">{`originator`}</inlineCode>{` and `}<inlineCode parentName="p">{`recipient`}</inlineCode>{` - to the snippet below and copy it to your IDE.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`// Create a MessageBirdService
final MessageBirdService messageBirdService = new MessageBirdServiceImpl("YOUR-API-KEY");
// Add the service to the client
final MessageBirdClient messageBirdClient = new MessageBirdClient(messageBirdService);
// convert String number into acceptable format
BigInteger phoneNumber = new BigInteger("+31XXXXXXXXX");
final List<BigInteger> phones = new ArrayList<BigInteger>();
phones.add(phoneNumber);

final MessageResponse response = messageBirdClient.sendMessage("+31XXXXXXXXX", "Hi! This is your first message", phones);
`}</code></pre>
    <Notification type="INFO" mdxType="Notification">
You can access and manage your credentials in the <a href="https://dashboard.messagebird.com/en/developers/access">MessageBird Developer Dashboard</a>.
    </Notification>
    <p>{`Now, run the application from your IDE.`}</p>
    <p>{`Good job! You’ve just sent your first SMS with the MessageBird SMS API!`}</p>
    <h2>{`Step 3: View the response`}</h2>
    <p>{`Now, let’s view MessageBird’s HTTP response to your API call. If the set up was correct, you'll receive a response similar to this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`#<MessageBird::Message:0x007f8d5b883520
@body="Hi! This is your first message",
@createdDatetime=2016-05-03 14:26:57 +0000,
@datacoding="plain",
@direction="mt",
@gateway=239,
@href=
    "https://rest.messagebird.com/messages/211e6280453ba746e8eeff7b12582146",
@id="211e6280453ba746e8eeff7b12582146",
@mclass=1,
@originator="FromMe",
@recipient=
    {"totalCount"=>1,
    "totalSentCount"=>1,
    "totalDeliveredCount"=>0,
    "totalDeliveryFailedCount"=>0,
    "items"=>
    [#<MessageBird::Recipient:0x007f8d5c058c00
        @recipient=31XXXXXXXXX,
        @status="sent",
        @statusDatetime=2016-05-03 14:26:57 +0000>]},
@reference=nil,
@scheduledDatetime=nil,
@type="sms",
@typeDetails={},
@validity=nil>
`}</code></pre>
    <p>{`If something went wrong, you’ll receive an error response from the MessageBird API. Don't worry, simply head over to the `}<a parentName="p" {...{
        "href": "/docs/sms-messaging/troubleshooting"
      }}>{`SMS API Troubleshooting`}</a>{` to understand the error.`}</p>
    <p>{`Congrats! You can now programmatically send SMS with the MessageBird SMS API using Java.`}</p>
    <NextSteps intro="You may now want to check out these resources:" steps={[{
      name: 'Receive an SMS',
      to: '/quickstarts/sms/receive-sms'
    }, {
      name: 'SMS Tutorials',
      to: '/tutorials?filter=sms'
    }, {
      name: 'SMS API Reference',
      to: '/api/sms-messaging'
    }]} mdxType="NextSteps" />
    <Questions mdxType="Questions" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      